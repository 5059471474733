//@ts-nocheck
import React from "react";

// Customizable Area Start
import {
  Paper,
  TableRow,
  TableHead,
  TableContainer,
  TableBody,
  Table,
  Box,
  Button,
  Popover,
  IconButton,
  TablePagination,
  TableCell,
  styled
} from "@material-ui/core";

import FilterIcon from "@material-ui/icons/FilterList";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import ProfessionListController, {  Props, configJSON } from "./ProfessionListController.web";
import PageContainer from "../../navigationmenu/src/PageContainer.web"
import ConfirmationModal from "../../../components/src/customComponents/ConfirmationModal.web";
import FilterPopover from "../../../components/src/FilterPopover";
import SortingTableHeader from "../../../components/src/SortingTableHeader";
import SortingTableCell from "../../../components/src/SortingTableCell";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import { OrgHeading } from "./OrganizationList.web";
import { colors } from "../../utilities/src/Colors";
import { renderBaseOnConditions } from "../../../blocks/utilities/src/CustomBlockHelpers";
// Customizable Area End

export default class ProfessionList extends ProfessionListController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
// Customizable Area Start
  renderDeactivateDialog() {
    return (
      <ConfirmationModal
        open={this.state.disableModalVisible}
        handleClose={this.handleDeactivateClose}
        handleConfirm={this.changeActivateProfession}
        title='Confirm Deactivate'
        message={{
          id: this.state.clickedProfession,
          title: "Profession",
        }}
        dynamic={true}
        confirmText='Deactivate'
      />
    );
  }


 // Customizable Area End
  render() {
    const { permissionStatus } = this.state;
    const rows = this.state.professions.map((profession) => {
      const profession_unique_id = profession.attributes.profession_unique_id;
      const profession_name = profession.attributes.profession_name;
      const profession_id = profession.attributes.id;
      const active = profession.attributes.active;
      return this.createData(profession_unique_id, profession_name, profession_id, active)
    })
    
    const filteredRows = rows.slice(
      this.state.page * this.state.pageSize,
      (this.state.page + 1) * this.state.pageSize
    );

    const emptyRows = this.state.pageSize - filteredRows.length;
    const sortingData = this.state.sortingData;

    // Customizable Area End
    return (
      // Customizable Area Start
        <Box style={webStyle.mainWrapper}>
        <PageContainer data-test-id="pagecontainerId"
          onSearchText={(value) => this.handleSearchProfession(value)}
          onUserChange={(userContext) => this.handleUserChange(userContext)}
          navigation={this.props.navigation}
        >
         <StylesBoxWrapper>
            <Box>
              <div style={webStyle.headerWrapper}>
                <OrgHeading>Profession</OrgHeading>
                <div style={webStyle.headerButtonPartAccount}>
                  <StylesFilterIcon
                   data-test-id="filterBtn"
                    onClick={(event) => {
                      this.setState({
                        filterAnchor: event.currentTarget,
                      });
                    }}
                  >
                    <FilterIcon />
                  </StylesFilterIcon>
                  <FilterPopover
                   data-test-id="filterPopover"
                    onClose={() => {
                      this.setState({ filterAnchor: undefined });
                    }}
                    anchor={this.state.filterAnchor}
                    onAutoCompleteChange={(title: string, value: string) => {
                      this.setState({ suggestionFieldTitle: title });
                      this.handleProfessionAutoComplete(title, value);
                    }}
                    onClearFilter={() => {
                      let updated = this.state.filters.map((item: IFilter) => {
                        item.value = ""; 
                        item.options=[];
                        return item;
                      });
                      this.setState({ filters: updated});
                    }}
                    onFilterChange={this.handleFilterChangeProfession}
                    filters={this.state.filters}
                  />
                  {
                    renderBaseOnConditions(
                      this.state.permissionStatus.createPermission,
                      <AddButton>
                        <Button
                          data-test-id='addProfession'
                          onClick={this.handleAddProfession}
                          className="addButton"
                        >
                          Add Profession
                        </Button>
                      </AddButton>,
                      <></>
                    )
                  }
                </div>
              </div>
              <TableContainer component={Paper}>
                <Table style={webStyle.table} aria-label='customized table'>
                  <TableHead>
                    <TableRow style={{ background: "#204B9C", color: "#FFFFFF" }}>
                      <SortingTableHeader
                        data-test-id="idSortingHeader"
                        sortingData={sortingData}
                        title='Profession ID'
                        sortingKey='profession_unique_id'
                        {...this.sortingProps}
                        type='left'
                        width = '32%'
                       
                      />
                      <SortingTableHeader
                        data-test-id="nameSortingHeader"
                        sortingData={sortingData}
                        title='Profession Name'
                        sortingKey='profession_name'
                        {...this.sortingProps}
                        width = '32%'
                      />

                      <SortingTableHeader
                        sortingData={sortingData} 
                        title='Status'
                        sortingKey=''
                        {...this.sortingProps}
                        width = '32%'
                        sortingDisabled
                      />
                     
                      <SortingTableHeader
                        sortingData={sortingData}
                        title=''
                        type='action'
                        sortingKey=''
                        {...this.sortingProps}
                        sortingDisabled
                        width = '4%'
                      />
                    </TableRow>
                  </TableHead>

                  {filteredRows.length>0&&<TableBody data-test-id="tableData">
                    {filteredRows.map((rowData, index) => (
                      <TableRow key={index + "TableRow"}>
                        <SortingTableCell width='60vw' type='left' content={rowData.profession_unique_id} />
                        <SortingTableCell width='60vw' type='middle' content={rowData.profession_name} />
                        <SortingTableCell width='60vw' type='right' content={rowData.active ? "Active" : "Deactivated"} />
                        <SortingTableCell
                         data-test-id = "moreCell"
                          width='5%'
                          type='action'
                          content={
                            <IconButton
                              data-test-id={`moreButton-${rowData.profession_id}`}
                              id={"IconButton" + index}
                              onClick={(event) => {
                                const position = event.currentTarget.getBoundingClientRect();
                                this.setState({
                                  popOverOpened: true,
                                  popOverLeft: position.left + window.scrollX,
                                  popOverTop: position.top + window.scrollY,
                                  popOverItemId: rowData.profession_id,
                                  popOverItemStatus: rowData.active, 
                                });
                              }}
                            >
                              <MoreVertIcon />
                            </IconButton>
                          }
                        />
                      </TableRow>
                    ))}
                     {emptyRows > 0 && (
                      <TableRow
                        style={{
                          height: 53 * emptyRows,
                        }} >
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>}
                </Table>
                {filteredRows.length === 0 && (
                  <div
                    style={{
                      width: "100%",
                      fontSize: 16,
                      textAlign: "center",
                      fontWeight: 601,
                    }}
                  >
                    No records found!!
                  </div>
                )}
              </TableContainer>
              {filteredRows.length>0&&<TablePagination
                data-test-id='pagination'
                rowsPerPageOptions={[10]}
                component='div'
                count={rows.length}
                rowsPerPage={this.state.pageSize}
                page={this.state.page}
                onPageChange={(event, page) => {this.handlePageChange(page)}}
              />}
            </Box>
          </StylesBoxWrapper>
            <Popover
              data-test-id={configJSON.popoverTest}
              style={{ boxShadow: "none" }}
              open={this.state.popOverOpened}
              onClose={() => this.setState({ popOverOpened: false })}
              anchorReference='anchorPosition' 
              anchorPosition={{
                left: this.state.popOverLeft - 80,
                top: this.state.popOverTop + 40, 
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              anchorOrigin={{
                vertical: "top",
                horizontal: "left",
              }} 
            >
              {
                renderBaseOnConditions(
                  (permissionStatus.viewPermission ||
                  permissionStatus.editPermission ||
                  permissionStatus.deactivatePermission),
                  <div style={webStyle.popoverContainer}>
                    {
                      renderBaseOnConditions(
                        this.state.permissionStatus.viewPermission,
                        <Box
                          data-test-id='viewButton'
                          onClick={() => this.handleViewProfession(this.state.popOverItemId)}
                          sx={webStyle.popoverButton}
                        >
                          View
                        </Box>,
                        <></>
                      )
                    }
                    {
                      renderBaseOnConditions(
                        this.state.permissionStatus.editPermission,
                        <Box
                          data-test-id='editButton'
                          onClick={() => this.handleEditProfession(this.state.popOverItemId)}
                          sx={webStyle.popoverButton}
                        >
                          Edit
                        </Box>,
                        <></>
                      )
                    }
                    {
                      renderBaseOnConditions(
                        this.state.permissionStatus.deactivatePermission && this.state.popOverItemStatus,
                        <Box
                          data-test-id='deactivateButton'
                          sx={webStyle.popoverButton}
                          onClick={() => {this.handleDeactivate(this.state.popOverItemId)}}
                        >
                          {"Deactivate"}
                        </Box>,
                        <></>
                      )
                    }
                    {
                      renderBaseOnConditions(
                        this.state.permissionStatus.activatePermission as boolean && !this.state.popOverItemStatus,
                        <Box
                          data-test-id='activateButton'
                          sx={webStyle.popoverButton}
                          onClick={() =>this.changeActivateProfession()}
                        >
                          {"Active"}
                        </Box>,
                        <></>
                      )
                    }
                  </div>,
                  <div style={webStyle.popoverContainer}>
                    <div style={webStyle.noPermissions}>
                      No Permissions
                    </div>
                  </div>
                )
              }
            </Popover>
          </PageContainer>
          {this.renderDeactivateDialog()}
          {this.state.deactivatedProfession && (
            <ConfirmationModal
              open={this.state.deactivatedProfession}
              handleClose={this.handleDeactivatedProfessionClose}
              handleConfirm={this.handleGoList}
              title={"Profession Deactivated"}
              message={{
                id: this.state.clickedProfession,
                title: "Profession",
              }}
              confirmText={"Back to Listing"}
              displayItem={true}
            />
          )}
          <Snackbar
            anchorOrigin={{
              horizontal: "center",
              vertical: "top",
            }}
            open={this.state.snackbarOpen}
            onClose={this.handleSnackbarClose}
            autoHideDuration={4000}
          >
             <Alert severity={this.state.severity === "success" ? "success" : "error"}>{this.state.snackbarMessage}</Alert> 
          </Snackbar>
        </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  table: {
    minWidth: 700,
  },
  pageHeader: {
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "24px",
    lineHeight: "29px",
  },
  mainWrapper: {
    marginTop: "32px",
    fontFamily: "Montserrat",
  },
  addButton: {
    background: "#204B9C",
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: "24px",
    color: "#FFFFFF",
    height: "56px",
    width: "184px",
    borderRadius: "8px",
  },
  headerWrapper: {
    display: "flex",
    flexWrap: 'wrap',
    justifyContent: "space-between",
    marginBottom: 32,
    alignItems: "center",
  },
  headerButtonPartAccount: {
    display: "flex",
  },
  contentWrapper: {
    marginRight: 32,
    marginTop: 56,
  },
  popoverContainer: {
    paddingTop: "16px",
    paddingBottom: "16px",
    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.03), 0px 8px 32px rgba(0, 0, 0, 0.06)",
    borderRadius: "8px",
    fontFamily: "Montserrat",
    fontWeight: 500,
    fontSize: "14px",
  },
  noPermissions: {
    padding: "0 8px"
  },
  popoverButton: {
    cursor: "pointer",
    width: "118px",
    height: "33px",
    paddingLeft: "24px",
    backgroundColor: "#FFFFFF",
    color: "#4D4D4D",
    "&:hover": {
      backgroundColor: "#204B9C",
      color: "#FFFFFF",
    },
    paddingTop: "8px",
  },
  filterButtonAccount: {
    marginRight: "24px",
    cursor: "pointer",
    background: "#FFFFFF",
    height: "56px",
    color: "#4d4d4d",
    width: "56px",
    fontSize: "24px",
    borderRadius: "8px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid #204B9C",
  },
};

const StylesBoxWrapper = styled(Box)({
  paddingTop:"20px"
});

const StylesFilterIcon = styled('div')({
  marginRight: "24px",
  cursor: "pointer",
  background: colors().white,
  height: "56px",
  color: colors().darkliver,
  width: "56px",
  fontSize: "24px",
  borderRadius: "8px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  border: "1px solid",
  borderColor: colors().cyancobaltblue,
  "@media only screen and (max-width: 1024px)": {
    fontSize: "16px",
    width: "44px",
    height: "44px",
  },
});

const AddButton = styled(Box)({
  '& .addButton': {
    background: colors().cyancobaltblue,
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: "24px",
    color: colors().white,
    height: "56px",
    width: "184px",
    borderRadius: "8px",
    textTransform: "unset",
    "@media only screen and (max-width: 1024px)": {
      fontSize: "13px",
      width: "160px",
      height: "44px",
    },
  }
});

// Customizable Area End
