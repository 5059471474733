import React from "react";

// Customizable Area Start
import { Box, Typography } from "@material-ui/core";
import {
  BackgroundImage,
  BackgroundPaper,
  AvatarImg,
  DynamicHeading,
  DynamicSubHeading,
} from "../../../components/src/customComponents/DynamicContent.web";
import CustomInput from "../../../components/src/customComponents/CostumInput.web";
import CostomButton from "../../../components/src/customComponents/CostomButton.web";
import { backgroundImageLogin } from "../../email-account-login/src/assets";
import CircularProgress from "@material-ui/core/CircularProgress";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
export const configJSON = require("./config");
import CustomComponentLanguage from "../../../components/src/customComponents/CustomComponentLanguage.web";

import ForgotPasswordController, { Props } from "./ForgotPasswordController.web";
import { logo } from "./assets";

export default class ForgotPassword extends ForgotPasswordController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      // Required for all blocks
      <BackgroundImage imageUrl={backgroundImageLogin}>
        <BackgroundPaper>
          <CustomComponentLanguage navigation={undefined} id={""} />
          <Box display="flex" justifyContent="center" paddingTop="0.2vw" paddingBottom="1vw">
            <img src={logo} width="33%"/>
          </Box>
          <DynamicHeading headingName={configJSON.heading} />
          <DynamicSubHeading headingName={configJSON.Subheading} />
          <p style={{ paddingTop: "1.356vw", paddingBottom: "0.5vw", ...webStyle.labelheading }}>
            {configJSON.labelheading}
          </p>
          <Box>
            <CustomInput
              type='text'
              name='emailAddress'
              placeholder='example@email.com'
              disabled={this.state.mobileNumber != ""}
              value={this.state.emailAddress}
              onChange={this.handleInputChange}
              onFocus={this.hideError}
              data-test-id='emailAddress'
            />
          </Box>
          <Box style={webStyle.errorMessageTop}>
            {this.state.errors.emailAddress ? (
              <small style={webStyle.errorStyle}>{this.state.errors.emailAddress}</small>
            ) : (
              <small style={{ visibility: "hidden" }}>""</small>
            )}
          </Box>
          <Typography style={{ ...webStyle.middleTitle, ...webStyle.heading }}>
            {configJSON.labelor}
          </Typography>
          <p style={{ ...webStyle.numberHeading, ...webStyle.labelheading }}>Mobile Number</p>

          <Box style={webStyle.passwordIcon}>
            <CustomInput
              name='mobileNumber'
              placeholder='Enter Mobile Number'
              disabled={this.state.emailAddress !== ""}
              maxLength={10}
              value={this.state.mobileNumber}
              onChange={this.handleInputChangeData}
              onFocus={this.hideError}
              data-test-id='mobileNumber'
              inputError={this.state.errors.mobileNumber}
            />
          </Box>
          <Box style={webStyle.errorMessageTop}>
            {this.state.errors.mobileNumber ? (
              <small style={webStyle.errorStyle}>{this.state.errors.mobileNumber}</small>
            ) : (
              <small style={{ visibility: "hidden" }}>""</small>
            )}
          </Box>
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            open={this.state.snackbarOpen}
            autoHideDuration={4000}
            onClose={this.handleCloseForgetPassword}
          >
            <Alert severity='error'>{this.state.snackbarMessage}</Alert>
          </Snackbar>
          <Box style={webStyle.buttonAdd}>
            <CostomButton
              data-test-id="submitBtn"
              disabled={this.state.emailAddress === "" && this.state.mobileNumber == ""}
              style={{
                textTransform: "none",
                backgroundColor:
                  this.state.emailAddress === "" && this.state.mobileNumber === ""
                    ? "#D3D3D3 "
                    : "#204B9C",

                ...webStyle.buttonStyle,
              }}
              onClick={this.handleForgetPassword}
            >
              {this.state.loadingSignIn ? (
                <CircularProgress color='primary' />
              ) : (
                configJSON.labelsubmit
              )}
            </CostomButton>
          </Box>
        </BackgroundPaper>
      </BackgroundImage>
    );
  }
}
const webStyle = {
  heading: {
    fontFamily: "Montserrat",
    fontWeight: 600,
  },
  labelheading: {
    fontFamily: "Montserrat",
    fontWeight: 600,
    fontSize: "1vw",
  },
  buttonStyle: {
    width: "100%",
    height: "3.472vw",
    borderRadius: "0.556vw",
    alignText: "center",
    color: "#FFFFFF",
    fontFamily: "Montserrat",
    fontWeight: 700,
    fontSize: "1.667vw",
  },
  buttonAdd: {
    display: "flex",
    paddingTop: "1.533vw",
    paddingBottom: "1.533vw",
  },
  passwordIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  errorStyle: {
    padding: "5px",
    color: "red",
    fontFamily: "Montserrat",
    fontSize: " 0.833vw",
  },
  errorMessageTop: {
    marginTop: "0.2vw",
  },
  middleTitle: {
    paddingTop: "0.5vw",
    paddingBottom: "0.5vw",
    fontSize: "1vw",
  },
  numberHeading: {
    marginTop: '13px',
    marginBottom: '5px'
  }
};
// Customizable Area End
