import React from 'react';






interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
    className?: any;
    style?: any;
  }






function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
        style={{ flexGrow:2}}
        
      >
        {value === index && (
          <>
          {children}
          </>
        )}
      </div>
    );
  }


  export default TabPanel;