import React from "react";
// Customizable Area Start
import {
  styled,
  Box,
  FormGroup,
  FormControlLabel,
  Checkbox,
  InputLabel,
  TextField,
  Button,
  MenuProps,
} from "@material-ui/core";
import { Formik, FieldArray, FormikValues, FormikProps } from "formik";
import * as Yup from "yup";

import AddHomeCleaningProductController, {
  IDOption,
  ProductAddIntialValues,
  ProductType,
  configJSON,
} from "./AddHomeCleaningProductController.web";
import { colors } from "../../utilities/src/Colors";
import PageContainer from "../../navigationmenu/src/PageContainer.web"
import RadioDropdownWeb from "../../../components/src/customComponents/RadioDropdown.web";
import { deleteIcon, plusIcon } from "./assets";
import {
  BottomContainer,
  StyledCheckbox,
  StyledChip,
  StyledMenuItem,
} from "../../promocodes/src/PromoCodeCreate.web";
import { CustomStyledTextField, MultiSelectionBox } from "../../../components/src/customComponents/CustomTags.web";
import { CustomSnackbar } from "../../../components/src/customComponents/CustomSnackbar.web";
import { AddOrganizationBtn } from "../../email-account-registration/src/OrganizationList.web";
import { ChipContainer } from "../../promocodes/src/PromoCodeView.web";
import { renderBaseOnConditions } from "../../../blocks/utilities/src/CustomBlockHelpers";
import { CommonIcomSearchComponent } from "../../../components/src/customComponents/CommonIcomSearchComponent";
const Strings = configJSON.Strings.homeCleaningString;
// Customizable Area End

class AddHomeCleaningProduct extends AddHomeCleaningProductController {
  // Customizable Area Start
  checkError(error: boolean, touch: boolean) {
    if (error && touch) {
      return true;
    }
  }

  finalErrorMessage(error: FormikValues, touch: FormikValues) {
    if (error) {
      if (touch) {
        return <span className='errorMsg'>{error}</span>;
      } else {
        return null;
      }
    } else {
      return;
    }
  }

  checkErrorAvailability(errors: FormikValues, index: number, key: string) {
    if (errors && errors.product_type && errors.product_type.length > index) {
      return errors.product_type[index] && errors.product_type[index][key];
    }
    return undefined;
  }

  checkTouchAvailability(touched: FormikValues, index: number, key: string) {
    if (
      touched &&
      touched.product_type &&
      touched.product_type.length > index
    ) {
      return touched.product_type[index] && touched.product_type[index][key];
    }
    return undefined;
  }

  renderTextField(
    label: string,
    key: string,
    errors: FormikValues,
    touched: FormikValues,
    getFieldProps: Function,
    placeholder: string
  ) {
    return (
      <>
        <InputLabel className='InputLabel'>{label}</InputLabel>
        <CustomStyledTextField
          error={this.checkError(errors[key], touched[key])}
          id={key}
          name={key}
          fullWidth={true}
          placeholder={placeholder}
          variant='outlined'
          {...getFieldProps(key)}
        />
        {this.finalErrorMessage(errors[key], touched[key])}
      </>
    );
  }

  renderProductType(
    value: ProductType,
    index: number,
    values: ProductAddIntialValues,
    errors: FormikValues,
    touched: FormikValues,
    getFieldProps: Function,
    setFieldValue: Function
  ) {
    return (
      <Box key={value.id}>
        <h3 className='TitleLabel'>{Strings.productType}</h3>
        <Box className='FormRow'>
          <Box className='FormCol'>
            <CustomStyledTextField
              error={this.checkError(
                this.checkErrorAvailability(errors, index, "type"),
                this.checkTouchAvailability(touched, index, "type")
              )}
              id={`product_type[${index}].[${value.type}]`}
              name={`product_type[${index}].[${value.type}]`}
              fullWidth={true}
              placeholder={`Type ${index + 1}`}
              variant='outlined'
              {...getFieldProps(`product_type[${index}].[type]`)}
              value={value.type}
            />
            {this.finalErrorMessage(
              this.checkErrorAvailability(errors, index, "type"),
              this.checkTouchAvailability(touched, index, "type")
            )}
          </Box>
          <Box className='FormCol'>
            <CustomStyledTextField
              error={this.checkError(
                this.checkErrorAvailability(errors, index, "price"),
                this.checkTouchAvailability(touched, index, "price")
              )}
              id={`product_type[${index}].[${value.price}]`}
              name={`product_type[${index}].[${value.price}]`}
              fullWidth={true}
              placeholder={`Enter price for type ${index + 1}`}
              variant='outlined'
              {...getFieldProps(`product_type[${index}].[price]`)}
              value={value.price}
            />
            <span className='label-span'>
              {values.measurement_type.includes("SQM")
                ? Strings.pricePerSqureMeter
                : Strings.price}
            </span>
            {this.finalErrorMessage(
              this.checkErrorAvailability(errors, index, "price"),
              this.checkTouchAvailability(touched, index, "price")
            )}
          </Box>
          {index > 0 && (
            <Box
              className='DeleteBtn'
              data-test-id={`DeleteBtn-${index + 1}`}
              onClick={() =>
                this.deleteProductType(setFieldValue, values, value.id)
              }
            >
              <img src={deleteIcon} />
            </Box>
          )}
        </Box>
      </Box>
    );
  }

  renderProductViewHeader() {
    const { id } = this.state.viewProductResponse.attributes;
    return (
      <Box className='header-flex'>
        <h2>{Strings.homeCleanProduct}</h2>
        {
          renderBaseOnConditions(
            this.state.permissionStatus.editPermission,
            <AddOrganizationBtn
              onClick={() => this.handleNavigateEditHomeCleanProduct(id)}
            >
              {Strings.edit}
            </AddOrganizationBtn>,
            <></>
          )
        }
      </Box>
    );
  }

  renderProductViewPage() {
    const {
      product_name,
      product_second_name,
      measurement_type,
      cleaning_expected_duration,
      cleaning_process_notes,
      home_cleaning_catalogue_types,
      cleaning_type,
    } = this.state.viewProductResponse.attributes;
    return (
      <div style={{paddingBottom:'50px'}}>
        <ViewContainer>
          <Box className='ViewHeader'>{Strings.homeCleanProduct}</Box>
          <Box className='viewInnerContainer'>
            <Box className='ViewColumn'>
              <Box className='ViewColRow'>
                <h4>{Strings.productName}</h4>
                <p>{product_name}</p>
              </Box>
              <Box className='ViewColRow'>
                <h4>{Strings.productSecondName}</h4>
                <p>{product_second_name}</p>
              </Box>
            </Box>
            <Box className='ViewColumn'>
              {cleaning_type.length > 0 && (
                <Box className='ViewColRow'>
                  <h4>{Strings.availableFor}</h4>
                  <p>{cleaning_type.join(", ")}</p>
                </Box>
              )}
              {measurement_type.length > 0 && (
                <Box className='ViewColRow'>
                  <h4>{Strings.measurementType}</h4>
                  <p>{measurement_type.join(", ")}</p>
                </Box>
              )}
            </Box>
            {cleaning_process_notes && (
              <Box className='ViewColumn'>
                <Box className='ViewColRow'>
                  <h4>{Strings.cleaningNotes}</h4>
                  <p>{cleaning_process_notes}</p>
                </Box>
                <Box className='ViewColRow'>
                  <h4>{Strings.cleaningExpDates}</h4>
                  <p>{cleaning_expected_duration}</p>
                </Box>
              </Box>
            )}
          </Box>
        </ViewContainer>
        <ViewContainer>
          <Box className='ViewHeader'>{Strings.productType}</Box>
          <Box className='viewInnerContainer'>
            {home_cleaning_catalogue_types.map((item) => {
              return (
                <Box className='ViewColumn'>
                  <Box className='ViewColRow'>
                    <h4>{Strings.productType}</h4>
                    <p>{item.data.attributes.name}</p>
                  </Box>
                  <Box className='ViewColRow'>
                    <h4>
                      {measurement_type.includes("SQM")
                        ? Strings.pricePerSqureMeter
                        : Strings.price}
                    </h4>
                    <p>{item.data.attributes.price}</p>
                  </Box>
                </Box>
              );
            })}
          </Box>
        </ViewContainer>
        <AddOrganizationBtn
          onClick={() => this.handleBackToListingPage()}
        >
          {Strings.back}
        </AddOrganizationBtn>
      </div>
    );
  }

  getOptionMultiple(
    placeholder: string,
    array: Array<string | number>,
    allDataLists: Array<IDOption>
  ) {
    return array.length === 0 ? (
      <span>{placeholder}</span>
    ) : (
      <ChipContainer>
        {array.map((item: string | number) => (
          <StyledChip
            label={
              allDataLists.find(({ value }: IDOption) => value === item)?.option
            }
            size='small'
          />
        ))}
      </ChipContainer>
    );
  }

  renderForm = () => {
    const { measurementOptions, isEdit, avabilityOptions } = this.state;
    const validationSchema = Yup.object({
      product_name: Yup.string()
        .trim()
        .required("Product Name is required."),
      product_second_name: Yup.string()
        .trim()
        .required("Product Second Name is required."),
      cleaning_type: Yup.string()
        .trim()
        .required("Please select availability."),
      cleaning_expected_duration: Yup.number()
        .typeError("Cleaning expected duration must be a valid number")
        .positive("Cleaning expected duration must be a positive number")
        .min(1, "Cleaning expected duration must be greater than 0")
        .required("Cleaning expected duration is required")
        .test(
          "not-special-characters",
          'Cleaning expected duration cannot be "-"',
          (value) => value !== "-"
        ),
      measurement_type: Yup.string()
        .trim()
        .required("Please select measurement"),
      image_id: Yup.string()
        .trim()
        .when([], {
          is: () => this.state.selectedImage.id === undefined,
          then: Yup.string().required("Required")
        }),
      product_type: Yup.array().of(
        Yup.object().shape({
          type: Yup.string().required("Type is required"),
          price: Yup.number()
            .typeError("Price must be a valid number")
            .positive("Price must be a positive number")
            .min(1, "Price must be greater than 0")
            .required("Price is required")
            .test(
              "not-special-characters",
              'Price cannot be "-"',
              (value) => value !== "-"
            ),
        })
      ),
    });

    const CustomMenuProps: Partial<MenuProps> = {
      PaperProps: {
        style: { maxHeight: 250 },
      },
      anchorOrigin: {
        vertical: "bottom",
        horizontal: "left",
      },
      transformOrigin: {
        vertical: "top",
        horizontal: "left",
      },
      getContentAnchorEl: null,
    };
    return (
      <Box className='mainContainer'>
        <Formik
          data-test-id='addPriceFormik'
          initialValues={this.state.addHomeCleaningProductIntialVal}
          validationSchema={validationSchema}
          onSubmit={(values: ProductAddIntialValues) =>
            this.handleFormSubmit(values)
          }
          enableReinitialize
        >
          {({
            values,
            errors,
            touched,
            handleSubmit,
            setFieldValue,
            getFieldProps,
          }: FormikProps<FormikValues>) => (
            <form onSubmit={handleSubmit}>
              <FormGroup className='FormGroup'>
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={(e) => {
                        const newValue = e.target.checked;
                        setFieldValue(
                          "temp_unavailable",
                          isEdit ? !newValue : newValue
                        );
                      }}
                      checked={
                        isEdit
                          ? !values.temp_unavailable
                          : values.temp_unavailable
                      }
                      value={values.temp_unavailable}
                    />
                  }
                  label='Temporary Unavailable'
                />
              </FormGroup>
              <Box className='FormRow'>
                <Box className='FormCol'>
                  {this.renderTextField(
                    Strings.productName,
                    "product_name",
                    errors,
                    touched,
                    getFieldProps,
                    Strings.productNamePlaceholder
                  )}
                </Box>
                <Box className='FormCol'>
                  {this.renderTextField(
                    Strings.productSecondName,
                    "product_second_name",
                    errors,
                    touched,
                    getFieldProps,
                    Strings.productSecondNamePlaceholder
                  )}
                </Box>
              </Box>
              <Box className='FormRow'>
                <Box className='FormCol'>
                  <InputLabel className='InputLabel'>
                    {Strings.availableFor}
                  </InputLabel>
                  <MultiSelectionBox
                    multiple
                    fullWidth
                    displayEmpty
                    value={values.cleaning_type}
                    id='cleaning_type'
                    name='cleaning_type'
                    variant='outlined'
                    data-test-id='cleaning-type-dropdown'
                    error={errors.cleaning_type && touched.cleaning_type}
                    renderValue={(array: unknown) =>
                      this.getOptionMultiple(
                        Strings.selectAvaibility,
                        array as Array<string | number>,
                        avabilityOptions.map((item: IDOption) => ({
                          value: item.value,
                          option: String(item.value)
                            .split("_")
                            .join(" "),
                        }))
                      )
                    }
                    onChange={(
                      e: React.ChangeEvent<{
                        value: Array<string | number>;
                      }>
                    ) => {
                      setFieldValue(
                        "cleaning_type",
                        e.target.value as string[]
                      );
                    }}
                    MenuProps={CustomMenuProps}
                  >
                    {avabilityOptions.map((item: IDOption) => {
                      return (
                        <StyledMenuItem
                          key={"cleaningType-" + item.value}
                          value={item.value}
                        >
                          <StyledCheckbox
                            checked={values.cleaning_type.includes(
                              item.value as never
                            )}
                          />
                          {item.option}
                        </StyledMenuItem>
                      );
                    })}
                  </MultiSelectionBox>
                  {errors.cleaning_type && touched.cleaning_type && (
                    <span className='errorMsg'>{errors.cleaning_type}</span>
                  )}
                </Box>
                <Box className='FormCol'>
                  <InputLabel className='InputLabel'>
                    {Strings.measurementType}
                  </InputLabel>
                  <MultiSelectionBox
                    multiple
                    fullWidth
                    displayEmpty
                    value={values.measurement_type}
                    id='measurement'
                    name='measurement'
                    variant='outlined'
                    data-test-id='measurement-type-dropdown'
                    error={errors.measurement_type && touched.measurement_type}
                    renderValue={(array: unknown) =>
                      this.getOptionMultiple(
                        Strings.selectMeasurementType,
                        array as Array<string | number>,
                        measurementOptions.map((item: IDOption) => ({
                          value: item.value,
                          option: String(item.value)
                            .split("_")
                            .join(" "),
                        }))
                      )
                    }
                    onChange={(
                      e: React.ChangeEvent<{
                        value: Array<string | number>;
                      }>
                    ) => {
                      setFieldValue(
                        "measurement_type",
                        e.target.value as string[]
                      );
                    }}
                    MenuProps={CustomMenuProps}
                  >
                    {measurementOptions.map((item: IDOption) => {
                      return (
                        <StyledMenuItem
                          key={"measurement-" + item.value}
                          value={item.value}
                        >
                          <StyledCheckbox
                            checked={values.measurement_type.includes(
                              item.value as never
                            )}
                          />
                          {item.option}
                        </StyledMenuItem>
                      );
                    })}
                  </MultiSelectionBox>
                  {errors.measurement_type && touched.measurement_type && (
                    <span className='errorMsg'>{errors.measurement_type}</span>
                  )}
                </Box>
              </Box>
              <FieldArray
                name='product_type'
                data-testid='dynamicField'
                render={() => {
                  return values.product_type
                    .filter((item: ProductType) => !item._destroy)
                    .map((value: ProductType, index: number) => {
                      return this.renderProductType(
                        value,
                        index,
                        values as ProductAddIntialValues,
                        errors,
                        touched,
                        getFieldProps,
                        setFieldValue
                      );
                    });
                }}
              />
              <Box className='inline-flex'>
                <BlueButton
                  data-test-id='btn-addProductType'
                  onClick={() =>
                    this.addMoreTypeField(
                      setFieldValue,
                      values as ProductAddIntialValues
                    )
                  }
                >
                  <img src={plusIcon} />
                </BlueButton>
              </Box>
              <StyledTextArea
                minRows={3}
                multiline
                fullWidth
                placeholder={Strings.cleaningNotes}
                value={values.notes}
                onChange={(
                  event: React.ChangeEvent<{
                    value: string;
                  }>
                ) => {
                  setFieldValue("notes", event.target.value);
                }}
                inputProps={{
                  "data-test-id": "field-description",
                }}
              />
              <Box className='FormRow'>
                <Box className='FormCol'>
                  {this.renderTextField(
                    Strings.cleaningExpDates,
                    "cleaning_expected_duration",
                    errors,
                    touched,
                    getFieldProps,
                    Strings.expectedDurationPlaceholder
                  )}
                </Box>
                <Box className='FormCol' />
              </Box>
              <Box className='FormRow'>
                <Box className='FormCol'>
                  <InputLabel className='InputLabel'>
                    {Strings.image}
                  </InputLabel>
                  <CommonIcomSearchComponent 
                    value={[]}
                    optionList={this.state.gallaryImageOptions}
                    debouncedFunction={() => {}}
                    handleScroll={this.handleLoadingImageScroll}
                    handleSelectOptions={this.handleSelectImage}
                    selectedItem={this.state.selectedImage}
                    placeholder="Select Image From Gallery"
                    defaultId={this.state.addHomeCleaningProductIntialVal.image_id}
                    isError={!this.state.selectedImage.id && Boolean(errors.image_id)}
                    errorText="Please select image"
                    
                  />
                </Box>
                <Box className='FormCol' />
              </Box>
              <BottomContainer>
                <button
                  type='button'
                  data-test-id='btn-cancel'
                  className='cancel'
                  onClick={() =>
                    this.props.navigation.navigate("HomeCleaningProductList")
                  }
                >
                  {Strings.cancel}
                </button>
                <button type='submit' data-test-id='btn-submit' className='add'>
                  {isEdit ? Strings.save : Strings.add}
                </button>
              </BottomContainer>
            </form>
          )}
        </Formik>
      </Box>
    );
  };
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { isViewProductPage } = this.state;
    // Customizable Area End
    return (
      // Customizable Area Start
      <PageContainer
        navigation={this.props.navigation}
        onUserChange={(userContext) => this.handleUserChange(userContext)}
      >
        <StyledWrapper>
          {!isViewProductPage && (
            <h2 className='pageHeading'>{Strings.addNewProduct}</h2>
          )}
          {isViewProductPage && this.renderProductViewHeader()}
          {!isViewProductPage && this.renderForm()}
          {isViewProductPage && this.renderProductViewPage()}

          <CustomSnackbar
            data-test-id='succesSnakBar'
            open={this.state.createSuccessSnackbarOpen}
            onClose={this.handleSnackbarClose}
            errorMessage={
              this.state.isEdit
                ? Strings.editProductSuccessMsg
                : Strings.CreateProductSuccessMsg
            }
            severity='success'
          />
        </StyledWrapper>
      </PageContainer>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const StyledWrapper = styled("div")({
  marginBottom: '50px',
  "& .d-flex": {
    display: "flex",
  },
  "& .header-flex": {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    margin: "0 0 32px 0",
    "& h2": {
      fontFamily: "Montserrat",
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: "24px",
      lineHeight: "29px",
      "@media only screen and (max-width: 1024px)": {
        fontSize: "16px",
      }
    },
  },
  "& .inline-flex": {
    display: "inline-flex",
    "@media only screen and (max-width: 1024px)": {
      height:'44px'
    }
  },
  "& .errorMsg": {
    fontSize: "12px",
    marginTop: "4px",
    color: colors().error,
  },
  "& .pageHeading": {
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "24px",
    lineHeight: "29px",
    paddingLeft:32,
    "@media only screen and (max-width: 1024px)": {
      fontSize: '16px',
    }
  },
  "& .mainContainer": {
    padding: "32px",
    borderRadius: "12px",
    boxShadow:
      "0px 4px 8px rgba(0, 0, 0, 0.03), 0px 8px 32px rgba(0, 0, 0, 0.06)",
  },
  "& .FormGroup": {
    display: "inline-flex",
    "& .MuiFormControlLabel-label": {
      fontSize: "16px",
      lineHeight: "24px",
      fontFamily: "Montserrat",
      fontWeight: 400,
      color: colors().darkjunglegreen,
      "@media only screen and (max-width: 1024px)": {
        fontSize: '13px',
      }
    },
  },
  "& .FormRow": {
    display: "flex",
    gap: "32px",
    margin: "24px 0",
  },
  "& .FormCol": {
    width: "100%",
  },
  "& .InputLabel": {
    fontFamily: "Montserrat",
    fontSize: "16px",
    lineHeight: "24px",
    fontWeight: 600,
    color: colors().charcoal,
    margin: "0 0 4px 0",
    "@media only screen and (max-width: 1024px)": {
      fontSize: '13px',
    }
  },
  "& .HcTextField": {
    fontFamily: "Montserrat",
  },
  "& .TitleLabel": {
    fontFamily: "Montserrat",
    fontSize: "16px",
    lineHeight: "24px",
    fontWeight: 600,
    color: colors().black,
    "@media only screen and (max-width: 1024px)": {
      fontSize: '13px',
    }
  },
  "& .DeleteBtn": {
    minWidth: "56px",
    height: "56px",
    background: colors().brightgray,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "8px",
    cursor: "pointer",
  },
  "& .label-span": {
    fontFamily: "Montserrat",
    fontSize: "14px",
    lineHeight: "18px",
    fontWeight: 400,
    color: colors().cyancobaltblue,
    display: "block",
    margin: "3px 0 0 2px",
  },
});

const BlueButton = styled(Button)({
  gap: 8,
  padding: 16,
  flexShrink: 0,
  width: "100%",
  height: "100%",
  borderRadius: 8,
  cursor: "pointer",
  alignItems: "center",
  display: "inline-flex",
  justifyContent: "center",
  background: "var(--primary, #204B9C)",
  "&:hover": {
    background: "var(--primary, #204B9C)",
  },
  "& > img": {
    maxWidth: "100%",
  },
});

const ViewContainer = styled(Box)({
  borderRadius: "12px",
  marginBottom: "32px",
  boxShadow:
    "0px 4px 8px rgba(0, 0, 0, 0.03), 0px 8px 32px rgba(0, 0, 0, 0.06)",
  "& .ViewHeader": {
    fontSize: "18px",
    fontWeight: 600,
    padding: "19px 32px",
    color: colors().white,
    background: colors().cyancobaltblue,
    "@media only screen and (max-width: 1024px)": {
      fontSize: '16px',
    }
  },
  "& .viewInnerContainer": {
    padding: "32px",
    display: "flex",
    flexDirection: "column",
    gap: "20px",
  },
  "& .ViewColumn": {
    display: "flex",
    alignItems: "center",
    gap: 24,
    "@media (max-width: 800px)": {
      flexDirection: "column",
    },
  },
  "& .ViewColRow": {
    width: "50%",
    fontFamily: "Montserrat",
    "@media (max-width: 800px)": {
      width: "100%",
    },
    "& h4": {
      fontSize: "16px",
      lineHeight: "22px",
      fontWeight: 600,
      color: colors().darkliver,
    },
    "& p": {
      fontSize: "16px",
      lineHeight: "22px",
      color: colors().viewTextColor,
      marginTop: "12px",
      "@media only screen and (max-width: 1024px)": {
        fontSize: '13px',
      }
    },
  },
});

const StyledTextArea = styled(TextField)({
  marginTop: 32,
  borderRadius: 8,
  "& .MuiInput-underline::before, & .MuiInput-underline::after": {
    border: "none !important",
  },
  "& .MuiInput-multiline": {
    padding: 8,
    borderRadius: 8,
    border: "1px solid var(--border, #ECECEC)",
    boxShadow: "0px 0px 1px rgba(0, 0, 0, 0.87)",
  },
});
export default AddHomeCleaningProduct;
// Customizable Area End
