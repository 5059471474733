import { IUserContext } from "../../navigationmenu/src/PageContainerController.web";
import { IFilter } from "../../../components/src/FilterPopover";

type ChartType = {
  data: Array<{
    section: string;
    sub_total: number;
    vat: number
  }>
}

export const handleDisplayRegion = (regionId: string, regionName: string, isSelectedAll?: boolean) => {
    const countSelectedRegion = regionId.split(",").length
    const selectedRegionText = countSelectedRegion > 1 ? `Selected Regions (${countSelectedRegion})` : regionName
    const textDisplayed = isSelectedAll ? "All Regions" : selectedRegionText
    return {
      textDisplayed
    }
}

export const handleUserContextDashboard = (context: IUserContext) => {
  const { region, store_management } = context.user?.attributes.employee_proifle.data.attributes || {}
  const regionId = region?.data.attributes.id.toString()
  const regionName = region?.data.attributes.branch_name.toString()
  const storeId = store_management?.store_id.toString()
  const storeName = store_management?.store_name.toString()
  return { regionId, regionName, storeId, storeName }
}

export const transformDataChartDashboard = (dataResponse: ChartType) => {
  const transform = dataResponse.data.map((responseDashboard, index) => ({
    name: responseDashboard.section,
    value_1: responseDashboard.vat,
    value_2: responseDashboard.sub_total
  }))
  return transform
}

export const handleFilterCustomDate = (filters: IFilter[]) => {
  const dateFilter = filters.find((item) => item?.title === "Date")?.datevalue;
  return {
    from: dateFilter?.from,
    to: dateFilter?.to
  }
}

export const transformDataChartDashboardNext = (dataResponse: ChartType) => {
  const transform = dataResponse.data.map((responseDashboard, index) => ({
    name: responseDashboard.section,
    first: responseDashboard.vat,
    second: responseDashboard.sub_total
  }))
  return transform
}

export const storeNameBasedOnGroup = (storeName: string) => {
  if(!storeName) {
    return `Selected Stores: (0)`
  } 
  const storeArr = storeName.split(",").length
 return  `Selected Stores (${storeArr})` 
}

export const handleFilterCustomDateNext = (filters: IFilter[]) => {
  const dateFilters = filters.find((item) => item?.title === "Date")?.datevalue;
  return {
    fromNext: dateFilters?.from,
    toNext: dateFilters?.to
  }
}