import React from "react";
// Customizable Area Start
import { Styles } from "@material-ui/styles";
import { withStyles, Theme } from "@material-ui/core/styles";

import QuickDropController, { Props } from "./QuickDropController.web";

import { quickDropIcon } from "./assets";
import { styled } from "@material-ui/core";

// Customizable Area End

export default class QuickDrop extends QuickDropController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
    <StyledWrapper>
      <div
        data-test-id='quickDropContainer'
        className={'quickDropContainer'}
      >
        <div className={'quickDropInnerContainer'}>
          <img className={'quickDropIcon'} src={quickDropIcon} />
          <div>Quick Drop</div>
        </div>

        <StyledButton
          data-test-id='quickDropMinusButton'
          style={{
            cursor: this.props.disabled ? "not-allowed" : "pointer",
          }}
          disabled={this.props.disabled}
          onClick={() => {
            if (this.state.count > 0) {
              this.setState({ count: this.state.count - 1 });
              this.props.onCountChange &&
                this.props.onCountChange(this.state.count - 1);
            }
          }}
        >
          -
        </StyledButton>
        <input
          data-test-id='quickDropInput'
          className={'quickDropInput'}
          value={this.state.count}
          disabled={this.props.disabled}
          onChange={this.props.handleChangeQty}
          type='text'
        />
        <StyledButton
          data-test-id='quickDropPlusButton'
          style={{
            cursor: this.props.disabled ? "not-allowed" : "pointer",
          }}
          disabled={this.props.disabled}
          onClick={() => {
            this.setState({ count: this.state.count + 1 });
            this.props.onCountChange &&
              this.props.onCountChange(this.state.count + 1);
          }}
        >
          +
        </StyledButton>
      </div>
    </StyledWrapper>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const styles = {
  buttons: {
    width: "20%",
    textAlign: "center",
    fontSize: "48px",
    color: "#1C1B1F",
    borderRight: "1px solid #ECECEC",
    borderLeft: "1px solid #ECECEC",
    cursor: "pointer",
    background: "white",
    borderTop: "none",
    borderBottom: "none",
  },
};

const StyledWrapper = styled('div')({
  "& .quickDropContainer": {
    width: "100%",
    display: "flex",
    borderRadius: "8px",
    border: "1px solid #ECECEC",
    marginTop: "24px",
  },
  "& .quickDropInnerContainer": {
    width: "40%",
    display: "flex",
    fontSize: "14px",
    alignItems: "center",
    margin: 14,
  },
  "& .quickDropIcon": { 
    width: "24px", 
    height: "24px", 
    marginRight: "8px" 
  },
  "& .quickDropInput": {
    width: "20%",
    border: "none",
    fontSize: 16,
    textAlign: "center",
    fontWeight: 500,
    outline: "none"
  },
  "& .quickDropInput:focus": {
    border: "1px solid black"
  },
  // "& .quickDropInput:focus-visible": {
  //   border: "1px solid black"
  // },
});

const StyledButton = styled("button")({
  width: "20% !important",
  textAlign: "center",
  fontSize: "48px !important",
  color: "#1C1B1F !important",
  borderRight: "1px solid #ECECEC !important",
  borderLeft: "1px solid #ECECEC !important",
  cursor: "pointer",
  background: "white !important",
  borderTop: "none !important",
  borderBottom: "none !important",
  "@media only screen and (max-width: 1024px)": {
    fontSize: "30px !important",
  },
});

// Customizable Area End
