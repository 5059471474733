export const emptyMyOrdersIcon = require("../assets/emptyMyOrdersIcon.png");
export const trashIcon = require("../assets/trash.svg");
export const printIcon = require("../assets/print.svg");
export const editIcon = require("../assets/edit.svg");
export const circleIcon = require("../assets/circle.svg");
export const crossIcon = require("../assets/cross.svg");
export const checkedIcon = require("../assets/checkedIcon.png");
export const unCheckedIcon = require("../assets/unCheckedIcon.png");
export const iconFolded = require("../assets/folded.png");
export const iconTShirt = require("../assets/tshirt.png");
export const iconLaundry = require("../assets/laundry.png");
export const iconVipSuit = require("../assets/vip-suit.png");
export const iconRoundCheck = require("../assets/round-check.svg");

export const iconLabelBlue = require("../assets/label-blue.svg");
export const iconLabelGray = require("../assets/label-gray.svg");
export const iconRecipetBlue = require("../assets/recipt-blue.svg");
export const iconRecipetGray = require("../assets/recipt-gray.svg");
export const iconPrinterGray = require("../assets/print-gray.svg");
export const iconFilterGray = require("../assets/filter-gray.svg");
export const iconUnpaidGray = require("../assets/unpaid.svg");
export const iconPlusAdd = require('../assets/orderDetailsPlusIcon.svg');
export const CheckTrue = require("../../../components/src/check_true.svg");
export const CheckFalse = require("../../../components/src/check_false.svg");
export const radio_white = require('../assets/radiobutton_white.svg');
export const radio_blue = require('../assets/radiobutton_blue.svg');
export const button_minus_dark = require('../assets/button_minus_dark.svg');
export const button_minus_light = require('../assets/button_minus_light.svg');
export const button_plus_dark = require('../assets/button_plus_dark.svg');
export const button_plus_light = require('../assets/button_plus_light.svg');
export const IconOrderDetailsCircle = require("../assets/orderDetailsCircle.svg");

