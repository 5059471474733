import React, { useState, useRef, useEffect } from 'react';

type Props = {
    src: string;
    alt?: string;
    width: number;
    height: number;
    className: string;
}

const LazyImage = ({ src, alt, width, height, className, ...props }: Props) => {
  const [isIntersecting, setIsIntersecting] = useState(false);
  const imgRef = useRef<HTMLImageElement | null>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            setIsIntersecting(true);
            observer.disconnect();
          }
        });
      },
      { threshold: 0.1 }
    );

    if (imgRef.current) {
      observer.observe(imgRef.current);
    }

    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <img
      ref={imgRef}
      src={isIntersecting ? src : ''}
      alt={alt}
      width={width}
      height={height}
      className={className}
      {...props}
      loading="lazy"
    />
  );
};

export default LazyImage
