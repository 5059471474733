// Customizable Area Start
export const encodeString = (str: string | null, key: string | null) => {
  let encoded = "";
  str = str || "";
  key = key || "";
  for (let i = 0; i < str.length; i++) {
    const charCode = str.charCodeAt(i) + key.charCodeAt(i % key.length);
    encoded += String.fromCharCode(charCode);
  }
  return encoded;
};

export const decodeString = (encodedStr: string | null, key: string | null) => {
  let decoded = "";
  encodedStr = encodedStr || "";
  key = key || "";
  for (let i = 0; i < encodedStr.length; i++) {
    const charCode = encodedStr.charCodeAt(i) - key.charCodeAt(i % key.length);
    decoded += String.fromCharCode(charCode);
  }
  return decoded;
};

// Customizable Area End
