import { CSSProperties } from "@material-ui/styles";


const styles: Record<string, CSSProperties> = {
    customCheckbox: {
      display: 'flex',
      alignItems: 'center',
      padding: '4px 8px',
      gap: '7px',
      backgroundColor: 'rgba(32, 75, 156, 0.1)',
      borderRadius: '16px',
      color: "#204B9C",
      
  
    },
    secondName: {
      height: '60px',
      borderRadius: '8px',
      display: "flex",
      justifyContent: "center",
      paddingLeft: '8px'
    },
    cancelBtn: {
      backgroundColor: '#F1F5F9',
      color: '#64748B',
      width: '126px',
      height: '56px',
      textTransform: 'none',
      border: "0"
    },
    addBtn: {
      backgroundColor: "#204B9C",
      color: "#ffff",
      height: "56px",
      width: '184px',
      marginLeft: "32px",
      textTransform: "none",
      fontSize: "16px",
      fontWeight: 700,
      border: "0",
      "&:hover": {
        backgroundColor: "#204B9C",
        color: "#ffff"
      },
      borderRadius:'8px'
    },
    servicesBtn: {
      display: "flex",
      minHeight: "60px",
      justifyContent: "center",
      borderRadius: "8px",
      padding: "10px",
      flexWrap: "wrap",
      boxSizing: 'border-box'
    },
    flexContainer: {
      display: "flex",
      flexWrap: "wrap",
      gap: "10px"
    },
    enterName: {
      height: "60px",
      borderRadius: '8px',
      display: "flex",
      justifyContent: "center",
      paddingLeft: "8px"
    },
   
    button: {
      fontSize: "16px",
      fontWeight: 700
    },
  
    dropDown: {
      maxWidth: '498px',
      overflowY: 'auto'
  
  
    },
    cardBox: {
      minHeight: "320px",
      borderRadius: "12px",
      border: 0,
      boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.03), 0px 8px 32px rgba(0, 0, 0, 0.06)"
    },
    titleContainer: {
      display: "flex",
      borderColor: "1px solid red",
      justifyContent: "space-between",
      alignItems: "center",
      margin: "10px 0 0 10px",
      padding: "46px 0",
    },
    productContainer: {
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      width: "100%",
      backgroundColor: "white",
      marginTop: "20px",
    },
    titleContainerText: {
      fontSize: '24px',
      fontWeight: 600,
      lineHeight: '29.26px',
  
  
    },
    mainContainer: {
      margin: '32px 0',
  
    },
    GalleryTitle: {
      display: "flex",
      borderColor: "1px solid red",
      justifyContent: "space-between",
      alignItems: "center",
      margin: "10px 0 0 10px",
    },
    listParenContainer: {
      border: "1px solid #ECECEC",
      height: "64px",
      borderRadius: "8px",
    },
   
      ImgContainer: {
        height: 150,
      },
      productImg: {
        width: "100%",
        height: "100%",
      },
      detailContent: {
        display: "flex",
        flexDirection: "column",
      },
  
      galleryBtn: {
        backgroundColor: "#204B9C",
        color: "#FFFFFF",
        borderRadius: "8px",
    
      },
      productIconCard: {
        minHeight: "400px",
        borderRadius: "12px",
        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.03), 0px 8px 32px rgba(0, 0, 0, 0.06)"
      },
      cstHeader: {
        background: "#204B9C",
        boxShadow: "0px 4px 8px rgb(0 0 0 / 3%), 0px 8px 32px rgb(0 0 0 / 6%)",
        borderRadius: "12px 12px 0px 0px",
        padding: "19px 44px",
        color: "#fff",
      },
      cardSmTitle: {
        fontWeight: 600,
        fontSize: "18px",
      },
      cardContent: {
        padding: "32px 65px",
      },
      gridBlock: {
        textAlign: "center",
      },
      cardTitle: {
        fontWeight: 600,
        fontSize: "16px",
        lineHeight: "22px",
        color: "#1A1A1A",
      },
      grid: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      },
      subtitle: {
        fontWeight: 500,
        fontSize: "12px",
        lineHeight: "22px",
      },
    
      marginTop: {
        marginTop: "2rem",
      },
      fw600: {
        fontWeight: 600,
      },
      margin: {
        margin: "2rem 0",
      },
      flex: {
        display: "flex",
        flexDirection: "column",
      },
      flexBetween: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      },
      marginBottom_26: {
        marginBottom: "26px",
      },
      fontSize_24: {
        fontSize: "24px",
      },
   
      lineHeight_29: {
        lineHeight: "29.26px",
      },
      padding_46: {
        padding: "46px 0",
      },
      buttonPrimary: {
        height: "56px",
        width: "184px",
        fontSize: "16px",
      },
      marginLeft76: {
       
      },
      maxWidth300: {
        maxWidth: "300px",
      },
      marginLeft23: {
        marginLeft: "23px",
      },
      marginTopBotton10: {
        margin: "10px 0",
      },
      marginLeft22: {
        marginLeft: "22px",
      },
      textCenter: {
        textAlign: "center",
      },
      parentContainer:{
          width: '100%',
          padding:'10px 49px'
      },
      serviceContainer:{
        
          display: 'flex',
          justifyContent: 'space-evenly',
          gap: '4rem'
    
      },
      activeContainer:{    
        display: 'flex',
        justifyContent: 'space-around',
        marginRight: '5rem'
    }
  
  }

  export default styles;