Object.defineProperty(exports, "__esModule", {
  value: true,
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "promocodes";
exports.labelBodyText = "promocodes Body";
exports.btnExampleTitle = "CLICK ME";
exports.getPromocodesEndPoint = "promo_codes/promo_codes?city_id=";
exports.getPromocodesAPIMethod = "GET";
exports.updatePromoteAPIMetho = "PATCH";
exports.placeHolderTextArea = "Select Area";
exports.emptyAreaDataMessage = "No Area Found";
exports.placeHolderTextSection = "Select Section";
exports.emptySectionDataMessage = "No Section Found";

exports.ApiUrls = {
  getInitialPromoCode: "/bx_block_promo_codes/promo_codes/initialize",
  excludeDayList: "bx_block_promo_codes/promo_codes/exclude_days_listing",
  editPromoCode: "bx_block_promo_codes/promo_codes/",
  savePromoCode: "bx_block_promo_codes/promo_codes",
  productList: "bx_block_catalogue/catalogues?dropdown=true",
  servicesList: "bx_block_categories/services",
  promoCodeShow: "bx_block_promo_codes/promo_codes/",
  platformSuggestion:
    "bx_block_promo_codes/promo_codes/promo_code_filter?platform=",
  promoCodeSuggestion:
    "bx_block_promo_codes/promo_codes/promo_code_filter?promo_code=",
  sectionList: "bx_block_categories/categories?dropdown=true&name=asc&token=",
  regionList: "bx_block_regions/regions?dropdown=true",
  areaList: "bx_block_regions/areas?dropdown=true",
  storeList: "bx_block_store_management/store_managements?dropdown=true&token=",
  sortingApi: "bx_block_promo_codes/promo_codes?",
  listApi: "bx_block_promo_codes/promo_codes?",
  platformList: "bx_block_promo_codes/promo_codes/platform_listing",
  homeCleanProductDropdownList: '/bx_block_catalogue/home_cleaning_catalogues',
};

exports.Strings = {
  token: "token",
  promoCodeList: {
    view: "View",
    edit: "Edit",
    active: "Active",
    endDate: "End Date",
    startDate: "Start Date",
    deactivate: "Deactivate",
    pageTitle: "Promo Codes",
    addPromoCode: "Add Promo Code",
    noRecordFounds: "No records found!!",
  },
  promoCodeView: {
    pageTitle: "Promo Code",
    edit: "Edit",
    noData: "Data Not Found!",
    back: "Back",
    description: "Description",
    promoCodeLabel: "Promo Code",
    section: "Section",
    region: "Region",
    area: "Area",
    services: "Services",
    product: "Product",
    platform: "Platform",
    used: "Used",
    nosOfRecords: "No. of Orders",
    store: "Store",
    startDate: "Start Date",
    endDate: "End Date",
    promoNotExpire: "Promo code does not expire",
    type: "Promo Code Type",
    promoCodeType: "Promo Code Type",
    discountProduct: "Discount Product",
    discountPlaceHolder: "Amount/ Percentage",
    amount: "Amount",
    excludeDay: "Exclude Day",
    availableTime: "Available Time",
    currency: "SAR",
    minAmount: "Minimum Order Amount",
    maxAmount: "Maximum Discount Amount",
    home_cleaning_product: "Home Cleaning Product",
    orderType: "Order Type",
    noViewDataString: "----",
  },
  promoCodeCreate: {
    error: {
      amountInvalid: "Amount must be a positive number",
      enterDescription: "Please enter description",
      enterPromoCode: "Please enter promo code",
      selectSection: "Please select section",
      selectServices: "Please select services",
      selectProducts: "Please select products",
      selectPlatforms: "Please select platforms",
      selectUsed: "Please select used",
      selectRegion: "Please select region",
      selectArea: "Please select area",
      selectStore: "Please select store",
      selectNoOfOrders: "Please enter no. of orders",
      noOfOrdersInvalid: "No. of orders must be a positive number",
      selectStartDate: "Please select start date",
      selectEndDate: "Please select end date",
      selectExcludeDay: "Please select exclude days",

      enterAmountRequired: "Please enter amount / percentage",
      enterAmountInvalid: "Amount / percentage must be a positive number",
      selectDiscountType: "Please select type",
      selectDiscountProduct: "Please select discount product",
      selectAvailableTime: "Please select available times",
      checkErrors: "Please check errors!",
      minGreaterThanMax: "Min amount cannot be more than Max amount",
      greaterThanHundred: "Discount % cannot be greater than 100",
      minAmount: "Please add minimum order amount",
      maxAmount: "Please add Maximum order amount",
    },
    selectStartEndDate: "Please select start or end date",
    promoError: "Please check errors!",
    promoSave: "Promo code is saved!",
    promoCode: "Promo Code",
    edit: "Edit",
    create: "Create",
    somethingWentWrong: "Something went wrong!",
    addDescription: "Add Description *",
    enterPromoCode: "Enter Promo Code",
    section: "Section *",
    home_cleaning_product: "Home Cleaning Product",
    region: "Region *",
    area: "Area *",
    store: "Store",
    selectSection: "Select Section",
    selectHomeCleanProduct: "Select Home clean product",
    selectRegion: "Select Region",
    selectArea: "Select Area",
    services: "Services *",
    selectServices: "Select Services",
    selectStore: "Select Store",
    product: "Product *",
    selectProduct: "Select Product",
    platform: "Platform *",
    selectPlatforms: "Select Platforms",
    orderType: "Order Type",
    used: "Used *",
    noOfOrders: "No. Of Orders",
    enterNoOfOrders: "Enter Number Of Orders",
    promoExpiry: "Promo Expiries",
    startDate: "Start Date *",
    endDate: "End Date",
    excludeDay: "Exclude Day",
    selectDay: "Select Day",
    selectOrderTyoe: "Select Order Type",
    type: "Amount/Percentage *",
    discountPlaceHolder: "Amount/ Percentage",
    promoCodeType: "Promo Code Type *",
    discountedProduct: "Discounted Product *",
    selectType: "Select Type",
    amountPercentage: "Amount/Percentage",
    percentage: "Percentage",
    minAmount: "Minimum Order Amount *",
    maxAmount: "Maximum Discount Amount *",
    enterAmount: "Enter Amount or Percentage",
    enterAmountOnly: "Enter Percentage",
    enterMinAmount: "Enter Minimum Order Amount",
    enterMaxAmount: "Enter Maximum Discount Amount",
    selectFirst: "Select Type First",
    availableTime: "Available Time *",
    from: "From",
    to: "To",
    cancel: "Cancel",
    save: "Save",
    add: "Add",
  },
};
exports.httpGetType = "GET";
exports.httpPostType = "POST";
exports.httpDeleteType = "DELETE";
exports.httpPutType = "PUT"
exports.getSectionListsAPIEndPoint = "bx_block_categories/categories";
exports.getServiceListDropDownEndpoint = "bx_block_categories/services?dropdown=true"
exports.productListDropdownApiEndPoint = "bx_block_catalogue/catalogues?dropdown=true"
exports.selectProductText="Select Product"
exports.selectAllProduct = "Select All"
exports.excludeAll = "Exclude All"
exports.store = "All Store"
exports.PromocodeCreateIssue = "Promocode Create Issue"
exports.SelectAllProductAndSection = "Select All Product And Section"
// Customizable Area End
