// Customizable Area Start
import React, { FunctionComponent } from "react";
import { TableCell, Box, styled, StyledComponentProps } from "@material-ui/core";
import { colors } from "../../blocks/utilities/src/Colors";

export type SortingType = "" | "asc" | "desc";
export interface ISortingData {
  [key: string]: SortingType;
}

interface SortingTableHeaderProps {
  title: string | React.ReactNode;
  sortingKey: string;
  sortingData: ISortingData;
  onChange: (sortingData: ISortingData) => any;
  onQueryChange: (query: string) => void;
  sortingDisabled?: boolean;
  type?: "left" | "middle" | "right" | "action";
  width: string;
  handleHeaderClick?: () => void;
  titleContainerClassName?: string;
  minHeightType?: boolean
}

const getIconStyle = (sortingData: ISortingData, sortingKey: string) => {
  if (sortingData[sortingKey] === "") {
    return "notrDirectionalIcon";
  } else {
    return "biDirectionalIcon";
  }
};

const getIcon = (sortingData: ISortingData, sortingKey: string) => {
  if (sortingData[sortingKey] === "") {
    return require("./assets/sort/sort.png");
  } else if (sortingData[sortingKey] === "asc") {
    return require("./assets/sort/sort top.png");
  } else if (sortingData[sortingKey] === "desc") {
    return require("./assets/sort/sort down.png");
  }
};

const getStyle = (position: string) => {
  if (position === "left") {
    return webStyle.sortingLeftHeaderCell;
  } else if (position === "right") {
    return webStyle.sortingRightHeaderCell;
  } else if (position === "middle") {
    return webStyle.sortingMiddleHeaderCell;
  } else if (position === "action") {
    return webStyle.actionHeader;
  }
};

const SortingTableHeader: FunctionComponent<SortingTableHeaderProps> = ({
  title,
  sortingKey,
  sortingData,
  onChange,
  onQueryChange,
  sortingDisabled,
  type = "middle",
  width,
  handleHeaderClick = () => { },
  titleContainerClassName,
  minHeightType
}) => {
  const icon = getIcon(sortingData, sortingKey);

  const onSortingClick = () => {
    const newSortingData = { ...sortingData };
    if (sortingData[sortingKey] === "") {
      newSortingData[sortingKey] = "asc";
    } else if (sortingData[sortingKey] === "asc") {
      newSortingData[sortingKey] = "desc";
    } else if (sortingData[sortingKey] === "desc") {
      newSortingData[sortingKey] = "";
    }

    Object.keys(newSortingData).forEach((key) => {
      if (key !== sortingKey) {
        newSortingData[key] = "";
      }
    });
    if (onChange) {
      onChange(newSortingData);
      let queryString = "";
      Object.keys(newSortingData).forEach((key) => {
        if (newSortingData[key]) {
          queryString += `&${key}=${newSortingData[key]}`;
        }
      });
      onQueryChange(queryString);
    }
  };
  return (
    <CustomTableCell
      style={{
        background: "#204B9C",
        color: "FFFFFF",
        width,
        fontWeight: 600,
        ...getStyle(type),
      }}
      minHeightType={minHeightType}
      align='left'
    >
      <StyledTableBoxWrapper>
        <div className={`mainHeaderTitleWrapper ${titleContainerClassName}`}>
          <div className="tableHeaderTitle">
            {title}
          </div>
          {!sortingDisabled && (
            <div>
              <img
                data-test-id="sortingIcon"
                aria-label={'sorting-table-header-' + sortingKey}
                className={getIconStyle(sortingData, sortingKey)}
                src={icon}
                onClick={() => { onSortingClick(); handleHeaderClick() }}
              />
            </div>
          )}
        </div>
      </StyledTableBoxWrapper>
    </CustomTableCell>
  );
};

const webStyle = {
  sortingMiddleHeaderCell: {
    paddingLeft: "0px",
  },
  sortingLeftHeaderCell: {
    borderTopLeftRadius: "12px"
  },
  sortingRightHeaderCell: {
    borderTopRightRadius: "12px",
  },
  actionHeader: {
    width: "10px",
    borderTopRightRadius: "12px",
  },
  biDirectionalIcon: {
    height: 24,
    width: 24,
    marginRight: 12,
    marginLeft: 6,
    cursor: "pointer",
  },
  notrDirectionalIcon: {
    // height: 28,
    width: 24,
    height: 24,
    marginTop: 2,
    marginBottom: 2,
    marginRight: 12,
    marginLeft: 6,
    cursor: "pointer",
  },
};
export default SortingTableHeader;

const StyledTableBoxWrapper = styled(Box)({
  "& .mainHeaderTitleWrapper": {
    display: "flex",
    alignItems: "center"
  },
  "& .tableHeaderTitle": {
    color: colors().white,
    fontFamily: "Montserrat",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "22px",
    "@media only screen and (max-width: 1280px)": {
      fontSize: "16px",
      fontWeight: 600,
    },
    "@media only screen and (max-width: 1024px)": {
      fontSize: "13px",
      fontWeight: 500,
    },
  },
  "& .biDirectionalIcon": {
    height: 32,
    width: 38,
    marginRight: 12,
    marginLeft: 6,
    cursor: "pointer",
    "@media only screen and (max-width: 1280px)": {
      height: 28,
      width: 33,
    },
    "@media only screen and (max-width: 1024px)": {
      height: 23,
      width: 27
    }
  },
  "& .notrDirectionalIcon": {
    height: 28,
    width: 32,
    marginTop: 2,
    marginBottom: 2,
    marginRight: 12,
    marginLeft: 6,
    cursor: "pointer",
    "@media only screen and (max-width: 1280px)": {
      height: 24,
      width: 28,
    },
    "@media only screen and (max-width: 1024px)": {
      height: 20,
      width: 23,
    },
  },
});

const CustomTableCell = styled(TableCell)((props: StyledComponentProps & { minHeightType?: boolean }) => ({
  boxSizing: "border-box",
  "@media only screen and (max-width: 1280px)": {
    padding: "12px 5px 12px 12px",
  },
  "@media only screen and (max-width: 1024px)": {
    padding: "5px 0 5px 10px",
    [props.minHeightType ? "minHeight" : "height"]: "44px"
  },
}));

// Customizable Area End
