/** @format */
// Customizable Area Start
import React, { Component, ReactNode } from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import Typography from "@material-ui/core/Typography";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Box from "@material-ui/core/Box";
import FormLabel from "@material-ui/core/FormLabel";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";
import Select from "@material-ui/core/Select";
import Radio from "@material-ui/core/Radio";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import "./ConfirmationModal.css";
export const configJSON = require("../config");
import { styled } from "@material-ui/core";
import { colors } from "../../../blocks/utilities/src/Colors";
// Customizable Area End

// Customizable Area Start
interface ModalProps {
  open: boolean;
  handleClose: () => void;
  handleConfirm: (disableAllUsers: boolean, roleId: string) => void;
  roleList: { id: string; name: string }[];
}
interface S {
  disableAllUsers: boolean;
  roleId: string;
  roleIdError: string;
}
// Customizable Area End

// Customizable Area Start

class RoleDisableModal extends Component<ModalProps, S> {
  constructor(props: any) {
    super(props);
    this.state = {
      disableAllUsers: true,
      roleId: "",
      roleIdError: ''
    };
  }
  render(): ReactNode {
    const { open, handleClose, handleConfirm } = this.props;
    const CustomRadio: React.FC<{ checked: boolean; onClick: () => void }> = ({
      checked,
      onClick,
    }) => (
      <Radio
        checked={checked}
        onClick={onClick}
        icon={
          <span
            style={{
              display: "inline-block",
              width: 20,
              height: 20,
              borderRadius: "50%",
              border: "1px solid #64748B",
              marginRight: "10px",
            }}
          />
        }
        checkedIcon={
          <span
            style={{
              display: "inline-block",
              width: 20,
              height: 20,
              borderRadius: "50%",
              border: "6px solid #204B9C",
              color: "#204b9c",
              marginRight: "10px",
            }}
          />
        }
      />
    );
    const StyledMenuItem = withStyles((theme) => ({
      root: {
        "&:focus": {
          backgroundColor: "#204B9C",
          "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
            color: theme.palette.common.white,
          },
        },
      },
    }))(MenuItem);
    const MenuProps: any = {
      PaperProps: {
        style: {
          maxHeight: 300,
          width: 200,
        },
      },
      anchorOrigin: {
        vertical: "bottom",
        horizontal: "left",
      },
      transformOrigin: {
        vertical: "top",
        horizontal: "left",
      },
      getContentAnchorEl: null,
    };

    return (
      <Dialog className='deactivate-modal' open={open} onClose={handleClose}>
        <Box className='dialog-main'>
          <Box className={`icon-section  "close"}`} style={{ color: "red" }}>
            <HighlightOffIcon />
          </Box>
          <DialogTitle>{"Confirm Deactivate"}</DialogTitle>
          <StyledDialogContent >
            Are you sure you want to deactivate the Role? This process cannot be
            undone
          </StyledDialogContent>
          <div style={{marginBottom: '32px'}}>
          <StyledTypographySelect>Select from the following options :</StyledTypographySelect>
            <div style={{ display: "flex", gap: 8, alignItems: "center" }}>
              <CustomRadio
                data-test-id='radio'
                checked={this.state.disableAllUsers}
                onClick={() =>
                  this.setState({
                    disableAllUsers: true,
                    roleIdError: ''
                  })
                }
              />
              <StyledRadioText>Deactivate all the users linked to this role</StyledRadioText>
            </div>
            <div style={{ display: "flex", gap: 8, alignItems: "center" }}>
              <CustomRadio
                data-test-id='radio'
                checked={!this.state.disableAllUsers}
                onClick={() =>
                  this.setState({
                    disableAllUsers: false,
                    roleIdError: ''
                  })
                }
              />
              <StyledRadioText>Select other roles to link users</StyledRadioText>
            </div>
            {!this.state.disableAllUsers && (
              <FormControl
                variant='outlined'
                style={{ width: "100%", alignItems: "start", margin: "10px" }}
                error={!!this.state.roleIdError}
              >
                <StyledFormLabel>
                  Select Role
                </StyledFormLabel>

                <StyledSelect
                  style={{ height: 56, width: 250, textAlign: 'left' }}
                  data-test-id='role_id'
                  id='role'
                  displayEmpty
                  renderValue={
                    this.state.roleId !== "" ? undefined : () => "Select Role"
                  }
                  value={this.state.roleId}
                  onChange={(event) =>
                    this.setState({
                      roleId: event.target.value as string,
                      roleIdError: ''
                    })
                  }
                  placeholder={configJSON.selectRoleText}
                  MenuProps={MenuProps}
                >
                  {this.props.roleList.map((role) => (
                    <StyledMenuItem key={role.id} value={role.id}>
                      <ListItemText primary={role.name} />
                    </StyledMenuItem>
                  ))}
                </StyledSelect>
                <div style={{color: '#F44336', margin: '5px'}}>{this.state.roleIdError}</div>
              </FormControl>
            )}
          </div>
          <MainBox>
          <SecondaryButtonBox>
            <Button
              className={`secondaryButtonStyle`}
              onClick={handleClose}
              color='primary'
            >
              {configJSON.cancelButton}
            </Button>
          </SecondaryButtonBox>
          <PrimaryButtonBox>
            <Button
              className={`primaryButtonStyle`}
              onClick={() => {
                if(!this.state.disableAllUsers && !this.state.roleId){
                  this.setState({roleIdError: 'Please select Role to link users'})
                  return 
                }
                handleConfirm(
                  this.state.disableAllUsers, 
                  this.state.disableAllUsers  ? '':  this.state.roleId
                )
              }}
              color='primary'
              autoFocus
            >
              Deactivate
            </Button>
          </PrimaryButtonBox>
          </MainBox>
        </Box>
      </Dialog>
    );
  }
}

const webStyle = {
  optionHeader: {
    color: "#1A1A1A",
    fontFamily: "Montserrat",
    fontWeight: 600,
    fontSize: "18px",
    textAlign: "left" as const,
    margin: "26px 0 10px 0",
  },
  dropDownTitle: {
    marginBottom: "8px",
    color: "#334155",
    fontWeight: 600,
    fontFamily: "Montserrat",
  },
};

export const StyledDialogContent = styled(DialogContent)({
  paddingTop: '0px',
  fontFamily: "Montserrat",
  fontWeight: 400,
  fontSize: "20px",
  lineHeight: "22px",
  color:  colors().darkliver,
  textAlign:"center",
  "@media only screen and (max-width: 1024px)": {
    fontFamily: "Montserrat",
    fontWeight: 400,
    fontSize: "20px",
    lineHeight: "22px",
    color:  colors().viewTextColor,
    textAlign:"center"
  }, 
});

const StyledTypographySelect = styled('div')({
  color: colors().viewTextColor,
  fontFamily: "Montserrat",
  fontWeight: 600,
  fontSize: "18px",
  textAlign: "left" as const,
  margin: "26px 0 10px 0",
  "@media only screen and (max-width: 1024px)": {
    color: colors().viewTextColor,
    fontFamily: "Montserrat",
    fontWeight: 600,
    fontSize: "16px",
    textAlign: "left" as const,
    margin: "26px 0 10px 0",
  }, 
});

const StyledRadioText = styled('div')({
  color: colors().darkliver,
  fontFamily: "Montserrat",
  fontWeight: 500,
  fontSize: "16px",
  textAlign: "left" as const,
});

export const SecondaryButtonBox = styled(Box)({
  '& .secondaryButtonStyle': {
    border: "none",
    fontSize: '16px',
    borderRadius: '8px',
    fontWeight: 700,
    cursor: 'pointer',
    textTransform: "none" as 'none',
    backgroundColor: colors().antiFlashWhite,
    color: colors().slateGray,
    width: 128,
    height: 56,
    marginRight:"20px",
    "@media only screen and (max-width: 1024px)": {
      width: 186,
      height: 44,
    }
  },
});


export const PrimaryButtonBox = styled(Box)({
  '& .primaryButtonStyle': {
    border: "none",
    fontSize: '16px',
    borderRadius: '8px',
    fontWeight: 700,
    cursor: 'pointer',
    textTransform: "none" as 'none',
    backgroundColor: colors().cyancobaltblue,
    color: colors().background,
    width: 128,
    height: 56,
    "@media only screen and (max-width: 1024px)": {
      width: 186,
      height: 44,
    }
  },
  '& .primaryButtonForConfirm': {
    border: "none",
    fontSize: '16px',
    borderRadius: '8px',
    fontWeight: 700,
    cursor: 'pointer',
    textTransform: "none" as 'none',
    backgroundColor: colors().cyancobaltblue,
    color: colors().background,
    width: 292,
    height: 56,
    "@media only screen and (max-width: 1024px)": {
      width: 200,
      height: 44,
    }
  }
});

export const MainBox = styled(Box)({
  display:"flex",
  justifyContent:"center",
  alignItems:"center"
});

const StyledFormLabel = styled(FormLabel)({
  marginBottom: "8px",
  color:colors().charcoal,
  fontWeight: 600,
  fontSize:"16px",
  lineHeight:"20px",
  fontFamily: "Montserrat",
  "@media only screen and (max-width: 1024px)": {
    fontSize:"13px"
  }
});

const StyledSelect = styled(Select)({
  borderRadius: 8,
  border: "1px solid var(--border, #ECECEC)",
  "@media only screen and (max-width: 1024px)": {
      fontSize: '12px',
      height:'44px !important',
      "& .MuiTypography-body1":{
        fontSize: '12px',
      }
  },
});
export default RoleDisableModal;
// Customizable Area End
