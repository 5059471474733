import React from 'react';
import {
  Select,
  MenuItem,
  FormControl,
  FormControlLabel,
  Radio,
  RadioProps
} from "@material-ui/core";
import { withStyles, Theme, makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { Styles } from "@material-ui/styles";
const DefaultIcon = require("../PageContainer/assets/default_icon.png")

export interface Props {
  fieldLabel: any;
  classes?: any;
  fieldName: any;
  isError?: any;
  options: any;
  errorMessage?: any;
  setFieldValue?: any;
  withIcon: any;
  onClick?: any;
  style?: any;
  placeholder?: any;
}

export class DropdownRadio extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { classes, fieldName, isError, fieldLabel, options, errorMessage, setFieldValue, withIcon, style, placeholder, ...rest }: any = this.props;
    const ITEM_HEIGHT = 56;
    const ITEM_PADDING_TOP = 8;
    const MenuProps: any = {
      PaperProps: {
        style: {
          maxHeight: ITEM_HEIGHT * 3 + ITEM_PADDING_TOP,
          width: 200,
          boxShadow: "1px 4px 8px rgba(0, 0, 0, 0.03), 1px 8px 32px rgba(0, 0, 0, 0.06)",
          borderRadius: "8px"
        },
      },
      anchorOrigin: {
        vertical: "bottom",
        horizontal: "left"
      },
      transformOrigin: {
        vertical: "top",
        horizontal: "left"
      },
      getContentAnchorEl: null
    };
    return (
      <>
        <FormControl variant="outlined" className={classes.formControl}>
          <MultiSelectionBox
            labelId={fieldName}
            id={fieldName}
            name={fieldName}
            fullWidth
            displayEmpty
            defaultValue={""}
            className={isError ? `${classes.multiSelectionBoxError} fieldBorderError` : `${classes.multiSelectionBox} fieldBorder`}
            onChange={(value: any) => setFieldValue(fieldName, value)}
            {...rest}
            MenuProps={MenuProps}
          >
            <MenuItem style={{ color: '#4d4d4d', display: 'none' }} value={""}>{placeholder}</MenuItem>
            {options?.length !== 0 && options !== undefined ?
              options.map((option: any) => {
                return <CustomMenuItem key={option.id} value={option.id}>
                  <FormControlLabel
                    checked={rest?.value === option.id}
                    className={classes.fieldValue}
                    control={<StyledRadio />}
                    label={""}
                    name="option"
                  />
                  {withIcon ?
                    option?.image !== undefined && option?.image !== null ? <img src={option.image} style={{ width: '18px', height: '18px', margin: '0 12px 0 3px' }} /> : <img src={DefaultIcon} style={{ width: '18px', height: '18px', margin: '0 12px 0 3px' }} />
                    : <></>}
                  {option?.name || option}
                </CustomMenuItem>
              }) :
              <CustomMenuItem disabled>
                No data
              </CustomMenuItem>
            }
          </MultiSelectionBox>
        </FormControl>
        {isError && <div className={classes.fieldError}>
          {errorMessage}
        </div>}
      </>
    )
  }
}

export function StyledRadio(props: RadioProps) {
  const classes = useStyles();

  return (
    <Radio
      className={classes.root}
      disableRipple
      color="primary"
      checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
      icon={<span className={classes.icon} />}
      {...props}
    />
  );
}

const useStyles = makeStyles({
  root: {
    "&:hover": {
      backgroundColor: "transparent"
    }
  },
  icon: {
    borderRadius: "50%",
    width: 16,
    height: 16,
    boxShadow:
      "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
    backgroundColor: "#f5f8fa",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
    "$root.Mui-focusVisible &": {
      outline: "2px auto #204B9C",
      outlineOffset: 2
    },
    "input:hover ~ &": {
      backgroundColor: "#ebf1f5"
    },
    "input:disabled ~ &": {
      boxShadow: "none",
      background: "rgba(206,217,224,.5)"
    }
  },
  checkedIcon: {
    backgroundColor: "#204B9C",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
    "&:before": {
      display: "block",
      width: 16,
      height: 16,
      backgroundImage: "radial-gradient(#fff,#fff 28%,transparent 32%)",
      content: '""'
    },
    "input:hover ~ &": {
      backgroundColor: "#204B9C"
    }
  }
});


const styles: Styles<Theme, {}, string> = () => ({
  formControl: {
    width: '100%',
    border: '1px solid #ECECEC',
    borderRadius: '8px',
    marginTop: '10px',
    fontFamily: 'Montserrat'

  },
  chips: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    height: '56px',
    overflow: 'hidden'
  },
  chip: {
    margin: "2px 5px",
    backgroundColor: 'rgba(32, 75, 156, 0.1)',
    color: '#204B9C',
    height: '26px'
  },
  multiSelectionBox: {
    width: '100%',
    borderRadius: "8px",
    margin: '0 !important',
  },
  multiSelectionBoxError: {
    width: '100%',
    borderRadius: "8px",
    margin: '0 !important',
  },
  fieldError: {
    color: "red",
    fontSize: "14px",
    margin: "5px 5px 5px 0"
  },
  '@global': {
    '*::-webkit-scrollbar': {
      width: '6px'
    },
    '*::-webkit-scrollbar-track': {
      '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)'
    },
    '*::-webkit-scrollbar-thumb': {
      backgroundColor: '#d1d1d1',
      borderRadius: '10px'
    }
  }
});

const MultiSelectionBox: any = withStyles({
  root: {
    width: '100%',
    borderRadius: "8px",
    padding: 0,
    margin: 0,
    height: '56px',
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '16px',
    color: '#4d4d4d',
    fontSize: '14px',
    "&:focus": {
      outline: "none",
      borderRadius: '8px'
    },
    "& .MuiOutlinedInput-root": {
      fontWeight: 400,
      fontSize: "16px",
      borderRadius: "8px",
      "& fieldset": {
        fontSize: "16px",
        borderRadius: "8px",
        borderWidth: 1,
        fontWeight: 400,
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: '#ececec'
        },
      },
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: '#ececec'
      },
    },
    "& .MuiInputBase-root": {
      height: 56,
    },
    "& .MuiSelect-root": {
      paddingLeft: '16px',
      color: '#4d4d4d'
    },
    "& ::placeholder": {
      opacity: 1,
      color: "#4D4D4D",
      fontSize: '14px'
    },
    "& .MuiSvgIcon-root": {
      marginRight: '10px',
      fill: 'black'
    },
    '& .MuiFormControlLabel-root': {
      height: '56px',
      margin: '0 10px',
      display: 'none',
      "& .MuiButtonBase-root": {
        display: 'none',
      }
    },
    "@media only screen and (max-width: 1024px)": {
      fontSize: "12px",
      height:'44px'
    },
  }
})(Select);

const CustomMenuItem: any = withStyles({
  root: {
    fontWeight: 500,
    fontSize: "16px",
    "&:hover": {
      backgroundColor: 'rgba(0, 0, 0, 0.08)'
    },
    "& .MuiSvgIcon-root": {
      marginRight: '10px',
      fill: 'black'
    },
    "& .MuiFormControlLabel-root": {
      marginRight: '6px'
    },
    "&.Mui-selected": {
      backgroundColor: 'transparent',
      color: '#204B9C',
      "&:hover": {
        backgroundColor: 'rgba(0, 0, 0, 0.08)'
      }
    },
    "& .MuiButtonBase-root": {
      "&:hover": {
        backgroundColor: 'rgba(0, 0, 0, 0.08)'
      },
      "& .MuiSvgIcon-root": {
        marginRight: '10px',
        fill: 'black'
      }
    },
    "& .MuiListItem-button": {
      "&:hover": {
        backgroundColor: 'rgba(0, 0, 0, 0.08)'
      }
    },
   
  }
})(MenuItem);

export default withStyles(styles)(DropdownRadio);