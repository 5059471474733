import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
// Customizable Area Start
import { ISortingData } from "../../../components/src/SortingTableHeader";
import { getFiterList, makeApiMessage } from "../../../components/src/common";
import { IFilter } from "../../../components/src/FilterPopover";
import { IUserContext } from "../../../blocks/navigationmenu/src/PageContainerController.web";
import {
  PermissionStatus,
  checkForNewPermissonStatus,
  customPermissionApiKey
} from "../../../blocks/utilities/src/CustomBlockHelpers";
import { PermissionGroupArray } from "../../../blocks/navigationmenu/src/utils";

export interface IProfession {
  id: string;
  type: string;
  attributes: {
    id: number;
    profession_unique_id: string;
    profession_name: string;
    active: boolean;
  }
}

const emptyProfession : IProfession = {
  id: "",
  type: "",
  attributes: {
    id: 0,
    profession_unique_id: "",
    profession_name: "",
    active: false
  }
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
}

export interface S {
  // Customizable Area Start
  drawerWidth: number;
  popOverOpened: boolean;
  popOverItemId: number;
  popOverItemStatus: boolean;
  popOverTop: number;
  popOverLeft: number;
  hoveredButton: string;
  professions: IProfession[];
  disableModalVisible: boolean;
  deactivatedProfession: boolean;
  filterAnchor: any;
  filters: any;
  clickedProfession: IProfession | undefined;
  sortingData: ISortingData;
  query: string;
  searchTextProfession: string;
  page: number;
  pageSize: number;
  snackbarMessage: string;
  severity: string;
  snackbarOpen: boolean;
  suggestionFieldTitle: string;
  permissionStatus: PermissionStatus;
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class ProfessionListController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getprofessionsCallId: string = "";
  filterSuggestionApiCallId: string = "";
  updateProfessionCallId: any ;
  tableRefs: HTMLElement | null = null;

  sortingProps = {
    onQueryChange: (query: string) => this.handleQueryChange(query),
    width: "18%",
    onChange: (sortingData: ISortingData) => this.setState({ sortingData }),
  };

  handleQueryChange = (query: string) => {
    this.setState({ query }, () => this.getProfessions());
  };


  // Customizable Area End

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];
    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    this.state = {
      drawerWidth: 0,
      popOverTop: 0,
      popOverOpened: false,
      popOverLeft: 0,
      hoveredButton: "",
      professions: [],
      popOverItemId: 0,
      popOverItemStatus: false,
      disableModalVisible: false,
      deactivatedProfession: false,
      filterAnchor: false,
      clickedProfession: undefined,
      page: 0,
      pageSize: 10,
      snackbarMessage: "",
      severity: "",
      snackbarOpen: false,
      suggestionFieldTitle: "",
      filters: [
        {
          title: "Profession Name",
          type: "autocompolete",
          value: "",
          apiKey: "profession_name",
          options: [],
        },
        {
          title: "Profession ID",
          type: "autocompolete",
          value: "",
          apiKey: "profession_id",
          options: [],
        },      
      ],
      sortingData: {
        profession_unique_id: "",
        profession_name: "",
      },
      query: "",
      searchTextProfession: "",
      permissionStatus: {
        mainPermission: false,
        createPermission: false,
        viewPermission: false,
        editPermission: false,
        deactivatePermission: false
      }
    };

    // Customizable Area End
  }

  
    // Customizable Area Start
    async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (apiRequestCallId === this.getprofessionsCallId) {
        if (responseJson && responseJson.data) {
          if (responseJson.meta) {
            this.processMeta(message);
          } else {
            let professions = responseJson.data as IProfession[];
            this.setState({ professions });
          } 
        }
      } else if (apiRequestCallId === this.updateProfessionCallId) {
        this.getProfessions();
        if (responseJson && this.state.popOverItemStatus) {
          this.setState({ deactivatedProfession: true });
        }
      } else if (apiRequestCallId === this.filterSuggestionApiCallId) {
        if (responseJson) {
          const updatedFilters = getFiterList(responseJson, this.state.filters, this.state.suggestionFieldTitle);
          this.setState({ filters: updatedFilters });
        }
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start

  createData(
    profession_unique_id: string,
    profession_name: string,
    profession_id: number,
    active: boolean
   
  ) {
    return { profession_unique_id, profession_name, profession_id, active };
  }

  handleDrawerWidthChange = (width: number) => {
    this.setState({ drawerWidth: width });
  };

  getProfessions = () => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: window.localStorage.getItem(configJSON.token),
    };
    const idFilter = this.state.filters.find((item:any) => item.title === "Profession ID")?.value;
    const nameFilter = this.state.filters.find((item:any) => item.title === "Profession Name")?.value;

    const searchTextProfession = this.state.searchTextProfession;
    const professionListApiEndPoint = configJSON.professionsApiEndPoint +
    "?page_no=" +
    (this.state.page + 1) +
     (idFilter ? `&filter_by[profession_unique_id]=${idFilter}` : "") +
    (nameFilter ? `&filter_by[profession_name]=${nameFilter}` : "") + (searchTextProfession ? `&filter_by[query]=${searchTextProfession}` : "") + (idFilter ? "" : this.state.query)
    
    const getDataMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getprofessionsCallId = getDataMsg.messageId;

    getDataMsg.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), professionListApiEndPoint);
    getDataMsg.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    getDataMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
    runEngine.sendMessage(getDataMsg.id, getDataMsg);
  };

  updateProfession = (userId: number, isActive: boolean) => {
    const header = {
      "Content-Type": configJSON.requestType,
      token: window.localStorage.getItem(configJSON.token),
    };

    {isActive && this.setState({
      snackbarMessage: configJSON.activeProfession, 
      severity: configJSON.severitySuccess, 
      snackbarOpen: true 
    })}

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.updateProfessionCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.professionsApiEndPoint}/${userId}/${isActive ? "activate" : "deactivate"}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "PUT");

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  // Customizable Area End

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    this.getProfessions();
    // Customizable Area End
  }

  // Customizable Area Start

  processMeta = (message: Message) => {
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

    let newProfession = Array<IProfession>(responseJson.meta.total_count);
    for (let i = 0; i < responseJson.meta.total_count; i++) {
      if (
        i >= (responseJson.meta.current_page - 1) * 10 &&
        i < responseJson.meta.current_page * 10
      ) {
        newProfession[i] = responseJson.data[i - (responseJson.meta.current_page - 1) * 10];
      } else {
        if (!this.state.professions[i] || !this.state.professions[i].id) newProfession[i] = { ...emptyProfession };
      }
    }
    this.setState({ professions: newProfession });
  };

  handlePageChange = (
    page: number
  ) => {
    this.setState({ page }, () => this.getProfessions());
  };

  handleSearchProfession = (value: string) => {	
    
    this.setState(	
      {	
        searchTextProfession: value,	
        page: 0
      },	
      this.getProfessions
    );
  };

  handleAddProfession = () => {
    this.props.navigation.navigate("CreateProfession");
  };

  handleEditProfession = (professionId: string) => {
    this.setState({ popOverOpened: false });
    this.props.navigation.navigate("ProfessionEdit");
    window.localStorage.setItem("profession_id", professionId);
  };

  handleViewProfession = (professionId: string) => {
    this.setState({ popOverOpened: false });
    this.props.navigation.navigate("ProfessionView");
    window.localStorage.setItem("profession_id", professionId);
  };

  handleDeactivate = (clickedProfessionId: string) => {
    this.setState({
      disableModalVisible: true,
      clickedProfession: this.state.professions.find((item:any) => item.id === clickedProfessionId),
    });
  };

  handleFilterChangeProfession = (filters: any) => {
    this.setState(
      {
        filters,
         sortingData:   
            {
            profession_unique_id:'',
            profession_name:''
          }   
      },
      this.getProfessions
      );
  };

  changeActivateProfession = () => {
    this.setState({ popOverOpened: false, disableModalVisible: false });
    this.updateProfession(this.state.popOverItemId, this.state.popOverItemStatus !== true);
  };

  handleSnackbarClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({ snackbarOpen: false });
  };

  handleDeactivateClose = () => {
    this.setState({ disableModalVisible: false, popOverOpened: false });
  };

  handleDeactivatedProfessionClose = () => {
    this.setState({ deactivatedProfession: false });
  };

  handleGoList = () => {
    this.setState({ deactivatedProfession: false });
  };

  handleSetProfessionFilterDataOnSortClick = (updatedFilters: any) => {
    this.setState({ filters: updatedFilters });
  }

  handleProfessionAutoComplete = (title: string, value: string) => {
    this.state.filters.forEach((item: IFilter) => {
      if (item.title === title) item.value = value;
      else item.value = "";
      return item;
    });

    let ApiUrl;
    if(title === "Profession Name") {
      ApiUrl = configJSON.professionsNameSuggestionApiEndPoint;
    } else if (title === "Profession ID") {
      ApiUrl = configJSON.professionsIdSuggestionApiEndPoint;
    }

    let requestMessage = makeApiMessage({
      url: ApiUrl + value,
      method: "GET",
    });
    this.filterSuggestionApiCallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleUserChange = (userContext: IUserContext) => {
    const apiKey = customPermissionApiKey.professionPermission;
    const userData = userContext.user?.attributes.permission_groups;
    const value = checkForNewPermissonStatus(apiKey, userData as Array<PermissionGroupArray>);
    this.setState({
      permissionStatus: value
    })
  };
  // Customizable Area End
}
