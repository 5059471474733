import React from "react";
// Customizable Area Start
import { Formik, FormikProps, FormikValues } from "formik";
import PageContainer from "../../navigationmenu/src/PageContainer.web"
import { MenuProps } from "../../email-account-registration/src/AccountCreationController.web";

import { styled, Snackbar,Box } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

import { colors } from "../../utilities/src/Colors";

import {
  Heading,
  Container,
  TextLabel,
  ErrorLabel,
  StyledSelect,
  GridContainer,
  StyledMenuItem,
  WhiteContainer,
  BottomContainer,
  StyledTextField,
} from "../../promocodes/src/PromoCodeCreate.web";

import { navigateTo } from "../../utilities/src/CustomBlockHelpers";

import { validationSchema } from "./utils";
import { StyledImageCheckbox } from "../../ordermanagement/src/Orders.web";
import { IUserContext } from "../../../components/src/PageContainer/PageContainerController";
import AutocompleteSelect from "../../../components/src/AutocompleteSelect.web";
// Customizable Area End

import HomeCleaningSettingsController, {
  Props,
  configJSON,
  // Customizable Area Start
  IRegion,
  // Customizable Area End
} from "./HomeCleaningSettingsController.web";

// Customizable Area Start
const CheckTrue = require("../../../components/src/check_true.svg");
const CheckFalse = require("../../../components/src/check_false.svg");
const Strings = configJSON.Strings.Settings;
// Customizable Area End

export default class HomeCleaningSettings extends HomeCleaningSettingsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  // Customizable Area End

  render() {
    // Customizable Area Start
    const {
      regionList,
      alertMessage,
      initialValueOfForm,
      initialValueOfFormErrors,
    } = this.state;

    const amountTypesList = [
      { label: "Flat Rate", value: "flat_rate" },
      { label: "Percentage", value: "percentage" },
    ];

    const errors422 = initialValueOfFormErrors?.data?.attributes;

    return (
      <PageContainer
        data-test-id='pageContainer'
        navigation={this.props.navigation}
        onUserChange={(context: IUserContext) => this.handleUserChange(context)}
      >
        <MainContainer>
          <Snackbar
            autoHideDuration={5000}
            open={String(alertMessage).length > 0}
            onClose={() => this.onCloseNotification()}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <Alert
              severity={"success"}
              data-test-id='alert-success'
              onClose={() => this.onCloseNotification()}
            >
              {alertMessage}
            </Alert>
          </Snackbar>
          <StyleContainer>
            <Formik
              enableReinitialize
              validationSchema={validationSchema}
              initialValues={initialValueOfForm?.data?.attributes}
              onSubmit={(body: FormikValues) => this.handleSubmitForm(body)}
            >
              {({
                values,
                errors,
                touched,
                handleSubmit,
                getFieldProps,
                setFieldValue,
              }: FormikProps<FormikValues>) => (
                <form onSubmit={handleSubmit}>
                  <Heading>{Strings.home_cleaning_settings}</Heading>
                  <WhiteContainer className='p-32'>
                    <GridContainer className='row-gap-24'>
                      <div>
                        <TextLabel>{Strings.region}</TextLabel>
                        <AutocompleteSelect
                          disableClear={true}
                          fieldName='region_id'
                          value={values?.region_id}
                          placeholder={Strings.select_region}
                          options={regionList.map((item: IRegion) => ({
                            option: item.name,
                            id: String(item.id),
                          }))}
                          onChange={(
                            _: React.ChangeEvent,
                            item: { id: string }
                          ) => setFieldValue("region_id", item.id)}
                          handleScroll={this.handelStoreDropdownScroll.bind(
                            this
                          )}
                        />
                        <ErrorLabel>
                          {errors422.region_id}
                          {touched.region_id && errors.region_id}
                        </ErrorLabel>
                      </div>
                      <div className='hide-div-in-mobile' />
                      <div>
                        <TextLabel>{Strings.minimum_order_amount}</TextLabel>
                        <StyledTextField
                          fullWidth
                          variant='outlined'
                          placeholder={Strings.enter_minimum_price_for_order}
                          inputProps={{
                            "data-test-id": "field-min-order-amount",
                          }}
                          {...getFieldProps("minimum_order_amount")}
                        />
                        <ErrorLabel>
                          {errors422.minimum_order_amount}
                          {touched.minimum_order_amount &&
                            errors.minimum_order_amount}
                        </ErrorLabel>
                      </div>
                      <div>
                        <TextLabel>{Strings.maximum_order_amount}</TextLabel>
                        <StyledTextField
                          fullWidth
                          variant='outlined'
                          placeholder={Strings.enter_maximum_price_for_order}
                          inputProps={{
                            "data-test-id": "field-max-order-amount",
                          }}
                          {...getFieldProps("maximum_order_amount")}
                        />
                        <ErrorLabel>
                          {errors422.maximum_order_amount}
                          {touched.maximum_order_amount &&
                            errors.maximum_order_amount}
                        </ErrorLabel>
                      </div>
                      <div>
                        <TextLabel>
                          {Strings.home_cleaning_service_charges}
                        </TextLabel>
                        <StyledTextField
                          fullWidth
                          variant='outlined'
                          placeholder='Enter Service Charge Amount'
                          inputProps={{
                            "data-test-id": "field-service-charge",
                          }}
                          {...getFieldProps("home_cleaning_service_charges")}
                        />
                        <ErrorLabel>
                          {errors422.home_cleaning_service_charges}
                          {touched.home_cleaning_service_charges &&
                            errors.home_cleaning_service_charges}
                        </ErrorLabel>
                      </div>

                      <NonRefundableBox>
                        <TextLabel>
                          {Strings.minimum_non_refundable_amount_to_pay}
                        </TextLabel>
                        <GridContainer className='row-gap-24'>
                          <div>
                            <TextLabel>
                              {Strings.flat_rate_percentage}
                            </TextLabel>
                            <StyledSelect
                              fullWidth
                              displayEmpty
                              variant='outlined'
                              MenuProps={MenuProps}
                              renderValue={(item) =>
                                amountTypesList.find(
                                  ({ value }) => item === value
                                )?.label || (
                                  <PlaceholderText>
                                    {Strings.selectType}
                                  </PlaceholderText>
                                )
                              }
                              data-test-id='field-non_refundable_type'
                              {...getFieldProps("non_refundable_type")}
                            >
                              {amountTypesList.map(({ label, value }) => {
                                return (
                                  <StyledMenuItem
                                    value={value}
                                    key={"amount-type-" + value}
                                  >
                                    {label}
                                  </StyledMenuItem>
                                );
                              })}
                            </StyledSelect>
                            <ErrorLabel>
                              {errors422.non_refundable_type}
                              {touched.non_refundable_type &&
                                errors.non_refundable_type}
                            </ErrorLabel>
                          </div>
                          <div>
                            <TextLabel>{Strings.rate}</TextLabel>
                            <StyledTextField
                              fullWidth
                              variant='outlined'
                              placeholder='Enter Rate'
                              inputProps={{
                                "data-test-id": "field-add_by",
                              }}
                              {...getFieldProps("add_by")}
                            />
                            <ErrorLabel>
                              {errors422.add_by}
                              {touched.add_by && errors.add_by}
                            </ErrorLabel>
                          </div>
                        </GridContainer>
                      </NonRefundableBox>
                      <div>
                        <MinServiceChargeLabel>
                          {Strings.minimum_amount_to_waive_of_service_charge}
                        </MinServiceChargeLabel>
                        <StyledTextField
                          fullWidth
                          variant='outlined'
                          placeholder='Enter Amount'
                          inputProps={{
                            "data-test-id": "field-min-waive-service-charge",
                          }}
                          {...getFieldProps(
                            "min_amount_to_waive_of_service_charge"
                          )}
                        />
                        <ErrorLabel>
                          {errors422.min_amount_to_waive_of_service_charge}
                          {touched.min_amount_to_waive_of_service_charge &&
                            errors.min_amount_to_waive_of_service_charge}
                        </ErrorLabel>
                      </div>
                      <div>
                        <TextLabel>
                          {Strings.number_of_open_slots_for_day}
                        </TextLabel>
                        <StyledTextField
                          fullWidth
                          variant='outlined'
                          placeholder='Enter Number'
                          inputProps={{
                            "data-test-id": "field-open-slots-per-day",
                          }}
                          {...getFieldProps("open_slots_per_day")}
                        />
                        <ErrorLabel>
                          {errors422.open_slots_per_day}
                          {touched.open_slots_per_day &&
                            errors.open_slots_per_day}
                        </ErrorLabel>
                        <br />
                        <div className='checkbox-container'>
                          <StyledImageCheckbox
                            src={values.close_slot ? CheckTrue : CheckFalse}
                            data-test-id='field-close-slots'
                            onClick={() =>
                              setFieldValue("close_slot", !values.close_slot)
                            }
                          />
                          <CloseLable>{Strings.close_slots}</CloseLable>
                        </div>
                      </div>
                    </GridContainer>
                  </WhiteContainer>
                  <BottomContainer>
                    <button
                      data-test-id='btn-cancel'
                      className='cancel'
                      onClick={() =>
                        navigateTo({
                          props: this.props,
                          screenName: "HomeCleaningSettingsList",
                        })
                      }
                    >
                      {Strings.cancel}
                    </button>
                    <button
                      data-test-id='btn-submit'
                      className='add'
                      type='submit'
                    >
                      {Strings.save}
                    </button>
                  </BottomContainer>
                </form>
              )}
            </Formik>
          </StyleContainer>
        </MainContainer>
      </PageContainer>
    );
    // Customizable Area End
  }
}

// Customizable Area Start

const NonRefundableBox = styled("div")({
  gridColumn: "1 / span 2",
  "& > div": {
    padding: 24,
    borderRadius: 8,
    border: `2.3px solid ${colors().lightborder}`,
  },
});

const PlaceholderText = styled("div")({
  color: colors().darkliver,
  fontSize: 14,
});

export const StyleContainer = styled("div")({
  paddingBottom:"25px",
  "& .hide-div-in-mobile": {
    "@media only screen and (max-width: 1024px)": {
      display: "none",
    },
  },
  "& .change-disable-bg > .MuiSelect-root": {
    backgroundColor: colors().brightgray,
  },
  "& .select-placeholder": {
    color: colors().darkliver,
    fontSize: 14,
  },
  "& .row-gap-24": {
    gridRowGap: 24,
  },
  "& .p-32": {
    padding: 32,
  },
  "& .checkbox-container": {
    alignItems:"center",
    display: "flex",
    gap: 12,
    "& > img": {
      paddingLeft: 0,
    },
    "& > label": {
      color: colors().darkjunglegreen,
      fontSize: 15,
    },
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: colors().lightborder,
  },
  "& .MuiOutlinedInput-input, & .MuiSelect-outlined": {
    fontWeight: "normal",
  },
  "& .MuiOutlinedInput-input::placeholder": {
    color: colors().darkliver,
    opacity: 1,
    fontSize: 14,
  },
  "& .MuiSelect-outlined.MuiInputBase-input": {
    fontSiz: "14px !important",
  },
  "& .MuiAutocomplete-root .MuiAutocomplete-input": {
    color: "#000",
    fontWeight: 500,
  },
});

const MainContainer = styled(Box)({
  paddingTop:'25px',
  paddingBottom:'25px'
});
const MinServiceChargeLabel = styled("label")({
  fontSize: 15,
  lineHeight: "24px",
  display: "block",
  fontWeight: 600,
  color: "var(--neutrals-cool-gray-700, #334155)",
  paddingBottom: 4,
  "@media only screen and (max-width: 1024px)": {
    fontSize: "12px",
  },
});

const CloseLable = styled("p")({
  fontSize: 15,
  lineHeight: "24px",
  fontWeight: 400,
  color: colors().darkjunglegreen,
  "@media only screen and (max-width: 1024px)": {
    fontSize: "14px",
  },
});
// Customizable Area End
