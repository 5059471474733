import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import { IBlock } from "../../../framework/src/IBlock";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";

import { IUserContext } from "../../../components/src/PageContainer/PageContainerController";
import { apiCall, makeApiMessage } from "../../../components/src/common";
import { getStorageData } from "../../../framework/src/Utilities";
import { IFilter } from "../../../components/src/FilterPopover";
import { ISortingData } from "../../../components/src/OrderTable/src/TableCell";
import moment from "moment";

export interface ICleaningOrderItem {
  id: string;
  type: string;
  attributes: {
    id: number;
    home_cleaning_order_id: number;
    quantity: number;
    unit_price: string;
    total_price: string;
    home_cleaning_catalogue_id: number;
    home_cleaning_catalogue_type_id: number;
    notes?: string;
    height: null;
    width: null;
    catalogue: {
      id: number;
      name: string;
      product_name: string;
      product_second_name: string;
      measurement_type: string[];
    };
    product_type: { id: number; name: string; price: string };
  };
}

interface ICleaningOrderTransactionItem {
  id: string;
  type: string;
  account_id: number;
  home_cleaning_order_id: number;
  amount: string;
  charge_status: null;
  name: string;
  second_name: string;
  active: boolean;
  created_at: string;
  updated_at: string;
  name_translation: string;
  attributes: {
    icon: {
      data: {
        id: number;
        type: string;
        attributes: {
          id: number;
          name: string;
          second_name: string;
          image_type: string;
          name_translation: string;
          image: string;
        };
      };
    };
  };
  currency: string;
}

interface DriverDropdownOption {
  id: string;
  type: string;
  attributes: {
    id: number;
    first_name: string;
  };
}

export interface ICleaningOrder {
  id: string;
  type: string;
  attributes: {
    id: number;
    order_number: string;
    account_id: number;
    sub_total: string;
    total: string;
    status: string;
    applied_discount: string;
    cancellation_reason: null;
    shipping_charge: null;
    total_tax: string;
    created_at: string;
    updated_at: string;
    notes: null;
    store_management_id: null;
    address_id: null;
    is_mobile: true;
    paid_at: null;
    paid: [];
    total_pieces: number;
    visit_date: string;
    visit_time_from: string;
    visit_time_to: string;
    service_charge: string;
    internal_status: string;
    min_non_refundable_amount:string;
    order_type:string;
    order_type_display:string;
    order_items: ICleaningOrderItem[];
    customer: {
      id: number;
      full_phone_number: string;
      email: string;
      full_name: string;
    };
    order_transactions: ICleaningOrderTransactionItem[];
    order_created_by: {
      id: number;
      first_name: null;
      last_name: null;
      full_name: string;
    };
    currency: string;
    tax_percentage: string;
    store_name: null;
    no_of_items: number;
    no_of_pieces: number;
    store_management: { data: null };
  };
}

export interface IStoreItem {
  id: string;
  type: string;
  attributes: {
    id: number;
    store_name: string;
  };
}

export interface IOption {
  value: string;
  label: string;
}

export interface IActionOption {
  value: string;
  label: string;
  tabId: number;
}

interface S {
  page: number;
  storeId: number;
  pageSize: number;
  searchText: string;
  totalCount: number;
  storeList: Array<IStoreItem>;
  orderList: Array<ICleaningOrder>;
  orderMetaData: {
    [field: string]: string;
  };
  selectedDropdownValue: string;
  dropDownList: Array<IActionOption>;
  filterAnchor: React.ReactNode;
  storeListPageNo: number;
  storeListEmptyFlag: boolean;
  userData: { attributes?: IUserContext | {} };
  selectedOrders: Array<number | never>;
  snackbarOpen: boolean;
  snakbarMessage: string;
  severity: "success" | "error" | "warning";
  filters: IFilter[];
  sortingData: ISortingData;
  sortingQuery: string;
  tabId: number;
  acceptedOrderStatus: string;
  customerSuggestionFieldTitle: string;
  orderData: ICleaningOrder | never | null;
}
interface SS {}

export interface Props {
  navigation: {};
  id: string;
}

export const configJSON = require("./config");

const NewStrings = configJSON.CleaningOrders;

export default class HomeOrderController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getStoreMessageId: string = "";
  getOrderMessageId: string = "";
  updateOrderStatusMessageId: string = "";
  getStoreCallId: string = "";
  driverListMessageId: string = "";
  updateAcceptedOrderStatusMessageId: string = "";
  filterCustomerSuggestionApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area Start
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      page: 1,
      storeId: -1,
      pageSize: 10,
      storeList: [],
      orderList: [],
      searchText: "",
      totalCount: 0,
      tabId: 0,
      dropDownList: [
        {
          label: "Move to Cleaning",
          value: "cleaning",
          tabId: 0,
        },
        {
          label: "Mark as Completed",
          value: "completed",
          tabId: 1,
        },
      ],
      orderMetaData: {},
      storeListPageNo: 1,
      filterAnchor: undefined,
      selectedDropdownValue: "",
      storeListEmptyFlag: false,
      userData: { attributes: {} },
      selectedOrders: [],
      snackbarOpen: false,
      snakbarMessage: "",
      severity: "success",
      filters: [
        {
          title: "Customer",
          type: "autocompolete",
          value: "",
          options: [],
        },
        {
          title: "Status",
          type: "select",
          value: "",
          options: [
            {
              label: "Cleaning",
              value: "cleaning",
            },
            {
              label: "Cleaned",
              value: "delivered",
            },
          ],
        },
      ],
      sortingData: {
        order_number: "",
        created_at: "",
        customer: "",
        full_phone_number: "",
        total: "",
      },
      sortingQuery: "",
      acceptedOrderStatus: "accepted",
      customerSuggestionFieldTitle: "",
      orderData: null,
      // Customizable Area End
    };

    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    const requestId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    // Customizable Area Start
    this.handleOrderResponse(requestId, responseJson);
    this.handelStoreResponse(requestId, responseJson);
    this.handelUpdateOrderStatusResponse(requestId, responseJson);
    this.handelUpdateAcceptedOrderStatusResponse(requestId, responseJson);
    this.handleFilterCustomerSuggestionResponse(requestId, responseJson);
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    const drawer = await getStorageData("isPinned");
    this.getOrdersList();
    // Customizable Area End
  }

  // Customizable Area Start

  handelStoreResponse(apiRequestCallId: string, responseJson: { data: [] }) {
    if (apiRequestCallId === this.getStoreCallId) {
      const { storeId } = this.state;
      const array = (responseJson?.data || []).filter(
        ({ id }: { id: string }) => Number(id) != storeId
      );
      this.setState((previous) => ({
        storeList: [...previous.storeList, ...array],
        storeListEmptyFlag: Boolean(array.length === 0),
      }));
    }
  }

  handleOrderResponse(
    requestId: string,
    response: { data: []; meta: { [field: string]: string } }
  ) {
    if (requestId === this.getOrderMessageId) {
      this.setState({
        orderList: response?.data,
        orderMetaData: response?.meta,
        totalCount: Number(response?.meta?.total_count),
      });
    }
  }

  handelUpdateOrderStatusResponse(
    requestId: string,
    response: { data: []; meta: { [field: string]: string } }
  ) {
    if (requestId === this.updateOrderStatusMessageId) {
      this.handleTabChange(this.state.tabId + 1);
    }
  }

  handelUpdateAcceptedOrderStatusResponse(
    requestId: string,
    response: { message: string; status: number }
  ) {
    if (requestId === this.updateAcceptedOrderStatusMessageId) {
      const status = this.getStatusFromSelectedTabList(this.state.tabId);
      this.getOrdersList(status, this.state.page);
    }
  }

  handleFilterCustomerSuggestionResponse(
    requestId: string,
    response: { filter_names: [] }
  ) {
    if (requestId === this.filterCustomerSuggestionApiCallId) {
      const list =
        response?.filter_names.map((name) => {
          return {
            label: name,
            value: name,
          };
        }) || [];
      this.setFilterSuggestionList(list);
    }
  }

  handleUserChange(userContext: IUserContext) {
    this.setState({ userData: userContext?.user || {} }, () => {
      const { store_id, store_name } =
        userContext?.user?.attributes?.employee_proifle?.data?.attributes
          ?.store_management || {};

      const item = {
        id: String(store_id),
        type: "",
        attributes: {
          id: Number(store_id),
          store_name: store_name,
        },
      };

      store_id
        ? this.setState(
            { storeList: [item] as IStoreItem[], storeId: Number(store_id) },
            () => this.getStoreList()
          )
        : this.getStoreList();
    });
  }

  handleSearch = (value: string) => {
    this.setState(
      {
        page: 1,
        searchText: value,
      },
      () => this.getOrdersList()
    );
  };

  handelStoreDropdownScroll = (event: React.SyntheticEvent) => {
    const { storeListEmptyFlag } = this.state;
    const listboxNode = event.currentTarget;

    const position = listboxNode.scrollTop + listboxNode.clientHeight;
    if (listboxNode.scrollHeight - position <= 1.3 && !storeListEmptyFlag) {
      this.setState(
        (prev) => ({ storeListPageNo: prev.storeListPageNo + 1 }),
        () => {
          this.getStoreList();
        }
      );
    }
  };

  getStoreList() {
    const { storeListPageNo } = this.state;

    const apiUrl =
      configJSON.getStoreListApi + "?dropdown=true&page_no=" + storeListPageNo;

    const requestMessage = makeApiMessage({
      url: apiUrl,
      method: "GET",
    });

    this.getStoreCallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getOrdersList(status = "in_store,accepted", pageNumber = 1) {
    const { sortingQuery, searchText } = this.state;
    const queryForSearch = searchText ? `&filter_by[query]=${searchText}` : "";
    const sort = sortingQuery ? "&" + sortingQuery : "";

    //filters
    const customerName = this.state.filters.find(
      (filter) => filter.title === this.state.customerSuggestionFieldTitle
    )?.value;
    const filterCustomerName = customerName
      ? `&filter_by[customer_name]=${customerName}`
      : "";

    const statusFilter = this.state.filters.find(
      (filter) => filter.title === "Status"
    )?.value as string;

    // if status is set from filter popover then pass that value
    if (statusFilter) {
      status = statusFilter ? statusFilter : status;

      // tab change for cleaning and cleaned filter popover status
      this.handleTabChangeOnFilterStatus(statusFilter);
    }

    const apiUrl =
      configJSON.homeCleaningOrdersListApi +
      status +
      `&page_no=${pageNumber}${sort}${queryForSearch}${filterCustomerName}`;
    const requestMessage = makeApiMessage({
      url: apiUrl,
      method: "GET",
    });

    this.getOrderMessageId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleTabChangeOnFilterStatus = (statusFilter: string) => {
    let tabIndex = 0;

    switch (statusFilter) {
      case "cleaning":
        tabIndex = 1;
        break;
      case "delivered":
        tabIndex = 2;
        break;
    }

    this.setState({
      tabId: tabIndex,
    });
  };

  handlePageChange(page: number) {
    const status = this.getStatusFromSelectedTabList(this.state.tabId);
    this.setState({ page: page + 1 }, () =>
      this.getOrdersList(status, this.state.page)
    );
  }

  handleBranchChange(_event: React.ChangeEvent, value: { id: number }) {
    const status = this.getStatusFromSelectedTabList(this.state.tabId);
    this.setState({ storeId: value.id }, () => this.getOrdersList(status));
  }

  handleDropDownSelect = (event: React.ChangeEvent<{ value: string }>) => {
    const value = event.target.value;
    this.setState({
      selectedDropdownValue: value,
      selectedOrders: [],
    });
  };

  handleCheckBoxSelection = (
    flag: boolean,
    trueValue: unknown,
    falseValue: unknown
  ) => {
    return flag ? trueValue : falseValue;
  };

  handleToggleOrderSelection = (orderId: number) => {
    let array: number[] = JSON.parse(JSON.stringify(this.state.selectedOrders));
    if (array.includes(orderId)) {
      array = array.filter((item: number) => item != orderId);
    } else {
      array.push(orderId);
    }

    this.setState({ selectedOrders: array });
  };

  handleSelectAllOrders = () => {
    const { orderList, selectedOrders } = this.state;
    const isSelectedAll = orderList.length === selectedOrders.length;
    if (isSelectedAll) {
      this.setState({ selectedOrders: [] });
    } else {
      this.setState({
        selectedOrders: orderList.map(
          (item: ICleaningOrder) => item.attributes.id
        ),
      });
    }
  };

  getColumnsDynamicWidth = () => {
    const screenWidth = window.innerWidth;
    if (this.renderSelectCheckBox()) {
      switch (true) {
        case screenWidth <= 1024:
          return "30px 90px 90px 100px 100px 140px 40px 60px 60px 70px 1fr";
        case screenWidth <= 1280:
          return "40px 130px 136px 125px 120px 160px 50px 80px 80px 90px 1fr";
        case screenWidth <= 1366:
          return "45px 120px 120px 130px 130px 170px 55px 90px 90px 100px 1fr";
        default:
          return "50px 130px 160px 150px 150px 200px 60px 100px 100px 100px 1fr";
      }

    } else {
      switch (true) {
        case screenWidth <= 1024:
          return "90px 90px 100px 100px 140px 40px 60px 60px 70px 1fr";
        case screenWidth <= 1280:
          return "130px 176px 125px 120px 160px 50px 80px 80px 90px 1fr";
        case screenWidth <= 1366:
          return "140px 130px 135px 130px 170px 70px 80px 80px 120px 1fr";
        default:
          return "130px 160px 150px 150px 200px 60px 100px 100px 100px 1fr";
      }
    }
  };

  renderSelectCheckBox = () => {
    const { selectedDropdownValue } = this.state;
    const dropDownActionValues = this.state.dropDownList.map(
      (actionDropDown) => actionDropDown.value
    );
    if (dropDownActionValues.includes(selectedDropdownValue)) {
      return true;
    } else {
      return false;
    }
  };

  getTableCellType(index: number) {
    if (index === 0) {
      return "left";
    } else if (index === 9) {
      return "right";
    } else {
      return "middle";
    }
  }

  handleCancel = () => {
    this.setState({
      selectedOrders: [],
      selectedDropdownValue: "",
    });
  };

  handleSnackbarClose = () => {
    this.setState({
      snackbarOpen: false,
    });
  };

  handleAcceptedOrder = (orderId: number) => {
    const { storeId } = this.state;
    const apiUrl =
      configJSON.homeCleaningOrderStatusApi +
      `?order_ids=${orderId}&store_id=${storeId}&order_action=${this.state.acceptedOrderStatus}`;

    const requestMessage = makeApiMessage({
      url: apiUrl,
      method: "PUT",
    });

    this.updateAcceptedOrderStatusMessageId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleMoveOrder = () => {
    const { selectedOrders, storeId, selectedDropdownValue } = this.state;
    const selectedOrdersIds = selectedOrders.join(",");
    const apiUrl =
      configJSON.homeCleaningOrderStatusApi +
      `?order_ids=${selectedOrdersIds}&store_id=${storeId}&order_action=${selectedDropdownValue}`;

    const requestMessage = makeApiMessage({
      url: apiUrl,
      method: "PUT",
    });

    this.updateOrderStatusMessageId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleSubmitOrder = () => {
    const { selectedOrders, tabId, orderList } = this.state;

    let errorMsg = "";
    if (!selectedOrders.length) {
      errorMsg = NewStrings.pleaseSelectOrders;
    } else if (selectedOrders.length > 0 && tabId === 0) {
      // check user has selected only accepted orders
      const selectedOrdersData = orderList.filter((order) =>
        selectedOrders.includes(Number(order.id))
      );

      let isOnlyAcceptedOrderPresentInOrder = false;
      isOnlyAcceptedOrderPresentInOrder = selectedOrdersData.every(
        (selectedOrder) =>
          selectedOrder.attributes.internal_status ===
          this.state.acceptedOrderStatus
      );
      if (!isOnlyAcceptedOrderPresentInOrder) {
        errorMsg = NewStrings.pleaseSelectOnlyAcceptedOrders;
      }
    }

    if (errorMsg.length > 0) {
      this.setState({
        snackbarOpen: true,
        snakbarMessage: errorMsg,
        severity: "error",
      });
    } else {
      this.handleMoveOrder();
    }
  };

  handleFilterChange = (filters: IFilter[]) => {
    const status = this.getStatusFromSelectedTabList(this.state.tabId);

    this.setState(
      {
        filters,
      },
      () => this.getOrdersList(status, 1)
    );
  };

  handleHomeCleaningFilterAutoComplete = (title: string, value: string) => {
    this.state.filters.forEach((item: IFilter) => {
      if (item.title === title) {
        item.value = value;
      }
      return item;
    });
    const ApiUrl =
      configJSON.homeCleaningOrdersCustomerFilterSuggestionEndPoint;

    let requestMessage = makeApiMessage({
      url: ApiUrl + value,
      method: "GET",
    });
    this.filterCustomerSuggestionApiCallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  setFilterSuggestionList(list: { label: string; value: string }[]) {
    const updatedFilters = this.state.filters.map((item: IFilter) => {
      if (item.title === this.state.customerSuggestionFieldTitle) {
        item.options = list;
      }
      return item;
    });
    this.setState({ filters: updatedFilters });
  }

  handleAutocompleteFilterChange(title: string, value: string) {
    this.setState({ customerSuggestionFieldTitle: title });
    this.handleHomeCleaningFilterAutoComplete(title, value);
  }

  handleClearFilter() {
    let updated = this.state.filters.map((item: IFilter) => {
      if (item.title === "Customer") {
        item.options = [];
        item.value = "";
      } else if (item.title === "Status") {
        item.value = "";
      } else {
        item.value = "";
      }
      return item;
    });
    this.setState({
      filters: updated,
    });
    setTimeout(() => this.handleFilterChange(updated), 1000);
  }

  handlePopeverOpen = (target: React.ReactNode) => {
    this.setState({
      filterAnchor: target,
    });
  };

  getSortingProps() {
    return {
      sortingData: this.state.sortingData,
      onQueryChange: (urlData: string) => {
        this.setState({ sortingQuery: urlData }, () => this.getOrdersList());
      },
      onChange: (data: ISortingData) => this.setState({ sortingData: data }),
    };
  }

  handleTabChange = (tabIndex: number) => {
    const status = this.getStatusFromSelectedTabList(tabIndex);

    let filterUpdated = this.state.filters.map((item: IFilter) => {
      if (item.title === "Customer") {
        item.options = [];
        item.value = "";
      } else if (item.title === "Status") {
        item.value = "";
      } else {
        item.value = "";
      }
      return item;
    });

    this.setState({
      tabId: tabIndex,
      selectedDropdownValue: "",
      selectedOrders: [],
      filters: filterUpdated,
    });
    this.getOrdersList(status);
  };

  getStatusFromSelectedTabList = (tabId: number) => {
    const tabList = [
      {
        label: "Orders",
        tabStatus: "in_store,accepted",
        tabIndex: 0,
      },
      {
        label: "Cleaning",
        tabStatus: "cleaning",
        tabIndex: 1,
      },
      {
        label: "Completed",
        tabStatus: "delivered",
        tabIndex: 2,
      },
      {
        label: "Cancelled",
        tabStatus: "cancelled",
        tabIndex: 3,
      },
    ];

    const selectedtab = tabList.find((tabData) => tabData.tabIndex === tabId);
    return selectedtab?.tabStatus;
  };

  displayStatusName = (internalStatus: string) => {
    switch (internalStatus) {
      case "in_store":
        return "Pending to Accept";
      case "accepted":
        return "Accepted";
      case "cleaning":
        return "In Process";
      case "delivered":
        return "Cleaned";
      case "cancelled":
        return "Cancelled";
    }
  };

  handleOpenDetailPopup = (itemData:ICleaningOrder) => {
    this.setState({ orderData: itemData });
  }

  closeOrderDetails() {
    this.setState({ orderData: null });
  }

  closeFilterPopOver = () => this.setState({ filterAnchor: undefined });

  formatCreatedDate = (created_at: string) =>
    moment(created_at).format("DD/MM/YYYY");
  // Customizable Area End
}
