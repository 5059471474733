import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
export interface HelpdeskReportingAttribute {
  id: string;
  type: string;
  attributes: {
    user_email: string;
    title: string;
    description: string;
    status: string;
    created_at: string;
    updated_at: string;
    resolved_at: string | null;
    response: string | null;
  };
}
interface ETicket {
  target: {
    value: string;
  };
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  ticketName: string;
  ticketEmail: string;
  ticketDesc: string;
  errorFlag: boolean;
  errorEmailFlag: boolean;
  errorDescFlag: boolean;
  ticketErrorMessage: string;
  ticketErrorEmailMessage: string;
  ticketDescErrorMessage: string;
  helpDeskReportData: HelpdeskReportingAttribute[];
  showForm: boolean;
  page: number;
  rowsPerPage: number;
  showListData: boolean;
  ticketCreationMessage: string;

  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class HelpdeskReportingController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  ticketListingData: string = "";
  createTicket: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      getName(MessageEnum.RestAPIResponceErrorMessage),
      getName(MessageEnum.RestAPIResponceDataMessage),
      // Customizable Area End
    ];
    // Customizable Area End

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      ticketName: "",
      ticketEmail: "",
      ticketDesc: "",
      errorFlag: false,
      errorEmailFlag: false,
      errorDescFlag: false,
      ticketErrorMessage: "",
      ticketErrorEmailMessage: "",
      ticketDescErrorMessage: "",
      helpDeskReportData: [],
      showForm: false,
      page: 0,
      rowsPerPage: 10,
      showListData: true,
      ticketCreationMessage: "",

      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId && responseJson) {
        switch (apiRequestCallId) {
          case this.ticketListingData:
            this.helpDeskReportListApiCallResp(responseJson);
            break;
          case this.createTicket:
            this.createTicketApiResp(responseJson);
            break;
        }
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start

  async componentDidMount() {
    this.ticketListingApiData();
  }

  helpDeskReportListApiCallResp = (responseJson: { errors: []; data: [] }) => {
    if (responseJson !== null && responseJson.data) {
      this.setState({ helpDeskReportData: responseJson.data });
    }
  };

  createTicketApiResp = (responseJson: { errors: []; data: [] }) => {
    if (responseJson !== null && responseJson.data) {
      this.setState({
        ticketCreationMessage: "Ticket Create Successfully",
      });
      this.ticketListingApiData();
      this.setState({
        ticketName: "",
        ticketEmail: "",
        ticketDesc: "",
      });
    } else {
      this.setState({
        ticketCreationMessage: "Please try again after sometime",
      });
    }
  };

  /* Create Help Desk Reporting Ticket API Call Function */
  createHelpRepoertingTicket() {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.createTicket = requestMessage.messageId;

    //* Adding endpoint
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.ticketListingDataApi
    );

    const headers = { "Content-Type": "application/json" };

    //* Adding header
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      headers
    );

    const createTicketBody = {
      title: this.state.ticketName,
      description: this.state.ticketDesc,
      user_email: this.state.ticketEmail,
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(createTicketBody)
    );

    /*Adding Request Method */
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.requestMethodOfAdding
    );

    //* Making Network Request
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  /* Create Help Desk Reporting Ticket API Call Function */
  ticketListingApiData() {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.ticketListingData = requestMessage.messageId;

    //* Adding endpoint

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.ticketListingDataApi
    );

    //* Adding Request Method

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.requestMethodOfListing
    );

    //* Making Network Request
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  /* Create Help Desk Reporting Ticket validation Function */
  doCreateTicket = (ticketForm: React.FormEvent<HTMLElement>) => {
    ticketForm.preventDefault();
    let ticketNameLength = this.state.ticketName;
    let ticketEmailLength = this.state.ticketEmail;
    let ticketDescLength = this.state.ticketDesc;

    if (ticketNameLength.trim().length <= 0) {
      this.setState({ errorFlag: true });
      this.setState({ ticketErrorMessage: configJSON.ticketNameError });
    } else {
      this.setState({ errorFlag: false });
      this.setState({ ticketErrorMessage: "" });
    }

    if (ticketEmailLength.trim().length <= 0) {
      this.setState({ errorEmailFlag: true });
      this.setState({ ticketErrorEmailMessage: configJSON.ticketEmailError });
    } else {
      this.setState({ errorEmailFlag: false });
      this.setState({ ticketErrorEmailMessage: "" });
    }

    if (ticketDescLength.trim().length <= 0) {
      this.setState({ errorDescFlag: true });
      this.setState({ ticketDescErrorMessage: configJSON.ticketDescError });
    } else {
      this.setState({ errorDescFlag: false });
      this.setState({ ticketDescErrorMessage: configJSON.blankString });
    }

    if (
      ticketNameLength.trim().length > 0 &&
      ticketEmailLength.trim().length > 0 &&
      ticketDescLength.trim().length > 0
    ) {
      this.setState({
        showListData: true,
        ticketName: ticketNameLength,
        ticketEmail: ticketEmailLength,
        ticketDesc: ticketDescLength,
      });
      this.createHelpRepoertingTicket();
    }
  };

  /* Save ticket name state function */
  saveTicketName = (eTicket: ETicket) => {
    let TicketNameValue = eTicket.target.value;
    this.setState({ ticketName: TicketNameValue });
    if (TicketNameValue.trim().length > 0) {
      this.setState({ errorFlag: false });
      this.setState({ ticketErrorMessage: "" });
    }
  };

  validateEmail = (inputEmail: string) => {
    const emailRegexp =
      /^[a-z0-9]+(\.[_a-z0-9]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,15})$/i;
    return emailRegexp.test(inputEmail);
  };

  /* Save ticket email state function */
  saveEmail = (ticketEmail: { target: { value: string } }) => {
    let TicketEmailValue = ticketEmail.target.value;
    this.setState({ ticketEmail: TicketEmailValue });
    if (this.validateEmail(this.state.ticketEmail) === true) {
      this.setState({ errorEmailFlag: false });
      this.setState({ ticketErrorEmailMessage: "" });
      this.setState({ ticketErrorEmailMessage: "" });
    } else {
      this.setState({ errorEmailFlag: true });
      this.setState({
        ticketErrorEmailMessage: configJSON.ticketValidEmailError,
      });
    }
  };

  /* Save ticket desc state function */
  saveTicketDesc = (ticketDescEvent: { target: { value: string } }) => {
    let TicketDescData = ticketDescEvent.target.value;
    this.setState({ ticketDesc: TicketDescData });

    if (TicketDescData.trim().length > 0) {
      this.setState({ errorDescFlag: false });
      this.setState({ ticketDescErrorMessage: "" });
    }
  };

  /* How and hide form UI */
  showHideForm = () => {
    if (this.state.showForm) {
      this.setState({ showForm: false });
    } else {
      this.setState({ showForm: true });
      this.setState({ showListData: false });
    }
  };

  handleChange = (event: unknown, newPage: number) => {
    this.setState({ page: newPage });
  };
  // Customizable Area End
}
