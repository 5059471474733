import React, { Component } from "react";
import { Popover, Box } from "@material-ui/core";
import ConfirmationModal from "./customComponents/ConfirmationModal.web";
import {
  renderBaseOnConditions,
  renderBooleanConditions,
  PermissionStatus
} from "../../blocks/utilities/src/CustomBlockHelpers";

type TablePopoverProps = {
  open: boolean;
  top: number;
  left: number;
  isActive: boolean;
  deactivateTitle?: string;
  disableDeactivateModal?: boolean;
  onClose: () => void;
  onView: () => void;
  onEdit: () => void;
  onActivate?: () => void;
  onDeactivate?: () => void;
  permissionStatus?: PermissionStatus;
};
type TablePopoverState = { disableModalVisible: boolean; backToList: boolean };

export default class TablePopover extends Component<
  TablePopoverProps,
  TablePopoverState
> {
  constructor(props: any) {
    super(props);
    this.state = { disableModalVisible: false, backToList: false };
  }

  renderModal = () => {
    return (
      <>
        {!this.props.disableDeactivateModal && <ConfirmationModal
          data-test-id="commonConfirmModal"
          open={this.state.disableModalVisible && !this.props.disableDeactivateModal}
          handleClose={() =>
            this.setState({ disableModalVisible: false, backToList: false })
          }
          handleConfirm={() => {
            this.setState({ disableModalVisible: false, backToList: true });
            this.props.onDeactivate?.();
          }}
          title='Confirm Deactivate'
          message={{
            title: this.props.deactivateTitle
          }}
          confirmText='Deactivate'
          dynamic={true}
        />}
        {this.state.backToList && (
          <ConfirmationModal
            data-test-id="commonConfirmModal"
            open={this.state.backToList}
            handleClose={() =>
              this.setState({ disableModalVisible: false, backToList: false })
            }
            handleConfirm={() =>
              this.setState({ disableModalVisible: false, backToList: false })
            }
            title={this.props.deactivateTitle + " Deactivated"}
            message={
              this.props.deactivateTitle + " has been deactivated successfully"
            }
            confirmText={"Back to Listing"}
            displayItem={true}
          />
        )}
      </>
    );
  };

  render() {
    const { permissionStatus } = this.props;
    return (
      <>
        <Popover
          style={{
            boxShadow: "none",
          }}
          data-test-id="tablePopover"
          anchorReference='anchorPosition'
          open={this.props.open}
          onClose={this.props.onClose}
          anchorPosition={{
            top: this.props.top + 40,
            left: this.props.left - 80,
          }}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          {renderBaseOnConditions(
            renderBooleanConditions(
              (permissionStatus as PermissionStatus)?.viewPermission ||
              (permissionStatus as PermissionStatus)?.editPermission ||
              (permissionStatus as PermissionStatus)?.activatePermission ||
              (permissionStatus as PermissionStatus)?.deactivatePermission,
              true,
              false
            ),
            <div style={webStyle.popoverContainer}>
              {renderBaseOnConditions(
                (permissionStatus as PermissionStatus)?.viewPermission,
                <Box
                  data-test-id='viewButton'
                  onClick={this.props.onView}
                  sx={webStyle.popoverButton}
                >
                  View
                </Box>,
                <></>
              )}
              {renderBaseOnConditions(
                (permissionStatus as PermissionStatus)?.editPermission,
                <Box
                  data-test-id='editButton'
                  onClick={this.props.onEdit}
                  sx={webStyle.popoverButton}
                >
                  Edit
                </Box>,
                <></>
              )}

              {(this.props.isActive
                && !!this.props.onDeactivate) && (
                <>
                  {renderBaseOnConditions(
                    (permissionStatus as PermissionStatus)
                      ?.deactivatePermission,
                    <Box
                      data-test-id='activateButton'
                      sx={webStyle.popoverButton}
                      onClick={() => {
                          if (this.props.deactivateTitle) {
                            this.setState({ disableModalVisible: true });
                          } else {
                            this.props.onDeactivate?.();
                          }
                      }}
                    >
                       Deactivate
                    </Box>,
                    <></>
                  )}
                </>
              )}

              {(!this.props.isActive
                && !!this.props.onActivate) && (
                <>
                  {renderBaseOnConditions(
                    (permissionStatus as PermissionStatus)
                      ?.activatePermission as boolean,
                    <Box
                      data-test-id='activateButton'
                      sx={webStyle.popoverButton}
                      onClick={() => {
                          this.props.onActivate?.();
                      }}
                    >
                     Active
                    </Box>,
                    <></>
                  )}
                </>
              )}
            </div>,
            <div style={webStyle.popoverContainer}>
              <div style={webStyle.noPermisions}> No Permissions </div>
            </div>
          )}
        </Popover>
        {this.props.deactivateTitle && this.renderModal()}
      </>
    );
  }
}

const webStyle = {
  popoverContainer: {
    paddingTop: "16px",
    paddingBottom: "16px",
    boxShadow:
      "0px 4px 8px rgba(0, 0, 0, 0.03), 0px 8px 32px rgba(0, 0, 0, 0.06)",
    borderRadius: "8px",
    fontFamily: "Montserrat",
    fontWeight: 500,
    fontSize: "14px",
  },
  popoverButton: {
    cursor: "pointer",
    width: "118px",
    height: "33px",
    paddingLeft: "24px",
    backgroundColor: "#FFFFFF",
    color: "#4D4D4D",
    "&:hover": {
      backgroundColor: "#204B9C",
      color: "#FFFFFF",
    },
    paddingTop: "8px",
  },
  noPermisions: {
    padding: "0 8px",
  }
};
