import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import * as Yup from "yup";
import { ClassNameMap } from "@material-ui/styles";
import React, {ChangeEvent} from "react";
import { debounce, concat, uniqBy } from "lodash";


export const configData = require("./config");
import { RawSectionListType, SectionListType} from "./SevicesTypes"
import utils from './util'
import { navigateTo } from "../../../../packages/blocks/utilities/src/CustomBlockHelpers";
import { updateMultiSelectData } from '../../../components/src/common';

export interface GetIconType {
    id: string,
    item: React.ReactNode,
    title: string
    attributes: {
        image: string;
        name: string;
    };

}



export interface AddFilledType {
    id?: string,
    name?: string,
    title?: string,
    serviceName: string | undefined,
    shortName: string | undefined,
    serviceSecondaryName: string,
    selectedIcon: string,
    selectedSections: string[],
    onlineOrder: boolean | undefined,
    selectedSectionName?: string[]
}

type Option = {
    id: string;
    option: string;
}

type IconOption = Option & {image: string;}




// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    classes: ClassNameMap;
    onChange: (event: ChangeEvent<HTMLInputElement>) => void;
    // Customizable Area End
}

interface S {
    token: string;
    // Customizable Area Start
   
    allIcons: IconOption[],
    loading: boolean,
    selectedIcon: IconOption | null,
    selectedOptions: string[] | string,
    sectionLists: Option[],
    sectionSelectedData: Option[];
    selectedSectionsForList: Option[]
    isSectionSelectAll: boolean;
    sectionPage: number;
    sectionAutoCompleteValue: string;
    iconPage: number;
    iconAutoCompleteValue: string;
    initialValues: AddFilledType,
    snackbar: {
          open: boolean,
          severity:"error" | "success" | "info" | "warning" | undefined,
          message:string
    },
    addBtnLoading: boolean,
    dropdownOptions: {
        section : {id?: string, name?: string}[],
        icons: {id?: string;
            option?: string;
            image?: string;}[]  
    },
    // Customizable Area End
}

interface SS {
    id: any;
}
  // Customizable Area End
export default class AddServicesController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    itemsPerPage = 10;
    getIconsCallIds: string = "";
  
    getSectionListCallId: string = "";
    addServiceCallId: string = "";
    disableLoadMoreSection = false;
    disableLoadMoreIcon = false;
   
   
  



    // Customizable Area End
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionSaveMessage),
            getName(MessageEnum.SessionResponseMessage),
            // Customizable Area Start
            getName(MessageEnum.RestAPIRequestMessage)
            // Customizable Area End
        ];

        this.receive = this.receive.bind(this);

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);


        this.state = {
            // Customizable Area Start
            allIcons: [],
            token: '',
            loading: false,
            addBtnLoading: false,
            selectedIcon: null,
            selectedOptions: [],
            sectionLists: [],
            initialValues: {
                serviceName: "",
                shortName: "",
                serviceSecondaryName: "",
                selectedIcon: "",
                selectedSections: [],
                onlineOrder: false
            },
            snackbar: {
                 open: false,
                 severity: 'info',
                 message:''
            },
            dropdownOptions: {
                section : [],
                icons: []            

            },
            sectionSelectedData: [],
            selectedSectionsForList: [],
            isSectionSelectAll: false,
            sectionAutoCompleteValue: '',
            iconAutoCompleteValue: '',
            sectionPage: 1,
            iconPage: 1
        // Customizable Area End
        };


    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        runEngine.debugLog("Message Recived", message);
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
            const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
            switch (apiRequestCallId) {

                case this.getSectionListCallId:
                    this.handleSectionListResponse(responseJson)
                    break;

                case this.getIconsCallIds:
                    this.setState({
                        loading: false
                    });
                    if (responseJson) {
                        this.handleIconListResponse(responseJson)
                    }
                    break;

                case this.addServiceCallId:
                    if (responseJson !==undefined && responseJson.error === undefined && !responseJson?.errors) {

                        this.setState({ 
                            snackbar: {
                                message: configData.addUserSuccess,
                                severity: 'success',
                                open:true
                            },
                            addBtnLoading: false,
                        });

                       setTimeout(()=>{
                        this.handleRedirect()
                       },1000) 

                    }
                    else{
        const keys = Object.keys(responseJson?.errors[0]);
                        this.setState({ 
                            addBtnLoading: false,
                            snackbar: {
                                message: keys?.[0] + ' has already been taken',
                                severity: 'error',
                                open:true
                            }
                        });
                    }

                    break;
            }

        }
        // Customizable Area End
    }


    async componentDidMount() {
        super.componentDidMount();
        // Customizable Area Start
        this.getSectionList();
        this.getAllIcon();
        // Customizable Area End


    }


    // Customizable Area Start

    token = localStorage.getItem('token');


    FormSchema = Yup.object().shape({
        serviceName: Yup.string().trim()
            .required("Service name is required"),
        shortName: Yup.string().trim()
            .required("Short name is required"),
        serviceSecondaryName: Yup.string(),
        selectedSections: Yup.array().of(
            Yup.string().required('please select at least one ')
        ).required('At least select one section'),
    })





    selectIcon = (item: IconOption, setFieldValue: Function) => {
          setFieldValue('selectedIcon', item.id)
     }

     handleRedirect = () => { navigateTo({ props: this.props, screenName: "ServicesList" })}

    getSectionList = () => {
        this.setState({ loading: true });
        const headers = {
            "Content-Type": configJSON.categoryApiContentType,
            token: this.token,
        };

        const sectionListDataMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getSectionListCallId = sectionListDataMessage.messageId;
        this.disableLoadMoreSection = true;
        const searchQuery = this.state.sectionAutoCompleteValue ? `&filter_by[query]=${this.state.sectionAutoCompleteValue}` : ''

        sectionListDataMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.getSectionListsAPIEndPoint}?dropdown=true${searchQuery}&page_no=${this.state.sectionPage}&per_page=${this.itemsPerPage}`
        );

        sectionListDataMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        sectionListDataMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.httpGetType
        );
        runEngine.sendMessage(sectionListDataMessage.id, sectionListDataMessage);
    }


    getAllIcon = () => {
        this.setState({ loading: true });
        const headers = {
            "Content-Type": configJSON.categoryApiContentType,
            token: this.token,
        };

        const iconsListDataMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)


        );

        const searchQuery = this.state.iconAutoCompleteValue ? `&filter_by[query]=${this.state.iconAutoCompleteValue}` : ''

        iconsListDataMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.getIconsListsAPIEndPoint}?page_no=${this.state.iconPage}&per_page=${this.itemsPerPage}${searchQuery}`
        );




        this.getIconsCallIds = iconsListDataMessage.messageId;

       
        iconsListDataMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.httpGetType
        );

        iconsListDataMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

  

        runEngine.sendMessage(iconsListDataMessage.id, iconsListDataMessage);
    }


  
  addService = (values: AddFilledType) => {
        this.setState({
            addBtnLoading: true
        })


        const header = {
            "Content-Type": configJSON.categoryApiContentType,
            token: this.token
        }

        const httpBody = JSON.stringify({
            data: {
                attributes: {
                    name: values?.serviceName,
                    second_name: values?.serviceSecondaryName,
                    section_ids: values?.selectedSections,
                    active: true,
                    online_order: values?.onlineOrder,
                    icon_id: Number(values?.selectedIcon),
                    short_name: values?.shortName
                }
            }

        })

        const getDataMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.addServiceCallId = getDataMsg.messageId;

        getDataMsg.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            httpBody
        );

        getDataMsg.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.addServiceListAPIEndPoint
        );
        getDataMsg.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        getDataMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "POST");
        runEngine.sendMessage(getDataMsg.id, getDataMsg);
    }

    handleCloseSnackbar = ()=>{

        this.setState({
            
            snackbar: {


                message:'',
                open:false,

                severity:'info'

            }
         })
    }

    handleSectionMultiSelect = (value: { id: string, option: string }[], option: { id: string, option: string }, setFieldValue: Function) => {
        let updateStateData: Partial<S> = {}
        let fieldValue: Array<string> = []
        if (option.id == "-1") {
          updateStateData = this.state.isSectionSelectAll ?
            { isSectionSelectAll: false, sectionSelectedData: [], selectedSectionsForList: [] }
            :
            {
              isSectionSelectAll: true,
              sectionSelectedData: [{ id: "-1", option: "Select All" }],
              selectedSectionsForList: [{ id: "-1", option: "Select All" }, ...this.state.sectionLists]
            };
          fieldValue = updateStateData.isSectionSelectAll ? ["All"] : []
    
        } else {
          const isValueContained = value.some((item) => item.id == option.id)
          value = value.filter((item) => item.id !== "-1")
          const newItems = isValueContained ? value.filter((item) => item.id != option.id) : [...value, option]
          updateStateData = updateMultiSelectData(newItems, this.state.sectionLists, 'isSectionSelectAll', 'sectionSelectedData', 'selectedSectionsForList')
          fieldValue = newItems.map((item) => item.id)
        }
        this.setState(updateStateData as Pick<S, keyof S>, () => {
          setFieldValue("selectedSections", fieldValue)
        })
      };

    handleSelectIcon = (value: IconOption, setFieldValue: (field: string, fieldValue:string) => void) => {
        this.setState({
            selectedIcon: value
        },() => setFieldValue("selectedIcon", value.id))
    }

    handleScrollSectionDropdown = (event: React.SyntheticEvent) => {
        if (this.disableLoadMoreSection) return;
        const checkListboxNode = event.currentTarget;
        const boxPosition = checkListboxNode.scrollTop + checkListboxNode.clientHeight;
    
        if (checkListboxNode.scrollHeight - boxPosition <= 1.30) {
          this.setState((prevState) => ({ sectionPage: prevState.sectionPage + 1 }), () => {
            this.getSectionList()
          })
        }
      };
    handleScrollIconDropdown = (event: React.SyntheticEvent) => {
        if (this.disableLoadMoreIcon) return;
        const checkListboxNode = event.currentTarget;
        const boxPosition = checkListboxNode.scrollTop + checkListboxNode.clientHeight;
    
        if (checkListboxNode.scrollHeight - boxPosition <= 1.30) {
          this.setState((prevState) => ({ iconPage: prevState.iconPage + 1 }), () => {
            this.getAllIcon()
          })
        }
      };
    debouncedFunction = debounce(
    (newInputValue: string, inputFunction: (inputValue: string) => void) => inputFunction(newInputValue),
        700,
        { maxWait: 2000 }
    );
    handleIconAutoCompleteChange = (getValue: string) => {
        if (getValue === this.state.iconAutoCompleteValue) return;
        this.setState({ iconAutoCompleteValue: getValue, iconPage: 1 }, () => {
            if (getValue.length < 1 || getValue.length > 2) {
            this.getAllIcon()
            }
        });
    }
    handleSectionAutoCompleteChange = (getValue: string) => {
        if (getValue === this.state.sectionAutoCompleteValue) return;
        this.setState({ sectionAutoCompleteValue: getValue, sectionPage: 1 }, () => {
            if (getValue.length < 1 || getValue.length > 2) {
            this.getSectionList()
            }
        });
    }
    handleSectionListResponse = (responseData: {data : RawSectionListType[]}) => {
        this.setState({
            loading: false
        });
        const { parsedSectionDropdown } = utils.parseSections(responseData)
        this.setState({
            sectionLists: this.state.sectionPage === 1 ? parsedSectionDropdown : [...this.state.sectionLists, ...parsedSectionDropdown],
            selectedSectionsForList: this.state.isSectionSelectAll ? 
                [...this.state.selectedSectionsForList, ...parsedSectionDropdown] 
                : this.state.selectedSectionsForList
        })
        this.disableLoadMoreSection = parsedSectionDropdown.length < this.itemsPerPage
    }

    handleIconListResponse = (responseJson: {data: GetIconType[]}) => {
        const {parsedDropdownIconss}  = utils.parsedAllIcon(responseJson)
        let newIcons = this.state.iconPage === 1 ? parsedDropdownIconss : concat(this.state.allIcons, parsedDropdownIconss)
        this.state.selectedIcon && newIcons.unshift(this.state.selectedIcon)
        this.setState({
            allIcons: uniqBy(newIcons, "id"),
        })
        this.disableLoadMoreIcon = parsedDropdownIconss.length < this.itemsPerPage
    }
    // Customizable Area End
}
