/** @format */
// Customizable Area Start
import React, { Component, ReactNode } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Box from "@material-ui/core/Box";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import "./ConfirmationModal.css";
import {
	styled
  } from "@material-ui/core";
import { MainBox, PrimaryButtonBox, SecondaryButtonBox, StyledDialogContent } from "./RoleDisableModal";
export const configJSON = require("../config");
// Customizable Area End

// Customizable Area Start
interface ConfirmationModalProps {
	open: boolean;
	handleClose: () => void;
	handleConfirm?: any
	title: string;
	message: any;
	confirmText: string | ReactNode;
	displayItem?: boolean;
	movedItem?: any;
	dynamic?: boolean;
	customMessage?: string;
}
// Customizable Area End

// Customizable Area Start
class ConfirmationModal extends Component<ConfirmationModalProps> {
	render(): ReactNode {
		const {
			open,
			handleClose,
			handleConfirm,
			title,
			message,
			confirmText,
			displayItem,
			movedItem,
			dynamic,
			customMessage
		} = this.props;
		return (
			<CustomDialog
				className="main-dialog-container"
				open={open}
        data-test-id="commonConfirmModal"
				onClose={handleClose}>
				<Box className="dialog-main">
					<Box className={`icon-section ${displayItem ? "error" : "close"}`}>
						{displayItem ? <ErrorOutlineIcon /> : <HighlightOffIcon />}
					</Box>
					<DialogTitle>{title}</DialogTitle>
					<StyledDialogContent>
						{customMessage ? <DialogContentText>{customMessage}</DialogContentText> :
						dynamic ? <DialogContentText>{configJSON.textMessage} {message?.title}? {configJSON.unDoneMessage}</DialogContentText> : <DialogContentText>{message?.title} {configJSON.confirmDeactiveMessage}</DialogContentText>}
					</StyledDialogContent>
					<MainBox>
            {
              dynamic && <SecondaryButtonBox>
              <Button
                data-test-id="cancelModelBtn"
                aria-label="cancel-modal-button"
                className={`secondaryButtonStyle`}
                onClick={handleClose}
                color="primary">
                {configJSON.cancelButton}
              </Button>
            </SecondaryButtonBox>
            }
						<PrimaryButtonBox>	
						<Button
              				data-test-id="confirmModelBtn"
							aria-label="confirm-modal-button"
							className={dynamic ? `primaryButtonStyle` : `primaryButtonForConfirm`}
							onClick={() => handleConfirm(movedItem)}
							color="primary"
							autoFocus>
							{confirmText}
						</Button>
						</PrimaryButtonBox>
					</MainBox>
				</Box>
			</CustomDialog>
		);
	}
}

export default ConfirmationModal;

const CustomDialog = styled(Dialog)({
	"@media only screen and (max-width: 1024px)": {
		fontSize: 14,
		"& .icon-section": {
			marginBottom: "15px"
		},
		"& .dialog-main" :{
			width: "471px",
			margin:" 40px 20px !important"
		}
	  }
})
// Customizable Area End
