// Customizable Area Start
import React from "react";
import { StyledAppBar } from "../../../components/src/customComponents/CustomTags.web";
import { Tabs, styled, Tab } from "@material-ui/core";
// Customizable Area End

// Customizable Area Start

interface Props {
  id: string;
  handleTabSeletion:(event: React.ChangeEvent<{}>, newValue: string) => void;
  value: string;
  tabList: string[];
}
// Customizable Area End

// Customizable Area Start
// Customizable Area End

// Customizable Area Start
const CustomCashierTab: React.FC<Props> = (props) => {
  const { id, handleTabSeletion, value, tabList } = props;
  return (
    <StyledAppBar position='static' style={{ margin: "0" }}>
      <Tabs
        variant='standard'
        data-test-id={id}
        value={value}
        onChange={handleTabSeletion}
      >
        {tabList.map((data,index) => {
          return <CustomTypeTab label={data} value={data} key={index} />;
        })}
      </Tabs>
    </StyledAppBar>
  );
};

const CustomTypeTab = styled(Tab)({
  fontFamily: "Montserrat",
  fontSize: "16px",
  textTransform: "none",
  minWidth: "0",
  "@media only screen and (max-width: 1024px)": {
    minHeight: "0px",
    fontSize: "14px",
  },
});

export default CustomCashierTab;
// Customizable Area End
