import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start

import React from "react";

export const configData = require("./config");
import { TaxResponse, Tax, subscribeMessage } from "./util";
import * as Yup from "yup";
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any;
  // Customizable Area End
}

interface S {
  token: string;
  // Customizable Area Start
  selectedType: string;
  errorMessage: string;
  loading: boolean;
  editId: string;
  intialValues: {
    id?: string;
    taxName: string;
    taxRate: string;
  };
  addBtnLoading: boolean;
  snackbar: {
    open: boolean;
    severity: "error" | "success" | "info" | "warning" | undefined;
    message: string;
  };
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class AddEditTaxController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getTaxCallId: string = "";
  updateTaxCallId: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = subscribeMessage();

    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    this.state = {
      selectedType: "",
      token: "",
      loading: false,
      errorMessage: "",
      editId: "",
      intialValues: {
        id: this.state?.editId,
        taxName: "",
        taxRate: "",
      },
      addBtnLoading: false,
      snackbar: {
        open: false,
        severity: "info",
        message: "",
      },
    };

    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Received", message);

    if (getName(MessageEnum.RestAPIResponceMessage) !== message.id) {
      return;
    }

    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    // Customizable Area Start
    if (apiRequestCallId === this.getTaxCallId) {
      this.setState({ loading: false });

      this.handlePrefilledResponse(responseJson);
    }

    if (apiRequestCallId === this.updateTaxCallId) {
      this.handleEditResponse(responseJson);
    }
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    this.setState({
      editId: this.props.navigation.getParam("id", "defaultValue"),
    });

    this.getPrefilledTaxData(this.state.editId);
    // Customizable Area End
  }

  componentDidUpdate(prevProps: Props, prevState: S) {
    // Customizable Area Start
    if (prevState.editId !== this.state.editId) {
      this.getPrefilledTaxData(this.state.editId);
    }
    // Customizable Area End
  }

  // Customizable Area Start

  // eslint-disable-next-line no-undef
  token = localStorage.getItem("token");

  FormSchema = Yup.object().shape({
    taxName: Yup.string()
      .required("Tax name is required")
      .test("no-number", "Tax name cannot contain numbers", (value) => {
        // Use a regular expression to check if the tax name contains any numbers
        return !/\d/.test(value);
      }),
    taxRate: Yup.string()
      .required("Tax percentage is required")
      .matches(/^\d+(\.\d+)?$/, "Tax percentage must be a valid number")
      .test(
        "positive-number",
        "Tax percentage must be a positive number",
        (value) => {
        // Convert the tax rate to a number and check if it's positive
        const numericValue = parseFloat(value);
          return !isNaN(numericValue) && numericValue > 0;
      })
  });

  getPrefilledTaxData = (id: string) => {
    this.setState({
      loading: true,
    });

    const header = {
      "Content-Type": configData.apiContentType,
      token: this.token,
    };
    const getDataMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getTaxCallId = getDataMsg.messageId;

    getDataMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configData.viewTaxApi}/${id}`
    );
    getDataMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    getDataMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "Get");
    runEngine.sendMessage(getDataMsg.id, getDataMsg);
  };
  /**
   * gets Tax preFilled Value
   * @param responseJson api response
   */
  handlePrefilledResponse = (responseJson: TaxResponse) => {
    const parsedData = {
      id: responseJson.data?.id,
      taxName: responseJson.data?.attributes.tax_name,
      taxRate: responseJson.data?.attributes.tax_rate,
    };

    this.setState({
      intialValues: parsedData,
    });

    console.log(this.state.intialValues);
  };

  /**
   * Function to handle api response for editing tax
   * @param responseJson Response from API
   */
  handleEditResponse = (responseJson: any) => {
    if (responseJson !== undefined && responseJson.error === undefined) {
      this.setState({
        snackbar: {
          message:
            this.state.editId === "new"
              ? configData.addTaxSuccess
              : configData.updateTaxSuccess,
          severity: "success",
          open: true,
        },
      });

      setTimeout(() => {
        this.props.navigation.navigate("TaxList");
      }, 2000);
    } else {
      this.setState({
        addBtnLoading: false,
        snackbar: {
          message: responseJson?.errors[0]?.message,
          severity: "error",
          open: true,
        },
      });
    }
  };

  /**
   * Function to update tax value
   * @param values Tax value
   */
  updateTax = (values: Tax) => {
    this.setState({
      addBtnLoading: true,
    });

    const header = {
      "Content-Type": configData.validationApiContentType,
      token: this.token,
    };

    const httpBodys = JSON.stringify({
      data: {
        tax_name: values.taxName,
        tax_rate: values.taxRate,
      },
    });

    const getDataMsgs = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.updateTaxCallId = getDataMsgs.messageId;

    getDataMsgs.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      httpBodys
    );
    getDataMsgs.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${
        this.state.editId === "new"
          ? configData.viewTaxApi
          : `${configData.viewTaxApi}/${this.state.editId}`
      }`
    );
    getDataMsgs.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    getDataMsgs.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      `${this.state.editId === "new" ? "Post" : "Put"}`
    );
    runEngine.sendMessage(getDataMsgs.id, getDataMsgs);
  };

  handleCloseSnackbar = () => {
    this.setState({
      snackbar: {
        open: false,
        severity: "info",
        message: "",
      },
    });
  };

  handelCancelForm = () => {
    this.props.navigation.navigate(configData.taxList)
  }

  // Customizable Area End
}
