import { CSSProperties } from "@material-ui/styles";
import { Overrides } from '@material-ui/core/styles/overrides';
import { ThemeOptions } from '@material-ui/core/styles';


export const editstyles: Record<string, CSSProperties> = {
    customCheckbox: {
      display: 'flex',
      alignItems: 'center',
      padding: '4px 8px',
      gap: '7px',
      backgroundColor: 'rgba(32, 75, 156, 0.1)',
      borderRadius: '16px',
      color: "#204B9C",
    
    },
    secondName: {
      height: '60px',
      borderRadius: '8px',
      display: "flex",
      justifyContent: "center",
      paddingLeft: '8px'
    },
    cancelBtn: {
      backgroundColor: '#F1F5F9',
      color: '#64748B',
      width: '126px',
      height: '56px',
      textTransform: 'none',
      border: "0"
    },
    addBtn: {
      backgroundColor: "#204B9C",
      color: "#ffff",
      width: "128px",
      height: "56px",
      marginLeft: "32px",
      textTransform: "none",
      border: "0",
      "&:hover": {
        backgroundColor: "#204B9C",
        color: "#ffff"
      }
    },
    servicesBtn: {
      display: "flex",
      minHeight: "60px",
      justifyContent: "center",
      borderRadius: "8px",
      padding: "10px",
      flexWrap: "wrap",
      boxSizing: 'border-box'
    },
    flexContainer: {
      display: "flex",
      flexWrap: "wrap",
      gap: "10px"
    },
   enterName: {
      height: "60px",
      borderRadius: '8px',
      display: "flex",
      justifyContent: "center",
      paddingLeft: "8px"
    },
    inputLabel: {
      fontWeight: 600,
      fontSize: "16px",
      color: "#334155"
    },
    button: {
      fontSize: "16px",
      fontWeight: 700,
      borderRadius: '8px'
    },
    onlineOrderText: {
      color: "334155"
    },
   dropDown :{
        maxWidth:'498px',
        overflowY: 'auto'
       
       
    },
    cardBox:{
      minHeight: "320px",
      borderRadius: "12px",
      border:0,
      boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.03), 0px 8px 32px rgba(0, 0, 0, 0.06)"
    },
    titleContainer: {
      display: "flex",
      borderColor: "1px solid red",
      justifyContent: "space-between",
      alignItems: "center",
      margin: "10px 0 0 10px",
      padding: "46px 0",
    },
    titleContainerText: {
      fontSize: '24px',
      fontWeight: 600,
      lineHeight:'29.26px',
    
      
    },
    mainContainer: {
      margin:'32px 0', 
      width: '100%',
      padding:'10px 49px',
    },
    select: {
      border: '1px solid #ECECEC',
      '&:focus': {
        border: '1px solid #ECECEC',
        borderBottom: '0',
      },
    },
    flex: {
        display: "flex",
        flexDirection: "column",
        gap: "2rem",
      },
     
      underline: {
        borderBottom: '0',
      },
      onelineOrder:{
        display: 'flex'
    },
    parentContainer:{
      width: '100%',
      padding:'10px 49px',
    
  }
    
  
  }


  export const styleOverride:ThemeOptions =  {
    typography: {
      fontFamily: "Montserrat, sans-serif",
    },
    palette: {
      primary: {
        main: "#0000ff",
        contrastText: "#fff",
      },
      secondary: {
        main: "#334155",
       
      },
  
    },
    // overrides: {

    //   customFormControl: {
    //     marginTop: 0,
    //     border: 0,
    //     borderColor: '#ECECEC'  
         
    //   },
    //   MuiInput: {
    //     underline: {
    //       '&:before': {
    //         borderBottom: '0'
    //       },
    //       '&:after': {
    //         borderBottom: '0', // Add your custom styles here
    //       },
    //       '&:hover:not($disabled):before': {
    //         borderBottom: 'none', // Add your custom styles here
    //       },
    //     },
       
    //     input: {
    //       color: '#4D4D4D'
    //     }
    //   },
    //   MuiSelect: {
    //     select: {
    //       "&:focus": {
    //         backgroundColor: 'transparent',
    //         // color:"red"
    //       }
  
    //     }
    //   },
    
    //   MuiMenu:{
    //       list: {
    //              height: '180px'
    //       },
    //       paper: {
    //          top: '416px !important',
    //       }
    //   },
    //  MuiOutlinedInput: {},
    //  MuiFormLabel:{
    //     root : {
    //         color: '#4D4D4D',
    //         fontWeight:400, 
    //         fontSize: '14px'
    //     }
    //  },
    //   MuiInputLabel: {
    //     formControl: {

        
    //       '& + .MuiInput-formControl': {
    //          marginTop: "0",
            
    //       },
    //     },
    //     animated: {
    //       padding: ' 0 10px',
    //       width: '100%',
    //       boxSizing: 'border-box',
    //     }
  
  
    //   },
    //   menuItem: {
    //     '&:hover': {
    //       backgroundColor: 'transparent', // Change this to your desired hover color
    //     },
    //   },

    //   // MuiListItem:{
    //   //     root:{
    //   //         Mui:{
                 
    //   //         }
    //   //     }   
    //   // },


    //   // '& .MuiListItem-root.Mui-selected, .MuiListItem-root.Mui-selected:hover':{
    //   //   color:'red',
    //   // }
  
    //   // .DropdownMultiSelect-formControl-77

  
  
    //   MuiCheckbox: {
    //    colorSecondary: {
    //       '&.Mui-checked': {
    //         // Add your custom styles here
    //         // For example:
    //         color: '#204B9C',
    //         borderRadius: '6px'
    //       },
       

    //     },
    //   }
    // } as Overrides
  }
  
