import React from "react";
// Customizable Area Start
import RewashOrderModalController, {
  Props,
  configJSON,
} from "./RewashOrderModalController.web";
import { Box, styled, FormLabel, TextField, Dialog } from "@material-ui/core";
import { colors } from "../../utilities/src/Colors";
import { BottomContainer } from "../../promocodes/src/PromoCodeCreate.web";
import {
  button_plus_light,
  button_minus_light,
  button_plus_dark,
  button_minus_dark,
  CheckTrue,
  CheckFalse,
} from "./assets";
import { CustomSnackbar } from "../../../components/src/customComponents/CustomSnackbar.web";
import { OrderItem } from "./utils";
import {
  OrderTable,
  RowContainer,
  TableRow,
  TableCell,
} from "../../../components/src/OrderTable/src";
const Strings = configJSON.RewashOrder;
// Customizable Area End

class RewashOrderModal extends RewashOrderModalController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  showProductName(item: OrderItem) {
    return (
      <Box className='d-flex'>
        <p className='product-name'>{item.attributes.catalogue.product_name}</p>
        <span className='product-circle'></span>
        <p className='product-second-name'>
          {item.attributes.catalogue.product_second_name}
        </p>
        {((Number(item.attributes.height) !== 0 && Number(item.attributes.width) !== 0 ) || (Number(item.attributes.weight) !== 0)) && 
          <>
            <span className='product-circle'></span>
          </>
        }
        <span style={{display: "block", color: "#0F172A", fontSize: "12px",fontWeight: "400", textTransform: "lowercase"}}>
            {Number(item.attributes.height) !== 0 && Number(item.attributes.width) !== 0  && (<>length * width: {item.attributes.height} m * {item.attributes.width} m </>)}
            {Number(item.attributes.weight) !== 0 && (<>weight : {item.attributes.weight} Kg</>)}
        </span>
      </Box>
    );
  }

  showQtyHandlerButtons(item: OrderItem) {
    return (
      <ButtonWrapper className='d-flex'>
        <button
          className='qty-button'
          data-test-id={`minus-qty-button-${item.attributes.id}`}
          disabled={this.checkForButtonDisabled(item, "minus")}
          onClick={() => this.handleUpdateQty(item, "minus")}
        >
          <img
            src={
              this.checkForButtonDisabled(item, "minus")
                ? button_minus_light
                : button_minus_dark
            }
            alt='qty-button'
          />
        </button>
        <span
          className='span-qty'
          data-test-id={`product-qty-${item.attributes.id}`}
        >
          {item.attributes.quantity}
        </span>
        <button
          disabled={this.checkForButtonDisabled(item, "plus")}
          className='qty-button'
          data-test-id={`plus-qty-button-${item.attributes.id}`}
          onClick={() => this.handleUpdateQty(item, "plus")}
        >
          <img
            src={
              this.checkForButtonDisabled(item, "plus")
                ? button_plus_light
                : button_plus_dark
            }
            alt='qty-button'
          />
        </button>
      </ButtonWrapper>
    );
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const {
      tempOrderItemsList,
      sigleOrderDetails,
      notes,
      complaint,
      snackBarMessage,
      snackBarOpen,
      severity,
      selectedRewashOrders,
    } = this.state;

    // Customizable Area End

    return (
      // Customizable Area Start
      <StyledDialog
        open={this.state.modalClose}
        data-test-id='rewash-dialog'
        onClose={() => {
          this.handleModalClose();
        }}
      >
        <h2 className='modal-heading'>{Strings.rewashItems}</h2>
        <Box className='form-container'>
          <FormRow>
            <FormCol>
              <FormLabel className='form-label'>{Strings.orderId}</FormLabel>
              <TextField
                value={sigleOrderDetails.attributes?.order_number}
                disabled
                type='text'
                variant='standard'
                placeholder={Strings.orderId}
                InputProps={{
                  disableUnderline: true,
                }}
              />
            </FormCol>
            <FormCol>
              <FormLabel className='form-label'>
                {Strings.orderCreationDate}
              </FormLabel>
              <TextField
                value={this.formatCreatedDate(sigleOrderDetails.attributes?.created_at)}
                disabled
                type='text'
                variant='standard'
                placeholder={Strings.orderCreationDate}
                InputProps={{
                  disableUnderline: true,
                }}
              />
            </FormCol>
          </FormRow>
          <FormRow>
            <FormCol>
              <FormLabel className='form-label'>
                {Strings.customerName}
              </FormLabel>
              <TextField
                value={sigleOrderDetails.attributes?.customer.full_name}
                disabled
                type='text'
                variant='standard'
                placeholder={Strings.customerName}
                InputProps={{
                  disableUnderline: true,
                }}
              />
            </FormCol>
            <FormCol />
          </FormRow>
          <OrderTable>
            <RowContainer header>
              <TableRow columns={"40px 1fr 1fr"}>
                <TableCell
                  header
                  content={
                    <img
                      data-test-id='selectAllRewashOrderCheckbox'
                      className='cursor-pointer'
                      onClick={() => this.handleSelectAllRewashOrder()}
                      src={this.handleCheckBoxSelection(
                        selectedRewashOrders.length ==
                          tempOrderItemsList.length,
                        CheckTrue,
                        CheckFalse
                      )}
                    />
                  }
                />
                <TableCell header content={"Products"}/>
              </TableRow>
            </RowContainer>
            <RowContainer>
              {tempOrderItemsList.map((item: OrderItem) => {
                return (
                  <TableRow className='table-rows' columns={"30px 1fr 1fr"}>
                    <TableCell
                      type='none'
                      className='justify-end'
                      content={
                        <img
                          data-test-id={`product-select-${item.attributes.id}`}
                          className='cursor-pointer'
                          onClick={() =>
                            this.handleToggleOrderSelection(item.attributes.id)
                          }
                          src={this.handleCheckBoxSelection(
                            selectedRewashOrders.includes(item.attributes.id),
                            CheckTrue,
                            CheckFalse
                          )}
                        />
                      }
                    />
                    {[
                      this.showProductName(item),
                      this.showQtyHandlerButtons(item),
                    ].map((heading: React.ReactNode, index: number) => (
                      <TableCell
                        content={heading}
                        type={index == 0 ? "left" : "right"}
                      />
                    ))}
                  </TableRow>
                );
              })}
            </RowContainer>
          </OrderTable>
          <StyledTextArea
            className='mt-32'
            rows={3}
            placeholder={Strings.addInstructions}
            value={notes}
            onChange={(event) => this.handleChangeNotes(event)}
          />
          <StyledTextArea
            rows={3}
            placeholder={Strings.customerComplaint}
            value={complaint}
            className='m-32'
            onChange={(event) => this.handleChangeComplaint(event)}
          />
          <Box className='bottom-box'>
            <p>{Strings.priceToPay}</p>
            <p>{Strings.free}</p>
          </Box>
        </Box>
        <BottomContainer className='p-12 d-flex'>
          <button
            data-test-id='btn-cancel-clean'
            className='cancel'
            onClick={() => this.handleModalClose()}
          >
            {Strings.cancel}
          </button>
          <button
            onClick={() => this.handleSubmitRewash()}
            data-test-id='btn-submit-rewash'
            className='add'
          >
            {Strings.rewash}
          </button>
        </BottomContainer>
        <CustomSnackbar
          data-test-id='succesSnakBar'
          open={snackBarOpen}
          onClose={this.handleSnackbarClose}
          errorMessage={snackBarMessage}
          severity={severity}
        />
      </StyledDialog>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const StyledDialog = styled(Dialog)({
  padding: "24px 32px",
  "& .MuiDialog-paperScrollPaper": {
    borderRadius: "24px",
  },
  "& .MuiDialog-paper": {
    boxShadow: "none",
    padding: "24px 12px 24px",
  },
  "& .dialogContentBox": {
    textAlign: "center",
    maxHeight: "100%",
  },
  "& .MuiDialog-paperWidthSm": {
    maxWidth: 840,
    width: 840,
  },
  "& .modal-heading": {
    fontSize: "24px",
    lineHeight: "32px",
    fontWeight: 600,
    fontFamily: "Montserrat",
    textAlign: "center",
  },
  "& .form-container": {
    padding: "24px",
    overflow: "auto",
  },
  "& .bottom-box": {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "& p": {
      fontSize: "16px",
      lineHeight: "20px",
      fontWeight: 600,
      fontFamily: "Montserrat",
      color: colors().black,
    },
  },
  "& .p-12": {
    padding: "12px 0",
  },
  "& .d-flex": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  "& .product-name": {
    fontSize: "14px",
    lineHeight: "18px",
    fontWeight: 500,
    fontFamily: "Montserrat",
    color: colors().viewTextColor,
  },
  "& .product-second-name": {
    fontSize: "13px",
    lineHeight: "18px",
    fontWeight: 400,
    fontFamily: "Montserrat",
    color: colors().viewTextColor,
  },
  "& .product-circle": {
    display: "block",
    margin: "0 12px",
    height: "3px",
    width: "3px",
    borderRadius: "100%",
    backgroundColor: colors().viewTextColor,
  },
  "& .cursor-pointer": {
    cursor: "pointer",
  },
  "& .justify-end": {
    justifyContent: "end",
  },
});

const FormCol = styled(Box)({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  "& input[type=text]": {
    backgroundColor: "#E9E9E9",
    border: "none",
    padding: "16px",
    color: colors().black,
    fontSize: "16px",
    lineHeight: "22px",
    fontWeight: 500,
    fontFamily: "Montserrat",
    borderRadius: "8px",
  },
  "& .form-label": {
    fontSize: "16px",
    lineHeight: "20px",
    fontWeight: 600,
    fontFamily: "Montserrat",
    margin: "0 0 4px 0",
    color: colors().black,
  },
  "& .d-flex": {
    display: "flex",
    alignItems: "center",
  },
  "&.flex-row": {
    flexDirection: "row",
  },
  "& .error-msg": {
    fontSize: "15px",
    lineHeight: "20px",
    fontFamily: "Montserrat",
    margin: "4px 0 0 0",
    color: colors().error,
  },
});

const FormRow = styled(Box)({
  display: "flex",
  gap: "32px",
  width: "100%",
  margin: "0 0 32px 0",
});

const StyledTextArea = styled("textarea")({
  width: "100%",
  outline: "none",
  borderRadius: "8px",
  padding: "8px",
  border: `1px solid ${colors().columbiaBlue}`,
  fontSize: "16px",
  lineHeight: "24px",
  fontWeight: 400,
  fontFamily: "Montserrat",
  "&:focus": {
    borderColor: colors().primary,
  },
  "&.m-32": {
    margin: "32px 0",
  },
  "&.mt-32": {
    marginTop: "32px",
  },
});

const ButtonWrapper = styled(Box)({
  width: "100%",
  "&.d-flex": {
    display: "flex",
    justifyContent: "end",
  },
  "& .span-qty": {
    width: "40px",
    textAlign: "center",
  },
  "& .qty-button": {
    outline: "none",
    border: "none",
    cursor: "pointer",
    margin: "0 8px",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
});

export default RewashOrderModal;
// Customizable Area End
