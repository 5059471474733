import React, { useState, useEffect } from "react";
import { styled } from "@material-ui/core";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const IconCalender = require("./assets/calender.png");

interface Props {
  testId: string;
  placeholder: string;
  values: [Date | null, Date | null] | null;
  disabled?: boolean;
  adornmentIcon?: string;
  dateFormat?: string;
  maxDate?: Date;
  onChange(range: Array<string | never | any>): void;
}

function CustomDateRangePicker({
  onChange,
  values,
  testId,
  disabled,
  placeholder,
  adornmentIcon,
  dateFormat,
  maxDate
}: Props) {
  let setOpen: Function;
  const [dates, setDates] = useState<Array<string | never | any>>(
    values || [null, null]
  );
  const [start, end] = dates;

  useEffect(() => {
    setDates(values || [null, null]);
  }, [values]);

  return (
    <Container>
      <DatePicker
        // Do not have type in the package
        // @ts-ignore
        ref={(args) => (setOpen = args && args.setOpen)}
        endDate={end}
        startDate={start}
        disabled={disabled}
        selectsRange={true}
        data-test-id={testId}
        dateFormat={dateFormat || 'MMM dd, yyyy'}
        maxDate={maxDate}
        placeholderText={placeholder}
        className='customDateRangePicker'
        onChange={(dates: [Date | null, Date | null]) => {
          setDates(dates);
          onChange(dates);
        }}
      />
      <Image
        width={24}
        height={24}
        src={adornmentIcon || IconCalender}
        onClick={() => setOpen && setOpen(true)}
      />
    </Container>
  );
}

CustomDateRangePicker.defaultProps = {
  testId: "",
  placeholder: "Select Date Range",
  values: [null, null],
  onChange: function() {},
};

const Image = styled("img")({
  right: 16,
  top: "50%",
  position: "absolute",
  transform: "translateY(-50%)",
});

const Container = styled("div")({
  padding: 2,
  borderRadius: 8,
  paddingRight: 14,
  position: "relative",
  display: "inline-block",
  border: "1.5px solid #ECECEC",
  boxShadow: "-1px -1px 3px #ECECEC",
  "& input, & input::placeholder": {
    opacity: 1,
    fontSize: 14,
    border: "none",
    fontWeight: 400,
    color: "#4D4D4D",
    padding: "16.5px 11px",
    fontFamily: "Montserrat",
    "@media only screen and (max-width: 1024px)": {
        fontSize: '12px',
        padding:'14.9px 14px'
    },
  },
  "& .react-datepicker__navigation": {
    top: 14,
  },
  "& .react-datepicker__current-month": {
    paddingTop: 10,
    paddingBottom: 16,
  },
  "& .react-datepicker, & .react-datepicker__current-month": {
    fontSize: 14,
  },
  "&:hover": {
    borderColor: "rgba(0, 0, 0, 0.87)",
  },
  "& .react-datepicker__header": {
    paddingBottom: 0,
    borderBottom: "none",
    backgroundColor: "#fff",
  },
  "& .react-datepicker__day, & .react-datepicker__day-name": {
    margin: 0,
    padding: 9,
    width: "3rem",
    marginBottom: 6,
  },
  "& .react-datepicker__day--in-range, & .react-datepicker__day--in-selecting-range": {
    borderLeft: 0,
    borderRight: 0,
    borderRadius: 0,
    color: "#204B9C",
    border: "1px solid rgba(32, 75, 156, 0.20)",
    backgroundColor: "rgba(32, 75, 156, 0.10) !important",
  },
  "& .react-datepicker__day--range-start, & .react-datepicker__day--selecting-range-start": {
    borderRadius: "20px 0px 0px 20px",
    borderLeft: "1px solid rgba(32, 75, 156, 0.20)",
  },
  "& .react-datepicker__day--range-end, & .react-datepicker__day--selecting-range-end": {
    borderRadius: "0px 20px 20px 0px",
    borderRight: "1px solid rgba(32, 75, 156, 0.20)",
  },
  "& .react-datepicker-popper": {
    zIndex: 3
  }
});

export default CustomDateRangePicker;
