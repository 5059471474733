import React from 'react';
// Customizable Area Start
class CustomInput extends React.Component<any> {
    render() {
        const { type, name, placeholder, value, onChange, onFocus,disabled ,onKeyDown,inputError, height} = this.props;
        const inputbox = {
            border: "0.139vw solid #ECECEC",
            borderRadius: "0.556vw",
            backgroungColor: "#FFFFFF",
            height: height ?`${height}px` : '3.889vw' ,
            width: "100%",
            outline: "none",
            fontFamily: "Montserrat",
            fontWeight: 500,
            fontSize: "16px",
            paddingLeft: "1vw",
        }
        return (
            <input
                type={type}
                name={name}
                placeholder={placeholder}
                style={{...inputbox , ...(inputError?{borderColor:'red'}:{})}}
                value={value}
                onChange={onChange}
                onFocus={onFocus}
                disabled={disabled}
                onKeyDown={onKeyDown}
                data-test-id='custom-input'
            />
        )
    }
}

export default CustomInput;
// Customizable Area End