Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.categoriesContentType = "application/json";
exports.categoriesApiMethodType = "GET";
exports.categoriesEndPoint =
  "/bx_block_itemgrouper/item_groupers/list_categories"; 

exports.subCategoriesContentType = "application/json";
exports.subCategoriesEndPoint =
  "/bx_block_itemgrouper/item_groupers/list_sub_categories";
exports.subCategoriesApiMethodType = "GET";
// Customizable Area End