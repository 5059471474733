import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { IFilter } from "../../../components/src/FilterPopover";
import { ISortingData } from "../../../components/src/SortingTableHeader";
import { IUserContext } from "../../navigationmenu/src/PageContainerController.web";
import {getRegionStoreGroupFiterList, makeApiMessage } from "../../../components/src/common";
import {
  PermissionStatus,
  checkForNewPermissonStatus,
  customPermissionApiKey,
  sortCondition
} from "../../utilities/src/CustomBlockHelpers";
import { PermissionGroupArray } from "../../navigationmenu/src/utils";
interface RegionAttributes {
  id: number;
  branch_name: string;
  name: string;
}

export interface RegionData {
  id: string;
  type: string;
  attributes: RegionAttributes;
}

interface AreaAttributes {
  id: number;
  area_name: string;
}

export interface AreaData {
  id: string;
  type: string;
  attributes: AreaAttributes;
}
interface StoreManagementAttributes {
  id: number;
  store_name: string;
  name_translation: string;
}

export interface StoreManagementData {
  id: string;
  type: string;
  attributes: StoreManagementAttributes;
}

export interface IStore {
  id: string;
  type: string;
  attributes: {
    id:string
    area_id:number;
    regions:{
      data: RegionData[];
  }
  areas: {
    data: AreaData[];
  };
  store_managements: {
      data: StoreManagementData[];
  };
  name:string;
  second_name:string;
  active:boolean
 };
}


// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: {
    navigate: Function;
    getParam: Function;
  };
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  popOverOpenedStore: boolean;
  popOverCloseStore: boolean;
  popOverItemId: string;
  popOverItemStatus: boolean;
  popOverTopStore: number;
  popOverLeftStore: number;
  hoveredButtonStore: string;
  stores: IStore[];
  clickedStore: IStore | undefined;
  page: number;
  pageSize: number;
  totalCounts: number;
  disableModalVisible: boolean;
  deactivatedStore: boolean;
  closedStore: boolean;
  reasonList: {
    id: number;
    label: string;
  }[];
  filterAnchor: { currentTarget: {} } | undefined | HTMLDivElement;
  filters: IFilter[];
  sortingData: ISortingData;
  query: string;
  searchTextStore: string;
  isSuperAdmin?: boolean;
  snackbarOpen: boolean;
  snackbarMessage: string;
  permissionStatus: PermissionStatus;
  suggestionFieldTitle: string;
  // Customizable Area End
}

export interface SS {
  id: number | string;
  // Customizable Area Start
  // Customizable Area End
}

export default class CfStoreGroupManagmentController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  public RequestMessage = {
    StoreCloseRequestId: this.buildRequestMessageForStore("PUT"),
  };
  getStoresCallId: string = "";
  updateStoreGrpCallId: string = "";
  filterSuggestionApiCallId: string = "";

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIResponceDataMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      stores: [],
      page: 1,
      pageSize: 10,
      suggestionFieldTitle: "",
      totalCounts: 0,
      popOverItemId: "",
      popOverItemStatus: false,
      disableModalVisible: false,
      deactivatedStore: false,
      closedStore: false,
      reasonList: [],
      popOverOpenedStore: false,
      popOverCloseStore: false,
      popOverLeftStore: 0,
      popOverTopStore: 0,
      hoveredButtonStore: "",
      filterAnchor: undefined,
      clickedStore: undefined,
      filters: [
        {
          title: "Store Group Name",
          type: "text",
          value: ""
        },
        {
          title: "Region",
          type: "autocompolete",
          value: "",
          options: [],
        },
      ],
      sortingData: {
        store_name: "",
        region_name: "",
        full_ip_phone_number: "",
        store_id: "",
        area_name: "",
        name: ""
      },
      query: "",
      searchTextStore: "",
      snackbarOpen: false,
      snackbarMessage: "",
      permissionStatus: {
        mainPermission: false,
        createPermission: false,
        viewPermission: false,
        editPermission: false,
        deactivatePermission: false,
        activatePermission: false
      }
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId === this.getStoresCallId) {
        if (responseJson) {
          
          const stores = responseJson.data as IStore[];
          this.setState({
            stores: stores,
            page: Number(responseJson.meta.current_page),
            totalCounts: Number(responseJson.meta.total_count),
          });
        }
      } else if (apiRequestCallId === this.filterSuggestionApiCallId) {
        if (responseJson) {
          const updatedFilters = getRegionStoreGroupFiterList(
            responseJson,
            this.state.filters,
            this.state.suggestionFieldTitle
          );
          this.setState({ filters: updatedFilters });
        }
      }
    }
    this.handleResForUpdateStoreGrpStatus(from,message)
    // Customizable Area End
  }

  private buildRequestMessageForStore(method: string): Message {
    const requestMessageForStore = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessageForStore.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.customerTypeEndPoint
    );
    requestMessageForStore.addData(
      getName(MessageEnum.RestAPIRequestMessage),
      configJSON.APIBaseURL
    );
    
    requestMessageForStore.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method.toString()
    );

    return requestMessageForStore;
  }
  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    this.getStores();
  }

  getStores = (page: number = this.state.page) => {
    const { pageSize, searchTextStore, filters, query } = this.state;

    const header = {
      "Content-Type": configJSON.apiContentType,
      token: window.localStorage.getItem(configJSON.token),
    };

    const storeGrpFilter = filters.find(
      (item: IFilter) => item.title === "Store Group Name"
    )?.value;

    const storeGrpRegionFilter = filters.find(
      (item: IFilter) => item.title === "Region"
    )?.value;


    const storeFilterQuery = sortCondition(Boolean(storeGrpFilter),`&filter_by[store_group_name]=${storeGrpFilter}`,"")
    const searchTextQuery = sortCondition(Boolean(searchTextStore),`&filter_by[query]=${searchTextStore}`,"")
    const regionFilterQuery = sortCondition(Boolean(storeGrpRegionFilter),`&filter_by[region_name]=${storeGrpRegionFilter}`,"")

    const storeApiEndPoint = `${configJSON.storeGroupApi}?page_no=${page}&per_page=${pageSize}${searchTextQuery}${storeFilterQuery}${regionFilterQuery}${query}`;

    const getDataMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getStoresCallId = getDataMsg.messageId;

    getDataMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      storeApiEndPoint
    );
    getDataMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    getDataMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
    runEngine.sendMessage(getDataMsg.id, getDataMsg);
  };


  sortingProps = {
    onChange: (sortingData: ISortingData) => this.setState({ sortingData }),
    width: "18%",
    onQueryChange: (query: string) => this.handleQueryChange(query),
  };

  handleAddStore = () => {
    this.props.navigation.navigate("StoreGroupAdd");
  };

  handlePageChange = (page: number) => {
    this.getStores(page);
    this.setState({ page });
  };

  handleQueryChange = (query: string) => {
    this.setState({ query }, () => this.getStores());
  };

  handleViewStore = (storeId: string) => {
    this.setState({ popOverOpenedStore: false });
    this.props.navigation.navigate("StoreGroupView",{
      id: storeId,
    });
  };

  handleEditStore = (storeId: string) => {
    this.setState({ popOverOpenedStore: false });
    this.props.navigation.navigate("StoreGroupEdit",{
      id: storeId,
    });
  };

  handleDeactivate = (clickedStoreId: string) => {
    console.log(clickedStoreId,"clickedStoreId")
    this.setState({
      disableModalVisible: true,
      clickedStore: this.state.stores.find(
        (store) => store.id == clickedStoreId
      ),
    });
  };

  handleDeactivateClose = () => {
    this.setState({ disableModalVisible: false, popOverOpenedStore: false });
  };


  changeActivateStore = () => {
    this.setState({ popOverOpenedStore: false, disableModalVisible: false });
    this.updateStore(
      this.state.popOverItemId,
      this.state.popOverItemStatus
    );
  };



  handleSnackbarClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }

    this.setState({ snackbarOpen: false });
  };

  handleUserChange = (userContext: IUserContext) => {
    const apiKey = customPermissionApiKey.storeManagementPermission;
    const userData = userContext.user?.attributes.permission_groups;
    const value = checkForNewPermissonStatus(apiKey, userData as Array<PermissionGroupArray>);
    this.setState({
      permissionStatus: value
    })
  };

  updateStore = (storeId: string, isActive: boolean) => {
    const header = {
      "Content-Type": configJSON.searchApiContentType,
      token: window.localStorage.getItem(configJSON.token),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.updateStoreGrpCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.storeGrpEndpoint}/${storeId}/${
        isActive ? "deactivate" : "activate"
      }`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleResForUpdateStoreGrpStatus = async (from: string, message: Message) => {
    if (this.updateStoreGrpCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.getStores();
      if (responseJson.errors) {
        this.setState({ snackbarOpen: true, popOverCloseStore: false, snackbarMessage: responseJson.errors })
      }
      if (responseJson.data && this.state.popOverItemStatus) {
        this.setState({ deactivatedStore: true });
      }
    }
  }

  handleSearchStore = (value: string) => {
    this.setState(
      {
        searchTextStore: value,
        page: 1
      },
      this.getStores
    );
  };

  handleFilterChangeStore = (filters: IFilter[]) => {
    this.setState(
      {
        filters,
        page: 1,
      },
      this.getStores
    );
  };

  handleStoreGroupAutoComplete = (title: string, value: string) => {
    this.state.filters.forEach((item: IFilter) => {
      if (item.title === title) item.value = value;
      else item.value = "";
      return item;
    });

    let ApiUrl;
     if (title === "Region") {
      ApiUrl = configJSON.filterRegionEndpoing;
    }

    let requestMessage = makeApiMessage({
      url: ApiUrl + value,
      method: "GET",
    });
    this.filterSuggestionApiCallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  // Customizable Area End
}
