import React from "react";

import {
  Box,
  Button,
  IconButton,
  // Customizable Area Start
  Paper,
  styled,
  TableRow,
  TableHead,
  TableContainer,
  TablePagination,
  TableCell,
  TableBody,
  Table,
  Snackbar
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import PageContainer from "../../navigationmenu/src/PageContainer.web"
import SortingTableHeader from "../../../components/src/SortingTableHeader";
import SortingTableCell from "../../../components/src/SortingTableCell";
import FilterIcon from "@material-ui/icons/FilterList";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Alert from "@material-ui/lab/Alert";
import { renderBaseOnConditions,sortCondition } from "../../utilities/src/CustomBlockHelpers";
import { colors } from "../../../blocks/utilities/src/Colors";
import ConfirmationModal from "../../../components/src/customComponents/ConfirmationModal.web";
import FilterPopover, { IFilter } from "../../../components/src/FilterPopover";



import CfStoreGroupManagmentController, {
  Props,
  configJSON,
  RegionData,
  StoreManagementData,
  AreaData
} from "./CfStoreGroupManagmentController";
import { StoreListPopover, StoreListWrapper, StoreListSubHeader } from "./CfStoreManagment.web";


// Customizable Area End

export default class CfStoreGroupManagment extends CfStoreGroupManagmentController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderTableHeader() {
    const sortingData = this.state.sortingData;

    return (
      <TableHead>
        <TableRow
          style={{ background: colors().cyancobaltblue, color: colors().background }}
        >
          <SortingTableHeader
            sortingData={sortingData}
            title='Group Name'
            sortingKey='name'
            {...this.sortingProps}
            type='left'
          />
          <SortingTableHeader
            sortingData={sortingData}
            title='Region'
            sortingKey='region_name'
            {...this.sortingProps}
          />
          <SortingTableHeader
            sortingData={sortingData}
            title='Area'
            sortingKey='area_name'
            {...this.sortingProps}
          />
          <SortingTableHeader
            sortingData={sortingData}
            title='Stores'
            sortingKey='store_name'
            {...this.sortingProps}
          />
          <SortingTableHeader
            sortingData={sortingData}
            title='Status'
            sortingKey=''
            {...this.sortingProps}
            sortingDisabled
          />
          <SortingTableHeader
            sortingData={sortingData}
            title=''
            sortingKey=''
            {...this.sortingProps}
            type='action'
            sortingDisabled
          />
        </TableRow>
      </TableHead>
    )
  }
  // Customizable Area End

  render() {
    const { permissionStatus, disableModalVisible } = this.state;
    const filteredStoreData = this.state.stores;
    const sortingData = this.state.sortingData;
    const emptyRowData = this.state.pageSize - filteredStoreData.length;
    return (
      // Customizable Area Start
      <StoreListWrapper>
        <PageContainer
          onSearchText={(value) => this.handleSearchStore(value)}
          navigation={this.props.navigation}
          onUserChange={(userContext) => this.handleUserChange(userContext)}
        >
          <Box>
          <Box className="contentWrapperStore">
            <div className="headerWrapperStore">
              <div className="pageHeaderStore">Store Groups</div>
              <StoreListSubHeader>
                <div
                  data-test-id="filterBtn"
                  onClick={(event) => {
                    this.setState({
                      filterAnchor: event.currentTarget,
                    });
                  }}
                  className="filterButtonStore"
                >
                  <FilterIcon />
                </div>

                <FilterPopover
                  onClose={() => {
                    this.setState({ filterAnchor: undefined });
                  }}
                  anchor={this.state.filterAnchor as HTMLDivElement | undefined}
                  onClearFilter={() => {
                    let updated = this.state.filters.map((item: IFilter) => {
                      item.value = "";
                      item.options = [];
                      return item;
                    });
                    this.setState({ filters: updated });
                  }}
                  onFilterChange={this.handleFilterChangeStore}
                  filters={this.state.filters}
                  onAutoCompleteChange={(title: string, value: string) => {
                    this.setState({ suggestionFieldTitle: title });
                    this.handleStoreGroupAutoComplete(title, value);
                  }}
                />
                
                {
                sortCondition(
                  this.state.permissionStatus.createPermission,
                  <Button
                  data-test-id='addStore'
                  onClick={this.handleAddStore}
                  style={{marginLeft:"12px"}}
                  className="addButtonStore"
                >
                  Add Store Group
                </Button>,
                  <></>
                )
              }
              </StoreListSubHeader>
            </div>
            <TableContainer component={Paper}>
                <Table style={webStyle.tableArea} aria-label='customized table'>
                  {this.renderTableHeader()}
                  {filteredStoreData?.length > 0
                   && (
                  <TableBody>
                    {filteredStoreData.map((rowData, index) => (
                      <TableRow key={index + "TableRow"}>
                        <SortingTableCell
                          width='18vw'
                          type='left'
                          content={rowData.attributes.name}
                        />
                        <SortingTableCell
                          width='18vw'
                          type='middle'
                          content={rowData.attributes?.regions?.data?.map((item:RegionData) => item.attributes.name).join(',')}
                        />
                        <SortingTableCell
                          width='18vw'
                          type='middle'
                          content={rowData.attributes?.areas?.data?.map((item:AreaData) => item.attributes.area_name).join(',')}
                        />
                        <SortingTableCell
                          width='18vw'
                          type='middle'
                          content={rowData.attributes?.store_managements?.data?.map((item:StoreManagementData) => item.attributes.store_name).join(',')}
                        />
                        <SortingTableCell
                          width='18vw'
                          type='right'
                          content={sortCondition(rowData.attributes.active,"Active","Deactivated") as React.ReactNode}
                        />
                        <SortingTableCell
                          width='18vw'
                          type='action'
                          data-test-id='moreCell'
                          content={
                            <IconButton
                              data-test-id={`moreButton_${rowData.attributes.id}`}
                              id={"IconButton" + index}
                              onClick={(event) => {
                                const position = event.currentTarget.getBoundingClientRect();
                                this.setState({
                                  popOverItemId: rowData.attributes.id,
                                  popOverItemStatus: rowData.attributes.active,
                                  popOverOpenedStore: true,
                                  popOverLeftStore:
                                    position.left + window.scrollX,
                                  popOverTopStore:
                                    position.top + window.scrollY,
                                });
                              }}
                            >
                              <MoreVertIcon />
                            </IconButton>
                          }
                        />
                      </TableRow>
                    ))}
                    {emptyRowData > 0 && (
                      <TableRow
                        style={{
                          height: 53 * emptyRowData,
                        }}
                      >
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>
                )}
                
                </Table>
                {filteredStoreData?.length === 0 && (
                <NoRecordBox className="NoRecord">{configJSON.noData}</NoRecordBox>
                )}
              </TableContainer>

            {filteredStoreData?.length > 0    &&
             (
              <TablePagination
                component='div'
                data-test-id='pagination'
                rowsPerPageOptions={[10]}
                page={this.state.page - 1}
                count={this.state.totalCounts}
                rowsPerPage={this.state.pageSize}
                onPageChange={(demo, page) => this.handlePageChange(page + 1)}
              />
            )}
          </Box>
          </Box>

          <StoreListPopover
            data-test-id={configJSON.popoverTest}
            anchorReference='anchorPosition'
            open={this.state.popOverOpenedStore}
            onClose={() => this.setState({ popOverOpenedStore: false })}
            anchorPosition={{
              top: this.state.popOverTopStore + 40,
              left: this.state.popOverLeftStore - 80,
            }}
            anchorOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
          >
            {
                renderBaseOnConditions(
                  (permissionStatus.viewPermission ||
                  permissionStatus.editPermission ||
                  permissionStatus.deactivatePermission || 
                permissionStatus.activatePermission as boolean),
                  <div className='popoverContainerStore'>
                    {
                      renderBaseOnConditions(
                        this.state.permissionStatus.viewPermission,
                        <Box
                          data-test-id={`viewButton_${this.state.popOverItemId}`}
                          onClick={() => this.handleViewStore(this.state.popOverItemId)}
                          className="popoverButtonStore"
                        >
                        View
                        </Box>,
                        <></>
                      )
                    }
                    {
                      renderBaseOnConditions(this.state.permissionStatus.editPermission,
                        <Box
                          data-test-id={`editButton_${this.state.popOverItemId}`}
                          onClick={() => this.handleEditStore(this.state.popOverItemId)}
                          className="popoverButtonStore"
                        >
                        Edit
                      </Box>,
                        <></>
                      )
                    }
                     {
                      renderBaseOnConditions(
                        permissionStatus.deactivatePermission && this.state.popOverItemStatus === true,
                        <>
                        <Box
                        data-test-id='deactivateButton'
                        className="popoverButtonStore"
                        onClick={() => {
                            this.handleDeactivate(this.state.popOverItemId);
                        }}
                        >
                          Deactivate
                        </Box>
                       </>
                        ,
                        <>
                        {!permissionStatus.viewPermission &&
                          !permissionStatus.editPermission &&
                          permissionStatus.activatePermission &&
                          this.state.popOverItemStatus === true
                        && !permissionStatus.deactivatePermission && <div className="popoverContainer1">
                        <div className="noPermissions">
                          No Permissions
                        </div>
                      </div>}
                        </>
                      )
                    }
                    {
                      renderBaseOnConditions(
                        permissionStatus.activatePermission as boolean && this.state.popOverItemStatus !== true,
                        <>
                        <Box
                        data-test-id='activateButton'
                        className="popoverButtonStore"
                        onClick={() => this.changeActivateStore()}
                        >
                          Activate
                        </Box>
                        </>,
                        <>{!permissionStatus.viewPermission &&
                          !permissionStatus.editPermission &&
                          !permissionStatus.activatePermission &&
                          this.state.popOverItemStatus !== true
                        && permissionStatus.deactivatePermission && <div className="popoverContainer">
                        <div className="noPermissions">
                          No Permissions
                        </div>
                      </div>}</>
                      )
                    }
                  </div>,
                  <div className="popoverContainer">
                    <div className="noPermissions">
                      No Permissions
                    </div>
                  </div>
                )
            }
          </StoreListPopover>
        </PageContainer>

        <ConfirmationModal
          open={disableModalVisible}
          handleClose={this.handleDeactivateClose}
          handleConfirm={this.changeActivateStore}
          title='Confirm Deactivate'
          message={{
            id: this.state.clickedStore?.id,
            title: this.state.clickedStore?.attributes.name,
          }}
          dynamic={true}
          confirmText='Deactivate'
        />
       
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          open={this.state.snackbarOpen}
          autoHideDuration={4000}
          onClose={this.handleSnackbarClose}
        >
          <Alert severity='error'>{this.state.snackbarMessage}</Alert>
        </Snackbar>
      </StoreListWrapper>
      // Customizable Area End
    );
  }
}

// Customizable Area Start


const webStyle = {
  tableArea: {
    minWidth: 700,
  }
};

const NoRecordBox = styled(Box)({
  fontSize: "16",
  textAlign: "center",
  fontWeight: 600,
  width: "100%",
  padding: "20px"
})

// Customizable Area End
