import React from "react";
// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import { IBlock } from "../../../framework/src/IBlock";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { apiCall } from "../../../components/src/common";
import { HomeCleanProductData } from "./HomeCleaningProductController.web";
import { IUserContext } from "../../../blocks/navigationmenu/src/PageContainerController.web";
import {
  PermissionStatus,
  checkForNewPermissonStatus,
  customPermissionApiKey
} from "../../../blocks/utilities/src/CustomBlockHelpers";
import { PermissionGroupArray } from "../../../blocks/navigationmenu/src/utils";
export const configJSON = require("./config");

const ApiUrls = configJSON.ApiUrls;
// Customizable Area End

// Customizable Area Start

export interface ProductType {
  id: number;
  type: string;
  price: number | undefined;
  _destroy: boolean;
  isNewAdded?: boolean;
}
export interface ProductAddIntialValues {
  temp_unavailable: boolean;
  product_name: string;
  product_second_name: string;
  measurement_type: string[];
  cleaning_type: string[];
  product_type: ProductType[];
  notes: string;
  cleaning_expected_duration: undefined | string;
  image_id: string | number;
}

export interface IDOption {
  value: string;
  option: string;
};

interface ImageList {
  id: string;
  type: string;
  attributes: {
    id: 266;
    image: string;
    name: string;
    second_name: string;
    image_type: string;
    name_translation: string;
  };
}

interface ImageOption {
  id: number;
  option: string;
  image: string;
}

interface MetaData {
  total_pages: number;
  current_page: number
}

const addProductIntialVal = {
  temp_unavailable: false,
  product_name: "",
  product_second_name: "",
  measurement_type: [],
  cleaning_type : [],
  product_type: [
    {
      id: new Date().getTime(),
      type: "",
      price: undefined,
      _destroy: false,
    },
  ],
  notes: "",
  cleaning_expected_duration: undefined,
  image_id: "",
};

const measurementOptions = [
  { value: "Quantity", option: "Quantity" },
  { value: "SQM", option: "SQM" },
  { value: "Weight", option: "Weight" },
];

const avabilityOptions = [
  { value: "Plant", option: "Plant" },
  { value: "Home Cleaning", option: "Home Cleaning" },
];
// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation: any;
  id: string;
  classes: any;
  // Customizable Area End
}

export interface S {
  // Customizable Area Start
  addHomeCleaningProductIntialVal: ProductAddIntialValues;
  measurementOptions: IDOption[];
  avabilityOptions: IDOption[];
  gallaryImageOptions: ImageOption[];
  createSuccessSnackbarOpen: boolean;
  isEdit: boolean;
  isViewProductPage: boolean;
  productId: number | undefined;
  viewProductResponse: HomeCleanProductData;
  permissionStatus: PermissionStatus;
  selectedImage: { id: string | number, option: string, image: string };
  pageNumImage: number;
  totalPageImage: number
  // Customizable Area End
}

export interface SS {}

export default class AddHomeCleaningProductAddController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getImagesListApiCallId: string = "";
  createHomeCleanProductApiCallId: string = "";
  singleProductDetailsApiCallId: string = "";
  editHomeCleanProductApiCallId: string = "";

  state: S = {
    addHomeCleaningProductIntialVal: addProductIntialVal,
    measurementOptions: measurementOptions,
    avabilityOptions: avabilityOptions,
    gallaryImageOptions: [],
    createSuccessSnackbarOpen: false,
    isEdit: false,
    isViewProductPage: false,
    productId: undefined,
    viewProductResponse: {
      id: "",
      type: "",
      attributes: {
        id: 0,
        product_name: "",
        product_second_name: "",
        cleaning_process_notes: "",
        cleaning_expected_duration: "",
        created_at: "",
        updated_at: "",
        image_id: 0,
        active: false,
        home_cleaning_catalogue_types: [],
        image: null,
        measurement_type: [],
        cleaning_type: [],
      },
    },
    permissionStatus: {
      mainPermission: false,
      createPermission: false,
      viewPermission: false,
      editPermission: false,
      deactivatePermission: false
    },
    selectedImage:{} as { id: string | number, option: string, image: string },
    pageNumImage: 1,
    totalPageImage:1,
  };
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const successResponse = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    const requestId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    switch (requestId) {
      case this.getImagesListApiCallId:
        this.handleImageListResp(successResponse.data, successResponse.meta);
        break;
      case this.createHomeCleanProductApiCallId:
        this.handleCreateHomeCleanProductResp(successResponse.data);
        break;
      case this.singleProductDetailsApiCallId:
        this.handleSingleProductApiDetailsResp(successResponse.data);
        break;
      case this.editHomeCleanProductApiCallId:
        this.handleEditProductApiResp(successResponse.data);
        break;
    }
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    this.getImagesList();

    this.setState({
      addHomeCleaningProductIntialVal: {
        ...this.state.addHomeCleaningProductIntialVal,
        product_type: [
          {
            id: new Date().getTime(),
            type: "",
            price: undefined,
            _destroy: false,
          },
        ],
      },
    });

    const path = window.location.pathname;
    let productId = this.props.navigation.getParam("productId");
    this.processPath(path, productId);
    // Customizable Area End
  }

  // Customizable Area Start

  processPath = (path: string, productId: number) => {
        if (path === `/CreateHomeCleaningProduct-Edit/${productId}`) {
      this.setState({ isEdit: true }, () => this.getImagesList());
      this.setState({ productId: productId }, () => this.getProductDetais());
    } else if (path === `/CreateHomeCleaningProduct-View/${productId}`) {
      this.setState({ isViewProductPage: true });
      this.setState({ productId: productId }, () => this.getProductDetais());
    }
  };

  handleFormSubmit = (values: ProductAddIntialValues) => {
    if (this.state.isEdit) {
      this.editHomeCleaningProduct(values);
    } else {
      this.addHomeCleaningProduct(values);
    }
  };

  getCreateProductBody = (values: ProductAddIntialValues) => {
    const catalogue_types_attributes = values.product_type.map(
      (item: ProductType) => {
        return {
          name: item.type,
          price: Number(item.price),
        };
      }
    );
    return {
      data: {
        attributes: {
          product_name: values.product_name,
          product_second_name: values.product_second_name,
          cleaning_process_notes: values.notes,
          cleaning_expected_duration: values.cleaning_expected_duration,
          image_id: this.state.selectedImage.id,
          measurement_type: values.measurement_type,
          cleaning_type: values.cleaning_type,
          active: !values.temp_unavailable,
          home_cleaning_catalogue_types_attributes: catalogue_types_attributes,
        },
      },
    };
  };

  addHomeCleaningProduct(values: ProductAddIntialValues) {
    let url = ApiUrls.getHomeCleanProductApiEndPoint;
    let headers = {
      token: localStorage.getItem(configJSON.token),
      "Content-Type": configJSON.productApiContentType,
    };
    let body = this.getCreateProductBody(values);

    const getAccount = apiCall({
      httpBody: body,
      header: headers,
      url: url,
      httpMethod: configJSON.apiMethodTypePost,
    });

    this.createHomeCleanProductApiCallId = getAccount.messageId;
    runEngine.sendMessage(getAccount.id, getAccount);
  }

  getEditProductBody = (values: ProductAddIntialValues) => {
    const catalogue_types_attributes = values.product_type.map(
      (item: ProductType) => {
        if (item.isNewAdded) {
          return {
            name: item.type,
            price: Number(item.price),
            _destroy: item._destroy,
          };
        } else {
          return {
            id: item.id,
            name: item.type,
            price: Number(item.price),
            _destroy: item._destroy,
          };
        }
      }
    );
    return {
      data: {
        attributes: {
          product_name: values.product_name,
          product_second_name: values.product_second_name,
          cleaning_process_notes: values.notes,
          cleaning_expected_duration: values.cleaning_expected_duration,
          image_id: this.state.selectedImage.id,
          measurement_type: values.measurement_type,
          cleaning_type: values.cleaning_type,
          active: values.temp_unavailable,
          home_cleaning_catalogue_types_attributes: catalogue_types_attributes,
        },
      },
    };
  };

  editHomeCleaningProduct(values: ProductAddIntialValues) {
    const { productId } = this.state;
    let url = ApiUrls.getHomeCleanProductApiEndPoint + `/${productId}`;
    let headers = {
      token: localStorage.getItem(configJSON.token),
      "Content-Type": configJSON.productApiContentType,
    };

    let body = this.getEditProductBody(values);

    const getAccount = apiCall({
      httpBody: body,
      header: headers,
      url: url,
      httpMethod: configJSON.apiMethodTypePut,
    });

    this.editHomeCleanProductApiCallId = getAccount.messageId;
    runEngine.sendMessage(getAccount.id, getAccount);
  }

  getImagesList() {
    const lastEndPoint = this.state.isEdit ? "" : `?page_no=${this.state.pageNumImage}`
    let url = ApiUrls.getImagesListApiEndPoint + lastEndPoint;
    let headers = {
      token: localStorage.getItem(configJSON.token),
    };
    const getAccount = apiCall({
      httpBody: {},
      header: headers,
      url: url,
      httpMethod: configJSON.apiMethodTypeGet,
    });

    this.getImagesListApiCallId = getAccount.messageId;
    runEngine.sendMessage(getAccount.id, getAccount);
  }

  getProductDetais() {
    const { productId } = this.state;
    let url = ApiUrls.getHomeCleanProductApiEndPoint + `/${productId}`;
    let headers = {
      token: localStorage.getItem(configJSON.token),
      "Content-Type": configJSON.productApiContentType,
    };
    const getAccount = apiCall({
      httpBody: {},
      header: headers,
      url: url,
      httpMethod: configJSON.apiMethodTypeGet,
    });

    this.singleProductDetailsApiCallId = getAccount.messageId;
    runEngine.sendMessage(getAccount.id, getAccount);
  }

  handleImageListResp = (successResponse: ImageList[], metaData: MetaData) => {
    const filterImgResp = successResponse.map((item: ImageList) => {
      return {
        id: item.attributes.id,
        option: item.attributes.name,
        image: item.attributes.image,
      };
    });
    this.setState({
      gallaryImageOptions: filterImgResp,
      totalPageImage: metaData?.total_pages
    });
  };

  handleCreateHomeCleanProductResp = (
    successResponse: HomeCleanProductData
  ) => {
    if (Object.entries(successResponse).length !== 0) {
      this.setState({
        createSuccessSnackbarOpen: true,
      });
      setTimeout(() => {
        this.props.navigation.navigate("HomeCleaningProductList");
      }, 1000);
    }
  };

  handleSingleProductApiDetailsResp = (responseJson: HomeCleanProductData) => {
    if (responseJson) {
      if (this.state.isEdit) {
        const valObj = {
          temp_unavailable: responseJson.attributes.active,
          product_name: responseJson.attributes.product_name,
          product_second_name: responseJson.attributes.product_second_name,
          measurement_type: responseJson.attributes.measurement_type,
          product_type: responseJson.attributes.home_cleaning_catalogue_types.map(
            (item) => {
              return {
                id: item.data.attributes.id,
                type: item.data.attributes.name,
                price: Number(item.data.attributes.price),
                _destroy: false,
              };
            }
          ),
          notes: responseJson.attributes.cleaning_process_notes,
          cleaning_expected_duration:
            responseJson.attributes.cleaning_expected_duration,
          image_id: responseJson.attributes.image_id,
          cleaning_type: responseJson.attributes.cleaning_type,
        };
        this.setState({
          addHomeCleaningProductIntialVal: valObj,
                  });
      } else {
        this.setState({
          viewProductResponse: responseJson,
        });
      }
    }
  };

  handleEditProductApiResp = (responseJson: HomeCleanProductData) => {
    if (responseJson) {
      this.setState({
        createSuccessSnackbarOpen: true,
      });
      setTimeout(() => {
        this.props.navigation.navigate("HomeCleaningProductList");
      }, 1000);
    }
  };

  handleNavigateEditHomeCleanProduct = (id: number) => {
    this.props.navigation.navigate("EditHomeCleaningProduct", {
      productId: id,
    });
  };

  addMoreTypeField = (
    setFieldValue: Function,
    values: ProductAddIntialValues
  ) => {
    let optionItem = {
      id: new Date().getTime(),
      type: "",
      price: undefined,
      _destroy: false,
      isNewAdded: true,
    };
    let newArray = values.product_type;
    newArray.push(optionItem);
    setFieldValue("product_type", newArray);
  };

  deleteProductType = (
    setFieldValue: Function,
    values: ProductAddIntialValues,
    id: number
  ) => {
    let newArray = values.product_type;
    let filterOption = newArray.map((item) => {
      if (item.id === id) {
        return {
          ...item,
          _destroy: true,
        };
      } else {
        return item;
      }
    });
    let newFilterOption = newArray.filter((item) => item.id !== id);
    let options = this.state.isEdit ? filterOption : newFilterOption;
    setFieldValue("product_type", options);
  };

  handleBackToListingPage = () => {
    this.props.navigation.navigate("HomeCleaningProductList");
    this.setState({
      addHomeCleaningProductIntialVal: addProductIntialVal,
    });
  };

  handleSnackbarClose = () => {
    this.setState({
      createSuccessSnackbarOpen: false,
    });
  };

  handleUserChange = (userContext: IUserContext) => {
    const apiKey = customPermissionApiKey.homeCleanProductPermission;
    const userData = userContext.user?.attributes.permission_groups;
    const value = checkForNewPermissonStatus(apiKey, userData as Array<PermissionGroupArray>);
    this.setState({
      permissionStatus: value
    })
  };
  handleSelectImage = (value:{ id: string | number, option: string, image: string }) => {
    this.setState({
      selectedImage:value,
    })
  }
  handleLoadingImageScroll = (event: React.SyntheticEvent) => {
    const listboxNode = event.currentTarget;
    const position = listboxNode.scrollTop + listboxNode.clientHeight;
    if (listboxNode.scrollHeight - position <= 1.30 && this.state.pageNumImage <= this.state.totalPageImage) {
      this.setState({ pageNumImage: this.state.pageNumImage + 1 }, () => {
        this.getImagesList()
      })
    }
  };
  // Customizable Area End
}
