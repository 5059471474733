/** @format */

import React from "react";
// Customizable Area Start
import { Snackbar,Box,styled } from "@material-ui/core";
import EditPreferenceController, { Props } from "./EditPreferenceController.web"
import "./AddPreference.css"
import PageContainer from "../../navigationmenu/src/PageContainer.web"
import Loader from "../../../components/src/Loader.web";
import Alert, { Color } from '@material-ui/lab/Alert';
import { CommonIcomSearchComponent } from "../../../components/src/customComponents/CommonIcomSearchComponent";
import { PrefHeading } from "./PreferenceList.web";
import { colors } from "../../../blocks/utilities/src/Colors";
export const configJSON = require("./config");

const StyledMninDivWrapper = styled('div')({
    marginTop: 0,
    padding: 0,
    marginBottom: 0,
    "& .editButton": {
        "@media only screen and (max-width: 1024px)": {
            fontSize: "16px !important",
            width: "128px !important",
            height: "44px !important",
            padding: "10px !important"
        },
        fontWeight: 600,
        fontSize: "16px !important",
        lineHeight: "24px",
        color: colors().white,
        background: colors().cyancobaltblue,
        height: "56px",
        width: '184px !important',
        marginLeft: "20px",
        borderRadius: "8px",
        textTransform: "unset",
    },
});

const StyledTextField = styled('input')({
    "@media only screen and (max-width: 1024px)": {
        fontSize: '12px',
        height: "44px"
    },
    width: "85%",
    outline: "none",
    borderRadius: "8px",
    marginTop: "7px",
    padding: "16px",
    textTransform: "capitalize",
    border: "1px solid #ececec",
});
// Customizable Area End

// Customizable Area Start
class EditPreference extends EditPreferenceController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    render() {
        const { openPopup, dropDownOptions, severity,preference_name, preference_second_title, isErrorMessage } = this.state;
        const preferenceNameInputClass = isErrorMessage && preference_name === '' && 'error';

        return (
            <PageContainer navigation={this.props.navigation}>
                <Loader loading={this.state.isLoading} />
                <Box>
                    <StyledMninDivWrapper className="preference-container">
                        <Snackbar data-test-id="snackbar-open" anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }} open={openPopup} autoHideDuration={1000} onClose={this.handleCloseSnackbar} >
                            <Alert data-test-id="snackbar-alert" severity={severity as Color} >
                                {this.state.message}
                            </Alert>
                        </Snackbar>
                        <PrefHeading className="header-preference">{configJSON.editPreference}</PrefHeading>
                        <Box className="add-preference-container">
                            <Box className="left-preference">
                                <Box >
                                    <h4 className="heading-name">Preference Name</h4>
                                    <StyledTextField 
                                    data-test-id="preference-name" 
                                    value={preference_name} 
                                    onChange={this.handleNameChange} 
                                    type="text" 
                                    className={`input-field-text ${preferenceNameInputClass}`} 
                                    placeholder="Enter Name" 
                                    />
                                    {isErrorMessage && preference_name === '' && <p className="error-message">Please enter your first name.</p>}
                                </Box>
                                <Box className="custom-dropdown-menu">
                                    <h4 className="heading-name">Icon</h4>
                                    <CommonIcomSearchComponent
                                            value={[]}
                                            optionList={this.getIconList(dropDownOptions)}
                                            debouncedFunction={() => { }}
                                            handleScroll={this.handleGetImageOnScroll}
                                            handleSelectOptions={(value: { id: string | number, option: string, image: string }) => this.handleSelectImage(value)}
                                            selectedItem={this.state.selectedImage}
                                        />
                                </Box>
                            </Box>
                            <Box className="right-preference">
                                <Box>
                                    <h4 className="heading-name">Preference Arabic Name</h4>
                                    <input dir="rtl" data-test-id="input-second-title" value={preference_second_title} onChange={this.handleSecondNameChange} type="text" className={`input-field-text`} placeholder="Enter Arabic Name" />
                                </Box>
                            </Box>
                        </Box>
                    </StyledMninDivWrapper>
                    <Box className="group-button">
                        <button data-test-id="cancel" onClick={this.handleCancel} className="cancel">Cancel</button>
                        <button data-test-id="addButton" onClick={this.handleSave} className="add">Save</button>
                    </Box>
                </Box>
            </PageContainer>
        );
    }
    // Customizable Area End
}
export default EditPreference 
    ;

// Customizable Area End
