// Customizable Area Start
import React, { Component, ReactNode } from "react";
import Dialog from "@material-ui/core/Dialog";
import { styled } from "@material-ui/core";
const shoppingCartImage = require("./assets/add_shopping_cart.png");
// Customizable Area End

// Customizable Area Start
interface SameProductDifferentServiceModalProps {
  open: boolean;
  classes?: any;
  handleClose: () => void;
  handleConfirmClick: () => void;
}

const webStyle = {
  modalMainContainer: {
    padding: "48px 73px",
    width: 574,
    display: "flex",
    flexDirection: "column" as const,
    alignItems: "center",
  },
  cancelButton: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
		color: "#64748b",
    background: "#f1f5f9",
    fontSize: 16,
    fontWeight: 700,
    marginRight: 20,
    padding: 16,
    borderRadius: 8,
    width: 128,
    height: 56
	},
	confirmButton: {
    color: "#ffffff",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: 16,
    fontWeight: 700,
    background: "#204b9c",
    padding: 16,
    height: 56,
    borderRadius: 8,
    width: 128,
	},
  modalTitle: {
    color: "#1A1A1A",
    fontSize: 24,
    fontWeight: 600,
    marginTop: 64,
  },
  modalText: {
    color: "#4D4D4D",
    fontSize: 20,
    fontWeight: 400,
    marginTop: 16,
    textAlign: "center" as const,
    lineHeight: "30px",
  },
  btnsContainer: {
    marginTop: 32,
    display: "flex",
  }
};

const StyledDialog = styled(Dialog)({
  "& .MuiPaper-rounded": {
    borderRadius: 24,
  },
});
// Customizable Area End

// Customizable Area Start
export class SameProductDifferentServiceModal extends Component<SameProductDifferentServiceModalProps> {
  render(): ReactNode {
    const { open, handleClose, handleConfirmClick } = this.props;

    return (
      <StyledDialog maxWidth='xl' open={open} onClose={handleClose}>
        <div style={webStyle.modalMainContainer}>
          <div>
            <img src={shoppingCartImage} />
          </div>
          <div style={webStyle.modalTitle}>Add Product</div>
          <div style={webStyle.modalText}>
            Are you sure you want to add same product with different service?
          </div>
          <div style={webStyle.btnsContainer}>
            <div 
              data-test-id={"cancel-button"}
              onClick={handleClose} 
              style={webStyle.cancelButton}
            >
              Cancel
            </div>
            <div
              data-test-id={"confirm-button"}
              style={webStyle.confirmButton}
              onClick={handleConfirmClick}
            >
              Confirm
            </div>
          </div>
        </div>
      </StyledDialog>
    );
  }
}

export default SameProductDifferentServiceModal;
// Customizable Area End
