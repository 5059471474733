
import React,{ useState, useRef, useEffect } from 'react'
import {Button,Box, Typography, Divider} from '@material-ui/core';


// import Popper from '@material-ui/core/Popper';
import Popover from '@material-ui/core/Popover';
import { makeStyles, Theme, createStyles, createTheme, ThemeProvider} from '@material-ui/core/styles';
import CustomButton from './customButton.web';

const filterIcon= require('./filterSvg.svg');
const filterFill =  require("./filterFill.svg")



const useStyles = makeStyles((theme:Theme)=>createStyles({
        paper: {
          border: '1px solid',
          padding: theme.spacing(1),
          backgroundColor: theme.palette.background.paper,
        },
       boxRoot: {
            padding: 0,
          },
          filterButton: {
            height:'56px', width:'56px',
            border: '1px solid rgba(236, 236, 236, 1)',
            "@media only screen and (max-width: 1024px)": {
              fontSize: "16px",
              width: "44px",
              height: "44px",
              minWidth:"44px"
            },  
          },
          popperFirstChild:{
             width:'483px', borderRadius:'8px', 
             boxShadow:'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
             display:'flex',
             flexDirection:'column',
            backgroundColor:'rgba(255, 255, 255, 1)'

          },
          popUpContainer:{
            display:'flex',
            flexGrow:2, 
            flexDirection:'column', 
            gap:'1rem', 
            textTransform:'none', 
            marginBottom:'1rem'
          },
          popUpFirstChild:{
          
            display:'flex', flexDirection:'column', gap:'1rem', margin:'1rem 2rem 0'
        },
        filterText:{
            fontWeight:600, fontSize:'16px', fontFamily:'Montserrat',color:'rgba(32, 75, 156, 1)'
        },
        popUpButtonsContainer:{
            display:'flex', flexGrow:1, gap:'1rem', justifyContent:'center', marginBottom:'10px'

        },
        cancelBtn:{
            width:'126px', 
            height:'44px', 
            borderRadius:'8px', 
            backgroundColor:'#fff', 
            color:'rgba(220, 38, 38, 1)',
            textTransform:'none',
            border:'1px solid rgba(220, 38, 38, 1)',
            '&:hover':{
              backgroundColor:'#fff', 
              color:'rgba(220, 38, 38, 1)',
            }
        },
        addBtn:{
            width:'126px', 
            height:'44px', 
            borderRadius:'8px', 
            backgroundColor:'rgba(32, 75, 156, 1)', 
            color:'#fff',
            textTransform:'none',
            '&:hover':{
              backgroundColor:'rgba(32, 75, 156, 1)', 
              color:'#fff',
            }
        },
        popper:{

          transform: 'translate3d(360px, 200px, 0) !important'

        },
      
        



      }),
      )

interface propTypes {
      children:any,
      callFilterApi?:Function,
      isUnderLine?: boolean,
      clearFilter:Function,
      clear: Function,
      getSectionLists?: () => void,
      sections?: { title: string, id: string, checked: boolean }[]
}




const theme =  createTheme({
  overrides: {
     MuiPopover:{
paper:{
top:'218px !important'
}
     }
    },



})



export default function FilterButton({children,callFilterApi,isUnderLine=false, clearFilter, clear, getSectionLists, sections}:propTypes) {

    const classes =  useStyles();
    const [anchorEl, setAnchorEl] =  React.useState<null | HTMLElement>(null);
    const [open, setOpen] = useState(false);
   

    const handleClick = () => {
      setOpen(!open)
      if (sections?.length === 0 && getSectionLists) {
        getSectionLists();
      }
    };

      // const open = Boolean(anchorEl);
      const id = open ? 'simple-popper' : undefined;

      const handleClose =  ()=>{
         setOpen(false)
         clearFilter();
      }

      const anchorRef = useRef<HTMLButtonElement | null>(null);

      const handleOutsideClick = (event:any) => {
        if (anchorRef.current && !anchorRef.current?.contains(event.target)) {
          setAnchorEl(null)
        }
      };
      

      const handleFilterApi = ()=>{
        setOpen(false)
        callFilterApi &&  callFilterApi();
          
    
      }
  
      
return (
    <ThemeProvider theme={theme}>
    
    <Button aria-describedby={id} 
    ref={anchorRef} data-test-id='filterBtn'  
    style={open ? 
    {border: '1px solid rgba(32, 75, 156, 1)', borderRadius:'8px'}  : 
    {borderRadius:'8px'} }  className={classes.filterButton} 
    onClick={handleClick}>
    {open ?  <img style={ open ? {color:'rgba(32, 75, 156, 1)'}:
    {color:'rgba(32, 75, 156, 1)'}}  
    src={filterFill} 
    alt='filterIcon'/> :
    <img style={ open ? {color:'rgba(32, 75, 156, 1)'}:
    {color:'rgba(32, 75, 156, 1)'}}  
    src={filterIcon} 
    alt='filterIcon'/>
    }
   

    </Button>
  
  <Popover    
  id={id} 
  open={open}  
  anchorEl={anchorRef.current} 
  onClose={() => setOpen(false)}
  anchorOrigin={{
    vertical:'top',
     horizontal:'right'
  }}  
  onBlur={handleOutsideClick}
  transformOrigin={{
vertical:'top',
horizontal:'right'
  }}
  
  
  >
       <Box className={classes.popperFirstChild} data-test-id='Popover'>
         <Box className={classes.popUpContainer}>
          <Box className={classes.popUpFirstChild}>
            <Box style={{display:'flex',justifyContent:'space-between'}}> 
            <Typography className={classes.filterText}>Filters</Typography>

            <CustomButton value='Clear' data-test-id='clearFilterBtn' fontWeight={500} height={24} width={42} color='rgba(220, 38, 38, 1)'   onClick={()=>clear()}  isUnderLine={isUnderLine} backgroundColor='#fff' borderRadius={0}/>

            </Box>
              <Divider />
          </Box>
           <Box>
           {children}
          </Box>
        
         </Box>

         <Box className={classes.popUpButtonsContainer}>
            <Button variant='contained' className={classes.cancelBtn}  
             data-test-id="cancel"
               onClick={handleClose}
            
            >Clear All</Button>
            <Button 
             variant='contained'
             className={classes.addBtn}
             onClick={handleFilterApi}
             data-test-id="apply"
            
            
            >Apply</Button>
         </Box>
      </Box>


  </Popover>

  
  </ThemeProvider>
 )
}

