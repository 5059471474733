import React from "react";

import {
  Container,
  Box,
  Button,
  Typography,
  // Customizable Area Start
  Grid,
  Card,
  Snackbar,
  styled
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import './RolesPermissionsCreate2.web.css'
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import Alert from "@material-ui/lab/Alert";
import RolesPermissionsCreate2Controller, { FormMode, Props } from "./RolesPermissionsCreate2Controller.web";
import { iconCheckBox, iconCheckBoxChecked, plusIcon } from "./assets";
import PageContainer from "../../navigationmenu/src/PageContainer.web"
import { configJSON } from "./RolesPermissions2Controller.web";
import { IPermissionGroup } from "./utils";
import DropDownMenu from "../../../components/src/DropdownMenu.web"
import { colors } from "../../../blocks/utilities/src/Colors";
import {CommonIcomSearchComponent} from "../../../components/src/customComponents/CommonIcomSearchComponent";
import { renderBaseOnConditions, sortCondition } from "../../../blocks/utilities/src/CustomBlockHelpers";
import ActionPermissionsModal, { AddPermissionWrapper } from "./ActionPermissionsModal.web";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
    secondary: {
      main: "#204B9C",
    },
  },
  typography: {
    fontFamily: "Montserrat",
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
    body1: {
      fontWeight: 500
    }
  },
});
// Customizable Area End


export default class RolesPermissionsCreate2 extends RolesPermissionsCreate2Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  renderEditButtonOnView = () => {
    const {permissionStatus} = this.state;
    if(this.state.formMode === FormMode.View){
      return (
        <>
        {
          renderBaseOnConditions(
            permissionStatus.editPermission,
            <div
              data-test-id={"editRole"}
              onClick={() => this.handleEditAccountClick(this.state.roleId)}
              className='editButton'
            >
              {configJSON.btnEditText}
            </div>,
            <></>
          )
        }
        </>
      );
    }
  };

  renderPermissonView() {
    const { selectedPermissions } = this.state;

    if(selectedPermissions.length == 0) {
      return (
        <ViewPermissionGroup>
          <Box className="viewPermissionBox">{configJSON.textNoPermission}</Box>
        </ViewPermissionGroup>
      )
    } else {
      return (
        <ViewPermissionGroup>
          {
            selectedPermissions.map((item) => {
              return (
                <Box className="viewPermissionBox">
                  <Typography className="permissionName">{item.name}</Typography>
                  <Box className="permissionViewWrapper">
                    {
                      item.permission_sub_groups.filter(
                        (subItem) => subItem.permitted).map(
                          (innerItem) => {
                            return <Typography className="permissionSubName">{innerItem.name}</Typography>
                      })
                    }
                  </Box>
                </Box>
              )
            })
          }
        </ViewPermissionGroup>
      )
    }
  }

  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <PageContainer 
        navigation={this.props.navigation}
        onUserChange={(userContext) => this.handleUserChange(userContext)}
      >
        <ThemeProvider theme={theme}>
          <Container style={{ padding: "30px 0px 30px 0px" }} maxWidth={false}>
            {this.state.loading ? configJSON.textLoading : (
              <>
                <Grid
                  justifyContent="space-between"
                  container
                >
                <HeaderBoxWrapper>
                  <Box>
                    <div className='headingGrid'>
                     <Typography variant="h6" className='heading'>
                      {this.renderHeadingTitle()}
                      </Typography>
                        {this.renderEditButtonOnView()}
                    </div>
                  </Box>
                </HeaderBoxWrapper>
                </Grid>

                <Card style={webStyle.cardWrapper}>
                  <HeaderBoxWrapper>
                    {this.returnFormModeView() ? 
                    <>
                     <Box className="mainViewWrapper">
                        <Box>
                          <p className="viewLabelheading">{configJSON.viewLabelRole}</p>
                          <Box >
                            <Typography className="roletext">{this.state.roleName}</Typography>
                          </Box>
                          <Box style={{ marginTop: "6px" }}>
                            {this.state.errorRoleName && (
                              <small style={webStyle.errorStyle}>
                                {this.state.errorRoleName}
                              </small>
                            )}
                          </Box>
                        </Box>
                        <Box>
                          <p className="viewLabelheading">{configJSON.icon}</p>
                          <Typography className="roletext">
                            <div className="viewIcon">
                              {this.state.selectedIcon && this.state.selectedIcon.item}
                              {sortCondition(Boolean(this.state.selectedIcon),this.state.roleName,"No Icon Found")}
                            </div>
                          </Typography>
                        </Box>
                    </Box> 
                    <Box  style={{ marginTop: '30px' }}>
                      <Typography className="viewLabelheading">
                        {configJSON.viewLabelPermission}
                      </Typography>
                      <Box>
                        {this.renderPermissonView()}
                      </Box>
                    </Box>
                    </>
                    :
                    <>
                      <Box className="mainAddEditWrapper">
                        <Box>
                          <FormLabel>{configJSON.labelRoleInput}</FormLabel>
                          <Box >
                            <StyledInputField
                              type="text"
                              name="roleName"
                              data-test-id='roleNameInput'
                              placeholder={configJSON.labelRoleInputPh}
                              value={this.state.roleName}
                              onChange={this.onRoleNameChangeHandler}
                            />
                          </Box>
                          <Box style={{ marginTop: "6px" }}>
                            {this.state.errorRoleName && (
                              <small style={webStyle.errorStyle}>
                                {this.state.errorRoleName}
                              </small>
                            )}
                          </Box>
                        </Box>
                        <Box>
                          <FormLabel>{configJSON.icon}</FormLabel>
                          <CommonIcomSearchComponent 
                              value={[]}
                              optionList={this.state.iconList}
                              debouncedFunction={() => { } }
                              selectedItem={this.state.selectedImage}                          
                              handleScroll={this.handleIconScroll}
                              handleSelectOptions={this.handleSelectImage} 
                              defaultId={this.state.defaultIcon}   
                               />
                        </Box>
                        <Box>
                          <AddPermissionWrapper
                            className='permission-wrapper'
                            onClick={() => this.handleOpenPermissionModal()}
                          >
                            <img src={plusIcon} width={20} height={20} alt='plus-icon' />
                            <span className='add-permission-span'>{configJSON.addPermission}</span>
                          </AddPermissionWrapper>
                        </Box>
                      </Box>
                      <Box className="mt-24">
                        <FormLabel>{configJSON.viewLabelPermission}</FormLabel>
                        {this.renderPermissonView()}
                      </Box>
                   </>
                    }
                  </HeaderBoxWrapper>
                 
                </Card>
                <Box display={'flex'} mt={2}>
                  <SecondaryButtonBox>
                    <Button className="secondaryButtonStyle" data-test-id="roleCancel" onClick={this.cancelHandler}>
                      {this.state.formMode === FormMode.View ? configJSON.btnBackText : configJSON.btnCancelText}
                    </Button>
                  </SecondaryButtonBox>
                  {this.state.formMode !== FormMode.View &&
                    <PrimaryButtonBox>
                      <Button className="primaryButtonStyle" data-test-id='roleSubmit' disabled={this.state.isSubmitting} onClick={this.onSubmitHandler}>
                        {this.getSubmiBtnText()}
                      </Button>
                    </PrimaryButtonBox>
                  }
                </Box>
              </>)}
            <Snackbar
              anchorOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              open={this.state.snackbar.open}
              autoHideDuration={4000}
              onClose={this.handleSnackbarClose}
            >
              <Alert severity={this.state.snackbar.severity}>{this.state.snackbar.message}</Alert>
            </Snackbar>
            {this.state.openPermissionModal && 
              <ActionPermissionsModal
                navigation={undefined}
                id={""}
                isOpenPermissionModal={this.state.openPermissionModal}
                onClosePermissionModal={this.handleClosePermissionModal}
                onContinuePermissionChange={this.handleChangePermission}
                permissionGroup={this.state.mainPermissionGroups}
              />
            }
          </Container>
        </ThemeProvider>
      </PageContainer>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  mainWrapper: {
    marginTop: '40px'
  },
  buttonStyle: {
    width: "126px",
    height: "56px",
    border: "none",
    fontSize: '16px',
    borderRadius: '8px',
    fontWeight: 700,
    cursor: 'pointer',
    textTransform: "none" as 'none',
  },
  buttonStylePrimary: {
    backgroundColor: "#204B9C",
    color: '#FFF'
  },
  buttonStyleSecondary: {
    backgroundColor: "#F1F5F9",
    color: '#64748B'
  },
  heading: {
    fontFamily: 'Montserrat',
    fontWeight: 600,
    fontSize: "24px",
    lineHeight: "29px",
  },
  headingGrid: {
    display: 'flex',
    alignItems: "center",
    marginTop: "20px"
  },
  labelheading: {
    fontFamily: 'Montserrat',
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: "20px",
    color: "#1A1A1A"
  },
  inputbox: {
    border: "0.139vw solid #ECECEC",
    borderRadius: "0.556vw",
    backgroungColor: '#FFFFFF',
    height: "56px",
    width: "36.25vw",
    paddingLeft: '16px'
  },
  cardWrapper: {
    padding: '29px 43px 19px 43px',
    margin: '19px 0 67px 0',
    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.03), 0px 8px 32px rgba(0, 0, 0, 0.06)",
    borderRadius: "12px"
  },
  labelPermission: {
    alignItems: 'flex-start',
    marginRight: '24px',
    paddingTop: '10px'
  },
  errorStyle: {
    padding: "5px",
    color: "red",
    fontFamily: "Montserrat",
    fontSize: "12px",
  },
};
const HeaderBoxWrapper = styled(Box)({
  width:"100%",
  "& .mt-24": {
    margin: "24px 0 0 0"
  },
  '& .heading': {
    fontFamily: 'Montserrat',
    fontWeight: 600,
    fontSize: "24px",
    lineHeight: "29px",
    "@media only screen and (max-width: 1024px)": {
      fontSize: "16px",
    },
  },
  '& .headingGrid': {
    display: 'flex',
    alignItems: "center",
    justifyContent:"space-between"
  },
  '& .roletext': {
    fontFamily: 'Montserrat',
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "22px",
    marginTop: "16px",
    color: colors().viewTextColor,
    "@media only screen and (max-width: 1024px)": {
      fontFamily: 'Montserrat',
      fontWeight: 500,
      fontSize: "13px",
      lineHeight: "20px",
      marginTop: "16px",
      color: colors().viewTextColor,
    },
  },
  '& .viewIcon': {
    display:"flex",
    alignItems:"center",
    gap:"16px"
  },
  '& .viewLabelheading': {
    fontFamily: 'Montserrat',
    fontWeight: 600,
    fontSize: "18px",
    lineHeight: "22px",
    color: colors().darkliver,
    margin: "0 0 16px 0",
    "@media only screen and (max-width: 1024px)": {
      fontSize: "16px",
      fontFamily: 'Montserrat',
      fontWeight: 600,
      lineHeight: "20px",
      color: colors().viewTextColor,
    },
  },
  '& .labelheading': {
    fontFamily: 'Montserrat',
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: "20px",
    color: colors().viewTextColor
  },
  '& .mainViewWrapper': {
    display: "flex", 
    alignItems:"center",
    gap:"50%"
  },
  '& .mainAddEditWrapper': {
    display: "grid", 
    gridTemplateColumns: "1fr 1fr",
    gap: "24px"
  },
  '& .permissionText': {
    fontFamily: 'Montserrat',
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "20px",
    color: colors().cyancobaltblue,
    "@media only screen and (max-width: 1024px)": {
      fontFamily: 'Montserrat',
      fontWeight: 500,
      fontSize: "13px",
      lineHeight: "20px",
      color: colors().cyancobaltblue,
    },
  },
  '& .mainPermissionGrid': {
    marginTop:"16px",
    marginLeft:"35px"
  },
  '& .editButton' : {
    padding: "16px 34px",
    borderRadius: 8,
    fontSize: 16,
    fontWeight: 700,
    cursor: "pointer",
    backgroundColor:  colors().cyancobaltblue,
    color: "white",
    width: 184,
    textAlign: "center",
    "@media only screen and (max-width: 1024px)": {
      fontSize: "16px",
      width: "128px",
      height:"44px",
      padding: "12px 34px"
    },
  },
});

const FormLabel = styled("p")({
  fontFamily: 'Montserrat',
  fontWeight: 600,
  fontSize: "16px",
  lineHeight: "20px",
  margin: "0 0 8px 0",
  color: colors().viewTextColor,
  "@media only screen and (max-width: 1024px)": {
    fontSize: "13px",
    color: colors().charcoal,
  },
});

const StyledInputField = styled('input')({
  border: "0.139vw solid",
  borderColor:colors().lightborder,
  borderRadius: "0.556vw",
  backgroungColor: '#FFFFFF',
  height: "56px",
  width: "36.25vw",
  paddingLeft: '16px',
  "@media only screen and (max-width: 1024px)": {
      fontSize: '12px',
      height:"44px"
  },
});

const SecondaryButtonBox = styled(Box)({
  '& .secondaryButtonStyle': {
    width: "126px",
    height: "56px",
    border: "none",
    fontSize: '16px',
    borderRadius: '8px',
    fontWeight: 700,
    cursor: 'pointer',
    textTransform: "none" as 'none',
    backgroundColor: colors().antiFlashWhite,
    color: colors().slateGray,
    "@media only screen and (max-width: 1024px)": {
      width: 128,
      height: 44,
    }
  },
});

export const PrimaryButtonBox = styled(Box)({
  marginLeft: '32px',
  '& .primaryButtonStyle': {
    width: "126px",
    height: "56px",
    border: "none",
    fontSize: '16px',
    borderRadius: '8px',
    fontWeight: 700,
    cursor: 'pointer',
    textTransform: "none" as 'none',
    backgroundColor: colors().cyancobaltblue,
    color: colors().background,
    "@media only screen and (max-width: 1024px)": {
      width: 128,
      height: 44,
    }
  },
});

export const ViewPermissionGroup = styled(Box)({
  display: "grid",
  gridTemplateColumns: "1fr 1fr 1fr 1fr",
  gap: "6px",
  "@media only screen and (max-width: 1366px)": {
    gridTemplateColumns: "1fr 1fr 1fr",
  },
  "@media only screen and (max-width: 1024px)": {
    gridTemplateColumns: "1fr 1fr",
  },
  "& .viewPermissionBox": {
    padding: "12px",
    background: "rgba(32, 75, 156, 0.15)",
    display: "flex",
    alignItems: "center",
    color: colors().cyancobaltblue,
    borderRadius: "8px",
    flexDirection: "column",
    maxWidth:"450px"
  },
  "& .permissionName": {
    fontSize: "16px",
    lineHeight: "18px",
    fontWeight: 600,
    fontFamily: "Montserrat",
  },
  "& .permissionViewWrapper": {
    display: "flex",
    flexWrap:"wrap",
    alignItems: "center",
    rowGap:"6px",
    margin: "8px 0 0 0"
  },
  "& .permissionSubName": {
    fontSize: "14px",
    lineHeight: "16px",
    fontWeight: 500,
    fontFamily: "Montserrat",
    borderRadius: "6px",
    margin: "0 0 0 6px",
    padding: "4px 6px",
    background: "rgb(32 75 156 / 60%)",
    color: colors().white,
  },
});

// Customizable Area End
