import React from "react";
// Customizable Area Start
import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import LanguageIcon from "@material-ui/icons/Language";
import ForgotPasswordController from "../../../blocks/forgot-password/src/ForgotPasswordController.web";

class CustomComponentLanguage extends ForgotPasswordController {
  constructor(props: any) {
    super(props);

  }
  render() {
    const webStyle = {

      dropBar: {
        top: "2vw",
        right: "3.472vw",
        border: '1px solid #ECECEC',
        borderRadius: '0.556vw',
        padding: "0.900vw"
      },
    };

    return (
      <Box style={{ position: "absolute", ...webStyle.dropBar }}>
        <TextField
          id="standard-select-currency"
          select
          value={this.state.language}
          onChange={this.handleLanguageChange}
          InputProps={{
            disableUnderline: true,
          }}
        >
          <MenuItem value="choose" style={{ padding: "1vw" }}>
            <div style={{ color: "white", display: "flex" }}>
              <LanguageIcon />
              <div style={{ color: "white", padding: "0.2vw 0.5vw 0 0.8vw" }}>
                English
              </div>
            </div>
          </MenuItem>
        </TextField>
      </Box>
    );
  }
}
// Customizable Area End
export default CustomComponentLanguage;
