export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const imgVisbility = require("../assets/visibility.svg");
export const imgVisbilityOff = require("../assets/visibilityOff.svg");
export const printIcon = require("../assets/print.svg");
export const editIcon = require("../assets/edit.svg");
export const pendingIcon = require("../assets/pendingicon.png")
export const storeIcon = require("../assets/storeicon.png")
export const uploadIcon = require("../assets/upload.png")
export const arrowBack = require("../assets/arrow_back.png")
export const completedIcon = require("../assets/completedicon.png")
