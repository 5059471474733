import React from 'react';
// Customizable Area Start
import { styled } from "@material-ui/core";
class CustomInput extends React.Component<any> {
    render() {
        const { type, name, placeholder, value, onChange, onFocus,disabled ,onKeyDown,inputError, inputDir} = this.props;
        const inputbox = {
            border: "0.139vw solid #ECECEC",
            borderRadius: "0.556vw",
            backgroungColor: "#FFFFFF",
            height: "56px",
            width: "100%",
            outline: "none",
            fontFamily: "Montserrat",
            fontWeight: 500,
            fontSize: "14px",
            paddingLeft: "1vw",
            paddingRight: "1vw"
        }

        // const placeholderStyle = {
        //     fontSize: "14px",
        //     fontWeight: 400,
        //     fontFamily: "Montserrat",
        // };


        return (
            <div>
            <StyledTextField
                type={type}
                name={name}
                placeholder={placeholder}
                style={{...inputbox , ...(inputError?{borderColor:'red'}:{})}}
                value={value}
                onChange={onChange}
                onFocus={onFocus}
                disabled={disabled}
                onKeyDown={onKeyDown}
                dir={inputDir}
            />
            </div>
        )
    }
}

export default CustomInput;

export const StyledTextField = styled('input')({
    borderRadius: 8,
    border: "1px solid var(--border, #ECECEC)",
    fontSize: '14px !important',
    fontWeight: 400,
    fontFamily: "Montserrat",
    "@media only screen and (max-width: 1024px)": {
        fontSize: '12px !important',
        height:"44px !important",
        fontWeight: 400,
        fontFamily: "Montserrat",
    },
  });
  
// Customizable Area End