// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import React from "react"
// Customizable Area End
export const configJSON = require("./config");

export interface Props {
  navigation?: any;
  id?: string;
  // Customizable Area Start

  // Customizable Area End
}

interface S {
  // Customizable Area Start
  otpValue:number;
  token:string;
  otpValues: string[];
  otpAttemptRights:number;
  fullPhoneNumber:number;
  isButtonDisabled:boolean;
  counter:number;
  errorMsg:string;
  showErrMessage:boolean;
  exceedErrMessage:string;
  showExceedErrMessage:boolean;
  dynamicImgLogo:string;
  snackbarOpen: boolean;
  snackbarMessage:string;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;

  // Customizable Area End
}
// Customizable Area Start

// Customizable Area End
export default class OtpController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  reSendOtpCallId: string = "";
  verifyOtpCallId: string = "";
  timer: unknown;
  dynamicLogoCallId:string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
    ];

    this.state = {
      otpValue:this.props?.navigation?.history?.location?.state?.state?.attributes?.pin,
      token:this.props?.navigation?.history?.location?.state?.state?.token,
      fullPhoneNumber:this.props?.navigation?.history?.location?.state?.state?.attributes?.full_phone_number,
      otpValues: ['', '', '', ''],
      isButtonDisabled:true,
      counter:50,
      errorMsg:"",
      showErrMessage:false,
      exceedErrMessage:"",
      showExceedErrMessage:false,
      dynamicImgLogo:"",
      snackbarOpen: false,
      snackbarMessage:"",
      otpAttemptRights:2

    };
    this.timer = React.createRef();
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  componentDidMount(): any {
    // Customizable Area Start
    this.startTimer();
    this.DynamicLogoCallApi()
    // Customizable Area End
  }
  // Customizable Area Start

  async componentWillUnmount() {
    this.clearTimer();
  }

  startTimer() {
    this.clearTimer();
    if (!this.timer) {
      this.timer = setInterval(() => {
        this.setState((prevState) => ({
          counter: prevState.counter - 1,
        }), () => {
          if (this.state.counter === 0) {
            this.clearTimer();
            this.setState({ isButtonDisabled: false });
          }
        });
      }, 1000);
    }
  }

  clearTimer() {
    if (this.timer) {
      clearInterval(this.timer as unknown as number);
      this.timer = null;
    }
  }
  handleChange = (event: React. ChangeEvent<HTMLInputElement>, index:number) => {
    const { otpValues } = this.state;
    const updatedOtpValues = [...otpValues];
    updatedOtpValues[index] = event.target.value;
    this.setState({ otpValues: updatedOtpValues });
  };

  handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>, index:number) => {
    if (event.key === 'Backspace' && this.state.otpValues[index] === '') {
      if (index > 0) {
        const previousInput = document.getElementById(`otp-${index - 1}`);
        previousInput?.focus();
      }
    }
  };

  handleKeyUp = (event: React.KeyboardEvent<HTMLInputElement>, index: number) => {
    const target = event.target as HTMLInputElement
    if (target?.value !== '' && index < 5) {
      const nextInput = document.getElementById(`otp-${index + 1}`);
      nextInput?.focus();
    }
  };

  handlePaste = (event: React.ClipboardEvent<HTMLInputElement>) => {
    event.preventDefault();
    const pastedData = event.clipboardData.getData('text/plain');
    if (pastedData?.length === 4 && !isNaN(pastedData as unknown as number)) {
      const updatedOtpValues = pastedData.split('').slice(0, 4);
      this.setState({ otpValues: updatedOtpValues });
    }
  };

  handleLogInputValue = () => {
    const { otpValues } = this.state;
    const inputValue = otpValues.join('');
    if(inputValue == this.state.otpValue as unknown as string){
     {
      this.verifyOtp();
      this.props.navigation.history.push("UpdateNewPassword",{token:this.state.token})
    }
    }else{
        this.setState({
          showErrMessage:true,
          errorMsg:"Invalid Pin!",
          // otpValues:['', '', '', '', '', ''],
        })
    }
    
  };
  handleTimeOut = () => {
    if(!this.state.otpAttemptRights)return;
    this.setState({ 
      isButtonDisabled: true, 
      counter: 50,
      otpAttemptRights:this.state.otpAttemptRights-1 
    });
    this.startTimer();
    this.reSendOtp()
  };

  reSendOtp = () => {

    let headers = {
        "Content-Type": configJSON.validationApiContentType,

    };
    let httpBody = {}
    httpBody = {
        data: {
            attributes: {
              full_phone_number: this.state.fullPhoneNumber,

            },
        }
    }
    const reSendOtpDataMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );

    this.reSendOtpCallId = reSendOtpDataMessage.messageId;

    reSendOtpDataMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        "bx_block_forgot_password/send_otp"
    );

    reSendOtpDataMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
    );
    reSendOtpDataMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
    );
    reSendOtpDataMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpPostMethod
    );

    runEngine.sendMessage(reSendOtpDataMessage.id, reSendOtpDataMessage);

}
  verifyOtp = () => {

    let headers = {
        "Content-Type": configJSON.validationApiContentType,
    };
    let httpBody = {}
    httpBody = {
        data: {
            attributes: {
              token: this.state.token,
              otp_code: this.state.otpValue
            },
        }
    }
    const reSendOtpDataMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );

    this.verifyOtpCallId = reSendOtpDataMessage.messageId;

    reSendOtpDataMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
       configJSON.verifyOtpApi
    );

    reSendOtpDataMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
    );
    reSendOtpDataMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
    );
    reSendOtpDataMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpPostMethod
    );

    runEngine.sendMessage(reSendOtpDataMessage.id, reSendOtpDataMessage);

}
DynamicLogoCallApi = () => {

  const header = {
    "Content-Type": configJSON.forgotPasswordAPiContentType,
  };

  const dynamicLogoMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  this.dynamicLogoCallId = dynamicLogoMessage.messageId;
  dynamicLogoMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.dynamicLogoCall
  );

  dynamicLogoMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );


  dynamicLogoMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.httpGetMethod
  );
  runEngine.sendMessage(dynamicLogoMessage.id, dynamicLogoMessage);

}
handleResponseForreSendOtp = (from: string, message: Message) => {
  if (this.reSendOtpCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiResponse?.data) {
          this.setState({otpValue:apiResponse?.data?.attributes.pin})

      } else{
        this.setState({showExceedErrMessage:true})
        this.setState({ snackbarOpen: true, snackbarMessage: apiResponse.errors[0].message });
       
      }
      }
  }
  handleResponseDynamicLogo = (from: string, message: Message) => {
    if (this.dynamicLogoCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
        const apiResponse = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
  
        if (apiResponse?.data) {
          this.setState({ dynamicImgLogo: apiResponse?.data[0]?.attributes?.icon})
          
        }
        }
    }
    handleCloseOtpError = (event?: React.SyntheticEvent, reason?: string) => {
      if (reason === configJSON.snackbarOtp) {
        return;
      }
  
      this.setState({ snackbarOpen: false })
    };
    
  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    this.handleResponseForreSendOtp(from,message)
    this.handleResponseDynamicLogo(from,message)
    // Customizable Area End
  }
}
// Customizable Area End
