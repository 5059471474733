import React from "react";
// Customizable Area Start
import { Styles } from "@material-ui/styles";
import {
  withStyles,
  createTheme,
  Theme,
  ThemeProvider,
} from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Button from "@material-ui/core/Button";
import Radio from "@material-ui/core/Radio";

import PromoCodePaymentController, {
  IPromoCode,
  Props,
  configJSON
} from "./PromoCodePaymentController.web";

import { PromoIcon } from "./assets";
import { getCurrencyString } from "../../catalogue/src/utils";
// Customizable Area End

// Customizable Area Start
const theme = createTheme({
  typography: {
    button: {
      textTransform: "none",
    },
  },
});
// Customizable Area End

export default class PromoCodePayment extends PromoCodePaymentController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderListItem = (promoCode: IPromoCode) => {
    const CustomRadio: React.FC<{ checked: boolean; onClick: () => void }> = ({
      checked,
      onClick,
    }) => (
      <Radio
        data-test-id='promo-item-radio'
        checked={checked}
        onClick={onClick}
        icon={<span style={webStyle.icon} />}
        checkedIcon={<span style={webStyle.checkedIcon} />}
      />
    );
    return (
      <div
        style={{
          width: "100%",
          opacity:
            this.state.selectedPromoCodeId >= 0 &&
            this.state.selectedPromoCodeId !== promoCode.id
              ? 0.3
              : 1,
        }}
      >
        <div style={webStyle.promocodeWrap}>
          <div style={{ display: "flex" }}>
            <div style={{ marginTop: "-5px" }}>
              <CustomRadio
                checked={this.state.selectedPromoCodeId === promoCode.id}
                onClick={() => {
                  this.setState({
                    selectedPromoCodeId: promoCode.id,
                    promoCode: promoCode.promo_code,
                    selectedPromoCode: promoCode,
                    checkError: "",
                  });
                }}
              />
            </div>

            <div style={webStyle.promocodeFlex}>
              <div className="promoCode" >{promoCode.promo_code}</div>
              <div style={webStyle.desc} className="promoCodeProductName">{promoCode.product_name}</div>
              <div style={webStyle.desc}>{promoCode.description}</div>
            </div>
          </div>

          {!this.state.selectedPromoCode?.bogo_enabled &&
          <div style={webStyle.saveContainer}>
            Save {getCurrencyString(promoCode.currency, promoCode.saved_amount)}
          </div>
          }
        </div>
        <div style={webStyle.line} />
      </div>
    );
  };
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <ThemeProvider theme={theme}>
        {this.state.applyEnabled ? (
          <div>
            <div style={webStyle.title}>Apply Promo Code</div>
            <TextField
              inputProps={{
                style: {
                  fontFamily: "Montserrat !important",
                  fontSize: "14px",
                  fontWeight: 600,
                                  },
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <Button
                                          disabled={!this.state.promoCode}
                      style={{ ...webStyle.checkButton, textTransform: "none" }}
                      onClick={() => this.applyCode(false)}
                    >
                      Check
                    </Button>
                  </InputAdornment>
                ),
              }}
              key={"check"}
              value={this.state.promoCode}
              style={webStyle.inputField}
              error={!!this.state.checkError}
              variant='outlined'
              type={"text"}
              placeholder={"Enter Promo Code"}
              // add addon on right side

              onChange={(event) => {
                const newCode = event.target.value || "";
                const newSelectedPromoCode = this.state.promoCodes.find(
                  (promoCode) => promoCode.promo_code === newCode
                );
                this.setState({
                  checkError: "",
                  promoCode: newCode,
                  selectedPromoCodeId: newSelectedPromoCode
                    ? newSelectedPromoCode.id
                    : -1,
                  selectedPromoCode: newSelectedPromoCode || undefined,
                });
              }}
            />
            {this.state.checkError && (
              <div className={"promoCodeErrorText"} style={webStyle.errorText}>{this.state.checkError}</div>
            )}

            <div style={{ maxHeight: "180px", overflow: "auto" }}>
              {this.state.promoCodes.map((promoCode) =>
                this.renderListItem(promoCode)
              )}
            </div>
            <div style={webStyle.savingBlock}>
              <div>
                { this.state.selectedPromoCode?.bogo_enabled ? 
                <div
                  style={{ ...webStyle.saving, color: "rgba(77, 77, 77, 1)" }}
                  className="PromoSavings"
                >
                  Buy1Get1
                </div> 
              :
                <>
                <div
                  style={{ ...webStyle.saving, color: "rgba(77, 77, 77, 1)" }}
                  className="PromoSavings"
                >
                  Savings:
                </div>
                <div style={webStyle.saving}
                 className="promoSavingsValue">
                  {this.state.selectedPromoCode &&
                  this.state.selectedPromoCode.promo_code ===
                    this.state.promoCode
                    ? getCurrencyString(
                        this.state.selectedPromoCode.currency,
                        this.state.selectedPromoCode.saved_amount
                      )
                    : "--"}
                </div>
                </>
                }
              </div>
              <Button
              className="promoButton"
                style={webStyle.applyButton}
                data-test-id='btn-promoCode-apply2'
                disabled={!this.state.selectedPromoCode}
                onClick={() => this.applyCode(true)}
              >
                Apply
              </Button>
            </div>
          </div>
        ) : (
          <div>
            <div style={webStyle.title} className={"promoCodeCls"}>{configJSON.label_promoCode}</div>
            <div style={webStyle.applyBox}>
              <img src={PromoIcon} />
              {!this.state.selectedPromoCode ? (
                <>
                  <div className={"applyPromoCodeCls"}>{configJSON.label_applyPromoCode}</div>
                  <Button
                    style={{
                      ...webStyle.checkButton,
                      textTransform: "none",
                      border: "1px solid #204B9C",
                    }}
                    className={"applyCls"}
                    data-test-id='btn-promoCode-apply'
                    onClick={() =>
                      this.setState({
                        applyEnabled: true,
                      })
                    }
                  >
                    Apply
                  </Button>
                </>
              ) : (
                <>
                  <div className={"applyPromoCodeCls"}>{configJSON.label_promoCodeApplied}</div>
                  <Button
                    data-test-id='edit-promocode-btn'
                    style={{
                      ...webStyle.checkButton,
                      textTransform: "none",
                      border: "1px solid #204B9C",
                    }}
                    className={"applyCls"}
                    onClick={() =>
                      this.setState({
                        applyEnabled: true,
                      })
                    }
                  >
                    Edit
                  </Button>
                </>
              )}
            </div>
          </div>
        )}
      </ThemeProvider>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyle = {
  saveContainer: {
    color: "#EC4E20",
    fontFamily: "Montserrat",
    fontSize: "12px",
    fontWeight: 500,
  },
  inputField: {
    width: "100%",
    fontFamily: "Montserrat !important",
    height: "56px",
    marginTop: "4px",
    marginBottom: "30px",
    borderRadius: "8px",
  },
  title: {
    fontFamily: "Montserrat !important",
    fontSize: "20px",
    fontWeight: 600,
    marginBottom: 12,
  },
  code: {
    fontFamily: "Montserrat !important",
    fontSize: "14px",
    fontWeight: 600,
  },
  desc: {
    fontFamily: "Montserrat !important",
    fontSize: "12px",
    fontWeight: 500,
    color: "#4D4D4D",
  },
  discount: {
    fontFamily: "Montserrat !important",
    fontSize: "12px",
    fontWeight: 500,
    color: "#EC4E20",
  },
  errorText: {
    fontSize: "12px",
    color: "red",
    marginTop: "-26px",
  },
  checkButton: {
    fontSize: "14px",
    color: "#204B9C",
    fontWeight: 600,
  },
  line: {
    width: "100%",
    height: "1px",
    backgroundColor: "#ECECEC",
    marginTop: "12px",
    marginBottom: "12px",
  },
  saving: {
    fontFamily: "Montserrat !important",
    fontSize: "14px",
    fontWeight: 600,
  },
  icon: {
    display: "inline-block",
    width: 20,
    height: 20,
    borderRadius: "50%",
    border: "1px solid #64748B",
    marginRight: "10px",
  },
  checkedIcon: {
    display: "inline-block",
    width: 20,
    height: 20,
    borderRadius: "50%",
    border: "6px solid #204B9C",
    color: "#204b9c",
    marginRight: "10px",
  },
  promocodeWrap: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
  },
  promocodeFlex: {
    display: "flex",
    flexDirection: "column" as const,
    gap: "6px",
  },
  savingBlock: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    marginTop: "12px",
  },
  applyButton: {
    backgroundColor: "#204B9C",
    color: "white",
    textTransform: "none" as const,
    width: "164px",
    height: "40px",
  },
  applyBox: {
    display: "flex",
    alignItems: "center",
    gap: "22px",
  },
};
// Customizable Area End
