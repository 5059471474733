import { v4 as uuidv4 } from "uuid";
import { runEngine } from "../../../framework/src/RunEngine";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import {
  getCustomEnumName,
  CustomEnums,
} from "../../utilities/src/CustomBlockHelpers";

export default class PageContainerWebAdapter {
  send: (message: Message) => void;
  constructor() {
    const blockId = uuidv4();
    this.send = (message) => runEngine.sendMessage(blockId, message);
    runEngine.attachBuildingBlock(this as IBlock, [
      getCustomEnumName(CustomEnums.PageContainerNavigateMessage),
    ]);
  }
  convert = (from: Message): Message => {
    const to = new Message(getName(MessageEnum.NavigationMessage));
    to.addData(
      getName(MessageEnum.NavigationTargetMessage),
      from.getData(getName(MessageEnum.NavigationTargetMessage))
    );
    to.addData(
      getName(MessageEnum.NavigationPropsMessage),
      from.getData(getName(MessageEnum.NavigationPropsMessage))
    );
    return to;
  };
  receive(from: string, message: Message): void {
    this.send(this.convert(message));
  }
}
