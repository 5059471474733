import React from "react";
// Customizable Area Start
import PageContainer from "../../navigationmenu/src/PageContainer.web"
import {
    Heading,
    Container,
    GridContainer,
    WhiteContainer,
    BottomContainer,
} from "../../promocodes/src/PromoCodeCreate.web";
import { StyledImageCheckbox } from "../../ordermanagement/src/Orders.web";
import { renderBaseOnConditions } from "../../../blocks/utilities/src/CustomBlockHelpers";
import {
    // Customizable Area Start
    styled,
    Box
    // Customizable Area End
  } from "@material-ui/core";
// Customizable Area End

import HomeCleaningSettingsViewController, {
    Props,
    configJSON,
    // Customizable Area Start
    // Customizable Area End
} from "./HomeCleaningSettingsViewController.web";
import { HeadingContainer, HeadingText, ValueText } from "../../promocodes/src/PromoCodeView.web";
import { StyleContainer } from "./HomeCleaningSettings.web";

// Customizable Area Start
const CheckTrue = require("../../../components/src/check_true.svg");
const CheckFalse = require("../../../components/src/check_false.svg");
const Strings = configJSON.Strings.Settings;
// Customizable Area End

export default class HomeCleaningSettingsView extends HomeCleaningSettingsViewController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    handleValues(getValue: string, isAmount: boolean) {
        if(getValue) {
            return <ValueText>{isAmount && `SAR`}&nbsp;{getValue}</ValueText>
        }
        return "--"
    }
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { attributes } = this.state.homeCleaningSettingsDetails.data;

        return (
            <PageContainer
                navigation={this.props.navigation}
                onUserChange={(userContext) => this.handleUserChange(userContext)}
              >
                <MainContainer>
                    <StyleContainer>
                        <HeadingContainer>
                            <Heading>{Strings.home_cleaning_settings_details}</Heading>
                            <BottomContainer>
                              {
                                renderBaseOnConditions(
                                  this.state.permissionStatus.editPermission,
                                  <button
                                    data-test-id='edit-btn'
                                    onClick={() => this.handleEdit(attributes.id)}
                                    className='add'
                                  >
                                    {Strings.edit}
                                  </button>,
                                  <></>
                                )
                              }
                            </BottomContainer>
                        </HeadingContainer>
                        <WhiteContainer className='p-32'>
                            <GridContainer className='row-gap-24'>
                                <div>
                                    <HeadingText>{Strings.region}</HeadingText>
                                    {this.handleValues(attributes.region_name, false)}
                                </div>
                                <div />
                                <div>
                                    <HeadingText>{Strings.minimum_order_amount}</HeadingText>
                                    {this.handleValues(attributes.minimum_order_amount, true)}
                                </div>
                                <div>
                                    <HeadingText>{Strings.maximum_order_amount}</HeadingText>
                                    {this.handleValues(attributes.maximum_order_amount, true)}
                                </div>
                                <div>
                                    <HeadingText>{Strings.minimum_non_refundable_amount_to_pay}</HeadingText>
                                    {this.handleValues(attributes.min_non_refundable_amount_to_pay, true)}
                                </div>

                                <div>
                                    <HeadingText>{Strings.home_cleaning_service_charges}</HeadingText>
                                    {this.handleValues(attributes.home_cleaning_service_charges, true)}
                                </div>

                                <div>
                                    <HeadingText>{Strings.flat_rate_percentage}</HeadingText>
                                    {this.handleValues(attributes.non_refundable_type, false)}
                                </div>

                                <div>
                                    <HeadingText>{Strings.rate}</HeadingText>
                                    {this.handleValues(attributes.add_by, attributes.non_refundable_type === 'flat_rate')}
                                </div>
                                <div>
                                    <HeadingText>{Strings.minimum_amount_to_waive_of_service_charge}</HeadingText>
                                    {this.handleValues(attributes.min_amount_to_waive_of_service_charge, true)}
                                </div>
                                <div>
                                    <HeadingText>{Strings.number_of_open_slots_for_day}</HeadingText>
                                    {this.handleValues(attributes.open_slots_per_day, false)}
                                </div>
                                <div className='checkbox-container'>
                                    <StyledImageCheckbox
                                        src={attributes.close_slot ? CheckTrue : CheckFalse}
                                        data-test-id='field-close-slots'
                                    />
                                    <label>{Strings.close_slots}</label>
                                </div>
                            </GridContainer>
                        </WhiteContainer>
                        <BottomContainer>
                            <button
                                data-test-id='back-btn'
                                className='add'
                                onClick={() => this.handleBack()}
                            >
                                {Strings.back}
                            </button>
                        </BottomContainer>
                    </StyleContainer>
                </MainContainer>
            </PageContainer>
        );
        // Customizable Area End
    }
}

// Customizable Area Start
const MainContainer = styled(Box)({
    paddingTop:'25px',
});
// Customizable Area End
