import React from "react";
// Customizable Area Start
import {
  TableRow,
  TableCell,
  OrderTable,
  RowContainer,
} from "../../../components/src/OrderTable/src";
import * as Yup from "yup";

import { Formik, FormikProps, FormikErrors } from "formik";

import {iconPlusAdd } from "./assets";
import { StyledCheckBox } from "../../../components/src/CustomTheme.web";
import {
  InputField,
  SimpleCustomSelect,
} from "../../../components/src/customComponents/CustomTags.web";
import { Alert } from "@material-ui/lab";

import { MenuProps as ImportedMenuProps } from "../../email-account-registration/src/AccountCreationController.web";
import { colors } from "../../utilities/src/Colors";
import { ICleaningOrder } from "./HomeCleaningOrdersController.web";
import { OrderItemPreferences } from "../../../components/src/Interface";
// Customizable Area End

import {
  // Customizable Area Start
  Box,
  Dialog,
  styled,
  MenuItem,
  Snackbar,
  DialogContent,
  Tooltip,
  withStyles,
  // Customizable Area End
} from "@material-ui/core";

import OrderDetailsController, {
  Props,
  configJSON,
  // Customizable Area Start
  IPaid,
  IItemData,
  IServiceItem,
  ICatalogueItem,
  IChargeListItem,
  ICategoryItem,
  IOrderData,
  ICarpAndFurnItemData,
  IPreferenceItem,
  // Customizable Area End
} from "./OrderDetailsController.web";

// Customizable Area Start
const Strings = configJSON.DetailsPage.Strings;
// Customizable Area End

export default class OrderDetails extends OrderDetailsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  getFooterButtons() {
    const { onClose, isEditPermission } = this.props;
    const { isEdit, isEdited } = this.state;
    const isActiveReceiveButton = this.getReceiveButtonStatus();
    const isEditPermissionHave = isEditPermission ? isEditPermission : false;

    if (isEdit) {
      return (
        <LeftMargin>
          <CancelButton
            data-test-id='btn-delete'
            onClick={() => this.handelDelete()}
          >
            {Strings.delete}
          </CancelButton>
          <CancelButton
            className='mLeft24'
            data-test-id='btn-close'
            onClick={() => onClose()}
          >
            {!isEdited ? Strings.cancel : Strings.close}
          </CancelButton>
          <SubmitButton
            form='form1'
            type='submit'
            className='mLeft24'
            data-test-id='btn-save'
          >
            {Strings.save}
          </SubmitButton>
        </LeftMargin>
      );
    } else {
      return (
        <LeftMargin>
          <CancelButton
            data-test-id='dialog-close-btn'
            onClick={() => onClose()}
          >
            {!isEdited ? Strings.cancel : Strings.close}
          </CancelButton>
          {(this.isEditEnable() && isEditPermissionHave) && (
            <SubmitButton data-test-id='btn-edit' onClick={() => this.onEdit()}>
              {Strings.edit}
            </SubmitButton>
          )}
          {isActiveReceiveButton && (
            <SubmitButton
              data-test-id='btn-received'
              onClick={() => this.updateOrderToReceived()}
            >
              {Strings.receive}
            </SubmitButton>
          )}
        </LeftMargin>
      );
    }
  }

  getNotesAndPaid() {
    const { orderData } = this.props;
    const { isEdit, orderMeta } = this.state;
    const { paid } = orderMeta || { paid: [] };
    const { notes, paid_at } = orderData?.attributes || {};

    function getPaidAtCondition() {
      return [paid.length == 0, !Boolean(paid_at)].includes(true);
    }

    if (!isEdit) {
      return (
        <BlueHeadingContainer>
          <div className='innerContainer'>
            <GridContainer>
              <div>
                <ContentHeading>{Strings.total}</ContentHeading>
                {getPaidAtCondition() && (
                  <ContentDetailsGreen>--</ContentDetailsGreen>
                )}
                {!getPaidAtCondition() &&
                  (paid as Array<IPaid>).map((item: IPaid) => (
                    <ContentDetailsGreen key={"paid-item-" + item.id}>
                      {Strings.sar} {item.amount} {Strings.paidBy} {item.name}
                    </ContentDetailsGreen>
                  ))}
              </div>
              <div>
                <ContentHeading>{Strings.notes}</ContentHeading>
                <ContentDetails>{notes || "--"}</ContentDetails>
              </div>
            </GridContainer>
          </div>
        </BlueHeadingContainer>
      );
    } else {
      return <></>;
    }
  }

  getAddProduct(values: IItemData[], setValue: Function) {
    const { isEdit } = this.state;
    const orderData = this.props.orderData as IOrderData;
    const isRewashOrder = orderData.attributes.rewash_order;
    if (isEdit && !isRewashOrder) {
      return (
        <TableCell
          type={"none"}
          className='f-14 addProductEmptyCol'
          content={
            <AddButtonBox
              data-test-id='btn-addProduct'
              onClick={() => this.onAddProduct(values, setValue)}
            >
              <img src={iconPlusAdd} />
              <div>{Strings.addProduct}</div>
            </AddButtonBox>
          }
        />
      );
    } else return <></>;
  }

  getAddProductFields(
    values: IItemData[],
    itemId: string,
    uniqueSpecifications: Array<string>,
    isError: boolean,
    setValue: Function
  ) {
    const MenuProps = {
      ...ImportedMenuProps,
      PaperProps: {
        style: { maxHeight: 150 },
      },
    };
    const { serviceItemList, categoryItemList, preferenceItemList, isEdit } = this.state;
    const extraSpecs = Array.from(
      { length: uniqueSpecifications.length },
      (_data, item) => item + 1
    ).map(() => <TableCell type={"middle"} content={"---"} />);

    return (
      <TableRow
        columns={this.getColumns(
          8 + uniqueSpecifications.length,
          uniqueSpecifications.length > 0
        )}
        className={`mb-16 capitalize ${isError && "mb-4"}`}
      >
        <TableCell
          className={`f-14 ${isEdit && "plr-0"}`}
          type={"none"}
          content={<></>}
        />
        <TableCell
          className='f-14'
          type={"left"}
          content={
            <StyledInput
              size='small'
              variant='outlined'
              placeholder='Product'
              inputProps={{
                "data-test-id": "field-addNew-catalogue",
              }}
              onChange={(event: React.ChangeEvent<{ value: unknown }>) =>
                this.onChangeCatalogue(
                  values,
                  itemId,
                  event.target.value as string,
                  setValue
                )
              }
            />
          }
        />
        <TableCell
          className='f-14'
          type={"middle"}
          content={
            <StyledNumberInput
              type='number'
              placeholder='0'
              data-test-id='field-addNew-quantity'
              onChange={(
                event: React.ChangeEvent<{ value: unknown }> | undefined
              ) =>
                this.onChangeQuantityOrPcs(
                  { items: values } as { items: IItemData[] },
                  itemId,
                  (event?.target.value as number) || 0,
                  null,
                  setValue
                )
              }
            />
          }
        />
        <TableCell
          className='f-14'
          type={"middle"}
          content={
            <StyledNumberInput 
              type='number'
              placeholder='0'
              disabled={true}
              data-test-id='field-addNew-pcs'
              onChange={(
                event: React.ChangeEvent<{ value: unknown }> | undefined
              ) =>
                this.onChangeQuantityOrPcs(
                  { items: values } as { items: IItemData[] },
                  itemId,
                  null,
                  (event?.target.value as number) || 0,
                  setValue
                )
              }
            />
          }
        />
        <TableCell
          className='f-14'
          type={"middle"}
          content={
            <FlexWith12>
              <StyledSelect
                name='addNew-category'
                size='small'
                displayEmpty
                variant='outlined'
                MenuProps={MenuProps}
                data-test-id='field-addNew-category'
                onChange={(event: React.ChangeEvent<{ value: unknown }>) =>
                  this.onChangeCategory(
                    values,
                    itemId,
                    event.target.value as string,
                    setValue
                  )
                }
                renderValue={(value: unknown) =>
                  this.getSelectPlaceholder(
                    value as string,
                    "Section",
                    categoryItemList
                  )
                }
              >
                {categoryItemList.map((item: ICategoryItem) => (
                  <MenuItem value={item.id}>{item.attributes.name}</MenuItem>
                ))}
              </StyledSelect>
            </FlexWith12>
          }
        />
        <TableCell
          className='f-14'
          type={"middle"}
          content={
            <FlexWith12>
              <StyledSelect
                size='small'
                displayEmpty
                variant='outlined'
                name='addNew-service'
                data-test-id='field-addNew-service'
                MenuProps={MenuProps}
                onChange={(event: React.ChangeEvent<{ value: unknown }>) =>
                  this.onChangeService(
                    values,
                    itemId,
                    event.target.value as string,
                    setValue
                  )
                }
                renderValue={(value: unknown) =>
                  this.getSelectPlaceholder(
                    value as string,
                    "Service",
                    serviceItemList
                  )
                }
              >
                {serviceItemList.map((item: IServiceItem) => (
                  <MenuItem value={item.id}>{item.attributes.name}</MenuItem>
                ))}
              </StyledSelect>
            </FlexWith12>
          }
        />
        {extraSpecs}
        <TableCell
          className='f-14'
          type={"middle"}
          content={
            <FlexWith12>
              <StyledSelect
                size='small'
                displayEmpty
                variant='outlined'
                name='addNew-preferences'
                data-test-id='field-addNew-preferences'
                MenuProps={MenuProps}
                onChange={(event: React.ChangeEvent<{ value: unknown }>) =>
                  this.onChangePreferences(
                    values,
                    itemId,
                    event.target.value as string,
                    setValue
                  )
                }
                renderValue={(value: unknown) =>
                  this.getSelectPlaceholder(
                    value as string,
                    "preference",
                    preferenceItemList
                  )
                }
              >
                {preferenceItemList.map((item: IPreferenceItem) => (
                  <MenuItem value={item.id}>{item.attributes.name}</MenuItem>
                ))}
              </StyledSelect>
            </FlexWith12>
          }
        />
        <TableCell type={"middle"} content={"---"} />
        <TableCell
          type={"right"}
          content={
            <StyledInput
              size='small'
              className='w-100'
              placeholder='Price'
              variant='outlined'
              inputProps={{
                "data-test-id": "field-addNew-price",
              }}
              onChange={(event: React.ChangeEvent<{ value: unknown }>) =>
                this.onChangePrice(
                  values,
                  itemId,
                  event.target.value as number,
                  setValue
                )
              }
            />
          }
        />
      </TableRow>
    );
  };

  showData = (dataList: string[]) => {
    if (dataList.length > 0) {
      if (dataList.length == 1) {
        return <span>{dataList[0]}</span>;
      } else {
        return (
          <PreferenceTooltip
            title={<span>{dataList.join(" , ")}</span>}
            arrow
            placement='top'
          >
            <span style={{ cursor: "pointer" }}>{dataList[0]} ...</span>
          </PreferenceTooltip>
        );
      }
    } else {
      return <span>---</span>;
    }
  };

  showAllPreferences = (preferences: OrderItemPreferences[]) => {
    const uniquePreferenceNames = Array.from(
      preferences
        .reduce((mapVal, objVal) => {
          if(objVal.attributes.preference !== null) {
            if (
              !mapVal.has(
                objVal.attributes.preference.attributes.preference_first_name
              )
            ) {
              mapVal.set(
                objVal.attributes.preference.attributes.preference_first_name,
                objVal
              );
            }
          }
          return mapVal;
        }, new Map())
        .values()
    );

    const allPreferencesName = uniquePreferenceNames.map(
      (item: OrderItemPreferences) =>
        item.attributes.preference.attributes.preference_first_name
    );

    return this.showData(allPreferencesName);
  };

  showAllUpcharges = (upcharges: OrderItemPreferences[]) => {
    const outputUpcharge = upcharges
      .flatMap((item) => item.attributes.upcharges)
      .reduce((accUpcharge: IChargeListItem[], current) => {
        if (
          !accUpcharge.some(
            (item) => item.attributes.id === current.attributes.id
          )
        ) {
          accUpcharge.push(current);
        }
        return accUpcharge;
      }, []);

    const allUpchargeName = outputUpcharge.map((item) => item.attributes.name);

    return this.showData(allUpchargeName);
  };

  showAllSpecifications = (specifications: OrderItemPreferences[], specificName: string) => {
    const output = specifications.map((item) => {
      if(item.attributes.specifications) {
        return item.attributes.specifications[specificName]
      } else {
        return ""
      }
    });
    const filterOutput = output.filter((item) => item);
    return this.showData([...new Set(filterOutput)]);
  };

  getTableRows({
    isNew,
    values,
    isEdit,
    isError,
    setValue,
    valueData,
    errorMessage,
    uniqueSpecifications,
  }: {
    isNew: boolean;
    isEdit: boolean;
    isError: boolean;
    values: IItemData;
    setValue: Function;
    errorMessage: string;
    valueData: { items: IItemData[] };
    uniqueSpecifications: Array<string>;
  }) {
    return (
      <>
        {this.getConditionBased(
          Boolean(isNew),
          this.getAddProductFields(
            valueData.items,
            values.id as string,
            uniqueSpecifications,
            isError,
            setValue
          ),
          <TableRow
            columns={this.getColumns(
              8 + uniqueSpecifications.length,
              uniqueSpecifications.length > 0
            )}
            className={
              this.getConditionBased(
                Boolean(isError),
                "mb-4 capitalize",
                "mb-16 capitalize"
              ) as string
            }
          >
            {[
              this.getConditionBased(
                isEdit,
                <StyledCheckBox
                  data-test-id={"item-select-" + values.id}
                  checked={this.state.selectedOrderItems.includes(
                    values.id as never
                  )}
                  onClick={() => this.onSelectOrderItem(values.id as string)}
                />,
                null
              ),
              <>
                <div style={{display: "block"}}>{values.attributes?.catalogue?.product_name}
                  <div style={{ color: "#0F172A", fontSize: "12px",fontWeight: "400", textTransform: "lowercase"}}>
                    {Number(values.attributes?.height) !== 0 && Number(values.attributes?.width) !== 0  && (<>length * width: <div>{values.attributes.height} m * {values.attributes.width} m </div> </>)}
                    {Number(values.attributes?.weight) !== 0 && (<>weight : {values.attributes.weight} Kg</>)}
                  </div>
                </div>
              </>,
              this.getConditionBased(
                isEdit,
                <StyledNumberInput
                  type='number'
                  data-test-id={"field-quantity-" + values.id}
                  className='lift-up'
                  onChange={(
                    event:
                      | React.ChangeEvent<{
                          value: unknown;
                        }>
                      | undefined
                  ) => {
                    this.onChangeQuantityOrPcs(
                      valueData,
                      values.id as string,
                      (event?.target.value as number) || 0,
                      null,
                      setValue
                    );
                  }}
                  defaultValue={values.attributes.quantity}
                />,
                <span className="columnLabel">{values.attributes.quantity}</span>
              ),
              <span className="columnLabel">{values.attributes.no_of_pieces}</span>,
              this.getCategoryNameById(values.attributes.category_id || -1),
              values.attributes.service?.attributes.name || "---",
              ...uniqueSpecifications.map(
                (name: string) =>
                  this.showAllSpecifications(
                    values.attributes.order_item_preferences,
                    name
                  )
              ),
              this.showAllPreferences(values.attributes.order_item_preferences),
              this.showAllUpcharges(values.attributes.order_item_preferences),
              values.attributes.total_price,
            ]
              .filter((item) => item)
              .map((item: unknown, childIndex: number) => {
                return (
                  <TableCell
                    key={"cell_" + values.id + "_" + childIndex}
                    className={
                      "f-14 " + this.getStyleForTableHeaderForEdit(childIndex)
                    }
                    type={this.getType(
                      childIndex,
                      7 + uniqueSpecifications.length
                    )}
                    content={item as React.ReactNode}
                  />
                );
              })}
          </TableRow>
        )}

        {this.getConditionBased(
          isError,
          <TableRow
            key={"order-details-items-" + values.id}
            className='mb-16'
            columns={"34px 1fr 60px"}
          >
            <TableCell className='p020' type='none' content={""} />
            <TableCell
              type='none'
              className='p0'
              content={
                <ErrorText className='orderItemErrors'>
                  {errorMessage}
                </ErrorText>
              }
            />
          </TableRow>,
          <></>
        )}

        {this.getConditionBased(
          this.state.isQtyError,
          <TableRow
            key={"order-details-items-" + values.id}
            className='mb-16'
            columns={"34px 1fr 60px"}
          >
            <TableCell className='p020' type='none' content={""} />
            <TableCell
              type='none'
              className='p0'
              content={
                <ErrorText className='orderItemErrors'>
                  {this.state.qtyErrorMsg}
                </ErrorText>
              }
            />
          </TableRow>,
          <></>
        )}
      </>
    );
  }

  showNotes = (notes: string, length: number = 15) => {
    if (notes != null && notes != undefined && notes != "") {
      if (notes.length > length) {
        return (
          <NotesTooltip title={<p>{notes}</p>} arrow>
            <span className="textstyle">{notes.slice(0, length)}...</span>
          </NotesTooltip>
        );
      } else {
        return notes;
      }
    } else {
      return "---";
    }
  }

  renderCarpetFurnitureOrderDetailsPopup() {
    const {store_name,order_number,total,no_of_items,min_non_refundable_amount,service_charge,order_type_display} =  (this.props.orderData as ICleaningOrder).attributes
    return (
      <OrderDetailsDialog
        maxWidth={"md"}
        open={true}
        fullWidth
        onClose={() => this.props.onClose()}
      >
        <DialogContent className='p-24'>
          <StyledWrapper>
            <OrderDetailsHeaderContainer>
              <OrderDetailsHeadingText>
                <div>
                  {configJSON.stringsListPage_table_storeNameVal} : {store_name}
                </div>
                <div>
                  {configJSON.stringsListPage_table_orderId} : {order_number}
                </div>
                <div>
                  {configJSON.stringsListPage_table_totalItems} : {no_of_items}
                </div>
                <div> 
                  {configJSON.stringsListPage_table_orderTypeVal} : {order_type_display}
                </div>
              </OrderDetailsHeadingText>
              <OrderDetailsHeadingText>
                <div>
                  {configJSON.stringsListPage_table_totalAmt} : SAR {total}
                </div>
                <div>
                  {configJSON.stringsListPage_table_credit} : SAR ---
                </div>
                <div>
                  {configJSON.stringsListPage_table_serviceCharge} : SAR {service_charge}
                </div>
                <div>
                  {configJSON.stringsListPage_table_nonRefundableAmount} : SAR {min_non_refundable_amount}
                </div>
              </OrderDetailsHeadingText>
            </OrderDetailsHeaderContainer>
            <Box className='p-16' />
            <OrderTable>
              <RowContainer header>
                <TableRow columns={"2fr 2fr 1fr 1.5fr 1.5fr 2fr 2fr"}>
                  {[
                    configJSON.stringsListPage_table_item,
                    configJSON.stringsListPage_table_type,
                    configJSON.stringsListPage_table_qty,
                    configJSON.stringsListPage_table_size,
                    configJSON.stringsListPage_table_weight,
                    configJSON.stringsListPage_table_price,
                    configJSON.stringsListPage_table_notes,
                  ].map((heading: string) => {
                    return (
                      <TableCell
                        header
                        content={heading}
                        className='align-item-center'
                      />
                    );
                  })}
                </TableRow>
              </RowContainer>
              <RowContainer>
                {this.state.carpAndFurnOrderItemsList.map((item: ICarpAndFurnItemData) => {
                  const {
                    notes,
                    product_type,
                    height,
                    weight,
                    quantity,
                    catalogue,
                    total_price,
                    width
                  } = item.attributes;
                  return (
                    <TableRow
                      columns={"2fr 2fr 1fr 1.5fr 1.5fr 2fr 2fr"}
                    >
                      {[
                        <Box className='catalogue_name'>
                          {catalogue.name}
                         <span><li></li></span>
                          {catalogue.product_second_name}
                        </Box>,
                        <Box className='catalogue_name'>
                          {product_type.name}
                          <span><li></li></span>
                          {product_type.second_name}
                        </Box>,
                        <span className="textstyle">{quantity}</span>,
                        <span className="textstyle">{Number(height) * Number(width) === 0 ? "---" :  Number(height) * Number(width) + ' Sqm' }</span>,
                        <span className="textstyle">{weight ? weight + ' Kg': "---"}</span>,
                        <span className="textstyle">SAR {total_price}</span>,      
                        this.showNotes(notes),
                      ].map((item: unknown, index: number) => (
                        <TableCell
                          content={item as React.ReactNode}
                          type={this.getTypeOfOrderItemTable(index)}
                        />
                      ))}
                    </TableRow>
                  );
               })}
              </RowContainer>
            </OrderTable>

            <Box className='p-8' />
            <Box className='text-center'>
                <button
                  className='cancelBtn'
                  data-test-id='btn-cancel'
                  onClick={() => this.props.onClose()}
                >
                  {configJSON.stringsListPage_table_cancel}
                </button>
            </Box>
          </StyledWrapper>
        </DialogContent>
      </OrderDetailsDialog>
    );
  }

  // Customizable Area End

  render() {
    // Customizable Area Start
    const { orderData,isNewOrderDetailsModal } = this.props;
    const {
      isEdit,
      orderMeta,
      errorMessage,
      orderItemsList,
      successMessage,
      openSuccessPopup,
      selectedOrderItems,
    } = this.state;

    const { customer, created_at, order_number, internal_status, rewash_order,parent_order, customer_complaint } =
      (orderData as IOrderData).attributes || {};

    const { total_items, total_pieces } = orderMeta || {};

    const isSelectAll =
      selectedOrderItems.length === this.getSelectableList().length;
    const uniqueSpecifications: Array<string> = this.getUniqueSpecs();

    const min_total_value = rewash_order ? 0 : 1;

    const validationSchemaForOrderDetails = Yup.object().shape({
      items: Yup.array().of(
        Yup.object({
          attributes: Yup.object({
            catalogue: Yup.object({
              product_name: Yup.string().trim().required("name"),
            }),
            service: Yup.object({
              attributes: Yup.object({
                name: Yup.string().trim().required("service"),
              }),
            }),
            preference_id: Yup.string().trim().required("preference"),
            total_price: Yup.number()
              .required("total price")
              .typeError("total price")
              .min(min_total_value, "total price"),
            quantity: Yup.number().min(1, "quantity"),
            category_id: Yup.number().min(0, "section"),
          }),
        })
      ),
    });
    // Customizable Area End
    return (
      // Customizable Area Start
      <>
        {isNewOrderDetailsModal 
        ? this.renderCarpetFurnitureOrderDetailsPopup()
        :<StyledDialog
          open={true}
          id='order-details-popup'
          onClose={() => this.props.onClose()}
        >
          <Snackbar
            key={"error-message-snackbar"}
            data-test-id='errorMsg'
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            autoHideDuration={4000}
            open={Boolean(errorMessage)}
            onClose={this.handelCloseSnackbar.bind(this)}
          >
            <StyledAlert severity='error'>{errorMessage}</StyledAlert>
          </Snackbar>
          <Snackbar
            key={"success-message-snackbar"}
            data-test-id='successMsg'
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            autoHideDuration={4000}
            open={Boolean(openSuccessPopup)}
            onClose={this.onCloseSuccessPopup.bind(this)}
          >
            <StyledAlert severity='success'>{successMessage}</StyledAlert>
          </Snackbar>
          <StyledDialogContent>
            <Container>
              <OrderTitle>{Strings.dialogTitle}</OrderTitle>
              <BlueHeadingContainer>
                <h3>
                  <h4>
                    {Strings.orderTitle} {order_number}
                  </h4>
                  {parent_order?.order_number && (
                    <div style={{float: "right"}}>
                      {Strings.parentOrderTitle} {parent_order?.order_number}
                    </div>
                  )}
                </h3>
                <div className='innerContainer'>
                  <GridContainer>
                    <div>
                      <ContentHeading>{Strings.customer}</ContentHeading>
                      <ContentDetails>{customer.full_name}</ContentDetails>
                    </div>
                    <div>
                      <ContentHeading>{Strings.contact}</ContentHeading>
                      <ContentDetails>
                        {customer.full_phone_number}
                      </ContentDetails>
                    </div>
                    <div>
                      <ContentHeading>{Strings.orderCrated}</ContentHeading>
                      <ContentDetails>
                        {this.formatCreatedDate(created_at)}
                      </ContentDetails>
                    </div>
                    <div>
                      <ContentHeading>{Strings.status}</ContentHeading>
                      <ContentDetails>
                        {this.getFormattedString(internal_status)}
                      </ContentDetails>
                    </div>
                    <div>
                      <ContentHeading>{Strings.totalItems}</ContentHeading>
                      <ContentDetails>{total_items}</ContentDetails>
                    </div>
                    <div>
                      <ContentHeading>{Strings.totalPice}</ContentHeading>
                      <ContentDetails>{total_pieces}</ContentDetails>
                    </div>
                    { order_number.includes("RWO") && 
                    <div>
                      <ContentHeading>{Strings.customerComplaint}</ContentHeading>
                      <ContentDetails>{customer_complaint || "---"}</ContentDetails>
                    </div>
                    }
                  </GridContainer>
                </div>
              </BlueHeadingContainer>

              <StyledTableContainer>
                <MaxHeightContainer>
                  <div className='innerContainer'>
                    <OrderTable
                      minWidth1024={840}
                      minWidth={
                        (7 + uniqueSpecifications.length - 1) * 150 + 200 + 32
                      }
                    >
                      <RowContainer header>
                        <TableRow
                          columns={this.getColumns(
                            8 + uniqueSpecifications.length,
                            uniqueSpecifications.length > 0,
                            true
                          )}
                          className='capitalize'
                        >
                          {[
                            isEdit ? (
                              <StyledCheckBox
                                data-test-id='field-selectAll'
                                checked={isSelectAll}
                                onClick={() =>
                                  this.onSelectUnSelectAll(!isSelectAll)
                                }
                              />
                            ) : null,
                            Strings.table.products,
                            Strings.table.qty,
                            Strings.table.pcs,
                            Strings.table.section,
                            Strings.table.service,
                            ...uniqueSpecifications,
                            Strings.table.preference,
                            Strings.table.upcharge,
                            Strings.table.price,
                          ]
                            .filter((item) => item)
                            .map((tableName: string, index: number) => (
                              <TableCell
                                className={`f-16 ${this.getStyleForTableHeaderForEdit(
                                  index
                                )}`}
                                header
                                key={"item-table-header-key-" + tableName}
                                type={this.getType(
                                  index,
                                  7 + uniqueSpecifications.length
                                )}
                                content={tableName}
                              />
                            ))}
                        </TableRow>
                      </RowContainer>

                      <Formik
                        enableReinitialize
                        onSubmit={({ items }) =>
                          this.handleOrderDetailForm(items)
                        }
                        initialValues={{ items: orderItemsList }}
                        validationSchema={validationSchemaForOrderDetails}
                      >
                        {({
                          errors,
                          handleSubmit,
                          getFieldMeta,
                          getFieldHelpers,
                          values: valueData,
                        }: FormikProps<{ items: IItemData[] }>) => {
                          const { setValue } = getFieldHelpers("items");

                          return (
                            <form onSubmit={handleSubmit} id='form1'>
                              <RowContainer data-test-id='order-items'>
                                {valueData.items.map(
                                  (values: IItemData, index: number) => {
                                    const errorMessage = this.getErrors(
                                      (errors?.items?.[index] ||
                                        {}) as FormikErrors<IItemData>
                                    );

                                    return this.getTableRows({
                                      values,
                                      isEdit,
                                      setValue,
                                      valueData,
                                      errorMessage,
                                      uniqueSpecifications,
                                      isNew: Boolean(values.isNew),
                                      isError: Boolean(errorMessage),
                                    });
                                  }
                                )}

                                {this.getAddProduct(
                                  getFieldMeta("items").value as IItemData[],
                                  setValue
                                )}
                              </RowContainer>
                            </form>
                          );
                        }}
                      </Formik>
                    </OrderTable>
                  </div>
                </MaxHeightContainer>
              </StyledTableContainer>

              {this.getNotesAndPaid()}
              {this.getFooterButtons()}
            </Container>
          </StyledDialogContent>
        </StyledDialog>}
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

const StyledTableContainer = styled(Box)({
  "& .plr-0": {
    paddingLeft: 0,
    paddingRight: 0,
  },
  "& .addProductEmptyCol": {
    paddingTop: 0,
    paddingLeft: 34,
    paddingBottom: 16,
  },
  "& .p0": {
    padding: "0px",
  },
  "& .p020": {
    padding: "0px 20px",
  },
  "& .mb-16": {
    marginBottom: 16,
  },
  "& .mb-4": {
    marginBottom: 4,
  },
  "& .f-16": {
    fontSize: 16,
  },
  "& .f-14": {
    fontSize: 14,
  },
  "& .f-12": {
    fontSize: 12,
  },
  "& .capitalize": {
    textTransform: "capitalize",
  },
  "& .columnLabel": {
    fontSize: "16px",
    fontWeight: 400,
    fontFamily: "Montserrat",
    "@media only screen and (max-width: 1024px)": {
      fontSize: "12px",
      color: colors().viewTextColor,
    }
  }
});

const StyledAlert = styled(Alert)({
  minWidth: "unset !important",
});

const FlexWith12 = styled(Box)({
  display: "flex",
  gap: 12,
});

const ErrorText = styled(Box)({
  fontSize: 12,
  color: "red",
  fontWeight: 400,
});

const AddButtonBox = styled(Box)({
  gap: 8,
  fontSize: 16,
  fontWeight: 600,
  cursor: "pointer",
  lineHeight: "24px",
  display: "inline-flex",
  fontFamily: "Montserrat",
  color: "var(--Primary, #204B9C)",
});

const LeftMargin = styled(Box)({
  textAlign: "center",
  "& .mLeft24": {
    marginLeft: 24,
  },
});

const StyledInput = styled(InputField)({
  paddingTop: 0,
  "& input": {
    fontWeight: 400,
  },
  "& input::placeholder": {
    opacity: 0.5,
    fontWeight: 400,
  },
  "& .MuiOutlinedInput, & .MuiOutlinedInput-notchedOutline": {
    borderColor: "#ECECEC",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    display: "none",
  },
});

const StyledSelect = styled(SimpleCustomSelect)({
  "& .MuiSelect-selectMenu": {
    minHeight: "unset",
    height: 36,
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "#ECECEC",
  },
});

const StyledNumberInput = styled("input")({
  width: 60,
  padding: 8,
  fontSize: 14,
  fontWeight: 400,
  borderRadius: 8,
  fontFamily: "Montserrat",
  border: "1px solid #ECECEC",
  background: "var(--basic-white, #FFF)",
  color: "var(--neutrals-dark-gray, #4D4D4D)",
  "&.w-100": {
    width: 100,
  },
  "&.lift-up": {
    marginTop: -6,
  },
});

const MaxHeightContainer = styled("div")({
  padding: 2,
  maxHeight: 438,
  marginBottom: 16,
  overflowY: "auto",
});

const StyledDialog = styled(Dialog)({
  "& .MuiPaper-rounded": {
    borderRadius: "12px 12px 0px 0px",
  },
  "& .MuiPaper-root": {
    minWidth: 1284,
    "@media only screen and (max-width: 1366px)": {
      minWidth: 1200
    },
    "@media only screen and (max-width: 1024px)": {
      minWidth: 600
    },
    "@media only screen and (max-width: 1280px)": {
      minWidth: 800
    }
  },
});

const StyledDialogContent = styled(DialogContent)({
  "&.MuiDialogContent-root": {
    padding: "0px !important",
  },
});

const GridContainer = styled("div")({
  display: "grid",
  gridGap: 24,
  gridTemplateColumns: "1fr 1fr",
  "@media only screen and (max-width: 1024px)": {
    gridTemplateColumns: "0.5fr 0.5fr",
  },
});

const BlueHeadingContainer = styled("div")({
  borderRadius: 12,
  boxShadow: "0px 2px 8px 0px rgba(0, 0, 0, 0.08)",
  marginBottom: 24,
  "& .space-1": {
    gap: 4,
  },
  "& > h3": {
    fontSize: "18px",
    fontWeight: 600,
    lineHeight: "22px",
    padding: "19px 32px",
    fontFamily: "Montserrat",
    color: "var(--white, #FFF)",
    backgroundColor: "#204B9C",
    borderTopRightRadius: 12,
    borderTopLeftRadius: 12,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "@media only screen and (max-width: 1024px)": {
      fontSize: "15px",
    },
  },
  "& .innerContainer": {
    padding: "24px 32px",
    "@media only screen and (max-width: 1366px)": {
      padding: "20px 32px"
    },
    "@media only screen and (max-width: 1024px)": {
      padding: "16px 32px"
    },
    "& .capitalize": {
      textTransform: "capitalize",
    },
  },
});

const Container = styled(Box)({
  padding: "24px 32px",
});

const OrderTitle = styled("h3")({
  fontSize: "24px",
  fontWeight: 600,
  textAlign: "center",
  letterSpacing: "-0.3px",
  fontFamily: "Montserrat",
  color: "var(--Tertiary, #1A1A1A)",
  margin: 0,
  padding: 0,
  paddingBottom: 24,
});

const ContentHeading = styled("div")({
  color: "var(--dark-grey, #4D4D4D)",
  fontFamily: "Montserrat",
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: 600,
  lineHeight: "22px",
  paddingBottom: 8,
});

const ContentDetails = styled("div")({
  color: "var(--Tertiary, #1A1A1A)",
  textTransform: "capitalize",
  fontFamily: "Montserrat",
  lineHeight: "22px",
  fontSize: "14px",
  fontWeight: 500,
});

const ContentDetailsGreen = styled(ContentDetails)({
  color: "#059669",
});

const CommonButton = styled("button")({
  gap: 8,
  fontSize: 16,
  marginTop: 32,
  borderRadius: 8,
  fontWeight: 700,
  minWidth: 186,
  border: "none",
  cursor: "pointer",
  padding: "16px",
  margin: "0 auto",
  lineHeight: "24px",
  alignItems: "center",
  display: "inline-flex",
  justifyContent: "center",
  fontFamily: "Montserrat",
  "@media only screen and (max-width: 1366px)": {
    minWidth: 170,
    padding: "14px",
    fontSize: 15
  },
  "@media only screen and (max-width: 1024px)": {
    minWidth: 160,
    padding: "12px",
    fontSize: 14
  }
});

const CancelButton = styled(CommonButton)({
  color: "var(--neutrals-cool-gray-500, #64748B)",
  background: "var(--neutrals-cool-gray-100, #F1F5F9)",
});

const SubmitButton = styled(CommonButton)({
  color: "white",
  marginLeft: 24,
  background: "var(--Primary, #204B9C);",
});



export const OrderDetailsDialog = styled(Dialog)({
  "& .MuiDialog-paper.MuiPaper-rounded": {
    borderRadius: 24,
  },
  "& .p-24": {
    padding: 24,
  },
  "& .p-32": {
    padding: 32,
  },
  "& .cancelBtn":{
    backgroundColor: colors().antiFlashWhite,
    color: colors().slateGray,
    border:"none",
    padding: "16px 34px",
    borderRadius: 8,
    fontSize: 16,
    fontWeight: 700,
    cursor: "pointer",
    width:"206px",
    height:"50px",
    "@media only screen and (max-width: 1024px)": {
      fontSize: 16,
      fontWeight: 600,
      width: 128,
      height: 44,
      padding: "12px 34px",
    }
  }
});

const StyledWrapper = styled("div")({
  "& .align-item-center": {
    alignItems: "center",
  },
  "& .text-center": {
    textAlign: "center",
  },
  "& .d-inlineFlex": {
    display: "inline-flex",
  },
  "& .mt-24": {
    marginTop: "24px !important",
  },
  "& .align-self-center": {
    alignSelf: "center",
  },
  "& .ml-auto": {
    marginLeft: "auto",
  },
  "& .fgrow1": {
    flexGrow: 1,
  },
  "& .w-100": {
    width: "100%",
  },
  "& .w-128": {
    width: 128,
  },
  "& .f-12": {
    fontSize: 12,
  },
  "& .f-16": {
    fontSize: 16,
  },
  "& .gap-16": {
    gap: 16,
  },
  "& .d-flex": {
    display: "flex",
  },
  "& .pb-48": {
    paddingBottom: 48,
  },
  "& .pt-0": {
    paddingTop: 0,
  },
  "& .pl-0": {
    paddingLeft: 0,
  },
  "& .mw-105": {
    minWidth: 105,
  },
  "& .mw-132": {
    minWidth: 132,
  },
  "& .mw-186": {
    minWidth: 186,
  },
  "& .mw-206": {
    minWidth: 206,
  },
  "& .p-8": {
    padding: 8,
  },
  "& .p-16": {
    padding: 16,
  },
  "& .no_records": {
    width: "100%",
    fontSize: 16,
    fontWeight: 700,
    textAlign: "center",
  },
  "& .cursor-pointer": {
    cursor: "pointer",
  },
  "& .custom-tabs": {
    backgroundColor: "#F8FAFC",
    display: "inline-flex",
    margin: "0px",
  },
  "& .bordered-select": {
    minWidth: "260px",
    margin: "0 12px",
  },
  "& .bottom-container": {
    paddingTop: 0,
    justifyContent: "flex-end",
  },
  "& .color_4D4D4D": {
    color: "#4D4D4D",
  },
  "& .catalogue_name": {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "flex-start",
    gap: 5,
    fontWeight:500,
    fontSize:"13px",
    fontFamily: "Montserrat",
    fontStyle: "normal",
    color:colors().viewTextColor
  },
  "& .textstyle": {
    fontWeight:500,
    fontSize:"13px",
    fontFamily: "Montserrat",
    fontStyle: "normal",
    color:colors().viewTextColor
  },
  "& .printIcon2": {
    gap: 8,
    display: "flex",
    color: "rgba(32, 75, 156, 1)",
  },
  "& .printIcon": {
    padding: 2,
    marginLeft: 12,
    borderRadius: 4,
    cursor: "pointer",
    bgcolor: "rgba(32, 75, 156, 0.1)",
  },
  "& .textAreaStyles": {
    width: "100%",
    padding: "8px",
    borderRadius: "8px",
    background: "#F8F8F8",
    height: "58px !important",
    border: "2px solid #ECECEC",
  },
  webStyles: {},
  "& .table": {
    minWidth: 700,
  },
  "& .mainWrapperOrder": {
    marginTop: "32px",
    fontFamily: "Montserrat",
  },
  "& .pageHeaderOrder": {
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "24px",
    lineHeight: "29px",
  },
  "& .headerWrapperOrder": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  "& .contentWrapperOrder": {},
  "& .headerButtonPartAccountOrder": {
    gap: 24,
    display: "flex",
    justifyContent: "flex-end",
  },
  "& .filterButtonAccountOrder": {
    cursor: "pointer",
    background: "#FFFFFF",
    height: "56px",
    color: "#4d4d4d",
    width: "56px",
    fontSize: "24px",
    borderRadius: "8px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid #ECECEC",
    "@media only screen and (max-width: 1024px)": {
      width: "44px",
      height: "44px",
    },
  },
  "& .summary": {
    display: "flex",
    width: "100%",
    padding: "16px 32px",
    alignItems: "flex-start",
    gap: "176px",
    borderRadius: "12px",
    background: "#EC4E20",
    marginBottom: "24px",
  },
  "& .summaryLabel": {
    color: "#FFF",
    fontFamily: "Montserrat",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "normal",
    width: "25%",
    display: "inlineBlock",
  },
  "& .summaryValue": {
    fontWeight: 600,
  },
  "& .detailBtn": {
    color: "#204B9C",
    textDecoration: "underline",
    fontSize: "16px",
    fontWeight: 600,
    marginTop: "12px",
    cursor: "pointer",
  },
  "& .cellText": {
    fontSize: "12px",
    fontWeight: 500,
    paddingTop: "16px",
  },
  "& .adjustmentTextArea": {
    height: 58,
    width: "100%",
    padding: "8px",
    borderRadius: "8px",
    background: "#F8F8F8",
    border: "2px solid #ECECEC",
  },
  "& .customTab": {
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "600",
    margin: "2px",
  },
  "& .tabbingSection": {
    backgroundColor: "#f8fafc",
    borderRadius: "40px",
    margin: "0 auto",
    boxShadow: "none",
  },
  "& .inputField": {
    width: "100%",
    height: "56px",
    fontFamily: "Montserrat !important",
  },
  "& .formControl": {
    width: "100%",
    borderRadius: "8px",
    fontFamily: "Montserrat",
  },
  "& .addButton": {
    background: "#204B9C",
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: "24px",
    color: "#FFFFFF",
    height: "56px",
    width: "184px",
    borderRadius: "8px",
  },
  "& .adjustmentDialogContent": {
    display: "flex",
    flexDirection: "column" as const,
    alignItems: "center",
    padding: 24,
  },
  "& .adjustmentPara": {
    margin: "0 0 24px 0",
    textAlign: "center" as const,
  },
  "& .adjustmentReasonError": {
    color: "red",
    fontSize: "13px",
    margin: "8px 0",
  },
  "& .adjustWrapper": {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  "& .reasonSelect": {
    minWidth: "220px",
  },
  "& .commentWrapper": {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "100%",
    position: "relative",
    "& .text": {
      fontSize: 13,
      fontWeight: 500,
      color: colors().statusRed,
      whiteSpace: "nowrap",
      padding: "6px 10px",
    },
  },
  "& .updateBtnWrap": {
    minWidth: "256px",
    textAlign: "right",
  },
  "& .w-c-340": {
    width: "calc(100vw - 340px)",
  },
  "& .w-c-158": {
    width: "calc(100vw - 158px)",
  },
  "& .disable-btn": {
    opacity: 0.85,
  },
  "& .plantLoader": {
    textAlign: "center",
    paddingBottom: 15,
  },
  "& .titleReviewOrder": {
    textAlign: "center",
    fontSize: "24px",
    fontWeight: 600,
    marginBottom: "20px",
    fontFamily: "Montserrat",
  },
  "& .label-15-600": {
    fontSize: "15px",
    fontWeight: 600,
    fontFamily: "Montserrat",
  },
  "& .table-rows > div": {
    minHeight: "100px",
  },
  "& .table-rows > div:last-child": {
    position: "relative",
  },
  "& .credit-note-button": {
    width: "100%",
    display: "flex",
    alignItems: "center",
    "& button": {
      gap: 6,
      border: 0,
      display: "flex",
      cursor: "pointer",
      padding: "6px 10px",
      textAlign: "center",
      borderRadius: "6px",
      alignItems: "center",
      color: colors().cyancobaltblue,
      backgroundColor: colors().cyancobaltblue + "1A",
      fontWeight: 600,
      "& span": {
        whiteSpace: "nowrap",
      },
    },
  },
  "& .flex-column": {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  "& .gap-24": {
    gap: "24px",
  },
  "& .gap-10": {
    gap: "10px",
  },
  "& .p-rl-15": {
    padding: "0 15px",
  },
  "& .warn-icon": {
    width: "64px",
    height: "64px",
    color: colors().cyancobaltblue,
  },
  "& .label-24-600": {
    fontSize: "24px",
    fontWeight: 600,
    fontFamily: "Montserrat",
  },
  "& .label-20-400": {
    fontSize: "20px",
    fontWeight: 400,
    fontFamily: "Montserrat",
  },
  "& .done-btn": {
    width: "186px",
    height: "50px",
    color: colors().white,
    textTransform: "none",
    borderRadius: "8px",
    background: colors().cyancobaltblue,
    fontFamily: "Montserrat",
    fontSize: "16px",
    fontWeight: "700",
  },
});

const OrderDetailsHeaderContainer = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  padding: 16,
  borderRadius: 12,
  border: "1px solid #DEDEDE",
  "& > div:first-child": {
    paddingBottom: 8,
  },
});

const OrderDetailsHeadingText = styled("div")({
  fontSize: 16,
  fontWeight: 500,
  color: "#1A1A1A",
  alignItems: "flex-start",
  flexDirection: "column",
  fontFamily: "Montserrat",
  justifyContent: "space-between",
  "& div": {
    margin: "0 0 6px 0",
  },
  "& div:last-child": {
    margin: "0",
  },
});

const NotesTooltip = withStyles({
  tooltip: {
    color: "black",
    fontSize: "12px",
    backgroundColor: "white",
    width: "400px",
    maxWidth: "400px",
    boxShadow:
      "rgba(0, 0, 0, 0.3) 0px 8px 32px 0px, rgba(0, 0, 0, 0.03) 0px 4px 8px 0px",
  },
  arrow: {
    color: "white",
    boxShadow:
      "rgba(0, 0, 0, 0.3) 0px 8px 32px 0px, rgba(0, 0, 0, 0.03) 0px 4px 8px 0px",
  },
})(Tooltip);

export const PreferenceTooltip = withStyles({
  tooltip: {
    color: "black",
    fontSize: "12px",
    backgroundColor: "white",
    width: "200px",
    maxWidth: "200px",
    boxShadow:
      "rgba(0, 0, 0, 0.3) 0px 8px 32px 0px, rgba(0, 0, 0, 0.03) 0px 4px 8px 0px",
  },
  arrow: {
    color: "white",
    boxShadow:
      "rgba(0, 0, 0, 0.3) 0px 8px 32px 0px, rgba(0, 0, 0, 0.03) 0px 4px 8px 0px",
  },
})(Tooltip);
// Customizable Area End
