// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { IUserContext } from "../../../blocks/navigationmenu/src/PageContainerController.web";
import {
  PermissionStatus,
  checkForNewPermissonStatus,
  customPermissionApiKey
} from "../../../blocks/utilities/src/CustomBlockHelpers";
import { PermissionGroupArray } from "../../../blocks/navigationmenu/src/utils";
// Customizable Area End
export const configJSON = require("./config");

export interface Props {
  navigation?: any;
  id?: string;
  // Customizable Area Start

  // Customizable Area End
}

interface S {
  // Customizable Area Start
  viewData: ViewData,
  iconDefault: string,
  permissionStatus: PermissionStatus;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: string | number;

  // Customizable Area End
}
// Customizable Area Start
interface ViewData {
  attributes: {
    id: number,
    section_name: string,
    section_second_name: string,
    preference_id: number,
    online:boolean,
    preference:{
      data:{
        attributes:{
          preference_first_name: string
          gallery_icon:{
            image:string
          } | null
        }
      }
    }
    icon: { data: { attributes: { image: string, name: string}}}
  }

}

// Customizable Area End

// Customizable Area Start
export default class ViewPreferenceController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  preferenceDataCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
    ];

    this.state = {
      viewData: this.props.navigation?.history.location?.state?.state,
      iconDefault: configJSON.defaultIcon,
      permissionStatus: {
        mainPermission: false,
        createPermission: false,
        viewPermission: false,
        editPermission: false,
        deactivatePermission: false
      }
    };
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  componentDidMount(): any {
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start


  handleCancel = () => {
    this.props.navigation.history.push("/Products-SectionList")
  }
  handleSectionEdit = (item:ViewData) => {
    this.props.navigation.history.push(`/Products-SectionListEdit${item?.attributes.section_name}`, { state: item })
}

handleUserChange = (userContext: IUserContext) => {
  const apiKey = customPermissionApiKey.sectionNewPermissions;
  const userData = userContext.user?.attributes.permission_groups;
  const value = checkForNewPermissonStatus(apiKey, userData as Array<PermissionGroupArray>);
  this.setState({
    permissionStatus: value
  })
};

  // Customizable Area End

}
// Customizable Area End
