import React from "react";
import { styled } from "@material-ui/core";

interface Props {
  header?: boolean;
  children: React.ReactNode;
}

function RowContainer(props: Props) {
  const { children, header, ...rest } = props;

  if (header) {
    return (
      <Wrapper {...rest}>
        <Container>{children}</Container>
      </Wrapper>
    );
  } else return <Container {...rest}>{children}</Container>;
}

export const Wrapper = styled("div")({
  "& .tableRow:first-child": {
    marginTop: "0px !important",
  },
  "& > div": {
    padding: "0px 16px",
    backgroundColor: "#204B9C",
    marginLeft: "0px !important",
    marginRight: "0px !important",
  },
});

export const Container = styled("div")({
  "& .tableRow:first-child": {
    marginTop: 16,
  },
  marginLeft: 16,
  marginRight: 16,
});

export default RowContainer;
