// Customizable Area Start
import React, { FunctionComponent, ReactNode } from "react";
import { TableCell, Box, styled } from "@material-ui/core";
import { colors } from "../../blocks/utilities/src/Colors";

export type SortingType = "" | "asc" | "desc";
export interface ISortingData {
  [key: string]: SortingType;
}

interface SortingTableHeaderProps {
  title: string | ReactNode;
  sortingKey: string;
  sortingData: ISortingData;
  onChange?: (sortingData: ISortingData) => any;
  onQueryChange?: (query: string) => void;
  sortingDisabled?: boolean;
  type?: "left" | "middle" | "right" | "action";
  width: string;
  handleHeaderClick?: () => void;
  backgroundColor?: string;
  fullRadius?: boolean;
}

const getIconStyle = (sortingData: ISortingData, sortingKey: string) => {
  if (sortingData[sortingKey] === "") {
    return 'notrDirectionalIcon';
  } else {
    return 'biDirectionalIcon';
  }
};

const getIcon = (sortingData: ISortingData, sortingKey: string) => {
  if (sortingData[sortingKey] === "") {
    return require("./sorting.png");
  } else if (sortingData[sortingKey] === "asc") {
    return require("./arrow-up.png");
  } else if (sortingData[sortingKey] === "desc") {
    return require("./arrow-down.png");
  }
};

const getStyle = (position: string, fullRadius: boolean) => {
  if (position === "left") {
    return {
      ...webStyle.sortingLeftHeaderCell,
      ...(fullRadius ? { borderBottomLeftRadius: "12px" } : {}),
    };
  } else if (position === "right") {
    return {
      ...webStyle.sortingRightHeaderCell,
      ...(fullRadius ? { borderBottomRightRadius: "12px" } : {}),
    };
  } else if (position === "middle") {
    return webStyle.sortingMiddleHeaderCell;
  } else if (position === "action") {
    return {
      ...webStyle.actionHeader,
      ...(fullRadius ? { borderBottomRightRadius: "12px" } : {}),
    };
  }
};

const SortingTableHeader: FunctionComponent<SortingTableHeaderProps> = ({
  title,
  sortingKey,
  sortingData,
  onChange,
  onQueryChange,
  sortingDisabled,
  type = "middle",
  width,
  handleHeaderClick = () => {},
  backgroundColor,
  fullRadius,
}) => {
  const icon = getIcon(sortingData, sortingKey);

  const onSortingClick = () => {
    const newSortingData = { ...sortingData };
    if (sortingData[sortingKey] === "") {
      newSortingData[sortingKey] = "asc";
    } else if (sortingData[sortingKey] === "asc") {
      newSortingData[sortingKey] = "desc";
    } else if (sortingData[sortingKey] === "desc") {
      newSortingData[sortingKey] = "";
    }

    Object.keys(newSortingData).forEach((key) => {
      if (key !== sortingKey) {
        newSortingData[key] = "";
      }
    });
    if (onChange) {
      onChange(newSortingData);
      let queryString = "";
      Object.keys(newSortingData).forEach((key) => {
        if (newSortingData[key]) {
          queryString += `&${key}=${newSortingData[key]}`;
        }
      });
      if (onQueryChange) onQueryChange(queryString);
    }
  };
  return (
    <TableCell
      style={{
        background: backgroundColor || "#204B9C",
        fontSize: "18px",
        color: "FFFFFF",
        width,
        fontWeight: 600,
        ...getStyle(type, !!fullRadius),
      }}
      align='left'
    >
      <StyledTableBoxWrapper>
        <div style={{ display: "flex", alignItems: "center" }}>
          <div className={"tableHeaderTitle"}>{title}</div>

          {!sortingDisabled && (
            <div>
              <img
                data-test-id='sortingIcon'
                aria-label={"sorting-table-header-" + sortingKey}
                // style={getIconStyle(sortingData, sortingKey)}
                className={getIconStyle(sortingData, sortingKey)}
                src={icon}
                onClick={() => {
                  onSortingClick();
                  handleHeaderClick();
                }}
              />
            </div>
          )}
        </div>
      </StyledTableBoxWrapper>
    </TableCell>
  );
};

const webStyle = {
  sortingMiddleHeaderCell: {
    paddingLeft: "0px",
  },
  sortingLeftHeaderCell: {
    borderTopLeftRadius: "12px",
  },
  sortingRightHeaderCell: {
    borderTopRightRadius: "12px",
  },
  actionHeader: {
    width: "10px",
    borderTopRightRadius: "12px",
  },
};

const StyledTableBoxWrapper = styled(Box)({
  "& .tableHeaderTitle": {
    color: colors().white,
    fontFamily: "Montserrat",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "22px",
    "@media only screen and (max-width: 1024px)": {
      fontSize: "13px",
      lineHeight: "15.6px",
      fontWeight: 500,
    },
  },
  "& .biDirectionalIcon": {
    height: 32,
    width: 38,
    marginRight: 12,
    marginLeft: 6,
    cursor: "pointer",
  },
  "& .notrDirectionalIcon": {
    height: 28,
    width: 32,
    marginTop: 2,
    marginBottom: 2,
    marginRight: 12,
    marginLeft: 6,
    cursor: "pointer",
    "@media only screen and (max-width: 1024px)": {
      height: 24,
      width: 30,
    },
  },
});

export default SortingTableHeader;

// Customizable Area End
