import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import { IBlock } from "../../../framework/src/IBlock";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { createRef } from "react";
import { saudiTime } from './utils'
import { IData, ITimeSlot } from "./PromoCodeViewController.web";
import { makeApiMessage as MakeApiMessage, updateMultiSelectData } from '../../../components/src/common'

import moment from "moment";
import { isEmpty, debounce, uniqBy, concat } from "lodash";

export interface ITimeSlotExtended extends ITimeSlot {
  _custom: boolean | never;
  _destroy: boolean | never;
}

export interface OptionStructure {
  id: string;
  option: string | number;
}

type Option = {
  id: string | number;
  option: string;
}

export interface ICommonResponse {
  id: number | string;
  attributes: {
    id: number;
    name: string;
    option: string;
    area_name: string;
    store_name: string;
    branch_name: string;
    product_name: string;
    section_name: string;
    active: boolean | string | number;
    services: { data: Array<{ id: string | number }> };
    sections: { data: Array<{ id: string | number }> };
    home_clean_products: { data: Array<{ id: string | number }> };
    errors: { [field: string]: string | Array<string> };
  };
}

interface  ExcludeDayType {
  id: string;
  type: string;
  attributes: {
    id: number;
    day: string;
    created_at: string;
    updated_at: string;
  };
};
export interface IProductItem {
  id: string | number;
  name: string | number;
  option: string | number;
  services: Array<string | number>;
}
export interface IOption {
  id: string | number | never;
  name: string;
  store_name?: string;
  product_name?: string;
}

export interface AvailTimeObj {
  id: number | null;
  endDate: null | string | Date;
  startDate: null | string | Date;
  _custom: boolean;
  _destroy: boolean;
}
interface Props {
  id: string;
  navigation: {
    navigate: Function;
    getParam: Function;
  };
}
interface State {
  saveStatus: string;
  usedList: {id:string,option:string}[];
  areaList: Array<IOption | never>;
  regionList: Array<IOption | never>;
  sectionList: Array<IOption | never>;
  serviceList: Array<IOption | never>;
  discountTypes: {id:string,option:string}[];
  excludeDayList: Array<IOption | never>;
  productList: Array<IProductItem | never>;
  sectionSelectedData: Option[];
  regionSelectedData: Option[];
  areaSelectedData: Option[];
  sectionLists: Option[],
  sectionPage: number;
  regionPage: number;
  sectionAutoCompleteValue: string;
  regionAutoCompleteValue: string;
  selectedSectionsForList: Option[];
  selectedRegionsForList:Option[];
  selectedAreasForList:Option[];
  isSectionSelectAll: boolean;
  isRegionSelectAll: boolean;
  isAreaSelectAll: boolean;
  selectedServices: Option[];
  serviceOptions:  Option[];
  serviceNextPage: number;
  serviceAutoCopmpleteValue: string;
  isServiceSelectAll: boolean;
  selectedServicesForList: Option[];
  selectedForAdd: Option[];
  productListItemsDropdown: IProductItem[];
  currentProductData: IProductItem[];
  productNextPage: number;
  searchQuery: boolean,
  autoCopmpleteValue: string;
  isSelectedAll: boolean;
  addSpecSelectedOptionForList: Option[];


  error: {
    [field: string]: string | Array<string>;
  };

  noData: boolean;
  isLoading: boolean;
  endDate: Date | null;
  startDate: Date | null;
  data:
  | IData
  | {
    attributes: {
      available_times: Array<ITimeSlotExtended> | never | Array<{}>;
    };
  };

  isPlatformSelectAll: boolean;
  selectedPlatform: Option[];
  platformSelectedOptionForList : Option[];
  platformList: OptionStructure[];

  isStoreSelectAll: boolean,
  selectedStore: Option[],
  storeSelectedOptionForList: Option[],
  storeList: OptionStructure[];

  isOrderTypeSelectAll: boolean,
  selectedOrderType: Option[],
  orderTypeSelectedOptionForList: Option[],
  orderType: OptionStructure[];

  isHomeCleanProductSelectAll: boolean,
  selectedHomeCleanProduct: Option[];
  homeCleanProductSelectedOptionForList: Option[];
  homeCleanProductList: OptionStructure[];

  isExcludeDaysSelectAll: boolean,
  selectedExcludeDays: Option[];
  excludeDaysSelectedOptionForList: Option[];
  excludeDays: OptionStructure[];
  isSaveLoader : boolean;
}

interface SS {}
// Customizable Area End

// Customizable Area Start
export const configJSON = require("./config");
const ApiUrls = configJSON.ApiUrls;
const Strings = configJSON.Strings.promoCodeCreate;
const errorMessages = Strings.error;

// Customizable Area End

class PromoCodeController extends BlockComponent<Props, State, SS> {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area End
  }

  // Customizable Area Start
  endDateRef = createRef<HTMLElement | null>();
  startDateRef = createRef<HTMLElement | null>();
  productMessageId: string = "";
  sectionMessageId: string = "";
  regionMessageId: string = "";
  areaMessageId: string = "";
  storeMessageId: string = "";
  saveApiMessageId: string = "";
  editApiMessageId: string = "";
  platformMessageId: string = "";
  servicesMessageId: string = "";
  promoCodeShowMessageId: string = "";
  excludeDayListMessageId: string = "";
  initialPromoCodeMessageId: string = "";
  homeCleanProductListMessageId: string = "";
  isEdit: boolean = this.props.navigation.getParam("id") ? true : false;
  itemsPerPage = 10;
  disableLoadMoreSection = false;
  disableLoadMoreRegion = false;
  disableLoadMoreService = false;
  disableLoadMorePriceList = false;

  // Customizable Area End

  state = {
    // Customizable Area Start
    saveStatus: "",
    excludeDays: [],
    orderType: [{id:"laundry",option:"Laundry"}, {id:"home_cleaning",option:"Home Cleaning"}],
    discountTypes: [{ id: "amount", option: "Amount" }, { id: "percentage", option: "Percentage" }, { id: "bogo", option: "Buy one get one" }],
    usedList: [{id:"one_order",option:"One Order"}, {id:"all_order",option:"All Order"}, {id:"specific_number_of_orders",option:"Specific Number of Orders"}],

    areaList: [],
    storeList: [],
    regionList: [],
    productList: [],
    sectionList: [],
    serviceList: [],
    platformList: [],
    excludeDayList: [],
    homeCleanProductList: [],
    isSaveLoader: false,
    error: {
      used: "",
      area: "",
      region: "",
      status: "",
      stores: "",
      end_date: "",
      discount: "",
      sections: "",
      services: "",
      products: "",
      minAmount: "",
      maxAmount: "",
      platforms: "",
      promo_code: "",
      start_date: "",
      description: "",
      no_of_orders: "",
      exclude_days: "",
      order_type: "",
      home_clean_products: "",
      discount_type: "",
      available_times: "",
      discount_product_id: ""
    },

    data: {
      id: "",
      attributes: {
        area: [],
        used: "",
        region: [],
        stores: [],
        discount: "",
        sections: [],
        services: [],
        products: [],
        platforms: [],
        minAmount: "",
        maxAmount: "",
        promo_code: "",
        end_date: null,
        description: "",
        status: "active",
        no_of_orders: "",
        start_date: null,
        discount_type: "",
        discount_product_id: {id: "", option: ""},
        exclude_days: [],
        order_type: [],
        home_clean_products: [],
        promo_expires: false,
        available_times: [
          {
            id: null,
            endDate: null,
            startDate: null,
            _custom: true,
            _destroy: false,
          },
        ],
      },
    },
    sectionSelectedData: [],
    regionSelectedData:[],
    areaSelectedData:[],
    sectionLists: [],
    sectionPage: 1,
    regionPage: 1,
    serviceNextPage: 1,
    sectionAutoCompleteValue: "",
    regionAutoCompleteValue:'',
    selectedSectionsForList: [],
    selectedRegionsForList: [],
    selectedAreasForList:[],
    isSectionSelectAll: false,
    isRegionSelectAll:false,
    isAreaSelectAll:false,
    selectedServices: [],
    serviceOptions: [],
    serviceAutoCopmpleteValue: '',
    isServiceSelectAll: false,
    selectedServicesForList: [],
    selectedForAdd: [],
    productListItemsDropdown: [],
    currentProductData: [],
    productNextPage: 1,
    searchQuery: false,
    autoCopmpleteValue:"",
    isSelectedAll: false,
    addSpecSelectedOptionForList: [],

    endDate: null,
    noData: false,
    startDate: null,
    isLoading: true,

    isPlatformSelectAll: false,
    selectedPlatform: [],
    platformSelectedOptionForList: [],

    isStoreSelectAll: false,
    selectedStore: [],
    storeSelectedOptionForList: [],

    isOrderTypeSelectAll: false,
    selectedOrderType: [],
    orderTypeSelectedOptionForList: [],

    isHomeCleanProductSelectAll: false,
    selectedHomeCleanProduct: [],
    homeCleanProductSelectedOptionForList: [],

    isExcludeDaysSelectAll: false,
    selectedExcludeDays: [],
    excludeDaysSelectedOptionForList: []

    // Customizable Area End
  };

  async componentDidMount() {
    super.componentDidMount();

    // Customizable Area Start
    this.getSectionList();
    this.getHomeCleanProductList();
    this.getPlatformList();
    this.getRegionList();
    this.getStoreList();
    this.getExcludeDayList();
    this.getProductDropDownListItem("")

    if (!this.isEdit) {
      this.setState({ isLoading: false });
      this.getPromoCodeInitialApi();
    } else {
      this.getPromoCodeItemApi();
    }

    // Customizable Area End
  }

  receive(from: string, message: Message): void {
    // Customizable Area Start
    const requestId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    const successResponse = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    switch (requestId) {
      case this.promoCodeShowMessageId:
        if (successResponse?.data) {
          let data = successResponse.data;
          data.attributes.available_times = data.attributes.available_times.map(
            ({ start_time, end_time, id }: ITimeSlotExtended) => {
              return {
                id,
                _destroy: false,
                endDate: saudiTime(end_time),
                startDate: saudiTime(start_time),
              };
            }
          );
         
          data.attributes.available_times.push({});
          
          data.attributes.area = data.attributes.areas.map(
            (item: IOption) => item.id
          );
          data.attributes.region = data.attributes.regions.map(
            (item: IOption) => item.id
          );
          data.attributes.start_date = moment(
            this.getDateFormat(data.attributes.start_date)
          );
          data.attributes.end_date = moment(
            this.getDateFormat(
              data.attributes.end_date || moment().format("DD/MM/YYYY")
            )
          );
          data.attributes.minAmount = data.attributes.min_order_amount;
          data.attributes.maxAmount = data.attributes.maximum_discount;
          data.attributes.discount_product_id = data.attributes.discount_product?.id ? {id: String(data.attributes.discount_product?.id), option: data.attributes.discount_product?.option }: {};

          this.setState({ isLoading: false, noData: false, data });
          let areaQueryString = data.attributes.region
            .map((item: string | number) => `&region_id[]=${item}`)
            .join("");
          let storeQueryString = data.attributes.area
            .map((item: string | number) => `&area_id[]=${item}`)
            .join("");
          let serviceQueryString = data.attributes.region
            .map((item: string | number) => `&category_id[]=${item}`)
            .join("");
          this.getAreaList(areaQueryString);
          this.getStoreList(storeQueryString);
          this.getProductList(data.attributes.sections);
          this.getServicesList(serviceQueryString);
          this.getProductDropDownListItem(data.attributes.sections)
          this.getServiceDropDownListItem(data.attributes.sections)
          this.getSectionList()
          this.setState({
            sectionSelectedData: this.getCommonSelectedData(
              data.attributes.sections.map((section: IOption) => {return {id: section.id, option: section.name}}),
              data.attributes.is_all_section_selected),
            regionSelectedData: data.attributes.regions.map((region: any) => {return {id: Number(region.id), option: region.branch_name}}),
            selectedRegionsForList: data.attributes.regions.map((region: any) => {return {id: Number(region.id), option: region.branch_name}}),    
            isServiceSelectAll: data.attributes.is_all_service_selected,
            isSectionSelectAll: data.attributes.is_all_section_selected,
            selectedAreasForList: this.getProductSelectedData(data.attributes.areas.map((area: {id:string,area_name:string}) => {return {id: area.id, option: area.area_name}}),data.attributes.is_all_area_selected),
            areaSelectedData: this.getCommonSelectedData(data.attributes.areas.map((area: {id:string,area_name:string}) => {return {id: area.id, option: area.area_name}}),data.attributes.is_all_area_selected), 
            isAreaSelectAll: data.attributes.is_all_area_selected,
            selectedSectionsForList: this.getProductSelectedData(
              data.attributes.sections.map((section: IOption) => {return {id: section.id, option: section.name}}),
              data.attributes.is_all_section_selected),
              isStoreSelectAll:data.attributes.is_all_store_selected,
            selectedServices: this.getCommonSelectedData(
              data.attributes.services.map((section: IOption) => {return {id: String(section.id), option: section.name}}),
              data.attributes.is_all_service_selected),
            selectedServicesForList: this.getProductSelectedData(
              data.attributes.services.map((section: IOption) => {return {id: String(section.id), option: section.name}}),
              data.attributes.is_all_service_selected),
            selectedForAdd:data.attributes.is_all_product_selected?  [{ id: "All", option: "Select All" }]  :data.attributes.products.map((section: IOption) => {return {id: String(section.id), option: section.name}}),
            isSelectedAll: data.attributes.is_all_product_selected ?  true : false,
            addSpecSelectedOptionForList: this.getProductSelectedData(data.attributes.products.map((section: IOption) => {return {id: String(section.id), option: section.name}}),data.attributes.is_all_product_selected),
            selectedPlatform: data.attributes.platforms.map((item: IOption) => ({id: item.id, option: item.name})),
            platformSelectedOptionForList:data.attributes.platforms.map((item: IOption) => ({id: item.id, option: item.name})),
            selectedStore: this.getCommonSelectedData(
              data.attributes.stores.map((item: IOption) => ({id: item.id, option: item.store_name})),
              data.attributes.is_all_store_selected),
            storeSelectedOptionForList: this.getProductSelectedData(
              data.attributes.stores.map((item: IOption) => ({id: item.id, option: item.store_name})),
              data.attributes.is_all_store_selected),
            selectedOrderType: data.attributes.order_type !== null ? data.attributes.order_type.map((item:string) => ({id: item, option: this.formatString(item)})) : [],
            orderTypeSelectedOptionForList: data.attributes.order_type !== null ? data.attributes.order_type.map((item:string) => ({id: item, option: this.formatString(item)})) : [],

            selectedHomeCleanProduct: data.attributes.home_cleaning_products.map((item: IOption) => ({id: item.id, option: item.product_name})) || [],
            homeCleanProductSelectedOptionForList: data.attributes.home_cleaning_products.map((item: IOption) => ({id: item.id, option: item.product_name})) || [],
            
            selectedExcludeDays:data.attributes.exclude_days !== null ?  this.getSelectedExcludeDays(data.attributes.exclude_days.length,data.attributes.exclude_days.map((item:{id:number,day:string}) => ({id: item.id, option: item.day}))) : [],
            excludeDaysSelectedOptionForList: this.getExcludeDaysStateData(data.attributes.exclude_days),
           
            isExcludeDaysSelectAll : this.state.excludeDayList.length === data.attributes.exclude_days.length
          })
        } else if (successResponse?.error) {
          this.setState({ isLoading: false, noData: true });
          setTimeout(() => this.props.navigation.navigate("PromoCode"), 2000);
        }
        break;

      case this.productMessageId:
        if (!successResponse || !successResponse.data) return;
        this.setState({
          productList: successResponse?.data.map((item: ICommonResponse) => ({
            id: item.attributes.id,
            name: item.attributes.product_name,
            option: item.attributes.product_name,
            services:
              item?.attributes?.services?.data?.map(
                (item: { id: string | number }) => item.id
              ) || [],
          })),
        });
        this.handleResponseForProductList(from, message)
        break;

      case this.regionMessageId:
        this.setState({
          regionList:
            successResponse.data.map((item: ICommonResponse) => ({
              id: item.attributes.id,
              name: item.attributes.branch_name,
            })) || [],
        });
        break;

      case this.areaMessageId:
        this.setState({
          areaList:
            successResponse.data.map((item: ICommonResponse) => ({
              id: item.attributes.id,
              name: item.attributes.area_name,
            })) || [],
        });
        break;

      case this.storeMessageId:
        this.setState({
          storeList:
            successResponse.data.map((item: ICommonResponse) => ({
              id: item.attributes.id,
              option: item.attributes.store_name,
            })) || [],
        });
        break;

      case this.sectionMessageId:
        this.setState({
          sectionList:
            successResponse.data.map((item: ICommonResponse) => ({
              id: item.attributes.id,
              name: item.attributes.section_name,
            })) || [],
          sectionLists: uniqBy([...this.state.sectionLists, ...successResponse.data.map((item: ICommonResponse) => ({
            id: item.attributes.id,
            option: item.attributes.section_name,
          }))], "id")
        });
        break;

      case this.homeCleanProductListMessageId:
        this.setState({
          homeCleanProductList:
            successResponse.data.map((item: { id: number, product_name: string }) => ({
              id: item.id,
              option: item.product_name,
            })) || [],
        });
        break;

      case this.platformMessageId:
        this.setState({
          platformList: successResponse.data.map((item: ICommonResponse) => ({
            id: item.attributes.id,
            option: item.attributes.name,
          })),
        });
        break;

      case this.servicesMessageId:
        this.setState({
          serviceList: successResponse.data
            .map((item: ICommonResponse) => ({
              id: item.attributes.id,
              name: item.attributes.name,
            }))
        });
       this.handleResponseForServiceItemList(from, message)
        break;

      case this.excludeDayListMessageId:
        if (successResponse) {
          this.setState({
            excludeDayList: successResponse?.data?.map(
              (item: ExcludeDayType) => {
                return {
                id: item.attributes.id,
                option: item.attributes.day
              }}
            ),
          });
        }
        break;

      case this.initialPromoCodeMessageId:
        this.onChangeValue(
          "promo_code",
          successResponse.data.attributes.promo_code
        );
        break;

      case this.saveApiMessageId:
      case this.editApiMessageId:
        if (successResponse) {
          if (!this.hasValidationErrors(successResponse)) {
            this.setState({isSaveLoader:false, saveStatus: Strings.promoSave });
            setTimeout(() => this.props.navigation.navigate("PromoCode"), 1000);
          } else {
            this.setState({isSaveLoader:false, saveStatus: Strings.promoError });
          }
        }
        break;
    }

    // Customizable Area End
  }

  // Customizable Area Start

  getDateFormat(date: string) {
    const [day, month, year] = String(date).split("/");
    return `${year}-${month}-${day}`;
  }
  token = localStorage.getItem('token');

  onAddTime() {
    const newTime = {
      endDate: null,
      startDate: null,
      id: null,
      _destroy: false,
      _custom: true,
    };

    const tempData = { ...this.state.data };
    const availableTimes = [...tempData.attributes.available_times];
    let error = this.state.error;
    if (
      !availableTimes[availableTimes.length - 1].startDate ||
      !availableTimes[availableTimes.length - 1].endDate
    ) {
      error.available_times = errorMessages.selectAvailableTime;
      this.setState({ error });
      return;
    } else {
      error.available_times = "";
      this.setState({ error });
    }

    availableTimes.push(newTime);

    tempData.attributes.available_times = availableTimes;
    this.setState({
      data: {
        ...tempData,
      },
    });
  }
  onRemoveTime(id: number | null, index: number) {
    if (!id) {
      let times = JSON.parse(
        JSON.stringify([
          ...this.state.data.attributes.available_times.filter(
            (item: { _destroy: boolean }) => !item._destroy
          ),
        ])
      );
      times.splice(index, 1);

      this.onChangeValue("available_times", times);
    } else {
      let times = this.state.data.attributes.available_times.map(
        (item: { id: null | number | string; _destroy: boolean }) => {
          if (item.id == id) item._destroy = true;
          return item;
        }
      );
      this.onChangeValue("available_times", times);
    }
  }

  getTimes() {
    let times = [];

    times.push("12 : 00 AM");
    for (let i = 1; i <= 11; i++) {
      let time = String(i).padStart(2, "0") + " : 00 AM";
      times.push(time);
    }

    times.push("12 : 00 PM");
    for (let i = 1; i <= 12; i++) {
      let time = String(i).padStart(2, "0") + " : 00 PM";
      times.push(time);
    }

    return times;
  }

  getExcludeDaysStateData = (exclude_days:{id:number,day:string}[]) => {
    const exlcudeDays = exclude_days.map((item:{id:number,day:string}) => ({id: item.id, option: item.day}))
    let updateStateValue: {id:number|string,option:string}[]
    if(exclude_days !== null) {
      if(this.state.excludeDayList.length === exclude_days.length){
        updateStateValue = [{ id: "-1", option: "Select All" }, ...exlcudeDays]
      }else{
        updateStateValue = exlcudeDays
      }
    }else{
      updateStateValue = []
    }
    return updateStateValue
  }

  getProductSelectedData = (data:{id:string,option:string}[],isSelectAll:boolean) => {
    if(isSelectAll){
      return  [{ id: "-1", option: "Select All" }, ...data]
    }else{
      return data
    }
  }

  getSelectedExcludeDays = (exlcudeDayLength:number , data:{id:string,option:string}[]) => {
    if(exlcudeDayLength === 7){
      return [{ id: "-1", option: "Select All" }]
    }else{
      return data
    }

  }

  getCommonSelectedData = (data:any,isSelectAll:boolean) => {
    if(isSelectAll){
      return  [{ id: "All", option: "Select All" }]
    }else{
      return data
    }
  }

  getPromoCodeItemApi() {
    const id = this.props.navigation.getParam("id");
    const requestMessage = MakeApiMessage({
      url: ApiUrls.promoCodeShow + id,
      method: "get",
    });

    this.promoCodeShowMessageId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  getSectionList = () => {
    const headers = {
        "Content-Type": configJSON.validationApiContentType,
        token: this.token,
    };

    const sectionListDataMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );

    this.sectionMessageId = sectionListDataMessage.messageId;
    const searchQuery = this.state.sectionAutoCompleteValue ? `&filter_by[query]=${this.state.sectionAutoCompleteValue}` : ''

    sectionListDataMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.getSectionListsAPIEndPoint}?dropdown=true${searchQuery}&page_no=${this.state.sectionPage}&per_page=${this.itemsPerPage}`
    );

    sectionListDataMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
    );

    sectionListDataMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.validationApiMethodType
    );
    runEngine.sendMessage(sectionListDataMessage.id, sectionListDataMessage);
}

  getHomeCleanProductList() {
    let requestMessage = MakeApiMessage({
      url: ApiUrls.homeCleanProductDropdownList + "?drop_down=true&dropdown=true",
      method: configJSON.getPromocodesAPIMethod,
    });
    this.homeCleanProductListMessageId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getRegionList() {

    let requestMessage = MakeApiMessage({
      url: ApiUrls.regionList,
      headers: { token: localStorage.getItem("token") },
      method: "get",
    });
    this.regionMessageId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getAreaList(queryParam: string = "") {
    let requestMessage = MakeApiMessage({
      url: ApiUrls.areaList + queryParam,
      headers: { token: localStorage.getItem("token") },
      method: "get",
    });
    this.areaMessageId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getStoreList(queryParam: string = "") {
    let requestMessage = MakeApiMessage({
      url: ApiUrls.storeList + localStorage.getItem("token") + queryParam,
      method: "get",
    });
    this.storeMessageId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getServicesList(queryParam: string) {
    let requestMessage = MakeApiMessage({
      url: ApiUrls.servicesList + "?dropdown=true" + queryParam,
      method: "get",
    });
    this.servicesMessageId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  getServiceDropDownListItem = (query: any) => {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.token
    };

    const serviceItemDataMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.servicesMessageId = serviceItemDataMessage.messageId;

    const sectionSelectedIds = this.state.sectionSelectedData.filter((item: Option) => item.id !== "-1").map((selectedSection: Option) => selectedSection.id)

    const _sectionSelectedIds = Array.isArray(query) && query.length >0 ? query.filter((item: Option )=> item.id !== "-1").map((item: Option) => item.id): [];

    const _query = Array.isArray(query) && query.length >0 ? _sectionSelectedIds : sectionSelectedIds

    const endpoint = !this.state.isSectionSelectAll ?
      configJSON.getServiceListDropDownEndpoint + `&filter_by[section_ids][]=${_query}` :
      configJSON.getServiceListDropDownEndpoint 
    const searchQuery = this.state.serviceAutoCopmpleteValue ? `&filter_by[query]=${this.state.serviceAutoCopmpleteValue}` : ''

    serviceItemDataMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${endpoint}${searchQuery}&page_no=${this.state.serviceNextPage}&per_page=${this.itemsPerPage}`
    );

    serviceItemDataMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    serviceItemDataMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetType
    );

    runEngine.sendMessage(serviceItemDataMessage.id, serviceItemDataMessage);
  }

  getProductList(sectionIds: Array<string | number>) {
    let filter = `&filter_by[section_ids]=${sectionIds.join(
      ","
    )}&page_no=1&per_page=1000`;
    let requestMessage = MakeApiMessage({
      url: ApiUrls.productList + filter,
      method: "get",
    });
    this.productMessageId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getProductDropDownListItem = (searchQuery: any) => {
    this.setState({ searchQuery: searchQuery.length < 1 ? true : false });
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.token
    };

    const productListDataMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.productMessageId = productListDataMessage.messageId;

    const searchQueryString = this.state.autoCopmpleteValue ? `&filter_by[query]=${this.state.autoCopmpleteValue}` : ''
    const sectionSelectedIds = this.state.sectionSelectedData.filter((item: Option )=> item.id !== "-1").map((item: Option) => item.id)
    const _sectionSelectedIds = Array.isArray(searchQuery) && searchQuery.length >0 ? searchQuery.filter((item: Option )=> item.id !== "-1").map((item: Option) => item.id) : []

    const query = Array.isArray(searchQuery) && searchQuery.length >0 ? _sectionSelectedIds : sectionSelectedIds

    const filterBySectionIds = !this.state.isSectionSelectAll ? `&filter_by[section_ids][]=${query}` : "";

    productListDataMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.productListDropdownApiEndPoint}${searchQueryString}${filterBySectionIds}&page_no=${this.state.productNextPage}`
    );

    productListDataMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    productListDataMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetType
    );
    this.disableLoadMorePriceList = true;
    runEngine.sendMessage(productListDataMessage.id, productListDataMessage);
  }

  getPlatformList() {
    let requestMessage = MakeApiMessage({
      url: ApiUrls.platformList,
      method: "get",
    });
    this.platformMessageId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getExcludeDayList() {
    let requestMessage = MakeApiMessage({
      url: ApiUrls.excludeDayList,
      method: "get",
    });
    this.excludeDayListMessageId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  onChangeRegion(values: Array<string | number>) {
    this.setState({ areaSelectedData : [],selectedAreasForList:[]})
    const regionSelectedIds = this.state.selectedRegionsForList.filter((item: Option )=> item.id !== "-1").map((item: Option) => item.id)
    let data = this.state.isRegionSelectAll ? regionSelectedIds : values

    this.onChangeValue("region", data);
    this.onChangeValue("area", []);
    const queryString = data
      .map((item: string | number) => `&region_id[]=${item}`)
      .join("") ;
    this.getAreaList(queryString ? queryString : `&region_id[]=`);
  }

  onChangeArea(values: Array<string | number>) {
    const areaSelectedIds = this.state.selectedAreasForList.filter((item: Option )=> item.id !== "-1").map((item: Option) => item.id)
    let data = this.state.isAreaSelectAll ? ['All'] : values
    let data1 = this.state.isAreaSelectAll ? areaSelectedIds : values
    this.onChangeValue("area", data);
    this.onChangeValue("stores", []);
    const queryString = data1
      .map((item: string | number) => `&area_id[]=${item}`)
      .join("");
    this.getStoreList(queryString ? queryString :`&area_id[]=` );
  }

  onChangeValue(key: string, value: any) {
    let data = this.state.data;
    if (key === "platforms" && !value.find((v: number) => v == 1)) {
      data.attributes.stores = [];
    }
    data.attributes = {
      ...data.attributes,
      [key]: value as string,
    };
    this.setState({ data });
  }

  isValid(): boolean {
    const {
      available_times,
      discount_type,
      discount_product_id,
      promo_expires,
      no_of_orders,
      description,
      promo_code,
      end_date,
      minAmount,
      maxAmount,
      start_date,
      used,
      region,
      discount,
      area,
    } = this.state.data.attributes;

    let error: { [field: string]: string | Array<string> } = {};
    function saveError(key: string, value: string) {
      error[key] = value;
    }

    if (!description) saveError("description", errorMessages.enterDescription);
    if (!promo_code) saveError("promo_code", errorMessages.enterPromoCode);
    if (!this.state.sectionSelectedData.length) saveError("sections", errorMessages.selectSection);
    if (!this.state.selectedServices.length) saveError("services", errorMessages.selectServices);
    if (!this.state.selectedForAdd.length) saveError("products", errorMessages.selectProducts);
    if (!this.state.selectedPlatform.length)
      saveError("platforms", errorMessages.selectPlatforms);
    if (!used.length) saveError("used", errorMessages.selectUsed);
    if (!region.length) saveError("region", errorMessages.selectRegion);
    if (!area.length) saveError("area", errorMessages.selectArea);
    if (!no_of_orders && used === "specific_number_of_orders") {
      saveError("no_of_orders", errorMessages.selectNoOfOrders);
    }
    if (parseInt(no_of_orders) < 1 && used === "specific_number_of_orders") {
      saveError("no_of_orders", errorMessages.noOfOrdersInvalid);
    }
    if (!start_date) saveError("start_date", errorMessages.selectStartDate);
    
    if (!end_date && Boolean(promo_expires))
      saveError("end_date", errorMessages.selectEndDate);

    if (!discount_type)
      saveError("discount_type", errorMessages.selectDiscountType);
    if (minAmount && parseFloat(minAmount) < 1 && discount_type !== "bogo")
      saveError("minAmount", errorMessages.amountInvalid);
    if (maxAmount && parseFloat(maxAmount) < 1 && discount_type !== "bogo")
      saveError("maxAmount", errorMessages.amountInvalid);

    if (!discount && discount_type !== "bogo") {
      saveError("discount", errorMessages.enterAmountRequired);
    }
    if (parseFloat(discount) < 1 && discount_type !== "bogo") {
      saveError("discount", errorMessages.enterAmountInvalid);
    }

    if (discount_type === "bogo" && !discount_product_id.id)
      saveError("discount_product_id", errorMessages.selectDiscountProduct);

    if (available_times.length == 1 && (!available_times[0].startDate && !available_times[0].endDate))
      saveError("available_times", errorMessages.selectAvailableTime);
    if (available_times.length) {
      for (const element of available_times.slice(0, -1)) {
        if (!element?.startDate || !element.endDate)
          saveError("available_times", errorMessages.selectAvailableTime);
      }
    }

    const hasErrors = Object.keys(error).length > 0;
    this.setState({
      error,
      saveStatus: hasErrors ? errorMessages.checkErrors : "",
    },()=>console.log(error,"eroror",discount_type));

    return !hasErrors;
  }

  onSubmit() {
    if (!this.isValid()) return;
    this.setState({isSaveLoader:true})
    const {
      used,
      region,
      area,
      description,
      promo_code,
      start_date,
      end_date,
      minAmount,
      maxAmount,
      no_of_orders,
      discount_type,
      discount_product_id,
      promo_expires,
      available_times,
      status,
      discount,
    } = this.state.data.attributes;

    let body: {
      data: {
        [field: string]:
          | null
          | string
          | number
          | boolean
          | Array<string | undefined | null | any>;
      };
    } = {
      
      data: {
        status,
        discount_type,
        discount_product_id: discount_product_id?.id,
        description,
        promo_code,
        used,
        region_ids: region,
        area_ids: area,
        start_date: moment(start_date).format("DD-MM-YYYY"),
        promo_expires,
        min_order_amount: this.handleAmountOrNull(minAmount),
        maximum_discount: this.handleAmountOrNull(maxAmount),
        section_ids: this.getSectionData(),
        store_ids: this.getStoreData(),
        product_ids: this.getProductData(),
        service_ids: this.getServiceData(),
        platform_ids: this.getPlatformData(),
        discount: discount,
        order_type: this.getOrderTypeData(),
        home_cleaning_product_ids: this.getHomeCleaningProducteData(),
        exclude_day_ids: this.getExcludeDaysData(),
        available_times_attributes: available_times.map(
          (
            { startDate, endDate, _destroy, _custom, id },
            index,
            available_times
          ) => {
            if (this.handleCheckIsAvailableTime(available_times, index)) {
              return {
                id: !_custom ? id : undefined,
                start_time: startDate,
                end_time: endDate,
                _destroy: _destroy && !_custom ? true : undefined,
              };
            } else {
              return null;
            }
          }
        ),
      },
    };

    if (used === "specific_number_of_orders")
      body.data.no_of_orders = no_of_orders;

    body.data.end_date = Boolean(promo_expires)
      ? moment(end_date).format("DD-MM-YYYY")
      : "";

    let stringifyBody = JSON.stringify(body);

    this.isEdit ? this.onEditApi(stringifyBody) : this.onSaveApi(stringifyBody);
  }
  getSectionData(){
    let selectedIds = this.state.selectedSectionsForList.filter((item: Option )=> item.id !== "-1").map((item: Option) => item.id)
    let data = this.state.isSectionSelectAll ? ['All'] : selectedIds    
    return data
  }
  getServiceData(){
    let data = this.state.isServiceSelectAll ? 
    ['All'] :
    this.state.selectedServices.map((data: Option) => data.id)
    return data
  }
  getPlatformData(){
    let selectedIds = this.state.platformSelectedOptionForList.filter((item: Option )=> item.id !== "-1").map((item: Option) => item.id)
    let data = this.state.isPlatformSelectAll ? 
    selectedIds :
    this.state.selectedPlatform.map((data: Option) => data.id)
    return data
  }
  getStoreData(){
    let data = this.state.isStoreSelectAll ? 
    ['All'] :
    this.state.selectedStore.map((data: Option) => data.id)
    return data
  }
  getOrderTypeData(){
    let selectedIds = this.state.orderTypeSelectedOptionForList.filter((item: Option )=> item.id !== "-1").map((item: Option) => item.id)
    let data = this.state.isOrderTypeSelectAll ? 
    selectedIds :
    this.state.selectedOrderType.map((data: Option) => data.id)
    return data
  }
  getHomeCleaningProducteData(){
    let selectedIds = this.state.homeCleanProductSelectedOptionForList.filter((item: Option )=> item.id !== "-1").map((item: Option) => item.id)
    let data = this.state.isHomeCleanProductSelectAll ? 
    selectedIds :
    this.state.selectedHomeCleanProduct.map((data: Option) => data.id)
    return data
  }
  getExcludeDaysData(){
    let selectedIds = this.state.excludeDaysSelectedOptionForList.filter((item: Option )=> item.id !== "-1").map((item: Option) => item.id)
    let data = this.state.isExcludeDaysSelectAll ? 
    selectedIds :
    this.state.selectedExcludeDays.map((data: Option) => data.id)
    return data
  }
  handleAmountOrNull(str: string) {
    return str || null;
  }

  getProductData(){
    let pIDS = this.state.isSelectedAll ? ['All'] : this.state.selectedForAdd.map((data: Option) => data.id)
    return pIDS
  }
  handleCheckIsAvailableTime(available_times: AvailTimeObj[], index: number) {
    return !isEmpty(available_times[index]) && available_times[index].startDate !== null
  }
  onSaveApi(body: any) {
    let requestMessage = MakeApiMessage({
      url: ApiUrls.savePromoCode,
      method: "POST",
      body,
    });
    this.saveApiMessageId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  clickCancel=()=>{
    this.props.navigation.navigate("PromoCode")
  }
  onEditApi(body: any) {
    let id = this.props.navigation.getParam("id");
    let requestMessage = MakeApiMessage({
      url: ApiUrls.editPromoCode + id,
      method: "PUT",
      body,
    });
    this.editApiMessageId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  hasValidationErrors(response: { data: ICommonResponse, errors: string }) {
    let errors = response?.data?.attributes?.errors as {
      [field: string]: string | Array<string>;
    } ?? response.errors;
    if (!errors) return false;

    Object.keys(errors).forEach((key: string) => {
      errors[key] = Array.isArray(errors[key])
        ? (errors[key] as Array<string>).join(", ")
        : errors[key];
    });

    this.setState({ error: errors as { [field: string]: string } });
    return true;
  }
  
  handlePromocodeTime(key: string, index: number, val: string) {
    let tempData = { ...this.state.data };
    let temp: any = [
      ...tempData.attributes.available_times.filter(
        (item: any) => !item._destroy
      ),
    ];
    temp[index][key] = val;
    this.setState({ data: { ...tempData } });
    return temp
  }

  onChangeSection(array: Array<string | number | never>) {
    
    if (array.includes("selectAll")) return;
    if (array.length) this.getProductList(array);
    else this.onChangeValue("products", []);
    this.onChangeValue("sections", array);
    this.onChangeValue("services", []);
    const queryString = array
      .map((item: string | number) => `&filter_by[section_ids][]=${item}`)
      .join("");
    this.getServicesList(queryString)
  }

  onChangeStore(array: Array<string>) {
    if (array.includes("selectAll")) return;
    this.onChangeValue("stores", array);
  }

  onChangeProduct(array: Array<string>) {
    if (array.includes("selectAll")) return;
    this.onChangeValue("products", array);
  }

  onChangeHomeCleanProduct(array: Array<string>) {
    if (array.includes("selectAll")) return;
    this.onChangeValue("home_clean_products", array);
  }
  
  onChangeServices(array: Array<string>) {
    if (array.includes("selectAll")) return;
    this.onChangeValue("services", array);
  }

  getPromoCodeInitialApi() {
    let requestMessage = MakeApiMessage({
      url: ApiUrls.getInitialPromoCode,
      method: "get",
    });
    this.initialPromoCodeMessageId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handlePlatformMultiSelect = (value: { id: string, option: string }[], option: { id: string, option: string }) => {
    console.log(value,option)
    let updateStateData: Partial<State> = {}
    if (option.id == "-1") {
      updateStateData = this.state.isPlatformSelectAll ?
        { ...updateStateData, isPlatformSelectAll: false, selectedPlatform: [], platformSelectedOptionForList: [] }
        :
        {
          ...updateStateData,
          isPlatformSelectAll: true,
          selectedPlatform: [{ id: "All", option: "Select All" }],
          platformSelectedOptionForList: [{ id: "-1", option: "Select All" }, ...this.state.platformList]
        };

    } else {
      const isValueContained = value.some((item) => item.id == option.id)
      value = value.filter((item) => item.id !== "-1")
      const newItems = isValueContained ? value.filter((item) => item.id != option.id) : [...value, option]
      updateStateData = updateMultiSelectData(
        newItems,
        this.state.platformList,
        'isPlatformSelectAll',
        'selectedPlatform',
        'platformSelectedOptionForList',
      )
    }
    this.setState(updateStateData as Pick<State, keyof State>, () => {})
  };

  handleSectionMultiSelect = (value: { id: string, option: string }[], option: { id: string, option: string }) => {
    let updateStateData: Partial<State> = {}
    if (option.id == "-1") {
      updateStateData = this.state.isSectionSelectAll ?
        { ...updateStateData, isSectionSelectAll: false, sectionSelectedData: [], selectedSectionsForList: [] }
        :
        {
          ...updateStateData,
          isSectionSelectAll: true,
          sectionSelectedData: [{ id: "All", option: "Select All" }],
          selectedSectionsForList: [{ id: "-1", option: "Select All" }, ...this.state.sectionLists]
        };

    } else {
      const isValueContained = value.some((item) => item.id == option.id)
      value = value.filter((item) => item.id !== "-1")
      const newItems = isValueContained ? value.filter((item) => item.id != option.id) : [...value, option]
      updateStateData = updateMultiSelectData(
        newItems,
        this.state.sectionLists,
        'isSectionSelectAll',
        'sectionSelectedData',
        'selectedSectionsForList',
      )
    }
    this.setState(updateStateData as Pick<State, keyof State>, () => {
      this.setState({isSelectedAll:false, isServiceSelectAll:false, selectedServices: [],selectedServicesForList:[],selectedForAdd:[],addSpecSelectedOptionForList:[]})
      this.getServiceDropDownListItem("")
      this.getProductDropDownListItem("")
    })
    
  };
  handleRegionMultiSelect = (value: { id: string, option: string }[], option: { id: string, option: string }) => {
    let updateStateData: Partial<State> = {}
    if (option.id == "-1") {
      updateStateData = this.state.isRegionSelectAll ?
        { ...updateStateData, isRegionSelectAll: false, regionSelectedData: [], selectedRegionsForList: [] }
        :
        {
          ...updateStateData,
          isRegionSelectAll: true,
          regionSelectedData: [{ id: "All", option: "Select All" }],
          selectedRegionsForList: [{ id: "-1", option: "Select All" }, ...this.state.regionList.map((item:any)=>{return {id: item.id, option: item.name}})]
        };

    } else {
      const isValueContained = value.some((item) => item.id == option.id)
      value = value.filter((item) => item.id !== "-1")
      const newItems = isValueContained ? value.filter((item) => item.id != option.id) : [...value, option]
      updateStateData = updateMultiSelectData(
        newItems,
        this.state.regionList.map((item:any)=>{return {id: item.id, option: item.name}}),
        'isRegionSelectAll',
        'regionSelectedData',
        'selectedRegionsForList',
      )
    }
    this.setState(updateStateData as Pick<State, keyof State>, () => {
      this.onChangeRegion(this.state.regionSelectedData?.map((item:any)=> item.id))
    })
  };

  handleAreaMultiSelect = (value: { id: string, option: string }[], option: { id: string, option: string }) => {
    let updateStateData: Partial<State> = {}
    if (option.id == "-1") {
      updateStateData = this.state.isAreaSelectAll ?
        { ...updateStateData, isAreaSelectAll: false, areaSelectedData: [], selectedAreasForList: [] }
        :
        {
          ...updateStateData,
          isAreaSelectAll: true,
          areaSelectedData: [{ id: "All", option: "Select All" }],
          selectedAreasForList: [{ id: "-1", option: "Select All" }, ...this.state.areaList.map((item:any)=>{return {id: item.id, option: item.name}})]
        };

    } else {
      const isValueContained = value.some((item) => item.id == option.id)
      value = value.filter((item) => item.id !== "-1")
      const newItems = isValueContained ? value.filter((item) => item.id != option.id) : [...value, option]
      updateStateData = updateMultiSelectData(
        newItems,
        this.state.areaList.map((item:any)=>{return {id: item.id, option: item.name}}),
        'isAreaSelectAll',
        'areaSelectedData',
        'selectedAreasForList',
      )
    }
    this.setState(updateStateData as Pick<State, keyof State>, () => {
      this.setState({isStoreSelectAll:false,selectedStore:[],storeSelectedOptionForList:[]})
      this.onChangeArea(this.state.selectedAreasForList.filter((area:{id: string})=> area.id != "-1").map((item:{id: string})=> item.id))
    })
  };
  handleSectionAutoCompleteChange = (getValue: string) => {
    if (getValue === this.state.sectionAutoCompleteValue) return;
    this.setState({ sectionAutoCompleteValue: getValue, sectionPage: 1 }, () => {
        if (getValue.length < 1 || getValue.length > 2) {
        this.getSectionList()
        }
    });
}


handleRegionAutoCompleteChange = (getValue: string) => {
 
}

handleServiceAutoCompleteChange = (getValue: string) => {
  if (getValue === this.state.serviceAutoCopmpleteValue) return;
  this.setState({ serviceAutoCopmpleteValue: getValue, serviceNextPage: 1 });
  if (getValue.length < 1 || getValue.length > 2) {
    if (getValue.length < 1 || getValue.length > 2) {
      this.getServiceDropDownListItem(getValue)
    }
  }
}

  debouncedFunction = debounce(
    (newInputValue: string, inputFunction: (inputValue: string) => void) => inputFunction(newInputValue),
        700,
        { maxWait: 2000 }
    );

    handleScrollSectionDropdown = (event: React.SyntheticEvent) => {
      if (this.disableLoadMoreSection) return;
      const checkListboxNode = event.currentTarget;
      const boxPosition = checkListboxNode.scrollTop + checkListboxNode.clientHeight;
  
      if (checkListboxNode.scrollHeight - boxPosition <= 1.30) {
        this.setState((prevState) => ({ sectionPage: prevState.sectionPage + 1 }), () => {
          this.getSectionList()
        })
      }
    };
    handleScrollRegionDropdown = (event: React.SyntheticEvent) => {
     
    };

    handleScrollServiceDropdown = (event: React.SyntheticEvent) => {
      if (this.disableLoadMoreService) return;
      const checkListboxNode = event.currentTarget;
      const boxPosition = checkListboxNode.scrollTop + checkListboxNode.clientHeight;
  
      if (checkListboxNode.scrollHeight - boxPosition <= 1.30) {
        this.setState((prevState) => ({ serviceNextPage: prevState.serviceNextPage + 1 }), () => {
          this.getServiceDropDownListItem("")
        })
      }
    };
    handleServiceClear = () => {
      let updateStateData: Partial<State> = {}
      updateStateData = {
        ...updateStateData,
        selectedServices: [],
        selectedServicesForList: [],
        isServiceSelectAll: false,
      }
      this.setState(updateStateData as Pick<State, keyof State>, () => {})
    }
    handleSelectServiceOptions = (value: Option[], option: Option) => {
      let updateStateData: Partial<State> = {}
      if (option.id == "-1") {
        updateStateData = this.state.isServiceSelectAll ?
          {
            ...updateStateData,
            isServiceSelectAll: false,
            selectedServices: [],
            selectedServicesForList: []
          }
          :
          {
            ...updateStateData,
            isServiceSelectAll: true,
            selectedServices: [{ id: "All", option: "Select All" }],
            selectedServicesForList: [{ id: "-1", option: "Select All" }, ...this.state.serviceOptions]
          }
      } else {
        const isValueContained = value.some((item) => item.id == option.id)
        updateStateData.isServiceSelectAll = false
        value = value.filter((item) => item.id !== "-1")
        const newItems = isValueContained ? value.filter((item) => item.id != option.id) : [...value, option]
        updateStateData.selectedServices = newItems
        updateStateData.selectedServicesForList = newItems
      }
      this.setState(updateStateData as Pick<State, keyof State>, () => {})
    };

    handleResponseForServiceItemList = (from: string, message: Message) => {
      if (this.servicesMessageId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
        const apiResponse = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        if (apiResponse) {
          const receivedServices = apiResponse.data?.map((item: { id: string; attributes: { name: string; active: boolean; }; }) => (
            {
              id: item.id,
              label: item.attributes.name,
              active: item.attributes.active,
              option: item.attributes.name,
            }
          )) || []
          this.setState({
            serviceOptions: receivedServices.length > 0 ? uniqBy([...this.state.serviceOptions, ...receivedServices], "id") : receivedServices,
            selectedServicesForList: this.state.isServiceSelectAll ?
              uniqBy([...this.state.selectedServicesForList, ...receivedServices], "id")
              : uniqBy(this.state.selectedServicesForList, "id")
          })
          this.disableLoadMoreService = receivedServices.length < this.itemsPerPage
        }
      }
    }

    //Product
    handleSpecificationScrollProductDropdown = (event: React.SyntheticEvent) => {
      if (this.disableLoadMoreSection) return;
      const checkListboxNode = event.currentTarget;
      const boxPosition = checkListboxNode.scrollTop + checkListboxNode.clientHeight;

      const scrollTop = checkListboxNode.scrollTop;
     
      if (
        checkListboxNode.scrollHeight - boxPosition <= 1.30 &&
        !this.disableLoadMorePriceList
        ) {
        this.setState({ productNextPage: this.state.productNextPage + 1 },() => {
          this.getProductDropDownListItem("")
          checkListboxNode.scrollTop = scrollTop;
        })
      }
    };

    handleSpecAutoCompltProductChange = (getValue: string) => {
      if (getValue === this.state.autoCopmpleteValue) return;
      this.setState({ autoCopmpleteValue: getValue, productNextPage: 1 });
      
      this.getProductDropDownListItem(getValue)
    }

    handleSpecificationSelectOptions = (value: { id: string, option: string }[], option: { id: string, option: string }) => {
      if (option.id === "-1") {
        if (this.state.isSelectedAll) {
          this.setState({ isSelectedAll: false, selectedForAdd: [], addSpecSelectedOptionForList: [] });
        } else {
          this.setState({
            isSelectedAll: true,
            selectedForAdd: [{ id: "All", option: "Select All" }],
            addSpecSelectedOptionForList: [{ id: "-1", option: "Select All" }, ...this.state.productListItemsDropdown.map((item: IProductItem) => { return { id: item.id.toString(), option: item.option.toString() } })]
          });
        }
        return;
      }
  
      const isValueContained = value.some((item) => item.id == option.id)
  
      if (isValueContained) {
        value = value.filter((item) => item.id !== "-1")
        this.setState({ addSpecSelectedOptionForList: value.filter((item) => item.id != option.id), selectedForAdd: value.filter((item) => item.id != option.id), isSelectedAll: false }, () => {})
      } else {
        value = value.filter((item) => item.id !== "-1")
        this.setState({ addSpecSelectedOptionForList: [...value, option], selectedForAdd: [...value, option], isSelectedAll: false }, () => {})
      }
    };

    handleResponseForProductList = (from: string, message: Message) => {
      if (this.productMessageId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
        const apiResponse = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        if (apiResponse) {
          const existingData = this.state.productListItemsDropdown;
          const responseData = apiResponse.data.map((product: ICommonResponse) => ({id: product.id,option: product.attributes.name}))

          this.disableLoadMorePriceList = responseData.length < this.itemsPerPage;

          if( this.state.data.attributes.discount_product_id?.id && this.state.productNextPage === 1) {
            responseData.unshift(this.state.data.attributes.discount_product_id as never);
          }

          this.setState({
            productListItemsDropdown: responseData.length > 0 ? uniqBy(this.handleProductListResAsPerQuery(existingData, responseData), "id"): [],
            // this.state.productNextPage === 1 ? existingData : concat(responseData, existingData)
            currentProductData: responseData
          }, () => {
            this.state.isSelectedAll && this.setState({
              addSpecSelectedOptionForList: [...this.state.addSpecSelectedOptionForList, ...responseData.map((item: IProductItem) => { return { id: item.id, option: item.name } })],
            })
          })
          this.setState({ isLoading: false })
        }
      }

    }

    handleProductListResAsPerQuery(existingData: IProductItem[], responseOptionList: IProductItem[]) {
      if (this.state.searchQuery) {
        return concat(existingData,responseOptionList)
      }
      return responseOptionList
    }

    handleStoreMultiSelect = (value: { id: string, option: string }[], option: { id: string, option: string }) => {
      let updateStateData: Partial<State> = {}
      if (option.id == "-1") {
        updateStateData = this.state.isStoreSelectAll ?
          { ...updateStateData, isStoreSelectAll: false, selectedStore: [], storeSelectedOptionForList: [] }
          :
          {
            ...updateStateData,
            isStoreSelectAll: true,
            selectedStore: [{ id: "All", option: "Select All" }],
            storeSelectedOptionForList: [{ id: "-1", option: "Select All" }, ...this.state.storeList]
          };
  
      } else {
        const isValueContained = value.some((item) => item.id == option.id)
        updateStateData.isStoreSelectAll = false
        value = value.filter((item) => item.id !== "-1")
        const newItems = isValueContained ? value.filter((item) => item.id != option.id) : [...value, option]
        updateStateData.selectedStore = newItems
        updateStateData.storeSelectedOptionForList = newItems
      }
      this.setState(updateStateData as Pick<State, keyof State>, () => {})
    };

    handleOrderTypeMultiSelect = (value: { id: string, option: string }[], option: { id: string, option: string }) => {
      let updateStateData: Partial<State> = {}
      if (option.id == "-1") {
        updateStateData = this.state.isOrderTypeSelectAll ?
          { ...updateStateData, isOrderTypeSelectAll: false, selectedOrderType: [], orderTypeSelectedOptionForList: [] }
          :
          {
            ...updateStateData,
            isOrderTypeSelectAll: true,
            selectedOrderType: [{ id: "All", option: "Select All" }],
            orderTypeSelectedOptionForList: [{ id: "-1", option: "Select All" }, ...this.state.orderType]
          };
  
      } else {
        const isValueContained = value.some((item) => item.id == option.id)
        updateStateData.isOrderTypeSelectAll = false
        value = value.filter((item) => item.id !== "-1")
        const newItems = isValueContained ? value.filter((item) => item.id != option.id) : [...value, option]
        updateStateData.selectedOrderType = newItems
        updateStateData.orderTypeSelectedOptionForList = newItems
      }
      this.setState(updateStateData as Pick<State, keyof State>, () => {})
    };

    formatString(input:string) {
      return input.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)) .join(' ');      
    }

    handleHomeCleaningProductMultiSelect = (value: { id: string, option: string }[], option: { id: string, option: string }) => {
      let updateStateData: Partial<State> = {}
      if (option.id == "-1") {
        updateStateData = this.state.isHomeCleanProductSelectAll ?
          { ...updateStateData, isHomeCleanProductSelectAll: false, selectedHomeCleanProduct: [], homeCleanProductSelectedOptionForList: [] }
          :
          {
            ...updateStateData,
            isHomeCleanProductSelectAll: true,
            selectedHomeCleanProduct: [{ id: "All", option: "Select All" }],
            homeCleanProductSelectedOptionForList: [{ id: "-1", option: "Select All" }, ...this.state.homeCleanProductList]
          };
  
      } else {
        const isValueContained = value.some((item) => item.id == option.id)
        updateStateData.isHomeCleanProductSelectAll = false
        value = value.filter((item) => item.id !== "-1")
        const newItems = isValueContained ? value.filter((item) => item.id != option.id) : [...value, option]
        updateStateData.selectedHomeCleanProduct = newItems
        updateStateData.homeCleanProductSelectedOptionForList = newItems
      }
      this.setState(updateStateData as Pick<State, keyof State>, () => {})
    };

    handleExcludeDaysMultiSelect = (value: { id: string, option: string }[], option: { id: string, option: string }) => {
      let updateStateData: Partial<State> = {}
      if (option.id == "-1") {
        updateStateData = this.state.isExcludeDaysSelectAll ?
          { ...updateStateData, isExcludeDaysSelectAll: false, selectedExcludeDays: [], excludeDaysSelectedOptionForList: [] }
          :
          {
            ...updateStateData,
            isExcludeDaysSelectAll: true,
            selectedExcludeDays: [{ id: "-1", option: "Select All" }],
            excludeDaysSelectedOptionForList: [{ id: "-1", option: "Select All" }, ...this.state.excludeDayList]
          };
      } else {
        const isValueContained = value.some((item) => item.id == option.id)
        updateStateData.isExcludeDaysSelectAll = false
        value = value.filter((item) => item.id !== "-1")
        const newItems = isValueContained ? value.filter((item) => item.id != option.id) : [...value, option]
        updateStateData.selectedExcludeDays = newItems
        updateStateData.excludeDaysSelectedOptionForList = newItems
        const selectedLength = updateStateData.selectedExcludeDays.length
        const totalLength = this.state.excludeDayList.length
        if(selectedLength ===  totalLength){
          updateStateData.isExcludeDaysSelectAll = true
          updateStateData.selectedExcludeDays= [{ id: "-1", option: "Select All" }]
          updateStateData.excludeDaysSelectedOptionForList =[{ id: "-1", option: "Select All" }, ...newItems]
        }else{
          updateStateData.selectedExcludeDays = newItems
          updateStateData.excludeDaysSelectedOptionForList = newItems
        }
      }
      this.setState(updateStateData as Pick<State, keyof State>, () => {})
    };

  handleChangeNumberField = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, property: string) => {
    if (/^[0-9\b.]+$/.test(event.target.value) || event.target.value === "") {
      this.onChangeValue(property, event.target.value);
    }
  }

  // Customizable Area End
}

export default PromoCodeController;
