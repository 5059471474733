import React from "react";
// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import { IBlock } from "../../../framework/src/IBlock";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { IPermissionGroup, ISubPermissionGroup } from "./utils";
export const configJSON = require("./config");
// Customizable Area End

// Customizable Area Start
// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation: unknown;
  id: string;
  isOpenPermissionModal: boolean;
  onClosePermissionModal: Function;
  onContinuePermissionChange: Function;
  permissionGroup: Array<IPermissionGroup>;
  // Customizable Area End
}

export interface S {
  // Customizable Area Start
  permissionGroupList: Array<IPermissionGroup>;
  permissionGroupIds: Array<number>;
  permissionSubGroupIds: Array<number>;
  searchTerm: string;
  // Customizable Area End
}

export interface SS {}

export default class ActionPermissionsModalController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    this.state = {
      permissionGroupList: [],
      permissionGroupIds: [],
      permissionSubGroupIds: [],
      searchTerm: "",
    };
    // Customizable Area End
  }

  async receive(_from: string, message: Message) {
    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    this.handleSetPermissionList();
    // Customizable Area End
  }

  // Customizable Area Start
  handleSetPermissionList = () => {
    const { permissionGroup } = this.props;
    this.setState({
      permissionGroupList: permissionGroup,
      permissionGroupIds: 
        this.handleReturnSelectedPermission(permissionGroup).permission_group_ids,
      permissionSubGroupIds:
        this.handleReturnSelectedPermission(permissionGroup).permission_sub_group_ids
    });
  };

  handleReturnSelectedPermission = (permissionsGroups: Array<IPermissionGroup>) => {
    let permission_group_ids: Array<number> = [];
    let permission_sub_group_ids: Array<number> = [];

    permissionsGroups.forEach((parentPItem) => {
      parentPItem.permission_sub_groups.forEach((subPItem) => {
        if (subPItem.permitted) {
          permission_group_ids.push(parentPItem.id);
          permission_sub_group_ids.push(subPItem.id);
        }
      });
    });

    const outputObj = {
      permission_group_ids:
        this.handleRemoveDuplicates(permission_group_ids),
      permission_sub_group_ids:
        this.handleRemoveDuplicates(permission_sub_group_ids)
    }
    return outputObj;

  };

  handleRemoveDuplicates = (array: Array<number>) => {
    return array.filter((item, index) => array.indexOf(item) === index);
  };

  handleOnCheckPermisson = (
    ParentPermission: IPermissionGroup,
    actionKey: number
  ) => {
    const { permissionGroupList } = this.state;
    let permission_group_ids: Array<number> = [];
    let permission_sub_group_ids: Array<number> = [];
    const tempPermissionGroupList = JSON.parse(JSON.stringify(permissionGroupList));
    const permissionIndex = tempPermissionGroupList.findIndex((permission: IPermissionGroup) => {
      return permission.id === ParentPermission.id;
    });
    if (permissionIndex != -1) {
      const tempSubPermissionList = [
        ...tempPermissionGroupList[permissionIndex].permission_sub_groups,
      ];
      const subGroupIndex = tempSubPermissionList.findIndex(
        (item) => item.id === actionKey
      );
      if (tempSubPermissionList[subGroupIndex].permitted) {
        tempSubPermissionList[subGroupIndex]["permitted"] = false;
      } else {
        tempSubPermissionList[subGroupIndex]["permitted"] = true;
      }
    }

    tempPermissionGroupList.forEach((parentItem: IPermissionGroup) => {
      parentItem.permission_sub_groups.forEach((subItem: ISubPermissionGroup) => {
        if (subItem.permitted) {
          permission_group_ids.push(parentItem.id);
          permission_sub_group_ids.push(subItem.id);
        }
      });
    });

    this.setState({
      permissionGroupList: tempPermissionGroupList,
      permissionGroupIds: this.handleRemoveDuplicates(permission_group_ids),
      permissionSubGroupIds: this.handleRemoveDuplicates(
        permission_sub_group_ids
      ),
    });
  };

  handleSelectAllPermisson = (event:React.ChangeEvent<{checked:boolean}>,ParentPermission: IPermissionGroup) =>{
    const { permissionGroupList } = this.state;
    let permission_group_ids: Array<number> = [];
    let permission_sub_group_ids: Array<number> = [];
    const tempPermissionGroupList = JSON.parse(JSON.stringify(permissionGroupList));
    const permissionIndex = tempPermissionGroupList.findIndex((permission: IPermissionGroup) => {
      return permission.id === ParentPermission.id;
    });
    if (permissionIndex != -1) {
      tempPermissionGroupList[permissionIndex].permission_sub_groups.map(
        (subGroup: ISubPermissionGroup) => {
          subGroup["permitted"] = event.target.checked;
          return subGroup;
        }
      );
   }

    tempPermissionGroupList.forEach((parentItem: IPermissionGroup) => {
      parentItem.permission_sub_groups.forEach((subItem: ISubPermissionGroup) => {
        if (subItem.permitted) {
          permission_group_ids.push(parentItem.id);
          permission_sub_group_ids.push(subItem.id);
        }
      });
    });

    this.setState({
      permissionGroupList: tempPermissionGroupList,
      permissionGroupIds: this.handleRemoveDuplicates(permission_group_ids),
      permissionSubGroupIds: this.handleRemoveDuplicates(
        permission_sub_group_ids
      ),
    });

  }

  onUpdatePermission = () => {
    const { permissionGroupIds, permissionSubGroupIds, permissionGroupList } = this.state;
    const outputObj = {
      permission_group_ids: permissionGroupIds,
      permission_sub_group_ids: permissionSubGroupIds,
    };
    this.props.onContinuePermissionChange(outputObj, permissionGroupList);
  };

  handleSearchPermission = (event: React.ChangeEvent<{ value: string }>) => {
    const value = event.target.value;
    this.setState({
      searchTerm: value,
    });
  };
  // Customizable Area End
}
