import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { ISortingData } from "../../../components/src/SortingTableHeader";
import { IFilter } from "../../../components/src/FilterPopover";

// Customizable Area Start
import { getFiterList, makeApiMessage } from "../../../components/src/common";
import { IUserContext } from "../../../blocks/navigationmenu/src/PageContainerController.web";
import {
  PermissionStatus,
  checkForNewPermissonStatus,
  customPermissionApiKey
} from "../../../blocks/utilities/src/CustomBlockHelpers";
import {  PermissionGroupArray } from "../../../blocks/navigationmenu/src/utils";
export interface ICompany {
  id: string;
  type: "company";
  attributes: {
    activated: boolean;
    id: number;
    name: string;
    registration_no: string;
    tin_no: string;
    created_at: string;
    updated_at: string;
    email: string;
    telephone: string;
    address: string;
    others: {
      [key: string]: string | { title: string; isSelected: boolean }[];
    };
    country_id: number;
    number_of_vats: string;
    vat_details_attributes: {
      id: 1;
      vat_name: string;
      company_id: number;
      created_at: string;
      updated_at: string;
    }[];
  };
  relationShips: {
    company: {
      data: {
        id: string;
      };
    };
  };
}
export interface ICountry {
  id: number;
  name: string;
}

const emptyCompany: ICompany = {
  id: "",
  type: "company",
  attributes: {
    activated: false,
    id: 0,
    name: "",
    registration_no: "",
    tin_no: "",
    created_at: "",
    updated_at: "",
    email: "",
    telephone: "",
    address: "",
    others: {},
    country_id: 0,
    number_of_vats: "",
    vat_details_attributes: [],
  },
  relationShips: {
    company: {
      data: {
        id: "",
      },
    },
  },
};
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
}

export interface S {
  // Customizable Area Start
  drawerWidth: number;
  popOverOpened: boolean;
  popOverItemId: string;
  popOverItemStatus: string;
  popOverTop: number;
  popOverLeft: number;
  hoveredButton: string;
  companies: ICompany[];
  countries: ICountry[];
  page: number;
  pageSize: number;
  sortingData: ISortingData;
  query: string;
  filterAnchor: HTMLDivElement | undefined;
  filters: IFilter[];
  searchTextCompany: string;
  suggestionFieldTitle: string;
  isSuperAdmin?: boolean;
  popOverItemTitle: string;
  permissionStatus: PermissionStatus;
  isCompanyListLoading: boolean;
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class CompanyListController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  updateUserCallId: string = "";
  getCompaniesCallId: string = "";
  getCountriesCallId: string = "";
  filterSuggestionApiCallId: string = "";
  tableRefs: HTMLElement | null = null;

  moreButtonProps(rowData: { [key: string]: string }) {
    return {
      onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        const position = event.currentTarget.getBoundingClientRect();
        this.setState({
          popOverOpened: true,
          popOverItemId: rowData.id,
          popOverItemStatus: rowData.status,
          popOverItemTitle: rowData.name,
          popOverLeft: position.left + window.scrollX,
          popOverTop: position.top + window.scrollY,
        });
      },
    };
  }

  receiveCountry(from: string, message: Message) {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    this.setState({ countries: responseJson.data });
  }

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIResponceDataMessage),

      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.SessionSaveMessage),
    ];
    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    this.state = {
      popOverOpened: false,
      hoveredButton: "",
      popOverTop: 0,
      companies: [],
      page: 0,
      popOverItemStatus: "",
      popOverItemTitle: "",
      drawerWidth: 0,
      pageSize: 10,
      popOverItemId: "",
      popOverLeft: 0,
      countries: [],
      sortingData: {
        name: "",
        email: "",
        telephone: "",
        country_name: "",
      },
      query: "",
      searchTextCompany: "",
      suggestionFieldTitle: "",
      filters: [
        {
          title: "Company Name",
          type: "autocompolete",
          value: "",
          options: [],
        },
        {
          title: "Country",
          type: "autocompolete",
          value: "",
          options: [],
        },
      ],
      filterAnchor: undefined,
      permissionStatus: {
        mainPermission: false,
        createPermission: false,
        viewPermission: false,
        editPermission: false,
        deactivatePermission: false
      },
      isCompanyListLoading: false,
    };

    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId === this.getCompaniesCallId) {
        if (responseJson && responseJson.data) {
          if (responseJson.meta) {
            this.processMeta(message);
          } else {
            let companies = responseJson.data as ICompany[];
            this.setState({ companies });
          }
        }
        this.setState({
          isCompanyListLoading: false
        })
      } else if (apiRequestCallId === this.updateUserCallId) {
        this.getCompanies();
      } else if (apiRequestCallId === this.getCountriesCallId) {
        this.receiveCountry(from, message);
      } else if (apiRequestCallId === this.filterSuggestionApiCallId) {
        if (responseJson) {
          const updatedFilters = getFiterList(
            responseJson,
            this.state.filters,
            this.state.suggestionFieldTitle
          );
          this.setState({ filters: updatedFilters });
        }
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start

  createData(
    name: string,
    email: string,
    phone: string,
    country: string,
    status: string,
    id: string
  ) {
    return { name, email, phone, country, status, id };
  }

  getCompanies = () => {
    this.setState({
      isCompanyListLoading: true
    })
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: window.localStorage.getItem(configJSON.tokenKey),
    };
    const getDataMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getCompaniesCallId = getDataMsg.messageId;

    const nameFilter = this.state.filters.find(
      (item) => item.title === "Company Name"
    )?.value;
    const countryFilter = this.state.filters.find(
      (item) => item.title === "Country"
    )?.value;
    const searchTextCompany = this.state.searchTextCompany;

    let url =
      configJSON.companyEndPoint +
      "?page_no=" +
      (this.state.page + 1) +
      (nameFilter ? `&filter_by[name]=${nameFilter}` : "") +
      (countryFilter ? `&filter_by[country]=${countryFilter}` : "") +
      (searchTextCompany ? `&filter_by[query]=${searchTextCompany}` : "") +
      this.state.query;

    getDataMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      url
    );
    getDataMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    getDataMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodType
    );
    runEngine.sendMessage(getDataMsg.id, getDataMsg);
  };

  getCountriesForCompany = () => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: window.localStorage.getItem(configJSON.tokenKey),
    };
    const getDataMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getCountriesCallId = getDataMsg.messageId;

    getDataMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.countryEndPoint + "?dropdown=true"
    );
    getDataMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    getDataMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.countryApiMethodType
    );
    runEngine.sendMessage(getDataMsg.id, getDataMsg);
  };

  updateUser = (companyId: string, isActive: boolean) => {
    const header = {
      "Content-Type": configJSON.requestType,
      token: window.localStorage.getItem(configJSON.tokenKey),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.updateUserCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.companyUpdateApi +
        "/" +
        companyId +
        (isActive ? "/activate" : "/deactivate")
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    this.getCompanies();
    this.getCountriesForCompany();
    // Customizable Area End
  }

  processMeta = (message: Message) => {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    let newCompanies = Array<ICompany>(responseJson.meta.total_count);
    for (let i = 0; i < responseJson.meta.total_count; i++) {
      if (
        i >= (responseJson.meta.current_page - 1) * 10 &&
        i < responseJson.meta.current_page * 10
      ) {
        newCompanies[i] =
          responseJson.data[i - (responseJson.meta.current_page - 1) * 10];
      } else {
        if (!this.state.companies[i] || !this.state.companies[i].id)
          newCompanies[i] = { ...emptyCompany };
      }
    }
    this.setState({ companies: newCompanies });
  };

  handlePageChange = (page: number) => {
    this.setState({ page }, () => this.getCompanies());
  };

  handleSearchCompany = (value: string) => {
    this.setState(
      {
        page: 0,
        searchTextCompany: value,
      },
      this.getCompanies
    );
  };

  handleAddCompany = () => {
    this.props.navigation.navigate("CompanyCreation");
  };

  handleEditCompany = (companyId: string) => {
    this.setState({ popOverOpened: false });
    this.props.navigation.history.push("Settings-General-CompanyListEdit?companyId=" + companyId);
    window.localStorage.setItem("companyId", companyId);
  };

  handleViewCompany = (companyId: string) => {
    this.setState({ popOverOpened: false });
    this.props.navigation.history.push("Settings-General-CompanyListView/?companyId=" + companyId);
    window.localStorage.setItem("companyId", companyId);
  };

  handleDeactivateCompany = () => {
    this.setState({ popOverOpened: false });
    this.updateUser(this.state.popOverItemId, false);
  };

  handleActivateCompany = () => {
    this.setState({ popOverOpened: false });
    this.updateUser(this.state.popOverItemId, true);
  };

  handleQueryChange = (query: string) => {
    this.setState({ query }, () => this.getCompanies());
  };

  sortingProps = {
    width: "18%",
    onQueryChange: (query: string) => this.handleQueryChange(query),
    onChange: (sortingData: ISortingData) => this.setState({ sortingData }),
  };

  handleFilterChange = (filters: IFilter[]) => {
    console.log("filters", filters);
    this.setState({ filters }, this.getCompanies);
  };

  handleCompanyAutoComplete = (title: string, value: string) => {
    this.state.filters.forEach((item: IFilter) => {
      if (item.title === title) item.value = value;
      return item;
    });

    let ApiUrl;
    if (title === "Company Name") {
      ApiUrl = configJSON.companyNameSuggestionsEndPoint;
    } else if (title === "Country") {
      ApiUrl = configJSON.countryNameSuggestionsEndPoint;
    }

    let requestMessage = makeApiMessage({
      url: ApiUrl + value,
      method: "GET",
    });
    this.filterSuggestionApiCallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleUserChange = (userContext: IUserContext) => {
    const apiKey = customPermissionApiKey.company;
    const userData = userContext.user?.attributes.permission_groups;
    const value = checkForNewPermissonStatus(apiKey, userData as Array<PermissionGroupArray>);
    this.setState({
      permissionStatus: value
    })
  };

  // Customizable Area End
}
