import React from 'react';
// Customizable Area Start
import { Typography, Box } from "@material-ui/core";
import Avatar from '@material-ui/core/Avatar';



export class BackgroundImage extends React.Component<any> {
    render() {
        const { imageUrl, children }: any = this.props;

        const divStyle = {
            width: "100%",
            backgroundImage: `url('${imageUrl}')`,
            display: "flex",
            alignItems: "center",
            backgroundPosition: "top left",
            height: "100%",
            backgroundSize: "cover"
        };

        return <div style={divStyle}>{children}</div>;
    }
}


export class BackgroundPaper extends React.Component<any> {
    render() {
        const { children }: any = this.props;

        const divStyle = {
            borderRadius: "1.667vw",
            backgroundColor: "white",
            padding: "2.033%",
            margin: " 0 auto",
            maxWidth: "32.917vw",
            minHeight: '37.111vw',
            width: "100%",
           
        };

        return <div style={{ boxSizing: 'border-box',...divStyle}}>{children}</div>;
    }
}


export class AvatarImg extends React.Component<any> {
    render() {
        const { ...avatarProps } = this.props;
        const divStyle = {
            justifyContent: "center",
            alignItems: "center",
            alignContent: " center",
            display: "flex",
            paddingTop: "0.2vw",
            paddingBottom: "1vw",
        };
        const AvatarStyle = {
            width: "4.861vw",
            height: "4.861vw",
        }

        return (
            <Box style={divStyle}>
                <Avatar  {...avatarProps} alt="program avatar" style={AvatarStyle} />
            </Box>
        );
    }
}


export class DynamicHeading extends React.Component<any> {
    render() {
        const { headingName }: any = this.props;
        const heading = {
            fontFamily: "Montserrat",
            fontWeight: 600,
            fontSize: "1.644vw",
        }



        return <Typography style={heading}>{headingName}</Typography>;
    }
}


export class DynamicSubHeading extends React.Component<any> {
    render() {
        const { headingName }: any = this.props;
        const Subheading = {
            fontFamily: "Montserrat",
            fontWeight: 400,
            fontSize: "1.189vw",
            width: "29.722vw",
            paddingTop: "0.500vw",
        }



        return <Typography style={Subheading}>{headingName}</Typography>;
    }
}
// Customizable Area End