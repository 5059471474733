import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import _lodash from "lodash";
import { getStorageData } from "../../../framework/src/Utilities";
import { IUserContext } from "../../../blocks/navigationmenu/src/PageContainerController.web";
import { findPrice, IService as IService2 } from "../../../components/src/customComponents/ProductCard.web";
import { CustomEnums, getCustomEnumName } from '../../utilities/src/CustomBlockHelpers'
import { getCurrencyString } from "../../catalogue/src/utils";

export const ORDER_TYPES = {
  STORE_ORDER: 'storeOrders',
  PLANT_ORDER: 'plantOrders',
  CLEANING_ORDER: 'cleaningOrders',
}

export const Actions = {
  ADD_QUANTITY: 'add_quantity',
  CHANGE_NOTES: 'change_note',
  CLEAR_CUSTOMER: 'clear_customer',
  REMOVE_QUANTITY: 'remove_quantity',
  CHANGE_MAIN_TAB: 'change_main_tab',
  SUMMARY_NEW_ORDER: 'summary_new_order',
  CHANGE_ORDER_NOTES: 'change_order_notes',
  CHANGE_COUNTRY_CODE: 'change_country_code',
  CHANGE_CUSTOMER_PHONE: 'change_customer_phone',
  OPEN_ADD_QUANTITY_POPUP: 'open_add_quantity_popup',
  CLOSE_ADD_QUANTITY_POPUP: 'close_add_quantity_popup',
}

export interface IPhoneValue {
  id: number,
  option: string,
  full_phone_number: string,
  country_code: number
}

export interface IFormValues {
  id?: number;
  notes?: string;  
  width?: string;
  height?: string;
  weight?: string;
  quantity: number;
  measurement_type?: string[];
  home_cleaning_catalogue_type_id?: string;
  home_cleaning_catalogue_id?: number | string;
  isNew: boolean;
  [field: string]: string | number | boolean | undefined | string[];
}

export interface ICatalogueTypes {
  data: {
    id: string;
    attributes: {
      id: number;
      name: string;
      price: string;
      second_name: string;
      name_translation: string;
      home_cleaning_catalogue_id: number;
    }
  }
}

export interface IPlantProduct {
  id: string;
  attributes: {
    id: number;
    name: string;
    image: string;
    active: boolean;
    product_name: string;
    measurement_type: string[];
    product_second_name: string;
    home_cleaning_catalogue_types: Array<ICatalogueTypes>;
  }
}

export interface ISnackbar {
  open: boolean;
  message: string;
}
export const ProductType = {
  NORMAL: "noraml",
  PARENT: "Parent",
  SUBPRODUCT: "Sub-Product",
};
import { apiCall, handleLogout, makeApiMessage } from "../../../components/src/common";
export interface IService {
  id: string;
  attributes: {
    id: number;
    name: string;
    second_name: string;
    short_name: string;
    icon_id: number;
    active: boolean;
    online_order: boolean;
    order_number: number;
    icon: {
      data: {
        id: string;
        type: string;
        attributes: {
          id: number;
          image: string;
          name: string;
          second_name: string;
          image_type: string;
        };
      };
    };
  };
}

interface ISpecification {
  id: number;
  name: string;
  options_attributes: {
    id: number;
    label: string;
    selected: boolean;
  }[];
}

interface IOrderItem {
  id: string;
  type: string;
  attributes: {
    id: number;
    order_management_order_id: number;
    quantity: number;
    no_of_tags: number;
    no_of_pieces: number;
    unit_price: string;
    notes: string;
    total_price: string;
    catalogue_id: number;
    catalogue_variant_id: number;
    upcharge_price: string;
    preference_id: null | string;
    service_id: number;
    parent_catalogue_id: null | string;
    specifications: null | string;
    category_id: number;
    tag_numbers: string[];
    order_statuses: {
      order_number: string;
      placed_at: null | string;
      confirmed_at: null | string;
      in_transit_at: null | string;
      delivered_at: null | string;
      cancelled_at: null | string;
      refunded_at: null | string;
    };
    catalogue: {
      id: number;
      product_name: string;
      product_second_name: string;
      name: string;
    };
    product_image: string;
    preference: null | string;
    service: IService;
    upcharge_list_ids: Array<UpchargeIds>;
  };
}

export interface IOrder {
  id: number;
  order_number: string;
  amount: null | string;
  account_id: number;
  coupon_code_id: null | number;
  delivery_address_id: null | number;
  sub_total: string;
  total_upcharge_amount: string;
  customer: {
    id: number;
    full_phone_number: string;
    email: string;
    full_name: string;
  };
  total: string;
  status: string;
  edit_order?: boolean;
  custom_label: null | string;
  applied_discount: string;
  cancellation_reason: null | string;
  order_date: null | string;
  is_gift: boolean;
  placed_at: null | string;
  confirmed_at: null | string;
  in_transit_at: null | string;
  delivered_at: null | string;
  cancelled_at: null | string;
  refunded_at: null | string;
  source: null | string;
  shipment_id: null | string;
  delivery_charges: null | string;
  tracking_url: null | string;
  schedule_time: null | string;
  payment_failed_at: null | string;
  payment_pending_at: null | string;
  returned_at: null | string;
  tax_charges: string;
  store_name: string;
  deliver_by: null | string;
  tracking_number: null | string;
  is_error: boolean;
  delivery_error_message: null | string;
  order_status_id: number;
  is_group: boolean;
  is_availability_checked: boolean;
  shipping_charge: null | string;
  shipping_discount: null | string;
  shipping_net_amt: null | string;
  shipping_total: null | string;
  total_tax: string;
  no_of_items: number;
  no_of_pieces: number;
  created_at: string;
  updated_at: string;
  delivery_addresses: [] | null;
  razorpay_order_id: null | string;
  charged: null | string;
  invoice_id: null | string;
  invoiced: null | string;
  save_for_future: boolean;
  notes: string;
  is_quick_drop?: boolean;
  promo_code_id: null | string;
  tax_percentage: string;
  order_items: IOrderItem[];
  service_charge?: string;
  invoice_receipt_pdf_url?: string | null;
  order_transactions: {
    id: string;
    attributes: {
      id: number;
      account_id: number;
      order_management_order_id: number;
      amount: string;
      payment_method: {
        id: number;
      };
    };
  }[];
  paid_at: string | null;
  order_created_by: {
    id: number;
    first_name: string;
    last_name: string;
  };
  currency: string;
}
interface ClickedProductInfo {
  price: string;
  image: string;
  productType: string;
  tabsId: undefined;
  parentProductId: string;
  title: string;
  catalogue_id: number;
  catalogue_variant_id: string
}
export interface IProduct {
  id: string;
  attributes: {
    id: number;
    name: string;
    price_list: {
      id: number;
      name: string;
      created_at: string;
      updated_at: string;
      product_currency_id: string;
    };
    pieces: null;
    price: string;
    active: boolean;
    created_at: string;
    updated_at: string;
    catalogue: {
      id: string;
      attributes: {
        id: number;
        product_name: string;
        name: string;
      };
    };
    image: {
      image: string;
    };
    measurement: {
      id: number;
      value: string;
    };
    product_currency_id: string;
  };
}
export interface Store {
  id: string;
  type: string;
  attributes: {
    id: number;
    store_name: string;
  };
}
export interface ICustomer {
  id: string;
  attributes: {
    full_name: string;
    activated: boolean;
    email: string;
    date_of_birth: string;
    phone_number: number;
    country_code: number;
    customer_profile?: {
      data: {
        id: string;
        type: string;
        attributes: {
          street_address: null | string;
          city: null | string;
          employee_id: string;
          customer_id: string;
          post_code: null | string;
          business_id: null | string;
          price_list: {};
          notes: null | string;
          gender_id: null | string;
          private_note: null | string;
          customer_type: string;
          profession: {
            data: null;
          };
          organisation_tax: {
            data: null;
          };
          payment: {
            id: null;
            name: null;
          };
          organization: {
            data: null;
          };
        };
      };
    };
    customer_preferences_attributes: IPreferenceItem[];
    order: IOrder;
    saved_notes: null;
  };
}
interface CatalogueVariant {
  id: string;
  type: string;
  attributes: {
    id: number;
    pieces: number | null;
    active: boolean;
    created_at: string;
    updated_at: string;
    catalogue: {
      id: string;
      type: string;
      attributes: {
        id: number;
        product_name: string;
        product_second_name: string;
        name: string;
      };
    };
    name: string;
    image: {
      id: number;
      image: string;
    };
    measurement: {
      id: number;
      value: string;
    };
    product_currency_id: string;
    catalogue_variants_services: {
      data: IService[];
    };
  };
}
interface PreferenceData {
  id: string;
  type: string;
  attributes: {
    specifications: never[];
    id: number;
    icon_id: number;
    preference_first_name: string;
    preference_second_name: string;
    active: boolean;
    gallery_icon: {
      id: number;
      image: string;
      name_translation: string;
    };
    name: string;
  };
}
export interface SubProductWithId {
  open: boolean,
  catalogueId: number
}
export interface CatalogueList {
  id: string;
  type: string;
  attributes: {
    id: number;
    product_name: string;
    product_second_name: string;
    minimum_washing_cycle: number;
    maximum_washing_cycle: number;
    preference_id: number;
    created_at: string;
    updated_at: string;
    order_number: number;
    product_type: string; 
    product_type_id: string;
    preference: {
      data: PreferenceData;
    };
    sub_products: {
      data: any[];
    };
    catalogue_variants: CatalogueVariant[];
    name: string;
  };
}
interface Meta {
  total_pages: number;
  total_count: number;
  current_page: number;
  next_page: number | undefined;
  pervious_page: number | undefined;
}
interface ParametersType {
  productSecondName: string | undefined;
  serviceName: string;
  serviceShortName: string | undefined;
  parentProductId: number;
  catalogue_variant_id: number;
  tabsId: number;
  serviceId: number; 
  productType: string, 
  catalogue_id: number 
}
import {
  ICustomerDataWithPreference,
  IPreferenceItem,
} from "../../../blocks/ProductDescription/src/CustomerPreferenceViewController.web";
import { UpchargeIds, UpchargeMainList } from "./utils";

export interface ICleaningOrderItem {
  id: string;
  attributes: {
    id: number;
    width: string;
    notes: string;
    height: string;
    quantity: number;
    currency: string;
    unit_price: string;
    total_price: string;    
    weight: string | null;
    home_cleaning_order_id: number;
    home_cleaning_catalogue_id: number;
    home_cleaning_catalogue_type_id: number;    
    catalogue: {
      id: number;
      name: string;
      second_name: string;
      product_image: string;
      name_translation: string;
      measurement_type: Array<string>;
    },
    product_type: {
      id: number;
      name: string;
      price: string;
      second_name: string;
      name_translation: string;
    }
  }
}
export interface ICleaningOrder {
  id: number;
  paid_at: null;
  total: string;
  address_id: null;
  is_mobile: false;
  sub_total: string;
  status: "in_cart" | "placed";
  total_tax: string;
  account_id: number;
  order_number: string;
  notes: string | null;
  shipping_charge: null;
  refund_amount: string;
  service_charge: string;
  applied_discount: string;
  cancellation_reason: null;
  store_management_id: number;
  confirmed_at: null | string;
  min_non_refundable_amount: string;
  order_items: Array<ICleaningOrderItem>;
  order_type?: "PlantCleaningOrder";
  order_transactions: {
    id: number;
    name: string;
    amount: string;
    active: boolean;
    created_at: string;
    second_name: string;
    name_translation: string;    
  }[];
  order_created_by: {
    id: number;
    last_name: string;
    full_name: string;
    first_name: string;
  };
  currency: string;
  store_name: string;
  no_of_items: number;
  no_of_pieces: number;
  tax_percentage: string;
  store_management: {
    data: {
      id: string,      
      attributes: {
        id: number,
        store_name: string
      }
    }
  },
  promo_code: null
}


export interface IPaymentOption {
  id: number;
  name: string;
  active: boolean;
}

export interface ICartProduct {
  catalogue_id: number;
  catalogue_variant_id: number;
  title: string;
  price: string | number;
  image: string;
  quantity: number;
  serviceName: string;
  serviceId: number | string;
  parentProductId: null | string | number;
  tabsId: number;
  productSecondName?: string;
  serviceShortName?: string;
  upcharge_price: string;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  tabsId: number;
  cartTotalOpen: boolean;
  paymentOptions: IPaymentOption[];
  paymentMethod: string;
  subProduct: { open: boolean } | SubProductWithId;
  cataloguesList: CatalogueList[];
  cataloguesListMeta: Meta;
  userData: any;
  userRefresh: () => void;
  storeId: number;
  storeList: {id: string; option: string}[];
  cartProducts: ICartProduct[];
  beforePopupCartProducts: ICartProduct[];
  addCustomerPopup: boolean;
  addProductPopup: boolean;
  viewCustomerPopup: boolean;
  isLoading: boolean;
  mobileNumberError: string;
  customerData?: ICustomer;
  countryCodeInput: string;
  customerId: null | string;
  previousPhoneNumber: string;
  previousCustomer?: ICustomer;
  subtotalOrder?: { attributes: IOrder | ICleaningOrder };
  orderSummaryVisible?: boolean;
  clearPhoneNumber?: boolean;
  isEdit: boolean | null;
  productPreferenceOpen: boolean;
  saveForFuture: boolean;
  orderNotes: string;
  snackbarOpen: boolean;
  paymentModalVisible: boolean;
  orderTryAgainModalVisible: boolean;
  sameProductDifferentServiceModalVisible: boolean;
  clickedProductIndex: number;
  errorSnackbarOpen: boolean;
  errorMessage: string;
  preferenceModalVisible: boolean;
  clickedProduct: ClickedProductInfo;
  customerPhone: string;
  preferenceData: PreferenceData;
  selectedService: {
    name: string,
    id: number,
  };
  successSnackBarMessage: string;
  order?: { attributes: IOrder };
  cartItemHoverId: { 
    catalogue_id : number | null, 
    tabsId : number | null, 
    serviceName : string | null 
  };
  customerDataWithPreference: ICustomerDataWithPreference | undefined;
  customersList: any,
  clickedProductInfo: ClickedProductInfo;
  itemsAddedToCartForPreference: {
    catalogue_variant_id: number;
    preferenceId: number;
    serviceId: number;
  }[];
  isQuickDrop: boolean;
  quickDropCount: number;
  currentPage: number;
  editId?: string;
  temporaryProducts: CatalogueList[];
  paymentClicked: boolean;
  quickDropReceivedCount: number;
  storeListPageNo: number;
  storeSearchInput: string;

  enableHomeCleaningSettings: boolean;
  selectedMainTab: string;
  productPopup: boolean;
  plantProducts: Array<IPlantProduct | never>;
  productPopupData: IPlantProduct | null;
  cleaningOrder?: null | ICleaningOrder;
  pickupDate: Date | null;
  UpchargeMainList: UpchargeMainList[];
  searchText: string;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: number | string;
  // Customizable Area End
}

export default class OrderCreationController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getProductApiCallId: string = "";
  getCatalogueListApiId: string = "";
  getTemporaryProductListApiId: string = "";
  getProductPreferencesApiId: string = "";
  updateEmployeeCurrentStoreApiId: string = "";
  createNewOrderApiId: string = "";
  getOrderApiId: string = "";
  createPreferenceApiId: string = "";
  getStoreListApiId: string = "";
  getCustomerListApiId: string = "";
  searchCustomerApiCallId: string = "";
  orderNotesTimeout: NodeJS.Timeout | string = "";
  searchCustomerApiMessageId: string = "";
  plantProductListMessageId: string = "";  
  homeCleaningOrderMessageId: string = "";  
  mobileNumberInput: string = "";
  storeListEmptyFlag = false;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      getName(MessageEnum.RestAPIResponceErrorMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.RestAPIResponceDataMessage),
      getCustomEnumName(CustomEnums.CustomActionReducers),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      isQuickDrop: false,
      quickDropCount: 0,
      previousPhoneNumber: "",
      paymentMethod: "",
      subProduct: { open: false },
      cataloguesList: [],
      cataloguesListMeta: {
        total_pages: 0,
        total_count: 0,
        current_page: 0,
        next_page: undefined,
        pervious_page: undefined
      },
      userData: {},
      userRefresh: () => {},
      storeId: -1,
      storeList: [],
      storeSearchInput: "",
      cartProducts: [],
      beforePopupCartProducts: [],
      addCustomerPopup: false,
      addProductPopup: false,
      viewCustomerPopup: false,
      isLoading: false,
      mobileNumberError: "",
      countryCodeInput: "+966",
      customerId: null,
      isEdit: false,
      productPreferenceOpen: false,
      saveForFuture: false,
      paymentModalVisible: false,
      orderTryAgainModalVisible: false,
      sameProductDifferentServiceModalVisible: false,
      clickedProductIndex: -1,
      snackbarOpen: false,
      orderNotes: "",
      errorSnackbarOpen: false,
      preferenceModalVisible: false,
      errorMessage: "",
      clickedProduct: {
        price: "",
        image: "",
        productType: "",
        tabsId: undefined,
        parentProductId: "",
        title: "",
        catalogue_id: 0,
        catalogue_variant_id: ""
      },
      customerPhone: "",
      preferenceData: {
        id: "",
        type: "",
        attributes: {
          id: 0,
          icon_id: 0,
          preference_first_name: "",
          preference_second_name: "",
          active: false,
          gallery_icon: {
            id: 0,
            image: "",
            name_translation: ""
          },
          name: "",
          specifications: []
        }
      },
      selectedService: {
        name: "",
        id: 0,
      },
      successSnackBarMessage: "",
      cartItemHoverId: { 
        catalogue_id : null, 
        tabsId : null, 
        serviceName : null 
      },
      customerDataWithPreference: undefined,
      customersList: [],
      clickedProductInfo: {
        catalogue_variant_id: "",
        price: "",
        image: "",
        productType: "",
        tabsId: undefined,
        parentProductId: "",
        title: "",
        catalogue_id: 0
      },
      itemsAddedToCartForPreference: [],
      currentPage: 1,
      temporaryProducts: [],
      paymentClicked: false,
      tabsId: 0,
      cartTotalOpen: false,
      paymentOptions: [],
      quickDropReceivedCount: 0,
      storeListPageNo: 1,
      selectedMainTab: ORDER_TYPES.STORE_ORDER,
      productPopup: false,
      plantProducts: [],
      productPopupData: null,
      cleaningOrder: null,
      enableHomeCleaningSettings: false,
      pickupDate: null,
      UpchargeMainList: [],
      searchText: ""
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      this.handleResponse(message);
    }

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      this.getSearchCustomerApiCallResponse(apiRequestCallId, responseJson);      
      this.handleCustomerSearchResponse(apiRequestCallId, responseJson);
      this.handlePlantProductResponse(apiRequestCallId, responseJson);
      this.handleResForCreateUpdatePlantOrder(apiRequestCallId, responseJson);
      
    }
    else if(getCustomEnumName(CustomEnums.CustomActionReducers) === message.id)
      this.handleCustomReducer(message);
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    if (localStorage.getItem("preferenceCustomer") as string) {
      this.setState({
        previousCustomer: JSON.parse(
          localStorage.getItem("preferenceCustomer") as string
        ),
        customersList: [
          JSON.parse(localStorage.getItem("preferenceCustomer") as string),
        ],
      });

      this.handleOnchangeCustomerMobileNumber(
        this.setFieldValue,
        JSON.parse(localStorage.getItem("preferenceCustomer") as string)
      );
    }
    
    const editId = this.props.navigation.getParam("editId");
    if (editId) {
      this.setState({ editId });
      this.getOrder(editId);
    }    
  }

  tabChangeHandler = (event: React.ChangeEvent<{}>, newValue: number) => {
    this.setState({ tabsId: newValue });
  };

  toggleCartTotalOpen = () => {
    this.setState({ cartTotalOpen: !this.state.cartTotalOpen });
  };

  handleAddProductClick = () => {
    this.handleValidationAddProduct();
  };

  handleValidationAddProduct = () => {
    this.setState({ addProductPopup: true });
  };

  handleOrderSummaryNewOrder = (
    previousCustomer = this.state.previousCustomer
  ) => {
    if (!previousCustomer) return;
    const customerItem = {
      id: previousCustomer.id,
      option: `${previousCustomer.attributes?.phone_number} - ${previousCustomer.attributes?.full_name}`,
      full_phone_number:
        previousCustomer.attributes?.country_code +
        "" +
        previousCustomer.attributes?.phone_number,
      country_code: previousCustomer.attributes?.country_code,
    };
    this.setState({
      orderSummaryVisible: false,
      customersList: [customerItem],
      clearPhoneNumber: false,      
      customerData: previousCustomer,
      customerId: previousCustomer?.id,
    });
    this.handleOnchangeCustomerMobileNumber(this.setFieldValue, customerItem);
  };

  getCustomerList = (message: Message) => {
    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    let temp: Array<{}> = [];
    responseJson.customer.forEach((customer: {
      id: number;
      full_phone_number: string;
      phone_number: number;
      country_code: string;
      full_name: string;
    }) => {
      temp.push({
        id: customer.id,
        option: `${customer.phone_number} - ${customer.full_name}`,
        full_phone_number: customer.full_phone_number,
        country_code: customer.country_code,
      });
    });
    if (temp.length === 0) {
      this.setState({ customerData: undefined, customerId: null });
    }
    this.setState({ customersList: temp });
  };

  handlePaymentDone = () => {
    this.setState({
      paymentClicked: true,
    });
    !this.state.cleaningOrder ? this.getOrder() : this.getCleaningOrder();
  };

  checkCartProductsInTemporary = () => {
    const temporaryProductIds = new Set(
      this.state.temporaryProducts.map((product: {
        attributes: {
          id: number
        }
      }) => product.attributes.id)
    );

    // Check if there are any cartProduct IDs not in temporaryProductIds
    const hasNonTemporaryProducts = this.state.cartProducts.some(
      (cartProduct: ICartProduct) => !temporaryProductIds.has(cartProduct.catalogue_id)
    );

    return hasNonTemporaryProducts;
  };

  handleGetOrder = (message: Message) => {
    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (
      responseJson.data.attributes.status === "placed" &&
      ((this.state.editId && !responseJson.data.attributes.is_quick_drop) ||
        this.state.paymentModalVisible)
    ) {
      
      setTimeout(() => {
        this.setState({
          paymentModalVisible: false,
          paymentClicked: false,
          orderSummaryVisible: true,
          clearPhoneNumber: true,
          previousCustomer: this.state.customerData,
          customerId: null,
          order: undefined,
          subtotalOrder: responseJson.data,
          cartProducts: [],
          isLoading: false,
          quickDropCount: 0,
        });
      }, 1000);
    } else {
      
      const order: IOrder = responseJson.data.attributes;
      if (this.state.editId) {
        if (order.is_quick_drop) {
          order.order_items = [];
        }
        order.is_quick_drop = false;
        order.status = "in_cart";
        order.edit_order = true;
      }
      this.setState({
        isLoading: false,
        order: { attributes: order },
        isQuickDrop: responseJson.data.attributes.is_quick_drop,
        quickDropCount: responseJson.data.attributes.is_quick_drop
          ? responseJson.data.attributes.order_items[0]?.attributes.quantity ||
            0
          : 0,
      });
      if (this.state.editId) {
        const customer = order.customer;
        this.setState({
          previousCustomer: customer as any,
          customersList: [customer],
        });

        this.handleOnchangeCustomerMobileNumber(this.setFieldValue, customer);
      }
    }
  };
  handleOrderCreation = (message: Message) => {
    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    this.handleUpchargeListMain(responseJson.data.attributes)
    this.setState({
      paymentModalVisible: false,
      isLoading: false,
      paymentClicked: false,
      order: { ...responseJson.data, attributes: { ...responseJson.data.attributes, order_items: responseJson.data.attributes.order_items.sort((a: { id: number }, b: { id: number }) => a.id - b.id) } },
    },
    () => {
      const order = JSON.parse(JSON.stringify(responseJson.data));
      const value = Boolean(order.attributes.is_quick_drop)
        ? Number(order?.attributes?.order_items[0]?.attributes?.quantity || 0)
        : 0;
      this.setState({ quickDropReceivedCount: value || 0 });
    }
    );
  };

  handleQuickDropChange = (count: number) => {
    if (count === 0) this.setState({ quickDropReceivedCount: 0 });
    this.setState(
      {
        quickDropCount: count,
        isQuickDrop: !!count,
        orderSummaryVisible: false,
      },
      () => {
        this.createUpdateOrder({ isQuickDrop: true });
      }
    );
  };

  handleResponse = (message: Message) => {
    let apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    let errorReponse = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );

    if (
      apiRequestCallId &&
      responseJson &&
      !responseJson?.errors &&
      !responseJson?.error
    ) {
      switch (apiRequestCallId) {
        case this.getCatalogueListApiId:
          this.setState(
            {
              cataloguesList: responseJson.data,
              cataloguesListMeta: responseJson.meta,
            },
            () => {
              this.handleGetTemporaryProductList();
            }
          );
          break;
        case this.getTemporaryProductListApiId:
          if (responseJson.data.length) {
            this.setState({
              cataloguesList: [
                ...this.state.cataloguesList,
                ...responseJson.data,
              ],
              temporaryProducts: responseJson.data,
            });
          }
          break;
        case this.getProductPreferencesApiId:
          this.setState({
            preferenceData: responseJson.data,
            itemsAddedToCartForPreference: [
              ...this.state.itemsAddedToCartForPreference,
              {
                preferenceId: responseJson.data.attributes.id,
                catalogue_variant_id:
                  responseJson.data.attributes.catalogue_variant_id,
                serviceId: this.state.selectedService.id,
              },
            ],
          });
          this.createUpdateOrder({});
          break;
        case this.getCustomerListApiId:
          this.getCustomerList(message);
          break;
        case this.getStoreListApiId:
          this.handelStoreListResponse(responseJson);
          break;
        case this.updateEmployeeCurrentStoreApiId:
          this.state.userRefresh();
          break;
        case this.createNewOrderApiId:
          this.orderTimeout = undefined;
          this.handleOrderCreation(message);
          break;
        case this.getOrderApiId:
          this.handleGetOrder(message);
          this.getCatalogueList();
          break;
        case this.createPreferenceApiId:
          this.setState(
            {
              isLoading: false,
              successSnackBarMessage:
                "Your preference was created successfully",
              preferenceModalVisible: false,
            },
            () => {
              this.setState({ snackbarOpen: true });
            }
          );
          break;
      }
    } else if (errorReponse || responseJson?.errors) {
      this.orderTimeout = undefined;
      if (typeof responseJson?.errors !== "string") {
        this.setState(
          { errorMessage: responseJson?.errors[0]?.message },
          () => {
            this.setState({ errorSnackbarOpen: true });
          }
        );
      }
    }
  };

  handelStoreListResponse(responseJson: { data: Store[] }) {
    const { userData, storeList, storeListPageNo } = this.state
    const { store_name, store_id } = userData?.attributes?.employee_proifle?.data?.attributes?.store_management || {}
    let currentStore = storeList.find(store => store.id === _lodash.toString(store_id))
    if (store_id !== undefined && currentStore === undefined) {
      currentStore = {
        id: _lodash.toString(store_id),
        option: _lodash.toString(store_name)
      }
    }
    const array = (responseJson?.data || []).map(item => ({id: item.id, option: item.attributes.store_name}))
    const newStoreList = storeListPageNo === 1 ? array: [...storeList, ...array]
    currentStore && newStoreList.unshift(currentStore)
    this.storeListEmptyFlag = array.length < 10
    this.setState({
        storeList: _lodash.uniqBy(newStoreList, "id")
    });
  }

  handleStoreChange = () => {
    this.handleResetCartCustomerPhone();
  };

  handleGetTemporaryProduct = (productInfo: any) => {
    this.setState(
      {
        cataloguesList: [...this.state.cataloguesList, productInfo.data],
        temporaryProducts: [...this.state.temporaryProducts, productInfo.data],
      },
      () => {
        this.setState({
          addProductPopup: false,
        });
      }
    );
  };

  handleGetTemporaryProductList = () => {
    const headers = {
      "Content-Type": configJSON.applicationJsonContentType,
      token: localStorage.getItem("token"),
    };

    const apiUrl =
      configJSON.getCatalogueListApi +
      `/get_store_products?store_id=${localStorage.getItem(
        "store_id"
      )}&customer_id=${this.state.customerId}&category_id=${this.state.tabsId}`;

    const message = new Message(getName(MessageEnum.RestAPIRequestMessage));

    message.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiUrl
    );

    message.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    message.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );

    this.getTemporaryProductListApiId = message.messageId;
    runEngine.sendMessage(message.id, message);
  };

  handleResetCartCustomerPhone = () => {
    this.setState({
      cartProducts: [],
      cartTotalOpen: false,
      customerId: null,
      customerData: undefined,
      orderNotes: "",
      saveForFuture: false,
      paymentMethod: "",
    });
    this.resetForm();
  };

  handleEditNavigate = () => {
    this.props.navigation.navigate("ProductPreference");
    localStorage.setItem(
      "preferenceCustomer",
      JSON.stringify(
        this.state.customersList.find(
          (custList: { id: string }) => custList.id == this.state.customerId
        ) || ""
      )
    );
    localStorage.setItem("preferenceCustomerId", this.state.customerId as string);
    localStorage.setItem(
      "previousCustomer",
      JSON.stringify(this.state.customerData)
    );

    const itemsOnTheCartForPreference =
      this.state.itemsAddedToCartForPreference.filter((item: {
        catalogue_variant_id: number,
        serviceId: string | number
      }) => {
        return this.state.cartProducts.some(
          (cartItem: ICartProduct) =>
            cartItem.catalogue_variant_id === item.catalogue_variant_id &&
            cartItem.serviceId === item.serviceId
        );
      });

    localStorage.setItem(
      "itemsAddedToCart",
      JSON.stringify(itemsOnTheCartForPreference)
    );
  };

  tabSectionChangeHandler = (
    _event: React.ChangeEvent<{}>,
    newValue: number
  ) => {
    this.setState({ tabsId: newValue }, () => {
      this.getCatalogueList();
    });
  };

  toggleCartTotalInfoOpen = () => {
    this.setState({ cartTotalOpen: !this.state.cartTotalOpen });
  };

  parentProductClickHandler = (catalogueId: number) => {
    this.setState({
      subProduct: { open: true, catalogueId: catalogueId },
      beforePopupCartProducts: _lodash.cloneDeep(this.state.cartProducts),
    });
  };

  subProductPopupCancelHandler = () => {
    this.setState((prevState) => ({
      subProduct: { open: false },
      cartProducts: _lodash.cloneDeep(prevState.beforePopupCartProducts),
      beforePopupCartProducts: [],
    }));
  };

  subProductPopupNextHandler = () => {
    this.setState({ subProduct: { open: false }, beforePopupCartProducts: [] });
  };

  orderToCardProducts = (order: IOrder) => {
    if (!order || !order.order_items) return;
    const newCardProducts = order.order_items.map((item: IOrderItem) => {
      return {
        catalogue_id: item.attributes.catalogue_id,
        catalogue_variant_id: item.attributes.catalogue_variant_id,
        title: item.attributes.catalogue.product_name,
        price: item.attributes.unit_price,
        image: item.attributes.product_image,
        quantity: item.attributes.quantity,
        serviceName: item.attributes.service
          ? item.attributes.service.attributes.name
          : "",
        serviceId: item.attributes.service_id,
        productType: this.state.cataloguesList.find(
          (catalogue: CatalogueList) => Number(catalogue.id) === item.attributes.catalogue_id
        )?.attributes?.product_type,
        parentProductId: item.attributes.parent_catalogue_id,
        tabsId: item.attributes.category_id,
        productSecondName: item.attributes.catalogue.product_second_name,
        serviceShortName: item.attributes.service
          ? item.attributes.service.attributes.short_name
          : "",
        upcharge_price: item.attributes.upcharge_price,
      };
    });

    this.setState({
      cartProducts: newCardProducts,
    });

    const orderData = JSON.parse(JSON.stringify(order));
    const value = Number(orderData?.order_items?.[0]?.attributes.quantity || 0);
    this.setState({ quickDropReceivedCount: value });
  };

  handleClearAllCart = () => {
    this.setState({
      cartProducts: [],
    });
  };

  addQuantityClickHandler = (parameters: ClickedProductInfo) => {
    if (parameters.productType === ProductType.PARENT) {
      this.parentProductClickHandler(parameters.catalogue_id);
      return;
    }

    this.setState((prevState: any) => {
      const cartProductsLocal = [...prevState.cartProducts];
      const productIndex = cartProductsLocal?.findIndex(
        (product: { catalogue_id: number, tabsId: string}) =>
          product?.catalogue_id === parameters?.catalogue_id &&
          product?.tabsId === parameters?.tabsId
      );
      if (productIndex === -1) {
        return {
          cartProducts: [
            ...prevState.cartProducts,
            {
              catalogue_id: parameters.catalogue_id,
              catalogue_variant_id: parameters.catalogue_variant_id,
              title: parameters.title,
              price: parameters.price,
              image: parameters.image,
              quantity: 1,
              serviceName: undefined,
              serviceId: undefined,
              productType: parameters.productType,
              parentProductId: parameters?.parentProductId,
              tabsId: parameters?.tabsId,
            },
          ],
          clickedProductIndex: prevState.clickedProductIndex,
          clickedProductInfo: parameters,
        };
      } else {
        if (cartProductsLocal[productIndex].serviceId) {
          this.handleSameProductDifferentService();
          return {
            clickedProductIndex: productIndex,
            cartProducts: cartProductsLocal,
            clickedProductInfo: parameters,
          };
        }

        cartProductsLocal[productIndex].quantity =
          cartProductsLocal[productIndex].quantity + 1;
        return {
          clickedProductIndex: productIndex,
          cartProducts: cartProductsLocal,
          clickedProductInfo: parameters,
        };
      }
    });
  };

  handleSameProductDifferentService = () => {
    this.setState({ sameProductDifferentServiceModalVisible: true });
  };

  handleSameProdDiffServiceConfirmClick = () => {
    this.setState({ sameProductDifferentServiceModalVisible: false });

    if (this.state.clickedProductInfo.productType === ProductType.PARENT) {
      this.parentProductClickHandler(
        this.state.clickedProductInfo.catalogue_id
      );
      return;
    }

    this.setState((prevState: any) => {
      const cartProductsLocal = [...prevState.cartProducts];
      const newProduct = {
        catalogue_id: this.state.clickedProductInfo.catalogue_id,
        catalogue_variant_id:
          this.state.clickedProductInfo.catalogue_variant_id,
        title: this.state.clickedProductInfo.title,
        price: this.state.clickedProductInfo.price,
        image: this.state.clickedProductInfo.image,
        quantity: 1,
        serviceName: undefined,
        serviceId: undefined,
        productType: this.state.clickedProductInfo.productType,
        parentProductId: this.state.clickedProductInfo?.parentProductId,
        tabsId: this.state.clickedProductInfo?.tabsId,
      };

      // Create a new array with the existing products and the new product
      const updatedCartProducts = [newProduct, ...cartProductsLocal];

      return {
        cartProducts: updatedCartProducts,
      };
    });
  };

  removeQuantityClickHandler = (parameters: ClickedProductInfo) => {
    if (parameters.productType === ProductType.PARENT) {
      this.parentProductClickHandler(parameters.catalogue_id);
      return;
    }
    this.setState((prevState: any) => {
      const cartProductsLocal = [...prevState.cartProducts];
      const productIndex = cartProductsLocal?.findIndex(
        (product: ICartProduct) =>
          product?.catalogue_id === parameters?.catalogue_id &&
          product?.tabsId === parameters?.tabsId
      );
      this.setState({ clickedProduct: cartProductsLocal[productIndex] });
      if (cartProductsLocal[productIndex].quantity > 0) {
        if (
          cartProductsLocal[productIndex].quantity === 1 &&
          !cartProductsLocal[productIndex].serviceId
        ) {
          cartProductsLocal.splice(productIndex, 1);
        } else {
          cartProductsLocal[productIndex].quantity =
            cartProductsLocal[productIndex].quantity - 1;
        }
      }
      return { cartProducts: cartProductsLocal };
    });
  };

  cartItemQuantityAddHandler = (parameters: any) => {
    this.setState((prevState: {
      cartProducts: ICartProduct[]
    }) => {
      const cartProductsLocal = [...prevState.cartProducts];
      const productIndex = cartProductsLocal?.findIndex(
        (product: ICartProduct) =>
          product?.catalogue_id === parameters?.catalogue_id &&
          product?.tabsId === parameters?.tabsId &&
          product?.serviceName === parameters?.serviceName
      );
      cartProductsLocal[productIndex].quantity =
        cartProductsLocal[productIndex].quantity + 1;

      return { cartProducts: cartProductsLocal };
    });
  };

  cartItemQuantityRemoveHandler = (parameters: any) => {
    this.setState((prevState: any) => {
      const cartProductsLocal = [...prevState.cartProducts];
      const productIndex = cartProductsLocal?.findIndex(
        (product: {
          catalogue_id: number,
          tabsId: string,
          serviceName: string
        }) =>
          product?.catalogue_id === parameters?.catalogue_id &&
          product?.tabsId === parameters?.tabsId &&
          product?.serviceName === parameters?.serviceName
      );

      if (cartProductsLocal[productIndex].quantity > 1) {
        cartProductsLocal[productIndex].quantity =
          cartProductsLocal[productIndex].quantity - 1;
      }
      return { cartProducts: cartProductsLocal };
    });
  };

  removeItemClickHandler = (parameters: {
    catalogue_id: number | null,
    tabsId: string | number | null,
    serviceName: string | null
  }) => {
    this.setState((prevState: any) => {
      const cartProductsLocal = [...prevState.cartProducts];
      const productIndex = cartProductsLocal?.findIndex(
        (product: {
          catalogue_id: number,
          tabsId: string,
          serviceName: string
        }) =>
          product?.catalogue_id === parameters?.catalogue_id &&
          product?.tabsId === parameters?.tabsId &&
          product?.serviceName === parameters?.serviceName
      );

      if (productIndex >= 0 && cartProductsLocal[productIndex].quantity > 0) {
        cartProductsLocal.splice(productIndex, 1);
      }

      return { cartProducts: cartProductsLocal };
    });
  };

  handleClosePreference = () => {
    this.setState({ preferenceModalVisible: false });
  };

  checkForService = (parameters: {
    tabsId: number;
    serviceId: string | number; 
    productType: string, 
    catalogue_id: number 
  }) => {
    if (parameters.productType === ProductType.PARENT) {
      this.parentProductClickHandler(parameters.catalogue_id);
      return false;
    }
    if (!this.state.customerId) {
      this.setState({
        errorSnackbarOpen: true,
        errorMessage:
          "Please select customer first before selecting the service",
      });
      return false;
    }

    if (
      this.state.userData?.attributes?.employee_proifle?.data?.attributes
        ?.store_management?.attributes?.item_preference_popup &&
      !this.state.cartProducts.find(
        (uniqueProduct: ICartProduct) =>
          (uniqueProduct.catalogue_id === parameters.catalogue_id &&
            uniqueProduct.tabsId === parameters.tabsId &&
            uniqueProduct.serviceId === parameters.serviceId) ||
          this.state.temporaryProducts.find(
            (temporaryProduct: {
              attributes: {
                id: number
              }
            }) =>
              temporaryProduct.attributes.id === parameters.catalogue_id
          )
      )
    ) {
      this.setState({ preferenceModalVisible: true });
    }
    return true;
  };

  serviceClickHandler = (parameters: ParametersType) => {
    if (!this.checkForService(parameters)) return;
    this.setState((prevState) => {
      const cartProductsLocal = [...prevState.cartProducts];
      const productIndex = cartProductsLocal?.findIndex(
        (product: {
          catalogue_id: number,
          tabsId: number
        }) =>
          product?.catalogue_id === parameters?.catalogue_id &&
          product?.tabsId === parameters?.tabsId
      );

      let product = this.state.cataloguesList.find(
        (item: CatalogueList) =>
          item.id ===
          (parameters.parentProductId
            ? String(parameters.parentProductId)
            : String(parameters.catalogue_id))
      );

      if (parameters.parentProductId) {
        product = product?.attributes?.sub_products?.data?.find(
          (item: { id: number }) => String(item.id) === String(parameters.catalogue_id)
        );
      }

      let services =
        product?.attributes?.catalogue_variants[0]?.attributes
          ?.catalogue_variants_services?.data as unknown as Array<{
            attributes: {
              service: IService2;
              price: string;
            }
          }>;

      let price = findPrice(services, parameters.serviceId);

      cartProductsLocal[productIndex].price = price;
      cartProductsLocal[productIndex].serviceName = parameters.serviceName;
      cartProductsLocal[productIndex].serviceId = parameters.serviceId;
      cartProductsLocal[productIndex].serviceShortName =
        parameters.serviceShortName;
      cartProductsLocal[productIndex].productSecondName =
        parameters.productSecondName;

      const uniqueCartProducts: any = [];
      cartProductsLocal.forEach((product: any) => {
        const existingProduct = uniqueCartProducts.find(
          (uniqueProduct: any) =>
            uniqueProduct.catalogue_id === product.catalogue_id &&
            uniqueProduct.tabsId === product.tabsId &&
            uniqueProduct.serviceId === product.serviceId
        );
        if (existingProduct) {
          existingProduct.quantity += product.quantity;
        } else {
          uniqueCartProducts.push(product);
        }
      });

      return { cartProducts: uniqueCartProducts };
    });

    this.getProductPreferencesCall(
      parameters.catalogue_variant_id,
      parameters.serviceId
    );
    
    this.setState({
      selectedService: {
        name: parameters.serviceName,
        id: parameters.serviceId,
      },
    });
  };

  getProductPreferencesCall = (
    catalogue_variant_id: number,
    service_id: number
  ) => {
    const headers = {
      "Content-Type": configJSON.applicationJsonContentType,
      token: localStorage.getItem("token"),
    };

    const apiUrl =
      configJSON.getProductPreferences +
      `?customer_id=${this.state.customerId}&catalogue_variant_id=${catalogue_variant_id}&service_id=${service_id}`;

    const message = new Message(getName(MessageEnum.RestAPIRequestMessage));

    message.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiUrl
    );

    message.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    message.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    this.getProductPreferencesApiId = message.messageId;
    runEngine.sendMessage(message.id, message);
  };

  handleSaveForFutureChange = (event: React.ChangeEvent<{}>, checked: boolean) => {
    this.setState(
      {
        saveForFuture: checked,
      },
      () => this.createUpdateOrder({})
    );
  };

  handleOrderNotesChange = (event: React.ChangeEvent<{ value: string }>) => {
    const { userData, customerId, selectedMainTab } = this.state
    const isStoreOrder = selectedMainTab == ORDER_TYPES.STORE_ORDER
    const isPlantOrder = selectedMainTab === ORDER_TYPES.PLANT_ORDER
    const storeId = userData?.attributes?.employee_proifle?.data?.attributes
      ?.store_management?.attributes?.store_management_id;

    this.setState(
      {
        orderNotes: event.target.value,
      },
      () => {
        clearTimeout(this.orderNotesTimeout);
        this.orderNotesTimeout = setTimeout(() => {
          isStoreOrder ? this.createUpdateOrder({}) : this.onCreateOrUpdatePlantOrder({
            data: {
              account_id: customerId,
              notes: this.state.orderNotes,
              store_management_id: storeId,
              type: isPlantOrder ? "PlantCleaningOrder" : undefined,
            }
          } as {})
        }, 500);
      }
    );
  };

  createOrderBody = (params: {
    preferenceData?: PreferenceData;
    isQuickDrop?: boolean;
  }) => {
    const body: any = params.isQuickDrop
      ? {
          edit_order: !!this.state.editId,

          data: {
            customer_id: this.state.customerId,
            notes: this.state.orderNotes,
            store_management_id:
              this.state.userData?.attributes?.employee_proifle?.data
                ?.attributes?.store_management?.store_id,
            save_for_future: this.state.saveForFuture,
            is_quick_drop: true,
            order_items_attributes: [
              {
                quantity: this.state.quickDropCount,
              },
            ],
          },
        }
      : {
          edit_order: !!this.state.editId,

          data: {
            is_quick_drop: false,
            customer_id: this.state.customerId,
            notes: this.state.orderNotes,
            store_management_id:
              this.state.userData?.attributes?.employee_proifle?.data
                ?.attributes?.store_management?.store_id,
            save_for_future: this.state.saveForFuture,
            order_items_attributes: this.state.cartProducts
              .filter((item: ICartProduct) => item.serviceId)
              .map((product: ICartProduct) => {
                return {
                  catalogue_id: product.catalogue_id,
                  catalogue_variant_id: product.catalogue_variant_id,
                  quantity: product.quantity,
                  service_id: product.serviceId,
                  category_id: product.tabsId,
                  customer_preference_id:
                    this.state.itemsAddedToCartForPreference?.find(
                      (item) =>
                        item.catalogue_variant_id ===
                          product.catalogue_variant_id &&
                        item.serviceId === product.serviceId
                    )?.preferenceId,
                };
              }),
          },
        };
    if (this.state.editId) body.data.status = "in_cart";
    return body;
  };

  fillExistingOrderBody = (
    params: {
      preferenceData?: PreferenceData;
      isQuickDrop?: boolean;
    },
    bodyData: any
  ) => {
    const order = this.state.order as { attributes: IOrder };
    if (!params.isQuickDrop) {
      let indOrder = 0;
      for (; indOrder < order.attributes.order_items.length; indOrder++) {
        if (bodyData.data.order_items_attributes[indOrder]) {
          bodyData.data.order_items_attributes[indOrder].id =
            order.attributes.order_items[indOrder].id;
        } else {
          bodyData.data.order_items_attributes.push({
            id: order.attributes.order_items[indOrder].id,
            _destroy: true,
          });
        }
      }
    } else {
      if (order.attributes.order_items.length > 0)
        bodyData.data.order_items_attributes[0].id =
          order.attributes.order_items[0].id;
    }

    bodyData.data.order_items_attributes.forEach((element: {
      _destroy: boolean,
      quantity: number
    }) => {
      if (!element.quantity) {
        element._destroy = true;
      }
    });
  };

  createUpdateOrderMilliseconds = 0;
  orderTimeout: any;

  createUpdateOrder = async(params: {
    preferenceData?: any;
    isQuickDrop?: boolean;
  }) => {
    if (this.orderTimeout) {
      clearTimeout(this.orderTimeout);
      this.orderTimeout = setTimeout(() => {
        this.createUpdateOrder(params);
      }, 500);
      return;
    }
    this.orderTimeout = true;
    if (Date.now() - this.createUpdateOrderMilliseconds < 50) return;
    this.createUpdateOrderMilliseconds = Date.now();
    const { preferenceData } = params;

    const header = {
      "Content-Type": configJSON.applicationJsonContentType,
      token: await getStorageData('token'),
    };

    const orderAvailable =
      !!this.state.order &&
      !!this.state.order.attributes &&
      !!this.state.order.attributes.id;

    let method = "POST";

    const bodyData: any = this.createOrderBody(params);

    if (orderAvailable) {
      method = "PUT";
      this.fillExistingOrderBody(params, bodyData);
    }

    if (preferenceData && !params.isQuickDrop) {
      bodyData.data.order_items_attributes.forEach(
        (item: any, index: number) => {
          if (
            item.catalogue_variant_id === preferenceData.catalogue_variant_id
          ) {
            bodyData.data.order_items_attributes[index] = {
              ...item,
              ...preferenceData,
            };
          }
        }
      );
    }

    const message = new Message(getName(MessageEnum.RestAPIRequestMessage));

    message.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createNewOrder +
        (orderAvailable
          ? `/${(this.state.order as { attributes: IOrder }).attributes.id}`
          : "new_order")
    );
    message.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    message.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(bodyData)
    );
    message.addData(getName(MessageEnum.RestAPIRequestMethodMessage), method);

    this.createNewOrderApiId = message.messageId;
    runEngine.sendMessage(message.id, message);
  };

  getOrder = (orderId?: string) => {
    const header = {
      "Content-Type": configJSON.applicationJsonContentType,
      token: localStorage.getItem("token"),
    };

    const orderAvailable =
      !!this.state.order &&
      !!this.state.order.attributes &&
      !!this.state.order.attributes.id;

    const message = new Message(getName(MessageEnum.RestAPIRequestMessage));

    message.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createNewOrder +
        (orderId || (this.state.order ? this.state.order.attributes.id : ""))
    );
    message.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    message.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");

    this.getOrderApiId = message.messageId;
    if (!orderAvailable && !orderId) return;
    runEngine.sendMessage(message.id, message);
  };

  handlePayClick = () => {
    this.setState({ isLoading: true });
    if (!this.state.customerId) {
      this.setState({
        orderTryAgainModalVisible: true,
      });
      return;
    }

    if (
      this.state.userData?.attributes?.employee_proifle?.data?.attributes
        ?.store_management?.attributes?.payment_method_screen
    ) {
      this.setState({ paymentModalVisible: true });
    } else {
      this.createUpdateOrder({});
    }
  };

  handlePageChange = (page: number) => {
    this.setState({ currentPage: page + 1 });
    this.getCatalogueList(page + 1);
  };

  // API
  getCatalogueList = (page: number = this.state.currentPage) => {
    const headers = {
      "Content-Type": configJSON.applicationJsonContentType,
      token: localStorage.getItem("token"),
    };
    const store_management =
      this.state?.userData?.attributes?.employee_proifle?.data?.attributes
        ?.store_management?.attributes;
    const priceId = store_management?.price_list_id;

    const filterSearch = this.state.searchText ? `&filter_by[query]=${this.state.searchText}` : "";
    const isRequiredPermissions = "&allow_access=true";

    const apiUrl =
      configJSON.getCatalogueListApi +
      `?price_list_id=${priceId}&category_id=${this.state.tabsId}&per_page=10&page_no=${page}${filterSearch}${isRequiredPermissions}`;

    const message = new Message(getName(MessageEnum.RestAPIRequestMessage));

    message.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiUrl
    );

    message.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    message.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    this.getCatalogueListApiId = message.messageId;
    runEngine.sendMessage(message.id, message);
  };

  getStoreList = () => {
    const headers = {
      "Content-Type": configJSON.applicationJsonContentType,
      token: localStorage.getItem("token"),
    };

    const searchQuery = this.state.storeSearchInput ? `&filter_by[query]=${this.state.storeSearchInput}` : "";
    const apiUrl = configJSON.getStoreListApi + `?dropdown=true&page_no=${this.state.storeListPageNo}${searchQuery}`;

    const message = new Message(getName(MessageEnum.RestAPIRequestMessage));

    message.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiUrl
    );

    message.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    message.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    this.storeListEmptyFlag = true;
    this.getStoreListApiId = message.messageId;
    runEngine.sendMessage(message.id, message);
  };

  updateEmployeeCurrentStore = (storeId: number) => {
    const header = {
      "Content-Type": configJSON.applicationJsonContentType,
      token: localStorage.getItem("token"),
    };

    const bodyData = {
      store_id: storeId,
    };

    const message = new Message(getName(MessageEnum.RestAPIRequestMessage));

    message.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.updateEmployeeCurrentStoreApi
    );
    message.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    message.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(bodyData)
    );
    message.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypePut
    );

    this.updateEmployeeCurrentStoreApiId = message.messageId;
    runEngine.sendMessage(message.id, message);
  };

  checkGetResponse(responseJson: any) {
    if (responseJson && !responseJson.errors) {
      return true;
    } else {
      handleLogout(this.props.navigation, responseJson?.errors);
      return false;
    }
  }

  setSearchCustomerData = (responseJson: any, order: any) => {
    if (order?.id) this.createUpdateOrderMilliseconds = Date.now();
    this.setState(
      {
        isLoading: false,
        mobileNumberError: "",
        countryCodeInput: "+" + responseJson.data.attributes?.country_code,
        customerData: responseJson.data,
        previousCustomer: responseJson.data,
        customersList: [
          {
            id: responseJson.data.id,
            option: `${responseJson.data.attributes?.phone_number} - ${responseJson.data.attributes.full_name}`,
            full_phone_number:
              responseJson.data.attributes.country_code +
              "" +
              responseJson.data.attributes?.phone_number,
            country_code: responseJson.data.attributes.country_code,
          },
        ],

        customerId: responseJson.data.id,
        orderNotes: responseJson.data.attributes?.saved_notes || "",
        saveForFuture: responseJson.data.attributes?.saved_notes ? true : false,
        itemsAddedToCartForPreference:
          responseJson.data.attributes.order?.order_items
            ?.filter((item: any) => item)
            .map((item: any) => {
              return {
                preferenceId: item.attributes?.customer_preference_id,
                catalogue_variant_id: item.attributes?.catalogue_variant_id,
                serviceId: item.attributes?.service_id,
              };
            }) || [],
      },
      () => {
        this.state.selectedMainTab == ORDER_TYPES.STORE_ORDER && this.getCatalogueList();
      }
    );

    if (!this.state.editId) {
      this.setState({
        order: order?.id > 0 ? { attributes: order } : undefined,
        isQuickDrop: order?.id > 0 ? order.is_quick_drop : false,
        quickDropCount: order?.is_quick_drop
          ? order.order_items[0]?.attributes.quantity || 0
          : 0,
      });
    }
  };

  getSearchCustomerApiCallResponse(apiRequestCallId: any, responseJson: any) {
    if (apiRequestCallId === this.searchCustomerApiCallId) {
      if (this.checkGetResponse(responseJson)) {
        this.orderToCardProducts(responseJson.data.attributes?.order);
        this.handleUpchargeListMain(responseJson.data.attributes?.order);
        const order = responseJson.data.attributes.order;
        localStorage.setItem('orderId', order?.id)
        this.setSearchCustomerData(responseJson, order);
        this.handleCleaningInCartOrder(responseJson?.data?.attributes?.cleaning_order)
      } else {
        this.setState({
          isLoading: false,
          mobileNumberError: responseJson?.errors,
        });
      }
    }
  }

  handleUpchargeListMain(order: IOrder) {
    if (!order || !order.order_items) return;
    const newArray = order.order_items.map((item) => {
      return {
        catalogue_id: item.attributes.catalogue_id,
        catalogue_variant_id: item.attributes.catalogue_variant_id,
        upcharge_list_ids: item.attributes.upcharge_list_ids,
        total_price: item.attributes.total_price
      }
    });
    this.setState({
      UpchargeMainList: newArray
    })
  };

  handleRenderUpchareList(catalogue_id: number, catalogue_variant_id: number) {
    const { UpchargeMainList } = this.state;
    const newArray = UpchargeMainList.find(
      (item) => (
        item.catalogue_id == catalogue_id && item.catalogue_variant_id == catalogue_variant_id)
      )
    if(newArray) {
      return newArray.upcharge_list_ids
    } else {
      return []
    }
  }

  handleRenderTotalPrice(catalogue_id: number, catalogue_variant_id: number) {
    const { UpchargeMainList } = this.state;
    const newArray = UpchargeMainList.find(
      (item) => (
        item.catalogue_id == catalogue_id && item.catalogue_variant_id == catalogue_variant_id)
      )
    if(newArray) {
      return newArray.total_price;
    } else {
      return "";
    }
  }

  handleCustomerMobileInputChange(value: string, mobilePart: string) {
    value = this.state.countryCodeInput + value.replace(/^0+/, '')
    if (value.includes(" ")) value = value.split(" ")[0];
    const sendVal = value.replace(/\+/g, "");
this.mobileNumberInput = (parseInt(mobilePart) || "").toString()

    if ((value.length || 0) < 3) return;
    const headers = {
      "Content-Type": configJSON.applicationJsonContentType,
      token: localStorage.getItem("token"),
    };

    const apiUrl =
      configJSON.customerSearch + `?full_phone_number=${sendVal || ""}`;

    const message = new Message(getName(MessageEnum.RestAPIRequestMessage));

    message.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiUrl
    );

    message.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    message.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    this.getCustomerListApiId = message.messageId;
    runEngine.sendMessage(message.id, message);

    if (!mobilePart)
      this.setState({
        customerId: this.state.previousCustomer
          ? this.state.previousCustomer.id
          : null,
        customerData: this.state.previousCustomer,
      });
  }

  handleAddCustomerPopupOpen(inputPhoneNumber: string) {
    if (inputPhoneNumber &&
      this.state.customersList
        .find((customer: { full_phone_number: string; country_code: number }) =>
          customer.full_phone_number === customer.country_code + inputPhoneNumber)
    ) {
      this.setState({
        errorSnackbarOpen: true,
        errorMessage: configJSON.Strings.add_customer_warning_message,
      });
      return;
    }
    if (!this.state.customerData)
      this.setState({
        addCustomerPopup: true,
        customerData: {
          attributes: {
            country_code: "+966",
            phone_number: inputPhoneNumber,
            gender: "",
            date_of_birth: "",
            profession: "",
            organization: "",
            street_address: "",
            city: "",
            post_code: "",
            employee_id: "",
            business: "",
            price_list: "",
            payment_type: "",
            notes: "",
            private_note: "",
          },
        } as any,
      });
    else
      this.setState(
        {
          customerData: {
            ...this.state.customerData,
            attributes: {
              ...this.state.customerData.attributes,
              full_name: "",
              country_code: "+966",
              phone_number: inputPhoneNumber,
              email: "",
              gender: "",
              date_of_birth: "",
              profession: "",
              organization: "",
              street_address: "",
              city: "",
              post_code: "",
              employee_id: "",
              business: "",
              price_list: "",
              payment_type: "",
              notes: "",
              private_note: "",
            },
          } as any,
        },
        () => {
          this.setState({ isEdit: null, addCustomerPopup: true });
        }
      );
  }

  handleAddCustomerPopupClose = () => {
    this.setState({ addCustomerPopup: false, isEdit: false });
  };

  handleAddProductPopupClose = () => {
    this.setState({ addProductPopup: false, isEdit: false });
  };

  handleGetCustomerPhone = (customer: any) => {
    this.setState({
      previousCustomer: customer,
      customersList: [customer],
    });

    this.handleOnchangeCustomerMobileNumber(this.setFieldValue, customer);
  };

  handleViewCustomerPopupOpen() {
    if (this.state.customerData && this.state.customerData.id)
      this.setState({ viewCustomerPopup: true, isEdit: false });
  }

  handleSnackbarClose = (_event?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }

    this.setState({ snackbarOpen: false, errorSnackbarOpen: false, errorMessage: '' });
  };

  handlePaymentModalClose = () => {
    this.setState({
      paymentModalVisible: false,
      paymentClicked: false,
    });
  };

  handleOrderTryAgainModalClose = () => {
    this.setState({ orderTryAgainModalVisible: false });
  };

  handleSameProdtDiffServiceModalClose = () => {
    this.setState({ sameProductDifferentServiceModalVisible: false });
  };

  handleViewCustomerPopupClose = () => {
    this.setState({ viewCustomerPopup: false, isEdit: false });
  };

  isCartProductsValid = (cartProducts: ICartProduct[]) => {
    return (
      cartProducts.filter(
        (productItem: ICartProduct) => productItem.serviceId && productItem.quantity > 0
      ).length >= 1
    );
  };

  handleIsEdit() {
    this.setState({ isEdit: true }, () => {
      this.setState({ viewCustomerPopup: false }, () => {
        this.setState({ customerData: this.state.customerData }, () => {
          this.setState({ addCustomerPopup: true });
        });
      });
    });
  }
  resetForm = () => {};
  setFieldValue = () => {};

  handleOnchangeCustomerMobileNumber = (setFieldValue: (field: string, value: string) => void, value: any) => {
    localStorage.removeItem("preferenceCustomer");
    localStorage.removeItem("preferenceCustomerId");
    localStorage.removeItem("itemsAddedToCart");
    this.setFieldValue = setFieldValue as () => void;
    setFieldValue("full_phone_number", value?.full_phone_number || value);

    this.handleCheckMobileNumber(value, false);

    if (!value) {
      this.setState({ customerId: null, order: undefined, cartProducts: [] });
    } else {
      this.setState({
        previousPhoneNumber: value,
      });
    }
  };

  handleCheckMobileNumber = (values: any, resetForm: any) => {
    if (!values) {
      return this.setState({ customerData: undefined, customerId: null });
    }
    
    const { selectedMainTab } = this.state
    let extraQueryParams = '&cart_order_required=true'
    if ([ORDER_TYPES.PLANT_ORDER, ORDER_TYPES.CLEANING_ORDER].includes(selectedMainTab)) {      
      extraQueryParams = "&cleaning_cart_order_required=true";
      if (selectedMainTab == ORDER_TYPES.PLANT_ORDER)
        extraQueryParams += "&order_type=PlantCleaningOrder"     
    }

    this.setState({ isLoading: true });
    let authToken = localStorage.getItem("token");
    if (resetForm) {
      this.resetForm = resetForm;
    }

    let headers = {
      "Content-type": "application/json",
      token: authToken,
    };

    let phoneUrl = `${configJSON.searchCustomerApiEndPoint}?full_phone_number=${
      values?.full_phone_number
    }${extraQueryParams}&store_id=${localStorage.getItem("store_id")}`;
    const getAccount = apiCall({
      header: headers,
      httpBody: {},
      url: phoneUrl,
      httpMethod: configJSON.searchCustomerApiMethod,
    });

    this.searchCustomerApiCallId = getAccount.messageId;
    runEngine.sendMessage(getAccount.id, getAccount);
  };

  // check request
  handleCreatePreference = (preferenceData: any) => {
    this.setState({ paymentModalVisible: false });
    const header = {
      "Content-Type": configJSON.applicationJsonContentType,
      token: localStorage.getItem("token"),
    };

    const selectedSpecifications = preferenceData.selectedOptions
      ?.filter((element: any) => element.option_id)
      .map((item: any) => ({
        specification_option_id: Number(item.option_id),
      }));

    const bodyData = {
      data: {
        attributes: {
          customer_id: parseInt(this.state.customerId as string),
          preferences_attributes: [
            {
              id: Number(this.state.preferenceData?.attributes?.id),
              catalogue_variant_id: Number(preferenceData.id),
              preference_id: Number(preferenceData.preferenceId),
              upcharge_list_ids: preferenceData.upchargeListIds,
              service_id: preferenceData.serviceId,
              notes: preferenceData.notes,
              save_for_future: preferenceData.saveForFuture,
              pref_spec_options_attributes: selectedSpecifications,
            },
          ],
        },
      },
    };
    if (this.state.order?.attributes?.id) {
      const spesificationList: ISpecification[] =
        this.state?.preferenceData?.attributes?.specifications ?? [];

      const spesificationData: any = {};

      selectedSpecifications.forEach((element: any) => {
        const optionId = element.specification_option_id;
        const spesification = spesificationList.find((element1) =>
        element1.options_attributes.map((element2) => element2.id).includes(optionId)
        );
        if (spesification) {
          spesificationData[spesification.name] =
            spesification.options_attributes.find(
              (specElement) => specElement.id === optionId
            )?.label;
        }
      });

      const updateOrderData = {
        specifications: spesificationData,
        preference_id: Number(preferenceData.preferenceId),
        upcharge_list_ids: preferenceData.upchargeListIds,
        catalogue_variant_id: Number(preferenceData.id),
      };

      this.createUpdateOrder({ preferenceData: updateOrderData });
    }

    const message = new Message(getName(MessageEnum.RestAPIRequestMessage));

    message.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createPreferenceApiEndPoint
    );
    message.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    message.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(bodyData)
    );
    message.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypePut
    );

    this.createPreferenceApiId = message.messageId;
    runEngine.sendMessage(message.id, message);
  };

  componentDidUpdate(_prevProps: Readonly<Props>, prevState: Readonly<S>): void {
    if (this.state.cartProducts !== prevState.cartProducts) {
      if (this.state.customerId) {
        this.createUpdateOrder({});
      }
    }
    if (
      this.state.cartProducts !== prevState.cartProducts &&
      this.state.cartProducts.length > 0
    ) {
      this.state.cartProducts.forEach((product: ICartProduct) => {
        if (product.quantity === 0) {
          product.serviceId = "";
          product.serviceName = "";
        }
      });
    }

    if (
      this.state.userData?.attributes?.employee_proifle?.data?.attributes
        ?.store_management?.store_id !==
      prevState.userData?.attributes?.employee_proifle?.data?.attributes
        ?.store_management?.store_id
    ) {
      localStorage.setItem(
        "store_id",
        this.state.userData?.attributes?.employee_proifle?.data?.attributes
          ?.store_management?.store_id
      );
    }
  }

  setCartItemHoverId = (catalogue_id: number | null, tabsId: number | null, serviceName: string | null) => {
    this.setState({ cartItemHoverId: { catalogue_id, tabsId, serviceName } });
  };

  handleClearOrder = () => {
    this.setState({
      orderSummaryVisible: false,
      clearPhoneNumber: false,
      customerData: undefined,
      customerId: null,
    });
  };

  handlePayButton() {
    const { quickDropCount, quickDropReceivedCount } = this.state;

    if (quickDropCount > 0) {
      if (quickDropCount === quickDropReceivedCount) {
        this.handlePayClick();
      }
    } else this.handlePayClick();
  }

  getPayButtonEnable() {
    const {
      isQuickDrop,
      cartProducts,
      customerData,
      quickDropCount,
      orderSummaryVisible,
      quickDropReceivedCount,
    } = this.state;

    if (isQuickDrop && quickDropCount !== quickDropReceivedCount) return true;

    return (
      (!isQuickDrop && !this.isCartProductsValid(cartProducts)) ||
      !customerData ||
      orderSummaryVisible
    );
  }  

  handelStoreDropdownScroll = (event: React.SyntheticEvent) => {
    if (this.storeListEmptyFlag) return;
    const listboxNode = event.currentTarget;
    const position = listboxNode.scrollTop + listboxNode.clientHeight;
    
    if (listboxNode.scrollHeight - position <= 1.3) {
      this.setState((prev) => ({ storeListPageNo: prev.storeListPageNo + 1 }), () => {
        this.getStoreList()
      })
    }
  };

  handleMainTabOnChange(value: string) {
    this.setState({
      currentPage: 1,
      orderNotes: "",
      order: undefined,
      customerId: null,
      customerPhone: "",
      cleaningOrder: null,
      selectedMainTab: value,
      customerData: undefined,
      subtotalOrder: undefined,
      orderSummaryVisible: false,
    }, () => {
      this.mobileNumberInput = "";
      ORDER_TYPES.STORE_ORDER == value && this.getCatalogueList();
      [ORDER_TYPES.PLANT_ORDER, ORDER_TYPES.CLEANING_ORDER].includes(value) && this.getProductList();
      ORDER_TYPES.CLEANING_ORDER == value && this.setState({ errorMessage: configJSON.Strings.home_cleaning_tab_change_warning_message, errorSnackbarOpen: true });
    })
  }

  // Plant order creation code implementation

  handleCustomerSearchResponse(requestId: string, response: { customer: Array<{ id: string, phone_number: string, full_name: string, full_phone_number: string, country_code: string }> }) {
    if (this.searchCustomerApiMessageId == requestId && response?.customer) {
      const list = response?.customer?.map((customer) => {
        return {
          id: customer.id,
          country_code: customer.country_code,
          full_phone_number: customer.full_phone_number,
          option: `${customer.phone_number} - ${customer.full_name}`,
        }
      }) || [];
      this.setState({ customersList: list });
    }
  }

  handlePlantProductResponse(requestId: string, response: { data: Array<IPlantProduct>, meta: Meta }) {
    if (this.plantProductListMessageId === requestId) {
      this.setState({ plantProducts: response?.data || [], cataloguesListMeta: response?.meta || {} })
    }
  }

  handleCustomReducer(message: Message) {
    const action = message.getData(getCustomEnumName(CustomEnums.CustomReducerAction));
    const payload = message.getData(getCustomEnumName(CustomEnums.CustomReducerPayload));

    const { customerData } = this.state

    switch (action) {

      case Actions.CHANGE_NOTES:
        this.handleOrderNotesChange(payload)
        break;

      case Actions.SUMMARY_NEW_ORDER:
        this.handleSummaryNewOrder()
        break;

      case Actions.CHANGE_MAIN_TAB:
        this.handleMainTabOnChange(payload);
        break;

      case Actions.CLEAR_CUSTOMER:
        this.handleClearCustomer()
        break;

      case Actions.CHANGE_COUNTRY_CODE:
        this.onChangeCountryCode(payload);
        break;

      case Actions.CHANGE_CUSTOMER_PHONE:
        this.onChangeCustomerPhone(payload);
        break;

      case Actions.ADD_QUANTITY:
        this.onChangeQuantity(payload, true);
        break;

      case Actions.REMOVE_QUANTITY:
        this.onChangeQuantity(payload, false);
        break;

      case Actions.OPEN_ADD_QUANTITY_POPUP:
        !customerData ?
          this.setState({ errorSnackbarOpen: true, errorMessage: 'Please select customer first!' })
          : this.setState({ productPopupData: payload }, () => this.setState({ productPopup: true }))
        break;

      case Actions.CLOSE_ADD_QUANTITY_POPUP:
        this.setState({ productPopup: false, productPopupData: null });
        break;
    }
  }

  handleSummaryNewOrder() {
    const { previousCustomer } = this.state
    const { phone_number, country_code, full_name } = previousCustomer?.attributes || {}
    const phoneStr = phone_number + ' - ' + full_name
    this.setState({
      customerPhone: phoneStr,
      cleaningOrder: undefined,
      subtotalOrder: undefined,
      orderSummaryVisible: false,
      countryCodeInput: String(country_code),
    }, () => {
      this.onChangeCustomerPhone(phoneStr);
    });

  }

  onChangeCountryCode(value: string) {
    this.setState({ countryCodeInput: value, customerPhone: '', cleaningOrder: null }, () => {
      this.onCustomerSearch()
    })
  }

  onChangeCustomerPhone(value: string) {
    const { customersList } = this.state
    const selectedOption = customersList.find((item: { option: string }) => item?.option == value)

    if (!value) {
      this.setState({ customerData: undefined, customerId: null })
      return;
    }

    this.setState({ customerPhone: value }, () =>
      !value.includes('-') ? this.onCustomerSearch() : this.handleCheckMobileNumber(
        selectedOption, false
      )
    )
  }

  onCustomerSearch() {    
    const { countryCodeInput, customerPhone } = this.state
    if(String(customerPhone).length < 3) return;
    
    const fullNumber = String(countryCodeInput + customerPhone).replace('+', '');
    const message = makeApiMessage({
      url: configJSON.customerSearch + '?full_phone_number=' + fullNumber,
      method: 'GET'
    });
    this.searchCustomerApiMessageId = message.messageId;
    runEngine.sendMessage(message.id, message);
  }

  getProductList(page: number = this.state.currentPage) {
    const { selectedMainTab } = this.state
    let type = ''

    if (selectedMainTab === ORDER_TYPES.PLANT_ORDER)
      type = '&cleaning_type=Plant'
    else if(selectedMainTab === ORDER_TYPES.CLEANING_ORDER)
      type = '&cleaning_type=Home Cleaning';

      const filterSearch = this.state.searchText ? `&filter_by[query]=${this.state.searchText}` : "";

    const allowAccessPermissions = "&allow_access=true";
    this.setState({ currentPage: page });
    const message = makeApiMessage({
      url: configJSON.homeCleaningProductList + `?page_no=${page}&per_page=10${type}${filterSearch}${allowAccessPermissions}`,
      method: 'GET'
    })
    this.plantProductListMessageId = message.messageId;
    runEngine.sendMessage(message.id, message);
  }
  
  onSendAction(action: string, payload?: unknown) {
    let message = new Message(getCustomEnumName(CustomEnums.CustomActionReducers));
    message.addData(getCustomEnumName(CustomEnums.CustomReducerAction), action);
    message.addData(getCustomEnumName(CustomEnums.CustomReducerPayload), payload);
    runEngine.sendMessage(message.id, message);
  }

  handleChangeAddProductItem(recordId: number, currentId: string, currentKey: string, currentValue: unknown, allItems: IFormValues[], setValue: Function) {
    const newArray = JSON.parse(JSON.stringify(allItems));    
    const array = newArray.map((item: IFormValues) => {
      if (currentId === item.home_cleaning_catalogue_id && recordId == item.id) {
        item[currentKey] = currentValue as string
      }
      return item
    })

    setValue(array as IFormValues[]);
  }

  handleSubmitAddProductForm(items: IFormValues[]) {
    const { customerData, userData, customerId, selectedMainTab } = this.state
    const storeId = userData?.attributes?.employee_proifle?.data?.attributes
      ?.store_management?.attributes?.store_management_id;

    const isPlantOrder = selectedMainTab === ORDER_TYPES.PLANT_ORDER

    const filteredItems = items.map((item) => {
      if (item.isNew) item.id = undefined
      return item
    })

    const body = {
      data: {
        account_id: customerId,
        store_management_id: storeId,
        type: isPlantOrder ? "PlantCleaningOrder" : undefined,
        home_cleaning_order_items_attributes: filteredItems.map(({
          home_cleaning_catalogue_type_id,
          home_cleaning_catalogue_id,
          quantity,
          weight,
          notes,
          height,
          width,
          id
        }: IFormValues) => ({
          home_cleaning_catalogue_type_id,
          home_cleaning_catalogue_id,
          quantity,
          weight,
          height,
          notes,
          width,
          id
        }))
      }
    }

    customerData && this.onCreateOrUpdatePlantOrder(body as {})    
  }  

  onChangeQuantity(item: ICleaningOrderItem, addQty: boolean) {
    if (!item) return;

    const { userData, customerId, selectedMainTab } = this.state
    const storeId = userData?.attributes?.employee_proifle?.data?.attributes
      ?.store_management?.attributes?.store_management_id;        

    const isPlantOrder = selectedMainTab == ORDER_TYPES.PLANT_ORDER
    const quantity = Number(item.attributes.quantity) + (addQty ? 1 : -1)
    const isDelete = quantity == 0
    const destroyParam = isDelete ? { _destroy: true } : { quantity }
    
    const body = {
      data: {
        account_id: customerId,
        store_management_id: storeId,
        type: isPlantOrder ? "PlantCleaningOrder" : undefined,
        home_cleaning_order_items_attributes: [
          {
            id: item.id,            
            home_cleaning_catalogue_id: item.attributes.home_cleaning_catalogue_id,
            home_cleaning_catalogue_type_id: item.attributes.home_cleaning_catalogue_type_id,
            ...destroyParam
          }
        ]
      }
    }
    this.onCreateOrUpdatePlantOrder(body as { })
  }

  handleCleaningInCartOrder(order?: ICleaningOrder | null) {    
    this.setState({ cleaningOrder: order as ICleaningOrder });
  }

  onCloseProductPopup() {
    this.setState({ productPopup: false, productPopupData: null });
  }

  handleResForCreateUpdatePlantOrder(requestId: string, responseJson: { errors: string; status?: number; data: { attributes: ICleaningOrder } }) {
    if (requestId === this.homeCleaningOrderMessageId) {
      if (responseJson?.data) {
        const order = responseJson?.data?.attributes
        this.setState({ cleaningOrder: order });
        this.onCloseProductPopup();
        if (order?.status == "placed") {
          this.setState({
            previousCustomer: this.state.customerData,
            customerPhone: '',
            cleaningOrder: null,
            cartTotalOpen: false,
            countryCodeInput: '+966',
            orderSummaryVisible: true,
            paymentModalVisible: false,
            subtotalOrder: responseJson.data
          });
        }
      } else if (responseJson?.status == 422) {
        this.onCloseProductPopup();
        this.setState({ errorMessage: responseJson.errors, errorSnackbarOpen: true });
      }
    }
  }

  onCreateOrUpdatePlantOrder(body: { }) {
    const { cleaningOrder } = this.state
    const { id } = cleaningOrder || {}                

    const suffix = id ? id : 'new_order'
    const message = makeApiMessage({
      url: configJSON.homeCleaningOrderEndpoint + suffix,
      method: id ? 'PUT' : 'POST',
      body: JSON.stringify(body)
    })

    this.homeCleaningOrderMessageId = message.messageId;
    runEngine.sendMessage(message.id, message);
  }  

  handleClearAll(specificID:string="") {
    if (!this.state.cleaningOrder) return;

    const { cleaningOrder, selectedMainTab } = this.state
    const { userData, customerId } = this.state
    const storeId = userData?.attributes?.employee_proifle?.data?.attributes
      ?.store_management?.attributes?.store_management_id;

    const isPlantOrder = selectedMainTab == ORDER_TYPES.PLANT_ORDER;

    let body = {
      data: {
        account_id: customerId,
        store_management_id: storeId,
        type: isPlantOrder ? "PlantCleaningOrder" : undefined,
        home_cleaning_order_items_attributes: cleaningOrder.order_items.map((item: ICleaningOrderItem) => {
          return {
            id: item.id,
            _destroy: true,
            home_cleaning_catalogue_id: item.attributes.home_cleaning_catalogue_id,
            home_cleaning_catalogue_type_id: item.attributes.home_cleaning_catalogue_type_id,
          }
        })
      }
    }

    if(specificID){
      body.data.home_cleaning_order_items_attributes = body.data.home_cleaning_order_items_attributes.filter(item => item.id == specificID)
    }

    this.onCreateOrUpdatePlantOrder(body as { })
  }

  handleClearCustomer() {
    this.setState({
      orderNotes: "",
      customerPhone: '',
      cleaningOrder: null,
      cartTotalOpen: false,
      countryCodeInput: '+966',
    })
  }

  getCleaningOrder() {
    const message = makeApiMessage({
      url: configJSON.homeCleaningGetOrderEndpoint + this.state.cleaningOrder?.id,
      method: 'GET'
    })
    this.homeCleaningOrderMessageId = message.messageId
    this.send(message);
  }

  handleCloseTotal() {
    this.setState({ cartTotalOpen: !this.state.cartTotalOpen });
  }

  onSetCustomerFromPopup(customer: unknown) {
    if (typeof customer != "string") {

      const { full_name, country_code, full_phone_number, customer_id } = customer as {
        full_name: string;
        customer_id: string;
        country_code: string;
        full_phone_number: string;
      } || {}

      const mobile_number = String(full_phone_number).replace(String(country_code).replace('+', ''), '');
      const option = `${mobile_number} - ${full_name}`;

      const customerList = [
        {
          option: option,
          id: customer_id,
          country_code: '+' + country_code,
          full_phone_number: full_phone_number,
        },
      ];

      this.setState({
        customersList: customerList,
        subtotalOrder: undefined,
        cartTotalOpen: false,
        cleaningOrder: null,
        order: undefined,
      }, () => {
        this.setState({
          countryCodeInput: '+' + country_code,
          customerPhone: `${mobile_number} - ${full_name}`
        });
      })
    }
  }

  handleCountyCodeInputChange = (event: React.ChangeEvent<{ value: string }>)=>{ 
    if (event && event.target && event.target.value) {
      this.setState({ countryCodeInput: event.target.value });
      this.handleCustomerMobileInputChange(
        event.target.value,
        ""
      );
    }
  }

  handleAutoPhoneChange = (value:IPhoneValue,setFieldValue: (field: string, value: string) => void,)=>{ 
    this.setState({
      clearPhoneNumber: false,
      orderSummaryVisible: false,
    });
    this.handleOnchangeCustomerMobileNumber(
      setFieldValue,
      value
    );
  }

  handlePayOptionChange = (value:string)=>{ 
    this.setState({ paymentMethod: value });
  }

  handleDateChange = (dateChange:Date)=>{ 
    this.setState({ pickupDate: dateChange });
  }

  handleOnUserChange = (userContext: IUserContext)=>{ 
    const {attributes} = userContext?.user?.attributes?.employee_proifle?.data?.attributes?.store_management || {}
    const { is_process_cleaning_products } = attributes || {}

  this.setState(
    {
      storeListPageNo: 1,
      enableHomeCleaningSettings: String(is_process_cleaning_products) == "true",
      userData: userContext?.user,
      userRefresh: userContext?.refreshUser,
      tabsId:
        userContext?.user?.attributes?.employee_proifle?.data
          ?.attributes?.store_management?.attributes?.sections?.[0]
          ?.id as number,
    },
    () => {
      this.getStoreList();
      this.getCatalogueList();
    }
  );
  }
  
  checkError(error: string, touch: boolean | undefined) {
    if (error && touch) {
      return true;
    }
  }

  formatTaxPercentage = () => {
    const { order: laundryOrder, cleaningOrder } = this.state;
    const order = laundryOrder || { attributes: cleaningOrder };

    if (!order || !order.attributes) return null;
    const decimalIndex = order.attributes.tax_percentage.indexOf(".");
    const formattedTaxPercentage =
      order.attributes.tax_percentage.slice(0, decimalIndex) + "%";
    return formattedTaxPercentage;
  };

  getConditionBasedUI = (condition: boolean, trueValue: unknown, falseValue: unknown) => {
    return condition ? trueValue : falseValue;
  }

  getGraterZeroValue = (value: unknown) => {
    if (Number(value) > 0 || isNaN(Number(value))) return Number(value)
    else return 0
  }
  
  getOrderRenderBasedOnOrderType = (trueValue: React.ReactNode, falseValue: React.ReactNode) => {
    const condition = [ORDER_TYPES.CLEANING_ORDER, ORDER_TYPES.PLANT_ORDER].includes(this.state.selectedMainTab)
    return condition ? trueValue : falseValue;
  }

  debouncedFunction = _lodash.debounce(
    (newInputValue: string) =>
      this.handleCustomerMobileInputChange(
        (this.state.countryCodeInput || "") + newInputValue,
        newInputValue
      ),
    1000,
    { maxWait: 2000 }
  );

  getPaymentStringJoin = () => {
    const {subtotalOrder} = this.state
    const Strings = configJSON.Strings;
    const order = subtotalOrder?.attributes;
    const paymentString = `${Strings.paid}(${(order as ICleaningOrder)?.order_transactions?.map(({name, amount}) =>
      `${name}-${getCurrencyString(order?.currency || "SAR", amount)}`
    ).join(', ')})`
    return paymentString
  }

  getHasItemFilter = () => {
    const { cleaningOrder } = this.state;
    const { order_items } = cleaningOrder || { currency: 'SAR' };
    const hasItems = [...(order_items || '')].filter(item => item)?.length > 0;
    return hasItems
  }

  getUniqueProductTypes = () => {
    const { cleaningOrder } = this.state
    const order_items = cleaningOrder?.order_items || [];
    const uniqueProductTypes = Array.from(new Set(order_items.map(item => item.attributes.home_cleaning_catalogue_id)));
    return uniqueProductTypes
  }

  getTimeValue = () => {
    return new Date().getTime()
  }

  getOrderTypeList = () => {
    const { enableHomeCleaningSettings } = this.state
    const Strings = configJSON.Strings;

    let orderTypes = [
      { label: Strings.plant_cleaning, value: ORDER_TYPES.STORE_ORDER },
      { label: Strings.plant_tab, value: ORDER_TYPES.PLANT_ORDER }
    ];

    if(enableHomeCleaningSettings){
      orderTypes.push({ label: Strings.cleaning_tab, value: ORDER_TYPES.CLEANING_ORDER })
    }

   return orderTypes 
  }

  getUniqueCombinationsSet = () => {
    const uniqueCombinationsSet = new Set<string>();
    this.state.cartProducts
      ?.filter((element: ICartProduct) => element.serviceId && element.quantity > 0)
      .forEach((item: ICartProduct) => {
        const combination = {
          serviceName: item?.serviceName,
          tabsId: item?.tabsId,
        };
        uniqueCombinationsSet.add(JSON.stringify(combination));
      });
    const uniqueCombinations = Array.from(uniqueCombinationsSet).map(
      (item:string) => JSON.parse(item)
    );   
    return uniqueCombinations
  }

  getOrderItemList = () => {
    const { productPopupData, cleaningOrder } = this.state;
    const { order_items } = cleaningOrder || { order_items: [] };
    const { id } = (productPopupData as IPlantProduct)?.attributes || {};
    const filteredItems = order_items.filter((item) => item.attributes.home_cleaning_catalogue_id == id);
    return filteredItems
  }

  getOrderInitialValues = () => {
    return this.getOrderItemList().map((item) => item.attributes)
  }

  handleSearch = (value: string) => {
    this.setState(
      {
        currentPage: 1,
        searchText: value,
      },
      () => {
        ORDER_TYPES.STORE_ORDER == this.state.selectedMainTab && this.getCatalogueList(1);
        [ORDER_TYPES.PLANT_ORDER, ORDER_TYPES.CLEANING_ORDER].includes(this.state.selectedMainTab) && this.getProductList(1);
      }
    );
  };

  handleSearchStore = (value: string) => {
    if (value === this.state.storeSearchInput) return;
    this.setState({
      storeListPageNo: 1,
      storeSearchInput: value
    }, () => this.getStoreList())
  }

  debouncedStoreSearch = _lodash.debounce(
    (newInputValue: string) => this.handleSearchStore(newInputValue),
    700,
    { maxWait: 2000 }
  );
  // Customizable Area End
}
