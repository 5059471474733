Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "PeopleManagement2";
exports.labelBodyText = "PeopleManagement2 Body";
exports.popoverTest = "popoverTest";
exports.txtCustomers = "Customers";
exports.exportimportText = "Export/Import"
exports.successMessage = "CSV file has been exported successfully"
exports.addCustomer = "Add Customer"

exports.btnExampleTitle = "CLICK ME";
exports.cicularLoader = "CircularLoader";
exports.btnExampleTitle2 = "CLICK ME2";

exports.CustomerListLabels = {
  actions: {
    view: "View",
    edit: "Edit",
    activate: "Activate",
    deactivate: "Deactivate",
    addCustomer: 'Add Customer',
    backToListing: "Back to Listing",
  },
  labels: {
    noRecordsFound: "No records found!!",
    confirmDeactivate: "Confirm Deactivate",
    accountDeactivated: "Account Deactivated",
    customerDeactivated: "Customer Deactivated",
  },
  tableHeader: {
    id: 'ID',
    name: "Name",
    city: "City",
    profession: "Profession",
    organization: "Organization",
    customerType: "Customer Type",
    businessCategory: "Business Category",
  },
};

exports.addNewCustomerApiEndPoint = '/account_block/web_customers';
exports.addNewCustomerApiMethod = 'POST';
exports.EditCustomerApiMethod = 'PUT';
exports.getCustomerDetailsApiMethod = 'GET';

exports.getCustomerListApiEndPoint = '/account_block/customers';
exports.getCustomerListApiMethod = 'GET';

exports.dragAndDropProductApiEndPoint = '/bx_block_catalogue/catalogues/order_numbers';
exports.dragAndDropProductApiMethod = 'PUT';

exports.customerSuggestionApiEndPoint = '/account_block/web_customers/web_customer_filter';
exports.customerSuggestionApiMethod = 'GET';

exports.exportCustomerListCSVApiUrl = "/account_block/web_customers/csv_export"
exports.importCustomerListCsvApiurl = "/account_block/web_customers/csv_import"
exports.exportTemplateCustomerListCsvApiurl = "/account_block/web_customers/csv_template"

exports.exportCustomerListExcelApiUrl = "/bx_block_data_import_export/customer_import_export/export_excel"
exports.importCustomerListExcelApiurl = "/bx_block_data_import_export/customer_import_export/excel_import"
exports.exportTemplateCustomerListExcelApiurl = "/bx_block_data_import_export/customer_import_export/export_excel_template"
exports.CustomerProfile = "Customer Profile"
exports.CustomerTabelSpace = "Customer Loader"

// Customizable Area End