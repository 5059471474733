import React from "react";
import {
    styled,
    Box,
    Button,
    Dialog,
    DialogTitle,
    DialogContentText
} from "@material-ui/core";
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { MainBox, PrimaryButtonBox, SecondaryButtonBox, StyledDialogContent } from "./RoleDisableModal";

interface ConfirmationDialogProps {
    open: boolean;
    handleClose: () => void;
    handleConfirm?: any
    title: string;
    confirmText: string;
    displayError?: boolean;
    dynamic?: boolean;
    customMessage?: string;
}

class ConfirmationDialog extends React.Component<ConfirmationDialogProps> {
    render(): React.ReactNode {
        const {
            open,
            handleClose,
            handleConfirm,
            title,
            confirmText,
            displayError,
            dynamic,
            customMessage
        } = this.props;
        return (
            <CustomDialog
                className="main-dialog-container"
                open={open}
                data-test-id="commonConfirmModal"
                onClose={handleClose}>
                <Box className="dialog-main">
                    <Box className={`icon-section ${displayError ? "close" : "error"}`}>
                        {displayError ? <HighlightOffIcon /> : <HelpOutlineIcon color="primary" />}
                    </Box>
                    <DialogTitle>{title}</DialogTitle>
                    <StyledDialogContent>
                        <DialogContentText>{customMessage}</DialogContentText>
                    </StyledDialogContent>
                    <MainBox>
                        {
                            dynamic && <SecondaryButtonBox>
                                <Button
                                    data-test-id="dialog-cancel-btn"
                                    aria-label="cancel-modal-button"
                                    className={`secondaryButtonStyle`}
                                    onClick={handleClose}
                                    color="primary">
                                    Cancel
                                </Button>
                            </SecondaryButtonBox>
                        }
                        <PrimaryButtonBox>
                            <Button
                                data-test-id="dialog-confirm-btn"
                                aria-label="confirm-modal-button"
                                className={dynamic ? `primaryButtonStyle` : `primaryButtonForConfirm`}
                                onClick={() => handleConfirm()}
                                color="primary"
                                autoFocus>
                                {confirmText}
                            </Button>
                        </PrimaryButtonBox>
                    </MainBox>
                </Box>
            </CustomDialog>
        );
    }
}

export default ConfirmationDialog;

const CustomDialog = styled(Dialog)({
    "@media only screen and (max-width: 1024px)": {
        fontSize: 14,
        "& .icon-section": {
            marginBottom: "15px"
        },
        "& .dialog-main": {
            width: "471px",
            margin: " 40px 20px !important"
        }
    }
})
