import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
export const configJSON = require("./config");
import { IUserContext } from "../../../blocks/navigationmenu/src/PageContainerController.web";
import {
  PermissionStatus,
  checkForNewPermissonStatus,
  customPermissionApiKey
} from "../../../blocks/utilities/src/CustomBlockHelpers";
import { PermissionGroupArray } from "../../../blocks/navigationmenu/src/utils";
// Customizable Area End

// Customizable Area Start
enum Method {
  GET = "GET",
}

export interface InviteFriendInterface {
  id: string;
  type: string;
  attributes: {
    id: number | null;
    discount_amount: number | null;
    new_customer_discount: number | null;
    allow_customer_referrals: string | null;
    activated: boolean | null;
  };
}
// Customizable Area End

export interface Props {
  // Customizable Area Start
  classes?: any;
  navigation: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isLoading: boolean;
  isSubmitting: boolean;
  detail: InviteFriendInterface;
  permissionStatus: PermissionStatus;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ViewInviteFriendsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  public RequestMessage = {
    GetInviteFriendsDetail: this.buildRequestMessageCustomerTyperForView(
      Method.GET
    ),
    Null: undefined as any,
  };
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      isLoading: false,
      isSubmitting: false,
      detail: {
        id: "",
        type: "",
        attributes: {
          id: null,
          discount_amount: null,
          new_customer_discount: null,
          allow_customer_referrals: null,
          activated: null,
        },
      },
      permissionStatus: {
        mainPermission: false,
        createPermission: false,
        viewPermission: false,
        editPermission: false,
        deactivatePermission: false,
        activatePermission: false
      }
      // Customizable Area End
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) !== message.id) {
      return;
    }

    const callID = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const response = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (callID === this.RequestMessage.GetInviteFriendsDetail.messageId) {
      this.handleDetailResponse(response);
    }
    // Customizable Area End
  }

  // Customizable Area Start
  private handleDetailResponse(response: { data: InviteFriendInterface }) {
    if (response) {
      const { data } = response;

      this.setState({
        detail: data,
        isLoading: false,
      });
    }
  }

  handleCancelClick = () => {
    this.props.navigation.goBack();
  };

  handleEditClick = () => {
    this.props.navigation.navigate(`EditInvitefriends`, {
      id: this.state.detail.id,
    });
  };

  getDetails = (invite_friend_id: string) => {
    this.setState({ isLoading: true });

    this.RequestMessage.GetInviteFriendsDetail.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.inviteFriendsApiEndpoint}/${invite_friend_id}`
    );

    runEngine.sendMessage(
      this.RequestMessage.GetInviteFriendsDetail.id,
      this.RequestMessage.GetInviteFriendsDetail
    );
  };

  private buildRequestMessageCustomerTyperForView(method: Method): Message {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.customerTypeEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMessage),
      configJSON.APIBaseURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      this.getHeaderMessageForView()
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method.toString()
    );

    return requestMessage;
  }

  getHeaderMessageForView() {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: window.localStorage.getItem(configJSON.token),
    };

    return JSON.stringify(header);
  }

  handleUserChange = (userContext: IUserContext) => {
    const apiKey = customPermissionApiKey.inviteFriendModule;
    const userData = userContext.user?.attributes.permission_groups;
    const value = checkForNewPermissonStatus(apiKey, userData as Array<PermissionGroupArray>);
    this.setState({
      permissionStatus: value
    })
  };

  async componentDidMount() {
    super.componentDidMount();

    const customerTypeId = window.localStorage.getItem("invite_friend_id");
    this.getDetails(customerTypeId as string);
  }
  // Customizable Area End
}
