import React from "react";
// Customizable Area Start
import {
  Box,
  Button,
  Typography,
  Card,
  CardHeader,
  CardContent,
  Checkbox,
  FormControlLabel,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  Paper,
  Snackbar,
  styled
} from "@material-ui/core";
import { createTheme, ThemeProvider, withStyles } from "@material-ui/core/styles";
import { TypographyProps } from '@material-ui/core/Typography/Typography';
import GalleryController, { Props, AllImageIconType, configJSON } from "./GalleryController.web";
import PageContainer from "../../navigationmenu/src/PageContainer.web"
import Loader, {LoadingComponent} from "../../../components/src/Loader.web";
import { CSSProperties } from "@material-ui/styles";
import VerticalGalleryTabs from "../../../components/src/customComponent/verticalTabsGallery.web";
import FilterButton from "../../../components/src/customComponent/filterButton.web";
const CheckTrue = require('../../../components/src/commonAssets/checkBoxTrue.svg');
const CheckFalse = require('../../../components/src/commonAssets/checkBoxFalse.svg');
import {StyledRadio} from "../../../components/src/customComponents/DropdownRadio.web";
import ListIcon from '@material-ui/icons/List';
import ViewModuleIcon from '@material-ui/icons/ViewModule';
import SortingTableHeader from "../../../components/src/SortingTableHeader";
import SortingTableCell from "../../../components/src/SortingTableCell";
import MenuPopover from "../../../components/src/commonComponents/MenuPopover";
import DynamicDialogBox from "../../../components/src/commonComponents/DynamicDialogBox";
import { imageDelete } from "../../../components/src/commonAssets/assets";
import Alert from '@material-ui/lab/Alert';
import ExportImportPopover from "../../../components/src/customComponents/ExportImportButton.web";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import { colors } from "../../../blocks/utilities/src/Colors";
import InfiniteScroll from "react-infinite-scroll-component";
import LazyImage from "../../../components/src/LazyLoadingImage";



const theme = createTheme({
  typography: {
    fontFamily: "Montserrat, sans-serif",
  },
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
  props: {
    MuiCheckbox: {
      icon: <img src={CheckFalse} />,
      checkedIcon: (
        <img src={CheckTrue} />
      ),
    },
  },
});


export const styles: Record<string, CSSProperties> = {
  imgContainer: {
    height: '24px',
    width: '24px',
    borderRadius: '8px',
    marginBottom: '16px'
  },
  iconContainer: {
    height: '84px',
    width: '84px',
    marginBottom: '16px',
    "@media only screen and (max-width: 1024px)": {
      height: '64px',
      width: '64px',
    },
  },
  img: {
    height: '100%',
    width: '100%',
    objectFit: 'cover',
    borderRadius: '8px'
  },
  iconImgList: {
    width: '24px',
    height: '24px',
    objectFit: 'cover',
    "@media only screen and (max-width: 1024px)": {
      height: '17px',
      width: '17px',
    },
  },
  imgImgList: {
    width: '64px',
    height: '64px',
    objectFit: 'cover',
    "@media only screen and (max-width: 1024px)": {
      height: '34px',
      width: '34px',
    },
  },
  imgName: {
    "@media only screen and (max-width: 1024px)": {
      lineHeight: '34px !important',
    },
  },
  imageBox: {
    border: "1px solid #DEDEDE",
    borderRadius: "12px",
    height: "137px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: 'column',
    width: '137px',
    "@media only screen and (max-width: 1024px)": {
      width: '113px',
      height: "90px"
    },
  },

  iconBox:{
    border: "1px solid #DEDEDE",
    borderRadius: "12px",
    height: "146px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: 'column',
    width: '146px',
    "@media only screen and (max-width: 1024px)": {
      width: '113px',
      height: "108px"
    },
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '34px',
  },
  iconParentcontainer: {
    display: 'flex',
    flexWrap: 'wrap',
    columnGap: '105px',
    rowGap: '32px',
    "@media only screen and (max-width: 1024px)": {
      columnGap: '30px',
    },
  },
  productImageCard: {
    // height: "360px",
    borderRadius: "12px",
    // overflowY: 'scroll',
  },
  cardHeaderClass:{
    '& .MuiCardHeader-action': {
      marginTop : "0px"
    }
  },
  listIconTableContainer: {
    maxHeight: '350px'
  },
  listImageTableContainer: {
    maxHeight: '450px'
  },
  mainTableContainer: {
    paddingBottom:"32px",
    "@media (max-width: 768px)": {
      margin: '1rem 0 !important',
      padding: '20px 30px !important'
    }
  },
  mainGridContainer: {
    paddingBottom:"32px"
  },
  headerDetails: {
    "@media (max-width: 672px)": {
      marginTop: 10,
      width: "100%",
      justifyContent: 'space-between',
      flexWrap: 'wrap'
    }
  }
}




// Customizable Area End




class Gallery extends GalleryController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderGridView = () => {
    const classes = this.props.classes;
    return (
      <Box sx={webStyle.flex} space-between data-test-id='galleryGrid'>


        {this.state.allIcons?.length === 0 && this.state.allImages?.length == 0 && <div>
          {configJSON.noRecordFound}
        </div>}

        {this.state.allIcons?.length > 0 &&
          <Card style={{ ...webStyle.productIconCard, overflowY: "scroll" }}>
            <CardHeader
              style={webStyle.cstHeader}
              className={classes.cardHeaderClass}
              data-test-id='iconCardHeader'
              title={
                <Box style={webStyle.flexBetween}>
                  <Typography
                    style={webStyle.cardSmTitle as TypographyProps}
                  >
                    {configJSON.iconTitle}
                  </Typography>
                </Box>
              }
              action={
                <Box>
                  {
                    this.state.gridSelectItem === 'Icon'
                    && this.state.gridSelectMode === 'Delete'
                    && <FormControlLabel
                      control={<Checkbox
                        name="selectAllIcon"
                        onChange={this.selectAllForDelete}
                        checked={this.state.isCheckedSelectAll}
                      />
                      }
                      label={configJSON.selectAllText}
                    />
                  }

                  <MenuPopover
                    getSelectedOption={this.gridViewMenuAction}
                    menuIconColor="#FFF"
                    options={this.state.options}
                    menuWidth='136px'
                    // item={card}
                    identifier='Icon'
                    data-test-id='iconMenuPopover'
                  />
                </Box>
              }
            ></CardHeader>

            <CardContent style={webStyle.cardContent}>
              <Box className={classes.container}>
                {this.state?.allIcons?.map(
                  (card: AllImageIconType, index: number) => (
                    <Box
                      key={card.attributes.id}
                            style={{position: 'relative'}}
                    >
                            <Box style={{position: 'absolute'}}>
                        {
                          (this.state.gridSelectItem === 'Icon' &&
                            (<>
                              {this.state.gridSelectMode === 'Edit' &&
                                <StyledRadio
                                  checked={this.state?.gridEditItem?.attributes?.id === card.attributes.id}
                                        onChange={()=>{this.gridViewItemSelecteEdit(card)}} 
                                  data-test-id='editRadioIcon'
                                />
                              }
                              {this.state.gridSelectMode === 'Delete' &&
                                <Checkbox
                                  checked={this.state.gridDeleteItemIds.includes(card.attributes.id)}
                                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    this.gridViewItemSelectDelete(card.attributes.id, event.target.checked)
                                  }}
                                  data-test-id='deleteCheckboxIcon'
                                />
                              }
                            </>)
                          )
                        }
                      </Box>
                      <Box className={classes.imageBox}>
                        <Box className={classes.imgContainer}>
                          <LazyImage
                            src={card.attributes.image}
                            alt={card.attributes.name}
                            className={classes.img}
                            width={24}
                            height={24}
                          />
                        </Box>
                        <StyledTypography
                          align="center"
                          data-test-id='gridIconItemName'
                        >
                          {card.attributes.name}
                        </StyledTypography>
                      </Box>
                    </Box>
                  )
                )}
              </Box>
            </CardContent>

          </Card>
        }

        {this.state.allImages?.length > 0 &&
          <Card className={classes.productImageCard}>
            <InfiniteScroll
              dataLength={this.state.allImages.length}
              next={this.fetchMoreImages}
              hasMore={this.state.pageNumImg < this.state.totalPageImg}
              loader={<h4>Loading...</h4>}
              height={400}
            >
              <CardHeader
                style={webStyle.cstHeader}
                className={classes.cardHeaderClass}
                data-test-id='imageCardHeader'
                title={
                  <Box style={webStyle.flexBetween}>
                    <Typography
                      style={webStyle.cardSmTitle as TypographyProps}
                    >
                      {configJSON.imageTitle}
                    </Typography>
                  </Box>
                }
                action={
                  <Box>
                    {
                      this.state.gridSelectItem === 'Image'
                      && this.state.gridSelectMode === 'Delete'
                      && <FormControlLabel
                        control={<Checkbox name="selectAllIcon"
                          onChange={this.selectAllForDelete}
                          checked={this.state.isCheckedSelectAll}
                        />}
                        data-test-id='selectAllImage'
                        label="Select All"
                      />
                    }

                    <MenuPopover
                      getSelectedOption={this.gridViewMenuAction}
                      menuIconColor="#FFF"
                      options={this.state.options}
                      menuWidth='136px'
                      identifier='Image'
                    />
                  </Box>
                }
              ></CardHeader>
              <CardContent style={webStyle.imageCardContent}>
                <Box className={classes.iconParentcontainer}>
                  {
                    this.state?.allImages?.map(
                      (card: AllImageIconType) => (
                          <Box data-test-id="gridBox" key={card.attributes.id} style={{position: 'relative'}}>
                            <Box style={{position: 'absolute'}}>
                            {(this.state.gridSelectItem === 'Image' &&
                              (
                                <>
                                  {this.state.gridSelectMode === 'Edit' &&
                                    <StyledRadio
                                      checked={this.state?.gridEditItem?.attributes?.id === card.attributes.id}
                                        onChange={()=>{this.gridViewItemSelecteEdit(card)}} 
                                      data-test-id='editRadioImage'
                                    />
                                  }
                                  {this.state.gridSelectMode === 'Delete' &&
                                    <Checkbox
                                      checked={this.state.gridDeleteItemIds.includes(card.attributes.id)}
                                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        this.gridViewItemSelectDelete(card.attributes.id, event.target.checked)
                                      }}
                                      data-test-id='deleteCheckboxImage'
                                    />
                                  }
                                </>
                              )
                            )}
                          </Box>

                          <Box className={classes.iconBox}>
                              <Box  className={classes.iconContainer}>
                              <LazyImage
                                src={card.attributes.image}
                                alt={card.attributes.name}
                                className={classes.img}
                                width={24}
                                height={24}
                              />
                            </Box>
                            <StyledTypography
                              align="center"
                            >
                              {card.attributes.name}
                            </StyledTypography>
                          </Box>

                        </Box>
                      )
                    )
                  }
                </Box>
              </CardContent>
            </InfiniteScroll>
          </Card>

        }
      </Box>
    )
  }

  renderListView = () => {
    const classes = this.props.classes;
    return (
      <Box sx={webStyle.flex} space-between data-test-id='galleryList'>


        {this.state.allIcons?.length === 0 && this.state.allImages?.length==0 && <div>
          {configJSON.noRecordFound}
        </div>}

      { this.state.allIcons?.length > 0 &&
          <TableContainer component={Paper} className={classes.listIconTableContainer} data-test-id='listIconTable'>
            <Table style={webStyle.tableArea} aria-label='customized table'>
              <TableHead>
                <TableRow style={{ background: "#204B9C", color: "#FFFFFF" }}>
                  <SortingTableHeader
                    {...this.sortingProps}
                    sortingData={this.state.sortingData}
                    title={'Icon'}
                    sortingKey='icon'
                    type='left'
                    sortingDisabled
                  />

                  <SortingTableHeader
                    {...this.sortingProps}
                    sortingData={this.state.sortingData}
                    title={'Name'}
                    sortingKey='name'
                    data-test-id='iconListNameSort'
                  />

                  <SortingTableHeader
                    {...this.sortingProps}
                    sortingData={this.state.sortingData}
                    title=''
                    sortingKey=''
                    sortingDisabled
                    type='action'
                    width='50px'
                  />
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.allIcons?.map((card, index) => (
                  <TableRow key={card.attributes.id} data-test-id='iconRow'>
                    <SortingTableCell
                      width='100%' type='left'
                      content={
                        <LazyImage
                          src={card.attributes.image}
                          alt={card.attributes.name}
                          className={classes.iconImgList}
                          width={24}
                          height={24}
                        />
                      }
                    />

                    <SortingTableCell type="right" width='100%' content={card.attributes.name} />

                    <SortingTableCell
                      width='50px'
                      type='action'
                      data-test-id='iconMenuCell'
                      content={
                        <MenuPopover
                          getSelectedOption={this.listViewMenuAction}
                          menuIconColor="#000"
                          options={this.state.options}
                          menuWidth='136px'
                          item={card}
                          identifier='image'
                        />
                      }
                    />
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        }


        {this.state.allImages?.length > 0 &&

          <TableContainer component={Paper} className={classes.listImageTableContainer} data-test-id='listImageTable'>
            <InfiniteScroll
              dataLength={this.state.allImages.length}
              next={this.fetchMoreImages}
              hasMore={this.state.pageNumImg < this.state.totalPageImg}
              loader={<h4>Loading...</h4>}
              height={400}
            >
              <Table style={webStyle.tableArea} aria-label='customized table'>
                <TableHead>
                  <TableRow style={{ background: "#204B9C", color: "#FFFFFF" }}>
                    <SortingTableHeader
                      {...this.sortingPropsImage}
                      sortingData={this.state.sortingData}
                      title={'Image'}
                      sortingKey='image'
                      type='left'
                      sortingDisabled
                    />

                    <SortingTableHeader
                      {...this.sortingPropsImage}
                      sortingData={this.state.sortingDataImage}
                      title={'Name'}
                      sortingKey='name'
                      data-test-id='imageListNameSort'
                    />

                    <SortingTableHeader
                      {...this.sortingPropsImage}
                      sortingData={this.state.sortingData}
                      title=''
                      sortingKey=''
                      sortingDisabled
                      type='action'
                      width='50px'
                    />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.state.allImages?.map((card, index) => (
                    <TableRow key={card.attributes.id} data-test-id='imageRow'>
                      <SortingTableCell
                        width='50vw'
                        type='left'
                        content={
                          <LazyImage
                            src={card.attributes.image}
                            alt={card.attributes.name}
                            className={classes.iconImgList}
                            width={24}
                            height={24}
                          />

                        }

                      />

                      <SortingTableCell
                        width='50vw'
                        type='right'
                        content={card.attributes.name}
                      />

                      <SortingTableCell
                        width='50px'
                        type='action'
                        content={
                          <MenuPopover
                            getSelectedOption={this.listViewMenuAction}
                            menuIconColor="#000"
                            options={this.state.options}
                            menuWidth='136px'
                            item={card}
                          />
                        }
                      />
                    </TableRow>
                  ))}
                </TableBody>

              </Table>
            </InfiniteScroll>
          </TableContainer>
        }
      </Box>
    )
  }

  render() {
    const classes = this.props.classes;

    const openGalleryPopoverAction = Boolean(this.state.openGalleryAction);
    const popOverId = openGalleryPopoverAction ? "simple-popover" : undefined;
    return (
      //Merge Engine DefaultContainer
      <ThemeProvider theme={theme}>
        <PageContainer
          onUserChange={(userContext) => this.handleUserChange(userContext)}
          data-test-id='pageContainer' navigation={this.props.navigation} onSearchText={(value) => this.onSearchText(value)}>
          <Loader loading={this.state.loading} />
          <MainBoxWrapper>
            <Box className={!this.state.isGridView ? classes.mainTableContainer : classes.mainGridContainer}>
              <StyleTitleGridBox>

                <PageTitle>
                  {configJSON.galleryText}
                </PageTitle>

                <StyledBoxWrapper>

                  <StyleGridBox>
                    <Box
                      className={!this.state.isGridView ? 'selectedLeftSelectionButton' : 'leftSelectionButton'}
                      data-test-id='btnGalleryListView'
                      {...this.listProps}
                    >
                      <ListIcon />
                    </Box>
                    <Box
                      className={this.state.isGridView ? 'selectedRightSelectionButton' : 'rightSelectionButton'}
                      data-test-id='btnGalleryGridView'
                      data-testId='btnGalleryGridView'
                      {...this.gridProps}
                    >
                      <ViewModuleIcon />
                    </Box>
                  </StyleGridBox>


                  <FilterButton
                    callFilterApi={this.callFilterApi}
                    isUnderLine={true}
                    clearFilter={this.clearFilter}
                    clear={this.clear}
                  >
                    <VerticalGalleryTabs
                      data-test-id="verticalGallery"
                      getSelectedValue={this.getSelectedTabValue}
                      selectedTabs={this.state.filters}
                      getCurrrentTabValue={this.getCurrentTabValue}
                      setGalleryName={(value: any) => { this.setGalleryName(value) }}
                      optionsList={this.state.optionsList}
                    />
                  </FilterButton>

                  <Box>
                  <LoadingComponent
                  isLoading={this.state.isLoadingPermission}
                  height={44}
                  width={160}
                  isAllowed={this.state.permissionStatus.exportPermission}
                  children={
                      <StyledButton
                        data-test-id={`export-import-btn`}
                        id={"IconButton"}
                        aria-describedby={popOverId}
                        onClick={(event) => { this.setState({ openGalleryAction: event.currentTarget }) }}
                      >
                        {configJSON.exportimportText} {this.state.openGalleryAction ? <ExpandLess /> : <ExpandMore />}
                      </StyledButton>
                    }
                    />
                    </Box>
                  <ExportImportPopover
                    popOverId={popOverId}
                    openAction={openGalleryPopoverAction}
                    popoverOpen={this.state.openGalleryAction}
                    popoverClose={() => { this.handleGalleryCloseActionList() }}
                    exportTemplateButtonClick={() => this.handleGalleryExportTemplate()}
                    importCsvButtonClick={() => this.handleGalleryOpenImportModal()}
                    exportCsvButtonClick={() => this.handleGalleryExportCsv()}
                  />

                  {this.state.gridSelectMode && <StyledAddButton
                    variant='contained'
                    // @ts-ignore
                    onClick={this.gridViewSelectBtnAction}
                    data-test-id='btnActionEditDelete'
                  >
                    {
                      this.state.gridSelectMode
                    }
                  </StyledAddButton>}
                <div style={{maxWidth:"160px"}}>
                <LoadingComponent
                  isLoading={this.state.isLoadingPermission}
                  height={44}
                  width={160}
                  isAllowed={this.state.permissionStatus.createPermission}
                  children={<StyledAddButton
                    variant='contained'
                    onClick={() => this.toggleForm()}
                    data-test-id='testAddIconBtn'
                  >
                    {configJSON.addIconBtn}
                </StyledAddButton>}
                />
                </div>
                </StyledBoxWrapper>
              </StyleTitleGridBox>

              {this.state.isGridView ? this.renderGridView() : this.renderListView()}


            </Box>
          </MainBoxWrapper>
          <DynamicDialogBox
            icon={<img src={imageDelete} />}
            title={configJSON.deleteModalTitle}
            message={configJSON.deleteModalMessage}
            open={this.state.deleteDialogOpen}
            confirmBtnText="Delete"
            handleClose={this.deletePopupCancel}
            handleConfirm={() => { this.deleteIconImage(this.state.isGridView ? this.state.gridDeleteItemIds : [this.state.listDeleteItemId]) }}
            data-test-id='testDeleteConfirmDialog'
          />
        </PageContainer>
        <Snackbar
            id={configJSON.snackbarContainer}
            anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
            autoHideDuration={3000}
            open={this.state.snackbar?.open}
            onClose={this.handleCloseSnackbar}
            data-test-id='snackbar'
          >
            <Alert severity={this.state.snackbar?.severity}>
              {this.state.snackbar?.message}
            </Alert>
          </Snackbar>
      </ThemeProvider >
      //Merge Engine End DefaultContainer
    );
  }
  // Customizable Area End
}

// Customizable Area Start
const webStyle = {
  productContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    width: "100%",
    backgroundColor: "white",
    marginTop: "20px",
  },
  ImgContainer: {
    height: 150,
  },
  productImg: {
    cardContent: {
      padding: "32px 65px",
    },
  },
  detailContent: {
    display: "flex",
    flexDirection: "column",
  },
  GalleryTitle: {
    display: "flex",
    borderColor: "1px solid red",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "10px 0 0 10px",
  },
  galleryBtn: {
    backgroundColor: "#204B9C",
    color: "#FFFFFF",
    borderRadius: "8px",
  },
  productIconCard: {
    minHeight: "300px",
    borderRadius: "12px",
    maxHeight: '400px'
  },

  cstHeader: {
    background: "#204B9C",
    boxShadow: "0px 4px 8px rgb(0 0 0 / 3%), 0px 8px 32px rgb(0 0 0 / 6%)",
    borderRadius: "12px 12px 0px 0px",
    padding: "7px 44px",
    color: "#fff",
    margin: '0px'
  },
  cardSmTitle: {
    fontWeight: "600",
    fontSize: "18px",
  },
  cardContent: {
    padding: "10px",
  },
  imageCardContent: {
    padding: "10px",
  },
  gridBlock: {
    textAlign: "center",
  },
  cardTitle: {
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "22px",
    color: "#1A1A1A",
  },
  subtitle: {
    fontWeight: "500",
    fontSize: "12px",
    lineHeight: "22px",
  },
  grid: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",

  },
  marginTop: {
    marginTop: "2rem",
  },
  flex: {
    display: "flex",
    flexDirection: "column",
    gap: "2rem",
    width: '100%'
  },
  flexBetween: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  marginBottom_26: {
    marginBottom: "26px",
  },
  fontSize_24: {
    fontSize: "24px",
  },
  fw600: {
    fontWeight: "600",
  },
  lineHeight_29: {
    lineHeight: "29.26px",
  },
  padding_46: {
    padding: "46px 0",
  },
  mainHeader: {
    flexWrap: 'wrap'
  },
  buttonPrimary: {
    height: "56px",
    width: "184px",
    fontSize: "16px",
    textTransform: "none",
  },
  leftSelectionButton: {
    padding: "16px",
    borderTopLeftRadius: "15px",
    borderBottomLeftRadius: "15px",
    cursor: "pointer",
  },
  rightSelectionButton: {
    padding: "16px",
    borderTopRightRadius: "15px",
    borderBottomRightRadius: "15px",
    cursor: "pointer",
  },
  tableArea: {
    minWidth: 700,
  },
};

export const StyledButton = styled(Button)({
  fontSize: "16px",
  fontWeight: 600,
  width: "184px",
  height: "56px",
  borderRadius: "8px",
  lineHeight: "24px",
  textTransform: "unset",
  background: "#204B9C",
  color: "#FFFFFF",
  "&:hover": {
    background: "#204B9C !important",
    color: "#FFFFFF !important",
  },
  "&.Mui-disabled": {
    backgroundColor: "rgba(32, 75, 156, 0.5) !important",
    color: "#FFFFFF",
    opacity: 0.7
  },
  "@media only screen and (max-width: 1024px)": {
    width: "160px",
    height: "44px",
    fontSize: "13px",
  },
});

export const PageTitle = styled(Typography)({
  fontFamily: "Montserrat",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: "24px",
  lineHeight: "29px",
  "@media only screen and (max-width: 1024px)": {
    fontSize: "16px",
  },
});

const StyledAddButton  = styled(Button)({
  boxShadow:"none",
  fontSize: "16px",
  fontWeight: 600,
  width: "184px",
  height: "56px",
  borderRadius: "8px",
  lineHeight: "24px",
  textTransform: "unset",
  background: colors().cyancobaltblue,
  color: colors().background,
  "&:hover": {
    background: colors().cyancobaltblue,
    color: colors().background,
    boxShadow:"none",
  },
  "@media only screen and (max-width: 1024px)": {
    width: "140px",
    height: "44px",
    fontSize: "13px",
  },
});

const StyledBoxWrapper = styled(Box)({
  display: "flex", 
  gap: "16px", 
  flexWrap: 'wrap',
  '& .leftSelectionButton': {
    padding: "16px",
    borderTopLeftRadius: "8px",
    borderBottomLeftRadius: "8px",
    cursor: "pointer",
    "@media only screen and (max-width: 1024px)": {
      fontSize: "16px",
      width: "44px",
      height: "44px",
      padding: "10px",
    },
  },
  '& .selectedLeftSelectionButton': {
    padding: "16px",
    borderTopLeftRadius: "8px",
    borderBottomLeftRadius: "8px",
    cursor: "pointer",
    background: colors().brightgray,
    "@media only screen and (max-width: 1024px)": {
      fontSize: "16px",
      width: "44px",
      height: "44px",
      padding: "10px",
    },
  },
  '& .rightSelectionButton': {
    padding: "16px",
    borderTopRightRadius: "8px",
    borderBottomRightRadius: "8px",
    cursor: "pointer",
    "@media only screen and (max-width: 1024px)": {
      fontSize: "16px",
      width: "44px",
      height: "44px",
      padding: "10px",
    },
  },
  '& .selectedRightSelectionButton': {
    padding: "16px",
    borderTopRightRadius: "8px",
    borderBottomRightRadius: "8px",
    cursor: "pointer",
    background: colors().brightgray,
    "@media only screen and (max-width: 1024px)": {
      fontSize: "16px",
      width: "44px",
      height: "44px",
      padding: "10px",
    },
  },
});

const StyleGridBox = styled(Box)({
  border: "1px solid",
  borderColor:colors().lightborder,
  borderRadius: "8px",
  display: "flex",
  "@media only screen and (max-width: 1024px)": {
    height: "44px"
  },
});


const StyleTitleGridBox = styled(Box)({
  display: "flex",
  borderColor: "1px solid red",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "32px 0",
  flexWrap: 'wrap',
  paddingBottom: "20px"
});

const MainBoxWrapper = styled(Box)({
  paddingBottom: "20px",
  "& .sortingTableRightCell":{
    "@media (min-width: 1025px)": {
      lineHeight:"1.4999"
    },
  }
});

const StyledTypography = styled(Typography)({
  fontWeight: 600,
  fontSize: "16px",
  lineHeight: "22px",
  color: "#1A1A1A",
  "@media only screen and (max-width: 1024px)": {
    fontSize: "13px",
    color: colors().darkjunglegreen
  },
});

export default  withStyles(styles)(Gallery)


// Customizable Area End







