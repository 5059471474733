// Customizable Area Start
import React from "react";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import { IBlock } from "../../../framework/src/IBlock";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { IFilterItem } from "../../promocodes/src/PromoCodeListController.web";
import { IFilter } from "../../../components/src/FilterPopover";
export const configJSON = require("./config");
import { makeApiMessage } from "../../../components/src/common";
import { ISortingData } from "../../../components/src/OrderTable/src/TableCell";
import moment from "moment-timezone";
import { renderBaseOnConditions, sortCondition } from "../../../blocks/utilities/src/CustomBlockHelpers";
// Customizable Area End

// Customizable Area Start

interface IStoreList {
  id: number;
  store_id: string;
  store_name: string;
  activated: boolean;
  region_id: number;
  created_at: string;
  posting_cash_date: string;
  sales_pcs: number;
  sale_amount: string;
  revenue_amount: string;
  declare_cash: string;
}

// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation: {};
  id: string;
  goBack: Function;
  onlyTable?: boolean;
  listLimit?: number;
  exportCsvFlag?: boolean;
  refeshStoreList?: boolean;
  receivedData?: { [key: string]: string; };
  filterExport?: string;
  filterData? : string;
  filterValue?: string;
  // Customizable Area End
}

export interface S {
  // Customizable Area Start
  filters: IFilterItem[];
  sortingData: ISortingData;
  query: string;
  page: number;
  totalCount: number;
  pageSize: number;
  storeList: Array<IStoreList>;
  filterBtnAnchor: { currentTarget: {} } | undefined | HTMLDivElement;
  storeMetaData: {
    [field: string]: string;
  };
  regionIdQuery: string;
  storeIdQuery: string;
  snackbar: {
    open: boolean;
    severity: "error" | "success" | "info" | "warning" | undefined;
    message: string;
  };
  // Customizable Area End
}

export interface SS {}

export default class StoreListController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getStoreListMessageId: string = "";
  getStoreExportMessageId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.BroadcastNavbarDataMessage)
    ];

    this.state = {
      filters: [
        {
          title: "Date",
          type: "dateselect",
          datevalue: { from: "", to: "" },
          value: this.props.filterValue || "today",
          options: [
            {
              label: "Today",
              value: "today",
            },
            {
              label: "Last 7 days",
              value: "last7",
            },
            {
              label: "Last 30 days",
              value: "last30",
            },
            {
              label: "Specific Dates",
              value: "specific",
            },
          ],
        },
      ],
      sortingData: {
        store_id: "",
        store_name: "",
        date_of_posting_cash: "",
        sales_pcs: "",
        sale_amount: "",
        revenue_amount: "",
        declare_cash: "",
      },
      snackbar: {
        open: false,
        severity: "info",
        message: "",
      },
      filterBtnAnchor: undefined,
      query: "",
      page: 1,
      storeMetaData: {},
      totalCount: 0,
      pageSize: 10,
      storeList: [],
      regionIdQuery:"",
      storeIdQuery: "",
    };

    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  async receive(_from: string, message: Message) {
    // Customizable Area Start
    const successResponseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    const apiRequestId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    this.handleStoreListListRes(apiRequestId, successResponseJson);
    this.handleStoreExporttRes(apiRequestId, successResponseJson);
    this.receiveDataFromTopBar(message)
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    this.getStoreBasedonRegion()
    // Customizable Area End
  }

  // Customizable Area Start

  getStoreBasedonRegion = () => {
    if(this.props.receivedData){
      let recievedData = this.props.receivedData
      this.setState({regionIdQuery:`&region_ids=${recievedData.regionMultiId}`,storeIdQuery:`&store_ids=${recievedData.storeId}`},()=>this.getStoreList())
    }
  }

  async componentDidUpdate(prevProps: Props) {
    if (this.props.exportCsvFlag !== prevProps.exportCsvFlag) {
      this.handleClickExport();
    }
    if (this.props.refeshStoreList !== prevProps.refeshStoreList) {
      this.getStoreList();
    }
  }

  getStoreList(pageNumber = 1) {
    const dateFilterQuery = sortCondition(this.props.onlyTable as boolean,this.props.filterData,this.handleDateFilterParam().dateFilterQueryVal)

    const apiUrl =
      configJSON.storeListApi +
      `?page_no=${pageNumber}&per_page=10${this.state.query}${dateFilterQuery}${this.state.regionIdQuery}${this.state.storeIdQuery}`;

    const requestMessage = makeApiMessage({
      method: configJSON.dashboarApiMethodType,
      url: apiUrl,
    });

    this.getStoreListMessageId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleDateFilterParam = () => {
    const dateFilter = this.state.filters.find(
      (item) => item?.title === "Date"
    )?.value;
    let startDate, endDate;
    let dateTypeQuery;
    let exportQuery;
    const today = moment().format("YYYY-MM-DD");
    let commonEndDate = today;
    let comomStartDate = moment().subtract(7, "days").format("YYYY-MM-DD");
    switch (dateFilter) {
      case "last7":
        endDate = commonEndDate;
        startDate = comomStartDate;
        dateTypeQuery = "&date_type=last_7_days";
        exportQuery = "?date_type=last_7_days";
        break;
      case "today":
        endDate = today;
        startDate = today;
        dateTypeQuery = "&date_type=today";
        exportQuery = "?date_type=today"
        break;
      case "specific":
        const dateFilter = this.state.filters.find(
          (item) => item?.title === "Date"
        )?.datevalue;
        if (dateFilter?.from && dateFilter?.to) {
          startDate = moment(dateFilter.from).format("YYYY-MM-DD");
          endDate = moment(dateFilter.to).format("YYYY-MM-DD");
          dateTypeQuery = `&filter_by[created_at]=true&filter_by[start_date]=${startDate}&filter_by[end_date]=${endDate}`;
          exportQuery = "?filter_by[created_at]=true&filter_by[start_date]=${startDate}&filter_by[end_date]=${endDate}";

        }
        break;
      case "last30":
        endDate = today;
        startDate = moment().subtract(30, "days").format("YYYY-MM-DD");
        dateTypeQuery = "&date_type=last_30_days";
        exportQuery = "?date_type=last_30_days";
        break;
      default:
        endDate = today;
        startDate = moment().subtract(7, "days").format("YYYY-MM-DD");
        dateTypeQuery = "&date_type=last_7_days";
        exportQuery = "?date_type=last_7_days";
    }

    const dateFilterQueryVal = dateTypeQuery;

    const endDateText = endDate
      ? this.handleMomentDateFormat(endDate, "DD-MM-YYYY")
      : moment().format("DD-MM-YYYY");
    const startDateText = startDate
      ? this.handleMomentDateFormat(startDate, "DD-MM-YYYY")
      : moment().subtract(7, "days").format("DD-MM-YYYY");
    return { dateFilterQueryVal, startDateText, endDateText,exportQuery };
  };

  handleStoreListListRes = (
    requestId: string,
    response: { data: []; meta: { [field: string]: string } }
  ) => {
    if (requestId === this.getStoreListMessageId) {
      this.setState({
        storeList: response?.data,
        storeMetaData: response?.meta,
        totalCount: Number(response?.meta?.total_count),
      });
    }
  };

  handleStoreExporttRes  = (
    requestId: string,
    response: { data: {url:string} }
  ) => {
    if (requestId === this.getStoreExportMessageId) {
      location.href = response.data.url
      this.setState({
        snackbar: {
          message: configJSON.successExportMsg,
          severity: "success",
          open: true,
        },
      });
    }
  };

  handleFilterButton = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    this.setState({
      filterBtnAnchor: event.currentTarget,
    });
  };

  handleCloseFilterButton = () => {
    this.setState({
      filterBtnAnchor: undefined,
    });
  };

  handleFilterChange = (filters: IFilter[]) => {
    this.setState(
      {
        filters,
        page: 1,
      },
      this.getStoreList
    );
  };

  getTableCellType(index: number, arrayLength: number) {
    if (index === 0) {
      return "left";
    } else if (index === arrayLength - 1) {
      return "right";
    } else {
      return "middle";
    }
  }

  getSortingProps() {
    return {
      sortingData: this.state.sortingData,
      onChange: (data: ISortingData) => this.setState({ sortingData: data }),
      onQueryChange: (query: string) => {
        const [sortBy, sortOrder] = query.split("=");
        this.handleQueryChange(`&sort_by=${sortBy}&sort_order=${sortOrder}`);
      },
    };
  }

  handleQueryChange = (query: string) => {
    this.setState({ query }, () => this.getStoreList());
  };

  handlePageChange(pageVal: number) {
    this.setState({ page: pageVal + 1 }, () => this.getStoreList(pageVal + 1));
  }

  handleMomentDateFormat = (date: string, dateFormat: string) => {
    let momentDate = moment.parseZone(date);
    const formattedDate = momentDate.format(dateFormat);
    return formattedDate;
  };

  getPeriodDate = (startPeriodDate: string, endPeriodDate: string) => {
    const periodDate = `${startPeriodDate}_${endPeriodDate}`;
    return periodDate;
  };

  handleSearch = (value: string) => {
    this.setState({ page: 1 });
  };

  getHeaderRender = (
    label: { title: string; sortingKey: string },
    index: number
  ) => {
    const targetIndex = [2, 4, 5, 6];
    return renderBaseOnConditions(
      targetIndex.includes(index),
      <div style={{ width: "90px" }}>{label.title}</div>,
      label.title
    );
  };

  handleClickExport = () => {
    const dateFilterQuery = sortCondition(this.props.onlyTable as boolean,this.props.filterExport,this.handleDateFilterParam().exportQuery)

    const apiUrl =
      configJSON.storeExportApi +
      `${this.state.query}${dateFilterQuery}${this.state.regionIdQuery}${this.state.storeIdQuery}`;

    const requestMessage = makeApiMessage({
      method: configJSON.dashboarApiMethodType,
      url: apiUrl,
    });

    this.getStoreExportMessageId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleCloseSnackbar = () => {
    this.setState({
      snackbar: {
        open: false,
        severity: "info",
        message: "",
      },
    });
  };

  receiveDataFromTopBar = (message: Message) => {
    if (message.id === getName(MessageEnum.BroadcastNavbarDataMessage)) {
      const recievedData = message.getData(
        getName(MessageEnum.BroadcastNavbarData)
      );
      if(recievedData.storeId){
        this.setState({regionIdQuery:`&region_ids=${recievedData.regionMultiId}`,storeIdQuery:`&store_ids=${recievedData.storeId}`},()=>this.getStoreList())
      }
    }
  }

  // Customizable Area End
}
