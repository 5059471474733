import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { IArea } from "./AreaListController.web";
import { ISortingData } from "../../../components/src/SortingTableHeader";
import { IFilter } from "../../../components/src/FilterPopover";
import { getFiterList, makeApiMessage } from "../../../components/src/common";
// Customizable Area Start
import { IUserContext } from "../../../blocks/navigationmenu/src/PageContainerController.web";
import {
  PermissionStatus,
  checkForNewPermissonStatus,
  customPermissionApiKey
} from "../../../blocks/utilities/src/CustomBlockHelpers";
import { PermissionGroupArray } from "../../../blocks/navigationmenu/src/utils";

export interface IRegion {
  id: string;
  type: string;
  attributes: {
    id: number;
    activated: boolean;
    branch_name: string;
    branch_id: string;
    email: string;
    telephone: string;
    areas: IArea[];
    others: {
      [key: string]: string;
    };
    branch_short_name: null | string;
    area_count: number;
    created_at: string;
    updated_at: string;
  };
  relationships: {
    company: {
      data: {
        id: string;
        type: string;
      };
    };
  };
}

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
}

export interface S {
  drawerWidth: number;
  popOverOpened: boolean;
  popOverItemId: string;
  popOverItemStatus: string;
  popOverTop: number;
  popOverLeft: number;
  hoveredButton: string;
  regions: IRegion[];
  page: number;
  pageSize: number;
  sortingData: ISortingData;
  query: string;
  filterAnchor: any;
  filters: IFilter[];
  searchTextRegion: string;
  suggestionFieldTitle: string;
  permissionStatus: PermissionStatus;
}

export interface SS {
  id: any;
}

const emptyRegion: IRegion = {
  id: "",
  type: "-----",
  attributes: {
    id: 0,
    areas: [],
    activated: false,
    branch_name: "-----",
    branch_id: "-----",
    email: "-----",
    telephone: "-----",
    others: {},
    branch_short_name: "-----",
    created_at: "",
    updated_at: "",
    area_count: 0
  },
  relationships: {
    company: {
      data: {
        id: "",
        type: "",
      },
    },
  },
};

export default class RegionListController extends BlockComponent<Props, S, SS> {
  getRegionsCallId: string = "";
  updateUserCallId: string = "";
  filterSuggestionApiCallId: string = "";
  tableRefs: HTMLElement | null = null;

  constructor(props: Props) {
    super(props);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),

      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];
    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    this.state = {
      popOverItemId: "",
      popOverItemStatus: "",
      drawerWidth: 0,
      popOverOpened: false,
      popOverLeft: 0,
      popOverTop: 0,
      hoveredButton: "",
      regions: [],
      page: 0,
      pageSize: 10,
      sortingData: {
        branch_name: "",
        email: "",
        telephone: "",
        branch_id: "",
        area_name: "",
        area_count: "",
      },
      query: "",
      searchTextRegion: "",
      suggestionFieldTitle: "",
      filters: [
        {
          title: "Region Name",
          type: "autocompolete",
          value: "",
          options: []
        },
        {
          title: "Company",
          type: "autocompolete",
          value: "",
          options: []
        },
        {
          title: "Area",
          type: "autocompolete",
          value: "",
          options: []
        },
        {
          title: "Area Count",
          type: "select",
          options: [
            {
              label: "Maximum To Minimum",
              value: "DESC",
            },
            {
              label: "Minimum To Maximum",
              value: "ASC",
            },
          ],
          value: "",
        },
      ],
      filterAnchor: false,
      permissionStatus: {
        mainPermission: false,
        createPermission: false,
        viewPermission: false,
        editPermission: false,
        deactivatePermission: false
      }
    };
  }

  processMeta = (message: Message) => {
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

    let newRegions = Array<IRegion>(responseJson.meta.total_count);
    for (let i = 0; i < responseJson.meta.total_count; i++) {
      if (
        i >= (responseJson.meta.current_page - 1) * 10 &&
        i < responseJson.meta.current_page * 10
      ) {
        newRegions[i] = responseJson.data[i - (responseJson.meta.current_page - 1) * 10];
      } else {
        if (!this.state.regions[i] || !this.state.regions[i].id) newRegions[i] = { ...emptyRegion };
      }
    }
    this.setState({ regions: newRegions });
  };

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (apiRequestCallId === this.getRegionsCallId) {
        this.handleRegionListData(responseJson, message);
      } else if (apiRequestCallId === this.updateUserCallId) {
        this.getRegions();
      } else if (apiRequestCallId === this.filterSuggestionApiCallId) {
        if (responseJson) {
          const updatedFilters = getFiterList(
            responseJson,
            this.state.filters,
            this.state.suggestionFieldTitle
          );
          this.setState({ filters: updatedFilters });
        }
      }
    }
  }

  createData(
    name: string,
    email: string,
    phone: string,
    branchId: string,
    area: string,
    id: string,
    status: string,
    areaCount: number,
  ) {
    return { name, email, phone, branchId, area, id, status, areaCount };
  }

  handleDrawerWidthChange = (width: number) => {
    this.setState({ drawerWidth: width });
  };

  getRegions = () => {
    const header = {
      "Content-Type": configJSON.contentType,
      token: window.localStorage.getItem(configJSON.tokenKey),
    };
    const getDataMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getRegionsCallId = getDataMsg.messageId;

    const nameFilter = this.state.filters.find((item) => item.title === "Region Name")?.value;
    const companyFilter = this.state.filters.find((item) => item.title === "Company")?.value;
    const areaFilter = this.state.filters.find((item) => item.title === "Area")?.value;
    const areaCount = this.state.filters.find((item) => item.title === "Area Count")?.value;
    const searchTextRegion = this.state.searchTextRegion;

    let url =
      configJSON.regionListApi +
      "?page_no=" +
      (this.state.page + 1) +
      (nameFilter ? `&filter_by[region_name]=${nameFilter}` : "") +
      (companyFilter ? `&filter_by[company_name]=${companyFilter}` : "") +
      (areaFilter ? `&filter_by[area_name]=${areaFilter}` : "") +
      (areaCount ? `&filter_by[area_count]=${areaCount}` : "") +
      (searchTextRegion ? `&filter_by[query]=${searchTextRegion}` : "") +
      this.state.query;

    getDataMsg.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), url);
    getDataMsg.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    getDataMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
    runEngine.sendMessage(getDataMsg.id, getDataMsg);
  };

  handleRegionListData = (responseJson: any, message: Message) => {
    if (responseJson) {
      if (responseJson.meta) {
        this.processMeta(message);
      } else {
        let regions = responseJson.data as IRegion[];
        regions = regions.filter((el) => el.attributes);
        this.setState({ regions: regions });
      }
    }
  };

  updateUser = (regionId: string, isActive: boolean) => {
    const header = {
      "Content-Type": configJSON.contentType,
      token: window.localStorage.getItem(configJSON.tokenKey),
    };

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.updateUserCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.regionListApi + "/" + regionId + "/" + (isActive ? "activate" : "deactivate")
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "PUT");

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  async componentDidMount() {
    super.componentDidMount();

    this.getRegions();
  }

  handlePageChange = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    page: number
  ) => {
    if (page !== this.state.page) {
      this.setState({ page }, () => this.getRegions());
    }
  };

  handleSearchRegion = (value: string) => {	
    this.setState(	
      {	
        searchTextRegion: value,	
      },	
      this.getRegions	
    );
  };

  handleAddRegion = () => {
    this.props.navigation.navigate("AddRegion");
  };

  handleEditRegion = (regionId: string) => {
    this.setState({ popOverOpened: false });
    this.props.navigation.history.push("Settings-General-RegionListEdit?regionId=" + regionId);
    window.localStorage.setItem("regionId", regionId);
  };

  handleViewRegion = (regionId: string) => {
    this.setState({ popOverOpened: false });
    this.props.navigation.history.push("Settings-General-RegionListView?regionId=" + regionId);
    window.localStorage.setItem("regionId", regionId);
  };

  handleDeactivateRegion = () => {
    this.setState({ popOverOpened: false });
    this.updateUser(this.state.popOverItemId, false);
  };

  handleActivateRegion = () => {
    this.setState({ popOverOpened: false });
    this.updateUser(this.state.popOverItemId, this.state.popOverItemStatus !== "Active");
  };

  handleQueryChange = (query: string) => {
    this.setState({ query }, () => this.getRegions());
  };

  sortingProps = {
    onChange: (sortingData: ISortingData) => this.setState({ sortingData }),
    onQueryChange: (query: string) => this.handleQueryChange(query),
    width: "18%",
  };

  handleFilterChange = (filters: IFilter[]) => {
    this.setState({ filters }, this.getRegions);
  };

  handleRegionAutoComplete = (title: string, value: string) => {
    this.state.filters.forEach((item: IFilter) => {
      if (item.title === title) item.value = value;
      else item.value = "";
      return item;
    });

    let ApiUrl;
    if(title === "Region Name") {
      ApiUrl = configJSON.regionFilterSuggestionEndPoint;
    } else if (title === "Company") {
      ApiUrl = configJSON.regionCompanyFilterSuggestionEndPoint;
    } else if (title === "Area") {
      ApiUrl = configJSON.regionAreaFilterSuggestionEndPoint;
    }

    let requestMessage = makeApiMessage({
      url: ApiUrl + value,
      method: "GET",
    });
    this.filterSuggestionApiCallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleUserChange = (userContext: IUserContext) => {
    const apiKey = customPermissionApiKey.regionNewPermission;
    const userData = userContext.user?.attributes.permission_groups;
    const value = checkForNewPermissonStatus(apiKey, userData as Array<PermissionGroupArray>);
    this.setState({
      permissionStatus: value
    })
  };

  // Customizable Area End
}
