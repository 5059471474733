import React from 'react';
import { Select, SelectProps, MenuItem, styled, Radio } from '@material-ui/core';
import { isString, toString } from 'lodash';
import { colors } from '../../../blocks/utilities/src/Colors';

type Option = string | { value: string; label: string }

type Props = SelectProps & {
    options: Option[]
}

const CustomSelect: React.FC<Props> = (props) => {
    const { options, value, placeholder } = props

    return (
        <StyledSelect
            variant="outlined"
            displayEmpty
            renderValue={(value) => options.includes(value as string) ? toString(value) : toString(placeholder)}
            MenuProps={{
                anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                },
                transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                },
                MenuListProps: {
                    style: {
                        maxHeight: 190,
                    },
                },
                PaperProps: {
                    style: {
                        marginTop: 12,
                        boxShadow: "0px 4px 8px 0px rgba(0, 0, 0, 0.03), 0px 8px 32px 0px rgba(0, 0, 0, 0.06)",
                        borderRadius: 8,
                        padding: 0
                    }
                },
            getContentAnchorEl: null,
            }}
            {...props}
        >
            {
                options.map((option: Option) => {
                    const optionValue = isString(option) ? option : option.value
                    const optionLabel = isString(option) ? option : option.label

                    return (
                        <StyledMenuItem key={optionValue} value={optionValue} >
                            <Radio
                                checked={optionValue == value}
                                icon={<span style={webStyle.radioIcon} />}
                                checkedIcon={<span style={webStyle.radioCheckedIcon} />}
                            />
                            <p>{optionLabel}</p>
                        </StyledMenuItem>
                    )
                })
            }
        </StyledSelect>
    )
}

const StyledSelect = styled(Select)({
    minWidth: 200,
    maxWidth: 400,
    "& .MuiSelect-select": {
        fontFamily: '"Montserrat", sans-serif',
        fontSize: 16,
        fontWeight: 500,
        textAlign: "left",
        lineHeight: "24px",
        borderRadius: 8,
        background: "white",
        paddingTop: 16,
        paddingBottom: 16,
        "@media only screen and (max-width: 1280px)": {
            fontSize: 14,
        },
        "@media only screen and (max-width: 1024px)": {
            fontSize: 12,
        }
    },
    "& .MuiOutlinedInput-notchedOutline": {
        borderColor: colors().lightborder,
        borderRadius: 8
    },
    "& .MuiSelect-icon": {
        color: "black"
    }
});

const StyledMenuItem = styled(MenuItem)({
    fontSize: 14,
    lineHeight: "22px"
})

const webStyle = {
    radioIcon: {
        display: "inline-block",
        borderRadius: "51%",
        width: 18,
        height: 18,
        border: "1px solid #64748C",
    },
    radioCheckedIcon: {
        display: "inline-block",
        width: 18,
        borderRadius: "51%",
        height: 18,
        border: "6px solid #204B9D",
        color: "#204b9c",
    },
}

export default CustomSelect;