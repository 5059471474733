import React from "react";

import {
  Container,
  Box,
  Button,
  Typography,
  // Customizable Area Start
  Grid,
  Card,
  Dialog,
  DialogContent,
  FormControlLabel,
  Checkbox,
  Avatar
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { imgCameraIcon, iconCheckBox, iconCheckBoxChecked } from "./assets";
import "./CustomisableUserProfiles.web.css"
import { FieldData } from "./utils";
import PageContainer from "../../navigationmenu/src/PageContainer.web"
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import CameraAltOutlinedIcon from '@material-ui/icons/CameraAltOutlined';

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
    secondary: {
      main: "#204B9C",
    }
  },
  typography: {
    fontFamily: "Montserrat",
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
  props: {
    MuiCheckbox: {
      icon: <img src={iconCheckBox} style={{ padding: '4px' }} />,
      checkedIcon: <img src={iconCheckBoxChecked} style={{ padding:'4px' }} />
    },
  }
});
// Customizable Area End

import CustomisableUserProfilesController, {
  Props,
  configJSON,
} from "./CustomisableUserProfilesController";

export default class CustomisableUserProfiles extends CustomisableUserProfilesController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderEditButton=()=>{
    return !this.state.isEditMode && <Grid item>
    <Box>
      <Button
        style={{ textTransform: "none", ...webStyle.buttonStyle }}
        onClick={() => { this.editModeChangeHandler(true) }}
        data-test-id='btnEditProfile'
      >
        {configJSON.btnEditProfileText}
      </Button>
    </Box>
  </Grid>
  }

  renderCover=()=>{
    return this.state.isEditMode ? (
      <Box style={{ ...webStyle.coverImgBlock, backgroundImage: `url('${this.state?.localCoverFile}')` }} onClick={this.coverImgInputRefClickHandler}>
        <input type='file' accept="image/jpeg, image/png, image/jpg" multiple={false} ref={this.coverImgInputRef} onChange={this.coverImageChangeHandler} style={{ display: 'none' }} data-test-id="inputCoverUpload" />
        <Box style={webStyle.imgProfileInnerBox}>
        <CameraAltOutlinedIcon style={{color: 'black',marginTop: '70px', fontSize: '32px'}} />
        </Box>
      </Box>
    ) : (
      <Box style={{ ...webStyle.coverImgBlock, cursor: "initial", backgroundImage: `url('${this.state?.localCoverFile}')` }}></Box>
    )
  }
  // Customizable Area End

  render() {
    const shortName = this.getUserShortName();
    return (
      // Customizable Area Start
      <PageContainer navigation={this.props.navigation}>
        <ThemeProvider theme={theme}>
          <Container style={{ padding: "30px"}} maxWidth={false}>
            <Grid
              justifyContent="space-between"
              container
            >
              <Grid item style={webStyle.headingGrid}>
                <Typography variant="h6" style={webStyle.heading}>
                  {this.getPageHeaderName()}
                </Typography>
              </Grid>

              {this.renderEditButton()}
            </Grid>
            {this.renderCover()}

            <Box
              sx={{ marginLeft: { xs: '0px', sm: '48px' }, flexDirection: { xs: 'column', sm: 'row' }, alignItems: { xs: 'center', sm: 'initial' } }}
              style={{ marginTop: "-76px", display: 'flex' }}
            >
              {this.state.isEditMode ?
                (<Box
                  style={{ position: 'relative', width: '156px', height: '156px', cursor: 'pointer' }}
                  onClick={this.imgInputRefClickHandler}
                >
                  <Box style={{ ...webStyle.imgProfileWrapper, backgroundImage: `url('${this.state?.localImgFile}')`, filter: 'brightness(50%)' }}>
                    {!this.state?.localImgFile && <Avatar style={{width: "100%", height: "100%"}} data-test-id="aaaaa">{shortName}</Avatar>}
                    <input type='file' accept="image/jpeg, image/png, image/jpg" multiple={false} ref={this.imgInputRef} onChange={this.imageChangeHandler} style={{ display: 'none' }} data-test-id="inputProfileUpload"></input>
                  </Box>
                  <Box style={{ ...webStyle.imgProfileBorder }}>
                    <Box style={webStyle.imgProfileBox}>
                      <Box style={webStyle.imgProfileInnerBox}>
                        <img src={imgCameraIcon} style={{ marginTop: '-10px' }}></img>
                        <Typography style={webStyle.changePhotoText}>{configJSON.textChangePhoto}</Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>)
                :
                (<Box style={{ position: 'relative', width: '156px', height: '156px' }} >
                  <Box style={{ ...webStyle.imgProfileWrapper, backgroundImage: `url('${this.state?.localImgFile}')` }}>
                  {!this.state?.localImgFile && <Avatar style={{width: "100%", height: "100%"}} data-test-id="aaaaa">{shortName}</Avatar>}
                  </Box>
                  <Box style={{ ...webStyle.imgProfileBorder }} />
                </Box>)}
              <Box
                sx={{ marginLeft: { xs: '0px', sm: '24px' }, marginTop: { xs: '10px', sm: '0px' } }}
                style={{ display: 'flex', flexDirection: 'column-reverse' }}
              >
                <Box>
                  <Typography style={webStyle.profileName}>{`${this.state.profileData?.first_name ?? ""} ${this.state.profileData?.last_name ?? ""}`}</Typography>
                  <Typography style={webStyle.profileRole}>{this.state.profileData?.role?.name ?? configJSON.textAdmin} </Typography>
                </Box>
              </Box>
            </Box>


            {this.state.isEditMode &&
              <Card style={webStyle.cardWrapper}>
                <Grid container spacing={3}>
                  <Grid item sm={6} xs={12} style={webStyle.gridItem} >
                    <Typography style={{ ...webStyle.labelheading }}>
                      {configJSON.texFirstName}
                    </Typography>
                    <Box>
                      <input
                        type="text"
                        name="firstname"
                        data-test-id="inputFirstName"
                        placeholder={configJSON.textEnterFirstName}
                        style={webStyle.inputbox}
                        value={this.state.firstName}
                        onChange={this.firstNameChangeHandler}
                      />
                    </Box>
                    <Box style={{ marginTop: "6px" }}>
                      {this.state.errorFirstName && (
                        <small style={webStyle.errorStyle}>
                          {this.state.errorFirstName}
                        </small>
                      )}
                    </Box>
                  </Grid>
                  <Grid item sm={6} xs={12} style={webStyle.gridItem} >
                    <Typography style={{ ...webStyle.labelheading }}>
                      {configJSON.textLastName}
                    </Typography>
                    <Box>
                      <input
                        type="text"
                        name="lastname"
                        data-test-id="inputLastName"
                        placeholder={configJSON.textEnterLastName}
                        style={webStyle.inputbox}
                        value={this.state.lastName}
                        onChange={this.lastNameChangeHandler}
                      />
                    </Box>
                    <Box style={{ marginTop: "6px" }}>
                      {this.state.errorLastName && (
                        <small style={webStyle.errorStyle}>
                          {this.state.errorLastName}
                        </small>
                      )}
                    </Box>
                  </Grid>
                  <Grid item sm={6} xs={12} style={webStyle.gridItem} >
                    <Typography style={{ ...webStyle.labelheading }}>
                      {configJSON.textPassword}
                    </Typography>
                    <Box>
                      <input
                        type="password"
                        name="password"
                        data-test-id="inputPassword"
                        placeholder={configJSON.textEnterPassword}
                        style={webStyle.inputbox}
                        value={this.state.password}
                        onChange={this.passwordChangeHandler}
                      />
                    </Box>
                    <Box style={{ marginTop: "6px" }}>
                      {this.state.errorPassword && (
                        <small style={webStyle.errorStyle}>
                          {this.state.errorPassword}
                        </small>
                      )}
                    </Box>
                  </Grid>
                  <Grid item sm={6} xs={12} style={webStyle.gridItem} >
                    <Typography style={{ ...webStyle.labelheading }}>
                      {configJSON.textConfirmPassword}
                    </Typography>
                    <Box>
                      <input
                        type="password"
                        name="confirmpassword"
                        data-test-id="inputConfirmPassword"
                        placeholder={configJSON.textEnterConfirmPassword}
                        style={webStyle.inputbox}
                        value={this.state.confirmPassword}
                        onChange={this.confirmPasswordChangeHandler}
                      />
                    </Box>
                    <Box style={{ marginTop: "6px" }}>
                      {this.state.errorConfirmPassword && (
                        <small style={webStyle.errorStyle}>
                          {this.state.errorConfirmPassword}
                        </small>
                      )}
                    </Box>
                  </Grid>

                </Grid>
              </Card>}

            <Card style={webStyle.cardWrapper}>
              <Grid container spacing={3}>
                <Grid item sm={6} xs={12} style={webStyle.gridItem} >
                  <Typography style={webStyle.title}>{configJSON.labelDetailMobile}</Typography>
                  <Typography style={webStyle.value}>

                    {this.state.profileData?.country_code && this.state.profileData?.phone_number &&
                      `+${this.state.profileData?.country_code}-${this.state.profileData?.phone_number}`}
                  </Typography>
                </Grid>
                <Grid item sm={6} xs={12} style={webStyle.gridItem} >
                  <Typography style={webStyle.title}>{configJSON.labelDetailEmail}</Typography>
                  <Typography style={webStyle.value}>{this.state.profileData?.email}</Typography>
                </Grid>
                <Grid item sm={6} xs={12} style={webStyle.gridItem} >
                  <Typography style={webStyle.title}>{configJSON.labelDetailAccountFor}</Typography>
                  <Typography style={webStyle.value}>{this.state.profileData?.user_type === 1 ? configJSON.subContractor : configJSON.employee}</Typography>
                </Grid>
                <Grid item sm={6} xs={12} style={webStyle.gridItem} >
                  <Typography style={webStyle.title}>{configJSON.labelDetailEmployeeId}</Typography>
                  <Typography style={webStyle.value}>{this.state.profileData?.employee_proifle.data.attributes.user_login_id}</Typography>
                </Grid>
                <Grid item sm={6} xs={12} style={webStyle.gridItem} >
                  <Typography style={webStyle.title}>{configJSON.labelDetailDepartment}</Typography>
                  <Typography style={webStyle.value}>{this.state.profileData?.employee_proifle.data.attributes.department_name}</Typography>
                </Grid>
                <Grid item sm={6} xs={12} style={webStyle.gridItem} >
                  <Typography style={webStyle.title}>{configJSON.labelDetailPosition}</Typography>
                  <Typography style={webStyle.value}>{this.state.profileData?.employee_proifle.data.attributes.position}</Typography>
                </Grid>
                <Grid item sm={6} xs={12} style={webStyle.gridItem} >
                  <Typography style={webStyle.title}>{configJSON.labelDetailLocation}</Typography>
                  <Typography style={webStyle.value}>{this.state.profileData?.employee_proifle.data.attributes.location}</Typography>
                </Grid>
                <Grid item sm={6} xs={12} style={webStyle.gridItem} >
                  <Typography style={webStyle.title}>{configJSON.labelDetailDirectManager}</Typography>
                  <Typography style={webStyle.value}>{this.state.profileData?.employee_proifle.data.attributes.direct_manager_name}</Typography>
                </Grid>
                <Grid item sm={6} xs={12} style={webStyle.gridItem} >
                  <Typography style={webStyle.title}>{configJSON.labelDetailRole}</Typography>
                  <Typography style={webStyle.value}>{this.state.profileData?.role?.name}</Typography>
                </Grid>
                <Grid item sm={6} xs={12} style={webStyle.gridItem} >
                  <Typography style={webStyle.title}>{configJSON.labelDetailPermission}</Typography>
                  <Typography style={webStyle.value}>{this.state.profileData?.permission_count}</Typography>
                </Grid>
                {this.state.isEditMode && <Grid item xs={12} style={webStyle.gridItem}>
                  <p style={webStyle.adminEditHeading} onClick={() => { this.adminEditModeChangeHandler(true) }} data-test-id="btnAdminEdit">
                    {configJSON.labelAdminEdit}
                  </p>
                  <p style={{...webStyle.adminEditHeading, paddingLeft: '15px'}} onClick={() => { this.adminEditModeChangeHandler(true) }} data-test-id="btnAdminEdit1">
                    {">"}
                  </p>
                </Grid>}
              </Grid>
            </Card>

            {this.state.isEditMode &&
              <Box display={'flex'} mt={2}>
                <Box>
                  <Button
                    style={{ ...webStyle.buttonStyleEdit, ...webStyle.buttonStyleSecondary }}
                    onClick={this.profileCancelHandler}
                    data-test-id='btnProfileCancel'
                  >
                    {configJSON.textCancel}
                  </Button>
                </Box>

                <Box sx={{ marginLeft: '32px' }}>
                  <Button disabled={this.state.isSubmitting} style={{ ...webStyle.buttonStyleEdit, ...webStyle.buttonStylePrimary }} onClick={this.profileSaveHandler} data-test-id='btnProfileSave'>
                    {configJSON.textSave}
                  </Button>
                </Box>
              </Box>
            }
            <Dialog
              open={this.state.isAdminEditMode}
              onClose={() => { this.adminEditModeChangeHandler(false) }}
              className="adminDialog"
            >
              <Typography style={webStyle.dialogTitle}>{configJSON.labelAdminEditDialogTitle}</Typography>
              <DialogContent>
                <Box display={'flex'} style={{ marginTop: '30px' }} className="permissionCard">
                  <Typography style={{ ...webStyle.labelheading, ...webStyle.labelPermission, marginRight: "12px" }}>
                    {configJSON.textInformation}
                  </Typography>
                  <Typography style={{ ...webStyle.labelheading, ...webStyle.labelPermission }}>
                    {":"}
                  </Typography>
                  <Grid container spacing={3}>
                    {
                      configJSON.fieldsData.map((field: FieldData) => {
                        return (
                          <Grid key={field.value} item sm={6} xs={12} style={webStyle.gridItem} >
                            <FormControlLabel
                              data-test-id={`field${field.value}`}
                              control={<Checkbox checked={this.state.adminEditChecks[field.value]} onChange={this.adminCheckboxChangeHandler} name={field.value} color='secondary' />}
                              label={field.title}
                            />
                          </Grid>
                        )
                      })
                    }
                  </Grid>
                </Box>
              </DialogContent>
              <Box display={'flex'} style={{ margin: 'auto', padding: "16px 0 32px 0" }} mt={2}>
                <Box>
                  <Button onClick={this.adminPopUpCancelHandler} style={{ ...webStyle.buttonStyleEdit, ...webStyle.buttonStyleSecondary }} data-test-id="btnAdminEditCancel">
                    {configJSON.textCancel}
                  </Button>
                </Box>

                <Box sx={{ marginLeft: '32px' }}>
                  <Button disabled={this.state.isSubmitting} onClick={this.adminPopUpAddHandler} style={{ ...webStyle.buttonStyleEdit, ...webStyle.buttonStylePrimary }} data-test-id="btnAdminEditAdd">
                    {configJSON.textAdd}
                  </Button>
                </Box>
              </Box>
            </Dialog>
            <Snackbar
              anchorOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              open={this.state.snackbar.open}
              autoHideDuration={4000}
              onClose={this.handleSnackbarClose}
            >
              <Alert severity={this.state.snackbar.severity}>{this.state.snackbar.message}</Alert>
            </Snackbar>
          </Container>
        </ThemeProvider>
      </PageContainer>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  cardWrapper: {
    padding: '32px',
    margin: '32px 0',
    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.03), 0px 8px 32px rgba(0, 0, 0, 0.06)",
    borderRadius: "12px"
  },
  buttonStyle: {
    width: "184px",
    height: "56px",
    border: "none",
    backgroundColor: "#204B9C",
    borderRadius: '8px',
    textTransformation: "none",
    fontWeight: 600,
    cursor: 'pointer',
    color: '#FFF',
    fontSize: "16px",
    lineHeight: "24px",
  },
  gridItem: {
    // marginBottom: '8px'
  },
  heading: {
    fontFamily: 'Montserrat',
    fontWeight: 600,
    fontSize: "24px",
    lineHeight: "29px",
  },
  headingGrid: {
    display: 'flex',
  },
  title: {
    fontWeight: 600,
    fontSize: "18px",
    lineHeight: "22px",
    color: "#4D4D4D"
  },
  value: {
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "22px",
    color: "#1A1A1A",
    marginTop: '12px'
  },
  coverImgBlock: {
    height: '179px',
    marginTop: '32px',
    backgroundSize: 'cover',
    borderRadius: '120px 12px 12px 12px',
    cursor: 'pointer',
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    border: '1px solid #DEDEDE'
  },
  profileName: {
    fontWeight: 600,
    fontSize: "21px",
    lineHeight: "22px",
    color: "#1A1A1A",
  },
  profileRole: {
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "22px",
    color: "#4D4D4D",
    marginTop: '10px'
  },
  imgProfileWrapper: {
    borderRadius: '136px',
    width: '100%',
    height: '100%',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'none',
    backgroundColor: '#FFF'
  },
  imgProfileBorder: {
    position: "absolute" as const,
    width: "100%",
    height: "100%",
    borderRadius: "136px",
    top: 0,
    left: 0,
    boxShadow: "inset 0 0 0 4px rgba(32, 75, 156, 0.15)"
  },
  changePhotoText: {
    fontFamily: 'Montserrat',
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "20px",
    display: "flex",
    alignItems: "center",
    letterSpacing: "-0.35px",
    color: "#FFFFFF",
    marginTop: '10px'
  },
  buttonStyleEdit: {
    width: "126px",
    height: "56px",
    border: "none",
    fontSize: '16px',
    borderRadius: '8px',
    fontWeight: 700,
    cursor: 'pointer',
    textTransform: "none" as 'none',
  },
  buttonStylePrimary: {
    backgroundColor: "#204B9C",
    color: '#FFF'
  },
  buttonStyleSecondary: {
    backgroundColor: "#F1F5F9",
    color: '#64748B'
  },
  imgProfileBox: {
    height: "100%",
    display: "flex",
    alignContent: "center",
    alignItems: "center",
  },
  imgProfileInnerBox: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    alignContent: 'center',
    flexDirection: "column" as const,
  },
  labelheading: {
    fontFamily: "Montserrat",
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: "20px",
    color: "#1A1A1A",
    paddingBottom: '4px'
  },
  inputbox: {
    border: "0.139vw solid #ECECEC",
    borderRadius: "8px",
    backgroungColor: "#FFFFFF",
    height: "56px",
    outline: "none",
    fontFamily: "Montserrat",
    fontWeight: 500,
    fontSize: "14px",
    paddingLeft: "1vw",
    width: '100%'
  },
  labelPermission: {
    alignItems: 'flex-start',
    marginRight: '24px',
    paddingTop: '10px'
  },
  dialogTitle: {
    textAlign: 'center' as const,
    fontWeight: 600,
    fontSize: "24px",
    lineHeight: "24px",
    color: "#1A1A1A",
    marginTop: '32px',
    paddingBottom: "0px"
  },
  adminEditHeading: {
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '24px',
    color: '#204B9C',
    marginTop: '24px',
    cursor: 'pointer',
    display: 'inline',
    fontFamily: "Montserrat",
  },
  errorStyle: {
    padding: "5px",
    color: "red",
    fontFamily: "Montserrat",
    fontSize: "12px",
  },
};
// Customizable Area End
