import React from "react";
import {
  // Customizable Area Start
  Chip,
  Radio,
  styled,
  Select,
  MenuItem,
  Checkbox,
  Snackbar,
  TextField,
  FormGroup,
  MenuProps,
  FormControlLabel,
  InputAdornment,
  Box
  // Customizable Area End
} from "@material-ui/core";

import PromoCodeController, {
  // Customizable Area Start
  IOption,
  configJSON,
  // Customizable Area End
} from "./PromoCodeController.web";

// Customizable Area Start
import DateFnsUtils from "@date-io/moment";
import PageContainer from "../../navigationmenu/src/PageContainer.web"
import { Alert } from "@material-ui/lab";
import { plusIcon, deleteIcon } from "./assets";
import { calenderIcon } from "../../CfPriceList/src/assets";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { Spacer32, ChipContainer, LoadingComponent } from "./PromoCodeView.web";
import AutocompleteSelect from "../../../components/src/AutocompleteSelect.web";
import CustomLoader from "../../../components/src/customComponents/CustomLoader.web";
import { CustomSearchableMultiSelect } from "../../../components/src/customComponent/CustomSearchableMultiSelect.web";
import moment from "moment";
const Strings = configJSON.Strings.promoCodeCreate;
// Customizable Area End
class PromoCodeCreate extends PromoCodeController {
  // Customizable Area Start
  getOptionMultiple(
    placeholder: string,
    array: Array<string | number>,
    allDataLists: Array<IOption>
  ) {
    return array.length === 0 ? (
      <PlaceholderText>{placeholder}</PlaceholderText>
    ) : (
      <ChipContainer>
        {array.map((item: string | number) => (
          <StyledChip
            label={allDataLists.find(({ id }: IOption) => id === item)?.name}
            size='small'
          />
        ))}
      </ChipContainer>
    );
  }

  getOptionSingle(placeholder: string, item: string): React.ReactNode {
    return item ? (
      <StyledChip
        label={String(item)
          .split("_")
          .join(" ")}
        size='small'
      />
    ) : (
      <PlaceholderText>{placeholder}</PlaceholderText>
    );
  }

  getSelectAllOption(fieldName: string, checked: boolean, callback: Function) {
    return (
      <StyledMenuItem
        value={"selectAll"}
        key={"select-item-select-all-option"}
        onClick={() => callback(checked)}
        data-test-id={"field-select-all-" + fieldName}
      >
        <StyledCheckbox checked={checked} />
        Select All
      </StyledMenuItem>
    );
  }

  // Customizable Area End

  render() {
    // Customizable Area Start
    const {
      data,
      error,
      noData,
      usedList,
      regionList,
      areaList,
      isLoading,
      saveStatus,
      excludeDayList,
      productList,
      sectionList,
      storeList,
      serviceList,
      platformList,
      discountTypes,
      orderType,
      homeCleanProductList
    } = this.state;

    const {
      available_times,
      discount_type,
      discount,
      promo_expires,
      no_of_orders,
      description,
      promo_code,
      start_date,
      end_date,
      minAmount,
      maxAmount,
      used
    } = data.attributes;

    const CustomMenuProps: Partial<MenuProps> = {
      PaperProps: {
        style: { maxHeight: 250 },
      },
      anchorOrigin: {
        vertical: "bottom",
        horizontal: "left",
      },
      transformOrigin: {
        vertical: "top",
        horizontal: "left",
      },
      getContentAnchorEl: null,
    };
    // Customizable Area End
    return (
      // Customizable Area Start
      <PageContainer navigation={this.props.navigation}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <MainContainer>
            <Snackbar
              open={saveStatus.length > 0}
              autoHideDuration={5000}
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
            >
              <Alert
                onClose={() => this.setState({ saveStatus: "" })}
                severity={
                  saveStatus === Strings.promoSave ? "success" : "error"
                }
              >
                {saveStatus}
              </Alert>
            </Snackbar>
            <Heading>
              {this.isEdit ? Strings.edit : Strings.create} {Strings.promoCode}
            </Heading>
            {this.state.isSaveLoader && !noData && <CustomLoader loading={this.state.isSaveLoader}/>}

            {isLoading && !noData && <LoadingComponent />}
            {!isLoading && noData && (
              <ErrorLabel>{Strings.somethingWentWrong}</ErrorLabel>
            )}
            {!isLoading && !noData && (
              <>
                <WhiteContainer>
                  <StyledTextArea
                    minRows={3}
                    multiline
                    fullWidth
                    placeholder={Strings.addDescription}
                    value={description}
                    inputProps={{
                      "data-test-id": "field-description",
                    }}
                    onChange={(e) =>
                      this.onChangeValue("description", e.target.value)
                    }
                  />
                  {error.description && (
                    <ErrorLabel>{error.description}</ErrorLabel>
                  )}
                  <Spacer32 />
                  <GridContainer>
                    <div>
                      <TextLabel>{configJSON.Strings.promoCodeView.promoCodeLabel}</TextLabel>
                      <StyledTextField
                        disabled
                        fullWidth
                        value={promo_code}
                        variant='outlined'
                        inputProps={{
                          "data-test-id": "field-promoCode",
                        }}
                        placeholder={Strings.enterPromoCode}
                        onChange={(e) =>
                          this.onChangeValue("promo_code", e.target.value)
                        }
                      />
                      {error.promo_code && (
                        <ErrorLabel>{error.promo_code}</ErrorLabel>
                      )}
                    </div>
                    <div>
                      <TextLabel>{Strings.region}</TextLabel>
                      <CustomSearchableMultiSelect
                        id="region"
                        name="region"
                        dataTestId="regionDropdown"
                        checkSelectAllText={() => { }}
                        value={this.state.regionSelectedData}
                        optionList={this.state.regionList.map((item:any)=>{
                          return {
                            id: item.id,
                            option: item.name}
                        })}
                        placeHolderText="Select Region"
                        changeInputOnClear
                        handleScroll={this.handleScrollRegionDropdown}
                        handleClickOnSelectAll={() => { }}
                        isOnBlurFunction={() => this.debouncedFunction("", this.handleRegionAutoCompleteChange)}
                        debouncedFunction={(getValue: string) => { this.debouncedFunction(getValue, this.handleRegionAutoCompleteChange) }}
                        handleSelectOptions={(value: { id: string, option: string }[], option: { id: string, option: string }) => { this.handleRegionMultiSelect(value, option) }}
                        emptyDataMessage="No Region Found"
                        handleEmptyAutoSelectValue={() => { }}
                        selectedOptionForList={this.state.selectedRegionsForList}
                        isError={Boolean(error.region)}
                      />
                      {error.region && <ErrorLabel>{error.region}</ErrorLabel>}
                    </div>
                    <div>
                      <TextLabel>{Strings.area}</TextLabel>
                      <CustomSearchableMultiSelect
                        id="area"
                        name="area"
                        dataTestId="areaDropdown"
                        checkSelectAllText={() => { }}
                        value={this.state.areaSelectedData}
                        optionList={this.state.areaList.map((item:any)=>{
                          return {
                            id: item.id,
                            option: item.name}
                        })}
                        placeHolderText={configJSON.placeHolderTextArea}
                        changeInputOnClear
                        handleScroll={this.handleScrollRegionDropdown}
                        handleClickOnSelectAll={() => { }}
                        isOnBlurFunction={() => this.debouncedFunction("", this.handleRegionAutoCompleteChange)}
                        debouncedFunction={(getValue: string) => { this.debouncedFunction(getValue, this.handleRegionAutoCompleteChange) }}
                        handleSelectOptions={(value: { id: string, option: string }[], option: { id: string, option: string }) => { this.handleAreaMultiSelect(value, option) }}
                        emptyDataMessage={configJSON.emptyAreaDataMessage}
                        handleEmptyAutoSelectValue={() => { }}
                        selectedOptionForList={this.state.selectedAreasForList}
                        isError={Boolean(error.area)}
                      />
                      {error.area && <ErrorLabel>{error.area}</ErrorLabel>}
                    </div>
                    <div>

                      <TextLabel>{Strings.section}</TextLabel>
                      <CustomSearchableMultiSelect
                        id="section"
                        name="section"
                        dataTestId="sectionDropdown"
                        checkSelectAllText={() => { }}
                        value={this.state.sectionSelectedData}
                        optionList={this.state.sectionLists}
                        placeHolderText={configJSON.placeHolderTextSection}
                        changeInputOnClear
                        handleScroll={this.handleScrollSectionDropdown}
                        handleClickOnSelectAll={() => { }}
                        isOnBlurFunction={() => this.debouncedFunction("", this.handleSectionAutoCompleteChange)}
                        debouncedFunction={(getValue: string) => { this.debouncedFunction(getValue, this.handleSectionAutoCompleteChange) }}
                        handleSelectOptions={(value: { id: string, option: string }[], option: { id: string, option: string }) => { this.handleSectionMultiSelect(value, option) }}
                        emptyDataMessage={configJSON.emptySectionDataMessage}
                        handleEmptyAutoSelectValue={() => { }}
                        selectedOptionForList={this.state.selectedSectionsForList}
                        isError={Boolean(error.sections)}
                      />
                      {error.sections && (
                        <ErrorLabel>{error.sections}</ErrorLabel>
                      )}
                    </div>
                    <div>
                      <TextLabel>{Strings.services}</TextLabel>
                      <CustomSearchableMultiSelect
                        id="service"
                        name="service"
                        dataTestId="serviceDropdown"
                        checkSelectAllText={() => { }}
                        value={this.state.selectedServices}
                        optionList={this.state.serviceOptions}
                        placeHolderText="Select Service"
                        handleScroll={this.handleScrollServiceDropdown}
                        changeInputOnClear 
                        handleClickOnSelectAll={() => { }}
                        isOnBlurFunction={() => this.debouncedFunction("", this.handleServiceAutoCompleteChange)}
                        debouncedFunction={(getValue: string) => { this.debouncedFunction(getValue, this.handleServiceAutoCompleteChange) }}
                        handleSelectOptions={(value: { id: string, option: string }[], option: { id: string, option: string }) => { this.handleSelectServiceOptions(value, option) }}
                        emptyDataMessage="No Service Found"
                        handleEmptyAutoSelectValue={() => { }}
                        selectedOptionForList={this.state.selectedServicesForList}
                        isError={Boolean(error.services)}
                      />
                      {error.services && (
                        <ErrorLabel>{error.services}</ErrorLabel>
                      )}
                    </div>
                    <div>
                      <TextLabel>{Strings.product}</TextLabel>
                      <CustomSearchableMultiSelect
                        id="product"
                        name="product"
                        dataTestId="productDropdown"
                        checkSelectAllText={() => { }}
                        value={this.state.selectedForAdd}
                        optionList={this.state.productListItemsDropdown}
                        placeHolderText={configJSON.selectProductText}
                        changeInputOnClear
                        handleScroll={this.handleSpecificationScrollProductDropdown}
                        isOnBlurFunction={() => this.debouncedFunction("", this.handleSpecAutoCompltProductChange)}
                        handleClickOnSelectAll={() => {}}
                        debouncedFunction={(getValue: string) => { this.debouncedFunction(getValue, this.handleSpecAutoCompltProductChange) }}
                        handleSelectOptions={(value: { id: string, option: string }[], option: { id: string, option: string }) => { this.handleSpecificationSelectOptions(value, option) }}
                        emptyDataMessage={this.state.productListItemsDropdown.length ? "Data Loading" : configJSON.noProductText }
                        handleEmptyAutoSelectValue={() => {}}
                        selectedOptionForList={this.state.addSpecSelectedOptionForList}
                        isError={Boolean(error.products)}
                      />
                      {error.products && (
                        <ErrorLabel>{error.products}</ErrorLabel>
                      )}
                    </div>

                    <div>
                      <TextLabel>{Strings.platform}</TextLabel>
                      <CustomSearchableMultiSelect
                        id="platforms"
                        name="platforms"
                        dataTestId="field-platform"
                        checkSelectAllText={() => { }}
                        placeHolderText="Select Platforms"
                        changeInputOnClear
                        emptyDataMessage="No Platform Found"
                        isError={Boolean(error.platforms)}
                        handleScroll={() => { }}
                        handleClickOnSelectAll={() => { }}
                        handleEmptyAutoSelectValue={() => { }}
                        debouncedFunction={(getValue: string) => { }}
                        optionList={platformList}
                        value={this.state.selectedPlatform}
                        selectedOptionForList={this.state.platformSelectedOptionForList}
                        handleSelectOptions={(value: { id: string, option: string }[], option: { id: string, option: string }) => { this.handlePlatformMultiSelect(value, option) }}
                      />
                      {error.platforms && (
                        <ErrorLabel>{error.platforms}</ErrorLabel>
                      )}
                    </div>

                    {this.state.platformSelectedOptionForList.find((element:{id:number}) => element.id === 1) !==
                      undefined ? (
                      <div>
                        <TextLabel>{Strings.store}</TextLabel>
                          <CustomSearchableMultiSelect
                            id="store"
                            name="store"
                            dataTestId="fieldStore"
                            placeHolderText={Strings.selectStore}
                            changeInputOnClear
                            emptyDataMessage="No Store Found"
                            isError={Boolean(error.stores)}
                            handleScroll={() => { }}
                            checkSelectAllText={() => { }}
                            handleClickOnSelectAll={() => { }}
                            handleEmptyAutoSelectValue={() => { }}
                            debouncedFunction={(getValue: string) => { }}
                            optionList={storeList}
                            value={this.state.selectedStore}
                            selectedOptionForList={this.state.storeSelectedOptionForList}
                            handleSelectOptions={(value: { id: string, option: string }[], option: { id: string, option: string }) => { this.handleStoreMultiSelect(value, option) }}
                          />
                        {error.stores && (
                          <ErrorLabel>{error.stores}</ErrorLabel>
                        )}
                      </div>
                    ) : (
                      <div />
                    )}

                    <div>
                      <TextLabel>{Strings.orderType}</TextLabel>

                      <CustomSearchableMultiSelect
                        id="order-type"
                        name="order-type"
                        dataTestId="field-order-type"
                        placeHolderText={Strings.selectOrderTyoe}
                        changeInputOnClear
                        emptyDataMessage="No Order Type Found"
                        handleScroll={() => { }}
                        checkSelectAllText={() => { }}
                        handleClickOnSelectAll={() => { }}
                        handleEmptyAutoSelectValue={() => { }}
                        debouncedFunction={(getValue: string) => { }}
                        optionList={orderType}
                        value={this.state.selectedOrderType}
                        selectedOptionForList={this.state.orderTypeSelectedOptionForList}
                        handleSelectOptions={(value: { id: string, option: string }[], option: { id: string, option: string }) => { this.handleOrderTypeMultiSelect(value, option) }}
                      />

                    </div>
                    {this.state.orderTypeSelectedOptionForList.some((item:{id:string}) => item.id === "home_cleaning") ?
                      (
                        <div>
                          <TextLabel>{Strings.home_cleaning_product}</TextLabel>

                          <CustomSearchableMultiSelect
                            id="field-home-clean-product"
                            name="field-home-clean-product"
                            dataTestId="field-home-clean-product"
                            placeHolderText={Strings.selectHomeCleanProduct}
                            changeInputOnClear
                            emptyDataMessage="No Home Cleaning Products Found"
                            handleScroll={() => { }}
                            checkSelectAllText={() => { }}
                            handleClickOnSelectAll={() => { }}
                            handleEmptyAutoSelectValue={() => { }}
                            debouncedFunction={(getValue: string) => { }}
                            optionList={homeCleanProductList}
                            value={this.state.selectedHomeCleanProduct}
                            selectedOptionForList={this.state.homeCleanProductSelectedOptionForList}
                            handleSelectOptions={(value: { id: string, option: string }[], option: { id: string, option: string }) => { this.handleHomeCleaningProductMultiSelect(value, option) }}
                          />

                        </div>
                      )
                      :
                      <div />
                    }

                    <div>
                      <TextLabel>{Strings.used}</TextLabel>
               
                      <AutocompleteSelect
                        data-test-id="field-used"
                        fieldName='used'
                        placeholder={"Select Orders"}
                        options={usedList}
                        value={used}
                        isRadioButtonShow={true}
                        onChange={(
                          _event: React.ChangeEvent,
                          value: { id: string }
                        ) => {
                          this.onChangeValue("used", value.id)
                        }}
                      />

                      {error.used && <ErrorLabel>{error.used}</ErrorLabel>}
                    </div>

                    {used === "specific_number_of_orders" ? (
                      <div>
                        <TextLabel>{Strings.noOfOrders}</TextLabel>
                        <StyledTextField
                          fullWidth
                          inputProps={{
                            "data-test-id": "field-noOfOrders",
                          }}
                          value={no_of_orders}
                          variant='outlined'
                          placeholder={Strings.enterNoOfOrders}
                          onChange={(e) => {
                            if (
                              /^[0-9\b]+$/.test(e.target.value) ||
                              e.target.value === ""
                            ) {
                              this.onChangeValue(
                                "no_of_orders",
                                e.target.value
                              );
                            }
                          }}
                        />
                        {error.no_of_orders && (
                          <ErrorLabel>{error.no_of_orders}</ErrorLabel>
                        )}
                      </div>
                    ) : (
                      <></>
                    )}

                    <div>
                      <TextLabel>{Strings.startDate}</TextLabel>
                      <div style={{ position: "relative" }}>
                        <StyledDatePicker
                          InputProps={{
                            inputRef: this.startDateRef,
                          }}
                          fullWidth
                          inputProps={{
                            "data-test-id": "field-startDate",
                          }}
                          placeholder='DD/MM/YYYY'
                          format='DD/MM/YYYY'
                          value={start_date}
                          onChange={(e) => this.onChangeValue("start_date", e)}
                          disablePast={true}              
                          TextFieldComponent={(params) => (
                            <TextField
                              {...params}
                              helperText="" 
                              error={false}
                            />
                          ) as any}
                        />
                        <CalenderIcon
                          onClick={() => this.startDateRef?.current?.click()}
                          src={calenderIcon}
                        />
                      </div>
                      <div>
                        {error.start_date && (
                          <ErrorLabel>{error.start_date}</ErrorLabel>
                        )}
                      </div>

                      <FormGroup row>
                        <FormControlLabel
                          checked={Boolean(promo_expires)}
                          control={
                            <Checkbox
                              data-test-id='field-promoExpiry'
                              onClick={() =>
                                this.onChangeValue(
                                  "promo_expires",
                                  !Boolean(promo_expires)
                                )
                              }
                            />
                          }
                          label={
                            <LabelStyled
                              style={{
                                color: promo_expires ? "#204B9C" : "#4D4D4D",
                              }}
                            >
                              {Strings.promoExpiry}
                            </LabelStyled>
                          }
                        />
                      </FormGroup>
                    </div>
                    {promo_expires ? (
                      <div>
                        <TextLabel>{Strings.endDate}</TextLabel>
                        <div style={{ position: "relative" }}>
                          <StyledDatePicker
                            fullWidth
                            InputProps={{
                              inputRef: this.endDateRef,
                            }}
                            value={end_date}
                            inputProps={{
                              "data-test-id": "field-endDate",
                            }}
                            placeholder='DD/MM/YYYY'
                            format='DD/MM/YYYY'
                            onChange={(e) => this.onChangeValue("end_date", e)}
                            disablePast={true}
                          />
                          <CalenderIcon
                            src={calenderIcon}
                            onClick={() => this.endDateRef?.current?.click()}
                          />
                        </div>
                        <div>
                          {error.end_date && (
                            <ErrorLabel>{error.end_date}</ErrorLabel>
                          )}
                        </div>
                      </div>
                    ) : (
                      <div />
                    )}
                  </GridContainer>
                  <br />
                  <GridContainer>
                    <LeftContainer>
                      <div>
                        <TextLabel>{Strings.excludeDay}</TextLabel>
                        
                        <CustomSearchableMultiSelect
                          id="field-excludeDay"
                          name="field-excludeDay"
                          dataTestId="field-excludeDay"
                          placeHolderText={Strings.selectDay}
                          changeInputOnClear
                          emptyDataMessage="No Data Found"
                          handleScroll={() => { }}
                          checkSelectAllText={() => { }}
                          handleClickOnSelectAll={() => { }}
                          handleEmptyAutoSelectValue={() => { }}
                          debouncedFunction={(getValue: string) => { }}
                          optionList={excludeDayList}
                          value={this.state.selectedExcludeDays}
                          selectedOptionForList={this.state.excludeDaysSelectedOptionForList}
                          handleSelectOptions={(value: { id: string, option: string }[], option: { id: string, option: string }) => { this.handleExcludeDaysMultiSelect(value, option) }}
                        />

                        {error.exclude_days && (
                          <ErrorLabel>{error.exclude_days}</ErrorLabel>
                        )}
                      </div>
                      <div>
                        <TextLabel>{Strings.promoCodeType}</TextLabel>
                        
                        <AutocompleteSelect
                          data-test-id="field-type"
                          disableClear={true}
                          fieldName='promoCodeType'
                          placeholder={Strings.selectType}
                          options={discountTypes}
                          value={discount_type}
                          isRadioButtonShow={true}
                          onChange={(
                            _event: React.ChangeEvent,
                            value: { id: string }
                          ) => {
                            this.onChangeValue("discount_type", value.id)
                          }}
                        />

                        {error.discount_type && (
                          <ErrorLabel>{error.discount_type}</ErrorLabel>
                        )}
                      </div>
                      { discount_type !== "bogo" && (
                        <>
                      <div>
                        <TextLabel>{Strings.type}</TextLabel>
                        <StyledTextField
                          fullWidth
                          InputProps={{
                            startAdornment: (
                                <InputAdornment position="start"><span style={{ color: '#000', fontSize: '14px', fontWeight: 500, paddingBottom:5 }}>
                                  {discount_type === 'amount' ? 'SAR' : ''}
                                  </span></InputAdornment>
                            ),
                            endAdornment: (
                              <InputAdornment position="start"><span style={{ color: '#000', fontSize: '14px', fontWeight: 500, paddingBottom:5 }}>
                                {discount_type === 'percentage' ? '%' : ''}
                                </span></InputAdornment>
                            )
                        }}

                          inputProps={{
                            "data-test-id": "field-dicount",
                          }}
                          value={discount}
                          variant='outlined'
                          placeholder={Strings.discountPlaceHolder}
                          onChange={(e) => {
                            if (
                              /^[0-9\b.]+$/.test(e.target.value) ||
                              e.target.value === ""
                            ) {
                              this.onChangeValue("discount", e.target.value);
                            }
                          }}
                        />
                        {error.discount && (
                          <ErrorLabel>{error.discount}</ErrorLabel>
                        )}
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <MinAmtDiv>
                          <TextLabel style={{whiteSpace:"nowrap"}}>{Strings.minAmount}</TextLabel>
                          <StyledTextField
                            fullWidth
                            data-test-id="minAmtField"
                            variant='outlined'
                            placeholder={"Amount"}
                            value={minAmount}
                            inputProps={{
                              "data-test-id": "field-minAmount",
                            }}
                            onChange={(event)=>this.handleChangeNumberField(event,"minAmount")}
                          />
                          {error.minAmount && (
                            <ErrorLabel>{error.minAmount}</ErrorLabel>
                          )}
                        </MinAmtDiv>
                        <MaxAmtDiv>
                          <TextLabel style={{whiteSpace:"nowrap"}}>{Strings.maxAmount}</TextLabel>
                          <StyledTextField
                            data-test-id="maxAmtField"
                            fullWidth
                            variant='outlined'
                            placeholder={"Amount"}
                            value={maxAmount}
                            inputProps={{
                              "data-test-id": "field-maxAmount",
                            }}
                            onChange={(event)=>this.handleChangeNumberField(event,"maxAmount")}
                          />
                          {error.maxAmount && (
                            <ErrorLabel>{error.maxAmount}</ErrorLabel>
                          )}
                        </MaxAmtDiv>
                      </div>
                      </>
                      )}
                      {
                        discount_type == "bogo" && (
                          <>
                            <div>
                              <TextLabel>{Strings.discountedProduct}</TextLabel>
                              <AutocompleteSelect
                                listBoxStyle={{maxHeight: 260}}
                                disableClear={true}
                                placeholder="Select Product"
                                fieldName="discountProduct"
                                noOptionText="No product found"
                                options={this.state.productListItemsDropdown}
                                value={this.state.data.attributes.discount_product_id?.id}
                                onBlur={() => this.debouncedFunction("", this.handleSpecAutoCompltProductChange)}
                                onChange={(
                                  _event: React.ChangeEvent,
                                  value: { id: string, }
                                ) => {
                                  this.onChangeValue("discount_product_id", value)
                                }}
                                handleScroll={this.handleSpecificationScrollProductDropdown}
                                debouncedFunction={(getValue: string) => { this.debouncedFunction(getValue, this.handleSpecAutoCompltProductChange) }}
                               />
                              {error.discount_product_id && (
                                <ErrorLabel>{error.discount_product_id}</ErrorLabel>
                              )}
                            </div>
                          </>
                        )
                      }
                    </LeftContainer>

                    <div>
                      <TextLabel>{Strings.availableTime}</TextLabel>
                      <TimeContainer>
                        <>
                          {available_times
                            .filter((item) => !item._destroy)
                            .map(
                              ({ startDate, endDate, id }, index: number) => (
                                <React.Fragment key={`id${index}`}>
                                  <ButtonContainer>
                                    <div>
                                     <PromocodeTimer
                                        id="time"
                                        type="time"
                                        onChange={(e) => this.handlePromocodeTime(
                                          "startDate",
                                          index,
                                          e.target.value
                                        )}
                                        value={startDate ? startDate : ""}
                                        InputLabelProps={{
                                          shrink: true,
                                        }}
                                        inputProps={{
                                          "data-test-id": `field-from${index}`,
                                          step: 300, // 5 min
                                        }}
                                      />
                                    </div>
                                     <div>
                                      <PromocodeTimer
                                        id="time"
                                        type="time"
                                        onChange={(e) => this.handlePromocodeTime(
                                          "endDate",
                                          index,
                                          e.target.value
                                        )}
                                        value={endDate ? endDate : ""}
                                        InputLabelProps={{
                                          shrink: true,
                                        }}
                                        inputProps={{
                                          "data-test-id": `field-to${index}`,
                                          step: 300, // 5 min
                                          min: moment(start_date).format('hh:mm A')
                                        }}
                                      />
                                    </div>
                                    {index ===
                                      available_times.filter(
                                        (item) => !item._destroy
                                      ).length -
                                      1 ? (
                                      <BlueButton
                                        data-test-id='btn-addTime'
                                        onClick={() => this.onAddTime()}
                                      >
                                        <img src={plusIcon} />
                                      </BlueButton>
                                    ) : (
                                      <WhiteDeleteButton
                                        data-test-id={`btn-removeTime${index}`}
                                        onClick={() =>
                                          this.onRemoveTime(id, index)
                                        }
                                      >
                                        <img src={deleteIcon} />
                                      </WhiteDeleteButton>
                                    )}
                                  </ButtonContainer>
                                </React.Fragment>
                              )
                            )}
                          {error.available_times && (
                            <ErrorLabel>{error.available_times}</ErrorLabel>
                          )}
                        </>
                      </TimeContainer>
                    </div>
                  </GridContainer>
                </WhiteContainer>

                <BottomContainer>
                  <button
                    data-test-id='btn-cancel'
                    onClick={() => this.clickCancel()}
                    className='cancel'
                  >
                    {Strings.cancel}
                  </button>
                  <button
                    data-test-id='btn-submit'
                    className='add'
                    onClick={() => this.onSubmit()}
                  >
                    {this.isEdit ? Strings.save : Strings.add}
                  </button>
                </BottomContainer>
              </>
            )}
            <br />
            <br />
          </MainContainer>
        </MuiPickersUtilsProvider>
      </PageContainer>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

const StyledRadio = styled(Radio)({
  "&.MuiRadio-root": {
    color: "#ECECEC",
  },
  "&.Mui-checked": {
    color: "#204B9C !important",
  },
});

export const StyledCheckbox = styled(Checkbox)({
  "&.MuiCheckbox-root": {
    color: "#ECECEC",
  },
  "&.Mui-checked": {
    color: "#204B9C !important",
  },
});

export const ErrorLabel = styled("div")({
  fontSize: 14,
  marginTop: 6,
  fontWeight: 500,
  color: "#EC4E20",
  lineHeight: "22px",
  letterSpacing: "0.25px",
  fontFamily: "Montserrat",
});

const PromocodeTimer = styled(TextField)({
  width: "100%",
  "& .MuiInputBase-input": {
    borderRadius: 8,
    minHeight: "1.1876em",
    padding: "17.5px 14px",
    border: "1px solid var(--border, #ECECEC)",
    boxShadow: "0px 0px 1px rgba(0, 0, 0, 0.87)",
    "&:hover": {
      border: "1px solid rgba(0, 0, 0, 0.87)",
    },
  },
  "& .MuiInput-underline::before, & .MuiInput-underline::after": {
    border: "none !important",
  },
  "@media only screen and (max-width: 1024px)": {
    '& .MuiInputBase-input': {
      fontSize: '12px',
      padding:'14.9px 14px'
    },
  },
});

const PlaceholderText = styled("div")({
  fontSize: 14,
  fontWeight: 400,
  lineHeight: "22px",
  fontFamily: "Montserrat",
  color: "var(--neutrals-dark-gray, #4D4D4D)",
  "@media only screen and (max-width: 1024px)": {
    fontSize: '12px',
  },
});

const MinAmtDiv = styled("div")({
  width:'47%',
  "@media only screen and (max-width: 1280px)": {
    width: '47%',
  },
  "@media only screen and (max-width: 1366px)": {
    width: '47%',
  },
});

const MaxAmtDiv = styled("div")({
  width:'49%',
  "@media only screen and (max-width: 1280px)": {
    width: '50%',
  },
  "@media only screen and (max-width: 1366px)": {
    width: '50%',
  },
});

const LeftContainer = styled("div")({
  "& > div": {
    paddingBottom: 32,
  },
});

export const StyledMenuItem = styled(MenuItem)({
  gap: 4,
  padding:8,
  display: "flex",
  alignItems: "center",
  textTransform: "capitalize",
  justifyContent: "flex-start",
  "&.store-dropdown-item.MuiListItem-root": {
    background: "transparent",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.04)"
    }
  }
});

const StyledMenuItemWithHover = styled(StyledMenuItem)({
  minHeight: 54,
  "&.MuiListItem-root.Mui-selected, &.MuiListItem-root.Mui-selected:hover, &.MuiListItem-button:hover": {
    backgroundColor: "#204B9C",
    color: "#fff",
  },
});

export const StyledChip = styled(Chip)({
  "&.MuiChip-root": {
    marginBottom: "2px",
    color: "#204B9C",
    textTransform: "capitalize",
    backgroundColor: "#204B9C1A",
  },
});

export const LabelStyled = styled("span")({
  fontWeight: 500,
  fontSize: "16px",
  lineHeight: "24px",
});

const CalenderIcon = styled("img")({
  top: "50%",
  right: 16,
  position: "absolute",
  transform: "translateY(-50%)",
});

export const BottomContainer = styled("div")({
  gap: 32,
  display: "flex",
  paddingTop: 48,
  "& > button": {
    fontSize: 16,
    padding: 16,
    minWidth: 126,
    border: "none",
    fontWeight: 700,
    borderRadius: 8,
    cursor: "pointer",
    lineHeight: "24px",
    alignItems: "center",
    display: "inline-flex",
    justifyContent: "center",
    fontFamily: "Montserrat",
    "&:disabled": {
      opacity: 0.5,
    },
  },
  "& > .add": {
    color: "white",
    background: "var(--primary, #204B9C)",
    "@media only screen and (max-width: 1024px)": {
      width: "126px",
      height: "44px",
    },
  },
  "& > .cancel": {
    color: "#64748B",
    background: "var(--primary, #F1F5F9)",
    "@media only screen and (max-width: 1024px)": {
      width: "126px",
      height: "44px",
    },
  },
});

const StyledTextArea = styled(TextField)({
  marginTop: 32,
  borderRadius: 8,
  "& .MuiInput-underline::before, & .MuiInput-underline::after": {
    border: "none !important",
  },
  "& .MuiInput-multiline": {
    padding: 8,
    borderRadius: 8,
    border: "1px solid var(--border, #ECECEC)",
    boxShadow: "0px 0px 1px rgba(0, 0, 0, 0.87)",
    backgroundColor: "#ECECEC",
    "&:hover": {
      border: "1px solid rgba(0, 0, 0, 0.87)",
    },
  },
  "@media only screen and (max-width: 1024px)": {
    '& .MuiInputBase-input': {
      fontSize: '12px',
    },
  },
});

const StyledDatePicker = styled(DatePicker)({
  "& .MuiInputBase-input": {
    borderRadius: 8,
    minHeight: "1.1876em",
    padding: "17.5px 14px",
    border: "1px solid var(--border, #ECECEC)",
    boxShadow: "0px 0px 1px rgba(0, 0, 0, 0.87)",
    "&:hover": {
      border: "1px solid rgba(0, 0, 0, 0.87)",
    },
  },
  "& .MuiInput-underline::before, & .MuiInput-underline::after": {
    border: "none !important",
  },
  "@media only screen and (max-width: 1024px)": {
    '& .MuiInputBase-input': {
      fontSize: '12px',
      padding:'14.9px 14px'
    },
  },
});

export const StyledSelect = styled(Select)({
  borderRadius: 8,
  border: "1px solid var(--border, #ECECEC)",
  "@media only screen and (max-width: 1024px)": {
    fontSize: '12px',
      height:'48px'
  },
});

export const StyledTextField = styled(TextField)({
  borderRadius: 8,
  border: "1px solid var(--border, #ECECEC)",
  "@media only screen and (max-width: 1024px)": {
    '& .MuiInputBase-input': {
      fontSize: '12px',
      padding:'14.9px 14px'
    },
  },
});

export const TextLabel = styled("label")({
  display: "block",
  fontSize: 16,
  lineHeight: "24px",
  fontWeight: 600,
  paddingBottom: 4,
  color: "var(--neutrals-cool-gray-700, #334155)",
  "@media only screen and (max-width: 1024px)": {
    fontSize: "13px",
  },
});

export const GridContainer = styled("div")({
  display: "grid",
  gridGap: 32,
  gridTemplateColumns: "1fr 1fr",
});

export const WhiteContainer = styled("div")({
  borderRadius: 12,
  background: "#FFF",
  padding: "24px 32px",
  boxShadow:
    "0px 8px 32px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(0, 0, 0, 0.03)",
});

export const Heading = styled("h2")({
  color: "#000",
  fontSize: 24,
  fontWeight: 600,
  paddingBottom: 25,
  "@media only screen and (max-width: 1024px)": {
    fontSize: "16px",
  },
});

export const Container = styled("div")({
  padding: "48px",
  margin: "32px 0px",
});

const BlueButton = styled("div")({
  gap: 8,
  padding: 16,
  flexShrink: 0,
  width: "100%",
  height: "100%",
  borderRadius: 8,
  cursor: "pointer",
  alignItems: "center",
  display: "inline-flex",
  justifyContent: "center",
  background: "var(--primary, #204B9C)",
  "& > img": {
    maxWidth: "100%",
  },
  "@media only screen and (max-width: 1024px)": {
    width: "55px",
    height: "50px",
  },
});

const ButtonContainer = styled(GridContainer)({
  gridGap: 24,
  gridTemplateColumns: "1fr 1fr 56px",
});

const WhiteDeleteButton = styled("div")({
  gap: 8,
  padding: 16,
  flexShrink: 0,
  width: "100%",
  height: "100%",
  borderRadius: 8,
  cursor: "pointer",
  alignItems: "center",
  display: "inline-flex",
  justifyContent: "center",
  "& > img": {
    maxWidth: "100%",
  },
});

const TimeContainer = styled("div")({
  "& > div": {
    paddingBottom: 24,
  },
  "& > div:last-child": {
    paddingBottom: 0,
  },
});

const MainContainer = styled(Box)({
  paddingTop:'25px'
});

// Customizable Area End

export default PromoCodeCreate;
