// Customizable Area Start
import React from "react";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import Box from "@material-ui/core/Box";
import PageContainer from "../../navigationmenu/src/PageContainer.web"

export const configJSON = require("./config");

import ViewInviteFriendsController, {
  Props,
} from "./ViewInviteFriendsController.web";
import { colors } from "../../utilities/src/Colors";
import {
  styled
} from "@material-ui/core";
import { sortCondition } from "../../../blocks/utilities/src/CustomBlockHelpers";
// Customizable Area End

// Customizable Area Start
const EditButton = styled('div')({
    fontSize: "16px",
    lineHeight: "24px",
    background: colors().cyancobaltblue,
    width: "184px",
    fontWeight: 700,
    color: colors().white,
    height: "56px",
    textTransform: "unset",
    borderRadius: "8px",
    padding: "16px",
    textAlign: "center",
    cursor: 'pointer',
    "@media only screen and (max-width: 1024px)": {
      width: "128px",
      fontSize: "16px",
      height: "44px",
      padding: "10px",
    },
});
const ViewPageTitle = styled('div')({
  fontFamily: "Montserrat",
  fontWeight: 600,
  fontSize: 24,
  fontStyle: "normal",
  color:colors().black,
  "@media only screen and (max-width: 1024px)": {
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontSize: "16px",
    fontWeight: 600,
    color:colors().viewTextColor,
  },
})
const ViewStylesGridWrapper = styled(Grid)({
  display: "flex",
  justifyContent: "space-between",
  marginBottom: "20px",
  marginTop: "20px",
  alignItems: "center",
});
const BackButtonBox = styled('div')({
  width: "128px",
  height: "56px",
  border: "none",
  fontSize: '16px',
  borderRadius: '8px',
  fontWeight: 700,
  cursor: 'pointer',
  textTransform: "none" as 'none',
  backgroundColor: colors().cyancobaltblue,
  color: colors().background,
  padding:"16px 0",
  textAlign:"center",
  "@media only screen and (max-width: 1024px)": {
    width: 128,
    height: 44,
    padding:"12px 0",
  }
});
const ViewFormLabel = styled(Typography)({
  marginBottom: "8px",
  fontFamily: "Montserrat",
  fontStyle: "normal",
  fontSize: "18px",
  fontWeight: 600,
  marginTop: 4,
  color:colors().darkliver,
  "@media only screen and (max-width: 1024px)": {
    fontSize: "16px",
    fontWeight: 600,
    color:colors().viewTextColor,
  }
});
const StyledContentTypography = styled(Typography)({
  fontFamily: "Montserrat",
  fontStyle: "normal",
  fontSize: 16,
  fontWeight: 500,
  marginTop: 4,
  color:colors().viewTextColor,
  "@media only screen and (max-width: 1024px)": {
    fontSize: 13,
    fontWeight: 500,
  }
});

export const MainGridView = styled(Grid)({
  borderRadius: "12px",
  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.03), 0px 8px 32px rgba(0, 0, 0, 0.06)",
  padding: "32px",
  display: "flex",
  "@media (max-width: 600px)": {
    flexWrap: 'wrap',
    gap: 10
  }
});
// Customizable Area End

export class ViewInviteFriends extends ViewInviteFriendsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderTitle = () => {
    const { classes = {} } = this.props;
    return <ViewPageTitle>{configJSON.title}</ViewPageTitle>;
  };
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { detail } = this.state;

    return (
      <>
        <PageContainer 
        navigation={this.props.navigation} 
        onUserChange={(userContext) => this.handleUserChange(userContext)}
        >
          <Grid container direction='column'   className='pageContainerView'>
            <Box>
              <ViewStylesGridWrapper
                direction='row'>
                {this.renderTitle()}
                {
                sortCondition(
                  this.state.permissionStatus.editPermission,
                  <EditButton
                  data-test-id={"btn-edit"}
                    onClick={this.handleEditClick}
                  >
                    Edit
                  </EditButton>,
                      <></>
                    )
                  }
              </ViewStylesGridWrapper>
              <MainGridView container direction='column' className='formContainerView'>
                <Grid container direction='row'>
                  <Grid item xs={12} sm={6} style={{ paddingRight: 32 }}>
                    <FormControl style={{ width: "100%" }}>
                      <ViewFormLabel>
                        {configJSON.selectAllow}
                      </ViewFormLabel>
                      <StyledContentTypography>
                        {`Give One Time Credit to Existing and New Customer`}
                      </StyledContentTypography>
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid container direction='row' style={{ marginTop: 32 }}>
                  <Grid item xs={12} sm={6} style={{ paddingRight: 32 }}>
                    <FormControl style={{ width: "100%" }}>
                      <ViewFormLabel style={{ marginBottom: "8px" }}>
                        {configJSON.inputExistingCredit}
                      </ViewFormLabel>
                      <StyledContentTypography>
                        SAR {detail.attributes.discount_amount || configJSON.blank}
                      </StyledContentTypography>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl style={{ width: "100%" }}>
                      <ViewFormLabel>
                        {configJSON.inputNewCredit}
                      </ViewFormLabel>
                      <StyledContentTypography>
                        SAR {detail.attributes.new_customer_discount || configJSON.blank}
                      </StyledContentTypography>
                    </FormControl>
                  </Grid>
                </Grid>
              </MainGridView>

              <Grid container direction='row' style={{ marginTop: 48 }}>
                <BackButtonBox
                  data-test-id={"back-button"}
                  onClick={this.handleCancelClick}
                >
                Back
                </BackButtonBox>
              </Grid>
            </Box>
          </Grid>
        </PageContainer>
      </>
    );
    // Customizable Area End
  }
}

export default ViewInviteFriends;
