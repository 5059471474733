
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { ClassNameMap } from "@material-ui/styles";
import { PermissionStatus, checkForNewPermissonStatus, customPermissionApiKey, navigateTo } from "../../../../packages/blocks/utilities/src/CustomBlockHelpers";
import { PermissionGroupArray } from "../../../blocks/navigationmenu/src/utils";
import { IUserContext } from "../../../blocks/navigationmenu/src/PageContainerController.web";
interface IUpcharge {
    icon?: {
      id: number
      image: string
      image_type: string
      name: string
      name_translation: string
      second_name: string
    }
    price?: number
    product?: string[]
    section?: string[]
    service?: string[]
    type?: string
    upcharge_name?: string
    upcharge_second_name?: string
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes?: ClassNameMap;
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  isLoading: boolean;
  upchargeDetail: IUpcharge;
  errorSnackbarOpen: boolean;
  errorMessage: string;
  permissionStatus: PermissionStatus;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ViewUpchargeController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getUpchargeDetailApiId: string = "";
  getUpchargeEditId: string | number = this.props.navigation.getParam('upchargeId');

  async componentDidMount() {
    const upchargeId = this.props.navigation.getParam('upchargeId')
    this.getUpchargeDetail(upchargeId);
  }
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      getName(MessageEnum.RestAPIResponceErrorMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      isLoading: false,
      upchargeDetail: {},
      errorSnackbarOpen: false,
      errorMessage: '',
      permissionStatus: {
        mainPermission: false,
        createPermission: false,
        viewPermission: false,
        editPermission: false,
        deactivatePermission: false
      }
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      this.handleResponse(message)
    }
    // Customizable Area End
  }
  // Customizable Area Start

  handleResponse = (message: Message) => {
    let apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    let errorReponse = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );

    if (apiRequestCallId && responseJson && !responseJson?.errors) {
      if (apiRequestCallId === this.getUpchargeDetailApiId) {
        const { attributes } = responseJson.data
        const upchargeData = {
          upcharge_name: attributes?.name,
          upcharge_second_name: attributes?.second_name,
          section: attributes?.sections?.data?.map((section: { attributes: { section_name: string }}) => (section.attributes.section_name)),
          service: attributes?.services?.data?.map((service: { attributes: { name: string }}) => (service.attributes.name)),
          product: attributes?.catalogues?.data?.map((catalogue: { attributes: { product_name: string }}) => (catalogue.attributes.product_name)),
          price: attributes?.price,
          icon: attributes?.icon,
          type: attributes?.upcharge_type,
        }
        this.setState({ isLoading: false, upchargeDetail: upchargeData })

      }
    } else if (errorReponse || responseJson?.errors) {
      this.setState({ isLoading: false })
      this.setState({errorMessage: responseJson?.errors[0]?.message}, () => {
        this.setState({errorSnackbarOpen: true})
      })
    }
  }

  handleBackToUpchargeList = () => {
    navigateTo({ props: this.props, screenName: "UpchargeList"})
  }

  handleRedirect = () => {
    navigateTo({ id: this.getUpchargeEditId as string, props: this.props, screenName: "EditUpcharge"})
  }

  getUpchargeDetail = (upchargeId: number) => {
    this.setState({ isLoading: true });
    const headers = {
      "Content-Type": configJSON.applicationJsonContentType,
      token: localStorage.getItem('token'),
    };
    const apiUrl = configJSON.getUpchargeDetailEndpoint + upchargeId

    const message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getUpchargeDetailApiId = message.messageId;
    message.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiUrl
    );

    message.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    message.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetType
    );
    runEngine.sendMessage(message.id, message);
  }

  handleSnackbarClose() {
    this.setState({errorSnackbarOpen: false})
  }

  handleUserChange = (userContext: IUserContext) => {
    const apiKey = customPermissionApiKey.upChargeNewPermission;
    const userData = userContext.user?.attributes.permission_groups;
    const value = checkForNewPermissonStatus(apiKey, userData as Array<PermissionGroupArray>);
    this.setState({
      permissionStatus: value
    })
  };
  // Customizable Area End
}
