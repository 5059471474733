import React, { Component } from "react";
import { Skeleton } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';



const useStyles = makeStyles((theme) => ({
    fullHeightSkeleton: {
      height: '100%',
      width: '100%',
     // background:'red',
    },
  }));
  

const  FullHeightSkeleton =  () => {
    const classes = useStyles();
    return (
        <Skeleton classes={{ root: classes.fullHeightSkeleton }} animation="wave" variant="rect" />
    );
  
}
export default FullHeightSkeleton
