/** @format */
// Customizable Area Start
import React, { useState, useEffect } from "react";

import {
	DragDropContext,
	Droppable,
	Draggable,
	DropResult
} from "react-beautiful-dnd";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import DragHandleIcon from "@material-ui/icons/DragHandle";
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import DoneIcon from '@material-ui/icons/Done';
import ConfirmationModal from "./ConfirmationModal.web";
import CustomArrowIcon from "./CustomArrowIcon.web"
import {
  renderBaseOnConditions,
  renderBooleanConditions,
  PermissionStatus
} from "../../../blocks/utilities/src/CustomBlockHelpers";
import { styled, Tooltip } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { sortCondition } from "../../../blocks/utilities/src/CustomBlockHelpers";
// Customizable Area End

// Customizable Area Start
interface Item {
	id: number;
	// order_number: number;
	attributes: {
		section_name: string,
		section_second_name: string
		active: boolean,
		online: boolean,
		preference_id: number,
		order_number: number;
    section_group_ids: Array<string>;
	};
}
interface AffectedData {
	id: number;
	order_number: number;
}

interface DraggableTableProps {
	data: Item[];
	handleDragAndDrop: (items: AffectedData[]) => void;
	openModalId: number | null;
	deActivateMessage: string;
	successMessage: string;
	buttonText: string;
	verifyMessage: string;
	handleOpenModel: (id: number) => void;
	handleView: (item: Item) => void;
	handleEdit: (item: Item) => void;
	handleDeActivate: (item: Item) => void;
	handleActivate: (item: Item) => void;
	goToPreviousPage: () => void;
	goToNextPage: () => void;
	isLoading?: boolean;
	meta: {
		next_page: number;
		pervious_page: number;
		total_pages: number;
		total_count: number;
		current_page: number
	};
	isDragableDisabled: boolean;
	nameSorting: {
		value: string
	};
	handleSortingBySectionName: () => void;
	permissionStatus?: PermissionStatus;
	onClickChooseButton: Function;
	groupByContClassName?: string;

}

const DraggableTable = ({ data, meta, handleDragAndDrop,
	isDragableDisabled, nameSorting, goToNextPage, goToPreviousPage, openModalId,
	deActivateMessage, buttonText,
	handleOpenModel, handleView, handleEdit, handleDeActivate, handleSortingBySectionName,
  permissionStatus, handleActivate,
  onClickChooseButton,isLoading,groupByContClassName
}: DraggableTableProps) => {

	const [tableData, setTableData] = useState<Item[]>([]);
	const [open, setOpen] = useState(false);
	const [deactivated, setDeactivated] = useState(false);
	const [movedItem, setMovedItem] = useState<Item | null>(null);
	const [message, setMessage] = useState({ id: 0, title: "" })


	const handleClickOpen = (item: Item) => {
		setMessage({ id: item.id, title: item.attributes.section_name })
		setMovedItem(item)
		setOpen(true);

	};

	const handleClose = () => {
		setOpen(false);
	};

	const handleDelete = () => {
		if (movedItem) {
			handleDeActivate(movedItem);
		}
		setOpen(false);
		setDeactivated(true);
	};

	const handleActivateItem =(item: Item) => {
		handleActivate(item)
		setOpen(false);
	}

	const handleGoBack = () => {
		setDeactivated(false);
	};

	useEffect(() => {
		const sortedData = data.sort(
			(a: { attributes: { order_number: number; }; }, b: { attributes: { order_number: number; }; }) => b.attributes.order_number - a.attributes.order_number
		);
		setTableData(sortedData);
	}, [data]);

	const handleDragEnd = (result: DropResult) => {
		if (!result.destination) return;

		const orderNumberTopData = tableData?.[0]?.attributes.order_number
		const updatedData = Array.from(tableData);
		const movedItem = updatedData.splice(result.source.index, 1)[0];
		updatedData.splice(result.destination.index, 0, movedItem);

		updatedData.forEach((item: Item, index: number) => {
			item.attributes.order_number = orderNumberTopData - index;
		});

		setTableData(updatedData);

		const affectedItems: AffectedData[] = updatedData
			.slice(
				Math.min(result.source.index, result.destination.index),
				Math.max(result.source.index, result.destination.index) + 1
			)
			.map((item: Item) => ({
				id: item.id,
				order_number: item.attributes.order_number,
			}));
		handleDragAndDrop(affectedItems)
	};

	const itemsPerPage = 10;
	const totalItems = meta.total_count;
	const totalPages = meta.total_pages;
	let startIndex = (meta.current_page - 1) * itemsPerPage + 1;
	let endIndex = Math.min(startIndex + itemsPerPage - 1, totalItems);

	if (totalItems === 0) {
		startIndex = 0;
		endIndex = 0;
	}

	const isPreviousButtonDisabled = meta.current_page === 1;
	const isNextButtonDisabled =
		meta.current_page === totalPages || totalItems === 0;

	return (
		<>
			<div className="container-table">
				<DragDropContext onDragEnd={handleDragEnd}>
					<ul className="header-ul">
						<li className="header-list header-color">
							<div className="header-list-sec header-space drag-drop-header">
								<div className="section-arrow">
									<p>Section Name</p>
									<CustomArrowIcon
										up="nameUp"
										down="nameDown"
										selected={nameSorting.value}
										handleSelect={handleSortingBySectionName}
									/>
								</div>
								<div className="section-arrow active-para">
									<p>Active</p>
								</div>
								<div className="section-arrow">
									<p>Online</p>
								</div>
                <div className={`section-arrow ${groupByContClassName}`}>
									<p>Group By</p>
								</div>
							</div>
							<p></p>
						</li>
					</ul>
					<Droppable droppableId="sections">
						{(provided: any) => (
							<ul
								{...provided.droppableProps}
								ref={provided.innerRef}
								className="preference-list">
									<div className="tableRow" style={{padding:"10px"}} >
									{isLoading && [...Array(5)].map((_, cellIndex) => (
										      <Skeleton animation='wave' style={{ margin: "5px 0" }} variant='text' />

										))}
								</div>
								{tableData?.length === 0 && !isLoading ? 
								<h4 data-test-id="empty-data" className="empty-item-list">No section to show</h4> 
								: !isLoading && tableData?.map((item, index) => (
									<Draggable
										key={item.id}
										draggableId={item.id.toString()}
										isDragDisabled={isDragableDisabled ? true : false}
										index={index}>
										{(provided: any) => (
											<li
												{...provided.draggableProps}
												{...provided.dragHandleProps}
												ref={provided.innerRef}
												data-test-id={`list-item-${index}`}
												key={item.id}
												className={`header-list border-list`}>
												<p className="drag-icon">
													<DragHandleIcon />
												</p>
												<div className={`option-section ${!item.attributes?.active && "list-opacity"}`}>
													<p  style={webStyle.sectionName} className="option-item option-section-item" data-test-id="section-name">
														{item.attributes.section_name}
													</p>
													<p  style={webStyle.activeName} className="section-item-inner">
														{item.attributes.active ? <DoneIcon /> : "--"}
													</p>
													<p  style={webStyle.onlineAttri} className="section-item-inner-online">
														{item.attributes.online ? <DoneIcon /> : "--"}
													</p>
                            <p className='section-item-inner-online'>
							<Tooltip title={sortCondition((permissionStatus as PermissionStatus).groupingPermission as boolean,"","You don't have permission") as NonNullable<React.ReactNode>} placement="top">
							<ChooseOptionButton
								disabled={!(permissionStatus as PermissionStatus).groupingPermission}
                                data-test-id={`choose-button-${item.id}`}
                                onClick={() => onClickChooseButton(item)}
                              >
                              Choose Section
                            </ChooseOptionButton>
							</Tooltip>
                              
													</p>
												</div>
												<div className="position-relative">
													<div
														data-test-id={`icon-more-${item.id}`}
														onClick={() => handleOpenModel(item.id)}
														className="icon-moreVert">
														<MoreVertIcon />
													</div>
													{openModalId === item.id &&
														(
                                <>
                                  <>
                              {renderBaseOnConditions(
                                      renderBooleanConditions(
										  (permissionStatus as PermissionStatus)
											  ?.viewPermission ||
										  (permissionStatus as PermissionStatus)
											  ?.editPermission ||
										  (permissionStatus as PermissionStatus)
											  ?.activatePermission ||
										  (permissionStatus as PermissionStatus)
											  ?.deactivatePermission,
                                        true,
                                        false
                                      ),
                                      <div
                                        className='postion-container'
                                        data-test-id='popverMenu'
                                      >
                                        {renderBaseOnConditions(
                                          (permissionStatus as PermissionStatus)
                                            ?.viewPermission,
                                          <button
                                            data-test-id='view-btn'
                                            onClick={() => handleView(item)}
                                          >
                                            View
                                          </button>,
                                          <></>
                                        )}
                                        {renderBaseOnConditions(
                                          (permissionStatus as PermissionStatus)
                                            ?.editPermission,
                                          <button
                                            data-test-id='edit-btn'
                                            onClick={() => handleEdit(item)}
                                          >
                                            Edit
                                          </button>,
                                          <></>
                                        )}
                                        { item.attributes.active && renderBaseOnConditions(
                                          (permissionStatus as PermissionStatus)
                                            ?.deactivatePermission,
                                          <button
                                            data-test-id='delete-btn'
                                            onClick={() =>
                                              handleClickOpen(item)
                                            }
                                          >
                                            Deactivate
                                          </button>,
                                          <></>
                                        )}

										{!item.attributes.active && renderBaseOnConditions(
                                          (permissionStatus as PermissionStatus)
                                            ?.activatePermission as boolean,
                                          <button
                                            data-test-id='activate-btn'
                                            onClick={() =>
                                              handleActivateItem(item)
                                            }
                                          >
                                            Activate
                                          </button>,
                                          <></>
                                        )}
                                      </div>,
                                      <div
                                        className='postion-container'
                                        data-test-id='popverMenu'
                                      >
                                        <p className="px-8">No Permissions</p>
                                      </div>
                                    )}
                                  </>
                                </>
														)}
												</div>
											</li>
										)}
									</Draggable>
								))}
								{provided.placeholder}
							</ul>
						)}
					</Droppable>
				</DragDropContext>
			</div>
			<div className="pagination">

				<label className="pagination-label">
					{startIndex}-{endIndex} of {totalItems}
				</label>
				<button
          data-test-id="prevPage"
					className="pagination-button"
					onClick={goToPreviousPage}
					disabled={isPreviousButtonDisabled}>
					<ChevronLeftIcon />
				</button>
				<button data-test-id="nextPage" onClick={goToNextPage} disabled={isNextButtonDisabled}>
					<ChevronRightIcon />
				</button>
			</div>

			<ConfirmationModal
				open={open}
				handleClose={handleClose}
				handleConfirm={handleDelete}
				title="Confirm Deactivate"
				movedItem={movedItem}
				message={message}
				confirmText="Deactivate"
				dynamic={true}
			/>

			{deactivated && (
				<ConfirmationModal
					open={deactivated}
					handleClose={handleClose}
					handleConfirm={handleGoBack}
					title={deActivateMessage}
					message={message}
					confirmText={buttonText}
					displayItem={true}
				/>
			)}
		</>
	);
};

const ChooseOptionButton = styled("button")({
  padding: "6px 12px",
  background: "#204b9c",
  outline: "none",
  border: "none",
  color: "#ffffff",
  borderRadius: "8px",
})
const webStyle ={
	sectionName:{
		position:'relative' as 'relative',
		left:'20px'
	},
	activeName:{
		position:'relative' as 'relative',
		left:'30px'
	},
	onlineAttri:{
		position:'relative' as 'relative',
		left:'70px'
	}
}

export default DraggableTable;
// Customizable Area End
