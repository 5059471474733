Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.dashboardGetUrl = "/bx_block_dashboard/candidates";
exports.dashboarContentType = "application/json";
exports.dashboarApiMethodType = "GET";
exports.dashboardHost = "<calculated when request is sent>";
exports.dashboarUserAgent = "PostmanRuntime/7.26.5";
exports.dashboarAccept = "*/*";
exports.dashboarAcceptEncoding = "gzip, deflate, br";
exports.dashboarConnection = "keep-alive";
exports.dashboartoken = "";
exports.labelTitleText = "dashboard";
exports.totalCandidateslabelTitleText = "Total Candidates";
exports.viewDetailsBtn = "View Details";
exports.candidateslabelTitleText = "Candidates";
exports.baseUrl = ""
exports.labelBodyText = "dashboard Body";
exports.storeListApi = "bx_block_dashboard/cashier_store_list_dashboards"
exports.storeExportApi = "bx_block_data_import_export/export/export_cashier_store_list"
exports.successExportMsg = "CSV file has been exported successfully"
exports.StoreList = {
  Strings: {
    storeID: "Store ID",
    store: "Store",
    DateOfPostingCash: "Date of posting cash",
    salesPcs: "Sales pcs",
    salesAmount: "Sales amount",
    revenueAmount : "Revenue amount",
    declaredCash : "Declared Cash",
  },
};
exports.stringsListPage_noRecordsFound = "No Records Found";
exports.top = "top";
exports.center = "center";
exports.cancelBtnText = "Cancel";
exports.saleRevenueEndpoint = "bx_block_dashboard/cashier_dashboards/sale_revenue"
exports.cashInOutEndpoint = "bx_block_dashboard/cashier_dashboards/cash_in_out"
exports.getOverAllSaleEndpoint = "bx_block_dashboard/cashier_dashboards/overall_sales"
exports.getAllStoreRevenue = "bx_block_dashboard/cashier_dashboards/store_revenue"
exports.getPaymentModeRevenue = "bx_block_dashboard/cashier_dashboards/revenue_by_payment_method"
exports.getUserDetailsEndpoint = "account_block/employees"
exports.getCleaningDueEndpoint = "bx_block_dashboard/plant_reports/cleaning_due_details"
exports.getCardOrderCountEndpoint = "bx_block_dashboard/plant_reports/plant_orders_count_by_status"
exports.getTopItemToCleanEndpoint = "bx_block_dashboard/plant_reports/top_items_to_clean"
exports.getCleanDueModalDetEndpoint = "bx_block_dashboard/plant_reports/section_wise_clean_due_orders"
exports.topItemToCleanText = "Top Items to Clean"
exports.Tabs = {
  Strings: {
    dashboard: "Dashboard",
    overview: "Overview",
    sectionSales: "Section Sales",
    plantReport: "Plant Report",
    popularTime: "Popular Time",
    sales: "Sales",
    revenue: "Revenue",
    customer: "Customer"
  },
};

exports.Cards = {
  Strings: {
    upcoming: "Upcoming",
    received: "Received",
    loading_area: "Loading Area",
    dispatched: "Dispatched",
    metal_progetti: "Metal Progetti",
    dueToday: "Due Today",
    dueTomorrow: "Due Tomorrow",
    overdue: "Overdue",
  },
};
exports.TabsTxt = [
  "Dashboard",
  "Plant Report",
  "Popular Time",
  "Customer",
  "Discounts & Credits"
] 
exports.customerCountEndpoint = "bx_block_dashboard/dashboards/customer_count";
exports.plantRepCardDetModal = "bx_block_dashboard/plant_reports/section_wise_plant_orders";
exports.plantReportChartEndpoint = "bx_block_dashboard/plant_reports/service_wise_categories_orders";

exports.mainDashboardEndPoint = "bx_block_dashboard/dashboards";
exports.popularTimesEndPoint = "bx_block_dashboard/dashboards/popular_time";
exports.sectionOverviewEndPoint = "bx_block_dashboard/overview_dashboards/section_overview_details";
exports.totalSalesOverviewEndPoint = "bx_block_dashboard/overview_dashboards/total_overview";
exports.revenueOverviewEndPoint = "bx_block_dashboard/overview_dashboards/revenue_overview_details";

exports.overallTitle = "Overall"
exports.allStoreTitle = "All Stores"
exports.ordersTitle = "Orders"
exports.amountTitle = "Amount"
exports.amountOfDiscountTitle = "Amount of discount "
exports.planReportChart = "planReportChart Tooltip"

exports.printSummary = "Print Summary"
exports.exportAll = "Export All"
exports.mobile = "Mobile"


//Dashboard Revenue
exports.orderRevenueDashboardEndPoint = "bx_block_dashboard/dashboards/order_revenue_dashboard?request_type="
exports.orderRevenueListEndPoint = "bx_block_dashboard/dashboards/order_revenue"
exports.today = "today"
exports.emptyDate = ""
exports.getGeneralDiscountEndpoint = "bx_block_dashboard/discounts_dashboards/general_discount";
exports.getStorewiseDiscountEndpoint = "bx_block_dashboard/discounts_dashboards/storewise_discount";

//Main Dashboard
exports.regionSalesGraphDesc = "X axis - Regions, Y axis - Amount (SAR)"
exports.mainDashboardApiEndPoint = "bx_block_dashboard/main_dashboards/"
exports.newStores = "New Stores"
exports.noData = "No Data"
exports.mobileSales = "Mobile App Sales"
exports.sectionSales = "Section Sales"

//Overview Dashboard
exports.totalOverviewDesc = "X axis - Total Overview, Y axis - Amount"
exports.revenueOverviewDesc = "X axis - Payment Mode, Y axis - Amount (in SAR)"
exports.salesSummaryApi = "bx_block_store_management/store_managements/generate_sales_summary_pdf"

exports.metalProgettiApi = "bx_block_cfmetalprogettisolutionintegration2/metalprogetti_solution_integrations/fetch_metal_progetti_data"
exports.apiTest = "fetchapidata"
// Customizable Area End