import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import React from 'react'
import { AdminEditData, ISnackbar, ProfileData } from "./utils";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  localImgFile: string | null | ArrayBuffer;
  localCoverFile: string | null | ArrayBuffer;
  imgFile: File | null | undefined
  coverImgFile: File | null | undefined
  isEditMode: boolean;
  isAdminEditMode: boolean;
  adminEditChecks: AdminEditData;
  profileData: ProfileData | null;
  firstName: string;
  lastName: string;
  password: string;
  confirmPassword: string;
  errorFirstName: string;
  errorLastName: string;
  errorPassword: string;
  errorConfirmPassword: string;
  isSubmitting: boolean;
  snackbar: ISnackbar;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CustomisableUserProfilesController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  imgInputRef: React.RefObject<HTMLInputElement>
  coverImgInputRef: React.RefObject<HTMLInputElement>
  getEmployeeProfileApiId: string
  updateProfileApiId: string
  updateProfileAdminApiId: string
  // Customizable Area End


  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.getEmployeeProfileApiId = "";
    this.updateProfileApiId = "";
    this.updateProfileAdminApiId = "";
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.SessionResponseToken),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.SessionRequestMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIResponceErrorMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage)
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      localImgFile: null,
      localCoverFile: null,
      imgFile: null,
      coverImgFile: null,
      isEditMode: false,
      isAdminEditMode: false,
      adminEditChecks: {
        mobile_number_change: false,
        account_for_change: false,
        department_change: false,
        location_change: false,
        direct_manager_change: false,
        permissions_change: false,
        email_address_change: false,
        employee_id_or_iqama_id_change: false,
        position_change: false,
        business_area_change: false,
        role_change: false
      },
      profileData: null,
      firstName: '',
      lastName: '',
      password: '',
      confirmPassword: '',
      errorFirstName: '',
      errorLastName: '',
      errorPassword: '',
      errorConfirmPassword: '',
      isSubmitting: false,
      snackbar: {
        open: false,
        message: "",
        severity: 'info'
      }
      // Customizable Area Start
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.imgInputRef = React.createRef();
    this.coverImgInputRef = React.createRef();
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      this.handleResponse(apiRequestCallId, message)
    }
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start

  async componentDidMount() {
    const token = localStorage.getItem('token')
    if (token) {
      this.getEmployeeProfile();
    }
  }

  handleResponse = (apiRequestCallId: string, message: Message) => {
    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    let errorReponse = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );

    if (apiRequestCallId && responseJson) {
      if (responseJson?.errors) {
        this.setState({ isSubmitting: false })
        this.setState({
          snackbar: {
            open: true,
            severity: "error",
            message: responseJson?.errors[0]?.message 
          }
        })
        return
      }
      switch (apiRequestCallId) {
        case this.getEmployeeProfileApiId:
          this.setState({
            profileData: responseJson.data.attributes,
            firstName: responseJson.data.attributes.first_name,
            lastName: responseJson.data.attributes.last_name,
            localImgFile: responseJson.data.attributes.employee_proifle.data.attributes?.profile_logo,
            localCoverFile: responseJson.data.attributes.employee_proifle.data.attributes?.background_cover_photo
          })
          break;
        case this.updateProfileApiId:
          this.setState({
            isEditMode: false,
            profileData: responseJson.data.attributes,
            firstName: responseJson.data.attributes.first_name,
            lastName: responseJson.data.attributes.last_name,
            password: "",
            confirmPassword: "",
            localImgFile: responseJson.data.attributes.employee_proifle.data.attributes?.profile_logo,
            localCoverFile: responseJson.data.attributes.employee_proifle.data.attributes?.background_cover_photo,
            imgFile: null,
            coverImgFile: null,
            isSubmitting: false,
            snackbar: {
              open: true,
              severity: "success",
              message: "Profile updated successfully." 
            }
          })
          break;
        case this.updateProfileAdminApiId:
          this.setState({ isSubmitting: false })
          alert("Profile request sent to admin.")
          this.adminPopUpCancelHandler()
          break;
        default:
          break;
      }
    } else if (errorReponse) {
      this.setState({ isSubmitting: false })
      this.setState({
        snackbar: {
          open: true,
          severity: "error",
          message: errorReponse?.errors[0]?.message 
        }
      })
    }
  }

  firstNameChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ firstName: event.target.value })
    let message = ""
    if (event.target.value === "") {
      message = "Please enter first name."
    }
    this.setState({ errorFirstName: message })

  }
  lastNameChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ lastName: event.target.value })
    let message = ""
    if (event.target.value === "") {
      message = "Please enter last name."
    }
    this.setState({ errorLastName: message })
  }

  passwordValidate = (passwordValue: string) => {
    let message = ""
    if (passwordValue.length < 8) {
      message = "Password must be at least 8 characters."
    }
    this.setState({ errorPassword: message })
    if (message === "") { return true } else return false
  }

  confirmPasswordValidate = (passwordValue: string) => {
    let message = ""
    if (passwordValue.length < 8) {
      message = "Password must be at least 8 characters."
    } else if (passwordValue !== this.state.password) {
      message = "Your password and confirmation password do not match."
    }
    this.setState({ errorConfirmPassword: message })
    if (message === "") { return true } else return false
  }

  passwordChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const passwordValue = event.target.value
    this.setState({ password: passwordValue }, () => {
      if (this.passwordValidate(passwordValue)) {
        this.confirmPasswordValidate(this.state.confirmPassword)
      }
    })
  }
  confirmPasswordChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const passwordValue = event.target.value
    this.setState({ confirmPassword: passwordValue }, () => {
      if (this.confirmPasswordValidate(passwordValue)) {
        this.passwordValidate(this.state.password)
      }
    })
  }

  imgInputRefClickHandler = () => {
    this.imgInputRef.current?.click()
  }

  coverImgInputRefClickHandler = () => {
    this.coverImgInputRef.current?.click()
  }

  imageChangeHandler = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const input = event?.target as HTMLInputElement;

    if (!input?.files?.[0]) {
      alert("No image upload.")
      return;
    }
    const image = input?.files?.[0];
    const maxFileSize = 10 * 1024 * 1024
    if ((/.(jpe?g|png)$/i).test(image?.name)) {
      if (image.size > maxFileSize) {
        alert("Image size can not be more than 10MB")
        return
      }
      const reader = new FileReader()
      reader.onloadend = () => {
        this.setState({ localImgFile: reader.result, imgFile: input?.files?.[0] })
      }
      reader.readAsDataURL(input.files[0])
    } else {
      alert("Invalid file type provided.")
    }
  }

  coverImageChangeHandler = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const input = event?.target as HTMLInputElement;

    if (!input?.files?.[0]) {
      alert("No image upload.")
      return;
    }
    const maxFileSize = 10 * 1024 * 1024
    const image = input?.files?.[0];
    if ((/.(jpe?g|png)$/i).test(image?.name)) {
      if (image.size > maxFileSize) {
        alert("Image size can not be more than 10MB")
        return
      }
      const reader = new FileReader()
      reader.onloadend = () => {
        this.setState({ localCoverFile: reader.result, coverImgFile: input?.files?.[0] })
      }
      reader.readAsDataURL(input.files[0])
    } else {
      alert("Invalid file type provided.")
    }
  }

  editModeChangeHandler = (isModeOn: boolean) => {
    this.setState({ isEditMode: isModeOn })
  }

  adminEditModeChangeHandler = (isModeOn: boolean) => {
    this.setState({ isAdminEditMode: isModeOn })
  }



  adminCheckboxChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const editData: AdminEditData = this.state.adminEditChecks
    editData[event.target.name] = event.target.checked
    this.setState({ adminEditChecks: editData })
  }

  adminPopUpCancelHandler = () => {
    this.adminEditModeChangeHandler(false)
    this.setState({
      adminEditChecks: {
        mobile_number_change: false,
        account_for_change: false,
        department_change: false,
        location_change: false,
        direct_manager_change: false,
        permissions_change: false,
        email_address_change: false,
        employee_id_or_iqama_id_change: false,
        position_change: false,
        business_area_change: false,
        role_change: false
      }
    })
  }

  adminPopUpAddHandler = () => {
    if (Object.values(this.state.adminEditChecks).includes(true)) {
      this.setState({ isSubmitting: true })
      this.updateProfileDetailAdmin()
    } else {
      alert(configJSON.textSelectField)
    }
  }

  profileCancelHandler = () => {
    this.setState({
      firstName: this.state.profileData?.first_name || "",
      lastName: this.state.profileData?.last_name || "",
      password: "",
      confirmPassword: "",
      localImgFile: this.state.profileData?.employee_proifle.data.attributes?.profile_logo || null,
      localCoverFile: this.state.profileData?.employee_proifle.data.attributes?.background_cover_photo || null,
      imgFile: null,
      coverImgFile: null
    })
    this.editModeChangeHandler(false)
  }

  profileSaveHandler = () => {
    this.setState({ isSubmitting: true })
    let isError = false
    if (this.state.firstName === "") {
      isError = true
      this.setState({ errorFirstName: "Please enter first name." })
    }
    if (this.state.lastName === "") {
      isError = true
      this.setState({ errorLastName: "Please enter last name." })
    }
    if (this.state.password.length > 0 && this.state.password.length < 8) {
      isError = true
      this.setState({ errorPassword: "Password must be at least 8 characters." })
    } else if (this.state.confirmPassword !== this.state.password) {
      isError = true
      this.setState({ errorPassword: "Your password and confirmation password do not match." })
    } else {
      this.setState({ errorPassword: "" })
    }
    if (this.state.confirmPassword.length > 0 && this.state.confirmPassword.length < 8) {
      isError = true
      this.setState({ errorConfirmPassword: "Password must be at least 8 characters." })
    } else if (this.state.confirmPassword !== this.state.password) {
      isError = true
      this.setState({ errorConfirmPassword: "Your password and confirmation password do not match." })
    } else {
      this.setState({ errorConfirmPassword: "" })
    }

    if (!isError) {
      this.updateProfileDetail()
    } else {
      this.setState({ isSubmitting: false })
    }
  }

  handleSnackbarClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }

    this.setState({ snackbar: {open: false, message: "", severity: 'info'} })
  };

  // APIs

  getEmployeeProfile = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": localStorage.getItem('token')
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getEmployeeProfileApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getProfileApiEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getUpdateProfileFormData = () => {
    let formdata = new FormData();
    formdata.append("first_name", this.state.firstName);
    formdata.append("last_name", this.state.lastName);
    if (this.state.password || this.state.confirmPassword) {
      formdata.append("password", this.state.password)
      formdata.append("password_confirmation", this.state.confirmPassword)
    }
    if (this.state.imgFile) { formdata.append("profile_logo", this.state?.imgFile) }
    if (this.state.coverImgFile) { formdata.append("background_cover_photo", this.state.coverImgFile) }

    return formdata
  }

  updateProfileDetail = () => {
    const header = {
      "token": localStorage.getItem('token')
    };


    let formdata = this.getUpdateProfileFormData()

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.updateProfileApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.updateProfileApiEndpoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  updateProfileDetailAdmin = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": localStorage.getItem('token')
    };

    const data = {
      request: { ...this.state.adminEditChecks }
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.updateProfileAdminApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.updateProfileAdminApiEndpoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getUserShortName=()=>{
    if(!this.state.firstName)return "NA";
   return (this.state.firstName[0] + this.state.lastName[0])
            ?.toUpperCase()
            ?.substring(0, 2);
  }

  getPageHeaderName=()=>{
    return this.state.isEditMode ?configJSON.labelEditProfileHeading: configJSON.labelProfileHeading;
  }

  // Customizable Area End
}
