import { CSSProperties } from "@material-ui/styles";


const styles: Record<string, CSSProperties> = {

    inputLabel: {
        color: "#4D4D4D",
        fontWeight: 600,
        marginBottom: '10px',
        fontSize: '18px'

    },

    innerContainer: {
        display: "flex",
        gap: "10px",
        alignItems: "center"
    },
    cancelButton: {
        backgroundColor: "#204B9C",
        color: "#FFFFFF",
        borderRadius: "8px",
        height: "56px",
        width: "128px",
        fontSize: "16px",
        '&:hover':{
            backgroundColor: "#204B9C",
            color: "#FFFFFF",
        },
        textTransform: 'none',
        fontWeight: 700
    },
    activeOrder: {
        height: "16px",
        width: "16px",
        backgroundColor: "#059669",
        borderRadius: "50%",
      
    },
    cardContent: {
        display: "flex",
        flexDirection: "column",
        gap: "32px",
        padding: "32px 65px",

    },

    serviceContainer: {
        display: "flex",
        gap: "10px"
    },
    serviceInnerContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "rgba(32, 75, 156, 0.1)",
        color: '#204B9C',
        borderRadius: "8px",
        padding: "4px 8px"

    },
    galleryContainer: {
        display: "flex",
        borderColor: "1px solid red",
        justifyContent: "space-between",
        alignItems: "center",
        margin: "10px 0 0 10px",
        padding: "46px 0",
    },
    heading: {
        fontSize: "24px",
        fontWeight: 600,
        lineHeight: "29.26px",
    },
    editButton: {
        backgroundColor: "#204B9C",
        color: "#FFFFFF",
        borderRadius: "8px",
        height: "56px",
        width: "184px",
        fontSize: "16px",
        '&:hover':{
            backgroundColor: "#204B9C",
            color: "#FFFFFF",
        },
        textTransform:'none',
        fontWeight: 700
    },
    cardContainer: {
        minHeight: "266px",
        borderRadius: "12px",
        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.03), 0px 8px 32px rgba(0, 0, 0, 0.06)"
    },
    cardParentContainer: {
        display: "flex",
        flexDirection: "column",
        gap: "2rem",
    },

    parentContainerMargin: {
        margin: "2rem 0",
    },

    imageBox:{
        display: "flex",
        gap: "20px",
        alignItems: "center",
        color: '#1A1A1A !important',
        fontSize:'16px !important',
        fontWeight:500 
    },
    parentContainer:{
        width: '100%',
        padding:'10px 49px',
      
    },
    value:{
        fontWeight:500, 
        color: '#1A1A1A'
    }

}

export default styles