import React from "react";

// Customizable Area Start
import {
  BackgroundImage,
  BackgroundPaper,
  AvatarImg,
  DynamicHeading,
  DynamicSubHeading,
}
  from "../../../components/src/customComponents/DynamicContent.web";
import CustomInput from "../../../components/src/customComponents/CostumInput.web"
import CostomButton from "../../../components/src/customComponents/CostomButton.web"
import { Box } from "@material-ui/core";
import { backgroundImageLogin, PasswordVisible, PasswordInVisible } from "../../email-account-login/src/assets";
export const configJSON = require("./config");
import CustomComponentLanguage from "../../../components/src/customComponents/CustomComponentLanguage.web"
import { logo } from "./assets";



import ForgotPasswordController, {
  Props
} from "./ForgotPasswordController.web";

export default class UpdateNewPassword extends ForgotPasswordController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      // Required for all blocks
      <BackgroundImage imageUrl={backgroundImageLogin}>
        <BackgroundPaper>
          <CustomComponentLanguage navigation={undefined} id={""} />
          <Box display="flex" justifyContent="center" paddingTop="0.2vw" paddingBottom="1vw">
            <img 
              src={logo} 
              width="33%"
            />
          </Box>
          <DynamicHeading headingName={configJSON.labelresetPassword} />
          <DynamicSubHeading headingName={this.state.dynamictext} />
          <p style={{ ...webStyle.labelheadingTop, ...webStyle.labelheading }}>
            {configJSON.labelchangePassword}
          </p>
          <Box style={webStyle.passwordIcon}>
            <CustomInput
              type={this.state.enablePasswordField ? "password" : "text"}
              name="pwd"
              placeholder="*********"
              value={this.state.pwd}
              onChange={this.handleInputChange}
              onFocus={this.hideErrorone}

            />
            <Box style={{ position: "absolute", ...webStyle.togglePasswordImage }}>
              {
                <img
                  data-test-id="passVisible"
                  style={webStyle.logoSwitch}
                  onClick={this.handleClickShowForgetPassword}
                  src={
                    this.state.enablePasswordField ?
                    PasswordInVisible 
                      : PasswordVisible
                  }
                />
              }
            </Box>
          </Box>
          <Box style={webStyle.errorMessageTop}>
            {
              (this.state.errors.pwd)
                ? <small style={webStyle.errorStyle}>{this.state.errors.pwd}</small>
                : <small style={{ visibility: 'hidden' }}>""</small>
            }
          </Box>
          <p style={webStyle.labelheading }>
            {configJSON.labelconfirmPassword}
          </p>
          <Box style={webStyle.passwordIcon}>
            <CustomInput
              type={this.state.enableConfirmPasswordField ? "password" : "text"}
              name="confirmPassword"
              placeholder="*********"
              value={this.state.confirmPassword}
              onChange={this.handleInputChange}
              onFocus={this.hideErrorone}
            />

            <Box style={{ position: "absolute", ...webStyle.togglePasswordImage }}>
              {
                <img
                  data-test-id="confirmPassVisible"
                  style={webStyle.logoSwitch}
                  onClick={this.handleClickConfirmShowPassword}
                  src={
                    this.state.enableConfirmPasswordField ?
                    PasswordInVisible  
                      : PasswordVisible
                  }
                />
              }
            </Box>
          </Box>
          <Box style={webStyle.errorMessageTop}>
            {
              (this.state.errors.confirmPassword)
                ? <small style={webStyle.errorStyle}>{this.state.errors.confirmPassword}</small>
                : <small style={{ visibility: 'hidden' }}>""</small>
            }
          </Box>

          <Box style={webStyle.buttonAdd}>
            <CostomButton
              style={{
                textTransform: "none",
                ...webStyle.buttonStyle
              }}
              data-test-id={configJSON.updatePasswordTextId}
              onClick={this.handlForgetPasswordUpdate}  >
              {configJSON.labelresetLogin}
            </CostomButton>
          </Box>
        </BackgroundPaper>
      </BackgroundImage>
    );
  }
}
const webStyle = {
  labelheading: {
    fontFamily: "Montserrat",
    fontWeight: 600,
    fontSize: "0.972vw",
    marginBottom: "0.256vw",
  },
  buttonStyle: {
    width: "100%",
    height: "3.472vw",
    borderRadius: "0.556vw",
    alignText: "center",
    color: "#FFFFFF",
    fontFamily: "Montserrat",
    fontWeight: 700,
    fontSize: "1.667vw",
    backgroundColor: "#204B9C"
  },
  buttonAdd: {
    display: "flex",
    paddingTop: "1.533vw",
    paddingBottom: "1.533vw",
  },
  passwordIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end"
  },
  errorStyle: {
    padding: "5px",
    color: "red",
    fontFamily: "Montserrat",
    fontSize: " 0.833vw",
  },
  logoSwitch: {
    height: "1.667vw",
    width: "1.667vw"
  },
  togglePasswordImage: {
    paddingRight: "1vw"
  },
  labelheadingTop: {
    paddingTop: "1.556vw",
  },
  errorMessageTop: {
    marginTop: '0.2vw'
  },
};
// Customizable Area End