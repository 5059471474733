import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import { IBlock } from "../../../framework/src/IBlock";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { IUserContext } from "../../../blocks/navigationmenu/src/PageContainerController.web";
import { ISortingData } from "../../../components/src/OrderTable/src/TableCell";
import { apiCall, makeApiMessage } from "../../../components/src/common";
import { getStorageData } from "../../../framework/src/Utilities";
import { IFilter } from "../../../components/src/FilterPopover";
import {
  navigateTo,
  INavigateTo,
  PermissionStatus,
  checkForPermissonStatus,
  customPermissionApiKey
} from "../../utilities/src/CustomBlockHelpers";
import { PermissionGroupArray } from "../../../blocks/navigationmenu/src/utils";

interface ISettingItem {
  id: string;
  type: string;
  attributes: {
    id: number;
    active: string;
    region_id: number;
    region_name: string;
    created_at: string;
    updated_at: string;
    open_slots_per_day: number;
    minimum_order_amount: number;
    maximum_order_amount: number;
    home_cleaning_service_charges: null;
    min_non_refundable_amount_to_pay: number;
  };
}

interface FilterSuggResponse {
  id: number;
  region: {
    id: number;
    branch_name: string;
  };
}
// Customizable Area End

interface S {
  // Customizable Area Start
  page: number;
  loading: boolean;
  searchText: string;
  isDrawerOpen: boolean;
  settingsMetaData: {
    [field: string]: string;
  };
  filters: IFilter[];
  filterQuery: string;
  sortingQuery: string;
  sortingData: ISortingData;
  selectedRegionId: number | null;
  filterAnchor: HTMLDivElement | null;
  settingsList: Array<ISettingItem>;
  permissionStatus: PermissionStatus;
  // Customizable Area End
}
interface SS {}

export interface Props {
  navigation: {};
  id: string;
}

export const configJSON = require("./config");

export default class HomeOrderController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  homeCleaningListMessageId: string = "";
  homeCleaningListActivateMessageId: string = "";
  regionFilterSuggestionApiId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area Start
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceDataMessage),
      // Customizable Area End
    ];

    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    const requestId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    // Customizable Area Start
    this.handleHomeCleaningSettingsStatusResponse(requestId);
    this.handleHomeCleaningSettingsResponse(requestId, responseJson);
    this.handleRegionFilterSuggResponse(requestId, responseJson);
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    const drawer = await getStorageData("isPinned");
    this.setState({ isDrawerOpen: drawer === "true" });
    // Customizable Area End
  }

  // Customizable Area Start

  handleHomeCleaningSettingsStatusResponse(requestId: string) {
    if (requestId !== this.homeCleaningListActivateMessageId) return;
    this.getSettingsList();
  }

  handleHomeCleaningSettingsResponse(
    requestId: string,
    response: { data: ISettingItem[]; meta: {} }
  ) {
    if (requestId === this.homeCleaningListMessageId && response?.data) {
      this.setState({
        loading: false,
        settingsList: response.data,
        settingsMetaData: response.meta,
      });
    }
  }

  handleSearch = (value: string) => {
    this.setState(
      {
        page: 1,
        searchText: value,
      },
      () => this.getSettingsList()
    );
  };

  handlePageChange(page: number) {
    this.setState({ page: page + 1 }, () => this.getSettingsList());
  }

  handleDrawerChange(open: boolean) {
    setTimeout(() => {
      this.setState({ isDrawerOpen: open });
    }, 50);
  }

  getSettingsList() {
    const { page, searchText, sortingQuery, filterQuery } = this.state;

    const query = Boolean(searchText) ? `&filter_by[query]=${searchText}` : "";
    const sorting = Boolean(sortingQuery) ? "&" + sortingQuery : "";
    const filter = Boolean(filterQuery) ? "&" + filterQuery : "";
    const pageQuery = `?page_no=${page}`;

    const fullQuery = `${pageQuery}${query}${filter}${sorting}`;

    const message = makeApiMessage({
      method: "GET",
      url: configJSON.homeCleaningSettingsListApi + fullQuery,
    });

    this.homeCleaningListMessageId = message.messageId;
    runEngine.sendMessage(message.id, message);
  }

  handleTableActions(action: string, id?: number) {
    switch (action) {
      case "Deactivate":
        this.handleStatusUpdate(false, id as number);
        break;

      case "Activate":
        this.handleStatusUpdate(true, id as number);
        break;

      case "Edit":
        navigateTo({
          id: String(id),
          props: this.props,
          screenName: "HomeCleaningSettingsEdit",
        } as INavigateTo);
        break;

      case "View":
        navigateTo({
          id: String(id),
          props: this.props,
          screenName: "HomeCleaningSettingsView",
        } as INavigateTo);
        break;

      case "Add":
        navigateTo({
          props: this.props,
          screenName: "HomeCleaningSettings",
        } as INavigateTo);
        break;
    }
  }

  handleStatusUpdate(isActive: boolean, id: unknown) {
    const appendString = `/${id}/${isActive ? "activate" : "deactivate"}`;
    const message = makeApiMessage({
      url: configJSON.homeCleaningSettingsListApi + appendString,
      method: "PUT",
    });
    this.homeCleaningListActivateMessageId = message.messageId;
    runEngine.sendMessage(message.id, message);
  }

  handelSortingData(data: ISortingData) {
    this.setState({ sortingData: data });
  }

  handleSortingQuery(query: string) {
    this.setState({ sortingQuery: query }, () => this.getSettingsList());
  }

  handleCloseFilter() {
    this.setState({ filterAnchor: null });
  }

  handleFilterChange(filters: IFilter[]) {
    const query = filters
      .map(({ apiKey, value, type }) => {
        if (!value) return "";

        if (type !== "custom") return `filter_by[${apiKey}]=${value}`;

        const condition = value[0] || value[1];
        return Boolean(condition)
          ? `filter_by[minimum_order_amount]=${value[0]}&filter_by[maximum_order_amount]=${value[1]}`
          : "";
      })
      .filter((item) => item)
      .join("&");

    this.setState({ filters, filterQuery: query }, () =>
      this.getSettingsList()
    );
  }

  handleClearFilter(clearAll = false) {
    const filters = this.state.filters.map((item) => {
      if (clearAll) item.value = "";
      if (item.type == "custom") item.value = ["", ""];
      return item;
    });
    this.setState({ filters });
  }

  handleClickFilter(event: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    this.setState({ filterAnchor: event?.currentTarget });
  }

  handleRegionNameFilterSuggestion = (title: string, value: string) => {
    this.state.filters.forEach((item: IFilter) => {
      if (item.title === title) item.value = value;
      return item;
    });

    let headers = {
      token: localStorage.getItem(configJSON.token),
      "Content-Type": configJSON.exampleApiContentType,
    };
    const suggetionProduct = apiCall({
      httpBody: {},
      header: headers,
      url: configJSON.regionNameFilterSuggAPiEndpoint + value,
      httpMethod: configJSON.validationApiMethodType,
    });

    this.regionFilterSuggestionApiId = suggetionProduct.messageId;
    runEngine.sendMessage(suggetionProduct.id, suggetionProduct);
  };

  handleRegionFilterSuggResponse(
    requestId: string,
    response: { data: FilterSuggResponse[]; status: number }
  ) {
    if (
      requestId === this.regionFilterSuggestionApiId &&
      response.status == 200
    ) {
      const list =
        response.data.map((item) => {
          return {
            label: item.region.branch_name,
            value: String(item.id),
          };
        }) || [];
      const updatedFilters = this.state.filters.map((item: IFilter) => {
        if (item.title === "Region") item.options = list;
        return item;
      });
      this.setState({ filters: updatedFilters });
    } else if (response.status !== 200) {
      const updatedFilters = this.state.filters.map((item: IFilter) => {
        if (item.title === "Region") item.options = [];
        return item;
      });
      this.setState({ filters: updatedFilters });
    }
  }

  handleUserChange(context: IUserContext) {
    const apiKey = customPermissionApiKey.homeCleanSettingPermissions;
    const userData = context.user?.attributes.permission_groups;
    const value = checkForPermissonStatus(apiKey, userData as Array<PermissionGroupArray>);
    this.setState({
      permissionStatus: value
    })

    const regionId =
      context?.user?.attributes?.employee_proifle?.data?.attributes
        ?.region_manager_id;

    const isRegionManager =
      context?.user?.attributes?.role?.name === "Region Manager";

    isRegionManager && this.setState({ selectedRegionId: Number(regionId) });

    this.getSettingsList();
  }

  // Customizable Area End
}
