// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area End

// Customizable Area Start
export const configJSON = require("./config");
import { IUserContext } from "../../../blocks/navigationmenu/src/PageContainerController.web";
import {
  PermissionStatus,
  checkForNewPermissonStatus,
  customPermissionApiKey
} from "../../../blocks/utilities/src/CustomBlockHelpers";
import { PermissionGroupArray } from "../../../blocks/navigationmenu/src/utils";
// Customizable Area End

export interface Props {
  navigation?: any;
  id?: string;
  // Customizable Area Start
  
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  permissionStatus: PermissionStatus;
    viewData:ViewData,
    iconDefault:string
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;

  // Customizable Area End
}

// Customizable Area Start
interface ViewData {
  attributes:{
    id:number,
  preference_first_name:string,
  preference_second_name:string,
  gallery_icon:{
    image:string,
    name_translation: string
  } | null
  }

}
// Customizable Area End

export default class ViewPreferenceController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
    ];

    this.state = {
      viewData: this.props.navigation?.history.location?.state?.state,
      permissionStatus: {
        mainPermission: false,
        createPermission: false,
        viewPermission: false,
        editPermission: false,
        deactivatePermission: false
      },
      iconDefault:configJSON.defaultIcon
    };
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  // Customizable Area Start

  handleUserChange = (userContext: IUserContext) => {
    const apiKey = customPermissionApiKey.preferenceListCreation;
    const userData = userContext.user?.attributes.permission_groups;
    const value = checkForNewPermissonStatus(apiKey, userData as Array<PermissionGroupArray>);

    this.setState({
      permissionStatus: value
    })
  };


  handleViewCancel = ()=> {
    this.props.navigation.history.push("/Products-PreferenceList")
}
handleEditPreference = (item: ViewData) => {
  this.props.navigation.history.push(`/Products-PreferenceListEdit/${item.attributes.preference_first_name}`, { state: item })
}
  // Customizable Area End
}
