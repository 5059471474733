import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { ISortingData } from "../../../components/src/SortingTableHeader";
import { IFilter } from "../../../components/src/FilterPopover";
import moment from "moment";
import { handleMomentDateFormat } from "../../../blocks/cfcashier/src/utils";
import { getStorageData } from "framework/src/Utilities";
import { makeApiMessage } from "../../../components/src/common";
import { IUserContext } from "../../navigationmenu/src/PageContainerController.web";
import { toString, toNumber } from "lodash";
import { transformSectionWiseData } from "./utils";
import { handleDisplayRegion } from "../../dashboard/src/utils";
import html2pdf from "html2pdf.js";
import { customPermissionApiKey, DashboardPermissionStatus, checkForDashboardPermissonStatus } from "../../utilities/src/CustomBlockHelpers";
import { PermissionGroupArray } from "../../../blocks/navigationmenu/src/utils";

export interface IMeta {
  next_page: number;
  pervious_page: number;
  total_pages: number;
  total_count: number;
  current_page: number;
}
export interface SectionWiseData {
  data: {
    sections_data: Array<{
      section: string,
      orders: number,
      pieces: number
    }>,
    total_orders: number,
    total_pieces: number
  }
}
type DataBarChartType = Array<{
  name: string,
  value_1: number,
  value_2: number
}>
type BarchartType  = Array<{
  data: Array<{name: string, value_1: number, value_2: number}>,
  dataHeader: Array<{name: string, value_1: number, value_2: number}>,
  chartTitle: string
}>
type SaleBySection = {
  data: Array<{
    section_name: string,
    total_price: number | string
  }>
}
type SaleByService = {
  data: Array<{
    service_name: string,
    total_price: number
  }>
}
type SummarySale = {
  data: Array<{
    section: string;
    discount: string;
    sub_total: string;
    vat: number;
    net: string
  }>
}
type VatAndSubtotal = {
  data: Array<{
    section: string;
    sub_total: number;
    vat: number
  }>
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  query: string;
  isLoading: boolean;
  sortingFields: ISortingData;
  pageNum: number;
  pageSize: number;
  textSearchHeader: string;
  metaData: IMeta;
  filterAnchor?: HTMLDivElement;
  filters: IFilter[];
  token: string;
  regionId: string
  storeId: string
  storeName: string
  groupId: string
  groupName: string
  regionName: string;
  sectionWiseLaundryData: DataBarChartType;
  sectionWiseLaundryDataHeader: DataBarChartType;
  sectionWiseDryCleaningData: DataBarChartType;
  sectionWiseDryCleaningDataHeader: DataBarChartType;
  sectionWisePressingData: DataBarChartType;
  sectionWisePressingDataHeader: DataBarChartType;
  saleBySection: SaleBySection;
  saleByService: SaleByService;
  saleSummary: SummarySale;
  vatSubtotalChart: DataBarChartType;
  isLoadingLaundry: boolean;
  isLoadingDryCleaning: boolean;
  isLoadingPressing: boolean;
  openExportModal: boolean;
  permissionStatus: DashboardPermissionStatus | null
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class Cfsalesdashboard3Controller extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  serviceWiseSectionLaundryId : string = ""
  serviceWiseSectionDryCleaningId : string = ""
  serviceWiseSectionPressingId : string = ""
  saleByServiceId : string = ""
  saleBySectionId : string = ""
  saleSummaryId : string = ""
  vatSubtotalId : string = ""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.BroadcastNavbarDataMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      sortingFields: {
        name: "",
        orders:"",
        pieces:"",
        amount:"",
        discount:"",
        subtotal:"",
        vat:"",
        net:""
      },
      query: "",
      isLoading: false,
      pageNum: 1,
      pageSize: 10,
      textSearchHeader: "",
      metaData: {
        next_page: 1,
        pervious_page: 1,
        total_pages: 1,
        total_count: 1,
        current_page: 1,
      },
      filterAnchor: undefined,
      filters: [
        {
          title: "Date",
          value: "today",
          type: "dateselect",
          datevalue: { from: "", to: "" },
          options: [
            {
              label: "Today",
              value: "today",
            },
            {
              label: "Last 7 days",
              value: "last7",
            },
            {
              label: "Last 30 days",
              value: "last30",
            },
            {
              label: "Specific Dates",
              value: "specific",
            },
          ],
        },
      ],
      token: "",
      regionId: "",
      regionName: "-",
      storeId: "",
      storeName: "",
      groupId: "",
      groupName: "",
      sectionWiseLaundryData:[],
      sectionWiseLaundryDataHeader:[],
      sectionWiseDryCleaningData:[],
      sectionWiseDryCleaningDataHeader:[],
      sectionWisePressingData:[],
      sectionWisePressingDataHeader:[],
      saleBySection: {data: []},
      saleByService: {data: []},
      saleSummary: {data: []},
      vatSubtotalChart: [],
      isLoadingDryCleaning: true,
      isLoadingLaundry: true,
      isLoadingPressing: true,
      openExportModal: false,
      permissionStatus: null
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    let token = message.getData(getName(MessageEnum.SessionResponseToken));
    this.setState({token:token})
    const requestId: any = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const successResponse: any = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if(requestId === this.serviceWiseSectionLaundryId) {
      this.handleServiceWiseSectionLaundry(successResponse);
    }
    if(requestId === this.serviceWiseSectionDryCleaningId) {
      this.handleServiceWiseSectionDryCleaning(successResponse);
    }
    if(requestId === this.serviceWiseSectionPressingId) {
      this.handleServiceWiseSectionPressing(successResponse);
    }
    if(requestId === this.saleByServiceId) {
      this.handleSaleByServiceResponse(successResponse);
    }
    if(requestId === this.saleBySectionId) {
      this.handleSaleBySectionResponse(successResponse);
    }
    if(requestId === this.saleSummaryId) {
      this.handleSaleSumaryResponse(successResponse);
    }
    if(requestId === this.vatSubtotalId) {
      this.handleVatSubtotalResponse(successResponse);
    }
    this.receiveDataFromTopBar(message)
    // Customizable Area End
  }

 
  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    if(this.state.regionId || this.state.storeId) {
      this.getAllApiCalling()
    }
  }

  getToken=()=>{
    const msg: Message = new Message(getName(MessageEnum.SessionRequestMessage));
    this.send(msg);
  }

  getServiceWiseSectionLaundry() {
    this.setState({isLoadingLaundry: true})

    const apiEndPoint =  configJSON.serviceWiseSectionLaundry + "&" + this.handleDateFilterParam().params
    const requestMessage: Message = makeApiMessage({
      url: apiEndPoint,
      method: "GET",
    });
    this.serviceWiseSectionLaundryId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  handleServiceWiseSectionLaundry = (responseData: SectionWiseData) => {
    const {dataChart, dataHeader} = transformSectionWiseData(responseData)
    this.setState({
      sectionWiseLaundryData: dataChart,
      sectionWiseLaundryDataHeader: dataHeader,
      isLoadingLaundry: false
    })
  }

  getServiceWiseSectionDryCleaning() {
    this.setState({isLoadingDryCleaning: true})

    const apiEndPoint =  configJSON.serviceWiseSectionDryClealing + "&" + this.handleDateFilterParam().params
    const requestMessage: Message = makeApiMessage({
      url: apiEndPoint,
      method: "GET",
    });
    this.serviceWiseSectionDryCleaningId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  handleServiceWiseSectionDryCleaning = (responseData: SectionWiseData) => {
    const {dataChart, dataHeader} = transformSectionWiseData(responseData)
    this.setState({
      sectionWiseDryCleaningData: dataChart,
      sectionWiseDryCleaningDataHeader: dataHeader,
      isLoadingDryCleaning: false
    })
  }

  getServiceWiseSectionPressing() {
    this.setState({isLoadingPressing: true})

    const apiEndPoint =  configJSON.serviceWiseSectionPressing + "&" + this.handleDateFilterParam().params
    const requestMessage: Message = makeApiMessage({
      url: apiEndPoint,
      method: "GET",
    });
    this.serviceWiseSectionPressingId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  handleServiceWiseSectionPressing= (responseData: SectionWiseData) => {
    const {dataChart, dataHeader} = transformSectionWiseData(responseData)
    this.setState({
      sectionWisePressingData: dataChart,
      sectionWisePressingDataHeader: dataHeader,
      isLoadingPressing: false
    })
  }
  getSaleByService() {
    const apiEndPoint =  configJSON.saleByServiceEndPoint  + "?" +  this.handleDateFilterParam().params
    const requestMessage: Message = makeApiMessage({
      url: apiEndPoint,
      method: "GET",
    });
    this.saleByServiceId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  handleSaleByServiceResponse= (responseData: SaleByService) => {
    this.setState({saleByService: responseData})
  }

  getSaleBySection() {
    const apiEndPoint =  configJSON.saleBySectionEndPoint  + "?" + this.handleDateFilterParam().params
    const requestMessage: Message = makeApiMessage({
      url: apiEndPoint,
      method: "GET",
    });
    this.saleBySectionId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  handleSaleBySectionResponse= (responseData: SaleBySection) => {
    this.setState({saleBySection: responseData})
  }

  getSaleSummary() {
    const apiEndPoint =  configJSON.saleSumaryEndPoint + "?" + this.handleDateFilterParam().params
    const requestMessage: Message = makeApiMessage({
      url: apiEndPoint,
      method: "GET",
    });
    this.saleSummaryId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  handleSaleSumaryResponse= (responseData: SummarySale) => {
    this.setState({saleSummary: responseData})
  }

  getVatSubtotal() {
    const apiEndPoint =  configJSON.vatSubtotalEndPoint + "?" + this.handleDateFilterParam().params
    const requestMessage: Message = makeApiMessage({
      url: apiEndPoint,
      method: "GET",
    });
    this.vatSubtotalId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  handleVatSubtotalResponse= (responseData: VatAndSubtotal) => {
      const dataTransform = responseData.data.map((response, index) => ({
        name: response.section,
        value_1: toNumber(response.vat),
        value_2: toNumber(response.sub_total)
      }))

      this.setState({vatSubtotalChart: dataTransform})
  }

  handleQueryChange = (query: string) => {
    this.setState({ query }, () => {
      this.getAllApiCalling()
    });
  };

  sortingProps = {
    onQueryChange: (query: string) => this.handleQueryChange(query),
    onChange: (sortingFields: ISortingData) => this.setState({ sortingFields }),
  };

  handleFilterOpen = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    this.setState({ filterAnchor: event.currentTarget });
  };
  handleCloseFilterPopover = () => {
    this.setState({ filterAnchor: undefined });
  };
  handleFilterChangeAccount = (filters: IFilter[]) => {
    this.setState(
      {
        filters,
        pageNum: 1,
      },
      () => this.getAllApiCalling()
    );
  };
  handleDateFilterParam = () => {
    const today = moment().format('YYYY-MM-DD');
    let startDate, endDate;
    let dateRange = "today"
    const dateFilter = this.state.filters.find((item) => item?.title === 'Date')?.value;
    let commonEndDate = today;
    let comomStartDate = moment().subtract(6, "days").format("YYYY-MM-DD");
    switch (dateFilter) {
      case "last7":
        endDate = commonEndDate;
        startDate = comomStartDate;
        dateRange = "last_7_days"
        break;
      case "today":
        dateRange = "today"
        endDate = today;
        startDate = today;
        break;
      case "specific":
        const dateFilter = this.state.filters.find(
          (item) => item?.title === "Date"
        )?.datevalue;
        if (dateFilter?.from && dateFilter?.to) {
          endDate = moment(dateFilter.to).format("YYYY-MM-DD");
          startDate = moment(dateFilter.from).format("YYYY-MM-DD");
        }
        dateRange = "custom"
        break;
      case "last30":
        dateRange = "last_30_days"
        endDate = today;
        startDate = moment().subtract(29, "days").format("YYYY-MM-DD");
        break;
      default:
        endDate = today;
        startDate = today;
    }
    const startDateText = startDate
      ? handleMomentDateFormat(startDate, "DD-MM-YYYY")
      : moment().subtract(7, "days").format("DD-MM-YYYY");
    const endDateText = endDate
      ? handleMomentDateFormat(endDate, "DD-MM-YYYY")
      : moment().format("DD-MM-YYYY");

      const dateFilterQuery = `date_range=${dateRange}&start_date=${startDate}&end_date=${endDate}`

      const {storeId, regionId,groupId} = this.state

    const regionParam = regionId && !storeId ? `&region_id=${regionId}` : ""

    let storeParam = `&store_ids=${storeId ? storeId : "All"}`

    storeParam = regionId ? storeParam  : ""

    const params =  dateFilterQuery + regionParam.replace("region_id", "region_ids") + storeParam

    return { startDateText, dateFilterQuery, endDateText, params };
  };

  handleStoreChange = (storeId: string, storeName: string,isAllSelected: boolean,groupId:string,groupName:string) => {
    this.setState({storeId,  storeName: isAllSelected ? "All Stores" : storeName,groupId,groupName}, () => this.getAllApiCalling())
  };

  handleUserChange = (context: IUserContext) => {
    const apiKey = customPermissionApiKey.dashboardPermission;
    const userData = context.user?.attributes.permission_groups;
    const value = checkForDashboardPermissonStatus(apiKey, userData as unknown as Array<PermissionGroupArray>);
    this.setState({
      permissionStatus: value
    });
  }

  getAllApiCalling = () => {
    this.getServiceWiseSectionLaundry()
    this.getServiceWiseSectionDryCleaning()
    this.getServiceWiseSectionPressing()
    this.getSaleBySection()
    this.getSaleByService()
    this.getSaleSummary()
    this.getVatSubtotal()
  }

  handleOpenExportModal = () => {
    this.setState({
      openExportModal: !this.state.openExportModal
    })
  }

  handleExportConfirm = async (method: string) => {
    if (method === "pdf") {
      const wrapperElement = document.getElementById("print-wrapper") as HTMLElement
     await html2pdf(wrapperElement, {
        filename: "section-sale.pdf",
        jsPDF: {
          unit: "px",
          format: [1440 , 1440]
        },
        margin: 24,
        pagebreak: { mode: ['css'] },
      })
      this.setState({openExportModal: false})
    }
  }

  receiveDataFromTopBar = (message: Message) => {
    if (message.id === getName(MessageEnum.BroadcastNavbarDataMessage)) {
      const receivedData = message.getData(
        getName(MessageEnum.BroadcastNavbarData)
      );
      const {storeId,regionMultiId,groupId,regionNames,regionSelectAll} = receivedData
      this.setState({regionId: regionMultiId, storeId, groupId:groupId,
        regionName: handleDisplayRegion(regionMultiId,regionNames,regionSelectAll).textDisplayed
       }, () => {
        if (regionMultiId) this.getAllApiCalling()
      })
    }
  }

  // Customizable Area End
}
