// Customizable Area Start
import React, { Component, ReactNode } from "react";
import Dialog from "@material-ui/core/Dialog";
import { withStyles, Theme } from "@material-ui/core/styles";
import { Styles } from "@material-ui/styles";
import { PhoneDisabledIcon } from "./PaymentModalAssets";
// Customizable Area End

// Customizable Area Start
interface OrderTryAgainModalProps {
  open: boolean;
  classes?: any;
  handleClose: () => void;
}

const webStyle = {
  orderTryAgainModalMainContainer: {
    padding: "64px 73px 48px 73px",
    width: 574,
    display: "flex",
    flexDirection: "column" as const,
    alignItems: "center",
  },
  orderBtn: {
    display: "flex",
    padding: 16,
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    color: "#FFF",
    fontSize: 16,
    fontFamily: "Montserrat",
    fontWeight: 700,
    cursor: "pointer",
    borderRadius: 8,
    background: "#204B9C",
    marginTop: 32,
  },
  modalTitle: {
    color: "#1A1A1A",
    fontSize: 24,
    fontWeight: 600,
    marginTop: 48,
  },
  modalText: {
    color: "#4D4D4D",
    fontSize: 20,
    fontWeight: 400,
    marginTop: 16,
    textAlign: "center" as const,
    lineHeight: "30px",
  },
};
// Customizable Area End

// Customizable Area Start
export class OrderTryAgainModal extends Component<OrderTryAgainModalProps> {
  render(): ReactNode {
    const { open, handleClose, classes = {} } = this.props;

    return (
      <Dialog maxWidth='xl' open={open} onClose={handleClose}>
        <div style={webStyle.orderTryAgainModalMainContainer}>
          <div>
            <PhoneDisabledIcon />
          </div>
          <div style={webStyle.modalTitle}>Try Again</div>
          <div style={webStyle.modalText}>
            Please enter your mobile number to proceed with your order
          </div>
          <div style={webStyle.orderBtn} onClick={handleClose}>
            Go to Order Creation
          </div>
        </div>
      </Dialog>
    );
  }
}

export default OrderTryAgainModal;
// Customizable Area End
