import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start



export const configData = require("./config");
import { Tax, TaxResponse } from "./util";
import { PermissionGroupArray } from "../../../blocks/navigationmenu/src/utils";
import { IUserContext } from "../../../blocks/navigationmenu/src/PageContainerController.web";
import { PermissionStatus,customPermissionApiKey,checkForNewPermissonStatus } from "../../utilities/src/CustomBlockHelpers"

// Customizable Area End



export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any;
  // Customizable Area End
}

interface S {
  token: string;
  // Customizable Area Start
  selectedType: string;
  errorMessage: string;
  loading: boolean;
  editId: string;
  viewTax :Tax;
  permissionStatus: PermissionStatus;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class ViewTaxController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  editId: string = "";
  getTaxCallId: string = "";

  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area Start
      getName(MessageEnum.RestAPIRequestMessage),
      // Customizable Area End
    ];

    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  // Customizable Area Start
    this.state = {
      selectedType: "",
      token: "",
      loading: false,
      errorMessage: "",
      editId: "",
      viewTax: {} as Tax,
      permissionStatus: {
        mainPermission: false,
        createPermission: false,
        viewPermission: false,
        editPermission: false,
        deactivatePermission: false
      },
    };

    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Received", message);

    if (getName(MessageEnum.RestAPIResponceMessage) !== message.id) {
      return;
    }

    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

    if (apiRequestCallId === this.getTaxCallId) {
      this.setState({
        loading: false,
      });
      this.handleViewTaxResponse(responseJson)
    }
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    this.setState({
      editId: this.props.navigation.getParam("id", "defaultValue"),
    });
    this.getTaxData(this.state.editId);
    // Customizable Area End
  }

  componentDidUpdate(prevProps: Props, prevState: S) {
     // Customizable Area Start
    if (prevState.editId !== this.state.editId) {
      this.getTaxData(this.state.editId);
       // Customizable Area End
    }
  }

  // Customizable Area Start

 
  token = localStorage.getItem("token");

  getTaxData = (id: string) => {
  
    this.setState({
      loading: true,
    });

    const header = {
      "Content-Type": configData.apiContentType,
      token: this.token,
    };
    const getDataMsgs = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getTaxCallId = getDataMsgs.messageId;

    getDataMsgs.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configData.viewTaxApi}/${id}`
    );
    getDataMsgs.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    getDataMsgs.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
    runEngine.sendMessage(getDataMsgs.id, getDataMsgs);
  };



  handleViewTaxResponse = (responseJson: TaxResponse) => {
    const parsedData ={
       id: responseJson.data.id,
       taxName: responseJson.data.attributes.tax_name,
      taxRate: responseJson.data.attributes.tax_rate,
    } 

    this.setState({
      viewTax: parsedData,
    })
 }

 handleBack = ()=>{
   this.props.navigation.navigate(configData.taxList)
 }

 handleEditNavigate = ()=>{
  this.props.navigation.navigate(configData.addEditTax, {
    id: this.state.viewTax.id,
  })
 }

 handleUserChange = (userContext: IUserContext) => {
  const apiKey = customPermissionApiKey.taxcalculator;
  const userData = userContext.user?.attributes.permission_groups;
  const value = checkForNewPermissonStatus(apiKey, userData as Array<PermissionGroupArray>);
  this.setState({
    permissionStatus: value
  })
};
  // Customizable Area End
}
