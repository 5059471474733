import React from "react";
// Customizable Area Start
import { Dialog, DialogContent, styled } from "@material-ui/core";
import ImportExportErrorModalController, {
  configJSON,
  Props,
} from "./ImportExportErrorModalController.web";
import { colors } from "../../utilities/src/Colors";
import { StyledButton } from "./ImportExportData.web";
// Customizable Area End

// Customizable Area Start
const Strings = configJSON.importExportError;
// Customizable Area End

export default class ImportExportErrorModal extends ImportExportErrorModalController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { errors, successCount } = this.state;
    // Customizable Area End
    return (
      // Customizable Area Start
      <StyledDialog
        open={this.state.errorModal}
        onClose={() => {
          this.handleModalClose();
        }}
      >
        <DialogContent className={"dialogContentBox"}>
          <p className={"successText"}>
            {Strings.successDataCount} {successCount}
          </p>
          <ul className={"errorList"}>
            {Object.keys(errors).map((key: any) => (
              <li key={key} className={"errorListItem"}>{`Row ${key}: ${errors[
                key
              ]}`}</li>
            ))}
          </ul>
          <p className={"paraText"}> {Strings.errorPara}</p>
          <div className={"btnGroup"}>
            <StyledButton
              data-test-id={"cancelButton"}
              className={"doneButton"}
              onClick={() => this.handleModalClose()}
            >
              {configJSON.cancel}
            </StyledButton>
          </div>
        </DialogContent>
      </StyledDialog>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const StyledDialog = styled(Dialog)({
  "& .MuiDialog-paperScrollPaper": {
    borderRadius: "24px",
  },
  "& .MuiDialog-paper": {
    boxShadow: "none",
  },
  "& .dialogContentBox": {
    width: 450,
    textAlign: "center",
    maxHeight: "100%",
  },
  "& .successText": {
    color: colors().activeGreen,
  },
  "& .paraText": {
    fontSize: 14,
    color: colors().darkliver,
  },
  "& .errorList": {
    listStyle: "none",
    padding: 0,
    margin: "12px 0",
  },
  "& .errorListItem": {
    margin: "6px 0",
    fontSize: 14,
    color: colors().error,
  },
  "& .btnGroup": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "12px 0",
  },
});

// Customizable Area End
