import React, { useState } from 'react'

import {

  Select,
  MenuItem,
} from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";

interface SelectProps {
  titleSelect: string,
  selectOptions: { text: string; value: string }[] | string[],
  options: {
    placeHolder?: string;
    value?: string;
    onChange?: (value: string) => void;
  },
  required: boolean,
  hideTitle?: boolean
}

const useStyles = makeStyles(() => ({
  select: {
    "& .Mui-selected": {
      backgroundColor: 'red',
      color: 'white'
    },
  }
}))
export const DropdownSelect: React.FC<SelectProps> = (props) => {
  let { titleSelect, selectOptions, options, required, hideTitle = false } = props

  const classes = useStyles();
  const [formError, setFormError] = useState('')
  const placeHolder = options.placeHolder ?? "Select " + titleSelect;
  let fixedSelectOptions: { text: string; value: string }[] = [];
  if (typeof selectOptions[0] === "string") {
    selectOptions = selectOptions as string[];
    fixedSelectOptions = selectOptions.map((option: string) => ({ text: option, value: option }));
  } else {
    fixedSelectOptions = selectOptions as { text: string; value: string }[];
  }

  return (
    <div style={webStyle.columnContainer}>
      {titleSelect && (
        <>
          {!hideTitle && <div style={webStyle.inputLabel}>{titleSelect}</div>}
          <Select
            key={titleSelect}
            data-test-id={titleSelect}
            value={options.value}
            renderValue={(value) =>
              fixedSelectOptions.find((option) => option.value === value)?.text || placeHolder
            }
            className={classes.select}
            onChange={(event) => {
              console.log('event.target.value, required :>> ', event.target.value, required);
              if (required && !event.target.value) {
                setFormError("Please select value.")
              } else {
                setFormError('')
              }
              if (options?.onChange) {
                options.onChange(event.target.value as string);
              }
            }}
            placeholder={placeHolder}
            displayEmpty
            style={webStyle.inputField}
            variant='outlined'
          // error={!!formErrors[titleSelect]}
          >
            <StyledMenuItem disabled value=''>
              <em>None</em>
            </StyledMenuItem>
            {fixedSelectOptions.map((option) => (
              <StyledMenuItem key={option.value} value={option.value}>{option.text}</StyledMenuItem>
            ))}
          </Select>
          {formError && (
            <div style={webStyle.errorText}>{formError}</div>
          )}
        </>
      )}
    </div>
  );



};

const webStyle = {
  columnContainer: {
    width: "100%",
  },
  inputLabel: {
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: "20px",
    alignItems: "center",
    letterSpacing: "-0.3px",
    color: "#1A1A1A",
    fontFamily: "Montserrat !important",
  },
  inputField: {
    width: "100%",
    height: "56px",
    marginTop: "4px",
    marginBottom: "30px",
    fontFamily: "Montserrat !important",
  },
  errorText: {
    color: "red",
    fontSize: "12px",
    marginTop: "-26px",
  },
}

const StyledMenuItem = withStyles({
  root: {
    "&.Mui-selected": {
      backgroundColor: '#204B9C',
      color: 'white'
    },
    "&.Mui-selected:hover": {
      backgroundColor: '#204B9C',
      color: 'white'
    }
  }
})(MenuItem)
