export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const IconBluePrint = require("../assets/bluePrint.svg");
export const IconOrderDetailsCircle = require("../assets/orderDetailsCircle.svg");
export const CheckTrue = require("../../../components/src/check_true.svg");
export const CheckFalse = require("../../../components/src/check_false.svg");
export const waitingForApprovalIcon = require("../assets/waiting_approval.svg");
export const darkPlusIcon = require("../assets/dark_plus_icon.svg");
export const bluePlusIcon = require("../assets/blue_plus_icon.svg");
export const calenderIcon = require("../assets/calender_icon.png");
export const filterIcon = require("../assets/filter_icon.png");
export const creditNoteIcon = require("../assets/credit_note_icon.svg");
export const washTshirt = require("../assets/washTshirt.svg");
export const starchHeavy = require("../assets/starchHeavy.svg");

export const AddProductIcon = require("../assets/button_add_item.png");
export const RemoveProductIcon = require("../assets/button_remove_item.png");
export const DeleteProductIcon = require("../assets/button_delete_item.png");
export const SampleProductItemImage = require("../assets/product_item.png");

export const SampleService1 = require("../assets/service1.png");
export const SampleService2 = require("../assets/service2.png");
export const SampleService3 = require("../assets/service3.png");
export const CircleIcon = require("../assets/circle.svg");

export const RadioCheckedIcon = require("../assets/radio_checked.png");
export const RadioUnCheckedIcon = require("../assets/radio_unchecked.png");

export const MinusLightButton = require("../assets/blue_light_minus_button_.png");
export const NoImagePlaceholder = require("../assets/no_image_placeholder.png")
export const UncheckedBoxImg = require("../assets/uncheckbox_.svg")
export const DropdownUnchceked = require("../assets/drop_uncheckbox.svg")
export const DropdownChecked = require("../assets/drop_checkbox.svg")
