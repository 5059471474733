import React from "react";
// Customizable Area Start

import {
  Paper,
  TableRow,
  TableHead,
  TableContainer,
  TablePagination,
  TableCell,
  TableBody,
  Table,
  Box,
  IconButton,
  Typography,
  Modal,
  Button
} from "@material-ui/core";

import MoreVertIcon from "@material-ui/icons/MoreVert";
import VisibilityIcon from '@material-ui/icons/Visibility';

import AreaListController, { Props } from "./AreaListController.web";
import PageContainer from "../../navigationmenu/src/PageContainer.web"
import { configJSON } from "./AddRegionController.web";
import TablePopover from "../../../components/src/TablePopover.web";
import SortingTableHeader from "../../../components/src/SortingTableHeader";
import SortingTableCell from "../../../components/src/SortingTableCell";
import PageHeader from "../../../components/src/PageHeader";
import FilterPopover, { IFilter } from "../../../components/src/FilterPopover";
import { filterIcon } from "../../RolesPermissions2/src/assets";
import { FilterBox } from "../../../components/src/customComponents/CustomTags.web";

const modalStyle: any = {
  maxHeight: "calc(100vh - 126px)",
  position: "absolute",
  top: "49%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "587px",
  height: "auto",
  bgcolor: "#fff",
  borderRadius: 12,
  boxShadow: 24,
  padding: "24px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  overflowY: "auto",
  "@media (max-width: 768px)": {
    padding: "20px",
    width: "75%",
  },
};
export default class AreaList extends AreaListController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { permissionStatus } = this.state;
    const rows = this.state.areas.map((area) => {
      const storeCount = area?.attributes.store_management_count;
      const storeList = area?.attributes.store_managements;
      const company = area?.attributes.company.data.attributes.name || "";
      const name = area?.attributes.area_name || "";
      const region = area?.attributes.region.data.attributes.branch_name || "";
      const status = area?.attributes.activated ? "Active" : "Deactivated";
      const id = area?.id || "";

      return this.createData(company, name, region, status, id, storeCount, storeList);
    });

    const filteredRowsArea = rows.slice(
      this.state.page * this.state.pageSize,
      (this.state.page + 1) * this.state.pageSize
    );
    const emptyRowsArea = this.state.pageSize - filteredRowsArea.length;
    const sortingData = this.state.sortingData;

    return (
      <>
        <Box style={webStyle.mainWrapperArea}>
          <PageContainer
            onSearchText={(value) => this.handleSearchArea(value)}
            navigation={this.props.navigation}
            onUserChange={(userContext) => this.handleUserChange(userContext)}
          >
            <Box>
            <Box style={{marginTop:'15px'}}>
              <PageHeader
                data-test-id='companyListHeader'
                header={configJSON.addAreaHeader}
                permissionStatus={this.state.permissionStatus.createPermission}
                buttonText={"Add Area"}
                onButtonClick={this.handleAddArea}
                filterComponent={
                  <div style={{ display: "flex" }}>
                    <FilterBox
                      data-test-id='filterBtn'
                      onClick={(event) => {
                        this.setState({
                          filterAnchor: event.currentTarget,
                        });
                      }}
                      style={webStyle.filterButtonArea}
                    >
                      <img src={filterIcon} />
                    </FilterBox>
                    <FilterPopover
                      anchor={this.state.filterAnchor}
                      onClose={() => {
                        this.setState({ filterAnchor: undefined });
                      }}
                      onAutoCompleteChange={(title: string, value: string) => {
                        this.setState({ suggestionFieldTitle: title });
                        this.handleAreaAutoComplete(title, value);
                      }}
                      onClearFilter={() => {
                        let updated = this.state.filters.map((item: IFilter) => {
                          item.value = ""; 
                          item.options=[];
                          return item;
                        });
                        this.setState({ filters: updated});
                      }}
                      filters={this.state.filters}
                      onFilterChange={this.handleFilterChange}
                    />
                  </div>
                }
              />
              <TableContainer component={Paper}>
                <Table style={webStyle.tableArea} aria-label='customized table'>
                  <TableHead>
                    <TableRow
                      style={{ background: "#204B9C", color: "#FFFFFF" }}
                    >
                      <SortingTableHeader
                        sortingData={sortingData}
                        title={configJSON.companyName}
                        sortingKey='company_name'
                        {...this.sortingProps}
                        type='left'
                      />
                      <SortingTableHeader
                        sortingData={sortingData}
                        title={configJSON.areaName}
                        sortingKey='area_name'
                        {...this.sortingProps}
                      />
                      <SortingTableHeader
                        sortingData={sortingData}
                        title={configJSON.region}
                        sortingKey='branch_name'
                        {...this.sortingProps}
                      />
                      <SortingTableHeader
                        sortingData={sortingData}
                        title='Status'
                        sortingKey=''
                        {...this.sortingProps}
                        sortingDisabled
                      />
                      <SortingTableHeader
                        sortingData={sortingData}
                        title='Store Count'
                        sortingKey='store_count'
                        {...this.sortingProps}
                      />
                      <SortingTableHeader
                        sortingData={sortingData}
                        title=''
                        sortingKey=''
                        {...this.sortingProps}
                        type='action'
                        sortingDisabled
                      />
                    </TableRow>
                  </TableHead>
                  {filteredRowsArea.length > 0 &&
                    <TableBody>
                      {filteredRowsArea.map((rowData, index) => (
                        <TableRow key={index + "TableRow"}>
                          <SortingTableCell
                            width='22vw'
                            type='left'
                            content={rowData.company}
                          />
                          <SortingTableCell
                            width='22vw'
                            content={rowData.name}
                          />
                          <SortingTableCell
                            width='22vw'
                            content={rowData.region}
                          />
                          <SortingTableCell
                            width='22vw'
                            content={rowData.status}
                          />
                          <SortingTableCell
                            width='22vw'
                            type='right'
                            content={<Box sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center", padding: 4, height: 2, marginTop: 10}}>
                              <Typography component="div">{rowData.storeCount}</Typography>
                              <IconButton data-test-id="view-store-list" onClick={() => { this.handleStoreList(rowData.storeList)}}>
                                <VisibilityIcon />
                              </IconButton>
                            </Box>}
                          />
                          <SortingTableCell
                            data-test-id='moreBtnTableCell'
                            width='22vw'
                            type='action'
                            content={
                              <IconButton
                                data-test-id={`moreButton-${rowData.id}`}
                                id={"IconButton" + index}
                                onClick={(event) => {
                                  const position =
                                    event.currentTarget.getBoundingClientRect();
                                  this.setState({
                                    popOverOpened: true,
                                    popOverItemId: rowData.id,
                                    popOverItemStatus: rowData.status,
                                    popOverLeft: position.left + window.scrollX,
                                    popOverTop: position.top + window.scrollY,
                                  });
                                }}
                              >
                                <MoreVertIcon />
                              </IconButton>
                            }
                          />
                        </TableRow>
                      ))}
                      {emptyRowsArea > 0 && (
                        <TableRow style={{ height: 53 * emptyRowsArea }}>
                          <TableCell colSpan={6} />
                        </TableRow>
                      )}
                    </TableBody>
                  }
                </Table>
                {filteredRowsArea.length === 0 && (
                  <div
                    style={{
                      fontSize: 16,
                      width: "100%",
                      textAlign: "center",
                      fontWeight: 602,
                    }}
                  >
                    {configJSON.noRecordFound}
                  </div>
                )}
                <TablePopover
                  data-test-id={configJSON.popoverTest}
                  permissionStatus={this.state.permissionStatus}
                  open={this.state.popOverOpened}
                  onClose={() => this.setState({ popOverOpened: false })}
                  top={this.state.popOverTop}
                  left={this.state.popOverLeft}
                  onView={() => this.handleViewArea(this.state.popOverItemId)}
                  onEdit={() => this.handleEditArea(this.state.popOverItemId)}
                  onDeactivate={() => this.handleDeactivate()}
                  onActivate={this.handleActivateArea}
                  deactivateTitle={configJSON.addAreaHeader}
                  isActive={
                    this.state.popOverItemStatus === "Active" ? true : false
                  }
                />
              </TableContainer>
              {filteredRowsArea.length > 0 && (
                <TablePagination
                  rowsPerPageOptions={[10]}
                  data-test-id='pagination'
                  component='div'
                  rowsPerPage={this.state.pageSize}
                  onPageChange={this.handlePageChange}
                  count={rows.length}
                  page={this.state.page}
                />
              )}
            </Box>
            </Box>
            {
              this.state.openStoreListDialog && (
                <Modal
                open={this.state.openStoreListDialog}
                data-test-id='store_list_modal'
                onClose={this.handleCloseStoreList}
                aria-labelledby='modal-modal-title'
                aria-describedby='modal-modal-description'
              >
                <Box sx={modalStyle}>
                  <h4 style={webStyle.modalHeading}>
                    Stores
                  </h4>
                  <Box className='listingWrapper'>
                    {this.state.listingDataShow.map((item: any) => {
                      return (
                        <Box
                          data-test-id='roles_list_item'
                          key={item}
                          className='listingItem'
                        >
                          {item}
                        </Box>
                      );
                    })}
                  </Box>
                  {this.state.listingDataShow.length <= 0 && (
                    <Box style={webStyle.noDataFound}>
                      {`No Stores found`}
                    </Box>
                  )}
                  <Button
                    data-test-id='closePermissionModal'
                    style={webStyle.modalCancelBtn}
                    onClick={() => this.handleCloseStoreList()}
                  >
                    Cancel
                  </Button>
                </Box>
              </Modal>
              )
            }
          </PageContainer>
        </Box>
      </>
    );
  }
}

const webStyle = {
  tableArea: {
    minWidth: 700,
  },
  mainWrapperArea: {
    marginTop: "32px",
    fontFamily: "Montserrat",
  },
  pageHeaderArea: {
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "24px",
    lineHeight: "29px",
  },
  headerWrapper: {
    justifyContent: "space-between",
    display: "flex",
    marginBottom: 32,
    alignItems: "center",
  },
  addButtonArea: {
    background: "#204B9C",
    fontWeight: 600,
    fontSize: "16px",
    color: "#FFFFFF",
    lineHeight: "24px",
    height: "56px",
    width: "184px",
    borderRadius: "8px",
  },
  filterButtonArea: {
    cursor: "pointer",
    padding: "16px",
    border: "solid 1px rgba(32, 75, 156, 1)",
    borderRadius: "15px",
  },

  modalHeading: {
    fontSize: "24px",
    lineHeight: "24px",
    fontFamily: "Montserrat",
    margin: "0 0 16px 0",
  },
  modalCancelBtn: {
    minHeight: "56px",
    width: "126px",
    backgroundColor: "rgba(32, 75, 156, 0.1)",
    borderRadius: "8px",
    marginTop: "32px",
    fontSize: "16px",
    lineHeight: "24px",
    color: "rgba(100, 116, 139, 1)",
    fontWeight: 600,
    textTransform: "capitalize" as const,
  },
  noDataFound: {
    fontSize: "20px",
    lineHeight: "20px",
    fontFamily: "Montserrat",
    padding: "16px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    margin: "16px 0",
    fontWeight: 500,
  },
};

// Customizable Area End
