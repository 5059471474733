import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  styled,
  Dialog,
  TextField
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { colors } from "../../utilities/src/Colors";
import CustomLoader from "../../../components/src/customComponents/CustomLoader.web";
import { barCodeImg } from "./assets";
import { trim } from "lodash";
// Customizable Area End

import BarcodesScannerController, {
  Props,
  configJSON,
} from "./BarcodesScannerController";

export default class BarcodesScanner extends BarcodesScannerController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <StyledWrapper>
        <Button className="toggle-button" onClick={this.toggleDialog}>
          <span>{configJSON.labelButton}</span>
        </Button>
        <StyledDialog
          open={Boolean(this.props.openDialog && this.state.open)}
          maxWidth='sm'
          data-test-id='barcode-modal'
          onClose={this.hanldeClose}
        >
          <CustomLoader loading={Boolean(this.props.loading)}/>
          <h3>Scan Order Barcode</h3>
          <img src={barCodeImg} alt="" />
          <p>Scan the barcode or type the order number:</p>
          <TextField
            autoFocus
            data-test-id="order-number-input" 
            label="Order Number" 
            value={this.state.txtInputValue} 
            onChange={(event) => this.setInputValue(trim(event.target.value))}
            onKeyPress={this.handlePress}
          />
          <Box className="actions">
            <Button className="cancel-btn" onClick={this.hanldeClose}><span>Cancel</span></Button>
            <Button className="submit-btn" data-test-id="barcode-submit-btn" onClick={() => this.handleSubmit()}><span>OK</span></Button>
          </Box>
        </StyledDialog>
      </StyledWrapper>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const StyledWrapper = styled(Box)({
  "& .toggle-button": {
    padding: 16,
    background: colors().cyancobaltblue,
    color: "white"
  },
})

const StyledDialog = styled(Dialog)({
  "& .MuiDialog-Paper": {
    padding: 16,
    borderRadius: 12,
    display: "flex",
    flexDirection: "column",
    gap: 16,
    "& h3": {
      textAlign: "center"
    },
    "& .MuiFormLabel-root": {
      fontFamily: "Montserrat"
    },
    "& .actions": {
      display: "flex",
      justifyContent: "center",
      gap: 20,
      "& > button": {
        padding: 16,
        borderRadius: 8,
        width: 126,
        border: 0,
        fontWeight: 700,
        lineHeight: 1.5,
        textTransform: "none",
        cursor: "pointer",
        "@media (max-width: 1024px)": {
          padding:"10px 0"
        },
      },
      "& .cancel-btn": {
        backgroundColor: colors().antiFlashWhite,
        color: colors().slateGray,
        
      },
      "& .submit-btn": {
        backgroundColor: colors().cyancobaltblue,
        color: "white",
      }
    }
  }
})
// Customizable Area End
