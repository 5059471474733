// Customizable Area Start
import React from "react";
import PlantAddController, {
  Props,
  FormModePlant,
  IFormPlant,
  IRenderViewPlant,
  IRenderArrayViewPlant,
} from "./PlantAddController.web";

import {styled} from "@material-ui/core";
import { withStyles, Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { Styles } from "@material-ui/styles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import ListItemText from "@material-ui/core/ListItemText";
import CircularProgress from "@material-ui/core/CircularProgress";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import PageContainer from "../../navigationmenu/src/PageContainer.web";
import Loader from "../../../components/src/Loader.web";
export const configJSON = require("./config");
import { MenuProps } from "../../email-account-registration/src/AccountCreationController.web";
import { CustomAutoCompleteSearchable } from "../../../components/src/customComponents/CustomAutoCompleteSearchable.web";
// Customizable Area End

// Customizable Area Start
const StyledMenuItemPlant = withStyles((theme) => ({
  root: {
    "&:focus": {
      backgroundColor: "#204B9C",
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.common.white,
      },
    },
    "& .option-label": {
      fontSize:"14px"
    }
  },
}))(MenuItem);
const useStyles: Styles<Theme, {}, string> = () => ({
  sectionFormContainerPlant: {
    padding: 24,
    marginTop: 24,
    border: "1px solid #ECECEC",
    borderRadius: 8,
    position: "relative",
  },
  removeBtn: {
    position: 'absolute',
    top: 5,
    right: 5,
    textTransform: 'none',
    border: "1px solid #DC2626",
    borderRadius: 6,
    color: "#DC2626",
    fontWeight: 500,
    fontSize: 14,
    "&:hover": {
      color: "#DC2626",
      border: "1px solid #DC2626",
    }
  },
  pageContainerPlant: {
    fontFamily: "Montserrat",
    padding: "0px 24px 32.5px",
    background: "white",
    "& .MuiSelect-select.Mui-disabled": {
      color: "#7E7E7E",
    },
  },
  pageTitlePlant: {
    fontSize: 24,
    fontWeight: 600,
    marginTop: 32,
    "@media only screen and (max-width: 1024px)": {
      fontSize: "16px"
    },
  },
  formContainerPlant: {
    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.03), 0px 8px 32px rgba(0, 0, 0, 0.06)",
    padding: 32,
    borderRadius: 12,
    "& .MuiFormLabel-root": {
      fontFamily: "Montserrat",
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: 16,
      letterSpacing: "-0.3px",
      color: "#1A1A1A",
    },
    position: "relative",
  },
  paddingRightClass: {
    paddingRight: 0,
  },
  cancelButton: {
    padding: "16px 34px",
    borderRadius: 8,
    fontSize: 16,
    fontWeight: 700,
    cursor: "pointer",
    backgroundColor: "#F1F5F9",
    color: "#64748B",
    marginRight: 32,
    marginTop: 16,
    "@media only screen and (max-width: 1024px)": {
      fontSize: "16px"
    },
  },
  submitButton: {
    padding: "16px 34px",
    borderRadius: 8,
    fontSize: 16,
    fontWeight: 700,
    cursor: "pointer",
    backgroundColor: "#204B9C",
    color: "white",
    width: 128,
    textAlign: "center",
    marginRight: 32,
    marginTop: 16,
    "@media only screen and (max-width: 1024px)": {
      fontSize: "16px"
    },                  
  },
  errorInput: {
    "& fieldset": {
      borderColor: "red",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "red",
    },
  },
  selectContainer: {
    height: 56,
  },
  viewSectionHeader: {
    padding: "19px 32px",
    background: "#204B9C",
    color: "white",
    fontWeight: 600,
    fontSize: 18,
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    borderTopLeftRadius: 12,
    borderTopRightRadius: 12,
  },
  sectionTitle: {
    fontFamily: "Montserrat",
    fontWeight: 600,
    fontSize: 18,
    "@media only screen and (max-width: 1024px)": {
      fontSize: "14px"
    },
  },
  buttonWithIcon: {
    cursor: "pointer",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    padding: "10px 16px",
    background: "rgba(32, 75, 156, 0.1)",
    borderRadius: 8,
    color: "#4D4D4D",
    fontWeight: 600,
    fontSize: 16,
    "@media only screen and (max-width: 1024px)": {
      fontSize: "16px"
    },
  },
});
// Customizable Area End


export class PlantAdd extends PlantAddController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderTextField(key: keyof IFormPlant, placeholder: string) {
    const { form, formErrors } = this.state;
    const { classes = {} } = this.props;

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      let inputValue = event.target.value;

      this.onFormElementChange({ ...event, target: { ...event.target, value: inputValue } }, key);
    };

    return (
      <StyledTextInput
        disabled={key === "total_capicity_in_kg" || key === "total_capicity_in_piece"}
        data-test-id={key}
        className={formErrors[key] ? classes.errorInput : "withoutError"}
        value={form[key]}
        color='primary'
        variant='outlined'
        type='text'
        name={key}
        placeholder={placeholder}
        onChange={handleInputChange}
      />
    );
  }

  renderInputTextField(
    key: keyof IRenderViewPlant,
    label: string,
    placeholder: string,
    leftOne: boolean
  ) {
    const { formErrors } = this.state;
    const { classes = {} } = this.props;

    return (
      <>
        <Grid item xs={12} md={6} className={leftOne ? classes.paddingRightClass : ""}>
          <FormControl style={{ width: "100%" }}>
            <StyledTextInputLabel style={{ marginBottom: "8px" }}>{label}</StyledTextInputLabel>
            {this.renderTextField(key, placeholder)}
            {formErrors[key] && (
              <Typography className='errorMsg2' variant='caption' style={{ color: "red" }}>
                {formErrors[key]}
              </Typography>
            )}
          </FormControl>
        </Grid>
      </>
    );
  }

  renderArrayInputTextField = (
    key: keyof IRenderArrayViewPlant,
    label: string,
    placeholder: string,
    item: {
      id: string | number;
      name: string;
      capicity_in_kg: number | null;
      capicity_in_piece: number | null;
      _destroy: boolean;
    },
    leftOne: boolean,
    testId?: string,
    itemId?: string|number
  ) => {
    const { formErrors } = this.state;
    const { classes = {} } = this.props;
    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      let inputValue = event.target.value;

      this.onArrayFormElementChange(
        { ...event, target: { ...event.target, value: inputValue } },
        key,
        itemId
      );
    };

    return (
      <>
        <Grid item xs={12} md={6} className={leftOne ? classes.paddingRightClass : ""}>
          <FormControl style={{ width: "100%" }}>
            <StyledTextInputLabel style={{ marginBottom: "8px" }}>{label}</StyledTextInputLabel>
            <StyledTextInput
              data-test-id={testId}
              className={formErrors[key] ? classes.errorInput : "withoutError"}
              value={item[key]}
              color='primary'
              variant='outlined'
              type='text'
              name={String(key)}
              placeholder={placeholder}
              onChange={handleInputChange}
            />
            {formErrors[`plant_section_${key}_${itemId}`] && (
              <Typography className={"errorMsg2"} variant='caption' style={{ color: "red" }}>
                {formErrors[`plant_section_${key}_${itemId}`]}
              </Typography>
            )}
          </FormControl>
        </Grid>
      </>
    );
  };

  renderRegionSelect() {
    const { form, formErrors, regionList } = this.state;
    const { classes = {} } = this.props;

    return (
      <Grid item xs={12} md={6}>
        <FormControl variant='outlined' style={{ width: "100%" }}>
          <StyledTextInputLabel style={{ marginBottom: "8px" }}>{configJSON.region}</StyledTextInputLabel>
          <CustomAutoCompleteSearchable 
                dataTestID="region"
                value={form.region_data} 
                optionList={regionList.map((region) => ({
                  option: region.name,
                  id: region.id + "",
                }))} 
                debouncedFunction={()=>{}} 
                handleScroll={this.handleScrollRegionDropdown} 
                handleSelectOptions={(value:any)=>{
                  this.setState({
                    form: {
                      ...this.state.form,
                      region_id:value.id,
                      region_data:{
                        id:value.id,
                        option:value.option
                      }
                    },
                  });
                }} 
                selectedItem={form.region_data} 
                placeholder={"Select " + configJSON.selectRegion} 
                isError={Boolean(formErrors.region_id)}
            />

          {formErrors.region_id && (
            <Typography
              className={"errorMsg2"}
              variant='caption'
              style={{ color: "red", marginBottom: "-20px" }}
            >
              {formErrors.region_id}
            </Typography>
          )}
        </FormControl>
      </Grid>
    );
  }

  renderTitle = () => {
    const { classes = {} } = this.props;
    return <div className={classes.pageTitlePlant}>{configJSON.plantInformation}</div>;
  };
  // Customizable Area End
  render() {
    // Customizable Area Start
    const { classes = {} } = this.props;

    const { form, isSubmitting, formMode } = this.state;
    let buttonText;

    if (isSubmitting) {
      buttonText = <CircularProgress size={16} color='inherit' />;
    } else if (formMode === FormModePlant.Edit) {
      buttonText = configJSON.saveText;
    } else {
      buttonText = configJSON.add;
    }
    return (
      <>
        <PageContainer navigation={this.props.navigation}>
          <Loader loading={this.state.isLoading} />
          <Box>
          <Grid container direction='column' className={classes.pageContainerPlant}>
            <Grid
              container
              direction='row'
              style={{
                marginBottom: 32,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              {this.renderTitle()}
            </Grid>
            <Grid container spacing={4} direction='column' className={classes.formContainerPlant}>
              <Grid container spacing={4} direction='row'>
                {this.renderInputTextField("name", "Plant Name", "Enter Plant Name", true)}
                {this.renderRegionSelect()}
              </Grid>

              <Grid container style={{ marginTop: 30 }} data-test-id="plantSections">
                <Grid item xs={12} md={6}>
                  <div className={classes.sectionTitle}>{configJSON.addPlantSection}</div>
                </Grid>
                {form.plant_selections_attributes
                .filter((item) => !item._destroy)
                .map((item, index) => (
                  <Grid container direction='column' className={classes.sectionFormContainerPlant}>
                    <Grid container spacing={4} direction='row'>
                      {this.renderArrayInputTextField(
                        "name",
                        "Plant Section",
                        "Enter Plant Section Name",
                        item,
                        true,
                        `name_${index}`,
                        item.id
                      )}
                    </Grid>
                    <Grid container spacing={4} direction='row' style={{ marginTop: 30 }}>
                      {this.renderArrayInputTextField(
                        "capicity_in_kg",
                        "Capacity in Kg",
                        "Enter Capacity in Kg",
                        item,
                        true,
                        `capicity_in_kg_${index}`,
                        item.id
                      )}
                      {this.renderArrayInputTextField(
                        "capicity_in_piece",
                        "Capacity in Pieces",
                        "Enter Capacity in Pieces",
                        item,
                        false,
                        `capicity_in_piece_${index}`,
                        item.id
                      )}
                    </Grid>
                    {index !== 0 && <Button
                      data-test-id={`removePlantBtn_${index}`}
                      className={classes.removeBtn}
                      onClick={() => { this.handleRemovePlantSection(item.id, index) }}
                    >
                      {configJSON.removeBtnText}
                    </Button>}
                  </Grid>
                ))}
              </Grid>

              <Grid container direction='row' style={{ marginTop: 24 }}>
                <div data-test-id="addPlantBtn" onClick={this.handleAddPlantSection} className={classes.buttonWithIcon}>
                  <AddCircleOutlineIcon
                    style={{ color: "#4D4D4D", fontSize: 20, marginRight: 10 }}
                  />
                  {configJSON.addPlantSection}
                </div>
              </Grid>

              <Grid container spacing={4} direction='row' style={{ marginTop: 30 }}>
                {this.renderInputTextField(
                  "total_capicity_in_kg",
                  "Total Plant Capacity in Kg",
                  "Enter Total Capacity in Kg",
                  true
                )}
                {this.renderInputTextField(
                  "total_capicity_in_piece",
                  "Total Plant Capacity in Pieces",
                  "Enter Total Capacity in Pieces",
                  false
                )}
              </Grid>
            </Grid>

            <Grid container direction='row' style={{ marginTop: 48 }}>
              <Box sx={{ display: 'flex', width: '100%', flexDirection: { xs: 'column-reverse', sm: 'unset'}, alignItems: { xs: 'center', sm: 'unset'}} }>
                <div
                  data-test-id={"cancelBtn"}
                  onClick={this.handleCancelClick}
                  className={classes.cancelButton}
                  >
                  {configJSON.cancelText}
                </div>

                <div
                  data-test-id={"submitBtn"}
                  onClick={this.handleOnSubmit}
                  className={classes.submitButton}
                >
                  {buttonText}
                </div>
              </Box>
            </Grid>
          </Grid>
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            open={!!this.state.snackbarOpen}
            autoHideDuration={4000}
            style={{marginTop:'200px'}}
            onClose={this.handleSnackbarClose}
          >
            <Alert severity='success'>{this.state.snackbarMessage}</Alert>
          </Snackbar>
          </Box>
        </PageContainer>
      </>
    );
  // Customizable Area End
  }
}
// Customizable Area Start
const StyledTextInputLabel = styled(FormLabel) ({
  fontFamily: "Montserrat, sans-serif !important",
  marginBottom: "2px",
  fontSize: "16px !important",
  fontWeight: 600,
  "@media only screen and (max-width: 1024px)": {
    fontSize: "13px !important",
    fontWeight: 600,
  }, 
})

const StyledTextInput = styled(TextField) ({
  "@media only screen and (max-width: 1024px)": {
    '& .MuiInputBase-input': {
      fontSize: '12px'
    },
}
})

const StyledSelectRegion = styled(Select) ({
  '& .MuiInputBase-input': {
    fontSize: '16px',
    fontWeight:"400",
    opacity:0.42,
  },
  "@media only screen and (max-width: 1024px)": {
    '& .MuiInputBase-input': {
      fontSize: '12px'
    },
}
})
export default withStyles(useStyles)(PlantAdd);
// Customizable Area End
