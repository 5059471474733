/** @format */
// Customizable Area Start
import React, { Component, ReactNode } from "react";
import { Box, Popover, styled } from "@material-ui/core";
import "./ConfirmationModal.css";
export const configJSON = require("./config");
// Customizable Area End

// Customizable Area Start
interface ExportImportPopoverProps {
    popOverId: string | undefined;
    openAction: boolean;
    popoverOpen: EventTarget & HTMLButtonElement | null;
    popoverClose: Function;
    exportTemplateButtonClick: Function;
    importCsvButtonClick: Function;
    exportCsvButtonClick: Function;
}
// Customizable Area End

// Customizable Area Start
class ExportImportPopover extends Component<ExportImportPopoverProps> {
    render(): ReactNode {
        const {
            popOverId,
            openAction,
            popoverOpen,
            popoverClose,
            exportTemplateButtonClick,
            importCsvButtonClick,
            exportCsvButtonClick
        } = this.props;
        return (
            <StyledPopover
                data-test-id="actionPopup"
                id={popOverId}
                open={openAction}
                anchorEl={popoverOpen}
                onClose={() => { popoverClose() }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <StyledMenu className={'popoverContainer'}>
                    <Box
                        data-test-id='exportTemplate'
                        onClick={() => exportTemplateButtonClick()}
                        className={'popoverButton'}
                    >
                        {configJSON.exportTemplate}
                    </Box>
                    <Box
                        data-test-id='import-csv'
                        onClick={() => importCsvButtonClick()}
                        className={'popoverButton'}
                    >
                        {configJSON.importCSV}
                    </Box>
                    <Box
                        data-test-id='export-csv'
                        onClick={() => exportCsvButtonClick()}
                        className={'popoverButton'}
                    >
                        {configJSON.exportCSV}
                    </Box>
                </StyledMenu>
            </StyledPopover>
        );
    }
}

const StyledPopover = styled(Popover)({
    width: "auto",
    boxShadow: "0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)",
});

const StyledMenu = styled(Box)({
    width: 'auto',
    "& .popoverContainer": {
        paddingTop: "8px",
        paddingBottom: "8px",
        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.03), 0px 8px 32px rgba(0, 0, 0, 0.06)",
        borderRadius: "8px",
    },
    "& .popoverButton": {
        cursor: "pointer",
        width: "172px",
        height: "33px",
        padding: "6px 20px",
        backgroundColor: "#FFFFFF",
        color: "#4D4D4D",
        fontSize: '14px',
        fontWeight: 500,
        display: 'flex',
        alignItems: 'center',
        "&:hover": {
            backgroundColor: "#204B9C",
            color: "#FFFFFF",
        },
    },
});

export default ExportImportPopover;
// Customizable Area End
