// Customizable Area Start
import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
const Icon = require('./closeStore.svg');
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  Select, MenuItem, Grid, TextField,Typography
} from "@material-ui/core";

// Customizable Area End

// Customizable Area Start

interface Props {
  open: boolean;
  handleClose: () => void;
  handleConfirm?: any
  isAlert?: boolean;
  reasonList: any;
}

interface State {
  reason: string;
  closed_reason_comment: string;
}
// Customizable Area End

// Customizable Area Start
const useStyles: any = makeStyles(() => ({
  Modal: {
    width: '574px',
    textAlign: 'center',
    marginTop: '40px',
    marginBottom: '40px',
    paddingLeft: '48px',
    paddingRight: '48px',
  },
  MainWrapper: {
    margin: '8px',
  },
  Typo: {
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: 'normal',
    letterSpacing: '-0.3px',
    textAlign: 'left',
    fontFamily: 'Montserrat',
  },
  Ls: {
    fontFamily: 'Montserrat',
    fontSize: '24px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: 'normal',
    letterSpacing: '-0.3px',
    marginBottom: '24px'
  },
  Span: {
    textAlign: 'center',
    fontSize: '20px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '30px',
    fontFamily: 'Montserrat',
    color: "black",
    marginBottom: "32px"
  },
  inputStyle: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  mainContainer: {
    margin: 48,
    marginTop: 62,
    fontFamily: "Montserrat !important",
  },
  formContainer: {
    marginTop: 32,
    padding: 32,
    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.03), 0px 8px 32px rgba(0, 0, 0, 0.06)",
    borderRadius: "12px",
  },
  inputLabel: {
    marginTop: '12px',
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: "20px",
    alignItems: "center",
    letterSpacing: "-0.3px",
    textAlign: "left"
  },
  inputField: {
    width: "100%",
    height: "56px",
    marginTop: "4px",
    fontFamily: "Montserrat !important",
  },
  errorText: {
    color: "red",
    fontSize: "12px",
    marginTop: "-26px",
  },
  mb3: {
    marginBottom: "30px",
  },
  taL: {
    textAlign: "left"
  },
  commentField: {
    background: '#F8F8F8',
  },
  textarea: {
    resize: "vertical"
  },
  mb64: {
    marginBottom: '64px'
  },
  content: {
    padding: 0,
    overflow: 'unset !important'
  }
}));

// Customizable Area End

// Customizable Area Start
const CloseStoreDialogBox: React.FC<Props> = (props) => {
  const [formData, setFormData] = useState<State>({
    reason: "",
    closed_reason_comment: ""
  });
  const [formErrros, setFormErrros] = useState<State>({
    reason: "",
    closed_reason_comment: ""
  });

  const checkEntries = () => {
    let tempData ={
      reason: formData.reason ? "" : "This field is required",
      closed_reason_comment: formData.closed_reason_comment ? "" : "This field is required",
    }
    setFormErrros({...tempData})
    if(!tempData.reason && !tempData.closed_reason_comment)
      handleConfirm(formData);

  }

  const classes = useStyles();
  const {
    open,
    handleClose,
    handleConfirm,
    isAlert,
    reasonList
  } = props
  return (
    <Dialog
      className="main-dialog-container"
      open={open}
      onClose={handleClose}>
      <Box className={classes.Modal} >
        <Box className={`icon-section ${isAlert ? "error" : "close"} ${classes.mb64}`}>
          <img src={Icon} />
        </Box>
        <div className={classes.Ls}>{`Close Store`}</div>
        <DialogContent className={classes.content}>
          <DialogContentText className={classes.Span}>{`Are you sure want to close the store?`}</DialogContentText>
          <div className={classes.Typo}>
            Select from the following Reasons :
          </div>
          <div>
            <Grid container spacing={2}>

              <Grid item xs={12} className={classes.taL}>
                <div>
                  <div className={classes.inputLabel}>{`Reason`}</div>
                  <Select
                    data-test-id="closeStoreModal"
                  displayEmpty={true}
                  renderValue={(value) => reasonList.find((option: any) => option.label === value)?.label || "Select Reason"}
                  
                    onChange={(e: any) =>
                      setFormData({ ...formData, reason: e.target.value })
                    }
                    className={classes.inputField}
                    variant='outlined'
                  >
                    {reasonList.map((option: any) => (
                      <MenuItem key={option.id} value={option.label}>{option.label}</MenuItem>
                    ))}
                    
                  </Select>
                  {formErrros['reason'] && <Typography className='errorText' variant='caption' style={{ color: "red" }}>{formErrros['reason']}</Typography>}
                </div>
              </Grid>
              <Grid item xs={12} className={classes.taL} style={{ paddingBottom: '20px' }}>
                <TextField
                  className={classes.commentField}
                  inputProps={{ 'data-test-id': 'closeComment', className: classes.textarea }}
                  label="Add Comment"
                  multiline
                  rows={4}
                  onChange={(e) => setFormData({...formData, closed_reason_comment: e.target.value})}
                  variant="outlined"
                  fullWidth
                />
                {formErrros['closed_reason_comment'] && <Typography className='errorMsg2' variant='caption' style={{ color: "red" }}>{formErrros['closed_reason_comment']}</Typography>}
              </Grid>
            </Grid>
          </div>
        </DialogContent>
        <Box className="buttons">
          <Button
            className={`btn-cancel`}
            data-test-id={`btn-cancel`}
            onClick={handleClose}
            color="primary">
            {"Cancel"}
          </Button>
          <Button
            className={`btn-confirm`}
            data-test-id={`btn-confirm`}
            onClick={() => checkEntries()}
            color="primary"
          >
            {`Close`}
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};

export default CloseStoreDialogBox
// Customizable Area End
