// Customizable Area Start
import React, { Fragment } from "react";
import {
  Box,
  styled,
  MenuProps,
  FormControl,
  TablePagination,
  Paper,
  Button,
} from "@material-ui/core";

import {
  TableRow,
  TableCell,
  OrderTable,
  RowContainer,
} from "../../../components/src/OrderTable/src";

import FilterIcon from "@material-ui/icons/FilterList";
import PageContainer from "../../navigationmenu/src/PageContainer.web";
import AutocompleteSelect, {
  optionObj,
} from "../../../components/src/AutocompleteSelect.web";
import { showNotes } from "../../advancedsearch/src/AdvancedSearch.web";

import HomeOrdersController, {
  Props,
  configJSON,
  ICleaningOrder,
} from "./HomeCleaningOrdersController.web";

import { CheckFalse, CheckTrue, circleIcon, printIcon } from "./assets";

import { webStyle, StyledSelect, StyledMenuItem, FilterBtn } from "./Orders.web";
import { BottomContainer } from "../../promocodes/src/PromoCodeCreate.web";
import { CustomSnackbar } from "../../../components/src/customComponents/CustomSnackbar.web";
import FilterPopover from "../../../components/src/FilterPopover";
import {
  CustomTab,
  CustomTabs,
} from "../../../components/src/customComponents/CustomTags.web";
import { colors } from "../../utilities/src/Colors";
import OrderDetails from "./OrderDetails.web";
const IconSortingUp = require("../../../components/src/assets/sort/sort top.png");
const IconSortingNormal = require("../../../components/src/assets/sort/sort.png");
const IconSortingDown = require("../../../components/src/assets/sort/sort down.png");

const Strings = configJSON.ListingPage.Strings;
const NewStrings = configJSON.CleaningOrders;

export const CustomMenuProps: Partial<MenuProps> = {
  PaperProps: {
    style: { maxHeight: 250 },
  },
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left",
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "left",
  },
  getContentAnchorEl: null,
};
// Customizable Area End

export default class HomeOrders extends HomeOrdersController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  renderAfterPermissionComponent() {
    const { storeList, storeId } = this.state;
    return (
      <PermissionContainer>
        <AutocompleteSelect
          placeholder={NewStrings.selectBranch}
          fieldName={"branch"}
          onChange={this.handleBranchChange.bind(this)}
          handleScroll={this.handelStoreDropdownScroll.bind(this)}
          value={storeId}
          options={storeList.map(
            (store: {
              attributes: { id: string | number; store_name: string };
            }) =>
              ({
                id: store.attributes.id,
                option: store.attributes.store_name,
              } as optionObj)
          )}
        />
      </PermissionContainer>
    );
  }

  getOrderDetails(rowData: ICleaningOrder): string | React.ReactNode {
    if (rowData.attributes?.order_items.length === 0) return "--";
    else
      return (
        <div>
          {rowData.attributes?.order_items?.map((item, index: number) => {
            const order = item.attributes;
            const name = order?.catalogue?.name;
            const secondName = order?.catalogue?.product_second_name;
            return (
              <div key={name + index}>
                {name} <img src={circleIcon} alt='circle' /> {secondName} x{" "}
                {order.quantity}
              </div>
            );
          })}
          <div className='pt-12'>
            <div
              data-test-id={`detailpopup${rowData.id}`}
              style={webStyle.detailBtn}
              onClick={()=>this.handleOpenDetailPopup(rowData)}
            >
              {NewStrings.details}
            </div>
          </div>
        </div>
      );
  }

  tabsContainer = () => {
    const { tabId } = this.state;
    return (
      <TabContainer>
        <Paper className={"tabbingSection"}>
          <CustomTabsWrap
            data-test-id='tabGroup'
            value={tabId}
            onChange={(_event: React.ChangeEvent, value: number) => {
              this.handleTabChange(value);
            }}
            variant='fullWidth'
            indicatorColor='primary'
            textColor='primary'
            aria-label='icon label tabs example'
            TabIndicatorProps={{ style: { display: "none" } }}
          >
            <CustomTab className='customTab' label='Orders' />
            <CustomTab
              data-test-id='storeTab'
              className='customTab'
              label='Cleaning'
            />
            <CustomTab className='customTab' label='Completed' />
            <CustomTab className='customTab' label='Cancelled' />
          </CustomTabsWrap>
        </Paper>
      </TabContainer>
    );
  };

  // Customizable Area End

  render() {
    // Customizable Area Start
    const {
      selectedDropdownValue,
      orderMetaData,
      totalCount,
      orderList,
      page,
      selectedOrders,
      orderData
    } = this.state;

    const { currency } = orderMetaData || {};

    return (
      <PageContainer
        navigation={this.props.navigation}
        onUserChange={this.handleUserChange.bind(this)}
        onSearchText={(value) => this.handleSearch(value)}
        afterPermissionComponent={this.renderAfterPermissionComponent()}
      >
        <FixStyleContainer>
          <Box>
            <div className='w-100 sticky py-24'>
              <div className='fix-heading'>
                <div data-test-id='pageTitle' className='pageHeaderOrder'>
                  {NewStrings.homeCleaningOrders}
                </div>
                <div className='fgrow1'>{this.tabsContainer()}</div>
                <div className='mw-180'>
                  <FormControl variant='outlined' style={webStyle.formControl}>
                    <StyledSelect
                      value={selectedDropdownValue}
                      renderValue={() =>
                        this.state.dropDownList.find(
                          (option) => option.value === selectedDropdownValue
                        )?.label || NewStrings.action
                      }
                      displayEmpty
                      variant='outlined'
                      className='rm-weight'
                      data-test-id='actionDropdown'
                      MenuProps={CustomMenuProps}
                      disabled={orderList.length === 0}
                      onChange={(event) => {
                        this.handleDropDownSelect(
                          event as React.ChangeEvent<{ value: string }>
                        );
                      }}
                    >
                      <StyledMenuItem value=''>
                        <em>{NewStrings.action}</em>
                      </StyledMenuItem>
                      {this.state.dropDownList.map(
                        (option) =>
                          option.tabId === this.state.tabId && (
                            <StyledMenuItem value={option.value}>
                              {option.label}
                            </StyledMenuItem>
                          )
                      )}
                    </StyledSelect>
                  </FormControl>
                </div>
                <div style={webStyle.headerButtonPartAccountOrder}>
                  <FilterBtn
                    data-test-id='filterButton'
                    onClick={(event) =>
                      this.handlePopeverOpen(event.currentTarget)
                    }
                  >
                    <FilterIcon />
                  </FilterBtn>
                </div>
                <FilterPopover
                  onClose={this.closeFilterPopOver}
                  anchor={this.state.filterAnchor as HTMLDivElement | undefined}
                  onFilterChange={this.handleFilterChange}
                  filters={this.state.filters}
                  onAutoCompleteChange={(title: string, value: string) =>
                    this.handleAutocompleteFilterChange(title, value)
                  }
                  onClearFilter={() => this.handleClearFilter()}
                />
              </div>
            </div>

            <Box
              style={webStyle.summary}
              sx={{ flexDirection: { xs: "column", sm: "unset" } }}
            >
              <div className={"summaryLabel"}>
                <span>
                  {Strings.orders}{" "}
                  <span data-test-id='orderValue' style={webStyle.summaryValue}>
                    : {orderMetaData?.orders}
                  </span>
                </span>
              </div>
              <div className={"summaryLabel"}>
                <span>
                  {Strings.pieces}{" "}
                  <span style={webStyle.summaryValue}>
                    : {orderMetaData?.pieces || 0}
                  </span>
                </span>
              </div>
              <div className={"summaryLabel"}>
                <span>
                  {Strings.value}{" "}
                  <span style={webStyle.summaryValue}>
                    : {currency} {orderMetaData?.total}
                  </span>
                </span>
              </div>
              <div className={"summaryLabel"}>
                <span>
                  {Strings.unpaidAmount}{" "}
                  <span
                    data-test-id='unpaidValue'
                    style={webStyle.summaryValue}
                  >
                    : {currency} {orderMetaData?.unpaid_amount}
                  </span>
                </span>
              </div>
            </Box>

            <OrderTable minWidth={1280} minWidth1024={887} minWidth1280={1109} minWidth1366={1184}>
              <RowContainer header>
                <TableRow
                  className='table-cols'
                  columns={this.getColumnsDynamicWidth()}
                >
                  {this.renderSelectCheckBox() && (
                    <TableCell
                      header
                      className='pl-0 align-item-center'
                      content={
                        <img
                          data-test-id='selectAllCheckbox'
                          className='cursor-pointer'
                          onClick={() => this.handleSelectAllOrders()}
                          src={
                            this.handleCheckBoxSelection(
                              this.state.orderList.length ===
                                this.state.selectedOrders.length,
                              CheckTrue,
                              CheckFalse
                            ) as string
                          }
                        />
                      }
                    />
                  )}
                  {[
                    {
                      title: NewStrings.table.orderId,
                      sortingKey: "order_number",
                    },
                    {
                      title: NewStrings.table.orderCreated,
                      sortingKey: "created_at",
                    },
                    {
                      title: NewStrings.table.customer,
                      sortingKey: "customer",
                    },
                    {
                      title: NewStrings.table.contact,
                      sortingKey: "full_phone_number",
                    },
                    {
                      title: NewStrings.table.order,
                      sortingKey: "",
                    },
                    {
                      title: NewStrings.table.pcs,
                      sortingKey: "",
                    },
                    {
                      title: NewStrings.table.notes,
                      sortingKey: "",
                    },
                    {
                      title: NewStrings.table.paid,
                      sortingKey: "",
                    },
                    {
                      title: NewStrings.table.total,
                      sortingKey: "total",
                    },
                    {
                      title: NewStrings.table.status,
                      sortingKey: "",
                    },
                  ].map((item: { title: string; sortingKey: string }) => {
                    return (
                      <TableCell
                        header
                        key={item.title}
                        content={item.title}
                        className='align-item-center'
                        sortingIcons={[
                          IconSortingNormal,
                          IconSortingUp,
                          IconSortingDown,
                        ]}
                        sortingKey={item.sortingKey}
                        sortingDisabled={!Boolean(item.sortingKey)}
                        {...this.getSortingProps()}
                      />
                    );
                  })}
                </TableRow>
              </RowContainer>
              <RowContainer>
                {orderList.length === 0 && (
                  <TableRow columns='1fr'>
                    <TableCell
                      type='none'
                      content={
                        <div className='no-records'>No Records Found</div>
                      }
                    />
                  </TableRow>
                )}
                {orderList.map((item, index) => {
                  const {
                    id,
                    total,
                    notes,
                    status,
                    customer,
                    created_at,
                    order_number,
                    no_of_items,
                    order_transactions,
                    internal_status,
                  } = item?.attributes || {};
                  const { full_name, full_phone_number } = customer || {};
                  const payStr =
                    order_transactions
                      ?.map((item) => item?.name || "")
                      .join(", ") || NewStrings.payLater;
                  return (
                    <Fragment key={"order-list-item-" + id}>
                      <TableRow
                        className='table-rows'
                        columns={this.getColumnsDynamicWidth()}
                      >
                        {this.handleCheckBoxSelection(
                          Boolean(this.renderSelectCheckBox()),
                          <TableCell
                            type='none'
                            className='pl-0 align-self-center'
                            content={
                              <img
                                onClick={() =>
                                  this.handleToggleOrderSelection(
                                    item.attributes.id
                                  )
                                }
                                className={"cursor-pointer"}
                                data-test-id={`singleCheckbox${index}`}
                                src={
                                  this.handleCheckBoxSelection(
                                    selectedOrders.includes(item.attributes.id),
                                    CheckTrue,
                                    CheckFalse
                                  ) as string
                                }
                              />
                            }
                          />,
                          <></>
                        )}
                        {[
                          order_number,
                          this.formatCreatedDate(created_at),
                          full_name,
                          `+${full_phone_number}`,
                          this.getOrderDetails(item),
                          no_of_items,
                          showNotes(notes || ""),
                          payStr,
                          `SAR${total}`,
                          this.displayStatusName(internal_status),
                        ].map((heading: unknown, index: number) => (
                          <TableCell
                            content={
                              (
                                <>
                                  {heading}
                                  {index === 8 &&
                                    this.state.tabId === 0 &&
                                    internal_status !==
                                      this.state.acceptedOrderStatus && (
                                      <Button
                                        className='accept-btn'
                                        data-test-id='btn-order-accept'
                                        onClick={() =>
                                          this.handleAcceptedOrder(id)
                                        }
                                      >
                                        {Strings.acceptOrder}
                                      </Button>
                                    )}
                                  {index === 9 && (
                                    <img
                                      alt={"print"}
                                      src={printIcon}
                                      className='print-icon'
                                      data-test-id='btn-print'
                                    />
                                  )}
                                </>
                              ) as React.ReactNode
                            }
                            type={this.getTableCellType(index)}
                          />
                        ))}
                      </TableRow>
                    </Fragment>
                  );
                })}
              </RowContainer>
            </OrderTable>
            <TablePagination
              count={totalCount}
              className='tablePagination'
              page={page - 1}
              data-test-id='pagination'
              rowsPerPageOptions={[10]}
              rowsPerPage={10}
              component='div'
              onPageChange={(
                _event: React.MouseEvent<HTMLElement> | null,
                page: number
              ) => this.handlePageChange(page)}
            />
          </Box>
          {this.renderSelectCheckBox() && (
            <BottomButtonBlock>
              <button
                data-test-id='btn-cancel'
                className='cancel'
                onClick={() => this.handleCancel()}
              >
                {NewStrings.cancel}
              </button>
              <button
                data-test-id='btn-submit'
                className='add'
                onClick={() => this.handleSubmitOrder()}
              >
                {NewStrings.move}
              </button>
            </BottomButtonBlock>
          )}
          {orderData && (
              <OrderDetails
                orderData = {orderData}
                onClose={this.closeOrderDetails.bind(this)}
                isNewOrderDetailsModal={true}
              />
            )}
          <CustomSnackbar
            data-test-id='snakbarTestId'
            open={this.state.snackbarOpen}
            onClose={this.handleSnackbarClose}
            errorMessage={this.state.snakbarMessage}
            severity={this.state.severity}
          />
        </FixStyleContainer>
      </PageContainer>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const PermissionContainer = styled("div")({
  margin: "0 12px",
  minWidth: "200px",
});

const FixStyleContainer = styled("div")({
  "& .detailsBtn": {
    ...webStyle.detailBtn,
    "@media only screen and (max-width: 1024px)": {
      fontSize: "14px"
    }
  },
  "& .summaryLabel": {
    ...webStyle.summaryLabel,
    "@media only screen and (max-width: 1024px)": {
      fontSize: "14px"
    }
  },
  "& .pageHeaderOrder": {
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "24px",
    lineHeight: "29px",
    "@media only screen and (max-width: 1280px)": {
      fontSize: "21px",
    },
    "@media only screen and (max-width: 1024px)": {
      fontSize: "17px"
    }
  },
  "& .py-24": {
    padding: "24px 0",
    "@media only screen and (max-width: 1366px)": {
      padding: "20px 0"
    },
    "@media only screen and (max-width: 1024px)": {
      padding: "16px 0"
    },
  },
  "& .sticky": {
    position: "sticky",
    top: 0,
    background: colors().white,
    zIndex: 200,
  },
  "& .no-records": {
    width: "100%",
    textAlign: "center",
  },
  "& .pt-12": {
    paddingTop: 12,
  },
  "& .w-100": {
    width: "100%",
  },
  "& .static": {
    position: "static",
  },
  "& .fix-main-container": {
    marginLeft: 48,
  },
  "& .fix-heading": {
    alignItems: "center",
    marginLeft: "auto",
    display: "flex",
    flexWrap: "wrap",
    gap: 24,
  },
  "& .fgrow1": {
    marginLeft: "auto",
    textAlign: "center",
  },
  "& .mw-180": {
    marginLeft: "auto",
    minWidth: 180,
    "@media only screen and (max-width: 1280px)": {
      minWidth: 160,
    },
    "@media only screen and (max-width: 1024px)": {
      minWidth: 148,
    }
  },
  "& .table-cols": {},
  "& .table-rows > div:last-child": {
    position: "relative",
  },
  "& .table-rows > div:nth-last-child(2)": {
    position: "relative",
  },
  "& .table-rows > div": {
    fontSize: "12px",
    textAlign: "left",
    minHeight: "168px",
    textTransform: "capitalize",
    fontWeight: "500 !important",
    "@media only screen and (max-width: 1366px)": {
      minHeight: "163px",
    },
    "@media only screen and (max-width: 1280px)": {
      minHeight: "158px",
    },
    "@media only screen and (max-width: 1024px)": {
      minHeight: "148px",
    }
  },
  "& .rm-weight .MuiSelect-root": {
    fontWeight: "normal !important",
  },
  "& .cursor-pointer": {
    cursor: "pointer",
  },
  "& .accept-btn": {
    right: 0,
    bottom: 0,
    width: 70,
    padding: "6px 10px",
    margin: 16,
    cursor: "pointer",
    textAlign: "right",
    position: "absolute",
    backgroundColor: colors().cyancobaltblue + "1A",
    color: colors().cyancobaltblue,
    borderRadius: "5px",
    fontWeight: 600,
    textTransform: "capitalize",
  },
  "& .print-icon": {
    right: 0,
    bottom: 0,
    width: 26,
    margin: 16,
    cursor: "pointer",
    textAlign: "right",
    position: "absolute",
  },
  "& .dropdown-wrapper": {
    width: "100%",
    margin: "16px 0",
  },
  "& .sentToDropdwon": {
    width: "256px !important",
  },
  "& .align-item-center": {
    alignItems: "center",
  },
  "& .tablePagination": {
    overflow: "unset",
  },
});

export const StyledImageCheckbox = styled("img")({
  padding: 0,
  paddingLeft: 9,
  cursor: "pointer",
});

const TabContainer = styled("div")({
  "& .MuiTab-root": {
    minWidth: "unset",
    whiteSpace: "nowrap",
    flexGrow: "unset",
    flexBasis: "unset",
  },
  "& .MuiTabs-flexContainer": {
    gap: 4,
    flexWrap: "wrap",
    justifyContent: "center",
  },
  "& .tabbingSection": {
    backgroundColor: "#f8fafc",
    borderRadius: "40px",
    margin: "0 auto",
    boxShadow: "none",
  },
});

const CustomTabsWrap = styled(CustomTabs)({
  margin: 0,
  "& .customTab": {
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "600",
    margin: "2px",
    "@media only screen and (max-width: 1280px)": {
      fontSize: "14px",
      padding: "0 8px",
      minHeight: "32px",
    },
    "@media only screen and (max-width: 1024px)": {
      fontSize: "12px",
      padding: "0 5px",
      minHeight: "28px"
    }
  },
});

const BottomButtonBlock = styled(BottomContainer)({
  padding: "32px 0",
});

// Customizable Area End
