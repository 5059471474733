import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
export const configJSON = require("./config");
import { IPlant } from "./PlantListController.web";

export enum FormModePlant {
  Create,
  Edit,
}

enum Method {
  GET = "GET",
  POST = "POST",
  PUT = "PUT",
  DELETE = "DELETE",
  PATCH = "PATCH",
}

export interface IFormPlant {
  name: string;
  region_id: number | null;
  total_capicity_in_kg?: number | null;
  total_capicity_in_piece?: number | null;
  plant_selections_attributes: {
    id: any;
    name: string;
    capicity_in_kg: number | null;
    capicity_in_piece: number | null;
    _destroy: boolean;
  }[];
  region_data:{
    id: string | number;
    option:string;
  }
}

export interface IRenderViewPlant {
  total_capicity_in_kg: string;
  total_capicity_in_piece: string;
  name: string;
  region_id: number | null;
}

export interface IRenderArrayViewPlant {
  capicity_in_piece: string;
  capicity_in_kg: string;
  name: string;
}

export interface IRegionListItem {
  id: number;
  name: string;
}
// Customizable Area End

export interface Props {
  // Customizable Area Start
  classes?: any;
  navigation: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  formErrors: { [key: string]: string };
  form: IFormPlant;
  regionList: IRegionListItem[];
  isLoading: boolean;
  isSubmitting: boolean;
  formMode: FormModePlant;
  snackbarOpen: boolean;
  snackbarMessage: string;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class PlantAddController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getRegionListCallId: string = "";
  passwordText: string = "password";
  private formInitialValues: IFormPlant = {
    name: "",
    region_id: null,
    total_capicity_in_kg: null,
    total_capicity_in_piece: null,
    plant_selections_attributes: [
      {
        name: "",
        capicity_in_kg: null,
        capicity_in_piece: null,
        _destroy: false,
        id:null
      },
    ],
    region_data:{
      id: '',
      option:''
    }
  };

  public RequestMessage = {
    GetStates: this.buildRequestMessage(Method.GET),
    CreatePaymentTerms: this.buildRequestMessage(Method.POST),
    CreatePlant: this.buildRequestMessage(Method.POST),
    UpdatePlant: this.buildRequestMessage(Method.PUT),
    GetRegionList: this.buildRequestMessage(Method.GET),
    GetPlantDetail: this.buildRequestMessage(Method.GET),
    Null: undefined as any,
  };
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      formErrors: {},
      form: { ...this.formInitialValues },
      regionList: [],
      isLoading: false,
      isSubmitting: false,
      formMode: FormModePlant.Create,
      snackbarOpen: false,
      snackbarMessage: "",
      // Customizable Area End
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) !== message.id) {
      return;
    }

    const callID = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const response = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

    switch (callID) {
      case this.RequestMessage.GetRegionList.messageId:
        this.handleGetRegionListResponse(response);
        break;

      case this.RequestMessage.GetPlantDetail.messageId:
        this.handleGetPlantDetailResponse(response);
        break;

      case this.RequestMessage.CreatePlant.messageId:
        this.handleCreatePlantResponse(response,"create");
        break;

      case this.RequestMessage.UpdatePlant.messageId:
        this.handleCreatePlantResponse(response,'update');
        break;
    }
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    this.getRegionListCall();

    const path = window.location.pathname;
    const plantId = window.localStorage.getItem("plant_id");
    this.processPath(path, plantId as string);
    // Customizable Area End
  }
  // Customizable Area Start
  private handleGetRegionListResponse(response: {
    data: {
      id: string;
      type: string;
      attributes: { id: number; branch_name: string };
    }[];
  }) {
    if (response && response.data) {
      const regionArray = response.data.map(
        (item: {
          attributes: {
            id: number;
            branch_name: string;
          };
        }) => ({
          id: item.attributes.id,
          name: item.attributes.branch_name,
        })
      );

      this.setState({
        isLoading: false,
        regionList: regionArray || [],
      });
    }
  }

  private handleGetPlantDetailResponse(response: { data: IPlant }) {
    if (response) {
      const {
        data: {
          attributes: {
            name,
            region,
            total_capicity_in_kg,
            total_capicity_in_piece,
            plant_selections,
          },
        },
      } = response;
      const plantDetailFormdata = {
        name,
        region_id: region.id,
        total_capicity_in_kg,
        total_capicity_in_piece,
        plant_selections_attributes: plant_selections.data.map((item) => ({
          name: item.attributes.name,
          capicity_in_kg: item.attributes.capicity_in_kg,
          capicity_in_piece: item.attributes.capicity_in_piece,
          id: item.attributes.id,
          _destroy: false
        })),
        region_data:{
          id: region.id,
          option:region.branch_name
        }
      };

      this.setState({
        form: plantDetailFormdata,
        isLoading: false,
      });
    }
  }

  private handleCreatePlantResponse(response: { data: { id: string }; full_messages: string[] }, apicall: string) {
    if (response && response.data && response.data.id) {
      this.setState({
        isSubmitting: false,
        snackbarOpen: true,
        snackbarMessage: apicall === "update" ? "Data updated successfully" : "Data added successfully",
      }, () => {
        setTimeout(() => {
          this.props.navigation.navigate("PlantList");
        }, 1000); 
      });
    }else {
      this.setState({
        isLoading: false,
        isSubmitting: false,
        snackbarOpen: true,
        snackbarMessage: response && response.full_messages[0],
      });
    }
  }

  processPath = (path: string, plantId: string) => {
    if (path === "/Settings-General-PlantListEdit") {
      this.setState({ formMode: FormModePlant.Edit });
      this.getPlantDetailCall(plantId);
    }
  };
  handleScrollRegionDropdown = (event: React.SyntheticEvent) => {
  
  };
  handleSnackbarClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }

    this.setState({ snackbarOpen: false });
  };

  handleSetRegionList = (regionArray: IRegionListItem[]) => {
    this.setState({
      regionList: regionArray,
    });
  };

  handleRegionId = (regionId: number) => {
    this.setState({
      form: {
        ...this.state.form,
        region_id: regionId,
      },
    });
  };

  handleAddPlantSection = () => {
    this.setState({
      form: {
        ...this.state.form,
        plant_selections_attributes: [
          ...this.state.form.plant_selections_attributes,
          {...this.formInitialValues.plant_selections_attributes[0], id:`new${this.state.form.plant_selections_attributes.length}`},
        ],
      },
    });
  };

  handleRemovePlantSection = (getPlantSectionId: string,index:number) => {
    let udpatedPlant = this.state.form.plant_selections_attributes.map((plants, index) => {
      if (plants.id === getPlantSectionId) {
        return { ...plants, _destroy: true };
      }
      return plants
    });
    if (this.state.form.plant_selections_attributes[Number(index)].name === "" ||
      this.state.form.plant_selections_attributes[Number(index)].capicity_in_kg === null ||
      this.state.form.plant_selections_attributes[Number(index)].capicity_in_piece === null) {
      udpatedPlant = this.state.form.plant_selections_attributes.filter((plants, index) => {
        if (plants.id !== getPlantSectionId) {
          return plants
        }
      })
    }
    let total_capicity_in_kg =0 
    for (const key in udpatedPlant) {
      if(!udpatedPlant[key]._destroy){
        total_capicity_in_kg = total_capicity_in_kg+Number(udpatedPlant[key].capicity_in_kg)
      }
    }
    let total_capicity_in_piece = 0
    for (const key in udpatedPlant) {
      if(!udpatedPlant[key]._destroy){
        total_capicity_in_piece = total_capicity_in_piece+Number(udpatedPlant[key].capicity_in_piece)
      }
    }

    this.setState({
      form: {
        ...this.state.form,
        total_capicity_in_kg ,
        total_capicity_in_piece,
        plant_selections_attributes: udpatedPlant,
      },
    });
  };

  onFormElementChange = (
    event: React.ChangeEvent<{
      name?: string;
      value: unknown;
    }>,
    property: string
  ) => {
    this.setState({
      form: {
        ...this.state.form,
        [property]: event.target.value,
      },
    });
  };

  onArrayFormElementChange = (
    event: React.ChangeEvent<{
      name?: string;
      value: unknown;
    }>,
    property: string,
    itemId?:string|number
  ) => {
    const { form } = this.state;
    const { plant_selections_attributes } = form;
    const updatedPlantSelections = plant_selections_attributes.map((item) => {
       if (itemId === item.id) {
          return { ...item, [property]: event.target.value };
        }
      return item;
    });
    let total_capicity_in_kg =0 
    for (const key in updatedPlantSelections) {
      if(!updatedPlantSelections[key]._destroy){
        total_capicity_in_kg = total_capicity_in_kg+Number(updatedPlantSelections[key].capicity_in_kg)
      }
    }

    let total_capicity_in_piece = 0
    for (const key in updatedPlantSelections) {
      if(!updatedPlantSelections[key]._destroy){
        total_capicity_in_piece = total_capicity_in_piece+Number(updatedPlantSelections[key].capicity_in_piece)
      }
    }

    const updatedForm = {
      ...form,
      total_capicity_in_kg,
      total_capicity_in_piece,
      plant_selections_attributes: updatedPlantSelections,
    };

    this.setState({ form: updatedForm });
  };

  handleCancelClick = () => {
    this.props.navigation.goBack();
  };

  checkEntries = (formErrors: { [key: string]: string }) => {
    const newFormErrors = { ...formErrors };
    Object.entries(this.state.form).forEach(([key, value]) => {
      // not required fields
      if (key === "total_capicity_in_kg" || key === "total_capicity_in_piece") {
        return;
      }

      if (key === "plant_selections_attributes") {
        this.state.form?.plant_selections_attributes?.forEach((item) => {
          Object?.entries(item).forEach(([key, value]) => {
            if (key !== "_destroy" && key !== "id" && !value) {
              newFormErrors[`plant_section_${key}_${item.id}`] =
                configJSON.fieldRequiredValidText;
            } else if (isNaN(Number(value)) && !['id', 'name', '_destroy'].includes(key)) {
              newFormErrors[`plant_section_${key}_${item.id}`] = configJSON.enterValidNumberError
            }
          });
        });
      }

      if (!value || value === undefined || value === "" || value === "null" || value.length === 0) {
        newFormErrors[key] = configJSON.fieldRequiredValidText;
      }
    });

    return newFormErrors;
  };

  handleOnSubmit = () => {
    let formErrors: { [key: string]: string } = {};
    if (!this.state.form || this.state.isSubmitting) {
      return;
    }

    formErrors = this.checkEntries(formErrors);

    if (Object.keys(formErrors).length > 0) {
      this.setState({ formErrors, isSubmitting: false });
      return;
    }

    const { form } = this.state;
    delete form.total_capicity_in_kg;
    delete form.total_capicity_in_piece;
    this.setState({isLoading: true})
    if (window.location.pathname === "/Settings-General-PlantListEdit") {
      this.updatePlantCall(form, window.localStorage.getItem("plant_id") as string);
    } else {
      this.createPlantCall(form);
    }
  };

  fillFormErrors = () => {
    this.setState({
      formErrors: {
        name: "error",
        capicity_in_kg: "error",
        capicity_in_piece: "error",
        region_id: "error",
        total_capicity_in_kg: "error",
        total_capicity_in_piece: "error",
      },
    });
  };

  changeFormMode = (formMode: FormModePlant) => {
    this.setState({
      formMode,
    });
  };

  createPlantCall = (form: IFormPlant) => {
    this.setState({ isSubmitting: true, formErrors: {} });
    form.plant_selections_attributes.forEach((item) => {
      if(item.id === null || item.id.toString().slice(0,3) === "new" ) {
        delete item.id
      }
      return item
    })
    this.RequestMessage.CreatePlant.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(form)
    );

    runEngine.sendMessage(this.RequestMessage.CreatePlant.id, this.RequestMessage.CreatePlant);
  };

  updatePlantCall = (form: IFormPlant, plant_id: string) => {
    this.setState({ isSubmitting: true, formErrors: {} });
    form.plant_selections_attributes.forEach((item) => {
      if(item.id.toString().slice(0,3) === "new") {
        delete item.id
      }
      return item
    })

    this.RequestMessage.UpdatePlant.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.plantDetailEndPoint}/${plant_id}`
    );

    this.RequestMessage.UpdatePlant.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(form)
    );

    runEngine.sendMessage(this.RequestMessage.UpdatePlant.id, this.RequestMessage.UpdatePlant);
  };

  getRegionListCall = () => {
    this.setState({ isLoading: true });

    this.RequestMessage.GetRegionList.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.regionListApiEndPoint+'?dropdown=true'
    );

    this.getRegionListCallId = this.RequestMessage.GetRegionList.messageId;

    runEngine.sendMessage(this.RequestMessage.GetRegionList.id, this.RequestMessage.GetRegionList);
  };

  getPlantDetailCall = (plant_id: string) => {
    this.setState({ isLoading: true });

    this.RequestMessage.GetPlantDetail.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.plantDetailEndPoint}/${plant_id}`
    );

    runEngine.sendMessage(
      this.RequestMessage.GetPlantDetail.id,
      this.RequestMessage.GetPlantDetail
    );
  };

  private buildRequestMessage(method: Method): Message {
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.plantDetailEndPoint
    );
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMessage), configJSON.APIBaseURL);
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      this.getHeaderMessage()
    );
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), method.toString());

    return requestMessage;
  }

  getHeaderMessage() {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: window.localStorage.getItem(configJSON.token),
    };

    return JSON.stringify(header);
  }
  // Customizable Area End
}
