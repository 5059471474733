// Customizable Area Start
import React from "react"
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { IFilter } from "../../../components/src/FilterPopover";
import { checkedIcon, unCheckedIcon } from "./assets"
import ImportExportWebAdapter from "../../adapters/src/ImportExportWebAdapter";
import { getCustomEnumName, CustomEnums, randomNumberGenerator, navigateTo, checkForImportExportPermissionStatus } from "../../utilities/src/CustomBlockHelpers"
import { apiCall, makeApiMessage } from "../../../components/src/common";
import { IImportCSVResponse } from "../../importexportdata/src/ImportExportData.web";
import { ISortingData } from "../../../components/src/SortingTableHeader2";
import { IUserContext } from "../../../blocks/navigationmenu/src/PageContainerController.web";
import {
  PermissionStatus,
  checkForNewPermissonStatus,
  customPermissionApiKey
} from "../../../blocks/utilities/src/CustomBlockHelpers";
import { PermissionGroupArray } from "../../../blocks/navigationmenu/src/utils";
// Customizable Area End
export const configJSON = require("./config");

export interface Props {
  navigation?: any;
  id?: string;
  // Customizable Area Start

  // Customizable Area End
}

interface S {
  // Customizable Area Start
  specificationList: SpecificationData[]
  openModalId: number | null;
  open: boolean;
  query: string;
  deactivateModal: boolean;
  deActivatedList: {
    id: string;
    title: string
  }
  filterAnchor: HTMLDivElement | boolean | undefined;
  filters: IFilter[];
  sectionOptions: {
    id: number
    label: string
    active: boolean;
    selected?: boolean
  }[];
  nameSorting: {
    value: string
  },
  productSorting: {
    value: string
  },
  meta: {
    next_page: number;
    pervious_page: number;
    total_pages: number;
    total_count: number;
    current_page: number
  };
  isLoading: boolean;
  selectedOption:string;
  expanded:boolean;
  isDropdown:boolean;
  openModal:boolean;
  openSpecificationImpExtPopoverAction: EventTarget & HTMLButtonElement | null;
  setLoaded: number;
  uploadedFile: File | null;
  snakcbarSeverity: "error" | "warning" | "info" | "success";
  errorSnackbarOpen: boolean;
  errorMessage: string;
  sortingData: ISortingData;
  openAction: (EventTarget & HTMLButtonElement) | null;
  currentPopver: undefined | SpecificationData;
  sortingQuery: string;
  permissionStatus: PermissionStatus;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: string | number;

  // Customizable Area End
}
// Customizable Area Start
const ApiUrls = configJSON.ApiUrls;
export interface Section {
  data: {
    id: number,
    attributes: {
      id: number,
      section_name: string,
      active: boolean
    }
  }[]
}

export interface Service {
  data: {
    id: string,
    attributes: {
      id: number,
      name: string,
      active: boolean
    }
  }[]
}

export interface Product {
  data: {
    id: string,
    attributes: {
      id: number,
      product_name: string,
      active: boolean
    }
  }[]
}
export interface SpecificationData {
  id: number
  attributes: {
    name: string
    second_name: string
    active: boolean
    options: {
      data: {
        attributes: {
          id?: string;
          label: string;
          _destroy?: boolean
        }
      }
    }[]
    sections: Section
    services: Service
    products: Product
  },

} 
// Customizable Area End


export default class SpecificationListController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getSpecificationCallId: string = "";
  deActivateSpecificationCallId: string = "";
  getsectionListCallId: string = "";
  adapter: ImportExportWebAdapter;
  exportSpecificationFileApiId: string = "";
  exportSpecificationTempFileApiId: string = "";
  importSpecificationFileApiId: string = "";
  activateProductApiCallId: string = "";

  handleQueryChange = (query: string) => {
    this.setState({ sortingQuery: query }, () => this.getAllSpecification(1, ""));
  };

  sortingProps = {
    onQueryChange: (query: string) => this.handleQueryChange(query),
    onChange: (sortingData: ISortingData) => this.setState({ sortingData }),
  };
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
      getCustomEnumName(CustomEnums.ImportExportPopupFileUploadMessage),
      getCustomEnumName(CustomEnums.ImportExportPopupDoneMessage),
      getCustomEnumName(CustomEnums.ImportExportPopupCloseButtonClicked),
      getCustomEnumName(CustomEnums.ImportExportClearFileMessage),
      getCustomEnumName(CustomEnums.ImportExportPopupClose),
      getCustomEnumName(CustomEnums.ImportExportErrorPopupGoBack),
    ];
    this.adapter = new ImportExportWebAdapter();
    this.state = {
      specificationList: [],
      openModalId: null,
      open: false,
      deactivateModal: false,
      query: "",
      nameSorting: {
        value: ""
      },
      productSorting: {
        value: ""
      },
      deActivatedList: {
        id: "",
        title: ""
      },
      meta: {
        next_page: 1,
        pervious_page: 1,
        total_pages: 1,
        total_count: 1,
        current_page: 1
      },
      sectionOptions: [],
      filterAnchor: false,
      filters: [
        {
          title: "Specification Name",
          type: "text",
          value: "",
        },
        {
          title: "Section",
          type: "custom",
          value: "",
          onClear: () => {
            const filterValue = this.state.filters.find((item) => item.title === "Section");
            filterValue!.value = JSON.stringify({});

            this.setState({
              filters: [...this.state.filters],
              sectionOptions: this.state.sectionOptions.map((option) => ({ ...option, selected: false })

              )
            });

          },
          customRender: () => {
            const filterData = this.state.filters.find((item) => item.title === "Section");
            const value = JSON.parse((filterData?.value as string) || "{}");
            return (
              <div className="section-option-group">
                {this.state.sectionOptions?.map((option) => {
                  let imageSrc = unCheckedIcon
                  value?.checkedIds?.forEach((checkedId: number) => {
                    if (option.id === checkedId) {
                      imageSrc = checkedIcon
                    }
                  })
                  return (
                    <label className="label-option checkbox" key={option.id} onClick={() => this.handleCheckboxChange(option.id)}>
                      <img
                        src={imageSrc}
                        alt={option.label}
                        width={20}
                        height={20}
                      />
                      {option.label}
                    </label>
                  )


                }

                )}
              </div>
            );
          },
        },
        {
          title: "Product",
          type: "text",
          value: "",
        },
      ],
      isLoading: false,
      selectedOption:"",
      expanded:false,
      isDropdown:false,
      openModal:false,
      openSpecificationImpExtPopoverAction: null,
      uploadedFile: null,
      setLoaded: 0,
      snakcbarSeverity: "error",
      errorSnackbarOpen: false,
      errorMessage: '',
      sortingData: {
        name: "",
        section_name: "",
        service_name: "",
        product_name: "",
      },
      openAction: null,
      currentPopver: undefined,
      sortingQuery: '',
      permissionStatus: {
        mainPermission: false,
        createPermission: false,
        viewPermission: false,
        editPermission: false,
        deactivatePermission: false,
        activatePermission: false,
        exportPermission: false
      }
    };
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  componentDidMount(): any {
    // Customizable Area Start
    this.getAllSpecification(1, "");
    // Customizable Area End
  }
  // Customizable Area Start
  onChangeValue = (value: string) => {
    this.setState({
      query: value,
      meta: { ...this.state.meta, current_page: 1 }
    },()=>this.getAllSpecification(this.state.meta.current_page, this.state.query))
  };

  onIconClickHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
    this.setState({ openSpecificationImpExtPopoverAction: event.currentTarget })
  }

  onFilterClickHandler = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    this.setState({ filterAnchor: event.currentTarget, }, () => { if (this.state.sectionOptions.length === 0) this.getSectionListItem() });
  }

  handleSection = (item: Section) => {
    return item.data.map((item) => item.attributes.section_name)
  }

  handleService = (item: Service) => {
    return item.data.map((item) => item.attributes.name)
  }

  handleProduct = (item: Product) => {
    return item.data.map((item) => item.attributes.product_name)
  }

  handleClickMoreIcon = (modalId: number) => {
    this.setState((prevState) => ({
      openModalId: prevState.openModalId === modalId ? null : modalId,
    }));
  };

  handleClosePopOver =() => {
    this.setState({ filterAnchor: undefined });
  };

  handleCheckboxChange = (optionId: number) => {
    const filter = this.state.filters.find((item) => item.title === "Section");
    const value = JSON.parse((filter?.value as string) || "{}");
    const updatedOptions = this.state.sectionOptions.map((option) =>
      option.id === optionId ? { ...option, selected: !option.selected } : option
    );
    const updatedCheckedIds = updatedOptions.filter((option) => option.selected).map((option) => option.id);
    filter!.value = JSON.stringify({
      ...value,
      checkedIds: updatedCheckedIds,
    });
    this.setState((prevState) => {
      const { sectionOptions } = prevState;
      const updatedOptions = sectionOptions.map((option) =>
        option.id === optionId ? { ...option, selected: !option.selected } : option
      );
      return {
        sectionOptions: updatedOptions,

      };

    });

  };

  handleAddSpecification = () => {
    this.props.navigation.history.push("/Products-SpecificationListAdd")
  };

  previousPage = () => {
    this.state.meta.pervious_page
      && this.getAllSpecification(this.state.meta.pervious_page,"",)
  };

  nextPage = () => {
    this.state.meta.next_page
      && this.getAllSpecification(this.state.meta.next_page,"",)
  };

  handleCloseModal = () => {
    this.setState({
      open: false
    })
  };

  handleDeActivateClick = () => {
    this.handleDeActivateSpecification(this.state.deActivatedList)
    this.setState({
      open: false,
      deactivateModal: true
    })
  };

  handleOpenDeactivateModal = (item: SpecificationData) => {
    if(item.attributes.active) {
      const result = {
        id: String(item.id),
        title: item.attributes.name
      };
      this.setState({
        deActivatedList: result,
        open: true
      })
    } else {
      this.handleActivateProduct()
    }
  };

  handleGoBackToListing = () => {
    this.setState({
      deactivateModal: false
    })
    this.handleCloseMenuPopver();
  };

  handleFilterChange = (filters: IFilter[]) => {
    this.setState({ filters }, () => {
      this.getAllSpecification(1,this.state.query)
    });
  };

  handleViewSpecification = (item: SpecificationData) => {
    navigateTo({
      id: String(item.id),
      props: this.props,
      screenName: "ViewSpecification",
    });
  };

  handleEditSpecification = (item: SpecificationData) => {
    navigateTo({
      id: String(item.id),
      props: this.props,
      screenName: "EditSpecification",
    });
  };

  getAllSpecification = (
    pageNumber: number,
    query: string
    ) => {
    this.setState({isLoading: true})
    const headers = {
      "Content-Type": configJSON.categoryApiContentType,
      token: window.localStorage.getItem(configJSON.token)
    };
    const nameFilter = this.state.filters.find((item) => item.title === "Specification Name")?.value;
    const productFilter = this.state.filters.find((item) => item.title === "Product")?.value;
    const sectionFilter = this.state.filters.find((item) => item.title === "Section")?.value;
    const checkedIdsFilter = JSON.parse((sectionFilter as string) || "{}")?.checkedIds;
    const numbersAsNumbers = checkedIdsFilter?.map(Number);
    const paramName = "&filter_by[section_ids]";
    const params = numbersAsNumbers?.map((item: number[]) => `${paramName}[]=${item}`).join("");
    const apiUrl = configJSON.specificationApiEndPoint + "?page_no=" + (pageNumber) +
      (nameFilter ? `&filter_by[specification_name]=${nameFilter}` : "") +
      (productFilter ? `&filter_by[product_name]=${productFilter}` : "") +
      (checkedIdsFilter ? `${params}` : "") + (query ? `&filter_by[query]=${query}` : "") +
      this.state.sortingQuery
    const specificationListMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getSpecificationCallId = specificationListMessage.messageId;

    specificationListMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiUrl
    );

    specificationListMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    specificationListMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetType
    );

    runEngine.sendMessage(specificationListMessage.id, specificationListMessage);
  };

  getSectionListItem = () => {
    const headers = {
      "Content-Type": configJSON.categoryApiContentType,
      token: window.localStorage.getItem(configJSON.token)
    };

    const listMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getsectionListCallId = listMessage.messageId;

    listMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.sectionDropDownListEndPoint
    );

    listMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    listMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetType
    );

    runEngine.sendMessage(listMessage.id, listMessage);
  };

  handleDeActivateSpecification = (item: { id: string, title: string}) => {

    let headers = {
      "Content-Type": configJSON.categoryApiContentType,
      token: window.localStorage.getItem(configJSON.token)
    };
    let httpBody = {}
    httpBody = {
      data: {
        attributes: {
          active: false
        }
      }

    }
    const deactivateDataMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deActivateSpecificationCallId = deactivateDataMessage.messageId;

    deactivateDataMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.createSpecificationApiEndPoint}/${item.id}/${configJSON.deActiveText}`
    );

    deactivateDataMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    deactivateDataMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    deactivateDataMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpUpdateMethodType
    );

    runEngine.sendMessage(deactivateDataMessage.id, deactivateDataMessage);

  };

  handleResponseForGetAllSpecification = (from: string, message: Message) => {
    if (this.getSpecificationCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiResponse && !apiResponse.error) {
        this.setState({
          specificationList: apiResponse.data,
          meta: apiResponse.meta,
          isLoading: false
        })
      } else if(apiResponse.status == 500) {
        this.setState({
          specificationList: [],
          meta: {
            next_page: 1,
            pervious_page: 1,
            total_pages: 1,
            total_count: 1,
            current_page: 1
          },
          isLoading: false,
          errorSnackbarOpen: true,
          errorMessage: "Internal server error",
          snakcbarSeverity: 'error'
        })
      }
    }
  };

  handleResponseForDeactivateSpecification = (from: string, message: Message) => {
    if (this.deActivateSpecificationCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiResponse) {
        const updatedFeedData = this.state.specificationList.map((object: SpecificationData) => {

          if (object.id == apiResponse.data.id) {
            object.attributes.active = false;
          }
          return object;
        })
        this.setState({
          specificationList: updatedFeedData,
          openModalId: null,
        })
      }

    }
  };

  handleResponseForSectionListData = (from: string, message: Message) => {
    if (this.getsectionListCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiResponse) {
        this.setState({
          sectionOptions: apiResponse.data?.map((item: { id: number; attributes: { section_name: string; active: boolean; }; }) => {
            return {
              id: item.id,
              label: item.attributes.section_name,
              active: item.attributes.active
            }
          })
        })
      }
    }
  };

  handleExpandToggle = () => {
    this.setState((prevState) => ({
      expanded: !prevState.expanded,
      isDropdown:!prevState.isDropdown
    }));
  };

  handleCloseImportExportPopover = () => {
    this.setState({ openSpecificationImpExtPopoverAction: null })
  };

  navigateToImportModalScreen = () => {
    this.setState({ openSpecificationImpExtPopoverAction: null });
    let message = new Message(getCustomEnumName(CustomEnums.ImportExportPopupMeassage))
    message.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props,
    )
    this.send(message)
  };

  handleExportCsvFile = () => {
    this.setState({ openSpecificationImpExtPopoverAction: null });

    const apiUrl = configJSON.exportSpecificationCSVApiUrl;

    const requestMessage = makeApiMessage({
      url: apiUrl,
      method: configJSON.httpGetType,
    });

    this.exportSpecificationFileApiId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
 
  handleSpecificationExportTemplate = () => {
    this.setState({ openSpecificationImpExtPopoverAction: null });

    const apiUrl = configJSON.exportSpecificationCSVTemplateApiUrl;

    const requestMessage = makeApiMessage({
      url: apiUrl,
      method: configJSON.httpGetType,
    });

    this.exportSpecificationTempFileApiId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleImportExportModalClose = () => {
    const message = new Message(getCustomEnumName(CustomEnums.ImportExportPopupClose))
    message.addData('ParentpageRoute', 'SpecificationList')
    runEngine.sendMessage(message.id, message)
  };

  exportCSVfileRes = (apiRequestCallId:string, responseJson: { data: { url: string }}) => {
    if(apiRequestCallId === this.exportSpecificationFileApiId) {
      if (responseJson) {
        location.href = responseJson.data.url
        this.setState({ 
          snakcbarSeverity: 'success', 
          errorSnackbarOpen: true, 
          errorMessage: configJSON.successExportMsg
        });
      }
    }
  };

  exportSpecificationTempCSVfileRes = (apiRequestCallId:string, responseJson: { url: string }) => {
    if(apiRequestCallId === this.exportSpecificationTempFileApiId) {
      if (responseJson) {
        location.href = responseJson.url
        this.setState({ 
          snakcbarSeverity: 'success', 
          errorSnackbarOpen: true, 
          errorMessage: configJSON.successExportMsg
        });
      }
    }
  };

  handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ setLoaded: 0 })
    const file = event.target.files;

    let randomNumber = randomNumberGenerator(1, 9);
    const delay = randomNumber * 25;
    const uploadInterval = setInterval(() => {
      this.setState({
        setLoaded: updateSpecificationLoadingTime(this.state.setLoaded)
      }, () => {
        const message = new Message(getCustomEnumName(CustomEnums.ImportExportPopupFileMessage))
        message.addData('returnValue', { setLoaded: this.state.setLoaded, file: file && file[0] })
        runEngine.sendMessage(message.id, message)
      })

    }, delay);

    // for adding 20 percent every time
    function updateSpecificationLoadingTime(prevLoaded: number) {
      if (prevLoaded >= 100) {
        clearInterval(uploadInterval);
        return 100;
      }
      return prevLoaded + 20
    }

    const checkSpecificationFile = file && file[0];
    this.setState({ uploadedFile: checkSpecificationFile as File})
  };

  handleImportFile = () => {
    const apiUrl = configJSON.importSpecificationCSVApiUrl;
    
    const formData = new FormData();
    formData.append('data[file]', this.state.uploadedFile as File);

    const requestMessage = makeApiMessage({
      url: apiUrl,
      method: configJSON.httpPostType,
      body: formData,
      isHeader: true
    });

    this.importSpecificationFileApiId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);    
  };

  handleImportCsvFileResponse(apiRequestCallId:string, response: IImportCSVResponse) {
    if(apiRequestCallId === this.importSpecificationFileApiId) {
      if (response) {
        const message = new Message(getCustomEnumName(CustomEnums.ImportExportAPIResponse))
        message.addData('APIresponse', response)
        runEngine.sendMessage(message.id, message);
        if (response?.message) {
          this.setState({ 
            snakcbarSeverity: 'success', 
            errorSnackbarOpen: true, 
            errorMessage: response.message 
          })
          navigateTo({ props: this.props, screenName: "SpecificationList" })
          this.getAllSpecification(1, "")
        } else if (response?.status == 500) {
          this.setState({
            snakcbarSeverity: 'error',
            errorSnackbarOpen: true,
            errorMessage: response?.error || ''
          });
          navigateTo({ props: this.props, screenName: "SpecificationList" })
        } else {
          const specificationmessage = new Message(getCustomEnumName(CustomEnums.ImportExportErrorPopupData))
          specificationmessage.addData('APIresponse', response);
          runEngine.sendMessage(specificationmessage.id, specificationmessage);
          navigateTo({ 
            props: this.props,
            screenName: "ImportExportErrorModal",
            raiseMessage: specificationmessage
          })
        }
      }
    }
  };

  handleBackToListPage = () => {
    navigateTo({ props: this.props, screenName: "SpecificationList" })
  };

  handleSnackbarClose = () => {
    this.setState({errorSnackbarOpen: false})
  };

  handlepopver = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    currentPopver: SpecificationData
  ) => {
    this.setState({
      openAction: event.currentTarget,
      currentPopver: currentPopver,
    });
  };

  handleCloseMenuPopver = () => {
    this.setState({ openAction: null });
  };

  handleActivateProduct = () => {
    const prodId = this.state.currentPopver?.id;
    const deactiveUrl = ApiUrls.productActivateAPIUrl + `/${prodId}` + '/activate';
    let headers = {
      token: localStorage.getItem(configJSON.token),
      "Content-Type": configJSON.categoryApiContentType,
    };
    const activateProduct = apiCall({
      httpBody: {},
      header: headers,
      url: deactiveUrl,
      httpMethod: configJSON.httpUpdateMethodType,
    });
    this.activateProductApiCallId = activateProduct.messageId;
    runEngine.sendMessage(activateProduct.id, activateProduct);
  };

  handleActivateProductResponse(apiRequestCallId:string, response: { data: SpecificationData }) {
    if(apiRequestCallId === this.activateProductApiCallId) {
      if (response) {
        this.handleCloseMenuPopver();
        const updatedFeedData = this.state.specificationList.map((object: SpecificationData) => {
          if (object.id == response.data.id) {
            object.attributes.active = true;
          }
          return object;
        });
        this.setState({
          specificationList: updatedFeedData,
        })
      }
    }
  };

  handleUserChange = (userContext: IUserContext) => {
    const apiKey = customPermissionApiKey.specificationMPermission;
    const userData = userContext.user?.attributes.permission_groups;
    const value = checkForNewPermissonStatus(apiKey, userData as Array<PermissionGroupArray>);
    
    const apiKeyImportExport = customPermissionApiKey.dataImportExportPermission;
    const valueImportExportPermission = checkForImportExportPermissionStatus(apiKeyImportExport, userData as Array<PermissionGroupArray>);
    
    this.setState({
      permissionStatus: {
        ...value,
        exportPermission: valueImportExportPermission.exportPermission
      }
    })
  };
  
  // Customizable Area End
  async receive(from: string, message: Message) {
    // Customizable Area Start

    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if(getCustomEnumName(CustomEnums.ImportExportErrorPopupGoBack) === message.id) {
      this.handleBackToListPage();
    }

    if (from === getCustomEnumName(CustomEnums.ImportExportPopupCloseButtonClicked)) {
      this.handleImportExportModalClose();
    };

    if (from === getCustomEnumName(CustomEnums.ImportExportPopupDoneMessage)) {
      this.handleImportFile();
    };
    
    if (from === getCustomEnumName(CustomEnums.ImportExportPopupFileUploadMessage)) {
      this.handleFileUpload(message.properties.fileEvent);
    };

    if (from === getCustomEnumName(CustomEnums.ImportExportClearFileMessage)) {
      this.setState({ 
        uploadedFile: message.properties.uploadedFile,
        setLoaded: message.properties.setLoaded 
      });
    };

    this.handleResponseForGetAllSpecification(from, message)
    this.handleResponseForDeactivateSpecification(from, message)
    this.handleResponseForSectionListData(from, message);
    this.exportCSVfileRes(apiRequestCallId, responseJson);
    this.exportSpecificationTempCSVfileRes(apiRequestCallId, responseJson);
    this.handleImportCsvFileResponse(apiRequestCallId, responseJson);
    this.handleActivateProductResponse(apiRequestCallId, responseJson);
    // Customizable Area End
  }

}
