import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { ICategory, ISubCategory } from "./utils";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  selectedCategoryId: string | null;
  categoriesData: Array<ICategory>;
  subCategoriesData: Array<ISubCategory>;
  loading: boolean;
  error: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ItemGrouperController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiGetCategoriesData = "";
  apiGetSubCategoriesData = "";

  async componentDidMount() {
    this.getCategoryData();
  }

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      selectedCategoryId: null,
      categoriesData: [],
      subCategoriesData: [],
      loading: false,
      error: false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (responseJson && responseJson.errors) {
        this.setState({ error: true });
      } else if (responseJson) {
       switch(apiRequestCallId) {
        case this.apiGetCategoriesData:
          this.setState({
            categoriesData: responseJson.data,
            error: false,
            loading: false,
          });
          if (!this.state.selectedCategoryId) {
            this.doCategoryClicked(responseJson.data[0].id);
          }
          break;
        case this.apiGetSubCategoriesData:
          this.setState({
            subCategoriesData: responseJson.data,
            error: false,
            loading: false,
          });
          break;
      }      
      } else if (errorReponse) {
        this.setState({ error: true });
      }
    }
    // Customizable Area End
  }

  doCategoryClicked = (selectedCategoryId: string) => {
    this.setState({
      selectedCategoryId,
    });
    this.getSubCategoriesData(selectedCategoryId);
  };

  // Customizable Area Start
  getCategoryData(): boolean {
    const header = {
      "Content-Type": configJSON.categoriesContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.categoriesEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.categoriesApiMethodType
    );
    runEngine.debugLog(requestMessage);

    this.apiGetCategoriesData = requestMessage.messageId;

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  getSubCategoriesData(selectedCategoryId: string): boolean {
    const header = {
      "Content-Type": configJSON.subCategoriesContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.subCategoriesEndPoint + "/" + selectedCategoryId
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.subCategoriesApiMethodType
    );
    runEngine.debugLog(requestMessage);

    this.apiGetSubCategoriesData = requestMessage.messageId;

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  // Customizable Area End
}
