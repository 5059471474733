import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  time: string;
  timerRunning: boolean;
  emailAddress: string;
  mobileNumber: string;
  errorEmailAddress: string;
  errormobileNumber: string;
  buttonActivate: boolean;
  pwd: string;
  confirmPassword: string;
  errorPassword: string;
  errorConfirmPassword: string;
  passwordType: string;
  passwordConfirmType: string;
  passwordButton: boolean;
  passworConfirmButton: boolean;
  errors: {[key: string]: string};
  loadingSignIn: boolean;
  snackbarOpen: boolean
  snackbarMessage: string;
  imgLogo: string;
  dynamictext: string;
  language: string;
  enablePasswordField: boolean;
  enableConfirmPasswordField: boolean;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class ForgotPasswordController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiforgetpassword: string = "";
  updateNewPasswordCallId: string = "";
  apiDynamicLogo: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
    ];

    this.state = {
      time: "00:00",
      timerRunning: false,
      emailAddress: "",
      mobileNumber: "",
      errorEmailAddress: "",
      errormobileNumber: "",
      buttonActivate: false,
      pwd: "",
      confirmPassword: "",
      errorPassword: "",
      errorConfirmPassword: "",
      passwordType: "",
      passwordConfirmType: "",
      passwordButton: false,
      passworConfirmButton: false,
      errors: {},
      loadingSignIn: false,
      snackbarOpen: false,
      snackbarMessage: "",
      imgLogo: "",
      dynamictext: "",
      language: "choose",
      enablePasswordField: true,
      enableConfirmPasswordField: true


    };
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start
    this.DynamicLogoCall()
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const messageId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    const apiResponse = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (this.apiforgetpassword === messageId) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if(apiResponse?.message){
        this.props.navigation.navigate("EmailSucess")
      }
      else if (apiResponse?.data) {
          this.props.navigation.history.push("Otp", { state: {...apiResponse.data,...apiResponse.meta} })
      }
      else {
        this.setState({ loadingSignIn: false })

        this.setState({ snackbarOpen: true, snackbarMessage: apiResponse?.errors[0]?.message });
      }
    }
    else if (this.apiDynamicLogo === messageId) {
      if (apiResponse?.data) {
        this.setState({ imgLogo: apiResponse?.data[0]?.attributes?.icon, dynamictext: apiResponse?.data[0]?.attributes?.value })

      }
    }
    else if (this.updateNewPasswordCallId === messageId) {
      if (apiResponse?.data) {
        this.props.navigation.navigate("EmailAccountLoginPage")
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start

  ///forgrtpassowrd post api start///
  postForgetpasswordCall = () => {
    const { emailAddress, mobileNumber } = this.state;
    const header = {
      "Content-Type": configJSON.forgotPasswordAPiContentType,
    };
    const httpBody = {
      data: {
        attributes: {
          ...(emailAddress === "" ? { full_phone_number: mobileNumber } : { email: emailAddress })
        },
      }
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiforgetpassword = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_forgot_password/send_otp"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  updateNewPasswordCall = () => {
    const header = {
      "Content-Type": configJSON.forgotPasswordAPiContentType,
    };

    const httpBody = {
      data: {
        attributes:{
          token:this.props.navigation?.history?.location?.state?.token || window.location.href.split("=")[1],
          new_password:this.state.pwd,
          new_confirm_password:this.state.pwd
        }
      }
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.updateNewPasswordCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.updateNewPasswordCall
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

  }
  ///forgetpassword post api end///
  /// dynamic logo start///
  DynamicLogoCall = () => {

    const header = {
      "Content-Type": configJSON.forgotPasswordAPiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiDynamicLogo = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.dynamicLogoCall
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

  }
  /// dynamic logo end///

  handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    this.setState({
                [name]: value,
      } as unknown as S);
      };



  hideError = (event: React.FocusEvent<HTMLInputElement>) => {

    this.state.errors[event.target.name] = '';
    this.setState({
      errors: this.state.errors
    })
  }
  hideErrorone = (event: React.FocusEvent<HTMLInputElement>) => {

    this.state.errors[event.target.name] = '';
    this.setState({
      errors: this.state.errors
    })
  }
  formValidateConfirmPassword = () => {
    const errorMsg1="Password cannot be blank"
    const errorMsg2="Min 8 characters with atleast one uppercase, one digit and one special character "

    const regularExpression = /^(?=.*[A-Z])(?=.*\d)(?=.*\W)[A-Za-z\d\W]{8,}$/;
    let isFormValid = true;

    if (this.state.pwd === '') {
      isFormValid = false;
      this.state.errors['pwd'] = errorMsg1
    }
    else if (!regularExpression.test(this.state.pwd)) {
      this.state.errors['pwd'] = errorMsg2
      isFormValid = false
    }
    if (this.state.confirmPassword === '') {
      isFormValid = false;
      this.state.errors['confirmPassword'] = errorMsg1
    }
    if (this.state.pwd != this.state.confirmPassword) {
      isFormValid = false;
      this.state.errors['confirmPassword'] = configJSON.passwordsMustMatch
    }

    return isFormValid
  }

  formValidateForgetPassword = () => {
    const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    const regexPhone = /^\d{10,12}$/;
    let isFormValid = true;
    if (this.state.mobileNumber == "") {
      if (this.state.emailAddress === '') {
        isFormValid = false;
        this.state.errors['emailAddress'] = configJSON.newEmailAddress
      }
      else if (!regex.test(this.state.emailAddress)) {
        isFormValid = false;
        this.state.errors['emailAddress'] = configJSON.validEmailAddress
      }
    } else if (this.state.emailAddress == "") {
      if (this.state.mobileNumber === '') {
        isFormValid = false;
        this.state.errors['mobileNumber'] = configJSON.newMobileNumber
      }
      else if (!regexPhone.test(this.state.mobileNumber)) {
        isFormValid = false;
        this.state.errors['mobileNumber'] =  configJSON.validMobileNumber

      }
    }

    return isFormValid
  }

  handleForgetPassword = () => {
    if (this.formValidateForgetPassword()) {
      this.setState({ loadingSignIn: true })
      this.postForgetpasswordCall()
    } else {
      this.setState({
        errors: this.state.errors
      })
    }
  }


  handlForgetPasswordUpdate = () => {
    if (this.formValidateConfirmPassword()) {
      this.setState({ loadingSignIn: true })
      this.updateNewPasswordCall();
    } else {
      this.setState({
        errors: this.state.errors
      })
    }
  }
  handleCloseForgetPassword = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }

    this.setState({ snackbarOpen: false })
  };
  handleLanguageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ language: event.target.value })
  }
  handleClickShowForgetPassword = () => {
    this.setState({
      enablePasswordField: !this.state.enablePasswordField,
    });
  };
  handleClickConfirmShowPassword = () => {
    this.setState({
      enableConfirmPasswordField: !this.state.enableConfirmPasswordField,
    });
  };
  handleInputChangeData = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    const regex = /^[0-9\b]+$/;
    if (name === 'mobileNumber' && (value === '' || regex.test(value))) {
      this.setState((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };
  // Customizable Area End
}

