import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { apiCall, handleLogout } from "../../../components/src/common";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes?: any;
  open: any;
  customerData?: any;
  handleClose: () => void;
  'data-test-id'?: string;
  getProductInfo: (productInfo: any) => void;
  categoryId: number;
  storeId: number;
  customerId: number;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  dropdownOptions: any;
  snackbarMessage: string;
  snackbarOpen: boolean;
  selected_service_ids: (string | number)[];
  serviceOfferList: { id: number; name: string; }[];
  selectAllService?: boolean;
  isLoading?: boolean;
  productName: string;
  prices: {
    service_id: number | string;
    price: string;
    service_name: string | undefined
  }[];
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class AddCustomerModalController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getCityDropdownOptionsApiCallId: string = "";
  getServiceListApiCallId: string = "";
  addProductApiCallId: string = "";
  authToken: string = "";

  async componentDidMount() {
    const authToken = localStorage.getItem("token");
    if (typeof (authToken) === "string") {
      this.authToken = authToken;
    }

    this.getServiceList();
  }
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.RestAPIResponceDataMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      dropdownOptions: {
        gender: [],
        profession: [],
        organisation: [],
        organisationTax: [],
        business: [],
        payment_type: [],
        city: []
      },
      snackbarMessage: '',
      snackbarOpen: false,
      selected_service_ids: [],
      serviceOfferList: [],
      selectAllService: false,
      isLoading: false,
      productName: '',
      prices: []
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      this.getServiceListApiCallResponse(apiRequestCallId, responseJson)

      if (apiRequestCallId === this.addProductApiCallId) {
        this.props.getProductInfo(responseJson);
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  checkGetResponse(responseJson: any) {
    if (responseJson && !responseJson.errors) {
      return true
    }
    else {
      handleLogout(this.props.navigation, responseJson && responseJson.errors);
      return false
    }
  }

  handleProductNameChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    this.setState({
      productName: event.target.value
    })
  }

  getServiceList() {
    let url = `${configJSON.getServiceListApiEndPoint}?dropdown=true`;

    let headers:any = {
      token: this.authToken
    }

    const getAccount = apiCall({
      httpBody: {},
      header: headers,
      url: url,
      httpMethod: 'GET',
    });

    this.getServiceListApiCallId = getAccount.messageId;
    runEngine.sendMessage(getAccount.id, getAccount);
  }

  getServiceListApiCallResponse(apiRequestCallId: any, responseJson: any) {
    if (apiRequestCallId === this.getServiceListApiCallId) {
      if (this.checkGetResponse(responseJson)) {
        this.setState({ isLoading: false });
        this.setState(() => ({
          serviceOfferList: responseJson.data?.map(
            (item: any) => {
              return { id: item.attributes.id, name: item.attributes.name }
            })
        }))
      } else {
        this.setState({ isLoading: false })
      }
    }
  }

  handleSnackbarClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }

    this.setState({ snackbarOpen: false });
  };

  handleServiceOfferChange = (selectedValues: (string | number)[]) => {
    if(selectedValues.includes("selectAll")) {
      this.handleSelectAllServiceChange()
    } else{
      const selectAllService =
        selectedValues.length === this.state.serviceOfferList.length;
    
      const newPrices = this.getNewPrice(selectedValues);
    
      this.setState({
        selectAllService,
        selected_service_ids: selectedValues,
        prices: newPrices,
      });
    }
  };

  getNewPrice(array: Array<string | number>) {
    return array.reduce(
      (accumulator, value) => {
        const existingPrice = this.state.prices.find(
          (price) => price.service_id === value
        );

        if (existingPrice) {
          // Use a type assertion to specify the correct type
          accumulator.push(
            existingPrice as {
              service_id: string | number;
              price: string;
              service_name: string | undefined;
            }
          );
        } else {
          // If not, add a new empty object to the prices array
          accumulator.push({
            service_id: value,
            price: "",
            service_name: this.state.serviceOfferList.find(
              (option) => option.id === value
            )?.name,
          });
        }

        return accumulator;
      },
      [] as {
        service_id: string | number;
        price: string;
        service_name: string | undefined;
      }[]
    );
  }

  handleSelectAllServiceChange = () => {

    if (!this.state.selectAllService) {
      const allServiceOfferIds = this.state.serviceOfferList.map(
        (option) => option.id
      );

      const newPrices = this.getNewPrice(allServiceOfferIds);

      this.setState({
        selectAllService: true,
        selected_service_ids: allServiceOfferIds,
        prices: newPrices,
      });
    } else {
      this.setState({
        selectAllService: false,
        selected_service_ids: [],
        prices: []
      });
    }
  };

  handlePriceChange = (event: React.ChangeEvent<HTMLInputElement>, service_id: string | number) => {
    const inputText = event.target.value;
    let price: any = inputText.replace(/\D/g, ''); // Remove non-numeric characters
    
    // Ensure that the price is not just 0
    price = price === '' ? '' : Math.max(1, parseInt(price, 10)); // Minimum price is 1

    const newPrices = this.state.prices.map((priceItem) => {
      if (priceItem.service_id === service_id) {
        return {
          ...priceItem,
          price
        }
      }

      return priceItem;
    })

    this.setState({
      prices: newPrices
    })
  };

  addProductCall = () => {
    const headers = {
      "Content-Type": "application/json",
      token: localStorage.getItem("token"),
    };
    const getAccount = apiCall({
      httpBody: {
        data: {
          attributes: {
            product_name: this.state.productName,
            product_second_name: this.state.productName,
            store_management_id: this.props.storeId,
            customer_id: this.props.customerId,
            category_ids: [this.props.categoryId],
            catalogue_variants_attributes: [
              {
                catalogues_services_attributes: this.state.prices.map((item) => {
                  return (
                    {
                      price: item.price,
                      service_id: item.service_id
                    }
                  )
                })
              }
            ]
          }
        }
      },
      header: headers,
      url: '/bx_block_catalogue/catalogues/create_store_product',
      httpMethod: 'POST',
    });
    
    this.addProductApiCallId = getAccount.messageId;

    runEngine.sendMessage(getAccount.id, getAccount);
  };

  handleAddClick = () => {
    const { productName, prices } = this.state;

    if (!this.props.customerId) {
      this.setState({
        snackbarOpen: true,
        snackbarMessage: 'Please select customer'
      })
      return;
    }

    if (!productName) {
      this.setState({
        snackbarOpen: true,
        snackbarMessage: 'Please enter product name'
      })
      return;
    }

    if (prices.length === 0) {
      this.setState({
        snackbarOpen: true,
        snackbarMessage: 'Please select service offer'
      })
      return;
    }


    if (prices.some((price) => !price.price)) {
      this.setState({
        snackbarOpen: true,
        snackbarMessage: 'Please enter price for all service offer'
      })
      return;
    }

    this.addProductCall();
  }
  // Customizable Area End
}
