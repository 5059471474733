import React from "react";

// Customizable Area Start
import {
    Box,
    Button
} from "@material-ui/core";
import "./Otp.css"
import {
    BackgroundImage,
    BackgroundPaper,
    AvatarImg,
    DynamicHeading,
    DynamicSubHeading,
}
    from "../../../components/src/customComponents/DynamicContent.web";
import CostomButton from "../../../components/src/customComponents/CostomButton.web"
import { backgroundImageLogin } from "../../email-account-login/src/assets"
export const configJSON = require("./config");
import Snackbar from "@material-ui/core/Snackbar";
import Alert from '@material-ui/lab/Alert';
import CustomComponentLanguage from "../../../components/src/customComponents/CustomComponentLanguage.web"
import { logo } from "./assets";

// Customizable Area End

import OtpController, {
    Props
} from "./OtpController.web";

// Customizable Area Start
export default class OtpPage extends OtpController {
    constructor(props: Props) {
        super(props);
    }
    // Customizable Area Start
    render() {
        return (
            // Required for all blocks
            <BackgroundImage imageUrl={backgroundImageLogin}>
                <BackgroundPaper>
                    <CustomComponentLanguage navigation={undefined} id={""} />
                    <Box display="flex" justifyContent="center" paddingTop="0.2vw" paddingBottom="1vw">
                        <img src={logo} width="33%"/>
                    </Box>
                    <DynamicHeading headingName={configJSON.otpheading} />
                    <DynamicSubHeading headingName={configJSON.otpsubheading} />
                    <Box style={webStyle.otpStyle}>
                        {this.state.otpValues.map((value, index) => (
                            <input
                                data-test-id="otp-box"
                                className="code"
                                key={index}
                                id={`otp-${index}`}
                                type="text"
                                maxLength={1}
                                value={value}
                                onChange={(event) => this.handleChange(event, index)}
                                onKeyDown={(event) => this.handleKeyDown(event, index)}
                                onKeyUp={(event) => this.handleKeyUp(event, index)}
                                onPaste={this.handlePaste}
                                style={this.state.showErrMessage?webStyle.errorStyle:{}}
                            />
                        ))}
                    </Box>
                    
                    {
                        (this.state.otpAttemptRights < 2 && this.state.otpAttemptRights > 0) &&
                        !this.state.showExceedErrMessage &&
                        <Snackbar anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }} open={true} autoHideDuration={4000}>
                            <Alert severity="warning"  icon={<div></div>}>
                                {configJSON.attemptLabel.replace("{attemptRight}", this.state.otpAttemptRights)}
                            </Alert>
                        </Snackbar>
                    }
                    {(this.state.otpAttemptRights===0 || this.state.showExceedErrMessage)&&<Snackbar anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }} style={{width:"fit-content"}} open={true} autoHideDuration={4000}>
                        <Alert severity="warning"  icon={<div></div>}>
                            <div style={webStyle.alertContainer}>
                                <div>{configJSON.exceedMessage}</div>
                                <Button  
                                size="small"
                                variant="contained" 
                                style={{...webStyle.contactButton,textTransform: "none",}}>
                                    {configJSON.contactLabel}
                                </Button>
                            </div>
                            
                        </Alert>
                    </Snackbar>}
                    {!this.state.showExceedErrMessage &&  
                        <Box style={webStyle.counterBox}>
                            {this.state.counter > 0 && (
                                <p style={{ textAlign: "center", ...webStyle.counterTimer }}>
                                    00:{String(this.state.counter).padStart(2,"0")}
                                </p>
                            )}
                            <button style={this.state.isButtonDisabled ? webStyle.resendBtn : webStyle.resendBtnBorder} data-test-id="resend-otp" onClick={this.handleTimeOut} disabled={this.state.isButtonDisabled || !this.state.otpAttemptRights}>Resend</button>

                        </Box>
                    }

                    <Box style={webStyle.buttonAdd}>
                        <CostomButton
                            data-test-id="verifyBtn"
                         disabled={!this.state.otpAttemptRights || !this.state.isButtonDisabled}
                         onClick={this.handleLogInputValue} style={{ textTransform: "none", ...webStyle.buttonStyle }}>{configJSON.labelverify}</CostomButton>
                    </Box>
                </BackgroundPaper>
            </BackgroundImage>
        );
    }
    // Customizable Area End
}

// Customizable Area End

// Customizable Area Start
const webStyle = {
    buttonStyle: {
        width: "100%",
        height: "3.472vw",
        borderRadius: "0.556vw",
        alignText: "center",
        color: "white",
        fontFamily: "Montserrat",
        fontWeight: 700,
        fontSize: "1.667vw",
    },

    otpStyle: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        margin: '4px 0',
        paddingTop: "1.5vw"
    },
    backgroundpaper: {
        padding: '4vw'
    },
    otpSend: {
        fontFamily: 'Montserrat',
        fontWeight: 500,
        fontSize: "1.050vw",
    },
    otpSendTemp: {
        color: '#EC4E20',
        marginRight: '2vw',
        width: "3.403vw",
        height: "1.467vw",
        borderRadius: "0.833vw",
        border: "1px solid #EC4E20",
        padding: "1vw",
    },
    timer: {
        display: 'flex',
        justifyContent: "center",
        paddingTop: '1.5vw',
    },
    runner: {
        color: '#4D4D4D',
        paddingTop: '1vw',
        cursor: "pointer"
    },
    buttonAdd: {
        display: "flex",
        background: "#204B9C",
        borderRadius: "7px",
        padding: "5px",

    },
    counterBox: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        margin: "2vw"
    },
    counterTimer: {
        color: "#EC4E20",
        border: "1px solid #fdeee9",
        borderRadius: "12px",
        padding: "10px",
        margin: "0",
        width: '5.139vw',

        fontWeight: 500,
        fontSize: '1.250vw',
        fontFamily: 'Montserrat'
    },
    resendBtn: {
        outline: "none",
        border: "none",
        padding: "10px",
        background: "#ffffff",
        marginLeft: "13px",
        fontWeight: 500,
        fontSize: '1.250vw',
        fontFamily: 'Montserrat'
    },
    resendBtnBorder: {
        border: "0.069vw solid #9861c2",
        borderRadius: "12px",
        padding: "10px",
        color: "#4D4D4D",
        background: "#ffffff",
        cursor: "pointer",
        fontWeight: 500,
        fontSize: '1.250vw',
        fontFamily: 'Montserrat'
    },
    contactButton:{
        background: "rgba(32, 75, 156, 0.15)",
        color:"#204B9C"
    },
    alertContainer:{
        display:"flex",    
        alignItems: "center",
        gap: "10px"
    },
    errorStyle:{
        color:"#FF0000",
        borderColor:"#FF0000"
    }

}
// Customizable Area End