import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
// Customizable Area Start
import { IOrder } from "../../OrderCreation/src/OrderCreationController.web";
export const configJSON = require("./config");

interface PromoCodeResp {
  errors?: [];
  data?: {
    data?: {
      attributes?: IOrder;
    };
  };
}

// Customizable Area End

export interface Props {
  // Customizable Area Start
  order?: IOrder;
  cleaningOrder?: boolean;
  onApply?: (order: IOrder) => void;
  // Customizable Area End
}

export interface IPromoCode {
  // Customizable Area Start
  saved_amount: number;
  product_name: string;
  discount: string;
  expiry_date: null | string;
  min_order_amount: null | string;
  promo_expires: boolean;
  promo_code: string;
  description: string;
  id: number;
  currency: string;
  bogo_enabled?: boolean
  // Customizable Area End
}

export interface S {
  // Customizable Area Start
  pageNumber: number;
  promoCodes: IPromoCode[];
  promoCode: string;
  checkError: string;
  selectedPromoCodeId: number;
  selectedPromoCode: IPromoCode | undefined;
  applyEnabled: boolean;
  disableAfterApply: boolean;
  // Customizable Area End
}

export interface SS {
  id: any;
}

export default class PromoCodePaymentController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getPromoCodesCallId: string = "";
  applyPromoCodeCallId: string = "";
  dynamicLogoCallId: string = "";
  popoverRef: HTMLElement | null = null;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),

      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area Start
    ];

    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start

    this.state = {
      pageNumber: 1,
      promoCodes: [],
      promoCode: "",
      checkError: "",
      selectedPromoCodeId: -1,
      selectedPromoCode: undefined,
      applyEnabled: false,
      disableAfterApply: false,
    };
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      switch (apiRequestCallId) {
        case this.getPromoCodesCallId:
          this.getPromocodeResp(responseJson);
          break;
        case this.applyPromoCodeCallId:
          this.applyPromoCodeResp(responseJson);
          break;
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  getPromoCodes = () => {
    const header = {
      token: window.localStorage.getItem(configJSON.tokenKey),
    };
    const getDataMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getPromoCodesCallId = getDataMsg.messageId;

    const apiUrl = configJSON.getPromocodeUrl;

    getDataMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiUrl +
        "platform=Store&page_no=" +
        this.state.pageNumber +
        "&per_page=1000&order_id=" +
        (this.props.order ? this.props.order.id : "")
    );
    getDataMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    getDataMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getDataMsg.id, getDataMsg);
  };

  applyCode = (disableAfterApply?: boolean) => {
    const header = {
      token: window.localStorage.getItem(configJSON.tokenKey),
      "Content-Type": configJSON.validationApiContentType,
    };
    const getDataMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.applyPromoCodeCallId = getDataMsg.messageId;

    const apiUrl = this.props.cleaningOrder ? configJSON.applyPromoCodeForCleaningOrderEndpoint : configJSON.appliedPromocodeUrl;

    getDataMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiUrl
    );

    getDataMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    getDataMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );

    getDataMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({
        id: this.props.order ? this.props.order.id : "",
        code: this.state.promoCode,
        platform: "Store",
      })
    );
    this.setState({
      disableAfterApply: !!disableAfterApply,
    });
    runEngine.sendMessage(getDataMsg.id, getDataMsg);
  };

  getPromocodeResp = (responseJson: {pagination:{current_page:number},data:IPromoCode[]}) => {
    const { current_page } = responseJson.pagination;

    this.setState(
      {
        pageNumber: current_page,
        promoCodes: responseJson.data,
      },
      this.checkOrderPromoCode
    );
  };

  applyPromoCodeResp = (responseJson: PromoCodeResp) => {
    if (responseJson.errors) {
      this.setState({
        checkError: "Sorry, this promo code is not valid",
      });
    } else {
      if (
        responseJson.data &&
        responseJson.data.data &&
        responseJson.data.data.attributes &&
        this.props.onApply
      ) {
        this.props.onApply(responseJson.data.data as IOrder);
      }
      if (this.state.disableAfterApply) {
        this.setState({
          applyEnabled: false,
        });
      }
    }
  };

  async componentDidMount() {
    super.componentDidMount();
    const token = window.localStorage.getItem(configJSON.tokenKey);
    if (!token) {
      window.location.href = "/";
    }

    if (this.props.order && this.props.order.id) {
      this.checkOrderPromoCode();
      this.getPromoCodes();
    }
  }

  checkOrderPromoCode = () => {
    if (this.props.order && this.props.order.promo_code_id) {
      const order = this.props.order;
      const promoCode = this.state.promoCodes.find(
        (item) => item.id === Number(order.promo_code_id)
      );
      if (promoCode) {
        this.setState({
          selectedPromoCodeId: Number(order.promo_code_id),
          selectedPromoCode: promoCode,
          promoCode: promoCode.promo_code,
        });
      }
    }
  };

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<S>,
    snapshot?: SS | undefined
  ): void {
    if (
      (prevProps.order ? prevProps.order.id : "") !==
        (this.props.order ? this.props.order.id : "") &&
      this.props.order &&
      this.props.order.id
    ) {
      this.checkOrderPromoCode();
      this.getPromoCodes();
    }
  }
  // Customizable Area End
}
