import React from "react";

// Customizable Area Start
import Dialog from "@material-ui/core/Dialog";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import MenuItem from "@material-ui/core/MenuItem";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import Checkbox from "@material-ui/core/Checkbox";
import ListItemText from "@material-ui/core/ListItemText";
import { styled, Chip, InputAdornment } from "@material-ui/core";
import { CustomSnackbar } from "../../../components/src/customComponents/CustomSnackbar.web";
import { CustomMenuProps } from "../../ProductDescription/src/HomeCleaningSettingsList.web";

// Customizable Area End

import AddProductModalController, {
  // Customizable Area Start
  Props,
  // Customizable Area End
} from "./AddProductModalController";

// Customizable Area Start

// Customizable Area End

export default class AddProductModal extends AddProductModalController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start

    // Customizable Area End
  }

  // Customizable Area Start

  // Customizable Area End

  render() {
    // Customizable Area Start
    const { open, handleClose } = this.props;
    const { productName, selected_service_ids, prices } = this.state;

    // Customizable Area End
    return (
      // Customizable Area Start
      <StyledDialog
        maxWidth='xl'
        open={open}
        onClose={handleClose}
        aria-label='add-product-modal'
      >
        <div style={webStyle.modalMainContainer}>
          <div style={webStyle.modalTitle}>Add Product</div>
          <Grid container direction='column' style={{ marginTop: 32 }}>
            <Grid container direction='row' spacing={3}>
              <Grid item xs={12} sm={6}>
                <FormControl style={{ width: "100%" }}>
                  <FormLabel style={webStyle.formLabel}>Product Name</FormLabel>
                  <TextField
                    data-test-id={"product-name"}
                    value={productName}
                    color='primary'
                    variant='outlined'
                    type='text'
                    placeholder={"Enter Product Name"}
                    onChange={this.handleProductNameChange}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl variant='outlined' style={{ width: "100%" }}>
                  <FormLabel style={webStyle.formLabel}>
                    Service Offer
                  </FormLabel>
                  <MultiSelectionBox
                    data-test-id='service_offer_id'
                    name='service_offer_id'
                    id='service_offer_id'
                    multiple={true}
                    value={selected_service_ids}
                    onChange={(
                      event: React.ChangeEvent<{ value: unknown }>
                    ) => {
                      let selectedValues = event.target.value as (
                        | string
                        | number
                      )[];
                      selectedValues = selectedValues.filter(selectedValues => selectedValues);
                      this.handleServiceOfferChange(selectedValues);
                    }}
                    input={<OutlinedInput />}
                    renderValue={(selected: unknown) => {
                      const selectedValues = selected as (string | number)[];

                      if (selectedValues.length === 0) {
                        return (
                          <div style={{ marginTop: 5 }}>
                            Select Service Offer
                          </div>
                        );
                      }

                      if (selectedValues.includes(-1)) {
                        return "Select All";
                      }

                      return (
                        <div style={webStyle.chips}>
                          {selectedValues.map((value: string | number) => {
                            const name = this.state.serviceOfferList.find(
                              (option) => option.id === value
                            )?.name;

                            return (
                              <Chip
                                key={name}
                                label={name}
                                style={webStyle.chip}
                              />
                            );
                          })}
                        </div>
                      );
                    }}
                    displayEmpty
                    placeholder='Select Service offer'
                    variant='outlined'
                    MenuProps={CustomMenuProps}
                  >
                    <StyledMultipleMenuItemStore value="selectAll">
                      <Checkbox
                        checked={this.state.selectAllService}
                        // onChange={() => this.handleSelectAllServiceChange()}
                        color='primary'
                      />
                      <div style={{ color: "black", fontWeight: 400 }} >
                        Select All
                      </div>
                    </StyledMultipleMenuItemStore>
                    {this.state.serviceOfferList.map((option) => (
                      <StyledMultipleMenuItemStore
                        key={option.id}
                        value={option.id}
                      >
                        <Checkbox
                          checked={selected_service_ids.includes(option.id)}
                        />
                        <ListItemText primary={option.name} />
                      </StyledMultipleMenuItemStore>
                    ))}
                  </MultiSelectionBox>
                </FormControl>
              </Grid>
            </Grid>

            <Grid container direction='row' spacing={3}>
              {prices.map((item) => {
                return (
                  <Grid item xs={12} sm={6} style={{ marginTop: 32 }}>
                    <FormControl style={{ width: "100%" }}>
                      <FormLabel style={webStyle.formLabel}>
                        {`Price for ${item.service_name}`}
                      </FormLabel>
                      <TextField
                        data-test-id={"product-name"}
                        value={item.price}
                        color='primary'
                        variant='outlined'
                        type='text'
                        InputProps={{
                          startAdornment: (
                              <InputAdornment position="start"><span style={{ color: '#000', fontSize: '14px', fontWeight: 400 }}>SAR</span></InputAdornment>
                          ),
                        }}
                        placeholder={"Enter Price"}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => this.handlePriceChange(event, item.service_id)}
                      />
                    </FormControl>
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
          <div
            style={{
              ...webStyle.btnsContainer,
              marginTop: !prices.length ? 56 : 32,
            }}
          >
            <div
              data-test-id='btn-cancel'
              onClick={handleClose}
              style={webStyle.cancelButton}
            >
              Cancel
            </div>
            <div
              data-test-id='btn-add'
              style={webStyle.confirmButton}
              onClick={this.handleAddClick}
            >
              Add
            </div>
          </div>
        </div>

        <CustomSnackbar
          data-test-id='customSnackbaronTemporaryAddProduct'
          open={this.state.snackbarOpen}
          onClose={this.handleSnackbarClose}
          errorMessage={this.state.snackbarMessage}
          severity='error'
        />
      </StyledDialog>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  chips: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap" as const,
    fontFamily: "Montserrat",
  },
  chip: {
    margin: "2px 14px 2px 0px",
    backgroundColor: "rgba(32, 75, 156, 0.1)",
    color: "#204B9C",
    height: "26px",
    fontFamily: "Montserrat",
  },
  modalMainContainer: {
    padding: 32,
    width: 760,
    display: "flex",
    flexDirection: "column" as const,
    alignItems: "center",
  },
  cancelButton: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#64748b",
    background: "#f1f5f9",
    fontSize: 16,
    fontWeight: 700,
    marginRight: 20,
    padding: 16,
    borderRadius: 8,
    width: 128,
    height: 56,
    cursor: "pointer",
  },
  confirmButton: {
    color: "#ffffff",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: 16,
    fontWeight: 700,
    background: "#204b9c",
    padding: 16,
    height: 56,
    borderRadius: 8,
    width: 128,
    cursor: "pointer",
  },
  modalTitle: {
    color: "#1A1A1A",
    fontSize: 24,
    fontWeight: 600,
  },
  formLabel: {
    marginBottom: 8,
    color: "#334155",
    fontFamily: "Montserrat",
    fontSize: 16,
    fontWeight: 600,
  },
  modalText: {
    color: "#4D4D4D",
    fontSize: 20,
    fontWeight: 400,
    marginTop: 16,
    textAlign: "center" as const,
    lineHeight: "30px",
  },
  btnsContainer: {
    marginTop: 32,
    display: "flex",
  },
};

const StyledDialog = styled(Dialog)({
  "& .MuiPaper-rounded": {
    borderRadius: 24,
  },
});

const MultiSelectionBox: any = withStyles({
  root: {
    paddingTop: 13,
    paddingBottom: 13,
    maxHeight: 70,
    minHeight: 56,
    boxSizing: "border-box",
    overflow: "auto",
    "&:focus": {
      outline: "none",
      borderRadius: "8px",
    },
  },
})(Select);

const StyledMultipleMenuItemStore = withStyles((theme) => ({
  root: {
    height: "56px",
    "&.Mui-selected": {
      backgroundColor: "transparent",
    },
  },
}))(MenuItem);
// Customizable Area End
