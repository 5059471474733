Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
const urlConfig = require("../../../framework/src/config");
exports.APIBaseURL = urlConfig.baseURL;
exports.validationApiContentType = "application/json";
exports.token = "token";
exports.title = "Invite Codes/Referrals";
exports.status = "Status"
exports.selectAllow = "Allow Customers Referrals";
exports.inputExistingCredit = "Credit to give to Existing Customer";
exports.inputNewCredit = "Credit to give to New Customer";
exports.fieldRequiredValidText = "This field is required";
exports.fieldValidText = "Value must be a positive number";
exports.pleaseSelectOneOption = "Please select one option";
exports.cancel = "Cancel";
exports.save = "Save";
exports.back = "Back";
exports.selectOption = "Select Option";
exports.inviteFriendsApiEndpoint = "bx_block_invitefriends/referral_discounts";
exports.inviteFriendTextValue ="Give One Time Credit to Existing and New Customer"
// Customizable Area End