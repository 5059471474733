import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { IRegion } from "./CfRegionListController.web";
import { IUserContext } from "../../../blocks/navigationmenu/src/PageContainerController.web";
import {
  PermissionStatus,
  checkForNewPermissonStatus,
  customPermissionApiKey
} from "../../../blocks/utilities/src/CustomBlockHelpers";
import { PermissionGroupArray } from "../../../blocks/navigationmenu/src/utils";

interface IArea {
  id: number;
  attributes: {
    area_name: string;
    id?: string;
    region: {
      data: {
        id: string;
        option:string;
        attributes?: {
          id: 32;
          branch_name: "testingplant";
        };
      };
    };
  };
}

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
}

interface S {
  areaInfo: IArea;
  initialAreaInfo: IArea;
  regionList: IRegion[];
  areaId: string;
  formErrors: { [key: string]: string };
  snackBarMessage: string;
  isLoading: boolean;
  permissionStatus: PermissionStatus;
}

interface SS {
  id: any;
}

export default class AddAreaController extends BlockComponent<Props, S, SS> {
  updateAreaCallId: string = "";
  addAreaCallId: string = "";
  getAreaCallId: string = "";
  regionListCallId: string = "";
  disableLoadMoreService=false;

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      areaInfo: {
        id: 0,
        attributes: {
          area_name: "",
          region: {
            data: {
              id: "",
              option:''
            },
          },
        },
      },
      initialAreaInfo: {
        id: 0,
        attributes: {
          area_name: "",
          region: {
            data: {
              id: "",
              option:''
            },
          },
        },
      },
      regionList: [],
      areaId: "",
      formErrors: {},
      snackBarMessage: "",
      isLoading: false,
      permissionStatus: {
        mainPermission: false,
        createPermission: false,
        viewPermission: false,
        editPermission: false,
        deactivatePermission: false,
        activatePermission: false
      }
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  receiveUpdateArea = (message: Message) => {
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

    if (responseJson) {
      if (this.state.areaId) this.showMessage("Area Updated Successfully");
      else this.showMessage("Area Added Successfully");
      setTimeout(() => {
        this.props.navigation.goBack();
      }, 2000);
    } else {
      this.setState({isLoading: false})
    }
  };
 
  receiveGetArea = (message: Message) => {
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    if (responseJson) {
    
      this.setState({
        areaInfo: {
          ...responseJson.data,
          attributes:{
            ...responseJson.data.attributes,
            region: { data: {...responseJson.data.attributes.region.data, option:responseJson.data.attributes.region.data.attributes.branch_name } },
          }
        },
        initialAreaInfo: responseJson.data,
      });
    }
  };
  handleScrollRegionDropdown = (event: React.SyntheticEvent) => {
  
  };
  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (apiRequestCallId === this.regionListCallId) {
        if (responseJson) {
          let regionList = responseJson.data as IRegion[];
          regionList = regionList.filter((el) => el.attributes);
          this.setState({ regionList });
        }
      } else if (apiRequestCallId === this.updateAreaCallId) {
        this.receiveUpdateArea(message);
      } else if (apiRequestCallId === this.getAreaCallId) {
        this.receiveGetArea(message);
      }
    }
  }

  getRegionList = () => {
    const header = {
      token: window.localStorage.getItem(configJSON.tokenKey),
      "Content-Type": configJSON.contentType,
    };
    const getDataMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.regionListCallId = getDataMsg.messageId;

    getDataMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
    getDataMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.dropdownRegionListApi
    );
    getDataMsg.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    runEngine.sendMessage(getDataMsg.id, getDataMsg);
  };

  getArea = (areaId: string) => {
    const header = {
      "Content-Type": configJSON.contentType,
      token: window.localStorage.getItem(configJSON.tokenKey),
    };
    const getDataMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getAreaCallId = getDataMsg.messageId;

    getDataMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.getMethod);
    getDataMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.areaListApi + "/" + areaId
    );
    getDataMsg.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    runEngine.sendMessage(getDataMsg.id, getDataMsg);
  };

  updateCreateAreaInfo = () => {
    const header = {
      "Content-Type": configJSON.contentType,
      token: window.localStorage.getItem(configJSON.tokenKey),
    };

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.updateAreaCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.areaListApi}` + (this.state.areaId ? `/${this.state.areaId}` : "")
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    const data = {
      attributes: {
        area_name: this.state.areaInfo.attributes.area_name,
        region_id: this.state.areaInfo.attributes.region.data.id,
      },
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({ data })
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      this.state.areaId ? configJSON.putMethod : configJSON.postMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  // web events

  onCancel = () => {
    this.setState({ areaInfo: { ...this.state.initialAreaInfo } });
    this.props.navigation.goBack();
  };

  checkFormErrors = () => {
    const { areaInfo } = this.state;
    const { attributes } = areaInfo;
    const {
      area_name,
      region: {
        data: { id: region_id },
      },
    } = attributes;
    const formErrors: { [key: string]: string } = {};
    if (!area_name) formErrors[configJSON.areaName] = "Area name is required";
    if (!region_id) formErrors[configJSON.selectRegion] = "Region  is required";

    this.setState({ formErrors });
    return Object.keys(formErrors).length === 0;
  };

  onSave = () => {
    if (this.checkFormErrors()) {
      this.setState({isLoading: true})
      this.updateCreateAreaInfo();
    }
  };

  async componentDidMount() {
    super.componentDidMount();
    this.getRegionList();
    const areaId = window.location.search.split("=")[1];
    if (areaId) {
      this.getArea(areaId);
      this.setState({ areaId });
    }
  }

  showMessage = (message: string) => {
    this.setState({ snackBarMessage: message });
  };

  closeSnackBar = () => {
    this.setState({ snackBarMessage: "" });
  };

  handleEdit = () => {
    const areaId = this.state.areaId;
    window.localStorage.setItem("areaId", areaId);
    this.props.navigation.history.push("/Settings-General-AreaListEdit?areaId=" + areaId);
  };

  handleAreaBack = () => {
    this.props.navigation.history.goBack();
  };

  handleUserChange = (userContext: IUserContext) => {
    const apiKey = customPermissionApiKey.areaPermissions;
    const userData = userContext.user?.attributes.permission_groups;
    const value = checkForNewPermissonStatus(apiKey, userData as Array<PermissionGroupArray>);
    this.setState({
      permissionStatus: value
    })
  };

  // Customizable Area End
}
