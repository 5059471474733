import React, { FunctionComponent, ReactNode, useState } from "react";
import {
    styled,
    Dialog,
    DialogContent,
} from "@material-ui/core";
import { iconCheck, iconJpeg, iconPdf } from "../../components/src/commonAssets/assets"


interface IAction {
    label: string;
    method: string;
    icon: string;
}
const actions: Array<IAction> = [
    {
        label: "Export as PDF",
        method: "pdf",
        icon: iconPdf,
    }
];

interface Props {
    title?: string;
    onCancel: () => void;
    onConfirm: (exportMethod: string) => void;
    isOpen: boolean
}


const ExportModal: FunctionComponent<Props> = (props) => {
    const [exportMethod, setExportMethod] = useState("")
    const { onCancel, onConfirm, isOpen } = props
    return (
        <StyledDialog open={isOpen}>
            <DialogContent>
                <Container>
                    <Title>Select from below</Title>
                    {actions.map((item: IAction) => {
                        const isSelected = exportMethod === item.method;
                        return (
                            <div className='text-center' key={"modal-actions-" + item.method}>
                                <SelectButton
                                    data-test-id={"select-button-" + item.method}
                                    onClick={() => setExportMethod(item.method)}
                                    className={isSelected ? "active" : ""}
                                >
                                    <img src={item.icon} />
                                    <span>{item.label}</span>
                                    {isSelected && <img src={iconCheck} />}
                                </SelectButton>
                            </div>
                        );
                    })}
                    <ButtonContainer>
                        <CancelButton
                            data-test-id='btn-cancel'
                            onClick={onCancel}
                        >
                            Cancel
                        </CancelButton>
                        <SubmitButton
                            data-test-id='btn-submit'
                            onClick={() => onConfirm(exportMethod)}
                            disabled={!exportMethod}
                        >
                            Submit
                        </SubmitButton>
                    </ButtonContainer>
                </Container>
            </DialogContent>
        </StyledDialog>
    );
};

export default ExportModal;

const StyledDialog = styled(Dialog)({
    "& .MuiDialog-paper": {
        borderRadius: 24,
    },
    "& .MuiDialogContent-root": {
        padding: 0,
    },
    "& .text-center": {
        textAlign: "center",
    },
});

const SelectButton = styled("div")({
    gap: 16,
    padding: 16,
    minWidth: 300,
    fontWeight: 600,
    borderRadius: 12,
    marginBottom: 16,
    cursor: "pointer",
    alignItems: "center",
    display: "inline-flex",
    border: "1px solid #DEDEDE",
    "& > span": {
        flexGrow: 1,
        textAlign: "left",
    },
    "&:hover": {
        backgroundColor: "#204B9C1A",
    },
});

const ButtonContainer = styled("div")({
    gap: 24,
    marginTop: 16,
    display: "flex",
    alignItems: "center",
});

const CancelButton = styled("button")({
    padding: 16,
    fontSize: 16,
    minWidth: 186,
    border: "none",
    fontWeight: 700,
    borderRadius: 8,
    color: "#64748B",
    cursor: "pointer",
    lineHeight: "24px",
    alignItems: "center",
    display: "inline-flex",
    justifyContent: "center",
    backgroundColor: "#F1F5F9",
});

const SubmitButton = styled(CancelButton)({
    color: "#fff",
    backgroundColor: "#204B9C",
    "&:disabled, &[disabled]": {
        opacity: 0.7,
    },
});

const Container = styled("div")({
    padding: 32,
    "& .active": {
        color: "#204B9C",
        backgroundColor: "#204B9C1A",
    },
});

const Title = styled("h3")({
    margin: 0,
    padding: 0,
    fontSize: 24,
    fontWeight: 600,
    paddingBottom: 32,
    lineHeight: "29px",
    textAlign: "center",
    fontFamily: "Montserrat",
});
