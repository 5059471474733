import { CSSProperties } from "@material-ui/styles";

export const style: Record<string, CSSProperties> = {
    // Customizable Area Start
  filtergroup: {
    display: "flex",
  },
  filterButton: {
    padding: "16px",
    borderRadius: "15px",
    border: "1px solid rgb(32, 75, 156)",
    cursor: "pointer",
  },
  TableRow: {
    background: "#204B9C",
    color: "#FFFFFF",
  },
  blackColor: {
    color: "#000000 !important",
  },
  NoRecord: {
    fontSize: "16",
    textAlign: "center",
    fontWeight: 600,
    width: "100%",
  },
  taxPopoverContainer: {
    paddingTop: "16px",
    paddingBottom: "16px",
    borderRadius: "8px",
  },
  taxPopoverButton: {
    width: "118px",
    height: "33px",
    paddingLeft: "24px",
    backgroundColor: "#FFFFFF",
    color: "#4D4D4D",
    fontSize: "14px",
    fontWeight: 500,
    display: "flex",
    alignItems: "center",
    paddingTop: 0,
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#204B9C",
      color: "#FFFFFF",
    },
  },
  tableHeading: {
    backgroundColor: "rgba(32, 75, 156, 1)",
    borderRadius: "8px 8px 0 0",
    padding: "10px",
    color: "#fff",
    fontFamily: "Montserrat",
    fontSize: "18px",
    fontWeight: 600,
  },
  tableHeadingChildrenFirst: {
    display: "flex",

    width: "100%",
    marginLeft: "10px",
  },
  tableHeadingText: {
    fontFamily: "Montserrat",
    fontSize: "18px",
    fontWeight: 600,
  },

  width48: {
    width: "48%",
  },
  tableBody: {
    minHeight: "400px",
    boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
    border: "0",
    borderRadius: "0 0 8px 8px",
    padding: "10px",
  },
  tableBodyFirstChild: {
    display: "flex",
    justifyContent: "space-between",
  },
  tableBodySecondChild: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    flexGrow: 0,
  },
  tableBodyInnerChild: {
    border: "1px solid rgba(236, 236, 236, 1)",
    flexGrow: 2,
    display: "flex",
    // gap: "22rem",
    paddingLeft: "10px",
    height: "4rem",
    alignItems: "center",
    justifyContent: "flex-start",
    borderRadius: "8px",
    width: " 100%",
  },
  noData: {
    border: "1px solid rgba(236, 236, 236, 1)",
    flexGrow: 2,
    display: "flex",
    // gap: "22rem",
    paddingLeft: "10px",
    height: "4rem",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "8px",
    width: " 100%",
  },
  bodyText: {
    fontFamily: "Montserrat",
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: "22px",
  },
  margin: {
    margin: "2rem 0",
  },
  parentContainer: {
    width: "100%",
    padding: "10px 49px",
  },
  taxListContainer: {
    "@media (max-width: 600px)":{
      width: 'calc(100% - 74px)'
    }
  },
  titleContainer: {
    display: "flex",
    borderColor: "1px solid red",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "10px 0 0 10px",
    padding: "46px 0",
    flexWrap: 'wrap' as 'wrap'
  },
  titleContainerText: {
    fontSize: "24px",
    fontWeight: 600,
    lineHeight: "29.26px",
    fontFamily: "Montserrat",
  },
  addBtn: {
    backgroundColor: "#204B9C",
    color: "#ffff",
    height: "56px",
    width: "184px",
    marginLeft: "32px",
    textTransform: "none",
    fontSize: "16px",
    fontWeight: 700,
    border: "0",
    "&:hover": {
      backgroundColor: "#204B9C",
      color: "#ffff",
    },
    borderRadius: "8px",
  },
  flex: {
    display: "flex",
    flexDirection: "column",
  },
  headingTextContainer: {
    display: "flex",
    width: " 47%",
    maxWidth: " 100%",
    alignItems: "center",
  },
  addEditCard: {
    borderRadius: "12px",
    boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
    height: "auto",
  },
  editInputBox: {
    display: "flex",
    justifyContent: "space-around",
    boxSizing: "border-box",
    padding: "36px 12px 36px 12px",
  },
  taxBodyContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
  },
  taxContainer: {
    maxWidth: "100%",
    width: "50%",
  },
  error: {
    color: "red",
  },
  buttonContainer: {
    marginTop: "48px",
    display: "flex",
    gap: "2rem",
    "@media (max-width: 600px)":{
      flexDirection: 'column-reverse',
      alignItems: 'center',

    },
  },
  marginTop48: {
    marginTop: "48px",
  },

   // Customizable Area End
};
