import React, { useEffect } from "react";
import {
  createTheme,
  ThemeProvider,
  makeStyles,
  Theme,
  createStyles,
} from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { Box } from "@material-ui/core";
import CustomInput from "../customComponents/CostumInput.web";
import TabPanel from "./TabPanel.web";
import { FilterCustomAutocomplete, InputField } from "../customComponents/CustomTags.web";

function a11yProps(index: any) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      border: "1px solid",
      padding: theme.spacing(1),
      backgroundColor: theme.palette.background.paper,
    },

    activeMenuItem: {
      backgroundColor: "#204B9C",
      color: "#ffffff",
    },
    selectedTab: {
      // Add your custom styles here
      // For example:
      backgroundColor: "#204B9C",
      color: "#fff",
    },
    indicator: {
      display: "none", // Hide the indicator as it's not needed in this vertical layout
    },
    tabLabel: {
      justifyContent: "flex-start",
      textAlign: "left",
      textTransform: "none",
      fontSize: "1rem", // Use relative units like 'rem'
      fontWeight: 500,
      fontFamily: "Montserrat",
      color: "rgba(0, 0, 0, 1)",
      opacity:1,
    },
    tabWrapper: {
      // Your custom styles for the tab wrapper
      // For example:
      alignItems: "flex-start",
    },
    boxRoot: {
      padding: 0,
    },
    flexBox: {
      display: "flex",
      gap: "0.625rem", // Use 'rem' instead of pixels for spacing
      flexDirection: "column",
      marginRight: "0.625rem", // Use 'rem' instead of pixels for spacing
      padding: "0 1.125rem", // Use 'rem' instead of pixels for spacing
      paddingLeft: '30px',
    },
    inputHeading: {
      color: "rgba(0, 0, 0, 1)",
      fontSize: "1rem", // Use relative units like 'rem'
      fontWeight: 600,
    },
  })
);

const theme = createTheme({
  overrides: {
    MuiTabs: {
      root: {
        "& .Mui-selected": {
          // Add your custom styles here
          // For example:
          backgroundColor: "#204B9C",
          color: "#fff",
          width: "12.9375rem", // Use 'rem' instead of pixels for width
        },
      },
    },
    MuiTab: {
      wrapper: {
        opacity:1,
        textAlign: "left",
        alignItems: "flex-start",
        marginLeft: "1.1rem",
        // Add any additional styles for the wrapper class
      },
    },
  },
});

const imgTypes = [
  { option: "Icon", selected: false, id: "1", key: "filter_by[image_type]" },
  { option: "Image", selected: false, id: "2", key: "filter_by[image_type]" },
];

const orders = [{ option: "Online", id: "1" }, { option: "Offline", id: "2" }];

interface PropTypes {
  getSelectedValue: Function;
  selectedTabs: any;
  getCurrrentTabValue:Function;
  setGalleryName: any;
  optionsList: any;
}

const VerticalGalleryTabs = ({ getSelectedValue,getCurrrentTabValue, selectedTabs, setGalleryName, optionsList }: PropTypes) => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [selectedImage, setSelectedImage] = React.useState<null | string>(null);
  const [selectedOrder, setSelectedOrder] = React.useState<null | string>(null);

  useEffect(() => {
    if (value !== 1) {
      setSelectedImage(null);
    }
    if (value !== 3) {
      setSelectedOrder(null);
    }
  }, [value]);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
    getCurrrentTabValue(newValue);
  };

  const handleImageType = (item: any) => {
   
    const updatedData =  {...selectedTabs, type :
      {
        key: item.key,
        value: item.option,
        id: item.id,
      }
    }
   
    getSelectedValue(updatedData);
  };

  const handleOrder = (id: string) => {
    setSelectedOrder(id);
  };

  const getInputValue = (value: any) => {

    const updatedData =  {...selectedTabs, name: {
      key: "filter_by[name]",
      value: value,
    } }


    getSelectedValue(updatedData);
  };

  return (
    <ThemeProvider theme={theme}>
      <div style={{ display: "flex", width: "100%" }}>
        <Tabs
          orientation='vertical'
          variant='scrollable'
          value={value}
          onChange={handleChange}
          aria-label='Vertical tabs example'
          classes={{ indicator: classes.indicator }}
        >
          <Tab
            style={{ minWidth: 0 }}
            className={classes.tabLabel}
            label='Name'
            {...a11yProps(0)}
          />
          <Tab
            style={{ minWidth: 0 }}
            className={classes.tabLabel}
            label='Type'
            {...a11yProps(1)}
          />
        </Tabs>
        <TabPanel value={value} index={0}>
          <Box className={classes.flexBox}>
            <Box className={classes.inputHeading}>Name </Box>
            <FilterCustomAutocomplete
                data-test-id="autoCompleteDropdown"
                id="service_name"
                name="service_name"
                options={optionsList?.map((option: any) => option.label) || []}
                inputValue={selectedTabs.name.value || ""}
                value={selectedTabs.name.value || ""}
                onChange={(_: any, value: any) => { getInputValue(value);setGalleryName(value); }}
                onInputChange={(_: any, value: any) => { getInputValue(value);setGalleryName(value); }}
                clearOnBlur={false}
                clearOnEscape={false}
                renderInput={(params: any) => (
                    <InputField
                        {...params}
                        variant="outlined"
                        placeholder="Type and select"
                        value={selectedTabs.name.value}
                        InputProps={{...params.InputProps,endAdornment:(<></>)}}
                    />
                )}
                ListboxProps={
                    {
                        style: {
                            maxHeight: '150px',
                        }
                    }
                }
            />
          </Box>
        </TabPanel>

        <TabPanel value={value} index={1}>
          <Box style={{ display: "flex", flexDirection: "column" }}>
            {imgTypes.map((item) => (
              <Box
                key={item.id} // Add a unique key for each item in the map
                onClick={() => handleImageType(item)}
                data-test-id="option"
                data-test-isChecked={selectedTabs.type.id === item.id}
                style={
                  selectedTabs.type.id === item.id
                    ? {
                        backgroundColor: "rgba(32, 75, 156, 1) ",
                        color: "#fff",
                        // padding: "0.90625rem 1.125rem",
                        height:"48px",
                        // marginTop: "0.1875rem",
                        display:'flex',
                        alignItems:'center',
                        paddingLeft:'30px'

                      }
                    : { 
                      // padding: "0.90625rem 1.125rem", 
                      // marginTop: "-0.1875rem", 
                     display:'flex',
                     alignItems:'center',
                     height:"48px",
                     paddingLeft:'30px',
                     fontWeight:500,
                     fontSize:'14px', 
                  }
                }
              >
                {item.option}
              </Box>
            ))}
          </Box>
        </TabPanel>
      </div>
    </ThemeProvider>
  );
};

export default VerticalGalleryTabs;
