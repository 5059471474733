// Customizable Area Start
import React, { useState, useEffect } from "react";
import {
	DragDropContext,
	Droppable,
	Draggable,
} from "react-beautiful-dnd";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import DragHandleIcon from "@material-ui/icons/DragHandle";
import SortingTableHeader, { ISortingData } from "../SortingTableHeader";
const sortIcon = require('./assets/sort_arrow.png')
import { styled } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { colors } from "../../../blocks/utilities/src/Colors";
import { PermissionStatus, renderBaseOnConditions } from "../../../blocks/utilities/src/CustomBlockHelpers";
// Customizable Area End

// Customizable Area Start
const TableList = ({isLoading, data, handleDragAndDrop, openModalId,isDragableDisabled, handleOpenModel, permission, handleView, handleEdit, handleDeActivate, handleActivate, handleQuery, handleUpchargeList }: any) => {
	const [tableData, setTableData] = useState<any>([]);
	const [sortingData, setSortingData] = useState<any>({
		name: '',
		price: '',
		product_name: ''
	});

	useEffect(() => {
		setTableData(data);
	}, [data]);

	const handleDragEnd = (result: any) => {
		if (!result.destination) return;

		const orderNumberTop = tableData?.[0]?.order_number
		const updatedData = Array.from(tableData);
		const movedItem = updatedData.splice(result.source.index, 1)[0];
		updatedData.splice(result.destination.index, 0, movedItem);

		updatedData.forEach((item: any, index: number) => {
			item.order_number = orderNumberTop - index;
		});

		setTableData(updatedData);

		const affectedItems: any[] = updatedData
			.slice(
				Math.min(result.source.index, result.destination.index),
				Math.max(result.source.index, result.destination.index) + 1
			)
			.map((item: any) => ({
				id: item.id,
				order_number: item.order_number,
			}));
		handleDragAndDrop(affectedItems)
	};

	const sortingProps = {
		onQueryChange: (query: string) => handleQueryChange(query),
		onChange: (sortingData: ISortingData) => setSortingData(sortingData),
	};

	const styleProps1 = {width: '50%'}
	const styleProps2 = {width: '20%'}
	const styleProps3 = {width: '20%'}

	const handleQueryChange = async (query: string) => {
		await handleQuery(query);
		await handleUpchargeList();
	};

	const permissionStatus: PermissionStatus = permission;

	return (
		<>
			<div className="container-table">
				<DragDropContext onDragEnd={handleDragEnd}>
					<ul className="header-ul">
						<li className="header-list header-color">
							<StyledHeadeSection className="header-section">
								<SortingTableHeader
									sortingData={sortingData}
									title='Upcharge Name'
									sortingKey='name'
									{...styleProps1}
									{...sortingProps}
									type='left'
								/>
								<SortingTableHeader
									sortingData={sortingData}
									title='Price'
									sortingKey='price'
									{...styleProps2}
									{...sortingProps}
									type='left'
								/>
								<SortingTableHeader
									sortingData={sortingData}
									title='Product'
									sortingKey='product_name'
									{...styleProps3}
									{...sortingProps}
									type='left'
								/>
							</StyledHeadeSection>
							<p></p>
						</li>
					</ul>
					<Droppable droppableId="upcharge">
						{(provided) => (
							<ul
								{...provided.droppableProps}
								ref={provided.innerRef}
								className="upcharge-list"
								data-test-id="upcharge-list"
								>
								{tableData?.length !== 0 && !isLoading ? tableData?.map((item: any, index: number) => {
									const itemPrice = item.upchargeType === "SAR"
										? `SAR ${(item.price).toFixed(1) ?? ''}`
										: `${item.price ?? ''}%`
									return (
										<Draggable
											key={item.id}
											draggableId={item.id.toString()}
											isDragDisabled={isDragableDisabled ? true : false}
											index={index}>
											{(provided) => (
												<li
													{...provided.draggableProps}
													{...provided.dragHandleProps}
													ref={provided.innerRef}
													data-test-id="list-item"
													key={item.id}
													className={`header-list border-list`}
												>
													<p className={`drag-icon ${!item?.active && "list-opacity"}`} data-test-id={`rowDraggable-${index}`}>
														<DragHandleIcon />
													</p>
													<div className={`option-section ${!item?.active && "list-opacity"}`}>
														<StyledOptionItem className="option-item">
															{item.name}
														</StyledOptionItem>
														<StyledSelectItemInner className="section-item-inner">
															{itemPrice}
														</StyledSelectItemInner>
														<StyledSelectItemInnerOnline className="section-item-inner-online">
															{item.product.length}
														</StyledSelectItemInnerOnline>
													</div>
													<div className="position-relative">
														<div
															data-test-id={`iconMenu_${item.id}`}
															onClick={() => handleOpenModel(item.id)}
															className="icon-moreVert">
															<MoreVertIcon />
														</div>
														{openModalId === item.id && (
																<div className="postion-container" data-test-id="position-container">
																	{
																		renderBaseOnConditions(
																			permissionStatus.viewPermission,
																			<button
																				data-test-id={`viewBtn_${item.id}`}
																				onClick={() => handleView(item)}
																			>
																				View
																			</button>,
																			<></>
																		)
																	}
																	{
																		renderBaseOnConditions(
																			permissionStatus.editPermission,
																			<button
																				data-test-id={`editBtn_${item.id}`}
																				onClick={() => handleEdit(item)}
																			>
																				Edit
																			</button>,
																			<></>
																		)
																	}
																	{
																		renderBaseOnConditions(
																			permissionStatus.deactivatePermission && item?.active,
																			<button
																				data-test-id={`deleteBtn_${item.id}`}
																				onClick={() => handleDeActivate(item)}
																			>
																				{"Deactivate"}
																			</button>,
																			<></>
																		)
																	}
																	{
																		renderBaseOnConditions(
																			permissionStatus.activatePermission as boolean && !item?.active,
																			<button
																				data-test-id={`deleteBtn_${item.id}`}
																				onClick={() => handleActivate(item)}
																			>
																				{"Activate"}
																			</button>,
																			<></>
																		)
																	}
																	{
																		renderBaseOnConditions(
																			!permissionStatus.viewPermission &&
																			!permissionStatus.editPermission &&
																			!permissionStatus.activatePermission &&
																			!permissionStatus.deactivatePermission ,
																			<span>
																				No Permissions
																			</span>,
																			<></>
																		)
																	}
																</div>
															)}
													</div>
												</li>
											)}
										</Draggable>
									)
								}): 
							   !isLoading && <div style={{padding: '15px 0', textAlign: 'center'}}>No result found</div>}
								{provided.placeholder}
								<div className="tableRow" style={{padding:"10px"}} >
									{isLoading && [...Array(5)].map((_, cellIndex) => (
										      <Skeleton animation='wave' style={{ margin: "5px 0" }} variant='text' />

										))}
								</div>
							</ul>
						)}
					</Droppable>
				</DragDropContext>
			</div>
		</>
	);
};

const StyledOptionItem = styled('p')({
	flexBasis: "50%",
	fontSize: "16px",
	fontWeight: 600,
	color: colors().viewTextColor,
	"@media only screen and (max-width: 1024px)": {
		fontSize: "12px",
		fontWeight: 400,
		color: colors().viewTextColor,
	},
});

const StyledSelectItemInner = styled('p')({
	flexBasis: "20%",
	fontSize: "16px",
	fontWeight: 600,
	color: colors().viewTextColor,
	"@media only screen and (max-width: 1024px)": {
		fontSize: "12px",
		fontWeight: 400,
		color: colors().viewTextColor,
	},
});

const StyledSelectItemInnerOnline = styled('p')({
	flexBasis: "18%",
	fontSize: "16px",
	fontWeight: 600,
	color: colors().viewTextColor,
	"@media only screen and (max-width: 1024px)": {
		fontSize: "12px",
		fontWeight: 400,
		color: colors().viewTextColor,
	},
});

const StyledHeadeSection = styled('div')({
	padding:"0px !important"
});


export default TableList;
// Customizable Area End
