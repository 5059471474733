// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { apiCall, makeApiMessage } from "../../../components/src/common";

import ImportExportWebAdapter from "../../adapters/src/ImportExportWebAdapter";
import { getCustomEnumName, CustomEnums, navigateTo, randomNumberGenerator, customPermissionApiKey, checkForNewPermissonStatus, PermissionStatus, checkForImportExportPermissionStatus } from "../../utilities/src/CustomBlockHelpers"
import { IImportCSVResponse } from "../../importexportdata/src/ImportExportData.web";
import { PermissionGroupArray } from "../../../blocks/navigationmenu/src/utils";
import { IUserContext } from "../../../blocks/navigationmenu/src/PageContainerController.web";
// Customizable Area End
export const configJSON = require("./config");

export interface Props {
  navigation?: any;
  id?: string;
  // Customizable Area Start

  // Customizable Area End
}

interface S {
  // Customizable Area Start
  openModalId: number | null;
  upchargeList: UpchargeListData[];
  open: boolean;
  deActivatedItem: UpchargeListData,
  deactivated: boolean,
  isLoading: boolean,
  meta: {};
  selectedFilterMenu: string;
  anchorElFilter: HTMLElement | null;
  selectedFilterRange: number[];
  filtersInitialValues: {name: string, price: string};
  upchargeValue: string;
  priceValue: string;
  priceRange: string | number[];
  sortFilter: {
    name: boolean,
    price: boolean,
    product: boolean
  };
  query: string;
  upchargeQuery: string;
  isDragableDisabled:boolean
  errorSnackbarOpen: boolean;
  snakcbarSeverity: "error" | "warning" | "info" | "success";
  errorMessage: string;
  upchargePagination: {
    next_page: number;
    pervious_page: number;
    total_pages: number;
    total_count: number;
    current_page: number
  };
  pageSize: number;
  page: number;
  optionsList: {label: string, value: string}[];
  upchangeExpanded:boolean;
  upchangeIsDropdown:boolean;
  upchangeOpenModal:boolean;
  openUpchargeModal:boolean;
  openAction: EventTarget & HTMLButtonElement | null;
  openImportModal: boolean;
  setLoaded: number;
  uploadedFile: File | null;
  permissionStatus: PermissionStatus;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;

  // Customizable Area End
}
// Customizable Area Start
interface UpchargeListData {
  id?: number,
  active?: boolean,
  name?: string,
  price?: number,
  upchargeType?: string,
  product?: number[],
  order_number?: number,
}

interface IUpcharge {
  id: string,
  type: string,
  attributes: {
    active: boolean
    catalogues: {
      data: {
        id: string,
        type: string,
        attributes: {
          id: number
          name: string
          product_name: string
          product_second_name: string
        }
      }[]
    }
    name: string
    name_translation: string
    order_number: number
    price: number
    second_name: string
    upcharge_type: string
  }
}
// Customizable Area End

export const filterMenu = {
  upchargeName: 'Upcharge Name',
  price: 'Price'
}

export default class UpchargeListController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getUpchargeListApiId: string = ""
  deactivateApiId: string = ""
  activateApiId: string = ""
  putDragDropApiId: string = ""
  upchargeSuggessionApiCallId: string = ""
  // upchargeExportDataApiCallId: string = ""
  exportFileApiId: string = "";
  importFileApiId: string = "";
  adapter: ImportExportWebAdapter;
  exportUpchargeTemplateApiId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
      getCustomEnumName(CustomEnums.ImportExportPopupDoneMessage),
      getCustomEnumName(CustomEnums.ImportExportPopupFileUploadMessage),
      getCustomEnumName(CustomEnums.ImportExportClearFileMessage),
      getCustomEnumName(CustomEnums.ImportExportPopupCloseButtonClicked),
      getCustomEnumName(CustomEnums.ImportExportPopupClose),
      getCustomEnumName(CustomEnums.ImportExportErrorPopupGoBack),
    ];
    this.adapter = new ImportExportWebAdapter();
    this.state = {
      openModalId: null,
      isLoading:false,
      upchargeList: [],
      open: false,
      deActivatedItem: {},
      deactivated: false,
      meta: {},
      selectedFilterMenu: filterMenu.upchargeName,
      anchorElFilter: null,
      selectedFilterRange: [0, 100],
      filtersInitialValues: { name: '', price: '' },
      upchargeValue: '',
      priceValue: '',
      priceRange: '',
      sortFilter: {
        name: false,
        price: false,
        product: false
      },
      query: '',
      upchargeQuery: '',
      isDragableDisabled:false,
      errorSnackbarOpen: false,
      snakcbarSeverity: 'error',
      errorMessage: '',
      upchargePagination: {
        next_page: 1,
        pervious_page: 1,
        total_pages: 1,
        total_count: 1,
        current_page: 1
      },
      pageSize: 10,
      page: 0,
      optionsList: [],
      upchangeExpanded:false,
      upchangeIsDropdown:false,
      upchangeOpenModal:false,
      openUpchargeModal:false,
      openAction: null,
      openImportModal: false,
      setLoaded: 0,
      uploadedFile: null,
      permissionStatus: {
        mainPermission: false,
        createPermission: false,
        viewPermission: false,
        editPermission: false,
        deactivatePermission: false
      }
    };
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      this.handleResponse(apiRequestCallId, message);
    }
    
    if (from === getCustomEnumName(CustomEnums.ImportExportPopupDoneMessage)) {
      this.handleImportFile()
    }

    if(getCustomEnumName(CustomEnums.ImportExportErrorPopupGoBack) === message.id) {
      this.handleBackToListPage();
    }
    
    if (from === getCustomEnumName(CustomEnums.ImportExportPopupFileUploadMessage)) {
      this.handleFileUpload(message.properties.fileEvent)
    }
    
    if (from === getCustomEnumName(CustomEnums.ImportExportClearFileMessage)) {
      this.setState({ uploadedFile: message.properties.uploadedFile, setLoaded: message.properties.setLoaded });
    }
    
    if (from === getCustomEnumName(CustomEnums.ImportExportPopupCloseButtonClicked)) {
      this.handleImportExportModalClose();
    }
    // Customizable Area End
  }

  componentDidMount(): any {
    // Customizable Area Start
    this.getUpchargeListData(1)
    // Customizable Area End
  }

  // Customizable Area Start
  componentDidUpdate(prevProps: Props, prevState: S) {
    if (prevState.upchargeQuery !== this.state.upchargeQuery 
      || prevState.upchargeValue !== this.state.upchargeValue 
      || prevState.priceValue !== this.state.priceValue
      || prevState.selectedFilterRange !== this.state.selectedFilterRange
      || prevState.query !== this.state.query

      ) {
      this.handleDeActivateDragable()
    }
  }
handleDeActivateDragable = ()=> {
  const selectedFilterRange = this.state.selectedFilterRange;
  const targetRange = [0, 100];
  const isRangeEqual = selectedFilterRange.length === targetRange.length &&
    selectedFilterRange.every((value:number, index:number) => value === targetRange[index]);
    if(this.state.upchargeQuery === "" && this.state.upchargeValue === "" && this.state.priceValue === "" 
    && isRangeEqual && this.state.query === ""
    ){
        this.setState({isDragableDisabled:false})
    }else {
        this.setState({isDragableDisabled:true}) 
    }
}
  handleResponse = (apiRequestCallId: string, message: Message) => {
    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    let errorReponse = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );

    if (apiRequestCallId && responseJson) {
      switch (apiRequestCallId) {
        case this.getUpchargeListApiId:
          const dataBody = responseJson.data.map((upcharge: IUpcharge): UpchargeListData => {
            const { name, price, upcharge_type, active, catalogues, order_number } = upcharge.attributes
            const cataloguesData = catalogues.data.map((catalogue: {id: string, type: string, attributes: {}}) => parseInt(catalogue.id))
            return {
              id: parseInt(upcharge.id),
              name: name,
              active: active,
              product: cataloguesData,
              price: price,
              upchargeType: upcharge_type,
              order_number: order_number
            }
          })
          const meta = responseJson.meta
          this.setState({isLoading:false, upchargeList: dataBody, meta: meta, upchargePagination: meta, anchorElFilter: null })
          break;
        case this.deactivateApiId:
          this.setState({ deactivated: true })
          this.getUpchargeListData(this.state.page + 1)
          break;
        case this.activateApiId:
          this.getUpchargeListData(this.state.page + 1)
          break;
        case this.putDragDropApiId:
          this.setState({})
          break;
        case this.upchargeSuggessionApiCallId:
          const list = responseJson?.suggestion?.map((value: string) => ({ label: value, value })) || [];
          this.setState({ optionsList: list });
          break;
        case this.importFileApiId:
          this.handleImportCsvFileResponse(responseJson)
            break;
        case this.exportFileApiId:
          this.exportCSVfileRes(responseJson)
            break;
        case this.exportUpchargeTemplateApiId:
          this.exportUpchargeTemplateCSVfileRes(responseJson)
            break;
          
      }
    } else if (errorReponse) {
       this.setState({errorMessage: errorReponse?.errors[0]?.message}, () => {
          this.setState({errorSnackbarOpen: true, snakcbarSeverity: 'error',isLoading:false})
        })
    }
    if (responseJson?.errors) {
      this.setState({errorMessage: responseJson?.errors[0]?.message || responseJson.errors?.[Number(Object.keys(responseJson?.errors))][0]}, () => {
        this.setState({errorSnackbarOpen: true})
      })
    }
  }

  handleOpenMenu = (itemId: number) => {
    this.setState((prevState) => ({
      openModalId: prevState.openModalId === itemId ? null : itemId,
    }));
  };

  handleChangePrice = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({priceValue: event.target.value})
  }

  onFilterClick = () => {
    this.setState({priceRange: "range"})
  }

  onFilterBtnClick = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    this.setState({ anchorElFilter: event?.currentTarget }) 
  }

  onClearBtnClick = () => {
    this.setState({selectedFilterMenu: 'Upcharge Name', upchargeValue: '', priceValue: '', selectedFilterRange: [0, 100]})
  }

  handleCloseConfirmModal = () => {
    this.setState({ open: false, })
  }

  handleDeactivate = () => {
    this.deActivateUpcharge(this.state.deActivatedItem)
    this.setState({ open: false })
  };

  handleGoBackToListing = () => {
    this.setState({ deactivated: false })
  };

  handleAdd = () => {
    this.props.navigation.history.push("/Products-UpchargeListAdd")
  }
  handleViewClick = (item: UpchargeListData) => {
    this.props.navigation.history.push(`Products-UpchargeListView/${item.id}`, { state: item })

  }
  handleEditClick = (item: UpchargeListData) => {
    this.props.navigation.history.push(`Products-UpchargeListEdit/${item.id}`, { state: item })
  }
  handleDeactivateClick = (item: UpchargeListData) => {
    this.setState({
      deActivatedItem: item,
      open: true,
      openModalId: null
    })
  }

  handleFilterPopupClose = () => {
    this.setState({ selectedFilterMenu: filterMenu.upchargeName, anchorElFilter: null })
  };

  handleFilterMenuItemClick = (menu: string) => {
    this.setState({ selectedFilterMenu: menu })
  };

  setSelectedFilterRange = (value: number[]) => {
    this.setState({ selectedFilterRange: value })
  }

  handleCloseFilter = () => {
    this.setState({
      selectedFilterMenu: filterMenu.upchargeName,
      anchorElFilter: null,
      selectedFilterRange: [0, 100],
      filtersInitialValues: { name: '', price: '' },
      upchargeValue: '',
      priceValue: '',
      priceRange: [0, 100]
    }, () => {this.getUpchargeListData(1)})
  }

  // APIs
  getUpchargeListData = (page: number) => {
    this.setState({isLoading: true})
    const headers = {
      "Content-Type": configJSON.applicationJsonContentType,
      token: window.localStorage.getItem(configJSON.token)
    };

    let apiUrl;

    let upcharge = this.state.upchargeValue;
    let price = this.state.priceValue;
    let query = this.state.query;
    let upchargeQuery = this.state.upchargeQuery;

    apiUrl = configJSON.filtersAPIEndPoint +
      `?page_no=${page}&per_page=10&skip_extra_attribute=true` +
      (upcharge ? `&filter_by[upcharge_name]=${this.state.upchargeValue}` : '') +
      (price ? `&filter_by[price]=${this.state.priceValue}` : '') +
      (query ? `${this.state.query}` : '') +
      (upchargeQuery ? `&filter_by[query]=${this.state.upchargeQuery}` : '')

    if (this.state.selectedFilterMenu === "Price" && this.state.priceRange === 'range') {
      apiUrl = configJSON.filtersAPIEndPoint +
        `?page_no=${page}&per_page=10&skip_extra_attribute=true` +
        (upcharge ? `&filter_by[upcharge_name]=${this.state.upchargeValue}` : '') +
        (price ? `&filter_by[price]=${this.state.priceValue}` : '') +
        (query ? `${this.state.query}` : '') +
        (upchargeQuery ? `&filter_by[query]=${this.state.upchargeQuery}` : '') +
        (`&filter_by[start_price]=${this.state.selectedFilterRange[0]}&filter_by[end_price]=${this.state.selectedFilterRange[1]}`)
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getUpchargeListApiId = requestMessage.messageId;
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handlePageChange = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    page: number
  ) => {
    this.setState({page: page})
    const headers = {
      "Content-Type": configJSON.applicationJsonContentType,
      token: window.localStorage.getItem(configJSON.token)
    };

    let apiUrl;

    let upcharge = this.state.upchargeValue;
    let price = this.state.priceValue;
    let query = this.state.query;
    let upchargeQuery = this.state.upchargeQuery;

    apiUrl = configJSON.filtersAPIEndPoint +
      `?page_no=${page + 1}&per_page=10&skip_extra_attribute=true` +
      (upcharge ? `&filter_by[upcharge_name]=${this.state.upchargeValue}` : '') +
      (price ? `&filter_by[price]=${this.state.priceValue}` : '') +
      (query ? `${this.state.query}` : '') +
      (upchargeQuery ? `&filter_by[query]=${this.state.upchargeQuery}` : '')

    if (this.state.selectedFilterMenu === "Price" && this.state.priceRange === 'range') {
      apiUrl = configJSON.filtersAPIEndPoint +
        `?page_no=${page + 1}&per_page=10&skip_extra_attribute=true` +
        (upcharge ? `&filter_by[upcharge_name]=${this.state.upchargeValue}` : '') +
        (price ? `&filter_by[price]=${this.state.priceValue}` : '') +
        (query ? `${this.state.query}` : '') +
        (upchargeQuery ? `&filter_by[query]=${this.state.upchargeQuery}` : '') +
        `&filter_by[start_price]=${this.state.selectedFilterRange[0]}&filter_by[end_price]=${this.state.selectedFilterRange[1]}`
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getUpchargeListApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiUrl
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  deActivateUpcharge = (item: UpchargeListData) => {
    const headers = {
      "Content-Type": configJSON.applicationJsonContentType,
      token: window.localStorage.getItem(configJSON.token)
    };
    const httpBody = { deactivated: true }
    const apiUrl = configJSON.deactivateUpchargeEndpoint + item.id + configJSON.textDeactivate

    const message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.deactivateApiId = message.messageId;
    message.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiUrl
    );
    message.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    message.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    message.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpUpdateMethodType
    );
    runEngine.sendMessage(message.id, message);
  }

  activateUpcharge = (item: UpchargeListData) => {
    const headers = {
      "Content-Type": configJSON.applicationJsonContentType,
      token: window.localStorage.getItem(configJSON.token)
    };
    const httpBody = { activated: true }
    const apiUrl = configJSON.deactivateUpchargeEndpoint + item.id + configJSON.textActivate

    const message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.activateApiId = message.messageId;
    message.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiUrl
    );
    message.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    message.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    message.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpUpdateMethodType
    );
    runEngine.sendMessage(message.id, message);
  }

  putDragAndDrop = (items: {id:number, order_number: number}) => {
    const headers = {
      "Content-Type": configJSON.categoryApiContentType,
      token: window.localStorage.getItem(configJSON.token)
    };
    const httpBody = {
      data: {
        attributes: items
      }
    }
    const message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.putDragDropApiId = message.messageId;
    message.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.dragAndDropUpchargeEndpoint
    );
    message.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    message.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    message.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPutType
    );
    runEngine.sendMessage(message.id, message);
  }

  handleFilters() {

    let apiUrl;
    let upcharge = this.state.upchargeValue;
    let price = this.state.priceValue;
    let query = this.state.query;
    let upchargeQuery = this.state.upchargeQuery;

    apiUrl = configJSON.filtersAPIEndPoint +
      `?page_no=${this.state.page + 1}&per_page=10&skip_extra_attribute=true` +
      (upcharge ? `&filter_by[upcharge_name]=${this.state.upchargeValue}` : '') +
      (price ? `&filter_by[price]=${this.state.priceValue}` : '') +
      (query ? `${this.state.query}` : '') +
      (upchargeQuery ? `&filter_by[query]=${this.state.upchargeQuery}` : '')

    if (this.state.selectedFilterMenu === "Price" && this.state.priceRange === 'range') {
      apiUrl = configJSON.filtersAPIEndPoint +
        `?page_no=${this.state.page + 1}&per_page=10&skip_extra_attribute=true` +
        (upcharge ? `&filter_by[upcharge_name]=${this.state.upchargeValue}` : '') +
        (price ? `&filter_by[price]=${this.state.priceValue}` : '') +
        (query ? `${this.state.query}` : '') +
        (upchargeQuery ? `&filter_by[query]=${this.state.upchargeQuery}` : '') +
        `&filter_by[start_price]=${this.state.selectedFilterRange[0]}&filter_by[end_price]=${this.state.selectedFilterRange[1]}`
    }
    const headers = {
      "Content-Type": configJSON.categoryApiContentType,
      token: window.localStorage.getItem(configJSON.token)
    };
    const getAccount = apiCall({
      httpBody: {},
      header: headers,
      url: apiUrl,
      httpMethod: configJSON.httpGetType,
    });

    this.getUpchargeListApiId = getAccount.messageId;
    runEngine.sendMessage(getAccount.id, getAccount);
  }

  setUpchargeName = (value: string) => {
    this.setState({ upchargeValue: value});

    let headers = {
      "Content-type": "application/json", 
      token: window.localStorage.getItem(configJSON.token)
    }

    let apiUrl;
    apiUrl = configJSON.upchargeSuggestionAPIEndPoint + `?upcharge_name=${value}`

    const getAccount = apiCall({
      header: headers,
      httpBody: {},
      url: apiUrl,
      httpMethod: configJSON.httpGetType,
    });

    this.upchargeSuggessionApiCallId = getAccount.messageId;
    runEngine.sendMessage(getAccount.id, getAccount);
  }

  handleQuery(getQuery: string) {
    this.setState({query: getQuery})
  }

  onChangeValue = (value: string) => {
    this.setState({upchargeQuery: value, page: 0},()=>this.getUpchargeListData(1))
  }

  handleSnackbarClose = () => {
    this.setState({errorSnackbarOpen: false})
  }

  handleImportCsvFileResponse(response: IImportCSVResponse) {
    if (response) {
      const message = new Message(getCustomEnumName(CustomEnums.ImportExportAPIResponse))
      message.addData('APIresponse', response)
      runEngine.sendMessage(message.id, message);
      if (response?.message) {
        this.setState({ snakcbarSeverity: 'success', errorSnackbarOpen: true, errorMessage: response.message })
        navigateTo({ props: this.props, screenName: "UpchargeList" })
        this.getUpchargeListData(1);
      } else if (response?.status == 500) {
        this.setState({
          snakcbarSeverity: 'error',
          errorSnackbarOpen: true,
          errorMessage: response?.error || ''
        });
        navigateTo({ props: this.props, screenName: "UpchargeList" })
      } else {
        const message = new Message(getCustomEnumName(CustomEnums.ImportExportErrorPopupData))
        message.addData('APIresponse', response);
        runEngine.sendMessage(message.id, message);
        navigateTo({ 
          props: this.props,
          screenName: "ImportExportErrorModal",
          raiseMessage: message
        })
      }
    }
  }

  exportCSVfileRes = (responseJson: {data: {url: string}}) => {
    if (responseJson) {
      location.href = responseJson.data.url
      this.setState({ 
        snakcbarSeverity: 'success', 
        errorSnackbarOpen: true, 
        errorMessage: configJSON.successExcelExportMsg
      });
    }
  }

  exportUpchargeTemplateCSVfileRes = (responseJson: { url: string }) => {
    if (responseJson) {
      location.href = responseJson.url
      this.setState({ 
        snakcbarSeverity: 'success',
        errorSnackbarOpen: true,
        errorMessage: configJSON.successExcelExportMsg
      });
    }
  }

  handleCloseActionList = () => {
    this.setState({ openAction: null })
  }
  
  handleOpenImportModal = () => {
    this.setState({ openImportModal: false, openAction: null });
    let message = new Message(getCustomEnumName(CustomEnums.ImportExportPopupMeassage))
    message.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props,
    )
    this.send(message)
  }
  
  handleUpchargeExportTemplate = () => {
    this.setState({ openAction: null });

    const apiUrl = configJSON.exportUpchargeTemplateCsvApiurl;

    const requestMessage = makeApiMessage({
      url: apiUrl,
      method: configJSON.httpGetType,
    });

    this.exportUpchargeTemplateApiId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);  
  }
  
  handleUpchargeExportCsv = () => {
    this.setState({ openAction: null });

    const apiUrl = configJSON.exportCSVApiUrl;

    const requestMessage = makeApiMessage({
      url: apiUrl,
      method: configJSON.httpGetType,
    });

    this.exportFileApiId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);  
  }

  handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ setLoaded: 0 })
    const file = event.target.files;

    let randomNumber = randomNumberGenerator(1, 9);
    const delay = randomNumber * 25;
    const uploadInterval = setInterval(() => {
      this.setState({
        setLoaded: updateLoadingTime(this.state.setLoaded)
      }, () => {
        const message = new Message(getCustomEnumName(CustomEnums.ImportExportPopupFileMessage))
        message.addData('returnValue', { setLoaded: this.state.setLoaded, file: file && file[0] })
        runEngine.sendMessage(message.id, message)
      })

    }, delay);

    // for adding 20 percent every time
    function updateLoadingTime(prevLoaded: number) {
      if (prevLoaded >= 100) {
        clearInterval(uploadInterval);
        return 100;
      }
      return prevLoaded + 20
    }

    const checkFile = file && file[0];
    this.setState({ uploadedFile: checkFile as File})
  }

  handleImportExportModalClose = () => {
    const message = new Message(getCustomEnumName(CustomEnums.ImportExportPopupClose))
    message.addData('ParentpageRoute', 'UpchargeList')
    runEngine.sendMessage(message.id, message)
  }

  handleImportFile = () => {
    const apiUrl = configJSON.importCsvApiurl;
    
    const formData = new FormData();
    formData.append('data[file]', this.state.uploadedFile as File);

    const requestMessage = makeApiMessage({
      url: apiUrl,
      method: configJSON.httpPostType,
      body: formData,
      isHeader: true
    });

    this.importFileApiId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);    
  };

  handleUpchargePopverAction = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    this.setState({ openAction: event.currentTarget as EventTarget & HTMLButtonElement});
  };

  handleBackToListPage = () => {
    navigateTo({ props: this.props, screenName: "UpchargeList" })
    this.getUpchargeListData(1);
  }

  handleUserChange = (userContext: IUserContext) => {
    const apiKey = customPermissionApiKey.upChargeNewPermission;
    const userData = userContext.user?.attributes.permission_groups;
    const value = checkForNewPermissonStatus(apiKey, userData as Array<PermissionGroupArray>);
    
    const apiKeyImportExport = customPermissionApiKey.dataImportExportPermission;
    const valueImportExportPermission = checkForImportExportPermissionStatus(apiKeyImportExport, userData as Array<PermissionGroupArray>);
    this.setState({
      permissionStatus: {
        ...value,
        exportPermission: valueImportExportPermission.exportPermission
      }
    })
  };
  // Customizable Area End

}
