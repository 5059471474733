import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { IUserContext } from "../../../blocks/navigationmenu/src/PageContainerController.web";
import {
  PermissionStatus,
  checkForNewPermissonStatus,
  customPermissionApiKey
} from "../../../blocks/utilities/src/CustomBlockHelpers";
import { PermissionGroupArray } from "../../../blocks/navigationmenu/src/utils";
import { IFilter } from "../../../components/src/FilterPopover";

import React from "react";
import { TextField } from "@material-ui/core";
import Slider from "@material-ui/core/Slider";
import { ISortingData } from "../../../components/src/SortingTableHeader";
import { getFiterList, makeApiMessage } from "../../../components/src/common";

export interface IPlant {
  id: string;
  type: string;
  attributes: {
    id: number;
    name: string;
    active: boolean;
    region: {
      id: number;
      branch_name: string;
      branch_id: string;
      address: string;
      company_id: number;
      email: string;
      telephone: string;
      others: {
        sdsdsc: string;
        dscdscdscs: string;
      };
      created_at: string;
      updated_at: string;
      branch_short_name: null;
    };
    total_capicity_in_kg: number;
    total_capicity_in_piece: number;
    plant_selections: {
      data: {
        id: string;
        type: string;
        attributes: {
          id: number;
          name: string;
          capicity_in_kg: number;
          capicity_in_piece: number;
        };
      }[];
    };
  };
}

export const configJSON = require("./config");
// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation: any;
  id: string;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  drawerWidthPlant: number;
  popOverOpenedPlant: boolean;
  popOverItemId: string;
  popOverItemStatus: string;
  popOverTopPlant: number;
  popOverLeftPlant: number;
  hoveredButtonPlant: string;
  permissionStatus: PermissionStatus;
  isLoadingPermission: boolean;
  plants: IPlant[];
  page: number;
  pageSize: number;
  totalCounts: number;
  disableModalVisible: boolean;
  deactivatedPlant: boolean;
  filterAnchor: any;
  filters: IFilter[];
  clickedPlant: IPlant | undefined;
  sortingData: ISortingData;
  query: string;
  searchTextPlant: string;
  suggestionFieldTitle: string;
  isSuperAdmin?: boolean;
  assignedRegionIds: string;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class PlantListController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getPlantsCallId: string = "";
  updatePlantCallId: string = "";
  filterSuggestionApiCallId: string ="";
  tableRefsPlant: HTMLElement | null = null;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),

      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      assignedRegionIds: "",
      plants: [],
      permissionStatus: {
        mainPermission: false,
        createPermission: false,
        viewPermission: false,
        editPermission: false,
        deactivatePermission: false,
        activatePermission: false
      },
      page: 1,
      pageSize: 10,
      totalCounts: 0,
      popOverItemId: "",
      popOverItemStatus: "",
      disableModalVisible: false,
      deactivatedPlant: false,
      drawerWidthPlant: 0,
      popOverOpenedPlant: false,
      popOverLeftPlant: 0,
      popOverTopPlant: 0,
      hoveredButtonPlant: "",
      suggestionFieldTitle: "",
      filterAnchor: false,
      clickedPlant: undefined,
      searchTextPlant: "",
      filters: [
        {
          title: "Plant Name",
          type: "autocompolete",
          value: "",
          apiKey: "plant_name",
          options: [],
        },
        {
          title: "Region",
          type: "autocompolete",
          value: "",
          apiKey: "plant_name",
          options: [],
        },
        {
          title: "Plant Capacity",
          type: "custom",
          value: "",
          onClear: () => {
            const filter = this.state.filters.find((item) => item.title === "Plant Capacity");
            filter!.value = JSON.stringify({});
            this.setState({ filters: [...this.state.filters] });
          },
          customRender: () => {
            const filter = this.state.filters.find((item) => item.title === "Plant Capacity");
            const value = JSON.parse((filter?.value as string) || "{}");

            return (
              <div
                style={{
                  marginLeft: "24px",
                  marginRight: "24px",
                }}
              >
                <div>
                  <div style={{ fontWeight: 600, fontSize: "14px", lineHeight: "22px" }}>
                    Plant Capacity in Kg
                  </div>
                  <TextField
                    inputProps={{ "data-test-id": "capacityKgInput" }}
                    key={"capacity_in_kg"}
                    value={value.kg}
                    style={{ marginTop: "4px", width: "190px" }}
                    variant='outlined'
                    type={"text"}
                    placeholder='0.00'
                    onChange={(event) => {
                      filter!.value = JSON.stringify({ ...value, kg: event.target.value });
                      this.setState({ filters: [...this.state.filters] });
                    }}
                  />
                  <div style={{ fontWeight: 500, fontSize: 14, marginTop: 16, marginBottom: 16 }}>
                    or Select Range
                  </div>
                  <div>{value.kgRange ? `${value.kgRange[0]} - ${value.kgRange[1]}` : "0-100"}</div>
                  <div>
                    <Slider
                      value={value.kgRange || [0, 100]}
                      onChange={(event, newValue) => {
                        filter!.value = JSON.stringify({
                          ...value,
                          kgRange: newValue,
                        });
                        this.setState({ filters: [...this.state.filters] });
                      }}
                      min={0}
                      max={100}
                      aria-labelledby='range-slider'
                    />
                  </div>
                  <div
                    style={{
                      height: 1,
                      backgroundColor: "#D9D9D9",
                      marginTop: 32,
                      marginBottom: 32,
                    }}
                  />
                  <div style={{ fontWeight: 600, fontSize: "14px", lineHeight: "22px" }}>
                    Plant Capacity in Pieces
                  </div>
                  <TextField
                    inputProps={{ "data-test-id": "capacityPicesInput" }}
                    key={"capacity_in_pieces"}
                    value={value.capacity_in_pieces}
                    style={{ marginTop: "4px", width: "190px" }}
                    variant='outlined'
                    type={"text"}
                    placeholder='0.00'
                    onChange={(event) => {
                      filter!.value = JSON.stringify({
                        ...value,
                        capacity_in_pieces: event.target.value,
                      });
                      this.setState({ filters: [...this.state.filters] });
                    }}
                  />
                  <div style={{ fontWeight: 500, fontSize: 14, marginTop: 16, marginBottom: 16 }}>
                    or Select Range
                  </div>
                  <div>
                    {value.piecesRange
                      ? `${value.piecesRange[0]} - ${value.piecesRange?.[1]}`
                      : "0-10000"}
                  </div>
                  <div>
                    <Slider
                      min={0}
                      max={10000}
                      value={value.piecesRange || [0, 10000]}
                      onChange={(event, newValue) => {
                        filter!.value = JSON.stringify({ ...value, piecesRange: newValue });
                        this.setState({ filters: [...this.state.filters] });
                      }}
                      aria-labelledby='range-slider'
                    />
                  </div>
                </div>
              </div>
            );
          },
        },
      ],
      sortingData: {
        name: "",
        region_name: "",
        total_capicity_in_kg: "",
        total_capicity_in_piece: "",
      },
      query: "",
      isLoadingPermission: true
      // Customizable Area End
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

      this.handlePlantsResponse(apiRequestCallId, responseJson);
      this.handelPlantUpdateResponse(apiRequestCallId, responseJson);
      this.handelPlantFilterResponse(apiRequestCallId, responseJson);
    }
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start

  handlePlantsResponse(apiRequestCallId: unknown, responseJson: { data: unknown, meta?: { total_count: number } }) {
    if (apiRequestCallId === this.getPlantsCallId) {
      if (responseJson) {
        const plants = responseJson?.data as IPlant[] || [];
        this.setState({
          plants,
          totalCounts: Number(responseJson?.meta?.total_count || 0),
        });
      }
    }
  }

  handelPlantUpdateResponse(apiRequestCallId: unknown, responseJson: unknown) {
    if (apiRequestCallId === this.updatePlantCallId) {
      this.getPlants();
      if (responseJson && this.state.popOverItemStatus === "Active") {
        this.setState({ deactivatedPlant: true });
      }
    }
  }

  handelPlantFilterResponse(apiRequestCallId: unknown, responseJson: unknown) {
    if (apiRequestCallId === this.filterSuggestionApiCallId) {
      if (responseJson) {
        const updatedFilters = getFiterList(responseJson, this.state.filters, this.state.suggestionFieldTitle);
        this.setState({ filters: updatedFilters });
      }
    }
  }

  createData(
    plantName: string,
    region: string,
    kgCapacity: number,
    pieceCapacity: number,
    plantId: string,
    status: string
  ) {
    return { plantName, region, kgCapacity, pieceCapacity, plantId, status };
  }

  handleIsActive = () => {
    this.setState({ popOverItemStatus: "Active" });
  };

  handleDrawerWidthChange = (width: number) => {
    this.setState({ drawerWidthPlant: width });
  };

  getPlants = (page: number = this.state.page) => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: window.localStorage.getItem(configJSON.token),
    };

    const plantNameFilter = this.state.filters.find((item) => item.title === "Plant Name")?.value;
    const regionFilter = this.state.filters.find((item) => item.title === "Region")?.value;
    const plantCapacityFilter = this.state.filters.find((item) => item.title === "Plant Capacity")
      ?.value;
    const kgFilter = JSON.parse((plantCapacityFilter as string) || "{}")?.kg;
    const piecesFilter = JSON.parse((plantCapacityFilter as string) || "{}")?.capacity_in_pieces;
    const start_range_in_kg = JSON.parse((plantCapacityFilter as string) || "{}")?.kgRange?.[0];
    const end_range_in_kg = JSON.parse((plantCapacityFilter as string) || "{}")?.kgRange?.[1];
    const start_range_in_pieces = JSON.parse((plantCapacityFilter as string) || "{}")
      ?.piecesRange?.[0];
    const end_range_in_pieces = JSON.parse((plantCapacityFilter as string) || "{}")
      ?.piecesRange?.[1];
    const searchTextPlant = this.state.searchTextPlant;

    const plantListEndPoint =
      configJSON.plantListApi +
      `?region_ids=${this.state.assignedRegionIds}&page_no=${page}&per_page=${this.state.pageSize}` +
      (plantNameFilter ? `&filter_by[name]=${plantNameFilter}` : "") +
      (regionFilter ? `&filter_by[region]=${regionFilter}` : "") +
      (kgFilter ? `&filter_by[capacity_in_kg]=${kgFilter}` : "") +
      (piecesFilter ? `&filter_by[capacity_in_pieces]=${piecesFilter}` : "") +
      (start_range_in_kg >= 0 ? `&filter_by[start_range_in_kg]=${start_range_in_kg}` : "") +
      (end_range_in_kg ? `&filter_by[end_range_in_kg]=${end_range_in_kg}` : "") +
      (start_range_in_pieces >= 0 ? `&filter_by[start_range_in_pieces]=${start_range_in_pieces}` : "") +
      (end_range_in_pieces ? `&filter_by[end_range_in_pieces]=${end_range_in_pieces}` : "") +
      (searchTextPlant ? `&filter_by[query]=${searchTextPlant}` : "") +
      this.state.query;

    const getDataMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getPlantsCallId = getDataMsg.messageId;

    getDataMsg.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), plantListEndPoint);
    getDataMsg.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    getDataMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
    runEngine.sendMessage(getDataMsg.id, getDataMsg);
  };

  updatePlant = (plantId: string, isActive: boolean) => {
    const header = {
      "Content-Type": configJSON.searchApiContentType,
      token: window.localStorage.getItem(configJSON.token),
    };

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.updatePlantCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.plantDetailEndPoint}/${plantId}/${isActive ? "deactivate" : "activate"}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "PUT");

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handlePageChange = (
    page: number
  ) => {
    this.setState({ page });
    this.getPlants(page);
  };

  handleSearchPlant = (value: string) => {	
    this.setState(	
      {	
        page: 1,
        searchTextPlant: value,	
      },	
      this.getPlants	
    );
  };	

  handleAddPlant = () => {
    this.props.navigation.navigate("PlantAdd");
  };

  handleEditPlant = (plantId: string) => {
    this.setState({ popOverOpenedPlant: false });
    this.props.navigation.navigate("PlantEdit");
    window.localStorage.setItem("plant_id", plantId);
  };

  handleViewPlant = (plantId: string) => {
    this.setState({ popOverOpenedPlant: false });
    this.props.navigation.navigate("PlantView");
    window.localStorage.setItem("plant_id", plantId);
  };

  handleDeactivate = (clickedPlantId: string) => {
    this.setState({
      disableModalVisible: true,
      clickedPlant: this.state.plants.find((item) => item.id === clickedPlantId),
    });
  };

  handleFilterChangePlant = (filters: IFilter[]) => {
    this.setState(
      {
        filters,
        page: 1,
      },
      this.getPlants
    );
  };

  changeActivateUser = () => {
    this.setState({ popOverOpenedPlant: false, disableModalVisible: false });
    this.updatePlant(this.state.popOverItemId, this.state.popOverItemStatus === "Active");
  };

  handleDeactivateClose = () => {
    this.setState({ disableModalVisible: false, popOverOpenedPlant: false });
  };

  handleDeactivatedPlantClose = () => {
    this.setState({ deactivatedPlant: false });
  };

  handleQueryChange = (query: string) => {
    this.setState({ query }, () => this.getPlants());
  };

  sortingProps = {
    width: "18%",
    onQueryChange: (query: string) => this.handleQueryChange(query),
    onChange: (sortingData: ISortingData) => this.setState({ sortingData }),
  };

  handleGoList = () => {
    this.setState({ deactivatedPlant: false });
  };

  handlePlantAutoComplete = (title: string, value: string) => {
    this.state.filters.forEach((item: IFilter) => {
      if (item.title === title) item.value = value;
      else item.value = "";
      return item;
    });

    let ApiUrl;
    if(title === "Plant Name") {
      ApiUrl = configJSON.plantNameSuggestionListApi;
    } else if (title === "Region") {
      ApiUrl = configJSON.plantRegionSuggestionListApi;
    }

    let requestMessage = makeApiMessage({
      url: ApiUrl + value,
      method: "GET",
    });
    this.filterSuggestionApiCallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleUserChange = (userContext: IUserContext) => {
    let regionIds = "";
    if(userContext.user){
      regionIds = userContext.user.attributes.employee_proifle.data.attributes.region_ids.join(",");
    }
    const apiKey = customPermissionApiKey.plantCreation;
    const userData = userContext.user?.attributes.permission_groups;
    const value = checkForNewPermissonStatus(apiKey, userData as Array<PermissionGroupArray>);
    this.setState({
      permissionStatus: value,
      assignedRegionIds: regionIds,
      isLoadingPermission: false
    },()=>this.getPlants())
  };
  // Customizable Area End
}
