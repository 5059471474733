// Customizable Area Start
import React from 'react';

import {GetIconType} from './EditServicesController.web'
import { RawSectionListType, SectionListType} from "./SevicesTypes"
import { toString } from 'lodash';

const parsedAllIcon =  (responseJson: {data: GetIconType[]}, )=>{


    const parsedAllIcons: GetIconType[] = [];

                          const parsedDropdownIconss: {id: string, option: string, image: string}[] = []
                        responseJson.data.forEach((item: GetIconType) => {
 
                            const object: GetIconType = {} as GetIconType;
                            const tempObjs = {
                                id: item.id,
                                option: item.attributes.name,
                                image: toString(item.attributes.image)
                            }
                            object.id = item.id;
                            object.item = (<div style={{ display: "flex", gap: "20px", alignItems: "center" }}>
                                <img src={item.attributes?.image} height={24} width={24} alt={item.attributes.name} />
                                <span>{item.attributes.name}</span>
                            </div>);
                            object.title = item.attributes.name
                            
                            parsedAllIcons.push(object);
                            parsedDropdownIconss.push(tempObjs);

                        })

  return {parsedAllIcons, parsedDropdownIconss}

}


const parseSections =  (responseJson: {data?: RawSectionListType[]})=>{
    const parsedSectionList: SectionListType[] = [];
    const parsedSectionDropdown: {id: string, option: string}[] = []

    responseJson.data?.forEach((item: RawSectionListType) => {

        const sectionItem: SectionListType = {} as SectionListType;
        const dropdownSection: {id: string, option: string}  = {
            id: item.id,
            option: item.attributes.section_name
        }

        sectionItem.id = item.id;
        sectionItem.section_name = item.attributes.section_name

        parsedSectionList.push(sectionItem);
        parsedSectionDropdown.push(dropdownSection);
    })

    return {parsedSectionList, parsedSectionDropdown }

}

const util = {
    parseSections,
    parsedAllIcon
}

export {
    parsedAllIcon,
    parseSections
}
export default util
// Customizable Area End