import React from "react";
import { styled } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";

interface Props {
  loading: boolean;
  disableBackground?: boolean
}

const CustomLoader = ({loading, disableBackground = false}: Props) => {
  const Component = disableBackground ? LoaderWrapperGlobal : LoaderWrapper
  return (
    <>
    {
      loading ? (
        <Component>
          <div className='circular-container'>
            <CircularProgress />
          </div>
        </Component>
      ) : null
    }
    </>
  )
}

  const LoaderWrapperGlobal = styled("div")({
    position: "absolute",
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    zIndex: 10000,
    "& .circular-container": {
      position: "absolute",
      left: "50%",
      top: "40%",
      transform: "translate(-50%, -50%)",
    },
  });
const LoaderWrapper = styled("div")({
  position: "absolute",
  left: 0,
  top: 0,
  right: 0,
  bottom: 0,
  zIndex: 10000,
  backgroundColor: "rgb(0, 0, 0, .4)",
  "& .circular-container": {
    position: "absolute",
    left: "50%",
    top: "40%",
    transform: "translate(-50%, -50%)",
  },
});

export default CustomLoader;
