// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { makeApiMessage } from "../../../components/src/common";
import { ZatcaHistory } from "./utils";
import moment from "moment";
import { IUserContext } from "../../navigationmenu/src/PageContainerController.web";
import {
  checkForOrderPermissonStatus,
  customPermissionApiKey,
} from "../../../blocks/utilities/src/CustomBlockHelpers";
import { PermissionGroupArray } from "../../../blocks/navigationmenu/src/utils";
// Customizable Area End

// Customizable Area Start
export const configJSON = require("./config");
// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation: unknown;
  id: string;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  zatacaHistoryList: Array<ZatcaHistory>;
  isLoading: boolean;
  selectedFromDate: string;
  selectedToDate: string;
  snackbarOpen: boolean;
  snackbarMessage: string;
  snakcbarSeverity: "error" | "success" | "warning";
  permissionStatus: boolean;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class ZatcaHistoryController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getZatcaLogsHistoryCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];
    // Customizable Area End

    // Customizable Area Start
    this.state = {
      zatacaHistoryList: [],
      isLoading: false,
      selectedFromDate: "",
      selectedToDate: "",
      snackbarOpen: false,
      snackbarMessage: "",
      snakcbarSeverity: "error",
      permissionStatus: true,
    };
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    this.getZatcaLogsHistory();
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      if (apiRequestCallId === this.getZatcaLogsHistoryCallId) {
        this.handleZatcaLogsHistoryResponse(message);
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  getZatcaLogsHistory = () => {
    this.setState({ isLoading: true });

    const { selectedFromDate, selectedToDate } = this.state;
    let fromFilterQuery = selectedFromDate
      ? `?start_date=${selectedFromDate}`
      : "";
    let toFilterQuery = selectedToDate ? `&end_date=${selectedToDate}` : "";

    let message = makeApiMessage({
      url:
        configJSON.zatcaLogsHistoryEndPoint + fromFilterQuery + toFilterQuery,
      method: "GET",
    });
    this.getZatcaLogsHistoryCallId = message.messageId;
    runEngine.sendMessage(message.id, message);
  };

  handleZatcaLogsHistoryResponse = (message: Message) => {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (responseJson && !responseJson.error) {
      this.setState({
        zatacaHistoryList: responseJson.data,
      });
    }

    this.setState({ isLoading: false });
  };

  handleSendtozatcaDate = (date: string) => {
    if (date) {
      return `${moment(date).format("L")} ${moment(date).format("LT")}`;
    }
  };

  handleAppliedFilter = () => {
    const { selectedFromDate, selectedToDate } = this.state;
    if (selectedFromDate && selectedToDate) {
      this.getZatcaLogsHistory();
    } else {
      this.setState({
        snackbarOpen: true,
        snackbarMessage: "Please selecte from and to date",
      });
    }
  };

  handleFromDate = (selectedDate: Date | null) => {
    if (selectedDate) {
      this.setState({
        selectedFromDate: moment(selectedDate).format("YYYY-MM-DD"),
      });
    }
  };

  handleToDate = (selectedDate: Date | null) => {
    if (selectedDate) {
      this.setState({
        selectedToDate: moment(selectedDate).format("YYYY-MM-DD"),
      });
    }
  };

  handleSnackbarClose = () => {
    this.setState({
      snackbarOpen: false,
    });
  };

  handleOnUserChange = (userContext: IUserContext) => {
    const apiKey = customPermissionApiKey.orderPermission;
    const userData = userContext.user?.attributes.permission_groups;
    const value = checkForOrderPermissonStatus(apiKey, userData as Array<PermissionGroupArray>);

    this.setState({
      permissionStatus: value.zatcaLogsAccess ? true : false
    });
  }
  // Customizable Area End
}
