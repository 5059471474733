import * as Yup from "yup";

// Customizable Area Start

const Strings = require("./config").Strings.Settings.errors;

export const validationSchema = Yup.object({
  region_id: Yup.string().trim().required(Strings.select_region),
  minimum_order_amount: Yup.number()
    .required(Strings.minimum_order_amount)
    .typeError(Strings.enter_number_only)
    .min(1, Strings.amount_grater_zero),
  maximum_order_amount: Yup.number()
    .required(Strings.maximum_order_amount)
    .typeError(Strings.enter_number_only)
    .min(1, Strings.amount_grater_zero)
    .moreThan(
      Yup.ref("minimum_order_amount"),
      Strings.minimum_order_amount_grater
    ),
  home_cleaning_service_charges: Yup.number()
    .required(Strings.service_charge_amount)
    .typeError(Strings.enter_number_only)
    .min(1, Strings.amount_grater_zero),
  open_slots_per_day: Yup.number()
    .required(Strings.number_of_open_slots)
    .typeError(Strings.enter_number_only)
    .min(1, Strings.amount_grater_zero),
  non_refundable_type: Yup.string()
    .trim()
    .required(Strings.select_flat_rate_percentage),
  min_amount_to_waive_of_service_charge: Yup.number()
    .required(Strings.waive_of_service_charges)
    .typeError(Strings.enter_number_only)
    .min(1, Strings.amount_grater_zero),
  add_by: Yup.number()
    .typeError(Strings.enter_number_only)
    .min(0, Strings.rate_must_be_grater_or_equal)
    .required(Strings.rate_must_be_grater)
    .when("non_refundable_type", {
      is: "percentage",
      then: (schema: Yup.NumberSchema) =>
        schema
          .min(0, Strings.rate_must_be_grater_or_equal)
          .max(100, Strings.rate_must_be_less_then_100)
          .required(Strings.rate_should_be_between_zero),
      otherwise: (schema: Yup.NumberSchema) =>
        schema
          .min(0, Strings.rate_must_be_grater_or_equal)
          .required(Strings.rate_should_be_between_zero),
    })
    .required(Strings.enter_rate),
});
// Customizable Area End
