import React, { useEffect, useRef } from 'react';
import Button from '@material-ui/core/Button';
import Popper from '@material-ui/core/Popper';
import MenuList from '@material-ui/core/MenuList';
import MenuItem from '@material-ui/core/MenuItem';
import Radio from '@material-ui/core/Radio';
import { Box, Typography,styled } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ArrowDropDown } from '@material-ui/icons';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';

const useStyles = makeStyles((theme) => ({
  button: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  '@global': {
    '*::-webkit-scrollbar': {
      width: '6px'
    },
    '*::-webkit-scrollbar-track': {
      '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)'
    },
    '*::-webkit-scrollbar-thumb': {
      backgroundColor: '#d1d1d1',
      borderRadius: '10px'
    }
  }
}));

interface DropdownMenuProps {
  items: any;
  selectedItem: any;
  onSelect: (arg: any) => void;
  setFieldsValue?: (fieldName: string, newFiled: string) => void;
  errorProp?: boolean;
  width?: string;
  withOutRadio?: boolean;
  disabled?: boolean;
  ignoreWidth?: boolean;
  placeholder?: string;
  isIcon?: boolean;
}

const DropdownMenu: React.FC<DropdownMenuProps> = ({
  items,
  selectedItem,
  onSelect,
  setFieldsValue,
  errorProp,
  width,
  withOutRadio,
  disabled = false,
  ignoreWidth,
  placeholder,
  isIcon = false
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const divRef = useRef<HTMLButtonElement | null>(null);

  const popperRef = useRef<HTMLDivElement | null>(null); // Add type assertion for the ref
  const classes = useStyles();
  useEffect(() => {
    const handleClickAway = (event: any) => {
      if (popperRef.current && !popperRef.current.contains(event.target as Node)) {
        handleClose();
      }
    };

    window.addEventListener('mousedown', handleClickAway);

    return () => {
      window.removeEventListener('mousedown', handleClickAway);
    };
  }, []);
  useEffect(() => {
    // Function to get the width of the div
    const getDivWidth = () => {
      const divElement = divRef.current;
      if (divElement) {
        const width = divElement.getBoundingClientRect().width;
      }
    };

    // Call the function when the component mounts
    getDivWidth();

    // You can also call the function whenever the window is resized
    window.addEventListener('resize', getDivWidth);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', getDivWidth);
    };
  }, []);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (anchorEl === event?.currentTarget) {
      handleClose();
    } else {
      setAnchorEl(event?.currentTarget);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (item: any) => {
    onSelect(item);
    setFieldsValue && setFieldsValue('selectedType', item);
    handleClose();
  };

  const CustomRadio: React.FC<{ checked: boolean }> = ({ checked }) => (
    <Radio
      checked={checked}
      icon={
        <span
          style={{
            display: 'inline-block',
            width: 20,
            height: 20,
            borderRadius: '50%',
            border: '1px solid #64748B',
            marginRight: '10px'
          }}
        />
      }
      checkedIcon={
        <span
          style={{
            display: 'inline-block',
            width: 20,
            height: 20,
            borderRadius: '50%',
            border: '6px solid #204B9C',
            color: '#204b9c',
            marginRight: '10px'
          }}
        />
      }
    />
  );

  const isUrl = (string: string) => {
    const urlPattern = /^(?:\w+:)?\/\/([^\s.]+\.\S{2}|localhost[:?\d]*)\S*$/;
    return urlPattern.test(string);
  }

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popper' : undefined;

  return (
    <div>
      <StyledButton
        ref={divRef}
        disabled={disabled}
        type="button"
        aria-describedby={id}
        onClick={handleClick}
        endIcon={open ? <ArrowDropUpIcon style={{ height: "24px", width: "24px" }} /> : <ArrowDropDown style={{ height: "24px", width: "24px" }} />}
        variant="outlined"
        className={classes.button}
        data-test-id='selectDropdown'
        style={{
          width: `${width ? width : '50%'}`,
          minWidth: "250px",
          padding: '15px',
          border: `1px solid ${errorProp ? '#f44336' : '#ECECEC'}`,
          borderRadius: '8px',
          maxWidth: `${ignoreWidth ? '100%' : '530px'}`,
          textTransform: 'none',
          color: 'rgba(0, 0, 0, 0.54)',
        }}
      >
        <div
          style={{
            width: '100%',
            display: 'flex',
            fontWeight: 400,
            fontSize: '14px',
            color: "#4D4D4D",
            fontFamily: 'Montserrat'
          }}
        >
          {selectedItem !== null && typeof selectedItem === 'object' ? (
            <div style={{ display: 'flex', alignItems: 'center', gap: '20px', maxWidth:"100%" }}>
              {selectedItem.item && selectedItem.item?.props?.children && selectedItem.item?.props?.children[0] && (
                <img
                  src={selectedItem.item.props.children[0].props.src}
                  alt={selectedItem.item.props.children[0].props.alt}
                  height={selectedItem.item.props.children[0].props.height}
                  width={selectedItem.item.props.children[0].props.width}
                />
              )}

              {isIcon ? (
                <img src={selectedItem.title} alt="Selected Item" height={24} width={24} />
              ) : (
                <span className='textTitle'>{selectedItem.title}</span>
              )}

            </div>
          ) : (
            <PlaceholderText>
              {selectedItem || placeholder}
            </PlaceholderText>
          )}
        </div>
      </StyledButton>
      <Popper
        ref={popperRef}
        id={id}
        open={open}
        anchorEl={anchorEl}
        placement="bottom-start"
        style={{
          zIndex: 1,
          width: `${ignoreWidth ? divRef.current?.clientWidth : width}`,
          backgroundColor: '#fff',
          borderRadius: '8px',
          marginTop: '4px',
          boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.03)',
        }}
      >
        <MenuList style={{ maxHeight: 164, overflowY: 'auto' }}>
          {items.map((item: any) =>
            typeof item === 'string' && !withOutRadio ? (
              <MenuItem key={item} data-test-id='menuItem' onClick={() => handleMenuItemClick(item)}>
                {!withOutRadio && <CustomRadio data-test-id="radio" checked={selectedItem === item} />}
                <Typography variant="inherit">{item}</Typography>
              </MenuItem>
            ) : (
              <MenuItem key={item?.title} data-test-id={`menuItem-${item.id}`} onClick={() => handleMenuItemClick(item)}>
                {!withOutRadio && (
                  <CustomRadio data-test-id="radio" checked={selectedItem?.title === item?.title} />
                )}
                {withOutRadio ? (
                  <Box style={{ display: 'flex', gap: '10px' }}>
                    {item.item}
                    <Typography variant="inherit">{item.title}</Typography>
                  </Box>
                ) : (
                  <Typography style={{ color: "#4D4D4D", fontWeight: 500, fontSize: '16px', fontFamily: 'Montserrat' }}>{item?.item}</Typography>
                )}
              </MenuItem>
            )
          )}
        </MenuList>
      </Popper>
    </div>
  );
};

const StyledButton = styled(Button)({
  "@media only screen and (max-width: 1024px)": {
      fontSize: '12px',
      height: "44px"
  },
  "& .textTitle": {
    overflow:"hidden",
    textOverflow:"ellipsis",
    whiteSpace:"nowrap",
    maxWidth:"100%"
  }
});


const PlaceholderText = styled("div")({
  fontSize: 14,
  fontWeight: 500,
  lineHeight: "22px",
  fontFamily: "Montserrat",
  color: "var(--neutrals-dark-gray, #4D4D4D)",
  "@media only screen and (max-width: 1024px)": {
      fontSize: '12px',
  },
});


export default DropdownMenu;

