import React from "react";
// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import { IBlock } from "../../../framework/src/IBlock";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { ISortingData } from "../../../components/src/SortingTableHeader";
import { IMeta } from "../../CfPriceList/src/PriceListController.web";
import { apiCall } from "../../../components/src/common";
import { IFilterItem } from "../../promocodes/src/PromoCodeListController.web";
import { IFilter } from "../../../components/src/FilterPopover";
import { IUserContext } from "../../../blocks/navigationmenu/src/PageContainerController.web";
import {
  PermissionStatus,
  checkForNewPermissonStatus,
  customPermissionApiKey
} from "../../../blocks/utilities/src/CustomBlockHelpers";
import { PermissionGroupArray } from "../../../blocks/navigationmenu/src/utils";
export const configJSON = require("./config");
// Customizable Area End

// Customizable Area Start
const ApiUrls = configJSON.ApiUrls;
const Strings = configJSON.Strings.homeCleaningString;
interface HomeCleaningProductType {
  data: {
    id: string;
    type: string;
    attributes: {
      id: number;
      home_cleaning_catalogue_id: number;
      name: string;
      price: string;
      created_at: string;
      updated_at: string;
    }
  }
}

export interface HomeCleanProductData {
  id: string;
  type: string;
  attributes: {
    id: number;
    product_name: string;
    product_second_name: string;
    cleaning_process_notes: string;
    cleaning_expected_duration: string;
    created_at: string;
    updated_at: string;
    image_id: number;
    active: boolean;
    home_cleaning_catalogue_types: HomeCleaningProductType[];
    image: null;
    measurement_type: string[];
    cleaning_type: string[];
  };
}

// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation: any;
  id: string;
  classes: any;
  // Customizable Area End
}

export interface S {
  // Customizable Area Start
  sortingData: ISortingData;
  query: string;
  search_query: string;
  page: number;
  per_page: number;
  meta: IMeta;
  homeCleaningProductList: HomeCleanProductData[];
  openAction: (EventTarget & HTMLButtonElement) | null;
  popverId: undefined | number;
  currentPopver: undefined | HomeCleanProductData;
  filters: IFilterItem[];
  filterAnchor: { currentTarget: {} } | undefined | HTMLDivElement;
  selectedFilterRange: number[];
  priceRange: string;
  openDeactiveModel: boolean;
  deactivated: boolean;
  successSnackbarOpen: boolean;
  successSnackbarMessage: string;
  suggestionFieldName: string;
  permissionStatus: PermissionStatus;
  // Customizable Area End
}

export interface SS {}

export default class HomeCleaningProductController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getHomeCleaningProductListApicallId: string = "";
  deleteProductApiCallId: string = "";
  deactivateProductApiCallId: string = "";
  activateProductApiCallId: string = "";
  productNameSuggestionProductApiCallId: string = "";
  productTypeSuggestionProductApiCallId: string = "";

  handleQueryChange = (query: string) => {
    this.setState({ query }, () => this.getHomeCleaningProducts());
  };

  sortingProps = {
    onQueryChange: (query: string) => this.handleQueryChange(query),
    onChange: (sortingData: ISortingData) => this.setState({ sortingData }),
  };
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  async receive(_from: string, message: Message) {
    // Customizable Area Start
    const successResponseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    const apiRequestId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    switch (apiRequestId) {
      case this.getHomeCleaningProductListApicallId:
        this.handleHomeCleaningProductResp(successResponseJson);
        break;
      case this.deactivateProductApiCallId:
        this.handleDeactivateProductResp(successResponseJson);
        break;
      case this.activateProductApiCallId:
        this.handleActivateProductResp(successResponseJson);
        break;
      case this.productNameSuggestionProductApiCallId:
        this.handleProductNameSuggestionResp(successResponseJson);
        break;
      case this.productTypeSuggestionProductApiCallId:
        this.handleProductTypeSuggestionResp(successResponseJson);
        break;
    }
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    this.getHomeCleaningProducts();
    // Customizable Area End
  }

  // Customizable Area Start
  handleSearchHomeCleaningProduct = (value: string) => {
    this.setState(
      {
        page: 0,
        search_query: value,
      },
      this.getHomeCleaningProducts
    );
  };

  handlePageChange = (page: number) => {
    this.setState(
      {
        page,
      },
      this.getHomeCleaningProducts
    );
  };

  handleClosePopver = () => {
    this.setState({ openAction: null });
  };

  handlepopver = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    currentPopver: HomeCleanProductData
  ) => {
    this.setState({
      openAction: event.currentTarget,
      currentPopver: currentPopver,
    });
  };

  getHomeCleaningProducts = () => {
    const { page, per_page, search_query, filters } = this.state;
    const product_name_filter = filters.find(
      (item) => item.title === "Product Name"
    )?.value;
    const product_type_filter = filters.find(
      (item) => item.title === "Product Type"
    )?.value;
    const product_price_filter = filters.find((item) => item.title === "Price")
      ?.value;
    const price_range_start = JSON.parse(
      (product_price_filter as string) || "{}"
    )?.priceRange?.[0];
    const price_range_end = JSON.parse((product_price_filter as string) || "{}")
      ?.priceRange?.[1];
    const price = JSON.parse((product_price_filter as string) || "{}")?.price;

    let url =
      ApiUrls.getHomeCleanProductApiEndPoint +
      `?page_no=${page + 1}` +
      `&per_page=${per_page}` +
      (product_name_filter
        ? `&filter_by[product_name]=${product_name_filter}`
        : "") +
      (product_type_filter
        ? `&filter_by[product_type]=${product_type_filter}`
        : "") +
      (price ? `&filter_by[price]=${price}` : "") +
      (price_range_start
        ? `&filter_by[start_price]=${price_range_start}`
        : "") +
      (price_range_end ? `&filter_by[end_price]=${price_range_end}` : "") +
      this.state.query +
      (search_query ? `&filter_by[query]=${search_query}` : "");

    let headers = {
      token: localStorage.getItem(configJSON.token),
    };

    const getAccount = apiCall({
      httpBody: {},
      header: headers,
      url: url,
      httpMethod: configJSON.apiMethodTypeGet,
    });

    this.getHomeCleaningProductListApicallId = getAccount.messageId;
    runEngine.sendMessage(getAccount.id, getAccount);
  };

  handleHomeCleaningProductResp = (responseJson: {
    data: HomeCleanProductData[];
    meta: IMeta;
  }) => {
    this.setState({
      homeCleaningProductList: responseJson.data,
      meta: responseJson.meta,
    });
  };

  handleNavigateEditHomeCleanProduct = (currentItem: HomeCleanProductData) => {
    this.props.navigation.navigate("EditHomeCleaningProduct", {
      productId: currentItem.attributes.id,
    });
  };

  handleNavigateViewHomeCleanProduct = (currentItem: HomeCleanProductData) => {
    this.props.navigation.navigate("ViewHomeCleaningProduct", {
      productId: currentItem.attributes.id,
    });
  };

  handleDeactivateProductResp = (successResponse: HomeCleanProductData) => {
    if (successResponse) {
      this.setState({
        openDeactiveModel: false,
        deactivated: true,
      });
    }
  };

  handleActivateProductResp = (successResponse: HomeCleanProductData) => {
    if (successResponse) {
      this.setState(
        {
          successSnackbarMessage: Strings.productActivateSuccessMessage,
          successSnackbarOpen: true,
        },
        () => {
          this.handleClosePopver();
          this.getHomeCleaningProducts();
        }
      );
    }
  };

  handleCloseConfirmModal = () => {
    this.setState({
      openDeactiveModel: false,
    });
  };

  handleDeactivateModalAction = (currentPopver: HomeCleanProductData) => {
    if (currentPopver.attributes.active) {
      this.setState({
        openDeactiveModel: true,
      });
    } else {
      this.handleActivateProduct();
    }
  };

  handleFilterChange = (filters: IFilter[]) => {
    this.setState(
      {
        filters,
      },
      () => this.getHomeCleaningProducts()
    );
  };

  handleFiltePriceText = (
    event: React.ChangeEvent<{
      value: string;
    }>,
    price: object
  ) => {
    const { filters } = this.state;
    const filter = filters.find((item) => item.title === "Price");
    filter!.value = JSON.stringify({ ...price, price: event.target.value });
    this.setState({
      filters: [...this.state.filters],
    });
  };

  setSelectedFilterRange = (range: number[], value: object) => {
    const { filters } = this.state;
    const filter = filters.find((item) => item.title === "Price");
    filter!.value = JSON.stringify({ ...value, priceRange: range });
    this.setState({
      selectedFilterRange: range,
      filters: [...this.state.filters],
    });
  };

  handleClearAllProductFilter = () => {
    const updated = this.state.filters.map((item: IFilterItem) => {
      item.value = "";
      item.options = [];
      return item;
    });
    this.setState({
      filters: updated,
      selectedFilterRange: [0, 100],
    });
  };

  handleBackToListing = () => {
    this.setState(
      {
        deactivated: false,
        successSnackbarMessage: Strings.productDeactivatedSuccessMessage,
        successSnackbarOpen: true,
      },
      () => {
        this.handleClosePopver();
        this.getHomeCleaningProducts();
      }
    );
  };

  getActivateActionBody = (active: boolean) => {
    return {
      data: {
        attributes: {
          active: active,
        },
      },
    };
  };

  handleDeActivateProduct = () => {
    const id = this.state.currentPopver?.id;
    const deactiveUrl = ApiUrls.getHomeCleanProductApiEndPoint + `/${id}`;

    let headers = {
      token: localStorage.getItem(configJSON.token),
      "Content-Type": configJSON.productApiContentType,
    };

    const deactivateProduct = apiCall({
      httpBody: this.getActivateActionBody(false),
      header: headers,
      url: deactiveUrl,
      httpMethod: configJSON.apiMethodTypePut,
    });

    this.deactivateProductApiCallId = deactivateProduct.messageId;
    runEngine.sendMessage(deactivateProduct.id, deactivateProduct);
  };

  handleActivateProduct = () => {
    const id = this.state.currentPopver?.id;
    const deactiveUrl = ApiUrls.getHomeCleanProductApiEndPoint + `/${id}`;

    let headers = {
      token: localStorage.getItem(configJSON.token),
      "Content-Type": configJSON.productApiContentType,
    };

    const activateProduct = apiCall({
      httpBody: this.getActivateActionBody(true),
      header: headers,
      url: deactiveUrl,
      httpMethod: configJSON.apiMethodTypePut,
    });

    this.activateProductApiCallId = activateProduct.messageId;
    runEngine.sendMessage(activateProduct.id, activateProduct);
  };

  handleSnackbarClose = () => {
    this.setState({
      successSnackbarOpen: false,
    });
  };

  handleProductAutoSuggestion = (title: string, value: string) => {
    this.state.filters.forEach((item: IFilterItem) => {
      if (item.title === title) item.value = value;
      return item;
    });

    let ApiUrl;
    if (title === "Product Name") {
      ApiUrl = ApiUrls.productNameFilterSuggestionEndPoint;
    } else if (title === "Product Type") {
      ApiUrl = ApiUrls.productTypeFilterSuggestionEndPoint;
    }

    let headers = {
      token: localStorage.getItem(configJSON.token),
      "Content-Type": configJSON.productApiContentType,
    };

    const suggetionProduct = apiCall({
      httpBody: {},
      header: headers,
      url: ApiUrl + value,
      httpMethod: configJSON.apiMethodTypeGet,
    });

    if (title === "Product Name") {
      this.productNameSuggestionProductApiCallId = suggetionProduct.messageId;
    } else if (title === "Product Type") {
      this.productTypeSuggestionProductApiCallId = suggetionProduct.messageId;
    }

    runEngine.sendMessage(suggetionProduct.id, suggetionProduct);
  };

  setFilterSuggestionList(list: { label: string; value: string }[]) {
    const updatedFilters = this.state.filters.map((item: IFilterItem) => {
      if (item.value !== "") {
        if (item.title === this.state.suggestionFieldName) item.options = list;
        return item;
      } else {
        if (item.title === this.state.suggestionFieldName) item.options = [];
        return item;
      }
    });
    this.setState({ filters: updatedFilters });
  }

  handleProductNameSuggestionResp = (response: {
    status: string;
    data: {
      id: number;
      product_name: string;
    }[];
  }) => {
    const list =
      response.data.map((item) => {
        return {
          label: item.product_name,
          value: String(item.id),
        };
      }) || [];
    this.setFilterSuggestionList(list);
  };

  handleProductTypeSuggestionResp = (response: {
    status: string;
    data: {
      id: number;
      name: string;
    }[];
  }) => {
    const list =
      response.data.map((item) => {
        return {
          label: item.name,
          value: String(item.id),
        };
      }) || [];
    this.setFilterSuggestionList(list);
  };

  handleUserChange = (userContext: IUserContext) => {
    const apiKey = customPermissionApiKey.homeCleanProductPermission;
    const userData = userContext.user?.attributes.permission_groups;
    const value = checkForNewPermissonStatus(apiKey, userData as Array<PermissionGroupArray>);
    this.setState({
      permissionStatus: value
    })
  };

  // Customizable Area End
}
