
import React, { useEffect, useRef, useState } from 'react';
import { Skeleton } from "@material-ui/lab";
import { styled } from "@material-ui/core";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, XAxisProps, DefaultTooltipContentProps } from 'recharts';
import { colors } from '../../blocks/utilities/src/Colors';
import { toString } from 'lodash';
import { formatBigNumber } from './customComponents/CustomColumnChart.web';

type Props = {
  chartData: { [field: string]: string | number }[]
  barProps: { dataKey: string }
  barCategoryGap?: number | string
  xAxisProps?: XAxisProps
  isPrinting?: boolean
  loading?: boolean
}

const OrderCountChart = ({ chartData, barProps, xAxisProps = {}, barCategoryGap, isPrinting, loading }: Props) => {
  const [position, setPosition] = useState({ y: 0, offset: -36 })
  const [fontSize, setFontSize] = useState(12)
  const containerRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    let resizeTimeout: NodeJS.Timeout;
    const handleResize = () => {
      clearTimeout(resizeTimeout);

      resizeTimeout = setTimeout(() => {
        if (containerRef.current) {
          const { width } = containerRef.current.getBoundingClientRect();
          if (width < 477) return setFontSize(10)
          if (width > 560) return setFontSize(13)
          setFontSize(width < 532 ? 11 : 12)
        }
      }, 500);
    };

    handleResize();

    const resizeObserver = new ResizeObserver(handleResize);

    if (containerRef.current && !(xAxisProps.tick as React.SVGProps<SVGTextElement>)?.fontSize) {
      resizeObserver.observe(containerRef.current);
    }

    return () => {
      resizeObserver.disconnect();
      clearTimeout(resizeTimeout);
    };
  }, []);

  return (
    loading ?
      <LoadingWrapper>
        {
          Array(10).fill(0).map((element, index) => (
            <Skeleton key={index} animation="wave" variant="rect" width="full" height={10} />
          ))
        }
      </LoadingWrapper>
      :
      <ResponsiveContainer width={isPrinting ? 670 : "100%"} height={400} className="custom-bar-chart-container" ref={containerRef}>
        <BarChart data={chartData} barCategoryGap={barCategoryGap} margin={{ bottom: isPrinting ? 16 : 10, top: 10, right: 10 }}>
          <CartesianGrid syncWithTicks vertical={false} strokeDasharray="1 0" stroke={colors().columbiaBlue} />
          <XAxis
            tickLine={false}
            axisLine={false}
            tickMargin={5}
            interval={0}
            tick={{ fontSize, fill: "black" }}
            {...xAxisProps}
          />
          <YAxis axisLine={false} tickLine={{ stroke: colors().columbiaBlue }} tick={{ fontSize: 13 }} allowDecimals={false} tickCount={10} tickFormatter={formatBigNumber} />
          <Tooltip
            active={Boolean(position.y)}
            cursor={false}
            allowEscapeViewBox={{ x: true }}
            offset={position.offset}
            position={{ y: position.y - 75 }}
            content={tooltipContent}
          />
          <Bar
            fill={colors().darkblue}
            radius={[4, 4, 0, 0]}
            onMouseMove={(event) => {
              let valueLength = toString(event.value).length
              if (valueLength === 1 && toString(event.day).length === 9) {
                valueLength++
              }
              let additionalOffset = valueLength * 4.5
              setPosition({ y: event.y, offset: -36 - additionalOffset })
            }
            }
            onMouseLeave={() => setPosition({ y: 0, offset: -36 })}
            {...barProps}
          >
          </Bar>
        </BarChart>
      </ResponsiveContainer>
  )
}

const tooltipContent = (props: DefaultTooltipContentProps<string, string>) => {

  if (props.payload?.length) return (
    <TooltipWrapper>
      <div>
        {formatLabel(props.label)}
      </div>
      {props.payload?.length ?
        <div>
          {props.payload[0].value} Orders
        </div> : null
      }
    </TooltipWrapper>
  )

  return null
}

const formatLabel = (label?: string) => {
  const labelString = label || "";
  const labelParts = labelString.split(" ");
  if (labelParts.length < 2) return labelString;
  const [hour, dayTime] = labelParts;
  return `${hour}:00 ${dayTime}`;
}

const TooltipWrapper = styled("div")({
  display: "grid",
  gridTemplateRows: "1fr 1fr",
  border: `solid 1px ${colors().antiFlashWhite}`,
  boxShadow: "0px 4px 8px 0px rgba(0, 0, 0, 0.03), 0px 8px 32px 0px rgba(0, 0, 0, 0.06)",
  borderRadius: 8,
  "& > div": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: 13,
    "&:first-of-type": {
      borderBottom: `1px solid ${colors().lightgray}`,
      background: colors().antiFlashWhite,
      fontWeight: 500,
      borderRadius: "8px 8px 0 0",
      padding: "0 4px"
    },
    "&:nth-of-type(2)": {
      padding: "8px 12px",
      background: "white",
      fontWeight: 600,
      borderRadius: "0 0 8px 8px"
    }
  },

})

const LoadingWrapper = styled("div")({
  padding: 16,
  paddingTop: 8,
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  height: 400,
  "& > .MuiSkeleton-root": {
    borderRadius: 4
  }
})

export default OrderCountChart;