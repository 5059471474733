Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "Payments";
exports.labelBodyText = "Payments Body";

exports.btnExampleTitle = "CLICK ME";
exports.tokenKey = "token";
exports.test1 = "test1";
exports.test2 = "test2";

exports.getRedeemPointsEndpoint = "account_block/customers/redeem_points?id=";
exports.paymentMethodsEndpoint = "/bx_block_settings/payment_methods";
exports.sendOtpForWallets = "bx_block_wallet/wallets/send_otp?customer_id=";
exports.getWalletBalanceEndpoint =
  "bx_block_wallet/wallets/wallet_balance?allow_access=true&customer_id=";
exports.getTotalRewardPointsEndpoint =
  "account_block/customers/total_reward_points?id=";
exports.addPaymentTransactionEndPoint =
  "bx_block_order_management/orders/add_payment_transactions";
exports.addPaymentTransactionHomeCleaningEndPoint =
  "bx_block_order_management/home_cleaning_orders/add_payment_transactions";

exports.getRemainingBalanceEndPointForCleaningOrder =
  "bx_block_order_management/home_cleaning_orders/get_remaining_balance?id=";
  
exports.getRemainingBalanceEndPoint =
  "bx_block_order_management/orders/get_remaining_balance?id=";
exports.getPromocodeUrl =
  "/bx_block_promo_codes/promo_codes/get_order_promo_codes?";
exports.appliedPromocodeUrl = "/bx_block_order_management/orders/apply_coupon";
exports.applyPromoCodeForCleaningOrderEndpoint = "bx_block_order_management/home_cleaning_orders/apply_coupon";

exports.label_tax = "Tax";
exports.label_use = "Use";
exports.label_cash = "Cash";
exports.remaining_payable_amount = "Remaining Payable Amount";
exports.label_total = "Total";
exports.label_wallet = "Wallet";
exports.label_redeem = "Redeem";
exports.label_payAll = "Pay All";
exports.label_subtotal = "Subtotal";
exports.label_payLater = "Pay Later";
exports.label_storePay = "Store Pay";
exports.label_totalItems = "Total Items";
exports.label_congrats = "Congratulations";
exports.label_postMachine = "Post Machine";
exports.label_paymentLink = "Payment Link";
exports.label_usablePoints = "Usable Points";
exports.label_backToModify = "Back or Modify";
exports.label_paymentMethod = "Payment Method";
exports.label_service_charge = "Service Charge";
exports.label_pointsRedeemed = "Points redeemed";
exports.label_promoCodeDiscount = "Promo Code Discount";
exports.label_notSufficientBalanceLabel =
  "Your balance is insufficient please select other payment method";
exports.label_pay = "Pay";
exports.label_notSufficientWalletBalance = "Insufficient wallet balance";
exports.label_selectSecondPaymentMethod = "Select second payment method";
exports.label_nowYouCanUseCreditAmount = "Now you can use the Credit amount";
exports.label_promoCode = 'Promo Code'
exports.label_applyPromoCode ='Apply Promo Code'
exports.label_promoCodeApplied ='1 Promo Code Applied'
exports.test = "Test";
exports.PaymentLoaderDisable = "Disable Payment Loader";
// Customizable Area End
