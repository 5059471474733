import React from 'react';
import {
    Input,
    Select,
    MenuItem,
    Chip,
    FormControl,
    styled
} from "@material-ui/core";
import { withStyles, Theme } from "@material-ui/core/styles";
import { Styles } from "@material-ui/styles";
const CheckTrue = require('../check_true.svg');
const CheckFalse = require('../check_false.svg');
import { colors } from "../../../blocks/utilities/src/Colors";

export interface Props {
    fieldLabel: any;
    classes?: any;
    fieldName: any;
    isError?: any;
    options: any;
    errorMessage?: any;
    setFieldValue?: any;
    placeholder?: any;
    selectOnClick?: any;
    selectedOptions?: any;
    isSelectAll?: any;
    selectAllSelected?: any;
    selectAllClick?: any;
    onChange?: any;
    value?: any;
}

export class MultiSelectDropdown extends React.Component<Props> {
    constructor(props: Props) {
        super(props);
    }

    render() {
        const { classes, fieldName, isError, fieldLabel, options, errorMessage, setFieldValue, placeholder, selectOnClick, selectedOptions, isSelectAll, selectAllSelected, selectAllClick, ...rest }: any = this.props;
        const ITEM_HEIGHT = 60;
        const ITEM_PADDING_TOP = 8;
        const MenuProps: any = {
            PaperProps: {
                style: {
                    maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                    width: 200,
                },
            },
            anchorOrigin: {
                vertical: "bottom",
                horizontal: "left"
            },
            transformOrigin: {
                vertical: "top",
                horizontal: "left"
            },
            getContentAnchorEl: null
        };

        return (
            <>
                <FormControl variant="outlined" className={classes.formControl}>
                    <MultiSelectionBox
                        labelId={fieldName}
                        id={fieldName}
                        name={fieldName}
                        multiple
                        fullWidth
                        displayEmpty
                        value={"select"}
                        className={isError ? classes.multiSelectionBoxError : classes.multiSelectionBox}
                        disableUnderline={true}
                        {...rest}
                        input={<Input id={fieldName} />}
                        renderValue={() => (
                            <div className={classes.chips}>
                                {selectedOptions?.length !== 0 ? <></> : <PlaceholderText value="select">{placeholder}</PlaceholderText>}
                                {
                                    selectedOptions?.length !== 0 &&
                                    selectedOptions?.map((value: any) => (
                                        <Chip key={value.option} label={value.option} className={classes.chip} />
                                    ))
                                }
                            </div>
                        )}
                        MenuProps={MenuProps}
                    >
                        {isSelectAll && <CustomMenuItem value={"selectAll"} style={{ color: selectAllSelected ? "#204B9C" : "" }} data-test-id={`selectAllOption_${fieldName}`} onClick={() => { selectAllClick() }}>
                            {selectAllSelected ?
                                (selectedOptions?.length === options?.length ?
                                    <img src={CheckTrue} style={{ marginRight: '16px', marginLeft: '16px' }} /> :
                                    <div className={classes.notAllSelected}>-</div>)
                                : <img src={CheckFalse} style={{ marginRight: '16px', marginLeft: '16px' }} />}
                            Select all
                        </CustomMenuItem>}
                        {options?.length !== 0 && options !== undefined ?
                            options?.map((option: any, index: any) => {
                              let isSelectItem = selectedOptions?.some((obj: any) => obj.id === option.id)
                                return (
                                  <CustomMenuItem 
                                      style={{ color: isSelectItem ? "#204B9C" : "" }}
                                      key={index} 
                                      value={option.id} 
                                      onClick={() => { selectOnClick(option) }} 
                                      data-test-id={`dropdownOption_${index}`}
                                    >
                                      {selectedOptions?.some((obj: any) => obj.id === option.id) ? 
                                        <img src={CheckTrue} style={{ marginRight: '16px', marginLeft: '16px' }} /> 
                                        : 
                                        <img src={CheckFalse} style={{ marginRight: '16px', marginLeft: '16px' }} />
                                      }
                                      {option.option}
                                  </CustomMenuItem>
                                )
                            })
                            :
                            <CustomMenuItem disabled>
                                No data
                            </CustomMenuItem>
                        }
                    </MultiSelectionBox>
                </FormControl>
                {isError && <div className={classes.fieldError}>
                    {errorMessage}
                </div>}
            </>
        )
    }
}

const styles: Styles<Theme, {}, string> = () => ({
    formControl: {
        width: '100%',
        border: '1px solid #ECECEC',
        borderRadius: '8px',
        marginTop: '10px'
    },
    chips: {
        '@media (max-width: 1200px)': {
            minHeight: '44px',
        },
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
        minHeight: '56px',
        fontFamily: 'Montserrat'
    },
    chip: {
        margin: "2px 0px 2px 15px",
        backgroundColor: 'rgba(32, 75, 156, 0.1)',
        color: '#204B9C',
        height: '26px',
        fontFamily: 'Montserrat'
    },
    multiSelectionBox: {
        width: '100%',
        borderRadius: "8px",
        margin: '0 !important',
        border: '1px solid #ececec',
        "&:focus": {
            border: '2px solid #204B9C',
        }
    },
    multiSelectionBoxError: {
        width: '100%',
        borderRadius: "8px",
        margin: '0 !important',
        border: '1px solid red',
        "&:focus": {
            border: '2px solid red',
        }
    },
    fieldError: {
        color: "red",
        fontSize: "14px",
        margin: "5px 5px 5px 0"
    },
    notAllSelected: {
        width: '20px',
        height: '20px',
        fontSize: '25px',
        fontWeight: 'bold',
        backgroundColor: 'gray',
        color: '#fff',
        border: 'none',
        borderRadius: '6px',
        margin: '0 16px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    '@global': {
        '*::-webkit-scrollbar': {
            width: '6px'
        },
        '*::-webkit-scrollbar-track': {
            '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)'
        },
        '*::-webkit-scrollbar-thumb': {
            backgroundColor: '#d1d1d1',
            borderRadius: '10px'
        }
    }
});

const MultiSelectionBox: any = withStyles({
    root: {
        width: '100%',
        borderRadius: "8px",
        padding: 0,
        margin: 0,
        minHeight: '56px',
        maxHeight: "70px",
        overflow: "auto",
        fontSize: '14px',
        "@media only screen and (max-width: 1024px)": {
            minHeight: "44px",
            fontSize:"12px !important"
          },
        "&:focus": {
            outline: "none",
            borderRadius: '8px'
        },
        "& .MuiOutlinedInput-root": {
            fontWeight: 400,
            borderRadius: "8px",
            "& fieldset": {
                fontSize: "16px",
                borderRadius: "8px",
                borderWidth: 1,
                fontWeight: 400,
            },
            "& .Mui-focused": {
                borderColor: '#204B9C'
            }
        },
        "& .MuiInputBase-root": {
            height: 56
        },
        "& ::placeholder": {
            opacity: 1,
            color: "#4D4D4D",
            fontSize: '14px',       
        }, 
    }
})(Select);

const CustomMenuItem: any = withStyles({
    root: {
        height: '56px',
        '@media (max-width: 1200px)': {
            height: '44px',
        },
        "&.Mui-selected": {
            backgroundColor: 'transparent',
        },
        "@media only screen and (max-width: 1024px)": {
            height:"30px",
            fontSize:"14px"
        },
    }
})(MenuItem);

const PlaceholderText = styled(MenuItem)({
    fontSize: 14,
    fontWeight: 400,
    lineHeight: "22px",
    fontFamily: "Montserrat",
    color: "var(--neutrals-dark-gray, #4D4D4D)",
    "@media only screen and (max-width: 1024px)": {
        fontSize: '12px',
    },
  });

export default withStyles(styles)(MultiSelectDropdown);