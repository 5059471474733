import React from "react";
// Customizable Area Start
import { withStyles, Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { Styles } from "@material-ui/styles";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import TextField from "@material-ui/core/TextField";
import CircularProgress from "@material-ui/core/CircularProgress";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import PageContainer from "../../navigationmenu/src/PageContainer.web"
import { styled } from "@material-ui/core";
import { colors } from "../../utilities/src/Colors";

export const configJSON = require("./config");

import CreateProfessionController, {
  FormMode,
  IProfession,
  IRenderView,
  Props,
  RenderViewValue,
} from "./CreateProfessionController.web";
import { renderBaseOnConditions } from "../../../blocks/utilities/src/CustomBlockHelpers";
// Customizable Area End

// Customizable Area Start


const useStyles: Styles<Theme, {}, string> = () => ({
  pageContainer: {
    padding: "32.5px 48px",
    fontFamily: "Montserrat",
    background: "white",

    "& .MuiSelect-select.Mui-disabled": {
      color: "#7E7E7E",
    },
  },
  pageTitles: {
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: 24,
    marginTop: 32,
    color:colors().black,
    "@media only screen and (max-width: 1024px)": {
      fontStyle: "normal",
      fontFamily: "Montserrat",
      color:colors().viewTextColor,
      fontWeight: 600,
      fontSize: "16px",
    },
  },
  iconBtnContainer: {
    backgroundColor: "#204B9C",
    width: 56,
    height: 56,
    borderRadius: 8,
    marginLeft: 16,
    marginTop: 24,
    cursor: "pointer",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  formContainer: {
    padding: 32,
    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.03), 0px 8px 32px rgba(0, 0, 0, 0.06)",
    borderRadius: 12,

    "& .MuiFormLabel-root": {
      fontFamily: "Montserrat",
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: 16,
      letterSpacing: "-0.3px",
      color: "#1A1A1A",
    },
    position: "relative",
  },
  errorInput: {
    "& fieldset": {
      borderColor: "red",
    },

    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "red",
    },
  },
  button: {
    borderRadius: 8,
    fontSize: 16,
    fontWeight: 700,
    cursor: "pointer",
    width: "128px",
    height: "56px",
    padding: "18px 0",
    textAlign:"center",
    marginBottom: 5,
    "@media only screen and (max-width: 1024px)": {
      fontSize: "16px",
      width: "128px",
      height: "44px",
      padding: "12px 0",
      textAlign:"center"
    },  
  },
  modalContainer: {
    width: "574px",
    height: "407px",
    borderRadius: 24,
  },
  checkBoxContainer: {
    marginBottom: 32,
    "& span": {
      paddingTop: 0,
      paddingBottom: 0,
    },
    "&:last-child": {
      marginBottom: 0,
    },
  },
  selectContainer: {
    height: 56,
  },
  tag: {
    background: "rgba(32, 75, 156, 0.15)",
    borderRadius: 8,
    padding: "6px 12px",
    marginRight: 16,
    display: "flex",
    alignItems: "center",
  },
  viewSectionHeader: {
    padding: "19px 32px",
    background: "#204B9C",
    color: "white",
    fontWeight: 600,
    fontSize: 18,
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    borderTopLeftRadius: 12,
    borderTopRightRadius: 12,
  },
  tagsContainer: {
    display: "flex",
  },
});

const StyledFormLable = styled('p')({
  fontFamily: "Montserrat",
  fontSize: "16px",
  fontStyle: "normal",
  color:colors().viewTextColor,
  marginBottom: "8px",
  fontWeight: 600,
  lineHeight: "20px",
  "@media only screen and (max-width: 1024px)": {
    fontStyle: "normal",
    fontFamily: "Montserrat",
    color:colors().charcoal,
    fontWeight: 600,
    fontSize: "13px",
    lineHeight: "20px",
  },
});
const StyledTextField = styled(TextField)({
  "@media only screen and (max-width: 1024px)": {
    '& .MuiInputBase-input': {
      padding:'14.9px 14px',
      fontSize: '12px'
    },
  },
});

const MainGridContainer = styled(Grid)({
  marginBottom: 25,
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
});
const ViewEditButton = styled('div')({
  borderRadius: 8,
  fontSize: 16,
  fontWeight: 700,
  cursor: "pointer",
  padding: "18px 0",
  marginBottom: 5,
  backgroundColor: "#204B9C",
  color: "white",
  marginTop: 32,
  width: 184,
  textAlign: "center",
  "@media only screen and (max-width: 1024px)": {
    fontSize: "16px",
    width: "128px",
    height: "44px",
    padding: "12px 0",
    textAlign:"center"
  },  
});
const ViewFormLabel = styled(Typography)({
  marginBottom: "8px",
  fontFamily: "Montserrat",
  fontStyle: "normal",
  fontSize: "18px",
  fontWeight: 600,
  marginTop: 4,
  color:colors().darkliver,
  "@media only screen and (max-width: 1024px)": {
    fontSize: "16px",
    fontWeight: 600,
    color:colors().viewTextColor,
  }
});
const StyledContentTypography = styled(Typography)({
  fontFamily: "Montserrat",
  fontStyle: "normal",
  fontSize: 16,
  fontWeight: 500,
  marginTop: 4,
  color:colors().viewTextColor,
  "@media only screen and (max-width: 1024px)": {
    fontSize: 13,
    fontWeight: 500,
  }
});
// Customizable Area End

export class CreateProfession extends CreateProfessionController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
 
  // Customizable Area Start
  renderView = (value: RenderViewValue, label: string, color: string = "#1A1A1A") => (
    <Grid item xs={12} sm={6} style={{ paddingRight: 32 }}>
      <FormControl style={{ width: "100%" }}>
        <ViewFormLabel style={{ marginBottom: "8px", marginTop: 8 }}>{label}</ViewFormLabel>
        <StyledContentTypography>
          {value}
        </StyledContentTypography>
      </FormControl>
    </Grid>
  );


  getFormContainerMarginTop() {
    if (this.state.formMode === FormMode.View) {
      return 21;
    }
    return 0;
  }

  renderTextField(key: keyof IProfession['data']['attributes'], placeholder: string) {
    const { form, formErrors, randomProfessionId } = this.state;
    const { classes = {} } = this.props;

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let inputValue = event.target.value; 
        this.onFormElementChange({ ...event, target: { ...event.target, value: inputValue } }, key);

   };
    return (
      <StyledTextField
        disabled={key === "profession_unique_id" && this.state.formMode === FormMode.Edit}
        data-test-id={key}
        className={formErrors[key] ? classes.errorInput : "withoutError"}
         value={key === "profession_unique_id" && this.state.formMode === FormMode.Create ? randomProfessionId : form.data.attributes[key]}
        color='primary'
        variant='outlined'
        type='text'
        name={key}
        placeholder={placeholder}
        onChange={ handleInputChange}
        style={{backgroundColor: key === "profession_unique_id" ? "lightGray" : "inherit",caretColor: key === "profession_unique_id" ? "transparent" : "auto"}}
      />
    );
  }

  renderInputTextField(
    key: keyof IRenderView['data']['attributes'],
    label: string,
    placeholder: string,
    viewLabel: string,
  ) {
    const { form, formErrors, formMode } = this.state;
    if (formMode === FormMode.View) {
      return this.renderView(form.data.attributes[key], viewLabel);
    }
    return (
      <>
        <Grid item xs={12} sm={6} style={{ paddingRight: 32, paddingTop: 5 }}>
          <FormControl style={{ width: "100%" }}>
            <StyledFormLable>{label}</StyledFormLable>
            {this.renderTextField(key, placeholder)}
            {formErrors[key] && (
              <Typography className='errorMsg2' variant='caption' style={{ color: "red" }}>
                {formErrors[key]}
              </Typography> )}
          </FormControl>
        </Grid>
      </>
    );
  }

  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes = {} } = this.props;
    const {isSubmitting, formMode } = this.state;
    let pageTitle;
    let buttonText;

    if (formMode === FormMode.Create) {
      pageTitle = configJSON.createProfession;
    } else if (formMode === FormMode.Edit) {
      pageTitle = configJSON.editprofession;
    } else {
      pageTitle = configJSON.userProfessionText;
    }

    if (isSubmitting) {
      buttonText = <CircularProgress size={16} color='inherit' />;
    } else if (formMode === FormMode.Edit) {
      buttonText = configJSON.saveText;
    } else {
      buttonText = configJSON.addText;
    }

    return (
      <>
        <PageContainer
          navigation={this.props.navigation}
          onUserChange={(userContext) => this.handleUserChange(userContext)}
        >
          <Grid container direction='column' className={classes.pageContainer}>
            <MainGridContainer  direction='row'>
              <div className={classes.pageTitles}>{pageTitle}</div>
              {formMode === FormMode.View && (
                <>
                  {
                    renderBaseOnConditions(
                      this.state.permissionStatus.editPermission,
                      <ViewEditButton                   
                        onClick={this.handleEditProfessionClick}
                        data-test-id={"editProfession"}
                      >
                        Edit
                      </ViewEditButton>,
                      <></>
                    )
                  }
                </>
              )}
            </MainGridContainer>
            <Grid container direction='column' className={classes.formContainer}>
          
              <Grid
                container
                direction='row'
                style={{ marginTop: this.getFormContainerMarginTop() }}
              >
                {this.renderInputTextField(
                  "profession_unique_id",
                  "Profession ID",
                  "Enter Profession Id",
                  "Profession ID"
                )}
                {this.renderInputTextField(
                  "profession_name",
                  "Profession Name",
                  "Enter Profession Name",
                  "Profession Name"
                )}
              </Grid>
           </Grid>
         
            <Grid container direction='row' style={{ marginTop: 48, display: 'flex', flexWrap: 'wrap-reverse' }}>
              <div
                data-test-id={"cancelBtn"}
                onClick={this.handleClickCancel}
                className={classes.button}
                style={{ backgroundColor: formMode === FormMode.View ? "#204B9C" : "#F1F5F9", color: formMode === FormMode.View ? "white" :"#64748B", marginRight: 32 }}
              > 
                {formMode === FormMode.View ? configJSON.backText : configJSON.cancelText}
              </div>
              {formMode !== FormMode.View && (
                <div
                  data-test-id={"submitBtn"}
                  onClick={this.handleOnSubmit}
                  className={classes.button}
                  style={{ backgroundColor: "#204B9C", color: "white" }}
                >
                  {buttonText}
                </div>
              )}
            </Grid>
          </Grid>  
        </PageContainer>
        <Snackbar
            data-test-id="snackbar"
            anchorOrigin={{
              horizontal: "center",
              vertical: "top",
            }}
            open={this.state.snackbarOpen}
            onClose={this.handleSnackbarClose}
            autoHideDuration={4000}
          >
             <Alert severity={this.state.severity === "success" ? "success" : "error"}>{this.state.snackbarMessage}</Alert> 
          </Snackbar>
      </>
    );
    // Customizable Area End
  }
}

export default withStyles(useStyles)(CreateProfession);
