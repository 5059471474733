Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "ImportExportData";
exports.labelBodyText = "ImportExportData Body";

exports.btnExampleTitle = "CLICK ME";

exports.exportSuccess = "Your file exported to path: ";
exports.exportCsvLabel = "Export CSV";
exports.exportJsonLabel = "Export JSON";
exports.loadingText = "Loading...";

exports.jsonApiContentType = "application/json";
exports.csvApiContentType = "text/csv";
exports.exportDataEndpoint = "data_import_export/export";
exports.exportDataMethod = "GET";
exports.errorMsg = "Something went wrong."
exports.modalTitle = "Import File"
exports.importCsvApiurl = "/bx_block_section/upcharge_lists/csv_import";
exports.cancel = "Cancel";
exports.ErorMessage = "File Upload Error Message";
exports.importExportError = {
  successDataCount: "Successfully Uploaded Data:",
  errorPara:
    "Please review the downloaded error CSV file to check the issues in the rows. Make necessary changes and re-upload the corrected file",
};
// Customizable Area End