// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { SpecificationData } from "./SpecificationListController.web";
import { apiCall } from "../../../components/src/common";
import { Message } from "../../../framework/src/Message";
import { ClassNameMap } from "@material-ui/styles";
import { navigateTo } from "../../utilities/src/CustomBlockHelpers";
import { specificationIntialVal } from "./EditSpecificationController.web";
import { IUserContext } from "../../../blocks/navigationmenu/src/PageContainerController.web";
import {
  PermissionStatus,
  checkForNewPermissonStatus,
  customPermissionApiKey
} from "../../../blocks/utilities/src/CustomBlockHelpers";
import { PermissionGroupArray } from "../../../blocks/navigationmenu/src/utils";
// Customizable Area End
export const configJSON = require("./config");

export interface Props {
  navigation?: any;
  id?: string;
  // Customizable Area Start
  classes: ClassNameMap
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  specificationCurrentItem: SpecificationData;
  permissionStatus: PermissionStatus;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: string | number;

  // Customizable Area End
}
// Customizable Area Start
// Customizable Area End

export default class ViewSpecificationController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  preferenceDataCallId: string = "";
  getSpecificationViewDataAPICallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
    ];

    this.state = {
      specificationCurrentItem: specificationIntialVal,
      permissionStatus: {
        mainPermission: false,
        createPermission: false,
        viewPermission: false,
        editPermission: false,
        deactivatePermission: false
      }
    };
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  componentDidMount(): any {
    // Customizable Area Start

    const paramId = this.props.navigation.getParam("navigationBarTitleText");
    this.getSpecificationViewData(paramId);
    
    // Customizable Area End
  }
  // Customizable Area Start

  getSpecificationViewData = (urlId?: string) => {
    let apiUrl = configJSON.specificationSingleDataApiUrl + `/${urlId}`;
    let headers = {
      token: localStorage.getItem(configJSON.token),
    };
    const getAccount = apiCall({
      httpBody: {},
      header: headers,
      url: apiUrl,
      httpMethod: configJSON.httpGetType,
    });

    this.getSpecificationViewDataAPICallId = getAccount.messageId;
    runEngine.sendMessage(getAccount.id, getAccount);
  };

  handleCancel = () => {
    navigateTo({
      props: this.props,
      screenName: "SpecificationList",
    });
  };

  handleSpecificationEdit = (item:SpecificationData) => {
    navigateTo({
      id: String(item.id),
      props: this.props,
      screenName: "EditSpecification",
    });
  };

  handleViewSpecificationDataResp = (responseJson: { data: SpecificationData }) => {
    this.setState({
      specificationCurrentItem: responseJson.data
    })
  };

  handleUserChange = (userContext: IUserContext) => {
    const apiKey = customPermissionApiKey.specificationMPermission;
    const userData = userContext.user?.attributes.permission_groups;
    const value = checkForNewPermissonStatus(apiKey, userData as Array<PermissionGroupArray>);
    this.setState({
      permissionStatus: value
    })
  };

  async receive(from: string, message: Message) {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if(apiRequestCallId === this.getSpecificationViewDataAPICallId) {
      this.handleViewSpecificationDataResp(responseJson)
    }
  }
  // Customizable Area End


}
