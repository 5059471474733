import moment from 'moment-timezone';

export const handleMomentDateFormat = (date: string, dateFormat: string,targetTimezone?: string) => {
    let momentDate = moment.parseZone(date);
    const formattedDate = momentDate.format(dateFormat);
    return formattedDate;
};

export const getPeriodDate = (startPeriodDate:string,endPeriodDate:string) => {
    const periodDate = `${startPeriodDate}_${endPeriodDate}`
    return periodDate
}

export const  saudiTimeView = (time: Date|string) => {
    const saudiTime = moment.parseZone(time);
    const istTime = saudiTime.tz("Asia/Kolkata");
    const now = moment();
    const diffMinutes = now.diff(istTime, 'minutes');
    let relativeTime;
    if (diffMinutes < 1) {
      relativeTime = "Just now";
    } else if (diffMinutes < 60) {
      relativeTime = `${diffMinutes} Min ago`;
    } else {
      const diffHours = now.diff(istTime, 'hours');
      if (diffHours < 24) {
        relativeTime = `${diffHours} Hr${diffHours > 1 ? 's' : ''} ago`;
      } else {
        relativeTime = istTime.format("MMM DD, YYYY");
      }
    }
    return  relativeTime ;
  };

  export const currentSaudiTime = () => {
    const saudiTime = moment().tz("Asia/Riyadh"); 
    return saudiTime.format("MM/DD/YYYY - h:mm A");
  };