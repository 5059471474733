/** @format */

import React from "react";
// Customizable Area Start
import { withStyles, Theme } from "@material-ui/core/styles";
import { Dialog, Typography, Button, Avatar, ListItem, ListItemText, Box, TextareaAutosize, Grid } from "@material-ui/core";
import { Styles } from "@material-ui/styles";
import CreateCustomerPreferenceController, { CataloguePreference, CataloguePreferenceData, CataloguePreferenceSpecification, CatalogueUpchargeData, IPreferenceData, Props } from "./CreateCustomerPreferenceController.web"
import { checkedIcon, unCheckedIcon, openCircle, filledCircle, addIcon, backIcon } from "./assets";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import ArrowDropDownCircleIcon from '@material-ui/icons/ArrowDropDownCircle';
import OutlinedInput from "@material-ui/core/OutlinedInput";
import Checkbox from "@material-ui/core/Checkbox";
import _ from "lodash";
import Loader from "../../../components/src/Loader.web";
import { MultiSelectionBox } from "../../../components/src/customComponents/CustomTags.web";
import { MenuProps } from "../../email-account-registration/src/AccountCreationController.web";
import CircularProgress from '@material-ui/core/CircularProgress';
export const configJSON = require("./config");
import AutocompleteSelect from "../../../components/src/AutocompleteSelect.web";
import AutocompleteSelectLoading from "../../../components/src/AutocompleteSelectLoading.web";

// Customizable Area End

// Customizable Area Start
const StyledMenuItemPreference = withStyles((theme) => ({
  root: {
    "&:focus": {
      backgroundColor: "#204B9C",
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

const StyledMultipleMenuItemPreference = withStyles((theme) => ({
  root: {
    "&:focus": {
      backgroundColor: "white",
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: "#204B9C",
      },
    },
    "& .MuiCheckbox-root": {
      padding: "3px"
    }
  },
}))(MenuItem);

export const styles: Styles<Theme, {}, string> = () => ({
	mainDialog: {
		"& .MuiDialog-paper": {
			maxWidth: "unset !important",
			borderRadius: 24,
		},
	},
	headerTitle: {
		textAlign: "center",
		marginTop: "20px",
		fontSize: "1.6rem",
	},
	mainContainer: {
		backgroundColor: "white",
		borderRadius: 10,
		border: "1px solid #ccc",
		margin: 20,
		boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
	},
	mainContainerInner: {
		backgroundColor: "#204b9c",
		borderTopLeftRadius: 10,
		borderTopRightRadius: 10,
	},
	topPartContainer: {
		display: "flex",
		marginLeft: 20,
		marginTop: 32
	},
	headerListSec: {
		display: "grid",
		gridTemplateColumns: "repeat(6,1fr)",
    gridAutoFlow: "column",
		padding: "20px 50px",
		gridGap:"40px",
		marginLeft: 10,
		["& h6"]: {
			fontWeight: 600,
			fontSize: 18,
			color: "#FFFFFF"
		},
	},
	selectedProduct: {
		display: "flex",
		alignItems: "center",
		marginTop: 30,
		["& p"]: {
			fontWeight: 600,
		},
	},
	dropDownSelection: {
		padding: "0px 15px",
		margin: 20,
		boxShadow: "0px 0px 5px 0px rgba(0, 0, 0, 0.2)",
		borderRadius: 12,
		position: "relative",
	},
	radioDropdown: {
		display: "grid",
		gridTemplateColumns: "repeat(6,1fr)",
    gridAutoFlow: "column",
		gridGap: 10,
		"@media (max-width: 1000px)": {
			gridTemplateColumns: "repeat(3,1fr)",
		},
	},
	upchargeSection: {
		display: "flex",
    margin: "30px 0",
    "@media (max-width: 1000px)": {
			alignItems: "flex-start",
			["& p"]: {
				marginTop: 10,
			},
		},
	},
	radioSection: {
		display: "flex",
		margin: "30px 0",
		alignItems: "center",
		"@media (max-width: 1000px)": {
			alignItems: "flex-start",
			["& p"]: {
				marginTop: 10,
			},
		},
	},
	header: {
		padding: "10px",
		color: "#1A1A1A",
		fontWeight: 600,
		fontSize: 14,
	},
	semiColon: {
		marginLeft: 20,
		color: "#1A1A1A",
		fontWeight: 600,
		fontSize: 14,
	},
	checkboxDetail: {
		display: "grid",
		gridTemplateColumns: "repeat(2,1fr)",
		gridGap: 5,
		"@media (max-width: 1000px)": {
			gridTemplateColumns: "repeat(1,1fr)",
		},
	},
	checkboxList: {
		cursor: "pointer",
		paddingTop: 0,
	},
	checkboxInner: {
		display: "flex",
		alignItems: "center",
		padding: "6px 20px",
		borderRadius: 12,
		marginLeft: 10,
		width: 300,
		textAlign: "right",
	},
	radioInner: {
		width: "unset",
	},
	checkboxInnerChecked: {
		background: "#F1F5F9",
		color: "#204B9C",
		border: "1px solid #ECECEC",
	},
	checkboxInnerUnChecked: {
		border: "1px solid #ECECEC",
		background: "#FFFFFF",
	},
	radioBox: {
		display: "flex",
		flexWrap: "wrap",
		cursor: "pointer",
		"@media (max-width: 1000px)": {
			flexDirection: "column",
		},
		"& .MuiListItem-root": {
			width: "auto"
		}
	},
	closeIcon: {
		position: "absolute",
		right: 0,
		top: 0,
		cursor: "pointer",
		color: "#4D4D4D",
	},
	textItem: {
		marginLeft: 15,
		textAlign: "left",
		width: 150
	},
  textItemOnPage: {
    textAlign: "left",
    marginLeft: 15,
  },
	iConSize: {
		width: 20,
		height: 20,
		borderRadius: "unset",
	},
	imageSize: {
		width: 24,
		height: 24,
		borderRadius: "unset",
	},
	chnageImageColor: {
		filter: "sepia(100%) saturate(600%) hue-rotate(195deg)",
	},
	checkboxListColor: {
		color: "#204B9C",
		cursor: "pointer",
	},
	addMore: {
		display: "flex",
		alignItems: "center",
		color: "#204b9c",
		cursor: "pointer",
		margin: 20,
		["& button"]: {
			textTransform: "capitalize",
			fontWeight: 600,
		},
	},
	addMoreButton: {
		color: "#204b9c",
		["&:hover"]: {
			color: "#204b9c",
			background: "#ffffff",
		},
	},
	groupButton: {
		margin: "30px auto",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		marginTop: 20,
		["& button"]: {
			textTransform: "capitalize",
			padding: "18px 35px",
			fontWeight: 600,
			fontSize: 16,
			borderRadius: 8,
			width: 185,
		},
	},
	cancelButton: {
		color: "#64748b",
		background: "#f1f5f9",
		marginRight: 20,
		["&:hover"]: {
			backgroundColor: "#f1f5f9",
		},
	},
	addButton: {
		color: "#ffffff",
		background: "#204b9c",
		["&:hover"]: {
			backgroundColor: "#204b9c",
		},
	},
	errorBox: {
		display: "flex"
	},
	errorColor: {
		color: "red"
	},
	inputFieldNote: {
		display: "flex",
		borderRadius: 8,
		border: "1px solid #ECECEC",
		background: "#f8f8f8",
		padding: 8,
		marginBottom: 15,
		width: "100%"
	},

	saveCheckbox: {
		display: "flex"
	},
	textgroup: {
		display: "flex",
		cursor: "pointer"
	},
	futureGroup: {
		flexBasis: "85%"
	},
	imageSizeSave: {
		width: 20,
		height: 20,
		borderRadius: "unset",
		marginRight: 15
	},
	topTitle: {
		color: "#334155",
		fontSize: "16px",
		fontWeight: 600,
		marginBottom: 4
	},
	topTextContainer: {
		padding: "10px 16px",
		borderRadius: "8px",
		background: "#E9E9E9",
		fontSize: 16,
		fontWeight: 500,
		height: 56,
		width: 364,
		display: "flex",
		alignItems: "center",
	},
	selectContainer: {
		height: "50%",
		marginTop: 16,
    "& .MuiSelect-outlined": {
      whiteSpace: "nowrap !important",
      wordBreak: "break-word !important"
    },
    "& .MuiTypography-displayBlock": {
      overflow: "hidden",
      textOverflow: "ellipsis"
    }
	},
	topSelectContainer: {
		height: 56,
		width: 364,
		marginTop: 16
	},
	productNameCollapseHeader: {
		backgroundColor: "#204b9c",
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
		color: "white",
		marginLeft: 20,
		marginRight: 20,
		borderRadius: 12,
		boxShadow: "0px 8px 32px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(0, 0, 0, 0.03)",
		marginTop: 32
  },
  pageHeaderContainer: {
    display: 'flex',
    fontSize: 16,
    fontFamily: 'Montserrat',
		margin: 20,
    color: '#1A1A1A',
    alignItems: 'flex-start',
    justifyContent: 'space-between'
  },
  pageHeaderBackPartContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
  },
  bulkSelectsContainer: {
    display: 'flex',
  },
  blueCheckbox: {
    "&$checked": {
      color: "#204B9C",
    },
  },
  multiSelectContainer: {
    minHeight: 56,
    width: 235,
  },
  headerSelectContainer: {
    height: 56,
    width: 235
  },
  headerSelectLabel: {
    color: '#334155',
    fontSize: 14,
    fontWeight: 600,
    marginBottom: 4
  },
  pageItemNamePartContainer: {
    marginTop: 16,
    color: '#1A1A1A',
    fontFamily: 'Montserrat',
    fontSize: 16,
    fontWeight: 500
  },
  pageItemNamePartContainerOnPage: {
    color: '#204B9C',
    marginTop: 16,
    display: 'flex',
    fontSize: 16,
    fontFamily: 'Montserrat',
    fontWeight: 600,
  },
  pageServiceName: {
    background: "#F1F5F9",
		color: "#204B9C",
    border: "1px solid #ECECEC",
    display: "flex",
		alignItems: "center",
		padding: "12px 20px",
		borderRadius: 8,
  },
  emptyDivToAlign: {
    minWidth: '16.5%'
  },
  w_100: {
    width: "100%"
  },
  mr_32: {
    marginRight: 32
  },
  mb_32: {
    marginBottom: 32
  },
  mr_16: {
    marginRight: 16
  },
  mr_45: {
    marginRight: 45
  },
  mr_20: {
    marginRight: 20
  },
  mt_10: {
    marginTop: 10
  },
  iconSize: {
    height: '24px'
  },
  collapseMenuIcon: {
    marginRight: 32,
    cursor: "pointer"
  },
  openCollapsedMenuIcon: {
    position: "absolute",
    top: 22,
    right: 32,
    color: "white",
    cursor: "pointer"
  },
  pos_relative: {
    position: 'relative'
  },
  transform_180: {
    transform: "rotate(180deg)"
  },
  minWidth_85: {
    minWidth: 85
  },
  backOrderMessage: {
    fontWeight: 500,
    fontSize: 16
  },
  actionButton: {
    justifyContent: 'flex-start',
    margin: 20
  },
  addMoreIcon: {
    marginTop: "20px",
    marginLeft: 20,
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer'
  },
  plusICon: {
    fontSize: '16px',
    fontWeight: 600, 
    color: '#204B9C', 
    marginLeft: '8px'
  },
  textLeft: {
    textAlign: 'left'
  },
  gridGap10: {
    gridGap: '10px 0'
  },
  alignStart: {
    alignItems: 'flex-start'
  },
  invisible: {
    visibility: 'hidden'
  },
  mr_5: {
    marginRight: 5
  },
  ml_10: {
    marginLeft: 10
  },
  upchargeDropdown: {
    marginLeft: 48,
    minHeight: 42,
    width: 360,
    "& .MuiSelect-selectMenu": {
      whiteSpace: "break-spaces",
    }
  },
  dropdownOptionWrapper: {
    width: "100%",
    display: "flex", 
    justifyContent: "space-between",
    margin: "0 0 0 8px",
    "& .MuiListItemText-root":{
      flex: "inherit"
    },
    "& .MuiTypography-root": {
      fontSize: "14px"
    }
  },
  seeAllUpchargesBox: {
    width: 300
  }
});
// Customizable Area End

class CreateCustomerPreference extends CreateCustomerPreferenceController {
	constructor(props: Props) {
		super(props);
		// Customizable Area Start
		// Customizable Area End
	}
	// Customizable Area Start

  renderSelectedLabel = (value: string, item: CataloguePreferenceSpecification | undefined ) => {
    if (!value) {
      return <Typography style={{ color: 'grey', textOverflow: 'ellipsis', overflow: 'hidden'}}>{`Select ${item?.name}`}</Typography>;
    }
    const selectedOptionIndex = item?.options_attributes.findIndex((option) => option.id.toString() == value);
    const optionLabel = (selectedOptionIndex !== undefined && selectedOptionIndex !== -1) ? item?.options_attributes[selectedOptionIndex].label : '';
    return optionLabel
  }
	renderLastPreference = () => { 
		const { classes = {}, editScreen, renderOnPage, isDrawerOpen } = this.props;
		const { product_ids, productList, serviceList, service_ids, preferenceList, selectedOptionsList } = this.state;

    return (
			preferenceList && preferenceList.map((preferenceData: CataloguePreference, index: number) => {
				if (this.state.collapsedData[index]) {
					return (
						<div
							className={classes.productNameCollapseHeader}
						>
							<Box className={classes.headerListSec}>
								<Typography key={index} variant="subtitle1">
                  {this.preferenceDataName(preferenceData)}
								</Typography>
							</Box>
							<div
                data-test-id="collapseMenu"
								className={classes.collapseMenuIcon}
								onClick={() => {
									const collapsedData = [...this.state.collapsedData];
									collapsedData[index] = false;
									this.setState({ collapsedData });
								}}
							>
								<ArrowDropDownCircleIcon />
							</div>
						</div>
					)
				}
				return (
          <>
            {/* top part disabled name or selectbox will be rendered only in modals */}
            {!renderOnPage && (
              <div className={classes.topPartContainer}>
                <div className={classes.mr_32}>
                  <div className={classes.topTitle}>{configJSON.productText}</div>
                  {editScreen ? (
                    <div className={classes.topTextContainer}>
                      {this.preferenceDataName(preferenceData)}
                    </div>
                  ) : (
                    <>
                    <AutocompleteSelectLoading
                    placeholder="Select Product"
                    data-test-id='preference-product-dropdown'
                    fieldName="product"
                    isLoading={this.state.isLoadingProduct}
                    handleScroll={this.handleScroll}
                    className={classes.topSelectContainer}
                    debouncedFunction={(getValue: string) => { 
                      this.debouncedFunction(getValue, this.handleAutoCompleteChange)
                    }}
                    disableClear
                      onChange={
                        (event:React.ChangeEvent,value: { id: string; option: string; image:string }) => {
                          const { product_ids, productList } = this.state;
                                product_ids[index] = parseInt(value.id);

                                  const productId = product_ids[index];
                                  const catalogue_id = productList.find((item:{
                                    id: string,
                                    name: string
                                }) => item.id == String(productId))?.catalogue_id;

                                  this.setState({ product_ids }, () => { this.getServiceList(catalogue_id); })
                                  if (!this.state.service_ids[index]) return
                                  this.setState({
                                      product_ids,
                                      lastChangedIndex: index,
                                      selectedOptionsList: this.state.selectedOptionsList.map((item: { specification_id: string | number, option_id: string | number }[], i: number) => {
                                          return this.handleSelectedOptionCondition(i, index, item)
                                      })
                                  })
                      }}
                    value={product_ids[index]}
                    options={productList.map((product:{
                      id: string,
                      name: string
                  }) => ({
                      id: product.id,
                      option: product.name
                    }))}
                  />
                  </>
                  )}
                </div>
                <div className={classes.mr_20}>
                  <div className={classes.topTitle}>{configJSON.serviceText}</div>
                  {editScreen ? (
                    <div className={classes.topTextContainer}>
                      {preferenceData?.attributes?.selectedService}
                    </div>
                  ) : (
                    
                    <AutocompleteSelect
                    placeholder="Select Service"
                    fieldName="service"
                    className={classes.topSelectContainer}
                    disableClear
                    onChange={
                      (event:React.ChangeEvent,value: { id: string; option: string; image:string })=> {
                        const { service_ids} = this.state;

                        service_ids[index] = parseInt(value.id);
                        this.setState({ service_ids });
                  
                        if (!this.state.product_ids[index]) return
                          
                        this.getProductPreferencesCall(this.state.product_ids[index], this.state.service_ids[index])
                        this.setState({
                          service_ids,
                          lastChangedIndex: index,
                          selectedOptionsList: this.state.selectedOptionsList.map((item: { specification_id: string | number, option_id: string | number }[], i: number) => {
                            return this.handleSelectedOptionCondition(i, index, item)
                          })
                        }, () => {
                          this.getProductPreferencesCall(this.state.product_ids[index], this.state.service_ids[index])
                        })
                  
                          }
                        }
                    value={service_ids[index]}
                    options={serviceList && serviceList.map((product:{id:string,name:string}) => ({
                      id: product.id,
                      option: product.name
                    }))}
                  />
                  )}
                </div>
              </div>
            )}
						{preferenceData.attributes && (
							<Box>
                <Box className={classes.mainContainer} style={{width: this.handleConditions(isDrawerOpen, "calc(100vw - 340px)", "calc(100vw - 158px)") }}>
                  <Box className={classes.mainContainerInner}>
                    <div className={classes.pos_relative}>
                      <Box className={classes.headerListSec}>
                        {/* on page add "item name" column to specifications line */}
                        {this.handleItemName(renderOnPage, index)}
                        {preferenceData?.attributes?.specifications?.map((item: CataloguePreferenceSpecification, index: number) => {
                          return (
                            <Typography key={index} variant="subtitle1">
                              {item.name}
                            </Typography>
                          )
                        })}
                      </Box>
                      {/* don't render dropdown icon if it is in page */}
                      {!renderOnPage && (
                        <div
                          className={classes.openCollapsedMenuIcon}
                          onClick={() => {
                            const collapsedData = [...this.state.collapsedData];
                            collapsedData[index] = true;
                            this.setState({ collapsedData });
                          }}
                        >
                            <ArrowDropDownCircleIcon className={classes.transform_180} />
                        </div>
                      )}
                    </div>
                  </Box>
                  <Box className={classes.dropDownSelection}>
                    <Box className={classes.radioDropdown}>
                      {/* on page add item name column to specification select boxes line */}
                      {this.handleNameAndService(renderOnPage, classes, preferenceData)}
                      {preferenceData?.attributes?.specifications?.map((item: CataloguePreferenceSpecification) => (
                        <FormControl variant='outlined' className={classes.w_100}>
                          <Select
                            className={classes.selectContainer}
                            data-test-id={`select-${item.name}`}
                            id={`select-${item.name}`}
                            renderValue={(value: any) => 
                              this.renderSelectedLabel(value, item)
                            }
                            displayEmpty
                            MenuProps={{
                              PaperProps: {
                                style: {
                                  maxHeight: 300,
                                }
                              },
                              anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left"
                              },
                              transformOrigin: {
                                vertical: "top",
                                horizontal: "left"
                              },
                              getContentAnchorEl: null
                            }}
                            value={selectedOptionsList[index]?.find((element: { specification_id: string | number, option_id: string | number }) => element.specification_id == item.id)?.option_id || ''}
                            onChange={(event) => {
                              const newSelectedOptions = _.cloneDeep(selectedOptionsList);
                              this.handleEmptyNewOption(newSelectedOptions, index)
                              const existingSelection = newSelectedOptions[index].find((element: { specification_id: string | number, option_id: string | number }) => element.specification_id == item.id);
                              this.handleExistingSelection(existingSelection, event, newSelectedOptions, item, index);
                              this.setState({
                                selectedOptionsList: newSelectedOptions
                              })
                            }}
                          >
                            {item.options_attributes.map((option: { id: number; label: string }) => (
                              <StyledMenuItemPreference key={option.id} value={`${option.id}`}>
                                <ListItemText primary={option.label} />
                              </StyledMenuItemPreference>
                            ))}
                          </Select>
                        </FormControl>
                      ))}	
                    </Box>
                    
                    <Box className={classes.upchargeSection} style={{ marginTop: this.handleConditions(renderOnPage, -30, 30) }}>
                      {this.handleEmptyDiv(renderOnPage, classes)}
                      <Typography className={classes.header} component="p">
                        {configJSON.upchargeText}
                        <Typography className={classes.semiColon} component="span">
                          :
                        </Typography>
                      </Typography>
                      <Box className={classes.checkboxDetail}>
                        {preferenceData?.attributes?.upcharges?.map((option: CatalogueUpchargeData, index: number) => (
                          <ListItem
                            className={classes.checkboxList}
                            key={option.id}
                            data-test-id={`upcharge-${option.id}`}
                            onClick={() => {
                              this.setState({
                                preferenceList: this.state.preferenceList.map((item: CataloguePreference) => {
                                  return this.handlePreferenceList(item, preferenceData, option)
                                })
                              })
                            }}
                          >
                            <Avatar
                              className={classes.iConSize}
                              src={this.handleConditions(option.checked, checkedIcon, unCheckedIcon)}
                              alt={option.name}
                            />
                            <Box
                              className={`${classes.checkboxInner} ${this.handleConditions(option.checked, classes.checkboxInnerChecked, classes.checkboxInnerUnChecked)}`}
                              >
                              <Avatar
                                className={`${classes.imageSize} ${this.handleConditions(option.checked, classes.chnageImageColor, "")}`}
                                src={option.icon}
                                alt={option.name}
                              />
                              <ListItemText primary={option.name} />
                              <ListItemText
                                className={classes.minWidth_85}
                                primary={`${option.currency} ${option.price}`}
                              />
                            </Box>
                          </ListItem>
                        ))}
                      </Box>
      
                    </Box>
                    
                    <Box className={classes.radioSection}>
                      {this.handleEmptyDiv(renderOnPage, classes)}
                      <Typography className={classes.header} component="p">
                        {configJSON.preferenceText}
                        <Typography className={classes.semiColon} component="span">
                          :
                        </Typography>
                      </Typography>
                      <Box className={classes.radioBox}>
                        {preferenceData?.attributes?.preferences?.map((option: CataloguePreferenceData) => (
                          <ListItem
                            key={option.id}
                            data-test-id={"radio-" + option.id}
                            onClick={() => this.setState({
                              preferenceList: this.state.preferenceList.map((item: CataloguePreference) => {
                                return this.handleUpchargeList(item, preferenceData, option)
                              })
                            })}
                          >
                            <Avatar
                              className={classes.iConSize}
                              src={this.handleConditions(!option.checked, filledCircle, openCircle)}
                              alt={option.name}
                            />
                            <Box
                              className={`${classes.checkboxInner} ${classes.radioInner} ${this.handleConditions(option.checked, classes.checkboxInnerChecked, classes.checkboxInnerUnChecked)
                                }`}>
                              <Avatar
                                className={`${classes.imageSize} ${this.handleConditions(option.checked, classes.chnageImageColor, "")
                                  }`}
                                src={option.icon}
                                alt={option.name}
                              />
                              <ListItemText className={classes.textItem} primary={option.name} />
                            </Box>
                          </ListItem>
                        ))}
                      </Box>
                    </Box>

                    {!editScreen && (
                      <Box className={classes.saveCheckbox}>
                        <Box className={classes.futureGroup}>
                          <TextareaAutosize
                            className={classes.inputFieldNote}
                            placeholder="Add Note"
                            value={preferenceData.attributes.notes as string}
                            data-test-id="notes"
                              onChange={(event) => { 
                                this.setState({
                                  preferenceList: this.state.preferenceList.map((item: CataloguePreference) => {
                                    return this.handleAddNote(item, preferenceData, event)
                                  }) as CataloguePreference[]
                                })
                              }}
                            rows={2}
                            rowsMax={6}
                          />
                        </Box>
                      </Box>
                    )}
                  </Box>
                </Box>
							</Box>
						)}
					</>
				);
			})
		)
  }

  renderEditPreferencePage = () => { 
		const { classes = {}, renderOnPage, isDrawerOpen } = this.props;
		const { specificationList, preferenceList, selectedOptionsList } = this.state;

		return (
      <Box>
        <Box className={classes.mainContainer} style={{ width: this.handleConditions(isDrawerOpen, "calc(100vw - 340px)", "calc(100vw - 158px)") }}>
          <Box className={classes.mainContainerInner}>
            <div className={classes.pos_relative}>
              <Box className={classes.headerListSec}>
                {this.handleOnPageItemName()}
                {specificationList?.map((item: { id: number, name: string }, index: number) => {
                  return <Typography key={index} variant="subtitle1">{item.name}</Typography>
                })}
              </Box>
            </div>
          </Box>
          {preferenceList.map((preferenceData: CataloguePreference, index: number) => {
            return (
              <>
                {preferenceData.attributes ?
                  <Box className={classes.dropDownSelection}>
                    <Box className={classes.radioDropdown}>
                      {this.handleNameAndServiceOnPage(renderOnPage, classes, preferenceData)}
                      {specificationList?.map((specificationItem) => {
                        let specificationExist = preferenceData?.attributes?.specifications?.filter((item) => { return item?.name === specificationItem?.name });
                        if (specificationExist?.length === 0) {
                          return <span className={classes.invisible}>{configJSON.noSpecificationText}</span>
                        }
                        else {
                          const innerItem = specificationExist && specificationExist[0];
                          return <FormControl variant='outlined' className={classes.w_100}>
                            <Select
                              data-test-id={`select-${innerItem?.name}`}
                              id={`select-${innerItem?.name}`}
                              renderValue={(value: any) => 
                                this.renderSelectedLabel(value, innerItem)
                              }
                              displayEmpty
                              className={classes.selectContainer}
                              MenuProps={{
                                PaperProps: {
                                  style: {
                                    maxHeight: 300,
                                  }
                                },
                                anchorOrigin: {
                                  vertical: "bottom",
                                  horizontal: "left"
                                },
                                transformOrigin: {
                                  vertical: "top",
                                  horizontal: "left"
                                },
                                getContentAnchorEl: null
                              }}
                              value={selectedOptionsList[index]?.find((element: { specification_id: string | number, option_id: string | number }) => element.specification_id == innerItem?.id)?.option_id || ''}
                              onChange={(event) => {
                                const newSelectedOptions = _.cloneDeep(selectedOptionsList);
                                this.handleEmptyNewOption(newSelectedOptions, index)
                                const existingSelection = newSelectedOptions[index].find((element: { specification_id: string | number, option_id: string | number }) => element.specification_id == innerItem?.id);
                                this.handleExistingSelection(existingSelection, event, newSelectedOptions, innerItem as CataloguePreferenceSpecification, index);
                                this.setState({
                                  selectedOptionsList: newSelectedOptions
                                })
                              }}
                            >
                              <StyledMenuItemPreference value="">
                                <ListItemText primary={`Select ${innerItem?.name}`} />
                              </StyledMenuItemPreference>
                              {innerItem?.options_attributes.map((option: { id: number; label: string }) => (
                                <StyledMenuItemPreference key={option.id} value={`${option.id}`}>
                                  <ListItemText primary={option.label} />
                                </StyledMenuItemPreference>
                              ))}
                            </Select>
                          </FormControl>
                        }
                      })}
                    </Box>

                    <Grid container spacing={1}>
                      <Grid item xs={12} lg={9}>
                        <Box className={classes.upchargeSection} style={{ marginTop: this.handleConditions(renderOnPage, 30, 30) }}>
                          <Typography className={classes.header} component="p">{configJSON.upchargeText}&nbsp;:</Typography>
                          <Box className={`${classes.checkboxDetail} ${classes.gridGap10}`}>
                            {preferenceData?.attributes?.upcharges?.slice(0, 6)?.map((option: CatalogueUpchargeData) => (
                              <ListItem
                                data-test-id={`upcharge-${option.id}`}
                                key={option.id}
                                className={classes.checkboxList}
                                onClick={() => {
                                  this.setState({
                                    preferenceList: this.state.preferenceList.map((item: CataloguePreference) => {
                                      return this.handlePreferenceList(item, preferenceData, option)
                                    })
                                  })
                                }}
                              >
                                <Avatar className={classes.iConSize} src={this.handleConditions(option.checked, checkedIcon, unCheckedIcon)} alt={option.name} />
                                <Box data-test-id={`upcharge-icon-${option.id}`} className={`${classes.checkboxInner} ${this.handleConditions(option.checked, classes.checkboxInnerChecked, classes.checkboxInnerUnChecked)} ${classes.ml_10}`}>
                                  <Avatar
                                    className={`${classes.imageSize} ${classes.mr_16} ${this.handleConditions(option.checked, classes.chnageImageColor, "")}`}
                                    src={option.icon}
                                    alt={option.name}
                                  />
                                  <ListItemText primary={option.name} className={classes.textLeft} />
                                  <ListItemText className={classes.minWidth_85} primary={`${option.currency} ${option.price}`} />
                                </Box>
                              </ListItem>
                            ))}
                            {preferenceData?.attributes?.upcharges?.slice(6).length > 0 && <Box className={classes.seeAllUpchargesBox}>
                              <Select
                                data-test-id='see-all-upcharge'
                                input={<OutlinedInput />}
                                displayEmpty
                                variant='outlined'
                                multiple
                                className={classes.upchargeDropdown}
                                value={this.handleSelectedDropdown(preferenceData)}
                                onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                                  let selectedItem = event.target.value as (string | number)[];
                                  let selectedValue = this.handleSelectedDropdown(preferenceData) as (string | number)[];
                                  const clickedItem: number | string | undefined = (selectedItem.length > selectedValue?.length) ? selectedItem?.find((item) => !selectedValue.includes(item)) : selectedValue.find((item) => !selectedItem.includes(item));
                                  this.setState({
                                    preferenceList: this.state.preferenceList.map((item: CataloguePreference) => {
                                      return this.handlePreferenceList(item, preferenceData, { id: clickedItem })
                                    })
                                  })
                                }}
                                renderValue={(selected: unknown) => {
                                  const selectedValues = selected as (string | number)[];
                                  if (selectedValues.length === 0) {
                                    return "See all upcharges";
                                  }

                                  return selectedValues.map((value: string | number) =>
                                    this.state.bulkUpdateUpchargeOptions.find((option: { id: number; name: string }) => option.id === value)?.name
                                  ).join(", ");
                                }}
                                MenuProps={MenuProps}
                              >
                                {preferenceData?.attributes?.upcharges?.slice(6).map((option: { id: number; name: string; price: number }) => (
                                  <StyledMultipleMenuItemPreference key={option.id} value={option.id}>
                                    <Checkbox
                                      classes={{ root: classes.blueCheckbox, checked: classes.checked }}
                                      checked={this.handleSelectedDropdown(preferenceData)?.includes(option.id)}
                                    />
                                    <div className={classes.dropdownOptionWrapper}>
                                      <ListItemText primary={option.name} />
                                      <ListItemText primary={`SAR ${option.price}`} />
                                    </div>
                                  </StyledMultipleMenuItemPreference>
                                ))}
                              </Select>
                            </Box>}
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item xs={12} lg={3}>
                        <Box className={`${classes.radioSection} ${classes.alignStart}`}>
                          <Typography className={`${classes.header} ${classes.mt_10}`} component="p">{configJSON.preferenceText}&nbsp;:</Typography>
                          <Box className={classes.radioBox}>
                            {preferenceData?.attributes?.preferences?.map((option: CataloguePreferenceData) => (
                              <ListItem
                                data-test-id={"radio-" + option.id}
                                key={option.id}
                                onClick={() => this.setState({
                                  preferenceList: this.state.preferenceList.map((item: CataloguePreference) => {
                                    return this.handleUpchargeList(item, preferenceData, option)
                                  })
                                })}
                              >
                                <Avatar
                                  src={this.handleConditions(!option.checked, filledCircle, openCircle)}
                                  className={classes.iConSize}
                                  alt={option.name}
                                />
                                <Box data-test-id={`radio-icon-box-${option.id}`} className={`${classes.checkboxInner} ${classes.radioInner} ${this.handleConditions(option.checked, classes.checkboxInnerChecked, classes.checkboxInnerUnChecked)} ${classes.ml_10}`}>
                                  <Avatar
                                    src={option.icon}
                                    className={`${classes.imageSize} ${this.handleConditions(option.checked, classes.chnageImageColor, "")}`}
                                    alt={option.name}
                                  />
                                  <ListItemText className={classes.textItemOnPage} primary={option.name} />
                                </Box>
                              </ListItem>
                            ))}
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                  : <Loader loading={this.state.isLoading} />}
              </>
            );
          })}
        </Box>
      </Box>
		)
  }

  handleRenderAsPage = () => {
    const { classes, handleClose, isDrawerOpen } = this.props;

    return (
      <div>
        <div className={classes.pageHeaderContainer} style={{width: this.handleConditions(isDrawerOpen, "calc(100vw - 340px)", "calc(100vw - 158px)") }}>
          <div onClick={handleClose} className={classes.pageHeaderBackPartContainer}>
            <div className={classes.mr_16}>
              <img src={backIcon} className={classes.iconSize}/>
            </div>
            <div className={classes.backOrderMessage}>Back to Order Creation</div>
          </div>
          <div className={classes.bulkSelectsContainer}>
            <div className={classes.mr_45}>
              <div className={classes.headerSelectLabel}>Common Upcharge</div>
              <FormControl variant='outlined' className={classes.w_100}>
                <Select
                  className={classes.multiSelectContainer}
                  data-test-id='service_offer_id'
                  id='service_offer_id'
                  multiple={true}
                  MenuProps={MenuProps}
                  value={this.state.bulkUpdateUpChargeSelectedOptions}
                  onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                    const selectedValues = event.target.value as (string | number)[];
                    this.setState({bulkUpdateUpChargeSelectedOptions:selectedValues});

                    const newPreferenceList: CataloguePreference[] = _.cloneDeep(this.state.preferenceList);

                    newPreferenceList.forEach((preference) => {
                      preference.attributes?.upcharges.forEach((upcharge: CatalogueUpchargeData) => {
                        if (selectedValues.includes(upcharge.id)) {
                          upcharge.checked = true;
                        } else {
                          upcharge.checked = false;
                        }
                      })
                    });
                    this.setState({
                      preferenceList: newPreferenceList,
                      selectAllUpcharges: false
                    });
                  }}
                  input={<OutlinedInput />}
                  renderValue={(selected: unknown) => {
                    const selectedValues = selected as (string | number)[];

                    if (selectedValues.length === 0) {
                      return "Select Upcharge";
                    }

                    if (selectedValues.includes(-1)) {
                      return "Select All";
                    }

                    return selectedValues
                      .map(
                        (value: string | number) =>
                          this.state.bulkUpdateUpchargeOptions.find((option: { id: number; name: string } ) => option.id === value)?.name
                      )
                      .join(", ");
                  }}
                  displayEmpty
                  placeholder='Select Upcharge'
                  variant='outlined'
                >
                  <StyledMultipleMenuItemPreference>
                    <Checkbox
                      checked={this.state.selectAllUpcharges}
                      onChange={this.handleSelectAllUpchargeChange}
                      color='primary'
                    />
                    <ListItemText primary={"Select All"} />
                  </StyledMultipleMenuItemPreference>
                  {this.state.bulkUpdateUpchargeOptions.map((option: { id: number; name: string; }) => (
                    <StyledMultipleMenuItemPreference key={option.id} value={option.id}>
                      <Checkbox
                        classes={{
                          root: classes.blueCheckbox,
                          checked: classes.checked,
                        }}
                         checked={this.state.bulkUpdateUpChargeSelectedOptions.includes(option.id)}
                      />
                      <ListItemText primary={option.name} />
                    </StyledMultipleMenuItemPreference>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div>
              <div className={classes.headerSelectLabel}>{configJSON.commonPreference}</div>
              <FormControl variant='outlined' className={classes.w_100}>
                <Select
                  className={classes.headerSelectContainer}
                  data-test-id={'common_preference_select'}
                  displayEmpty
                  MenuProps={MenuProps}
                  renderValue={this.state.selectedBulkPreference !== null ? undefined : () => 'Select Preference'}
                  value={this.state.selectedBulkPreference}
                  onChange={(event) =>{
                    const newPreferenceList: CataloguePreference[] = _.cloneDeep(this.state.preferenceList);

                    newPreferenceList.forEach((preference: CataloguePreference) => {
                      preference.attributes?.preferences.forEach((preference: CataloguePreferenceData) => {
                        if (event.target.value ==  preference.id) {
                          preference.checked = true;
                        } else {
                          preference.checked = false;
                        }
                      })
                    });
                    this.setState({
                      preferenceList: newPreferenceList,
                      selectedBulkPreference: event.target.value as number
                    });
                  }}
                  placeholder={'Select Preference'}
                >
                  {this.state.bulkUpdatePreferenceOptions.map((option: IPreferenceData) => (
                    <StyledMenuItemPreference
                      key={option.id}
                      value={option.id}
                    >
                      <ListItemText primary={option.name} />
                    </StyledMenuItemPreference>
                  ))}
                </Select>
              </FormControl>
            </div>
          </div>
        </div>

        {this.renderEditPreferencePage()}

        <Box className={`${classes.groupButton} ${classes.actionButton}`}>
          <Button data-test-id='cancel-btn-customer-preference' onClick={handleClose} disableRipple className={classes.cancelButton}>
            Cancel
          </Button>
          <Button
            data-test-id="create-button-customer-preference"
            disableRipple
            onClick={this.handleCreateClick}
            className={classes.addButton}
            disabled={this.props.loading}
          >
            {this.renderButton()}
          </Button>
        </Box>
      </div>
    )
  }

  renderButton = () => {
    return(
      <>
         {!this.props?.loading ?  "Save" : <CircularProgress/>}
      </>
    )
  }

  handleSelectedDropdown = (preferenceData: CataloguePreference) => {
    const updatedData = preferenceData?.attributes?.upcharges?.slice(6)?.map((item) => {
      if (item.checked === true) {
        return item.id
      }
    }).filter((itemId) => itemId !== undefined);
    return updatedData
  }

  handleNameAndService = (renderOnPage: boolean | undefined, classes: any, preferenceData: CataloguePreference) => {
    if (renderOnPage) {
     return <div className={classes.pageItemNamePartContainer}>
        <div className={classes.mb_32}>
          {this.preferenceDataName(preferenceData)}
        </div>
        <div className={classes.pageServiceName}>
          {preferenceData?.attributes?.selectedService}
        </div>
      </div>
    }
  }

  handleNameAndServiceOnPage = (renderOnPage: boolean | undefined, classes: any, preferenceData: CataloguePreference) => {
    if (renderOnPage) {
      return <div className={classes.pageItemNamePartContainerOnPage}>
        {this.preferenceDataName(preferenceData)} &nbsp;<span>|</span>&nbsp; <Avatar
          className={`${classes.iConSize} ${classes.mr_5}`}
          src={preferenceData?.attributes?.image}
          alt={preferenceData?.attributes?.name as string}
        /> <span>{preferenceData?.attributes?.selectedService}</span>
      </div>
    }
  }

  handleEmptyNewOption = (newSelectedOptions: { specification_id: string | number; option_id: string | number; }[][], index: number) => {
    if (!newSelectedOptions[index]) {
      newSelectedOptions[index] = []
    }
  }

  handleExistingSelection = (existingSelection: { specification_id: string | number; option_id: string | number; } | undefined, event: React.ChangeEvent<{ name?: string; value: unknown; }>, newSelectedOptions: { specification_id: string | number; option_id: string | number; }[][], item: CataloguePreferenceSpecification, index: number) => {
    if (existingSelection) {
      existingSelection.option_id = event.target.value as string | number;
    } else {
      newSelectedOptions[index].push({
        specification_id: item.id,
        option_id: event.target.value as string | number
      })
    }
  }

  handlePreferenceList = (item: CataloguePreference, preferenceData: CataloguePreference, option: CatalogueUpchargeData | { id: number | string | undefined }): CataloguePreference => {
    if (item.id !== preferenceData.id) {
      return item;
    }

    const attributes = item.attributes?.upcharges
      ? {
        ...item.attributes,
        upcharges: item.attributes.upcharges.map((upcharge: CatalogueUpchargeData) => {
          if (upcharge.id === option.id) {
            return {
              ...upcharge,
              checked: !upcharge.checked
            };
          } else {
            return {
              ...upcharge
            };
          }
        })
      }
      : item.attributes;

    return {
      ...item,
      attributes: attributes || undefined
    };
  }

  handleUpchargeList = (item: CataloguePreference, preferenceData: CataloguePreference, option: CataloguePreferenceData): CataloguePreference => {
    if (item.id !== preferenceData.id) {
      return item;
    }

    const attributes = item.attributes?.preferences
      ? {
        ...item.attributes,
        preferences: item.attributes.preferences.map((item: CataloguePreferenceData) => {
          if (item.id === option.id) {
            return {
              ...item,
              checked: !item.checked
            };
          } else {
            return {
              ...item,
              checked: false
            };
          }
        })
      }
      : item.attributes;

    return {
      ...item,
      attributes: attributes || undefined
    };
  }

  handleAddNote = (item: CataloguePreference, preferenceData: CataloguePreference, event: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (item.id === preferenceData.id) {
      return {
        ...item,
        attributes: {
          ...item.attributes,
          notes: event.target.value
        }
      }
    } else {
      return item
    }
  }

  handleConditions = (condition: any, firstReturnValue: any, secondReturnValue: any) => {
    if(condition) {
      return firstReturnValue
    }
    return secondReturnValue
  }

  handleSelectedOptionCondition = (i: number, index: number, item: { specification_id: string | number, option_id: string | number }[]) => {
    if (i === index) {
      return []
    }
    else {
      return item
    }
  }

  handleItemName = (renderOnPage: boolean | undefined, index: number) => {
    if (renderOnPage) {
      return <Typography key={index} variant="subtitle1">
        Item Name
      </Typography>
    }
  }

  handleOnPageItemName = () => {
    return <Typography variant="subtitle1">{configJSON.itemnameServiceText}</Typography>
  }

  handleEmptyDiv = (renderOnPage: boolean | undefined, classes: any) => {
    if(renderOnPage) {
      return <div className={classes.emptyDivToAlign} />
    }
  }

  preferenceDataName = (preferenceData: CataloguePreference) => {
    if (preferenceData.attributes) {
      return preferenceData.attributes.name
    }
  }
  
	render() {
		const { classes, open, headerTitle, handleClose, editScreen, renderOnPage } = this.props;
    if (renderOnPage) {
      return this.handleRenderAsPage();
    } else {
      return (
        <Dialog open={open} className={classes.mainDialog} data-test-id="dialog">
          <Typography className={classes.headerTitle} variant="h4">
            {headerTitle}
          </Typography>
  
          {this.renderLastPreference()}
  
          {this.state.preferenceList && this.state.preferenceList[this.state.preferenceList.length - 1]?.attributes && !editScreen && (
            <Typography
              data-test-id="add-more"
              className={classes.addMoreIcon}
            >
              <img src={addIcon} alt="plus icon" />
              <Typography 
                onClick={() => {
                  this.state.collapsedData[this.state.preferenceList.length - 1] = true;
                  this.setState({
                    collapsedData: [...this.state.collapsedData],
                    preferenceList: [...this.state.preferenceList, {}]
                  })}
                }
                className={classes.plusICon}
              >
                Add More
              </Typography>
            </Typography>
          )}
          <Box className={classes.groupButton}>
            <Button data-test-id='cancel-btn-customer-preference' onClick={handleClose} disableRipple className={classes.cancelButton}>
              Cancel
            </Button>
            <Button
              data-test-id="create-button-customer-preference"
              disableRipple
              onClick={this.handleCreateClick}
              className={classes.addButton}
              disabled={this.state.isLoader}
            >
              {this.state.isLoader ?<Box style={{ display: 'flex',alignItems:"center", gap:"10px",color:"white" }}>
                    <CircularProgress  style={{width:"24px",height:"none",color:"white"}}/> {this.handleConditions(editScreen, "Save", "Create")}
                  </Box> : this.handleConditions(editScreen, "Save", "Create")}
            </Button>
          </Box>
        </Dialog>
      ); 
    }
	}
	// Customizable Area End
}
export default withStyles(styles)(CreateCustomerPreference);

