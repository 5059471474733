import React from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

type Props = {
    isCollapsible: boolean
    title: React.ReactNode
    headerActions: React.ReactNode
    children: React.ReactNode
}
export default function CustomAccordion(props: Props) {
    const [expanded, setExpanded] = React.useState(true);

    const handleChange = () => {
        if (!props.isCollapsible) return;
        setExpanded((prev) => !prev);
    };

    return (
        <Accordion expanded={expanded} TransitionProps={{ unmountOnExit: true }}>
            <AccordionSummary
                expandIcon={props.isCollapsible ? <ExpandMoreIcon /> : null}
                IconButtonProps={{
                    onClick: handleChange
                }}
            >
                <div className="accordion-header">
                    {props.title}
                    {expanded ? props.headerActions : null}
                </div>

            </AccordionSummary>
            <AccordionDetails>
                {props.children}
            </AccordionDetails>
        </Accordion>
    );
}
