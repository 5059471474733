export const BoxImg = require('../assets/galleryIcons/box.svg');
export const DryClean = require('../assets/galleryIcons/dryClean.svg');
export const foldedClothes = require('../assets/galleryIcons/foldedClothes.svg');
export  const suit = require("../assets/galleryIcons/suit.svg");
export  const tshirt = require("../assets/galleryIcons/tShirt.svg");
export  const jeans = require("../assets/galleryIcons/jeans.svg");
export const addImage = require("../assets/galleryIcons/image_add_.svg");
export const threeDots =  require("../assets/galleryIcons/Vector.svg");
export const CrossIcon = require("../assets/catalogue/cross.svg");
export const plusIcon = require("../assets/catalogue/plus.svg");
export const deleteIcon = require("../assets/catalogue/deleteIcon.svg");
export const NoImagePlaceholder = require("../assets/no_image_placeholder.png");
export const button_minus_dark = require('../assets/button_minus_dark.svg');
export const button_minus_light = require('../assets/button_minus_light.svg');
export const button_plus_dark = require('../assets/button_plus_dark.svg');
export const button_plus_light = require('../assets/button_plus_light.svg');
export const sub_product_icon = require('../assets/sub_product_icon.svg');
export const product_img_default = require('../assets/product_img_default.png');

