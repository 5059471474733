// Customizable Area Start

export function onChangeOrganization(
  filteredOptions: Array<{ id: number; option: string }>,
  setFieldValue: Function,
  updatedValue: string,
  saveKey: string
) {
  setFieldValue("organisation", updatedValue || '');

  if (filteredOptions.length > 0) {
    setFieldValue(saveKey, filteredOptions[0]?.option);
  } else {
    setFieldValue(saveKey, "");
  }
}

// Customizable Area End
