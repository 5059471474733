import React from "react";
// Customizable Area Start
import UpchargeListController, { Props, configJSON, filterMenu } from "./UpchargeListController.web";
import PageContainer from "../../navigationmenu/src/PageContainer.web"
import TableList from "../../../components/src/customComponents/TableList.web";
import "./UpchargeList.css"
import ConfirmationModal from "../../../components/src/customComponents/ConfirmationModal.web";
import CustomPagination from "../../../components/src/customComponents/CustomPagination.web";
import { colors } from "../../utilities/src/Colors";

import {
  Button,
  Popover,
  List,
  ListItem,
  ListItemText,
  Divider,
  Box,
  TextField,
  InputLabel,
  Slider,
  InputAdornment,
  TablePagination,
  IconButton,
  styled
} from "@material-ui/core";
import { FieldConfig, FieldInputProps, Formik, FormikErrors } from 'formik';
import { createTheme, ThemeProvider, withStyles } from "@material-ui/core/styles";
import { filterIcon, activeFilterIcon } from './assets'
import { CustomSnackbar } from "../../../components/src/customComponents/CustomSnackbar.web";
import { FilterCustomAutocomplete, InputField as InnerInputField } from "../../../components/src/customComponents/CustomTags.web";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import ExportImportPopover from "../../../components/src/customComponents/ExportImportButton.web";
import { renderBaseOnConditions } from "../../../blocks/utilities/src/CustomBlockHelpers";
const theme = createTheme({
  typography: {
    fontFamily: "Montserrat",
  },
});


// Customizable Area End


class UpchargeList extends UpchargeListController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  render() {
    const renderFilters = (getFieldProps: <Value>(props: string | FieldConfig<Value>) => FieldInputProps<Value>, setFieldValue: (field: string, value: string, shouldValidate?: boolean | undefined) => Promise<void | FormikErrors<{name: string; price: string }>>, values: {name: string, price: string}) => {
      switch (this.state.selectedFilterMenu) {
        case filterMenu.upchargeName:
          return <div>
            <InputLabel style={{ ...webStyle.fieldLabel, ...webStyle.firstLabel, fontWeight: 600 }}>
              Upcharge Name
            </InputLabel>
            <FilterCustomAutocomplete
              data-test-id="autoCompleteDropdown"
              id="upcharge_name"
              name="upcharge_name"
              options={this.state.optionsList?.map((option: {label: string, value: string}) => option.label) || []}
              inputValue={this.state.upchargeValue || ""}
              value={this.state.upchargeValue || ""}
              onChange={(blank: string, value: string) => { this.setUpchargeName(value); }}
              onInputChange={(blank: string, value: string) => { this.setUpchargeName(value); }}
              clearOnBlur={false}
              clearOnEscape={false}
              renderInput={(params: JSX.IntrinsicAttributes) => (
                <InnerInputField
                  {...params}
                  variant="outlined"
                  placeholder="Type and select"
                  value={this.state.upchargeValue}
                  className="upcharge_autocomplete"
                />
              )}
              ListboxProps={
                {
                  style: {
                    maxHeight: '150px',
                  }
                }
              }
            />
          </div>;
        case filterMenu.price:
          return <div>
            <InputLabel style={{ ...webStyle.fieldLabel, ...webStyle.firstLabel }}>
              Price
            </InputLabel>
            <InputField
              id="price"
              fullWidth={true}
              placeholder="Enter Price"
              variant="outlined"
              data-test-id="price"
              {...getFieldProps('price')}
              value={this.state.priceValue}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => { this.handleChangePrice(event)}}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <span style={{color: 'black'}}>SAR</span>
                  </InputAdornment>
                ),
              }}
            />
            <InputLabel style={webStyle.fieldLabel}>or Select Range</InputLabel>
            <InputLabel style={{ ...webStyle.fieldLabel, marginBottom: '0px' }}>
              {this.state.selectedFilterRange[0]}-{this.state.selectedFilterRange[1]}
            </InputLabel>
            <StyledSlider
              data-test-id="rangeSlider"
              value={this.state.selectedFilterRange}
              valueLabelDisplay="auto"
              onChange={(event: React.ChangeEvent<{}>, value: number[]) => {
                this.setSelectedFilterRange(value)
              }}
              onClick={() => {this.onFilterClick()}}
              track="inverted"
            ></StyledSlider>
          </div>;
        default:
          return null;
      }
    };

    const open = Boolean(this.state.anchorElFilter)
    const filterId = open ? 'simple-popover' : undefined;
    const openAction = Boolean(this.state.openAction);
    const popOverId = openAction ? "simple-popover" : undefined;

    const { permissionStatus } = this.state;
    return (
      <ThemeProvider theme={theme}>
        <PageContainer 
         navigation={this.props.navigation} 
         data-test-id="pageContainer" 
         onSearchText={(value)=> this.onChangeValue(value) }
         onUserChange={(userContext) => this.handleUserChange(userContext)}
        >
          <Box>
          <StyledBoxWrapper className="font-montserrat upcharge-list-container">
            <div className="upcharge-top">
              <OrgHeading>Upcharge</OrgHeading>
              <div style={{ marginLeft: 'auto', marginRight: '24px' }}>
                {this.state.anchorElFilter !== null ?
                  <Box style={webStyle.filterActive} data-test-id="filterBtn" aria-describedby={filterId}>
                    <img src={activeFilterIcon} style={webStyle.activeFilter} data-test-id="filterIcon" alt='active filter icon' />
                  </Box>
                  :
                  <Box className="filterButtonAccount" data-test-id="filterBtn" aria-describedby={filterId} onClick={(event) => { this.onFilterBtnClick(event) }}>
                    <img src={filterIcon} alt='filter icon' />
                  </Box>
                }
                <Popover
                  id={filterId}
                  open={open}
                  anchorEl={this.state.anchorElFilter}
                  onClose={this.handleFilterPopupClose}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  data-test-id='filterPopup'
                  style={{minWidth: '500px'}}
                >

                  <Formik
                    initialValues={this.state.filtersInitialValues}
                    onSubmit={() => { }}
                    enableReinitialize
                    data-test-id='filterForm'
                  >
                    {({
                      values,
                      handleSubmit,
                      setFieldValue,
                      getFieldProps
                    }) => (
                      <form onSubmit={handleSubmit} data-test-id="addUpchargeForm">
                        <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                          <h4 style={webStyle.filterPopupTitle}>Filters</h4>
                          <h5 style={{...webStyle.filterPopupTitle, textDecoration: 'underline', cursor: 'pointer', color: '#DC2626'}} data-test-id="clearBtn" onClick={() => {this.onClearBtnClick()}}>Clear</h5>
                        </div>

                        <Divider variant="middle" />
                        <div style={{ display: 'flex' }}>
                          <StyledList style={{ marginRight: '10px' }}>
                            {Object.values(filterMenu).map((menu: string) => (
                              <ListItem
                                button
                                key={menu}
                                selected={this.state.selectedFilterMenu === menu}
                                onClick={() => this.handleFilterMenuItemClick(menu)}
                                data-test-id={`filterMenuList-${menu}`}
                                style={webStyle.filterMenuStyle}
                              >
                                <ListItemText primary={menu}/>
                              </ListItem>
                            ))}
                          </StyledList>
                          <div style={{ padding: '20px' }}>
                            {renderFilters(getFieldProps, setFieldValue, values)}
                          </div>
                        </div>
                        <Box 
                          style={{ display: 'flex', justifyContent: 'center', marginTop: '4px', marginBottom: '24px' }}
                        >
                          <Button
                            data-test-id="btnCancel"
                            style={{ ...webStyle.button, ...webStyle.buttonSecondary }}
                            onClick={this.handleCloseFilter}
                          >
                            Clear All
                          </Button>
                          <Button
                            type="submit"
                            data-test-id='btnSubmit'
                            style={{ ...webStyle.button, marginRight: '0px' }}
                            onClick={()=>{this.handleFilters()}}
                          >
                            Apply
                          </Button>
                        </Box>

                      </form>
                    )}
                  </Formik>
                </Popover>
              </div>
              {permissionStatus.exportPermission && <div>
                <IconButton
                  data-test-id={`export-import-btn`}
                  id={"IconButton"}
                  aria-describedby={popOverId}
                  onClick={(event) => this.handleUpchargePopverAction(event)}
                  className="export-btn"
                >
                  {configJSON.exportimportText} {this.state.openAction ? <ExpandLess /> : <ExpandMore />}
                </IconButton>
              </div>}
              <ExportImportPopover
                popOverId={popOverId}
                openAction={openAction}
                popoverOpen={this.state.openAction}
                popoverClose={() => {this.handleCloseActionList()}}
                exportTemplateButtonClick={() => this.handleUpchargeExportTemplate()}
                importCsvButtonClick={() => this.handleOpenImportModal()}
                exportCsvButtonClick={() => this.handleUpchargeExportCsv()}
              />
              {
                renderBaseOnConditions(
                  permissionStatus.createPermission,
                  <Button className="addButton" data-test-id="addUpcharge" onClick={this.handleAdd}>
                    Add Upcharge
                  </Button>,
                  <></>
                )
              }
            </div>
            <TableList
              data={this.state.upchargeList}
              openModalId={this.state.openModalId}
              handleOpenModel={this.handleOpenMenu}
              permission={this.state.permissionStatus}
              handleView={this.handleViewClick}
              handleEdit={this.handleEditClick}
              handleDeActivate={this.handleDeactivateClick}
              handleActivate={this.activateUpcharge}
              handleDragAndDrop={this.putDragAndDrop}
              handleQuery={(value:string)=>{this.handleQuery(value)}}
              handleUpchargeList={() => {this.handleFilters()}}
              isDragableDisabled={this.state.isDragableDisabled}
              isLoading = {this.state.isLoading}
            />
            <TablePagination
              data-test-id='upchargePagination'
              rowsPerPageOptions={[10]}
              component='div'
              count={this.state.upchargePagination?.total_count}
              rowsPerPage={this.state.pageSize}
              page={this.state.page}
              onPageChange={(event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, page: number) => { this.handlePageChange(event, page) }}
            />
          </StyledBoxWrapper>
        </Box>
          <ConfirmationModal
            open={this.state.open}
            handleClose={this.handleCloseConfirmModal}
            handleConfirm={this.handleDeactivate}
            title="Confirm Deactivate"
            message={{
                id: this.state.deActivatedItem.id, 
                title: this.state.deActivatedItem.name
              }}
              confirmText="Deactivate"
              dynamic={true}
            />
            <ConfirmationModal
              open={this.state.deactivated}
              handleClose={this.handleCloseConfirmModal}
              handleConfirm={this.handleGoBackToListing}
              title={configJSON.upchargeDeactivateMessage}
              message={{
                id: this.state.deActivatedItem.id, 
                title: this.state.deActivatedItem.name
              }}
              confirmText={configJSON.confirmButtonText}
              displayItem={true}
            />
            <CustomSnackbar
              open={this.state.errorSnackbarOpen}
              onClose={this.handleSnackbarClose}
              errorMessage={this.state.errorMessage}
              severity={this.state.snakcbarSeverity}
            />
        </PageContainer>
      </ThemeProvider>
    );
  }
  // Customizable Area End
}
export default UpchargeList;
// Customizable Area Start
const webStyle = {
  button: {
    background: "#204B9C",
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: "24px",
    color: "#FFFFFF",
    height: "44px",
    width: "126px",
    borderRadius: "8px",
    marginRight: 32,
    textTransform: "unset" as 'unset'
  },
  buttonSecondary: {
    backgroundColor: '#FFFFFF',
    color: '#DC2626',
    border: '1px solid #DC2626'
  },
  fieldLabel: {
    marginBottom: "16px",
    marginTop: '16px',
    color: "#000",
    fontSize: "14px",
    fontWeight: 500,
  },
  filterBtn: {
    width: '56px',
    height: '56px',
    borderRadius: '8px',
    border: '1px solid #ECECEC',
    background: '#FFF'
  },
  filterPopupTitle: {
    padding: '24px 24px 14px 24px',
    color: '#204B9C',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: 'normal',
    letterSpacing: '-0.35px'
  },
  filterMenuStyle: {
    color: 'black',
    fontSize: '14px',
    fontWeight: 500
  },
  firstLabel: {
    marginTop: '0px',
  },
  filter: {
    minWidth: '56px',
    height: '56px',
    border: '1px solid #ececec',
    borderRadius: '8px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: '10px',
    cursor: 'pointer'
  },
  filterActive: {
    minWidth: '56px',
    height: '56px',
    borderRadius: '8px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: '10px',
    cursor: 'pointer'
  },
  activeFilter: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer'
  },
}
const InputField = withStyles({
  root: {
    width: '100%',
    borderRadius: "8px",
    "& .MuiOutlinedInput-root": {
      fontWeight: 400,
      border: '1px solid #ECECEC',
      fontSize: "14px",
      borderRadius: "8px",
      "& fieldset": {
        fontSize: "14px",
        borderRadius: "8px",
        borderWidth: 1,
        fontWeight: 400,
      },
    },
    "& ::placeholder": {
      opacity: 1,
      color: "#4D4D4D",
      fontSize: '14px'
    },
  }
})(TextField);

const StyledList = withStyles({
  root: {
    backgroundColor: '#FFF',
    minWidth: '207px',
    "& .Mui-selected": {
      backgroundColor: '#204B9C',
      color: '#FFF !important',
      
      "&:hover": {
        backgroundColor: '#204B9C',
        color: '#FFF !important'
      }
    },
    "& .Mui-selected:hover": {
      color: '#000'
    },
    "& .MuiTypography-root": {
      fontWeight: 500,
      letterSpacing: '-0.35px',
      fontSize: '14px'
    }
  }
})(List)

const StyledSlider: React.ElementType = withStyles({
  root: {
    width: "calc(100% - 12px)",
    minwWidth: '220px',
    marginLeft: '6px'
  }
})(Slider);

const StyledBoxWrapper = styled(Box)({
  "& .export-btn": {
    width: 'auto !important',
    margin: '0 10px',
    background: colors().cyancobaltblue,
    fontWeight: 600,
    fontSize: "16px !important",
    lineHeight: "24px",
    color: colors().white,
    height: "56px",
    borderRadius: "8px",
    textTransform: "unset",
    "@media only screen and (max-width: 1024px)": {
      fontSize: "13px !important",
      width: "160px !important",
      height: "44px !important",
    },
  },
  "& .addButton": {
    background: colors().cyancobaltblue,
    fontWeight: 600,
    fontSize: "16px !important",
    lineHeight: "24px",
    color: colors().white,
    height: "56px",
    width: 'auto !important',
    borderRadius: "8px",
    textTransform: "unset",
    "@media only screen and (max-width: 1024px)": {
      fontSize: "13px !important",
      width: "160px !important",
      height: "44px !important",
    },
  },
  "& .filterButtonAccount": {  
    cursor: "pointer",
    background: colors().white,
    height: "56px",
    color:  colors().darkliver,
    width: "56px",
    fontSize: "24px",
    borderRadius: "8px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid",
    borderColor:colors().cyancobaltblue,
    "@media only screen and (max-width: 1024px)": {
      fontSize: "16px",
      width: "44px",
      height: "44px",
    },
  },
  padding:0,
	marginTop:0,
	marginBottom: 0 
});

export const OrgHeading = styled("p")({
  fontSize: "24px",
  lineHeight: "30px",
  fontWeight: 600,
  color: colors().black,
  "@media only screen and (max-width: 600px)": {
    marginBottom: "20px",
  },
  "@media only screen and (max-width: 1024px)": {
    fontSize: "16px",
    fontWeight: 600,
    color: colors().black,
  },
});

// Customizable Area End
