Object.defineProperty(exports, "__esModule", {
  value: true,
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.apiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.apiMethodType = "GET";
exports.getMethod = "GET";
exports.postMethod = "POST";
exports.putMethod = "PUT";
exports.exampleApiContentType = "application/json";
exports.contentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "CfRegionsManagement";
exports.labelBodyText = "CfRegionsManagement Body";
exports.regionViewHeader = "Region/Business Area";
exports.regionFilterSuggestionEndPoint =
  "bx_block_regions/regions/region_filter?region_name=";
exports.regionAreaFilterSuggestionEndPoint =
  "bx_block_regions/regions/region_filter?area_name=";
exports.regionCompanyFilterSuggestionEndPoint =
  "bx_block_regions/regions/region_filter?company_name=";
exports.areaCompanyNameFilterSuggEndPoint =
  "bx_block_regions/areas/area_filter?company_name=";
exports.areaNameFilterSuggEndPoint =
  "bx_block_regions/areas/area_filter?area_name=";
exports.areaRegionFilterSuggEndPoint =
  "bx_block_regions/areas/area_filter?region_name=";

exports.btnExampleTitle = "CLICK ME";
exports.regionName = "Region/Business Area Name";
exports.regionId = "Region/Business Area ID";
exports.regionShortName = "Region Short Name";
exports.country = "Country";
exports.company = "Company";
exports.telephone = "Telephone";
exports.edit = "Edit";
exports.email = "E-Mail Address";
exports.address = "Address";
exports.tokenKey = "token";
exports.addRegionHeader = "Region/Business Area Information";
exports.addAreaHeader = "Area";
exports.edit = "Edit";
exports.deactivate = "Deactivate";
exports.cancel = "Cancel";
exports.region = "Region";
exports.areaName = "Area Name";
exports.selectRegion = " Region";
exports.areaInfoHeader = "Area Information";
exports.companyName = "Company Name";
exports.companyRegistration = "Company Registration Number";
exports.regionListApi = "bx_block_regions/regions";
exports.dropdownRegionListApi = "bx_block_regions/regions?dropdown=true";
exports.regionSuggestionApi = "bx_block_regions/regions/initialize";
exports.areaListApi = "bx_block_regions/areas";
exports.deactivateContent = "Do you want to deactivate this Area?";
exports.deactivateTitle = "Area Deactivation";
exports.popoverTestID = "popoverTest";
exports.back = "Back";
exports.cancel = "Cancel";
exports.add = "Add";
exports.title = "Title";
exports.phoneNumber = "telephone";
exports.addField = "Add Field";
exports.textFieldText = "TextField Text";
exports.countryEndPoint = "bx_block_taxcalculator/countries";
exports.companyListEndPoint = "bx_block_company/companies?dropdown=true";
exports.noRecordFound = "No records found!!";
exports.save = "Save";
exports.emailReg = new RegExp(/^[^\s@]+@[^\s@]+.[^\s@]+$/);
exports.addMoreRegion = "Add More Region"
// Customizable Area End
