import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
   // Customizable Area Start
export interface TaxListApiResponse {
  errors: undefined;
  data: RawTax[];
  meta: {
    total_pages: number;
    total_count: number;
    current_page: number;
    next_page: null | number;
    pervious_page: null | number;
  };
}

export interface RawTax {
  id: string;
  type: string;
  attributes: {
    id: number;
    tax_name: string;
    tax_rate: string;
    created_at: string;
    updated_at: string;
  };
}

export interface TaxResponse {
  data: RawTax;
}

export interface Tax {
  id?: string;
  taxName: string;
  taxRate: string;
}

export const subscribeMessage = () => {
  return [
    getName(MessageEnum.RestAPIResponceMessage),
    getName(MessageEnum.SessionSaveMessage),
    getName(MessageEnum.SessionResponseMessage),
    getName(MessageEnum.RestAPIResponceErrorMessage),
    // Customizable Area Start
    getName(MessageEnum.RestAPIRequestMessage),
    // Customizable Area End
  ];
};

export interface SelectedTab {
  id: string;
  value: string;
  key: string;
}
// Customizable Area End
