import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { apiCall, handleLogout } from "../../../components/src/common";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes?: any;
  customerId?: any;
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  isLoading: boolean;
  selectedTab: any;
  customerDetails: any;
  mockData: any;
  orderList: any;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class OrderInformationController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  authToken: string = "";
  getOrderListApiCallId: string = "";

  async componentDidMount(){
    const authToken = localStorage.getItem("token");

    if (typeof (authToken) === "string") {
      this.authToken= authToken;
    }

    this.getOrderList(true);
  }
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.RestAPIResponceMessage),

      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      selectedTab: 1,
      isLoading: false,
      customerDetails: {},
      mockData: [
        {
          "id": "26",
          "type": "customer_order",
          "attributes": {
              "id": 26,
              "order_number": "OD00000006",
              "status": "placed",
              "created_at": "2023-07-14T06:12:00.295Z",
              "qty": 5,
              "order": [
                  "Shirts"
              ]
          }
      },
      {
          "id": "27",
          "type": "customer_order",
          "attributes": {
              "id": 27,
              "order_number": "OD00000007",
              "status": "placed",
              "created_at": "2023-07-14T13:02:10.040Z",
              "qty": 5,
              "order": [
                  "Shirts"
              ]
          }
      },
      {
          "id": "28",
          "type": "customer_order",
          "attributes": {
              "id": 28,
              "order_number": "OD00000008",
              "status": "placed",
              "created_at": "2023-07-18T09:09:04.482Z",
              "qty": 5,
              "order": [
                  "Shirts"
              ]
          }
      },
      ],
      orderList: []
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

      this.getOrderListApiCallResponse(apiRequestCallId, responseJson);

    }
    // Customizable Area End
  }

  // Customizable Area Start
  checkGetResponse(responseJson: any) {
    if (responseJson && !responseJson.errors) {
      return true
    }
    else {
      handleLogout(this.props.navigation, responseJson?.errors);
      return false
    }
  }

  getOrderListApiCallResponse(apiRequestCallId: any, responseJson: any) {
    if (apiRequestCallId === this.getOrderListApiCallId) {
      if (this.checkGetResponse(responseJson)) {
        this.setState({ isLoading: false, orderList: responseJson?.orders?.data });
      } else {
        this.setState({ isLoading: false })
      }
    }
  }
  handleTabChange(event:any, value:any) {
    value === 1 ? this.getOrderList(true) : this.getOrderList(false);
    this.setState({selectedTab: value})
  }

  convertTo12HourFormat = (timestamp:any) => {
    const [datePart, timePart] = timestamp.split('T');
    const [hours, minutes] = timePart.slice(0, -1).split(':');
    const hours12 = (parseInt(hours) % 12) || 12;
    const ampm = parseInt(hours) < 12 ? 'AM' : 'PM';
    const formattedDateTime = `${datePart} at ${hours12}:${minutes} ${ampm}`;
    return formattedDateTime;
  };

  getOrderList(orderType: boolean) {
    this.setState({isLoading: true})
    let url = `${configJSON.getOrderListApi}?customer_id=${this.props.customerId}&active_orders=${orderType}`;
    let headers:any = {
      "Content-type": "application/json",
      token: this.authToken
    }

    const getAccount = apiCall({
      httpBody: {},
      header: headers,
      url: url,
      httpMethod: configJSON.apiMethodTypeGet,
    });

    this.getOrderListApiCallId = getAccount.messageId;
    runEngine.sendMessage(getAccount.id, getAccount);
  }

  // Customizable Area End
}
