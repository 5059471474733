import React from "react";
// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import { IBlock } from "../../../framework/src/IBlock";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { apiCall } from "../../../components/src/common";
import {
  CustomEnums,
  getCustomEnumName,
  navigateTo,
  sortCondition,
} from "../../utilities/src/CustomBlockHelpers";
import { OrderItem, SingleOrder } from "./utils";
import { getStorageData } from "../../../framework/src/Utilities";
import moment from "moment";
export const configJSON = require("./config");
// Customizable Area End

// Customizable Area Start
// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation: any;
  id: string;
  // Customizable Area End
}

export interface S {
  // Customizable Area Start
  modalClose: boolean;
  orderItemsList: Array<OrderItem>;
  tempOrderItemsList: Array<OrderItem>;
  notes: string;
  complaint: string;
  snackBarOpen: boolean;
  snackBarMessage: string;
  severity: "success" | "error" | "warning";
  handleTabChange: Function;
  sigleOrderDetails: SingleOrder;
  PlantCleaningOrderType: boolean;
  selectedRewashOrders: Array<number>;
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class HomeCleaningProductController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  createRewashOrderMessageId: string = "";
  getOrderDetailsMessageId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getCustomEnumName(CustomEnums.RewashModalOrderDataPass),
    ];

    this.state = {
      modalClose: true,
      orderItemsList: [],
      tempOrderItemsList: [],
      notes: "",
      complaint: "",
      snackBarOpen: false,
      snackBarMessage: "",
      severity: "error",
      sigleOrderDetails: {} as SingleOrder,
      handleTabChange: () => {},
      PlantCleaningOrderType: false,
      selectedRewashOrders: [],
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const successResponseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    const apiRequestId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    if (message.id == getCustomEnumName(CustomEnums.RewashModalOrderDataPass)) {
      const order_id = message.properties.ApiResponse.temp;
      const handleTabChange = message.properties.ApiResponse.handleTabChange;
      const carpetFurnitureType =
        message.properties.ApiResponse.typePlantCarpetFurnitureOrder;
      this.setState({ PlantCleaningOrderType: carpetFurnitureType });
      this.handleDynamicData(order_id, handleTabChange, carpetFurnitureType);
    }

    if (apiRequestId === this.getOrderDetailsMessageId) {
      this.handleGetOrderDetailsResp(successResponseJson);
    } else if (apiRequestId === this.createRewashOrderMessageId) {
      this.handleCreateRewashOrderResp(successResponseJson);
    }
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  handleModalClose = () => {
    this.props.navigation.goBack();
  };

  handleUpdateQty = (order: OrderItem, keyValue: string) => {
    const { tempOrderItemsList } = this.state;

    let temp = JSON.parse(
      JSON.stringify(tempOrderItemsList)
    ) as Array<OrderItem>;

    const findIndex = temp.findIndex(
      (item) => item.attributes.id === order.attributes.id
    );

    if (findIndex !== -1 && keyValue === "plus") {
      const updatedQty = temp[findIndex].attributes["quantity"] + 1;
      temp[findIndex].attributes["quantity"] = updatedQty;

      this.setState({
        tempOrderItemsList: temp,
      });
    } else {
      const updatedQty = temp[findIndex].attributes["quantity"] - 1;
      temp[findIndex].attributes["quantity"] = updatedQty;

      this.setState({
        tempOrderItemsList: temp,
      });
    }
  };

  handleStopPropogation = (event: React.MouseEvent<HTMLImageElement>) => {
    event.stopPropagation();
  };

  handleCreateRewashOrderResp = (responseJson: { data: SingleOrder }) => {
    const { handleTabChange } = this.state;
    if (responseJson.data) {
      this.setState({
        snackBarOpen: true,
        snackBarMessage: configJSON.successOrder,
        severity: "success",
      });
      setTimeout(() => {
        navigateTo({
          props: this.props,
          screenName: "Orders",
        });
        handleTabChange(2);
      }, 700);
    }
  };

  handleGetOrderDetailsResp = (responseJson: { data: SingleOrder }) => {
    if (responseJson) {
      this.setState({
        sigleOrderDetails: responseJson.data,
        orderItemsList: responseJson.data.attributes.order_items,
        tempOrderItemsList: responseJson.data.attributes.order_items,
      });
    }
  };

  handleDynamicData = async (
    order_id: number,
    handleTabChange: Function,
    carpetFurnitureType: boolean
  ) => {
    const header = {
      token: await getStorageData(configJSON.token),
      "Content-Type": configJSON.ordersApiContentType,
    };

    const requestMessage = apiCall({
      url:
        sortCondition(
          carpetFurnitureType,
          configJSON.getPlantHomeCleaningOrder,
          configJSON.getOrderDetailsApi
        ) + `/${order_id}`,
      httpMethod: configJSON.httpGetMethod,
      header: header,
      httpBody: {},
    });

    this.getOrderDetailsMessageId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);

    this.setState({
      handleTabChange: handleTabChange,
    });
  };

  checkForButtonDisabled = (order: OrderItem, keyValue: string) => {
    const { selectedRewashOrders, tempOrderItemsList, orderItemsList } =
      this.state;

    const isIncluseOrder = selectedRewashOrders.find(
      (item) => item === order.attributes.id
    );
    const findOrignleQty = orderItemsList.find(
      (item) => item.attributes.id === order.attributes.id
    )?.attributes.quantity;

    const findChangeQty = tempOrderItemsList.find(
      (item) => item.attributes.id === order.attributes.id
    )?.attributes.quantity;

    let isQtyChange;

    if (keyValue === "plus") {
      isQtyChange = (findOrignleQty as number) > (findChangeQty as number);
    } else {
      isQtyChange = (findChangeQty as number) > 1;
    }

    if (isIncluseOrder && isQtyChange) {
      return false;
    } else {
      return true;
    }
  };

  handleChangeNotes = (event: React.ChangeEvent<{ value: string }>) => {
    this.setState({
      notes: event.target.value,
    });
  };

  handleChangeComplaint = (event: React.ChangeEvent<{ value: string }>) => {
    this.setState({
      complaint: event.target.value,
    });
  };

  getCreateRewashBody = () => {
    let orderType = sortCondition(
      this.state.PlantCleaningOrderType,
      "PlantCleaningOrder",
      "RewashOrder"
    );
    const {
      selectedRewashOrders,
      tempOrderItemsList,
      notes,
      complaint,
      sigleOrderDetails,
    } = this.state;

    const orderItems = tempOrderItemsList.filter((item) =>
    selectedRewashOrders.includes(item.attributes.id)
    );

    const baseAttributes = {
      store_management_id: sigleOrderDetails.attributes.store_management_id,
      rewash_order: true,
      parent_order_id: sigleOrderDetails.attributes.id,
      customer_complaint: complaint,
      notes: notes,
    };

    const typeSpecificAttributes = {
      RewashOrder: {
        customer_id: sigleOrderDetails.attributes.customer.id,
        order_items_attributes: orderItems.map((item) => ({
          parent_order_item_id: item.attributes.id,
          quantity: item.attributes.quantity,
        })),
      },
      PlantCleaningOrder: {
        account_id: sigleOrderDetails.attributes.customer.id,
        type: "PlantCleaningOrder",
        home_cleaning_order_items_attributes: orderItems.map((item) => ({
          parent_order_item_id: item.attributes.id,
          quantity: item.attributes.quantity,
        })),
      },
    };

    return {
      data: {
        ...baseAttributes,
        ...typeSpecificAttributes[
          orderType as "RewashOrder" | "PlantCleaningOrder"
        ],
      },
    };
  };

  handleSubmitRewash = async () => {
    const { selectedRewashOrders } = this.state;

    if (selectedRewashOrders.length < 1) {
      this.setState({
        snackBarOpen: true,
        snackBarMessage: "Please select product for rewash",
        severity: "error",
      });
      return;
    }

    const header = {
      token: await getStorageData(configJSON.token),
      "Content-Type": configJSON.ordersApiContentType,
    };

    const requestMessage = apiCall({
      url: sortCondition(
        this.state.PlantCleaningOrderType,
        configJSON.plantHomeCleaningOrderRewash,
        configJSON.createRewashOrderApi
      ) as string,
      httpMethod: configJSON.httpPostMethod,
      header: header,
      httpBody: this.getCreateRewashBody(),
    });

    this.createRewashOrderMessageId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleSnackbarClose = () => {
    this.setState({
      snackBarOpen: false,
    });
  };

  formatCreatedDate = (created_at: string) => {
    return moment(created_at).format("DD/MM/YYYY")
  }
  handleToggleOrderSelection = (orderId: number) => {
    const { selectedRewashOrders } = this.state;
    let array: number[] = JSON.parse(JSON.stringify(selectedRewashOrders));
    if (array.includes(orderId)) {
      array = array.filter((item: number) => item != orderId);
    } else {
      array.push(orderId);
    }

    this.setState({ selectedRewashOrders: array });
  };

  handleSelectAllRewashOrder = () => {
    const { tempOrderItemsList, selectedRewashOrders } = this.state;
    if (selectedRewashOrders.length === tempOrderItemsList.length) {
      this.setState({
        selectedRewashOrders: [],
      });
    } else {
      const tempList = tempOrderItemsList.map((item) => item.attributes.id);

      this.setState({
        selectedRewashOrders: tempList,
      });
    }
  };

  handleCheckBoxSelection = (
    flag: boolean,
    trueValue: string,
    falseValue: string
  ) => {
    return flag ? trueValue : falseValue;
  };
  // Customizable Area End
}
