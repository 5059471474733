import React from "react";

// Customizable Area Start
import {
    Button,
    Paper,
    Box,
    styled,
    Typography,
    Snackbar
} from "@material-ui/core";
import Alert from '@material-ui/lab/Alert';
import { colors } from "../../utilities/src/Colors";
import { sortCondition } from "../../../blocks/utilities/src/CustomBlockHelpers";
import { Formik } from 'formik'
import moment from "moment";
import * as Yup from "yup";
import AutocompleteSelect from "../../../components/src/AutocompleteSelect.web";
import Loader from "../../../components/src/Loader.web";
import { currentSaudiTime } from "./utils";
// Customizable Area End

// Customizable Area Start
import AddCashUpController, { Props, DriverDropdown, FormValues, StoreManagementDropdown } from "./AddCashUpController.web";
// Customizable Area End

export default class AddCashUp extends AddCashUpController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
    renderInput = (label: string, inputType: string, inputName: keyof FormValues, inputReadOnly: boolean, formikProps: { touched: Partial<Record<keyof FormValues, boolean>>, errors: Partial<Record<keyof FormValues, string>>, values: { [index: string]: string }, handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void }, endAdornment?: string) => {
        return (
            <Box visibility={sortCondition(inputName=="none","hidden","unset")} className={sortCondition(endAdornment as unknown as boolean, 'inputContainer relative', 'inputContainer') as string}>
                <Typography className="lable-16-600">{label}</Typography>
                <Box>
                    <input data-test-id={`input-${inputName}`} readOnly={inputReadOnly} name={inputName} type={inputType} value={formikProps.values[inputName]} onChange={formikProps.handleChange} className={sortCondition(endAdornment as unknown as boolean, 'inputStyle inputPaddingEndAdornment', 'inputStyle') as string} />
                    {endAdornment && <Typography className="lable-16-400 endAdornment">{endAdornment}</Typography>}
                    {formikProps.touched[inputName] && formikProps.errors[inputName] && <Typography className="inputErrorTxt">{formikProps.errors[inputName]}</Typography>}
                </Box>
            </Box>
        )
    }

    renderNumberInput = (label: string, inputType: string, inputName: keyof FormValues, inputReadOnly: boolean, formikProps: { touched: Partial<Record<keyof FormValues, boolean>>, errors: Partial<Record<keyof FormValues, string>>, values: { [index: string]: string }, handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void }, endAdornment?: string) => {
        return (
            <Box className={sortCondition(endAdornment as unknown as boolean, 'inputContainer relative', 'inputContainer') as string}>
                <Typography className="lable-16-600">{label}</Typography>
                <Box>
                    <input onFocus={(e: React.FocusEvent<HTMLInputElement>) => {
                          e.target.addEventListener(
                            "wheel",
                            function (e: WheelEvent) {
                              e.preventDefault();
                            },
                            { passive: false }
                          );
                        }} data-test-id={`input-${inputName}`} readOnly={inputReadOnly} name={inputName} type={inputType} value={formikProps.values[inputName]} onChange={formikProps.handleChange} className={sortCondition(endAdornment as unknown as boolean, 'inputStyle inputPaddingEndAdornment', 'inputStyle') as string} />
                    {endAdornment && <Typography className="lable-16-400 endAdornment">{endAdornment}</Typography>}
                    {formikProps.touched[inputName] && formikProps.errors[inputName] && <Typography className="inputErrorTxt">{formikProps.errors[inputName]}</Typography>}
                </Box>
            </Box>
        )
    }

    renderSelectDriver = (label: string, selectName: keyof FormValues, optionArray: DriverDropdown[], formikProps: { touched: Partial<Record<keyof FormValues, boolean>>, errors: Partial<Record<keyof FormValues, string>>, values: { [index: string]: string }, handleChange: (event: React.ChangeEvent<{ name?: string; value: unknown; }>) => void, setFieldValue: Function }) => {
        return (
            <Box className="inputContainer" >
                <Typography className="lable-16-600">{label}</Typography>
                <Box>
                    <AutocompleteSelectResponsive
                        placeholder="Please select driver"
                        fieldName={selectName}
                        data-test-id="driverSelect"
                        disableClear
                        onChange={
                            (event: React.ChangeEvent, value: { id: string; option: string }) => {
                                formikProps.setFieldValue(selectName,value.id)
                            }}
                        value={formikProps.values[selectName]}
                        options={this.convertToOptionObjForDriver(optionArray)}
                    />
                    {formikProps.touched[selectName] && formikProps.errors[selectName] && <Typography className="inputErrorTxt">{formikProps.errors[selectName]}</Typography>}
                </Box>
            </Box>
        )
    }

    renderSelectStore = (label: string, selectName: keyof FormValues, optionArray: StoreManagementDropdown[], formikProps: { touched: Partial<Record<keyof FormValues, boolean>>, errors: Partial<Record<keyof FormValues, string>>, values: { [index: string]: string }, handleChange: (event: React.ChangeEvent<{ name?: string; value: unknown; }>) => void }) => {
        return (
            <Box className="inputContainer" >
                <Typography className="lable-16-600">{label}</Typography>
                <Box>
                    <AutocompleteSelectResponsive
                        placeholder="Please select store"
                        fieldName={selectName}
                        data-test-id="storeSelect"
                        disableClear
                        onChange={
                            (event: React.ChangeEvent, value: { id: string; option: string }) => {
                                this.hanldeStoreChange(value.id)
                            }}
                        value={formikProps.values[selectName]}
                        options={this.convertToOptionObjForStore(optionArray)}
                    />
                    {formikProps.touched[selectName] && formikProps.errors[selectName] && <Typography className="inputErrorTxt">{formikProps.errors[selectName]}</Typography>}
                </Box>
            </Box>
        )
    }

  // Customizable Area End

  render() {
    // Customizable Area Start
    const { handleCancel } = this.props;
    const { driverData } = this.state;
    return (
        <StyledWrapper>
            <Loader loading={this.state.loading} isAllowed={true} hideBackgroundColor={true} />

            <Box className="headingContainer">
                <Box>
                    <Typography gutterBottom className="lable-16-700">Cash up</Typography>
                </Box>
            </Box>

            <Formik
                innerRef={this.formikRef}
                initialValues={{
                    transfer_id: "",
                    date: currentSaudiTime(),
                    shopkeeperName: "",
                    opening_cash_date: currentSaudiTime(),
                    station: "1",
                    opening_cash: "0.00",
                    petty_cash: "0.00",
                    cash_from_orders: "",
                    cash_in: "0.00",
                    cash_out: "0.00",
                    amount: "0.00",
                    variance: "0.00",
                    driver_account_id: "",
                    store_id: "",
                    none: ""
                }}
                validationSchema={Yup.object().shape({
                    station: Yup.string().trim()
                        .required("Station number is required"),
                    driver_account_id: Yup.string().trim()
                        .required("Driver selection is required"),
                    store_id: Yup.string().trim()
                        .required("Store selection is required"),
                    opening_cash:nonNegativeNumber,
                    petty_cash: nonNegativeNumber,
                    cash_in: nonNegativeNumber,
                    cash_out: nonNegativeNumber,
                    amount: nonNegativeNumber
                })}
                onSubmit={(dataObject) => this.createCashUp(dataObject)}
            >
                {({
                    handleSubmit,
                    values,
                    handleChange,
                    handleReset, errors, touched, setFieldValue
                }) => {
                    return (
                        <form onSubmit={handleSubmit} onReset={handleReset}>
                            <Paper className="paperContainer">
                                <Box className="rowSeries">
                                    {this.renderInput("Cash up ID", "text", "transfer_id", true, { handleChange, values, errors, touched })}
                                    {this.renderInput("Date", "text", "date", true, { handleChange, values, errors, touched })}
                                    {this.renderInput("Shopkeeper", "text", "shopkeeperName", true, { handleChange, values, errors, touched })}
                                    {this.renderInput("Opening Cash Date", "text", "opening_cash_date", true, { handleChange, values, errors, touched })}
                                </Box>
                                <Box className="rowSeries">
                                    {this.renderInput("Station Number", "text", "station", false, { handleChange, values, errors, touched })}
                                    {this.renderSelectStore("Store", 'store_id', this.props.storeListData as StoreManagementDropdown[], { handleChange, values, errors, touched })}
                                    {this.renderSelectDriver("Driver", 'driver_account_id', driverData, { handleChange, values, errors, touched, setFieldValue })}
                                    {this.renderNumberInput("Opening Cash", "number", "opening_cash", false, { handleChange, values, errors, touched }, "SAR")}
                                </Box>
                                <Box className="rowSeries">
                                    {this.renderNumberInput("Petty Cash", "number", "petty_cash", false, { handleChange, values, errors, touched }, "SAR")}
                                    {this.renderInput("Cash From Orders", "number", "cash_from_orders", true, { handleChange, values, errors, touched }, "SAR")}
                                    {this.renderNumberInput("Cash In", "number", "cash_in", false, { handleChange, values, errors, touched }, "SAR")}
                                    {this.renderNumberInput("Cash Out", "number", "cash_out", false, { handleChange, values, errors, touched }, "SAR")}
                                </Box>
                                <Box className="rowSeries">
                                    {this.renderNumberInput("Declare Cash", "number", "amount", false, { handleChange: (event) => this.handleChangeDeclareCash(event, handleChange, setFieldValue, values), values, errors, touched }, "SAR")}
                                    {this.renderInput("Variance", "number", "variance", true, { handleChange, values, errors, touched }, "SAR")}
                                    {this.renderInput("none", "none", "none", true, { handleChange, values, errors, touched }, "SAR")}
                                    {this.renderInput("none", "none", "none", true, { handleChange, values, errors, touched }, "SAR")}
                                </Box>
                            </Paper>

                            <Box className="actionBtnContainer">
                                <Button onClick={() => handleCancel()} variant="contained" className="cancelBtn">Cancel</Button>
                                <Button disabled={this.state.loading} data-test-id={"submitBtn"} type="submit" variant="contained" className="submitBtn">Submit</Button>
                            </Box>
                        </form>
                    )
                }
                }
            </Formik>

            <Snackbar anchorOrigin={{
                vertical: "top",
                horizontal: "center"
            }}
                autoHideDuration={2000}
                open={this.state.snackbar.open}
                onClose={this.handleCloseSnackbar}
                data-test-id="snackBar"
            >
                <Alert severity={this.state.snackbar.severity}>{this.state.snackbar.message}</Alert>
            </Snackbar>

        </StyledWrapper>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
export const StyledWrapper = styled(Box)({
    "& .headingContainer": {
    },
    "& .inputErrorTxt":{
        marginTop:"5px",
        color: "red",
        fontSize:"14px",
        fontFamily: "Montserrat",
        fontWeight: 400
    },
    "& .lable-16-700": {
        fontSize: "16px",
        fontWeight: 700,
        fontFamily: "Montserrat",
        "@media only screen and (max-width: 1280px)": {
            fontSize: "14px !important",
        },
        "@media only screen and (max-width: 1024px)": {
            fontSize: "13px !important",
        }
    },
    "& .lable-16-600": {
        fontSize: "16px",
        fontFamily: "Montserrat",
        fontWeight: 600,
        "@media only screen and (max-width: 1280px)": {
            fontSize: "14px",
        },
        "@media only screen and (max-width: 1024px)": {
            fontSize: "13px",
        }
    },
    "& .lable-16-400": {
        fontSize: "16px",
        fontFamily: "Montserrat",
        fontWeight: 400,
        "@media only screen and (max-width: 1280px)": {
            fontSize: "14px !important",
        },
        "@media only screen and (max-width: 1024px)": {
            fontSize: "13px !important",
        }
    },
    "& .paperContainer": {
        boxSizing: "border-box",
        background: colors().white,
        borderRadius: "12px",
        boxShadow:
            "0px 4px 8px rgba(0, 0, 0, 0.03), 0px 5px 32px rgba(0, 0, 0, 0.06)",
        padding: "32px",
        display: "flex",
        flexDirection: "column",
        rowGap: "20px",
    },
    "& .inputStyle": {
        "maxWidth": "292px",
        width:"100%",
        boxSizing: "border-box",
        fontFamily: "Montserrat",
        "height": "56px",
        "padding": "10px 16px 10px 16px",
        "gap": "8px",
        "borderRadius": "8px",
        "border": "1px solid #ECECEC",
        fontSize: "16px",
        fontWeight: 400,
        "-moz-appearance": "textfield",
        '&[readonly]': {
            backgroundColor: '#F8F8F8',
        },
        "@media only screen and (max-width: 1366px)": {
            "height": "55px",
        },
        "@media only screen and (max-width: 1280px)": {
            "height": "50px",
            fontSize: "15px",
        },
        "@media only screen and (max-width: 1024px)": {
            "height": "40px",
            fontSize: "13px",
        }
    },
    "& .inputContainer": {
        display: "flex",
        width:"100%",
        flexDirection: "column",
        gap: "10px"
    },
    "& .rowSeries": {
        display: "flex",
        gap: "20px"
    },
    "& .relative": {
        position: "relative"
    },
    "& .endAdornment": {
        position: "absolute",
        left: "10px",
        top: "50px",
        "@media only screen and (max-width: 1280px)": {
            top: "46px"
        },
        "@media only screen and (max-width: 1024px)": {
            top: "40px"
        }
    },
    "& .inputPaddingEndAdornment": {
        "padding": "10px 16px 10px 54px",
    },
    "& .actionBtnContainer": {
        margin: "40px 0 20px 0",
        display: "flex",
        gap: "24px"
    },
    "& .submitBtn": {
        backgroundColor: colors().cyancobaltblue,
        width: "128px",
        textTransform: "none",
        height: "56px",
        fontSize: "16px",
        fontWeight: 700,
        borderRadius: "8px",
        color: colors().white,
        "@media only screen and (max-width: 1280px)": {
            height: "50px",
            width: "114px",
            fontSize: "14px",
        },
        "@media only screen and (max-width: 1024px)": {
            height: "45px",
            width: "102px",
            fontSize: "13px",
        }
    },
    "& .cancelBtn": {
        backgroundColor: colors().antiFlashWhite,
        textTransform: "none",
        height: "56px",
        width: "128px",
        fontSize: "16px",
        fontWeight: 700,
        borderRadius: "8px",
        color: colors().slateGray,
        "@media only screen and (max-width: 1280px)": {
            height: "50px",
            width: "114px",
            fontSize: "14px",
        },
        "@media only screen and (max-width: 1024px)": {
            height: "45px",
            width: "102px",
            fontSize: "13px",
        }
    },
})

const AutocompleteSelectResponsive = styled(AutocompleteSelect)({
    borderRadius: 8,
    maxWidth: "292px",
    width:"100%",
    boxSizing: "border-box",
    "& .MuiSelect-outlined": {
        "border": "1px solid #ECECEC",
    },
    "& .MuiOutlinedInput-notchedOutline": {
        border: "0"
    },
    "@media only screen and (max-width: 1366px)": {
        "height": "55px",
        '& .MuiOutlinedInput-input':{
            padding: '17px 14px',
        }
    },
    "@media only screen and (max-width: 1280px)": {
        "height": "50px",
        fontSize: "15px",
        "& .MuiAutocomplete-inputRoot":{
            padding:"6px"
        },
        '& .MuiInputBase-input': {
            fontSize: '15px !important',
        },
        '& .MuiOutlinedInput-input':{
            padding: '15px 14px',
        }
    },
    "@media only screen and (max-width: 1024px)": {
        "height": "40px",
        fontSize: "13px",
        "& .MuiAutocomplete-inputRoot":{
            padding:"0"
        },
        '& .MuiInputBase-input': {
            fontSize: '13px !important',
        },
        '& .MuiOutlinedInput-input':{
            padding: '11.5px 14px !important',
        }
    }
});

const nonNegativeNumber = Yup.string().trim().test(
    'is-non-negative',
    'Amount cannot be negative',
    value => parseFloat(value) >= 0
).required('Number is required')

// Customizable Area End
