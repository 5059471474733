// Customizable Area Start
import React from "react";
import {
  Box,
  IconButton,
  styled,
  Typography,
  Button,
  TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Tooltip
} from "@material-ui/core";
import FilterIcon from '@material-ui/icons/FilterListRounded';
import FilterPopover from "../../../components/src/FilterPopover";
import RevenueDashboardController, { Props, revenueColumns, totalColumns, dateColumns, configJSON } from "./RevenueDashboardController";
// Customizable Area End

// Customizable Area Start
import DashboardTabPanel from "../../../components/src/DashboardTabPanel.web";
import PageContainer from "../../navigationmenu/src/PageContainer.web";
import { calendarIcon } from "./assets";
import { SingleHorizontalBarChart } from "../../../components/src/CustomCharts";
import { colors } from "../../../blocks/utilities/src/Colors";
import ExportModal from "../../../components/src//ExportModal";
import { getGroupNameDisplay } from "../../cfsalesdashboard3/src/utils";
import { sortCondition } from "../../../blocks/utilities/src/CustomBlockHelpers";
import { storeNameBasedOnGroup } from "./utils";
// Customizable Area End

export default class RevenueDashBoard extends RevenueDashboardController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { regionName, storeName } = this.state
    // Customizable Area End
    return (
      // Customizable Area Start
      <PageContainer
        navigation={this.props.navigation}
        onMultiRegionChange={()=>true}
        onStoreAndGroupChange={this.handleStoreChange}
        onUserChange={this.handleUserChange}
      >
        <DashboardTabPanel navigation={this.props.navigation} id="RevenueDashboard" permissionStatus={this.state.permissionStatus} />
        <RevenueDashboardWrapper id={sortCondition(this.state.openExportModal, "print-wrapper", undefined) as string}>
          <Box className="headingContainer">
            <Box className="leftHeading">
              <Box className="rowHeading">
                <Box className="fieldBox">
                  <Typography className="label">Region: </Typography>
                  <Typography className="value">{regionName}</Typography>
                </Box>
                <Box className="fieldBox">
                  <Typography className="label">Store: </Typography>
                  <Typography className="value">{storeNameBasedOnGroup(this.state.storeId)}</Typography>
                </Box>
                <Box className="fieldBox">
                  <Typography className="label">Group: </Typography>
                  <Typography className="value">{getGroupNameDisplay(this.state.groupId)}</Typography>
                </Box>
              </Box>
              <Box className="rowHeading">
                <Box className="fieldBox">
                  <Typography className="label">Period: </Typography>
                  <Typography className="value">{this.handleDateFilterParam().startDateText} _ {this.handleDateFilterParam().endDateText}</Typography>
                </Box>
              </Box>
            </Box>
            <Box className="actionBtnContainer">
              <div data-test-id="filterButton" onClick={(event) => { this.handleFilterOpen(event) }}>
                <IconButton className="filterBtn">
                  <FilterIcon />
                </IconButton>
              </div>

              <button
                onClick={this.handleOpenExportModal}
                data-test-id='export-all'
                className='exportAll'
                
              >
                {configJSON.exportAll}
              </button>
            </Box>
            <FilterPopover
              data-test-id="filterPopover"
              onClose={() => { this.handleCloseFilterPopover() }}
              anchor={this.state.filterAnchor}
              onFilterChange={this.handleFilterChangeAccount}
              filters={this.state.filters}
            />
          </Box>
          <Box className="chartWrapper">
            <SingleHorizontalBarChart data={this.state.revenueRequestTypeTotal} title="Total" hasIcon isLoading={this.state.isLoadingTotal} right={80}/>
            <SingleHorizontalBarChart data={this.state.revenueRequestTypeRevenue} dataHeader={this.state.revenueRequestTypeRevenueHeader} title="Revenue" color="#34D399" isLoading={this.state.isLoadingRevenue} right={80}/>
            <SingleHorizontalBarChart data={this.state.revenueRequestTypeUnpaid} dataHeader={this.state.revenueRequestTypeUnpaidHeader} title="Unpaid" color="#F0714D" isLoading={this.state.isLoadingUnpaid} right={80}/>
          </Box>

          <TableWrapper >
            <p className="table-title">
              <span className="table-label">
                <img src={calendarIcon} alt="" />
                Year - 2024
              </span>
              <span className="table-range">
                {this.handleDateFilterParam().startDateText} to {this.handleDateFilterParam().endDateText}
              </span>
            </p>

            <StyledTable>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell colSpan={1}>
                      <p>Dates</p>
                    </TableCell>
                    <TableCell colSpan={7}>
                      <p>Total</p>
                    </TableCell>
                    <TableCell colSpan={6}>
                      <p>Revenue</p>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    {
                      dateColumns.map(column => (
                        <TableCell key={column.binding}>
                          <p className={"sub-header"}>
                            {column.header}
                          </p>
                        </TableCell>
                      ))
                    }
                    {
                      totalColumns.map(column => (
                        <TableCell key={column.binding}>
                          <p className={"sub-header"}>
                          {column.header} {column.unit}
                          </p>
                        </TableCell>
                      ))
                    }
                    {
                      revenueColumns.map(column => (
                        <TableCell key={column.binding}>
                          <p className={"sub-header"}>
                           {column.header} {column.unit}
                          </p>
                        </TableCell>
                      ))
                    }
                  </TableRow>
                  {
                    this.state.revenueByYears.map((revenue, index) => (
                      <TableRow key={index}>
                        {
                          dateColumns.map(column => (
                            <TableCell key={column.binding}>
                              <p className={"dark-color dates"}>
                                {revenue.dates}
                              </p>
                            </TableCell>
                          ))
                        }
                        {
                          totalColumns.map(column => (
                            <TableCell key={column.binding}>
                              <Tooltip title={revenue.total[column.binding]} arrow>
                                <p className={"dark-color"}>
                               {revenue.total[column.binding]}
                                </p>
                              </Tooltip>
                            </TableCell>
                          ))
                        }
                        {
                          revenueColumns.map(column => (
                            <TableCell key={column.binding}>
                              <Tooltip title={revenue.revenue[column.binding]} arrow>
                                <p className={"dark-color"}>
                                {revenue.revenue[column.binding]}
                                </p>
                              </Tooltip>
                            </TableCell>
                          ))
                        }
                      </TableRow>
                    ))
                  }
                </TableBody>
              </Table>
            </StyledTable>
          </TableWrapper>
          <ExportModal isOpen={this.state.openExportModal} onCancel={this.handleOpenExportModal} onConfirm={this.handleExportConfirm} />
        </RevenueDashboardWrapper>
      </PageContainer>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const RevenueDashboardWrapper = styled("div")({
  "& .headingContainer": {
    display: "flex",
    justifyContent: "space-between",
    fontFamily: "Montserrat",
  },
  "& .leftHeading": {
    display: "flex",
    gap: "1rem",
    flexDirection: "column",
    marginBottom: '1rem'
  },
  "& .rowHeading": {
    display: "flex",
    gap: "2rem"
  },
  "& .fieldBox": {
    display: "flex",
    gap: "0.5rem",
    lineHeight: "24px",
    fontSize: "1rem",
    "& .label": {
      fontWeight: 700,
      fontFamily: "Montserrat",
    },
    "& .value": {
      fontWeight: 400,
      fontFamily: "Montserrat",
      color: colors().darkjunglegreen
    }
  },
  "& .actionBtnContainer": {
    display: "flex",
    gap: "1rem",
    alignItems: "center",
    marginBottom: '1rem',
    justifyContent: "center"
  },
  "& .chartWrapper": {
    display: "grid",
    gridTemplateColumns: "repeat(3,1fr)",
    gap: "1rem",
    "@media only screen and (max-width: 1280px)": {
      gridTemplateColumns: "repeat(2,1fr)",
    },
    "& > div": {
      paddingTop: 12
    },
    "& > div:nth-child(1)":{
      "& .headerTitle": {
        marginBottom:'2.3rem',
      }
    },
  },
  "& .print": {
    display: "flex",
    gap: "1rem",
    justifyContent: "center",
    alignItems: "center"
  },
  "& .exportAll": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: 10,
    padding: "10px 16px",
    lineHeight: 1.5,
    border: 0,
    borderRadius: 8,
    background: colors().lightgray,
    color: colors().darkliver,
    fontWeight: 600,
    fontSize: "16px",
    cursor: "pointer",
  },
})

const TableWrapper = styled("div")({
  borderRadius: 12,
  display: "flex",
  flexDirection: "column",
  gap: 16,
  margin: "1rem 0",
  maxWidth:"100%",
  "& .table-title": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderBottom: `solid 1px ${colors().lightborder}`,
    padding: 12
  },
  "& .table-label": {
    display: "flex",
    gap: 8,
    alignItems: "center",
    fontSize: 19,
    fontWeight: 500,
    lineHeight: 1.5,
  },
})

const StyledTable = styled(TableContainer)({
  borderRadius: 12,
  backgroundColor: colors().brightgray,
  padding: "12px 0",
  margin: "1rem 0 4rem 0",
  "& .MuiTableCell-root": {
    borderBottom: 0,
    padding: 10,
    color: colors().darkliver,
    fontSize: 14,
    lineHeight: 1.5,
    "&:nth-child(1)": {
      borderRight: `solid 1px ${colors().dividerColor}`,
    },
    "&:nth-child(8)": {
      borderRight: `solid 1px ${colors().dividerColor}`,

    },
    "&.MuiTableCell-head": {
      fontSize: 13,
      lineHeight: "15.6px",
      fontWeight: 600,
      paddingTop: 0,
      paddingBottom: 12,
      borderBottom: `solid 1px ${colors().dividerColor}`,
      "&:first-child": {
        color: colors().darkjunglegreen,
        fontWeight: 600
      },
      "&:nth-child(2)": {
        borderRight: `solid 1px ${colors().dividerColor}`,
      },
      "&:nth-child(3)": {
        borderBottom: `solid 1px ${colors().dividerColor}`
      },
    },
    "&.MuiTableCell-body": {
      fontWeight: 500,
      paddingBottom: 0,
      "&:first-child": {
        fontWeight: 600
      },
      "&:nth-child(8)": {
        borderRight: `solid 1px ${colors().dividerColor}`,
      },
    },
    "& .sub-header": {
      fontWeight: 400,
      whiteSpace:"nowrap"
    },
    "& .dark-color": {
      color: colors().darkjunglegreen,
      whiteSpace: "nowrap",
    },
    "& .dates": {
      maxWidth: "10ch",
    }
  },
})
// Customizable Area End