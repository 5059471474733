Object.defineProperty(exports, "__esModule", {
  value: true,
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "HelpdeskReporting";
exports.labelBodyText = "Helpdesk Reporting Module";
exports.labelTicketName = "Ticket Name";
exports.labelEmail = "Email";
exports.labelDesc = "Ticket Description";
exports.btnTitle = "Help";
exports.btnTicket = "Create Ticket";
exports.btnCreateTitle = "Create Ticket";
exports.ticketNameError = "Please Enter Ticket Name";
exports.ticketEmailError = "Please Enter Email Address";
exports.ticketValidEmailError = "Please Enter Valid Email Address";
exports.ticketDescError = "Please Enter Ticket Description";
exports.blankString = "";
exports.ticketListingDataApi =
  "https://jlaundrygitex2022-283543-ruby.b283543.dev.eastus.az.svc.builder.cafe/bx_block_helpdeskreporting/helpdeskreportings";
exports.createTicketApi =
  "https://jlaundrygitex2022-283543-ruby.b283543.dev.eastus.az.svc.builder.cafe/bx_block_helpdeskreporting/helpdeskreportings";
exports.requestMethodOfListing = "GET";
exports.requestMethodOfAdding = "POST";
exports.successMessage = "Ticket Created Successfully";
// Customizable Area End
