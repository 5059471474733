import React, { useCallback, useState } from "react";
import { StyledCheckBox } from "./CustomTheme.web";
import { makeStyles } from '@material-ui/core/styles';
import Autocomplete,{ AutocompleteRenderGroupParams } from "@material-ui/lab/Autocomplete";
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { withStyles } from "@material-ui/core/styles";
import { IProductItems } from "../../blocks/CfPriceList/src/PriceListViewController.web";
import {Collapse,List, ListItem,
    styled,Typography,Divider,ListSubheader, Box, TextField, TextFieldProps,Popper,PaperProps,PopperProps,Paper ,
    FormControlLabel, Radio, } from "@material-ui/core";
import {isEmpty} from "lodash"
const autoSeletStyle = {
    radioIcon: {
      display: "inline-block",
      borderRadius: "51%",
      width: 20,
      height: 20,
      border: "1px solid #64748C",
    },
    radioCheckedIcon: {
      display: "inline-block",
      width: 20,
      borderRadius: "51%",
      height: 20,
      border: "6px solid #204B9D",
      color: "#204b9c",
    },
    inputField: { 
      paddingTop: "0px",
        }
  };
interface Props {
    dataTestId: string,
    value: { id: string | number, option: string }[],
    selectedOptionForList?: { id: string | number, option: string,type:string }[],
    selectedGroupOptionForList?: { id: string | number, option: string,type:string }[],
    optionList: Array<IProductItems | { id: string | number, option: string }>,
    placeHolderText: string,
    debouncedFunction: Function,
    handleScroll?: Function,
    handleSelectOptions: Function,
    emptyDataMessage: string,
    isOnBlurFunction?: Function,
    id?: string,
    name?: string,
    style?: Object,
    isError?: boolean,
    customPaper?: boolean,
    customPopper?: boolean
    changeInputOnClear?: boolean
    listBoxHeight?:string;
    isRadioButtonShow?: boolean;
    isSelectAll?: boolean;
    optionStoreList: Array<IProductItems | { id: string | number, option: string,type:string }>,
    optionGroupList: Array<IProductItems | { id: string | number, option: string,type:string,storecount?:number }>,
    currentGroupId: string
    searchStoreValue: string;

}

const useStyles = makeStyles(() => ({
    newProductDropdown: {
        minHeight: 56,
        minWidth: 300,
        maxWidth: "100%"
    },
    menuItems: {
        display: 'flex',
        alignItems: 'center',
        gap:"7px",
        width: '100%',
        padding:"8px",
        paddingTop:"0"
    },
    mainComp:{
        maxHeight: 400,
        width: '250px',
    }
}));

const PageConatinerStoreGroupMultiSelect = ({
    dataTestId = 'searchableMultiselect',
    value,
    selectedOptionForList,
    selectedGroupOptionForList,
    optionList,
    placeHolderText = "",
    debouncedFunction,
    handleScroll,
    handleSelectOptions,
    emptyDataMessage = "No Data Found",
    isOnBlurFunction,
    id = "productAutoSelect",
    name = "productAutoSelect",
    style = {},
    isError,
    customPaper,
    customPopper,
    changeInputOnClear,
    listBoxHeight="400",
    isRadioButtonShow,
    isSelectAll,
    optionStoreList,
    optionGroupList,
    currentGroupId,
    searchStoreValue
}: Props) => {
    const classes = useStyles();

    const handleChangeInputOnClear = (event: unknown, value: unknown, reason: string) => {
        if (reason === "clear") {
            debouncedFunction("")
        }
    }
      
        let combinedOptions: Array<IProductItems | { id: string | number, option: string,type:string,isDivider?:boolean,storecount?:number }>
        if(optionStoreList.length > 0 || optionGroupList.length>0){
            combinedOptions   = [
                ...optionGroupList,
              ];
        }else{
            combinedOptions= []
        }

        let filterOptionStoreList: Array<IProductItems | { id: string | number, option: string,type:string }>
        if(searchStoreValue){
            filterOptionStoreList = optionStoreList.filter((store:  IProductItems | { id: string | number, option: string,type:string }|any ) =>
                store.option.toLowerCase().includes(searchStoreValue.toLowerCase())
            );
    
        }else{
            filterOptionStoreList = optionStoreList
        }
        
    return (
        <SimpleCustomAutocomplete
            disableClearable={false}
            clearOnEscape={false}
            clearOnBlur={false}
             data-test-id={dataTestId}
            label={'productAutoSelect'}
            id={id}
            name={name}
            multiple
            noOptionsText={emptyDataMessage}
            value={value}
            options={combinedOptions}
            disableCloseOnSelect
            onChange={currentGroupId ? handleChangeInputOnClear : undefined}
            getOptionLabel={(option: { id: string | number, option: string}) => option.option}
            renderOption={(option: { id: string, option: string,storecount:number }, props: Object) => {
              
                return (
                <div style={{display:"flex",flexDirection:"column"}}>
               {option.id === optionGroupList[0]?.id &&
               <>
                <Typography style={{fontSize:"16px",fontWeight:600,marginBottom:"20px",marginTop:"20px",color: "#204B9C"}}>Group Store</Typography>
                </>
                }
                <Box
                  component={"li"}
                  {...props}
                  id={`products-${option.id}`}
                  data-test-id={`products-${option.id}`}
                    onClick={() => {
                        handleSelectOptions(selectedGroupOptionForList, option)
                    }}
                >
                  {isRadioButtonShow ? (
                    <>
                      <Radio
                        checked={selectedGroupOptionForList?.map((item) => item.id).includes(option.id)}
                        icon={<span style={autoSeletStyle.radioIcon} />}
                        checkedIcon={<span style={autoSeletStyle.radioCheckedIcon} />}
                      />
                      <span style={selectedGroupOptionForList?.map((item) => item.id).includes(option.id) ?
                      {color: "#204B9C"}:{}} >{option.option} ({option.storecount} Stores)</span>  
                       {currentGroupId === option.id && <Divider style={{marginTop: "10px"}} />}
                      <Collapse in={currentGroupId === option.id} timeout="auto" unmountOnExit
                      >
                        <List style={{marginTop: "20px"}} component="div" disablePadding>
                        {filterOptionStoreList.map((store:any) => (
                            <ListItem onClick={(event) =>{
                                 event.stopPropagation()

                                 }} 
                                 key={store.id} id={`store-${store.id}`}>
                                <Box
                                    component={"li"}
                                    {...props}
                                    id={`productstore-${store.id}`}
                                    data-test-id={`productstore-${store.id}`}
                                    className={classes.menuItems}
                                    onClick={(event) => {
                                        event.stopPropagation();  
                                    handleSelectOptions(selectedOptionForList, store);
                                    }}
                                >
                                    <StyledCheckBox checked={selectedOptionForList?.map((item) => item.id).includes(store.id)} />&nbsp;<span>  {store?.option}</span>                                 
                                </Box>
                              
                            </ListItem>
                        ))}
                        </List>
                      </Collapse>
                    </>
                  ) : (
                    <Box
                      component={"li"}
                      {...props}
                      id={`products-${option.id}`}
                      data-test-id={`products-${option.id}`}
                      className={classes.menuItems}
                      onClick={() => {
                        handleSelectOptions(selectedOptionForList, option);
                      }}
                    >
                      <StyledCheckBox checked={selectedOptionForList?.map((item) => item.id).includes(option.id)} />&nbsp;<span>{option.option}</span>
                    </Box>
                  )}
                </Box>
                </div>
                )
            }}      
            renderInput={(params:JSX.IntrinsicAttributes & TextFieldProps) => {
                const sortedValues = value.sort((a:{id: string | number, option: string,type?:string}, b:{id: string | number, option: string,type?:string}) => {
                    if (a.type === "group" && b.type !== "group") return -1;
                    if (a.type !== "group" && b.type === "group") return 1;
                    return 0;
                });
                
                const displayValues = sortedValues.length > 2 
                    ? `${sortedValues.slice(0, 2).map(item => item.option).join(', ')}...`
                    : sortedValues.map(item => item.option).join(', ');
                return (
                    <TextFieldComp
                        {...params}
                        style={{ paddingTop: "0px" }}
                        data-test-id="autoCompleteInput"
                        fullWidth={true}
                        variant='outlined'
                        value={searchStoreValue}
                        className={`${isError && 'isError'}`}
                        onChange={(e) => { debouncedFunction(e.target.value) }}
                        InputProps={{
                            ...params.InputProps,
                            startAdornment: (
                                <span style={{color:"#4d4d4d",textOverflow: "ellipsis",
                                overflow: "hidden",
                                whiteSpace: "nowrap",fontWeight:500}}>
                                    {
                                    displayValues ? displayValues : <span style={{fontSize:"14px"}}>No Group Selected</span>}
                                    
                                </span>
                            )
                        }}
                    />
                );
            }}
            ListboxProps={{
                onScroll: handleScroll,
                style: {
                    maxHeight: listBoxHeight,
                },               
            }}
            PaperComponent={useCallback((props: PaperProps) => {
                return (<Paper
                            {...props}
                            className={classes.mainComp}
                        />)
            },[])}
            PopperComponent={useCallback((props: PopperProps) => {
                return (<Popper {...props}
                    placement="bottom"
                />)
            },[])}
        />
    );
}

const SimpleCustomAutocomplete: any = withStyles({
    root: {
        width: "230px",
        "@media only screen and (max-width: 1024px)": {
            width: "200px",
        },
        "& .MuiInputBase-root": {
            borderRadius: "8px",
            minHeight: "56px",
            flexWrap: "nowrap",
            "@media only screen and (max-width: 1024px)": {
                maxHeight: '44px',
                alignContent: 'center',
                fontSize: "12px",
                height: 'auto',
                padding: "9.5px 4px",
                minHeight: "40px",
                paddingLeft:"20px !important"
            },
        },
        "& .MuiChip-deleteIcon": {
            display: 'none'
        },
        "& .MuiChip-root": {
            color: "#204B9C",
            backgroundColor: "#204B9C1A",
        }
    },
    option: {
        backgroundColor: 'transparent',
        '&:hover': {
            backgroundColor: 'transparent',
        },
        '&.Mui-selected': {
            backgroundColor: 'transparent',
        },
        '&.Mui-focused': {
            backgroundColor: 'transparent',
        },
    },
})(Autocomplete);

const TextFieldComp = withStyles({
    root: {
        "&.isError .MuiOutlinedInput-notchedOutline": {
            borderColor: 'red'
        },
        "@media only screen and (max-width: 1024px)": {
            '& .MuiInputBase-input': {
                fontSize: '12px',
                padding: '6.5px 14px !important'
            },
        },
    },
})(TextField)

const LabelOptions = styled("p")({
    fontWight:500,
    fontSize:"16px",
    "@media only screen and (max-width: 1024px)": {
      fontSize:"14px",
    }
  })

const TitleTypography = styled(Typography)({
    fontWeight:600,
    fontSize:"16px",
    color: "#4D4D4D",
    marginTop:"20px",
})

const StyleListSubheader = styled(ListSubheader)({
    fontWeight:600,
    fontSize:"16px",
    color: "#4D4D4D",
    marginTop:"20px",
})
  
const GroupHeader = styled('div')(({ theme }) => ({
    position: 'sticky',
    top: '-8px',
    padding: '4px 10px',
    color: theme.palette.primary.main,
}));

const GroupItems = styled('ul')({
    padding: 0,
});

export default PageConatinerStoreGroupMultiSelect