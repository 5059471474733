import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { IUserContext } from "../../../blocks/navigationmenu/src/PageContainerController.web";
import {
  PermissionStatus,
  checkForNewPermissonStatus,
  customPermissionApiKey
} from "../../../blocks/utilities/src/CustomBlockHelpers";
import { PermissionGroupArray } from "../../../blocks/navigationmenu/src/utils";
export const configJSON = require("./config");

enum Method {
  GET = "GET",
  POST = "POST",
  PUT = "PUT",
  DELETE = "DELETE",
  PATCH = "PATCH",
}

export interface IPlantItem {
  id: string;
  type: string;
  attributes: {
    id: number | null;
    name: string;
    region: {
      id: number | null;
      branch_name: string;
      branch_id: string;
      address: string;
      company_id: number | null;
      email: string;
      telephone: string;
      others: {
        sdsdsc: string;
        dscdscdscs: string;
      };
      created_at: string;
      updated_at: string;
      branch_short_name: null;
    };
    total_capicity_in_kg: number | null;
    total_capicity_in_piece: number | null;
    plant_selections: {
      data: {
        id: string;
        type: string;
        attributes: {
          id: number | null;
          name: string;
          capicity_in_kg: number | null;
          capicity_in_piece: number | null;
        };
      }[];
    };
  };
}
// Customizable Area End

export interface Props {
  // Customizable Area Start
  classes?: any;
  navigation: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isLoading: boolean;
  isSubmitting: boolean;
  plantDetail: IPlantItem;
  permissionStatus: PermissionStatus;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class PlantViewController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  public RequestMessage = {
    GetPlantDetail: this.buildRequestMessagePlantForView(Method.GET),
    Null: undefined as any,
  };
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      isLoading: false,
      isSubmitting: false,
      plantDetail: {
        id: "",
        type: "",
        attributes: {
          id: null,
          name: "",
          region: {
            id: null,
            branch_name: "",
            branch_id: "",
            address: "",
            company_id: null,
            email: "",
            telephone: "",
            others: {
              sdsdsc: "",
              dscdscdscs: "",
            },
            created_at: "",
            updated_at: "",
            branch_short_name: null,
          },
          total_capicity_in_kg: null,
          total_capicity_in_piece: null,
          plant_selections: { data: [] },
        },
      },
      permissionStatus: {
        mainPermission: false,
        createPermission: false,
        viewPermission: false,
        editPermission: false,
        deactivatePermission: false
      },
      // Customizable Area End
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) !== message.id) {
      return;
    }

    const callID = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const response = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

    if (callID === this.RequestMessage.GetPlantDetail.messageId) {
      this.handleGetPlantDetailResponse(response);
    }
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    const plantId = window.localStorage.getItem("plant_id");
    this.getPlantDetailCallForView(plantId as string);
    // Customizable Area End
  }
  // Customizable Area Start
  private handleGetPlantDetailResponse(response: { data: IPlantItem }) {
    if (response) {
      const { data } = response;

      this.setState({
        plantDetail: data,
        isLoading: false,
      });
    }
  }

  handleCancelClick = () => {
    this.props.navigation.goBack();
  };

  handleEditPlantClick = () => {
    this.props.navigation.navigate("PlantEdit");
  };

  getPlantDetailCallForView = (plant_id: string) => {
    this.setState({ isLoading: true });

    this.RequestMessage.GetPlantDetail.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.plantDetailEndPoint}/${plant_id}`
    );

    runEngine.sendMessage(
      this.RequestMessage.GetPlantDetail.id,
      this.RequestMessage.GetPlantDetail
    );
  };

  private buildRequestMessagePlantForView(method: Method): Message {
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.plantEndPoint
    );
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMessage), configJSON.APIBaseURL);
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      this.getHeaderMessagePlantForView()
    );
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), method.toString());

    return requestMessage;
  }

  getHeaderMessagePlantForView() {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: window.localStorage.getItem(configJSON.token),
    };

    return JSON.stringify(header);
  }
  handleUserChange = (userContext: IUserContext) => {
    const apiKey = customPermissionApiKey.plantCreation;
    const userData = userContext.user?.attributes.permission_groups;
    const value = checkForNewPermissonStatus(apiKey, userData as Array<PermissionGroupArray>);
    this.setState({
      permissionStatus: value
    })
  };

  // Customizable Area End
}
