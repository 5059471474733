// Customizable Area Start
import moment from "moment";
import momentT from "moment-timezone";

export const formatTime = (time: Date | string) =>
  moment(time)
    .utc()
    .format("HH:mm")
    .toString()
    .toUpperCase();

export const saudiTimeView = (time: Date | string) => {
  const saudiTime = momentT.parseZone(time);
  const formattedTime = saudiTime.tz("Asia/Riyadh").format("h:mm A");
  return formattedTime;
};

export const saudiTime = (time: Date | string) => {
  const saudiTime = momentT.parseZone(time);
  const formattedTime = saudiTime.tz("Asia/Riyadh").format("HH:mm");
  return formattedTime;
};
// Customizable Area End
