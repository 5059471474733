import React from "react";
// Customizable Area Start
import {
  styled,
  Box,
  Button,
  TableContainer,
  TablePagination,
  Paper,
  Table,
  TableRow,
  TableCell,
  Popover,
  TableBody,
  TableHead,
  IconButton,
  withStyles,
  Tooltip,
  TextField,
  Slider,
} from "@material-ui/core";
import {
  FilterList as FilterIcon,
  MoreVert as MoreVertIcon,
} from "@material-ui/icons";

import HomeCleaningProductController, {
  Props,
  S,
  HomeCleanProductData,
  configJSON,
} from "./HomeCleaningProductController.web";
import SortingTableCell from "../../../components/src/SortingTableCell";
import SortingTableHeader from "../../../components/src/SortingTableHeader2";
import PageContainer from "../../navigationmenu/src/PageContainer.web";
import { colors } from "../../utilities/src/Colors";
import { IFilterItem } from "../../promocodes/src/PromoCodeListController.web";
import FilterPopover, { IFilter } from "../../../components/src/FilterPopover";
import ConfirmationModal from "../../../components/src/customComponents/ConfirmationModal.web";
import { CustomSnackbar } from "../../../components/src/customComponents/CustomSnackbar.web";
import { renderBaseOnConditions, sortCondition } from "../../../blocks/utilities/src/CustomBlockHelpers";

const Strings = configJSON.Strings.homeCleaningString;
// Customizable Area End

class HomeCleaningProduct extends HomeCleaningProductController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.state = {
      query: "",
      search_query: "",
      page: 0,
      per_page: 10,
      popverId: undefined,
      currentPopver: undefined,
      openAction: null,
      meta: {
        current_page: 1,
        next_page: 1,
        pervious_page: 1,
        total_count: 1,
        total_pages: 1,
      },
      sortingData: {
        product_name: "",
        product_type: "",
      },
      homeCleaningProductList: [],
      openDeactiveModel: false,
      deactivated: false,
      successSnackbarMessage: "",
      successSnackbarOpen: false,
      filterAnchor: undefined,
      selectedFilterRange: [0, 100],
      priceRange: "",
      filters: [
        {
          title: "Product Name",
          type: "autocompolete",
          value: "",
          options: [],
        },
        {
          title: "Product Type",
          type: "autocompolete",
          value: "",
          options: [],
        },
        {
          title: "Price",
          type: "custom",
          value: "",
          customRender: this.getPriceRangeFilter.bind(this),
          onClear: this.clearFilter.bind(this),
        },
      ],
      suggestionFieldName: "",
      permissionStatus: {
        mainPermission: false,
        createPermission: false,
        viewPermission: false,
        editPermission: false,
        deactivatePermission: false
      }
    } as S;
    // Customizable Area End
  }

  // Customizable Area Start
  getPopoverData() {
    const { openAction, currentPopver, permissionStatus } = this.state;
    const open = Boolean(this.state.openAction);
    const popOverId = open ? "simple-popover" : undefined;

    return (
      <ActionPopver
        data-test-id='popver'
        id={popOverId}
        open={open}
        anchorEl={openAction}
        onClose={this.handleClosePopver}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        {
          renderBaseOnConditions(
            (permissionStatus.viewPermission ||
            permissionStatus.editPermission ||
            permissionStatus.activatePermission ||
            permissionStatus.deactivatePermission),
            <div className='popoverContainer'>
              {
                renderBaseOnConditions(
                  this.state.permissionStatus.viewPermission,
                  <Box
                    onClick={() =>
                      this.handleNavigateViewHomeCleanProduct(
                        currentPopver as HomeCleanProductData
                      )
                    }
                    data-test-id='viewButton'
                    className='popoverButton'
                  >
                    {Strings.view}
                  </Box>,
                  <></>
                )
              }
              {
                renderBaseOnConditions(
                  this.state.permissionStatus.editPermission,
                  <Box
                    onClick={() =>
                      this.handleNavigateEditHomeCleanProduct(
                        currentPopver as HomeCleanProductData
                      )
                    }
                    data-test-id='editButton'
                    className='popoverButton'
                  >
                    {Strings.edit}
                  </Box>,
                  <></>
                )
              }
              {
                renderBaseOnConditions(
                  this.state.permissionStatus.deactivatePermission && currentPopver?.attributes.active as boolean,
                  <Box
                    onClick={() =>
                      this.handleDeactivateModalAction(
                        currentPopver as HomeCleanProductData
                      )
                    }
                    data-test-id='deactiveButton'
                    className='popoverButton'
                  >
                    {Strings.deactive}
                  </Box>,
                  <></>
                )
              }
              {
                renderBaseOnConditions(
                  this.state.permissionStatus.activatePermission as boolean && !currentPopver?.attributes.active,
                  <Box
                    onClick={() =>
                      this.handleDeactivateModalAction(
                        currentPopver as HomeCleanProductData
                      )
                    }
                    data-test-id='activeButton'
                    className='popoverButton'
                  >
                    {Strings.active}
                  </Box>,
                  <></>
                )
              }
            </div>,
            <div className="popoverContainer">
              <div className="noPermissions">
                No Permissions
              </div>
            </div>
          )
        }
      </ActionPopver>
    );
  }

  showProductType(item: HomeCleanProductData) {
    let stringArray: string[] = [];
    item.attributes.home_cleaning_catalogue_types.forEach((item) => {
      let string = `${item.data.attributes.name} (SAR ${item.data.attributes.price})`;
      stringArray.push(string);
    });
    if (stringArray.length > 3) {
      return (
        <ProductShowTip title={<span>{stringArray.join(", ")}</span>} arrow>
          <span>{stringArray.splice(0, 3).join(", ")}...</span>
        </ProductShowTip>
      );
    } else {
      return <span>{stringArray.join(", ")}</span>;
    }
  }

  clearFilter() {
    const updated = this.state.filters.map((item: IFilterItem) => {
      item.value = "";
      item.options = [];
      return item;
    });
    this.setState({ filters: updated, selectedFilterRange: [0, 100] });
  }

  getPriceRangeFilter() {
    const { filters } = this.state;
    const filter = filters.find((item) => item.title === "Price");
    const value = JSON.parse((filter?.value as string) || "{}");

    return (
      <PriceRangeWrapper>
        <Box className='pr-24 mb-24'>
          <Box className='w-190'>
            <span className='filterLabel'>{Strings.price}</span>
            <TextField
              variant='outlined'
              type={"text"}
              placeholder='Enter Amount'
              value={value.price}
              onChange={(event) => this.handleFiltePriceText(event, value)}
            />
          </Box>
        </Box>
        <Box className='pr-24'>
          <Box className='w-190'>
            <span className='filterLabel'>{Strings.selectPriceRange}</span>
            <span className='filterLabel m-24'>
              {this.state.selectedFilterRange[0]}-
              {this.state.selectedFilterRange[1]}
            </span>
            <StyledSlider
              data-test-id='rangeSlider'
              value={this.state.selectedFilterRange}
              valueLabelDisplay='auto'
              onChange={(
                _event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
                range: number[]
              ) => {
                this.setSelectedFilterRange(range, value);
              }}
              onClick={() => {
                this.setState({ priceRange: "range" });
              }}
              track='inverted'
            ></StyledSlider>
          </Box>
        </Box>
      </PriceRangeWrapper>
    );
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { sortingData } = this.state;
    const open = Boolean(this.state.openAction);
    const popOverId = open ? "simple-popover" : undefined;
    // Customizable Area End

    return (
      // Customizable Area Start
      <PageContainer
        onSearchText={(value) => this.handleSearchHomeCleaningProduct(value)}
        onUserChange={(userContext) => this.handleUserChange(userContext)}
        navigation={this.props.navigation}
      >
        <StyledWrapper>
          <Box className='headerWrapper'>
            <h2 className='PageHeading'>{Strings.homeCleaning}</h2>
            <Box className='d-flex'>
              <div
                data-test-id='filterBtn'
                className='filterButtonAccount'
                onClick={(event) =>
                  this.setState({
                    filterAnchor: event.currentTarget,
                  })
                }
              >
                <FilterIcon />
              </div>
              <FilterPopover
                onClose={() => this.setState({ filterAnchor: undefined })}
                anchor={this.state.filterAnchor as HTMLDivElement | undefined}
                onAutoCompleteChange={(title: string, value: string) => {
                  this.setState({ suggestionFieldName: title });
                  this.handleProductAutoSuggestion(title, value);
                }}
                onFilterChange={this.handleFilterChange}
                onClearFilter={() => this.handleClearAllProductFilter()}
                filters={this.state.filters as IFilter[]}
              />
              {
                sortCondition(
                  this.state.permissionStatus.createPermission,
                  <Button
                    onClick={() =>
                      this.props.navigation.navigate("CreateHomeCleaningProduct")
                    }
                    data-test-id='addProduct'
                    className='addButton'
                  >
                    {Strings.addProduct}
                  </Button>,
                  <></>
                )
              }
            </Box>
          </Box>
          <TableContainer component={Paper} className='tableContainer'>
            <Table className='table' aria-label='customized table'>
              <TableHead>
                <TableRow className='tableRow'>
                  <SortingTableHeader
                    sortingData={sortingData}
                    title={Strings.productName}
                    sortingKey='product_name'
                    {...this.sortingProps}
                    type='left'
                    width='30%'
                  />
                  <SortingTableHeader
                    sortingData={sortingData}
                    title={Strings.productTypeAndSqMeter}
                    sortingKey='product_type'
                    {...this.sortingProps}
                    width='50%'
                  />
                  <SortingTableHeader
                    sortingData={sortingData}
                    title={Strings.status}
                    sortingKey=''
                    sortingDisabled
                    {...this.sortingProps}
                    type='middle'
                    width='25%'
                  />
                  <SortingTableHeader
                    sortingData={sortingData}
                    title=''
                    type='action'
                    sortingKey=''
                    {...this.sortingProps}
                    width='5%'
                    sortingDisabled
                  />
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.homeCleaningProductList.map((item) => {
                  return (
                    <TableRow key={item.id}>
                      <SortingTableCell
                        width='30vw'
                        type='left'
                        content={item.attributes.product_name}
                      />
                      <SortingTableCell
                        width='50vw'
                        type='middle'
                        content={this.showProductType(item)}
                      />
                      <SortingTableCell
                        width='25vw'
                        type='right'
                        content={
                          item.attributes.active
                            ? Strings.active
                            : Strings.deactivated
                        }
                      />
                      <SortingTableCell
                        width='5vw'
                        type='action'
                        content={
                          <IconButton
                            data-test-id={`more-menu-${item.attributes.id}`}
                            aria-describedby={popOverId}
                            onClick={(event) => this.handlepopver(event, item)}
                          >
                            <MoreVertIcon />
                          </IconButton>
                        }
                      />
                    </TableRow>
                  );
                })}
                {this.state.homeCleaningProductList.length === 0 && (
                  <TableRow>
                    <TableCell colSpan={5}>
                      <div className='noRecordFound'>
                        {Strings.noRecordFound}
                      </div>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            onPageChange={(_event, page) => {
              this.handlePageChange(page);
            }}
            count={this.state.meta.total_count}
            component='div'
            data-test-id='pagination'
            page={this.state.page}
            rowsPerPage={this.state.per_page}
            rowsPerPageOptions={[10]}
            className='tablePagination'
          />
          {this.getPopoverData()}
        </StyledWrapper>
        <ConfirmationModal
          data-test-id='deactiveModel'
          open={this.state.openDeactiveModel}
          handleClose={this.handleCloseConfirmModal}
          handleConfirm={this.handleDeActivateProduct}
          title={Strings.conformDeactiveText}
          message={{
            id: this.state.currentPopver?.id,
            title: this.state.currentPopver?.attributes.product_name,
          }}
          confirmText='Deactivate'
          dynamic={true}
        />
        <ConfirmationModal
          data-test-id='confirmDeactiveModel'
          open={this.state.deactivated}
          handleClose={this.handleCloseConfirmModal}
          handleConfirm={this.handleBackToListing}
          title={Strings.productDeactivateMessage}
          message={{
            id: this.state.currentPopver?.id,
            title: this.state.currentPopver?.attributes.product_name,
          }}
          confirmText={Strings.confirmButtonText}
          displayItem={true}
        />
        <CustomSnackbar
          data-test-id='succesSnakBar'
          open={this.state.successSnackbarOpen}
          onClose={this.handleSnackbarClose}
          errorMessage={this.state.successSnackbarMessage}
          severity='success'
        />
      </PageContainer>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const StyledWrapper = styled("div")({
  "& .tableContainer": {
    flex: "1 1 0",
  },
  "& .headerWrapper": {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    marginBottom: 32,
    alignItems: "center",
  },
  "& .PageHeading": {
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "24px",
    lineHeight: "29px",
    "@media only screen and (max-width: 1024px)": {
      fontSize: "16px",
    }
  },
  "& .d-flex": {
    display: "flex",
  },
  "& .filterButtonAccount": {
    marginRight: "24px",
    cursor: "pointer",
    background: colors().white,
    height: "56px",
    color: colors().darkliver,
    width: "56px",
    fontSize: "24px",
    borderRadius: "8px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid rgb(32, 75, 156)",
    "@media only screen and (max-width: 1024px)": {
      height:44,
      width:44,
    }
  },
  "& .addButton": {
    textTransform: "unset",
    fontFamily: "Montserrat",
    background: colors().cyancobaltblue,
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: "24px",
    color: colors().white,
    height: "56px",
    width: "184px",
    borderRadius: "8px",
    "&:hover": {
      background: colors().cyancobaltblue,
      color: colors().white,
    },
    "@media only screen and (max-width: 1024px)": {
      height: 44,
    }
  },
  "& .table": {
    minWidth: 700,
  },
  "& .noRecordFound": {
    textAlign: "center",
    fontSize: "16px",
    fontWeight: 600,
  },
  "& .tableRow": {
    background: colors().cyancobaltblue,
    color: colors().white,
  },
});
const ActionPopver = styled(Popover)({
  "& .popoverContainer": {
    paddingTop: "16px",
    paddingBottom: "16px",
    boxShadow:
      "0px 4px 8px rgba(0, 0, 0, 0.03), 0px 8px 32px rgba(0, 0, 0, 0.06)",
    borderRadius: "8px",
    fontFamily: "Montserrat",
    fontWeight: 500,
    fontSize: "14px",
  },
  "& .noPermissions": {
    padding: "0 8px",
  },
  "& .popoverButton": {
    cursor: "pointer",
    width: "118px",
    height: "33px",
    paddingLeft: "24px",
    backgroundColor: colors().white,
    color: colors().darkliver,
    "&:hover": {
      backgroundColor: colors().cyancobaltblue,
      color: colors().white,
    },
    paddingTop: "8px",
  },
});

export const ProductShowTip = withStyles({
  tooltip: {
    color: colors().black,
    fontSize: "14px",
    backgroundColor: colors().white,
    width: "450px",
    maxWidth: "450px",
    boxShadow:
      "rgba(0, 0, 0, 0.3) 0px 8px 32px 0px, rgba(0, 0, 0, 0.03) 0px 4px 8px 0px",
  },
  arrow: {
    color: colors().white,
    boxShadow:
      "rgba(0, 0, 0, 0.3) 0px 8px 32px 0px, rgba(0, 0, 0, 0.03) 0px 4px 8px 0px",
  },
})(Tooltip);
const PriceRangeWrapper = styled("div")({
  "& .pr-24": {
    padding: "0 24px",
  },
  "& .w-190": {
    width: "190px",
  },
  "& .mb-24": {
    marginBottom: "24px",
  },
  "& .filterLabel": {
    display: "block",
    color: "rgb(0, 0, 0)",
    margin: "0 0 4px 0",
    fontWeight: 600,
    fontSize: 14,
    "&.m-24": {
      margin: "24px 0",
    },
  },
});
const StyledSlider: any = withStyles({
  root: {
    width: "calc(100% - 12px)",
    minwWidth: "220px",
    marginLeft: "6px",
  },
})(Slider);
export default HomeCleaningProduct;
// Customizable Area End
