import React from "react";
// Customizable Area Start
import { Button, Box, Grid } from "@material-ui/core";

import AddAreaController, { Props, configJSON } from "./AddAreaController.web";
import PageContainer from "../../../blocks/navigationmenu/src/PageContainer.web";
import { renderBaseOnConditions } from "../../../blocks/utilities/src/CustomBlockHelpers";

export default class AreaView extends AddAreaController {
  constructor(props: Props) {
    super(props);
  }

  renderAreaText = (inputTitle: string, value: string) => {
    return (
      <Grid item xs={12} md={6} >
        {inputTitle && (
          <>
            <div style={webStyle.textHeader}>
              {inputTitle.charAt(0).toUpperCase() + inputTitle.slice(1)}
            </div>
            <div style={webStyle.textContent}>{value || "--"}</div>
          </>
        )}
      </Grid>
    );
  };

  render() {
    return (
        <PageContainer
          navigation={this.props.navigation}
          onUserChange={(userContext) => this.handleUserChange(userContext)}
        >
          <div>
            <div style={{ ...webStyle.headerWrapper }}>
              <div style={webStyle.pageHeader}>{configJSON.addAreaHeader}</div>
              {renderBaseOnConditions(
                this.state.permissionStatus.editPermission,
                <Button
                  data-test-id='addCompany'
                  onClick={this.handleEdit}
                  style={{ ...webStyle.addButtonArea, textTransform: "unset" }}
                >
                  {configJSON.edit}
                </Button>,
                <></>
              )}
            </div>
            <Grid container style={webStyle.formContainerArea}>
              {this.renderAreaText(configJSON.areaName, this.state.areaInfo.attributes.area_name)}
              {this.renderAreaText(
                configJSON.region,
                this.state.areaInfo.attributes.region.data.attributes
                  ? this.state.areaInfo.attributes.region.data.attributes.branch_name
                  : "--"
              )}
            </Grid>
            <div style={webStyle.buttonContainer}>
              <Button
                data-test-id='backButton'
                variant='contained'
                onClick={this.handleAreaBack}
                size='medium'
                style={{ ...webStyle.backButton, textTransform: "none" }}
                color='secondary'
              >
                {configJSON.back}
              </Button>
          </div>
          </div>
        </PageContainer>
    );
  }
}

const webStyle = {
  formContainerArea: {
    marginTop: 32,
    padding: 32,
    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.03), 0px 8px 32px rgba(0, 0, 0, 0.06)",
    borderRadius: "12px",
  },

  rowContainerArea: {
    display: "flex",
    gap: "32px",
    marginBottom: "32px",
  },

  columnContainer: {
    width: "100%",
  },
  addButtonArea: {
    fontWeight: 600,
    fontSize: "16px",
    color: "#FFFFFF",
    background: "#204B9C",
    lineHeight: "24px",
    width: "184px",
    height: "56px",
    borderRadius: "8px",
  },

  backButton: {
    width: "128px",
    backgroundColor: "#204B9C",
    height: "56px",
  },
  buttonContainer: { 
    display: "flex",
    gap: "32px",
    padding: "32px 0"
  },
  pageHeader: {
    fontStyle: "normal",
    fontSize: "24px",
    fontWeight: 600,
    lineHeight: "29px",
  },
  headerWrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "32px 0",
  },
  textContent: {
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "22px",
    fontSize: "16px",
    marginTop: "12px",
    color: "#1A1A1A",
  },
  textHeader: {
    fontWeight: 600,
    fontStyle: "normal",
    lineHeight: "22px",
    fontSize: "18px",
    color: "#4D4D4D",
  },
};
// Customizable Area End
