/** @format */

import React from "react";
// Customizable Area Start
import { Box } from "@material-ui/core";
import ProductPreferenceController, {
  Props,
} from "./ProductPreferenceController.web";
import {
  createTheme,
  ThemeProvider,
} from "@material-ui/core/styles";
import PageContainer from "../../navigationmenu/src/PageContainer.web"

import Loader from "../../../components/src/Loader.web";
export const configJSON = require("./config");
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import CreateCustomerPreferenceWeb from './CreateCustomerPreference.web';
import CircularProgress from '@material-ui/core/CircularProgress';
// Customizable Area End

// Customizable Area Start
const theme = createTheme({
  typography: {
    fontFamily: "Montserrat",
  },
});

const webStyle = {
  mainWrapper: {
    marginTop: "32px",
    marginBottom: "32px",
    fontFamily: "Montserrat",
  }
};
// Customizable Area End

class ProductPreference extends ProductPreferenceController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start

  render() {
    return (
      <Box style={webStyle.mainWrapper}>
        <ThemeProvider theme={theme}>
          <Loader loading={false} />
          <PageContainer
            data-test-id='pageContainer'
            navigation={this.props.navigation}
            onUserChange={(userContext: any) => {}}
            afterPermissionComponent={
              <div style={{ minWidth: "200px" }}>
              </div>
            }
            onOpenedChanged={(open) => {this.handleDrawer(open)}}
          >
            {
              this.state.loading && 
              <Box display="flex" style={{width: "100%", justifyContent: "center", alignItems: "center"}}>
                <CircularProgress/>
              </Box>
            }
            {this.state.customerDataWithPreference && 
            <CreateCustomerPreferenceWeb
              loading={this.state.buttonLoading}
              data-test-id='editPreferenceModal'
              onCreateForCustomer={(data) => this.handleEditPreferenceForCustomer(data)}
              selectedService={{}}
              editScreen={true}
              preferenceData={this.state.customerDataWithPreference}
              handleClose={this.handleEditClose}
              renderOnPage={true}
              data={[]}
              headerTitle={"Create Item Preference"}
              saveForFuture={true}
              open={false}
              responseData={{
                id: "11",
                type: "preference",
                attributes: {
                  id: 11,
                  name: "Preference 1",
                  product_second_name: "Product 1",
                  specifications: [],
                  upcharges: [],
                  preferences: [],
                  services: [],
                },
              }}
              onCreate={(data) => { }}
              isDrawerOpen={this.state.isDrawerOpen}
            />
             }
          </PageContainer>
        </ThemeProvider>
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          open={this.state.snackbarOpen}
          autoHideDuration={6000}
          onClose={this.handleSnackbarClose}
        >
          <Alert severity='success'>
            Preference updated successfully!
          </Alert>
        </Snackbar>
      </Box>
    );
  }
  // Customizable Area End
}
export default ProductPreference;
