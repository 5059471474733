import React from "react";

// Customizable Area Start
import { Box, styled } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import PageContainer from "../../navigationmenu/src/PageContainer.web";
import CustomCashierTab from '../../../components/src/commonComponents/CustomCashierTab';
import Dashboard from "../../../blocks/dashboard/src/Dashboard.web";
// Customizable Area End

// Customizable Area Start
import CfcashierController, {
  Props,
  configJSON,
} from "./CfcashierController";

export const tabString = configJSON.Tabs.Strings
// Customizable Area End

export default class Cfcashier extends CfcashierController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { permissionStatus } = this.state;
    // Customizable Area End
    return (
      // Customizable Area Start
      <PageContainer
        onUserChange={(userContext) => this.handleUserChange(userContext)}
        onMultiRegionChange={() => true}
        onStoreChange={() => true}
        navigation={this.props.navigation}
      >
        <Box>
          <Box>
            <CustomCashierTab
              id="cashier"
              value={this.state.selectedTab}
              handleTabSeletion={this.handleTabSeletion}
              tabList={[tabString.overview,tabString.incomingTransaction, tabString.outgoingTransaction]}
            />
          </Box>
          {permissionStatus.viewPermission ?
            <Box>
              <Dashboard navigation={this.props.navigation} />
            </Box> :
            <PermissionMsgContainer>
              <Alert className="msgContainer" severity='warning'>{configJSON.permissionMessage}</Alert>
            </PermissionMsgContainer>
          }
          
        </Box>
      </PageContainer>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const PermissionMsgContainer = styled("div")({
  width:"100%",
  height:"100%",
  display:"grid",
  placeItems:"center",
  "& .msgContainer":{
    display:"flex",
    justifyContent:"center",
    width:"fit-content"
  }
});
// Customizable Area End
