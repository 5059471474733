import React from "react";
import {
  Box,
  // Customizable Area Start
  Paper,
  Divider,
  Grid,
  ButtonBase,
  Typography,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";
import { ICategory } from "./utils";

const theme = createTheme({
  palette: {
    primary: {
      main: "#222",
      contrastText: "#444",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

// Customizable Area End

import ItemGrouperController, { Props } from "./ItemGrouperController";

export default class ItemGrouper extends ItemGrouperController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Grid container spacing={3} style={webStyle.categoriesWrapper}>
              {this.state.categoriesData.map((category: ICategory) => (
                <Grid item xs={12} md={4}>
                  <Paper
                    elevation={
                      this.state.selectedCategoryId === category.id ? 3 : 0
                    }
                  >
                    <ButtonBase
                      onClick={() => {
                        this.doCategoryClicked(category.id);
                      }}
                      style={webStyle.categoryCardStyle}
                      data-test-id="category-card"
                    >
                      <Box
                        sx={{
                          p: "1rem",
                          width: "100%",
                          height: "100%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Typography
                          variant="h5"
                          display="block"
                          color="primary"
                        >
                          {category.attributes.name}
                        </Typography>
                      </Box>
                    </ButtonBase>
                  </Paper>
                </Grid>
              ))}
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={3} style={webStyle.categoriesWrapper}>
              {this.state.subCategoriesData.map((subCategory) => (
                <Grid item xs={12} sm={6} md={3} lg={2}>
                  <Paper elevation={3}>
                    <ButtonBase style={webStyle.categoryCardStyle}>
                      <Grid
                        container
                        style={webStyle.subCategoryContainerStyle}
                      >
                        <Grid container item xs={12} justifyContent="center">
                          <Box
                            sx={{
                              width: "6.5rem;",
                              height: "6.5rem;",
                              borderRadius: "50%",
                              overflow: "hidden",
                            }}
                          >
                            <img
                              src={subCategory.attributes.image}
                              alt={subCategory.attributes.name}
                              style={webStyle.subCategoryImageStyle}
                            />
                          </Box>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          style={webStyle.subCategoryNameWrapperStyle}
                        >
                          <Typography
                            variant="body2"
                            display="block"
                            color="primary"
                          >
                            {subCategory.attributes.name}
                          </Typography>
                        </Grid>
                      </Grid>
                    </ButtonBase>
                  </Paper>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  inputStyle: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
  },
  categoriesWrapper: {
    padding: "1rem",
  },
  subCategoryContainerStyle: {
    padding: ".6rem",
  },
  subCategoryImageStyle: {
    width: "100%",
    height: "100%",
  },
  subCategoryNameWrapperStyle: {
    padding: "1rem",
  },
  categoryCardStyle: {
    width: "100%",
    height: "100%",
  },
};

// Customizable Area End
