export interface AllImageIconType {
    id: string,
    type: string,
    attributes: {
      id: number,
      image: string,
      name: string
      second_name: string,
      image_type: string
    }
  }
  
  
  interface ImageProps extends React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement> {
    key?: string | number ;
}
  
  export interface GetIconType {
    id: string,
    item: React.ReactNode,
    title: string
    attributes: {
      image: string;
      name: string;

  };
  
  }
  
  export interface SectionType {
    id: string,
    type: string,
    attributes: {
      section_name: string,
      section_second_name: string,
      active: boolean,
      online: boolean,
      preference_id: number,
      order_number: number,
      sub_section_group_with: {
        data: { id: string, type: string, attributes: { section_id: number, sub_section_id: number, section_name: string } }[]
      }
    }
  }
  
  export interface PreFilledType {
    id?: string,
    name?: string,
    title?: string,
    service_name: string | undefined,
    service_secondayName: string,
    selected_icon: string,
    selectedIcon_name?: string,
    selected_sections: string[] | undefined | string,
    online_order: boolean | undefined,
    selected_section_name?: string[]
  }
  
  
  export interface ServiceListType {
    id: string,
    name: string,
    secondName: string,
    active: boolean,
    orderNumber:number,
    onlineOrder: boolean,
    sectionList: string[]
  }
  
  export interface RawServiceListType {
    id: string,
    name: string,
    attributes: {
      id: string,
      name: string,
      second_name: string,
      icon_id: number,
      active: boolean,
      online_order: boolean,
      order_number: number,
      icon: {
        id: string,
        name: string,
        created_at: string,
        updated_at: string,
        second_name: null | string,
        image_type: null | string
      },
      sections: {
        data: SectionType[]
      }
    }
  }
  
  
  export interface RawSectionListType {
    id: string,
    type: string
  
    attributes: {
      section_name: string,
      section_second_name: string,
  
      active: boolean,
      online: boolean,
      preference_id: number,
      order_number: number
      sub_sections_group_withs: {
        data: SectionType[]
      }
    }
  }
  
  
  
  export interface SectionListType {
    id: string,
    section_name: string,
  }


  export enum Random {
    Top =  'top',
    Center= 'center',
    Online=  'Online',
    Disabled = 'disabled'
   
}

export enum TimeDuration {
     duration = 4000
}
