Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "ProductDescription";
exports.labelBodyText = "ProductDescription Body";
exports.test = "test";
exports.noSpecificationText = "No specification!";
exports.serviceText = "Service";

exports.btnExampleTitle = "CLICK ME";
exports.token = "token";
exports.typeCataloguePreference = "catalogue_preference";
exports.productPreferenceEndPoint =
  "bx_block_profile_bio/customer_preferences/product_preferences?catalogue_variant_id=";
exports.productPreferenceAPIEndPoint =
  "bx_block_profile_bio/customer_preferences/product_preferences";
exports.specificationAPIEndPoint = "bx_block_categories/specifications";
exports.updatePreferenceAPIEndPoint =
  "bx_block_profile_bio/customer_preferences/update_preferences";
exports.getCustomerPreferenceListAPIEndPoint =
  "bx_block_profile_bio/customer_preferences?customer_id=";
exports.productApiEndPoint = "bx_block_catalogue/catalogues_variants";
exports.productListApiEndPoint = "bx_block_catalogue/catalogues";
exports.regionNameFilterSuggAPiEndpoint =
  "bx_block_order_management/home_cleaning_settings/cleaning_filter?region_name=";
exports.servicesApiEndPoint = "/bx_block_categories/services";
exports.getORders = "/bx_block_order_management/orders/";
exports.createText = "Create Preference";
exports.buttonOpenText = "Open preference";
exports.errorMessage = "Something went wrong";
exports.serviceText = "Service";
exports.damageText = "Damage";
exports.patternText = "Pattern";
exports.materialText = "Material";
exports.stainsText = "Stains";
exports.colorText = "Color";
exports.preferenceText = "Preference";
exports.upchargeText = "Preference";
exports.cancelBtnText = "Cancel";
exports.editBtnText = "Edit";
exports.upchargeText = "Upcharge";
exports.preferenceText = "Preference";
exports.productText = "Product";
exports.itemnameServiceText = "Item Name | Service";
exports.homeCleaningSettingsFormApi =
  "bx_block_order_management/home_cleaning_settings";
exports.homeCleaningSettingsListApi =
  "bx_block_order_management/home_cleaning_settings";
exports.testcoverage = "testcoverage";
exports.newtestcoverage = "newtestcoverage";

exports.regionListApi = "bx_block_regions/regions?dropdown=true";

exports.Strings = {
  SettingsList: {
    noRecordsFound: "No Records Found",
    title: "Home Cleaning Settings",
    addSetting: "Add Setting",
    view: "View",
    edit: "Edit",
    delete: "Delete",
    activate: "Activate",
    deactivate: "Deactivate",
    tableHeaders: {
      id: "ID",
      status: "Status",
      region: "Region",
      createdBy: "Created By",
      openSlots: "No. Of Open Slots",
      minOrderAmt: "Min. Order Amount",
      maxOrderAmt: "Max. Order Amount",
      serviceCharges: "Service Charges",
      minNonRefundableAmt: "Min Non Refundable Amt",
    },
  },
  Settings: {
    errors: {
      enter_number_only: "* Enter number only",
      amount_grater_zero: "* Amount must be grater then zero",

      select_region: "* Please select region",
      minimum_order_amount: "* Minimum order amount is required",
      maximum_order_amount: "* Maximum order amount is required",
      minimum_order_amount_grater:
        "* Amount must be grater then minimum order amount",
      service_charge_amount: "* Service charge amount is required",
      number_of_open_slots: "* Number of open slots is required",
      select_flat_rate_percentage: "* Please select flat rate or percentage",
      waive_of_service_charges: "* Waive of service charge is required",
      rate_must_be_grater: "* Rate must be grater then zero",
      rate_must_be_grater_or_equal: "* Rate must be grater than or equal to 0",
      rate_must_be_less_then_100: "* Rate should be less then or equal to 100",
      rate_should_be_between_zero: "* Rate should be between 0 to 100",
      enter_rate: "* Please enter rate",
    },
    save: "Save",
    back: "Back",
    edit: "Edit",
    region: "Region",
    cancel: "Cancel",
    successMessage: "Setting is $$ successfully!",
    close_slots: "Close slots",
    enter_amount: "Enter Amount",
    select_region: "Select Region",
    please_fill_fields: "Please fill fields",
    minimum_non_refundable_amount_to_pay:
      "Minimum non-refundable amount to pay",
    minimum_order_amount: "Minimum Order Amount (SAR)",
    maximum_order_amount: "Maximum Order Amount (SAR)",
    home_cleaning_settings: "Home Cleaning Settings",
    home_cleaning_settings_details: "Home Cleaning Settings Details",
    home_cleaning_service_charges: "Home cleaning Service charges",
    enter_maximum_price_for_order: "Enter Maximum Price For Order",
    enter_minimum_price_for_order: "Enter Minimum Price For Order",
    number_of_open_slots_for_day: "Number of Open slots for day",
    rate: "Rate (SAR)",
    flat_rate_percentage: "Flat Rate/Percentage",
    minimum_amount_to_waive_of_service_charge:
      "Minimum amount to waive of Service charge (SAR)",
    closeSets: "Close Slots",
    selectType: "Select Type",
    selectRegion: "Select Region",
  },
};
exports.newtestcoverage1 = "newtestcoverage1";
exports.crearePreference = "crearePreference";
exports.cols1024 = "150px 150px 150px 130px 0.8fr 10px"
exports.cols1280 = "180px 200px 200px 160px 1fr 50px"
exports.cols1366 = "200px 220px 220px 180px 1fr 50px"
exports.baseURLHome = "bx_block_order_management/home_cleaning_settings"
exports.enabledBtn = "Enabled"
exports.edited="edited"

exports.commonPreference="Common Preference"
exports.customerFor="Normal products"
exports.NewStrings = {
  editPreferencetxt: "Edit Preference",
  selectUpchargetxt: "Select Upcharge",
  txtItemNameService: "Item Name  |  Service",
  txtPreference: "Preferences",
  save: "Save",
  cancel: "Cancel",
  adjustmentPopup_customer_name:"Customer Name: ",
  adjustmentPopup_customer_mobile_no: "Customer Mobile No.: ",
  adjustmentPopup_business: "Business: ",
  adjustmentPopup_order_creation_date: "Order Creation Date: ",
  adjustmentPopup_order_id: "Order ID: ",
  adjustmentPopup_store_name: "Store Name: ",
  adjustmentPopup_price_list: "Price List: ",
  adjustmentPopup_discount: "Discount: ",
  adjustmentPopup_promocode_applied: "Promocode Applied: "
}
// Customizable Area End