export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const openCircle = require("../assets/skip_radio.png");
export const filledCircle = require("../assets/skip_radio1.png");
export const checkedIcon = require("../assets/checkedIcon.png");
export const unCheckedIcon = require("../assets/unCheckedIcon.png");
export const folded = require("../assets/image_folded.png");
export const hanged = require("../assets/image_hanged.png");
export const repairMachine = require("../assets/image_repairing.png");
export const starch = require("../assets/image_starch.png");
export const vipSuit = require("../assets/image_starch.png");
export const addIcon = require("../assets/add_icon.png");
export const backIcon = require("../assets/back_arrow.png");

export const RadioCheckedIcon = require("../assets/radio_checked.png");
export const RadioUnCheckedIcon = require("../assets/radio_unchecked.png");