Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "CustomisableUserProfiles";
exports.labelBodyText = "CustomisableUserProfiles Body";

exports.getApiMethod = "GET"
exports.postApiMethod = "POST"
exports.putApiMethod = "PUT"
exports.delete="delete"

exports.labelProfileHeading = "Profile"
exports.labelEditProfileHeading = "Edit Profile"
exports.btnEditProfileText = "Edit Profile"
exports.labelDetailMobile = "Mobile Number"
exports.labelDetailEmail = "Email Address"
exports.labelDetailAccountFor = "Account For"
exports.labelDetailEmployeeId = "Employee ID / Iqama ID"
exports.labelDetailDepartment = "Department"
exports.labelDetailPosition = "Position"
exports.labelDetailLocation = "Location"
exports.labelDetailBusinessArea = "Business Area"
exports.labelDetailRegionArea = "Business Area/Region"
exports.labelDetailDirectManager = "Direct Manager"
exports.labelDetailRole = "Role"
exports.labelDetailPermission = "Permission"
exports.labelDetailPassword = "Password"


exports.labelheading = "temp title"

exports.getProfileApiEndpoint = 'account_block/employees'
exports.updateProfileApiEndpoint = "account_block/employees/employee_profile_update"
exports.updateProfileAdminApiEndpoint = "bx_block_request_management/account_info_change_requests"

exports.fieldsData = [
  {
    id: 1,
    value: "mobile_number_change",
    title: "Mobile Number"
  },
  {
    id: 2,
    value: "email_address_change",
    title: "Email Address"
  },
  {
    id: 3,
    value: "account_for_change",
    title: "Account For"
  },
  {
    id: 4,
    value: "employee_id_or_iqama_id_change",
    title: "Employee ID / Iqama ID"
  },
  {
    id: 5,
    value: "department_change",
    title: "Department"
  },
  {
    id: 6,
    value: "position_change",
    title: "Position"
  },
  {
    id: 7,
    value: "location_change",
    title: "Location"
  },
  {
    id: 8,
    value: "business_area_change",
    title: "Business Area"
  },
  {
    id: 9,
    value: "direct_manager_change",
    title: "Direct Manager"
  },
  {
    id: 10,
    value: "role_change",
    title: "Role"
  },
  {
    id: 11,
    value: "permissions_change",
    title: "Permission"
  },
]

exports.textChangePhoto = "Change Photo"
exports.textAdmin = "admin"
exports.texFirstName = "First Name"
exports.textEnterFirstName = "Enter First Name"
exports.textLastName = "Last Name"
exports.textEnterLastName = "Enter Last Name"
exports.textPassword = "Password"
exports.textEnterPassword = "Enter Password"
exports.textConfirmPassword = "Confirm Password"
exports.textEnterConfirmPassword = "Enter Confirm Password"

exports.textCancel = "Cancel"
exports.textSave = "Save"
exports.textInformation = "Information"
exports.textAdd = "Add"

exports.labelAdminEdit = "Request Admin to change other information"
exports.labelAdminEditDialogTitle = "select what you need to be changed"

exports.subContractor = "Sub - Contractor";
exports.employee = "Employee";

exports.textSelectField ="Please select fields to update."
// Customizable Area End