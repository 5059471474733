import React, { useState } from "react";
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { Menu, MenuItem } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  dropdown: {
    '& .MuiMenuItem-root:hover': {
      backgroundColor: '#204B9C',
      color: '#ffff'
    },
  },
  noPermissions: {
    padding: "0 8px",
    fontSize: "13px"
  }
}));

const ThreeDotDropdown = ({ options, color: colorProp, getSelectedOption, item, disabled, menuItemStyle }: { options: string[], color?: string, getSelectedOption?: any, item?: any, disabled?: boolean, menuItemStyle?: React.CSSProperties }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event?.currentTarget);
  };

  const handleClose = (MenuSelected: any) => {
    setAnchorEl(null);
    getSelectedOption(MenuSelected, item);
  };

  const ITEM_HEIGHT = 48;

  return (
    <>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={anchorEl ? 'long-menu' : undefined}
        aria-expanded={anchorEl ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
        disabled={disabled}
        data-test-id={`menuIconBtn-${item?.id}`}
      >
        <MoreVertIcon style={colorProp ? { color: colorProp } : { color: 'white' }} />
      </IconButton>

      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => {handleClose(undefined)}}
        className={classes.dropdown} // Apply the dropdown class
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        PaperProps={{          
          style: {
            marginTop: '8px',
            marginLeft:"-2rem"
          },
        }}
      >
        {options.filter((option) => option).length === 0 ? (
          <p data-test-id="noPermissionEle" className={classes.noPermissions}>No Permissions</p>
        ) : (
          options
            .filter((option) => option)
            .map((option) => (
              <MenuItem
                key={option}
                selected={option === "Pyxis"}
                value={option}
                data-test-id={option}
                onClick={() => {
                  handleClose(option);
                }}
                style={menuItemStyle}
              >
                {option}
              </MenuItem>
            ))
        )}
      </Menu>
    </>
  );
}

export default ThreeDotDropdown;
