/** @format */

import React from "react";
// Customizable Area Start
import { withStyles, Theme } from "@material-ui/core/styles";
import {
  Dialog,
  Typography,
  Button,
  Avatar,
  ListItem,
  ListItemText,
  Box,
  TextareaAutosize,
} from "@material-ui/core";
import { Styles } from "@material-ui/styles";
import PreferenceItemController, {
  Props,
  Options,
} from "./PreferenceItemController.web";
import CustomRadioDropdown from "./CustomDropdownRadioSelect.web";
import {
  checkedIcon,
  unCheckedIcon,
  openCircle,
  filledCircle,
} from "../../../blocks/ProductDescription/src/assets";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";

export const configJSON = require("../config");
// Customizable Area End

// Customizable Area Start
const StyledMenuItemPreference = withStyles((theme) => ({
  root: {
    "&:focus": {
      backgroundColor: "#204B9C",
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

export const styles: Styles<Theme, {}, string> = () => ({
  mainDialog: {
    "& .MuiDialog-paper": {
      maxWidth: "unset !important",
      borderRadius: 24,
    },
  },
  headerTitle: {
    textAlign: "center",
    marginTop: "20px",
    fontSize: "1.6rem",
  },
  mainContainer: {
    backgroundColor: "white",
    borderRadius: 10,
    border: "1px solid #ccc",
    margin: 20,
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
    width: "90vw",
  },
  mainContainerInner: {
    backgroundColor: "#204b9c",
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
  },
  topPartContainer: {
    display: "flex",
    marginLeft: 20,
    marginTop: 32,
  },
  headerListSec: {
    display: "grid",
    gridTemplateColumns: "repeat(5,1fr)",
    padding: "20px 50px",
    gridGap: "40px",
    marginLeft: 10,
    ["& h6"]: {
      fontWeight: 600,
      fontSize: 18,
      color: "#FFFFFF",
    },
  },
  selectedProduct: {
    display: "flex",
    alignItems: "center",
    marginTop: 30,
    ["& p"]: {
      fontWeight: 600,
    },
  },
  dropDownSelection: {
    padding: "0px 30px",
    margin: 20,
    boxShadow: "0px 0px 5px 0px rgba(0, 0, 0, 0.2)",
    borderRadius: 12,
    position: "relative",
  },
  radioDropdown: {
    display: "grid",
    gridTemplateColumns: "repeat(5,1fr)",
    gridGap: 40,
    "@media (max-width: 1000px)": {
      gridTemplateColumns: "repeat(3,1fr)",
    },
  },
  upchargeSection: {
    display: "flex",
    margin: "30px 0",
  },
  radioSection: {
    display: "flex",
    margin: "30px 0",
    alignItems: "center",
    "@media (max-width: 1000px)": {
      alignItems: "flex-start",
      ["& p"]: {
        marginTop: 10,
      },
    },
  },
  header: {
    padding: "10px",
    color: "#1A1A1A",
    fontWeight: 600,
    fontSize: 14,
  },
  semiColon: {
    marginLeft: 20,
    color: "#1A1A1A",
    fontWeight: 600,
    fontSize: 14,
  },
  checkboxDetail: {
    display: "grid",
    gridTemplateColumns: "repeat(2,1fr)",
    gridGap: 10,
    "@media (max-width: 1000px)": {
      gridTemplateColumns: "repeat(1,1fr)",
    },
  },
  checkboxList: {
    cursor: "pointer",
    paddingTop: 0,
  },
  checkboxInner: {
    display: "flex",
    alignItems: "center",
    padding: "6px 20px",
    borderRadius: 12,
    marginLeft: 10,
    width: 300,
    textAlign: "right",
  },
  radioInner: {
    width: "unset",
  },
  checkboxInnerChecked: {
    background: "#F1F5F9",
    color: "#204B9C",
    border: "1px solid #ECECEC",
  },
  checkboxInnerUnChecked: {
    border: "1px solid #ECECEC",
    background: "#FFFFFF",
  },
  radioBox: {
    display: "flex",
    flexWrap: "wrap",
    cursor: "pointer",
    "@media (max-width: 1000px)": {
      flexDirection: "column",
    },
    "& .MuiListItem-root": {
      width: "auto",
    },
  },
  closeIcon: {
    position: "absolute",
    right: 0,
    top: 0,
    cursor: "pointer",
    color: "#4D4D4D",
  },
  textItem: {
    marginLeft: 15,
    textAlign: "left",
    width: 150,
  },
  iConSize: {
    width: 20,
    height: 20,
    borderRadius: "unset",
  },
  imageSize: {
    width: 24,
    height: 24,
    borderRadius: "unset",
  },
  chnageImageColor: {
    filter: "sepia(100%) saturate(600%) hue-rotate(195deg)",
  },
  checkboxListColor: {
    color: "#204B9C",
    cursor: "pointer",
  },
  addMore: {
    display: "flex",
    alignItems: "center",
    color: "#204b9c",
    cursor: "pointer",
    margin: 20,
    ["& button"]: {
      textTransform: "capitalize",
      fontWeight: 600,
    },
  },
  addMoreButton: {
    color: "#204b9c",
    ["&:hover"]: {
      color: "#204b9c",
      background: "#ffffff",
    },
  },
  groupButton: {
    margin: "30px auto",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: 20,
    ["& button"]: {
      textTransform: "capitalize",
      padding: "18px 35px",
      fontWeight: 600,
      fontSize: 16,
      borderRadius: 8,
      width: 185,
    },
  },
  cancelButton: {
    color: "#64748b",
    background: "#f1f5f9",
    marginRight: 20,
    ["&:hover"]: {
      backgroundColor: "#f1f5f9",
    },
  },
  addButton: {
    color: "#ffffff",
    background: "#204b9c",
    ["&:hover"]: {
      backgroundColor: "#204b9c",
    },
  },
  errorBox: {
    display: "flex",
  },
  errorColor: {
    color: "red",
  },
  inputFieldNote: {
    display: "flex",
    borderRadius: 8,
    border: "1px solid #ECECEC",
    background: "#f8f8f8",
    padding: 8,
    marginBottom: 15,
    width: "100%",
  },

  saveCheckbox: {
    display: "flex",
  },
  textgroup: {
    display: "flex",
    cursor: "pointer",
  },
  futureGroup: {
    flexBasis: "85%",
  },
  imageSizeSave: {
    width: 20,
    height: 20,
    borderRadius: "unset",
    marginRight: 15,
  },
  topTitle: {
    color: "#334155",
    fontSize: "16px",
    fontWeight: 600,
    marginBottom: 4,
  },
  topTextContainer: {
    padding: "10px 16px",
    borderRadius: "8px",
    background: "#E9E9E9",
    fontSize: 16,
    fontWeight: 500,
    height: 56,
    width: 364,
    display: "flex",
    alignItems: "center",
  },
  selectContainer: {
    height: "auto",
    marginTop: 16,
    "& .MuiSelect-outlined": {
      whiteSpace: "nowrap !important",
      wordBreak: "break-word !important"
    },
    "& .MuiTypography-displayBlock": {
      overflow: "hidden",
      textOverflow: "ellipsis"
    }
  },
});
// Customizable Area End

class PreferenceItem extends PreferenceItemController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start

  renderSelectedLabel = (value: string, item: any | undefined ) => {
    if (!value) {
      return <Typography style={{ color: 'grey', textOverflow: 'ellipsis', overflow: 'hidden'}}>{`Select ${item?.name}`}</Typography>;
    }
    const selectedOptionIndex = item?.options_attributes.findIndex((option:{id: string}) => option.id.toString() == value);
    const optionLabel = (selectedOptionIndex !== undefined && selectedOptionIndex !== -1) ? item?.options_attributes[selectedOptionIndex].label : '';
    return optionLabel
  }

  renderLastPreference = (index?: number) => {
    const { classes, preferenceData, selectedService } = this.props;
    const { selectedOptions } = this.state;

    if (index === undefined) index = 0;
    return (
      <>
        <div className={classes.topPartContainer}>
          <div style={{ marginRight: 32 }}>
            <div className={classes.topTitle}>Product</div>
            <div className={classes.topTextContainer}>
              {preferenceData?.attributes?.name}
            </div>
          </div>
          <div>
            <div className={classes.topTitle}>Service</div>
            <div className={classes.topTextContainer}>
              {selectedService?.name}
            </div>
          </div>
        </div>
        <Box>
          <Box className={classes.mainContainer}>
            <Box className={classes.mainContainerInner}>
              <Box className={classes.headerListSec}>
                {preferenceData?.attributes?.specifications?.map(
                  (item: any, index: number) => {
                    return (
                      <Typography key={index} variant='subtitle1'>
                        {item.name}
                      </Typography>
                    );
                  }
                )}
              </Box>
            </Box>
            <Box className={classes.dropDownSelection}>
              <Box className={classes.radioDropdown}>
                {preferenceData?.attributes?.specifications?.map((item: any) => (
                  <FormControl variant='outlined' style={{ width: "100%" }}>
                    <Select
                      className={classes.selectContainer}
                      data-test-id={`select-${item.name}`}
                      id={`select-${item.name}`}
                      renderValue={(value: any) => 
                        this.renderSelectedLabel(value, item)
                      }
                      displayEmpty
                      MenuProps={{
                        PaperProps: {
                          style: {
                            maxHeight: 300,
                          }
                        },
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left"
                        },
                        transformOrigin: {
                          vertical: "top",
                          horizontal: "left"
                        },
                        getContentAnchorEl: null
                      }}
                      value={
                        selectedOptions.find(
                          (element) => element.specification_id == item.id
                        )?.option_id || ""
                      }
                      onChange={(event) => {
                        const existingSelection = selectedOptions.find(
                          (element) => element.specification_id == item.id
                        );
                        if (existingSelection) {
                          existingSelection.option_id = event.target
                            .value as string;
                        } else {
                          selectedOptions.push({
                            specification_id: item.id,
                            option_id: event.target.value as string,
                          });
                        }
                        this.setState({
                          selectedOptions: [...selectedOptions],
                        });
                      }}
                    >
                      <StyledMenuItemPreference value="">
                        <ListItemText primary={`Select ${item.name}`} />
                      </StyledMenuItemPreference>
                      {item.options_attributes.map(
                        (option: { id: number; label: string }) => (
                          <StyledMenuItemPreference
                            key={option.id}
                            value={`${option.id}`}
                          >
                            <ListItemText primary={option.label} />
                          </StyledMenuItemPreference>
                        )
                      )}
                    </Select>
                  </FormControl>
                ))}
              </Box>

              <Box className={classes.upchargeSection}>
                <Typography className={classes.header} component='p'>
                  {configJSON.upchargeText}
                  <Typography className={classes.semiColon} component='span'>
                    :
                  </Typography>
                </Typography>
                <Box className={classes.checkboxDetail}>
                  {this.state.preferenceStateData?.attributes?.upcharges?.map(
                    (option: any, index: any) => (
                      <ListItem
                        className={classes.checkboxList}
                        key={option.id}
                        onClick={() => {
                          this.setState({
                            preferenceStateData: {
                              ...this.state.preferenceStateData,
                              attributes: {
                                ...this.state.preferenceStateData?.attributes,
                                upcharges: this.state.preferenceStateData?.attributes?.upcharges?.map(
                                  (item: any) => {
                                    if (item.id === option.id) {
                                      item.checked = !item.checked;
                                    }
                                    return item;
                                  }
                                ),
                              },
                            },
                          });
                        }}
                      >
                        <Avatar
                          className={classes.iConSize}
                          src={option.checked ? checkedIcon : unCheckedIcon}
                          alt={option.id}
                        />
                        <Box
                          className={`${classes.checkboxInner} ${
                            option.checked
                              ? classes.checkboxInnerChecked
                              : classes.checkboxInnerUnChecked
                          }`}
                        >
                          <Avatar
                            className={`${classes.imageSize} ${
                              option.checked ? classes.chnageImageColor : ""
                            }`}
                            src={option.icon}
                            alt={option.id}
                          />
                          <ListItemText primary={option.name} />
                          <ListItemText
                            style={{ minWidth: 85 }}
                            primary={`${option.currency} ${option.price}`}
                          />
                        </Box>
                      </ListItem>
                    )
                  )}
                </Box>
              </Box>

              <Box className={classes.radioSection}>
                <Typography className={classes.header} component='p'>
                  {configJSON.preferenceText}
                  <Typography className={classes.semiColon} component='span'>
                    :
                  </Typography>
                </Typography>
                <Box className={classes.radioBox}>
                  {this.state.preferenceStateData?.attributes?.preferences?.map(
                    (option: any) => (
                      <ListItem
                        key={option.id}
                        onClick={() =>
                          this.setState({
                            preferenceStateData: {
                              ...this.state.preferenceStateData,
                              attributes: {
                                ...this.state.preferenceStateData?.attributes,
                                preferences: this.state.preferenceStateData?.attributes?.preferences?.map(
                                  (item: any) => {
                                    if (item.id === option.id) {
                                      return {
                                        ...item,
                                        checked: true,
                                      };
                                    } else {
                                      return {
                                        ...item,
                                        checked: false,
                                      };
                                    }
                                  }
                                ),
                              },
                            },
                          })
                        }
                      >
                        <Avatar
                          className={classes.iConSize}
                          src={!option.checked ? filledCircle : openCircle}
                          alt={option.name}
                        />
                        <Box
                          className={`${classes.checkboxInner} ${
                            classes.radioInner
                          } ${
                            option.checked
                              ? classes.checkboxInnerChecked
                              : classes.checkboxInnerUnChecked
                          }`}
                        >
                          <Avatar
                            className={`${classes.imageSize} ${
                              option.checked ? classes.chnageImageColor : ""
                            }`}
                            src={option.icon}
                            alt={option.id}
                          />
                          <ListItemText
                            className={classes.textItem}
                            primary={option.name}
                          />
                        </Box>
                      </ListItem>
                    )
                  )}
                </Box>
              </Box>
              <Box className={classes.saveCheckbox}>
                <Box className={classes.futureGroup}>
                  <TextareaAutosize
                    className={classes.inputFieldNote}
                    placeholder='Add Note'
                    value={
                      this.state.preferenceStateData?.attributes?.notes || ""
                    }
                    onChange={(event) => {
                      this.setState({
                        preferenceStateData: {
                          ...this.state.preferenceStateData,
                          attributes: {
                            ...this.state.preferenceStateData?.attributes,
                            notes: event.target.value,
                          },
                        },
                      });
                    }}
                    rows={2}
                    rowsMax={6}
                  />
                  <Box
                    style={{ marginBottom: 16 }}
                    onClick={() =>
                      this.setState({
                        preferenceStateData: {
                          ...this.state.preferenceStateData,
                          attributes: {
                            ...this.state.preferenceStateData?.attributes,
                            save_for_future: !this.state.preferenceStateData
                              ?.attributes?.save_for_future,
                          },
                        },
                      })
                    }
                  >
                    <Typography className={classes.textgroup}>
                      <Avatar
                        className={classes.imageSizeSave}
                        src={
                          this.state.preferenceStateData?.attributes
                            ?.save_for_future
                            ? checkedIcon
                            : unCheckedIcon
                        }
                        alt='Checked'
                      />
                      Save for future
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </>
    );
  };
  render() {
    const { classes, open, editable, headerTitle, handleClose } = this.props;

    return (
      <Dialog open={open} className={classes.mainDialog}>
        <Typography className={classes.headerTitle} variant='h4'>
          {headerTitle}
        </Typography>

        {this.renderLastPreference()}

        <Box className={classes.groupButton}>
          <Button
            onClick={handleClose}
            disableRipple
            className={classes.cancelButton}
          >
            Cancel
          </Button>
          <Button
            data-test-id='btn-submit-preference'
            disableRipple
            onClick={this.handleCreateClick}
            className={classes.addButton}
          >
            {editable ? "Save" : "Create"}
          </Button>
        </Box>
      </Dialog>
    );
  }
  // Customizable Area End
}
export default withStyles(styles)(PreferenceItem);

