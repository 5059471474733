import React from "react";
import { Skeleton } from "@material-ui/lab";
import { TableCell } from "@material-ui/core";

export const TableRowsSkeleton = () => {
  return (
    <TableCell>
      <Skeleton animation='wave' style={{ margin: "5px 0" }} variant='text' />
    </TableCell>
  );
};
