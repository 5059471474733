Object.defineProperty(exports, "__esModule", {
    value: true
  });

//APi Methods
exports.httpGetMethod = "GET";
exports.httpPostMethod =  "POST";

exports.profileValidationSettingsAPiEndPoint = "profile/validations";
exports.passwordRecoveryStartOtpAPiEndPoint = "forgot_password/otp"
exports.passwordRecoveryConfirmOtpAPiEndPoint = "otp_confirmation"
exports.passwordRecoveryChangePasswordAPiEndPoint = "forgot_password/password"
exports.forgotPasswordAPiContentType = "application/json";
exports.pleaseEnterAValidEmail = "Please enter a valid email";
exports.emailIsRequired = "Email is required";
exports.phoneNumberIsNotValid = "Phone number is not valid";
exports.phoneNumberIsRequired = "Phone number is required";
exports.otpCodeIsRequired = "Phone number is required";
exports.pleaseEnterAPassword = "Please enter a password";
exports.passwordMustBeAtLeast2Characters = "Password must be at least 2 characters";
exports.pleaseConfirmYourPassword = "Please confirm your password";
exports.passwordsMustMatch = "Passwords must match";
exports.invalidEmailAddress = "Invalid email address";
exports.invalidPassword = "Invalid password";
exports.goToOtpAfterPhoneValidationErrorTitle = "Error";
exports.goToOtpAfterPhoneValidationErrorBody = "Please select country code";

exports.labelTextIsAccountRecovery = "Account Recovery";
exports.secondLabelText = "Please choose what type of account you signed up with."
exports.thirdLabelText = "To Reset your password, please enter the email associated with your account.";
exports.forthLabelText = "We sent a confirmation code to the following email:";
exports.fifthLabelText = "To Reset your password, please enter the phone number associated with your account."
exports.sixthLabelText = "We sent a confirmation code to the following phone:"

exports.firstInputAutoCompleteType = "email";
exports.firstInputPlaceholder = "Email";
exports.firstInputKeyboardStyle = "email-address";
exports.firstInputErrorColor = "red";

exports.buttonTextIsNext = "Next";
exports.buttonColorForNextButton = "#6200EE";

exports.secondInputAutoCompleteType = "tel";
exports.secondInputKeyboardType= "phone-pad"
exports.secondInputPlaceholder = "Mobile"
exports.secondInputErrorColor = "red";

exports.thirdInputPlaceholder = "Enter OTP";
exports.thirdInputErrorColor = "red";

exports.buttonTitleIsSMSPhoneAccount = "SMS (Phone) Account";
exports.buttonTitleIsEmailAccount = "Email Account";

exports.labelTextIsPleaseEnterYourNewPassword = "Please enter your new password.";
exports.labelTextIsYourPasswordHasBeenSuccessfullyChanged = "Your password has been successfully changed";

exports.handled = "handled";

exports.placeholderIsReTypePassword = "Re-Type Password";

exports.buttonTitleIsOk = "Ok"
exports.buttonColorForOkButton = "#6200EE"; 

exports.placeholderIsPassword = "password";
exports.countryCodeSelectorPlaceholder = "Select Country";
// Customizable Area Start
exports.heading="Forgot Password";
exports.Subheading="Provide the account email for which you want to reset your password.";
exports.labelheading="Email Address";
exports.labelor="Or";
exports.labelsubmit="Submit";
exports.emailheading="Check your mail";
exports.emailsubheading="Please check your email to verify and reset your password";
exports.otpheading="Enter OTP";
exports.otpsubheading="Enter the otp sent to your mobile number to reset the password";
exports.otpresend="Resend";
exports.labelverify="Verify"
exports.labelresetPassword="Reset Password"
exports.labelresetPasswordSub="Lorem Ipsum is simply dummy text of the printing and typesetting industry"
exports.labelchangePassword="Password"
exports.labelconfirmPassword="Confirm Password"
exports.labelresetLogin="Login"
exports.dynamicLogoCall="bx_block_settings/dynamic_contents"
exports.updateNewPasswordCall="bx_block_forgot_password/reset_password"
exports.verifyOtpApi="bx_block_forgot_password/verify_otp"
exports.validationApiContentType = "application/json"
exports.snackbarOtp="clickaway"
exports.updatePasswordTextId="updatePasswordTextId"
exports.exceedMessage="You have exceeded the limit for reset password. Please contact Admin"
exports.contactLabel="Contact"
exports.attemptLabel="You have {attemptRight} attempts left"
exports.newEmailAddress="Email Address cannot be blank"
exports.validEmailAddress="Email Address is not valid"
exports.newMobileNumber="Mobile Number cannot be blank"
exports.validMobileNumber="Mobile Number is not valid"
exports.validMobileNumberValue="Mobile Number Value is not valid"
// Customizable Area End