import React from "react";
// Customizable Area Start


import {
  Box,
  Button,
 
  Typography,
  Card,
  CardContent,
  Grid,
  Snackbar,
  styled
} from "@material-ui/core";


import { createTheme, ThemeProvider, withStyles } from "@material-ui/core/styles";

import CostumInput from "../../../components/src/customComponent/cutomInput.web"

import {  Random, TimeDuration } from "./SevicesTypes"

import AddServicesController, { Props, configData } from "./AddServicesController.web";
import PageContainer from "../../navigationmenu/src/PageContainer.web"

import DropdownMenu from "../../../components/src/DropdownMenu.web";
import CustomLoader from "../../../components/src/customComponents/CustomLoader.web";

import { Formik } from "formik";

import Alert from '@material-ui/lab/Alert';
import styles from "./AddServiceStyle"
import { styleOverride } from "./EditServicesStyle"
import { CheckBoxTrue, CheckBoxFalse } from "./assets"
import { CustomSearchableMultiSelect } from "../../../components/src/customComponent/CustomSearchableMultiSelect.web";
import AutocompleteSelect from "../../../components/src/AutocompleteSelect.web";


const theme = createTheme(styleOverride);



// Customizable Area End
class AddServices extends AddServicesController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  checkIsError(errors: string | undefined, touched: boolean | undefined) {
    if(errors && touched) {
      return true
    }
  }

  render() {
    return (
      //Merge Engine DefaultContainer
      <ThemeProvider theme={theme}>
        <PageContainer navigation={this.props.navigation}>
          <CustomLoader loading={this.state.addBtnLoading}/>
          <AddServicesWrapper className="mainContainer">
            <Box className="titleContainer">
              <Typography
                className="titleContainerText"
              >
                {configData.addService}
              </Typography>

            </Box>
            <div className="contentBox">
            <Formik
              initialValues={{
                ...this.state.initialValues
              }}
              enableReinitialize
              validateOnBlur={true}
              data-test-id="formikAddService"
              onSubmit={this.addService}
              validationSchema={this.FormSchema}
            >

              {({
                handleSubmit,
                setFieldValue,
                errors,
                values,
                handleChange,
                touched,
                resetForm,
                getFieldProps
              }) => {



                return (

                  <form onSubmit={handleSubmit}>
                    <Box className="flex">
                      <Card className="cardBox">
                        <CardContent >
                          <Grid container spacing={6} >
                            <Grid item xs={5} >
                              <Typography className="inputLabels">
                                {configData.serviceName}
                              </Typography>
                              <CostumInput placeholder={configData.enterName}
                                data-test-id="enterName"
                                inputError={touched.serviceName && errors.serviceName}
                                value={values?.serviceName}
                                onChange={handleChange} name="serviceName" 
                                
                                />
                                
                              {
                                touched.serviceName &&
                                errors.serviceName &&
                                <Typography className="error">{errors.serviceName}</Typography>
                              }
                            </Grid>

                            <Grid item xs={5}>

                              <Typography className="inputLabels" >
                                {configData.serviceSecondName}
                              </Typography>
                              <CostumInput
                                data-test-id="secondName"
                                name="serviceSecondaryName"
                                inputDir="rtl"
                                value={values?.serviceSecondaryName}
                                onChange={handleChange}
                                placeholder={configData.enterSecondName}
                                inputError={touched.serviceSecondaryName && errors.serviceSecondaryName}
                              />

                              {
                                touched.serviceSecondaryName &&
                                errors.serviceSecondaryName &&
                                <Typography className="error">{errors.serviceSecondaryName}</Typography>
                              }

                            </Grid>
                          </Grid>

                          <Grid container spacing={6} >
                          <Grid item xs={5}>

                          <Typography className="inputLabels" >
                            {configData.serviceShortName}
                          </Typography>
                          <CostumInput
                            data-test-id="shortName"
                            name="shortName"
                            value={values?.shortName}
                            onChange={handleChange}
                            placeholder={configData.enterShortName}
                            inputError={touched.shortName && errors.shortName}
                          />

                          {
                            touched.shortName &&
                            errors.shortName &&
                            <Typography className="error">{errors.shortName}</Typography>
                          }

                       </Grid>
                            <Grid item xs={5} >
                              <Typography className="inputLabels">{configData.section}</Typography>
                              <CustomSearchableMultiSelect
                                id="section"
                                name="section"
                                dataTestId="sectionDropdown"
                                checkSelectAllText={() => {}}
                                value={this.state.sectionSelectedData}
                                optionList={this.state.sectionLists}
                                placeHolderText={configData.placeHolderTextSection}
                                changeInputOnClear
                                handleScroll={this.handleScrollSectionDropdown}
                                handleClickOnSelectAll={() => {}}
                                isOnBlurFunction={() => this.debouncedFunction("", this.handleSectionAutoCompleteChange)}
                                debouncedFunction={(getValue: string) => { this.debouncedFunction(getValue, this.handleSectionAutoCompleteChange) }}
                                handleSelectOptions={(value: { id: string, option: string }[], option: { id: string, option: string }) => { this.handleSectionMultiSelect(value, option, setFieldValue) }}
                                emptyDataMessage={configData.emptySectionDataMessage}
                                handleEmptyAutoSelectValue={() => {}}
                                selectedOptionForList={this.state.selectedSectionsForList}
                                isError={Boolean(errors.selectedSections && touched.selectedSections)}
                            />
                            {touched.selectedSections && errors.selectedSections && (
                              <Typography className="error">{errors.selectedSections}</Typography>
                            )}
                            </Grid>
                            <Grid item xs={5} style={{paddingTop: "0"}}>

                              <Typography className="inputLabels">
                                Icon
                              </Typography>
                              <AutocompleteSelect
                                data-test-id='iconDropdown'
                                placeholder="Select icon"
                                fieldName="icon"
                                noOptionText="No icon found"
                                options={this.state.allIcons}
                                value={values.selectedIcon}
                                disableClear
                                listBoxStyle={{maxHeight: 260}}
                                onChange={(_event: React.ChangeEvent, value: { id: string; option: string; image:string }) => this.handleSelectIcon(value, setFieldValue)}
                                handleScroll={this.handleScrollIconDropdown}
                                onBlur={() => this.debouncedFunction(this.state.selectedIcon?.option || "", this.handleIconAutoCompleteChange)}
                                debouncedFunction={(getValue: string) => { this.debouncedFunction(getValue, this.handleIconAutoCompleteChange) }}
                                error={Boolean(touched.selectedIcon && errors.selectedIcon)}
                               />
                              {touched.selectedIcon && errors.selectedIcon && <Typography className="error">{configData.pleaseSelectOneIcon}</Typography>}
                            </Grid>
                          </Grid>
                          <Grid container spacing={6}>

                            <Grid item xs={3} className="onelineOrder" alignItems={configData.alignCenter} >

                              <Box className="checkBoxContainer">


                                <Box data-name={Random.Online} onClick={() => setFieldValue('onlineOrder', !values?.onlineOrder)}   data-test-id="checkbox" >
                                  {values?.onlineOrder ? 
                                  <img src={CheckBoxTrue} alt="checkboxFalse" /> : 
                                  <img src={CheckBoxFalse} alt="unchecked" />}
                                </Box>

                                
                                
                                <Typography className="onlineOrderText" 
                                
                                style={values?.onlineOrder ? { color: '#204B9C', fontWeight:500  } : {}}>
                                  
                                  
                                  {configData.onlineOrder}
                                  </Typography>
                              </Box>





                            </Grid>





                          </Grid>

                        </CardContent>



                      </Card>

                      
                      <Box>

                        <Button
                          variant={configData.outlined}
                          className={`cancelBtn button`}

                          onClick={() => this.handleRedirect()}
                          data-test-id='cancel'
                        >

                          {configData.cancel}

                        </Button>
                        <Button
                          className={`addBtn button`}
                          variant={configData.outlined}
                          data-test-id="add"
                          disabled={this.state.addBtnLoading}
                          type={configData.submit}

                        >
                            {configData.add}
                        </Button>
                      </Box>
                    </Box>
                  </form>
                )
              }}
            </Formik>

            <Snackbar anchorOrigin={{
              horizontal: Random.Center,
              vertical: Random.Top

            }}
              autoHideDuration={TimeDuration.duration}
              open={this.state.snackbar.open}
              onClose={this.handleCloseSnackbar}
              data-test-id=""
            >
              <Alert severity={this.state.snackbar.severity}>
                {this.state.snackbar.message}
              </Alert>
            </Snackbar>
            </div>
          </AddServicesWrapper>
        </PageContainer>


      </ThemeProvider >
      //Merge Engine End DefaultContainer
    )
  }
    // Customizable Area End
}
// Customizable Area Start

const webStyle = {






};

const AddServicesWrapper = styled("div")({
  "& .cancelBtn": {
    backgroundColor: '#F1F5F9',
    color: '#64748B',
    width: '128px',
    height: '56px',
    textTransform: 'none',
    border: "0",
    padding:"16px",
    "@media (max-width: 1024px)": {
      width: '128px',
      height: '44px',
      padding:"10px 0"
    },
    "@media (max-width: 768px)": {
      padding: "10px 0",
    },
  },
  "& .addBtn": {
    backgroundColor: "#204B9C",
    color: "#ffff",
    width: '128px',
    height: '56px',
    marginLeft: "32px",
    textTransform: "none",
    border: "0",
    "@media (max-width: 1024px)": {
      width: '128px',
      height: '44px',
     padding:"10px 0"
    },
    "&:hover": {
      backgroundColor: "#204B9C",
      color: "#ffff"
    },
    "@media (max-width: 768px)": {
      padding: "10px 0",
    },
  },
  "& .servicesBtns": {
    display: "flex",
    justifyContent: "center",
    borderRadius: "8px",
    padding: "10px",
    flexWrap: "wrap",
    boxSizing: 'border-box'
  },
  "& .flexContainer": {
    display: "flex",
    flexWrap: "wrap",
    gap: "10px"
  },
  "& .secondNames": {
    height: '60px',
    borderRadius: '8px',
    display: "flex",
    justifyContent: "center",
    paddingLeft: '8px',
    fontWeight:400,
    fontSize:'14px',
    '& ::-webkit-input-placeholder': { color: 'rgba(77, 77, 77, 1)', fontWeight:400 },
  },

  "& .enterNames": {
    height: "60px",
    color: "red",
    borderRadius: '8px',
    display: "flex",
    justifyContent: "center",
    paddingLeft: "8px",
    fontWeight:400,
    fontSize:'14px',
    '& ::-webkit-input-placeholder': { color: 'blue', fontWeight:400 },
    
  },
  "& .inputLabels": {
    fontWeight: 600,
    fontSize: "16px",
    color: "#334155",
    marginBottom:0,
   "@media (max-width: 1024px)": {
      fontSize: '13px',
    },
  },
  "& .button": {
    fontSize: "16px",
    fontWeight: 700,
    borderRadius: '8px'
  },
  "& .onlineOrderText": {
    color: "#334155",
    textWrap:"nowrap",
    minWidth:"200px",
    fontSize:"16px",
    fontWeight:500
  },
 "& .dropDown": {
    width: '460px',
    overflowY: 'auto',
    left:'-5px',
    color:"red"
  },
  "& .MuiAutocomplete-root": {
    maxWidth: "unset",
    minWidth: "unset",
    minHeight: "unset",
    "& .MuiInputBase-root": {
      maxHeight: "unset",
      minHeight: "56px",
      padding: '4px',
      "@media only screen and (max-width: 1024px)": {
        minHeight: "44px",
      },
    },
    "& .MuiOutlinedInput-root.Mui-focused fieldset": {
        borderColor: "rgb(236, 236, 236)"
    },
  },
  "& .menuItem": {
    '&:hover': {
      backgroundColor: 'transparent', 
    },
  },
  "& .cardBox": {
    minHeight: "320px",
    borderRadius: "12px",
    border: 0,
    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.03), 0px 8px 32px rgba(0, 0, 0, 0.06)"
  },
  "& .titleContainer": {
    display: "flex",
    borderColor: "1px solid red",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "10px",
    padding: 0,
  },
  "& .titleContainerText": {
    fontSize: '24px',
    fontWeight: 600,
    lineHeight: '29.26px',
    margin: "30px",
    "@media (max-width: 1024px)": {
      fontSize: '16px',
      fontWeight: 600,
      lineHeight:"19.2px",
    },
  },
  "& .mainContainer": {
    margin: '32px 0',
    width: '100%',
    padding:'10px 49px',

  },
  "& .GalleryTitle": {
      display: "flex",
      borderColor: "1px solid red",
      justifyContent: "space-between",
      alignItems: "center",
      margin: "10px 0 0 10px",
    },
    "& .galleryBtn": {
      backgroundColor: "#204B9C",
      color: "#FFFFFF",
      borderRadius: "8px",
    },
    "& .flex": {
      display: "flex",
      flexDirection: "column",
      gap: "2rem",
    },
    "& .fontSize_24": {
      fontSize: "24px",
    },
    "& .fw600": {
      fontWeight: 600,
    },
    "& .lineHeight_29": {
      lineHeight: "29.26px",
    },
    "& .padding_46": {
      padding: "46px 0",
    },
    "& .select": {
      border: '1px solid #ECECEC',
      '&:focus': {
        border: '1px solid #ECECEC',
        borderBottom: '0',
      },
    },
    "& .underline": {
      borderBottom: '0',
    },
    "& .onelineOrder":{
        display: 'flex'
    },
    "& .customCheckbox": {
      display: 'flex',
      alignItems: 'center',
      padding: '4px 8px',
      gap: '7px',
      backgroundColor: 'rgba(32, 75, 156, 0.1)',
      borderRadius: '16px',
      color: "#204B9C",
     
    },
    "& .error":{
      color: "rgb(244, 67, 54)"
    },
    "& .marginTopZero": {
      marginTop: 0
    },
    "& .checkBoxContainer":{
      display: 'flex',
      gap: '10px'

    },
    "& .contentBox": {
      marginLeft:"32px"
    }
})

export default withStyles(styles)(AddServices);
// Customizable Area End










































