import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { IUserContext } from "../../../blocks/navigationmenu/src/PageContainerController.web";
import {
  PermissionStatus,
  checkForImportExportPermissionStatus,
  checkForNewPermissonStatus,
  customPermissionApiKey
} from "../../../blocks/utilities/src/CustomBlockHelpers";
import { PermissionGroupArray } from "../../../blocks/navigationmenu/src/utils";
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { apiCall, handleLogout, makeApiMessage } from "../../../components/src/common";
import { ISortingData } from "../../../components/src/SortingTableHeader";
import { IFilter } from "../../../components/src/FilterPopover";
import { CustomEnums, getCustomEnumName, navigateTo, randomNumberGenerator } from "../../utilities/src/CustomBlockHelpers";
import ImportExportWebAdapter from "../../adapters/src/ImportExportWebAdapter";
import { IImportCSVResponse } from "../../importexportdata/src/ImportExportData.web";

export interface IMetadata {
  total_pages: number;
  total_count: number;
  current_page: number;
  next_page: number | null;
  previous_page: number | null;
}

interface IImportExcelResponse {
  status?: number;
  message: string;
  error?: string;
  errors: string;
  url:string
}

export interface ICustomerProfile {
  street_address: string | null;
  city: {
    id: number;
    name_en: string;
    name_ar: string;
  } | null;
  employee_id: string;
  customer_id: string;
  post_code: string | null;
  business_id: {
    id: number,
    label: string,
    module_name: string
  };
  price_list: IPriceList | null;
  notes: string | null;
  gender_id: {id: string; label:string}
  private_note: string | null;
  customer_type: string | null;
  profession: IProfession;
  organisation_tax: IOrganisationTax | null;
  payment: {
    id: string | null;
    name: string | null;
  };
  organization: IOrganization;
}

export interface ICustomerData {
  id: string;
  type: string;
  attributes: ICustomerAttributes;
}

export interface ICustomerAttributes {
  full_name: string;
  activated: boolean;
  email: string;
  date_of_birth: string;
  phone_number: number;
  country_code: number;
  customer_profile: {
    data: {
      id: string;
      type: string;
      attributes: ICustomerProfile
    }
  };
  customer_preferences_attributes: CatalogueVariantsServices[];
  order: any;
  saved_notes: string | null;
  user_addresses: {
    data: {
      id: string;
      type: string;
      attributes: {
        id: number;
        location: string;
        address: string;
        landmark: string | null;
        post_code: string | null;
        latitude: number | null;
        longitude: number | null;
        notes: string | null;
        label: string | null;
        default: boolean;
        city: {
          id: number;
          name_en: string;
          name_ar: string;
          name: string;
        };
      };
    }[];
  }
}

interface ServiceAttributes {
  id: number;
  price: string;
  catalogue_variant_id: number;
  default: boolean;
  currency: string;
  service: {
    data: {
      id: string;
      type: string;
      attributes: {
        id: number;
        name: string;
        second_name: string;
        short_name: string;
        icon_id: number;
        active: boolean;
        online_order: boolean;
        order_number: number;
        icon: {
          data: {
            id: string;
            type: string;
            attributes: {
              id: number;
              image: string;
              name: string;
              second_name: string;
              image_type: string;
              name_translation: string;
            };
          };
        };
        sections: {
          data: {
            id: string;
            type: string;
            attributes: {
              id: number;
              section_name: string;
              section_second_name: string;
              active: boolean;
              online: boolean;
              order_number: number;
              created_at: string;
              updated_at: string;
              model_name: string;
              icon: {
                data: {
                  id: string;
                  type: string;
                  attributes: {
                    id: number;
                    image: string;
                    name: string;
                    second_name: string;
                    image_type: string;
                    name_translation: string;
                  };
                };
              };
              preference: {
                data: {
                  id: string;
                  type: string;
                  attributes: {
                    id: number;
                    preference_first_name: string;
                    icon_id: number;
                    gallery_icon: {
                      id: number;
                      image: string;
                      name_translation: string;
                    };
                    name: string;
                  };
                };
              };
              name: string;
            };
          }[];
        };
        name_translation: string;
      };
    };
  };
}

interface CatalogueService {
  id: string;
  type: string;
  attributes: ServiceAttributes;
}

interface CatalogueVariantsServices {
  data: CatalogueService[];
}

interface IProduct {
  name: string;
  id: number;
  image: string | null;
}

interface IPriceListAttributes {
  id: number;
  name: string;
  price_list_type: string;
  product_currency_id: string;
  created_at: string;
  updated_at: string;
  tax_type: string;
  activated: boolean;
  price_list_to_copy: any;
  model_name: string;
  add_by: string;
  is_master_price_list: boolean;
  sections: {
    data: any[];
  };
  products: IProduct[];
}

interface IPriceListData {
  id: string;
  type: string;
  attributes: IPriceListAttributes;
}

interface IPriceList {
  data: IPriceListData;
}

export interface IProfessionData {
  id: string;
  type: string;
  attributes: {
    id: number;
    profession_unique_id: string;
    profession_name: string;
    active: boolean;
  };
}

export interface IProfession {
  data: IProfessionData
}

interface IOrganisationTax {
  data: {
    id: string;
    type: string;
    attributes: {
      id: number;
      name: string;
      tax_number: string | null;
      active: boolean;
    };
  };
}

export interface IOrganization {
  data: IOrganizationData;
}

export interface IOrganizationData {
  id: string;
  type: string;
  attributes: {
    id: number;
    name: string;
    tax_number: string | null;
    active: boolean;
  };
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes?: any;
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  popOverOpened: boolean;
  permissionStatus: PermissionStatus;
  popOverItemId: string;
  popOverItemStatus: string | boolean;
  popOverTop: number;
  popOverLeft: number;
  page: number;
  pageSize: number;
  filterAnchor: HTMLDivElement | undefined;
  filters: IFilter[];
  query: string;
  sortingData: ISortingData;
  isLoading: boolean;
  customerListPagination: IMetadata;
  customerList: ICustomerData[];
  customerQuery: string;
  rowData: ICustomerData;
  confirmDeactivationModalOpen: boolean;
  customerDeactivateModalOpen: boolean;
  currentFilter: string;
  openCustomerListAction: EventTarget & HTMLButtonElement | null;
  uploadedFile: File | null;
  setLoaded: number;
  errorSnackbarOpen: boolean;
  snakcbarSeverity: "error" | "warning" | "info" | "success";
  errorMessage: string;
  isDeactivationStarted: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class PeopleManagement2Controller extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getCustomerListApiCallId: string = "";
  activeDeactiveCustomerApiCallId: string = "";
  customerSuggestionApiCallId: string = "";
  exportFileApiId: string = "";
  exportTemplateFileApiId: string = "";
  importFileApiId: string = "";
  adapter: ImportExportWebAdapter;
  authToken: string = "";

  async componentDidMount() {
    super.componentDidMount();
    const authToken = localStorage.getItem("token");
    if (typeof (authToken) === "string") {
      this.authToken = authToken;
    }

    this.getCustomerList();
  }
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),

      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getCustomEnumName(CustomEnums.ImportExportPopupDoneMessage),
      getCustomEnumName(CustomEnums.ImportExportPopupFileUploadMessage),
      getCustomEnumName(CustomEnums.ImportExportClearFileMessage),
      getCustomEnumName(CustomEnums.ImportExportPopupCloseButtonClicked),
      getCustomEnumName(CustomEnums.ImportExportPopupClose),
      getCustomEnumName(CustomEnums.ImportExportErrorPopupGoBack),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      popOverOpened: false,
      permissionStatus: {
        mainPermission: false,
        createPermission: false,
        viewPermission: false,
        editPermission: false,
        deactivatePermission: false,
        activatePermission: false,
        exportPermission: false
      },
      popOverLeft: 0,
      popOverTop: 0,
      page: 0,
      pageSize: 100,
      popOverItemId: "",
      popOverItemStatus: "",
      filterAnchor: undefined,
      filters: [
        {
          title: "Name",
          type: "autocompolete",
          options: [],
          value: "",
        },
        {
          title: "Organization",
          type: "autocompolete",
          options: [],
          value: "",
        },
        {
          title: "City",
          type: "autocompolete",
          value: "",
          options: [],
        },
        {
          title: "Profession",
          type: "autocompolete",
          options: [],
          value: "",
        },
        {
          title: "Business Category",
          type: "autocompolete",
          options: [],
          value: "",
        },
        {
          title: "Customer Type",
          type: "autocompolete",
          value: "",
          options: [],
        },
      ],
      sortingData: {
        name: "",
        organisation_name: "",
        city: "",
        profession: "",
        business: "",
        customer_type: "",
        phone_number:"",
        gender:""
      },
      query: "",
      isLoading: false,
      customerListPagination: {
        total_pages: 0,
        total_count: 0,
        current_page: 0,
        next_page: null,
        previous_page: null
      },
      customerList: [],
      customerQuery: "",
      rowData: {
        id: "",
        type: "",
        attributes: {
          full_name: "",
          activated: false,
          email: "",
          date_of_birth: "",
          phone_number: 0,
          country_code: 0,
          customer_profile: {
            data: {
              id: "",
              type: "",
              attributes: {
                street_address: null,
                city: {
                  id: 0,
                  name_en: '',
                  name_ar: ''
                },
                employee_id: "",
                customer_id: "",
                post_code: null,
                business_id: {
                  id: 0,
                  label: "",
                  module_name: ""
                },
                price_list: null,
                notes: null,
                gender_id: {id:"", label:""},
                private_note: null,
                customer_type: "",
                profession: {
                  data: {
                    id: "",
                    type: "",
                    attributes: {
                      id: 0,
                      profession_unique_id: "",
                      profession_name: "",
                      active: false
                    }
                  }
                },
                organisation_tax: null,
                payment: {
                  id: null,
                  name: null
                },
                organization: {
                  data: {
                    id: "",
                    type: "",
                    attributes: {
                      id: 0,
                      name: "",
                      tax_number: "",
                      active: false
                    }
                  }
                }
              }
            }
          },
          user_addresses: {
            data: []
          },
          customer_preferences_attributes: [],
          order: undefined,
          saved_notes: null
        }
      },
      confirmDeactivationModalOpen: false,
      customerDeactivateModalOpen: false,
      currentFilter: '',
      openCustomerListAction: null,
      uploadedFile: null,
      setLoaded: 0,
      errorSnackbarOpen: false,
      snakcbarSeverity: 'error',
      errorMessage: '',
      isDeactivationStarted: false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.adapter = new ImportExportWebAdapter();
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    if (from === getCustomEnumName(CustomEnums.ImportExportPopupCloseButtonClicked)) {
      this.handleCustomerListImportExportModalClose();
    };

    if (from === getCustomEnumName(CustomEnums.ImportExportPopupDoneMessage)) {
      this.handleCustomerListImportFile();
    };
    
    if (from === getCustomEnumName(CustomEnums.ImportExportPopupFileUploadMessage)) {
      this.handleCustomerListFileUpload(message.properties.fileEvent);
    };

    if(getCustomEnumName(CustomEnums.ImportExportErrorPopupGoBack) === message.id) {
      this.handleBackToListPage();
    }

    if (from === getCustomEnumName(CustomEnums.ImportExportClearFileMessage)) {
      this.setState({ 
        uploadedFile: message.properties.uploadedFile,
        setLoaded: message.properties.setLoaded 
      });
    };

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

      this.getCustomerListApiCallResponse(apiRequestCallId, responseJson);
      this.activeDeactiveCustomerApiCallResponse(apiRequestCallId, responseJson);
      this.customerSuggestionApiCallResponse(apiRequestCallId, responseJson);
      this.exportCustomerListCSVApiCallResponse(apiRequestCallId, responseJson);
      this.exportTemplateCustomerListCSVApiCallResponse(apiRequestCallId, responseJson);
      this.importCustomerListCSVApiCallResponse(apiRequestCallId, responseJson);
    }
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start
  checkGetResponse(responseJson: { errors?: { message: string } }) {
    if (responseJson && !responseJson.errors) {
      return true
    }
    else {
      handleLogout(this.props.navigation, responseJson && responseJson.errors);
      return false
    }
  }

  handleBackToListPage = () => {
    navigateTo({ props: this.props, screenName: "PeopleManagement2" })
  }

  getCustomerListApiCallResponse(apiRequestCallId: string, responseJson: { data: ICustomerData[], meta: IMetadata, message?: string, errors?: { message: string } }) {
    if (apiRequestCallId === this.getCustomerListApiCallId) {
      if (this.checkGetResponse(responseJson) && !responseJson.message) {
        this.setState({ isLoading: false, customerList: responseJson.data, customerListPagination: responseJson.meta });
      } else {
        this.setState({ isLoading: false })
      }
    }
  }

  customerSuggestionApiCallResponse(apiRequestCallId: string, responseJson: {filter_names: string[], errors?: { message: string }}) {
    if (apiRequestCallId === this.customerSuggestionApiCallId) {
      if (this.checkGetResponse(responseJson)) {
        const list = responseJson?.filter_names?.map((value: string) => ({
          label: value,
          value,
        })) || [];
        const updatedFilters = this.state.filters.map((item: IFilter) => {
          if (item.title === this.state.currentFilter) item.options = list;
          return item;
        });
        this.setState({ filters: updatedFilters });
      }
    }
  }
  
  exportCustomerListCSVApiCallResponse(apiRequestCallId: string, responseJson: { message:string, file_url: string }) {
    if (apiRequestCallId === this.exportFileApiId) {
      if (responseJson) {
        location.href = responseJson.file_url
        this.setState({ snakcbarSeverity: 'success', errorSnackbarOpen: true, errorMessage: responseJson.message })
      }
    }
  }
  
  exportTemplateCustomerListCSVApiCallResponse(apiRequestCallId: string, responseJson: {message:string, url: string }) {
    if (apiRequestCallId === this.exportTemplateFileApiId) {
      if (responseJson) {
        location.href = responseJson.url
        this.setState({
          snakcbarSeverity: 'success',
          errorMessage: responseJson.message,
          errorSnackbarOpen: true
        })
      }
    }
  }
  
  importCustomerListCSVApiCallResponse(apiRequestCallId: string, responseJson: IImportExcelResponse) {
    if (apiRequestCallId === this.importFileApiId) {
      if (responseJson) {
        if (responseJson?.message) {
          this.setState({ snakcbarSeverity: 'success', errorSnackbarOpen: true, errorMessage: responseJson.message })
          navigateTo({ props: this.props, screenName: "PeopleManagement2" })
          this.getCustomerList();
        } else if (responseJson?.errors) {
          const message = new Message(getCustomEnumName(CustomEnums.ImportExportErrorPopupData))
          message.addData('APIresponse', responseJson);
          runEngine.sendMessage(message.id, message);
          navigateTo({
            props: this.props,
            screenName: "ImportExportErrorModal",
            raiseMessage: message
          })
        }
      }
    }
  }

  activeDeactiveCustomerApiCallResponse(apiRequestCallId: string, responseJson: {responseJson: ICustomerData, errors?: { message: string }}) {
    if (apiRequestCallId === this.activeDeactiveCustomerApiCallId) {
      if (this.checkGetResponse(responseJson)) {
        if(this.state.popOverItemStatus) {
          this.setState({
            isLoading: false,
            isDeactivationStarted: false,
            confirmDeactivationModalOpen: false,
            customerDeactivateModalOpen: true
          });
        }else {
          this.setState({isLoading: false}, () => {this.handleGoList()});
        }
      } else {
        this.setState({ isLoading: false })
      }
    }
  }

  getCustomerList = () => {
    this.setState({ isLoading: true });

    let headers = {
      "Content-type": "application/json", 
      token: this.authToken
    }

    let url;
    let nameFilter = this.state.filters.find((item:IFilter) => item.title === "Name")?.value;
    let organizationFilter = this.state.filters.find((item:IFilter) => item.title === "Organization")?.value;
    let cityFilter = this.state.filters.find((item:IFilter) => item.title === "City")?.value;
    let professionFilter = this.state.filters.find((item:IFilter) => item.title === "Profession")?.value;
    let customerTypeFilter = this.state.filters.find((item:IFilter) => item.title === "Customer Type")?.value;
    let businessCategoryFilter = this.state.filters.find((item:IFilter) => item.title === "Business Category")?.value;

    url = configJSON.addNewCustomerApiEndPoint +
      (`?page_no=${this.state.page + 1}&per_page=${this.state.pageSize}`) +
      (nameFilter ? `&filter_by[name]=${nameFilter}` : '') +
      (organizationFilter ? `&filter_by[organisation_name]=${organizationFilter}` : '') +
      (cityFilter ? `&filter_by[city]=${cityFilter}` : '') +
      (professionFilter ? `&filter_by[profession_name]=${professionFilter}` : '') +
      (customerTypeFilter ? `&filter_by[customer_type]=${customerTypeFilter}` : '') +
      (businessCategoryFilter ? `&filter_by[business]=${businessCategoryFilter}` : '') +
      (this.state.query ? `${this.state.query}` : '') +
      (this.state.customerQuery ? `&filter_by[query]=${this.state.customerQuery}` : '');

    const getAccount = apiCall({
      header: headers,
      httpBody: {},
      url: url,
      httpMethod: configJSON.getCustomerListApiMethod,
    });

    this.getCustomerListApiCallId = getAccount.messageId;
    runEngine.sendMessage(getAccount.id, getAccount);
  }

  sortingProps = {
    width: "18%",
    onQueryChange: (query: string) => this.handleQueryChange(query),
    onChange: (sortingData: ISortingData) => this.setState({ sortingData }),
  };

  handleQueryChange = (query: string) => {
    this.setState({ query }, () => this.getCustomerList());
  };

  handlePageChange = (page: number) => {
    this.setState({ page }, () => { this.getCustomerList() })
  }

  onChangeValue = (value: string) => {
    this.setState({customerQuery: value,page: 0},()=>this.getCustomerList())
  }

  handleFilterChangeAccount = (filters: IFilter[]) => {
    this.setState({
      filters,
      page: 0
    }, () => {
      this.getCustomerList();
    });
  };

  handleViewCustomerDetails = (getCustomerId: string | number) => {
    this.props.navigation.navigate("CustomerView", { customerId: getCustomerId })
  }

  handleEditCustomerDetails = (getCustomerId: string | number) => {
    this.props.navigation.navigate("CustomerEdit", { customerId: getCustomerId })
  }

  handleDeactivateModalOpen = () => {
    this.setState({confirmDeactivationModalOpen: true, popOverOpened: false})
  }

  handleDeactivateModalClose() {
    this.setState({confirmDeactivationModalOpen: false})
  }

  handleConfirmProductDeactivateModalClose() {
    this.setState({customerDeactivateModalOpen: true})
  }

  handleGoList() {
    this.setState({customerDeactivateModalOpen: false});
    this.getCustomerList();
  }

  activeDeactiveCustomer = () => {
    if(this.state.popOverItemStatus) {
      this.setState({ isDeactivationStarted: true, popOverOpened: false });
    } else {
      this.setState({ isLoading: true, popOverOpened: false });
    }

    let headers = {
      "Content-type": "application/json", 
      token: this.authToken
    }

    let url = `${configJSON.addNewCustomerApiEndPoint}/${this.state.rowData.id}/${this.state.popOverItemStatus ? "deactivate" : "activate"}`;

    const getAccount = apiCall({
      header: headers,
      httpBody: {},
      url: url,
      httpMethod: configJSON.dragAndDropProductApiMethod,
    });

    this.activeDeactiveCustomerApiCallId = getAccount.messageId;
    runEngine.sendMessage(getAccount.id, getAccount);
  }

  handleFilterAutoCompleteChange = (filterName: string, query: string) => {
    this.setState({currentFilter: filterName})
    this.state.filters.forEach((item: IFilter) => {
      if (item.title === filterName) item.value = query;
      return item;
    });

    let headers = {
      "Content-type": "application/json", 
      token: this.authToken
    }

    let url;
    url = configJSON.customerSuggestionApiEndPoint +
      (filterName === "Name" ? `?name=${query}` : '') +
      (filterName === "Organization" ? `?organisation_name=${query}` : '') +
      (filterName === "City" ? `?city=${query}` : '') +
      (filterName === "Profession" ? `?profession=${query}` : '') +
      (filterName === "Customer Type" ? `customer_type=${query}` : '') +
      (filterName === "Business Category" ? `?business=${query}` : '');

    const getAccount = apiCall({
      header: headers,
      httpBody: {},
      url: url,
      httpMethod: configJSON.customerSuggestionApiMethod,
    });

    this.customerSuggestionApiCallId = getAccount.messageId;
    runEngine.sendMessage(getAccount.id, getAccount);
  }

  handlePopoverMenuClose = () => {
    this.setState({ popOverOpened: false })
  }

  handleFilterClose = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    this.setState({ filterAnchor: event.currentTarget })
  }

  handleCloseFilterPopover = () => {
    this.setState({ filterAnchor: undefined })
  }

  handleMoreMenu = (item: ICustomerData, position: DOMRect) => {
    this.setState({
      popOverOpened: true,
      popOverItemId: item.id,
      popOverItemStatus: item.attributes.activated,
      popOverLeft: position.left + window.scrollX,
      popOverTop: position.top + window.scrollY,
      rowData: item
    });
  }

  handleCustomerListCloseActionList = () => {
    this.setState({ openCustomerListAction: null })
  }

  handleCustomerListExportTemplate = () => {
    this.setState({ openCustomerListAction: null })
    const url = configJSON.exportTemplateCustomerListExcelApiurl;

    const requestMessage = makeApiMessage({
      url: url,
      method: configJSON.validationApiMethodType,
    });

    this.exportTemplateFileApiId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleCustomerListOpenImportModal = () => {
    this.setState({ openCustomerListAction: null });
    let msg = new Message(getCustomEnumName(CustomEnums.ImportExportPopupMeassage))
    msg.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props,
    )
    this.send(msg)
  }

  handleCustomerListExportCsv = () => {
    this.setState({ openCustomerListAction: null });
    const token = this.authToken;
    const url = configJSON.exportCustomerListExcelApiUrl + `?token=${token}`;

    const requestMessage = makeApiMessage({
      url: url,
      method: configJSON.validationApiMethodType,
    });

    this.exportFileApiId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);  
  }

  handleCustomerListImportExportModalClose = () => {
    const message = new Message(getCustomEnumName(CustomEnums.ImportExportPopupClose))
    message.addData('ParentpageRoute', 'PeopleManagement2')
    runEngine.sendMessage(message.id, message)
  }

  handleCustomerListFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ setLoaded: 0 })
    const getFile = event.target.files;

    let randomCustomerListFileNumber = randomNumberGenerator(1, 9);
    const delay = randomCustomerListFileNumber * 25;
    const uploadCustomerListInterval = setInterval(() => {
      this.setState({
        setLoaded: updateCustomerListLoadingTime(this.state.setLoaded)
      }, () => {
        const message = new Message(getCustomEnumName(CustomEnums.ImportExportPopupFileMessage))
        message.addData('returnValue', { setLoaded: this.state.setLoaded, file: getFile && getFile[0] })
        runEngine.sendMessage(message.id, message)
      })

    }, delay);

    // for adding 20 percent every time
    function updateCustomerListLoadingTime(prevLoaded: number) {
      if (prevLoaded >= 100) {
        clearInterval(uploadCustomerListInterval);
        return 100;
      }
      return prevLoaded + 20
    }

    const checkFile = getFile && getFile[0];
    this.setState({ uploadedFile: checkFile as File})
  };

  handleCustomerListImportFile = () => {
    const url = configJSON.importCustomerListExcelApiurl;
    
    const formData = new FormData();
    formData.append('data[file]', this.state.uploadedFile as File);
    const requestMessage = makeApiMessage({
      url: url,
      body: formData,
      method: configJSON.exampleAPiMethod,
      isHeader: true
    });

    this.importFileApiId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);    
  }

  handleCustomerListSnackbarClose = () => {
    this.setState({ errorSnackbarOpen: false })
  }
  handleUserChange = (userContext: IUserContext) => {
    const apiKey = customPermissionApiKey.customerPermission;
    const userData = userContext.user?.attributes.permission_groups;
    const value = checkForNewPermissonStatus(apiKey, userData as Array<PermissionGroupArray>);
    
    const apiKeyImportExport = customPermissionApiKey.dataImportExportPermission;
    const valueImportExportPermission = checkForImportExportPermissionStatus(apiKeyImportExport, userData as Array<PermissionGroupArray>);
    this.setState({
      permissionStatus: {
        ...value,
        exportPermission: valueImportExportPermission.exportPermission
      }
    })
  };
  // Customizable Area End
}
