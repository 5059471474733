import React, { CSSProperties, Component, RefObject  } from 'react';
import { Tooltip, Typography, withStyles, TooltipProps} from "@material-ui/core";


interface Props {
 text: string;
 title?: string;
 maxWidth?: string;
 classes?: TooltipProps['classes'];
 direction?: TooltipProps["placement"];
 classNames?: string;
 isOverflowProps?: boolean;
 charCount?: number;
}

interface State {
  isTextOverflowing: boolean;
}

const tStyles: {} | CSSProperties =  ({
    tooltip: {
      fontStyle: "normal",
      textAlign: "center",
      borderRadius: "5px",
      color: "white",
      fontWeight: 600,
      padding: "5px 9px",
      fontSize: "12px",
      lineHeight: "16px",
      maxHeight: "280px",
    },
  });

class TruncatedTextWithTooltip extends Component<Props, State> {
  textRef: RefObject<HTMLDivElement>;
  constructor(props: Props) {
    super(props);
    this.state = {
      isTextOverflowing: false,
    };
    this.textRef = React.createRef();
  }

  componentDidMount() {
    if(this.props.isOverflowProps){
      this.setState({ isTextOverflowing: true });
    } else {
      this.checkTextOverflow();
    }
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.text !== this.props.text) {
      this.checkTextOverflow();
    }
  }

  checkTextOverflow() {
    if (this.textRef.current) {
      const isOverflowing =
        this.textRef.current.clientWidth < this.textRef.current.scrollWidth;
      this.setState({ isTextOverflowing: isOverflowing });
    }
  }

  truncateText = (val: string, valLength: number) => {
    if (val === undefined || val === null) return "";
    if (val.length > valLength) {
      return `${val.slice(0, valLength)}...`;
    } else {
      return val;
    }
  };

  render() {
    const { text, maxWidth, classes, direction, classNames, title, charCount } = this.props;
    const { isTextOverflowing } = this.state;

    const resultText = this.truncateText(text, charCount || text.length)

    const isOverflow = (!!charCount && charCount > 0 && resultText.endsWith("...")) || isTextOverflowing
    const tipTitle = title ? title : isOverflow  ? text : ''


    return (
      <Tooltip title={tipTitle} arrow classes={classes} placement={direction ? direction : "bottom"}>
        <Typography
          style={{
            ...(maxWidth ? {
            maxWidth: maxWidth,
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis'} 
            : {}),
            cursor: tipTitle ? 'pointer' : 'default',
          }}
          className={classNames}
          ref={this.textRef}
        >
          {resultText}
        </Typography>
      </Tooltip>
    );
  }
};

export default withStyles(tStyles)(TruncatedTextWithTooltip);