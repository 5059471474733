import React from "react"

type Props = {
    onPrint : Function
}

const PrintRaw = ({onPrint} : Props) => {
    return (
        <div>
          <button onClick={() => onPrint({data:"#M#\n123456789\n123456\n098765\n1245\nF-H#E#" })}>Print multiple lines</button>
          <button onClick={() => onPrint({data:"#T#\nTEST LABEL\n#E#" })}>Print text</button>
          <button onClick={() => onPrint({data:"#T#\nTEST LABEL\n#E#\n" })}>Print text2</button>
          <button onClick={() => onPrint({data:["#T#\nTEST LABEL\n#E#\n"] })}>Print text3</button>
          <button onClick={() => onPrint({data:"T#T\nTEST LABEL\n#E#" })}>Print test</button>
          <button onClick={() => onPrint({data:"Q#\nr12345678\n#E#\n" })}>Print QR</button>
          <button onClick={() => onPrint({data:"Q#\nr12345678\n#E#" })}>Print QR2</button>
          <button onClick={() => onPrint({data: ["#Q#\n", "QR code data", "#E#\n"]})}>print qr 3</button>
          <button onClick={() => onPrint({data: "#T#\nText line 1\nText line 2\nText line 3\nText line 4\n#E#\n#Q#QR code data#E#"})}>print 2 columns</button>
          <button onClick={() => onPrint({data: "#T#\nText line 1\nText line 2\nText line 3\nText line 4#Q#QR code data#E#"})}>print 2 columns</button>
          <button onClick={() => onPrint({data: ["#T#\n","Text line 1\n","Text line 2\n","Text line 3\n","Text line 4\n","#Q#\n", "QR code data", "#E#"]})}>print 2 columns</button>
          </div>
    )
}

export default PrintRaw