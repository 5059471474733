import React from 'react';
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    customIconHidden: {
        '& .MuiAlert-icon': {
            display: 'none',
        },
    },
    addBorderLeft: {
      borderLeft: '3px solid #D97706',
      borderRadius: 5
    }
}));

export interface ISnackbarData {
    open: boolean;
    onClose: () => void;
    errorMessage: string;
    severity: "error" | "warning" | "info" | "success";
    hideIcon?: boolean;
    autoHideDuration?: number;
}

export const CustomSnackbar = (props: ISnackbarData) => {
    const { open, onClose, errorMessage, severity, hideIcon, autoHideDuration } = props;
    const classes = useStyles();

    return (
        <Snackbar
            anchorOrigin={{
                vertical: "top",
                horizontal: "center",
            }}
            open={open}
            onClose={onClose}
            className={hideIcon ? classes.addBorderLeft : ''}
            autoHideDuration={autoHideDuration || 2000}
        >
            <Alert severity={severity} className={hideIcon ? classes.customIconHidden : ''}>
                {errorMessage}
            </Alert>
        </Snackbar>
    );
};