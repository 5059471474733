// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { makeApiMessage } from "../../../components/src/common";
import {
  CustomerList,
  CustomerSearchResponse,
  ServiceDropdownList,
} from "./utils";
import { IMyUser } from "../../../blocks/navigationmenu/src/utils";
import {
  CustomerDataType,
  IlaundryOrder,
  IlaundryOrderItem,
  UniqueCombination,
} from "../../../components/src/Interface";
import {
  CustomEnums,
  debounce,
  getCustomEnumName,
  navigateTo
} from "../../../blocks/utilities/src/CustomBlockHelpers";
import { isNumber } from "lodash";
// Customizable Area End

// Customizable Area Start
export const configJSON = require("./config");
const serviceDropList = [
  {
    value: "pick_up",
    option: "Pick up",
  },
  {
    value: "delivery",
    option: "Delivery",
  },
  {
    value: "pick_up_and_delivery",
    option: "Pick up and Delivery",
  },
];
// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation: any;
  id: string;
  currentUser: IMyUser | undefined;
  selectedMainTab: string;
  printerSetting: { no_of_copies: number | null } | null; 
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  cartTotalOpen: boolean;
  countryCodeInput: string;
  customerPhoneNumber: string;
  customerList: Array<CustomerList>;
  customerData: CustomerDataType | null;
  openAddCustomerModal: boolean;
  errorSnackbarOpen: boolean;
  errorMessage: string;
  viewCustomerPopup: boolean;
  isEditCustomerForm: boolean;
  serviceDropdownList: Array<ServiceDropdownList>;
  selectedServiceValue: string;
  isQuickDropCount: number;
  isQuickDropOrder: boolean;
  order: IlaundryOrder | undefined;
  paymentModalVisible: boolean;
  paymentClicked: boolean;
  notesTextArea: string;
  saveForFuture: boolean;
  orderSummaryVisible: boolean;
  placedOrder: IlaundryOrder | undefined;
  isPrmocodeApplied: boolean;
  prefrenceCustomerNumber: string;
  customerId: string;
  editId?: string;
  isLoading: boolean;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class ShoppingCartController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  searchCustomerApiMessageId: string = "";
  searchCustomerDataApiMessageId: string = "";
  createQuickdropOrderId: string = "";
  getOrderDetailsAPIcallID: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getCustomEnumName(CustomEnums.CustomActionReducers),
    ];
    // Customizable Area End

    // Customizable Area Start
    this.state = {
      cartTotalOpen: false,
      countryCodeInput: "+966",
      customerPhoneNumber: "",
      customerList: [],
      customerData: null,
      openAddCustomerModal: false,
      errorSnackbarOpen: false,
      errorMessage: "",
      viewCustomerPopup: false,
      isEditCustomerForm: false,
      serviceDropdownList: serviceDropList,
      selectedServiceValue: "",
      isQuickDropCount: 0,
      isQuickDropOrder: false,
      order: undefined,
      paymentModalVisible: false,
      paymentClicked: false,
      notesTextArea: "",
      saveForFuture: false,
      orderSummaryVisible: false,
      placedOrder: undefined,
      isPrmocodeApplied: false,
      prefrenceCustomerNumber: "",
      customerId: "",
      isLoading: false,
    };
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    const editId = this.props.navigation.getParam("editId");
    if (editId) {
      this.setState({ editId },()=>{
        this.onCustomerSearch()
        this.getLoundryOrder(editId)
      }
    );
    }  
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    console.debug(from, message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId === this.searchCustomerApiMessageId) {
        this.handleCustomerSearchResponse(responseJson);
      } else if (apiRequestCallId === this.searchCustomerDataApiMessageId) {
        this.handleSearchedCustomerDataResponse(responseJson.data);
      } else if (apiRequestCallId === this.createQuickdropOrderId) {
        this.handleQuickDropOrderResponse(responseJson.data);
      } else if (apiRequestCallId === this.getOrderDetailsAPIcallID) {
        this.handleGetOrderDetailsResponse(responseJson.data);
      }
    } else if (
      getCustomEnumName(CustomEnums.CustomActionReducers) === message.id
    ) {
      this.handleCustomReducer(message);
    } 
    // Customizable Area End
  }

  // Customizable Area Start
  handleCustomReducer = (message: Message) => {
    const action = message.getData(
      getCustomEnumName(CustomEnums.CustomReducerAction)
    );
    const payload = message.getData(
      getCustomEnumName(CustomEnums.CustomReducerPayload)
    );

    if (action === "SET_ORDER") {
      this.setState({
        order: payload,
      });
    } else if(action === 'CLEAR_CUSTOMER_CART') {
      this.handleClearOrder();
    } else if(action === "CUSTOMER_PHONE"){
      this.setState({
        customerPhoneNumber: payload.option,
        prefrenceCustomerNumber: "",
      },() =>this.handleCheckMobileNumber(payload));
    }
  };

  handleToggleCartTotal() {
    const { order } = this.state;
    if (order?.no_of_items) {
      this.setState((prev) => ({ cartTotalOpen: !prev.cartTotalOpen }));
    }
  }

  handleChangeCountryCode(value: string) {
    this.setState({
      countryCodeInput: value,
    });
  }

  handleCustomerPhoneNumber(value: string) {
    localStorage.removeItem("preferenceCustomer")
    const { customerList } = this.state;

    const selectedOption = customerList.find(
      (item: { option: string }) => item?.option == value
    );
    if(!this.state.editId){
      this.setState(
        {
          customerPhoneNumber: value.replace(/^0+/, ""),
          isQuickDropCount: 0,
          isQuickDropOrder: false,
          order: undefined,
          orderSummaryVisible: false
        },
        () =>
          !value.includes("-")
            ? this.onCustomerSearch()
            : this.handleCheckMobileNumber(selectedOption as CustomerList)
      );
    }else{
     if(value === this.state.customerPhoneNumber){
       this.setState(
         {
           customerPhoneNumber: value.replace(/^0+/, ""),
           isQuickDropCount: 0,
           isQuickDropOrder: false,
         },
         () =>
           !value.includes("-")
             ? this.onCustomerSearch()
             : this.handleCheckMobileNumber(selectedOption as CustomerList)
       );
     }else{
      navigateTo({ props: this.props, screenName: "OrderCreation" })
     }
    }
   
  }

  onCustomerSearch() {
    const { countryCodeInput, customerPhoneNumber } = this.state;

    if (String(customerPhoneNumber).length < 3) return;

    const fullNumber = String(countryCodeInput + customerPhoneNumber).replace(
      "+",
      ""
    );

    const message = makeApiMessage({
      url:
        configJSON.customerSearchEndPoint + "?full_phone_number=" + fullNumber,
      method: configJSON.getApiMethod,
    });
    this.searchCustomerApiMessageId = message.messageId;
    runEngine.sendMessage(message.id, message);
  }

  handleCustomerSearchResponse(responseJson: CustomerSearchResponse) {
    if (responseJson) {
      const customer_list =
        responseJson.customer?.map((customer) => {
          return {
            id: String(customer.id),
            country_code: String(customer.country_code),
            full_phone_number: customer.full_phone_number,
            option: `${customer.full_name} - ${customer.phone_number}`,
          };
        }) || [];
      this.setState({ customerList: customer_list });
    }
  }

  handleOnChangeCustomerPhone() {
    this.setState({
      customerPhoneNumber: "",
      countryCodeInput: "+966",
      customerList: [],
      customerData: null,
      isQuickDropCount: 0,
      order: undefined,
    });
    this.handleSendAction("SELECT_CUSTOMER", null);
  }

  handleCheckMobileNumber(searched_customer: CustomerList) {
    this.setState({ isLoading: true });

    if(this.props.currentUser?.attributes?.employee_proifle){
      const store_management_id =
      this.props.currentUser?.attributes.employee_proifle.data.attributes
        .store_management_id;

      let extraQueryParams = "&cart_order_required=true";
      let store_id = `&store_id=${store_management_id}`;

      if(searched_customer?.full_phone_number) {
        const message = makeApiMessage({
          url:
            configJSON.searchCustomerApiEndPoint +
            "?full_phone_number=" +
            searched_customer?.full_phone_number +
            extraQueryParams +
            store_id,
          method: configJSON.getApiMethod,
        });
        this.searchCustomerDataApiMessageId = message.messageId;
        runEngine.sendMessage(message.id, message);
      }
    }
  }

  handleCheckMobileNumberForPrefrenceCustomer(searched_customer: CustomerList) {
    this.setState({ isLoading: true });
    
    const store_management_id =
      localStorage.getItem("store_id");
    let extraQueryParams = "&cart_order_required=true";
    let store_id = `&store_id=${store_management_id}`;

    if(searched_customer?.full_phone_number) {
      const message = makeApiMessage({
        url:
          configJSON.searchCustomerApiEndPoint +
          "?full_phone_number=" +
          searched_customer?.full_phone_number +
          extraQueryParams +
          store_id,
        method: configJSON.getApiMethod,
      });
      this.searchCustomerDataApiMessageId = message.messageId;
      runEngine.sendMessage(message.id, message);
    }
  }


  handleSearchedCustomerDataResponse(customerData: CustomerDataType) {
    if(this.state.prefrenceCustomerNumber) {
      this.setState({
        customerPhoneNumber: this.state.prefrenceCustomerNumber,
        prefrenceCustomerNumber: "",
      });
    }
    const saveForFutureAndNotesPayload = {
      notesTextArea: customerData.attributes.saved_notes
      ? customerData.attributes.saved_notes
      : customerData?.attributes?.order?.notes,
      saveForFuture: customerData?.attributes?.order?.save_for_future || false,
      callUpdateApiForSaveForFutureNote: false
    }
    this.setState({
      customerData: customerData,
      notesTextArea: customerData.attributes.saved_notes
      ? customerData.attributes.saved_notes
      : customerData?.attributes?.order?.notes,
      saveForFuture: customerData?.attributes?.order?.save_for_future || false
    } , () => this.handleSendAction("SET_SAVE_FOR_FUTURE_&_NOTES", saveForFutureAndNotesPayload));
    this.handleSendAction("SELECT_CUSTOMER", customerData);
    this.handleCustomerQuickDropOrder(customerData.attributes.order);
    this.setState({ isLoading: false });
  }

  handleQuickDropOrderResponse = (response: { attributes: IlaundryOrder }) => {
    const isQuickDropCountHas = response.attributes.order_items.length > 0;
    this.setState({
      order: response.attributes,
      isQuickDropOrder: true,
      isQuickDropCount: isQuickDropCountHas
        ? response.attributes.order_items[0].attributes.quantity
        : 0,
      isLoading: false
    });
    this.handleSendAction("SET_ORDER", response.attributes);
  };

  handleGetOrderDetailsResponse = (responseJson: {
    attributes: IlaundryOrder;
  }) => {
    if (responseJson.attributes.status === "placed" 
    &&
      ((this.state.editId && !responseJson.attributes.is_quick_drop)||
      this.state.paymentModalVisible)
    ) {
      this.setState({
        paymentModalVisible: false,
        paymentClicked: false,
        orderSummaryVisible: true,
        order: undefined,
        placedOrder: responseJson.attributes,
        cartTotalOpen: false,
        isQuickDropCount: 0,
      });
      this.handlePrintReceipt(responseJson.attributes.invoice_receipt_pdf_url)
      this.handleSendAction("SET_ORDER", null);
      this.handleSendAction("REMOVE_TEMP_PRODUCT_AFTER_PLACING_ORDER", null)
    } else if (responseJson.attributes.status === "in_cart" && !this.state.editId) {

      this.setState({
        order: responseJson.attributes,
      });
      this.handleSendAction("SET_ORDER", responseJson.attributes);
    } else {
      const order: any = responseJson.attributes;
      if (this.state.editId) {
        if (order.is_quick_drop) { 
          order.order_items = [];
        }
        order.is_quick_drop = false;
        order.status = "in_cart";
        order.edit_order = true;
        
        const selectedOption = {
          country_code: order.customer.country_code,
          full_phone_number: `${order?.customer?.full_phone_number}`,
          id: String(order?.id),
          option:`${order?.customer.full_name} - ${order?.customer?.full_phone_number.substring(3)}`,
        }
        this.setState({order:order})
        setTimeout(() => {
          this.setState({prefrenceCustomerNumber: selectedOption.option})
        } , 500)
          this.setState({
            paymentModalVisible: false,
            paymentClicked: false,
            orderSummaryVisible: true,
            placedOrder: order,
            customerId:String(responseJson.attributes.customer.id) , 
            customerPhoneNumber:`${order?.customer?.full_phone_number.substring(3)} - ${order?.customer.full_name}`})
            this.handleSendAction("EDIT_SELECT_CUSTOMER", order);
      }
    }
    this.setState({ isLoading: false });
  };

  handleClearOrder = () => {
    const { customerData } = this.state;
    this.setState({
      orderSummaryVisible: false,
      customerData: null,
      order: undefined,
      placedOrder: undefined,
      isQuickDropOrder: false,
      isQuickDropCount: 0,
      editId: ""
    });
    this.handleOnChangeCustomerPhone();
    this.handleSendAction("SET_ORDER", null);
    if(this.state.editId){
      navigateTo({ props: this.props, screenName: "OrderCreation" })
      if(customerData){
        const customerItem = {
            id: customerData.id,
            option: `${customerData.attributes?.full_name} - ${customerData.attributes?.phone_number}`,
            full_phone_number:
              customerData.attributes?.country_code +
              "" +
              customerData.attributes?.phone_number,
            country_code: customerData.attributes?.country_code,
          };
          setTimeout(()=>{
            this.handleSendAction("CUSTOMER_PHONE", customerItem);
          },1000)
        }
      }
  };

  handleOrderSummaryNewOrder = () => {
    const { customerData } = this.state;
    if (customerData) {
      const customerItem = {
        id: customerData.id,
        option: `${customerData.attributes?.full_name} - ${customerData.attributes?.phone_number}`,
        full_phone_number:
          customerData.attributes?.country_code +
          "" +
          customerData.attributes?.phone_number,
        country_code: customerData.attributes?.country_code,
      };
      this.setState({
        orderSummaryVisible: false,
        placedOrder: undefined,
        editId: ""
      });
      this.handleCheckMobileNumber(customerItem);
      if(this.state.editId){
        navigateTo({ props: this.props, screenName: "OrderCreation" })
        setTimeout(()=>{
          this.handleSendAction("CUSTOMER_PHONE", customerItem);
        },1000)
      }
    }
  };

  handleAddCustomerPopupOpen() {
    const { customerPhoneNumber, countryCodeInput } = this.state;

    this.setState({
      openAddCustomerModal: true,
      customerData: {
        attributes: {
          country_code: countryCodeInput,
          phone_number: customerPhoneNumber,
          payment_type: "",
          gender: "",
          city: "",
          business: "",
        },
      } as CustomerDataType,
    });
  };

  handleOpenAddCustomerPopup() {
    const {
      customerData,
      customerPhoneNumber,
      customerList,
      countryCodeInput,
    } = this.state;

    if (
      customerPhoneNumber &&
      customerList.find(
        (customer: CustomerList) => customer.option === customerPhoneNumber
      )
    ) {
      this.setState({
        errorSnackbarOpen: true,
        errorMessage: configJSON.Strings.customer_phone_already_register,
      });
      return;
    }

    if (customerData == null) {
      this.handleAddCustomerPopupOpen();
    } else if(customerData.id == undefined) {
      this.handleAddCustomerPopupOpen();
    }
  }

  handleCloseAddCustomerPopup() {
    this.setState({
      openAddCustomerModal: false,
      isEditCustomerForm: false
    });
  }

  handleSnackbarClose(_event?: React.SyntheticEvent, reason?: string) {
    if (reason === "clickaway") {
      return;
    }
    this.setState({
      errorSnackbarOpen: false,
      errorMessage: "",
    });
  }

  onSetCustomerFromPopup(customer: unknown) {
    if (typeof customer != "string") {
      const { full_name, country_code, full_phone_number, customer_id } =
        (customer as {
          full_name: string;
          customer_id: string;
          country_code: string;
          full_phone_number: string;
        }) || {};

      const mobile_number = String(full_phone_number).replace(
        String(country_code).replace("+", ""),
        ""
      );
      const option = `${full_name} - ${mobile_number}`;

      const customerList = {
        option: option,
        id: customer_id,
        country_code: "+" + country_code,
        full_phone_number: full_phone_number,
      };

      this.setState(
        {
          customerList: [customerList],
        },
        () => {
          this.handleCheckMobileNumber(customerList as CustomerList)
          this.setState({
            countryCodeInput: country_code,
            customerPhoneNumber: `${mobile_number} - ${full_name}`,
          });
        }
      );
    }
  }

  handleViewCustomerPopupOpen() {
    const { customerData } = this.state;
    if (customerData !== null && customerData.id) {
      this.setState({
        viewCustomerPopup: true,
      });
    }
  }

  handleCloseViewCustomerPopup = () => {
    this.setState({ viewCustomerPopup: false, isEditCustomerForm: false });
  };

  handleEditCustomerForm() {
    this.setState({
      isEditCustomerForm: true,
      viewCustomerPopup: false,
      openAddCustomerModal: true,
    });
  }

  handleServiceDropdown = (
    event: React.ChangeEvent<{
      value: string;
    }>
  ) => {
    this.setState({
      selectedServiceValue: event.target.value,
    });
  };

  handleOnChangeNotes = (
    event: React.ChangeEvent<{
      value: string;
    }>
  ) => {
    this.setState({
      notesTextArea: event.target.value,
    });
    this.debouncedFetchNotes();
  };

  handleOnCheckedToggle = (checked: boolean) => {
    this.setState({
      saveForFuture: checked,
    }, () => this.handleSaveForFutureOrder());
  };

  returnQuickDropOrderBody = () => {
    const { isQuickDropCount, customerData, order } = this.state;
    const store_management_id =
      this.props.currentUser?.attributes.employee_proifle.data.attributes
        .store_management_id;

    if (order !== undefined) {
      let order_items_attributes = [];
      if (order.order_items.length > 0) {
        order_items_attributes = order.order_items.map((orderItem) => {
          return {
            id: orderItem.attributes.id,
            quantity: isQuickDropCount,
            _destroy: isQuickDropCount == 0 ? true : false,
          };
        });
      } else {
        order_items_attributes = [{ quantity: isQuickDropCount }];
      }
      return {
        data: {
          customer_id: customerData?.id,
          notes: "",
          store_management_id: store_management_id,
          save_for_future: false,
          is_quick_drop: true,
          order_items_attributes: order_items_attributes,
        },
      };
    } else {
      return {
        data: {
          customer_id: customerData?.id,
          notes: "",
          store_management_id: store_management_id,
          save_for_future: false,
          is_quick_drop: true,
          order_items_attributes: [
            {
              quantity: isQuickDropCount,
            },
          ],
        },
      };
    }
  };

  handleCreateQuickDropOrder = () => {
    const { order } = this.state;

    let apiEndPoint = "";
    let apiMethod = "";
    if (order !== undefined) {
      apiEndPoint = configJSON.updateOrderEndPoint + order.id;
      apiMethod = "PUT";
    } else {
      apiEndPoint = configJSON.createNewOrderEndPoint;
      apiMethod = "POST";
    }

    let message = makeApiMessage({
      url: apiEndPoint,
      body: JSON.stringify(this.returnQuickDropOrderBody()),
      method: apiMethod,
    });
    this.createQuickdropOrderId = message.messageId;
    runEngine.sendMessage(message.id, message);
  };

  isQuickDropDisabled = () => {
    const { customerData, order } = this.state;
    if (customerData == null) {
      return true;
    } else {
      if (order !== undefined) {
        if (!order.is_quick_drop && order.order_items.length > 0) {
          return true;
        }
      } else {
        return false;
      }
    }
  };

  handleQuickDropChange = (count: number) => {
    this.setState(
      {
        isQuickDropCount: count,
        isQuickDropOrder: !!count,
        isLoading: true
      },
      () => this.handleCreateQuickDropOrder()
    );
  };

  handleCustomerQuickDropOrder = (customerOrder: IlaundryOrder) => {
    if (customerOrder !== null) {
      this.setState({
        order: customerOrder,
        isQuickDropOrder: customerOrder.is_quick_drop,
        isQuickDropCount: customerOrder.is_quick_drop
          ? customerOrder.order_items[0]?.attributes.quantity
          : 0,
      });
    } else {
      this.setState({
        order: undefined,
        isQuickDropOrder: false,
        isQuickDropCount: 0,
      });
    }
  };

  handlePayButton = () => {
    const { isQuickDropOrder, isQuickDropCount } = this.state;
    if (isQuickDropOrder) {
      if (isQuickDropCount > 0) this.handlePayClick();
    } else {
      this.handlePayClick();
    }
  };

  getPayButtonEnable() {
    const { isQuickDropOrder, isQuickDropCount, order } = this.state;

    if (order !== undefined) {
      if (isQuickDropOrder && isQuickDropCount > 0) {
        return false;
      } else if (order.order_items.length > 0) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  }

  handlePayClick = () => {
    const { currentUser } = this.props;

    if (
      currentUser?.attributes?.employee_proifle?.data?.attributes
        ?.store_management?.attributes?.payment_method_screen
    ) {
      this.setState({ paymentModalVisible: true });
    }
  };

  handlePaymentDone = () => {
    const {order} = this.state
    this.setState({
      paymentClicked: true,
    });
    this.getLoundryOrder();
    if(this.state.editId){
      const selectedOption = {
        country_code: String(order?.customer.country_code),
        full_phone_number: `${order?.customer?.full_phone_number}`,
        id: String(order?.id),
        option:`${order?.customer.full_name} - ${order?.customer?.full_phone_number.substring(3)}`,
      }
      this.handleCheckMobileNumberForPrefrenceCustomer(selectedOption)
    }
  };

  getLoundryOrder = (editOrderId?:string) => {
    const { order } = this.state;
    let message 
    if(editOrderId){
       message = makeApiMessage({
        url: configJSON.updateOrderEndPoint + editOrderId,
        method: "GET",
      });
    }else{
       message = makeApiMessage({
        url: configJSON.updateOrderEndPoint + order?.id,
        method: "GET",
      });
    }
    if(editOrderId){
      this.getOrderDetailsAPIcallID = message.messageId;
      runEngine.sendMessage(message.id, message);
    }else{
      this.getOrderDetailsAPIcallID = message.messageId;
      if (!order?.id) return;
      runEngine.sendMessage(message.id, message);
    }
  };

  handlePaymentModalClose = () => {
    this.setState({
      paymentModalVisible: false,
      paymentClicked: false,
    });
  };

  formatTaxPercentage = () => {
    const { order: laundryOrder } = this.state;
    const order = laundryOrder;

    if (!order) return null;
    const decimalIndex = order.tax_percentage.indexOf(".");
    const formattedTaxPercentage =
      order.tax_percentage.slice(0, decimalIndex) + "%";
    return formattedTaxPercentage;
  };

  getPayButtonText() {
    const { isQuickDropOrder } = this.state;
    let text = isQuickDropOrder ? "Submit" : "Pay";
    return text;
  }

  handleSendAction(action: string, payload?: unknown) {
    let message = new Message(
      getCustomEnumName(CustomEnums.CustomActionReducers)
    );
    message.addData(getCustomEnumName(CustomEnums.CustomReducerAction), action);
    message.addData(
      getCustomEnumName(CustomEnums.CustomReducerPayload),
      payload
    );
    runEngine.sendMessage(message.id, message);
  }

  getCombinationList() {
    const { order } = this.state;
    const newArry = Array.from(
      new Set(
        order?.order_items
          .sort(
            (accval: IlaundryOrderItem, bccVal: IlaundryOrderItem) =>
              Number(accval.id) - Number(bccVal.id)
          )
          .map((item) => {
            return {
              service_id: Number(item.attributes.service_id),
              category_id: item.attributes.category_id,
            };
          })
      )
    );
    return newArry;
  }

  getUniqueCombinationList(combinationList: UniqueCombination[]) {
    const newArray = combinationList.filter(
      (filterEle, index) =>
        index ===
        combinationList.findIndex(
          (element) =>
            element.category_id === filterEle.category_id &&
            element.service_id === filterEle.service_id
        )
    );
    return newArray;
  }

  findItemFromUnique(uniqueItem: UniqueCombination) {
    const { order } = this.state;

    const uniqueSingleItem = order?.order_items?.find(
      (item) =>
        item.attributes.service_id == uniqueItem.service_id &&
        item.attributes.category_id === uniqueItem.category_id
    );

    return uniqueSingleItem;
  }

  findSubItemsList(uniqueItem: UniqueCombination) {
    const { order } = this.state;

    const subItemList = order?.order_items?.filter(
      (item) =>
        item.attributes.service_id == uniqueItem.service_id &&
        item.attributes.category_id === uniqueItem.category_id
    );

    return subItemList;
  }

  handlePrintReceipt = (fileUrl?: string | null) => {
    if (fileUrl) {
      const printMessage = new Message(getCustomEnumName(CustomEnums.CustomActionReducers))
      printMessage.addData(getCustomEnumName(CustomEnums.CustomReducerAction), "PRINT_FILE")
      printMessage.addData(getCustomEnumName(CustomEnums.CustomReducerPayload), {
        format: "pdf",
        data: fileUrl
      })
      const copies =  this.props.printerSetting?.no_of_copies
      const printOptions = (isNumber(copies) && copies > 1) ? {copies} : undefined
      printMessage.addData(getCustomEnumName(CustomEnums.CustomPrinterOptions), printOptions)
      this.send(printMessage)
    }
  }

  handleSaveForFutureOrder = () => {
    const payload = {
      notesTextArea: this.state.notesTextArea,
      saveForFuture: this.state.saveForFuture,
      callUpdateApiForSaveForFutureNote: true
    }
    this.handleSendAction("SET_SAVE_FOR_FUTURE_&_NOTES", payload);
  };

  handleInputQty = (
    event: React.ChangeEvent<{ value: string }>,
    orderItem: IlaundryOrderItem
  ) => {
    let specials = /^\d*$/;
    if(specials.test(event.target.value)) {
      const { order } = this.state;
      let tempOrder = JSON.parse(JSON.stringify(order)) as IlaundryOrder;
      const findChangeItem = tempOrder.order_items.find(
        (order_item) => order_item.id == orderItem.id
      );
      if (findChangeItem) {
        findChangeItem.attributes.quantity = Number(event.target.value);
        this.debouncedFetchSearchResults(findChangeItem);
      }

      this.setState({ order: tempOrder });
    } else {
      this.setState({
        errorSnackbarOpen: true,
        errorMessage: "Please enter number only"
      });
    }
  };

  handleCustomChangeQty = (findChangeItem: IlaundryOrderItem) => {
    this.handleSendAction("ADD_CUSTOM_QTY", findChangeItem);
  };

  debouncedFetchSearchResults = debounce(this.handleCustomChangeQty, 500);

  debouncedFetchNotes = debounce(this.handleSaveForFutureOrder, 600);

  handleChangeQuickInputQty = (
    event: React.ChangeEvent<{ value: string }>
  ) => {
    let specials = /^\d*$/;
    if(specials.test(event.target.value)) {
      let qtyNumber = Number(event.target.value);
      this.setState(
        {
          isQuickDropCount: qtyNumber,
          isQuickDropOrder: !!qtyNumber,
          isLoading: true
        }
      );
      this.debouncedFetchCustomQty();
    } else {
      this.setState({
        errorSnackbarOpen: true,
        errorMessage: "Please enter number only"
      });
    }
  };

  debouncedFetchCustomQty = debounce(this.handleCreateQuickDropOrder, 500);

  // Customizable Area End
}
