import React from "react";
// Customizable Area Start
import { withStyles, Theme } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import { Styles } from "@material-ui/styles";

import Grid from "@material-ui/core/Grid";

import CustomerPreferenceViewController, {
  ISpecificationItem,
  ISpecificationOptionItem,
  Props,
} from "./CustomerPreferenceViewController.web";
import CreateCustomerPreferenceWeb from './CreateCustomerPreference.web';
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
// Customizable Area End

// Customizable Area Start
export const styles: Styles<Theme, {}, string> = () => ({
  mainContainer: {
    widht: "100%",
  },
  sectionFormContainerView: {
    marginTop: 24,
    padding: 24,
    border: "1px solid #ECECEC",
    position: "relative",
    borderRadius: 12,
  },
  viewSectionHeaderView: {
    padding: "19px 32px",
    background: "#204B9C",
    color: "white",
    fontWeight: 600,
    fontSize: 18,
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    borderTopLeftRadius: 12,
    borderTopRightRadius: 12,
  },
  rowItem: {
    width: "100%",
    marginTop: 32,
  },
  rowItemLabel: {
    marginBottom: 12,
    fontSize: 18,
    fontWeight: 600,
    color: "#4D4D4D",
  },
  rowItemValue: {
    fontSize: 16,
    fontWeight: 500,
  },
  iconImg: {
    width: 24,
    height: 24,
    marginRight: 16,
  },
  iconImgContainer: {
    display: "flex",
    alignItems: "center",
  },
  upchargeListContainer: {
    display: "flex",
    flexWrap: "wrap",
  },
  upchargeItemContainer: {
    display: "flex",
    marginTop: 12,
    height: 40,
    alignItems: "center",
    borderRadius: 8,
    border: "1px solid #ECECEC",
    background: "rgba(32, 75, 156, 0.15)",
    padding: "9px 16px",
    color: "#204B9C",
    fontSize: 16,
    fontWeight: 500,
    marginRight: 24,
  },
  upchargeIcon: {
    marginRight: 16,
    height: 22,
    width: 22,
  },
  footerContainer: {
    display: "flex",
    marginTop: 32,
    width: "100%",
    justifyContent: "center",
  },
  btnContainer: {
    width: 186,
    height: 50,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 8,
    background: "#F1F5F9",
    fontSize: 16,
    fontWeight: 700,
    color: "white",
    cursor: "pointer"
  }
});
// Customizable Area End

class CustomerPreferenceView extends CustomerPreferenceViewController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  getWillBeRenderedSpecifications = (specifications: ISpecificationItem[]) => {
    let willBeRenderedSpecifications: any = [];
    specifications.forEach((specification: any) => {
      if (
        specification.options_attributes.length > 0 &&
        specification.options_attributes.find(
          (option: ISpecificationOptionItem) => option.selected
        )
      ) {
        willBeRenderedSpecifications.push(specification);
      }
    });

    return willBeRenderedSpecifications;
  };
  // Customizable Area End

  // Customizable Area Start
  render() {
    const { classes = {} } = this.props;
    const { customerDataWithPreference, editModalOpen } = this.state;
    const isCreate: boolean = customerDataWithPreference?.attributes.customer_preferences_attributes?.length === 0

    return (
      <div className={classes.mainContainer}>
        {isCreate && <div style={{textAlign: 'center', fontWeight: 500, padding: "32px 0px"}}>No preference added!</div>}
        {customerDataWithPreference?.attributes.customer_preferences_attributes.map(
          (item) => {
            return (
              <Grid
                key={item.id}
                container
                direction='column'
                className={classes.sectionFormContainerView}
              >
                <Grid container direction='row'>
                  <div className={classes.viewSectionHeaderView}>
                    {item.attributes.product?.attributes.name}
                  </div>
                </Grid>
                <Grid style={{ marginTop: 32 }} container direction='row'>
                  <Grid item xs={12} sm={6}>
                    <div className={classes.rowItem}>
                      <div className={classes.rowItemLabel}>Service</div>
                      <div className={classes.iconImgContainer}>
                        <div>
                          <img
                            className={classes.iconImg}
                            src={item.attributes.service?.attributes?.icon}
                          />
                        </div>
                        <div className={classes.rowItemValue}>
                          {item.attributes.service?.attributes?.name}
                        </div>
                      </div>
                    </div>
                  </Grid>
                  {this.getWillBeRenderedSpecifications(item.attributes
                    .specifications as ISpecificationItem[]).map(
                    (specification: ISpecificationItem) => (
                      <Grid key={item.id} item xs={12} sm={6}>
                        <div className={classes.rowItem}>
                          <div className={classes.rowItemLabel}>
                            {specification.name}
                          </div>
                          <Typography className={classes.rowItemValue}>
                            {
                              specification?.options_attributes.find(
                                (option: ISpecificationOptionItem) =>
                                  option.selected
                              )?.label
                            }
                          </Typography>
                        </div>
                      </Grid>
                    )
                  )}
                </Grid>
                <Grid container direction='row'>
                  <Grid item xs={12} sm={6}>
                    <div className={classes.rowItem}>
                      <div className={classes.rowItemLabel}>Preference</div>
                      <div className={classes.iconImgContainer}>
                        <div>
                          <img
                            className={classes.iconImg}
                            src={
                              item.attributes.preferences.find(
                                (preferenceItem) => preferenceItem.checked
                              )?.icon
                            }
                          />
                        </div>
                        <div className={classes.rowItemValue}>
                          {
                            item.attributes.preferences.find(
                              (preferenceItem) => preferenceItem.checked
                            )?.name
                          }
                        </div>
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <div className={classes.rowItem}>
                      <div
                        className={classes.rowItemLabel}
                        style={{ marginBottom: 0 }}
                      >
                        Upcharge
                      </div>
                      <div className={classes.upchargeListContainer}>
                        {item.attributes.upcharge_lists
                          ?.filter((upcharge) => upcharge.checked)
                          .map((upcharge_item) => (
                            <div
                              key={upcharge_item.name}
                              className={classes.upchargeItemContainer}
                            >
                              <div>
                                <img
                                  className={classes.upchargeIcon}
                                  src={upcharge_item.icon}
                                />
                              </div>
                              <div>{upcharge_item.name}</div>
                            </div>
                          ))}
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            );
          }
        )}
        <div className={classes.footerContainer}>
          <div
            data-test-id='btn-cancel'
            className={classes.btnContainer}
            style={{ color: "#64748B" }}
            onClick={this.props.onCancel}
          >
            Cancel
          </div>
          <div
            data-test-id="btn-edit"
            className={classes.btnContainer}
            style={{ background: "#204B9C", marginLeft: 24 }}
            onClick={this.handleEditModalOpen}
          >
            {isCreate ? "Create" : "Edit"}
          </div>
        </div>
        {editModalOpen &&  <CreateCustomerPreferenceWeb
          customerId={this.props.customerId}
          priceId={this.props.priceListId}
          data-test-id='editPreferenceModal2'
          onCreateForCustomer={(data) => this.handleEditPreferenceForCustomer(data)}
          selectedService={{}}
          editScreen={!isCreate}
          preferenceData={customerDataWithPreference}
          handleClose={this.handleEditModalClose}
          data={[]}
          headerTitle={"Create Item Preference"}
          saveForFuture={true}
          open={editModalOpen}
          responseData={{
            id: "11",
            type: "preference",
            attributes: {
              id: 11,
              name: "Preference 1",
              product_second_name: "Product 1",
              specifications: [],
              upcharges: [],
              preferences: [],
              services: [],
            },
          }}
          onCreate={(data) => { }}
        />
        }
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          open={this.state.snackbarOpen}
          autoHideDuration={6000}
          onClose={this.handleSnackbarClose}
        >
          <Alert severity='success'>
            Preference updated successfully!
          </Alert>
        </Snackbar>
      </div>
    );
  }
  // Customizable Area End
}

// Customizable Area Start
export default withStyles(styles)(CustomerPreferenceView);
// Customizable Area End
