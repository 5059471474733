import React from "react";
// Customizable Area Start
import { toString } from "lodash";
import {
  Box,
  Button,
  Typography,
  IconButton,
  styled,
  AppBar,
  Tabs,
  Tab,
  TextField,
  TablePagination,
  TextareaAutosize,
  FormControlLabel,
  Checkbox,
  Collapse,
  Dialog,
  Select,
  MenuItem,
  DialogActions,
  DialogContent,
  DialogTitle,
  CircularProgress,
  Divider
} from "@material-ui/core";
import { Autocomplete } from '@material-ui/lab'

import Snackbar from "@material-ui/core/Snackbar";
import Radio from "@material-ui/core/Radio";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/moment";
import { Alert } from "@material-ui/lab";
import {
  createTheme,
  ThemeProvider,
  withStyles,
} from "@material-ui/core/styles";
import ProductCard from "../../../components/src/customComponents/ProductCard.web";
import {
  iconCheckBox,
  iconCheckBoxChecked,
  customerAddIcon,
  customerViewIcon,
  emptyCartIcon,
  deleteItemIcon,
  PlusIcon,
  MinusIcon,
  productAddIcon,
  CrossIcon,
  calenderIcon
} from "./assets";

import PageContainer from "../../../blocks/navigationmenu/src/PageContainer.web";
import { IUserContext } from "../../../blocks/navigationmenu/src/PageContainerController.web";

import Loader from "../../../components/src/Loader.web";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import { DropdownSelect } from "../../../components/src/customComponents/DropdownSelect.web";
import {
  InputField,
  SimpleCustomAutocomplete,
  StyledTextField,
} from "../../../components/src/customComponents/CustomTags.web";
import AddCustomerModal from "../../email-account-registration/src/AddCustomerModal.web";
import ShowCustomerDetailsModal from "../../email-account-registration/src/ShowCustomerDetailsModal.web";
import { Formik, FormikProps, FormikErrors } from "formik";
import * as Yup from "yup";
import { OrderTryAgainModal } from "../../../components/src/customComponents/OrderTryAgainModal.web";
import { CustomSnackbar } from "../../../components/src/customComponents/CustomSnackbar.web";
import PreferenceItem from "../../../components/src/customComponents/PreferenceItem.web";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import SameProductDifferentServiceModal from "../../../components/src/customComponents/SameProductDifferentServiceModal.web";
import OrderSummary from "./OrderSummary.web";
import AddProductModal from "../../catalogue/src/AddProductModal.web";
import AutocompleteSelect from "../../../components/src/AutocompleteSelect.web";

import { colors } from "../../utilities/src/Colors";
import { CustomMenuProps } from "../../ProductDescription/src/HomeCleaningSettingsList.web";
import { TextLabel, ErrorLabel } from '../../promocodes/src/PromoCodeCreate.web'
import { validationSchemaForAddProduct } from './utils'

const muiStyle = {
  muiIcon: { padding: "4px" },
  muiCheckIcon: { position: "relative" as const, top: "4px" },
};

const theme = createTheme({
  typography: {
    fontFamily: "Montserrat",
  },
  props: {
    MuiCheckbox: {
      icon: <img src={iconCheckBox} style={muiStyle.muiIcon} />,
      checkedIcon: (
        <img src={iconCheckBoxChecked} style={muiStyle.muiCheckIcon} />
      ),
    },
  },
});
import PaymentModal from "../../Payments/src/PaymentModal.web";
import QuickDrop from "./QuickDrop.web";
const productType = {
  NORMAL: "noraml",
  SUBPRODUCT: "Sub-Product",
  PARENT: "Parent",
};
// Customizable Area End

import OrderCreationController, {
  // Customizable Area Start
  CatalogueList,
  ICartProduct,
  IPaymentOption,
  Props,
  Store,
  ICatalogueTypes,
  ICleaningOrderItem,
  IPlantProduct,
  SubProductWithId,
  configJSON,
  Actions,
  IFormValues,
  IOrder,
  ORDER_TYPES,
  ICustomer,
  ICleaningOrder,
  IPhoneValue,
  // Customizable Area End
} from "./OrderCreationController.web";

export default class OrderCreation extends OrderCreationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start


  renderSubProducts = () => {
    return (
      <DialogContent>
        <Box className="dialogProducts">
          {this?.state?.cataloguesList
            ?.find(
              (catalog: CatalogueList) =>
                catalog?.attributes?.id === (this.state.subProduct as SubProductWithId)?.catalogueId
            )
            ?.attributes?.sub_products?.data?.map((catalogue: {
              id: string,
              attributes: { id: number, product_type_id: string }
            }) => {
              const catalogueId = catalogue.attributes.id;
              const selectedProductData = this.state.cartProducts.find(
                (product: ICartProduct) =>
                  product.catalogue_id === catalogueId &&
                  product.tabsId === this.state.tabsId
              );
              const selectedServiceId =
                selectedProductData?.serviceId || undefined;
              const selectedProductQuantity =
                selectedProductData?.quantity || 0;
              return (
                <ProductCard
                  data-test-id='product-card-component'
                  tabsId={this.state.tabsId}
                  key={catalogue.id}
                  catalogue={catalogue}
                  productType={catalogue?.attributes?.product_type_id}
                  addQuantityClickHandler={this.addQuantityClickHandler}
                  removeQuantityClickHandler={this.removeQuantityClickHandler}
                  serviceClickHandler={this.serviceClickHandler}
                  selectedServiceId={selectedServiceId}
                  selectedProductQuantity={selectedProductQuantity}
                  parentProductId={(this.state.subProduct as SubProductWithId)?.catalogueId}
                  isQuickDrop={this.state.isQuickDrop}
                />
              );
            })}
        </Box>
      </DialogContent>
    );
  };

  renderProductsList = () => {
    const { cataloguesListMeta } = this.state;

    return (
      <CatalogProductGrid>
        <Box className="productsListContainer">
          {this.state.cataloguesList?.map((catalogue: CatalogueList) => {
            const catalogueId = catalogue?.attributes?.id;
            const selectedProductData = this.state.cartProducts.find(
              (product: ICartProduct) =>
                product.catalogue_id === catalogueId &&
                product.tabsId === this.state.tabsId
            );
            const selectedServiceId =
              selectedProductData?.serviceId || undefined;
            let selectedProductQuantity = 0;
            if (catalogue?.attributes?.product_type_id === productType.PARENT) {
              selectedProductQuantity =
                this.state.cartProducts
                  ?.filter(
                    (product: ICartProduct) =>
                      product?.parentProductId === catalogueId &&
                      product.tabsId === this.state.tabsId
                  )
                  ?.reduce((accu: number, product: { quantity: number }) => {
                    return accu + product?.quantity;
                  }, 0) || 0;
            } else {
              selectedProductQuantity = selectedProductData?.quantity || 0;
            }
            return (
              <div data-test-id='product-card-component'>
                <ProductCard
                  tabsId={this.state.tabsId}
                  key={catalogue.id}
                  catalogue={catalogue}
                  productType={catalogue?.attributes?.product_type_id}
                  addQuantityClickHandler={this.addQuantityClickHandler}
                  removeQuantityClickHandler={this.removeQuantityClickHandler}
                  serviceClickHandler={this.serviceClickHandler}
                  selectedServiceId={selectedServiceId}
                  selectedProductQuantity={selectedProductQuantity}
                  isQuickDrop={this.state.isQuickDrop}
                />
              </div>
            );
          })}
          <div
            data-test-id='add-product-card'
            onClick={() => this.handleAddProductClick()}
            className="addProductBtnContainer"
          >
            <div className="addProductBtnPlusContainer">
              <div className="addProductBtnPlus">+</div>
            </div>
            <div className="addProductBtnText">{configJSON.addProduct}</div>
          </div>
          {this.state.cataloguesList?.length < 1 && configJSON.noProductAvail}
        </Box>
        {cataloguesListMeta && cataloguesListMeta.total_pages > 1 && (
          <PaginationContainer>
            <TablePagination
              data-test-id='table-pagination'
              rowsPerPageOptions={[10]}
              component='div'
              rowsPerPage={10}
              count={cataloguesListMeta.total_count}
              page={this.state.currentPage - 1}
              onPageChange={(_event: React.MouseEvent<HTMLElement> | null, page: number) => this.handlePageChange(page)}
            />
          </PaginationContainer>
        )}
      </CatalogProductGrid>
    );
  };

  renderCollapse = (orderAttributes: IOrder) => {
    const Strings = configJSON.Strings;

    return (
      <Collapse
        in={this.state.cartTotalOpen}
        timeout='auto'
        unmountOnExit
        className="cartTotalListWrapper"
      >
        <Box className="cartTotalList">
          <Typography className="ftWeight400">{Strings.total_items}</Typography>
          <Typography className="cartTotalListPrice">
            {this.state.isQuickDrop
              ? this.state.quickDropCount
              : orderAttributes.order_items?.length || 0}
          </Typography>
        </Box>
        <Box className="cartTotalList">
          <Typography className="ftWeight400">{configJSON.subtotal}</Typography>
          <Typography className="cartTotalListPrice">
            {`SAR ${orderAttributes.sub_total}`}
          </Typography>
        </Box>
        <Box className="cartTotalList">
          <Typography
            className="ftWeight400"
          >{`${Strings.tax} (${this.formatTaxPercentage()})`}</Typography>
          <Typography className="cartTotalListPrice">
            {`SAR ${orderAttributes.total_tax}`}
          </Typography>
        </Box>
        <Divider style={{ backgroundColor: "#ECECEC", marginBottom: 16 }} />
      </Collapse>
    );
  };

  getPayButtonText() {
    const { isQuickDrop, quickDropCount, quickDropReceivedCount } = this.state;
    const condition = isQuickDrop && quickDropCount !== quickDropReceivedCount;
    let text = this.state.isQuickDrop ? "Submit" : "Pay";
    if (condition) text = "Loading";
    return (
      <>
        {text}{condition && <>&nbsp;&nbsp;<StyledProgress size={16} /></>}
      </>
    );
  }


  renderCartForm = (customerPhone: string, _uniqueCombinations: Array<{ serviceName: string; tabsId: number }>) => {
    return (
      <>
        <Formik
          data-test-id='checkMobileFormik'
          initialValues={{
            full_phone_number: customerPhone ? customerPhone : "",
          }}
          validationSchema={Yup.object({
            full_phone_number: Yup.string()
              .required("Please Enter Mobile Number.")
              .nullable(),
          })}
          onSubmit={(values, parameter) => {
            this.handleCheckMobileNumber(
              values?.full_phone_number,
              parameter?.resetForm
            );
          }}
          enableReinitialize
        >
          {({
            errors,
            touched,
            handleSubmit,
            handleBlur,
            setFieldValue,
          }) => (
            <form
              onSubmit={handleSubmit}
              data-test-id='addMobileNumberForm'
              className="mb-24"
            >
              <Box className="mobileBlock">
                <Typography className="backInner">
                  <InputField
                    inputProps={{
                      "data-test-id": "countryCodeInput",
                    }}
                    value={this.state.countryCodeInput}
                    className="countryInput"
                    variant='outlined'
                    onChange={(event: React.ChangeEvent<{ value: string }>) => this.handleCountyCodeInputChange(event)}
                  />
                  <SimpleCustomAutocomplete
                    className="w-full-80 phone-number"
                    id='full_phone_number'
                    name='full_phone_number'
                    data-test-id='full_phone_number'
                    noOptionsText='No customers found'
                    handleBlur={handleBlur}
                    clearOnBlur={false}
                    value={
                      this.state.clearPhoneNumber
                        ? undefined
                        : this.state.customersList.find(
                          (custList: { id: string | number | null }) => custList.id == this.state.customerId
                        ) || ""
                    }
                    onInputChange={(_event: React.MouseEvent<HTMLElement> | null, newInputValue: string) =>
                      this.handleCustomerMobileInputChange(
                        newInputValue,
                        newInputValue
                      )
                      // this.debouncedFunction(newInputValue)
                    }
                    onChange={(_event: React.MouseEvent<HTMLElement> | null, value: IPhoneValue) => this.handleAutoPhoneChange(value, setFieldValue)}
                    options={this.state.customersList || []}
                    filterOptions={() => this.state.customersList}
                    error={this.checkError(
                      errors.full_phone_number || this.state.mobileNumberError,
                      touched.full_phone_number
                    )}
                    getOptionLabel={(option: { option: string }) => {
                      return this.state.customersList.length && option.option
                        ? option.option
                        : "";
                    }}
                    renderOption={(props: { id: string; option: string }) => (
                      <StyledOption
                        component={"li"}
                        {...props}
                      >
                        <FormControlLabel
                          control={
                            <Radio
                              checked={this.state.customerId == props.id}
                            />
                          }
                          label={<StyleLable>{props.option}</StyleLable>}
                        />
                      </StyledOption>
                    )}
                    renderInput={(params: object) => (
                      <>
                        <TextField
                          {...params}
                          fullWidth={true}
                          placeholder='Mobile Number'
                          variant='outlined'
                        />
                      </>
                    )}
                  />
                </Typography>
                <Box className="buttonWrapper">
                  <Button className="customerButton">
                    <img
                      src={customerAddIcon}
                      className="cursor-pointer"
                      data-test-id='addCustomerButton'
                      onClick={() => {
                        this.handleAddCustomerPopupOpen(this.mobileNumberInput);
                      }}
                      alt='customer add icon'
                    />
                  </Button>
                  <Button
                    type='button'
                    data-test-id='viewCustomerButton'
                    className="customerButton"
                    onClick={() => this.handleViewCustomerPopupOpen()}
                  >
                    <img
                      src={customerViewIcon}
                      className="cursor-pointer"
                      alt='customer view icon'
                    />
                  </Button>
                </Box>
              </Box>
            </form>
          )}
        </Formik>
      </>
    );
  };

  getOrderSummaryForCleaningOrder() {
    const { orderSummaryVisible, subtotalOrder, previousCustomer } = this.state
    if (!orderSummaryVisible || !subtotalOrder) return <></>

    const Strings = configJSON.Strings;

    const customer = previousCustomer as ICustomer
    const order = subtotalOrder.attributes;
    const phone = "+" + customer.attributes.country_code + " " + customer.attributes.phone_number;
    const name = customer.attributes.full_name;

    return (
      <OrderSummaryContainer>
        <div className="button-container">
          <div className="header">
            {Strings.orderHas}{order.order_number}
          </div>
          <img
            className="close-icon"
            data-test-id='btn-closeOrderSummary'
            onClick={() => this.handleClearOrder()}
            src={CrossIcon}
          />
        </div>

        {

          [
            { label: Strings.name, value: name },
            { label: Strings.contact, value: phone },
            { label: Strings.no_of_items, value: order.no_of_items },
            { label: Strings.total, value: order.total },
            { label: Strings.payment, value: this.getPaymentStringJoin() },
          ].map(({ label, value }) => (
            <div className="row">
              <div className="label">{label}</div>
              <div className="dot">:</div>
              <div>{value}</div>
            </div>
          ))}

        <div className="button-container">
          <Button
            className="button"
            data-test-id='btn-newOrder'
            onClick={() => this.onSendAction(Actions.SUMMARY_NEW_ORDER)}
          >
            {Strings.newOrder}
          </Button>
        </div>
      </OrderSummaryContainer>
    )
  }

  renderTotalAndQuickDrop = (order: { attributes?: IOrder } | undefined, userData: {
    attributes: {
      employee_proifle: {
        data: {
          attributes: {
            store_management: {
              attributes: {
                payment_method_screen: string
              }
            }
          }
        }
      }
    }
  }) => {
    return (
      <>
        {this.state.orderSummaryVisible && (
          <OrderSummary
            data-test-id='orderSummary'
            order={this.state.subtotalOrder?.attributes as IOrder}
            customer={this.state.customerData}
            onNewOrder={() => this.handleOrderSummaryNewOrder()}
            onClose={() => this.handleClearOrder()}
            isQuickDrop={this.state.isQuickDrop}
          />
        )}
        <Box className="cartTotalWrapper">
          {this.state.order?.attributes ? this.renderCollapse(this.state.order.attributes) : null}
          <Box
            data-test-id='cartTotalInfo'
            className="cartSubtotalWrapper"
            onClick={this.toggleCartTotalInfoOpen.bind(this)}
          >
            <Typography className="ftWeight600">{configJSON.total}</Typography>
            <Typography className="cartSubtotalPrice">
              {order?.attributes?.total !== undefined && order.attributes.total !== null ? `SAR ${order.attributes.total}` : ""}
            </Typography>
            <Box>
              {!this.state.cartTotalOpen ? <ExpandLess /> : <ExpandMore />}
            </Box>
          </Box>
        </Box>
        {!userData?.attributes?.employee_proifle?.data?.attributes
          ?.store_management?.attributes?.payment_method_screen && (
            <Box className="cartPaymentOption">
              <DropdownSelect
                titleSelect='Payment Option'
                hideTitle={true}
                selectOptions={this.state.paymentOptions?.map((option: IPaymentOption) => ({
                  text: option.name,
                  value: String(option.id),
                }))}
                required={true}
                options={{
                  value: this.state.paymentMethod,
                  onChange: (value: string) => this.handlePayOptionChange(value),
                  placeHolder: "Payment Option",
                }}
              />
            </Box>
          )}
        {!this.state.editId && (
          <QuickDrop
            disabled={
              this.isCartProductsValid(this.state.cartProducts) ||
              this.state.orderSummaryVisible ||
              !this.state.customerId
            }
            count={this.state.quickDropCount}
            onCountChange={this.handleQuickDropChange}
            order={this.state.order}
          />
        )}
        <MainButton
          disabled={this.getPayButtonEnable()}
          data-test-id='btnPay'
          onClick={() => this.handlePayButton()}
          className="fullWidth mt-24"
        >
          {this.getPayButtonText()}
        </MainButton>
      </>
    );
  };

  renderSelectDatePicker = () => {
    const Strings = configJSON.Strings;
    return (
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Box className="dateSelectWrapper">
          <Box>
            <label className="selectDataLabel">{Strings.pickup_date}</label>
            <div className='customerBirthdate'>
              <StyledCalenderIcon src={calenderIcon} alt='calender-icon' />
              <StyledDatePicker
                data-test-id='order-pickup-date'
                value={this.state.pickupDate}
                placeholder='Select Date'
                onChange={(dateChange: Date) => this.handleDateChange(dateChange)}
                format='DD/MM/YYYY'
                className={false ? "birthdate-picker-errors" : "birthdate-picker"}
              />
            </div>
          </Box>
          <Box>
            <label className="selectDataLabel">{Strings.delivery_date}</label>
            <div className='customerBirthdate'>
              <StyledCalenderIcon src={calenderIcon} alt='calender-icon' />
              <StyledDatePicker
                data-test-id='order-delivery-date'
                value={this.state.pickupDate}
                placeholder='Select Date'
                onChange={(dateChange: Date) => this.handleDateChange(dateChange)}
                format='DD/MM/YYYY'
                className={false ? "birthdate-picker-errors" : "birthdate-picker"}
              />
            </div>
          </Box>
        </Box>
      </MuiPickersUtilsProvider>
    )
  }

  renderCart = (
    customerPhone: string,
    uniqueCombinations: Array<{ serviceName: string; tabsId: number }>,
    order: {
      attributes?: IOrder
    } | undefined,
    userData: {
      attributes: {
        employee_proifle: {
          data: {
            attributes: {
              store_management: {
                attributes: {
                  payment_method_screen: string
                }
              }
            }
          }
        }
      }
    }
  ) => {
    return (
      <CatalogCartGrid>
        <Box className="sideCart">
          {this.renderCartForm(customerPhone, uniqueCombinations)}
          <Box className="cartItemContainer">
            {!this.isCartProductsValid(this.state.cartProducts) ? (
              <div className="emptyCartWrapper">
                <img src={emptyCartIcon} className="emptyCartIcon"></img>
                <div className="cartProductDetailPrice">
                  {configJSON.cartEmptyStr}
                </div>
              </div>
            ) : (
              <>
                <div className="cartHeaderContainer">
                  <div className="font-600">{configJSON.orderDetails}</div>
                  {this.checkCartProductsInTemporary() && (
                    <div
                      data-test-id={"navigate-to-edit-btn"}
                      className="editAllBtn"
                      onClick={this.handleEditNavigate}
                    >
                      {configJSON.editPreference}
                    </div>
                  )}
                </div>
                {this.isCartProductsValid(this.state.cartProducts) && (
                  <div className="clearAllBtnContainer">
                    <div
                      className="clearAllBtn"
                      onClick={this.handleClearAllCart}
                    >
                      {configJSON.clearAll}
                    </div>
                  </div>
                )}
              </>
            )}
            <div className="cartProductsContainer">
              {uniqueCombinations.map(({ serviceName, tabsId }) => {
                return (
                  <div key={`${serviceName}_${tabsId}`}>
                    <Typography className="cartServiceTitle">
                      {
                        this.state.userData?.attributes?.employee_proifle?.data?.attributes?.store_management?.attributes?.sections.find(
                          (item: { id: string }) => item.id.toString() === tabsId.toString()
                        )?.name
                      }{" "}
                      - {serviceName}
                    </Typography>
                    {this.state.cartProducts
                      ?.filter((product: ICartProduct) => product)
                      .filter(
                        (product: ICartProduct) =>
                          product.serviceName === serviceName &&
                          product.tabsId === tabsId
                      )
                      ?.map((product: ICartProduct) => {
                        return (
                          <div
                            key={product.catalogue_id + "_" + product.tabsId}
                            className="cartProductContainer"
                            onMouseEnter={() => {
                              this.setCartItemHoverId(
                                product.catalogue_id,
                                product.tabsId,
                                product.serviceName
                              );
                            }}
                            onMouseLeave={() => {
                              this.setCartItemHoverId(null, null, null);
                            }}
                            data-test-id='cartItems'
                          >
                            <Box className="cartProductDetailContainerMain">
                              <Box className="cartProductDetailContainer">
                                <Box className="mainCartDetailConatiner">
                                  <Box className="cartLeftSection">
                                    <Box className="cartProductDetailTitle">
                                      <p className="productNameTitle">{product.title}</p>
                                      <div className="greyPoint" />
                                      <p className="mr-8 productNameTitle">
                                        {`${product.productSecondName} (${product.serviceShortName})`} X {product?.quantity}
                                      </p>
                                    </Box>
                                    {
                                      this.handleRenderUpchareList(product.catalogue_id, product.catalogue_variant_id)
                                        ?.map((item) => {
                                          return (
                                            <p className="upchargeTitle">
                                              {item.attributes.name} - {"SAR"} {" "} {item.attributes.order_price}
                                            </p>
                                          )
                                        })
                                    }
                                  </Box>
                                  <Box className="cartRightSection">
                                    <p className="cartProductDetailTotal">
                                      {`SAR ${this.handleRenderTotalPrice(product.catalogue_id, product.catalogue_variant_id)}`}
                                      <img
                                        src={deleteItemIcon}
                                        className="deleteIcon"
                                        onClick={() => {
                                          this.removeItemClickHandler({
                                            catalogue_id:
                                              this.state.cartItemHoverId?.catalogue_id,
                                            tabsId: this.state.cartItemHoverId?.tabsId,
                                            serviceName:
                                              this.state.cartItemHoverId?.serviceName,
                                          });
                                        }}
                                        data-test-id='itemDelete'
                                      />
                                    </p>
                                    <Box className="cartProductQuantityContainerSecond">
                                      <IconButton
                                        disabled={product.quantity <= 1}
                                        className="cartItemQuantityIcon"
                                        onClick={() => {
                                          this.cartItemQuantityRemoveHandler({
                                            catalogue_id:
                                              this.state.cartItemHoverId
                                                ?.catalogue_id,
                                            tabsId:
                                              this.state.cartItemHoverId?.tabsId,
                                            serviceName:
                                              this.state.cartItemHoverId?.serviceName,
                                          });
                                        }}
                                        data-test-id='removeItem'
                                      >
                                        <RemoveIcon />
                                      </IconButton>
                                      <Typography
                                        className="qtyNum"
                                        data-test-id='itemQuantity'
                                      >
                                        {product?.quantity}
                                      </Typography>

                                      <IconButton
                                        className="cartItemQuantityIcon"
                                        onClick={() => {
                                          this.cartItemQuantityAddHandler({
                                            catalogue_id: this.state.cartItemHoverId
                                              ?.catalogue_id,
                                            tabsId: this.state.cartItemHoverId?.tabsId,
                                            serviceName: this.state.cartItemHoverId?.serviceName,
                                            price: "",
                                            image: "",
                                            productType: "",
                                            parentProductId: "",
                                            title: "",
                                            catalogue_variant_id: ""
                                          });
                                        }}
                                        data-test-id='AddItem'
                                      >
                                        <AddIcon />
                                      </IconButton>
                                    </Box>
                                  </Box>
                                </Box>
                              </Box>
                            </Box>

                          </div>
                        );
                      })}
                    <StyledDivider />
                  </div>
                );
              })}
            </div>
          </Box>
          {this.isCartProductsValid(this.state.cartProducts) && this.renderSelectDatePicker()}
          {this.isCartProductsValid(this.state.cartProducts) && (
            <>
              <TextareaAutosize
                data-test-id='orderNotes'
                className="cartProductNote"
                minRows={1}
                placeholder='Add Note'
                value={this.state.orderNotes}
                onChange={this.handleOrderNotesChange}
              />

              <StyledFormControlLabel
                data-test-id='saveForFuture'
                control={<Checkbox data-test-id='field-saveForFuture' />}
                label={"Save for Future"}
                checked={this.state.saveForFuture}
                onChange={this.handleSaveForFutureChange}
              />
            </>
          )}
          {this.renderTotalAndQuickDrop(order, userData)}
        </Box>
      </CatalogCartGrid>
    );
  };

  renderAfterPermissionComponent() {
    const { userData, storeList } = this.state
    const dropDownId = userData?.attributes?.employee_proifle?.data
      ?.attributes?.store_management?.store_id

    return (
      <div className="branchDropDown">
        <AutocompleteSelect
          data-test-id='branchDropdown'
          placeholder="Select branch"
          fieldName={"branch"}
          options={storeList}
          value={String(dropDownId)}
          disableClear={true}
          debouncedFunction={this.debouncedStoreSearch}
          onChange={(_event: React.ChangeEvent, value: { id: number }) => {
            this.handleStoreChange();
            this.updateEmployeeCurrentStore(value.id);
          }}
          handleScroll={this.handelStoreDropdownScroll.bind(this)}
          onBlur={() => this.debouncedStoreSearch("")}
          listBoxStyle={{maxHeight: 310}}
        />
      </div>
    )
  }

  getPlantOrderTotals() {
    const { cartTotalOpen, cleaningOrder, selectedMainTab } = this.state;
    const { no_of_items, sub_total, tax_percentage, total_tax, total, currency, service_charge, min_non_refundable_amount } = cleaningOrder || { currency: 'SAR' };

    const isHomeCleaningOrder = selectedMainTab == ORDER_TYPES.CLEANING_ORDER;
    const Strings = configJSON.Strings;

    return (
      <div>
        <PlantOrderSummaryContainer>
          <Collapse in={cartTotalOpen} timeout='auto' unmountOnExit>
            <div className='flex'>
              <div>{Strings.total_items2}</div>
              <div>{no_of_items || "0"}</div>
            </div>
            <div className='flex'>
              <div>{Strings.subtotal}</div>
              <div>{currency} {sub_total || "0.0"}</div>
            </div>
            <div className='flex'>
              <div>{Strings.tax} ({tax_percentage || "0%"})</div>
              <div>{currency} {total_tax || "0.0"}</div>
            </div>
            {isHomeCleaningOrder && <>
              <div className='flex'>
                <div>{Strings.service_charge}</div>
                <div>{currency} {service_charge || "0.0"}</div>
              </div>
              <div className='flex'>
                <div>{Strings.non_refundable_amount}</div>
                <div>{currency} {min_non_refundable_amount || "0.0"}</div>
              </div>
            </>}
            <div>
              <hr />
            </div>
          </Collapse>
          <div
            className='flex total'
            data-test-id="btn-total"
            onClick={() => this.getHasItemFilter() && this.handleCloseTotal()}
          >
            <div>{Strings.total}</div>
            {this.getHasItemFilter() && (<div>{currency} {total || "0.0"}</div>)}
            <div className="caret-icon">{cartTotalOpen ? <ExpandMore /> : <ExpandLess />}</div>
          </div>
        </PlantOrderSummaryContainer>
      </div>
    );
  }

  getPlantProductList() {
    const { plantProducts, cataloguesListMeta } = this.state;
    const hasProduct = plantProducts?.length > 0;
    const Strings = configJSON.Strings;

    return (
      <div className='grow'>
        {!hasProduct && <span>{Strings.no_products_are_available}</span>}
        {hasProduct && (
          <ProductListContainer>
            {plantProducts.map((item: IPlantProduct) => this.getPlantProductItem(item))}
          </ProductListContainer>
        )}
        {cataloguesListMeta?.total_pages > 1 && hasProduct && (
          <PaginationContainer>
            <TablePagination
              data-test-id='table-pagination'
              rowsPerPageOptions={[10]}
              component='div'
              rowsPerPage={10}
              page={this.state.currentPage - 1}
              count={cataloguesListMeta?.total_count}
              onPageChange={(_event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, page: number) =>
                this.getProductList(page + 1)
              }
            />
          </PaginationContainer>
        )}
      </div>
    );
  }

  getPlantProductItem(item: IPlantProduct) {
    const isUnActive = String(item.attributes.active) == "false"
    if (isUnActive) return <></>

    return (
      <ProductItemContainer data-test-id={'product-item-' + item.id} onClick={() => this.onSendAction(Actions.OPEN_ADD_QUANTITY_POPUP, item)}>
        <div className="image-container">
          <img src={item.attributes.image} />
        </div>
        <div className='heading'>{item.attributes.name}</div>
      </ProductItemContainer>
    );
  }

  getPlantProductCart() {
    const { userData, cleaningOrder } = this.state
    const hasItems = (cleaningOrder as ICleaningOrder)?.order_items?.length > 0 || false
    const hasPaymentPopup = userData?.attributes?.employee_proifle?.data?.attributes
      ?.store_management?.attributes?.payment_method_screen;

    const Strings = configJSON.Strings;

    return (
      <PlantCartWrapper>
        <ProductCartContainer>
          {this.getHomeCleaningCustomerForm()}
          {this.getPlantInCartOrderDetails()}
          {hasItems && (
            <TextareaAutosize
              minRows={2}
              data-test-id='field-note'
              className='cartProductNote'
              placeholder={Strings.add_note}
              onChange={this.handleOrderNotesChange.bind(this)}
            />
          )}
          {this.getOrderSummaryForCleaningOrder()}
          {this.getPlantOrderTotals()}
          {!hasPaymentPopup && (
            <StyledSelectPaymentMethod
              fullWidth
              displayEmpty
              variant='outlined'
              MenuProps={CustomMenuProps}
            >
              <MenuItem>{Strings.payment_method}</MenuItem>
            </StyledSelectPaymentMethod>
          )}
          <MainButton data-test-id='btn-pay' disabled={!hasItems} onClick={() => this.handlePayButton()} className='fullWidth'>
            {Strings.pay}
          </MainButton>
        </ProductCartContainer>
      </PlantCartWrapper>
    );
  }

  getHomeCleaningCustomerForm() {
    const { countryCodeInput, customerPhone, customersList } = this.state
    const Strings = configJSON.Strings
    return (
      <PlantOrderCustomerFormContainer>
        <InputField
          variant='outlined'
          value={countryCodeInput}
          className='countryInputDigit'
          inputProps={{
            'data-test-id': 'field-countryCode'
          }}
          onChange={(event: React.ChangeEvent<{ value: string }>) => this.onSendAction(Actions.CHANGE_COUNTRY_CODE, event.target.value as unknown)}
        />
        <div>
          <StyledAutoComplete
            freeSolo
            data-test-id='field-mobileNo'
            value={customerPhone}
            options={customersList.map((item: { option: string }) => item.option)}
            onChange={(_event, value) => !value && this.onSendAction(Actions.CLEAR_CUSTOMER)}
            onInputChange={(_event, value: string) => this.onSendAction(Actions.CHANGE_CUSTOMER_PHONE, value as unknown)}
            renderOption={(option) => {
              return (
                <StyledOption
                  component={"li"}
                >
                  <FormControlLabel
                    control={
                      <Radio
                        checked={customerPhone == option}
                      />
                    }
                    label={<StyleLable>{option as string}</StyleLable>}
                  />
                </StyledOption>
              )
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                variant="outlined"
                placeholder={Strings.mobile_number}
                InputProps={{
                  ...params.InputProps,
                  type: 'search',
                }}
              />
            )}
          />
          <Button className='customerButton'>
            <img
              src={customerAddIcon}
              className='cursor-pointer'
              alt='customer add icon'
              data-test-id="btn-addCustomer"
              onClick={() => this.handleAddCustomerPopupOpen((parseInt(customerPhone) || "").toString())}
            />
          </Button>
          <Button className='customerButton'>
            <img
              src={customerViewIcon}
              className='cursor-pointer'
              alt='customer view icon'
              data-test-id="btn-viewCustomer"
              onClick={() => this.handleViewCustomerPopupOpen()}
            />
          </Button>
        </div>
      </PlantOrderCustomerFormContainer>
    );
  }

  getPlantInCartOrderDetails() {
    const { cleaningOrder } = this.state
    const order_items = cleaningOrder?.order_items || [];
    const currency = cleaningOrder?.currency || '';

    const Strings = configJSON.Strings;

    return <PlantOrderItemContainer>
      {
        !this.getUniqueProductTypes().length ? (
          <div className="emptyCart">
            <div>
              <img src={emptyCartIcon} className="emptyCartIcon" />
              <div className="cartProductDetailPrice">
                {configJSON.cartEmptyStr}
              </div>
            </div>
          </div>
        ) : (
          <>
            <div className="flex">
              <div className="title">{Strings.order_details}</div>
              <div className="link" data-test-id='btn-clearAll' onClick={() => this.handleClearAll()}>{Strings.clearAll}</div>
            </div>
            <div className="cart-item">
              {this.getUniqueProductTypes().map((uniqueId: number) =>
                <div data-test-id={'cart-item-' + uniqueId}>
                  <div className="product-name">{order_items.find((item: ICleaningOrderItem) => item.attributes.home_cleaning_catalogue_id == uniqueId)?.attributes?.catalogue?.name}</div>
                  {order_items
                    .filter((item: ICleaningOrderItem) => item.attributes.home_cleaning_catalogue_id == uniqueId)
                    .map((item: ICleaningOrderItem) => {
                      const { product_type, quantity, unit_price, id } = item.attributes
                      const totalPrice = Number(quantity) * Number(unit_price);
                      const isTotalPriceNaN = isNaN(totalPrice);
                      return (
                        <div className="detail-container" data-test-id={'cart-item-' + uniqueId + '-' + id}>
                          <div className="details">{product_type?.name}</div>
                          <div className="right-section">
                            <div className="pcs">
                              <div data-test-id='btn-remove' onClick={() => this.onSendAction(Actions.REMOVE_QUANTITY, item)}>
                                <IconButton
                                  className="cartItemQuantityIcon"
                                >
                                  <RemoveIcon />
                                </IconButton>
                              </div>
                              <div data-test-id='text-quantity'>
                                {quantity}
                              </div>
                              <div onClick={() => this.onSendAction(Actions.ADD_QUANTITY, item)} data-test-id='btn-add'>
                                <IconButton
                                  className="cartItemQuantityIcon"
                                >
                                  <AddIcon />
                                </IconButton>
                              </div>
                            </div>
                            <div className="price-box">
                              <div className="price">{currency} {isTotalPriceNaN ? '-' : totalPrice.toFixed(2)}</div>
                              <div className="delete" data-test-id='btn-delete'>
                                <img
                                  src={deleteItemIcon}
                                  className="deleteIcon"
                                  data-test-id='itemDelete'
                                  onClick={() => this.handleClearAll(item.id)}
                                />
                                <span>{Strings.remove}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                    })}
                </div>
              )}
            </div>
          </>
        )
      }
    </PlantOrderItemContainer>;
  }

  renderPlantHomeCleaningProductPopup(hasItems: boolean, initValuesFromItems: { items: ICleaningOrderItem["attributes"][] }, initialValues: { items: IFormValues[] }) {
    const { productPopup, productPopupData, cleaningOrder } = this.state;
    const { order_items, currency } = cleaningOrder || { order_items: [] };
    const { name, home_cleaning_catalogue_types, measurement_type } = (productPopupData as IPlantProduct)?.attributes || {};

    const Strings = configJSON.Strings

    return (
      <StyledDialog open={productPopup} maxWidth={false}>
        <DialogContent>
          <div className="dialog-title">{name}</div>
          {
            productPopupData && (
              <Formik
                enableReinitialize
                key={'formik-add-popup-form'}
                validationSchema={validationSchemaForAddProduct}
                initialValues={{
                  ...JSON.parse(JSON.stringify(this.getConditionBasedUI(hasItems, initValuesFromItems, initialValues))),
                  weightError: '',
                  sizeWeightError: '',
                }}
                onSubmit={(data: { items: IFormValues[] }) =>
                  this.handleSubmitAddProductForm(data.items)
                }
              >
                {({
                  values,
                  errors,
                  handleSubmit,
                  validateForm,
                  getFieldHelpers,
                }: FormikProps<{ items: IFormValues[] }>) => {
                  const { setValue } = getFieldHelpers("items");
                  return (
                    <form id='productForm'>
                      {values.items.map((item: IFormValues, index: number) => {
                        const mainItemId = item.home_cleaning_catalogue_id
                        const error = (errors?.items?.[index] || {}) as FormikErrors<IFormValues>;
                        const recordId = item.id as number;

                        const hasSqm = measurement_type.includes('SQM')
                        const hasWeight = measurement_type.includes('Weight')
                        const hasSqmAndWeightBoth = hasSqm && hasWeight;

                        const area = Number(Number(item.width) * Number(item.height)).toFixed(2);
                        const isAreaNaN = isNaN(Number(area));
                        const unitPrice = home_cleaning_catalogue_types?.find((type) => type.data.id == item.home_cleaning_catalogue_type_id)?.data?.attributes?.price;

                        let valueToMultiply = 1;
                        if (hasSqmAndWeightBoth || hasSqm) {
                          valueToMultiply = Number(area)
                        } else if (hasWeight) {
                          valueToMultiply = Number(item.weight)
                        }
                        const totalPrice = Number(item.quantity) * Number(unitPrice) * Number(valueToMultiply);
                        const isPriceNaN = isNaN(totalPrice);

                        return (
                          <PlantOrderItemContainer key={'product-popup-id' + item.id} className="dialog">
                            <div className="dialog-container">
                              <div className="grid col-2">
                                <div>
                                  <TextLabel>{Strings.type}</TextLabel>
                                  <Select
                                    variant="outlined"
                                    fullWidth
                                    displayEmpty
                                    name='type'
                                    data-test-id="field-type"
                                    MenuProps={CustomMenuProps}
                                    value={item.home_cleaning_catalogue_type_id || ''}
                                    onChange={(event: React.ChangeEvent<{ name?: string; value: unknown }>) =>
                                      this.handleChangeAddProductItem(recordId, mainItemId as string, 'home_cleaning_catalogue_type_id', event.target.value, values.items, setValue)
                                    }
                                  >
                                    <MenuItem style={{ display: 'none' }} value={''} >{Strings.select_type}</MenuItem>
                                    {home_cleaning_catalogue_types.map((item: ICatalogueTypes) =>
                                      <MenuItem value={item.data.attributes.id}>{item.data.attributes.name}</MenuItem>
                                    )}
                                  </Select>
                                  <ErrorLabel>{error.home_cleaning_catalogue_type_id}</ErrorLabel>
                                </div>
                                <div className="hide-768" />
                              </div>
                              {this.getConditionBasedUI(hasSqm, (
                                <div>
                                  <TextLabel>{Strings.size}</TextLabel>
                                  <div className="border-box">
                                    <div className="grid gap-2 col-2">
                                      <div>
                                        <TextLabel>{Strings['length']}</TextLabel>
                                        <StyledTextField variant="outlined" placeholder="Type Here" InputProps={{
                                          endAdornment: <span className="suffix">m</span>
                                        }}
                                          inputProps={{ "data-test-id": 'field-height' }}
                                          value={item.height}
                                          onChange={(event: React.ChangeEvent<{ value: string }>) => this.handleChangeAddProductItem(recordId, mainItemId as string, 'height', event.target.value, values.items, setValue)} />
                                        <ErrorLabel>{error.height}</ErrorLabel>
                                      </div>

                                      <div>
                                        <TextLabel>{Strings.width}</TextLabel>
                                        <StyledTextField value={item.width} variant="outlined" placeholder="Type Here" InputProps={{
                                          endAdornment: <span className="suffix">m</span>
                                        }}
                                          inputProps={{ "data-test-id": 'field-width' }}
                                          onChange={(event: React.ChangeEvent<{ value: string }>) => this.handleChangeAddProductItem(recordId, mainItemId as string, 'width', event.target.value, values.items, setValue)} />
                                        <ErrorLabel>{error.width}</ErrorLabel>
                                      </div>
                                    </div>
                                    <div className="pt-32">
                                      {Strings.area_of_surface}{this.getConditionBasedUI(isAreaNaN, "__sqm", area + ' sqm')}
                                    </div>
                                  </div>
                                  <ErrorLabel>{error.size}</ErrorLabel>
                                  <ErrorLabel>{error.sizeWeightError}</ErrorLabel>
                                </div>
                              ), <></>)
                              }
                              {this.getConditionBasedUI(hasWeight, (
                                <div className="grid col-2">
                                  <div>
                                    <TextLabel>{Strings.weight}</TextLabel>
                                    <StyledTextField value={item.weight} variant="outlined" placeholder="Type Here" InputProps={{
                                      endAdornment: <span className="suffix">Kg</span>
                                    }}
                                      inputProps={{ "data-test-id": 'field-weight' }}
                                      onChange={(event: React.ChangeEvent<{ value: string }>) => this.handleChangeAddProductItem(recordId, mainItemId as string, 'weight', event.target.value, values.items, setValue)} />
                                    <ErrorLabel>{error.weight}</ErrorLabel>
                                    <ErrorLabel>{error.weightError}</ErrorLabel>
                                    <ErrorLabel>{error.sizeWeightError}</ErrorLabel>
                                  </div>
                                  <div className="hide-768" />
                                </div>
                              ), <></>)
                              }
                              <div>
                                <div className="flex space-between align-center">
                                  <TextLabel>{Strings.quantity}</TextLabel>
                                  <div className="flex gap-2 align-center">
                                    <img src={MinusIcon}
                                      style={{ opacity: this.getConditionBasedUI(item.quantity > 0, 1, 0.5) as number }}
                                      onClick={() => this.handleChangeAddProductItem(recordId, mainItemId as string, 'quantity', this.getGraterZeroValue(Number(item.quantity - 1)), values.items, setValue)} data-test-id='btn-minus' />
                                    <div className='count'>{item.quantity}</div>
                                    <img src={PlusIcon} onClick={() => this.handleChangeAddProductItem(recordId, mainItemId as string, 'quantity', Number(item.quantity) + 1, values.items, setValue)} data-test-id='btn-plus' />
                                  </div>
                                </div>
                                <ErrorLabel>{error.quantity}</ErrorLabel>
                              </div>
                              <div>
                                <StyledTextArea
                                  minRows={2}
                                  value={item.notes}
                                  data-test-id='field-notes'
                                  placeholder={Strings.add_note_optional}
                                  onChange={(event: React.ChangeEvent<{ value: string }>) => this.handleChangeAddProductItem(recordId, mainItemId as string, 'notes', event.target.value, values.items, setValue)}
                                />
                                <ErrorLabel>{error.notes}</ErrorLabel>
                              </div>
                              <div className="flex space-between">
                                <TextLabel>{Strings.price}</TextLabel>
                                <TextLabel>{currency} {this.getConditionBasedUI(isPriceNaN, "0.0", totalPrice.toFixed(2))}</TextLabel>
                              </div>
                            </div>
                          </PlantOrderItemContainer>
                        )
                      })}
                      <div className="link-button" data-test-id='btn-addCarpet' onClick={() => {
                        setValue([
                          ...values.items,
                          JSON.parse(JSON.stringify({
                            ...initialValues.items[0],
                            id: new Date().getTime(),
                            isNew: true
                          }))
                        ])
                        setTimeout(() => validateForm(), 500);
                      }
                      }>
                        <img src={productAddIcon} />
                        <div>{Strings.add_another_carpet + name}</div>
                      </div>
                      <div className="button-container">
                        <MainButton
                          data-test-id='btn-cancel'
                          className="buttonSecondary"
                          onClick={() => this.onCloseProductPopup()}
                        >
                          {configJSON.cancel}
                        </MainButton>
                        <MainButton
                          data-test-id='btn-addToBag'
                          onClick={() => handleSubmit()}
                        >
                          {Strings.add_to_bag}
                        </MainButton>
                      </div>
                    </form>
                  )
                }}
              </Formik>
            )
          }
        </DialogContent>
      </StyledDialog>
    )
  }

  getMainOrderTypeAppBar() {
    const { selectedMainTab } = this.state
    return (
      <StyledMainAppBar position='static' data-test-id='main-order-type-tabs'>
        <Tabs
          variant='scrollable'
          scrollButtons='on'
          value={selectedMainTab}
          onChange={(_event: React.ChangeEvent<{}>, value: string) =>
            this.onSendAction(Actions.CHANGE_MAIN_TAB, value)
          }
        >
          {this.getOrderTypeList().map(({ label, value }) =>
            <Tab label={label} value={value} key={"order-type-item-" + value} />
          )}
        </Tabs>
      </StyledMainAppBar>
    );
  }


  getPaymentModal() {
    const {
      order,
      customerId,
      isQuickDrop,
      cleaningOrder,
      quickDropCount,
      paymentClicked,
      selectedMainTab,
      paymentModalVisible,
    } = this.state

    const isStoreOrder = selectedMainTab == ORDER_TYPES.STORE_ORDER
    let totalItems = 0

    if (!isStoreOrder) {
      totalItems = cleaningOrder?.no_of_items || 0
    } else if (isQuickDrop) {
      totalItems = quickDropCount
    } else {
      totalItems = order?.attributes.order_items.length || 0
    }

    return (
      <PaymentModal
        totalItems={totalItems}
        open={paymentModalVisible}
        data-test-id='paymentmodal'
        cleaningOrder={!isStoreOrder}
        paymentClicked={paymentClicked}
        customerId={customerId as string}
        onPaymentDone={this.handlePaymentDone}
        handleClose={this.handlePaymentModalClose}
        taxPercentage={this.formatTaxPercentage()}
        order={cleaningOrder as unknown as IOrder || order?.attributes}
        onPromoCodeApply={() => !isStoreOrder ? this.getCleaningOrder() : this.getOrder()}
      />
    )
  }

  // Customizable Area End

  render() {
    // Customizable Area Start
    const store_management =
      this.state?.userData?.attributes?.employee_proifle?.data?.attributes
        ?.store_management?.attributes;
    const priceId = store_management?.price_list_id;

    const { userData, customerPhone, productPopupData } = this.state;
    // Customizable Area End
    return (
      // Customizable Area Start
      <MainWrapper>
        <ThemeProvider theme={theme}>
          <Loader loading={false} />

          <PageContainer
            data-test-id='pageContainer'
            navigation={this.props.navigation}
            onSearchText={(value) => { this.handleSearch(value) }}
            onUserChange={(userContext: IUserContext) => this.handleOnUserChange(userContext)}
            afterPermissionComponent={
              this.renderAfterPermissionComponent()
            }
          >
            <Box className='productsPage'>
              {this.getMainOrderTypeAppBar()}
              {this.getOrderRenderBasedOnOrderType(
                <ProductContainer>
                  {this.getPlantProductList()}
                  {this.getPlantProductCart()}
                </ProductContainer>
                ,
                <>
                  <StyledAppBar position='static'>
                    <Tabs
                      value={this.state.tabsId}
                      scrollButtons='on'
                      variant='scrollable'
                      onChange={this.tabSectionChangeHandler}
                    >
                      {this.state.userData?.attributes?.employee_proifle?.data?.attributes?.store_management?.attributes?.sections?.map(
                        (section: { id: string; name: number }) => {
                          return (
                            <Tab
                              label={section?.name}
                              value={section?.id}
                              key={section?.id}
                            />
                          );
                        }
                      )}
                    </Tabs>
                  </StyledAppBar>

                  <CatalogContainer>
                    {this.renderProductsList()}

                    {this.renderCart(
                      customerPhone,
                      this.getUniqueCombinationsSet(),
                      this.state.order,
                      userData
                    )}
                  </CatalogContainer>
                </>
              )}
            </Box>
          </PageContainer>

          {this.renderPlantHomeCleaningProductPopup(this.getOrderItemList().length > 0,
            {
              items: this.getOrderInitialValues()
            },
            {
              items: [{
                id: this.getTimeValue(),
                size: "",
                width: "",
                notes: "",
                height: "",
                weight: "",
                quantity: 1,
                isNew: true,
                home_cleaning_catalogue_id: productPopupData?.attributes?.id,
                measurement_type: productPopupData?.attributes?.measurement_type || [],
                home_cleaning_catalogue_type_id: "",
              }]
            })}

          <Dialog
            disablePortal
            open={this.state.subProduct?.open}
            maxWidth={false}
          >
            <>
              <DialogTitle className="dialogTitle">
                {configJSON.selectSubproduct}
              </DialogTitle>
              {this.renderSubProducts()}
              <DialogActions className="dialogActionWrapper">
                <MainButton
                  data-test-id='btnCancel'
                  onClick={this.subProductPopupCancelHandler}
                  className="buttonSecondary"
                >
                  {configJSON.cancel}
                </MainButton>
                <MainButton
                  data-test-id='btnNext'
                  onClick={this.subProductPopupNextHandler}
                >
                  {configJSON.next}
                </MainButton>
              </DialogActions>
            </>
          </Dialog>

          {this.state.addCustomerPopup && (
            <AddCustomerModal
              id={"AddCustomerModal"}
              data-test-id='addCustomerModal'
              navigation={this.props.navigation}
              open={this.state.addCustomerPopup}
              handleClose={() => {
                this.handleAddCustomerPopupClose();
              }}
              handleGetCustomerPhone={(phone: string) => {
                const isStoreOrder = this.state.selectedMainTab == ORDER_TYPES.STORE_ORDER;
                isStoreOrder ? this.handleGetCustomerPhone(phone) : this.onSetCustomerFromPopup(phone as unknown);
              }}
              customerData={this.state.customerData}
              isEdit={this.state.isEdit as boolean}
              priceId={priceId}
            />
          )}
          {this.state.addProductPopup && (
            <AddProductModal
              data-test-id='addProductModal'
              navigation={this.props.navigation}
              id={"AddProductModal"}
              open={this.state.addProductPopup}
              handleClose={() => {
                this.handleAddProductPopupClose();
              }}
              getProductInfo={this.handleGetTemporaryProduct}
              categoryId={this.state.tabsId}
              storeId={
                this.state?.userData?.attributes?.employee_proifle?.data
                  ?.attributes?.store_management?.store_id
              }
              customerId={Number(this.state.customerId as string)}
            />
          )}
          <ShowCustomerDetailsModal
            data-test-id='viewCustomerModal'
            navigation={this.props.navigation}
            id={"ShowCustomerDetailsModal"}
            open={this.state.viewCustomerPopup}
            handleClose={() => {
              this.handleViewCustomerPopupClose();
            }}
            customerData={this.state.customerData}
            handleIsEdit={() => {
              this.handleIsEdit();
            }}
          />
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            open={this.state.snackbarOpen}
            autoHideDuration={4000}
            onClose={this.handleSnackbarClose}
          >
            <Alert severity='success'>
              {this.state.successSnackBarMessage}
            </Alert>
          </Snackbar>
          <CustomSnackbar
            data-test-id='customErrorSnackbar'
            open={this.state.errorSnackbarOpen}
            onClose={this.handleSnackbarClose}
            errorMessage={this.state.errorMessage}
            autoHideDuration={4000}
            severity='warning'
            hideIcon={true}
          />

          {this.getPaymentModal()}
          {
            this.state.orderTryAgainModalVisible && (
              <OrderTryAgainModal
                open={this.state.orderTryAgainModalVisible}
                handleClose={this.handleOrderTryAgainModalClose}
              />
            )
          }
          {
            this.state.preferenceModalVisible && (
              <PreferenceItem
                data-test-id='preferenceItem'
                preferenceData={this.state.preferenceData}
                selectedService={this.state.selectedService}
                handleClose={this.handleClosePreference}
                data={[]}
                headerTitle={"Create Item Preference"}
                saveForFuture={true}
                open={this.state.preferenceModalVisible}
                responseData={{
                  id: "11",
                  type: "preference",
                  attributes: {
                    id: 11,
                    name: "Preference 1",
                    product_second_name: "Product 1",
                    specifications: [],
                    upcharges: [],
                    preferences: [],
                    services: [],
                  },
                }}
                onCreate={(data) => this.handleCreatePreference(data)}
              />
            )
          }
          {
            this.state.sameProductDifferentServiceModalVisible && (
              <SameProductDifferentServiceModal
                open={this.state.sameProductDifferentServiceModalVisible}
                handleClose={this.handleSameProdtDiffServiceModalClose}
                handleConfirmClick={
                  this.handleSameProdDiffServiceConfirmClick
                }
              />
            )
          }
        </ThemeProvider>
      </MainWrapper>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

const StyledTextArea = styled(TextareaAutosize)({
  padding: 8,
  width: '100%',
  borderRadius: 8,
  border: `1px solid ${colors().lightborder}`
});

const StyledDialog = styled(Dialog)({
  "& .MuiDialog-paper": {
    borderRadius: 24,
    "& .MuiDialogContent-root": {
      padding: "24px 32px"
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: 8
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: colors().lightborder
    },
    "& .MuiTextField-root": {
      paddingTop: 0
    },
    "& .link-button": {
      gap: 8,
      fontSize: 16,
      display: 'flex',
      fontWeight: 700,
      cursor: 'pointer',
      alignItems: 'center',
      color: colors().cyancobaltblue,
    },
    "& .button-container": {
      display: 'flex',
      paddingTop: 32,
      paddingBottom: 24,
      alignItems: 'center',
      justifyContent: 'center',
      "@media only screen and (max-width: 425px)": {
        flexFlow: 'column-reverse',
        gap: 16,
      }
    },
    "& .dialog-title": {
      fontSize: 24,
      fontWeight: 600,
      paddingBottom: 32,
      textAlign: "center",
      textTransform: 'capitalize'
    }
  }
})

const PlantOrderCustomerFormContainer = styled("div")({
  gap: 12,
  display: "grid",
  alignItems: "center",
  gridTemplateColumns: '80px 1fr',
  "@media only screen and (max-width: 625px)": {
    gridTemplateRows: '1fr 1fr',
    gridTemplateColumns: '1fr',
  },
  "@media only screen and (max-width: 1024px)": {
    gridTemplateColumns: '56px 1fr',
    gap: 8,
    "& input": {
      fontSize: 11
    }
  },
  "& > div": {
    gap: 12,
    display: 'flex',
    alignItems: "center",
    "@media only screen and (max-width: 1024px)": {
      gap: 8,
    },
  },
  "& .MuiOutlinedInput-root": {
    width: '100%'
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: colors().lightborder,
  },
  "& .countryInputDigit": {
    width: '100%',
    "& .MuiOutlinedInput-input": {
      padding: "10px 8px",
    },
    "& input": {
      fontWeight: 500
    },
    "& .MuiOutlinedInput-root": {
      border: "none"
    },
  },
  "& .MuiInputBase-input": {
    height: 'unset'
  },
  "& .customerButton": {
    width: 36,
    height: 36,
  },
  "& .MuiAutocomplete-root, & .MuiTextField-root": {
    paddingTop: 0,
  },
  "& .MuiAutocomplete-root .MuiOutlinedInput-root": {
    minWidth: 175,
    padding: 0,
    paddingRight: 30,
    "& input": {
      fontWeight: 500,
      padding: "10px 8px !important",
    },
  },
});

const PlantOrderItemContainer = styled("div")({
  padding: 16,
  borderRadius: 12,
  boxShadow: "0px 2px 8px 0px #00000014",
  "@media only screen and (max-width: 1024px)": {
    fontSize: "13px !important"
  },
  "& .emptyCart": {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  "&.dialog": {
    minWidth: 872,
    marginBottom: 32,
    "@media only screen and (max-width: 1024px)": {
      minWidth: 'unset'
    },
    "& .hide-768": {
      "@media only screen and (max-width: 768px)": {
        display: 'none'
      },
    },
    "& .suffix": {
      fontSize: 16,
      fontWeight: 600,
    },
    "& .pt-32": {
      paddingTop: 32,
    },
    "& .dialog-container": {
      gap: 32,
      display: "flex",
      flexFlow: 'column'
    },
    "& .flex": {
      display: "flex",
      paddingBottom: 0,
    },
    "& .align-center": {
      alignItems: 'center'
    },
    "& .space-between": {
      justifyContent: 'space-between'
    },
    "& .grid": {
      display: 'grid'
    },
    "& .col-2": {
      gridTemplateColumns: '1fr 1fr',
      "@media only screen and (max-width: 768px)": {
        gridTemplateColumns: '1fr',
      },
    },
    "& .gap-2": {
      gap: 12,
      gridGap: 12
    },
  },
  "& .border-box": {
    borderRadius: 12,
    padding: 16,
    border: `1px solid ${colors().lightborder}`
  },
  "& .detail-container": {
    gap: 16,
    fontSize: 14,
    display: 'flex',
    fontWeight: 500,
    paddingBottom: 16,
    "& .right-section": {
      display: "flex",
      flexDirection: "column-reverse",
      alignItems: "flex-end",
      gap: 10
    },
    "& .price-box": {
      display: "flex",
      justifyContent: "end",
      gap: 5,
      "& > .price": {
        minWidth: 80,
        textAlign: 'right'
      },
    },
    "& .delete": {
      gap: 4,
      display: 'flex',
      cursor: 'pointer',
      alignItems: 'center',
      "& span": {
        display: 'none'
      }
    },
    "@media only screen and (max-width: 768px)": {
      flexFlow: 'column',
      alignItems: 'flex-start',
      "& > .price": {
        width: '100%',
        textAlign: 'left',
        paddingBottom: 12,
        borderBottom: `1px dotted ${colors().lightborder}`
      },
      "& .delete span": {
        display: 'block !important'
      }
    },
    "@media only screen and (max-width: 1024px)": {
      fontSize: 13
    },
  },
  "& .cart-item > div": {
    marginBottom: 16,
    borderBottom: `1px solid ${colors().lightborder}`
  },
  "& .cart-item > div:last-child": {
    marginBottom: 0,
    border: 0
  },
  "& .cart-item > div:last-child > div:last-child": {
    paddingBottom: 0
  },
  "& .pcs": {
    width: "fit-content",
    height: "30px",
    background: "#EC4E20",
    color: "#FFF",
    textAlign: "center" as const,
    lineHeight: "30px",
    minWidth: "95px",
    padding: "0 8px",
    display: "flex",
    alignItems: "center",
    gap: 8,
    fontWeight: 400,
    borderRadius: 4,
    justifyContent: 'center',
    backgroundColor: colors().orange,
    "@media only screen and (max-width: 1024px)": {
      minWidth: 80,
      height: 24,
      padding: 0
    },
  },
  "& .details": {
    flexGrow: 1,
    color: colors().darkjunglegreen,
    textTransform: 'capitalize',
  },
  "& .product-name": {
    fontSize: 14,
    fontWeight: 500,
    paddingBottom: 12,
    textTransform: 'capitalize',
    color: colors().darkliver,
    "@media only screen and (max-width: 1024px)": {
      fontSize: 13
    },
  },
  "& .flex": {
    display: 'flex',
    paddingBottom: 16,
    justifyContent: 'space-between',
    fontSize: 15,
    "@media only screen and (max-width: 1024px)": {
      fontSize: 13
    },
  },
  "& .title": {
    fontWeight: 600
  },
  "& .link": {
    cursor: 'pointer',
    color: colors().orange,
    textDecoration: 'underline',
  }
});

const MainWrapper = styled("div")({
  fontFamily: "Montserrat",
  "& .mb-24": {
    marginBottom: "24px"
  },
  "& .buttonWrapper": {
    minWidth: "100px",
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
  },
  "& .branchDropDown": {
    minWidth: "200px",
    margin: "0 12px",
  },
  "& .font-600": { fontWeight: 600 },
  "& .mr-8": { marginRight: 8 },
  "&. .qtyNum": { padding: "0 10px" },
  "& .w-full-80": { width: "80%" },
  "& .cursor-pointer": { cursor: "pointer" },
  "& .customerButton": {
    padding: 0,
    backgroundColor: "#204B9C",
    minWidth: "36px",
    height: "36px",
    borderRadius: "8px",
  },
  "& .cartPaymentOption": {
    marginTop: "24px",
    marginBottom: 0,
  },
  "& .mobileBlock": {
    display: "flex",
    justifyContent: "space-berween",
  },
  "& .cartSubtotalWrapper": {
    display: "flex",
    width: "100%",
    cursor: "pointer",
  },
  "& .cartTotalWrapper": {
    backgroundColor: "#EC4E20",
    borderRadius: "8px",
    padding: "16px 24px",
    color: "#FFF",
    marginTop: "20px",
  },
  "& .backInner": {
    width: "100%",
    display: "flex"
  },
  "& .cartSubtotalPrice": {
    marginLeft: "auto",
    fontWeight: 600,
  },
  "& .cartTotalListPrice": {
    fontWeight: 400,
    marginLeft: "auto",
    marginRight: "25px",
  },
  "& .countryInput": {
    width: "72px",
    marginRight: "12px",
    padding: 0
  },
  "& .countryInput .MuiOutlinedInput-input": {
    padding: 10
  },
  "& .phone-number .MuiAutocomplete-inputRoot": {
    padding: 1
  },
  "& .phone-number .MuiInputBase-input": {
    fontSize: "14px",
    fontWeight: 500
  },
  "& .deleteIcon": {
    cursor: "pointer",
    width: "16px",
    height: "16px",
  },
  "& .dialogTitle": {
    margin: "auto",
    paddingTop: "32px",
    paddingBottom: "24px",
  },
  "& .dialogActionWrapper": {
    justifyContent: "center",
    paddingTop: "24px",
    paddingBottom: "32px",
  },
  "& .cartItemQuantityIcon": {
    cursor: "pointer",
    width: "16px",
    height: "16px",
    color: "white",
  },
  "& .emptyCartIcon": {
    width: "93px",
    height: "74px",
  },
  "& .cartProductNote": {
    width: "100%",
    padding: "8px",
    maxWidth: "100%",
    borderRadius: "8px",
    border: "1px solid #ECECEC",
  },
  "& .sideCart": {
    padding: "16px",
    borderRadius: "8px",
    border: "1px solid #ECECEC",
  },
  "& .cartItemContainer": {
    borderRadius: "12px",
    boxShadow: "0px 2px 4px 4px rgba(0, 0, 0, 0.08)",
    padding: "20px 16px",
  },
  "& .editAllBtn": {
    padding: "8px 10px",
    borderRadius: "8px",
    background: "rgba(32, 75, 156, 0.10)",
    color: "#204B9C",
    cursor: "pointer",
    fontSize: "13px",
    fontWeight: 600
  },
  "& .cartHeaderContainer": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  "& .cartProductDetailPrice": {
    color: "#4D4D4D",
    fontSize: "12px",
    fontWeight: 500,
    letterSpacing: "-0.35px",
    opacity: 0.6,
  },
  "& .cartProductQuantityContainerMain": {
    maxWidth: "81px",
    flex: "0 0 81px",
    padding: "10px 8px",
  },
  "& .cartProductQuantityContainer": {
    height: "30px",
    borderRadius: "7px",
    background: "#EC4E20",
    color: "#FFF",
    textAlign: "center" as const,
    lineHeight: "30px",
  },
  "& .selectDataLabel": {
    color: colors().black,
    fontWeight: 600,
    fontSize: 14,
  },
  "& .cartProductDetailTotal": {
    color: colors().viewTextColor,
    fontSize: "14px",
    fontWeight: 400,
    whiteSpace: "nowrap" as const,
    display: "flex",
    gap: "8px",
    alignItems: "center",
  },
  "& .cartProductQuantityContainerSecond": {
    height: "30px",
    borderRadius: "7px",
    background: "#EC4E20",
    color: "#FFF",
    textAlign: "center" as const,
    lineHeight: "30px",
    minWidth: "95px",
    padding: "0 8px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  "& .cartProductDetailContainer": {
    display: "flex",
    flexDirection: "column" as const,
    margin: "0 8px 0 0",
    alignSelf: "baseline",
  },
  "& .mainCartDetailConatiner": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "start"
  },
  "& .cartLeftSection": {
    display: "flex",
    flexDirection: "column",
    gap: "10px"
  },
  "& .cartRightSection": {
    display: "flex",
    flexDirection: "column",
    gap: "20px"
  },
  "& .cartProductDetailContainerMain": {
    flex: "2",
    padding: "10px 0px",
  },
  "& .clearAllBtnContainer": {
    display: "flex",
    justifyContent: "flex-end",
  },
  "& .greyPoint": {
    backgroundColor: "#DEDEDE",
    width: 6,
    height: 6,
    borderRadius: "50%",
    marginLeft: 8,
    marginRight: 8,
  },
  "& .cartProductImgWrapperMain": {
    maxWidth: "70px",
    flex: "0 0 70px",
    padding: "10px 8px",
  },
  "& .cartProductDetailTitle": {
    display: "flex",
    alignItems: "center",
    marginRight: "12px"
  },
  "& .productNameTitle": {
    color: colors().darkjunglegreen,
    fontSize: "14px",
    fontWeight: 600,
    whiteSpace: "nowrap" as const
  },
  "& .upchargeTitle": {
    color: colors().darkliver,
    fontSize: "12px",
    fontWeight: 500,
    whiteSpace: "nowrap" as const
  },
  "& .dateSelectWrapper": {
    padding: "16px 0",
    display: "flex",
    gap: "16px",
    "@media only screen and (max-width:1024px)": {
      flexDirection: "column"
    },
  },
  "& .cartProductImg": {
    width: "100%",
    height: "100%",
  },
  "& .cartProductImgWrapper": {
    width: "100%",
    borderRadius: "8px",
    border: "1px solid #ECECEC",
    height: "54px",
  },
  "& .cartProductContainer": {
    display: "flex",
    alignItems: "center",
    marginBottom: "24px",
  },
  "& .clearAllBtn": {
    color: "#ec4e20",
    borderBottom: "1px solid #ec4e20",
    cursor: "pointer",
    marginTop: 16,
    fontWeight: 600,
  },
  "& .cartServiceTitle": {
    color: colors().cyancobaltblue,
    fontSize: "14px",
    fontWeight: 600,
    lineheight: "18px",
    marginTop: "24px",
  },
  "& .emptyCartWrapper": {
    display: "flex",
    flexDirection: "column" as const,
    alignItems: "center",
  },
  "& .cartProductsContainer": {
    overflowY: "auto" as const,
    maxHeight: 540,
  },
  "& .addProductBtnText": {
    color: "#204B9C",
    fontSize: 16,
    fontWeight: 600,
    marginTop: 8,
  },
  "& .cartTotalList": {
    display: "flex",
    width: "100%",
    marginBottom: "16px",
  },
  "& .addProductBtnPlus": {
    fontSize: 40,
    color: "#204B9C",
    fontWeight: 400,
  },
  "& .addProductBtnPlusContainer": {
    width: 48,
    height: 48,
    display: "flex",
    background: "#D4DDEC",
    justifyContent: "center",
    borderRadius: 8,
    alignItems: "center",
  },
  "& cartTotalListWrapper": {
    marginBottom: "20px",
  },
  "& .productsPage": {
    paddingRight: "32px",
    paddingTop: "56px",
    paddingLeft: "48px",
  },
  "& .dialogProducts": {
    display: "flex",
    flexWrap: "wrap" as const,
    gap: "18px",
    justifyContent: "space-evenly" as const,
  },
  "& .productsListContainer": {
    display: "grid",
    gridGap: "32px",
    gridTemplateColumns: "minmax(192px, 1fr) minmax(192px, 1fr) minmax(192px, 1fr)",
    "@media only screen and (min-width:1024px)": {
      gridGap: "16px",
      gridTemplateColumns: "minmax(128px, 1fr) minmax(128px, 1fr)",
    },
    "@media only screen and (min-width:1280px)": {
      gridGap: "16px",
      gridTemplateColumns: "minmax(128px, 1fr) minmax(128px, 1fr)",
    },
    "@media only screen and (max-width:800px)": {
      gridGap: "16px",
      gridTemplateColumns: "minmax(128px, 1fr) minmax(128px, 1fr)",
    },
    "@media only screen and (min-width:1400px)": {
      gridGap: "24px",
      gridTemplateColumns: "minmax(128px, 1fr) minmax(128px, 1fr) minmax(128px, 1fr) minmax(128px, 1fr)",
    },
  },
  "& .addProductBtnContainer": {
    height: "100%",
    borderRadius: 8,
    background: "#E8EDF5",
    display: "flex" as const,
    flexDirection: "column" as const,
    justifyContent: "center" as const,
    alignItems: "center",
    cursor: "pointer",
    minHeight: "298px"
  },
  "& .ftWeight400": {
    fontWeight: 400,
  },
  "& .ftWeight600": {
    fontWeight: 600,
  },
});

export const MainButton = styled(Button)({
  background: "#204B9C",
  fontWeight: 700,
  fontSize: "16px",
  lineHeight: "24px",
  color: colors().white,
  height: "50px",
  width: "186px",
  borderRadius: "8px",
  marginRight: 32,
  textTransform: "unset" as const,
  "&.MuiButton-root.Mui-disabled": {
    color: colors().white,
    cursor: 'not-allowed',
    opacity: 0.5,
  },
  "&:hover": {
    background: "#204B9C",
    color: colors().white,
  },
  "&.buttonSecondary": {
    backgroundColor: "#F1F5F9",
    color: "#64748B",
  },
  "&.fullWidth": {
    width: "100%",
  },
  "&.mt-24": {
    marginTop: 24
  },
});

const PaginationContainer = styled("div")({
  display: "flex",
  padding: "32px 0px",
  justifyContent: "flex-end"
});

const CatalogProductGrid = styled("div")({
  flex: "1 1 0",
  width: "100%",
  "@media only screen and (max-width:800px)": {
    maxWidth: "100%",
    width: "100%",
  },
});

const CatalogContainer = styled("div")({
  display: "flex",
  gap: "32px",
  "@media only screen and (max-width:1280px)": {
    gap: "16px",
  },
  "@media only screen and (max-width:800px)": {
    flexDirection: "column",
  },
});

const StyledProgress = styled(CircularProgress)({
  "&.MuiCircularProgress-colorPrimary": {
    color: "white",
  },
});

const CatalogCartGrid = styled("div")({
  maxWidth: "452px",
  width: "100%",
  "@media only screen and (min-width:1366px)": {
    maxWidth: "452px",
  },
  "@media only screen and (max-width:1024px)": {
    maxWidth: "420px",
  },
  "@media only screen and (max-width:800px)": {
    maxWidth: "100%",
  },
});

const StyledFormControlLabel = withStyles({
  root: {
    "& .Mui-checked + .MuiFormControlLabel-label": {
      color: "#204B9C",
    },
    "&.for_plant_orders": {
      margin: 0,
      "& > span": {
        padding: 0
      },
      "& .MuiTypography-root": {
        fontSize: 14,
        paddingLeft: 4,
      }
    }
  },
})(FormControlLabel);

const StyledDivider = withStyles({
  root: {
    background: colors().lightborder
  },
})(Divider);

const ProductContainer = styled("div")({
  gap: 24,
  display: "flex",
  paddingBottom: 32,
  paddingTop: 24,
  "& .grow": {
    flexGrow: 1,
  },
  "@media only screen and (max-width:1280px)": {
    gap: "16px",
  },
  "@media only screen and (max-width:1024px)": {
    gap: "12px",
  },
  "@media only screen and (max-width:800px)": {
    flexDirection: "column",
  },
});

const ProductListContainer = styled("div")({
  display: "grid",
  gridTemplateColumns: "minmax(192px, 1fr) minmax(192px, 1fr) minmax(192px, 1fr)",
  "@media only screen and (min-width:1024px)": {
    gridGap: "12px",
    gridTemplateColumns: "minmax(128px, 1fr) minmax(128px, 1fr) minmax(128px, 1fr)",
  },
  "@media only screen and (min-width:1280px)": {
    gridGap: "16px",
    gridTemplateColumns: "minmax(128px, 1fr) minmax(128px, 1fr) minmax(128px, 1fr)",
  },
  "@media only screen and (max-width:800px)": {
    gridGap: "16px",
    gridTemplateColumns: "minmax(128px, 1fr) minmax(128px, 1fr)",
  },
  "@media only screen and (min-width:1366px)": {
    gridGap: "24px",
    gridTemplateColumns: "minmax(128px, 1fr) minmax(128px, 1fr) minmax(128px, 1fr)",
  },
});

const ProductItemContainer = styled("div")({
  boxShadow: "0px 8px 32px 0px #0000000F, 0px 4px 8px 0px #00000008",
  border: `1px solid ${colors().lightborder}`,
  borderRadius: 8,
  padding: 14,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  "& .image-container": {
    width: '129px',
    height: '129px',
    borderRadius: '8px',
    "@media only screen and (max-width:1160px)": {
      width: '76px',
      height: '76px',
    },
  },
  "& img": {
    width: "100%",
    height: '100%',
    borderRadius: "8px",
  },
  "& .heading": {
    fontSize: 18,
    fontWeight: 500,
    marginTop: "14px",
    textAlign: "center",
    color: '#1A1A1A',
    lineHeight: '22px',
    "@media only screen and (max-width:1280px)": {
      fontSize: '16px',
    },
    "@media only screen and (max-width:1024px)": {
      fontSize: '13px',
    },
  },
  "& .actionButtons": {
    gap: 32,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& img": {
      cursor: "pointer",
    },
    "& .count": {
      fontSize: 14,
    },
  },
});

const ProductCartContainer = styled("div")({
  gap: 16,
  padding: 16,
  borderRadius: 8,
  display: "flex",
  flexFlow: "column",
  border: `1px solid ${colors().lightborder}`,
  "& .cartProductNote": {
    margin: 0,
    fontSize: 14,
  },
  "@media only screen and (max-width: 768px)": {
    minWidth: 'unset',
    maxWidth: 'unset',
  },
  "@media only screen and (max-width: 1024px)": {
    padding: 12
  }
});

const StyledSelectPaymentMethod = styled(Select)({
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: colors().lightborder,
  },
  "&.MuiOutlinedInput-root": {
    fontSize: 14,
    borderRadius: 8,
    color: colors().slateGray,
  },
  "& .MuiSelect-root": {
    padding: "10px 8px",
    paddingRight: 32,
  },
});

const PlantOrderSummaryContainer = styled("div")({
  fontSize: 14,
  fontWeight: 500,
  borderRadius: 8,
  padding: "16px 24px",
  color: colors().white,
  backgroundColor: colors().orange,
  "& hr": {
    backgroundColor: colors().white,
    height: 1,
    border: 0,
    marginBottom: 24 - 8,
  },
  "& .flex": {
    gap: 8,
    display: "flex",
    alignItems: "center",
    paddingBottom: 24,
    "& > div:first-child": {
      flexGrow: 1,
    },
  },
  "& .total": {
    fontWeight: 600,
    paddingBottom: 0,
  },
  "& svg": {
    cursor: "pointer",
  },
  "& .caret-icon": {
    marginRight: -6
  }
});

const StyledAutoComplete = styled(Autocomplete)({
  flexGrow: 1,
  "& .MuiOutlinedInput-root": {
    borderRadius: 8,
    fontSize: 14,
  }
})

const StyledAppBar = withStyles({
  root: {
    color: colors().darkliver,
    margin: "24px 0",
    backgroundColor: colors().white,
    borderBottom: `1px solid ${colors().lightborder}`,
    boxShadow: "none",
    "& .MuiTab-root": {
      textTransform: "none",
      maxWidth: "unset",
      fontSize: 18,
      "@media only screen and (max-width: 1024px)": {
        fontSize: 16,
      },
    },
    "& .MuiTabs-flexContainer": {
      justifyContent: "space-between"
    },
    "& .Mui-selected": {
      color: colors().cyancobaltblue,
      fontWeight: 700
    },
    "& .MuiTabs-indicator": {
      backgroundColor: `${colors().cyancobaltblue} !important`,
    },
    "& .MuiPaper-elevation": {
      boxShadow: "none",
    },
  },
})(AppBar);

const StyledMainAppBar = withStyles({
  root: {
    margin: 0,
    "& .MuiTab-root": {
      textTransform: "none",
      maxWidth: "unset",
      fontSize: 20,
      "@media only screen and (max-width: 1024px)": {
        fontSize: 18,
      },
      "@media only screen and (max-width: 768px)": {
        fontSize: 16,
      },
    },
  },
})(StyledAppBar);

const OrderSummaryContainer = styled('div')({
  gap: 16,
  padding: 16,
  fontSize: 14,
  display: "flex",
  borderRadius: 12,
  flexDirection: 'column',
  background: "rgba(32, 75, 156, 0.10)",
  "& .close-icon": {
    cursor: "pointer",
    maxHeight: "25px"
  },
  "& .button-container": {
    display: "flex",
    alignItems: 'center',
    justifyContent: "space-between",
    "& .close-icon": {
      marginTop: -1
    },
    "& .button": {
      fontSize: 14,
      textTransform: 'none'
    }
  },
  "& .header": {
    color: "#1A1A1A",
    fontFamily: "Montserrat",
    fontSize: 16,
    fontWeight: 600,
    fontStyle: "normal",
  },
  "& .row": {
    gridGap: 10,
    display: "grid",
    gridTemplateColumns: '128px 1px 1fr',
    "@media only screen and (max-width: 530px)": {
      gridTemplateColumns: '1fr',
      borderBottom: `1px solid ${colors().white}`,
      paddingBottom: 14,
      "& .label": {
        fontWeight: 500,
      },
      "& .dot": {
        display: 'none'
      }
    }
  },
  "& .button": {
    background: "rgba(32, 75, 156, 0.10)",
    color: "rgba(32, 75, 156, 1)",
    fontFamily: "Montserrat",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 600,
    borderRadius: 8,
  },
});

const StyledDatePicker = styled(DatePicker)({
  "& .MuiInput-root": {
    height: "100%",
  },
  "& .MuiInput-underline::before, & .MuiInput-underline::after": {
    borderBottom: "none !important",
  },
});

const StyledCalenderIcon = styled("img")({
  top: 16,
  right: 12,
  zIndex: 1,
  paddingTop: 10,
  position: "absolute",
  pointerEvents: "none",
});

const StyledOption = styled(Box)({
  "& > img": {
    mr: 2,
    flexShrink: 0
  }
})

const StyleLable = styled("p")({
  fontSize: "1rem",
  fontFamily: "Montserrat",
  fontWeight: 400,
  "@media only screen and (max-width: 1024px)": {
    fontSize: 13,
  },
});

const PlantCartWrapper = styled("div")({
  maxWidth: "452px",
  width: "100%",
  "@media only screen and (min-width:1366px)": {
    maxWidth: "452px",
  },
  "@media only screen and (max-width:1024px)": {
    maxWidth: "352px",
  },
  "@media only screen and (max-width:800px)": {
    maxWidth: "100%",
  },
});
// Customizable Area End
