import React from "react";

// Customizable Area Start
import {
  Box,
  Button,
  Popover,
  Tab,
  Tabs,
  styled
} from "@material-ui/core";
import { createTheme } from "@material-ui/core/styles";
import DoneAllIcon from '@material-ui/icons/DoneAll';
import { colors } from "../../utilities/src/Colors";
import CircularProgress from "@material-ui/core/CircularProgress";

import NotificationsController, {
  INotificationData,
  Props,
  configJSON,
} from "./NotificationsController";
// Customizable Area End

export default class Notifications extends NotificationsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  notificationComponentWithLoader(notificationList: INotificationData[], isLoading: boolean) {
    return (isLoading ? <Box component={"div"} className="loading"><CircularProgress /></Box> : this.notificationComponent(notificationList))
  }

  notificationContent = (notificationSentence: string,normalType: boolean, notification: INotificationData) => {
    if(normalType) return (<span>{notificationSentence}</span>);
    return notificationSentence.split(/Order No\. (\w+)/).map((part, index) => {
      if (index === 1) {
        return (
          <Box component={'span'} key={index} data-test-id={`orderNumber-${this.orderComponent(notificationSentence)}`} className="notification-orderno" onClick={() => { this.handleNavigateToOrder(notification.attributes.order_number, notification.attributes.internal_status) }}>
            Order No. <span style={{ cursor: 'pointer', textDecoration: 'underline' }}>
              {this.orderComponent(notificationSentence)}
            </span>
          </Box>
        );
      } else {
        return <span key={index}>{part}</span>;
      }
    })
  }

  notificationComponent(notificationList: INotificationData[]) {
    if (notificationList?.length) {
      return notificationList?.map((notification) => {
        const { headings } = notification.attributes;
        const isImportNotification = headings.includes("Import Successful") || headings.includes("Import Errors");
        return (
          <Box className="notification" data-test-id={`notification-${notification.id}`} onClick={() => { this.state.notificationStatus === "false" && this.handleClickOnNotification(notification.id) }}>
            <span className="notification-icon">
              <img src={notification.attributes.icon} alt='notification-content-icon' width={24} height={24}/>
            </span>
            <Box>
              <Box className="notification-header">
                <Box component={"h4"} className="notification-title">{notification.attributes.headings}</Box>
                <Box className="notification-time">{this.timeSince(notification.attributes.created_at)} ago</Box>
              </Box>

              <Box className="notification-description">
                <Box className="notification-content">
                  {this.notificationContent(notification.attributes.contents,notification.attributes.headings.includes(configJSON.orderAdjustmentTxt),notification)}
                </Box>
                {notification.attributes.headings.includes("New Order Created") && <Button className="viewOrder-button" onClick={() => {this.handleNavigateToOrder(notification.attributes.order_number, notification.attributes.internal_status)}}>{configJSON.viewOrderText}</Button>}
                {isImportNotification && <Button className="viewOrder-button" onClick={() => {this.handleDownload(notification.attributes.app_url)}}>{configJSON.downloadTxt}</Button>}
              </Box>

              {notification.attributes.headings.includes(configJSON.orderAdjustmentTxt) && <Button className="goToAdjustment-button" onClick={() => {this.handleNavigateToAdjustmentOrder(notification.attributes.order_number, notification.attributes.internal_status, notification.attributes.store_management_id)}}>{configJSON.goToAdjustmentTxt}</Button>}

            </Box>
          </Box>
        )
      })
    }
    return <Box component={'div'} className="no-notification">{configJSON.noNotificationText}</Box>
  }

  handleAllTabNotification() {
    return this.notificationComponentWithLoader(this.state.allNotificationList.data, this.state.isLoading)
  }

  handleReadTabNotification() {
    return this.notificationComponentWithLoader(this.state.readNotificationList.data, this.state.isLoading)
  }

  handleUnreadTabNotification() {
    return this.notificationComponentWithLoader(this.state.unreadNotificationList.data, this.state.isLoading)
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <StyledPopover
        id={this.props.popOverId}
        data-test-id="actionPopup"
        open={Boolean(this.props.isOpenAnchor)}
        anchorEl={this.props.isOpenAnchor}
        onClose={() => { this.props.onClose() }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Box className="popover-wrapper">
          <Box className="notification-heading-wrapper">
            <Box component={"h3"}>{configJSON.notificatinosText}</Box>
            <Box component={"div"} className="notification-mark-text" onClick={() => { this.handleMarkAllAsRead() }} data-test-id="markAllAsReadText">
              <DoneAllIcon style={{ color: colors().cyancobaltblue }} /> <span className="notification-markall-text">{configJSON.markAllAsReadText}</span>
            </Box>
          </Box>
          <Box className="notification-tab-wrapper">
            <Box className="notofication-tabs">
              <Tabs
                value={this.state.selectedTab}
                onChange={(_, newValue) => { this.handleTabChange(newValue) }}
                aria-label="basic tabs example"
                TabIndicatorProps={{
                  style: {
                    backgroundColor: colors().cyancobaltblue,
                  }
                }}
              >
                <Tab label="All" value="All" />
                <Tab label="Read" value="true" data-test-id='readTab' />
                <Tab label="Unread" value="false" data-test-id='unreadTab' />
              </Tabs>
            </Box>
            <Box>
              {this.state.selectedTab === "All" && this.handleAllTabNotification()}
              {this.state.selectedTab === "true" && this.handleReadTabNotification()}
              {this.state.selectedTab === "false" && this.handleUnreadTabNotification()}
            </Box>
          </Box>
        </Box>
      </StyledPopover>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  itemWrapper: {
    border: "1px solid #767676",
    display: "flex",
    width: "70%",
    flexDirection: "row" as "row",
    marginTop: 10,
    padding: 16,
    paddingTop: 10,
    cursor: "pointer",
  },
  itemHeadingWrapper: {
    display: "flex",
    flex: 1,
    flexDirection: "row" as "row",
  },
  iconStyle: {
    width: 20,
    height: 26,
    marginTop: 6,
  },
  itemHeading: {
    color: "#000",
    flex: 1,
    fontWeight: 700,
  },
  itemHeadingRead: {
    color: "#6200EE",
    flex: 1,
    fontWeight: 700,
  },
  contents: {
    fontSize: 16,
    color: "#767676",
  },
  okButton: {
    backgroundColor: "#ccc",
    color: "#000",
  },
  deleteButton: {
    backgroundColor: "#FF0000",
  },
};

export const StyledPopover = styled(Popover)({
  "& .MuiPopover-paper": {
    overflowY: "hidden"
  },
  "& .popover-wrapper": {
    width: 500,
    maxHeight: 700,
    minheight: 160,
    overflow: "auto",

    "& .notification-heading-wrapper": {
      display: 'flex',
      justifyContent: 'space-between',
      padding: '20px 20px 0',
      position: 'sticky',
      top: 0,
      zIndex: 2,
      backgroundColor: colors().white,

      "& .notification-mark-text": {
        color: colors().cyancobaltblue,
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',

        "& .notification-markall-text": {
          color: colors().cyancobaltblue,
          marginLeft: 8,
          fontWeight: 600
        }
      },
      "& .Mui-selected": {
        color: colors().cyancobaltblue,
      }
    },
    "& .notification-tab-wrapper": {
      "& .loading": {
        textAlign: 'center',
        padding: "25px 0"
      },
      "& .no-notification": {
        textAlign: 'center',
        padding: '25px 0',
        fontSize: 16,
        fontWeight: 600
      },
      "& .notofication-tabs": {
        position: 'sticky',
        top: 43,
        backgroundColor: colors().white,
        boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
        zIndex: 2,
        padding: "0 10px",

        "& .MuiTabs-flexContainer": {
          width: '50%',

          "& .MuiTab-root": {
            minWidth: 'unset',
            textTransform: 'capitalize'
          }
        }
      },
      "& .notification": {
        display: 'flex',
        borderBottom: `1px solid ${colors().gray93}`,
        padding: "20px 20px",

        "& .notification-icon": {
          marginRight: 12
        },
        "& .notification-header": {
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: 15,
          height: 30,

          "& .notification-title": {
            color: colors().darkjunglegreen
          },
          "& .notification-time": {

          }
        },
        "& .notification-description": {
          display: 'flex',

          "& .notification-content": {
            lineHeight: "25px",
            fontWeight: 500,
            color: colors().darkjunglegreen,
            padding: "0 8px 0 0",

            "& .notification-orderno": {
              color: colors().cyancobaltblue
            }
          },

          "& .viewOrder-button": {
            backgroundColor: colors().brightgray,
            color: colors().cyancobaltblue,
            borderRadius: 6,
            textTransform: 'none',
            minWidth: 110,
            height: 30,
            fontWeight: 600
          }
        },
        "& .goToAdjustment-button": {
          margin:"10px",
          color: colors().cyancobaltblue,
          textTransform: 'none',
          backgroundColor: colors().brightgray,
          height: 32,
          borderRadius: 8,
          fontWeight: 600,
          minWidth: 178,
          padding:"6px 10px", 
          fontSize: "16px"
        }
      }
    }
  }
});
// Customizable Area End
