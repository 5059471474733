// Customizable Area Start
import React from "react";
import {
  Paper,
  TableRow,
  TableHead,
  TableContainer,
  TablePagination,
  TableCell,
  TableBody,
  Table,
  Box,
  Button,
  Popover,
  IconButton,
  styled
} from "@material-ui/core";

import MoreVertIcon from "@material-ui/icons/MoreVert";
import FilterIcon from "@material-ui/icons/FilterList";

import CustomerTypeController, { Props, configJSON } from "./CustomerTypeController.web";
import PageContainer from "../../../blocks/navigationmenu/src/PageContainer.web";
import ConfirmationModal from "../../../components/src/customComponents/ConfirmationModal.web";
import FilterPopover, { IFilter } from "../../../components/src/FilterPopover";
import SortingTableHeader from "../../../components/src/SortingTableHeader";
import SortingTableCell from "../../../components/src/SortingTableCell";
import { colors } from "../../../blocks/utilities/src/Colors";
import { renderBaseOnConditions } from "../../../blocks/utilities/src/CustomBlockHelpers";
// Customizable Area End

export default class CustomerTypeList extends CustomerTypeController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  render() {
    const { isModalOpen, deactivated: deactivated, customerTypesList, meta, permissionStatus } = this.state;
    const sortingData = this.state.sortingData;

    return (
      <>
        <Box style={webStyle.mainWrapper}>
          <PageContainer
            onSearchText={(value) => this.handleSearch(value)}
            data-test-id="pagecontainer"
            navigation={this.props.navigation}
            onUserChange={(userContext) => this.handleUserChange(userContext)}
          >
            <StyledBoxWrapper>
              <Box>
                <div style={webStyle.headerWrapper}>
                  <div className={'pageHeaderTitle'}>Customer Type</div>
                  <div style={webStyle.headerButtonPartAccount}>
                    <div
                      data-test-id="filterButton"
                      onClick={(event) => {
                        this.setState({
                          filterAnchor: event.currentTarget,
                        });
                      }}
                      className={'filterButtonAccount'}
                    >
                      <FilterIcon />
                    </div>
                    <FilterPopover
                      onClose={() => {
                        this.setState({ filterAnchor: undefined });
                      }}
                      anchor={this.state.filterAnchor}
                      onAutoCompleteChange={(title: string, value: string) => {
                        this.setState({ suggestionFieldTitle: title });
                        this.handleCustomerTypeAutoComplete(title, value);
                      }}
                      onClearFilter={() => {
                        let updated = this.state.filters.map((item: IFilter) => {
                          item.value = ""; 
                          item.options=[];
                          return item;
                        });
                        this.setState({ filters: updated});
                      }}
                      onFilterChange={this.handleFilterChange}
                      filters={this.state.filters}
                    />
                    {renderBaseOnConditions(permissionStatus.createPermission,
                      <Button
                        data-test-id='addCustomerType'
                        onClick={this.handleAdd}
                        className={'addButton'}
                      >
                        Add Customer Type
                      </Button>,
                      <></>)}
                  </div>
                </div>

                <TableContainer component={Paper}>
                  <Table style={webStyle.table} aria-label='customized table'>
                    <TableHead>
                      <TableRow style={{ background: "#204B9C", color: "#FFFFFF" }}>
                        <SortingTableHeader
                          sortingData={sortingData}
                          title='Customer Class/Type'
                          sortingKey='customer_type_name'
                          {...this.sortingProps}
                          type='left'
                          width="21%"
                        />
                        <SortingTableHeader
                          sortingData={sortingData}
                          title='No. of Users'
                          sortingKey='no_of_users'
                          {...this.sortingProps}
                        />
                        <SortingTableHeader
                          sortingData={sortingData}
                          title='Min. Point'
                          sortingKey='minimum_points'
                          {...this.sortingProps}
                        />
                        <SortingTableHeader
                          sortingData={sortingData}
                          title='Max. Point'
                          sortingKey='maximum_points'
                          {...this.sortingProps}
                        />

                        <SortingTableHeader
                          sortingData={sortingData}
                          title='Min. Order'
                          sortingKey='min_number_of_orders'
                          {...this.sortingProps}
                        />
                        <SortingTableHeader
                          sortingData={sortingData}
                          title='Max. Order'
                          sortingKey='max_number_of_orders'
                          {...this.sortingProps}
                        />
                        <SortingTableHeader
                          sortingData={sortingData}
                          title='Status'
                          sortingKey=''
                          {...this.sortingProps}
                          sortingDisabled
                        />
                        <SortingTableHeader
                          sortingData={sortingData}
                          type='action'
                          title=''
                          sortingKey=''
                          {...this.sortingProps}
                          sortingDisabled
                        />
                      </TableRow>
                    </TableHead>

                    {customerTypesList.length>0 && 
                      <TableBody>
                        {customerTypesList.map((rowData, index) => (
                          <TableRow key={index + "TableRow"}>
                            <SortingTableCell width='19vw' type='left' content={rowData.attributes.customer_type_name} />
                            <SortingTableCell width='19vw' content={rowData.attributes.no_of_users} />
                            <SortingTableCell width='19vw' content={rowData.attributes.minimum_points} />
                            <SortingTableCell width='19vw' content={rowData.attributes.maximum_points} />
                            <SortingTableCell width='19vw' content={rowData.attributes.min_number_of_orders} />
                            <SortingTableCell width='19vw' content={rowData.attributes.max_number_of_orders} />
                            <SortingTableCell width='20vw' type='right' content={rowData.attributes.active === true ? 'Active' : 'Deactivated'} />
                            
                            <SortingTableCell
                              width='19vw'
                              type='action'
                              content={
                                <IconButton
                                  data-test-id={`moreButton-${rowData.id}`}
                                  id={"IconButton" + index}
                                  onClick={(event) => {
                                    const position = event.currentTarget.getBoundingClientRect();
                                    this.setState({
                                      popOverItemId: rowData.id,
                                      popOverItemStatus: rowData.attributes.active === true ? 'Active' : 'Deactive',
                                      popOverOpened: true,
                                      popOverLeft: position.left + window.scrollX,
                                      popOverTop: position.top + window.scrollY,
                                    });
                                  }}
                                >
                                  <MoreVertIcon />
                                </IconButton>
                              }
                            />
                          </TableRow>
                        ))}
                      </TableBody>
                    }
                  
                  </Table>
                  {customerTypesList.length === 0 && (
                    <div className={"filterDiv"}>
                      No  records  found!!
                    </div>
                  )}
                </TableContainer>
                {customerTypesList.length > 0 && <TablePagination
                  data-test-id='pagination'
                  rowsPerPageOptions={[10]}
                  count={meta.total_count}
                  component='div'
                  rowsPerPage={this.state.pageSize}
                  page={this.state.page - 1}
                  onPageChange={(_, page) => {
                    this.handlePageChange(page+1)
                  }}
                />}
              </Box>
            </StyledBoxWrapper>

            <Popover
              data-test-id={configJSON.popoverTest}
              style={webStyle.noBoxShadow}
              anchorReference='anchorPosition'
              open={this.state.popOverOpened}
              onClose={() => this.setState({ popOverOpened: false })}
              anchorPosition={{
                top: this.state.popOverTop + 40,
                left: this.state.popOverLeft - 80,
              }}
              anchorOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
            >
              {
                renderBaseOnConditions(
                  (permissionStatus.viewPermission ||
                    permissionStatus.editPermission ||
                    permissionStatus.activatePermission ||
                    permissionStatus.deactivatePermission),
                  <div style={webStyle.popoverContainer}>
                    {
                      renderBaseOnConditions(
                        permissionStatus.viewPermission,
                        <Box
                          data-test-id='viewButton'
                          onClick={() => this.handleView(this.state.popOverItemId)}
                          sx={webStyle.popoverButton}
                        >
                          View
                        </Box>,
                        <></>
                      )
                    }
                    {
                      renderBaseOnConditions(
                        permissionStatus.editPermission,
                        <Box
                          data-test-id='editButton'
                          onClick={() => this.handleEdit(this.state.popOverItemId)}
                          sx={webStyle.popoverButton}
                        >
                          Edit
                        </Box>,
                        <></>
                      )
                    }
                    {
                      renderBaseOnConditions(
                        permissionStatus.deactivatePermission && this.state.popOverItemStatus === "Active",
                        <Box
                          data-test-id={'deactivateButton' + this.state.popOverItemId}
                          sx={webStyle.popoverButton}
                          onClick={() => this.handleDeactivate(this.state.popOverItemId)}
                        >
                          {"Deactivate"}
                        </Box>,
                        <></>
                      )
                    }
                    {
                      renderBaseOnConditions(
                        permissionStatus.activatePermission as boolean && this.state.popOverItemStatus !== "Active",
                        <Box
                          data-test-id={'activateButton' + this.state.popOverItemId}
                          sx={webStyle.popoverButton}
                          onClick={() => this.changeActivateUser()}
                        >
                          {"Active"}
                        </Box>,
                        <></>
                      )
                    }
                  </div>,
                  <Box style={webStyle.popoverContainer}>
                    <Box sx={webStyle.noPermissions}>
                      No permissions
                    </Box>
                  </Box>
                )
              }
            </Popover>
          </PageContainer>
          <ConfirmationModal
            open={isModalOpen}
            handleClose={this.handleDeactivateClose}
            handleConfirm={this.changeActivateUser}
            title='Confirm Deactivate'
            message={{
              id: this.state.clickedCustomerType?.id,
              title: `${this.state.clickedCustomerType?.attributes?.customer_type_name}`,
            }}
            dynamic={true}
            confirmText='Deactivate'
          />

          {deactivated && (
            <ConfirmationModal
              open={deactivated}
              handleClose={this.handleDeactivatedClose}
              handleConfirm={this.handleGoList}
              title={"Customer Type Deactivated"}
              message={{
                id: this.state.clickedCustomerType?.id,
                title: `${this.state.clickedCustomerType?.attributes?.customer_type_name}`,
              }}
              confirmText={"Back to Listing"}
              displayItem={true}
            />
          )}
        </Box>
      </>
    );
  }
  // Customizable Area End
}

// Customizable Area Start
const webStyle = {
  mainWrapper: {
    fontFamily: "Montserrat",
  },
  headerWrapper: {
    display: "flex",
    flexWrap: 'wrap' as 'wrap',
    justifyContent: "space-between",
    marginBottom: 32,
    alignItems: "center",
  },
  headerButtonPartAccount: {
    display: "flex",
  },
  table: {
    minWidth: 700,
  },
  noBoxShadow:{
    boxShadow: "none",
  },
  noPermissions: {
    padding: "0 8px"
  },
  "popoverContainer": {
    "paddingTop": "16px",
    "paddingBottom": "16px",
    "boxShadow": "0px 4px 8px rgba(0, 0, 0, 0.03), 0px 8px 32px rgba(0, 0, 0, 0.06)",
    "borderRadius": "8px",
    "fontFamily": "Montserrat, sans-serif",
    "fontWeight": "500",
    "fontSize": "14px"
  },

  popoverButton: {
    cursor: "pointer",
    width: "118px",
    height: "33px",
    paddingLeft: "24px",
    backgroundColor: "#FFFFFF",
    color: "#4D4D4D",
    "&:hover": {
      backgroundColor: "#204B9C",
      color: "#FFFFFF",
    },
    paddingTop: "8px",
  },
  addButton: {
    background: "#204B9C",
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: "24px",
    color: "#FFFFFF",
    height: "56px",
    // width: "184px",
    borderRadius: "8px",
    padding: "16px"
  },
}

const StyledButton =  styled(Button)({
  ...webStyle.addButton,
  "@media only screen and (max-width: 1024px)":{
    height:"44px",
    fontSize:"13px"
  },
  "&:hover":{
    background: "#204B9C",
  }
})

const StyledBoxWrapper = styled(Box)({
  paddingTop: "25px",
  "& .pageHeaderTitle": {  
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "24px",
    lineHeight: "29px",
    "@media only screen and (max-width: 1024px)": {
      fontSize: "16px"
    },
  },
  "& .filterButtonAccount": {  
    marginRight: "24px",
    cursor: "pointer",
    background: colors().white,
    height: "56px",
    color:  colors().darkliver,
    width: "56px",
    fontSize: "24px",
    borderRadius: "8px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid",
    borderColor:colors().cyancobaltblue,
    "@media only screen and (max-width: 1024px)": {
      fontSize: "16px",
      width: "44px",
      height: "44px",
    },
  },
  "& .addButton": {
    background: colors().cyancobaltblue,
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: "24px",
    color: colors().white,
    height: "56px",
    minWidth: "184px",
    padding: "0 20px",
    borderRadius: "8px",
    textTransform: "unset",
    "@media only screen and (max-width: 1024px)": {
      fontSize: "13px",
      width: "160px",
      height: "44px",
    },
  }
});
// Customizable Area End
