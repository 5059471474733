import React from 'react'
import { Box, Typography, styled, Tooltip } from '@material-ui/core'
import { withStyles, Theme } from '@material-ui/core/styles'
import { formatNumberWithCommas } from '../../blocks/utilities/src/CustomBlockHelpers'

interface BarDataStructure {
    label: string;
    value: string;
    quantity: number;
}

interface Props {
    BarData: BarDataStructure[],
    Height: string;
}

const CustomHBarGraph = (props: Props) => {

    const { BarData, Height } = props;

    function getMaxLimitValue(data: BarDataStructure[]) {
        const maxValue = Math.max(...data.map(dataObject => parseFloat(dataObject.value)));
        const bufferedMaxValue = maxValue * 1.1;
        return bufferedMaxValue.toFixed();
    }

    function calculateBarPercentage(value: number, maxValue: number) {
        if (maxValue === 0) {
            return '0%';
        }
        const barPercentage = (value / maxValue) * 100;
        return `${Math.max(barPercentage, 0.5)}%`;
    }

    function getBarProcessedData(data: BarDataStructure[]) {
        let maxLimitValue = getMaxLimitValue(data)
        return data.map(item => ({
            ...item,
            percentaga: calculateBarPercentage(parseFloat(item.value), parseFloat(maxLimitValue))
        }));
    }

    function getLabelsVs(data: BarDataStructure[]) {
        return data.map(store => store.label).join(' vs ')
    }

    const renderBar = (width: string, value: string | number, Qty: string | number, barColor: string, label: string) => {
        return (
            <Box className='barContainer'>
                <Box className='barLabel'>
                    <Typography className='lable-14-400'>{`SAR ${formatNumberWithCommas(value)}`}</Typography>
                    <Typography className='lable-14-400 coolGrey'>{`${formatNumberWithCommas(Qty)} Qty`}</Typography>
                </Box>
                <Box className='barBody'>
                    <CustomTooltip title={`${label}: SAR ${formatNumberWithCommas(value)}`} placement='top'>
                        <Box width={width} style={{ background: barColor }} className='barDynamic' />
                    </CustomTooltip>
                </Box>
            </Box>
        )
    }

    const renderLegends = (lable: string, color: string) => {
        return (
            <Box className='graphLegendsBody'>
                <Box className='LegendIcon' style={{ background: color }} />
                <Typography noWrap className='lable-12-400'>{lable}</Typography>
            </Box>
        )
    }

    return (
        <StyledWrapper>
            {BarData.length > 0 && <Box className='paymentOptionContainerHGraph'>
                <Box className='paymentSummaryHeaderContainer'>
                    <Typography className='lable-16-700'>
                        Summary of payment options
                    </Typography>
                    <Typography className='lable-12-400 coolGrey'>
                        {getLabelsVs(BarData)}
                    </Typography>
                </Box>

                <Box height={Height} className='barsWrapper'>
                    {getBarProcessedData(BarData).map((dataObject, index) => (
                        renderBar(dataObject.percentaga, dataObject.value, dataObject.quantity, Colors[index], dataObject.label)
                    ))}
                </Box>

                <Box className='graphLegendsContainer'>
                    {BarData.map((data, index) => {
                        return (renderLegends(data.label, Colors[index]))
                    })}
                </Box>

            </Box>}

            {BarData.length == 0 && <Box className='paymentOptionContainerHGraph noDataConatainer'>
                <Typography>No Data</Typography>
            </Box>}

        </StyledWrapper>
    )

}

const StyledWrapper = styled(Box)({
    width: "40%",
    "& .noDataConatainer": {
        display: "grid",
        placeItems: "center",
        height: "315px"
    },
    "& .graphLegendsContainer": {
        marginTop: "20px",
        display: "flex",
        gap: "10px",
        overflow: "auto",
        "&::-webkit-scrollbar": {
            width: "3px",
            height: "3px"
        },
        "&::-webkit-scrollbar-track": {
            background: "#f1f1f1"
        },
        "&::-webkit-scrollbar-thumb": {
            background: "#888",
            borderRadius: "5px"
        },
        "&::-webkit-scrollbar-thumb:hover": {
            background: "#555"
        }
    },
    "& .graphLegendsBody": {
        display: "flex",
        gap: "5px",
        alignItems: "center"
    },
    "& .LegendIcon": {
        width: "12px",
        height: "12px",
        borderRadius: "4px"
    },
    "& .barContainer": {
        display: "flex",
        flexDirection: "column",
        gap: "10px"
    },
    "& .paymentOptionContainerHGraph": {
        padding: "8px 16px",
        boxSizing: "border-box",
        boxShadow: "0px 2px 8px 0px #00000014",
        width: "100%",
        height: "100%",
        borderRadius: "12px"
    },
    "& .paymentSummaryHeaderContainer": {

    },
    "& .barsWrapper": {
        display: "flex",
        flexDirection: "column",
        gap: "20px",
        marginTop: "20px",
        overflow: "auto",
        padding: "0 5px",
        "&::-webkit-scrollbar": {
            width: "3px",
            height: "3px"
        },
        "&::-webkit-scrollbar-track": {
            background: "#f1f1f1"
        },
        "&::-webkit-scrollbar-thumb": {
            background: "#888",
            borderRadius: "5px"
        },
        "&::-webkit-scrollbar-thumb:hover": {
            background: "#555"
        }
    },
    "& .barLabel": {
        display: "flex",
        gap: "10px",
        alignSelf: "end"
    },
    "& .barBody": {
        borderRadius: "10px",
        height: "3px",
        width: "100%",
        backgroundColor: "#E2E8F0"
    },
    "& .barDynamic": {
        borderRadius: "10px",
        height: "3px"
    },
    "& .lable-16-700": {
        fontSize: "16px",
        fontFamily: "Montserrat",
        fontWeight: 700
    },
    "& .lable-14-400": {
        fontSize: "14px",
        fontFamily: "Montserrat",
        fontWeight: 400
    },
    "& .lable-12-400": {
        fontSize: "12px",
        fontFamily: "Montserrat",
        fontWeight: 400
    },
    "& .coolGrey": {
        color: "#64748B"
    },
})

const CustomTooltip = withStyles((theme: Theme) => ({
    tooltip: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        fontSize: 11,
    },
}))(Tooltip);

const Colors = [
    '#059669',
    '#0071CE',
    '#F59E0B',
    '#8833FF',
    '#4B5563',
    '#10B981',
    '#DB2777',
    '#7C3AED',
    '#3B82F6',
    '#6B7280'
];

export default CustomHBarGraph
