// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { Item, Data, Options } from "../../../components/src/customComponents/PreferenceItemController.web";
import { CustomerPrefData, SpecificationsAttributes } from "./CreateCustomerPreferenceController.web";
import { makeApiMessage } from "../../../components/src/common";

interface Specification {
  Color: string;
  Damage: string;
  Strains: string;
  Material: string;
}

interface GalleryIcon {
  id: number;
  image: string;
  name_translation: string;
}

interface Preference {
  id: string;
  type: string;
  attributes: {
    id: number;
    icon_id: number;
    preference_first_name: string;
    preference_second_name: string;
    active: boolean;
    gallery_icon: GalleryIcon;
    name: string;
  };
}

interface Service {
  id: string;
  type: string;
  attributes: {
    id: number;
    name: string;
    second_name: string;
    short_name: string;
    icon_id: number;
    active: boolean;
    online_order: boolean;
    order_number: number;
    icon: GalleryIcon;
    name_translation: string;
  };
}

interface Upcharge {
  id: string;
  type: string;
  attributes: {
    id: number;
    name: string;
    price: number;
    upcharge_type: string;
    icon: GalleryIcon;
    name_translation: string;
  };
}

interface Catalogue {
  id: number;
  product_name: string;
  product_second_name: string;
  name: string;
}

interface OrderItem {
  id: string;
  type: string;
  attributes: {
    id: number;
    order_management_order_id: number;
    quantity: number;
    unit_price: string;
    total_price: string;
    catalogue_id: number;
    catalogue_variant_id: number;
    customer_preference_id: number;
    upcharge_price: string;
    preference_id: number;
    service_id: number;
    parent_catalogue_id: null;
    specifications: Specification;
    category_id: number;
    no_of_pieces: number;
    no_of_tags: number;
    tag_numbers: any[]; // Adjust the type based on the actual data structure
    notes: null;
    actual_price: string;
    actual_total_price: string;
    catalogue: Catalogue;
    product_image: string;
    preference: Preference;
    service: Service;
    upcharge_list_ids: Upcharge[];
  };
}

interface OrderItemInfo {
  customer_preference_id: number;
  preference_id: number;
  catalogue_variant_id: number;
  service_id: number;
}


interface SelectedOption {
  specification_id: number;
  option_id: number;
}

interface UpchargeItem {
  id: string;
  upchargeListIds: number[];
  preferenceId: number;
  selectedOptions: SelectedOption[];
  serviceId: number;
  variant_id: string;
  saveForFuture: boolean;
}

// interface OrderItemsInterfaceProps {
//   orderItems: OrderItem[];
// }
// Customizable Area End
export const configJSON = require("./config");

export interface Props {
  navigation?: any;
  id?: string;
  // Customizable Area Start

  // Customizable Area End
}

interface S {
  // Customizable Area Start
  open: boolean;
  responseData: Data;
  productData: Options[];
  data: Item[];
  errorMsg: string;
  customerDataWithPreference: CustomerPrefData | undefined;
  snackbarOpen: boolean;
  isDrawerOpen: boolean;
  orderItems: OrderItem[];
  loading: boolean;
  buttonLoading: boolean;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;

  // Customizable Area End
}
// Customizable Area Start


// Customizable Area End

export default class ProductPreferenceController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  productPreferenceCallId: string = "";
  productDetailCallId: string = "";
  getCustomerPreferenceListCallId: string = "";
  getOrdersCallId: string = "";
  updateOrdersCallId: string = "";
  updateCustomerPreferenceListCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
    ];

    this.state = {
      snackbarOpen: false,
      open: false,
      data: [{
        product: "", productId: "", color: "", colorId: "", damage: "", damageId: "", stain: "",
        stainId: "", pattern: "", patternId: "", material: "", materialId: "", options: [], radioOption: "",
        showErrorCheckbox: false, showRadioDataError: false, textNote: "", isSaveFuture: false
      },
      ],
      responseData: {
        id: "",
        type: "",
        attributes: {
          id: 0,
          name: "",
          product_second_name: "",
          specifications: [],
          upcharges: [],
          preferences: [],
          services: []
        }
      },
      productData: [],
      errorMsg: "",
      customerDataWithPreference: undefined,
      isDrawerOpen: false,
      orderItems: [],
      loading: false,
      buttonLoading: false
    };
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  componentDidMount(): any {
    // Customizable Area Start
    this.setState({isDrawerOpen: JSON.parse(localStorage.getItem("isOpen") || "false"), loading: true})
    this.getProductDetail();
    this.getCustomerPreferenceList();
    let orderId = localStorage.getItem('orderId');
    if(orderId) this.getOrders(orderId);
    // Customizable Area End
  }
  // Customizable Area Start
  handleSnackbarClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }

    this.setState({ snackbarOpen: false });
  };

  getProductDetail = () => {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: window.localStorage.getItem(configJSON.token)
    };

    const productDetailMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.productDetailCallId = productDetailMsg.messageId;

    productDetailMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.productApiEndPoint
    );

    productDetailMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    productDetailMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(productDetailMsg.id, productDetailMsg);
  }
  handleResponseForProductDetail = (from: string, message: Message) => {
    if (this.productDetailCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiResponse) {
        this.setState({
          productData: apiResponse?.data.map((item: { attributes: { id: string; name: string; active: boolean; }; }) => ({
            id: item.attributes.id,
            label: item.attributes.name,
            selected: item.attributes.active
          }))
        })
        
      }else {
        this.setState({
          errorMsg:apiResponse.errors[0]
        })
      }
    }
  }
  handleEditClose = () => {
    this.props.navigation.navigate('OrderCreation')
  }

  handleEditPreferenceForCustomer = (data: UpchargeItem[]) => {
    this.updateOrders(data);
    this.setState({buttonLoading: true})
    const bodyData = {
      data: {
        attributes: {
          customer_id: parseInt(localStorage.getItem("preferenceCustomerId") as string),
          preferences_attributes: data?.map((item: any) => {
            return {
              id: Number(item.id),
              catalogue_variant_id: Number(item.variant_id),
              preference_id: Number(item.preferenceId),
              upcharge_list_ids: item.upchargeListIds,
              service_id: item.serviceId,
              notes: item.notes,
              save_for_future: item.saveForFuture,
              pref_spec_options_attributes: item.selectedOptions?.filter((item: {option_id: string}) => Number(item?.option_id) !== 0).map(
                (item: {option_id: string}) => ({
                  specification_option_id: Number(item.option_id),
                })
              ),
            }
          })
        }
      } 
    }

    const headers = {
      "Content-Type": "application/json",
      token: localStorage.getItem("token"),
    };

    const message = new Message(getName(MessageEnum.RestAPIRequestMessage));
    message.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.updatePreferenceAPIEndPoint
    );

    message.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    message.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(bodyData)
    );

    message.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "PUT");
    this.updateCustomerPreferenceListCallId = message.messageId;
    runEngine.sendMessage(message.id, message);

  }

  getOrders = (orderId: string | number) => {
    const message = makeApiMessage({
      method: "GET",
      url: configJSON.getORders + orderId,
    });

    this.getOrdersCallId = message.messageId;
    runEngine.sendMessage(message.id, message);
  }

  updateOrders = (getData: UpchargeItem[]) => {
    let orderId = localStorage.getItem('orderId');
    const httpBody = {
      data: {
        customer_id: parseInt(localStorage.getItem("preferenceCustomerId") as string),
        order_items_attributes: this.state.orderItems.map((item, index) => {
          const catalogue_id = item.attributes.catalogue_id;
          const service_id = item.attributes.service_id;

          let specificationList: SpecificationsAttributes[] = [];

          const CustomerPrefData = this.state.customerDataWithPreference?.attributes.customer_preferences_attributes.
            find((customer_preference) => 
              Number (customer_preference.attributes.service.id) === Number(service_id) &&
              Number (customer_preference.attributes.product.attributes.catalogue.id) === Number(catalogue_id)
            );
            
          specificationList = CustomerPrefData?.attributes.specifications?.length ? CustomerPrefData?.attributes.specifications : [] ;

          const selectedPrefrenceForCatalogueData = getData.find((productPref) => Number(productPref.id) === Number(item.attributes.customer_preference_id)) as UpchargeItem;
          const selectedUpchargeIds = selectedPrefrenceForCatalogueData?.upchargeListIds;
          const selectedPreferenceId = selectedPrefrenceForCatalogueData?.preferenceId;
          const selectedSpecifications = selectedPrefrenceForCatalogueData?.selectedOptions
            ?.filter((element) => element.option_id)
            .map((item) => ({
              specification_option_id: Number(item.option_id),
            }));

            const spesificationData: { [key: string]: string | undefined } = {};
            
            selectedSpecifications.forEach((element) => {
              const optionId = element.specification_option_id;
              const spesification = specificationList.find((element) =>
                element.options_attributes.map((element2) => element2.id).includes(optionId)
            );
            if (spesification) {
              spesificationData[spesification.name] =
              spesification.options_attributes.find(
                (element) => element.id === optionId
              )?.label;
            }
          });
          
          const data = {
            id: item.attributes.id,
            catalogue_id: item.attributes.catalogue_id,
            catalogue_variant_id: item.attributes.catalogue_variant_id,
            category_id: item.attributes.category_id,
            parent_catalogue_id: item.attributes.parent_catalogue_id,
            quantity: item.attributes.quantity,
            preference_id: selectedPreferenceId,
            service_id: item.attributes.service_id,
            specifications: spesificationData,
            upcharge_list_ids: selectedUpchargeIds
          }
          return data
        })
      }
    };
    const message = makeApiMessage({
      body: JSON.stringify(httpBody),
      method: "PUT",
      url: configJSON.getORders + orderId,
    });

    this.updateOrdersCallId = message.messageId;
    runEngine.sendMessage(message.id, message);
  }

  getCustomerPreferenceList = () => {
    this.setState({loading: true})
    const headers = {
      "Content-Type": "application/json",
      token: localStorage.getItem("token"),
    };

    const preferenceIds = JSON.parse(localStorage.getItem('itemsAddedToCart') || "[]").map((item: OrderItemInfo) => {
      return `ids[]=${item.customer_preference_id}`
    });

    const message = new Message(getName(MessageEnum.RestAPIRequestMessage));
    message.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getCustomerPreferenceListAPIEndPoint}${
        localStorage.getItem("preferenceCustomerId")
      }&${preferenceIds.join("&")}`
    );

    message.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    message.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
    this.getCustomerPreferenceListCallId = message.messageId;
    runEngine.sendMessage(message.id, message);
  };

  handleDrawer = (getValue: boolean) => {
    this.setState({ isDrawerOpen: getValue })
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    this.handleResponseForProductDetail(from, message)
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId === this.getCustomerPreferenceListCallId) {
        if (responseJson) {
          this.setState({
            customerDataWithPreference: responseJson.data,
          });
        }
      }  else if (apiRequestCallId === this.updateCustomerPreferenceListCallId) {
          if (responseJson) {
            this.getCustomerPreferenceList();
            this.setState({
              snackbarOpen: true,
              buttonLoading: false
            })
            this.props.navigation.navigate('OrderCreation')
        }
      } else if (apiRequestCallId === this.getOrdersCallId) {
        if (responseJson) {
          this.setState({ orderItems: responseJson.data.attributes.order_items })
        }
      }
    }
    this.setState({loading: false})
    // Customizable Area End
  }
  // Customizable Area End


}
