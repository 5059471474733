import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { IPermissionGroup, IPermissionListingData, IRoleData, ISnackbar, GetIconsResponse, GetIcons } from "./utils";
import { PermissionGroupArray } from "../../../blocks/navigationmenu/src/utils";
import { IUserContext } from "../../../blocks/navigationmenu/src/PageContainerController.web";
import { PermissionStatus, checkForNewPermissonStatus, customPermissionApiKey } from "../../../blocks/utilities/src/CustomBlockHelpers";

// Customizable Area Start
export enum FormMode {
  Create,
  Edit,
  View
}
import React from "react";

interface PermissionObj {
  permission_group_ids: Array<number>;
  permission_sub_group_ids: Array<number>;
};
interface ImageInterface {
  id:number,
  option:string,
  image:string
};
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  // Customizable Area Start
  formMode: FormMode;
  roleName: string;
  errorRoleName: string;
  selectedPermission: number[]
  permissionListing: IPermissionListingData[];
  roleId: number | null
  loading: boolean
  isSubmitting: boolean
  snackbar: ISnackbar;
  icons: GetIcons[];
  selectedIcon: GetIcons;
  selectedIconId : string;
  openPermissionModal: boolean;
  permissionGroups: Array<IPermissionGroup>;
  permission_sub_group_ids: Array<number>;
  permission_group_ids: Array<number>;
  mainPermissionGroups: Array<IPermissionGroup>;
  selectedPermissions: Array<IPermissionGroup>;
  permissionStatus: PermissionStatus;
  iconList:ImageInterface[];
  currentIconData:GetIconsResponse[];
  productNextPage:number;
  responseIconList:GetIconsResponse[];
  selectedImage: { id: string | number, option: string, image: string };
  defaultIcon?: string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class RolesPermissionsCreate2Controller extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getPermissionListingApiId: string
  getRoleDetailApiId: string
  createRoleApiId: string
  updateRoleApiId: string
  getIconsApiId :string
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.getPermissionListingApiId = ""
    this.getRoleDetailApiId = ""
    this.createRoleApiId = ""
    this.updateRoleApiId = ""
    this.getIconsApiId = ""
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIResponceErrorMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage)
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      // Customizable Area Start
      formMode: FormMode.Create,
      roleName: "",
      errorRoleName: "",
      selectedPermission: [],
      permissionListing: [],
      roleId: null,
      loading: true,
      isSubmitting: false,
      snackbar: {
        open: false,
        message: "",
        severity: 'info'
      },
      icons: [],
      selectedIcon:{} as GetIcons,
      selectedIconId:'',
      openPermissionModal: false,
      permissionGroups: [],
      permission_sub_group_ids: [],
      permission_group_ids: [],
      mainPermissionGroups: [],
      selectedPermissions: [],
      permissionStatus: {
        mainPermission: false,
        createPermission: false,
        viewPermission: false,
        editPermission: false,
        deactivatePermission: false
      },
      iconList:[],
      currentIconData:[],
      productNextPage:1,
      responseIconList:[],
      selectedImage:{} as { id: string | number, option: string, image: string },
      defaultIcon: ""

      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      this.handleResponse(apiRequestCallId, message)
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    this.getPermissionListing();
    this.getIcons();

    this.processPath(window.location.pathname);
  }

  FormatError = (errors: Array<{[key: string] : string}>) => {
    if(!Array.isArray(errors)) return configJSON.textSomethingWentWrong;
    let allerrors = "";
    errors.forEach(object => {
      const newLocal = JSON.stringify(object);
      JSON.parse(newLocal, (_parcekey, value) => {
        if (value.length > 0) {
          allerrors =
            allerrors.length <= 0 ? value : `${allerrors}{\n}${value}`;
        }
      });
    });
    return allerrors;
  }

  errorResponseHandler = (responseJson: { errors: Array<{[key: string]: string}>}) => {
    let text = this.FormatError(responseJson.errors)
    this.setState({
      snackbar: {
        open: true,
        severity: "error",
        message: text
      }
    })
    this.setState({ isSubmitting: false })
  }

  createUpdateApiHandler = (text: string) => {
    this.setState({
      snackbar: {
        open: true,
        severity: "success",
        message: text
      }
    })
    const preventClick = (event: globalThis.MouseEvent) => {
      event.preventDefault()
      event.stopPropagation()
    }
    window.document.addEventListener("click",preventClick)
    setTimeout(() => {
      window.document.removeEventListener('click', preventClick)
      this.setState({isSubmitting: false})
      this.props.navigation.goBack()
    }, 1000);
  }

  handleResponse = (apiRequestCallId: string, message: Message) => {
    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    let errorReponse = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );

    if (apiRequestCallId && responseJson && !errorReponse) {
      if (responseJson?.errors || responseJson?.error) {
        this.errorResponseHandler(responseJson)
        return
      }
      switch (apiRequestCallId) {
        case this.getPermissionListingApiId:
          this.setState({
            permissionListing: responseJson.data,
            mainPermissionGroups:
              responseJson.data.map((list: IPermissionListingData) => list.attributes)
          });
          break;
        case this.getRoleDetailApiId:
          const permissionTrue: number[] = []
          responseJson.data.attributes.permission_groups.forEach((permissionGrp: IPermissionGroup) => {
            if (permissionGrp.permitted) { permissionTrue.push(permissionGrp.id) }
          });
          const permissionGroup = responseJson.data.attributes.permission_groups;
         this.setState({
            roleName: responseJson.data.attributes.name,
            selectedIcon: 
              this.getSelectedIcon(responseJson.data.attributes.gallery_icon?.id, this.state.icons),
            defaultIcon: responseJson.data.attributes.gallery_icon?.id,
            loading: false,
            mainPermissionGroups: permissionGroup,
            permission_group_ids: 
              this.handleReturnSelectedPermission(permissionGroup).permission_group_ids,
            permission_sub_group_ids: 
              this.handleReturnSelectedPermission(permissionGroup).permission_sub_group_ids,
            selectedPermission: permissionTrue,
            selectedImage:{id:responseJson.data.attributes.gallery_icon?.id,option:responseJson.data.attributes.gallery_icon?.name,image:responseJson.data.attributes.gallery_icon?.image}
          }, 
            () => this.handlePermissionViewArray()
          );
          break;
        case this.createRoleApiId:
          this.createUpdateApiHandler(configJSON.textRoleCreatedSucc)
          break;
        case this.updateRoleApiId:
          this.createUpdateApiHandler(configJSON.textRoleUpdatedSucc)
          break;
        case this.getIconsApiId: 
          this.handleIconsResponse(responseJson.data)
          break;
        default:
          break;
      }
    } else if (errorReponse) {
      this.errorResponseHandler(errorReponse)
    }
  }

  processPath = (path: string) => {
    if (/^\/Settings-RolesPermissionsEdit2/.exec(path)) {
      const roleId = this.props.navigation.getParam('roleId')
      this.setState({ formMode: FormMode.Edit, roleId: roleId }, () => this.getIcons());
      this.getRoleDetail(roleId)
    } else if (/^\/Settings-RolesPermissionsView2/.exec(path)) {
      const roleId = this.props.navigation.getParam('roleId')
      this.setState({ formMode: FormMode.View, roleId: roleId });
      this.getRoleDetail(roleId)
    } else if (/^\/Settings-RolesPermissionsCreate2/.exec(path)) {
      this.setState({ loading: false });
    }
  };

  cancelHandler = () => {
    this.props.navigation.goBack()
  }

  onRoleNameChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    let message = ""
    if (!event.target.value) {
      message = configJSON.textInvalidRoleName
    }
    this.setState({ roleName: event.target.value, errorRoleName: message })
  }

  onSubmitHandler = () => {
    if (!this.state.roleName) {
      alert(configJSON.textInvalidRoleName)
      this.setState({ errorRoleName: configJSON.textInvalidRoleName })
      return
    }
    this.setState({ isSubmitting: true })
    if (/^\/Settings-RolesPermissionsEdit2/.exec(window.location.pathname)) {
      this.updateRole();
    } else {
      this.createRole();
    }
  }

  handleSnackbarClose = (_event?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({ snackbar: {open: false, message: "", severity: 'info'} })
  };

  handleEditAccountClick = (roleId: number|null) => {
    this.props.navigation.navigate("RolesPermissionsEdit2", {
      roleId: roleId,
    });
    this.setState({ formMode: FormMode.Edit });
  };

  getPermissionListing = () => {
    const header = {
      "Content-Type": configJSON.applicationJsonContentType,
      "token": localStorage.getItem('token')
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getPermissionListingApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getPermissionGroupListingUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getRoleDetail = (roleId: number) => {
    const header = {
      "Content-Type": configJSON.applicationJsonContentType,
      "token": localStorage.getItem('token')
    };

    const apiUrl = configJSON.showRoleUrl + roleId

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getRoleDetailApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  createRole = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": localStorage.getItem('token'),
    };

    const BodyJSON = {
      data: {
        attributes: {
          "name": this.state.roleName,
          "icon_id": this.state.selectedImage.id,
          "permission_group_ids": this.state.permission_group_ids,
          "permission_sub_group_ids": this.state.permission_sub_group_ids,
        }
      }
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.createRoleApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createRoleUrl
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(BodyJSON)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  updateRole = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": localStorage.getItem('token')
    };

    const BodyJSON = {
      data: {
        attributes: {
          "name": this.state.roleName,
          "icon_id": this.state?.selectedImage?.id,
          "permission_group_ids": this.state.permission_group_ids,
          "permission_sub_group_ids": this.state.permission_sub_group_ids,
        }
      }
    }
    const apiUrl = configJSON.updateRoleUrl + this.state.roleId

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.updateRoleApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiUrl
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(BodyJSON)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getIcons = ()=>{
    const header = {
      "Content-Type": configJSON.applicationJsonContentType,
      "token": localStorage.getItem('token')
    };

    const apiUrl = this.state.formMode === FormMode.Create ? configJSON.getIcons+`?page_no=${this.state.productNextPage}` : configJSON.getIcons

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getIconsApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleIconsResponse = (responseData: GetIconsResponse[]) => {
    const parsedIcons =  responseData.reduce((accIcon:GetIcons[],current:GetIconsResponse)=>{

           const tempData =  {} as GetIcons

           tempData.id =  current.attributes.id;
           tempData.item =  <img  src={current.attributes.image} height={24} width={24} alt={current.attributes.name}/>;
           tempData.title = current.attributes.image;

           return [...accIcon, tempData];

    },[])
    const iconWholeData = [...this.state.responseIconList,...responseData]
    
    const parsedIconList:ImageInterface[] = iconWholeData.map((data: GetIconsResponse)=>{
      return {id:data.attributes.id,image:data.attributes.image,option:data.attributes.name}
    })
    this.setState({
      icons: parsedIcons,
      selectedIcon: parsedIcons[0],
      iconList:parsedIconList,
      responseIconList:iconWholeData,
      currentIconData:responseData
    })  
  }

  getSelectedIcon = (iconId:number, icons:GetIcons[]) => {
    const icon = icons.find((item:GetIcons) => item.id == iconId)
    return icon as GetIcons;
  }

  selectIcon = (item:GetIcons)=>{
    this.setState({
      selectedIcon: item,
    })
  }

  handleClosePermissionModal = () => {
    this.setState({
      openPermissionModal: false
    });
  };

  handleChangePermission = (
    permission: PermissionObj,
    returnedPermissions: Array<IPermissionGroup>
  ) => {
    this.setState({
      permission_group_ids: permission.permission_group_ids,
      permission_sub_group_ids: permission.permission_sub_group_ids,
      openPermissionModal: false,
      mainPermissionGroups: returnedPermissions
    }, () => this.handlePermissionViewArray());
  };
  
  handleOpenPermissionModal = () => {
    this.setState({
      openPermissionModal: true
    });
  };
  
  handleReturnSelectedPermission = (permissionGroups: Array<IPermissionGroup>) => {
    let permission_group_ids: Array<number> = [];
    let permission_sub_group_ids: Array<number> = [];
    permissionGroups.forEach((parentItem) => {
      parentItem.permission_sub_groups.forEach((subItem) => {
        if (subItem.permitted) {
          permission_group_ids.push(parentItem.id);
          permission_sub_group_ids.push(subItem.id);
        }
      });
    });
    const outputObj = {
      permission_group_ids: permission_group_ids,
      permission_sub_group_ids: permission_sub_group_ids
    }
    return outputObj;
  };
  
  handleRemoveDuplicates = (array: Array<IPermissionGroup>) => {
    return array.filter((item, index) => array.indexOf(item) === index);
  };
  
  getSubmiBtnText = () => {
    let submitBtnText = ""
    if (this.state.formMode === FormMode.Create) {
      submitBtnText = configJSON.btnCreateText
      return submitBtnText
    } else {
      submitBtnText = configJSON.btnUpdateText
      return submitBtnText
    }
  };
  
  renderHeadingTitle = () => {
    switch(this.state.formMode){
      case FormMode.Create:
        return configJSON.labelCreateRoleHeading
        case FormMode.Edit:
          return configJSON.labelEditRoleHeading
          case FormMode.View:
            return configJSON.labelViewRoleHeading
            default :
            break;
          }
        };
        
        handlePermissionViewArray = () => {
          const { permission_sub_group_ids, mainPermissionGroups } = this.state;
          
          const tempNewArray: Array<IPermissionGroup> = [];
          mainPermissionGroups.forEach((parentItem) => {
            parentItem.permission_sub_groups.forEach((subItem) => {
              if (permission_sub_group_ids.includes(subItem.id)) {
          tempNewArray.push(parentItem)
        }
      });
    });
    
    this.setState({
      selectedPermissions: this.handleRemoveDuplicates(tempNewArray),
    });
  };
  
  returnFormModeView = () => {
    const { formMode } = this.state;
    
    if(formMode === FormMode.View) {
      return true;
    } else {
      return false;
    }
  };
  
  handleUserChange = (userContext: IUserContext) => {
    const apiKey = customPermissionApiKey.rolePermission;
    const userData = userContext.user?.attributes.permission_groups;
    const value = checkForNewPermissonStatus(apiKey, userData as Array<PermissionGroupArray>);
    this.setState({
      permissionStatus: value
    })
  };
  
  handleIconScroll = (event: React.SyntheticEvent) => {
    const listboxNode = event.currentTarget;
    const position = listboxNode.scrollTop + listboxNode.clientHeight;
    
    if (listboxNode.scrollHeight - position <= 1.30 && this.state.currentIconData.length !== 0) {
      this.setState({ productNextPage: this.state.productNextPage + 1 }, () => {
        this.getIcons()
      })
    }
  };
  handleSelectImage = (value:{ id: string | number, option: string, image: string }) => {
    this.setState({
      selectedImage:value,
    })
  }
  
  // Customizable Area End
}
