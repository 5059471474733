
import React from 'react'

import {Button} from '@material-ui/core'
import { CSSProperties } from "@material-ui/styles";
import { Divider } from 'react-native-elements';





interface PropTypes {
    value: string;
    height:number;
    width:number;
    color:string,
    backgroundColor:string,
    borderRadius:number,
    onClick?:()=>void
    type?:"button" | "reset" | "submit" | undefined,
    fontWeight?:number,
    isUnderLine?: boolean 
}


interface ButtonStyle extends CSSProperties {
    textTransform: 'none' | undefined;
    fontWeight: number;
    fontSize: string;
    
  }
  



export default function CustomButton({value, height, type='button', width,color, fontWeight = 800,  backgroundColor, borderRadius, onClick, isUnderLine}:PropTypes) {


  const btnStyle:ButtonStyle  = {
       textTransform:'none',
       height:`${height}px`,
       width: `${width}px`,
       color: color,
       backgroundColor: backgroundColor,
       borderRadius:`${borderRadius}px`,
       fontWeight: fontWeight,
       fontSize: '16px',
       letterSpacing:0,
       lineHeight:1
       
  }





  return (
    <>
    <Button data-test-id={value} onClick={onClick} type={type} style={btnStyle}>
     {isUnderLine ?  
     <span style={{borderBottom:'1px solid red'}}>
      {value}
      </span> : value }    
      
      </Button>
    
    </>
  )
}
