import React from "react";

import { Input, Button, Typography } from '@builder/component-library';

// Customizable Area Start
import Print from "../../print/src/Print.web";

// Customizable Area End

import CfthermopatchtaggingsolutionintegrationController, {
  Props,
  configJSON,
} from "./CfthermopatchtaggingsolutionintegrationController";

export default class Cfthermopatchtaggingsolutionintegration extends CfthermopatchtaggingsolutionintegrationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Print 
        navigation={this.props.navigation} 
        id="Garment Tag Printer" 
        isGarmentTagSetting
      />
      // Customizable Area End
    );
  }
}

// Customizable Area Start

// Customizable Area End
