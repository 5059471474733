// Customizable Area Start
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Typography,
  Box
} from "@material-ui/core";
import clsx from "clsx";

const BtnRemoveItem = require('./assets/button_remove_item.png')
const BtnAddItem = require('./assets/button_add_item.png')
const IconWashingClothe = require('./assets/icon_washing_clothes.png')
const Icontest = require('./assets/icontest.jpg')
const DefaultProduct = require('./assets/ProductImgDefault.png')
// Customizable Area End

// Customizable Area Start

export interface IService {
  data: {
    id: string;
    attributes: {
      id: number;
      name: string;
      active: boolean;
      short_name: string;
      icon: {
        data: {
          attributes: {
            image: string;
          }
        }
      }
    }
  }
}

interface ProductProps {
  addQuantityClickHandler?: (props: any) => void,
  catalogue?: any,
  productType?: string,
  removeQuantityClickHandler?: (props: any) => void,
  serviceClickHandler?: (props: any) => void,
  selectedProductQuantity?: number,
  selectedServiceId?: number | string,
  parentProductId?: number,
  tabsId: number,
  isQuickDrop?:boolean
}
// Customizable Area End

// Customizable Area Start
const useStyles = makeStyles(() => ({
  productCardWrapper: {
    borderRadius: '8px',
    border: "1px solid  #ECECEC",
    boxShadow: "0px 8px 32px 0px #0000000F, 0px 4px 8px 0px #00000008",
    padding: '12px 24px',
    height: "100%",
    backgroundColor: '#FFF',
    "@media only screen and (max-width:1160px)": {
      padding: '12px',
    },
  },
  detailsContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  productImgContainer: {
    width: '129px',
    height: '129px',
    borderRadius: '8px',
    "@media only screen and (max-width:1160px)": {
      width: '76px',
      height: '76px',
    },
  },
  productImg: {
    width: '100%',
    height: '100%'
  },
  temporaryProductDiv: {
    height: 129,
    background: '#E9E9E9',
    borderRadius: 8,
    width: 129,
    "@media only screen and (max-width:1160px)": {
      width: '76px',
      height: '76px',
    },
  },
  productTitle: {
    color: '#1A1A1A',
    fontSize: '18px',
    fontWeight: 500,
    marginTop: '14px',
    lineHeight: '22px',
    textAlign: "center",
    "@media only screen and (max-width:1160px)": {
      fontSize: '14px',
    },
  },
  productPrice: {
    color: "#1A1A1A",
    fontSize: "14px",
    fontWeight: 500,
    letterSpacing: "-0.525px",
    opacity: 0.4,
    marginTop: '8px'
  },
  productQuantityContainer: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '12px',
    "& img": {
      height: 30,
      width: 30
    },
    "@media only screen and (max-width:1160px)": {
      "& img": {
        height: 24,
        width: 24
      },
    },
  },
  disabledQuantity: {
    opacity: 0.3,
    pointerEvents: 'none'
  },
  quantityCount: {
    margin: '0 16px'
  },
  serviceContainer: {
    display: 'flex',
    gap: '24px',
    marginTop: '20px',
    flexWrap: 'wrap',
    "@media only screen and (max-width:1160px)": {
      gap: '12px',
    },
  },
  serviceSelectBar: {
    width: '32px',
    height: '2px',
    borderRadius: '2px',
    backgroundColor: 'white',
    marginTop: '8px',
    "@media only screen and (max-width:1160px)": {
      width: '24px',
    },
  },
  serviceIcon: {
    width: '32px',
    height: '32px',
    borderRadius: '8px',
    "@media only screen and (max-width:1160px)": {
      width: '24px',
      height: '24px',
    },
  }
}));
// Customizable Area End

// Customizable Area Start
const ProductCard: React.FC<ProductProps> = (props) => {
  const catalogueData = props?.catalogue?.attributes?.catalogue_variants?.[0]?.attributes
  const addQuaitityHandler = () => {
    if (props.isQuickDrop)return
    if (props?.addQuantityClickHandler ) {
      props.addQuantityClickHandler({
        catalogue_id: props?.catalogue?.attributes?.id,
        catalogue_variant_id: catalogueData?.id,
        title: catalogueData?.name,
        price: catalogueData?.price,
        image: catalogueData?.image?.image,
        productType: props.productType,
        parentProductId: props?.parentProductId,
        tabsId: props?.tabsId
      })
    }
  }
  const removeQuaitityHandler = () => {
    if (props.isQuickDrop)return
    if (disableRemoveQuaitityBtn) {
      return
    }
    if (props?.removeQuantityClickHandler) {
      props.removeQuantityClickHandler({
        catalogue_id: props?.catalogue?.attributes?.id,
        catalogue_variant_id: catalogueData?.id,
        title: catalogueData?.name,
        price: catalogueData?.price,
        image: catalogueData?.image?.image,
        productType: props.productType,
        parentProductId: props?.parentProductId,
        tabsId: props?.tabsId
      }
      )
    }
  }

  const serviceClickHandler = (serviceProps: any) => {
    if (disableRemoveQuaitityBtn) {
      return
    }
    if (props?.serviceClickHandler) {
      props.serviceClickHandler({
        ...serviceProps,
        catalogue_id: props?.catalogue?.attributes?.id,
        catalogue_variant_id: catalogueData?.id,
        title: catalogueData?.name,
        image: catalogueData?.image?.image,
        productType: props.productType,
        parentProductId: props?.parentProductId,
        tabsId: props?.tabsId,
        productSecondName: props?.catalogue?.attributes?.product_second_name
      })
    }
  }

  const disableRemoveQuaitityBtn = props?.selectedProductQuantity !== undefined && props?.selectedProductQuantity < 1

  const classes = useStyles();

  const services = props.catalogue?.attributes?.catalogue_variants[0]?.attributes?.catalogue_variants_services?.data || []

  return (
    <Box
      className={classes.productCardWrapper}
    >
      <Box className={classes.detailsContainer}>
        <Box className={classes.productImgContainer}>
          {/* Remove fallback image */}
          {props?.catalogue?.attributes?.maximum_washing_cycle
            ? <img className={classes.productImg} src={catalogueData?.image?.image || DefaultProduct}></img>
            : <div className={classes.temporaryProductDiv} />
          }
        </Box>
        <Typography className={classes.productTitle}>{catalogueData?.name}</Typography>
        <Typography className={classes.productPrice}>Price: SAR {props?.selectedServiceId?findPrice(services, props?.selectedServiceId ):services.find((el:any)=>el.attributes.default)?.attributes.price || 0}</Typography>

        <Box className={classes.productQuantityContainer}>
          <img src={BtnRemoveItem}
            className={
            (disableRemoveQuaitityBtn)
              ? classes.disabledQuantity : ''
            }
            onClick={removeQuaitityHandler}
            data-test-id="productRemove"
          ></img>
          <Typography data-test-id='field-quantity' className={classes.quantityCount}>{props.selectedProductQuantity}</Typography>
          <img 
            src={BtnAddItem}
            onClick={addQuaitityHandler}
            data-test-id="productAdd"
          ></img>
        </Box>
        <Box className={classes.serviceContainer}>
          {
            services.map((item: {
              id: string;
              attributes: {
                price: number;
                service: IService;
              }
            }) => {
              const price = Number(item?.attributes?.price)
              const service = item?.attributes?.service?.data
              const isActive = service?.attributes?.active == true
              
              if (!isActive) return <></>

              return (
                <Box
                  key={service?.id}
                  onClick={() => {
                    if (disableRemoveQuaitityBtn) {
                      serviceClickHandler({ serviceId: null, serviceName: '', price: 0 })
                      return
                    }
                    serviceClickHandler({
                      serviceId: service?.attributes?.id,
                      serviceName: service?.attributes?.name,
                      price,
                      serviceShortName: service?.attributes?.short_name
                    })
                  }}
                  data-test-id={'btn-service-' + service?.attributes?.name}
                >
                  <div className={classes.serviceIcon}>
                    <img 
                      width={"100%"}
                      height={"100%"}
                      src={service?.attributes?.icon?.data?.attributes?.image || IconWashingClothe}
                    ></img>
                  </div>
                  <div
                    className={classes.serviceSelectBar}
                    style={
                      props.selectedServiceId === service?.attributes?.id
                        ? { backgroundColor: "#204B9C" }
                        : {}
                    }
                  />
                </Box>
              )
            })
          }
        </Box>
      </Box>
    </Box>
  );
};

export function findPrice(services: Array<{
  attributes: {
    service: IService;
    price: string;
  }
}>, selectedServiceId: number | unknown) {
  let item = services?.find((item: {
    attributes: {
      service: IService;
      price: string;
    }
  }) => item?.attributes?.service?.data?.id == String(selectedServiceId));

  let price = item ? Number(item?.attributes?.price) : '0';
  return price;
}


export default ProductCard
// Customizable Area End
