export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const upArrow = require("../assets/up.png");
export const downArrow = require("../assets/down.png");
export const checked = require("../assets/check_box.png");
export const unchecked = require("../assets/blank_check_box.png");
export const Delete = require("../assets/delete.png");
export const Check =  require("../assets/image_check.svg");
export const DragHandle = require("../assets/imageDrag.svg");
export const CheckBoxTrue = require("../assets/check_true.svg");
export const CheckBoxFalse = require("../assets/check_false.svg");
export const openCircle = require("../assets/skip_radio.png");
export const filledCircle = require("../assets/skip_radioCircle.png");
export const checkedIcon = require("../assets/checkedIcon.png");
export const unCheckedIcon = require("../assets/unCheckedIcon.png");
export const filterIcon = require("../assets/filter_icon.png");
export const activeFilterIcon = require("../assets/filter_active_icon.png");
export const sortingImage = require("../assets/image_swap.svg")
