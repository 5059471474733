import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
import { apiCall } from "../../../components/src/common";
import { navigateTo } from "../../utilities/src/CustomBlockHelpers";
import { IFilter } from "../../../components/src/FilterPopover";
import { handleMomentDateFormat } from "../../cfcashier/src/utils";
import moment from "moment";
interface CashInOutStatus {
  cash_in: string;
  cash_out: string;
}

interface RevenueDetail {
  orders: number;
  total: string;
}

interface RevenueData {
  total_revenue: string;
  laundry_revenue: RevenueDetail;
  home_cleaning_revenue: RevenueDetail;
}

interface StoreData {
  store_name: string;
  amount: string;
}

interface PaymentMethod {
  payment_method_name: string;
  total_amount: string;
  total_quantity: number;
}

// Customizable Area End

export const webConfigJSON = require("./config.js");

export interface Props {
  navigation: any;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  dashboardData: {
    type: string;
    quantity: string;
  }[];
  totalCandidates: string;
  type: string;
  token: string;
  errorMsg: string;
  loading: boolean;
  overAllSale: string;
  saleRevenue: RevenueData;
  viewAllStoreFlag: boolean;
  cashInOutData: CashInOutStatus
  storeId: string;
  regionId: string;
  filterAnchor: HTMLDivElement | undefined;
  filters: IFilter[];
  exportCsvFlag: boolean;
  receivedDataSaved: { [key: string]: string; };
  storeRevenue: StoreData[];
  paymentModeRevenue: PaymentMethod[];
  refershStoreList: boolean;
  // Customizable Area End
}
interface SS {}

export default class DashboardController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  apiDashboardItemCallId: string = "";
  dashboardApiCallId: string = "";
  apiGetQueryStrinurl: string = "";
  overAllSaleCallID: string = "";
  saleRevenueCallID: string = "";
  cashInOutCallId: string = "";
  getUserDataCallId: string = "";
  storeRevenueCallID: string = "";
  paymentModeRevenueCallID: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.BroadcastNavbarDataMessage),
      getName(MessageEnum.BroadcastNavbarData)
    ];

    this.state = {
      type: "",
      dashboardData: [],
      totalCandidates: "",
      errorMsg: "",
      token: "",
      loading: false,
      overAllSale: "",
      saleRevenue: {
        total_revenue: "0.00",
        laundry_revenue: {
          orders: 0,
          total: "0.00"
        },
        home_cleaning_revenue: {
          orders: 0,
          total: "0.00"
        }
      },
      cashInOutData: {
        cash_in: "",
        cash_out: ""
      },
      viewAllStoreFlag: false,
      storeId: "",
      regionId: "",
      filterAnchor: undefined,
      filters: [
        {
          title: "Date",
          value: "today",
          type: "dateselect",
          datevalue: { from: "", to: "" },
          options: [
            {
              label: "Today",
              value: "today",
            },
            {
              label: "Last 7 days",
              value: "last7",
            },
            {
              label: "Last 30 days",
              value: "last30",
            },
            {
              label: "Specific Dates",
              value: "specific",
            },
          ]
        }
      ],
      exportCsvFlag: false,
      receivedDataSaved:{},
      storeRevenue:[],
      paymentModeRevenue: [],
      refershStoreList: false
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getDashboardData();
    // Customizable Area Start
    // Customizable Area End
  }

  getDashboardData(): boolean {
    // Customizable Area Start
    
    // Customizable Area End
    return true;
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    this.handleResForStoreRevenue(from,message)
    this.handleResForPaymentModeRevenue(from,message)
    this.handleResForOverAllSale(from,message)
    this.handleResForSaleRevenue(from,message)
    this.handleResForCashInOut(from,message)
    if (message.id === getName(MessageEnum.BroadcastNavbarDataMessage)) {
      const recievedData = message.getData(
        getName(MessageEnum.BroadcastNavbarData)
      );
      if(recievedData.storeId && !this.state.viewAllStoreFlag){
        this.setState({storeId:recievedData.storeId, regionId: recievedData.regionMultiId  ,receivedDataSaved: recievedData},()=>{
          this.getOverAllSale()
          this.getSaleRevenue()
          this.getCashInOutData()
          this.getAllStoreRevenue()
          this.getPaymentModeRevenue()
        })
      }
    }
    // Customizable Area End
  }
  // Customizable Area Start
  getOverAllSale = async () => {
    const dateFilterQuery = this.handleDateFilterParam().dateFilterQuery
    let headers = {
      token: await getStorageData('token'),
      "Content-Type": webConfigJSON.dashboarContentType,
    };

    const requestMessage = apiCall({
      httpBody: {},
      header: headers,
      url: `${webConfigJSON.getOverAllSaleEndpoint}?store_ids=${this.state.storeId}&region_id=${this.state.regionId}${dateFilterQuery}`,
      httpMethod: webConfigJSON.dashboarApiMethodType,
    });

    this.overAllSaleCallID = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getSaleRevenue = async () => {
    const dateFilterQuery = this.handleDateFilterParam().dateFilterQuery
    let headers = {
      token: await getStorageData('token'),
      "Content-Type": webConfigJSON.dashboarContentType,
    };

    const requestMessage = apiCall({
      httpBody: {},
      header: headers,
      url: `${webConfigJSON.saleRevenueEndpoint}?store_ids=${this.state.storeId}&region_id=${this.state.regionId}${dateFilterQuery}`,
      httpMethod: webConfigJSON.dashboarApiMethodType,
    });

    this.saleRevenueCallID = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getCashInOutData = async () => {
    const dateFilterQuery = this.handleDateFilterParam().dateFilterQuery
    let headers = {
      token: await getStorageData('token'),
      "Content-Type": webConfigJSON.dashboarContentType,
    };

    const requestMessage = apiCall({
      httpBody: {},
      header: headers,
      url: `${webConfigJSON.cashInOutEndpoint}?store_ids=${this.state.storeId}&region_id=${this.state.regionId}${dateFilterQuery}`,
      httpMethod: webConfigJSON.dashboarApiMethodType,
    });

    this.cashInOutCallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getAllStoreRevenue = async () => {
    const dateFilterQuery = this.handleDateFilterParam().dateFilterQuery
    let headers = {
      token: await getStorageData('token'),
      "Content-Type": webConfigJSON.dashboarContentType,
    };

    const requestMessage = apiCall({
      httpBody: {},
      header: headers,
      url: `${webConfigJSON.getAllStoreRevenue}?store_ids=${this.state.storeId}&region_id=${this.state.regionId}${dateFilterQuery}`,
      httpMethod: webConfigJSON.dashboarApiMethodType,
    });

    this.storeRevenueCallID = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getPaymentModeRevenue = async () => {
    const dateFilterQuery = this.handleDateFilterParam().dateFilterQuery
    let headers = {
      token: await getStorageData('token'),
      "Content-Type": webConfigJSON.dashboarContentType,
    };

    const requestMessage = apiCall({
      httpBody: {},
      header: headers,
      url: `${webConfigJSON.getPaymentModeRevenue}?store_ids=${this.state.storeId}&region_id=${this.state.regionId}${dateFilterQuery}`,
      httpMethod: webConfigJSON.dashboarApiMethodType,
    });

    this.paymentModeRevenueCallID = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleResForOverAllSale = async (from: string, message: Message) => {
    if (this.overAllSaleCallID === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson.status == 200) {
        this.setState({overAllSale:responseJson.data})
      }
    }
  }

  handleResForSaleRevenue = async (from: string, message: Message) => {
    if (this.saleRevenueCallID === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson.status == 200) {
        this.setState({ saleRevenue: responseJson.data})
      }
    }
  }

  handleResForCashInOut = async (from: string, message: Message) => {
    if (this.cashInOutCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson.status == 200) {
        this.setState({ cashInOutData: {cash_in: responseJson.cash_in, cash_out: responseJson.cash_out}})
      }
    }
  }

  handleResForStoreRevenue = async (from: string, message: Message) => {
    if (this.storeRevenueCallID === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson.status == 200) {
        this.setState({storeRevenue:responseJson.data})
      }
    }
  }

  handleResForPaymentModeRevenue = async (from: string, message: Message) => {
    if (this.paymentModeRevenueCallID === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson.status == 200) {
        this.setState({paymentModeRevenue:responseJson.data})
      }
    }
  }

  handleStoreViewAllPage = (stats: boolean, filterStats:IFilter[] = this.state.filters) => {
    this.setState({ viewAllStoreFlag: stats , filters:filterStats}, () => {
      let msgData = new Message(getName(MessageEnum.BroadcastNavbarDataMessage),)
      msgData.addData(getName(MessageEnum.BroadcastNavbarData), this.state.receivedDataSaved)
      this.send(msgData)
    })
  }

  handleNavigation = (screenName: string)=>{
    navigateTo({screenName,props:this.props})
  }

  handleCloseFilterPopover = () => {
    this.setState({ filterAnchor: undefined })
  }

  handleFilterChangeAccount = (filters: IFilter[]) => {
    this.setState({filters,refershStoreList:!this.state.refershStoreList},()=>{
      this.getOverAllSale()
      this.getSaleRevenue()
      this.getCashInOutData()
      this.getAllStoreRevenue()
      this.getPaymentModeRevenue()
    });
  };

  handleFilterOpen = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    this.setState({ filterAnchor: event.currentTarget })
  }

  handleDateFilterParam = () => {
    const today = moment().format('YYYY-MM-DD');
    let comomStartDate = moment().subtract(7, 'days').format('YYYY-MM-DD');
    const dateFilter = this.state.filters.find((item) => item?.title === 'Date')?.value;
    let commonEndDate = today;
    let startDate, endDate;
    switch (dateFilter) {
      case 'last7':
        endDate = commonEndDate;
        startDate = comomStartDate;
        break;
      case 'today':
        startDate = today;
        endDate = today;
        break;
      case 'specific':
        const dateFilter = this.state.filters.find((item) => item?.title === 'Date')?.datevalue;
        if (dateFilter?.from && dateFilter?.to) {
          endDate = moment(dateFilter.to).format('YYYY-MM-DD');
          startDate = moment(dateFilter.from).format('YYYY-MM-DD');
        }
        break;
      case 'last30':
        startDate = moment().subtract(30, 'days').format('YYYY-MM-DD');
        endDate = today;
        break;
      default:
        endDate = today;
        startDate = moment().subtract(7, 'days').format('YYYY-MM-DD');
    }
    const startDateText = startDate ? handleMomentDateFormat(startDate, 'DD-MM-YYYY') : moment().subtract(7, 'days').format('DD-MM-YYYY')
    const endDateText = endDate ? handleMomentDateFormat(endDate, 'DD-MM-YYYY') : moment().format('DD-MM-YYYY')

    const dateFilterQuery = startDate && endDate
      ? `&date_type=specific_dates&start_date=${startDate}&end_date=${endDate}`
      : '';

    return { startDateText, dateFilterQuery, endDateText }
  };

  handleExportCsv = () =>{
    this.setState({exportCsvFlag:!this.state.exportCsvFlag})
  }

  getSelectedFilterLabel = () => {
    const data = this.state.filters
    const selectedValue = data[0].value;
    const options = data[0].options;
    if (options) {
      for (let option of options) {
        if (option.value === selectedValue) {
          return option.label;
        }
      }
      return "";
    }
  }

  // Customizable Area End
}
