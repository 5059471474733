import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgBell } from "./assets";
import { makeApiMessage } from "../../../components/src/common";
import { CustomEnums, getCustomEnumName, navigateTo } from "../../utilities/src/CustomBlockHelpers";

export interface Account {
  id: number;
  first_name: string;
  last_name: string;
  full_phone_number: string;
  country_code: number;
  phone_number: number;
  email: string;
  activated: boolean;
  device_id: string | null;
  unique_auth_id: string;
  password_digest: string;
  type: string;
  created_at: string;
  updated_at: string;
  user_name: string | null;
  platform: string | null;
  app_language_id: string | null;
  last_visit_at: string | null;
  is_blacklisted: boolean;
  suspend_until: string | null;
  status: string;
  stripe_id: string | null;
  stripe_subscription_id: string | null;
  stripe_subscription_date: string | null;
  role_id: number;
  full_name: string;
  gpa: number | null;
  gender: string | null;
  date_of_birth: string | null;
  age: number | null;
  email_sent: string | null;
  notes: string | null;
  express_delivery_enable: boolean;
  user_type: string;
  profile_request: boolean;
  initial_password: string;
  user_permissions_count: number;
  total_reward_points: number;
  in_app_notification: boolean;
  email_notification: boolean;
  sms_notification: boolean;
  whatsapp_notification: boolean;
  pause_notification: boolean;
  deactivated_platform_id: string | null;
  deactivated_reason: string | null;
  reason_option_id: string | null;
  last_activity_at: string | null;
}

export interface Notification {
  data: INotificationData[];
  meta: {
    total_pages: number;
    total_count: number;
    current_page: number;
    next_page: null;
    pervious_page: null;
  };
}

export interface INotificationData {
  id: string;
  type: string;
  attributes: {
    id: number;
    created_by: null;
    app_url: string;
    is_read: boolean;
    read_at: null;
    created_at: string;
    updated_at: string;
    notification_type: string;
    order_id: number;
    order_number: string;
    store_management_id: number;
    internal_status: string | null;
    account: Account;
    headings: string;
    contents: string;
    icon: string;
  };
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  isOpenAnchor: HTMLElement | null;
  popOverId: string | undefined;
  onClose: Function;
  updateCount?: Function;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  data: any[];
  selectedData: any;
  token: any;
  selectedTab: string;
  notificationStatus: string;
  currentPage: number;
  perPage: number;
  allNotificationList: Notification;
  readNotificationList: Notification;
  unreadNotificationList: Notification;
  isLoading: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class NotificationsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getDataCallId: string = "";
  markAsReadCallId: string = "";
  deleteCallId: string = "";
  markAllAsReadApiCallId: string = "";
  getNotificationsApiCallId: string = "";
  markAsReadApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      data: [],
      selectedData: null,
      token: "",
      selectedTab: "All",
      notificationStatus: "All",
      currentPage: 1,
      perPage: 10,
      allNotificationList: {
        data: [],
        meta: {
          total_pages: 0,
          total_count: 0,
          current_page: 0,
          next_page: null,
          pervious_page: null
        }
      },
      readNotificationList: {
        data: [],
        meta: {
          total_pages: 0,
          total_count: 0,
          current_page: 0,
          next_page: null,
          pervious_page: null
        }
      },
      unreadNotificationList: {
        data: [],
        meta: {
          total_pages: 0,
          total_count: 0,
          current_page: 0,
          next_page: null,
          pervious_page: null
        }
      },
      isLoading: false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    // Customizable Area Start
    this.getNotifications(this.state.notificationStatus);
    // Customizable Area End
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);

    const apiRequestId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    const apiSuccessResponse = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if(apiRequestId === this.markAllAsReadApiCallId) {
      this.handleMarkAllAsReadResponse(apiSuccessResponse);
    }
    if(apiRequestId === this.getNotificationsApiCallId) {
      this.getNotificationsResponse(apiSuccessResponse);
    }
    if(apiRequestId === this.markAsReadApiCallId) {
      this.markAsReadResponse(apiSuccessResponse);
    }

    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      runEngine.debugLog("TOKEN", token);
      this.setState({ token: token });
    } else if (
      this.getDataCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      apiResponse &&
        this.setState({
          data: apiResponse.data,
        });
    } else if (
      this.deleteCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiResponse?.data) {
        this.showAlert("Message", configJSON.deleteMessage);
      }
      this.setState({ selectedData: null });
    }
    // Customizable Area End
  }

  // Customizable Area Start
  getNotificationsResponse(responseJson: Notification) {
    if (this.state.notificationStatus === "All") {
      this.setState({ allNotificationList: responseJson, isLoading: false })
    }
    if (this.state.notificationStatus === "true") {
      this.setState({ readNotificationList: responseJson, isLoading: false })
    }
    if (this.state.notificationStatus === "false") {
      this.setState({ unreadNotificationList: responseJson, isLoading: false })
    }
  }

  handleMarkAllAsReadResponse(responseJson: { message: string }) {
    if (responseJson) {
      this.getNotifications(this.state.notificationStatus)
      this.props.updateCount && this.props.updateCount();
    }
  }

  markAsReadResponse(responseJson: Notification) {
    if (responseJson) {
      this.getNotifications(this.state.notificationStatus);
      this.props.updateCount && this.props.updateCount();
    }
  }

  iconBellProps = {
    source: imgBell,
  };

  markAsRead(id: number) {
    const markAsReadMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.markAsReadCallId = markAsReadMsg.messageId;

    markAsReadMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.endPoint}/${id}`
    );

    markAsReadMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": configJSON.apiContentType,
        token: this.state.token ? this.state.token : "",
      })
    );

    markAsReadMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.markAsReadMethod
    );

    runEngine.sendMessage(markAsReadMsg.id, markAsReadMsg);
  }

  deleteNotifications(id: number) {
    console.log(id);
    const deletedMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.deleteCallId = deletedMsg.messageId;

    deletedMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.endPoint}/${id}`
    );

    deletedMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": configJSON.apiContentType,
        token: this.state.token ? this.state.token : "",
      })
    );

    deletedMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "DELETE"
    );

    runEngine.sendMessage(deletedMsg.id, deletedMsg);
  }
  
  timeSince(date: string) {
    var seconds = Math.floor(
      (new Date().valueOf() - new Date(date).valueOf()) / 1000
    );
    var interval = seconds / 31536000;
    if (interval > 1) {
      return Math.floor(interval) + " years";
    }
    interval = seconds / 2592000;
    if (interval > 1) {
      return Math.floor(interval) + " months";
    }
    interval = seconds / 86400;
    if (interval > 1) {
      return Math.floor(interval) + " days";
    }
    interval = seconds / 3600;
    if (interval > 1) {
      return Math.floor(interval) + " hours";
    }
    interval = seconds / 60;
    if (interval > 1) {
      return Math.floor(interval) + " minutes";
    }
    return Math.floor(seconds) + " seconds";
  }
  convertDate(inputFormat: string) {
    function pad(s: any) {
      return s < 10 ? "0" + s : s;
    }
    var d = new Date(inputFormat);
    return [pad(d.getDate()), pad(d.getMonth() + 1), d.getFullYear()].join("-");
  }

  handleTabChange(newGetValue: string) {
    this.getNotifications(newGetValue)
    this.setState({ selectedTab: newGetValue });
  }

  getNotifications(notificationStatus: string = "All") {
    this.setState({ notificationStatus, isLoading: true })
    const apiUrl = configJSON.getNotificationApiEndpoint + `?status=${notificationStatus}&page=${this.state.currentPage}&per_page=${this.state.perPage}`;
    const requestMessage = makeApiMessage({
      url: apiUrl,
      method: configJSON.getDataMethod,
    });
    this.getNotificationsApiCallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleMarkAllAsRead() {
    const apiUrl = configJSON.markAllAsReadApiEndpoint;
    const requestMessage = makeApiMessage({
      url: apiUrl,
      method: configJSON.patchMethod,
    });
    this.markAllAsReadApiCallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleClickOnNotification(getId: number|string) {
    const apiUrl = configJSON.getNotificationApiEndpoint + `/${getId}`;
    const requestMessage = makeApiMessage({
      url: apiUrl,
      method: configJSON.markAsReadMethod,
    });
    this.markAsReadApiCallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleNavigateToOrder = (order_number: string, internal_status: string | null) => {
    const message = new Message(getCustomEnumName(CustomEnums.DataForRedirectionFromNotification))
    const orderData = {
      order_number : order_number,
      internal_status: internal_status
    }
    message.addData('orderData', orderData);
    runEngine.sendMessage(message.id, message);

    navigateTo({ props: this.props, screenName: "Orders", raiseMessage: message })
  }

  handleNavigateToAdjustmentOrder = (order_number: string, internal_status: string | null, store_management_id: number) => {
    const message = new Message(getCustomEnumName(CustomEnums.DataForRedirectionFromNotification))
    const orderData = {
      order_number : order_number,
      internal_status: internal_status,
      store_management_id: store_management_id
    }
    message.addData('orderData', orderData);
    runEngine.sendMessage(message.id, message);

    navigateTo({ props: this.props, screenName: "Cfplantdashboard2", raiseMessage: message })
  }

  orderComponent = (sentence: string) => {
    const orderNoRegex = /Order No\. (\w+)/;
    const orderNoMatch = sentence.match(orderNoRegex);
    const orderNo = orderNoMatch ? orderNoMatch[1] : '';
    return orderNo
  }

  handleDownload=(link:string)=>{
    location.href = link
  }

  // Customizable Area End
}
