export const eyeIcon = require("../assets/eyeicon.png")
export const customerIcon = require("../assets/customer_icon.png");
export const statsIcon = require("../assets/stats_icon.png");
export const regionIcon = require("../assets/region_icon.png");
export const sectionIcon = require("../assets/section_icon.png");
export const mobileIcon = require("../assets/mobile_icon.png");
export const darkMobileIcon = require("../assets/dark_mobile_icon.png");
export const storeIcon = require("../assets/store_icon.png");
export const calendarIcon = require("../assets/calendar_icon.png");
export const printIcon = require("../assets/print_icon.png");
export const compensationCreditIcon = require("../assets/compensation_credit.png");
export const compensationPaidIcon = require("../assets/compensation_paid.png");
export const campaignDiscountIcon = require("../assets/campaign_discount.png");
export const generalDiscountIcon = require("../assets/general_discount.png");
export const loyaltyCreditIcon = require("../assets/loyalty_credit.png");
export const searchIcon = require("../assets/search_icon.png");
export const barChartIcon = require("../assets/bar_chart.svg");
export const horizontalBarChartIcon = require("../assets/horizontal_bar_chart.svg")
export const pieChartIcon = require("../assets/pie_chart.svg");
export const viewIcon = require("../assets/view_icon.png");

export const dummyOverallData = [
  {
    title: "Campaign Discount",
    icon: `${campaignDiscountIcon}`,
    orders: 0,
    amount: 0,
    bgColor: ["#F7F6C0", "EDECB9"],
  },
  {
    title: "Loyalty Credit",
    icon: `${loyaltyCreditIcon}`,
    orders: 0,
    amount: 0,
    bgColor: ["#DBF9D8", "#CAF4CC"],
  },
  {
    title: "Compensation Credit",
    icon: `${compensationCreditIcon}`,
    orders: 0,
    amount: 0,
    bgColor: ["#FAE1BF", "#F3D9B5"],
  },
  {
    title: "Compensation Paid",
    icon: `${compensationPaidIcon}`,
    orders: 0,
    amount: 0,
    bgColor: ["#D3E4EA", "#C4DCE5"],
  },
];

export const allStoresDummyData = [
    {
        store: "Hasa",
        data: dummyOverallData
    },
    {
        store: "Jubail",
        data: dummyOverallData
    },
    {
        store: "Builder Store",
        data: dummyOverallData
    },
    {
        store: "Riyadh store",
        data: dummyOverallData
    }
]

export const progressDummyData = [
  {
    value: "25000",
    percentage: 60,
    color: "#BAADEA"
  },
  {
    value: "2000",
    percentage: 60,
    color: "#AAAD5C"
  },
  {
    value: "1800",
    percentage: 60,
    color: "#76ADC5"
  },
  {
    value: "1500",
    percentage: 60,
    color: "#C2AD82"
  },
  {
    value: "1200",
    percentage: 60,
    color: "#88ADBA"
  }
]
