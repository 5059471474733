// Customizable Area Start
import React from "react";
import {
    Snackbar,
    Box,
    styled,
    InputAdornment,
    TextField
} from "@material-ui/core";
import AddPreferenceController, { Props } from "./AddPreferenceController.web"
import "./AddPreference.css"
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import Alert, { Color } from '@material-ui/lab/Alert';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import PageContainer from "../../navigationmenu/src/PageContainer.web"
import { openCircle, filledCircle } from "./assets";
import Loader from "../../../components/src/Loader.web";
import { colors } from "../../../blocks/utilities/src/Colors";
import { PrefHeading } from "./PreferenceList.web";
import { CommonIcomSearchComponent } from "../../..//components/src/customComponents/CommonIcomSearchComponent";
// Customizable Area End

// Customizable Area Start
// Customizable Area End

class AddPreference extends AddPreferenceController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { options, searchQuery, preference_first_name, severity, preference_second_name, showError } = this.state;
        const firstNameInputClass = showError && preference_first_name === '' && 'error';

        const filterOptions = options.filter((option) => option.attributes.name.toLowerCase().includes(searchQuery))

        return (
            <PageContainer navigation={this.props.navigation}>
                <Loader loading={this.state.isLoading} />
                <Box>
                    <StyledMninDivWrapper data-test-id="preference" className="preference-container">
                        <Snackbar style={{ marginTop: '160px' }} data-test-id="snackbar" anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }} open={this.state.openSnackbar} autoHideDuration={4000} onClose={this.handleClose} >
                            <Alert data-test-id="alert-snackbar" severity={severity as Color} >
                                {this.state.message}
                            </Alert>
                        </Snackbar>
                        <PrefHeading className="header-preference">Add Preference</PrefHeading>
                        <div className="add-preference-container">
                            <div className="left-preference">
                                <div >
                                    <TextLabel className="heading-name">Preference Name</TextLabel>
                                    <StyledTextField data-test-id="preference-first-name" value={preference_first_name} onChange={this.handleFirstNamePreferenceChange} type="text" className={`${firstNameInputClass}`} placeholder="Enter Name" />
                                    {showError && preference_first_name === '' && <p className="error-message">Please enter your first name.</p>}
                                </div>
                                <div className="custom-dropdown-menu">
                                    <TextLabel className="heading-name">Icon</TextLabel>
                                    <CommonIcomSearchComponent
                                        value={[]}
                                        optionList={this.getIconList(options)}
                                        debouncedFunction={() => { }}
                                        handleScroll={this.handleGetImageOnScroll}
                                        handleSelectOptions={(value: { id: string | number, option: string, image: string }) => this.handleSelectImage(value)}
                                        selectedItem={this.state.selectedImage}
                                    />
                                </div>
                            </div>
                            <div className="right-preference">
                                <div >
                                    <TextLabel className="heading-name">Preference Arabic Name</TextLabel>
                                    <StyledTextField dir="rtl" data-test-id="input-second-name" value={preference_second_name} onChange={this.handleSecondNamePreferenceChange} type="text" placeholder="Enter Arabic Name" />
                                    
                                </div>
                            </div>
                        </div>
                    </StyledMninDivWrapper>
                    <BottomContainer className="group-button">
                        <button onClick={this.handleCancelFunc} data-test-id="cancel-button" className="cancel">Cancel</button>
                        <button data-test-id="add-button" onClick={this.handleClick} className="add">Add</button>
                    </BottomContainer>
                </Box>
            </PageContainer>
        );
        // Customizable Area End
    }
}

// Customizable Area Start
export default AddPreference;

export const StyledMninDivWrapper = styled('div')({
    padding: 0,
    marginTop: 0,
    marginBottom: 0,
    "& .editButton": {
        background: colors().cyancobaltblue,
        fontWeight: 600,
        fontSize: "16px !important",
        lineHeight: "24px",
        color: colors().white,
        height: "56px",
        width: '184px !important',
        borderRadius: "8px",
        textTransform: "unset",
        marginLeft: "20px",
        "@media only screen and (max-width: 1024px)": {
            fontSize: "16px !important",
            width: "128px !important",
            height: "44px !important",
            padding: "10px !important"
        },
    },
});

const TextLabel = styled("label")({
    display: "block",
    fontSize: 16,
    lineHeight: "24px",
    fontWeight: 600,
    paddingBottom: 4,
    color: colors().charcoal,
    "@media only screen and (max-width: 1024px)": {
        fontSize: "13px",
    },
});

export const StyledTextField = styled('input')({
    marginTop: "7px",
    width: "85%",
    outline: "none",
    borderRadius: "8px",
    padding: "16px",
    border: "1px solid #ececec",
    textTransform: "capitalize",
    "@media only screen and (max-width: 1024px)": {
        fontSize: '12px',
        height: "44px"
    },
});

const PlaceholderText = styled("div")({
    fontSize: 14,
    fontWeight: 400,
    lineHeight: "22px",
    fontFamily: "Montserrat",
    color: "var(--neutrals-dark-gray, #4D4D4D)",
    "@media only screen and (max-width: 1024px)": {
        fontSize: '12px',
    },
});

const StyledCustomDropDown= styled("div")({
    height: '56px',
    "@media only screen and (max-width: 1024px)": {
        height: '44px',
    },
});

export const BottomContainer = styled("div")({
    gap: 32,
    display: "flex",
    paddingBottom:"20px",
    "& > button": {
        fontSize: 16,
        padding: 16,
        minWidth: 126,
        border: "none",
        fontWeight: 700,
        borderRadius: 8,
        cursor: "pointer",
        lineHeight: "24px",
        alignItems: "center",
        display: "inline-flex",
        justifyContent: "center",
        fontFamily: "Montserrat",
        "&:disabled": {
            opacity: 0.5,
        },
    },
    "& > .add": {
        color: "white",
        background: "var(--primary, #204B9C)",
        "@media only screen and (max-width: 1024px)": {
            width: "126px",
            height: "44px",
        },
    },
    "& > .cancel": {
        color: "#64748B",
        background: "var(--primary, #F1F5F9)",
        margin: 0,
        "@media only screen and (max-width: 1024px)": {
            width: "126px",
            height: "44px",
        },
    },
});
// Customizable Area End
