
import React, { ReactElement, useCallback, useState } from 'react';
import { styled, Box } from "@material-ui/core";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, ResponsiveContainer, XAxisProps, Tooltip, TooltipProps, LabelList, Text, AreaChart, Area } from 'recharts';
import { colors } from '../../blocks/utilities/src/Colors';
import { showChartIcon, totalIcon } from "../../components/src/commonAssets/assets"
import {
    ValueType,
    NameType,
} from 'recharts/types/component/DefaultTooltipContent';
import { formatBigNumber } from './customComponents/CustomColumnChart.web';
import { Skeleton } from "@material-ui/lab";
const SAR_UNIT = ["Amount", "Unpaid", "Cash", "Credit", "Card", "Online", "Revenue", "Paid", "This Week", "Last Two Week","This Month", "This Year", "+1 Year"]

type Props = {
    chartData: { [field: string]: string | number }[]
    barProps: { dataKey: string, barSize?: number }
    xAxisProps?: XAxisProps,
}

type BarChartProps = {
    data: DataChart,
    dataHeader: DataChart,
    color_1?: string,
    color_2?: string,
    title: string,
    height?: number,
    isLoading?: boolean
}

type AreaChartProps = {
    title: string | ReactElement,
    description: string | ReactElement,
    items: Array<{ label: string, color: string }>,
    data: DataChart,
    tooltipLabel: Array<string>
    responsive?: boolean,
    isLoading?: boolean
}
type HorizontalBarProps = {
    data: DataChart,
    noTick?: boolean,
    width?: number | string,
    height?: number,
    color_1?: string,
    color_2?: string,
    left?: number
    xAxisProps?: XAxisProps;
    isLoading?: boolean
}

interface CustomTooltip extends TooltipProps<ValueType, NameType> {
    tooltipLabel: Array<string>
}

type AxisTickProps = {
    x: number;
    y: number;
    payload: { value: string };
};

type DataChart = Array<{
    name: string,
    value_1: number, //uv
    value_2: number, //pv
}>

type SingleHorizontalBarProps = Partial<{
    data: Array<{ name: string, value: number }>,
    dataHeader: Array<{ name: string, value: number }>,
    color: string,
    width: number,
    height: number,
    left: number,
    right?: number,
    title: string,
    hasIcon: boolean,
    isLoading?: boolean
}>


const YAxisLeftTick = ({ y, payload: { value } }: AxisTickProps) => {
    return (
        <Text x={0} y={y} textAnchor="start" verticalAnchor="middle" fontWeight={500} fontSize="13px" fill='black'>
            {value}
        </Text>
    );
};

const CustomTooltip = ({
    active,
    payload,
    label,
    tooltipLabel
}: CustomTooltip) => {
    if (active && payload?.length) {
        return (
            <ToolTipWrapper>
                <div className='header'>{label}</div>
                <div className='value'>{tooltipLabel[0]} {`${payload?.[0].value}`}</div>
                <div className='value'>{tooltipLabel[1]} {`${payload?.[1].value}`}</div>
            </ToolTipWrapper>
        );
    }
    return null;
};

const getPrefix = (nameField: string) => {
    return SAR_UNIT.includes(nameField) ? "SAR" : ""
}


export const HorizontalBarChart = (props: HorizontalBarProps) => {
    const { data, noTick, width = 350, height = 140, color_1 = "#A666FF", color_2 = "#E1CCFF", left = 90, xAxisProps= {} , isLoading = false} = props

    if(isLoading) {
    return  (
        <Box>
         <Skeleton animation="wave" variant="rect" width={"full"} height={100} />
        </Box>
    )
    }

    return (
        <ResponsiveContainer width={width} height={height}>
            <BarChart
                data={data}
                margin={{
                    top: 5,
                    right: 50,
                    left: left,
                    bottom: 5,
                }}
                layout='vertical'
            >
                <CartesianGrid horizontal={false} vertical={false} />
                <XAxis type='number' hide {...xAxisProps}/>
                <YAxis type='category' dataKey="name" axisLine={false} tick={noTick ? false : (props) => <YAxisLeftTick {...props} />} tickLine={false} />
                <Bar dataKey="value_1" fill={color_1}>
                    <LabelList dataKey="value_1" position="right" style={{ fill: color_1, fontSize: "11px", fontWeight: 500 }} />
                </Bar>
                <Bar dataKey="value_2" fill={color_2} >
                    <LabelList dataKey="value_2" position="right" style={{ fill: color_1, fontSize: "11px", fontWeight: 500 }} />
                </Bar>
            </BarChart>
        </ResponsiveContainer>
    )
}



export const DashboardHorizontalBarChartBox = (props: BarChartProps) => {
    const { data, dataHeader, color_1 = "#A666FF", color_2 = "#E1CCFF", title , height, isLoading = false} = props
    if(isLoading) {
        return <LoadingChart/>
    }
    return (
        <BarChartWrapper>
            <Box className='header'>
                <Box className='left'>
                    <Box>
                        {title}
                    </Box>
                    <HorizontalBarChart data={dataHeader} noTick width={230} height={40} left={-55} color_1={color_1} color_2={color_2} />
                </Box>
                <Box className='right'>
                    <div className='item'>
                        <div className='icon' style={{ background: color_1 }} />
                        <div className='label'>Orders</div>
                    </div>
                    <div className='item'>
                        <div className='icon' style={{ background: color_2 }} />
                        <div className='label'>Pieces</div>
                    </div>
                </Box>
            </Box>
            <HorizontalBarChart data={data} color_1={color_1} color_2={color_2} height={height} />
        </BarChartWrapper>
    )
}

export const DashboardAreaChart = (props: AreaChartProps) => {
    const { title, description, items, data, tooltipLabel, responsive, isLoading } = props
    const Container = useCallback(
        ({ children }: { children: React.ReactElement }) => responsive ?
            <Box className="chart-wrapper">
                <ResponsiveContainer width="100%" height={200}>
                    {children}
                </ResponsiveContainer>
            </Box>
            :
            <>{children}</>, [responsive]
    )

    if(isLoading) {
        return <LoadingChart/>
    }

    return (
        <AreaWrapper>
            <Box className='header'>
                <Box className='headerTitle'>
                    <img src={showChartIcon} alt="icon" className='icon' />
                    <div className='title'>{title}</div>
                </Box>
                <Box className='description'>{description}</Box>
            </Box>
            <Container>
                <AreaChart
                    width={responsive ? undefined : 520}
                    height={responsive ? undefined : 180}
                    data={data}
                    margin={{ top: 10, right: 15, left: 0, bottom: 0 }}
                >
                    <defs>
                        <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="0.6" stopColor={colors().dueTodayColor} stopOpacity={0.1} />
                            <stop offset="1" stopColor={colors().dueTodayColor} stopOpacity={0} />
                        </linearGradient>
                        <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="0.6" stopColor={colors().dueTomorrowColor} stopOpacity={0.1} />
                            <stop offset="1" stopColor={colors().dueTomorrowColor} stopOpacity={0} />
                        </linearGradient>
                    </defs>
                    <XAxis dataKey="name" tickLine={false} axisLine={false} tick={{ fontSize: 12, fill: "black", fontWeight: 400, fontFamily: "Montserrat" }} interval={"preserveStart"} />
                    <YAxis axisLine={false} tickLine={false} tick={{ fontSize: 12, fill: colors().darkliver, fontWeight: 400, fontFamily: "Montserrat" }} tickFormatter={formatBigNumber} />
                    <CartesianGrid vertical={false} />
                    <Tooltip content={<CustomTooltip tooltipLabel={tooltipLabel} />} />
                    <Area
                        type="monotone"
                        dataKey="value_1"
                        stroke={colors().dueTodayColor}
                        fillOpacity={1}
                        fill="url(#colorUv)"
                    />
                    <Area
                        type="monotone"
                        dataKey="value_2"
                        stroke={colors().dueTomorrowColor}
                        fillOpacity={1}
                        fill="url(#colorPv)"
                    />
                </AreaChart>
            </Container>
            <Box className='footer'>
                {
                    items.map((item, index) => {
                        return <div className='item' key={index}>
                            <div className='icon' style={{ background: item.color }} />
                            <div className='label'>{item.label}</div>
                        </div>
                    })
                }
            </Box>
        </AreaWrapper>)
}


export const SingleHorizontalBarChart = (props: SingleHorizontalBarProps) => {
    const { data, dataHeader, width = 350, height = 140, color = "#4D6FB0", left = 50, right = 50, title, hasIcon, isLoading } = props

    if(isLoading) {
        return <LoadingChart/>
    }

    const customTooltip = ({ active, payload, label }: TooltipProps<string, string>) => {
        const isDisplayTooltip = active && payload?.length && payload?.[0]?.payload
        const itemPayload = payload?.[0]?.payload
        return <ToolTipWrapper>
            {
                isDisplayTooltip && <div className='value'>{itemPayload?.name}&nbsp;:&nbsp;{getPrefix(itemPayload?.name)}&nbsp;{itemPayload?.value}</div>
            }
        </ToolTipWrapper>
    }

    return (
        <SingleHorizontalBarChartWrapper>
            <Box className='header'>
                <Box className='headerTitle'>
                    {hasIcon && <img src={totalIcon} alt="icon" className='icon' />}
                    <div className='title'>{title}</div>
                </Box>
                {
                    dataHeader && <ResponsiveContainer height={40}>
                        <BarChart
                            data={dataHeader}
                            margin={{
                                top: 5,
                                right: right,
                                left: -55,
                                bottom: 5,
                            }}
                            layout='vertical'
                        >
                            <CartesianGrid horizontal={false} vertical={false} />
                            <XAxis type='number' hide />
                            <YAxis type='category' dataKey="name" axisLine={false} tick={(props) => <YAxisLeftTick {...props} />} tickLine={false} />
                            <Bar dataKey="value" fill={color} barSize={15}>
                                <LabelList dataKey="value" position="right" style={{ fill: color, fontSize: "11px", fontWeight: 500 }} />
                            </Bar>
                        </BarChart>
                    </ResponsiveContainer>
                }
            </Box>
            <ResponsiveContainer height={height}>
                <BarChart
                    data={data}
                    margin={{
                        top: 5,
                        right: right,
                        left: left,
                        bottom: 5,
                    }}
                    layout='vertical'
                >
                    <CartesianGrid horizontal={false} vertical={false} />
                    <XAxis type='number' hide />
                    <YAxis type='category' dataKey="name" axisLine={false} tick={(props) => <YAxisLeftTick {...props} />} tickLine={false} />
                    <Tooltip
                        cursor={false}
                        content={customTooltip}
                    />
                    <Bar dataKey="value" fill={color} barSize={15}>
                        <LabelList dataKey="value" position="right" style={{ fill: color, fontSize: "11px", fontWeight: 500 }} />
                    </Bar>
                </BarChart>
            </ResponsiveContainer>

            <Box className='footer'>
                <div className='item'>
                    <div className='icon' style={{ background: color }} />
                    <div className='label'>Average for the selected period</div>
                </div>
            </Box>
        </SingleHorizontalBarChartWrapper>
    )
}

export const LoadingChart = () => {
    return <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Skeleton animation="wave" variant="text" width={"full"} height={80} />
        <Skeleton animation="wave" variant="rect" width={"full"} height={180} />
        <Skeleton animation="wave" variant="text" width={"full"} height={80} />
    </Box>
}

const AreaWrapper = styled("div")({
    width: "560px",
    padding: "12px",
    display: "flex",
    flexDirection: "column",
    border:"1px solid #ECECEC",
    borderRadius:"12px",
    "& .header": {
        display: "flex",
        flexDirection: "column",
        alignItems: "start",
        gap: "0.5rem",
        border: "none",
        padding: "8px 12px"
    },
    "& .headerTitle": {
        display: "flex",
        gap: "1rem",
        width: "100%",
        fontFamily: "Montserrat",
        fontSize: "19px",
        fontWeight: 500,
        lineHeight: "120%",
        borderBottom: `1px solid ${colors().lightborder}`,
    },
    "& .description": {
        fontFamily: "Montserrat",
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "22px",
        color: colors().darkjunglegreen
    },
    "& .footer": {
        display: "flex",
        gap: "1rem",
        marginTop: "1rem",
        "& .item": {
            display: "flex",
            gap: "0.5rem",
            alignItems: "center",
            "& .icon": {
                width: "12px",
                height: "12px",
                borderRadius: "4px",
            },
            "& .label": {
                fontFamily: "Montserrat",
                fontSize: "12px",
                fontWeight: 400,
                lineHeight: "18px",
            }
        }
    }
})

const ToolTipWrapper = styled("div")({
    display: "flex",
    flexDirection: "column",
    gap: "0.5rem",
    justifyContent: "center",
    alignItems: "center",
    background: "white",
    borderRadius: 8,
    "& .header": {
        padding: "8px",
        borderBottom: `1px solid ${colors().lightgray}`,
        background: colors().antiFlashWhite,
        fontSize: "13px",
        fontWeight: 500,
        fontFamily: "Montserrat",
        alignItems: "center",
        borderRadius: "8px 8px 0 0",
    },
    "& .value": {
        fontFamily: "Montserrat",
        fontSize: '12px',
        fontWeight: 400,
        color: colors().darkjunglegreen,
        padding: "0 5px",
        "&:last-of-type": {
            borderRadius: "0 0 8px 8px",
            paddingBottom: 10
        }
    }
})

const BarChartWrapper = styled("div")({
    display: "flex",
    flexDirection: "column",
    padding: "12px",
    minWidth: "340px",
    border: `1px solid ${colors().lightborder}`,
    borderRadius: '12px',
    "& .header": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "end",
        padding: "0 0 8px 0"
    },
    "& .left": {
        display: "flex",
        flexDirection: "column",
        fontFamily: "Montserrat",
        fontSize: "19px",
        fontWeight: 500,
        "& > div": {
            fontSize: "19px",
            fontWeight: 500,
            lineHeight:"22.8px"
        }
    },
    "& .right": {
        display: "flex",
        flexDirection: "column",
        gap: "0.3rem",
        "& .item": {
            display: "flex",
            gap: "0.5rem",
            "& .icon": {
                width: "12px",
                height: "12px",
                borderRadius: "4px"
            },
            "& .label": {
                fontSize: "12px",
                fontWeight: 400,
                fontFamily: "Montserrat"
            }
        }
    }
})

const SingleHorizontalBarChartWrapper = styled("div")({
    display: "flex",
    flexDirection: "column",
    border:`1px solid ${colors().lightborder}`,
    borderRadius:"12px",
    padding:"0 12px",
    "& .header": {
        display: "flex",
        flexDirection: "column",
        alignItems: "start",
        justifyContent: "flex-start",
        padding:"4px"
    },
    "& .headerTitle": {
        display: "flex",
        justifyContent: "flex-start",
        gap: "1rem",
        fontWeight: 500,
        fontSize: "19px",
        fontFamily: "Montserrat",
    },
    "& .footer": {
        display: "flex",
        gap: "1rem",
        margin: "1rem 0",
        "& .item": {
            display: "flex",
            gap: "0.5rem",
            alignItems: "center",
            "& .icon": {
                width: "12px",
                height: "12px",
                borderRadius: "4px",
            },
            "& .label": {
                fontFamily: "Montserrat",
                fontSize: "12px",
                fontWeight: 400,
                lineHeight: "18px",
            }
        }
    }
})