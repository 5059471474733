// Customizable Area Start
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import InfoIcon from '@material-ui/icons/Info';

// Customizable Area End

// Customizable Area Start

interface Props {
    open: boolean;
    handleClose: () => void;
    handleConfirm?: any
    title: string;
    message: any;
    confirmBtnText: string;
    isAlert?: boolean;
    icon?: any;
}
// Customizable Area End

// Customizable Area Start
const useStyles = makeStyles(() => ({
  MainWrapper: {
    margin: '8px',
  },
  
}));
// Customizable Area End

// Customizable Area Start
const DynamicDialogBox: React.FC<Props> = (props) => {

  const classes = useStyles();
  const {
    open,
    handleClose,
    handleConfirm,
    title,
    message,
    confirmBtnText,
    isAlert,
    icon
} = props
  return (
    <Dialog
				className="main-dialog-container"
				open={open}
				onClose={handleClose}>
				<Box className="dialog-main">
					<Box className={`icon-section ${isAlert ? "error" : "close"}`}>
						{icon ? icon : <InfoIcon />}
					</Box>
					<DialogTitle>{title}</DialogTitle>
					<DialogContent>
						<DialogContentText>{message}</DialogContentText>
					</DialogContent>
					<Box className="buttons">
						<Button
							className={`btn-cancel ${isAlert && "display-none"}`}
							onClick={handleClose}
							color="primary"
              data-test-id='btnCancel'
            >
							{"Cancel"}
						</Button>
						<Button
							className={`btn-confirm ${isAlert && "width-extend"}`}
							onClick={()=>handleConfirm()}
							color="primary"
							autoFocus
              data-test-id='btnConfirm'
            >
							{confirmBtnText}
						</Button>
					</Box>
				</Box>
			</Dialog>
  );
};

export default DynamicDialogBox
// Customizable Area End
