import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import { IBlock } from "../../../framework/src/IBlock";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { makeApiMessage as MakeApiMessage } from '../../../components/src/common'
import { IUserContext } from "../../../blocks/navigationmenu/src/PageContainerController.web";
import {
  PermissionStatus,
  checkForNewPermissonStatus,
  customPermissionApiKey
} from "../../../blocks/utilities/src/CustomBlockHelpers";
import { PermissionGroupArray } from "../../../blocks/navigationmenu/src/utils";
import { saudiTimeView } from "./utils";
export interface ITimeSlot {
  id: number;
  end_time: Date;
  start_time: Date;
  promo_code_id: number;
  endDate: Date | string | null | undefined | never;
  startDate: Date | string | null | undefined | never;
}
interface IOption {
  id: number;
  name: string;
  area_name: string;
  store_name: string;
  branch_name: string;
}

export interface IService {
  id: number;
  name: string;
  icon_id: number;
  active: boolean;
  created_at: Date;
  updated_at: Date;
  short_name: string;
  second_name: string;
  order_number: number;
  online_order: boolean;
}

export interface IData {
  id: string;
  attributes: {
    used: string;
    status: string;
    discount?: string;
    promo_code: string;
    description: string;
    discount_type: string;
    areas: Array<string | never>;
    promo_expires: boolean | never;
    stores: Array<string | never>;
    regions: Array<string | never>;
    sections: Array<string | never>;
    services: Array<string | never>;
    products: Array<string | never>;
    platforms: Array<string | never>;
    maximum_discount?: number | string;
    min_order_amount?: number | string;
    exclude_days: {id:number,day:string}[];
    no_of_orders: number | string | null;
    end_date: string | Date | null | never;
    start_date: string | Date | null | never;
    available_times: Array<ITimeSlot | never>;
    home_cleaning_products: Array<string | never>;
    order_types: Array<string | never>;
    discount_product: {id: string, option: string};
  };
}
interface Props {
  navigation: {
    navigate: Function;
    getParam: Function;
  };
}
interface State {
  noData: boolean;
  isLoading: boolean;
  isViewLoading: boolean;
  data: IData | never;
  permissionStatus: PermissionStatus;
}
interface SS {}
// Customizable Area End

// Customizable Area Start
export const configJSON = require("./config");
const ApiUrls = configJSON.ApiUrls;
// Customizable Area End

class PromoCodeViewController extends BlockComponent<Props, State, SS> {
  constructor(props: Props) {
    super(props);

    // Customizable Area Start
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  // Customizable Area Start
  promoCodeShowMessageId: string = "";
  // Customizable Area End

  state: State = {
    // Customizable Area Start
    noData: false,
    isLoading: true,
    isViewLoading: false,
    data: {
      id: "",
      attributes: {
        discount_type: "",
        discount: "",
        description: "",
        status: "",
        promo_code: "",
        used: "",
        no_of_orders: null,
        start_date: "",
        end_date: "",
        promo_expires: false,
        available_times: [],
        sections: [],
        services: [],
        products: [],
        platforms: [],
        exclude_days: [],
        regions: [],
        areas: [],
        min_order_amount: "",
        maximum_discount: "",
        stores: [],
        home_cleaning_products: [],
        order_types: [],
        discount_product: {id: "" ,option: ""}
      },
    },
    permissionStatus: {
      mainPermission: false,
      createPermission: false,
      viewPermission: false,
      editPermission: false,
      deactivatePermission: false
    }
    // Customizable Area End
  };

  receive(from: string, message: Message): void {
    // Customizable Area Start
    let requestId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    let successResponse = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (successResponse?.data) {
      if (requestId === this.promoCodeShowMessageId) {
        let data = successResponse.data;

        data.attributes.sections = data.attributes.sections.map(
          (item: IOption) => item.name
        );
        data.attributes.services = data.attributes.services.map(
          (item: IOption) => item.name
        );
        data.attributes.products = data.attributes.products.map(
          (item: IOption) => item.name
        );
        data.attributes.platforms = data.attributes.platforms.map(
          (item: IOption) => item.name
        );
        data.attributes.stores = data.attributes.stores.map(
          (item: IOption) => item.store_name
        );
        data.attributes.areas = data.attributes.areas.map(
          (item: IOption) => item.area_name
        );
        data.attributes.regions = data.attributes.regions.map(
          (item: IOption) => item.branch_name
        );
        data.attributes.home_cleaning_products = data.attributes.home_cleaning_products.map(
          (item: {product_name : string}) => item.product_name
        );
        data.attributes.order_types = data.attributes.order_type !== null ? data.attributes.order_type.map(
          (item: {name : string}) => String(item).split("_").join(" ")
        ) : [];
        data.attributes.available_times = data.attributes.available_times.map(
          ({ start_time, end_time, id }: ITimeSlot) => {
            return {
              id,
              start_time: saudiTimeView(start_time),
              end_time: saudiTimeView(end_time),
            };
          }
        );

        this.setState({isViewLoading:false, isLoading: false, noData: false, data });
      }
    } else if (successResponse.error) {
      if (requestId === this.promoCodeShowMessageId) {
        this.setState({isViewLoading:false, isLoading: false, noData: true });
      }
    }

    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    this.getPromoCodeItemApi();
    // Customizable Area End
  }

  // Customizable Area Start

  getPromoCodeItemApi() {
    this.setState({isViewLoading:true})
    const id = this.props.navigation.getParam("id");
    const requestMessage = MakeApiMessage({
      url: ApiUrls.promoCodeShow + id,
      method: "get",
    });

    this.promoCodeShowMessageId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  redirectTo(page: string, props = {}) {
    this.props.navigation.navigate(page, props);
  }

  handleUserChange = (userContext: IUserContext) => {
    const apiKey = customPermissionApiKey.promocodePermissions;
    const userData = userContext.user?.attributes.permission_groups;
    const value = checkForNewPermissonStatus(apiKey, userData as Array<PermissionGroupArray>);
    this.setState({
      permissionStatus: value
    })
  };

  // Customizable Area End
}

// Customizable Area Start

// Customizable Area End

export default PromoCodeViewController;
