import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";
import Switch from '@material-ui/core/Switch';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import moment from 'moment'
import Grid from '@material-ui/core/Grid';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
    secondary: {
      main: "#1f72b6",
      contrastText: "#1f72b6",
    },
  },
  typography: {
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
// Customizable Area End

import ExpressDeliveryController, {
  Props,
  configJSON,
} from "./ExpressDeliveryController";

export default class ExpressDelivery extends ExpressDeliveryController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Container maxWidth={"sm"}>
          <Box sx={webStyle.mainWrapper}>
            <div style={webStyle.section}>
              <Typography variant="body1" component="div" style={webStyle.header}>
                {configJSON.addressLabel}
              </Typography>
              <div style={webStyle.sectionData}>

                <Typography variant="body1" component="div">
                  {configJSON.addressText}
                </Typography>
              </div>
            </div>

            <div style={webStyle.sectionAccordian}>
              <Accordion expanded={this.state.isExpanded} onChange={this.toggleExpand} style={webStyle.accordion}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  data-test-id="expressDeliveryHeader"
                  style={webStyle.accordionSummary}
                >
                  <div style={webStyle.sectionData}>
                    {configJSON.expressDeliveryTitle}
                  </div>
                </AccordionSummary>
                <AccordionDetails style={webStyle.accordianDetails}>
                  <FormControl component="fieldset">
                    <FormGroup aria-label="position" row>
                      <FormControlLabel
                        value="start"
                        control={
                          <Switch
                            checked={this.state.isExpressDeliveryEnabled}
                            onChange={this.toggleSwitch}
                            color="secondary"
                          />
                        }
                        data-test-id="expressDelivery"
                        label="Enable Express Delivery"
                        labelPlacement="start"
                        style={{ marginLeft: "0px" }}
                      />
                    </FormGroup>
                  </FormControl>

                  <Grid container >
                    <Grid item xs>
                      {configJSON.deliveryChargeLabel}
                    </Grid>
                    <Grid item xs={6}>
                      <div style={webStyle.rightContent}>
                        ${this.state.deliveryCharge}
                      </div>
                    </Grid>
                  </Grid>

                  <Grid container>
                    <Grid item xs>
                      {configJSON.deliveryDateLabel}
                    </Grid>
                    <Grid item xs={6}>
                      <div style={webStyle.rightContent}>
                        {moment(new Date(this.state.date)).format("Do MMM YYYY [by] hh:mm A")}
                      </div>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </div>

            <div style={webStyle.section}>
              <Typography variant="body1" style={webStyle.header}>{configJSON.totalCostLabel}</Typography>
              <div style={webStyle.sectionData}>
                <Grid container>
                  <Grid item xs>
                    {configJSON.totalLabel}
                  </Grid>
                  <Grid item xs={6}>
                    <div style={webStyle.rightContent}>
                      ${this.state.total}
                    </div>
                  </Grid>
                </Grid >

                <Grid container>
                  <Grid item xs={6}>
                    {configJSON.deliveryCostLabel}
                    {this.state.isExpressDeliveryEnabled &&
                      <div style={webStyle.subText}>
                        {configJSON.expressDeliveryLabel}
                      </div>
                    }
                  </Grid>
                  <Grid item xs={6}>
                    <div style={webStyle.rightContent}>
                      ${this.state.isExpressDeliveryEnabled ? this.state.deliveryCharge : '0'}
                    </div>
                  </Grid>
                </Grid>

                <Grid container>
                  <Grid item xs>
                    {configJSON.grandTotalLabel}
                  </Grid>
                  <Grid item xs={6}>
                    <div style={webStyle.rightContent}>
                      ${this.state.isExpressDeliveryEnabled ? this.state.total + this.state.deliveryCharge : this.state.total}
                    </div>
                  </Grid>
                </Grid>
              </div>
            </div>

            <button
              style={webStyle.buttonStyle}
              data-test-id="btnCheckout"
            >
              {configJSON.checkoutBtnText}
            </button>

          </Box>
        </Container>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "right",
    paddingBottom: "30px",
    background: "#fff",
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "20px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
    color: "#fff",
    cursor: 'pointer',
    textTransform: 'uppercase' as "uppercase"
  },
  column: {
    display: "flex",
    columnGap: "20px"
  },
  header: {
    backgroundColor: "#F2F2F2",
    paddingLeft: "16px",
    width: '100%'
  },
  rightContent: {
    textAlign: 'right' as 'right'
  },
  subText: {
    fontSize: '12px',
    fontWeight: 300
  },
  section: {
    margin: "10px"
  },
  sectionData: {
    padding: "5px 5px 5px 16px"
  },
  accordianDetails: {
    display: "block"
  },
  accordion: {
    boxShadow: "none",
    padding: "0px 5px"
  },
  accordionSummary: {
    backgroundColor: "#F2F2F2",
    paddingLeft: "16px",
    width: '100%',
    padding: "0px",
    color: 'black'
  },
  sectionAccordian: {
    marginLeft: "10px",
    width: '100%'
  }
};
// Customizable Area End
