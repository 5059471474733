Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";

exports.addressLabel = "Select pickup address"
exports.addressText = "2211 miami, USA"
exports.deliveryChargeLabel = "Delivery Charges"
exports.deliveryDateLabel = "Delivery Date and Time"
exports.totalCostLabel = "Total Cost"
exports.totalLabel = "Total"
exports.deliveryCostLabel = "Delivery Cost"
exports.expressDeliveryLabel = "Express Delivery Selected"
exports.grandTotalLabel = "Total Cost"
exports.checkoutBtnText = "go to checkout"
exports.defaultAddress = "2211 miami, USA"
exports.defaultDeliveryCharge = 100
exports.defaultTotal = 100
exports.getDeliveryInfoMethodType = "GET";
exports.getDeliveryInfoContentType = "application/json";
exports.expressDeliveryTitle = "Express Delivery"
exports.patchDeliveryMethodType = "PUT";
exports.patchDeliveryContentType = "application/json";
exports.pathcDeliveryUrl = "bx_block_expressdelivery/express_delivery/toggle-express-delivery"
exports.getUserProfile = "account_block/user-profile";


// native
exports.textInputPlaceHolder = "Select pickup address";
exports.labelTitleText = "ExpressDelivery";
exports.labelBodyText = "ExpressDelivery Body";

exports.btnExampleTitle = "CLICK ME";
exports.expressDelivery = "Express Delivery";
exports.deliveryCharges = "Delivery Charges";
exports.deliveryDateTime = "Delivery Date and Time";
exports.totalCost = "Total Cost";
exports.totalLabel = "Total";
exports.deliveryCost = "Delivery Cost";
exports.expDeliverySelected = "Express Delivery Selected";
exports.btnCheckout = "CHECKOUT";
exports.expressDeliveryApi = "bx_block_expressdelivery/express_delivery"
exports.enableExpressDelivery = "Enable Express Delivery";
// Customizable Area End