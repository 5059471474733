import React from "react";

// Customizable Area Start
import { Box ,styled} from "@material-ui/core";

import CostomButton from "../../../components/src/customComponents/CostomButton.web";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
export const configJSON = require("./config");

import OtpController, { Props } from "./OtpController.web";
// Customizable Area End

export default class Otp extends OtpController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    // Customizable Area End
    return (
      // Customizable Area Start
      <StyledWrapper className={"verificationBox"} >
        <div className={"verificationTitle"}>{configJSON.title_Verification}</div>
        <div className={"subTitleStyleTitle"}>
          Enter OTP sent to your registered mobile number to continue payment
        </div>
        <Box style={webStyle.otpStyle}>
          {this.state.otpValues.map((value: any, index: any) => (
            <input
              className='otp_code'
              key={index}
              id={`otp-${index}`}
              type='text'
              maxLength={1}
              value={value}
              data-test-id='otp-boxes'
              onChange={(e) => this.handleChange(e, index)}
              onKeyDown={(e) => this.handleKeyDown(e, index)}
              onKeyUp={(e) => this.handleKeyUp(e, index)}
              onPaste={this.handlePaste}
              style={this.state.showErrMessage ? webStyle.errorStyle : {}}
            />
          ))}
        </Box>

        {this.state.otpAttemptRights < 2 &&
          this.state.otpAttemptRights > 0 &&
          !this.state.showExceedErrMessage && (
            <Snackbar
              anchorOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              open={true}
              autoHideDuration={4000}
            >
              <Alert severity='warning' icon={<div />}>
                {configJSON.attemptLabel.replace(
                  "{attemptRight}",
                  this.state.otpAttemptRights + ""
                )}
              </Alert>
            </Snackbar>
          )}
        {(this.state.otpAttemptRights === 0 ||
          this.state.showExceedErrMessage) && (
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            style={{ width: "fit-content" }}
            open={true}
            autoHideDuration={4000}
          >
            <Alert severity='warning' icon={<div />}>
              <div style={webStyle.alertContainer}>
                <div>{configJSON.exceedMessage}</div>
              </div>
            </Alert>
          </Snackbar>
        )}
        {!this.state.showExceedErrMessage && (
          <Box style={webStyle.counterBox}>
            {this.state.counter > 0 && (
              <p style={{ textAlign: "center", ...webStyle.counterTimer }}>
                00:{String(this.state.counter).padStart(2, "0")}
              </p>
            )}
            <button
              style={
                this.state.isButtonDisabled
                  ? webStyle.resendBtn
                  : webStyle.resendBtnBorder
              }
              data-test-id='resend-otp'
              onClick={this.handleTimeOut}
              disabled={
                this.state.isButtonDisabled || !this.state.otpAttemptRights
              }
            >
              Resend
            </button>
          </Box>
        )}

        <Box style={webStyle.buttonAdd}>
          <CostomButton
            disabled={
              !this.state.otpAttemptRights || !this.state.isButtonDisabled
            }
            data-test-id='btn-otp-verify'
            onClick={this.handleLogInputValue}
            style={{ textTransform: "none", ...webStyle.buttonStyle }}
          >
            Verify
          </CostomButton>
        </Box>
      </StyledWrapper>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  buttonStyle: {
    width: "100%",
    height: "3.472vw",
    borderRadius: "0.556vw",
    alignText: "center",
    color: "white",
    fontFamily: "Montserrat",
    fontWeight: 700,
    fontSize: "1.667vw",
  },

  otpStyle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "4px 0",
    paddingTop: "1.5vw",
  },
  backgroundpaper: {
    padding: "4vw",
  },
  otpSend: {
    fontFamily: "Montserrat",
    fontWeight: 500,
    fontSize: "1.050vw",
  },
  otpSendTemp: {
    color: "#EC4E20",
    marginRight: "2vw",
    width: "3.403vw",
    height: "1.467vw",
    borderRadius: "0.833vw",
    border: "1px solid #EC4E20",
    padding: "1vw",
  },
  timer: {
    display: "flex",
    justifyContent: "center",
    paddingTop: "1.5vw",
  },
  runner: {
    color: "#4D4D4D",
    paddingTop: "1vw",
    cursor: "pointer",
  },
  buttonAdd: {
    display: "flex",
    background: "#204B9C",
    borderRadius: "7px",
    padding: "5px",
  },
  counterBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "2vw",
  },
  counterTimer: {
    color: "#EC4E20",
    border: "1px solid #fdeee9",
    borderRadius: "12px",
    padding: "10px",
    margin: "0",

    fontWeight: 500,
    fontSize: "18px",
    fontFamily: "Montserrat",
  },
  resendBtn: {
    outline: "none",
    border: "none",
    padding: "10px",
    background: "#ffffff",
    marginLeft: "13px",
    fontWeight: 500,
    fontSize: "18px",
    fontFamily: "Montserrat",
  },
  resendBtnBorder: {
    border: "0.069vw solid #9861c2",
    borderRadius: "12px",
    padding: "10px",
    color: "#4D4D4D",
    background: "#ffffff",
    cursor: "pointer",
    fontWeight: 500,
    fontSize: "18px",
    fontFamily: "Montserrat",
  },
  alertContainer: {
    display: "flex",
    alignItems: "center",
    gap: "10px",
  },
  errorStyle: {
    color: "#FF0000",
    borderColor: "#FF0000",
  },
  titleStyle: {
    fontFamily: "Montserrat",
    fontSize: "28px",
    fontWeight: 600,
  },
  subTitleStyle: {
    fontFamily: "Montserrat",
    fontSize: "16px",
    fontWeight: 500,
  },
};
const StyledWrapper = styled('div')({
  "& .verificationTitle": {
    fontFamily: "Montserrat",
    fontSize: "28px",
    fontWeight: 600,
  },
  "& .subTitleStyleTitle": {
    fontFamily: "Montserrat",
    fontSize: "16px",
    fontWeight: 500,
  },
  "& .verificationBox": {
   padding:'32px',
  },
  "& .otp_code": {
    borderColor:'#204B9C',
    boxShadow: 'none'
   },
  
  
  '@media (max-width: 1200px)': {
  "& .verificationTitle": {
    fontSize: '16px !important',
    fontWeight: '600 !important'
  },
  "& .subTitleStyleTitle": {
    fontFamily: "Montserrat",
    fontSize: "12px",
    fontWeight: 400 ,
    maxWidth: '297px'
  },
  "& .otp_code": {
   maxWidth:'56.25px',
   maxHeight:'43px',
   borderColor:'#204B9C',
   boxShadow: 'none'
  },
  "& .verificationBox": {
    padding:'12px !important',
   },
}
})
// Customizable Area End
