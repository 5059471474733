// Customizable Area Start
import React from "react";
import EditPreferenceController, {
  MapPreferencesData,
  Props,
  configJSON,
} from "./EditPreferenceController";
import {
  styled,
  Dialog,
  Box,
  Typography,
  DialogActions,
  Select,
  MenuItem,
  OutlinedInput,
  CircularProgress,
} from "@material-ui/core";
import {
  TableRow,
  TableCell,
  OrderTable,
  RowContainer,
} from "../../../components/src/OrderTable/src";
import {
  renderBaseOnConditions,
  sortCondition,
} from "../../../blocks/utilities/src/CustomBlockHelpers";
import { colors } from "../../../blocks/utilities/src/Colors";
import { MainButtonComponent } from "../../../components/src/customComponents/CustomTags.web";
import { MenuProps } from "../../../blocks/email-account-registration/src/CreateProfessionController.web";
import {
  RadioCheckedIcon,
  RadioUnCheckedIcon,
  checkedIcon,
  unCheckedIcon,
} from "./assets";
import { CustomMenuUpchargeDrop } from "../../../blocks/cfplantdashboard2/src/utils";
// Customizable Area End

// Customizable Area Start
const String = configJSON.NewStrings;
// Customizable Area End

export default class EditPreference extends EditPreferenceController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  renderPreferenceBody(preferenceData: MapPreferencesData) {
    const preferences_list = preferenceData.preferece_list;
    return (
      <SelectUpchargeRow>
        <Typography>Preferences :</Typography>
        <div className='wrapper-componenet'>
          {preferences_list.map((preferences) => {
            return (
              <div className='preference-main-wrap'>
                <img
                  data-test-id={`preference-${preferenceData.order_items_preference_id}-${preferences.id}`}
                  className='preference-icon'
                  onClick={() =>
                    this.handlePreferenceSelect(
                      preferences.id,
                      preferenceData.order_items_preference_id
                    )
                  }
                  src={
                    sortCondition(
                      this.renderPreferenceRadioVal(
                        preferenceData.order_items_preference_id,
                        preferences.id
                      ),
                      RadioCheckedIcon,
                      RadioUnCheckedIcon
                    ) as string
                  }
                />
                <Box
                  className={
                    sortCondition(
                      this.renderPreferenceRadioVal(
                        preferenceData.order_items_preference_id,
                        preferences.id
                      ),
                      "shadow-box active-preference",
                      "shadow-box"
                    ) as string
                  }
                >
                  <Box className='preference-main'>
                    <img width={20} height={20} src={preferences.icon} />
                    <Typography className='preference-name'>
                      {preferences.name}
                    </Typography>
                  </Box>
                </Box>
              </div>
            );
          })}
        </div>
      </SelectUpchargeRow>
    );
  }

  renderUpchargeBody(preferenceData: MapPreferencesData) {
    const upcharges_list = preferenceData.upcharge_list;
    return (
      <SelectUpchargeRow>
        <Typography>Upcharges :</Typography>
        <div>
          <div className='wrapper-componenet'>
            {upcharges_list?.slice(0, 6).map((upcharge) => {
              return (
                <>
                  <div className='preference-main-wrap'>
                    <img
                      data-test-id={`upcharge-${preferenceData.order_items_preference_id}-${upcharge.id}`}
                      className='preference-icon'
                      onClick={() =>
                        this.handleSelectUpcharge(
                          upcharge.id,
                          preferenceData.order_items_preference_id
                        )
                      }
                      src={
                        sortCondition(
                          this.renderUpchargeSelectionVal(
                            upcharge.id,
                            preferenceData.order_items_preference_id
                          ),
                          checkedIcon,
                          unCheckedIcon
                        ) as string
                      }
                    />
                    <Box
                      className={
                        sortCondition(
                          this.renderUpchargeSelectionVal(
                            upcharge.id,
                            preferenceData.order_items_preference_id
                          ),
                          "shadow-box active-preference",
                          "shadow-box"
                        ) as string
                      }
                    >
                      <Box className='preference-main'>
                        <img className='upcharge-icon' src={upcharge.icon} />
                        <Typography className='preference-name'>
                          {upcharge.name}
                        </Typography>
                      </Box>
                      <Typography className='preference-name'>
                        SAR {upcharge.price}
                      </Typography>
                    </Box>
                  </div>
                </>
              );
            })}
          </div>
          {upcharges_list.length > 6 && (
            <Box className='bottom-upcharge-select-wrap'>
              <CustomSelect
                multiple
                displayEmpty
                input={<OutlinedInput />}
                disableUnderline
                name='upchargeSelect'
                data-test-id='field-upchargeSelect'
                value={[]}
                renderValue={() => "See all upcharges"}
                MenuProps={CustomMenuUpchargeDrop}
                onChange={(
                  event: React.ChangeEvent<{
                    value: unknown;
                  }>
                ) =>
                  this.handleSelectionUpchargeFromDropdown(
                    event.target.value as number[],
                    preferenceData.order_items_preference_id
                  )
                }
              >
                {upcharges_list.slice(6).map((dropUpcharge) => {
                  return (
                    <UpchargeSelectMenuItem value={dropUpcharge.id}>
                      <div className='d-flex'>
                        <img
                          data-test-id='checkbox-selectAll'
                          className='cursor-pointer'
                          src={
                            sortCondition(
                              this.renderUpchargeSelectionVal(
                                dropUpcharge.id,
                                preferenceData.order_items_preference_id
                              ),
                              checkedIcon,
                              unCheckedIcon
                            ) as string
                          }
                        />
                        <div className='d-flex'>
                          <img
                            src={dropUpcharge.icon}
                            className='upchargeIcon'
                          />
                          <span
                            className={
                              sortCondition(
                                this.renderUpchargeSelectionVal(
                                  dropUpcharge.id,
                                  preferenceData.order_items_preference_id
                                ),
                                "dropdownUpchargeActiveName",
                                "dropdownUpchargeName"
                              ) as string
                            }
                          >
                            {dropUpcharge.name}
                          </span>
                        </div>
                      </div>
                      <span
                        className={
                          sortCondition(
                            this.renderUpchargeSelectionVal(
                              dropUpcharge.id,
                              preferenceData.order_items_preference_id
                            ),
                            "dropdownUpchargeActiveName",
                            "dropdownUpchargeName"
                          ) as string
                        }
                      >
                        SAR {dropUpcharge.price}
                      </span>
                    </UpchargeSelectMenuItem>
                  );
                })}
              </CustomSelect>
            </Box>
          )}
        </div>
      </SelectUpchargeRow>
    );
  }

  renderSpecificationBody(preferenceData: MapPreferencesData) {
    const specifications_list = preferenceData.specifications;
    return (
      <SelectUpchargeRow>
        <Typography>Specifications :</Typography>
        <div className='specification-wrapper-componenet'>
          {specifications_list.map((specification) => {
            return (
              <SpecificationSelectContainer key={specification.id}>
                <div className='label'>{specification.name}</div>
                <Select
                  fullWidth
                  displayEmpty
                  value={this.handleSpecificationVal(
                    specification.id,
                    preferenceData.order_items_preference_id
                  )}
                  variant='outlined'
                  input={<OutlinedInput />}
                  placeholder={`Select ${specification.name}`}
                  MenuProps={MenuProps}
                  name={`specifications-${preferenceData.order_items_preference_id}-${specification.id}`}
                  data-test-id={`field-specifications-${preferenceData.order_items_preference_id}-${specification.id}`}
                  onChange={(
                    event: React.ChangeEvent<{
                      value: unknown;
                    }>
                  ) =>
                    this.handleOnChangeSpecification(
                      preferenceData.order_items_preference_id,
                      specification.id,
                      event.target.value
                    )
                  }
                >
                  <MenuItem
                    value={""}
                  >{`Select ${specification.name}`}</MenuItem>
                  {specification.options_attributes.map((specificOption) => {
                    return (
                      <MenuItem
                        value={specificOption.id}
                        key={specificOption.id}
                      >
                        {specificOption.label}
                      </MenuItem>
                    );
                  })}
                </Select>
              </SpecificationSelectContainer>
            );
          })}
        </div>
      </SelectUpchargeRow>
    );
  }

  showSQMData(height: string, width: string, weight: string) {
    return (
      <>
        {Number(height) !== 0 && Number(width) !== 0 && (
          <>
            <Typography className='sqm-size'>{height} m</Typography>
            <span className='cross-span'>X</span>
            <Typography className='sqm-size'>{width} m</Typography>
          </>
        )}
        {Number(weight) !== 0 && (
          <Typography className='sqm-size'>{weight} Kg</Typography>
        )}
      </>
    );
  }

  renderAdjustOrderCustomer() {
    const { laundryOrder } = this.props;
    const customer = laundryOrder?.customer;
    const store = laundryOrder?.store_management.data.attributes;

    return (
      <OrderDetailsHeaderContainer className='responsive'>
        <OrderDetailsHeadingText>
          <div>
            <b>{String.adjustmentPopup_customer_name}</b>
            {customer?.full_name}
          </div>
          <div>
            <b>{String.adjustmentPopup_customer_mobile_no}</b>
            {customer?.full_phone_number}
          </div>

          <div>
            <b>{String.adjustmentPopup_business}</b>{" "}
            {customer?.business || "--"}
          </div>

          <div>
            <b>{String.adjustmentPopup_order_creation_date}</b>
            {this.getMomentFormat(laundryOrder?.created_at || "")}
          </div>
        </OrderDetailsHeadingText>
        <OrderDetailsHeadingText>
          <div>
            <b>{String.adjustmentPopup_order_id}</b>{" "}
            {laundryOrder?.order_number}
          </div>
          <div>
            <b>{String.adjustmentPopup_store_name}</b> {store?.store_name}
          </div>

          <div>
            <b>{String.adjustmentPopup_price_list}</b>{" "}
            {laundryOrder?.price_list_name || "--"}
          </div>

          <div>
            <b>{String.adjustmentPopup_discount}</b> {laundryOrder?.currency}{" "}
            {laundryOrder?.applied_discount || "--"}
          </div>

          <div>
            <b>{String.adjustmentPopup_promocode_applied}</b>
            {laundryOrder?.promo_code?.promo_code || "--"}
          </div>
        </OrderDetailsHeadingText>
      </OrderDetailsHeaderContainer>
    );
  }

  renderCommonUpchargePart() {
    const {
      commonUpchargeList,
      commonPreferencesList,
      selectedCommonPreference,
      selectedCommonUpcharges,
    } = this.state;
    return (
      <Box className='top-dropdown-wrap'>
        <Box className='max-250'>
          <div className='label'>Common Upcharge</div>
          <Select
            fullWidth
            multiple
            value={[]}
            renderValue={() => "Select Upcharges"}
            variant='outlined'
            displayEmpty
            data-test-id={"select-common-upcharge"}
            name={"common-upcharge"}
            input={<OutlinedInput />}
            MenuProps={MenuProps}
            onChange={(
              event: React.ChangeEvent<{
                value: unknown;
              }>
            ) =>
              this.handleChangeCommonUpcharge(event.target.value as unknown[])
            }
          >
            {renderBaseOnConditions(
              commonUpchargeList.length > 0,
              <UpchargeSelectMenuItem value={"selectAll"}>
                <Box className='d-flex'>
                  <img
                    data-test-id='checkbox-selectAll'
                    className='cursor-pointer'
                    src={
                      sortCondition(
                        selectedCommonUpcharges?.length ==
                          commonUpchargeList.length,
                        checkedIcon,
                        unCheckedIcon
                      ) as string
                    }
                  />
                  <span className='upcharge-name ml-8'>Select All</span>
                </Box>
              </UpchargeSelectMenuItem>,
              <></>
            )}
            {commonUpchargeList.map((commonUpcharge) => {
              return (
                <UpchargeSelectMenuItem
                  key={commonUpcharge.id}
                  value={commonUpcharge.id}
                >
                  <Box className='d-flex'>
                    <img
                      data-test-id='checkbox-selectAll'
                      className='cursor-pointer'
                      src={
                        sortCondition(
                          commonUpcharge.checked,
                          checkedIcon,
                          unCheckedIcon
                        ) as string
                      }
                    />
                    <span className='upcharge-name ml-8'>
                      {commonUpcharge.name}
                    </span>
                  </Box>
                </UpchargeSelectMenuItem>
              );
            })}
            {
              commonUpchargeList.length === 0 &&
                <span 
                  style={{fontSize: "16px",fontWeight: 400,lineHeight: "24px",
                    fontFamily: "Montserrat",margin: "0 0 0 8px"}}>
                  No Upcharge
                </span>
            }
          </Select>
        </Box>
        <Box className='max-250'>
          <div className='label'>Common Preference</div>
          <Select
            fullWidth
            value={selectedCommonPreference}
            renderValue={
              selectedCommonPreference ? undefined : () => "Select Preferences"
            }
            variant='outlined'
            displayEmpty
            input={<OutlinedInput />}
            data-test-id={"select-common-preference"}
            name={"select-preference"}
            MenuProps={MenuProps}
            onChange={(
              event: React.ChangeEvent<{
                value: unknown;
              }>
            ) =>
              this.handleChangeCommonPreference(event.target.value as number)
            }
          >
            {commonPreferencesList.map((preference) => {
              return (
                <MenuItem key={preference.id} value={preference.id}>
                  {preference.name}
                </MenuItem>
              );
            })}
          </Select>
        </Box>
      </Box>
    );
  }

  // Customizable Area End

  render() {
    // Customizable Area Start
    const { open, handleClosePopup, isCfPlantAdjustmentscreen } = this.props;
    const { preferencesMapData, isEditPreferenceLoading } = this.state;
    // Customizable Area End
    return (
      // Customizable Area Start
      <StyledDialogWrapper open={open} fullWidth maxWidth='lg'>
        <h3 className='dialog-title'>
          {isCfPlantAdjustmentscreen
            ? String.selectUpchargetxt
            : String.editPreferencetxt}
        </h3>
        {!isCfPlantAdjustmentscreen && this.renderCommonUpchargePart()}
        {isCfPlantAdjustmentscreen && <Box className='p-10' />}
        {isCfPlantAdjustmentscreen && this.renderAdjustOrderCustomer()}
        <Box className='p-10' />
        <OrderTable>
          <RowContainer header>
            <TableRow columns={"2fr 8fr"}>
              {[String.txtItemNameService, String.txtPreference].map(
                (heading: string) => {
                  return (
                    <TableCell
                      key={heading}
                      header
                      content={heading}
                      className='align-item-center'
                    />
                  );
                }
              )}
            </TableRow>
          </RowContainer>
          <RowContainer>
            {isEditPreferenceLoading ? (
              <Box className='loader'>
                <CircularProgress />
              </Box>
            ) : (
              preferencesMapData.map((preference) => {
                return (
                  <TableRow columns={"2fr 8fr"} key={preference.product_name}>
                    {[
                      <PreferenceTopName>
                        <Box className='d-flex'>
                          <Typography className='product-name'>
                            {preference.product_name} |
                          </Typography>
                          <Box className='d-flex'>
                            <img
                              src={preference.service_icon}
                              width={20}
                              height={20}
                            />
                            <Typography className='product-name'>
                              {preference.service_name}
                            </Typography>
                          </Box>
                        </Box>
                        <Box className='d-flex'>
                          {this.showSQMData(
                            preference.height,
                            preference.width,
                            preference.weight
                          )}
                        </Box>
                      </PreferenceTopName>,
                      <SpecsContainer>
                        {this.renderSpecificationBody(preference)}
                        {this.renderPreferenceBody(preference)}
                        {this.renderUpchargeBody(preference)}
                      </SpecsContainer>,
                    ].map((heading: unknown, index: number) => {
                      return (
                        <TableCell
                          content={heading as React.ReactNode}
                          type={
                            sortCondition(index == 0, "left", "right") as
                              | "middle"
                              | "left"
                              | "right"
                              | "none"
                              | undefined
                          }
                        />
                      );
                    })}
                  </TableRow>
                );
              })
            )}
          </RowContainer>
        </OrderTable>
        <DialogActions className='dialogActionWrapper'>
          <MainButtonComponent
            data-test-id='btnSubCancel'
            className='buttonSecondary'
            onClick={() => handleClosePopup()}
          >
            {String.cancel}
          </MainButtonComponent>
          <MainButtonComponent
            data-test-id='btnSubNext'
            onClick={() => this.onSavePreferences()}
          >
            {String.save}
          </MainButtonComponent>
        </DialogActions>
      </StyledDialogWrapper>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const StyledDialogWrapper = styled(Dialog)({
  "& .loader": {
    height: "150px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  "& .MuiDialog-paper": {
    borderRadius: 20,
    padding: "24px 16px 0 16px",
  },
  "& .dialog-title": {
    fontSize: 24,
    fontWeight: 600,
    paddingBottom: 16,
    textAlign: "center",
    fontFamily: "Montserrat",
  },
  "& .p-10": {
    padding: 10,
  },
  "& .max-250": {
    maxWidth: 250,
    width: "100%",
  },
  "& .top-dropdown-wrap": {
    width: "100%",
    display: "flex",
    justifyContent: "end",
    gap: "12px",
  },
  "& .dialogActionWrapper": {
    justifyContent: "center",
    paddingTop: "24px",
    paddingBottom: "32px",
  },
  "& .label": {
    fontSize: 14,
    fontWeight: 500,
    margin: "0 0 6px 0",
    color: colors().darkliver,
  },
  "& .upcharge-name": {
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    fontFamily: "Montserrat",
    margin: "0 0 0 8px",
  },
});

const PreferenceTopName = styled("div")({
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  gap: "5px",
  "& .product-name": {
    fontSize: "16px",
    fontWeight: 500,
    fontFamily: "Montserrat",
    color: colors().cyancobaltblue,
  },
  "& .sqm-size": {
    fontSize: "13px",
    fontWeight: 400,
    fontFamily: "Montserrat",
    color: colors().cyancobaltblue,
  },
  "& .cross-span": {
    fontSize: "14px",
    fontWeight: 400,
    fontFamily: "Montserrat",
    color: colors().cyancobaltblue,
    margin: "0 6px",
  },
  "& .d-flex": {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
  },
});

const SpecsContainer = styled("div")({
  gap: 24,
  display: "flex",
  flexDirection: "column",
  width: "100%",
});

const SelectUpchargeRow = styled("div")({
  width: "100%",
  display: "grid",
  gridTemplateColumns: "150px 1fr",
  "& p:first-child": {
    fontSize: "16px",
    fontWeight: 600,
    fontFamily: "Montserrat",
    whiteSpace: "pre",
  },
  "& .specification-wrapper-componenet": {
    width: "100%",
    flex: "1 1 0",
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr",
    gap: "14px",
  },
  "& .wrapper-componenet": {
    width: "100%",
    flex: "1 1 0",
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gap: "14px",
  },
  "& .preference-main-wrap": {
    display: "flex",
    gap: "14px",
    alignItems: "center",
  },
  "& .preference-main": {
    display: "flex",
    gap: "8px",
    alignItems: "center",
  },
  "& .preference-name": {
    fontSize: "16px",
    lineHeight: "24px",
    fontWeight: 400,
    fontFamily: "Montserrat",
  },
  "& .shadow-box": {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: 8,
    borderRadius: "8px",
    border: `1px solid ${colors().lightborder}`,
  },
  "& .active-preference": {
    background: colors().brightgray,
  },
  "& .preference-icon": {
    height: 20,
    width: 20,
  },
  "& .upcharge-icon": {
    height: 24,
    width: 24,
  },
  "& .bottom-upcharge-select-wrap": {
    padding: "12px 0 0 32px",
  },
  "@media only screen and (max-width: 1024px)": {
    "& .specification-wrapper-componenet": {
      gridTemplateColumns: "1fr 1fr",
    },
  }
});

const SpecificationSelectContainer = styled("div")({
  "& .label": {
    fontSize: 14,
    fontWeight: 500,
    margin: "0 0 6px 0",
    color: colors().darkliver,
  },
  "& .MuiSelect-root": {
    padding: 14,
    paddingTop: 16,
  },
});

const UpchargeSelectMenuItem = styled(MenuItem)({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  paddingTop: "8px",
  paddingBottom: "8px",
  "&.MuiListItem-root.Mui-selected": {
    backgroundColor: `${colors().brightgray} !important`,
  },
  "& .upchargeName": {
    fontSize: "15px",
    fontFamily: "Montserrat",
  },
  "& .upchargeActiveName": {
    fontSize: "15px",
    fontFamily: "Montserrat",
    color: colors().cyancobaltblue,
  },
  "& .dropdownUpchargeName": {
    fontSize: "14px",
    fontFamily: "Montserrat",
  },
  "& .dropdownUpchargeActiveName": {
    fontSize: "14px",
    fontFamily: "Montserrat",
    color: colors().cyancobaltblue,
  },
  "& .upchargeIcon": {
    width: "18px",
    height: "18px",
    borderRadius: "4px",
    margin: "0 8px",
  },
  "& .d-flex": {
    display: "flex",
    alignItems: "center",
  },
  "& .upcharge-name": {
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    fontFamily: "Montserrat",
    margin: "0 0 0 8px",
  },
  "& .ml-8": {
    margin: "0 0 0 8px",
  },
});

const CustomSelect = styled(Select)({
  padding: "10px 8px",
  borderRadius: "8px",
  border: `1px solid ${colors().lightborder}`,
  fontSize: "12px",
  fontWeight: 400,
  background: colors().white,
  fontFamily: "Montserrat",
  width: "330px",
  height: "40px",
  "&:focus": {
    borderRadius: "8px",
    borderColor: colors().cyancobaltblue,
    outline: "none",
  },
});

export const OrderDetailsHeaderContainer = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  padding: 16,
  borderRadius: 12,
  border: "1px solid #DEDEDE",
  "& > div:first-child": {
    paddingBottom: 8,
  },
});

const OrderDetailsHeadingText = styled("div")({
  fontSize: 16,
  fontWeight: 500,
  color: "#1A1A1A",
  alignItems: "flex-start",
  flexDirection: "column",
  fontFamily: "Montserrat",
  justifyContent: "space-between",
  "& div": {
    margin: "0 0 6px 0",
  },
  "& div:last-child": {
    margin: "0",
  },
});

// Customizable Area End
