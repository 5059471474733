// Customizable Area Start
import React from "react";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import ControlPointIcon from "@material-ui/icons/ControlPoint";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  Input,
  List,
  ListItem,
  Typography,
  Box
} from "@material-ui/core";
import { colors } from "../../../blocks/utilities/src/Colors";
// Customizable Area End

// Customizable Area Start


interface ItemListProps {
  newItem: string;
  items: {
    data: {
      attributes: {
        id?: string;
        label: string;
        _destroy?: boolean
      }
    }
    _destroy?: boolean
  }[];
  inputPlaceholder: string;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleClick: () => void;
  handleDelete: (id?: string,index?:number) => void;
}
// Customizable Area End

// Customizable Area Start
const useStyles = makeStyles(() => ({
  listItemContainer: {
    display: "flex",
    flexDirection: "column",
    width: 500,
  },

  listItems: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    marginBottom: 20,
    padding: 0,
    "@media only screen and (max-width: 1024px)": {
      width: "64%",
  },
  },
  textItem: {
    width: "80%",
    border: "1px solid #ececec",
    borderRadius: 8,
    padding: 15,
    "@media only screen and (max-width: 1024px)": {
      color: colors().darkliver,
      fontSize: "12px",
      fontFamily: "Montserrat",
      height: "44px",
      padding: 12,
  },
  },
  inputContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 10,
    width: "100%",
    "@media only screen and (max-width: 1024px)": {
      width: "67%",
      gap: "16px"
  },
  },
  ulList: {
    padding: 0
  },
  inputText: {
    width: "80%",
    border: "1px solid #ececec",
    borderRadius: 8,
    padding: "9px 18px",
    outline: "none",
    "&:hover": {
      border: "1px solid #ececec",
    },
    "&:focus": {
      border: "1px solid #ececec",
    },
    "@media only screen and (max-width: 1024px)": {
      opacity: 1,
      color: colors().darkliver,
      fontSize: "12px",
      fontFamily: "Montserrat",
      height: "44px"
  },
  },
  deleteButton: {
    backgroundColor: "transparent",
    margin: "auto",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  deleteIcon: {
    width: 24,
    height: 24,
  },
  plusButton: {
    background: "#204b9c",
    width: 60,
    height: 50,
    borderRadius: 8,
    margin: "auto",
    "&:hover": {
      backgroundColor: "#204b9c",
    },
    "@media only screen and (max-width: 1024px)": {
      width: 50,
      height: 40,
    },
  },
  plusIcon: {
    color: "#ffffff",
  },
  removeMargin:{
    marginBottom:0
  }
}));
// Customizable Area End

// Customizable Area Start
const CustomItemList: React.FC<ItemListProps> = (props) => {
  const {
    newItem,
    items,
    inputPlaceholder,
    handleChange,
    handleClick,
    handleDelete,
  } = props;
  const classes = useStyles();
  return (
    <Box className={classes.listItemContainer}>
      <List className={classes.ulList} data-test-id="optionList">
        {items?.filter((item) => !item._destroy).map((item, index) => (
          <ListItem key={index} className={`${classes.listItems} ${item.data.attributes._destroy && classes.removeMargin}`}>
            {!item.data.attributes._destroy && <>
              <Typography className={classes.textItem}>{item.data.attributes.label}</Typography>
              <Button
                data-test-id={`deleteList-${item.data.attributes.id}`}
                className={classes.deleteButton}
                onClick={() => handleDelete(item.data.attributes.id,index)}
              >
                <DeleteOutlineIcon className={classes.deleteIcon} />
              </Button></>}
          </ListItem>
        ))}
      </List>
      <Box className={classes.inputContainer}>
        <Input
          type="text"
          value={newItem}
          onChange={handleChange}
          placeholder={inputPlaceholder}
          className={classes.inputText}
          disableUnderline
        />
        <Button className={classes.plusButton} onClick={handleClick} data-test-id="plusIcon">
          <ControlPointIcon className={classes.plusIcon} />
        </Button>
      </Box>
    </Box>
  );
};

export default CustomItemList
// Customizable Area End
