import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { ILoyalty } from "./LoyaltyListController.web";
import React from "react";
import _ from "lodash";
import { getStorageData } from "../../../framework/src/Utilities";
import { IUserContext } from "../../../blocks/navigationmenu/src/PageContainerController.web";
import {
  PermissionStatus,
  checkForNewPermissonStatus,
  customPermissionApiKey
} from "../../../blocks/utilities/src/CustomBlockHelpers";
import { PermissionGroupArray } from "../../../blocks/navigationmenu/src/utils";
import { updateMultiSelectData } from "../../../components/src/common";

export interface IExpiryDate {
  id: string;
  type: string;
  attributes: {
    expiry_date: string;
  };
}

export const configJSON = require("./config");

export interface Props {
  navigation: {
    navigate: (routeName: string) => void;
    goBack: () => void;
    history: {
      push: (path: string) => void;
    };
  };
  id: string;
}

type SectionOption = {
  id: string
  option: string
}

interface S {
  loyalty: ILoyalty;
  initialLoyalty: ILoyalty;
  loyaltyId: string;
  formErrors: { [key: string]: string };
  expiryDateList: IExpiryDate[];
  sectionList: SectionOption[];
  selectedSections: SectionOption[];
  selectedSectionsForList: SectionOption[];
  isSectionSelectAll: boolean;
  sectionPage: number;
  sectionSearchText: string;
  snackBarMessage: string;
  permissionStatus: PermissionStatus;
}

interface SS {
  id: number | string;
}

const emptyLoyalty: ILoyalty = {
  id: "",
  type: "reward",
  attributes: {
    active: false,
    expiry_date: "",
    all_section_enabled: false,
    sections: [],
  },
};

export default class AddLoyaltyController extends BlockComponent<Props, S, SS> {
  itemsPerPage = 10;
  updateLoyaltyCallId: string = "";
  addLoyaltyCallId: string = "";
  getLoyaltyCallId: string = "";
  expiryListCallId: string = "";
  sectionListCallId: string = "";
  disableLoadMoreSection = false;

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      loyalty: {
        ...emptyLoyalty,
      },
      initialLoyalty: {
        ...emptyLoyalty,
      },
      loyaltyId: "",
      formErrors: {},
      snackBarMessage: "",
      expiryDateList: [],
      sectionList: [],
      selectedSections: [],
      selectedSectionsForList: [],
      sectionPage: 1,
      sectionSearchText: '',
      isSectionSelectAll: false,
      permissionStatus: {
        mainPermission: false,
        createPermission: false,
        viewPermission: false,
        editPermission: false,
        deactivatePermission: false
      }
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  receiveUpdateLoyalty = (message: Message) => {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (responseJson) {
      if (responseJson.data.attributes && responseJson.data.attributes.errors) {
        const errors = responseJson.data.attributes.errors;
        if (errors.sections) {
          this.setState({
            formErrors: {
              ...this.state.formErrors,
              sections: errors.sections[0],
            },
          });
        }
      } else {
        if (this.state.loyaltyId)
          this.showMessage("Loyalty Updated Successfully");
        else this.showMessage("Loyalty Added Successfully");
        setTimeout(() => {
          this.props.navigation.goBack();
        }, 2000);
      }
    }
  };

  receiveGetLoyalty = (message: Message) => {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (responseJson) {
      const { all_section_enabled = false, sections = [] } = responseJson.data?.attributes || {}
      const currentSelectedSections = sections.map((section: {id: number; name: string}) => ({id: section.id.toString(), option: section.name}))

      this.setState({
        loyalty: responseJson.data,
        initialLoyalty: responseJson.data,
        isSectionSelectAll: all_section_enabled,
        sectionList: currentSelectedSections,
        selectedSections: all_section_enabled ? [{ id: "-1", option: "Select All" }] : currentSelectedSections,
        selectedSectionsForList: all_section_enabled ? [{ id: "-1", option: "Select All" },... currentSelectedSections] : currentSelectedSections
      }, () => this.getSectionList());
    }
  };

  receiveSectionResponse = (responseJson : {data?: {id: string; attributes: {section_name: string}}[]}) => {
    const sectionListData  = responseJson.data || []
          const receivedSections = sectionListData.map(sectionItem => ({id: sectionItem.id, option: sectionItem.attributes.section_name}))
          this.disableLoadMoreSection = receivedSections.length < this.itemsPerPage
          const currentSelectedSections = this.state.selectedSectionsForList.filter(item => item.id !== "-1")
          this.setState({
              sectionList: _.uniqBy([...(this.state.sectionPage === 1 ? currentSelectedSections 
                  : this.state.sectionList), ...receivedSections],"id"),
              selectedSectionsForList: this.state.isSectionSelectAll ? 
                  _.uniqBy([
                      ...this.state.selectedSectionsForList,
                      ...receivedSections
                  ], "id") : this.state.selectedSectionsForList,
          })
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId === this.expiryListCallId) {
        if (responseJson) {
          this.setState({ expiryDateList: responseJson.data });
        }
      } else if (apiRequestCallId === this.sectionListCallId) {
        if (responseJson) {
          this.receiveSectionResponse(responseJson)
        }
      } else if (apiRequestCallId === this.updateLoyaltyCallId) {
        this.receiveUpdateLoyalty(message);
      } else if (apiRequestCallId === this.getLoyaltyCallId) {
        this.receiveGetLoyalty(message);
      }
    }
  }

  getExpiryList = async () => {
    const header = {
      token: await getStorageData(configJSON.tokenKey),
      "Content-Type": configJSON.contentType,
    };
    const getDataMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.expiryListCallId = getDataMsg.messageId;

    getDataMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
    getDataMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.expiryListApi
    );
    getDataMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    runEngine.sendMessage(getDataMsg.id, getDataMsg);
  };
  getSectionList =async () => {
    const headerSection = {
      "Content-Type": configJSON.contentType,
      token: await getStorageData(configJSON.tokenKey),
    };
    const getDataMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.sectionListCallId = getDataMsg.messageId;
    this.disableLoadMoreSection = true;
    const searchQuery = this.state.sectionSearchText ? `&filter_by[query]=${this.state.sectionSearchText}` : ''
    getDataMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headerSection)
    );
    getDataMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.sectionListApi}${searchQuery}&page_no=${this.state.sectionPage}&per_page=${this.itemsPerPage}`
    );
    getDataMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");

    runEngine.sendMessage(getDataMsg.id, getDataMsg);
  };

  getLoyalty = async (loyaltyId: string) => {
    const header = {
      "Content-Type": configJSON.contentType,
      token: await getStorageData(configJSON.tokenKey),
    };
    const getDataMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getLoyaltyCallId = getDataMsg.messageId;

    getDataMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getMethod
    );
    getDataMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.loyaltyListApi + "/" + loyaltyId
    );
    getDataMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    runEngine.sendMessage(getDataMsg.id, getDataMsg);
  };

  updateCreateLoyaltyInfo = async () => {
    const header = {
      "Content-Type": configJSON.contentType,
      token: await getStorageData(configJSON.tokenKey),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.updateLoyaltyCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.loyaltyListApi}` +
        (this.state.loyaltyId ? `/${this.state.loyaltyId}` : "")
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    const expiry = this.state.expiryDateList.find(
      (expiryDate) =>
        expiryDate.attributes.expiry_date ===
        this.state.loyalty.attributes.expiry_date
    );
    const datas = {
      active: this.state.loyalty.attributes.active,
      spend_amount: this.state.loyalty.attributes.spend_amount,
      earned_points: this.state.loyalty.attributes.earned_points,
      redeemed_points: this.state.loyalty.attributes.redeemed_points,
      received_amount: this.state.loyalty.attributes.received_amount,
      min_redeemed_points: this.state.loyalty.attributes.min_redeemed_points,
      expiry_id: expiry ? parseInt(expiry.id) : "",
      all_section_enabled: this.state.isSectionSelectAll ? true : undefined,
      section_ids: this.state.selectedSectionsForList.filter(item => item.id !== "-1").map(section => Number(section.id)),
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({ data:datas })
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      this.state.loyaltyId ? configJSON.putMethod : configJSON.postMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  // web events

  onCancel = () => {
    this.setState({ loyalty: { ...this.state.initialLoyalty } });
    this.props.navigation.goBack();
  };

  checkFormErrors = () => {
    const { loyalty, selectedSections, isSectionSelectAll } = this.state;
    const { attributes } = loyalty;
    const {
      expiry_date,
      spend_amount,
      earned_points,
      received_amount,
      redeemed_points,
      min_redeemed_points,
    } = attributes;
    const formErrors: { [key: string]: string } = {};
    if (!expiry_date)
      formErrors["expiry_date"] = "Expiry date name is required";
    if (spend_amount === undefined)
      formErrors["spend_amount"] = "Spend amount is required";
    if (earned_points === undefined)
      formErrors["earned_points"] = "Earned points is required";
    if (received_amount === undefined)
      formErrors["received_amount"] = "Credit amount is required";
    if (redeemed_points === undefined)
      formErrors["redeemed_points"] = "Used points is required";
    if (min_redeemed_points === undefined)
      formErrors["min_redeemed_points"] = "Minimum Redeem points is required";
    if (!selectedSections.length && !isSectionSelectAll)
      formErrors["sections"] = "Section is required";
    this.setState({ formErrors });
    return Object.keys(formErrors).length === 0;
  };

  onSave = () => {
    if (this.checkFormErrors()) {
      this.updateCreateLoyaltyInfo();
    }
  };

  goBack = () => {
    this.props.navigation.goBack();
  };

  async componentDidMount() {
    super.componentDidMount();
    this.getExpiryList();
    const loyaltyId = window.location.search.split("=")[1];
    if (loyaltyId) {
      this.getLoyalty(loyaltyId);
      this.setState({ loyaltyId: loyaltyId });
    } else {
      this.getSectionList();
    }
  }

  showMessage = (message: string) => {
    this.setState({ snackBarMessage: message });
  };

  closeSnackBar = () => {
    this.setState({ snackBarMessage: "" });
  };

  handleEdit = () => {
    const loyaltyId = this.state.loyaltyId;
    window.localStorage.setItem("loyaltyId", loyaltyId);
    this.props.navigation.history.push("EditLoyalty?loyaltyId=" + loyaltyId);
  };

  handleCheckedChange = (checked: boolean) => {
    this.setState({
      loyalty: {
        ...this.state.loyalty,
        attributes: {
          ...this.state.loyalty.attributes,
          active: checked,
        },
      },
    });
  };

  handleSectionMultiSelect = (option: { id: string, option: string } ) => {
    const {selectedSectionsForList} = this.state
    let updateStateData: Partial<S> = {}
    if (option.id === "-1") {
      updateStateData =  this.state.isSectionSelectAll ?
      { isSectionSelectAll: false, selectedSections: [], selectedSectionsForList: [] }
      :
      {
        isSectionSelectAll: true,
        selectedSections: [{ id: "-1", option: "Select All" }],
        selectedSectionsForList: [{ id: "-1", option: "Select All" }, ...this.state.sectionList]
      }
    } else {
      const isValueContained = selectedSectionsForList.some((item) => item.id == option.id)
      const selectedSectionOptions = selectedSectionsForList.filter((item) => item.id !== "-1")
      const newSelectedSections = isValueContained ? selectedSectionOptions.filter((item) => item.id != option.id) : [...selectedSectionOptions, option]
      updateStateData = updateMultiSelectData(newSelectedSections, this.state.sectionList, 'isSectionSelectAll', 'selectedSections', 'selectedSectionsForList')
    }
    this.setState({isSectionSelectAll: updateStateData.isSectionSelectAll as boolean, selectedSections: updateStateData.selectedSections as SectionOption[], selectedSectionsForList: updateStateData.selectedSectionsForList as SectionOption[]})
  };

  debouncedFunction = _.debounce(
    (newInputValue: string) => this.handleSectionAutoCompleteChange(newInputValue),
        700,
        { maxWait: 2000 }
  );
  handleSectionAutoCompleteChange = (getValue: string) => {
    if (getValue === this.state.sectionSearchText) return;
    this.setState({ sectionSearchText: getValue, sectionPage: 1 }, () => {
        if (getValue.length < 1 || getValue.length > 2) {
        this.getSectionList()
        }
    });
  }

  handleScrollSectionDropdown = (event: React.SyntheticEvent) => {
    if (this.disableLoadMoreSection) return;
    const checkListboxNode = event.currentTarget;
    const boxPosition = checkListboxNode.scrollTop + checkListboxNode.clientHeight;

    if (checkListboxNode.scrollHeight - boxPosition <= 1.30) {
      this.setState((prevState) => ({ sectionPage: prevState.sectionPage + 1 }), () => {
        this.getSectionList()
      })
    }
  };

  handleExpiryDateChange = (
    event: React.ChangeEvent<{
      name?: string;
      value: unknown;
    }>
  ) => {
    this.setState({
      loyalty: {
        ...this.state.loyalty,
        attributes: {
          ...this.state.loyalty.attributes,
          expiry_date: event.target.value as string,
        },
      },
    });
  };

  handleUserChange = (userContext: IUserContext) => {
    const apiKey = customPermissionApiKey.loyaltySystem;
    const userData = userContext.user?.attributes.permission_groups;
    const value = checkForNewPermissonStatus(apiKey, userData as Array<PermissionGroupArray>);
    this.setState({
      permissionStatus: value
    })
  };

  numericInputChange = (event: React.ChangeEvent<HTMLInputElement>, keys: string) => {
    this.setState((prevState) => ({
      loyalty: {
        ...prevState.loyalty,
        attributes: {
          ...prevState.loyalty.attributes,
          [keys]: event.target.value ? parseFloat(event.target.value) : "",
        },
      },
    }));
  };

  numericKeyPress = (event: React.KeyboardEvent) => {
    if (!/^\d$/.test(event.key)) {
      event.preventDefault();
    }
  };

  // Customizable Area End
}
