Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.apiContentType = "application/json";
exports.httpGetMethod = "GET";
exports.httpPostMethod = "POST";
exports.httpPutMethod = "PUT";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "Print";
exports.labelBodyText = "Print Body";

exports.printImageLabel = "Print image file";

exports.orderDetailEndPoint = "bx_block_order_management/orders"
exports.getQzTrayCertEndPoint = "bx_block_dashboard/dashboards/get_qz_tray_key"
exports.verifyOutFilesEndPoint = "bx_block_cfmetalprogettisolutionintegration2/metalprogetti_solution_integrations/verify_out_files"
exports.updateOutFiles = "bx_block_cfmetalprogettisolutionintegration2/metalprogetti_solution_integrations/update_out_files"

exports.btnExampleTitle = "CLICK ME";

exports.receiptPrinter = "Receipt Printer"
exports.sharedFolderConnectionError = "unable to connect with shared folder"
exports.writeFileSuccessMsg = "Input file has been written successfully to shared folder"
exports.saveSettingSuccessMsg = "Setting saved successfully"
exports.sharedFolderLocationStorageKey = "sharedFolderLocation"
exports.receiptPrinterStorageKey = "qzTrayPrinter"
exports.garmentTagStorageKey = "garmentTagQzTrayPrinter"

exports.cancel= "Cancel"

exports.printerSettingsApi = "bx_block_settings/printer_settings/"
exports.garmentTagSettingApi = "bx_block_settings/printer_settings?printer_setting_type=garment_tag_printer"
// Customizable Area End