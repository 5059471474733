// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { debounce } from "lodash";
import { makeApiMessage as MakeApiMessage } from '../../../components/src/common'
import { ClassNameMap } from "@material-ui/styles";
import { StoreGroupData, Region, Area, StoreManagement } from "./StoreGroupController";
import { IUserContext } from "../../../../packages/blocks/navigationmenu/src/PageContainerController.web";

export const configJSON = require("./config");

export enum FormModeStore {
  Create,
  Edit,
}

export interface IFormStore {
  region_ids: number[] ;
  area_ids: number[] ;
  name: string| null;
  second_name:string|null;
  store_management_ids:[]
}

export interface ISentFormStore {
  region_ids: number[] | [];
  area_ids: number[] | [];
  store_management_ids: number[] | [];
  name: string| null;
  second_name:string|null;
  active:boolean
}


export interface ICommonResponse {
  id: number | string;
  attributes: {
    id: number;
    name: string;
    option: string;
    area_name: string;
    store_name: string;
    branch_name: string;
    product_name: string;
    section_name: string;
    active: boolean | string | number;
    services: { data: Array<{ id: string | number }> };
    sections: { data: Array<{ id: string | number }> };
    home_clean_products: { data: Array<{ id: string | number }> };
    errors: { [field: string]: string | Array<string> };
  };
}
export interface IOptionListItem {
  id: number;
  name: string;
  option?: string;
}

export interface Props {
  classes: ClassNameMap,
   navigation: {
    navigate: Function,
    goBack:()=>void,
    getParam: Function;
  };
}
type Option ={ id: string | number, option: string }
interface S {
  saveStatus: string;
  formErrors: { [key: string]: string };
  form: IFormStore;
  regionList: IOptionListItem[];
  areaList: IOptionListItem[];
  storeList: IOptionListItem[];
  isLoading: boolean;
  isSubmitting: boolean;
  formMode: FormModeStore;
  regionSelectedData: Option[];
  selectedRegionsForList:Option[];
  isRegionSelectAll: boolean;

  areaSelectedData: Option[];
  selectedAreasForList:Option[];
  isAreaSelectAll: boolean;

  storeSelectedData: Option[];
  selectedStoresForList:Option[];
  isStoreSelectAll: boolean;
  storeGroupInfo: StoreGroupData ;
  initialGroupInfo:StoreGroupData ;
}
export interface OptionType {
  id: number ;
  name: string;
}

interface SS {
  id: string | number;
}
// Customizable Area End

export default class StoreGroupAddController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  itemsPerPage = 10;
  private formInitialValues: IFormStore = {
    region_ids: [],
    area_ids: [],
    name: '',
    second_name:'',
    store_management_ids:[]
  };
  // Customizable Area End

  constructor(props: Props) {
    // Customizable Area Start

    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

    this.state = {
      storeGroupInfo:{} as StoreGroupData,
      initialGroupInfo: {} as StoreGroupData,
      saveStatus: "",
      formErrors: {},
      form: { ...this.formInitialValues },
      regionList: [],
      areaList: [],
      storeList: [],
      isLoading: false,
      isSubmitting: false,
      formMode: FormModeStore.Create,
      regionSelectedData:[],
      selectedRegionsForList: [],
      isRegionSelectAll:false,
      areaSelectedData:  [],
      selectedAreasForList: [],
      isAreaSelectAll: false,
      storeSelectedData:  [],
      selectedStoresForList: [],
      isStoreSelectAll: false
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }
  regionMessageId: string = "";
  detailMessageId: string = "";
  areaMessageId :string = "";
  storeMessageId: string = "";
  saveApiMessageId: string = "";
  editApiMessageId: string = "";

  async receive(from: string, message: Message) {

    const requestId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    const successResponse = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    switch (requestId) {
      case this.detailMessageId:
          if(successResponse?.data){
            let data = successResponse?.data
            let areaQueryString = data?.attributes.regions?.data
              .map((item: Region) => `&region_id[]=${item?.id}`)
              .join("");
            let storeQueryString = data?.attributes.areas?.data
              .map((item: Area) => `&area_id[]=${item?.id}`)
              .join("");
            this.getAreaList(areaQueryString);
            this.getStoreList(storeQueryString);
            this.setState({
              initialGroupInfo:data,
              storeGroupInfo:data,
              regionSelectedData: data?.attributes.regions?.data.map((region: Region) => {
                return {id: Number(region.id), option: region?.attributes?.branch_name}}),
              selectedRegionsForList: data.attributes.regions?.data.map((region: Region) => {
                return {id: Number(region.id), option: region?.attributes?.branch_name}}),
  
              areaSelectedData: data?.attributes.areas?.data.map((region: Area) => {
                return {id: Number(region.id), option: region?.attributes?.area_name}}),
              selectedAreasForList: data.attributes.areas?.data.map((region: Area) => {
                return {id: Number(region.id), option: region?.attributes?.area_name}}),
  
              storeSelectedData: data?.attributes.store_managements?.data.map((region: StoreManagement) => {
                return {id: Number(region.id), option: region?.attributes?.store_name}}),
              selectedStoresForList: data.attributes.store_managements?.data.map((region: StoreManagement) => {
                return {id: Number(region.id), option: region?.attributes?.store_name}}),
  
              form: {
                region_ids: data?.attributes.regions?.data?.map((item:Region)=> item.id),
                area_ids: data?.attributes.areas?.data?.map((item:Area)=> item.id),
                name: data?.attributes.name,
                second_name: data?.attributes.second_name,
                store_management_ids:data?.attributes.store_managements?.data?.map((item:StoreManagement)=> item.id)
              },
            });
            
          }
        break;
        case this.regionMessageId:
        this.setState({
          regionList:
            successResponse.data.map((item: ICommonResponse) => ({
              id: item.attributes.id,
              name: item.attributes.branch_name,
            })) || [],
        });
        break;
        case this.areaMessageId:
          this.setState({
            areaList:
              successResponse.data.map((item: ICommonResponse) => ({
                id: item.attributes.id,
                name: item.attributes.area_name,
              })) || [],
          });
          break;
          case this.storeMessageId:
            this.setState({
              storeList:
                successResponse.data.map((item: ICommonResponse) => ({
                  id: item.attributes.id,
                  name: item.attributes.store_name,
                })) || [],
            });
            break;

            case this.saveApiMessageId:
              case this.editApiMessageId:
                if (successResponse) {
                  if(successResponse.data){
                    this.setState({ saveStatus: configJSON.saveText });
                    this.props.navigation.navigate("StoreGroupList");
                  }else{
                    this.setState({ saveStatus: configJSON.errorText });
                  }
                }
                break;
      default:
        break;
    }
   
  }
  // Customizable Area Start

 
  getStoreGroupDetail = () => {
    const id = this.props.navigation.getParam("id");
    let requestMessage = MakeApiMessage({
      url: configJSON.saveStoreGroup +'/'+ id,
      headers: { token: localStorage.getItem("token") },
      method: "get",
    });
    this.detailMessageId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getRegionList() {
    let requestMessage = MakeApiMessage({
      url: configJSON.regionListApiEndPoint,
      headers: { token: localStorage.getItem("token") },
      method: "get",
    });
    this.regionMessageId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  onChangeRegion(values: Array<string | number>) {
    const regionSelectedIds = this.state.selectedRegionsForList.filter((item: Option )=> item.id !== "-1").map((item: Option) => item.id)
    let data = this.state.isRegionSelectAll ? regionSelectedIds : values
    this.onFormElementChange(data, "region_ids");
    
    const queryString = data
      .map((item: string | number) => `&region_id[]=${item}`)
      .join("") ;

    this.getAreaList(queryString);
  }
  getAreaList(queryParam: string = "") {
    let requestMessage = MakeApiMessage({
      url: configJSON.areaListApiEndPoint + queryParam,
      headers: { token: localStorage.getItem("token") },
      method: "get",
    });

    this.areaMessageId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getStoreList(queryParam: string = "") {
    let requestMessage = MakeApiMessage({
      url:configJSON.storeList + localStorage.getItem("token") + queryParam,
      method: "get",
    });
    this.storeMessageId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  async componentDidMount() {
    super.componentDidMount();
    this.getRegionList();
    const _id = this.props?.navigation?.getParam("id");
    if (_id) {
      this.getStoreGroupDetail();
    }
  }
  onFormElementChange = (
    value: string | string[] | number[] | boolean | number  | Array<string | number>,
    property: string
  ) => {
    this.setState({
      form: {
        ...this.state.form,
        [property]: value,
      },
    });
  };

  handleCancelClick = () => {
    this.props.navigation.goBack();
  };

  checkEntriesForValidation = (formErrors: { [keyOf: string]: string }) => {
    const newFormErrors = { ...formErrors };
    Object.entries(this.state.form).forEach(([keyOf, value]) => {
      if(keyOf == "second_name") return;
      const notRequiredFields = [
        "second_name"
      ];
      if (notRequiredFields.includes(keyOf)) {
        return;
      }
      if (
        !value ||
        value === undefined ||
        value === "" ||
        value === "null" ||
        value.length === 0
      ) {
        newFormErrors[keyOf] = configJSON.fieldRequiredValidText;
      }
      if(keyOf === "store_management_ids" && value.length < 2){
        newFormErrors[keyOf] = configJSON.selectAtleastTwoStoreMsg;
      }
    });

    return newFormErrors;
  };
  handleScrollRegionDropdown = (event: React.SyntheticEvent) => {
     
  };
  handleRegionAutoCompleteChange = (getValue: string) => {
 
  }
  handleRegionMultiSelect = (value: { id: string, option: string }[], option: { id: string, option: string }) => {
    let updateStateData: Partial<S> = {}
    if (option.id == "-1") {
      updateStateData = this.state.isRegionSelectAll ?
        { ...updateStateData, isRegionSelectAll: false, regionSelectedData: [], selectedRegionsForList: [] }
        :
        {
          ...updateStateData,
          isRegionSelectAll: true,
          regionSelectedData: [{ id: "All", option: "Select All" }],
          selectedRegionsForList: [{ id: "-1", option: "Select All" }, ...this.state.regionList.map((item: IOptionListItem)=>{return {id: item.id, option: item.name}})]
        };

    } 
    else {
      const isValueContained = value.some((item) => item.id == option.id)
      updateStateData.isRegionSelectAll = false
      value = value.filter((item) => item.id !== "-1")
      const newItems = isValueContained ? value.filter((item) => item.id != option.id) : [...value, option]
      updateStateData.regionSelectedData = newItems
      updateStateData.selectedRegionsForList = newItems
    }
    updateStateData.areaSelectedData = []
    updateStateData.selectedAreasForList=[]
    updateStateData.storeSelectedData=[]
    updateStateData.selectedStoresForList=[]
    this.setState(updateStateData as Pick<S, keyof S>,()=>{
      this.onChangeRegion(this.state.regionSelectedData?.map((item:Option)=> item.id))
    })
  };
  handleAreaMultiSelect = (value: { id: string, option: string }[], option: { id: string, option: string }) => {
    let updateStateData: Partial<S> = {}
    if (option.id == "-1") {
      updateStateData = this.state.isAreaSelectAll ?
        { ...updateStateData, isAreaSelectAll: false, areaSelectedData: [], selectedAreasForList: [] }
        :
        {
          ...updateStateData,
          isAreaSelectAll: true,
          areaSelectedData: [{ id: "All", option: "Select All" }],
          selectedAreasForList: [{ id: "-1", option: "Select All" }, ...this.state.areaList.map((item:IOptionListItem)=>{return {id: item.id, option: item.name}})]
        };

    } else {
      const isValueContained = value.some((item) => item.id == option.id)
      updateStateData.isAreaSelectAll = false
      value = value.filter((item) => item.id !== "-1")
      const newItems = isValueContained ? value.filter((item) => item.id != option.id) : [...value, option]
      updateStateData.areaSelectedData = newItems
      updateStateData.selectedAreasForList = newItems
    }
    updateStateData.storeSelectedData=[]
    updateStateData.selectedStoresForList=[]
    this.setState(updateStateData as Pick<S, keyof S>, () => {
      this.onChangeArea(this.state.areaSelectedData?.map((item:Option)=> item.id))
    })
  };
  handleStoreMultiSelect = (value: { id: string, option: string }[], option: { id: string, option: string }) => {
    let updateStateData: Partial<S> = {}
    if (option.id == "-1") {
      updateStateData = this.state.isStoreSelectAll ?
        { ...updateStateData, isStoreSelectAll: false, storeSelectedData: [], selectedStoresForList: [] }
        :
        {
          ...updateStateData,
          isStoreSelectAll: true,
          storeSelectedData: [{ id: "All", option: "Select All" }],
          selectedStoresForList: [{ id: "-1", option: "Select All" }, ...this.state.storeList.map((item:IOptionListItem)=>{return {id: item.id, option: item.name}})]
        };

    } else {
      const isValueContained = value.some((item) => item.id == option.id)
      updateStateData.isStoreSelectAll = false
      value = value.filter((item) => item.id !== "-1")
      const newItems = isValueContained ? value.filter((item) => item.id != option.id) : [...value, option]
      updateStateData.storeSelectedData = newItems
      updateStateData.selectedStoresForList = newItems
    }
    this.setState(updateStateData as Pick<S, keyof S>,()=>{
      this.onChangeStore(this.state.storeSelectedData?.map((item:Option)=> item.id))
    })
  };
  
  onChangeArea(values: Array<string | number>) {
    const areaSelectedIds = this.state.selectedAreasForList.filter((item: Option )=> item.id !== "-1").map((item: Option) => item.id)
    let data = this.state.isAreaSelectAll ? areaSelectedIds : values
    this.onFormElementChange(data, "area_ids");
    const queryString = data
      .map((item: string | number) => `&area_id[]=${item}`)
      .join("");
    this.getStoreList(queryString);
  }
  onChangeStore(values: Array<string | number>) {
    const areaSelectedIds = this.state.selectedStoresForList.filter((item: Option )=> item.id !== "-1").map((item: Option) => item.id)
    let data = this.state.isStoreSelectAll ? areaSelectedIds : values
    this.onFormElementChange(data, "store_management_ids");
  }
  debouncedFunction = debounce(
    (newInputValue: string, inputFunction: (inputValue: string) => void) => inputFunction(newInputValue),
        700,
        { maxWait: 2000 }
    );
  handleOnSubmit = () => {
    let formErrors: { [keyOf: string]: string } = {};

    if (!this.state.form || this.state.isSubmitting) {
      return;
    }

    formErrors = this.checkEntriesForValidation(formErrors);

    if (Object.keys(formErrors).length > 0) {
      this.setState({ formErrors, isSubmitting: false });
      return;
    }

    const { form } = this.state;
    const { ...rest } = form;

    const sentForm = {
      "data":{
        "attributes":{
          ...rest,
          "active": true
        }
      }
    };
    this.setState({isLoading: true})
    const id = this.props.navigation.getParam("id");
    if (id) {
      this.onEditApi( JSON.stringify(sentForm), id);
    }else{
      this.onSaveApi( JSON.stringify(sentForm))
    }
  };
  onSaveApi(body: string) {
    let requestMessage = MakeApiMessage({
      url: configJSON.saveStoreGroup,
      method: "POST",
      body,
    });
    this.saveApiMessageId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  onEditApi(body: string,id:number) {
    let requestMessage = MakeApiMessage({
      url: configJSON.saveStoreGroup+ '/'+id,
      method: "PUT",
      body,
    });
    this.editApiMessageId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  handleEditGroup(storeId: string){
    this.props.navigation.navigate("StoreGroupEdit",{
      id: storeId,
    });
  }
  fillFormErrors = () => {
    this.setState({
      formErrors: {
        region_ids: "error",
        area_ids: "error",
      },
    });
  };
  handleUserChange = (userContext: IUserContext) => {
    
  };
  // Customizable Area End
}
