import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import { IBlock } from "../../../framework/src/IBlock";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start

import { IFilter } from "../../../components/src/FilterPopover";
import { ISortingData } from "../../../components/src/SortingTableHeader2";
import { makeApiMessage as MakeApiMessage } from '../../../components/src/common'
import { IUserContext } from "../../../blocks/navigationmenu/src/PageContainerController.web";
import {
  PermissionStatus,
  checkForNewPermissonStatus,
  customPermissionApiKey,
  navigateTo
} from "../../../blocks/utilities/src/CustomBlockHelpers";
import { PermissionGroupArray } from "../../../blocks/navigationmenu/src/utils";
import moment from "moment";

export const configJSON = require("./config");
export interface ISection {
  id: number;
  name: string;
  section_name: string;
  section_second_name: string;
}
export interface IPlatform {
  id: number;
  name: string;
}
export interface IPromoItem {
  id: string;
  type: string;
  attributes: {
    status: string;
    end_date: string;
    promo_code: string;
    start_date: string;
    sections: Array<ISection>;
    platforms: Array<IPlatform>;
  };
}
interface IFilterOmitValue extends Omit<IFilter, "value"> {
  value?:
    | string
    | string[]
    | Date[]
    | [null, null]
    | [undefined, undefined];    
}
export interface IFilterItem extends Omit<IFilterOmitValue, "apiKey"> {
  apiKey?: string | string[];
}

const Strings = configJSON.Strings.promoCodeList;
const ApiUrls = configJSON.ApiUrls;
// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation: {
    navigate: Function;
    getParam: Function;
  };
  id: string;
  // Customizable Area End
}

export interface S {
  // Customizable Area Start
  filters: IFilterItem[];
  sortingData: ISortingData;
  filterAnchor: { currentTarget: {} } | undefined | HTMLDivElement;
  totalCount: number;
  popOverTop: number;
  popOverLeft: number;
  endDate: Date | null | string | undefined;
  startDate: Date | null | string | undefined;
  popOverOpened: boolean;
  confirmModalFlag: boolean;
  confirmModalType: boolean;
  sectionList: Array<ISection>;
  sectionSelected: Array<string>;
  promoCodeList: Array<IPromoItem>;
  permissionStatus: PermissionStatus;
  // Customizable Area End
}

export interface SS {}

class PromoCodeListController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  filterQuery: string = "";
  searchQuery: string = "";
  currentPage: number = 1;
  rowPerPage: number = 10;
  sortQuery: string = "";

  promoSortingId: string = "";
  sectionMessageId: string = "";
  confirmModalValue: string = "";
  popOverItemStatus: string = "";
  deactivateMessageId: string = "";
  platformSuggestionId: string = "";
  promoCodeSuggestionId: string = "";
  popOverItemId: string | number = "";

  sortingProps = {
    width: "18%",
    onQueryChange: (query: string) => {
      this.sortQuery = query;
      this.getPromoCodesApi();
    },
    onChange: (d: ISortingData) => this.setState({ sortingData: d }),
  };
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start

    const requestId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    const successResponse = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (requestId === this.sectionMessageId) {
      this.setState({
        sectionList:
          successResponse.data.map(
            (item: { attributes: {} }) => item.attributes
          ) || [],
      });
    } else if (requestId === this.promoSortingId) {
      const { total_count, current_page } = successResponse.meta || {};

      this.currentPage = current_page || 1;
      this.setState({ totalCount: total_count || 1 });

      this.setState({ promoCodeList: successResponse.data });
    } else if (requestId === this.promoCodeSuggestionId) {
      const list =
        successResponse?.filter_names?.map((value: string) => ({
          label: value,
          value,
        })) || [];
      const updatedFilters = this.state.filters.map((item: IFilterItem) => {
        if (item.title === "Promo Code") item.options = list;
        return item;
      });
      this.setState({ filters: updatedFilters });
    } else if (requestId === this.platformSuggestionId) {
      const list =
        successResponse?.filter_names?.map((value: string) => ({
          label: value,
          value,
        })) || [];
      const updatedFilters = this.state.filters.map((item: IFilterItem) => {
        if (item.title === "Platform") item.options = list;
        return item;
      });
      this.setState({ filters: updatedFilters });
    } else if (requestId === this.deactivateMessageId) {
      if (successResponse) {
        this.setState({
          confirmModalFlag: this.popOverItemStatus === "active",
        });
        this.setState({ popOverOpened: false });
        this.getPromoCodesApi();
      }
    }

    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    this.getPromoCodesApi();
    // Customizable Area End
  }

  // Customizable Area Start

  clearFilter(filterKey: string) {
    if (filterKey === "Section") {
      this.setState({ sectionSelected: [] });
    } else if (filterKey === "Date") {
      const updated = this.state.filters.map((item: IFilterItem) => {
        if (item.title === "Date") item.value = [null, null];
        return item;
      });
      this.setState({ filters: updated, startDate: null, endDate: null });
    }
  }  

  handleMultiSelectCheckboxClick(item: string) {
    const { sectionSelected } = this.state;

    if (sectionSelected.includes(item))
      this.setState({
        sectionSelected: sectionSelected.filter((i: string) => i != item),
      });
    else
      this.setState({
        sectionSelected: [...sectionSelected, item],
      });
  }

  getPromoCodesApi() {
    let paginationQuery = `page_no=${this.currentPage}&per_page=${this.rowPerPage}`;
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: window.localStorage.getItem("token"),
    };
    const getDataMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));

    getDataMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      ApiUrls.listApi +
        paginationQuery +
        this.filterQuery +
        this.searchQuery +
        this.sortQuery
    );
    getDataMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    getDataMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");

    this.promoSortingId = getDataMsg.messageId;
    runEngine.sendMessage(getDataMsg.id, getDataMsg);
  }

  onChangePage(page: number) {
    this.currentPage = page;
    this.getPromoCodesApi();
  }

  getSectionList() {
    let requestMessage: Message = MakeApiMessage({
      url: ApiUrls.sectionList + localStorage.getItem(configJSON.Strings.token),
      method: "GET",
    });
    this.sectionMessageId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getPromoCodeSuggestionList(query: string) {
    let updated = this.state.filters.map((item: IFilterItem) => {
      if (item.title === "Promo Code") item.value = query;
      return item;
    });

    this.setState({ filters: updated });

    let requestMessage = MakeApiMessage({
      url: ApiUrls.promoCodeSuggestion + query,
      method: "GET",
    });
    this.promoCodeSuggestionId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getPlatformSuggestionList(query: string) {
    let updated = this.state.filters.map((item: IFilterItem) => {
      if (item.title === "Platform") item.value = query;
      return item;
    });

    this.setState({ filters: updated });

    let requestMessage = MakeApiMessage({
      url: ApiUrls.platformSuggestion + query,
      method: "GET",
    });
    this.platformSuggestionId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  onApplyFilter(filters: IFilterItem[]) {
    const { sectionList, sectionSelected } = this.state;

    this.filterQuery = filters
      .map((currentFilter: IFilterItem) => {
        const { title, value, apiKey } = currentFilter;
        let apiString: string = "";

        if (title === "Date") {
          const [start, end] = value || [];
          start &&
            (apiString = `filter_by[start_date]=${moment(start).format(
              "DD-MM-YYYY"
            )}`);

          end &&
            (apiString += `&filter_by[end_date]=${moment(end).format(
              "DD-MM-YYYY"
            )}`);
        } else if (title === "Section") {
          let ids = sectionSelected
            .map((value: string) => {
              const item: ISection | undefined = sectionList.find(
                (item: ISection) => item?.section_name === value
              );
              return item;
            })
            .map((item: ISection | undefined) => item?.id)
            .filter((item: number | undefined) => item)
            .map(
              (id: number | undefined) =>
                `filter_by[section_ids][]=${String(id)}`
            );

          apiString = ids.join("&");
        } else value && (apiString = `filter_by[${apiKey}]=${value}`);

        return apiString;
      })
      .filter((item) => item)
      .join("&");

    if (this.filterQuery) this.filterQuery = "&" + this.filterQuery;

    this.getPromoCodesApi();
  }

  handleConfirmModal() {
    this.setState({
      confirmModalFlag: false,
    });

    setTimeout(() => {
      if (!this.state.confirmModalType) {
        this.setState({
          confirmModalType: true,
        });
        this.onEditApi();
      } else this.setState({ confirmModalType: false });
    }, 1000);
  }

  onEditApi(activate = false) {
    let requestMessage = MakeApiMessage({
      url: ApiUrls.editPromoCode + this.popOverItemId,
      method: "PUT",
      body: JSON.stringify({
        data: {
          status: activate ? "active" : "inactive",
        },
      }),
    });
    this.deactivateMessageId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleFilterChange(event: { currentTarget: {} } | undefined) {
    this.setState({
      filterAnchor: event?.currentTarget as
        | HTMLDivElement
        | undefined,
    });
    if(this.state.sectionList.length === 0) {
      this.getSectionList();
    }
  }

  handleUserChange = (userContext: IUserContext) => {
    const apiKey = customPermissionApiKey.promocodePermissions;
    const userData = userContext.user?.attributes.permission_groups;
    const value = checkForNewPermissonStatus(apiKey, userData as Array<PermissionGroupArray>);
    this.setState({
      permissionStatus: value
    })
  };

  handleRedirection=(screen:string)=>{
    navigateTo({
      props: this.props,
      screenName: screen,
    });
  }

  // Customizable Area End
}

export default PromoCodeListController;
