import { CSSProperties } from "@material-ui/styles";


const styles: Record<string, CSSProperties> = {
   
  '@global': {
    '*::-webkit-scrollbar': {
      width: '6px'
    },
    '*::-webkit-scrollbar-track': {
      '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)'
    },
    '*::-webkit-scrollbar-thumb': {
      backgroundColor: '#d1d1d1',
      borderRadius: '10px'
    }
    
  },
    cancelBtn: {
      backgroundColor: '#F1F5F9',
      color: '#64748B',
      width: '126px',
      height: '56px',
      textTransform: 'none',
      border: "0"
    },
    addBtn: {
      backgroundColor: "#204B9C",
      color: "#ffff",
      width: '126px',
      height: "56px",
      marginLeft: "32px",
      textTransform: "none",
      border: "0",
      "&:hover": {
        backgroundColor: "#204B9C",
        color: "#ffff"
      }
    },
    servicesBtns: {
      display: "flex",
      justifyContent: "center",
      borderRadius: "8px",
      padding: "10px",
      flexWrap: "wrap",
      boxSizing: 'border-box'
    },
    flexContainer: {
      display: "flex",
      flexWrap: "wrap",
      gap: "10px"
    },
    secondNames: {
      height: '60px',
      borderRadius: '8px',
      display: "flex",
      justifyContent: "center",
      paddingLeft: '8px',
      fontWeight:400,
      fontSize:'14px',
      '& ::-webkit-input-placeholder': { color: 'rgba(77, 77, 77, 1)', fontWeight:400 },
    },
  
    enterNames: {
      height: "60px",
      color: "red",
      borderRadius: '8px',
      display: "flex",
      justifyContent: "center",
      paddingLeft: "8px",
      fontWeight:400,
      fontSize:'14px',
      '& ::-webkit-input-placeholder': { color: 'blue', fontWeight:400 },
      
    },
    inputLabels: {
      fontWeight: 600,
      fontSize: "16px",
      color: "#334155",
      marginBottom:0
    },
    button: {
      fontSize: "16px",
      fontWeight: 700,
      borderRadius: '8px'
    },
    onlineOrderText: {
      color: "#334155"
    },
   dropDown: {
      width: '460px',
      overflowY: 'auto',
      left:'-5px',
      color:"red"
      // color: 'rgba(77, 77, 77, 1)'
      
    },

    menuItem: {
      '&:hover': {
        backgroundColor: 'transparent', // Change this to your desired hover color
      },
    },
    cardBox: {
      minHeight: "320px",
      borderRadius: "12px",
      border: 0,
      boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.03), 0px 8px 32px rgba(0, 0, 0, 0.06)"
    },
    titleContainer: {
      display: "flex",
      borderColor: "1px solid red",
      justifyContent: "space-between",
      alignItems: "center",
      margin: "10px 0 0 10px",
      padding: "46px 0",
    },
    titleContainerText: {
      fontSize: '24px',
      fontWeight: 600,
      lineHeight: '29.26px',
  
  
    },
    mainContainer: {
      margin: '32px 0',
      width: '100%',
      padding:'10px 49px',
  
    },
    GalleryTitle: {
        display: "flex",
        borderColor: "1px solid red",
        justifyContent: "space-between",
        alignItems: "center",
        margin: "10px 0 0 10px",
      },
      galleryBtn: {
        backgroundColor: "#204B9C",
        color: "#FFFFFF",
        borderRadius: "8px",
      },
      flex: {
        display: "flex",
        flexDirection: "column",
        gap: "2rem",
      },
    
    
      fontSize_24: {
        fontSize: "24px",
      },
      fw600: {
        fontWeight: 600,
      },
      lineHeight_29: {
        lineHeight: "29.26px",
      },
      padding_46: {
        padding: "46px 0",
      },
    
      select: {
        border: '1px solid #ECECEC',
        '&:focus': {
          border: '1px solid #ECECEC',
          borderBottom: '0',
        },
      },
      underline: {
        borderBottom: '0',
      },
      onelineOrder:{
          display: 'flex'
      },
      customCheckbox: {
        display: 'flex',
        alignItems: 'center',
        padding: '4px 8px',
        gap: '7px',
        backgroundColor: 'rgba(32, 75, 156, 0.1)',
        borderRadius: '16px',
        color: "#204B9C",
       
      },
      error:{
        color: "rgb(244, 67, 54)"
      },

      marginTopZero: {
        marginTop: 0
      },

      checkBoxContainer:{
        display: 'flex', 
        gap: '10px'

      }

  
  }

  export default styles