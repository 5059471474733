import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import React from "react";
import { apiCall, makeApiMessage as MakeApiMessage } from "../../../components/src/common";
import { FormikValues } from "formik";
import moment from "moment";
import { isNumber } from "lodash";
import { ReceiptPrinterSetting } from "../../navigationmenu/src/PageContainerController.web";
import { getCustomEnumName, CustomEnums } from "../../utilities/src/CustomBlockHelpers";

export interface IOption {
  label: string;
  value: string | number;
  disabled?: boolean;
}

export enum FieldType {
  dateRange,
  textbox,
  select,
  date,
}
export interface IFieldOptions {
  label: string;
  type: FieldType;
  filterKey: string;
  span?: boolean;
}

export interface OrderItems {
  attributes: {
    catalogue: {
      name: string;
      product_name: string;
      product_second_name: string;
    },
    quantity: number;
  }
}

export interface IPaid {
  amount: string;
  id: number;
  name: string;
  second_name: string;
  active: boolean;
  created_at: string;
  updated_at: string;
  name_translation: string;
  attributes: {
    icon: {
      data: {
        id: number;
        type: string;
        attributes: {
          id: number;
          name: string;
          second_name: string;
          image_type: string;
          name_translation: string;
          image: string;
        };
      };
    };
  };
}

export interface OrderTransactions {
  attributes: {
    payment_method: {
      name: string;
    }
  }
}

export interface OrderResult {
  data: ISearchResult
}
export interface ISearchResult {
  id: string | number;
  type: string;
  attributes: {
    id: number;
    order_number: string;
    account_id: number;
    notes: string;
    sub_total: string;
    status: string;

    created_at: string;
    internal_status: string;
    is_quick_drop: boolean;
    order_items: OrderItems[];
    total_items: number;
    total_pieces: number;
    paid_at: Date | null;
    customer: {
      id: number;
      full_phone_number: string;
      email: string;
      full_name: string;
      business: string | null;
      full_address: string;
      customer_profile: {
        street_address: string;
        business_label: string | null;
        customer_id: string | number;
      };
    };
    send_to_zatca?: boolean;
    order_transactions: OrderTransactions[];
    currency: string;
    total: string;
    store_name: string;
    applied_discount: string;
    cancellation_reason: string;
    tax_charges: string;
    shipping_charge: string;
    shipping_discount: string;
    shipping_total: string;
    total_tax: string;
    updated_at: string;
    save_for_future: boolean;
    promo_code_id: number;
    store_management_id: number;
    tax_included_in_product_price: boolean;
    address_id: string | null;
    is_mobile: boolean;
    adjustment_reason: string;
    adjustment_note: string;
    refund_amount: string;
    rewash_order: boolean;
    customer_complaint: string | null;
    parent_order_id: string | null;
    adjustment_request: string | null;
    adjustment_order: boolean;
    tax_percentage: string;
    driver_account_id: string | null;
    driver_action: string | null;
    driver_action_at: string | null;
    driver_confirmed_at: string | null;
    driver_order_changes: string | null;
    pickup_date: string | null;
    delivery_date: string | null;
    status_plant: string;
    placed_at: string,
    invoice_receipt_pdf_url: string;
    split_order_by: string | null,
    delivery_address: string | null,
    order_created_by: {
      id: number;
      first_name: string;
      last_name: string;
      full_name: string;
    },
    promo_code: {
      saved_amount: string;
      discount: string;
      expiry_date: string |null,
      min_order_amount: string;
      promo_expires: boolean;
      promo_code: string;
      description: string;
      id: number;
      currency: string;
      max_discount: string;
    },
    store_second_name: string | null,
    name_translation: string;
    no_of_items: number;
    no_of_pieces: number;
    store_management: {
      data: {
        id: string;
        type: string;
        attributes: {
          id: number;
          store_name: string;
          name_translation: string;
        }
      }
    },
    invoice_pdf_url: string | null,
    total_upcharge_amount: string;
    driver_account: string | null,
    price_list_name: string;
  };
}

export interface ISearchResultData {
  data: ISearchResult[];
  meta: {
    total_pages: number;
    total_count: number;
    current_page: number;
    next_page: number | null;
    previous_page: number | null;
  };
}

export interface IFilterData {
  name?: string;
  mobile_number?: string;
  email?: string;
  address?: string;
  customer_id?: string;
  business?: string;
  payment?: string;
  payment_type?: number;
  paid?: string;
  specific_date?: string;
  order_id?: string;
  notes?: string;
  placed?: string;
  paid_date_range?: [string, string];
  placed_date_range?: [string, string];
}

export interface ISearchResultList {
  openOrderDetails: Function;
  searchResultList: ISearchResult[];
  totalCount: number;
  currentPage: number;
  rowPerPage: number;
  onChangePage: Function;
  isDrawerOpen: boolean;
  tabId: number;
  handleTabChange: Function;
  handleMomentDateFormat: Function;
  onPrintReceipt: (fileUrl: string) => void
  showPaymentModal?: Function;
}

export interface IBusinessData {
  data: IBusiness[];
}

export interface ICityData {
  cities: ICity[];
}

export interface ICity {
  id: number;
  name: string;
  name_ar: string;
  name_en: string;
}

interface IBusiness {
  id: string;
  type: string;
  attributes: {
    id: number;
    label: string;
    module_name: string;
    created_at: string;
    updated_at: string;
    model_name: string;
  };
}

interface IStore {
  id: string;
  type: string;
  attributes: {
    id: number;
    store_name: string;
  };
}

interface IStoreData {
  data: Array<IStore>;
}

interface IconAttributes {
  id: number;
  name: string;
  second_name: string;
  image_type: string;
  name_translation: string;
  image: string;
}

interface IconData {
  id: number;
  type: string;
  attributes: IconAttributes;
}

interface PaymentMethodAttributes {
  id: number;
  name: string;
  second_name: string;
  active: boolean;
  created_at: string;
  updated_at: string;
  name_translation: string;
  attributes: {
    icon: {
      data: IconData;
    };
  };
}

interface PaymentMethod {
  id: number;
  name: string;
  second_name: string;
  active: boolean;
  created_at: string;
  updated_at: string;
  name_translation: string;
  attributes: PaymentMethodAttributes;
}

interface PaymentMethodResp {
  status: number;
  data: PaymentMethod[];
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  orderData: ISearchResult | never | null;
  firstNameSearchText: string;
  lastNameSearchText: string;
  advancedsearchList: any;
  activeId: number;
  activeFirstName: string;
  activeLastName: string;
  activeUserName: string;
  activeEmail: string;
  activePhoneNumber: string;
  activeCountryCode: string;
  activeType: string;
  activeDeviceId: string;
  activeCreatedAt: string;
  isVisible: boolean;
  selectedSearchType: string;
  selectedOrderType: string;
  allPaymentMethod: IOption[];
  allBusinessList: IOption[];
  allCitiesList: IOption[];
  searchResultList: ISearchResult[];
  totalCount: number;
  totalSearchedResult: number | null;
  filterValues: IFilterData;
  tabId: number;
  isDrawerOpen: boolean;
  showPaymentPopup: boolean;
  selectedOrder: ISearchResult | never | null;
  paymentClicked: boolean;
  customerId: string;
  receiptPrinterSetting: ReceiptPrinterSetting | null;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class AdvancedSearchController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  advancedsearchApiCallId: string = "";

  commonFields = [
    {
      filterKey: "name",
      label: "Name",
      type: FieldType.textbox,
    },
    {
      filterKey: "mobile_number",
      label: "Mobile No",
      type: FieldType.textbox,
    },
    {
      filterKey: "email",
      label: "Email",
      type: FieldType.textbox,
    },
    {
      filterKey: "address",
      label: "Address",
      type: FieldType.textbox,
    },
    {
      filterKey: "customer_id",
      label: "Customer ID",
      type: FieldType.textbox,
    },
  ];

  customerFields: Array<IFieldOptions> = [
    ...this.commonFields,
    {
      filterKey: "city_id",
      label: "City",
      type: FieldType.select,
    },
    {
      filterKey: "business",
      label: "Business",
      type: FieldType.select,
    },
  ];
  orderFields: Array<IFieldOptions> = [
    ...this.commonFields,
    {
      filterKey: "business",
      label: "Business",
      type: FieldType.select,
    },
    {
      filterKey: "payment",
      label: "Payment",
      type: FieldType.select,
    },
    {
      filterKey: "payment_type",
      label: "Payment Types",
      type: FieldType.select,
    },
    {
      filterKey: "paid",
      label: "Paid",
      type: FieldType.select,
    },
    {
      filterKey: "paid_date",
      label: "Paid",
      type: FieldType.date,
    },
    {
      filterKey: "paid_date_range",
      label: "Paid - Date Range",
      type: FieldType.dateRange,
      span: true,
    },
    {
      filterKey: "order_id",
      label: "Order ID",
      type: FieldType.textbox,
    },
    {
      filterKey: "notes",
      label: "Notes",
      type: FieldType.textbox,
    },
    {
      filterKey: "placed",
      label: "Placed",
      type: FieldType.select,
    },
    {
      filterKey: "placed_date",
      label: "Placed",
      type: FieldType.date,
    },
    {
      filterKey: "placed_date_range",
      label: "Placed - Date Range",
      type: FieldType.dateRange,
      span: true,
    },
  ];

  currentPage: number = 1;
  rowPerPage: number = 10;
  searchOrderApiCallID: string = "";
  getAllPaymentMethodApiCallID: string = "";
  getAllBusinessListApiCallID: string = "";
  getAllCitiesListApiCallID: string = "";
  getOrderMessageId: string = "";

  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      token: "",
      orderData: null,
      firstNameSearchText: "",
      lastNameSearchText: "",
      advancedsearchList: [],
      activeId: 0,
      activeFirstName: "",
      activeLastName: "",
      activeUserName: "",
      activeEmail: "",
      activePhoneNumber: "",
      activeCountryCode: "",
      activeType: "",
      activeDeviceId: "",
      activeCreatedAt: "",
      isVisible: false,
      selectedSearchType: "Orders",
      selectedOrderType: "All Orders",
      allPaymentMethod: [],
      allBusinessList: [],
      allCitiesList: [],
      searchResultList: [],
      totalCount: 10,
      totalSearchedResult: null,
      filterValues: {},
      tabId: 0,
      isDrawerOpen: localStorage.getItem("isPinned") == "true",
      showPaymentPopup: false,
      selectedOrder: null,
      paymentClicked: false,
      customerId: '',
      receiptPrinterSetting: null
      // Customizable Area End
    };
    // Customizable Area Start
    this.showPaymentModal = this.showPaymentModal.bind(this)
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    // Customizable Area Start
    this.getAllPaymentMethod();
    this.getAllBusinessList();
    // Customizable Area End
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      switch (apiRequestCallId) {
        case this.getAllPaymentMethodApiCallID:
          this.handlePaymentAllMethodResponse(responseJson);
          break;
        case this.getAllBusinessListApiCallID:
          this.handleBusinessListResponse(responseJson);
          break;
        case this.getAllCitiesListApiCallID:
          this.handleCitiesListResponse(responseJson);
          break;
        case this.searchOrderApiCallID:
          this.handleSearchApiListResponse(responseJson);
          break;
        case this.getOrderMessageId:
          this.handleGetOrderResponse(responseJson)
          break;
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start

  renderOptionValues(
    item: string,
    options: Array<IOption>,
    placeholder: string
  ) {
    let labelToDisplay: string | React.ReactNode = "";
    if (Array.isArray(options)) {
      let option = options.find((node) => node.value === item);
      labelToDisplay =
        option && option.value ? (
          option.label
        ) : (
          <span style={{ color: "#4D4D4D" }}>{placeholder}</span>
        );
    }
    return labelToDisplay;
  }

  getAllPaymentMethod() {
    let paymentApiUrl = `${configJSON.getAllPaymentMethodEndPoint}?dropdown=true`;
    let headers = {
      token: localStorage.getItem("token"),
    };
    const getAllPaymentAllMsg = apiCall({
      httpBody: {},
      header: headers,
      url: paymentApiUrl,
      httpMethod: "GET",
    });
    this.getAllPaymentMethodApiCallID = getAllPaymentAllMsg.messageId;
    runEngine.sendMessage(getAllPaymentAllMsg.id, getAllPaymentAllMsg);
  }

  getAllBusinessList() {
    let businessApiUrl = `${configJSON.getAllBusinessListEndPoint}`;
    let headers = {
      token: localStorage.getItem("token"),
    };
    const getAllBusinessListMsg = apiCall({
      httpBody: {},
      header: headers,
      url: businessApiUrl,
      httpMethod: "GET",
    });
    this.getAllBusinessListApiCallID = getAllBusinessListMsg.messageId;
    runEngine.sendMessage(getAllBusinessListMsg.id, getAllBusinessListMsg);
  }

  getAllCitiesList() {
    let citiesApiUrl = `${configJSON.getCitiesListEndPoint}`;

    const getAllCityListMsg = apiCall({
      httpBody: {},
      header: {},
      url: citiesApiUrl,
      httpMethod: "GET",
    });

    this.getAllCitiesListApiCallID = getAllCityListMsg.messageId;
    runEngine.sendMessage(getAllCityListMsg.id, getAllCityListMsg);
  }

  handlePaymentAllMethodResponse(responseJson: PaymentMethodResp) {
    let newArray = responseJson.data.map((item) => {
      return {
        value: item.id,
        label: item.name,
      };
    });
    this.setState({ allPaymentMethod: newArray });
  }

  handleBusinessListResponse(responseJson: IBusinessData) {
    let newArray = responseJson.data.map((item: IBusiness) => {
      return {
        value: item.id,
        label: item.attributes.label,
      };
    });
    this.setState({ allBusinessList: newArray });
  }

  handleCitiesListResponse(responseJson: ICityData) {
    let newArray = responseJson.cities.map((item: ICity) => {
      return {
        value: item.id,
        label: item.name,
      };
    });
    this.setState({ allCitiesList: newArray });
  }

  handleSearchApiListResponse(responseJson: ISearchResultData) {
    const { data, meta } = responseJson;
    this.setState({
      searchResultList: data,
      totalCount: meta.total_count,
      totalSearchedResult: meta.total_count,
    });
    this.currentPage = meta.current_page;
  }

  getOptions(stringkey: string) {
    const { allPaymentMethod, allBusinessList, allCitiesList } = this.state;
    switch (stringkey) {
      case "payment_type":
        return allPaymentMethod;
      case "paid":
      case "placed":
        return [
          {
            label: "After",
            value: "After",
          },
          {
            label: "Before",
            value: "Before",
          },
        ];
      case "payment":
        return [
          {
            label: "Paid",
            value: "Paid",
          },
          {
            label: "Unpaid",
            value: "Unpaid",
          },
        ];
      case "business":
        return allBusinessList;
      case "city_id":
        return allCitiesList;
      case "all_orders":
        return [
          {
            label: "All Orders",
            value: "all",
          },
          {
            label: "Awaiting Clean",
            value: "cleaning",
          },
          {
            label: "Awaiting Collection",
            value: "ready",
          },
          {
            label: "Deadstock",
            value: "send_to_deadstock",
          },
          {
            label: "Collected",
            value: "",
            disabled: true,
          },
          {
            label: "Picking Up",
            value: "",
            disabled: true,
          },
        ];
    }
    return [];
  }

  isDateFieldDisable(filterKey: string, values: FormikValues) {
    if (filterKey === "paid_date") return !values["paid"];
    else if (filterKey === "placed_date") return !values["placed"];
    else return false;
  }  

  handelDateRangeChange(
    filterKey: string,
    setFieldValue: Function,
    value?: string
  ) {
    value && setFieldValue(filterKey, value);
    if (["paid_date", "paid"].includes(filterKey)) {
      setFieldValue("paid_date_range", "");
    } else if (["placed_date", "placed"].includes(filterKey)) {
      setFieldValue("placed_date_range", "");
    } else if (filterKey === "paid_date_range") {
      setFieldValue("paid", "");
      setFieldValue("paid_date", "");
    } else if (filterKey === "placed_date_range") {
      setFieldValue("placed", "");
      setFieldValue("placed_date", "");
    }
  }

  onChangeStatus(value:string) {
    this.setState({
      selectedOrderType: value,
      totalSearchedResult: null,
      searchResultList: [],
    });
  }

  handleSearchApiCall(values: FormikValues) {
    const { selectedOrderType, selectedSearchType } = this.state;
    this.setState({ filterValues: values });
    const tabId = this.orderTypeReturn();

    const filterList = Object.keys(values).filter((item) => values[item]);
    let orderStatus = this.getOptions("all_orders")?.filter((item) => {
      return item.label == selectedOrderType;
    })[0].value;
    const allRecords = orderStatus === "all";
    let statusKey =
      selectedSearchType === "Orders" && !allRecords
        ? `status=${orderStatus}&`
        : "";

    let urlString = filterList.map((item) => {
      if (item == "paid_date") {
        return `paid[date]=${moment(values[item]).format("YYYY-MM-DD")}`;
      } else if (item == "placed_date") {
        return `placed[date]=${moment(values[item]).format("YYYY-MM-DD")}`;
      } else if (item == "paid_date_range") {
        return `paid_date_range[from]=${moment(values[item][0]).format(
          "YYYY-MM-DD"
        )}&paid_date_range[to]=${moment(values[item][1]).format("YYYY-MM-DD")}`;
      } else if (item == "placed_date_range") {
        return `placed_date_range[from]=${moment(values[item][0]).format(
          "YYYY-MM-DD"
        )}&placed_date_range[to]=${moment(values[item][1]).format(
          "YYYY-MM-DD"
        )}`;
      } else if (item == "placed") {
        return `placed[value]=${values[item]}`;
      } else if (item == "paid") {
        return `paid[value]=${values[item]}`;
      } else {
        return `${item}=${values[item]}`;
      }
    });

    let searchApiurl = `${configJSON.searchOrderApiEndPoint}?page_no=${
      this.currentPage
    }&per_page=${this.rowPerPage}&${statusKey}${urlString.join("&")}&order_type=${tabId}`;
    let headers = {
      token: localStorage.getItem("token"),
    };

    const getSearchResultMsg = apiCall({
      httpBody: {},
      header: headers,
      url: searchApiurl,
      httpMethod: "GET",
    });

    this.searchOrderApiCallID = getSearchResultMsg.messageId;
    runEngine.sendMessage(getSearchResultMsg.id, getSearchResultMsg);
  }

  onChangePage(page: number) {
    this.currentPage = page;
    this.handleSearchApiCall(this.state.filterValues);
  }

  onChangeSearchType(value: string) {
    this.setState({
      selectedSearchType: value,
      totalSearchedResult: null,
      searchResultList: [],
      tabId: 0
    });
    value === 'Customers' && this.state.allCitiesList.length === 0 && this.getAllCitiesList();
  }

  onResetForm(callback?: Function) {
    this.setState({
      searchResultList: [],
      totalSearchedResult: null,
      selectedSearchType: "Orders",
      selectedOrderType: "All Orders",
      tabId: 0
    });
    callback && callback();
  }

  getDynamicFieldArray(isOrder: boolean): Array<IFieldOptions> {
    return isOrder ? this.orderFields : this.customerFields;
  }

  getDynamicInitialValues(fields: Array<IFieldOptions>) {
    return fields.reduce((accval, current) => ({ ...accval, [current.filterKey]: "" }), {});
  }

  setModal = (item: any) => {
    this.setState({
      activeId: item.id,
      activeFirstName: item.attributes.first_name,
      activeLastName: item.attributes.last_name,
      activeUserName: item.attributes.user_name,
      activeEmail: item.attributes.email,
      activePhoneNumber: item.attributes.phone_number,
      activeCountryCode: item.attributes.country_code,
      activeType: item.type,
      activeDeviceId: item.attributes.device_id,
      activeCreatedAt: item.attributes.created_at,
      isVisible: !this.state.isVisible,
    });
  };

  closeOrderDetails() {
    this.setState({ orderData: null });
  }

  openOrderDetails(data: ISearchResult) {
    this.setState({ orderData: data });
  }

  getAdvancedSearchList = (token: string) => {
    if (
      this.state.firstNameSearchText.length === 0 &&
      this.state.lastNameSearchText.length === 0
    ) {
      return;
    }

    const header = {
      "Content-Type": configJSON.advancedsearchApiContentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    let attrs = null;

    if (
      this.state.firstNameSearchText.length > 0 &&
      this.state.lastNameSearchText.length > 0
    ) {
      attrs = {
        first_name: this.state.firstNameSearchText,
        last_name: this.state.lastNameSearchText,
      };
    } else if (this.state.firstNameSearchText.length > 0) {
      attrs = {
        first_name: this.state.firstNameSearchText,
      };
    } else if (this.state.lastNameSearchText.length > 0) {
      attrs = {
        last_name: this.state.lastNameSearchText,
      };
    }

    this.advancedsearchApiCallId = requestMessage.messageId;

    
    let urlParams = new URLSearchParams(attrs as Record<string, string>).toString();

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getAdvancedSearchApiEndPoint}?${urlParams}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  setFirstNameText = (firstName: string) => {
    this.setState({ firstNameSearchText: firstName });
  };

  setLastNameText = (firstName: string) => {
    this.setState({ lastNameSearchText: firstName });
  };

  hideModal = () => {
    this.setState({ isVisible: !this.state.isVisible });
  };

  txtInputFirstNameSearchTextProps = {
    onChangeText: (text: string) => {
      this.setFirstNameText(text);
    },
  };

  txtInputLastNameSearchTextProps = {
    onChangeText: (text: string) => {
      this.setLastNameText(text);
    },
  };

  orderTypeReturn = () => {
    const { tabId } = this.state;
    switch(tabId) {
      case 0:
        return "store_order";
      case 1:
        return "home_cleaning";
    }
  }

  handleTabChange = (valNum: number) => {
    const { filterValues } = this.state;
    this.setState({ 
      tabId: valNum
    }, () => this.handleSearchApiCall(filterValues));
  };

  handleOpenChanged = (open: boolean) => {
    this.setState({ 
      isDrawerOpen: open 
    })
  };

  handleMomentDateFormat(date: string, dateFormat: string) {
    const formatedDate = moment(date).format(dateFormat);
    return formatedDate;
  }

  showPaymentModal(order: ISearchResult) {
    this.setState({
      showPaymentPopup: !this.state.showPaymentPopup,
      selectedOrder: order
    })
  }

  handlePaymentDone = () => {
    this.setState({ paymentClicked: true });
    this.getOrder();
  };

  getOrder() {
    let order = this.state.selectedOrder;
    this.setState({ customerId: String(order?.attributes.account_id) });

    let message = MakeApiMessage({
      method: "GET",
      url: configJSON.getOrderApi + order?.id,
    });
    this.getOrderMessageId = message.messageId;
    runEngine.sendMessage(message.id, message);
  }

  handleGetOrderResponse(
    response: OrderResult
  ) {
      if (response.data) {
        this.setState({ selectedOrder: response.data });
        if (this.state.paymentClicked) {
          this.setState(
            {
              showPaymentPopup: false,
              paymentClicked: false,
              selectedOrder: null
            },
            () => { this.handleSearchApiCall(this.state.filterValues) }
          );
        }
      }
  }

  handlePaymentModalClose = () => {
    this.setState({ showPaymentPopup: false });
  };

  formatTaxPercentage = () => {
    const { selectedOrder } = this.state;

    if (!selectedOrder) return null;
    const decimalIndex = selectedOrder.attributes.tax_percentage.indexOf(".");
    const formattedTaxPercentage =
    selectedOrder.attributes.tax_percentage.slice(0, decimalIndex) + "%";
    return formattedTaxPercentage;
  };

  convertToOptionObj(options: IOption[],idProperty: keyof IOption) {
    return options.map(option => ({
      id: String(option[idProperty]),
      option: String(option.label),
      disabled: option.disabled || false
    }));
  }

  handleChangePrinterSetting = (setting: ReceiptPrinterSetting) => this.setState({receiptPrinterSetting: setting})

  handlePrintReceipt = (fileUrl: string) => {
    const printMessage = new Message(getCustomEnumName(CustomEnums.CustomActionReducers))
    printMessage.addData(getCustomEnumName(CustomEnums.CustomReducerAction), "PRINT_FILE")
    printMessage.addData(getCustomEnumName(CustomEnums.CustomReducerPayload), {
      format: "pdf",
      data: fileUrl
    })
    const numOfCopies = this.state.receiptPrinterSetting?.no_of_copies
    const printerOption = isNumber(numOfCopies) && numOfCopies > 1 ? {copies: numOfCopies} : undefined 
    printMessage.addData(getCustomEnumName(CustomEnums.CustomPrinterOptions), printerOption)
    this.send(printMessage)
  }

  // Customizable Area End
}
