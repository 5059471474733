import React, { Component } from "react";
import Switch, { SwitchProps } from "@material-ui/core/Switch";
import { styled } from "@material-ui/styles";
type Props = {
  checked: boolean;
  onChange: (value: boolean) => void;
  style?: any;
};
type State = {};

const StyledSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName='.Mui-focusVisible' disableRipple {...props} />
))(() => ({
  width: 48,
  height: 28,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(22px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: "#204B9C",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color: "#E2E8F0",
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 23,
    height: 23,
    backgroundColor: "white",
  },
  "& .MuiSwitch-track": {
    borderRadius: 28 / 2,
    backgroundColor: "#E2E8F0",
    opacity: 1,
    // transition: theme.transitions.create(["background-color"], {
    //   duration: 500,
    // }),
  },
}));

export default class CustomSwitch extends Component<Props, State> {
  constructor(props: any) {
    super(props);
    this.state = { disableModalVisible: false, backToList: false };
  }

  render() {
    return (
      <StyledSwitch
        {...this.props}
        checked={this.props.checked}
        onChange={(event, checked) => this.props.onChange(checked)}
        data-test-id="switch-test"
      />
    );
  }
}
