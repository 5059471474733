exports.tokenKey = "token";
exports.logoutLabel = "Logout";
exports.logoLabel = "logo";
exports.test = "test";
exports.viewProfileLabel = "View Profile";
exports.dynamicLogoCall = "bx_block_settings/dynamic_contents";
exports.permissionApi = "account_block/employees";
exports.permissionApiMethod = "GET";
exports.dynamicLogoApiContentType = "application/json";
exports.dynamicLogoApiMethodType = "GET";
exports.userShortNameKey = "userShortName";
exports.cancelButton = "Cancel";
exports.textMessage = "Are you sure you want to deactivate the"
exports.unDoneMessage = "This process cannot be undone"
exports.confirmDeactiveMessage = "has been deactivated successfully";
exports.applyButton = "Apply";
exports.clearButton = "Clear All";
exports.ascendingButton = "Ascending";
exports.descendingButton = "Descending";
exports.activateButton = "Activate";
exports.deActivateButton = "Deactivated";
exports.onlineButton = "Online";
exports.offlineButton = "Offline";
exports.sectionText = "Section Name";
exports.statusText = "Status";
exports.orderText = "Order";
exports.filterTitle = "Filters";
exports.upchargeText = "Upcharge";
exports.upchargeError = "Please select at least one option for upcharge.";
exports.preferenceText = "Preference";
exports.preferenceError = "Please select at least one option for preference.";
exports.placeholderText = "Type to Select"

exports.getNotificationCountEndPoint = "/bx_block_notifications/notifications/notification_count";
exports.getMethod = "GET";