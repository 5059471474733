Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "CfMetalProgettiSolutionIntegration2";
exports.labelBodyText = "CfMetalProgettiSolutionIntegration2 Body";

exports.btnExampleTitle = "CLICK ME";

exports.sharedFolderLocationStorageKey = "sharedFolderLocation"
exports.sharedFolder = "Shared Folder"
exports.metalProgetti = "Metal Progetti"
exports.metalProgettiTabTestId = "metal-progetti-tab"
exports.addConveyorTestId = "add-conveyor-btn"

exports.employeeApi = "account_block/employees"

exports.metalProgettiSettingApi = "bx_block_cfmetalprogettisolutionintegration2/metalprogetti_settings"
exports.plantListApi = "bx_block_plants/plants"
exports.storeListApi = "bx_block_store_management/store_managements?dropdown=true&allow_access=true"
exports.sectionListApi = "bx_block_categories/categories?dropdown=true"
// Customizable Area End