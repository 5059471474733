import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
export interface OrgInputVal {
  org_name: string,
  org_taxnumber: string,
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes?: any;
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  isLoading: boolean;
  addOrgintialValue: OrgInputVal;
  isEdit: boolean;
  organizationId: number;
  errorSnackbarOpen: boolean;
  errorMessage: string;
  successSnackbarOpen: boolean;
  successMessage: string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class AddOrganizationController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getOrganizationCallId: string = "";
  createOrganizationApiId: string = "";
  editOrganizationApiId: string = ""

  async componentDidMount() {
    const path = window.location.pathname;
    let organizationId: any = this.props.navigation.getParam('organizationId');
    this.processPath(path, organizationId);
  }

  processPath = (path: string, organizationId: number) => {
    if (path === `/Marketing-Customer-OrganizationList-edit-organization/${organizationId}`) {
      this.setState({isEdit: true});
      this.setState({organizationId: organizationId}, () => this.getOrganizationDetails());
    }
  };
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),

      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      isLoading: false,
      addOrgintialValue: {
        org_name: "",
        org_taxnumber: ""
      },
      isEdit: false,
      organizationId: 0,
      errorSnackbarOpen: false,
      errorMessage: "",
      successSnackbarOpen: false,
      successMessage: ""
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      switch(apiRequestCallId) {
        case this.getOrganizationCallId:
          this.handleGetOrgResponse(responseJson);
          break;
        case this.createOrganizationApiId:
          this.handleAddOrgResponse(responseJson);
          break;
        case this.editOrganizationApiId:
          this.handleEditOrgResponse(responseJson);
          break;
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start

  getOrganizationDetails = () => {
    this.setState({ isLoading: true });
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem(configJSON.token),
    };
    const apiUrl = configJSON.getAllOrgListEndPoint+"/"+this.state.organizationId
    const getOrgMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getOrganizationCallId = getOrgMessage.messageId;
    getOrgMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiUrl
    );
    getOrgMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getOrgMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getOrgMessage.id, getOrgMessage);
  }

  createOrganization = (values: any) => {
    const header = {
      "token": localStorage.getItem(configJSON.token)
    };
    let formData = new FormData();
    formData.append("organisation[name]", values.org_name);
    formData.append("organisation[tax_number]", values.org_taxnumber);
    const createOrgmessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createOrganizationApiId = createOrgmessage.messageId;
    createOrgmessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createOrgApiEndPoint
    );
    createOrgmessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    createOrgmessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );
    createOrgmessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );
    runEngine.sendMessage(createOrgmessage.id, createOrgmessage);
  }

  editOrganization = (values: any) => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": localStorage.getItem(configJSON.token)
    };
    const formBody = {
      name: values.org_name,
      tax_number: values.org_taxnumber
    };
    const apiUrl = configJSON.getAllOrgListEndPoint+"/" +this.state.organizationId
    const editOrgmessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.editOrganizationApiId = editOrgmessage.messageId;
    editOrgmessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiUrl
    );
    editOrgmessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    editOrgmessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(formBody)
    );
    editOrgmessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.typeput
    );
    runEngine.sendMessage(editOrgmessage.id, editOrgmessage);
  }

  handleSubmit = (values: any) => {
    this.setState({ isLoading: true });
    this.state.isEdit
      ? this.editOrganization(values)
      : this.createOrganization(values)
  }

  handleBackTotListing = () => {
    this.props.navigation.navigate("OrganizationList")
  }

  handleSnackbarClose = () => {
    this.setState({errorSnackbarOpen: false})
  }

  handleSuccessSnackbarClose = () => {
    this.setState({successSnackbarOpen: false})
  }

  handleGetOrgResponse = (responseJson: any) => {
    if (responseJson && responseJson.data) {
      const item = {
        org_name: responseJson.data.attributes.name,
        org_taxnumber: responseJson.data.attributes.tax_number,
      }
      this.setState({
        addOrgintialValue: item,
        isLoading: false
      })
    }
  }

  createErrorMessage = (responseJson: any) => {
    let errorMsg = "";
    if(responseJson.name && responseJson.tax_number) {
      if(responseJson.tax_number.length > 1){
        errorMsg = responseJson.name[0]+" "+"and"+" "+responseJson.tax_number[0]+" "+"and"+" "+responseJson.tax_number[1];
      } else {
        errorMsg = responseJson.name[0] + " " + "and" + " " + responseJson.tax_number[0];
      }
      return errorMsg;
    } else if(responseJson.name) {
      errorMsg = responseJson.name[0];
      return errorMsg;
    } else {
      errorMsg = responseJson.tax_number[0];
      return errorMsg;
    }
  }

  handleAddOrgResponse = (responseJson: any) => {
    if (responseJson && responseJson.data) {
      this.setState({
        isLoading: false,
        successMessage: configJSON.orgAddedSuccessMsg,
        successSnackbarOpen: true,
        addOrgintialValue: {
          org_name: "",
          org_taxnumber: ""
        }
      });
      setTimeout(() => this.handleBackTotListing(), 1000);
    } else {
      this.setState({
        isLoading: false,
        addOrgintialValue: {
          org_name: "",
          org_taxnumber: ""
        },
        errorSnackbarOpen: true,
        errorMessage: this.createErrorMessage(responseJson) || ""
      });
    }
  }

  handleEditOrgResponse = (responseJson: any) => {
    if (responseJson && responseJson.data) {
      this.setState({ 
        isLoading: false,
        successMessage: configJSON.orgEditedSuccessMsg,
        successSnackbarOpen: true,
      });
      setTimeout(() => this.handleBackTotListing(), 1000);
    } else {
      this.setState({
        isLoading: false,
        errorSnackbarOpen: true,
        errorMessage: this.createErrorMessage(responseJson) || ""
      });
    }
  }

  // Customizable Area End
}
