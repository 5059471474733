// Customizable Area Start
import React from "react";
import {
  styled,
  Dialog,
  DialogContent,
  TextareaAutosize,
  Button,
} from "@material-ui/core";
import { Formik, FormikErrors, FormikProps } from "formik";
import {
  IFormValues,
  configJSON,
} from "../../../blocks/OrderCreation/src/OrderCreation2Controller.web";
import { colors } from "../../../blocks/utilities/src/Colors";
import { CatalogueList } from "../Interface";
import { validationSchemaForAddProduct } from "../../../blocks/OrderCreation/src/utils";
import { renderBaseOnConditions } from "../../../blocks/utilities/src/CustomBlockHelpers";
import {
  TextLabel,
  ErrorLabel,
} from "../../../blocks/promocodes/src/PromoCodeCreate.web";
import { StyledTextField } from "../customComponents/CustomTags.web";
import {
  MinusIcon,
  PlusIcon,
  productAddIcon,
} from "../../../blocks/OrderCreation/src/assets";

// Customizable Area End

// Customizable Area Start

interface Props {
  productPopup: boolean;
  carpetFurnitureProductPopupData: CatalogueList | null;
  selectedService: { name: string; id: number;} | null;
  handleSubmitCarpetAndFurnitureProduct: Function;
  closeCarpetAndFurnitureModal: Function
}
// Customizable Area End

// Customizable Area Start
const handleChangeAddProductItem = (
  recordId: number,
  currentId: string,
  currentKey: string,
  currentValue: unknown,
  allItems: IFormValues[],
  setValue: Function
) => {
  const newArray = JSON.parse(JSON.stringify(allItems));
  const array = newArray.map((item: IFormValues) => {
    if (currentId === item.catalogue_id && recordId == item.id) {
      item[currentKey] = currentValue as string;
      if (currentKey === "height" || currentKey === "width") {
        item.weight = "";
      } else if (currentKey === "weight") {
        item.height = "";
        item.width = "";
      }
    }
    return item;
  });

  setValue(array as IFormValues[]);
};

const getGraterZeroValue = (value: unknown) => {
  if (Number(value) > 0 || isNaN(Number(value))) return Number(value);
  else return 0;
};
// Customizable Area End

// Customizable Area Start
const CarpetAndFurnitureProductDialogue: React.FC<Props> = (props) => {
  // props
  // carpetFurnitureProductPopupData
  // selectedService
  // productPopup (open or close)
  const idValue = new Date().getTime();
  const initialValues = {
    items: [
      {
        id: idValue,
        size: "",
        width: "",
        notes: "",
        height: "",
        weight: "",
        quantity: 1,
        isNew: true,
        catalogue_id: props.carpetFurnitureProductPopupData?.attributes?.id,
        measurement_type:
          props.carpetFurnitureProductPopupData?.attributes?.catalogue_variants
            .data[0].attributes.measurement_type || [],
      },
    ],
  };
  const {
    productPopup,
    carpetFurnitureProductPopupData: productPopupData,
    selectedService,
  } = props;

  const { product_name, catalogue_variants } =
    (productPopupData as CatalogueList)?.attributes || {};

  const measurement_type = catalogue_variants?.data[0]?.attributes
    ?.measurement_type as string[];

  const servicePrice =
    catalogue_variants?.data[0]?.attributes?.catalogue_variants_services.find(
      (variantService) =>
        Number(variantService.service.id) === Number(selectedService?.id)
    );

  const sqmPrice = servicePrice?.sqm_price;
  let unitSQM = Number(servicePrice?.sqm);
  const weightPrice = servicePrice?.weight_price;
  let unitWeight = Number(servicePrice?.weight);
  const Strings = configJSON.Strings;

  return (
    <StyledDialog open={productPopup} maxWidth={false}>
      <DialogContent>
        <div className='dialog-title'>{product_name}</div>
        {productPopupData && (
          <Formik
            enableReinitialize
            key={"formik-add-popup-form"}
            validationSchema={validationSchemaForAddProduct}
            initialValues={{
              ...JSON.parse(JSON.stringify(initialValues)),
              weightError: "",
              sizeWeightError: "",
            }}
            onSubmit={(data: { items: IFormValues[] }) =>
                props.handleSubmitCarpetAndFurnitureProduct(data.items)
            }
          >
            {({
              values,
              errors,
              handleSubmit,
              validateForm,
              getFieldHelpers,
            }: FormikProps<{ items: IFormValues[] }>) => {
              const { setValue } = getFieldHelpers("items");
              return (
                <form id='productForm'>
                  {values.items.map((item: IFormValues, index: number) => {
                    const mainItemId = item.catalogue_id;
                    const error = (errors?.items?.[index] ||
                      {}) as FormikErrors<IFormValues>;
                    const recordId = item.id as number;

                    const hasSqm = measurement_type.includes("SQM");
                    const hasWeight = measurement_type.includes("Weight");

                    const area = Number(
                      Number(item.width) * Number(item.height)
                    ).toFixed(2);
                    const isAreaNaN = isNaN(Number(area));
                    let unitPrice = "0.0";
                    if (item.weight) {
                      const unitWeightPrice = Number(weightPrice);
                      unitWeight = Number(unitWeight);
                      unitPrice = Number(unitWeightPrice / unitWeight).toFixed(
                        2
                      );
                    } else if (item.height && item.width) {
                      const unitSQMPrice = Number(sqmPrice);
                      unitSQM = Number(unitSQM);
                      unitPrice = Number(unitSQMPrice / unitSQM).toFixed(2);
                    }
                    let valueToMultiply = 1;
                    if (item.height || item.width) {
                      valueToMultiply = Number(area);
                    } else if (item.weight) {
                      valueToMultiply = Number(item.weight);
                    }
                    const totalPrice =
                      Number(item.quantity) *
                      Number(unitPrice) *
                      Number(valueToMultiply);
                    const isPriceNaN = isNaN(totalPrice);

                    return (
                      <PlantOrderItemContainer
                        key={"product-popup-id" + item.id}
                        className='dialog'
                      >
                        <div className='dialog-container'>
                          {renderBaseOnConditions(
                            hasSqm,
                            <div>
                              <TextLabel>{Strings.size}</TextLabel>
                              <div className='border-box'>
                                <div className='grid gap-2 col-2'>
                                  <div>
                                    <TextLabel>{Strings["length"]}</TextLabel>
                                    <StyledTextField
                                      variant='outlined'
                                      placeholder='Type Here'
                                      InputProps={{
                                        endAdornment: (
                                          <span className='suffix'>m</span>
                                        ),
                                      }}
                                      inputProps={{
                                        "data-test-id": "field-height",
                                      }}
                                      value={item.height}
                                      onChange={(
                                        event: React.ChangeEvent<{
                                          value: string;
                                        }>
                                      ) =>
                                        handleChangeAddProductItem(
                                          recordId,
                                          mainItemId as string,
                                          "height",
                                          event.target.value,
                                          values.items,
                                          setValue
                                        )
                                      }
                                    />
                                    <ErrorLabel>{error.height}</ErrorLabel>
                                  </div>

                                  <div>
                                    <TextLabel>{Strings.width}</TextLabel>
                                    <StyledTextField
                                      value={item.width}
                                      variant='outlined'
                                      placeholder='Type Here'
                                      InputProps={{
                                        endAdornment: (
                                          <span className='suffix'>m</span>
                                        ),
                                      }}
                                      inputProps={{
                                        "data-test-id": "field-width",
                                      }}
                                      onChange={(
                                        event: React.ChangeEvent<{
                                          value: string;
                                        }>
                                      ) =>
                                        handleChangeAddProductItem(
                                          recordId,
                                          mainItemId as string,
                                          "width",
                                          event.target.value,
                                          values.items,
                                          setValue
                                        )
                                      }
                                    />
                                    <ErrorLabel>{error.width}</ErrorLabel>
                                  </div>
                                </div>
                                <div className='pt-32'>
                                  {Strings.area_of_surface}
                                  {renderBaseOnConditions(
                                    isAreaNaN,
                                    "__sqm",
                                    area + " sqm"
                                  )}
                                </div>
                              </div>
                              <ErrorLabel>{error.size}</ErrorLabel>
                              <ErrorLabel>{error.sizeWeightError}</ErrorLabel>
                            </div>,
                            <></>
                          )}
                          {renderBaseOnConditions(
                            hasWeight,
                            <div className='grid col-2'>
                              <div>
                                <TextLabel>{Strings.weight}</TextLabel>
                                <StyledTextField
                                  value={item.weight}
                                  variant='outlined'
                                  placeholder='Type Here'
                                  InputProps={{
                                    endAdornment: (
                                      <span className='suffix'>Kg</span>
                                    ),
                                  }}
                                  inputProps={{
                                    "data-test-id": "field-weight",
                                  }}
                                  onChange={(
                                    event: React.ChangeEvent<{ value: string }>
                                  ) =>
                                    handleChangeAddProductItem(
                                      recordId,
                                      mainItemId as string,
                                      "weight",
                                      event.target.value,
                                      values.items,
                                      setValue
                                    )
                                  }
                                />
                                <ErrorLabel>{error.weight}</ErrorLabel>
                                <ErrorLabel>{error.weightError}</ErrorLabel>
                                <ErrorLabel>{error.sizeWeightError}</ErrorLabel>
                              </div>
                              <div className='hide-768' />
                            </div>,
                            <></>
                          )}
                          <div>
                            <div className='flex space-between align-center'>
                              <TextLabel>{Strings.quantity}</TextLabel>
                              <div className='flex gap-2 align-center'>
                                <img
                                  src={MinusIcon}
                                  style={{
                                    opacity: renderBaseOnConditions(
                                      item.quantity > 0,
                                      1,
                                      0.5
                                    ) as number,
                                  }}
                                  onClick={() =>
                                    handleChangeAddProductItem(
                                      recordId,
                                      mainItemId as string,
                                      "quantity",
                                      getGraterZeroValue(
                                        Number(item.quantity - 1)
                                      ),
                                      values.items,
                                      setValue
                                    )
                                  }
                                  data-test-id='btn-minus'
                                />
                                <div className='count'>{item.quantity}</div>
                                <img
                                  src={PlusIcon}
                                  onClick={() =>
                                    handleChangeAddProductItem(
                                      recordId,
                                      mainItemId as string,
                                      "quantity",
                                      Number(item.quantity) + 1,
                                      values.items,
                                      setValue
                                    )
                                  }
                                  data-test-id='btn-plus'
                                />
                              </div>
                            </div>
                            <ErrorLabel>{error.quantity}</ErrorLabel>
                          </div>
                          <div>
                            <StyledTextArea
                              minRows={2}
                              value={item.notes}
                              data-test-id='field-notes'
                              placeholder={Strings.add_note_optional}
                              onChange={(
                                event: React.ChangeEvent<{ value: string }>
                              ) =>
                                handleChangeAddProductItem(
                                  recordId,
                                  mainItemId as string,
                                  "notes",
                                  event.target.value,
                                  values.items,
                                  setValue
                                )
                              }
                            />
                            <ErrorLabel>{error.notes}</ErrorLabel>
                          </div>
                          <div className='flex space-between'>
                            <TextLabel>{Strings.price}</TextLabel>
                            <TextLabel>
                              {"SAR"}{" "}
                              {renderBaseOnConditions(
                                isPriceNaN,
                                "0.0",
                                totalPrice.toFixed(2)
                              )}
                            </TextLabel>
                          </div>
                        </div>
                      </PlantOrderItemContainer>
                    );
                  })}
                  <div
                    className='link-button'
                    data-test-id='btn-addCarpet'
                    onClick={() => {
                      setValue([
                        ...values.items,
                        JSON.parse(
                          JSON.stringify({
                            ...initialValues.items[0],
                            id: new Date().getTime(),
                            isNew: true,
                          })
                        ),
                      ]);
                      setTimeout(() => validateForm(), 500);
                    }}
                  >
                    <img src={productAddIcon} />
                    <div>{Strings.add_another_carpet + product_name}</div>
                  </div>
                  <div className='button-container'>
                    <MainButton
                      data-test-id='btn-cancel'
                      className='buttonSecondary'
                        onClick={() => props.closeCarpetAndFurnitureModal()}
                    >
                      {configJSON.cancel}
                    </MainButton>
                    <MainButton
                      data-test-id='btn-addToBag'
                      onClick={() => handleSubmit()}
                    >
                      {Strings.add_to_bag}
                    </MainButton>
                  </div>
                </form>
              );
            }}
          </Formik>
        )}
      </DialogContent>
    </StyledDialog>
  );
};

const PlantOrderItemContainer = styled("div")({
  padding: 16,
  borderRadius: 12,
  boxShadow: "0px 2px 8px 0px #00000014",
  "@media only screen and (max-width: 1024px)": {
    fontSize: "13px !important",
  },
  "& .emptyCart": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  "&.dialog": {
    minWidth: 872,
    marginBottom: 32,
    "@media only screen and (max-width: 1024px)": {
      minWidth: "unset",
    },
    "& .hide-768": {
      "@media only screen and (max-width: 768px)": {
        display: "none",
      },
    },
    "& .suffix": {
      fontSize: 16,
      fontWeight: 600,
    },
    "& .pt-32": {
      paddingTop: 32,
    },
    "& .dialog-container": {
      gap: 32,
      display: "flex",
      flexFlow: "column",
    },
    "& .flex": {
      display: "flex",
      paddingBottom: 0,
    },
    "& .align-center": {
      alignItems: "center",
    },
    "& .space-between": {
      justifyContent: "space-between",
    },
    "& .grid": {
      display: "grid",
    },
    "& .col-2": {
      gridTemplateColumns: "1fr 1fr",
      "@media only screen and (max-width: 768px)": {
        gridTemplateColumns: "1fr",
      },
    },
    "& .gap-2": {
      gap: 12,
      gridGap: 12,
    },
  },
  "& .border-box": {
    borderRadius: 12,
    padding: 16,
    border: `1px solid ${colors().lightborder}`,
  },
  "& .detail-container": {
    gap: 16,
    fontSize: 14,
    display: "flex",
    fontWeight: 500,
    paddingBottom: 16,
    "& .right-section": {
      display: "flex",
      flexDirection: "column-reverse",
      alignItems: "flex-end",
      gap: 10,
    },
    "& .price-box": {
      display: "flex",
      justifyContent: "end",
      gap: 5,
      "& > .price": {
        minWidth: 80,
        textAlign: "right",
      },
    },
    "& .delete": {
      gap: 4,
      display: "flex",
      cursor: "pointer",
      alignItems: "center",
      "& span": {
        display: "none",
      },
    },
    "@media only screen and (max-width: 768px)": {
      flexFlow: "column",
      alignItems: "flex-start",
      "& > .price": {
        width: "100%",
        textAlign: "left",
        paddingBottom: 12,
        borderBottom: `1px dotted ${colors().lightborder}`,
      },
      "& .delete span": {
        display: "block !important",
      },
    },
    "@media only screen and (max-width: 1024px)": {
      fontSize: 13,
    },
  },
  "& .cart-item > div": {
    marginBottom: 16,
    borderBottom: `1px solid ${colors().lightborder}`,
  },
  "& .cart-item > div:last-child": {
    marginBottom: 0,
    border: 0,
  },
  "& .cart-item > div:last-child > div:last-child": {
    paddingBottom: 0,
  },
  "& .pcs": {
    width: "fit-content",
    height: "30px",
    background: "#EC4E20",
    color: "#FFF",
    textAlign: "center" as const,
    lineHeight: "30px",
    minWidth: "95px",
    padding: "0 8px",
    display: "flex",
    alignItems: "center",
    gap: 8,
    fontWeight: 400,
    borderRadius: 4,
    justifyContent: "center",
    backgroundColor: colors().orange,
    "@media only screen and (max-width: 1024px)": {
      minWidth: 80,
      height: 24,
      padding: 0,
    },
  },
  "& .details": {
    flexGrow: 1,
    color: colors().darkjunglegreen,
    textTransform: "capitalize",
  },
  "& .product-name": {
    fontSize: 14,
    fontWeight: 500,
    paddingBottom: 12,
    textTransform: "capitalize",
    color: colors().darkliver,
    "@media only screen and (max-width: 1024px)": {
      fontSize: 13,
    },
  },
  "& .flex": {
    display: "flex",
    paddingBottom: 16,
    justifyContent: "space-between",
    fontSize: 15,
    "@media only screen and (max-width: 1024px)": {
      fontSize: 13,
    },
  },
  "& .title": {
    fontWeight: 600,
  },
  "& .link": {
    cursor: "pointer",
    color: colors().orange,
    textDecoration: "underline",
  },
});

const StyledDialog = styled(Dialog)({
  "& .MuiDialog-paper": {
    borderRadius: 24,
    "& .MuiDialogContent-root": {
      padding: "24px 32px",
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: 8,
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: colors().lightborder,
    },
    "& .MuiTextField-root": {
      paddingTop: 0,
    },
    "& .link-button": {
      gap: 8,
      fontSize: 16,
      display: "flex",
      fontWeight: 700,
      cursor: "pointer",
      alignItems: "center",
      color: colors().cyancobaltblue,
    },
    "& .button-container": {
      display: "flex",
      paddingTop: 32,
      paddingBottom: 24,
      alignItems: "center",
      justifyContent: "center",
      "@media only screen and (max-width: 425px)": {
        flexFlow: "column-reverse",
        gap: 16,
      },
    },
    "& .dialog-title": {
      fontSize: 24,
      fontWeight: 600,
      paddingBottom: 32,
      textAlign: "center",
      textTransform: "capitalize",
    },
  },
});

const StyledTextArea = styled(TextareaAutosize)({
  padding: 8,
  width: "100%",
  borderRadius: 8,
  border: `1px solid ${colors().lightborder}`,
});

export const MainButton = styled(Button)({
  background: "#204B9C",
  fontWeight: 700,
  fontSize: "16px",
  lineHeight: "24px",
  color: colors().white,
  height: "50px",
  width: "186px",
  borderRadius: "8px",
  marginRight: 32,
  textTransform: "unset" as const,
  "&.MuiButton-root.Mui-disabled": {
    color: colors().white,
    cursor: "not-allowed",
    opacity: 0.5,
  },
  "&:hover": {
    background: "#204B9C",
    color: colors().white,
  },
  "&.buttonSecondary": {
    backgroundColor: "#F1F5F9",
    color: "#64748B",
  },
  "&.fullWidth": {
    width: "100%",
  },
  "&.mt-24": {
    marginTop: 24,
  },
});
export default CarpetAndFurnitureProductDialogue;
// Customizable Area End
