import React from "react";

// Customizable Area Start
import { Box,styled } from "@material-ui/core";
import Loader from "../../../components/src/Loader.web";
import PageContainer from "../../navigationmenu/src/PageContainer.web"
import OrganizationListViewController, {
  Props,
  configJSON,
} from "./OrganizationListViewController.web";
import { Styles } from "@material-ui/styles";
import { withStyles, Theme } from "@material-ui/core/styles";
import { OrgHeading, TopHeader } from "./OrganizationList.web";
import { colors } from "../../utilities/src/Colors";
import { renderBaseOnConditions } from "../../../blocks/utilities/src/CustomBlockHelpers";
// Customizable Area End

export class OrganizationListView extends OrganizationListViewController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes } = this.props;
    const { permissionStatus } = this.state;
    // Customizable Area End
    return (
      // Customizable Area Start
      <PageContainer
        navigation={this.props.navigation}
        onUserChange={(userContext) => this.handleUserChange(userContext)}
      >
        <Loader loading={this.state.isLoading} />
        <Box className={classes.mainContainer}>
          <TopHeader>
            <OrgHeading>{configJSON.organization}</OrgHeading>
            {
                renderBaseOnConditions(
                  permissionStatus.editPermission,
                  <EditOrganizationBtn
                    data-test-id='navigateEditPage'
                    onClick={() => this.navigateEditOrganizationPage()}
                  >
                    {configJSON.edit}
                  </EditOrganizationBtn>,
                  <></>
                )
              }
          </TopHeader>
       
          <Box className={classes.formOrgViewContainer}>
            <Box className={classes.formContainerInner}>
              <Box className={classes.rowContainer}>
                <Box className={classes.textHeader}>{configJSON.orgName}</Box>
                <Box className={classes.textContent} data-test-id='orgName'>
                  {this.state.organizationItem.name}
                </Box>
              </Box>
              <Box className={classes.rowContainer}>
                <Box className={classes.textHeader}>{configJSON.orgTaxNumber}</Box>
                <Box className={classes.textContent} data-test-id='taxNumber'>
                  {this.state.organizationItem.tax_number}
                </Box>
              </Box>
            </Box>
          </Box>
          <Box className={classes.btnGroup}>
            <BackOrganizationBtn
              data-test-id='backButton'
              onClick={this.handleBack}
              color='secondary'
            >
              {configJSON.back}
            </BackOrganizationBtn>
          </Box>
        </Box>
      </PageContainer>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export const useStyles = () => {
  return {
    mainContainer: {
      margin: "70px 0 0 0",
      width: "100%",
      padding: "0 32px 0 50px",
      fontFamily: "Montserrat, sans-serif",
    },
    formOrgViewContainer: {
      padding: "0 0 32px 0",
    },
    formContainerInner: {
      borderRadius: "12px",
      boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.03), 0px 8px 32px rgba(0, 0, 0, 0.06)",
      padding: "32px",
      display: "flex",
      "@media (max-width: 600px)": {
        flexWrap: 'wrap',
        gap: 10
      }
    },
    rowContainer: {
      display: "flex",
      flexDirection: "column",
      width: "100%"
    },
    textHeader: {
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: "18px",
      lineHeight: "22px",
      color: colors().darkliver,
      fontFamily: "Montserrat",
      "@media only screen and (max-width: 1024px)": {
        fontSize: "16px",
        fontWeight: 600,
        color:colors().viewTextColor
      },
    },
    textContent: {
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "16px",
      lineHeight: "22px",
      color: "#1A1A1A",
      marginTop: "12px",
      "@media only screen and (max-width: 1024px)": {
        fontSize: "13px",
        fontWeight: 500,
        color:colors().viewTextColor
      },
    },
    btnGroup: {
      display: "flex",
      gap: "32px",
      marginBottom: "16px",
    }
  }
}

const BackOrganizationBtn = styled("button")({
  textTransform: "capitalize",
  fontSize: "16px",
  padding: "16px 20px",
  borderRadius: "8px",
  background: colors().cyancobaltblue,
  lineHeight: "24px",
  fontWeight: 700,
  color: "rgb(255, 255, 255)",
  outline: "none",
  border: "none",
  "&.secondary": {
    color: colors().slateGray,
    background: colors().antiFlashWhite
  },
  width:"128px",
  height:"56px",
  "@media only screen and (max-width: 1024px)": {
    width: "128px",
    fontSize: "13px",
    padding: "10px 20px",
    height: "44px",
  },
  cursor: "pointer",
});

const EditOrganizationBtn = styled("button")({
  textTransform: "capitalize",
  padding: "16px 20px",
  borderRadius: "8px",
  fontWeight: 700,
  fontSize: "16px",
  "&.secondary": {
    color: colors().slateGray,
    background: colors().antiFlashWhite,
  },
  lineHeight: "24px",
  background: colors().cyancobaltblue,
  color: "rgb(255, 255, 255)",
  width:"184px",
  border: "none",
  cursor: "pointer",
  outline: "none",
  height:"56px",
  "@media only screen and (max-width: 1024px)": {
    padding: "10px 20px",
    width: "128px",
    fontSize: "13px",
    height: "44px",
  },
});

export default withStyles(useStyles as Styles<Theme, {}, string>)(OrganizationListView);
// Customizable Area End
