import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
// Customizable Area Start
import { ICustomer, IOrder } from "../../OrderCreation/src/OrderCreationController.web";

export const configJSON = require("./config.js");
// Customizable Area End

export interface Props {
  // Customizable Area Start
  disabled?: boolean;
  count?: number;
  order?: { attributes?: IOrder };
  customer?: ICustomer;
  onClose?: () => void;
  onCountChange?: (count: number) => void;
  handleChangeQty?: (event: React.ChangeEvent<{ value: string}>) => void;
  // Customizable Area End
}

export interface S {
  // Customizable Area Start
  count: number;
  // Customizable Area End
}

export interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class QuickDropController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),

      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];
    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    this.state = {
      // Customizable Area Start
      count: 0,
      // Customizable Area End
    };
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  async componentDidMount() {
    super.componentDidMount();
  }

  async componentDidUpdate(prevProps: Readonly<Props>) {
    if (prevProps.count !== this.props.count) {
      this.setState({ count: this.props.count || 0 });
    }
  }
  // Customizable Area End
}
