import React from "react";
// Customizable Area Start
import {
  Dialog,
  styled,
  DialogContent,
  Typography,
  Box,
  TextField,
  InputAdornment,
  DialogActions,
  Checkbox,
  Accordion, AccordionSummary, AccordionDetails,
} from "@material-ui/core";
import SearchOutlined from "@material-ui/icons/SearchOutlined";

import ActionPermissionsModalController, {
  Props,
} from "./ActionPermissionsModalController.web";
import {
  OrderTable,
  RowContainer,
  TableRow,
  TableCell,
} from "../../../components/src/OrderTable/src";
import { MainButton } from "../../../blocks/OrderCreation/src/OrderCreation.web";
import { colors } from "../../../blocks/utilities/src/Colors";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
// Customizable Area End

class ActionPermissionsModal extends ActionPermissionsModalController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { isOpenPermissionModal, onClosePermissionModal } = this.props;
    const { permissionGroupList, searchTerm } = this.state;

    const filterPermissionList = permissionGroupList.filter((item) =>
      item.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    // Customizable Area End

    return (
      // Customizable Area Start
      <ActionDialog open={isOpenPermissionModal} maxWidth='md'>
        <DialogContent className='p-24'>
          <Typography className='dialog-heading'>Custom Permissions</Typography>
          <Box className='search-modal-input'>
            <TextField
              className="searchInput"
              placeholder='Search'
              type='search'
              variant='outlined'
              fullWidth
              size='small'
              onChange={(event) => this.handleSearchPermission(event)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <SearchOutlined style={{color:"#4D4D4D"}} />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
            
            <Box className="accordionContainer">
              {filterPermissionList.map((permission) => (
                <Accordion key={permission.id} className="accordion">
                  <AccordionSummary
                    className="accordionSummary"
                    expandIcon={<ArrowDropDownIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography className="lable-16-500 txtCapitalize">{permission.name}</Typography>
                  </AccordionSummary>
                  <AccordionDetailsStyle>
                  <Box className="checkBoxCont1">
                      <StyledCheckbox
                           data-test-id={`selectall-permission`}
                           onChange={(event) =>
                            this.handleSelectAllPermisson(event,permission)        
                          }                  
                          checked={permission.permission_sub_groups.every((subPermission: {permitted:boolean}) => subPermission.permitted)}
                        />
                      <Typography className="lable-14-400 txtCapitalize">Select All</Typography>
                    </Box>
                    <Box className="accordionContent">
                      {permission.permission_sub_groups.map((subPermission) => (
                        <Box key={subPermission.id} className="checkBoxCont">
                          <StyledCheckbox
                            data-test-id={`checkbox-permission-${subPermission.id}`}
                            onChange={() =>
                              this.handleOnCheckPermisson(
                                permission,
                                subPermission.id
                              )
                            }
                            checked={subPermission.permitted}
                          />
                          <Typography className="lable-14-400 txtCapitalize">{subPermission.name}</Typography>
                        </Box>
                      ))}
                    </Box>
                  </AccordionDetailsStyle>
                </Accordion>
              ))}
            </Box>
          
          <DialogActions className='dialogActionWrapper'>
            <MainButton
              data-test-id='btnSubCancel'
              className='buttonSecondary'
              onClick={() => onClosePermissionModal()}
            >
              Cancel
            </MainButton>
            <MainButton
              data-test-id='btnSubNext'
              onClick={() => this.onUpdatePermission()}
            >
              Continue
            </MainButton>
          </DialogActions>
        </DialogContent>
      </ActionDialog>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

const ActionDialog = styled(Dialog)({
  "& .txtCapitalize":{
    textTransform: "capitalize"
  },
  "& .lable-16-500":{
    fontSize: "16px",
    fontWeight: 500,
    fontFamily: "Montserrat",
  },
  "& .lable-14-400":{
    fontSize: "14px",
    fontWeight: 400,
    fontFamily: "Montserrat",
  },
  "& .accordionSummary":{
    "& .MuiIconButton-label":{
      width:"36px",
      height:"36px",
      border: "1px solid #ECECEC",
      borderRadius:"8px",
      "& .MuiSvgIcon-root":{
        color:"black"
      }
    }
  },
  "& .checkBoxCont":{
    display:"flex",
    gap:"10px",
    border: "1px solid #ECECEC",
    height: "46px",
    borderRadius:"8px",
    boxSizing:"border-box",
    padding:"12px",
    alignItems: "center"
  },
  "& .checkBoxCont1":{
    width: "162px",
    display:"flex",
    gap:"10px",
    border: "1px solid #ECECEC",
    height: "46px",
    borderRadius:"8px",
    boxSizing:"border-box",
    padding:"12px",
    alignItems: "center"
  },
  "& .accordionContent":{
    display:"flex",
    gap:"20px",
    flexWrap:"wrap"
  },
  "& .accordionContainer":{
    boxShadow:"0px 2px 8px 0px #00000014",
    boxSizing: "border-box",
    padding: "16px 12px",
    display:"flex",
    flexDirection:"column",
    gap:"20px",
    overflow: "auto"
  },
  "& .accordion":{
    boxShadow:"0px 2px 8px 0px #00000014",
    "&.MuiAccordion-root:before": {
      backgroundColor: "transparent"
    }
  },
  "& .searchInput":{
    "& .MuiOutlinedInput-root":{
      borderRadius:"8px",
      border: "1px solid #ECECEC"
    },
    "& .MuiInputBase-input::placeholder":{
      color:"#4D4D4D"
    }
  },
  "& .MuiDialog-paperScrollPaper": {
    width: "100%",
    borderRadius: "24px",
    "@media only screen and (max-width: 1024px)": {
      maxWidth: "792px",
    },
  },
  "& .MuiDialogContent-root": {
    padding: "24px",
    display: "flex",
    flexDirection: "column",
  },
  "& .dialogActionWrapper": {
    justifyContent: "center",
    margin: "24px 0 0 0",
  },
  "& .dialog-heading": {
    fontSize: "24px",
    lineHeight: "32px",
    fontWeight: 600,
    fontFamily: "Montserrat",
    letterSpacing: "-0.5%",
    textAlign: "center" as const,
    margin: "0 0 24px 0",
  },
  "& .search-modal-input": {
    maxWidth: "300px",
    width: "100%",
    margin: "0 0 16px 0",
  },
  "& .jutify-center": {
    justifyContent: "center",
  },
  "& .action-table": {
    flex: "1 1 auto",
    overflow: "auto",
  },
});

const StyledCheckbox = styled(Checkbox)({
  "&.MuiCheckbox-root": {
    width: "20px",
    height: "20px",
    borderRadius: "6px",
  },
  "&.Mui-checked": {
    color: `${colors().cyancobaltblue} !important`,
  },
});

export const AddPermissionWrapper = styled(Box)({
  "&.permission-wrapper": {
    maxWidth: "280px",
    width: "100%",
    display: "flex",
    alignItems: "center",
    padding: "10px 20px",
    backgroundColor: colors().white,
    borderRadius: "8px",
    boxShadow:
      "rgba(0, 0, 0, 0.04) 0px 4px 8px, rgba(0, 0, 0, 0.06) 0px 8px 32px",
    cursor: "pointer",
  },
  "& .mt-24": {
    margin: "24px 0 0 0",
  },
  "& .add-permission-span": {
    fontSize: "16px",
    lineHeight: "24px",
    fontWeight: 700,
    fontFamily: "Montserrat",
    color: colors().cyancobaltblue,
    display: "block",
    margin: "0 0 0 10px",
  },
});

const AccordionDetailsStyle = styled(AccordionDetails)({
  flexDirection: "column",
  display:"flex",
  gap: "15px"
});

export default ActionPermissionsModal;
// Customizable Area End
