import React from 'react';
import {Grid} from "@material-ui/core";
import {Skeleton} from "@material-ui/lab";

export const CatalogueCardSkeleton = () => {
    return (
        <Grid 
            direction="column"
            justifyContent="center"
            alignItems="center"
            container
            style={{ padding: "6px",
                        border:"1px solid #ECECEC",
                        borderRadius: "5px", height:"175px", width: "144px"
            }}
        >
            <Skeleton animation='wave' style={{margin: "5px 0"}} width={70} height={70} variant='rect' />
            <Skeleton animation='wave' style={{margin: "5px 0"}} width={90} height={20} variant='rect' />
            <Skeleton animation='wave' style={{margin: "5px 0"}} width={90} height={20} variant='rect' />
        </Grid>
    );
};