import React from "react";

// Customizable Area Start
import {
    Box,
    Tabs,
    Tab,
    TableContainer,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Table,
    Paper,
    Grid,
    Typography,
    styled
    // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { StyledAppBar } from '../../../components/src/customComponents/CustomTags.web';


import OrderInformationController, {
    Props,
    configJSON
} from "./OrderInformationController.web";
import { priceListWebStyle } from "../../CfPriceList/src/CfPriceList.web";
import CircularProgress from "@material-ui/core/CircularProgress";
import {CrossIcon} from "./assets";
// Customizable Area End

export default class OrderInformation extends OrderInformationController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    renderOrderInformation() {
        if(this.state.selectedTab === 1) {
            return this.activeOrders()
        }
        else {
            return this.allOrders()
        }
    }

    activeOrders = () => {
        return <div>
            <TableContainer component={Paper}>
                <Table style={orderInformationWebStyle.table} aria-label='customized table'>
                    <TableHead>
                        <TableRow style={{ color: "#FFFFFF", background: "#204B9C" }}>
                            <TableCell
                                style={{
                                    ...priceListWebStyle.actionHeader,
                                    ...priceListWebStyle.tableHeaderLeft,
                                    ...orderInformationWebStyle.tableHeader,
                                    width: '23%',
                                    paddingLeft: '32px',
                                }}
                            >
                                ID
                            </TableCell>
                            <TableCell
                                style={{
                                    ...priceListWebStyle.actionHeader,
                                    ...orderInformationWebStyle.tableHeader,
                                    width: '24%'
                                }}
                                align='left'
                            >
                                Order Created
                            </TableCell>
                            <TableCell
                                style={{
                                    ...priceListWebStyle.actionHeader,
                                    ...orderInformationWebStyle.tableHeader,
                                    width: '29%'
                                }}
                                align='left'
                            >
                                Order
                            </TableCell>
                            <TableCell
                                style={{
                                    ...priceListWebStyle.actionHeader,
                                    ...orderInformationWebStyle.tableHeader,
                                    width: '10%'
                                }}
                                align='left'
                            >
                                Pieces
                            </TableCell>
                            <TableCell
                                style={{
                                    ...priceListWebStyle.actionHeader,
                                    ...priceListWebStyle.tableHeaderRight,
                                    ...orderInformationWebStyle.tableHeader,
                                    width: '14%'
                                }}
                                align='left'
                            >
                                <StatusWrapper>
                                  Status
                                </StatusWrapper>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                </Table>
                {this.state.isLoading ? <div style={{ textAlign: 'center', margin: '15px 0' }}><CircularProgress /></div> :
                    <div style={{ maxHeight: 350, overflowY: "scroll" }}>
                        <Table>
                            <TableBody data-test-id="tblBody">
                                {this.state.orderList?.length !== 0 ? this.state.orderList?.map((rowData: any) => (
                                    this.tableRow(rowData)
                                )) :
                                    <TableRow>
                                        <TableCell style={priceListWebStyle.nodataCellContainer} colSpan={6} align='left'>
                                            <div style={{ ...priceListWebStyle.noDataCell, textAlign: "center" }}>
                                                 {configJSON.noOrderMsg}
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                }
                            </TableBody>
                        </Table>
                    </div>
                }
            </TableContainer>
          </div>
    }

    checkRowData = (data: any) => {
        if(data) {
            return data
        }
        return "--"
    }

    tableRow = (rowData: any) => {
        return (
            <Grid container style={orderInformationWebStyle.tableData}>
                <Grid item xs={12} direction='row'>
                    <Grid
                        container
                        style={orderInformationWebStyle.tableRowData}
                        justifyContent='space-around'
                        alignItems='center'
                    >
                        <Grid item style={{ width: "23%" }}>
                            <Typography
                                variant='h6'
                                style={orderInformationWebStyle.rowData}
                                align="left"
                            >
                                {this.checkRowData(rowData?.attributes?.order_number)}
                            </Typography>
                        </Grid>
                        <Grid item style={{ width: "24%" }}>
                            <Typography
                                variant='h6'
                                style={orderInformationWebStyle.rowData}
                            >
                                {this.checkRowData(this.convertTo12HourFormat(rowData?.attributes?.created_at))}
                            </Typography>
                        </Grid>
                        <Grid item style={{ width: "29%" }}>
                            <Typography variant='h6' style={orderInformationWebStyle.rowData}>
                                {rowData?.attributes?.order.length > 0 ? rowData?.attributes?.order.map((o: any) => {
                                    return (<div key={o.name}>{o.name} <img src={CrossIcon} /> {o.qty}</div>)
                                }) : "--"}
                            </Typography>
                        </Grid>
                        <Grid item style={{ width: "10%" }}>
                            <Typography variant='h6' style={orderInformationWebStyle.rowData}>
                                {this.checkRowData(rowData?.attributes?.qty)}
                            </Typography>
                        </Grid>
                        <Grid item style={{ width: "14%" }}>
                            <Typography variant='h6' style={orderInformationWebStyle.rowData}>
                                {this.checkRowData(rowData?.attributes?.status)}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        )
    }

    allOrders = () => {
        return <div>
        <TableContainer component={Paper}>
            <Table style={orderInformationWebStyle.table} aria-label='customized table'>
                <TableHead>
                    <TableRow style={{ background: "#204B9C", color: "#FFFFFF" }}>
                        <TableCell
                            style={{
                                ...orderInformationWebStyle.tableHeader,
                                ...priceListWebStyle.actionHeader,
                                ...priceListWebStyle.tableHeaderLeft,
                                width: '23%',
                                paddingLeft: '32px'
                            }}
                            >
                                ID
                            </TableCell>
                        <TableCell
                            style={{
                                ...orderInformationWebStyle.tableHeader,
                                ...priceListWebStyle.actionHeader,
                                width: '24%'
                            }}
                            align='left'
                        >
                            Order Created
                            </TableCell>
                        <TableCell
                            style={{
                                ...orderInformationWebStyle.tableHeader,
                                ...priceListWebStyle.actionHeader,
                                width: '29%'
                            }}
                            align='left'
                            >
                                Order
                            </TableCell>
                        <TableCell
                            style={{
                                ...orderInformationWebStyle.tableHeader,
                                ...priceListWebStyle.actionHeader,
                                width: '10%'
                            }}
                            align='left'
                            >
                                Pieces
                            </TableCell>
                        <TableCell
                            style={{
                                ...orderInformationWebStyle.tableHeader,
                                ...priceListWebStyle.actionHeader,
                                ...priceListWebStyle.tableHeaderRight,
                                width: '14%'
                            }}
                            align='left'
                            >
                                <StatusWrapper>
                                  Status
                                </StatusWrapper>
                            </TableCell>
                    </TableRow>
                </TableHead>
                </Table>
                {this.state.isLoading ? <div style={{ textAlign: 'center', margin: '15px 0' }}>
                    <CircularProgress />
                </div> :
                    <div style={{ maxHeight: 350, overflowY: 'scroll' }}>
                        <Table>
                            <TableBody data-test-id="tblBody">
                                {this.state.orderList?.length !== 0 ? this.state.orderList?.map((rowData: any, index: any) => (
                                    this.tableRow(rowData)
                                )) :
                                    <TableRow>
                                        <TableCell style={priceListWebStyle.nodataCellContainer} align='left' colSpan={6}>
                                            <div style={{ ...priceListWebStyle.noDataCell, textAlign: 'center' }}>
                                                {configJSON.noOrderMsg}
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                }
                            </TableBody>
                        </Table>
                    </div>
                }
        </TableContainer>
      </div>
    }
    // Customizable Area End

    render() {
        // Customizable Area Start
        // Customizable Area End
        return (
            // Customizable Area Start
            <Box>
                <Box style={{ width: '320px', margin: '45px auto 30px' }}>
                    <StyledAppBar position='static'>
                        <Tabs
                            value={this.state.selectedTab}
                            variant='scrollable'
                            onChange={(event: any, value: any) => { this.handleTabChange(event, value) }}
                            scrollButtons="off"
                            data-test-id="tabsGroup"
                        >
                            <Tab
                                label={"Active Orders"}
                                value={1}
                                key={1}
                                style={orderInformationWebStyle.tabStyle}
                            />
                            <Tab
                                label={"All Orders"}
                                value={2}
                                key={2}
                                data-test-id='btn-all-orders'
                                style={orderInformationWebStyle.tabStyle}
                            />
                        </Tabs>
                    </StyledAppBar>
                </Box>
                <Box>
                    {this.renderOrderInformation()}
                </Box>
            </Box>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
export const orderInformationWebStyle: any = {
    tabStyle: {
        textTransform: 'none',
        fontSize: '18px',
        fontWeight: 600
    },
    table: {
        width: 850,
    },
    bodyContainer: {
        height: '200px'
    },
    tableHeader: {
        color: "FFFFFF",
        fontSize: "18px",
        fontWeight: 600,
        background: "#204B9C",
        paddingLeft: 0
    },
    cellContainer: {
        padding: "0px",
        border: "hidden",
    },
    tableData: {
        margin: "10px 0",
        paddingLeft: '7px'
    },
    tableRowData: {
        width: '100%',
        minHeight: '64px',
        border: "1px solid #ECECEC",
        height: "unset",
        borderRadius: "8px"
    },
    rowData: {
        fontSize: "14px",
        fontWeight: 600,
        wordBreak: "break-all"
    }
};
const StatusWrapper = styled("p")({
    "@media only screen and (max-width: 1024px)": {
        paddingLeft:"30px"
      },
})
// Customizable Area End
