import React from "react";
// Customizable Area Start
import {
  Box,
  TextField,
  Card,
  InputLabel,
  Grid,
  Typography,
  CardProps,
  TypographyProps,
  Button,
  Snackbar,
  styled
} from "@material-ui/core";
import { createTheme, ThemeProvider, } from "@material-ui/core/styles";
import { addImage } from "../src/assets"
import Alert from '@material-ui/lab/Alert';

import GalleryController, { Props, configJSON } from "./GalleryController.web";
import PageContainer from "../../navigationmenu/src/PageContainer.web"
import ThreeDotDropdown from "../../../components/src/ThreeDotDropdown";
import { Formik , Form} from 'formik'
import DropdownMenu from "../../../components/src/DropdownMenu.web";
import Loader from "../../../components/src/Loader.web";
import { CSSProperties } from "@material-ui/styles";
import { PageTitle } from "./Gallery.web";
import { colors } from "../../../blocks/utilities/src/Colors";


const theme = createTheme({
  typography: {
    fontFamily: "Montserrat, sans-serif",
  },
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

// Customizable Area End





export default class AddImageIcon extends GalleryController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start



  render() {
    const pageTitle = this.state.isEditMode ? configJSON.editIcon :configJSON.addIcon
    return (
      //Merge Engine DefaultContainer
      <ThemeProvider theme={theme}>
        <PageContainer 
          onUserChange={(userContext) => this.handleUserChange(userContext)}
          data-test-id='pageContainer' navigation={this.props.navigation} onSearchText={(value) => this.onSearchText(value)}>
          <Box>
          <Formik
            data-test-id='addImageIconFormik'
            enableReinitialize={true}
            validateOnBlur={true}
            initialValues={{
              primaryName: this.getFormikInitValue(this.state.editItemData?.attributes?.name),
              secondaryName: this.getFormikInitValue(this.state.editItemData?.attributes?.second_name),
              selectedType: this.getFormikInitValue(this.state?.selectedItem),
              uploadFileUrl: this.getFormikInitValue(this.state.editItemData?.attributes?.image),
              id: this.state.editItemData?.attributes?.id
            }}
            onSubmit={(
              values,
              { resetForm, setFieldValue }
            ) => {
              {this.state.isEditMode ? this.updateGalleryItem(values) :this.handleSubmit(values)}
            }}
            validationSchema={this.FormSchema}
          >
            {({
              errors,
              values,
              handleSubmit,
              setFieldValue,
              handleChange,
              touched,
            }) => {
              return (
                // @ts-ignore
                <Form onSubmit={handleSubmit} translate="ImageIcon">
                  <Box style={{...webStyle.margin, flexDirection:'column'}}>
                    <Loader loading={this.state.loading} />
                    <Box
                      sx={{
                        ...webStyle.GalleryTitle,
                       }}
                    >
                      <PageTitle
                        data-test-id='pageTitle'
                      >
                        {pageTitle}
                      </PageTitle>
                    </Box>
                    <Card
                      style={{
                        ...webStyle.flexRow as CardProps,
                        ...webStyle.cardWidth,
                      }}
                    >
                      <Box sx={webStyle.fieldWidth}>
                        <StyleInputLabel
                          id="demo-simple-select-label"
                        >
                          Type
                        </StyleInputLabel>
                        <DropdownMenu
                          errorProp={ this.checkIsError(touched, errors, "selectedType") ? true: false}
                          data-test-id="dropdown"
                          items={configJSON.typeDropdownValues}
                          setFieldsValue={setFieldValue}
                          selectedItem={this.state?.selectedItem}
                          onSelect={this.handleSelectItem}
                          width="100%"
                          ignoreWidth={true}
                        />
                        {this.checkIsError(touched, errors, "selectedType") && (
                          <Typography style={webStyle.error}>
                            {configJSON.selectOneTypeText}
                          </Typography>
                        ) }
                      </Box>

                      <Box>
                        <Grid container spacing={4}>
                          <Grid item xs={12} sm={6}>
                            <StyleInputLabel
                              id="name"
                            >
                              Name
                            </StyleInputLabel>
                            <StyledTextField
                              value={values.primaryName}
                              id="name"
                              name="primaryName"
                              fullWidth
                              placeholder={configJSON.imageNamePlaceholder}
                              variant="outlined"
                              onChange={handleChange}
                              error={
                                touched.primaryName &&
                                Boolean(errors.primaryName)
                              }
                              helperText={
                                this.checkIsError(touched, errors, "primaryName")
                                  ? errors.primaryName
                                  : ""
                              }
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <StyleInputLabel
                              id="second_name"
                            >
                              Arabic Name
                            </StyleInputLabel>
                            <StyledTextField
                              id="second_name"
                              value={values.secondaryName}
                              name="secondaryName"
                              fullWidth
                              dir="rtl"
                              placeholder={configJSON.imageArabicNamePlaceholder}
                              variant="outlined"
                              onChange={handleChange}
                              error={
                                Boolean(touched.secondaryName) &&
                                Boolean(errors.secondaryName)
                              }
                              helperText={
                                this.checkIsError(touched, errors, "secondaryName")
                                  ? errors.secondaryName
                                  : ""
                              }
                            />
                          </Grid>
                        </Grid>
                      </Box>
                      <MainImgGrid
                        container
                      >
                        <Grid
                          item
                          xs={12}
                          style={{
                            ...webStyle.flexBetween as CSSProperties,
                            ...webStyle.marginTopZero,
                          }}
                        >
                          <Box
                            style={{
                              ...webStyle.flexCenter,
                              ...webStyle.gap_14,
                            }}
                          >
                            <input
                              data-test-id="fileUpload"
                              accept={configJSON.uploadInputAccepts}
                              name="uploadFileUrl"
                              style={{ display: "none" }}
                              id="contained-button-file"
                              multiple
                              type="file"
                              onChange={(e) =>
                                this.handleUploadClick(e, setFieldValue)
                              }
                            />
                            <label htmlFor="contained-button-file">
                              <StyledImg
                                src={addImage}
                                alt="add_image"
                                className="image"
                              />
                            </label>

                            <StyledImgLable>
                              {this.state.imageLabel}
                            </StyledImgLable>
                          </Box>
                          <ThreeDotDropdown
                            getSelectedOption={(value:string, id:string)=> this.getSelectedOption(value, id, setFieldValue )}
                            color="#475569"
                            options={configJSON.threeDotOptions}
                            data-test-id='fileUploadMenuIcon'
                          />
                        </Grid>
                      </MainImgGrid>
                      <StyledImgLable>
                          {this.state.selectedFileName}
                      </StyledImgLable>
                      {this.checkIsError(touched, errors, "uploadFileUrl") ? (
                        <Typography style={webStyle.error}>
                          {`${configJSON.uploadErrorMessage} ${this.state.imageLabel}`}
                        </Typography>
                      ) : (
                        ""
                      )}
                      <Typography
                        style={{
                          ...webStyle.fontSm,
                          ...webStyle.fw500 as TypographyProps,
                        }}
                      >
                        {configJSON.ResolutionImage}
                      </Typography>
                    </Card>
                    <BottomButton className="bottomButton">
                      {configJSON.buttonMap.map((item: any, index: number) => (
                        <StyledButton
                         data-test-id="cancelBtn"
                          type={this.getAddImageSubmitBtnType(item.title)}
                          variant="outlined"
                          key={item.title}
                          style={{
                            ...item.cssProperties,
                            ...webStyle.font_md,
                            ...webStyle.fw700,
                          }}
                          onClick={()=> this.handleCancelBtn(item)}
                          >
                          {this.getAddImageSubmitBtnText(item.title)}
                        </StyledButton>
                      ))}
                    </BottomButton>
                  </Box>
                </Form>
              )
            }
            }
          </Formik>
          <Snackbar anchorOrigin={configJSON.anchorOptions}
                            
                            autoHideDuration={configJSON.autoHideDuration}
                            open={this.state.snackbar.open}
                            onClose={this.handleCloseSnackbar}

                            
                            >
                            <Alert severity={this.state.snackbar.severity}>
                            {this.state.snackbar.message}
                            </Alert>
                            </Snackbar>
           </Box>
        </PageContainer>
      </ThemeProvider>
      //Merge Engine End DefaultContainer
    );
  }
    // Customizable Area End
}

// Customizable Area Start
const webStyle = {
  GalleryTitle: {
    display: "flex",
    borderColor: "1px solid red",
    justifyContent: "space-between",
    alignItems: "center",
   
  },
  margin: {
    margin: '3rem 17px',
    paddingLeft: '33px',
    display: 'flex',
    marginTop: "110px",
}
,
  flex: {
    display: "flex",
    flexDirection: "column",
    gap: "2rem",
  },
  flexRow: {
    display: "flex",
    flexDirection: "column",
    gap: "2rem",
    padding: "32px",
    marginTop:"20px"
  },
  selectedRadio: {
    color: "#204B9C",
  },
  marginBottom: {
    marginBottom: "4px",
  },
  addImageIconContainer: {
    width: "327px",
    height: "84px",
    borderRadius: "8px",
    boxShadow:
      "0px 4px 8px rgba(0, 0, 0, 0.03), 0px 8px 32px rgba(0, 0, 0, 0.06)",
  },
  fontSm: {
    fontSize: "12px",
  },
  fw500: {
    fontWeight: "500",
  },
  lineHeight_12: {
    lineHeight: "12",
  },
  flexBetween: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexWrap: "nowrap",
  },
  marginTopZero: {
    margin: "0 8px",
  },
  flexCenter: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  gap_14: {
    gap: "14px",
  },
  marginTop_64: {
    marginTop: "64px",
  },
  marginBottom_4: {
    marginBottom: "4px",
  },
  width_400: {
    width: "400px",
  },
  borderRadius_8: {
    borderRadius: "8px",
  },
  height_64: {
    height: "64px",
  },
  fw600: {
    fontWeight: 600 as CSSProperties["fontWeight"],
  },
  lineHeight_29: {
    lineHeight: "29.26px",
  },
  fontSize_24: {
    fontSize: "24px",
  },
  padding_32: {
    padding: "32px",
  },

  font_md: {
    fontSize: "16px",
  },
  colorNeutral: {
    color: "#334155",
  },
  fw700: {
    fontWeight: 700 as CSSProperties["fontWeight"],
  },
  cardWidth: {
    width: "calc(100% - 50px)",
  },
  error: {
    color: "#f44336",
    fontSize: "0.75rem",
  },
  fieldWidth: {
    width: "50%",
    paddingRight: 15,
    "@media (max-width: 600px)": {
      width: "100%",
      paddingRight: 0
    }
  }
};

export const StyledTextField = styled(TextField)({
  borderRadius: 8,
  border: "1px solid var(--border, #ECECEC)",
  "@media only screen and (max-width: 1024px)": {
    '& .MuiInputBase-input': {
      fontSize: '12px',
      padding:'12.9px 14px'
    },
  },
});

const MainImgGrid = styled(Grid)({
  width: "327px",
  height: "84px",
  borderRadius: "8px",
  boxShadow:"0px 4px 8px rgba(0, 0, 0, 0.03), 0px 8px 32px rgba(0, 0, 0, 0.06)",
  "@media only screen and (max-width: 1024px)": {
    height: "60px"
  },
});

const StyleInputLabel = styled(InputLabel)({
  marginBottom: "4px",
  fontSize: "16px",
  fontWeight: 600,
  color: colors().charcoal,
  "@media only screen and (max-width: 1024px)": {
    fontSize: "13px",
    fontWeight: 600,
  },
});

const StyledButton = styled(Button)({
  borderRadius: "8px !important",
  "@media only screen and (max-width: 1024px)": {
    width: "128px !important",
    height: "44px !important",
    fontSize: "13px !important",
  },
});

const StyledImg = styled('img')({
  "@media only screen and (max-width: 1024px)": {
    width:"30px",
    height:"30px"
  },
})

const StyledImgLable = styled(Typography)({
  fontSize: "16px",
  fontWeight: 500,
  color: colors().charcoal,
  "@media only screen and (max-width: 1024px)": {
    fontSize: "14px",
  },
})

const BottomButton = styled(Box)({
  marginTop: "35px"
})

// Customizable Area End





