// Customizable Area Start
import React, { FunctionComponent } from "react";
import { TableCell } from "@material-ui/core";

export type SortingType = "" | "asc" | "desc";
export interface ISortingData {
  [key: string]: SortingType;
}

interface SortingTableCellProps {
  content: React.ReactNode;
  width: string;
  type?: "left" | "middle" | "right" | "action" | "icon";
  iconSrc?: string;
  style?: any;
  tableCellStyle?: any;
}

const SortingTableCellWrap: FunctionComponent<SortingTableCellProps> = ({
  content,
  width,
  type = "middle",
  iconSrc,
  tableCellStyle = {},
  style = {}

}) => {
  const typeStyle =
    type === "left"
      ? webStyle.sortingTableLeftCell
      : type === "right"
      ? webStyle.sortingTableRightCell
      : type === "middle"
      ? webStyle.sortingTableMiddleCell
      : {};
  return (
    <TableCell
      style={
        type === "action"
          ? webStyle.sortingTableActionCellContainer
          : webStyle.sortingTableCellContainer
      }
      align='left'
    >
      {type === "icon" ? (
        <div
          style={{
            ...webStyle.sortingTableMiddleCell,
            whiteSpace: "nowrap",
            paddingTop: "6px",
            paddingBottom: "5px",
            maxWidth: width,
            ...tableCellStyle,
          }}
        >
          <img style={webStyle.cellIcon} src={iconSrc} />
        </div>
      ) : (
        <div style={{ ...typeStyle, maxWidth: width, ...tableCellStyle , ...style ,  textWrap:"wrap",}}>
          {typeof content === "string"
            ? content.trim() ?? "---"
            : content ?? "---"}
        </div>
      )}
    </TableCell>
  );
};

const webStyle = {
  sortingTableCellContainer: {
    border: "hidden",
    padding: "0px",
    paddingTop: "8px",
    paddingBottom: "8px",
  },
  sortingTableActionCellContainer: {
    paddingTop: "4px",
    border: "hidden",
    paddingBottom: "4px",
  },
  sortingTableLeftCell: {
    fontSize: "16px",
    paddingBottom: "10px",
    fontWeight: 600,
    width: "100%",
    paddingTop: "10px",
    marginLeft: "12px",
    borderBottom: "1px solid #ECECEC",
    borderLeft: "1px solid #ECECEC",
    borderBottomLeftRadius: "12px",
    borderTopLeftRadius: "12px",
    borderTop: "1px solid #ECECEC",
    overflow: "hidden",
    paddingLeft: "12px",
    marginBottom: "0px",
    marginTop: "0px",
    textOverflow: "ellipsis",
    paddingRight: "12px",
    whiteSpace: "nowrap",
    minHeight: "45px",
    height:"100%"
  },
  sortingTableRightCell: {
    fontSize: "16px",
    width: "100%",
    paddingTop: "10px",
    paddingBottom: "10px",
    borderTop: "1px solid #ECECEC",
    fontWeight: 600,
    marginTop: "0px",
    borderRight: "1px solid #ECECEC",
    borderBottomRightRadius: "12px",
    borderBottom: "1px solid #ECECEC",
    marginRight: "12px",
    marginBottom: "0px",
    borderTopRightRadius: "12px",
    whiteSpace: "nowrap",
    minHeight: "45px",
    height:"100%"

  },
  sortingTableMiddleCell: {
    paddingBottom: "10px",
    fontWeight: 600,
    width: "100%",
    paddingTop: "10px",
    borderTop: "1px solid #ECECEC",
    fontSize: "16px",
    marginBottom: "0px",
    borderBottom: "1px solid #ECECEC",
    textOverflow: "ellipsis",
    overflow: "hidden",
    marginTop: "0px",
    maxWidth: "18vw",
    paddingRight: "12px",
    whiteSpace: "nowrap",
    minHeight: "45px",
    height:"100%"

  },
  cellIcon: {
    width: "32px",
    height: "32px",
  },
};
export default SortingTableCellWrap;

// Customizable Area End
