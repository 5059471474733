import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import React from "react";

export const configJSON = require("./config");
// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation?: any;
  id?: string;
  customer_id: string;
  totalAmount: string;
  show: boolean;
  onSuccess: () => void;
  onError: (message: string) => void;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  otpValue: string;
  otpValues: any;
  otpAttemptRights: number;
  counter: number;
  isButtonDisabled: boolean;
  errorMsg: string;
  showErrMessage: boolean;
  showExceedErrMessage: boolean;
  exceedErrMessage: string;
  dynamicImgLogo: string;
  snackbarMessage: string;
  snackbarOpen: boolean;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class OtpController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  verifyOtpCallId: string = "";
  reSendOtpCallId: string = "";
  timer: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.ReciveUserCredentials)
      // Customizable Area End
    ];

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.state = {
      otpValue: "",
      otpValues: ["", "", "", ""],
      isButtonDisabled: true,
      counter: 50,
      errorMsg: "",
      showErrMessage: false,
      exceedErrMessage: "",
      showExceedErrMessage: false,
      dynamicImgLogo: "",
      snackbarOpen: false,
      snackbarMessage: "",
      otpAttemptRights: 2,
    };
    this.timer = React.createRef();
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    const apiResponse = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    const apiCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    if (this.reSendOtpCallId === apiCallId) {
      if (apiResponse && apiResponse.data) {
        this.setState({ otpValue: apiResponse.data.attributes.pin });
      } else {
        this.setState({ showExceedErrMessage: true });
        this.setState({
          snackbarOpen: true,
          snackbarMessage: apiResponse.errors[0].message,
        });
      }
    } else if (this.verifyOtpCallId === apiCallId) {
      console.log("apiResponse", apiResponse);
      if (apiResponse.errors) {
        this.props.onError(apiResponse.errors);
      } else this.props.onSuccess();
    }
    // Customizable Area End
  }

  componentDidMount(): any {
    // Customizable Area Start
    this.startTimer();
    // Customizable Area End
  }

  // Customizable Area Start

  componentWillUnmount(): any {
    this.clearTimer();
  }

  componentDidUpdate(prevProps: Readonly<Props>): void {
    if (this.props.show !== prevProps.show) {
      this.startTimer();
      this.setState({
        otpValues: ["", "", "", ""],
        isButtonDisabled: true,
        counter: 50,
        errorMsg: "",
        showErrMessage: false,
        exceedErrMessage: "",
        showExceedErrMessage: false,
        otpAttemptRights: 2,
      });
    }
  }

  startTimer() {
    this.clearTimer();
    if (!this.timer) {
      this.timer = setInterval(() => {
        this.setState(
          (prevState) => ({
            counter: prevState.counter - 1,
          }),
          () => {
            if (this.state.counter === 0) {
              this.clearTimer();
              this.setState({ isButtonDisabled: false });
            }
          }
        );
      }, 1000);
    }
  }

  clearTimer() {
    if (this.timer) {
      clearInterval(this.timer);
      this.timer = null;
    }
  }

  handleKeyDown = (e: any, index: number) => {
    if (
      e.key === "Backspace" &&
      this.state.otpValues[index] === "" &&
      index > 0
    ) {
      const previousInput = document.getElementById(`otp-${index - 1}`);
      if (previousInput) previousInput.focus();
    }
  };

  handleKeyUp = (e: any, index: any) => {
    if (e.target.value !== "" && index < 5) {
      const nextInput = document.getElementById(`otp-${index + 1}`);
      if (nextInput) nextInput.focus();
    }
  };

  handlePaste = (e: any) => {
    e.preventDefault();
    const pastedData = e.clipboardData.getData("text/plain");
    if (pastedData && pastedData.length === 4 && !isNaN(pastedData)) {
      const updatedOtpValues = pastedData.split("").slice(0, 4);
      this.setState({ otpValues: updatedOtpValues });
    }
  };

  handleChange = (e: any, index: number) => {
    const { otpValues } = this.state;
    const updatedOtpValues = [...otpValues];
    updatedOtpValues[index] = e.target.value;
    this.setState({ otpValues: updatedOtpValues });
  };

  handleLogInputValue = () => {
    this.verifyOtp();
  };
  handleTimeOut = () => {
    if (!this.state.otpAttemptRights) return;
    this.setState({
      isButtonDisabled: true,
      counter: 50,
      otpAttemptRights: this.state.otpAttemptRights - 1,
    });
    this.startTimer();
    this.reSendOtp();
  };

  reSendOtp = () => {
    let headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token"),
    };

    const reSendOtpDataMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.reSendOtpCallId = reSendOtpDataMessage.messageId;

    const apiUrl = "bx_block_wallet/wallets/send_otp";

    reSendOtpDataMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiUrl +
        "?customer_id=" +
        this.props.customer_id +
        "&total_amount=" +
        this.props.totalAmount
    );

    reSendOtpDataMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    reSendOtpDataMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(reSendOtpDataMessage.id, reSendOtpDataMessage);
  };
  verifyOtp = () => {
    let headers = {
      "Content-Type": "application/json",
      token: localStorage.getItem("token"),
    };
    let httpBody = {};

    const reSendOtpDataMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.verifyOtpCallId = reSendOtpDataMessage.messageId;

    reSendOtpDataMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.verifyOtpApi +
        "?customer_id=" +
        this.props.customer_id +
        "&pin=" +
        this.state.otpValues.join("")
    );

    reSendOtpDataMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    reSendOtpDataMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    reSendOtpDataMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(reSendOtpDataMessage.id, reSendOtpDataMessage);
  };

  handleCloseOtpError = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === configJSON.snackbarOtp) {
      return;
    }

    this.setState({ snackbarOpen: false });
  };

  // Customizable Area End
}
