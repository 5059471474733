import React from 'react';
import {
    Typography,
    Slider,
    Button
} from "@material-ui/core";
import { FilterTypography, CustomSortingPopover, InputField, CustomSlider, FilterCustomAutocomplete } from './CustomTags.web'
const CheckTrue = require('../check_true.svg');
const CheckFalse = require('../check_false.svg');

export interface Props {
    productSortingId: any;
    sortingOpen: any;
    sortingClose: any;
    anchor: any;
    selectedFilter: any;
    handleSelectedFilter: any;
    priceRangeValue: any;
    handleApplyFilter: any;
    subFilterMenuList: any;
    handleClearFitler: any;
    nameFilter: any;
    setName:any;
    priceFilter: any;
    setPrice: any;
    sectionFilter: any;
    setSection: any;
    productTypeFilter: any;
    setProductType: any;
    preferenceFilter: any;
    setPreference: any;
    optionsList: any;
}

export default class ProductListingFilters extends React.Component<Props> {
    constructor(props: Props) {
        super(props);
    }

    render() {
        const { productSortingId,
            sortingOpen,
            sortingClose,
            anchor,
            selectedFilter,
            handleSelectedFilter,
            priceRangeValue,
            handleApplyFilter,
            subFilterMenuList,
            handleClearFitler,
            nameFilter,
            setName,
            priceFilter,
            setPrice,
            productTypeFilter,
            setProductType,
            preferenceFilter,
            setPreference,
            sectionFilter,
            setSection,
            optionsList
        }: any = this.props;
        return (
            <CustomSortingPopover
                data-test-id="customFilterPopver"
                id={productSortingId}
                open={sortingOpen}
                anchorEl={anchor}
                onClose={() => { sortingClose(false) }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <div style={productListingFiltersStyle.sortingPopoverContainer}>
                    <div style={{display: 'flex', justifyContent: 'space-between', padding: '24px 24px 16px', alignItems: 'center'}}>
                    <Typography variant="h6" style={{ color: '#204B9C' }}>Filters</Typography>
                    <p data-test-id="clearLink" style={{ textDecoration: 'underline', color: "#DC2626", cursor: 'pointer', fontSize: "16px" }} onClick={() => {handleClearFitler()}}>Clear</p>
                    </div>
                    <hr style={{ margin: "0 20px" }} />
                    <div style={{ display: 'flex', width: '100%' }}>
                        <div style={{ width: '100%' }}>
                            <FilterTypography data-test-id="nameFilter" style={selectedFilter === 0 ? productListingFiltersStyle.selectedFilterStyle : {}} onClick={() => { handleSelectedFilter(0, "name") }}>
                                Name
                            </FilterTypography>
                            <FilterTypography data-test-id="priceFilter" style={selectedFilter === 1 ? productListingFiltersStyle.selectedFilterStyle : {}} onClick={() => { handleSelectedFilter(1, "price_order") }}>
                                Price
                            </FilterTypography>
                            <FilterTypography data-test-id="sectionFilter" style={selectedFilter === 2 ? productListingFiltersStyle.selectedFilterStyle : {}} onClick={() => { handleSelectedFilter(2, "section_ids") }}>
                                Section
                            </FilterTypography>
                            <FilterTypography data-test-id="productTypeFilter" style={selectedFilter === 3 ? productListingFiltersStyle.selectedFilterStyle : {}} onClick={() => { handleSelectedFilter(3, "product_type") }}>
                                Product Type
                            </FilterTypography>
                            <FilterTypography data-test-id="preferenceFilter" style={selectedFilter === 4 ? productListingFiltersStyle.selectedFilterStyle : {}} onClick={() => { handleSelectedFilter(4, 'preference') }}>
                                Preference
                            </FilterTypography>
                        </div>
                        <div style={{ width: '100%', maxHeight: '275px', overflowY: 'auto', padding: '0 6px' }}>
                            {selectedFilter === 0 && nameSubFilter(nameFilter,setName, optionsList)}
                            {selectedFilter === 1 && priceSubFilter(priceRangeValue, priceFilter, setPrice)}
                            {selectedFilter === 2 && sectionSubFilter(subFilterMenuList.section, sectionFilter, setSection)}
                            {selectedFilter === 3 && productTypeSubFilter(productTypeFilter, setProductType)}
                            {selectedFilter === 4 && preferenceSubFilter(subFilterMenuList.preference, preferenceFilter, setPreference )}
                        </div>
                    </div>
                    <div style={{ marginTop: '20px', marginBottom: '10px', textAlign: 'center' }}>
                        <Button
                          data-test-id={"CancelButton"}
                            onClick={() => { sortingClose(true) }}
                            style={{ ...productListingFiltersStyle.addButton, textTransform: "unset", backgroundColor: '#FFFFFF', color: '#DC2626', width: '128px', border: '1px solid #DC2626' }}
                            >
                            Clear All
                        </Button>
                        <Button
                            data-test-id="SaveButton"
                            type="submit"
                            onClick={() => { handleApplyFilter() }}
                            style={{ ...productListingFiltersStyle.addButton, textTransform: "unset", width: '128px' }}
                        >
                            Apply
                        </Button>
                    </div>
                </div>
            </CustomSortingPopover>
        )
    }
}

const nameSubFilter = (nameFilter:any, setName:any, optionsList: any) => {
    return (
        <div style={{padding: '0 20px 0 10px'}}>
            <Typography style={{fontSize: '14px', fontWeight: 600, margin: '5px 0 0', fontFamily: 'Montserrat'}}>Product Name</Typography>
            <FilterCustomAutocomplete
                data-test-id="autoCompleteDropdown"
                id="product_name"
                name="product_name"
                options={optionsList?.map((option: any) => option.label) || []}
                inputValue={nameFilter || ""}
                value={nameFilter || ""}
                onChange={(_: any, value: any) => { setName(value); }}
                onInputChange={(_: any, value: any) => { setName(value); }}
                clearOnBlur={false}
                clearOnEscape={false}
                renderInput={(params: any) => (
                    <InputField
                        {...params}
                        variant="outlined"
                        placeholder="Type and select"
                        value={nameFilter}
                    />
                )}
                ListboxProps={
                    {
                        style: {
                            maxHeight: '150px',
                        }
                    }
                }
            />
        </div>
    )
}

const priceSubFilter = (priceRangeValue: any, priceFilter:any, setPrice: any) => {
    return (
        <>
            <FilterTypography style={priceFilter.order === 'asc' ? productListingFiltersStyle.selectedSubFilterStyle : {}} onClick={() => { setPrice('order', "asc") }}>
                Lower to Higher
            </FilterTypography>
            <FilterTypography style={priceFilter.order === 'desc' ? productListingFiltersStyle.selectedSubFilterStyle : {}} onClick={() => { setPrice('order', "desc") }}>
                Higher to Lower
            </FilterTypography>
            <div style={{ margin: '10px 5px 0', paddingRight: '15px' }}>
                <Typography id="range-slider" style={{...productListingFiltersStyle.priceSelect, marginBottom: '0'}} gutterBottom>
                    or Select Range
                </Typography>
                <Typography style={{...productListingFiltersStyle.priceSelect, marginTop: '0'}}>{priceRangeValue[0]}-{priceRangeValue[1]}</Typography>
                <CustomSlider
                    value={priceFilter.range}
                    onChange={(event: any, value: any) => { setPrice('range', value) }}
                    valueLabelDisplay="auto"
                    track="inverted"
                    aria-labelledby="range-slider"
                />
            </div>
        </>
    )
}

const sectionSubFilter = (optionList:any, sectionFilter: any, setSection:any) => {
    return (optionList?.map((option:any) => {
            return <Typography key={option.id} data-test-id={`section-${option.option}`} style={{...productListingFiltersStyle.sectionStyle, paddingLeft: '5px'}} onClick={() => { setSection(option) }}>
                {sectionFilter?.some((obj:any) => obj === option.id) ? <img src={CheckTrue} style={{ marginRight: '16px', marginLeft: '16px' }} /> : <img src={CheckFalse} style={{ marginRight: '16px', marginLeft: '16px' }} />}<span style={{display: 'flex', alignItems: 'center'}}>{option.option}</span>
            </Typography>
        }))
}

const productTypeSubFilter = (productTypeFilter: any, setProductType: any) => {
    return (
        <>
            <FilterTypography style={productTypeFilter === "Normal" ? productListingFiltersStyle.selectedSubFilterStyle : {}} onClick={() => { setProductType("Normal") }}>
                Normal
            </FilterTypography>
            <FilterTypography style={productTypeFilter === "Parent" ? productListingFiltersStyle.selectedSubFilterStyle : {}} onClick={() => { setProductType("Parent") }}>
                Parent
            </FilterTypography>
            <FilterTypography style={productTypeFilter === "Sub-Product" ? productListingFiltersStyle.selectedSubFilterStyle : {}} onClick={() => { setProductType("Sub-Product") }}>
                Sub-product
            </FilterTypography>
        </>
    )
}

const preferenceSubFilter = (optionList:any, preferenceFilter: any, setPreference: any) => {
    return (
        <>
        {optionList?.map((option:any, index:any) => {
            return <FilterTypography style={preferenceFilter.id === option.id ? productListingFiltersStyle.selectedSubFilterStyle : {}} onClick={() => { setPreference(option) }}>
                {option.option}
            </FilterTypography>
        })}
        </>
    )
}

const productListingFiltersStyle = {
    addButton: {
        background: "#204B9C",
        fontWeight: 600,
        fontSize: "16px",
        lineHeight: "24px",
        color: "#FFFFFF",
        height: "44px",
        width: "184px",
        borderRadius: "8px",
        marginLeft: 10
    },
    sortingPopoverContainer: {
        paddingTop: "16px",
        paddingBottom: "16px",
        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.03), 0px 8px 32px rgba(0, 0, 0, 0.06)",
        borderRadius: "8px",
    },
    selectedFilterStyle: {
        backgroundColor: '#204B9C',
        color: '#ffffff'
    },
    selectedSubFilterStyle: {
        backgroundColor: '#204B9C',
        color: '#ffffff'
    },
    sectionStyle: {
        minHeight: '33px',
        borderRadius: 0,
        margin: '5px 0',
        paddingLeft: '20px',
        cursor: 'pointer',
        fontSize: '14px',
        fontWeight: 500,
        display: 'flex'
    },
    priceSelect: {
        background: '#fff',
        color: '#000000',
        height: '33px',
        borderRadius: '0',
        display: 'flex',
        alignItems: 'center',
        paddingLeft: '16px',
        margin: '5px 0',
        boxShadow: "none",
        fontSize: '14px',
        fontWeight: 500,
        cursor: 'pointer',
        fontFamily: 'Montserrat',
    }
}