// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { Message } from "../../../framework/src/Message";
export const configJSON = require("./config");
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../framework/src/IBlock";
import { navigateTo } from "../../utilities/src/CustomBlockHelpers";
import { IFilterItem } from "../../promocodes/src/PromoCodeListController.web";
import { IFilter } from "../../../components/src/FilterPopover";
import { makeApiMessage } from "../../../components/src/common";
import { IUserContext } from "../../../blocks/navigationmenu/src/PageContainerController.web";
import {
  PermissionStatus,
  checkForNewPermissonStatus,
  customPermissionApiKey,
  CustomEnums,
  getCustomEnumName
} from "../../../blocks/utilities/src/CustomBlockHelpers";
import { PermissionGroupArray } from "../../../blocks/navigationmenu/src/utils";
import { ISortingData } from "../../../components/src/OrderTable/src/TableCell";
import { sortCondition } from "../../../blocks/utilities/src/CustomBlockHelpers";
import { handleMomentDateFormat } from "./utils";
import moment from "moment";
// Customizable Area End

// Customizable Area Start
export interface IInTransactionList {
  id: string;
  type: string;
  attributes: {
    id: number;
    transfer_id: string;
    account_id: number;
    driver_account_id: number;
    transfer_from_id: number;
    transfer_from_type: string;
    transfer_to_id: number;
    transfer_to_type: string;
    status: string;
    driver_confirmed_at: string | null;
    driver_status: string;
    station: string | null;
    region_id: number;
    opening_cash_date: string | null;
    reason: string | null;
    created_at: string;
    driver: {
      id: number;
      first_name: string;
      last_name: string;
      full_name: string | null;
    };
    created_by: {
      id: number;
      first_name: string;
      last_name: string;
      full_name: string;
    };
    transfer_from: {
      id: number;
      store_name: string;
      store_id: string;
      store_address: string;
    };
    transfer_to: {
      id: number;
      name: string;
    };
    is_cashier?: boolean;
    received_by: string | null;
    amount: string;
    currency: string;
    confirm_amount: string;
    variance: string;
    opening_cash: string;
    petty_cash: string;
    cash_from_orders: string;
    cash_in: string;
    cash_out: string;
    declare_cash: string;
    variance_amount: string
    invoice_pdf: string | null
  };
}
// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation: {};
  id: string;
  // Customizable Area End
}

export interface S {
  // Customizable Area Start
  filters: IFilterItem[];
  transactionMetaData: {
    [field: string]: string;
  };
  selectedTab: string;
  totalCount: number;
  filterBtnAnchor: { currentTarget: {} } | undefined | HTMLDivElement;
  transactionList: Array<IInTransactionList>;
  page: number;
  isOpenConfirmAmt: boolean;
  editConfirmAmt: string;
  editConfirmAmtId: string;
  confirmAmtVal: number | string;
  searchText: string;
  permissionStatus: PermissionStatus;
  sortingData: ISortingData;
  query: string;
  regionIdQuery: string;
  storeIdQuery: string;
  loading: boolean;
  // Customizable Area End
}

export interface SS {}

export default class IncomingTransactionController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getIncTransactionListMessageId: string = "";
  editCofrimAmtMessageId: string = "";
  exportAllApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIResponceDataMessage),
    ];
    this.state = {
      loading: false,
      regionIdQuery:"",
      storeIdQuery: "",
      sortingData: {
        transfer_id: "",
        from_store: "",
        from_store_id : "",
        station: "",
        date: "",
        account: "",
        to: "",
        driver: "",
        transfered: "",
        confirm_amount: "",
        variance: ""
      },
      query: "",
      filters: [
        {
          title: "Date",
          type: "dateselect",
          datevalue: { from: "", to: "" },
          value: "",
          options: [
            {
              label: "Today",
              value: "today",
            },
            {
              label: "Last 7 days",
              value: "last7",
            },
            {
              label: "Last 30 days",
              value: "last30",
            },
            {
              label: "Specific Dates",
              value: "specific",
            },
          ],
        },
      ],
      transactionList: [],
      selectedTab: configJSON.Tabs.Strings.incomingTransaction,
      transactionMetaData: {},
      filterBtnAnchor: undefined,
      totalCount: 0,
      page: 1,
      isOpenConfirmAmt: false,
      editConfirmAmt: "",
      editConfirmAmtId: "",
      confirmAmtVal: 0,
      searchText: "",
      permissionStatus: {
        mainPermission: false,
        createPermission: false,
        viewPermission: false,
        editPermission: false,
        deactivatePermission: false
      }
    };

    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  async receive(_from: string, message: Message) {
    // Customizable Area Start
    const requestId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    this.handleIncTransactionListRes(requestId, responseJson);
    this.handleEditConfrimAmtRes(requestId, responseJson);
    this.handleResForExportAll(_from,message)
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    this.getIncomingTransactionList();
    // Customizable Area End
  }

  // Customizable Area Start

  handleIncTransactionListRes(
    requestId: string,
    response: { data: []; meta: { [field: string]: string } }
  ) {
    if (requestId === this.getIncTransactionListMessageId) {
      this.setState({
        transactionList: response?.data,
        transactionMetaData: response?.meta,
        totalCount: Number(response?.meta?.total_count),
      });
    }
  }

  handleTabSeletion = (event: React.ChangeEvent<{}>, newValue: string) => {
    this.setState({ selectedTab: newValue });
    const tabString = configJSON.Tabs.Strings;
    if (newValue === tabString.overview) {
      navigateTo({
        props: this.props,
        screenName: "CfCashier",
      });
    } else {
      navigateTo({
        props: this.props,
        screenName: "OutGoingTransaction",
      });
    }
  };

  getIncomingTransactionList(pageNumber = 1) {
    const dateFilterQuery = this.handleDateFilterParam().dateFilterQuery
    
    const apiUrl =
    configJSON.transferRequestsListApi +
    `?request_type=incoming` +
    `&page_no=${pageNumber}${this.state.query}${sortCondition(this.state.searchText as unknown as boolean, "&filter_by[query]=" + this.state.searchText, "")}${dateFilterQuery}${this.state.regionIdQuery}${this.state.storeIdQuery}&request_page=cashier`;

    const requestMessage = makeApiMessage({
      method: "GET",
      url: apiUrl,
    });

    this.getIncTransactionListMessageId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleDateFilterParam = () => {
    const dateFilter = this.state. filters.find((item) => item?.title === 'Date')?.value;
    let startDate, endDate;
    const today = moment().format('YYYY-MM-DD');
    let comomStartDate = moment().subtract(7, 'days').format('YYYY-MM-DD');
    let commonEndDate = today;
    switch (dateFilter) {
      case 'today':
        startDate = today;
        endDate = today;
        break;
      case 'last7':
        startDate = comomStartDate
        endDate = commonEndDate;
        break;
      case 'last30':
        startDate = moment().subtract(30, 'days').format('YYYY-MM-DD');
        endDate = today;
        break;
      case 'specific':
        const dateFilter = this.state.filters.find((item) => item?.title === 'Date')?.datevalue;
        if (dateFilter?.from && dateFilter?.to) {
          startDate = moment(dateFilter.from).format('YYYY-MM-DD');
          endDate = moment(dateFilter.to).format('YYYY-MM-DD');
        }
        break;
      default:
        startDate =  moment().subtract(7, 'days').format('YYYY-MM-DD');
        endDate = today;
    }

    const dateFilterQuery = startDate && endDate 
      ? `&filter_by[created_at]=true&filter_by[start_date]=${startDate}&filter_by[end_date]=${endDate}` 
      : '';

    const startDateText = startDate ? handleMomentDateFormat(startDate,'DD-MM-YYYY') : moment().subtract(7, 'days').format('DD-MM-YYYY')
    const endDateText = endDate ? handleMomentDateFormat(endDate,'DD-MM-YYYY') : moment().format('DD-MM-YYYY')

    return {dateFilterQuery, startDateText, endDateText}
  };

  handleCloseFilterButton = () => {
    this.setState({
      filterBtnAnchor: undefined,
    });
  };

  handleFilterButton = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    this.setState({
      filterBtnAnchor: event.currentTarget,
    });
  };

  handleFilterChange = (filters: IFilter[]) => {
    this.setState({
      filters,
      page: 1
    },this.getIncomingTransactionList);
  };

  getTableCellType(index: number, arrayLength: number) {
    if (index === 0) {
      return "left";
    } else if (index === arrayLength - 1) {
      return "right";
    } else {
      return "middle";
    }
  }

  handlePageChange(pageVal: number) {
    this.setState({ page: pageVal + 1 }, () =>
      this.getIncomingTransactionList(pageVal + 1)
    );
  }

  handleConfirmAmtInput = (item: IInTransactionList) => {
    this.setState({
      isOpenConfirmAmt: true,
      editConfirmAmt: item.attributes.confirm_amount,
      editConfirmAmtId: item.id,
    });
  };

  handleAmountChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (!isNaN(Number(value))) {
      this.setState({
        editConfirmAmt: value,
      });
    }
  };

  handleUpdateConfirmAmt = () => {
    this.setState({loading:true})
    const body = {
      id: this.state.editConfirmAmtId,
      confirm_amount: this.state.editConfirmAmt,
      status: "completed",
    };
    const message = makeApiMessage({
      url: `${configJSON.editConfirmAmountApi}`,
      method: "PATCH",
      body: JSON.stringify(body),
    });

    this.editCofrimAmtMessageId = message.messageId;
    runEngine.sendMessage(message.id, message);
  };

  handleEditConfrimAmtRes(
    requestId: string,
    response: { status: number; message: string }
  ) {
    if (requestId === this.editCofrimAmtMessageId) {
      this.setState(
        {
          isOpenConfirmAmt: false,
          loading: false,
          editConfirmAmt: "",
          editConfirmAmtId: "",
        },
        () => this.getIncomingTransactionList()
      );
    }
  }

  handleSearch = (value: string) => {
    this.setState({ page: 1, searchText: value },() => this.getIncomingTransactionList());
  };

  getSortingProps() {
    return {
      sortingData: this.state.sortingData,
      onChange: (data: ISortingData) => this.setState({ sortingData: data }),
      onQueryChange: (query: string) => this.handleQueryChange(`&${query}`),
    };
  }

  handleQueryChange = (query: string) => {
    this.setState({ query }, () => this.getIncomingTransactionList());
  };

  handleRedirectDetail = (item:IInTransactionList,index:number)=>{
    const {isOpenConfirmAmt} = this.state
    if(index === 10 || (isOpenConfirmAmt && index === 9)){
        return
    }
    navigateTo({
      id: `cashier/${item.id}`,
      props: this.props,
      screenName: "OutGoingTransactionDetails",
    });
  }

  handleUserChange = (userContext: IUserContext) => {
    const apiKey = customPermissionApiKey.cashierPermission;
    const userData = userContext.user?.attributes.permission_groups;
    const value = checkForNewPermissonStatus(apiKey, userData as Array<PermissionGroupArray>);
    this.setState({
      permissionStatus: value
    })
  };

  handleRegionChange = (value: string) => {
    this.setState({regionIdQuery:`&filter_by[region_ids]=${value}`},()=>()=>this.getIncomingTransactionList())
  }

  handleStoreChange = (value: string) => {
    this.setState({storeIdQuery:`&filter_by[store_ids]=${value}`},()=>this.getIncomingTransactionList())
  }

  exportAll = async () => {
    const dateFilterQuery = this.handleDateFilterParam().dateFilterQuery
    const requestMessage = makeApiMessage({
      method: configJSON.validationApiMethodType,
      url: `${configJSON.exportAllEndpoint}?request_type=incoming${dateFilterQuery}${this.state.storeIdQuery}&request_page=cashier`,
    });

    this.exportAllApiCallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleResForExportAll = async (from: string, message: Message) => {
    if (this.exportAllApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson.data) {
        location.href = responseJson.data.url;
      }
    }
  }

  handlePrintInvoice = (fileUrl?: string | null) => {
    if (!fileUrl) return;
    const printMessage = new Message(getCustomEnumName(CustomEnums.CustomActionReducers))
    printMessage.addData(getCustomEnumName(CustomEnums.CustomReducerAction), "PRINT_FILE")
    printMessage.addData(getCustomEnumName(CustomEnums.CustomReducerPayload), {
      format: "pdf",
      data: fileUrl
    })
    this.send(printMessage)
  }

  handleDirectConfirmAmt = (event: React.MouseEvent<HTMLButtonElement>, transferID: string | number, amount: string) => {
    event.stopPropagation()
    this.setState({ editConfirmAmtId: String(transferID), editConfirmAmt: amount }, () => this.handleUpdateConfirmAmt())
  }

  getTableRowColumns() {
    const currentScreenWidth = window.innerWidth;
    const tableColumnConfigs = [
      { maxWidth: 1024, columnLayout: `113px 80px 70px 84px 80px 117px 60px 75px 95px ${sortCondition(this.state.isOpenConfirmAmt,"140px","90px")} 93px` },
      { maxWidth: 1280, columnLayout: `130px 100px 95px 104px 95px 134px 70px 90px 110px ${sortCondition(this.state.isOpenConfirmAmt,"160px","108px")} 105px` },
      { maxWidth: Infinity, columnLayout: `1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr ${sortCondition(this.state.isOpenConfirmAmt,"218px","1fr")} 1fr` }
    ];
    const getColumnLayout = (screenConfigs: { maxWidth: number; columnLayout: string }[]) =>
      screenConfigs.find(config => currentScreenWidth <= config.maxWidth)?.columnLayout;
    const mainTableColumns = getColumnLayout(tableColumnConfigs);
    return `${mainTableColumns}`.trim();
  }

  // Customizable Area End
}
