import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import { IBlock } from "../../../framework/src/IBlock";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { FormikValues } from "formik";
import { makeApiMessage } from "../../../components/src/common";
import { IUserContext } from "../../../components/src/PageContainer/PageContainerController";
import {
  navigateTo,
  INavigateTo,
  handle422Errors,
} from "../../utilities/src/CustomBlockHelpers";

type IField = number | string | null;
type IField2 = string | null;

export interface IFormData {
  data: {
    attributes: {
      id?: IField;
      add_by?: IField;
      region_id?: IField;
      region_name?: IField2;
      open_slots_per_day?: IField;
      close_slot?: boolean | string;
      minimum_order_amount?: IField;
      maximum_order_amount?: IField;
      non_refundable_type?: IField2;
      home_cleaning_service_charges?: IField;
      min_amount_to_waive_of_service_charge?: IField;
    };
  };
}

export interface IRegion {
  id?: number;
  name: string;
}

// Customizable Area End

export interface S {
  // Customizable Area Start
  editId: string;
  pageNo: number;
  pageEmpty: boolean;
  alertMessage: string;
  isRegionManger: boolean;
  initialValueOfForm: IFormData;
  regionList: Array<IRegion | never>;
  initialValueOfFormErrors: IFormData;
  // Customizable Area End
}
export interface Props {
  id: string;
  // Customizable Area Start
  navigation: {
    navigate: Function;
    getParam: Function;
  };
  // Customizable Area End
}
interface SS {
  id: string;
}

export const configJSON = require("./config");

export default class HomeCleaningSettingsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  regionMessageId: string = "";
  settingSaveMessageId: string = "";
  settingShowMessageId: string = "";
  // Customizable Area End

  state = {
    // Customizable Area Start
    editId: "",
    pageNo: 1,
    pageEmpty: false,
    regionList: [],
    alertMessage: "",
    isRegionManger: false,
    initialValueOfForm: {
      data: {
        attributes: {
          add_by: "",
          region_id: "",
          close_slot: false,
          open_slots_per_day: "",
          non_refundable_type: "",
          minimum_order_amount: "",
          maximum_order_amount: "",
          home_cleaning_service_charges: "",
          min_amount_to_waive_of_service_charge: "",
        },
      },
    },
    initialValueOfFormErrors: {
      data: {
        attributes: {
          add_by: "",
          region_id: "",
          close_slot: "",
          open_slots_per_day: "",
          non_refundable_type: "",
          minimum_order_amount: "",
          maximum_order_amount: "",
          home_cleaning_service_charges: "",
          min_amount_to_waive_of_service_charge: "",
        },
      },
    },
    // Customizable Area End
  };

  constructor(props: Props) {
    super(props);
    this.receive.bind(this);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.handleUserChange = this.handleUserChange.bind(this);
    // Customizable Area End
  }

  receive(from: string, message: Message): void {
    const requestId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const successResponse = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    // Customizable Area Start
    this.handleShowResponse(requestId, successResponse);
    this.handelSubmitResponse(requestId, successResponse);
    this.handleRegionListResponse(requestId, successResponse);

    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    const id = this.props.navigation.getParam("navigationBarTitleText");
    this.setState({ editId: id as string });
    this.getCleaningShowData(id);
    // Customizable Area End
  }

  // Customizable Area Start

  getCleaningShowData(id?: string) {
    if (!id) return;

    const message = makeApiMessage({
      method: "GET",
      url: configJSON.homeCleaningSettingsFormApi + "/" + id,
    });

    this.settingShowMessageId = message.messageId;
    runEngine.sendMessage(message.id, message);
  }

  handleShowResponse(requestId: string, response: IFormData) {
    if (requestId === this.settingShowMessageId) {
      if (response?.data) {
        this.setState({ initialValueOfForm: response });
      }
    }
  }

  handelSubmitResponse(requestId: string, response: { status: number }) {
    if (requestId === this.settingSaveMessageId) {
      if (
        response &&
        handle422Errors(response as unknown, this.handle422InForm.bind(this))
      ) {
        const { editId } = this.state;
        const msgType = editId ? "updated" : "saved";
        const message = String(
          configJSON.Strings.Settings.successMessage
        ).replace("$$", msgType);

        this.setState({ alertMessage: message });

        setTimeout(() => {
          navigateTo({
            props: this.props,
            screenName: "HomeCleaningSettingsList",
          } as INavigateTo);
        }, 2000);
      }
    }
  }

  handleSubmitForm(body: FormikValues) {
    const { editId } = this.state;
    const isEdit = Boolean(editId);
    const editPrams = isEdit ? "/" + editId : "";

    const message = makeApiMessage({
      body: JSON.stringify(body),
      method: isEdit ? "PUT" : "POST",
      url: configJSON.homeCleaningSettingsFormApi + editPrams,
    });

    this.settingSaveMessageId = message.messageId;
    runEngine.sendMessage(message.id, message);
  }

  onCloseNotification() {
    this.setState({ alertMessage: "" });
  }

  handleUserChange({ user }: IUserContext) {
    const isEdit = Boolean(
      this.props.navigation.getParam("navigationBarTitleText")
    );
    const isRegionManager = user?.attributes?.role?.name === "Region Manager";
    const regionId =
      user?.attributes?.employee_proifle?.data?.attributes?.region_manager_id ||
      "";
    const regionName =
      user?.attributes?.employee_proifle?.data?.attributes
        ?.region_manager_name || "";

    isRegionManager
      ? this.setState({
          isRegionManger: true,
          regionList: [{ id: regionId as number, name: regionName }],
        })
      : this.getRegionList();

    isRegionManager &&
      !isEdit &&
      this.setState({
        initialValueOfForm: {
          data: {
            attributes: {
              add_by: "",
              close_slot: false,
              region_id: regionId,
              open_slots_per_day: "",
              non_refundable_type: "",
              minimum_order_amount: "",
              maximum_order_amount: "",
              home_cleaning_service_charges: "",
              min_amount_to_waive_of_service_charge: "",
            },
          },
        },
      });
  }

  handle422InForm(errors: { [key: string]: string }) {
    this.setState({
      initialValueOfFormErrors: {
        data: {
          attributes: {
            add_by: errors?.add_by,
            home_cleaning_service_charges:
              errors?.home_cleaning_service_charges,
            region_id: errors?.region_id,
            open_slots_per_day: errors?.open_slots_per_day,
            non_refundable_type: errors?.non_refundable_type,
            minimum_order_amount: errors?.minimum_order_amount,
            maximum_order_amount: errors?.maximum_order_amount,
            close_slot: errors?.close_slot as unknown as boolean,
            min_amount_to_waive_of_service_charge:
              errors?.min_amount_to_waive_of_service_charge,
          },
        },
      },
    });
  }

  handleRegionListResponse(
    requestId: string,
    responseJson: { data: [{ attributes: IRegion }] }
  ) {
    if (this.regionMessageId != requestId && !Array.isArray(responseJson?.data))
      return;

    const array = (
      responseJson?.data?.map((item) => item.attributes) || ([] as IRegion[])
    ).filter((item) => item.id && item.name);

    this.setState((prev) => ({
      pageEmpty: array.length === 0,
      regionList: [...prev.regionList, ...array],
    }));
  }

  getRegionList() {
    const { pageNo } = this.state;
    const message = makeApiMessage({
      url: configJSON.regionListApi + "&page_no=" + pageNo,
      method: "GET",
    });
    this.regionMessageId = message.messageId;
    runEngine.sendMessage(message.id, message);
  }

  handelStoreDropdownScroll = (event: React.SyntheticEvent) => {
    const { pageEmpty, isRegionManger } = this.state;
    if (isRegionManger) return;

    const listboxNode = event.currentTarget;
    const position = listboxNode.scrollTop + listboxNode.clientHeight;
    if (listboxNode.scrollHeight - position <= 1.3 && !pageEmpty) {
      this.setState(
        (prev) => ({ pageNo: prev.pageNo + 1 }),
        () => {
          this.getRegionList();
        }
      );
    }
  };
  // Customizable Area End
}
