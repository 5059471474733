import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import * as Yup from "yup";
import React  from "react";
import { debounce, uniqBy, toString, concat } from "lodash";
import { ClassNameMap } from "@material-ui/styles";


export const configData = require("./config");
import {AllImageIconType, SectionType, ServiceListType, RawSectionListType, SectionListType} from "./SevicesTypes"
import utils from './util'
import { navigateTo } from "../../../../packages/blocks/utilities/src/CustomBlockHelpers";

interface ImageProps extends React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement> {
    key?: string | number ;
}

export interface GetIconType {
    id: string,
    item: React.ReactNode,
    title: string
    attributes: {
        image: string;
        name: string;

    };


}

export interface IPayload {
    id: string,
    type: string,
    attributes: {
        active: boolean
        icon_id: number | string
        id: number
        name: string
        name_translation: string
        online_order: boolean
        order_number: number
        second_name: string
        icon: {data:{id: string; attributes: {id: number; name: string; image: string | null}}}
        sections: { data: SectionType[] }
        short_name: string
    }
}

export interface ISection {
    id: number,
    type: string
    attributes: {
        id: number,
        name: string,
        section_name: string
    }
}

export interface PreFilledType {
    id?: string,
    name?: string,
    title?: string,
    serviceName: string | undefined,
    serviceSecondaryName: string,
    shortName: string | undefined,
    selectedIcon: string,
    selectedIconName?: string,
    selectedSections: string[],
    onlineOrder: boolean | undefined,
    selectedSectionName?: string[]
}

type SectionOption = {
    id: string;
    option: string;
}
type IconOption = SectionOption & {image: string}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    classes: ClassNameMap;
    // Customizable Area End
}

interface S {
    token: string;
    // Customizable Area Start
    anchorEl: null | HTMLElement,
    selectedType: string,
    allImages: AllImageIconType[],
    loading: boolean,
    errorMessage: string,
    selectedFile: string | null | ArrayBuffer,
    selectedIcon: IconOption | null,
    selectedOptions: SectionOption[],
    serviceList: ServiceListType[],
    iconList: IconOption[],
    iconPage: number;
    iconSearchText: string;
    sectionLists: SectionOption[],
    selectedSectionsForList: SectionOption[]
    isSectionSelectAll: boolean;
    sectionPage: number;
    sectionAutoCompleteValue: string;
    preFilledEdit: PreFilledType | null,
    initialValues: PreFilledType,
    snackbar: {
        open: boolean,
        severity: "error" | "success" | "info" | "warning" | undefined,
        message: string
    },
    addBtnLoading: boolean,
    dropdownOptions: {
        section:  {id?: string, name?: string}[],
        icons: []
    };
    productSelectedData: {id?: string, name?: string, option?: string}[];
    isSelectAll: boolean;


    // Customizable Area End
}

interface SS {
    id: any;
}

export default class EditServicesListController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    itemsPerPage = 10;
    getIconsCallId: string = "";
    createIconsCallId: string = "";
    getSectionListCallId: string = "";
    updateServiceCallId: string = "";
    getEditPreFilledCallId: string = "";
    editId: string = ""
    disableLoadMoreSection = false;
    disableLoadMoreIcon = false;



    // Customizable Area End
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            getName(MessageEnum.SessionSaveMessage),
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.RestAPIResponceMessage),

          
            // Customizable Area Start
            getName(MessageEnum.RestAPIRequestMessage)
          
        ];

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
       


        this.state = {
               // Customizable Area Start
            selectedType: '',
            token: '',
            anchorEl: null,
            allImages: [],
            loading: false,
            errorMessage: "",
            selectedIcon: null,
            selectedFile: "",
            selectedOptions: [],
            selectedSectionsForList: [],
            isSectionSelectAll: false,
            sectionPage: 1,
            sectionAutoCompleteValue: '',
            serviceList: [],
            sectionLists: [],
            iconList: [],
            iconPage: 1,
            iconSearchText: "",
            preFilledEdit: null,
            initialValues: {
                serviceName: '',
                serviceSecondaryName: '',
                selectedIcon: "",
                selectedSections: [],
                shortName: "",
                onlineOrder: false
            },
            snackbar: {
                open: false,
                severity: 'info',
                message: ''
            },
            addBtnLoading:false,
            dropdownOptions: {
                section : [],
                icons: []            

            },
            productSelectedData: [],
            isSelectAll: false
        // Customizable Area End


        };


    }

  
 async receive(from: string, message: Message) {
        // Customizable Area Start
        runEngine.debugLog("Message Received", message);
        
        if (getName(MessageEnum.RestAPIResponceMessage) !== message.id) {
          return;
        }
      
        const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
        const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      
        switch (apiRequestCallId) {
          case this.getIconsCallId:
            this.handleGetIconsResponse(responseJson);
            break;
      
          case this.updateServiceCallId:
            this.handleUpdateServiceResponse(responseJson);
            break;
      
          case this.getEditPreFilledCallId:
            this.handleGetEditPreFilledResponse(responseJson);
            break;
      
          case this.getSectionListCallId:
            this.handleGetSectionListResponse(responseJson);
            break;
        }
      
        // Customizable Area End
      }




  // Customizable Area Start
      handleGetIconsResponse(responseJson: {data: GetIconType[]}) {
      
        if (responseJson) {
       
       const {parsedDropdownIconss}  = utils.parsedAllIcon(responseJson)
       let newIcons = this.state.iconPage === 1 ? parsedDropdownIconss : concat(this.state.iconList, parsedDropdownIconss)
       this.state.selectedIcon && newIcons.unshift(this.state.selectedIcon)
       this.setState({
           iconList: uniqBy(newIcons, "id"),
       })
       this.disableLoadMoreIcon = parsedDropdownIconss.length < this.itemsPerPage
        }
      }


      handleUpdateServiceResponse(responseJson: {data: IPayload, error: {}, errors: {}[]}) {
        if (responseJson && !responseJson.error && !responseJson.errors) {
          this.setState({
            snackbar: {
              message: configData.updateSectionSuccess,
              severity: 'success',
              open: true,
            },
            addBtnLoading: false
          });

          setTimeout(()=>{
            this.props.navigation.history.push('/Products-ServicesList')
          },1000)

        } else {    
        const keys = Object.keys(responseJson?.errors[0]);
          this.setState({
            addBtnLoading: false,
            snackbar: {
              message: keys?.[0] + ' has already been taken',
              severity: 'error',
              open: true,
            },
          });
        }
      }



      handleGetEditPreFilledResponse(responseJson: {data: IPayload}) {
      
        if (responseJson) {
          const preFilledInfo: PreFilledType = {} as PreFilledType;
          const sections = responseJson.data?.attributes.sections.data.map(section => ({
            id: section.id,
            option: section.attributes.section_name
          })) || []
          const currentIcon = responseJson.data?.attributes ?  {
            id: responseJson.data.attributes.icon.data?.id,
            option: responseJson.data.attributes.icon.data?.attributes.name,
            image: toString(responseJson.data.attributes.icon.data?.attributes.image)
          } : null
          preFilledInfo.id = responseJson?.data.id;
          preFilledInfo.serviceName = responseJson?.data?.attributes?.name;
          preFilledInfo.serviceSecondaryName = responseJson?.data?.attributes?.second_name;
          preFilledInfo.shortName = responseJson?.data?.attributes?.short_name;
          preFilledInfo.selectedSections = sections.map(section => section.id)
          preFilledInfo.selectedIcon = toString(responseJson?.data.attributes?.icon_id);
          preFilledInfo.onlineOrder = responseJson?.data?.attributes?.online_order;
          preFilledInfo.selectedSectionName = this.getAllSectionName(responseJson?.data?.attributes?.sections?.data);
          
        this.setState({
            preFilledEdit: preFilledInfo,
            productSelectedData: this.getAllSelectedSectionData(responseJson?.data?.attributes?.sections.data),
            initialValues: {
                serviceName: preFilledInfo.serviceName,
                serviceSecondaryName: preFilledInfo.serviceSecondaryName,
                selectedSections: preFilledInfo.selectedSections,
                selectedIcon: preFilledInfo.selectedIcon,
                onlineOrder: preFilledInfo.onlineOrder,
                shortName: preFilledInfo.shortName,
            },
            sectionLists: sections,
            selectedSectionsForList: sections,
            selectedOptions: sections,
            iconList: currentIcon ? [currentIcon] : [],
            selectedIcon: currentIcon,
        }, () => {
            this.getSectionLists()
            this.getAllIcons()
            this.setState({loading: false})
        });
        }
      }


    handleGetSectionListResponse(responseJson: { data: RawSectionListType[]}) {
        if (responseJson) {
            const {parsedSectionDropdown} =  utils.parseSections(responseJson);
                const currentSelectedSections = this.state.selectedSectionsForList.filter(item => item.id !== "-1")
                this.setState({
                    sectionLists: uniqBy([...(this.state.sectionPage === 1 ? currentSelectedSections 
                        : this.state.sectionLists), ...parsedSectionDropdown],"id"),
                    selectedSectionsForList: this.state.isSectionSelectAll ? 
                        uniqBy([
                            ...this.state.selectedSectionsForList,
                            ...parsedSectionDropdown
                        ], "id") : this.state.selectedSectionsForList,
                })
                this.disableLoadMoreSection = parsedSectionDropdown.length < this.itemsPerPage
        }
    }

    handleRedirect = () => {
        navigateTo({props: this.props, screenName: 'ServicesList'})
    }
       // Customizable Area End


 async componentDidMount() {
        super.componentDidMount();
        // Customizable Area Start


        this.editId = this.props.navigation?.getParam("id") || ""
        this.getPreFilledValues();
        // Customizable Area End


    }


    // Customizable Area Start

    token = localStorage.getItem('token');


    FormSchema = Yup.object().shape({
        serviceName: Yup.string().trim()
            .required("Service name is required"),
        shortName: Yup.string().trim()
            .required("Short name is required"),
        selectedSections: Yup.array().of(
            Yup.string().required('please select at least one ')
        ).required('At least select one section'),
        selectedIcon: Yup.string()
            .required("Select at aleast one Icon")
    })


 selectIcon = (item: GetIconType, setFieldSValue: Function) => {
     setFieldSValue('selectedIcon', item)
}




    getSectionLists = () => {

        const headers = {
            "Content-Type": configJSON.categoryApiContentType,
            token: this.token,
        };


       


        const sectionListDataMessages = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );


        this.getSectionListCallId = sectionListDataMessages.messageId;
        this.disableLoadMoreSection = true;
        const searchQuery = this.state.sectionAutoCompleteValue ? `&filter_by[query]=${this.state.sectionAutoCompleteValue}` : ''


        sectionListDataMessages.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.getSectionListsAPIEndPoint}?dropdown=true${searchQuery}&page_no=${this.state.sectionPage}&per_page=${this.itemsPerPage}`
        );

        sectionListDataMessages.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.httpGetType
        );

        sectionListDataMessages.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

      
        runEngine.sendMessage(sectionListDataMessages.id, sectionListDataMessages);
    }


    getAllIcons = () => {

        const headers = {
            "Content-Type": configJSON.categoryApiContentType,
            token: this.token,
        };

       
        const iconsListDataMessages = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getIconsCallId = iconsListDataMessages.messageId;

        iconsListDataMessages.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        const searchQuery = this.state.iconSearchText ? `&filter_by[query]=${this.state.iconSearchText}` : ''
        

        iconsListDataMessages.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.getIconsListsAPIEndPoint}?page_no=${this.state.iconPage}&per_page=${this.itemsPerPage}${searchQuery}`
        );

       

        iconsListDataMessages.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.httpGetType
        );

        runEngine.sendMessage(iconsListDataMessages.id, iconsListDataMessages);
    }


    getAllSectionName = (sections: SectionType[]) => {

        const parsedSectionName: string[] = []

        sections.forEach((section: SectionType) => {
            parsedSectionName.push(section.attributes.section_name)
        })

        return parsedSectionName

    }

    getAllSelectedSectionIds = (sections: SectionType[]) => {
        const parsedSectionIds: string[] = []
        sections.forEach((section: SectionType) => {
            parsedSectionIds.push(section.id)
        })
        return parsedSectionIds
    }

    getAllSelectedSectionData = (sections: SectionType[]) => {
        const parsedSectionIds: {id: string, name?: string, option: string}[] = []
        sections.forEach((section: SectionType) => {
            parsedSectionIds.push({id: section.id, option: section?.attributes?.section_name})
        })
        return parsedSectionIds
    }


    getSelctedIcon = (iconId: string): IconOption | undefined => {
        if(iconId){
            const dataBody = this.state.iconList.find((option) => option.id == iconId)
            return dataBody
        }
     }




    updateService = (values: PreFilledType) => {

        this.setState({
            addBtnLoading: true
        })

        const header = {
            "Content-Type": configJSON.categoryApiContentType,
            token: localStorage.getItem('token')
        }


        const httpBodys = JSON.stringify({
            data: {
                attributes: {
                    name: values.serviceName,
                    second_name: values.serviceSecondaryName,
                    section_ids: values.selectedSections,
                    active: true,
                    online_order: values.onlineOrder,
                    icon_id: Number(values?.selectedIcon),
                    short_name: values?.shortName
                }
            }

        })

        const getDataMsgs = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.updateServiceCallId = getDataMsgs.messageId;

        getDataMsgs.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            httpBodys
        );
        getDataMsgs.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.addServiceListAPIEndPoint}/${this.editId}`
        );
        getDataMsgs.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        getDataMsgs.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "PUT");
        runEngine.sendMessage(getDataMsgs.id, getDataMsgs);

    }



    getPreFilledValues = () => {
        if (!this.editId) return;
        this.setState({loading: true})
        const headers = {
            "Content-Type": configJSON.categoryApiContentType,
            token: this.token,
        };



      

        const preFilledServiceDataMessages = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getEditPreFilledCallId = preFilledServiceDataMessages.messageId;


        preFilledServiceDataMessages.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );


        preFilledServiceDataMessages.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.addServiceListAPIEndPoint}/${this.editId}`
        );

      

        preFilledServiceDataMessages.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.httpGetType
        );

        runEngine.sendMessage(preFilledServiceDataMessages.id, preFilledServiceDataMessages);

    }

    handleCloseSnackbar =  ()=>{
         this.setState({
              snackbar: {
                  open:false,
                  severity:'info',
                  message:''

              }

         })
    }

    handleProductMultiSelect(getData: {id: string, name: string, option: string}) {
        let idCheck: string = getData?.id;
        let newArray: {id?: string, name?: string, option?: string}[];
        if (this.state.productSelectedData.some((object: {id?: string, name?: string, option?: string}) => object.id === idCheck)) {
            newArray = this.state.productSelectedData?.filter((objct: {id?: string, name?: string, option?: string}) => objct.id !== idCheck);
            this.setState({ productSelectedData: newArray })
        }
        else {
            this.setState({ productSelectedData: [...this.state.productSelectedData, getData] }, () => {
                if (this.state?.productSelectedData?.length === this.state?.dropdownOptions?.section?.length) { this.setState({ isSelectAll: true }) }
            })
        }
    }

    handleProductSelectAll(values: PreFilledType) {       
        if (this.state.isSelectAll) {
            this.setState({ isSelectAll: false, productSelectedData: [] })
            this.setState(() => ({
                initialValues: {
                    serviceSecondaryName: values.serviceSecondaryName,
                    serviceName: values.serviceName,
                    selectedSections: [],
                    selectedIcon: values.selectedIcon,
                    onlineOrder: values.onlineOrder,
                    shortName: values.shortName
                }
            }))
        }
        else {
            this.setState({ isSelectAll: true, productSelectedData: this.state.dropdownOptions.section })
        }
    }

    handleSectionMultiSelect = (value: { id: string, option: string }[], option: { id: string, option: string }, setFieldValue: Function) => {
        let updateStateData: Partial<S> = {}
        let fieldValue: Array<string> = []
        if (option.id == "-1") {
          updateStateData = this.state.isSectionSelectAll ?
            { 
                isSectionSelectAll: false, 
                selectedOptions: [], 
                selectedSectionsForList: [] 
            }
            :
            {
              isSectionSelectAll: true,
              selectedOptions: [{ id: "-1", option: "Select All" }],
              selectedSectionsForList: [{ id: "-1", option: "Select All" }, ...this.state.sectionLists]
            };
          fieldValue = updateStateData.isSectionSelectAll ? ["All"] : []
        } else {
          const isValueContained = value.some((item) => item.id == option.id)
          updateStateData.isSectionSelectAll = false
          value = value.filter((item) => item.id !== "-1")
          const newItems = isValueContained ? value.filter((item) => item.id != option.id) : [...value, option]
          updateStateData.selectedOptions = newItems
          updateStateData.selectedSectionsForList = newItems
          fieldValue = newItems.map((item) => item.id)
        }
        this.setState(updateStateData as Pick<S, keyof S>, () => {
          setFieldValue("selectedSections", fieldValue)
        })
      };
    debouncedFunction = debounce((newInputValue: string, inputFunction: (inputValue: string) => void) => 
        inputFunction(newInputValue),
            700,
            { maxWait: 2000 }
        );
    handleSectionAutoCompleteChange = (getValue: string) => {
        if (getValue === this.state.sectionAutoCompleteValue) return;
        this.setState({ sectionAutoCompleteValue: getValue, sectionPage: 1 }, () => {
            if (getValue.length < 1 || getValue.length > 2) {
            this.getSectionLists()
            }
        });
    }
    handleScrollSectionDropdown = (event: React.SyntheticEvent) => {
        if (this.disableLoadMoreSection) return;
        const checkListboxNode = event.currentTarget;
        const boxPosition = checkListboxNode.scrollTop + checkListboxNode.clientHeight;
    
        if (checkListboxNode.scrollHeight - boxPosition <= 1.30) {
          this.setState((prevState) => ({ sectionPage: prevState.sectionPage + 1 }), () => {
            this.getSectionLists()
          })
        }
    };

    handleSelectIcon = (value: IconOption, setFieldValue: (field: string, fieldValue:string) => void) => {
        this.setState({
            selectedIcon: value
        },() => setFieldValue("selectedIcon", value.id))
    }

    handleIconAutoCompleteChange = (getValue: string) => {
        if (getValue === this.state.iconSearchText) return;
        this.setState({ iconSearchText: getValue, iconPage: 1 }, () => {
            if (getValue.length < 1 || getValue.length > 2) {
            this.getAllIcons()
            }
        });
    }
    handleScrollIconDropdown = (event: React.SyntheticEvent) => {
        if (this.disableLoadMoreIcon) return;
        const checkListboxNode = event.currentTarget;
        const boxPosition = checkListboxNode.scrollTop + checkListboxNode.clientHeight;
    
        if (checkListboxNode.scrollHeight - boxPosition <= 1.30) {
          this.setState((prevState) => ({ iconPage: prevState.iconPage + 1 }), () => {
            this.getAllIcons()
          })
        }
    };
    // Customizable Area End
}
