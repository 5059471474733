import React, { Component } from "react";
import { Button, Box, styled } from "@material-ui/core";
import { colors } from "../../blocks/utilities/src/Colors";
import { sortCondition } from "../../blocks/utilities/src/CustomBlockHelpers";

type TablePopoverProps = {
  header: string;
  buttonText?: string;
  onButtonClick?: () => void;
  buttonStyle?: React.CSSProperties;
  onClickGrid?: () => void;
  onClickList?: () => void;
  onFilterChange?: () => void
  middleComponent?: React.ReactNode;
  filterComponent?: React.ReactNode;
  permissionStatus?: boolean;
};
type TablePopoverState = { onChangeValue?: (value: boolean) => void };

export default class PageHeader extends Component<
  TablePopoverProps,
  TablePopoverState
> {
  constructor(props: any) {
    super(props);
    this.state = {};
  }

  render() {
    const { permissionStatus } = this.props;
    return (
      <HeaderBoxWrapper style={webStyle.headerWrapper}>
        <div className={'pageHeaderTitle'}>{this.props.header}</div>
        {this.props.middleComponent}
        <div style={{ display: "flex", gap: "24px" }}>
          {this.props.filterComponent}
          {this.props.buttonText && (
              sortCondition(
                (permissionStatus as boolean),
                  <Button
                    data-test-id="PageHeaderButton"
                    onClick={this.props.onButtonClick}
                    className={"headerAddButton"}
                    style={{
                      textTransform: "unset",
                      ...(this.props.buttonStyle || {}),
                    }}
                  >
                    {this.props.buttonText}
                  </Button>,
                <></>
              )
          )}
        </div>
      </HeaderBoxWrapper>
    );
  }
}

const webStyle = {
  headerWrapper: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: 32,
    alignItems: "center",
    flexWrap: 'wrap' as 'wrap'
  },
};

const HeaderBoxWrapper=styled(Box)({
  "& .pageHeaderTitle": {
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "24px",
    lineHeight: "29px",
    "@media only screen and (max-width: 1024px)": {
      fontSize: "16px",
    },
  },
  "& .headerAddButton": {
    fontWeight: 600,
    background: colors().cyancobaltblue,
    fontSize: "16px",
    color: colors().white,
    lineHeight: "24px",
    height: "56px",
    width: "184px",
    textTransform: "unset",
    borderRadius: "8px",
    "&:hover": {
      background: colors().cyancobaltblue,
    },
    "@media only screen and (max-width: 1024px)": {
      fontSize: "13px",
      width: "160px",
      height: "44px",
    }
  }

})
