export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const CrossIcon = require("../assets/cross.png");
export const EyeIcon = require("../assets/eye.png");
export const PrintIcon = require("../assets/print.png");
export const iconCheckBox = require("../../../components/src/customComponents/assets/checkbox.svg");
export const iconCheckBoxChecked = require("../../../components/src/customComponents/assets/checkbox_checked.svg");
export const customerAddIcon = require("../assets/customer_add_icon.png");
export const customerViewIcon = require("../assets/customer_view_icon.png");
export const emptyCartIcon = require("../assets/image_empty_cart.png");
export const deleteItemIcon = require("../assets/image_delete.png");
export const quickDropIcon = require("../assets/group_package.png");
export const ProductImage = require("../assets/defaultProductImg.png");
export const PlusIcon = require("../assets/button_add_item.png");
export const MinusIcon = require("../assets/button_remove_item.png");
export const productAddIcon = require("../assets/product_add_item.svg");
export const calenderIcon = require("../assets/calender_icon.png");