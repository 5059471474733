import { Message } from "../../framework/src/Message";
import MessageEnum, { getName } from "../../framework/src/Messages/MessageEnum";
import { IFilter } from "./FilterPopover";

interface IApiCall {
    header: any;
    httpBody: any;
    url: string;
    httpMethod: string;
}

export function apiCall({ header, httpBody, url, httpMethod }: IApiCall) {
    const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        url
    );

    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
    );

    if (Object.keys(httpBody).length > 0) {
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );
    }

    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        httpMethod
    );

    return requestMessage;
}

export function getHeaders(obj: Record<string, string>): Headers {
    const headers: any = {};
    headers["Content-Type"] = "application/json";
    for (let key in obj) {
        headers[key] = obj[key];
    }
    return headers;
}

export function handleLogout(navigation:any, errors:any) {
    if (errors.length > 0 && (errors[0]?.token === "Token has Expired" || errors[0]?.token === "Invalid token")) {
        navigation.navigate("EmailAccountLoginPage");
    }
}

export const makeApiMessage = ({
  url = "",
  headers = {},
  method = "GET",
  body = null,
  isHeader = false
}: {
  body?: any;
  url: string;
  headers?: any;
  method: string;
  isHeader?: boolean
}) => {
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  const header = {
    "Content-Type": "application/json",
    token: localStorage.getItem("token"),
    ...headers,
  };

  const headreWithoutContentType = {
    token: localStorage.getItem("token"),
    ...headers,
  };

  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    url
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(!isHeader ? header : headreWithoutContentType)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    method
  );

  body &&
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      body
    );

  return requestMessage;
}

export const getFiterList = (responseJson: any, filters: IFilter[], title: string) => {
  const list = responseJson?.filter_names?.map((value: string) => {
    return {
      label: value,
      value,
    }
  }) || [];
  const updatedFilters = filters.map((item: IFilter) => {
    if(item.value !== ""){
      if (item.title === title) item.options = list;
      return item;
    } else {
      if (item.title === title) item.options = [];
      return item;
    }
  });

  return updatedFilters;
};

export const getRegionStoreGroupFiterList = (responseJson: any, filters: IFilter[], title: string) => {
  const list = responseJson?.suggestion?.map((value: string) => {
    return {
      label: value,
      value,
    }
  }) || [];
  const updatedFilters = filters.map((item: IFilter) => {
    if(item.value !== ""){
      if (item.title === title) item.options = list;
      return item;
    } else {
      if (item.title === title) item.options = [];
      return item;
    }
  });
  
  return updatedFilters;
}

export const lazyRetry = (componentImport: Function) => {
  return new Promise((resolve, reject) => {
      const hasRefreshed = JSON.parse(
          window.sessionStorage.getItem('retry-lazy-refreshed') || 'false'
      );
      componentImport().then((component: any) => {
          window.sessionStorage.setItem('retry-lazy-refreshed', 'false');
          resolve(component);
      }).catch((error: any) => {
          if (!hasRefreshed) {
              window.sessionStorage.setItem('retry-lazy-refreshed', 'true'); 
              return window.location.reload();
          }
          reject(error);
      });
  });
};

export  const updateMultiSelectData = (newItems: {id: string | number, option: string}[], totalList: {id: string | number, option: string}[], isSelectAll: string, selectedData: string, selectedForList: string) => {
  const updateStateData: { [key: string]: boolean | {id: string| number, option: string}[]} = {}
  updateStateData[isSelectAll] = false
  updateStateData[selectedData] = newItems
  updateStateData[selectedForList] = newItems
  if(newItems.length === totalList.length) {
    updateStateData[isSelectAll] = true
    updateStateData[selectedData] = [{ id: "-1", option: "Select All" }]
    updateStateData[selectedForList] = [{ id: "-1", option: "Select All" }, ...totalList]
  }

  return updateStateData
}
