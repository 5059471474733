import * as Yup from "yup";

export const validationSchemaForAddProduct = Yup.object().shape({
  items: Yup.array().of(
    Yup.object({
      measurement_type: Yup.array(),
      quantity: Yup.number()
        .min(1, "Quantity should be greater zero")
        .typeError("Please enter number"),
      width: Yup.number()
        .moreThan(0, "Please enter width greater zero")
        .typeError("Please enter number only")
        .nullable(),
      height: Yup.number()
        .moreThan(0, "Please enter length greater zero")
        .typeError("Please enter number only")
        .nullable(),
      weight: Yup.number()
        .moreThan(0, "Please enter weight greater zero")
        .typeError("Please enter number only")
        .nullable(),
      size: Yup.string().when(
        ["measurement_type", "width", "height", "weight"],
        {
          is: (measurement_type, width, height, weight) => {
            const type = measurement_type || [];
            const hasSQM = type.includes("SQM");
            const hasWeight = type.includes("Weight");

            if (!width && !height && hasSQM && !hasWeight) return true;
            else if (!width && height && hasSQM) return true;
            else if (width && !height && hasSQM) return true;
            else if (!width && !height && weight && hasWeight) return false;
            else return false;
          },
          then: Yup.string().required("Length and width is required."),
        }
      ),
      weightError: Yup.string().when(["measurement_type", "weight"], {
        is: (measurement_type, weight) => {
          const type = measurement_type || [];
          const hasSQM = type.includes("SQM");
          const hasWeight = type.includes("Weight");
          return !hasSQM && hasWeight && !weight;
        },
        then: Yup.string().required("Weight is required."),
      }),
      sizeWeightError: Yup.string().when(
        ["measurement_type", "width", "height", "weight"],
        {
          is: (measurement_type, width, height, weight) => {
            const type = measurement_type || [];
            const hasSQM = type.includes("SQM");
            const hasWeight = type.includes("Weight");
            return hasSQM && hasWeight ? !width && !height && !weight : false;
          },
          then: Yup.string().required("Size or Weight is required."),
        }
      ),
    })
  ),
});

export interface UpchargeIds {
  attributes: {
    name: string;
    price: number;
    upcharge_type: string;
    order_price: string;
  }
}
export interface UpchargeMainList {
  catalogue_id: number;
  catalogue_variant_id: number;
  total_price: string;
  upcharge_list_ids: Array<UpchargeIds>
}

export interface ICartProductItem {
  id: undefined | number;
  catalogue_id: number;
  catalogue_variant_id: number;
  customer_preference_id: number | null;
  name: string;
  product_name: string;
  preference_id: number;
  product_second_name: string;
  unit_price: number;
  image: string | null;
  quantity: number;
  service_name: string;
  service_id: number;
  parent_Product_id: null | string | number;
  category_id: number;
  upcharge_list_ids: IUpchargeListItem[]
  isCarpetAndFurnitureProduct?: boolean;
  notes?: string;
  height?: number;
  width?: number;
  weight?: number;
}

export interface IUpchargeListItem {
  attributes: {
    id: number;
    name: string;
    price: number;
    upcharge_type: string;
    icon: {
      attributes: {
        image: null;
        name: string;
        second_name: string;
        image_type: string;
      };
    };
  };
}