/** @format */
// Customizable Area Start
import React from "react";
import { Button, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
// Customizable Area End

// Customizable Area Start
const useStyles = makeStyles(() => ({
	pagination: {
		display: "flex",
		alignItems: "center",
		justifyContent: "flex-end",
		marginTop: "10px",
		fontSize: "0.85rem",
	},
	button: {
		margin: "0 5px",
		padding: "5px 10px",
		fontSize: "16px",
		borderRadius: "4px",
		backgroundColor: "transparent",
		cursor: "pointer",
		outline: "none",
		border: "none",
		"&:hover, &:focus": {
			backgroundColor: "transparent",
		},
	},
	paginationLabel: {
		color: "#1a1a1a",
		fontSize: '1rem',
		fontWeight: 500,
	},
}));
// Customizable Area End

// Customizable Area Start
interface PaginationProps {
  currentPage: number;
  totalPages: number;
  totalItems: number;
  goToNextPage: () => void;
  goToPreviousPage: () => void;
  itemsPerPage: number;
}
// Customizable Area End

// Customizable Area Start
const CustomPagination: React.FC<PaginationProps> = ({
	currentPage,
	totalPages,
	totalItems,
	goToNextPage,
	goToPreviousPage,
	itemsPerPage,
}) => {
	const classes = useStyles();

	const startIndex = (currentPage - 1) * itemsPerPage + 1;
	const endIndex = Math.min(startIndex + itemsPerPage - 1, totalItems);

	const isPreviousButtonDisabled = currentPage === 1;
	const isNextButtonDisabled =
		currentPage === totalPages || totalItems === 0;

	return (
		<div className={classes.pagination} data-test-id="preferenceListPagination">
			<Typography variant="body1" className={classes.paginationLabel}>
				{startIndex || 0}-{endIndex || 0} of {totalItems || 0}
			</Typography>
			<Button
			    data-test-id='previousButton'
				className={classes.button}
				onClick={goToPreviousPage}
				disabled={isPreviousButtonDisabled}>
				<ChevronLeftIcon />
			</Button>
			<Button
			    data-test-id='nextpage'
				className={classes.button}
				onClick={goToNextPage}
				disabled={isNextButtonDisabled}>
				<ChevronRightIcon />
			</Button>
		</div>
	);
};

export default CustomPagination;
// Customizable Area End