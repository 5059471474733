import React, { useState } from "react";
import IconButton from "@material-ui/core/IconButton";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { Menu, MenuItem, Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

interface Props {
  options: string[];
  menuIconColor?: string;
  getSelectedOption?: any;
  item?: any;
  disabled?: boolean;
  menuWidth?:string;
  identifier?: any;
}

const useStyles = makeStyles((theme) => ({
  dropdown: {
    "& .MuiMenuItem-root:hover": {
      backgroundColor: "#204B9C",
      color: "#ffff",
    },
  },
  fontFamily: {
    fontFamily: "Montserrat",
  },
}));

const MenuPopover = ({
  options,
  menuIconColor,
  getSelectedOption,
  item,
  disabled,
  menuWidth,
  identifier
}: Props ) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event?.currentTarget);
  };

  const handleClose = (selectedOption: any) => {
    setAnchorEl(null);
    if(getSelectedOption){
      getSelectedOption(selectedOption, item, identifier);
    }
  };
  

  return (
    <>
      <IconButton disabled={disabled}>
        <Box onClick={handleClick} data-test-id='MenuPopoverIcon'>
          <MoreVertIcon 
            style={menuIconColor ? { color: menuIconColor } : { color: "white" }}
          />
        </Box>
      </IconButton>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => {handleClose(undefined)}}
        className={classes.dropdown}
        getContentAnchorEl={null}
        disablePortal
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        PaperProps={{
          style: {
            marginTop: "8px",
            borderRadius: '8px',
            width: menuWidth ?? 'inherit',
            boxShadow: '0 2px 4px rgba(0,0,0,.3)'
          },
        }}
      >
        {options.map((option) => (
          <MenuItem
            key={option}
            className={classes.fontFamily}
            value={option}
            onClick={() => {handleClose(option)}}
            data-test-id='MenuItemTest'
          >
            {option}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default MenuPopover;
