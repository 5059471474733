// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { Message } from "../../../framework/src/Message";
import { navigateTo } from "../../utilities/src/CustomBlockHelpers";
export const configJSON = require("./config");
import { makeApiMessage } from "../../../components/src/common";
import { runEngine } from "../../../framework/src/RunEngine";
import { IBlock } from "../../../framework/src/IBlock";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { FormikErrors, FormikValues, FormikTouched } from "formik";
import { IUserContext } from "../../../blocks/navigationmenu/src/PageContainerController.web";
// Customizable Area End

// Customizable Area Start
export interface TransferReqIntialValues {
  transfer_id: number;
  amount: number | null;
  store: string;
  driver: string;
  reason: string;
}

export interface IStoreList {
  id: string;
  type: string;
  attributes: {
    id: number;
    store_name: string;
    name_translation: string;
  };
}

export interface IDriverList {
  id: string;
  type: string;
  attributes: {
    id: number;
    first_name: string;
  };
}

export interface IDropdownList {
  id: string;
  option: string;
}

// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation: {};
  id: string;
  // Customizable Area End
}

export interface S {
  // Customizable Area Start
  addTransfReqtIntialVal: TransferReqIntialValues;
  transferId: number;
  storeList: Array<IDropdownList>;
  driverList: Array<IDropdownList>;
  snackbar: {
    open: boolean;
    severity: "error" | "success" | "info" | "warning" | undefined;
    message: string;
  };
  regionId: number | null | undefined | string;
  isLoading: boolean
  // Customizable Area End
}

export interface SS { }

export default class TransferAmountController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getTransferMessageId: string = "";
  getStoreDropdownMessageId: string = "";
  getDriverDropdownMessageId: string = "";
  createTransferAmtMessageId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIResponceDataMessage),
    ];
    this.state = {
      addTransfReqtIntialVal: {
        transfer_id: 0,
        amount: null,
        reason: "",
        store: "",
        driver: "",
      },
      transferId: 0,
      storeList: [],
      driverList: [],
      snackbar: {
        open: false,
        severity: "info",
        message: "",
      },
      regionId: null,
      isLoading: false
    };
    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  async receive(_from: string, message: Message) {
    // Customizable Area Start
    const requestId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    this.handleTransferIdRes(requestId, responseJson);
    this.handleStoreDropdownRes(requestId, responseJson);
    this.handleDriverDropdownRes(requestId, responseJson);
    this.handleCreateTranfAmtRes(requestId, responseJson);
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    this.getTransferId();
    this.getStoreDropdown();
    this.getDriverDropdown();
    // Customizable Area End
  }

  // Customizable Area Start

  getTransferId() {
    const apiUrl = configJSON.generateTransferIdApi;
    const requestMessage = makeApiMessage({
      url: apiUrl,
      method: "GET",
    });

    this.getTransferMessageId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getStoreDropdown() {
    const apiUrl = `${configJSON.getStoreDropdownApi}&region_ids=${this.state.regionId}`;
    const requestMessage = makeApiMessage({
      url: apiUrl,
      method: "GET",
    });

    this.getStoreDropdownMessageId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getDriverDropdown() {
    const apiUrl = configJSON.getDriverDropdownApi;
    const requestMessage = makeApiMessage({
      url: apiUrl,
      method: "GET",
    });

    this.getDriverDropdownMessageId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleTransferIdRes(requestId: string, response: { transfer_id: number }) {
    if (requestId === this.getTransferMessageId) {
      this.setState({
        transferId: response.transfer_id,
      });
    }
  }

  handleStoreDropdownRes(requestId: string, response: { data: [] }) {
    if (requestId === this.getStoreDropdownMessageId) {
      const listData = response.data.map((item: IStoreList) => {
        return {
          id: String(item.attributes.id),
          option: item.attributes.store_name,
        };
      });
      this.setState({
        storeList: listData,
      });
    }
  }

  handleDriverDropdownRes(requestId: string, response: { data: [] }) {
    if (requestId === this.getDriverDropdownMessageId) {
      const listData = response.data.map((item: IDriverList) => {
        return {
          id: String(item.attributes.id),
          option: item.attributes.first_name,
        };
      });
      this.setState({
        driverList: listData,
      });
    }
  }

  handleRedirect = () => {
    navigateTo({
      props: this.props,
      screenName: "OutGoingTransaction",
    });
  };

  handleSubmit = (values: TransferReqIntialValues) => {
    this.setState({isLoading:true})
    const body = {
      transfer_id: this.state.transferId,
      amount: values.amount,
      driver_account_id: values.driver,
      store_id: values.store,
      reason: values.reason,
      "request_page": "cashier"
    };
    const message = makeApiMessage({
      url: configJSON.transferRequestsListApi,
      method: "POST",
      body: JSON.stringify(body),
    });

    this.createTransferAmtMessageId = message.messageId;
    runEngine.sendMessage(message.id, message);
  };

  handleCreateTranfAmtRes(
    requestId: string,
    response: { status: number; message: string }
  ) {
    if (requestId === this.createTransferAmtMessageId) {
      this.setState({
        snackbar: {
          message: response.message,
          severity: "success",
          open: true,
        },
      });
      setTimeout(() => {
        this.setState({isLoading:false})
        this.handleRedirect();
      }, 2000);
    }
  }

  checkError(
    error: FormikErrors<FormikValues> | undefined,
    touch: FormikTouched<FormikValues>
  ) {
    if (error && touch) {
      return true;
    }
  }

  handleCloseSnackbar = () => {
    this.setState({
      snackbar: {
        open: false,
        severity: "info",
        message: "",
      },
    });
  };

  handleUserChange = (userContext: IUserContext) => {
    this.setState(
      {
        regionId:
          userContext.user?.attributes.employee_proifle.data.attributes.region_ids.join(',')
      },
      () => this.getStoreDropdown()
    );
  };
  // Customizable Area End
}
