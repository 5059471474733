// Customizable Area Start
Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.errorTitle = "Error";

exports.apiVerifyOtpContentType = "application/json";
exports.apiVerifyOtpEndPoint =
  "account/accounts/sms_confirmation?pin=";

exports.apiVerifyForgotPasswordOtpEndPoint =
  "forgot_password/otp_confirmation";

exports.apiVerifyOtpMethod = "POST";

exports.errorOtpNotValid = "OTP is not valid.";

exports.btnTxtSubmitOtp = "Submit otp";

exports.placeHolderOtp = "OTP";

exports.labelInfo =
  "We Just sent a 4 digits OTP to phone. Enter your OTP code below.";

exports.submitButtonColor = "#6200EE";

exports.attemptLabel = "You have {attemptRight} attempts left";
exports.exceedMessage =
  "You have exceeded the limit for reset password. Please contact Admin";
exports.verifyOtpApi = "bx_block_wallet/wallets/verify_otp";

exports.title_Verification ='Verification'
// Customizable Area End