export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const imgVisbility = require("../assets/visibility.svg");
export const imgVisbilityOff = require("../assets/visibilityOff.svg");
export const printIcon = require("../assets/print.svg");
export const iconPdf = require("../assets/iconpdf.svg");
export const iconJpeg = require("../assets/iconjpeg.svg");
export const iconCheck = require("../assets/round-check.svg");
export const saleByIcon = require("../assets/salebyiocn.svg");
export const printIconPng = require("../assets/print_icon.png")



