Object.defineProperty(exports, "__esModule", {
  value: true,
});

// Customizable Area Start

exports.test01="testing01"

exports.taxListApi = "bx_block_taxcalculator/taxcalculators";
exports.filterByTaxRateApi = "bx_block_taxcalculator/filter_by_tax_rate";
exports.baseFilterApi = "bx_block_taxcalculator";
exports.viewTaxApi = "bx_block_taxcalculator/taxcalculators";
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiMethod = "POST";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "TaxCalculator";
exports.labelBodyText = "TaxCalculator Body";
exports.country = "Country";
exports.countryPlaceholder = "Select at least one country";
exports.countryError = "Please select at least one country";
exports.price = "Price";
exports.pricePlaceholder = "Enter price";
exports.priceError = "Please enter valid price";
exports.productType = "Product Type";
exports.productTypePlaceholder = "Select at least one product type";
exports.productTypeError = "Please select at least one product";
exports.productName = "Product name";
exports.productNamePlaceholder = "Enter product name";
exports.productNameError = "Please enter valid product name";
exports.countryListApi = "bx_block_taxcalculator/countries";
exports.productTypeListApi = "bx_block_categories/categories";
exports.calculateTaxApi = "bx_block_taxcalculator/calculate_tax";
exports.btnExampleTitle = "SUBMIT";
exports.addTax = "Add Tax";
exports.tax = "Tax";
exports.taxName = "Tax Name";
exports.taxPercentage = "Tax Percentage";
exports.taxInformation = "Tax Information";
exports.labelColor = "rgba(51, 65, 85, 1)";
exports.enterTaxName = "Enter Tax Name";
exports.enterTaxPercentage = "Enter Tax Percentage";
exports.updateTaxSuccess = "Tax Updated Successfully !!!";
exports.addTaxSuccess = "Tax Added Successfully !!!";
exports.errorMsg = "something went wrong";
exports.addEditTax = "AddEditTax";
exports.new = "new";
exports.view = "View";
exports.edit = "Edit";
exports.black = "black";
exports.filterIcon = "filterIcon";
exports.contained = "contained";
exports.addService = "add-service";
exports.white = "#fff";
exports.blue = "rgba(32, 75, 156, 1)";
exports.one = "1";
exports.taxList = "TaxList";
exports.add = "Add";
exports.cancel = "Cancel";
exports.back = "Back";
exports.top = "top";
exports.center = "center";
exports.noData = "No records found !!";
exports.taxName = "Tax Name";
exports.taxpercentage = "Tax Percentage";
exports.token = "token";
exports.setViewDecimal = "Decimal Rate";
exports.taxNameFilterEndPoint =
  "/bx_block_taxcalculator/taxcalculators/tax_calculator_filter?tax_name=";
// Customizable Area End
