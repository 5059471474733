import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { isEmail } from "../../../framework/src/Utilities";

// Customizable Area Start
import _ from "lodash";
import { IUserContext } from "../../../blocks/navigationmenu/src/PageContainerController.web";
import {
  PermissionStatus,
  checkForNewPermissonStatus,
  customPermissionApiKey
} from "../../../blocks/utilities/src/CustomBlockHelpers";
import { PermissionGroupArray } from "../../../blocks/navigationmenu/src/utils";
interface ModalOptions {
  scrollHeight: number ; 
}
interface ICompany {
  id?: string;
  type?: "company";
  attributes: {
    activated: boolean;
    id?: number;
    name: string;
    name_arabic?: string;
    vat_number: string;
    vat_number_arabic?: string;
    registration_no: string;
    tin_no: string;
    created_at: string;
    updated_at: string;
    email: string;
    telephone: string;
    address: string;
    others: {
      [key: string]: string | { title: string; isSelected: boolean }[];
    };
    country_id: number;
    number_of_vats: string;
    vat_details_attributes: {
      id?: number;
      tax_calculator_id?: number;
      tax_calculator_name?: string;
      vat_name?: string;
      company_id?: number;
      default_tax?: boolean;
      created_at?: string;
      updated_at?: string;
    }[];
    country_data:{
      id:string | number;
      option:string
    }
  };
}

interface ICountry {
  id: number;
  name: string;
  country_code: string;
  created_at: string;
  updated_at: string;
}
interface ITax {
  id: string;
  type: "taxcalculator";
  attributes: {
    id: number;
    tax_name: string;
  };
}

const initialCompanyInfo: ICompany = {
  attributes: {
    activated: false,
    name: "",
    registration_no: "",
    vat_number: "",
    vat_number_arabic: "",
    tin_no: "",
    created_at: "",
    updated_at: "",
    email: "",
    telephone: "",
    address: "",
    others: {},
    country_id: 0,
    number_of_vats: "1",
    vat_details_attributes: [
      {
        tax_calculator_name: "",
        vat_name: "",
        default_tax: true,
      },
    ],
    country_data:{
      id:'',
      option:''
    }
  },
};
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  addModalVisible: boolean;
  companyInfo: ICompany;
  initialCompanyInfo: ICompany;
  modalCategory: "text" | "dropdown";
  modalTitle: string;
  modalText: string;
  modalOptions: { title: string; isSelected: boolean }[];
  countries: ICountry[];
  formErrors: { [key: string]: string };
  companyId: string;
  snackBarMessage: string;
  taxList: ITax[];
  isLoading: boolean;
  permissionStatus: PermissionStatus;
  modalError:{
    title:string|null,
    text:string|null,
    option:string|null
  }
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CfCompanyInfoController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  updateCompanyCallId: string = "";
  getCountriesCallId: string = "";
  addCompanyCallId: string = "";
  getCompanyCallId: string = "";
  getTaxListId: string = "";
  modalOptionRef: ModalOptions & { scrollTo?: ((x: number, y: number) => void) | undefined } | null = null;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      addModalVisible: false,
      modalCategory: "text",
      modalTitle: "",
      modalText: "",
      companyInfo: _.cloneDeep(initialCompanyInfo),
      initialCompanyInfo: _.cloneDeep(initialCompanyInfo),
      modalOptions: [{ title: "", isSelected: false }],
      countries: [],
      formErrors: {},
      companyId: "",
      snackBarMessage: "",
      taxList: [],
      isLoading: false,
      permissionStatus: {
        mainPermission: false,
        createPermission: false,
        viewPermission: false,
        editPermission: false,
        deactivatePermission: false
      },
      modalError:{
        title:null,
        text:null,
        option:null
      }
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId === this.getCountriesCallId) {
        if (responseJson && responseJson.data) {
          let countries = responseJson.data as ICountry[];
          this.setState({ countries });
        }
      } else if (apiRequestCallId === this.getCompanyCallId) {
        this.receiveCompanyInfo(message);
      } else if (apiRequestCallId === this.updateCompanyCallId) {
        this.receiveUpdateCompany(message);
      } else if (apiRequestCallId === this.getTaxListId) {
        this.setState({ taxList: responseJson.data as ITax[] });
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start

  receiveUpdateCompany = (message: Message) => {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (responseJson && responseJson.data) {
      if (responseJson.data.type !== "error") {
        if (this.state.companyId)
          this.showMessage("Company Info Updated Successfully");
        else this.showMessage("Company Info Added Successfully");
        setTimeout(() => {
          this.props.navigation.goBack();
        }, 2000);
      } else {
        const formErrors = this.state.formErrors;
        const telephoneError = responseJson.data.attributes.errors.telephone;
        if (telephoneError) {
          formErrors[configJSON.phoneNumber] = 'Telephone '+ telephoneError[0];
        }
        const emailError = responseJson.data.attributes.errors.email;
        if (emailError) {
          formErrors[configJSON.emailAddress] = 'Email ' + emailError[0];
        }
        const nameError = responseJson.data.attributes.errors.name;
        if (nameError) {
          formErrors[configJSON.companyName] = 'Name ' + nameError[0];
        }
        const tinError = responseJson.data.attributes.errors.tin_no;
        if (tinError) {
          formErrors[configJSON.tinNo] = 'Tin '+ tinError[0];
        }
        const regError = responseJson.data.attributes.errors.registration_no;
        if (regError) {
          formErrors[configJSON.registrationNumber] = 'Registration ' + regError[0];
        }
        this.setState({ formErrors, isLoading: false });
      }
    }
  };

  receiveCompanyInfo = (message: Message) => {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (responseJson && responseJson.data) {
      let companyInfo = {
        ...responseJson.data as ICompany,
        attributes:{
          ...responseJson.data.attributes,
            country_data:{
              id:responseJson.data.attributes.country_id,
              option: this.state.countries && this.state.countries?.find((item:any)=> item.id === responseJson.data.attributes.country_id)?.name
            }
        }
      }
      this.setState({ companyInfo, initialCompanyInfo: companyInfo });
    }
  };
  handleScrollCountryDropdown =()=>{

  }
  getCompany = (companyId: string) => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: window.localStorage.getItem(configJSON.tokenKey),
    };
    const getDataMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getCompanyCallId = getDataMsg.messageId;
    getDataMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodType
    );
    getDataMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.companyEndPoint + "/" + companyId
    );
    getDataMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    runEngine.sendMessage(getDataMsg.id, getDataMsg);
  };

  getCountries = () => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: window.localStorage.getItem(configJSON.tokenKey),
    };
    const getDataMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getCountriesCallId = getDataMsg.messageId;

    getDataMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.countryEndPoint
    );
    getDataMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    getDataMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodType
    );
    runEngine.sendMessage(getDataMsg.id, getDataMsg);
  };

  getTaxList = () => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: window.localStorage.getItem(configJSON.tokenKey),
    };
    const getDataMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getTaxListId = getDataMsg.messageId;

    getDataMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.taxListApi
    );
    getDataMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    getDataMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
    runEngine.sendMessage(getDataMsg.id, getDataMsg);
  };

  updateCreateCompanyInfo = () => {
    const companyId = this.state.companyId;
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: window.localStorage.getItem("token"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.updateCompanyCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.companyEndPoint}` + (companyId ? `/${companyId}` : "")
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    const newCompanyInfo = _.cloneDeep(this.state.companyInfo);
    if (!companyId) {
      delete newCompanyInfo.id;
      delete newCompanyInfo.attributes.id;
      delete newCompanyInfo.type;
    }

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({ data: newCompanyInfo })
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      companyId ? configJSON.httpPutMethod : configJSON.httpPostMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  // web events

  onCancel = () => {
    this.props.navigation.goBack();
    this.setState({ companyInfo: { ...this.state.initialCompanyInfo } });
  };

  onSave = () => {
    if (this.checkFormErrors()) {
      this.setState({ isLoading: true });
      this.updateCreateCompanyInfo();
    }
  };
  onAddButtonClick = () => {
    this.setState({
      addModalVisible: true,
      modalTitle: "",
      modalText: "",
      modalOptions: [{ title: "", isSelected: false }],
    });
  };
  onModalCancelClick = () => {
    this.setState({ 
      addModalVisible: false,
      modalError:{
        option:null,
        text:null,
        title:null
      }
     });
  };
  onModalAddClick = () => {
    if (this.handleValidateModal()) {
      const others =
        this.state.modalCategory === "text"
          ? {
            ...this.state.companyInfo.attributes.others,
            [this.state.modalTitle]: this.state.modalText,
          }
          : {
            ...this.state.companyInfo.attributes.others,
            [this.state.modalTitle]: this.state.modalOptions,
          };
      this.setState({
        modalCategory: "text",
        modalTitle: "",
        modalText: "",
        modalOptions: [{ title: "", isSelected: false }],
        addModalVisible: false,
        companyInfo: {
          ...this.state.companyInfo,
          attributes: {
            ...this.state.companyInfo.attributes,
            others,
          },
        },
      });
    }
  };

  onModalCategoryChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      modalCategory: event.target.value as "text" | "dropdown",
      modalError:{
        option:null,
        text:null,
        title:null
      }
    });
  };

  onChangeVatName = (value: string, index: number) => {
    const vat_details_attributes =
      this.state.companyInfo.attributes.vat_details_attributes;
    vat_details_attributes[index].tax_calculator_id = parseInt(value);
    this.setState({
      companyInfo: {
        ...this.state.companyInfo,
        attributes: {
          ...this.state.companyInfo.attributes,
          vat_details_attributes,
        },
      },
    });
  };

  onClickVatDefault = (index: number) => {
    const vat_details_attributes =
      this.state.companyInfo.attributes.vat_details_attributes;
    vat_details_attributes[index].default_tax = true;
    vat_details_attributes.forEach((vatDetail, i) => {
      if (i !== index) vatDetail.default_tax = false;
    });
    this.setState({
      companyInfo: {
        ...this.state.companyInfo,
        attributes: {
          ...this.state.companyInfo.attributes,
          vat_details_attributes,
        },
      },
    });
  };

  async componentDidMount() {
    const companyId = window.location.search.split("=")[1];
    this.getCountries();
    this.getTaxList();
    if (companyId) {
      this.getCompany(companyId);
      this.setState({ companyId });
    }
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>): void {
    if (
      this.state.companyInfo.attributes.number_of_vats !==
      prevState.companyInfo.attributes.number_of_vats
    ) {
      let newVatDetails = [];
      for (
        let i = 0;
        i < parseInt(this.state.companyInfo.attributes.number_of_vats);
        i++
      ) {
        if (this.state.companyInfo.attributes.vat_details_attributes[i]) {
          newVatDetails.push(
            this.state.companyInfo.attributes.vat_details_attributes[i]
          );
        } else {
          newVatDetails.push({
            tax_calculator_name: "",
          });
        }
      }
      const number_of_vats =
        this.state.companyInfo.attributes.number_of_vats || "1";
      if (newVatDetails.length === 0) {
        newVatDetails.push({
          tax_calculator_name: "",
        });
      }

      this.setState({
        companyInfo: {
          ...this.state.companyInfo,
          attributes: {
            ...this.state.companyInfo.attributes,
            number_of_vats,
            vat_details_attributes: newVatDetails,
          },
        },
      });
    }

    if (
      this.state.companyInfo.attributes.vat_details_attributes !==
        prevState.companyInfo.attributes.vat_details_attributes &&
      this.state.companyInfo.attributes.vat_details_attributes.length > 0
    ) {
      const defaultVat =
        this.state.companyInfo.attributes.vat_details_attributes.find(
          (vat) => vat.default_tax
        );

      if (!defaultVat) {
        this.setState({
          companyInfo: {
            ...this.state.companyInfo,
            attributes: {
              ...this.state.companyInfo.attributes,
              vat_details_attributes:
                this.state.companyInfo.attributes.vat_details_attributes.map(
                  (vatDetail, i) => {
                    if (i === 0) vatDetail.default_tax = true;
                    return vatDetail;
                  }
                ),
            },
          },
        });
      }
    }
  }

  onAddOption = () => {
    this.setState(
      {
        modalOptions: [
          ...this.state.modalOptions,
          { title: "", isSelected: false },
        ],
      },
      () => {
        if (this.modalOptionRef) {
          this.modalOptionRef.scrollTo?.(0, this.modalOptionRef.scrollHeight);
        }
      }
    );
  };

  onDeleteOption = (index: number) => {
    const modalOptions = [...this.state.modalOptions];
    modalOptions.splice(index, 1);
    this.setState({ modalOptions });
  };

  checkEmail = (email: string, formErrors: { [key: string]: string }) => {
    if (!email) formErrors[configJSON.emailAddress] = "Email is required";
    else {
      const message = isEmail("", email).message;
      if (message) formErrors[configJSON.emailAddress] = message;
    }
  };

  checkFormErrors = () => {
    const { companyInfo } = this.state;
    const { attributes } = companyInfo;
    const {
      name,
      email,
      address,
      country_id,
      telephone,
      registration_no,
      tin_no,
    } = attributes;
    const formErrors: { [key: string]: string } = {};
    this.checkEmail(email, formErrors);
    if (!name.trim()) formErrors[configJSON.companyName] = "Company name is required";
    if (!address.trim()) formErrors[configJSON.address] = "Address is required";
    if (!country_id) formErrors[configJSON.country] = "Country is required";
    if (!telephone.trim())
      formErrors[configJSON.phoneNumber] = "Telephone is required";
    if (!registration_no.trim())
      formErrors[configJSON.registrationNumber] =
        "Registration number is required";
    if (!tin_no) formErrors[configJSON.tinNo] = "TIN number is required";


    this.setState({ formErrors });
    return Object.keys(formErrors).length === 0;
  };

  handleEdit = () => {
    const companyId = this.state.companyId;
    window.localStorage.setItem("companyId", companyId);
    this.props.navigation.history.push(
      "/Settings-General-CompanyListEdit?companyId=" + companyId
    );
  };

  handleBack = () => {
    this.props.navigation.history.goBack();
  };

  showMessage = (message: string) => {
    this.setState({ snackBarMessage: message });
  };

  closeSnackBar = () => {
    this.setState({ snackBarMessage: "" });
  };

  handleValidateDropDown = () => {
    const validateField = (fieldValue:string, errorKey:string, errorMessage:string) => {
      this.setState((prevValue) => ({
        modalError: {
          ...prevValue.modalError,
          [errorKey]: fieldValue.trim() === "" ? errorMessage : null,
        },
      }));
    };
  
    validateField(this.state.modalTitle, "title", "Text is required");
    validateField(this.state.modalOptions[0].title, "option", "Option is required");
  };
  

  handleUserChange = (userContext: IUserContext) => {
    const apiKey = customPermissionApiKey.company;
    const userData = userContext.user?.attributes.permission_groups;
    const value = checkForNewPermissonStatus(apiKey, userData as Array<PermissionGroupArray>);
    this.setState({
      permissionStatus: value
    })
  };

  handleValidateModal = () => {
    const validateField = (fieldValue:string, errorKey:string, errorMessage:string) => {
      this.setState((prevValue) => ({
        modalError: {
          ...prevValue.modalError,
          [errorKey]: fieldValue.trim() === "" ? errorMessage : null,
        },
      }));
      return fieldValue.trim() !== "";
    };
 
    let isValid = true;
  
    if (this.state.modalCategory === "text") {
      isValid = validateField(this.state.modalTitle, "title", "Title is required") && isValid;
      isValid = validateField(this.state.modalText, "text", "Text is required") && isValid;
    } else {
      this.handleValidateDropDown();
      isValid = validateField(this.state.modalTitle, "title", "Title is required") && isValid;
      isValid = validateField(this.state.modalOptions[0].title, "option", "Option is required") && isValid;
    }
  
    return isValid;
  };

  handleDeleteDynamicField = (propertyToDelete:string) => {
    this.setState(prevState => ({
      companyInfo: {
        ...prevState.companyInfo,
        attributes: {
          ...prevState.companyInfo.attributes,
          others: Object.fromEntries(
            Object.entries(prevState.companyInfo.attributes.others)
              .filter(([key]) => key !== propertyToDelete)
          )
        }
      }
    }));
  }

  // Customizable Area End
}
