import React from 'react'
import { Box, Typography, styled, Tooltip } from '@material-ui/core'
import { withStyles, Theme } from '@material-ui/core/styles'
import { formatNumberWithCommas } from '../../blocks/utilities/src/CustomBlockHelpers'

interface BarDataStructure {
    label: string;
    value: number;
}

interface Props {
    BarData: BarDataStructure[];
    Height: string;
    steps?: { formatted: string; raw: number; }[];
    customSubHeader?: string;
}

const CustomBarGraph = (props: Props) => {

    const { BarData, Height, steps, customSubHeader } = props;

    function getColorByIndex(index: number) {
        if (index >= 0 && index < Colors.length) {
            return Colors[index];
        } else {
            let randomColor;
            do {
                randomColor = '#' + Math.floor(Math.random() * 16777215).toString(16);
            } while (randomColor === '#FFFFFF');
            return randomColor;
        }
    }

    function generateSteps(data: BarDataStructure[], numberOfSteps: number) {
        const maxValue = Math.max(...data.map((dataObject) => dataObject.value));
        const bufferedMaxValue = maxValue * 1.1;
        const stepSize = Math.ceil(bufferedMaxValue / numberOfSteps);
        const steps = [];
        for (let i = 1; i <= numberOfSteps; i++) {
            steps.push({
                formatted: formatNumber(i * stepSize),
                raw: i * stepSize
            });
        }
        return steps.reverse();
    }

    function formatNumber(num: number) {
        if (num >= 1e9) {
            return (num / 1e9).toFixed(1) + 'B';
        }
        if (num >= 1e6) {
            return (num / 1e6).toFixed(1) + 'M';
        }
        if (num >= 1e3) {
            return (num / 1e3).toFixed(1) + 'k';
        }
        return num.toString();
    }

    function calculateBarHeight(value: number, maxValue: number, maxHeight: number) {
        const heightPercentage = (value / maxValue) * 100;
        const barHeight = (heightPercentage / 100) * maxHeight;
        return `${Math.max(parseFloat(barHeight.toFixed(2)), 2)}px`;
    }

    function getBarHeight(data: BarDataStructure[]) {
        const correction = 18
        let limitArray = steps || generateSteps(BarData, 5)
        let maxLimit = limitArray[0].raw;
        return data.map((item) => ({
            label: item.label,
            value: item.value,
            height: calculateBarHeight(item.value, maxLimit, (parseInt(Height) - correction))
        }));
    }

    function getLablesVs(data: BarDataStructure[]) {
        return data.map((store) => store.label).join(' vs ')
    }

    const RenderColumnLimit = (limit: string) => {
        return (
            <Box className='columnLimitContainer'>
                <Typography align='right' className='lable-12-400 coolGrey mw40'>{limit}</Typography>
                <Box className='columnLimitLine' />
            </Box>
        )
    }

    const RenderLegends = (lable: string, color: string) => {
        return (
            <Box className='graphLegendsBody'>
                <Box className='LegendIcon' style={{ background: color }} />
                <Typography noWrap className='lable-12-400'>{lable}</Typography>
            </Box>
        )
    }

    return (
        <StyledWrapper>
            {BarData.length > 0 && <Box className='paymentOptionContainerGraph'>
                <Box className='paymentSummaryHeaderContainer'>
                    <Typography className='lable-16-700'>
                        Average Store Revenue
                    </Typography>
                    <Typography className='lable-12-400 coolGrey'>
                        {customSubHeader ? customSubHeader : getLablesVs(BarData)}
                    </Typography>
                </Box>

                <Box className='columnGraphContainer'>
                    {(steps || generateSteps(BarData, 5)).map((data) => (
                        RenderColumnLimit(data.formatted)
                    ))}


                    <Box className='relative'>
                        <Box className='columnLimitContainer'>
                            <Typography align='right' className='lable-12-400 coolGrey mw40'>{"0"}</Typography>
                            <Box className='columnLimitLine' />
                        </Box>

                        <Box className='columnLimitContainer columnBarBase'>
                            <Box className='mw40' />
                            <Box className='graphControlBox'>
                                <Box className='graphScrollBox'>
                                    <Box className='columnHBarContainerBody'>
                                        {getBarHeight(BarData).map((data, index) => (
                                            <CustomTooltip placement="top" title={`${data.label}: SAR ${formatNumberWithCommas(data.value)}`}>
                                                <Box height={data.height} style={{ background: getColorByIndex(index) }} className='columnBar' />
                                            </CustomTooltip>
                                        ))}
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Box>

                </Box>

                <Box className='graphLegendsContainer'>
                    {BarData.map((data, index) => {
                        return (RenderLegends(data.label, getColorByIndex(index)))
                    })}
                </Box>
            </Box>}
            {BarData.length == 0 && <Box className='paymentOptionContainerGraph noDataConatainer'>
                <Typography>No Data</Typography>
            </Box>}
        </StyledWrapper>
    )

}

const StyledWrapper = styled(Box)({
    width: "40%",
    "& .graphScrollBox": {
        position: "absolute",
        width: "100%",
        paddingBottom: "10px",
        overflow: "auto",
        bottom: "-11px",
        "&::-webkit-scrollbar": {
            width: "3px",
            height: "3px"
        },
        "&::-webkit-scrollbar-track": {
            background: "#f1f1f1"
        },
        "&::-webkit-scrollbar-thumb": {
            background: "#888",
            borderRadius: "5px"
        },
        "&::-webkit-scrollbar-thumb:hover": {
            background: "#555"
        }
    },
    "& .graphControlBox": {
        position: "relative",
        width: "100%"
    },
    "& .noDataConatainer": {
        display: "grid",
        placeItems: "center",
        height: "315px"
    },
    "& .barAnnotations": {
        "position": "absolute",
        "bottom": "-15px",
        "color": "#64748B",
        "left": "50%",
        "transform": "translate(-50%, 0)",
        width: "35px",
        "whiteSpace": "nowrap",
        "overflow": "hidden",
        "textOverflow": "ellipsis"
    },
    "& .relative": {
        position: "relative"
    },
    "& .columnHBarContainerBody": {
        width: "fit-content",
        padding: "0 10px",
        boxSizing: "border-box",
        display: "flex",
        justifyContent: "space-around",
        alignItems: "flex-end",
        gap: "35px"
    },
    "& .columnBarBase": {
        position: "absolute",
        width: "100%",
        bottom: "9px"
    },
    "& .mw40": {
        minWidth: "30px"
    },
    "& .columnBar": {
        width: "8px",
        borderRadius: "4px 4px 0px 0px",
        position: "relative"
    },
    "& .columnGraphContainer": {
        display: "flex",
        flexDirection: "column",
        gap: "18px",
        marginTop: "20px"
    },
    "& .columnLimitContainer": {
        display: "flex",
        alignItems: "center",
        gap: "10px"
    },
    "& .columnLimitLine": {
        borderRadius: "1px",
        width: "100%",
        height: "1px",
        background: "#CBD5E1"
    },
    "& .graphLegendsContainer": {
        marginTop: "20px",
        display: "flex",
        gap: "10px",
        overflow: "auto",
        "&::-webkit-scrollbar": {
            width: "3px",
            height: "3px"
        },
        "&::-webkit-scrollbar-track": {
            background: "#f1f1f1"
        },
        "&::-webkit-scrollbar-thumb": {
            background: "#888",
            borderRadius: "5px"
        },
        "&::-webkit-scrollbar-thumb:hover": {
            background: "#555"
        }
    },
    "& .graphLegendsBody": {
        display: "flex",
        gap: "5px",
        alignItems: "center"
    },
    "& .LegendIcon": {
        width: "12px",
        height: "12px",
        borderRadius: "4px"
    },
    "& .paymentOptionContainerGraph": {
        padding: "8px 16px",
        boxSizing: "border-box",
        boxShadow: "0px 2px 8px 0px #00000014",
        width: "100%",
        height: "100%",
        borderRadius: "8px"
    },
    "& .paymentSummaryHeaderContainer": {

    },
    "& .lable-16-700": {
        fontSize: "16px",
        fontFamily: "Montserrat",
        fontWeight: 700
    },
    "& .lable-12-400": {
        fontSize: "12px",
        fontFamily: "Montserrat",
        fontWeight: 400
    },
    "& .coolGrey": {
        color: "#64748B"
    },
})

const Colors = [
    '#EC4E20',
    '#059669',
    '#F59E0B',
    '#7F9CF5',
    '#10B981',
    '#DB2777',
    '#7C3AED',
    '#3B82F6',
    '#6B7280',
    '#4B5563',
    '#FF5733',
    '#C70039',
    '#900C3F',
    '#581845',
    '#1F618D',
    '#76D7C4',
    '#F4D03F',
    '#D35400',
    '#6C3483',
    '#27AE60',
    '#F5B7B1',
    '#5DADE2',
    '#AF7AC5',
    '#52BE80',
    '#F8C471'
];

const CustomTooltip = withStyles((theme: Theme) => ({
    tooltip: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        fontSize: 11,
    },
}))(Tooltip);


export default CustomBarGraph
