import React, { Fragment } from "react";
import {
  // Customizable Area Start
  Box,
  styled,
  Button,
  MenuProps,
  TablePagination,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { StyledTextField } from "../../promocodes/src/PromoCodeCreate.web";

import {
  TableRow,
  TableCell,
  OrderTable,
  RowContainer,
  TableActionMenu,
} from "../../../components/src/OrderTable/src";

import Loader from "../../../components/src/Loader.web";
import PageContainer from "../../navigationmenu/src/PageContainer.web"

import { colors } from "../../utilities/src/Colors";
import FilterIcon from "@material-ui/icons/FilterList";
import FilterPopover from "../../../components/src/FilterPopover";

import { webStyle, TabWrapper } from "../../ordermanagement/src/Orders.web";
import { webStyle as promoCodeWebStyle } from "../../promocodes/src/PromoCodeList.web";
import { renderBaseOnConditions } from "../../../blocks/utilities/src/CustomBlockHelpers";
// Customizable Area End

import HomeCleaningSettingsListController, {
  Props,
  configJSON,
  // Customizable Area Start
  // Customizable Area End
} from "./HomeCleaningSettingsListController.web";

// Customizable Area Start
const IconSortingUp = require("../../../components/src/assets/sort/sort top.png");
const IconSortingNormal = require("../../../components/src/assets/sort/sort.png");
const IconSortingDown = require("../../../components/src/assets/sort/sort down.png");
const Strings = configJSON.Strings.SettingsList;

export const CustomMenuProps: Partial<MenuProps> = {
  PaperProps: {
    style: { maxHeight: 250 },
  },
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left",
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "left",
  },
  getContentAnchorEl: null,
};
// Customizable Area End
export default class HomeOrders extends HomeCleaningSettingsListController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.state = {
      page: 1,
      loading: true,
      filterQuery: "",
      selectedRegionId: null,
      filters: [
        {
          title: "Region",
          type: "autocompolete",
          apiKey: "region_name",
          options: [],
        },
        {
          title: "Amount",
          type: "custom",
          apiKey: "amount",
          onClear: this.handleClearFilter.bind(this),
          customRender: this.handelCustomRender.bind(this),
        },
        { title: "Slots", type: "autocompolete", apiKey: "slot" },
        {
          type: "select",
          title: "Status",
          apiKey: "status",
          options: [
            { label: "Activate", value: "active" },
            { label: "Deactivate", value: "deactive" },
          ],
        },
      ],
      searchText: "",
      sortingQuery: "",
      settingsList: [],
      filterAnchor: null,
      isDrawerOpen: false,
      settingsMetaData: {},
      sortingData: {
        active: "",
        region_name: "",
        open_slots_per_day: "",
        minimum_order_amount: "",
        maximum_order_amount: "",
      },
      permissionStatus: {
        mainPermission: false,
        createPermission: false,
        viewPermission: false,
        editPermission: false,
        deactivatePermission: false
      }
    };
    // Customizable Area End
  }

  // Customizable Area Start

  getSortingKeys(index: number) {
    return [
      "region_name",
      "minimum_order_amount",
      "maximum_order_amount",
      "open_slots_per_day",
      "active",
    ][index];
  }

  onChangeAmount(type: string, amount: string) {
    const array = this.state.filters.map((item) => {
      if (item.apiKey !== "amount") return item;

      if (!Array.isArray(item.value)) item.value = ["", ""];
      if (type === "min") item.value = [amount, item.value[1]];
      if (type === "max") item.value = [item.value[0], amount];

      return item;
    });

    this.setState({ filters: array });
  }

  handelCustomRender() {
    const { filters } = this.state;
    const amountFilter = filters.find((item) => item.apiKey === "amount");
    const [min, max] = amountFilter?.value || [null, null];
    return (
      <FilterContainer>
        <div>
          <label>Minimum Amount</label>
          <StyledTextField
            value={min}
            variant='outlined'
            placeholder='Enter Amount'
            onChange={(e: React.ChangeEvent<{ value: unknown }>) =>
              this.onChangeAmount("min", e.target.value as string)
            }
          />
        </div>
        <div>
          <label>Maximum Amount</label>
          <StyledTextField
            value={max}
            variant='outlined'
            placeholder='Enter Amount'
            onChange={(e: React.ChangeEvent<{ value: unknown }>) =>
              this.onChangeAmount("max", e.target.value as string)
            }
          />
        </div>
      </FilterContainer>
    );
  }

  getColTypes(index: number) {
    if (index === 0) return "left";
    else if (index === 4) return "right";
    else return "middle";
  }

  // Customizable Area End

  render() {
    // Customizable Area Start
    const {
      page,
      filters,
      loading,
      sortingData,
      isDrawerOpen,
      settingsList,
      filterAnchor,
      selectedRegionId,
      settingsMetaData,
      permissionStatus
    } = this.state;

    return (
      <Box style={webStyle.mainWrapperOrder}>
        <Loader loading={loading} />
        <PageContainer
          navigation={this.props.navigation}
          onUserChange={this.handleUserChange.bind(this)}
          onSearchText={(value) => this.handleSearch(value)}
          onOpenedChanged={(open) => this.handleDrawerChange(open)}
        >
          <FixStyleContainer>
          <MainBoxWrapper>
            <Box>
              <div>
                <TabWrapper className='static'>
                  <div className='pageHeaderOrder w-100'>
                    <div className='fix-heading'>
                      <div className="titleHeading">{Strings.title}</div>
                      <div className='fgrow1' />
                      <div>
                        <div style={webStyle.headerButtonPartAccountOrder}>
                          <div
                            data-test-id='filterButton'
                            onClick={this.handleClickFilter.bind(this)}
                            className="filter-box"
                          >
                            <FilterIcon />
                          </div>
                          <FilterPopover
                            filters={filters}
                            anchor={filterAnchor as HTMLDivElement}
                            onClose={this.handleCloseFilter.bind(this)}
                            onAutoCompleteChange={(
                              title: string,
                              value: string
                            ) => {
                              this.handleRegionNameFilterSuggestion(
                                title,
                                value
                              );
                            }}
                            onClearFilter={() => this.handleClearFilter(true)}
                            onFilterChange={this.handleFilterChange.bind(this)}
                          />
                        </div>
                      </div>
                      <div>
                        {
                          renderBaseOnConditions(
                            permissionStatus.createPermission,
                            <Button
                              onClick={() => this.handleTableActions("Add")}
                              data-test-id='addSetting'
                              className="addButton"
                            >
                              {Strings.addSetting}
                            </Button>,
                            <></>
                          )
                        }
                      </div>
                    </div>
                  </div>
                </TabWrapper>

                <OrderTable>
                  <RowContainer header>
                    <TableRow
                      className='table-cols'
                      columns={"220px 270px 270px 230px 1fr 40px"}
                      cols1024={configJSON.cols1024}
                      cols1280={configJSON.cols1280}
                      cols1366={configJSON.cols1366}
                      >
                      {[
                        Strings.tableHeaders.region,
                        Strings.tableHeaders.minOrderAmt,
                        Strings.tableHeaders.maxOrderAmt,
                        Strings.tableHeaders.openSlots,
                        Strings.tableHeaders.status,
                        "",
                      ].map((heading: string, index: number) => {
                        return (
                          <TableCell
                            header
                            key={heading}
                            sortingIcons={[
                              IconSortingNormal,
                              IconSortingUp,
                              IconSortingDown,
                            ]}
                            sortingData={sortingData}
                            className='align-item-center'
                            content={heading as React.ReactNode}
                            sortingKey={this.getSortingKeys(index)}
                            onChange={this.handelSortingData.bind(this)}
                            onQueryChange={this.handleSortingQuery.bind(this)}
                          />
                        );
                      })}
                    </TableRow>
                  </RowContainer>
                  <RowContainer>
                    {settingsList.length === 0 && (
                      <TableRow className='table-rows' columns={"1fr"}>
                        <TableCell
                          content={
                            <div className='no-records-found'>
                              {Strings.noRecordsFound}
                            </div>
                          }
                          type={"none"}
                        />
                      </TableRow>
                    )}
                    {settingsList.map((item) => {
                      const {
                        id,
                        active,
                        region_name,
                        open_slots_per_day,
                        minimum_order_amount,
                        maximum_order_amount,
                      } = item?.attributes || {};

                      const isRegionManager = selectedRegionId != null;
                      const isSameRegion =
                        Number(selectedRegionId) ===
                        Number(item?.attributes?.region_id);

                      return (
                        <Fragment key={"order-list-item-" + id}>
                          <TableRow
                            className='table-rows'
                            columns={"220px 270px 230px 270px 1fr 40px"}
                            cols1024={configJSON.cols1024}
                            cols1280={configJSON.cols1280}
                            cols1366={configJSON.cols1366}
                            >
                            {[
                              region_name,
                              `SAR ${minimum_order_amount}`,
                              `SAR ${maximum_order_amount}`,
                              open_slots_per_day,
                              active ? "Activate" : "Deactivated",
                            ].map((heading: unknown, index: number) => (
                              <TableCell
                                key={
                                  "order-list-item-" +
                                  id +
                                  "-table-cell-" +
                                  index
                                }
                                content={heading as React.ReactNode}
                                type={this.getColTypes(index)}
                              />
                            ))}
                            <TableCell
                              content={
                                !isRegionManager ? (
                                  <TableActionMenu
                                    item={{ id }}                                    
                                    color='rgba(0, 0, 0, 0.54)'
                                    options={[
                                      permissionStatus.viewPermission && Strings.view,
                                      permissionStatus.editPermission && Strings.edit,
                                      permissionStatus.deactivatePermission && (!active
                                        ? Strings.activate
                                        : Strings.deactivate),
                                    ]}
                                    menuItemStyle={{ fontSize: 14 }}
                                    getSelectedOption={(action: string) =>
                                      this.handleTableActions(
                                        action,
                                        Number(id)
                                      )
                                    }
                                  />
                                ) : (
                                  isSameRegion && (
                                    <TableActionMenu
                                      item={{ id }}
                                      color='rgba(0, 0, 0, 0.54)'
                                      options={[
                                        permissionStatus.viewPermission && Strings.view,
                                        permissionStatus.editPermission &&  Strings.edit,
                                        permissionStatus.deactivatePermission && (!active
                                          ? Strings.activate
                                          : Strings.deactivate),
                                      ]}
                                      menuItemStyle={{ fontSize: 14 }}
                                      getSelectedOption={(action: string) =>
                                        this.handleTableActions(
                                          action,
                                          Number(id)
                                        )
                                      }
                                    />
                                  )
                                )
                              }
                              type={"none"}
                            />
                          </TableRow>
                        </Fragment>
                      );
                    })}
                  </RowContainer>
                </OrderTable>

                <TablePagination
                  count={Number(settingsMetaData?.total_count)}
                  page={page - 1}
                  data-test-id='pagination'
                  rowsPerPageOptions={[10]}
                  rowsPerPage={10}
                  component='div'
                  onPageChange={(
                    _: React.MouseEvent<HTMLElement> | null,
                    page: number
                  ) => this.handlePageChange(page)}
                />
              </div>
            </Box>
          </MainBoxWrapper>
          </FixStyleContainer>
        </PageContainer>
      </Box>
    );
    // Customizable Area End
  }
}

// Customizable Area Start

const FilterContainer = styled("div")({
  width: 200,
  margin: "0px 24px",
  "& label": {
    fontSize: 14,
    display: "block",
    fontWeight: 600,
    paddingBottom: 4,
  },
  "& > div:first-child": {
    paddingBottom: 16,
  },
});

const FixStyleContainer = styled("div")({
  paddingBottom: 32,
  "& .filter-box": {
    borderColor: colors().cyancobaltblue + " !important",
    cursor: "pointer",
    background: colors().white,
    height: "56px",
    color:  colors().darkliver,
    width: "56px",
    fontSize: "24px",
    borderRadius: "8px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid",
    "@media only screen and (max-width: 1024px)": {
      fontSize: "16px",
      width: "44px",
      height: "44px",
    },
  },
  "& .no-records-found": {
    width: "100%",
    fontWeight: "bold",
    textAlign: "center",
  },
  "& .table-sorting-icons": {
    width: 24,
    height: 24,
  },
  "& .w-100": {
    width: "100%",
  },
  "& .static": {
    position: "static",
  },
  "& #long-button": {
    padding: 0,
  },
  "& .fix-main-container": {
    marginLeft: 48,
  },
  "& .fix-heading": {
    alignItems: "center",
    marginLeft: "auto",
    display: "flex",
    gap: 24,
  },
  "& .titleHeading": {
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "24px",
    lineHeight: "29px",
    color:colors().black,
    "@media only screen and (max-width: 1024px)": {
      fontSize: "16px",
    },
  },
  "& .fgrow1": {
    flexGrow: 1,
  },
  "& .mw-180": {
    minWidth: 180,
  },
  "& .table-cols > div": {
    fontSize: 18,
  },
  "& .table-rows > div:last-child": {
    position: "relative",
  },
  "& .table-rows > div": {
    textAlign: "left",
    textTransform: "capitalize",
    fontWeight: "500 !important",
  },
  "& .rm-weight .MuiSelect-root": {
    fontWeight: "normal !important",
  },
  "& .print-icon": {
    right: 0,
    bottom: 0,
    width: 26,
    margin: 16,
    cursor: "pointer",
    textAlign: "right",
    position: "absolute",
  },
  "& .addButton": {
    background: colors().cyancobaltblue,
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: "24px",
    color: colors().white,
    height: "56px",
    width: "184px",
    borderRadius: "8px",
    textTransform: "unset",
    marginLeft: "24px",
    "@media only screen and (max-width: 1024px)": {
      fontSize: "13px",
      width: "160px",
      height: "44px",
    },
  },
});
const MainBoxWrapper = styled(Box)({
  marginTop:'20px',
});
// Customizable Area End
