import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import { IBlock } from "../../../framework/src/IBlock";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { makeApiMessage } from "../../../components/src/common";
import { IFormData } from "./HomeCleaningSettingsController.web";
import {
  INavigateTo,
  navigateTo,
  PermissionStatus,
  checkForPermissonStatus,
  customPermissionApiKey
} from "../../utilities/src/CustomBlockHelpers";
import { IUserContext } from "../../../blocks/navigationmenu/src/PageContainerController.web";
import { PermissionGroupArray } from "../../../blocks/navigationmenu/src/utils";

interface IRegion {
  id?: number;
  name: string;
}

// Customizable Area End

export interface S {
  // Customizable Area Start
  editId: string;
  region: IRegion;
  homeCleaningSettingsDetails: IFormData;
  permissionStatus: PermissionStatus;
  // Customizable Area End
}
export interface Props {
  id: string;
  // Customizable Area Start
  navigation: {
    navigate: Function;
    getParam: Function;
  };
  // Customizable Area End
}
interface SS {
  id: string;
}

export const configJSON = require("./config");

export default class HomeCleaningSettingsViewController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  settingSaveMessageId: string = "";
  settingShowMessageId: string = "";
  // Customizable Area End

  state = {
    // Customizable Area Start
    editId: "",
    region: {
      id: undefined,
      name: "",
    },
    homeCleaningSettingsDetails: {
      data: {
        attributes: {
          id: 0,
          add_by: "",
          region_id: "",
          region_name: "",
          open_slots_per_day: "",
          non_refundable_type: "",
          minimum_order_amount: "",
          maximum_order_amount: "",
          home_cleaning_service_charges: "",
          min_non_refundable_amount_to_pay: "",
          min_amount_to_waive_of_service_charge: "",
          close_slot: false
        },
      },
    },
    permissionStatus: {
      mainPermission: false,
      createPermission: false,
      viewPermission: false,
      editPermission: false,
      deactivatePermission: false
    }
    // Customizable Area End
  };

  constructor(props: Props) {
    super(props);
    this.receive.bind(this);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  receive(from: string, message: Message): void {
    const requestId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const successResponse = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    // Customizable Area Start
    this.handleShowResponse(requestId, successResponse);
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    const id = this.props.navigation.getParam("navigationBarTitleText");
    this.getCleaningShowData(id);
    // Customizable Area End
  }

  // Customizable Area Start

  getCleaningShowData(id?: string) {
    if (!id) return;

    const message = makeApiMessage({
        url: configJSON.homeCleaningSettingsFormApi + "/" + id,
        method: "GET",
    });

    this.settingShowMessageId = message.messageId;
    runEngine.sendMessage(message.id, message);
  }

  handleShowResponse(requestId: string, response: IFormData) {
    if (requestId === this.settingShowMessageId) {
      if (response?.data) {
        this.setState({ homeCleaningSettingsDetails: response });
      }
    }
  }

  handleBack() {
    navigateTo({
      screenName: "HomeCleaningSettingsList",
      props: this.props,
    })
  }
  
  handleEdit(getId: number) {
    // this.props.navigation.navigate("HomeCleaningSettingsEdit", { id: getId })
    console.log("getId", getId)
    navigateTo({
      id: String(getId),
      props: this.props,
      screenName: "HomeCleaningSettingsEdit",
    } as INavigateTo);
  }

  handleUserChange = (userContext: IUserContext) => {
    const apiKey = customPermissionApiKey.homeCleanSettingPermissions;
    const userData = userContext.user?.attributes.permission_groups;
    const value = checkForPermissonStatus(apiKey, userData as Array<PermissionGroupArray>);
    this.setState({
      permissionStatus: value
    })
  };
  // Customizable Area End
}
