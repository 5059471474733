// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area End
export const configJSON = require("./config");

export interface Props {
    navigation?: any;
    id?: string;
    // Customizable Area Start

    // Customizable Area End
}

interface S {
    // Customizable Area Start
    options: Options[];
    optionSelected: string;
    isOpen: boolean;
    isChecked: boolean;
    defaultIcon: string;
    iconId: number | string;
    preference_first_name: string;
    preference_second_name: string;
    showError: boolean;
    openSnackbar: boolean;
    message: string;
    successMessage: string;
    severity: string;
    isLoading: boolean;
    iconNextPage: number;
    currentOptions: Options[];
    iconName : string;
    searchQuery:string;
    imageNextPage:number;
    currentIconData:Options[];
    selectedImage:{id:string | number,option:string,image:string}
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    id: string | number;

    // Customizable Area End
}
// Customizable Area Start
export interface Options {
    id: number | string,
    attributes: {
        name: string;
        id: number,
        label: string,
        image: string 
    }
}
// Customizable Area End

// Customizable Area Start
export default class AddPreferenceController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    iconListDataCallId: string = "";
    createPreferenceCallId: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.CountryCodeMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.ReciveUserCredentials),
        ];

        this.state = {
            options: [],
            optionSelected: '',
            searchQuery:'',
            iconName:'',
            isOpen: false,
            isChecked: false,
            defaultIcon: configJSON.defaultIcon,
            iconId: "",
            preference_first_name: "",
            preference_second_name: "",
            showError: false,
            openSnackbar: false,
            message: "",
            successMessage: "",
            severity: "",
            isLoading: false,
            iconNextPage: 1,
            currentOptions: [],
            imageNextPage:1,
            currentIconData:[] as Options[],
            selectedImage:{} as {id:string | number,option:string,image:string}
        };
        // Customizable Area End

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    componentDidMount(): any {
        // Customizable Area Start
        this.getIconsListData()
        // Customizable Area End
    }
    // Customizable Area Start

    dropDownToggle = () => {
        this.setState((prevState) => ({
            isOpen: !prevState.isOpen,
        }));
    };
    handleClose = () => {
        this.setState({ openSnackbar: false });
    }
    handleCancelFunc = () => {
        this.props.navigation.history.push("/Products-PreferenceList")
    }
    handleSelectOption = (option: Options) => {
        this.setState({
            iconId: option.attributes.id,
            optionSelected: option.attributes.image === null ? this.state.defaultIcon : option.attributes.image ,
            iconName : option.attributes.name,
            isOpen: false,
            searchQuery:''
        });
    };
    handleSearchChange = (event:React.ChangeEvent<HTMLInputElement>) => {
        this.setState({searchQuery: event.target.value.toLowerCase(),isOpen:true})
    }
    handleFirstNamePreferenceChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            preference_first_name: event.target.value,
            showError: false,
            isOpen:false
        });
    };
    handleSecondNamePreferenceChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            preference_second_name: event.target.value,
            showError: false,
            isOpen:false
        });
    };

    checkFormErrors = () => {
      const { preference_first_name } = this.state;
      return preference_first_name !== ''
    }

    handleClick = () => {
        const { preference_first_name } = this.state;
        if (preference_first_name === '') {
            this.setState({ showError: true });
        } else {
            this.setState({ showError: false });
        }
        if (this.checkFormErrors()) {
            this.setState({isLoading: true})
            this.createPreference()
        }
    };

    getIconsListData = () => {
        const headers = {
            "Content-Type": configJSON.categoryApiContentType,
            token: window.localStorage.getItem(configJSON.token)
        };

        const iconMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.iconListDataCallId = iconMessage.messageId;

        iconMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.iconListApiEndPoint + `?page_no=${this.state.iconNextPage}`
        );

        iconMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        iconMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.httpGetType
        );

        runEngine.sendMessage(iconMessage.id, iconMessage);
    }
    createPreference = () => {

        let headers = {
            "Content-Type": configJSON.categoryApiContentType,
            token: window.localStorage.getItem(configJSON.token)
        };
        let httpBody = {}
        httpBody = {

            preference_first_name: this.state.preference_first_name,
            preference_second_name: this.state.preference_second_name,
            icon_id: this.state.iconId

        }
        const createPreferenceDataMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.createPreferenceCallId = createPreferenceDataMessage.messageId;

        createPreferenceDataMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.createPreferenceApiEndPoint
        );

        createPreferenceDataMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        createPreferenceDataMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );
        createPreferenceDataMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.httpPostType
        );

        runEngine.sendMessage(createPreferenceDataMessage.id, createPreferenceDataMessage);

    }
    handleResponseForIconsDataList = (from: string, message: Message) => {
        if (this.iconListDataCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
            const apiResponse = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (apiResponse.data) {
                const existingOption = this.state.options;
                const NewOptions = [...existingOption, ...apiResponse.data]
                this.setState({
                    options: NewOptions,
                    currentOptions: apiResponse.data 
                })
            }
        }
    }
    handleResponseForCreatePreference = (from: string, message: Message) => {
        if (this.createPreferenceCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
            const apiResponse = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (apiResponse.data) {
                this.setState({
                    openSnackbar: true,
                    message: configJSON.snackbarMessage,
                    severity: configJSON.severitySuccess
                })
                setTimeout(() => {
                    this.props.navigation.history.push("/Products-PreferenceList")
                }, 1000)

            } else {
                this.setState({
                    isLoading: false,
                    openSnackbar: true,
                    message: apiResponse?.errors[0],
                    severity: configJSON.typeError

                })
            }
        }
    }

    handleScroll = (event: React.UIEvent<HTMLUListElement, UIEvent>) => {
        const target = event.target as HTMLElement;
        if (target.scrollHeight - target.scrollTop === target.clientHeight && this.state.currentOptions.length !== 0 ) {
            this.setState({iconNextPage: this.state.iconNextPage + 1},
                () => this.getIconsListData()
            )
        }
    };

    handleGetImageOnScroll = (event: React.SyntheticEvent) => {
        const listboxNode = event.currentTarget;
        const position = listboxNode.scrollTop + listboxNode.clientHeight;
        
        if (listboxNode.scrollHeight - position <= 1.30 && this.state.currentOptions.length !== 0) {
          this.setState({ iconNextPage: this.state.iconNextPage + 1 }, () => {
            event.preventDefault();
            this.getIconsListData()
          })
        }
      };
      handleSelectImage = (value:{ id: string | number, option: string, image: string }) => {
        this.setState({
          selectedImage:value,
          iconId:value.id,
          optionSelected: value.image === null ? this.state.defaultIcon : value.image ,
        })
      }
      getIconList = (options:Options[])=>{
        return options.map((option)=>{
            return {id:option.attributes.id,image:option.attributes.image,option:option.attributes.name}
        })
    
    }

    // Customizable Area End
    async receive(from: string, message: Message) {
        // Customizable Area Start
        this.handleResponseForIconsDataList(from, message)
        this.handleResponseForCreatePreference(from, message)
        // Customizable Area End
    }

}
// Customizable Area End
