Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.apiContentType = "application/json"
exports.endPoint = "notifications/notifications";
exports.getDataMethod = "GET";
exports.markAsReadMethod = "PUT";
exports.deleteMethod = "DELETE";
exports.patchMethod = "PATCH";

exports.markAllAsReadApiEndpoint = "/bx_block_notifications/notifications/mark_all_as_read";
exports.getNotificationApiEndpoint = "/bx_block_notifications/notifications";

exports.test = "test";
exports.notifications = "Notifications";
exports.deleteText = "DELETE"
exports.okText = "OK"
exports.deleteMessage = "Notifications deleted!"
exports.viewOrderText = "View Order"
exports.noNotificationText = "No Notification found"
exports.notificatinosText = "Notifications"
exports.markAllAsReadText = "Mark all as read"
exports.goToAdjustmentTxt = "Go to Adjustments";
exports.orderAdjustmentTxt = "Order Adjustment"
exports.downloadTxt = "Download"
// Customizable Area End