Object.defineProperty(exports, '__esModule', {
  value: true
})

// Customizable Area Start
exports.httpGetMethod = "GET";
exports.httpPostMethod = "POST";
exports.advancedsearchApiContentType = "application/json";
exports.getAdvancedSearchApiEndPoint = "advanced_search/search";
exports.errorTitle = "Error";

exports.searchOrderApiEndPoint = "/bx_block_order_management/orders/search";
exports.getOrderApi = "bx_block_order_management/orders/";
exports.getAllPaymentMethodEndPoint = "/bx_block_settings/payment_methods";
exports.getAllBusinessListEndPoint =
  "/bx_block_drop_down/drop_down?module_name=Business";
exports.getCitiesListEndPoint = "/bx_block_city/cities";
exports.getStoreListEndPoint =
  "/bx_block_store_management/store_managements?dropdown=true";
  exports.testing="testing"
exports.testPhase="testPhase"

exports.Strings = {
  reset: "Reset",
  search: "Search",
  advanceSearch: "Advance Search",
  details: "Details",
  searchResult: "Search Result",
  plantCleaningVal: "Plant Cleaning",
  carpetFurniturePlantVal: "Carpet and furniture (plant)",
  carpetFurnitureHomecleanVal: "Carpet and furniture (home cleaning)"
};

exports.noDataMessageVal = "No records found!";
exports.dataFound = "Data Found"
exports.requiredField = "Choose a date or date range"
exports.pickAdate= "Pick a date"
exports.text = "text";
exports.input = "placeholder"
exports.right = "right"
exports.advancedFilter = "More Filters"
exports.advancedType = "Advanced Type"
// Customizable Area End
