import React from "react";
import { styled, StyledComponentProps } from "@material-ui/core";

interface Props {
  columns: string;
  style?: React.CSSProperties;
  children: React.ReactNode;
  className?: string;
  cols1024?: string;
  cols1280?: string;
  cols1366?: string;
}

interface RowProps {
  columns: string;
  cols1024?: string;
  cols1280?: string;
  cols1366?: string;
}

function TableRow(props: Props) {
  const { columns, style, cols1024,cols1280,cols1366, ...rest } = props;
  return (
    <Row
      columns={columns}
      cols1024={cols1024}
      cols1280={cols1280}
      cols1366={cols1366}
      className='tableRow'
      style={{ ...style }}
      {...rest}
    >
      {props.children}
    </Row>
  );
}

const Row = styled('div')((props: StyledComponentProps & RowProps) => ({
  display: "grid",
  marginBottom: 16,
  alignItems: "stretch",
  gridTemplateColumns: props.columns,
  "@media only screen and (max-width: 1024px)": {
    gridTemplateColumns: props.cols1024,
  },
  "@media only screen and (min-width: 1280px)": {
    gridTemplateColumns: props.cols1280,
  },
  "@media only screen and (min-width: 1366px)": {
    gridTemplateColumns: props.cols1366,
  },
"&.sticky": {
  position:"fixed",
  width:"80%"
}
}));

export default TableRow;
