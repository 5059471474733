import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  TextField,
  TableContainer,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  TablePagination,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";
import HelpdeskReportingController, {
  Props,
  configJSON,
  HelpdeskReportingAttribute,
} from "./HelpdeskReportingController.web";
const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
// Customizable Area End

export default class HelpdeskReporting extends HelpdeskReportingController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Container maxWidth={"sm"}>
          <Box sx={webStyle.mainWrapper}>
            <Typography variant="h6">{configJSON.labelTitleText}</Typography>
            <Typography variant="subtitle1" component="div">
              {configJSON.labelBodyText}
            </Typography>

            {this.state.showListData &&
            this.state.helpDeskReportData.length > 0 ? (
              <>
                <TableContainer>
                  <Table aria-label="Ticket Table">
                    <TableHead>
                      <TableRow>
                        <TableCell align="right">Ticket ID</TableCell>
                        <TableCell align="right">Ticket Name</TableCell>
                        <TableCell align="right">Ticket Email&nbsp;</TableCell>
                        <TableCell align="right">Ticket Status&nbsp;</TableCell>
                        <TableCell align="right">
                          Ticket Description&nbsp;
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody className="ticket-list-data">
                      {Object.keys(this.state.helpDeskReportData)
                        .slice(
                          this.state.page * this.state.rowsPerPage,
                          this.state.page * this.state.rowsPerPage +
                            this.state.rowsPerPage
                        )
                        .map((ListItem: string) => {
                          const dataKey =
                            typeof ListItem === "string"
                              ? parseInt(ListItem)
                              : ListItem;
                          const listData: HelpdeskReportingAttribute =
                            this.state.helpDeskReportData[dataKey];
                          return (
                            <>
                              <TableRow key={listData.id}>
                                <TableCell component="th" scope="row">
                                  {listData.id}
                                </TableCell>
                                <TableCell align="right">
                                  {listData.attributes.title}
                                </TableCell>
                                <TableCell align="right">
                                  {listData.attributes.user_email}
                                </TableCell>
                                <TableCell align="right">
                                  {listData.attributes.description}
                                </TableCell>
                                <TableCell align="right">
                                  {listData.attributes.status}
                                </TableCell>
                              </TableRow>
                            </>
                          );
                        })}
                    </TableBody>
                  </Table>
                  {
                    <TablePagination
                      rowsPerPageOptions={[]}
                      component="div"
                      count={this.state.helpDeskReportData.length}
                      rowsPerPage={10}
                      page={this.state.page}
                      onPageChange={this.handleChange}
                    />
                  }
                </TableContainer>
              </>
            ) : (
              ""
            )}

            <Box
              data-test-id="btnshow"
              component="button"
              sx={webStyle.buttonStyle}
              onClick={this.showHideForm}
              color={"primary"}
            >
              {configJSON.btnTitle}
            </Box>

            {this.state.showForm ? (
              <Box
                component="form"
                data-test-id="ticketForm"
                className="ticketFormMain"
                onSubmit={(ticketForm) => this.doCreateTicket(ticketForm)}
              >
                <TextField
                  id="filled"
                  type={"text"}
                  name="ticketName"
                  value={this.state.ticketName}
                  data-test-id="ticketName"
                  label={configJSON.labelTicketName}
                  placeholder={configJSON.labelTicketName}
                  fullWidth={true}
                  onChange={this.saveTicketName}
                  error={this.state.errorFlag}
                  helperText={this.state.ticketErrorMessage}
                  variant="filled"
                />

                <TextField
                  id="filled"
                  type={"email"}
                  name="ticketEmail"
                  value={this.state.ticketEmail}
                  data-test-id="ticketEmail"
                  label={configJSON.labelEmail}
                  placeholder={configJSON.labelEmail}
                  fullWidth={true}
                  onChange={this.saveEmail}
                  error={this.state.errorEmailFlag}
                  helperText={this.state.ticketErrorEmailMessage}
                  variant="filled"
                />

                <TextField
                  id="filled"
                  label={configJSON.labelDesc}
                  data-test-id="ticketDesc"
                  name="ticketDesc"
                  value={this.state.ticketDesc}
                  placeholder={configJSON.labelDesc}
                  multiline
                  minRows={14}
                  maxRows={6}
                  fullWidth={true}
                  variant="filled"
                  onChange={this.saveTicketDesc}
                  error={this.state.errorDescFlag}
                  helperText={this.state.ticketDescErrorMessage}
                />

                <Box
                  data-test-id="ticketButton"
                  className="ticketButton"
                  id="ticketButton"
                  component="button"
                  sx={webStyle.buttonStyle}
                  color={"primary"}
                >
                  {configJSON.btnTicket}
                </Box>
                <h6>{this.state.ticketCreationMessage}</h6>
              </Box>
            ) : (
              ""
            )}
          </Box>
        </Container>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  inputStyle: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
  },
};
// Customizable Area End
