import React from "react";
import {styled} from "@material-ui/core"
import { checkedCheckbox, checkbox } from "./assets";
import { colors } from "../../../blocks/utilities/src/Colors";

type CheckboxValues = {
    hf: boolean;
    sp: boolean;
    fh: boolean;
}

type CheckboxFieldNames = {
    hf: string;
    sp: string;
    fh: string;
}

const CHECKBOXES = ["hf", "sp", "fh"] as const

type Props = {
    value: CheckboxValues
    fieldNames: CheckboxFieldNames
    onChange: (fieldName: string, fieldValue: boolean) => void
    disabled?: boolean 
}


export const PresentationCheckboxes = (props: Props) => {
    const {value, fieldNames, onChange, disabled} = props
    return (
        <StyledWrapper>
            <p>Presentation: </p>
            <div className="checkboxes">
                {
                    CHECKBOXES.map(pref => {
                        const isChecked = value[pref]
                        const imgSrc = isChecked ? checkedCheckbox : checkbox
                        return (
                        <div className={"checkbox-item" + (isChecked ? " checked" : "")} key={pref}>
                            <img 
                                src={imgSrc} 
                                onClick={() => {
                                    if (disabled) return;
                                    onChange(fieldNames[pref], !isChecked)
                                }}
                            />
                            <p>{pref}</p>
                        </div>
                        )
                    })
                }
            </div>
        </StyledWrapper>
    )
}

const StyledWrapper = styled("div")({
    display: "flex",
    alignItems: "center",
    gap: 24,
    fontSize: 16,
    "& > p" : {
        fontWeight: 600,
        color: colors().charcoal
    },
    "& .checkboxes": {
        display: "grid",
        gridTemplateColumns: "0.33fr 0.33fr 0.33fr",
        flex: 1,
        "& .checkbox-item": {
            display: "flex",
            alignItems: "center",
            gap: 20,
            "&.checked": {
                "& > p" : {
                    color: colors().cyancobaltblue
                }
            },
            "& > p" : {
                fontWeight: 500,
                textTransform: "uppercase"
            },
            "@media only screen and (max-width: 1200px)": {
                gap: 6,
              },
        }
    }
})