import React from "react";
// Customizable Area Start

import { Button } from "@material-ui/core";

class CostomButton extends React.Component<any> {
    render() {
        const { ...buttonProps }: any = this.props;
        return <Button {...buttonProps} />;
    }
}
export default CostomButton
// Customizable Area End