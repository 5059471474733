import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { CustomEnums, getCustomEnumName } from '../../utilities/src/CustomBlockHelpers'
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loadingCSV: boolean;
  loadingJson: boolean;
  token: string;
  modalClose:boolean;
  uploadedFile: File | null;
  setLoaded: number;
  errorSnackbarOpen: boolean;
  errorMessage: string;
  importButtonLoading: boolean;
  isFileUploadError: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ImportExportDataController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  jsonDataApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),

      getCustomEnumName(CustomEnums.ImportExportPopupFileMessage),
      getCustomEnumName(CustomEnums.ImportExportAPIResponse),
      getCustomEnumName(CustomEnums.ImportExportPopupFileMessagetest),
      getCustomEnumName(CustomEnums.ImportExportPopupClose),
      getCustomEnumName(CustomEnums.ImportAPIDoneRepsonseMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      loadingCSV: false,
      loadingJson: false,
      isFileUploadError:false,
      token: "",
      modalClose: true,
      uploadedFile: null,
      setLoaded: 0,
      errorSnackbarOpen: false,
      errorMessage: '',
      importButtonLoading: false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    if (from === getCustomEnumName(CustomEnums.ImportExportPopupFileMessage)) {
      console.log("checkvalue", message.properties.returnValue)
      this.setState({ setLoaded: message.properties.returnValue.setLoaded, uploadedFile: message.properties.returnValue.file })
    }
    
    if (from === getCustomEnumName(CustomEnums.ImportExportPopupClose)) {
      this.props.navigation.navigate(message.properties.ParentpageRoute)
    }

    this.handleImportActions(from, message);
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
  }

  handleImportActions(from: string, message: Message) {
    if(from === getCustomEnumName(CustomEnums.ImportAPIDoneRepsonseMessage)) {
      this.setState({importButtonLoading: false})
    }    
  }
 
  handleClearFile = () => {
    this.setState({
      uploadedFile: null,
      setLoaded: 0
    })
    const message = new Message(getCustomEnumName(CustomEnums.ImportExportClearFileMessage))
    message.addData('clearFileEvent', { uploadedFile: null, setLoaded: 0 })
    runEngine.sendMessage(message.id, message)
  }

  handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({isFileUploadError:false})
    
    const file = event.target.files?.[0];
    if (file) {
      const validFormats = [
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "application/vnd.ms-excel",
      ];
      
      if (!validFormats.includes(file.type)) {
        this.setState({isFileUploadError:true})
        return;
      }
      const message = new Message(getCustomEnumName(CustomEnums.ImportExportPopupFileUploadMessage));
      message.addData('fileEvent', event);
      runEngine.sendMessage(message.id, message);
    }
  }

  handleDoneClick = () => {
    this.setState({importButtonLoading: true})
    const message = new Message(getCustomEnumName(CustomEnums.ImportExportPopupDoneMessage))
    message.addData('doneButtonClickEvent', 'Done Button Clicked')
    runEngine.sendMessage(message.id, message)
  }
  
  handleSnackbarClose = () => {
    this.setState({ errorSnackbarOpen: false })
  }
  
  handleModalClose = (routeName: string) => {
    const message = new Message(getCustomEnumName(CustomEnums.ImportExportPopupCloseButtonClicked))
    message.addData('closeButtonClicked', 'Close Button Clicked')
    runEngine.sendMessage(message.id, message)
  }
  // Customizable Area End
}
