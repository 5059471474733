import React, { forwardRef, useEffect, useRef, useState } from "react";
import { InputField } from "../customComponents/CustomTags.web";
import { StyledCheckBox } from "../CustomTheme.web";
import { makeStyles } from "@material-ui/core/styles";
import { SelectAllButton } from "../../../blocks/CfPriceList/src/PriceListView.web";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { withStyles } from "@material-ui/core/styles";
import { IProductItems } from "../../../blocks/CfPriceList/src/PriceListViewController.web";
import {
  Box,
  TextField,
  TextFieldProps,
  Popper,
  PaperProps,
  PopperProps,
  Paper,
  InputAdornment,
  Typography,
  styled,
  Radio,
} from "@material-ui/core";
import { colors } from "../../../blocks/utilities/src/Colors";
import { ref } from "yup";

interface Props {
  value: { id: string | number; option: string };
  optionList: { id: string | number; option: string }[];
  debouncedFunction: Function;
  handleScroll: Function;
  handleSelectOptions: Function;
  style?: Object;
  isError?: boolean;
  changeInputOnClear?: boolean;
  selectedItem: { id: string | number; option: string };
  placeholder: string;
  defaultId?: string | number;
  errorText?: string;
  dataTestID: string;
}
let errorBoolean: boolean = false;

const useStyles = makeStyles(() => ({
  newProductDropdown: {
    minHeight: 56,
    maxWidth: "100%",
  },
  menuItems: {
    display: "flex",
    alignItems: "center",
    width: "100%",
  },
  mainComp: {
    maxHeight: 200,
    width: "100%",
  },
}));

export const CustomAutoCompleteSearchable = ({
  value,
  optionList,
  debouncedFunction,
  handleScroll,
  handleSelectOptions,
  style = {},
  isError = false,
  selectedItem,
  placeholder,
  defaultId,
  errorText,
  dataTestID,
}: Props) => {
  const classes = useStyles();
  const defaultValue = optionList.find(
    (option) => option.id.toString() === defaultId?.toString()
  );
  errorBoolean = isError;

  const selectedOption = selectedItem.id ? selectedItem : defaultValue;

  return (
    <SimpleCustomAutocomplete
      defaultValue={defaultValue}
      fullWidth
      value={value}
      data-test-id={`autocompleteDropdown-${dataTestID}`}
      options={[
        ...optionList.map((item) => ({ id: item.id, option: item.option })),
      ]}
      clearOnEscape={false}
      clearOnBlur={false}
      getOptionLabel={(option: { id: string | number; option: string }) =>
        option.option
      }
      className={classes.newProductDropdown}
      style={style}
      onChange={() => {}}
      renderOption={(
        option: { id: string | number; option: string },
        props: Object
      ) => (
        <>
          <Box
            component={"li"}
            {...props}
            id={`${dataTestID}-${option.id}`}
            data-test-id={`${dataTestID}-${option.id}`}
            className={classes.menuItems}
            onClick={() => {
              handleSelectOptions(option);
            }}
          >
            <Radio checked={option.id === selectedItem.id} />
            {console.log("asdfasdfsadfsadfsad", selectedItem.id, option.id)}
            <Typography>{option.option}</Typography>
          </Box>
        </>
      )}
      renderInput={(params: JSX.IntrinsicAttributes & TextFieldProps) => (
        <>
          <TextFieldComp
            {...params}
            style={{ paddingTop: "0px" }}
            data-test-id={`autoCompleteInput-${dataTestID}`}
            fullWidth={true}
            placeholder={placeholder}
            variant='outlined'
            className={`${isError && "isError"}`}
            onChange={(e: React.ChangeEvent<{ value: string }>) => {
              debouncedFunction(e.target.value);
            }}
          />

          {isError && <ErrorText>{errorText}</ErrorText>}
        </>
      )}
      ListboxProps={{
        onScroll: handleScroll,
        style: {
          maxHeight: "200px",
        },
      }}
      PaperComponent={(props: PaperProps) => {
        return <Paper {...props} className={classes.mainComp} />;
      }}
      PopperComponent={(props: PopperProps) => {
        return (
          <Popper
            {...props}
            placement='bottom-start'
            modifiers={{
              flip: {
                enabled: false,
              },
            }}
          />
        );
      }}
    />
  );
};

const TextFieldComp = withStyles({
  root: {
    width: "100%",
    paddingTop: "10px",
    borderRadius: "8px",
    fontFamily: "Montserrat",
    "& .MuiOutlinedInput-root": {
      fontWeight: 500,
      fontSize: "14px",
      border: "1px solid #ECECEC",
      fontFamily: "Montserrat",
      borderRadius: "8px",
      "& fieldset": {
        fontSize: "14px",
        borderRadius: "8px",
        borderWidth: 1,
        fontWeight: 400,
        fontFamily: "Montserrat",
      },
    },
    "& ::placeholder": {
      opacity: 1,
      color: "#4D4D4D",
      fontSize: "14px",
      fontFamily: "Montserrat",
      "@media only screen and (max-width: 1024px)": {
        fontSize: "12px",
      },
    },
  },
})(TextField);

export const StyledMninDivWrapper = styled("div")({
  border: "1px solid #ececec",
  cursor: "pointer",
  display: "flex",
  justifyContent: "space-between",
  alignTtems: "center",
  borderRadius: "8px",
  marginTop: "8px",
  color: "#4d4d4d",
});
const ErrorText = styled("p")({
  fontSize: "12px",
  color: colors().error,
});
const SimpleCustomAutocomplete: any = styled(Autocomplete)({
  "& .MuiInputBase-root": {
    borderRadius: "8px",
    "@media only screen and (max-width: 1024px)": {
      maxHeight: "44px",
      alignContent: "center",
      fontSize: "12px",
      height: "auto",
      padding: "9.5px 4px",
    },
  },
  "& .MuiChip-deleteIcon": {
    display: "none",
  },
});
