// Customizable Area Start
import React from "react";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import { Box } from "@material-ui/core";
import { withStyles, Theme } from "@material-ui/core/styles";
import { Styles, ClassNameMap } from "@material-ui/styles";
// Customizable Area End

// Customizable Area Start

interface ItemListProps {
    handleSelect?:() => void;
    classes: ClassNameMap<"arrowGroup" | "upArrow" | "downArrow">;
    selected?: string;
    up?: string;
    down?: string
}
// Customizable Area End

// Customizable Area Start
const useStyles: Styles<Theme, {}, string> = () => ({
    arrowGroup: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginLeft: "8px",
        cursor: "pointer",
        ['& svg']: {
            color: "#fff",
            fontSize: "16px"
        }
    },
    upArrow: {
        transform: 'translateY(-5px)',

    border: "1px solid transparent",
    "&.selected": {
        color: "#dfe5f1",
        border: "1px solid #d9d9d9",
        borderRadius: "5px"
    },
},
downArrow: {
    transform: 'translate(-12px,8px)',
    border: "1px solid transparent",
    "&.selected": {
        color: "#dfe5f1",
        border: "1px solid #d9d9d9",
        borderRadius: "5px"
    },
},
});

// Customizable Area End

class CustomArrowIcon extends React.Component<ItemListProps> {
    constructor(props: ItemListProps) {
        super(props);
        // Customizable Area Start

    // Customizable Area End
}

// Customizable Area Start

render() {
    const { classes, up, down, selected,handleSelect} = this.props;

    const upArrow = up === selected ? "selected" : "";
    const downArrow = down === selected ? "selected" : "";
    return (
        <Box className={classes.arrowGroup} onClick={handleSelect} data-test-id="sortingArrow">
            <Box className={`${classes.upArrow} ${upArrow}`} >
                <ArrowUpwardIcon />
            </Box>
            <Box className={`${classes.downArrow} ${downArrow}`}>
                <ArrowDownwardIcon />
            </Box>
        </Box>
    );
}
// Customizable Area End
};

export default withStyles(useStyles)(CustomArrowIcon);