import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
const deliveryDetailsData = { "data": { "id": "4", "type": "expressdelivery", "attributes": { "delivery_charges": 0, "delivery_date_and_time": "2023-07-08T20:09:00.000Z" } } }
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  token: string;
  addressTxt: string;
  isExpressDeliveryEnabled: boolean;
  isLoading: boolean;
  expressDeliveryData: {
    attributes: {
      delivery_charges: number;
      delivery_date_and_time: string;
    }
  };

  error: boolean;
  address: string,
  deliveryCharge: number,
  total: number,
  date: string
  isExpanded: boolean;
  isViewExpanded: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ExpressDeliveryController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  expressDeliveryDataId: string;
  patchDeliveryDataId: string;
  getUserDataId: string;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.expressDeliveryDataId = '';
    this.patchDeliveryDataId = "";
    this.getUserDataId = "";
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseToken),
      getName(MessageEnum.SessionResponseMessage)
      // Customizable Area End
    ];
    // Customizable Area End

    this.state = {
      txtInputValue: "",
      token: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      addressTxt: '',
      isExpressDeliveryEnabled: false,
      isLoading: false,
      expressDeliveryData: deliveryDetailsData.data,
      isViewExpanded: false,

      error: false,
      address: configJSON.defaultAddress,
      deliveryCharge: configJSON.defaultDeliveryCharge,
      total: configJSON.defaultTotal,
      date: new Date(Date.now() + 1000 * 60 * 60 * 24).toISOString(),
      isExpanded: false

      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let valueTxt = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + valueTxt
      );

      this.setState({ txtSavedValue: valueTxt });
    }

    // Customizable Area Start
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      this.setState({ token: token }, () => {
        this.getExpressDeliveryData();
        this.getUserEDData()
      });

    } else if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (responseJson && responseJson.errors) {
        this.setState({ error: true });
      } else if (responseJson && responseJson?.data && apiRequestCallId === this.expressDeliveryDataId) {
        const attributes = responseJson.data?.attributes
        this.setState({ deliveryCharge: attributes?.delivery_charges, date: attributes?.delivery_date_and_time, error: false });
        this.setState({ expressDeliveryData: responseJson.data, isExpressDeliveryEnabled: responseJson?.isExpressDeliveryEnabled || false })
      } else if (responseJson && responseJson?.data && apiRequestCallId === this.getUserDataId) {
        this.setState({ isExpressDeliveryEnabled: responseJson.data?.attributes?.express_delivery_enable ?? false })
      }
      else if (errorReponse) {
        this.setState({ error: true });
      }
    }
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (textValue: string) => {
      this.setState({ txtInputValue: textValue });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msgTxt = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msgTxt.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msgTxt);
  }

  // web events
  setInputValue = (textValue: string) => {
    this.setState({ txtInputValue: textValue });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start
  async componentDidMount() {
    this.getToken()
  }

  getToken = () => {
    const message: Message = new Message(getName(MessageEnum.SessionRequestMessage));
    this.send(message);
  };


  toggleSwitch = () => {
    const isExpressDelivery = !this.state.isExpressDeliveryEnabled
    this.setState({ isExpressDeliveryEnabled: isExpressDelivery }, () => {
      this.patchExpressDeliveryData()
    });

  }

  toggleExpandView = () => {
    this.setState({ isViewExpanded: !this.state.isViewExpanded });
  }

  toggleExpand = (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
    this.setState({ isExpanded: isExpanded })
  }

  getExpressDeliveryData = () => {

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.expressDeliveryDataId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.expressDeliveryApi
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  patchExpressDeliveryData() {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.patchDeliveryDataId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.pathcDeliveryUrl
    );

    const headers = {
      "Content-Type": configJSON.patchDeliveryContentType,
      token: this.state.token
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      headers
    );

    const bodyData = { express_delivery_enable: this.state.isExpressDeliveryEnabled };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(bodyData)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.patchDeliveryMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getUserEDData = () => {

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getUserDataId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getUserProfile
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  // Customizable Area End
}
