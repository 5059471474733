// Customizable Area Start
import React from "react";
import {
  Paper,
  TableRow,
  TableHead,
  TableContainer,
  TablePagination,
  TableBody,
  Table,
  Box,
  Button,
  Popover,
  IconButton,
  TableCell,
  styled
} from "@material-ui/core";

import MoreVertIcon from "@material-ui/icons/MoreVert";
import FilterIcon from "@material-ui/icons/FilterList";

import InvitefriendsController, { Props, configJSON } from "./InviteFriendsController.web";
import PageContainer from "../../navigationmenu/src/PageContainer.web"
import ConfirmationModal from "../../../components/src/customComponents/ConfirmationModal.web";
import FilterPopover, { IFilter } from "../../../components/src/FilterPopover";
import SortingTableHeader from "../../../components/src/SortingTableHeader";
import SortingTableCell from "../../../components/src/SortingTableCell";
import { colors } from "../../utilities/src/Colors";
import { renderBaseOnConditions,sortCondition } from "../../../blocks/utilities/src/CustomBlockHelpers";
// Customizable Area End

export default class InviteFriends extends InvitefriendsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  render() {
    const { isModalOpen, deactivated,permissionStatus } = this.state;
    const rows = [...this.state.list];

    const sortingData = this.state.sortingData;
    return (
      <>
        <Box className={"mainWrapper"}>
          <PageContainer
            data-test-id='pagecontainer'
            onSearchText={(value) => this.handleSearch(value)}
            navigation={this.props.navigation}
            onUserChange={(userContext) => this.handleUserChange(userContext)}
          >
          <StylesBoxWrapper>
            <Box>
              <HeaderWrapper className={"headerWrapper"}>
                <OrgHeading>{configJSON.title}</OrgHeading>
                <HeaderButtonPart className={"headerButtonPart"}>
                  <FilterButtonDiv data-test-id='filterButton'>
                    <FilterIcon />
                  </FilterButtonDiv>
                  <FilterPopover
                    onClose={() => {
                      this.setState({ filterAnchor: undefined });
                    }}
                    anchor={this.state.filterAnchor}
                    onClearFilter={() => {
                      let updated = this.state.filters.map((item: IFilter) => {
                        item.value = "";
                        item.options = [];
                        return item;
                      });
                      this.setState({ filters: updated });
                    }}
                    onFilterChange={this.handleFilterChange}
                    filters={this.state.filters}
                  />
              
                  {
                    sortCondition(
                      this.state.permissionStatus.createPermission,
                      <AddButton> 
                      <Button
                        data-test-id='addInviteFriend'
                        onClick={this.handleAdd}
                        className="addInviteButton"
                      >
                      Add Invite Code
                    </Button>
                    </AddButton>,
                      <></>
                      )
                  }
              
                </HeaderButtonPart>
              </HeaderWrapper>
              <TableContainer component={Paper}>
                <Table className={"table"} aria-label='customized table'>
                  <TableHead>
                    <TableRow
                      style={{ background: "#204B9C", color: "#FFFFFF" }}
                    >
                      <SortingTableHeader
                        sortingData={sortingData}
                        title={configJSON.inputExistingCredit}
                        sortingKey='discount_amount'
                        {...this.sortingProps}
                        type='left'
                        width='39%'
                      />
                      <SortingTableHeader
                        sortingData={sortingData}
                        title={configJSON.inputNewCredit}
                        sortingKey='new_customer_discount'
                        {...this.sortingProps}
                        type='middle'
                        width='39%'
                      />
                      <SortingTableHeader
                        sortingData={sortingData}
                        title={configJSON.status}
                        sortingKey='activated'
                        {...this.sortingProps}
                        type='middle'
                        width='15%'
                      />
                      <SortingTableHeader
                        sortingData={sortingData}
                        title={""}
                        sortingKey=''
                        sortingDisabled={true}
                        {...this.sortingProps}
                        type='action'
                        width='7%'
                      />
                    </TableRow>
                  </TableHead>
                  {rows.length > 0 && (
                    <TableBody>
                      {rows.map((rowData, index) => (
                        <TableRow key={index + "TableRow"}>
                          <SortingTableCell
                            width='39vw'
                            type='left'
                            content={`SAR ${rowData.attributes.discount_amount || 0}`}
                          />
                          <SortingTableCell
                            width='39vw'
                            content={`SAR ${rowData.attributes.new_customer_discount || 0}`}
                          />
                          <SortingTableCell
                            type='right'
                            width='15vw'
                            content={this.handleStatusColumn(rowData)}
                          />

                          <SortingTableCell
                            type='action'
                            width='7vw'
                            content={
                              <IconButton
                                data-test-id={`moreButton${index}`}
                                id={"IconButton" + index}
                                onClick={(event) => {
                                  const position = event.currentTarget.getBoundingClientRect();
                                  this.setState({
                                    popOverInviteId: rowData.id,
                                    popOverItemStatus:
                                      rowData.attributes.activated === true
                                        ? "Active"
                                        : "Deactive",
                                    popOverOpened: true,
                                    popOverLeft: position.left + window.scrollX,
                                    popOverTop: position.top + window.scrollY,
                                  });
                                }}
                              >
                                <MoreVertIcon />
                              </IconButton>
                            }
                          />
                        </TableRow>
                      ))}
                     {this.state.pageSize - rows.length > 0 && (
                      <TableRow
                        style={{
                          height: 53 * (this.state.pageSize - rows.length),
                        }}
                      >
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                    </TableBody>
                  )}
                </Table>
                {rows.length === 0 && (
                  <TableRow>
                    <TableCell colSpan={5}>
                       <div className={"loaderDiv"}>No records found!!</div>
                    </TableCell>
                  </TableRow>
                )}
              </TableContainer>
              {rows.length > 0 && (
                <TablePagination
                  data-test-id='pagination'
                  rowsPerPageOptions={[10]}
                  component='div'
                  count={this.state.metaData.total_count}
                  rowsPerPage={this.state.pageSize}
                  page={this.state.page - 1}
                  onPageChange={(event, page) => {
                    this.handlePageChange(page + 1);
                  }}
                />
              )}
            </Box>
          </StylesBoxWrapper>
            <Popover
              data-test-id={configJSON.popoverTest}
              className='boxShadowNone'
              anchorReference='anchorPosition'
              open={this.state.popOverOpened}
              onClose={() => this.setState({ popOverOpened: false })}
              anchorPosition={{
                top: this.state.popOverTop + 40,
                left: this.state.popOverLeft - 80,
              }}
              anchorOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
            >
               {
                renderBaseOnConditions(
                  (permissionStatus.viewPermission ||
                  permissionStatus.editPermission ||
                  permissionStatus.deactivatePermission ||
                  permissionStatus.activatePermission as boolean),
                  <div className='popoverContainer'>
                    {
                      renderBaseOnConditions(
                        this.state.permissionStatus.viewPermission,
                        <Box
                        data-test-id={'viewButton'+ this.state.popOverInviteId}
                        onClick={() => this.handleView(this.state.popOverInviteId)}
                        sx={webStyle.popoverButton}
                        >
                          View
                        </Box>,
                        <></>
                      )
                    }
                    {
                      renderBaseOnConditions(
                        this.state.permissionStatus.editPermission,
                        <Box
                          data-test-id='editButton'
                          onClick={() => this.handleEdit(this.state.popOverInviteId)}
                          sx={webStyle.popoverButton}
                        >
                          Edit
                        </Box>,
                        <></>
                      )
                    }
                    {
                      renderBaseOnConditions(
                        permissionStatus.deactivatePermission && this.state.popOverItemStatus === "Active",
                        <Box
                          onClick={() => this.handleDeactivate(this.state.popOverInviteId)}
                          data-test-id={"activateButton" + this.state.popOverInviteId}
                          sx={webStyle.popoverButton}
                        >
                          Deactivate
                        </Box>,
                        <></>
                      )
                    }
                    {
                      renderBaseOnConditions(
                        permissionStatus.activatePermission as boolean && this.state.popOverItemStatus !== "Active",
                        <Box
                          data-test-id={"activateButton" + this.state.popOverInviteId}
                          sx={webStyle.popoverButton}
                          onClick={() => this.changeActivateUser()}
                        >
                          Activate
                        </Box>,
                        <></>
                      )
                    }
                  </div>,
                  <div className="popoverContainer">
                    <StyleNoPermission>
                      No Permissions
                    </StyleNoPermission>
                  </div>
                )
            }
            </Popover>
          </PageContainer>
          <ConfirmationModal
            open={isModalOpen}
            handleClose={this.handleDeactivateClose}
            handleConfirm={this.changeActivateUser}
            title='Confirm Deactivate'
            message={{
              id: this.state.clickedCustomerType?.id,
              title: `Invite code / Referral`,
            }}
            dynamic={true}
            confirmText='Deactivate'
          />

          {deactivated && (
            <ConfirmationModal
              open={deactivated}
              handleClose={this.handleDeactivatedClose}
              handleConfirm={this.handleGoList}
              title={"Customer Type Deactivated"}
              message={{
                id: this.state.clickedCustomerType?.id,
                title: `Invite code / Referral`,
              }}
              confirmText={"Back to Listing"}
              displayItem={true}
            />
          )}
        </Box>
      </>
    );
  }
  // Customizable Area End
}

// Customizable Area Start
const webStyle = {
  popoverButton: {
    cursor: "pointer",
    width: "118px",
    height: "33px",
    paddingLeft: "24px",
    backgroundColor: "#FFFFFF",
    color: "#4D4D4D",
    "&:hover": {
      backgroundColor: "#204B9C",
      color: "#FFFFFF",
    },
    paddingTop: "8px",
  },
  addButton: {
    background: "#204B9C",
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: "24px",
    color: "#FFFFFF",
    height: "56px",
    // width: "184px",
    borderRadius: "8px",
  },
}
const StylesBoxWrapper = styled(Box)({
  paddingTop:"20px",
  "& .loaderDiv":{
    textAlign: "center",
    fontSize: "16px",
    fontWeight: 600,
  }
});

export const OrgHeading = styled("p")({
  fontSize: "24px",
  lineHeight: "30px",
  fontWeight: 600,
  color: colors().black,
  "@media only screen and (max-width: 600px)": {
    marginBottom: "20px",
  },
  "@media only screen and (max-width: 1024px)": {
    fontSize: "16px",
    fontWeight: 600,
    color: colors().black,
  },
});

const FilterButtonDiv = styled("div")({
  padding: "16px",
  borderRadius: "15px",
  border: "1px solid rgba(32, 75, 156, 1)",
  cursor: "pointer",
  marginRight: "24px",
  "@media only screen and (max-width: 1024px)": {
    fontSize: "16px",
    width: "44px",
    height: "44px",
    padding: "9px",
  },
})

const AddButton = styled(Box)({
  '& .addInviteButton': {
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: "24px",
    background: colors().cyancobaltblue,
    width: "184px",
    color: colors().white,
    height: "56px",
    textTransform: "unset",
    borderRadius: "8px",
    padding: "16px",
    "@media only screen and (max-width: 1024px)": {
      width: "160px",
      fontSize: "13px",
      height: "44px",
    },
  }
});

const HeaderWrapper = styled('div')({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginBottom: "32px"
})

const HeaderButtonPart = styled('div')({
  display: "flex",
  alignItems: "center"
})

const StyleNoPermission = styled('div')({
    padding: "8px",
})
// Customizable Area End
