Object.defineProperty(exports, '__esModule', {
  value: true
})

// Customizable Area Start
exports.apiContentType = 'application/json'
exports.getApiMethod = 'GET'
exports.postApiMethod = 'POST'
exports.deleteApiMethod = 'DELETE'
exports.test = 'test'

exports.getOrdersApiEndPoint = 'shopping_cart/orders'
exports.createOrderItemApiEndPoint = 'shopping_cart/order_items'
exports.deleteOrderItemApiEndPoint = 'shopping_cart/orders'

exports.customerSearchEndPoint = "account_block/customers/auto_complete";
exports.searchCustomerApiEndPoint =
  "/account_block/web_customers/search_customer_account";
exports.createNewOrderEndPoint = "/bx_block_order_management/orders/new_order";
exports.updateOrderEndPoint = "/bx_block_order_management/orders/"

exports.Strings = {
  customer_phone_already_register: "This phone number already belongs to a customer",
  service: "Service",
  select_service: "Select Service",
  cart_empty_text: "Your Cart is Empty"
}
exports.clearAllText = "Clear All"

exports.errorTitle = 'Error'
exports.save = "Save"
exports.test = "Test"
exports.errorAllFieldsAreMandatory = 'All fields are mandatory.'
exports.noCustomerFound = "No customers found";
exports.mobileNumber = "Mobile Number";
exports.setLoaderFalse = "setLoaderFalse";
exports.setCustomerPhone = "Customer phone retain";
exports.EditOrderIdPass = "Quick Drop Edit Order";
// Customizable Area End
