// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area End
export const configJSON = require("./config");

export interface Props {
    navigation?: any;
    id?: string;
    // Customizable Area Start

    // Customizable Area End
}

interface S {
    // Customizable Area Start
    dropDownOptions: DropDowns[],
    dropDownSelected: string,
    openDropDown: boolean,
    defaultIcon: string,
    optionIconId: number | string,
    preference_name: string,
    preference_second_title: string,
    isErrorMessage: boolean,
    openPopup: boolean;
    severity: string
    message: string,
    item: {
        id: number
        attributes: {
            preference_first_name: string,
            preference_second_name: string,
            icon_id: number,
            gallery_icon: {
                image: string,
                name_translation:string
            } | null
        }
    },
    isLoading: boolean;
    iconNextPage: number;
    currentDropDownOptions: DropDowns[],
    imageNextPage:number;
    currentIconData:Options[];
    selectedImage:{id:string | number,option:string,image:string},
    currentOptions: Options[];
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    id: string | number;

    // Customizable Area End
}
// Customizable Area Start
export interface DropDowns {
    id: number|string,
    attributes: {
        name: string;
        id: number,
        label: string,
        image: string 
    }
}
export interface Options {
    id: number | string,
    attributes: {
        name: string;
        id: number,
        label: string,
        image: string 
    }
}


// Customizable Area End

// Customizable Area Start
export default class EditPreferenceController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    iconListDetailCallId: string = "";
    editPreferenceCallId: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.CountryCodeMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.ReciveUserCredentials),
        ];

        this.state = {
            dropDownOptions: [],
            item: this.props?.navigation.history.location.state?.state,
            dropDownSelected: "",
            openDropDown: false,
            defaultIcon: configJSON.defaultIcon,
            optionIconId: "",
            preference_name: "",
            preference_second_title: "",
            isErrorMessage: false,
            openPopup: false,
            message: "",
            severity: "",
            isLoading: false,
            iconNextPage: 1,
            currentDropDownOptions: [],
            imageNextPage:1,
            currentIconData:[] as Options[],
            selectedImage:{} as {id:string | number,option:string,image:string},
            currentOptions:[] as Options[]
        };
        // Customizable Area End

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    componentDidMount(): any {
        // Customizable Area Start
        this.getAllIcons()
        this.handleChangeValue()
        // Customizable Area End
    }
    // Customizable Area Start
    handleChangeValue = () => {
        const { attributes } = this.state.item
        this.setState({
            preference_name: attributes.preference_first_name,
            preference_second_title: attributes.preference_second_name,
            optionIconId: attributes.icon_id,
            dropDownSelected: attributes.gallery_icon === null ? this.state.defaultIcon : attributes.gallery_icon.image,
            selectedImage:{id:attributes.icon_id,image:attributes.gallery_icon === null ? this.state.defaultIcon : attributes.gallery_icon.image,option:attributes.gallery_icon?attributes.gallery_icon.name_translation:''}
        })
    }
    handleOpenDropdown = () => {
        this.setState((prevState) => ({
            openDropDown: !prevState.openDropDown,
        }));
    };
    handleCloseSnackbar = () => {
        this.setState({ openPopup: false });
    }
    handleCancel = () => {
        this.props.navigation.history.push("/Products-PreferenceList")
    }
    handleDropdownOptionSelect = (option: DropDowns) => {
        this.setState({
            optionIconId: option.attributes.id,
            dropDownSelected: option.attributes.image === null ? this.state.defaultIcon : option.attributes.image,
            openDropDown: false,
        });
    };
    handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            preference_name: event.target.value,
            isErrorMessage: false,
            openDropDown: false,
        });
    };
    handleSecondNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            preference_second_title: event.target.value,
            isErrorMessage: false,
            openDropDown: false,
        });
    };

    checkFormErrors = () => {
      const { preference_name } = this.state;
      return preference_name !== ''
    }

    handleSave = () => {
        const { preference_name, item: { id } } = this.state;
        if (preference_name === '') {
            this.setState({ isErrorMessage: true });
        } else {
            this.setState({ isErrorMessage: false });
        }
        if (this.checkFormErrors()) {
            this.setState({isLoading: true})
            this.editPreference(id)
        }
    };

    getAllIcons = () => {
        const headers = {
            "Content-Type": configJSON.categoryApiContentType,
            token: window.localStorage.getItem(configJSON.token)
        };

        const getIconsMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.iconListDetailCallId = getIconsMessage.messageId;

        getIconsMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.iconListApiEndPoint + `?page_no=${this.state.iconNextPage}`
        );

        getIconsMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        getIconsMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.httpGetType
        );

        runEngine.sendMessage(getIconsMessage.id, getIconsMessage);
    }
    editPreference = (account_id: number) => {

        let headers = {
            "Content-Type": configJSON.categoryApiContentType,
            token: window.localStorage.getItem(configJSON.token)
        };
        let httpBody = {}
        httpBody = {

            preference_first_name: this.state.preference_name,
            preference_second_name: this.state.preference_second_title,
            icon_id: this.state.optionIconId

        }
        const editPreferenceMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.editPreferenceCallId = editPreferenceMessage.messageId;

        editPreferenceMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.updatePreferenceListEndPoint}/${account_id}`
        );

        editPreferenceMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        editPreferenceMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );
        editPreferenceMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.httpUpdateMethodType
        );

        runEngine.sendMessage(editPreferenceMessage.id, editPreferenceMessage);

    }
    handleResponseAllIconsList = (from: string, message: Message) => {
        if (this.iconListDetailCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
            const apiResponse = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (apiResponse.data) {
                const existingDropDownOption = this.state.dropDownOptions;
                const NewOptions = [...existingDropDownOption, ...apiResponse.data]
                this.setState({
                    dropDownOptions: NewOptions,
                    currentDropDownOptions: apiResponse.data
                })
            }
        }
    }
    handleResponseForEditPreference = (from: string, message: Message) => {
        if (this.editPreferenceCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
            const apiResponse = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (apiResponse.data) {
                this.setState({
                    openPopup: true,
                    message: configJSON.snackbarSuccessMessage,
                    severity: configJSON.severitySuccess
                })
                setTimeout(() => {
                    this.props.navigation.history.push("/Products-PreferenceList")
                }, 1000)

            } else {
                this.setState({
                    isLoading: false,
                    openPopup: true,
                    message: apiResponse?.errors[0]?.message,
                    severity: configJSON.typeError

                })
            }
        }
    }

    handleScroll = (event: React.UIEvent<HTMLUListElement, UIEvent>) => {
        const target = event.target as HTMLElement;
        if (target.scrollHeight - target.scrollTop === target.clientHeight && this.state.currentDropDownOptions.length !== 0 ) {
            this.setState({iconNextPage: this.state.iconNextPage + 1},
                () => this.getAllIcons()
            )
        }
    };

    getIconList = (options:DropDowns[])=>{
        return options.map((option)=>{
            return {id:option.attributes.id,image:option.attributes.image,option:option.attributes.name}
        })
    }

    handleGetImageOnScroll = (event: React.SyntheticEvent) => {
        const listboxNode = event.currentTarget;
        const position = listboxNode.scrollTop + listboxNode.clientHeight;
        
        if (listboxNode.scrollHeight - position <= 1.30 && this.state.currentDropDownOptions.length !== 0) {
          this.setState({ iconNextPage: this.state.iconNextPage + 1 }, () => {
            event.preventDefault();
            this.getAllIcons()
          })
        }
      };

      handleSelectImage = (value:{ id: string | number, option: string, image: string }) => {
        this.setState({
            selectedImage:value,
            optionIconId: value.id,
            dropDownSelected: value.image === null ? this.state.defaultIcon : value.image,
            openDropDown: false,
        })
      }


    // Customizable Area End
    async receive(from: string, message: Message) {
        // Customizable Area Start
        this.handleResponseAllIconsList(from, message)
        this.handleResponseForEditPreference(from, message)
        // Customizable Area End
    }

}
// Customizable Area End
